import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import store from '@/store';
import type { HResourceType, OrderAccount } from '@/types';
import { isCPanelHosting } from '@/utils/helpers';
import { i18n } from '@/utils/services/i18nService';

interface Props {
  serviceId?: string | null;
  service?: HResourceType;
  shouldFetch?: boolean;
  websites?: OrderAccount[];
}

export const useHostingResources = (props: Props = {}) => {
  const route = useRoute();
  const id = computed(() => props.serviceId || route?.params?.id);

  const shouldFetch = computed(() => props.shouldFetch ?? true);

  onMounted(async () => {
    if ((props.websites && !props?.websites?.length) || !shouldFetch.value) {
      return;
    }

    const username = props.websites?.[0]?.username;

    if (id.value && props.service && !isCPanelHosting(props.service)) {
      await store.dispatch('fetchHostingOrderUsage', {
        orderId: id.value,
        username,
      });
    }
  });

  // In the last 24 hours
  const resourceUsagePercentage = computed(() =>
    store.getters.getHostingOrderUsage(id.value),
  );

  const indicationBarUsage = computed(
    () => `${resourceUsagePercentage.value ?? 0}%`,
  );

  const indicationBarColors = computed(() => {
    if (resourceUsagePercentage.value >= Threshold.DANGER_THRESHOLD) {
      return {
        backgroundColor: 'var(--danger-light)',
        barColor: 'var(--danger)',
      };
    }
    if (resourceUsagePercentage.value >= Threshold.WARNING_THRESHOLD) {
      return {
        backgroundColor: 'var(--warning-light)',
        barColor: 'var(--warning)',
      };
    }

    return {
      backgroundColor: 'var(--primary-light)',
      barColor: 'var(--primary)',
    };
  });

  const isSomeResourceLimitReached = computed(
    () =>
      resourceUsagePercentage.value >= Threshold.WARNING_THRESHOLD &&
      resourceUsagePercentage.value < Threshold.MAX_THRESHOLD,
  );

  const usageBarTooltip = computed(() =>
    i18n.t(
      `Indicates highest usage of main resources: Disk space, inodes, memory, NPROC, EP.`,
    ),
  );

  return {
    resourceUsagePercentage,
    indicationBarUsage,
    indicationBarColors,
    isSomeResourceLimitReached,
    usageBarTooltip,
  };
};
enum Threshold {
  MAX_THRESHOLD = 100,
  WARNING_THRESHOLD = 80,
  DANGER_THRESHOLD = 90,
}
