<template>
  <Component
    :is="routerOrExternal"
    class="h-list-link"
    v-bind="link"
    :target="target"
  >
    <slot />
  </Component>
</template>
<script>
import { isObject } from 'lodash';

export default {
  name: 'HListLink',
  props: {
    to: [String, Object],
    target: [String, Boolean],
  },
  computed: {
    routerOrExternal() {
      if (isObject(this.to)) return 'router-link';

      return 'a';
    },
    link() {
      if (this.routerOrExternal === 'router-link') {
        return {
          to: this.to,
        };
      }

      return { href: this.to };
    },
  },
};
</script>
<style lang="scss">
@import '../../sass/components/h-list-link';
</style>
