<script setup lang="ts">
import { computed } from 'vue';

import type { HLabelTheme } from '@/types';
import { H_LABEL_THEME_CONFIGURATION } from '@/types';
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

type Props = {
  theme?: HLabelTheme;
};

const props = withDefaults(defineProps<Props>(), {
  theme: 'primary',
});

const configuration = computed(() => H_LABEL_THEME_CONFIGURATION[props.theme]);

const style = computed(() => ({
  backgroundColor: wrapInCssVar(configuration.value.backgroundColor),
  color: wrapInCssVar(configuration.value.color),
}));
</script>

<template>
  <div class="label">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  background-color: v-bind('style.backgroundColor');
  color: v-bind('style.color');
}
</style>
