import type { RouteRecordRaw } from 'vue-router';

import domainRoutes from '@/router/routes/domains';
import hostingEmails from '@/router/routes/emails/hostingEmails';
import titanEmailRoutes from '@/router/routes/emails/titanEmailRoutes';
import hBillingRoutes from '@/router/routes/hbillingRoutes';
import hostingRoutes from '@/router/routes/hosting';
import vpsRoutes from '@/router/routes/servers/servers';
import { websitesRoute } from '@/router/routes/websitesRoutes';
import { RoutePath } from '@/types';

export const extractRouteNames = (routeConfig: RouteRecordRaw | undefined) => {
  if (!routeConfig) return [];

  const routeNames = [];

  if (routeConfig.name) {
    routeNames.push(routeConfig.name);
  }

  if (routeConfig.children && routeConfig.children.length > 0) {
    routeConfig.children.forEach((childRoute) => {
      const childRouteNames = extractRouteNames(childRoute);
      routeNames.push(...childRouteNames);
    });
  }

  return routeNames;
};

export const extractRoutesNames = (routeConfigs: RouteRecordRaw[]) =>
  routeConfigs.flatMap((routeConfig) => extractRouteNames(routeConfig));

export const hostingDashboardRouteNames = extractRouteNames(websitesRoute);

export const hostingV2RouteNames = extractRouteNames(
  hostingRoutes.find((route) => route.path === RoutePath.Hosting.HOSTING_V2),
);

export const hostingerEmailRouteNames = extractRoutesNames(hostingEmails);

export const hBillingRouteNames = extractRoutesNames(hBillingRoutes);

export const vpsRouteNames = extractRoutesNames(vpsRoutes);

export const titanEmailRouteNames = extractRoutesNames(titanEmailRoutes);

export const domainRouteNames = extractRouteNames(
  domainRoutes.find((route) => route.path === RoutePath.Domain.DOMAIN),
);

export const getIsHostingDashboardRoute = (routeName: string) =>
  hostingDashboardRouteNames.includes(routeName);

export const getIsHostingEmailRoute = (routeName: string) =>
  hostingerEmailRouteNames.includes(routeName);

export const getIsHostingV2Route = (routeName: string) =>
  hostingV2RouteNames.includes(routeName);

export const getIsHBillingRoute = (routeName: string) =>
  hBillingRouteNames.includes(routeName);

export const getIsVpsRoute = (routeName: string) =>
  vpsRouteNames.includes(routeName);

export const getIsTitanEmailRoute = (routeName: string) =>
  titanEmailRouteNames.includes(routeName);

export const getIsDomainRoutes = (routeName: string) =>
  domainRouteNames.includes(routeName);
