<template>
  <HFormDeprecated ref="form" v-model="form">
    <HFormFieldDeprecated
      name="accountSelect"
      :schema="accountSelectSchema"
      @change="updateSelect($event, true)"
    />
  </HFormDeprecated>
</template>

<script>
import { mapGetters } from 'vuex';

import { useAddWebsite } from '@/composables';
import { mapSelected } from '@/utils/helpers';

export default {
  name: 'HostingAccountSelect',
  props: {
    hideAddOption: Boolean,
  },
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    if (this.domainToManage) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
      });

      return;
    }

    if (this.domain) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          domain: this.domain,
          domainToManage: this.domain,
          directory: this.directory,
        },
        query: { ...this.$route.query },
      });
    }
  },
  setup() {
    const { addWebsiteRedirectByCurrentOrderId } = useAddWebsite();

    return {
      addWebsiteRedirectByCurrentOrderId,
    };
  },
  methods: {
    getAddWebsiteOption() {
      return {
        label: `+ ${this.$t('Add Website')}`,
        value: 'domains_add_website',
      };
    },
    updateSelect(newDomain, fromSelect) {
      const value = newDomain.value;

      if (value === 'domains_add_website') {
        this.addWebsiteRedirectByCurrentOrderId();

        return;
      }
      const domainToManage = this.$route.params?.domainToManage?.includes(value)
        ? this.$route.params?.domainToManage
        : value;

      const routeParams = fromSelect
        ? { domain: value, domainToManage }
        : this.$route.params;

      this.$router.push({
        name: this.$route.name,
        params: routeParams,
        query: { ...this.$route.query },
      });
    },
  },
  computed: {
    accountSelectSchema() {
      return {
        label: this.showLabel ? 'Website name' : false,
        type: 'select',
        value: this.selectedDomain,
        values: [
          !this.hideAddOption && this.getAddWebsiteOption(),
          ...this.activeDomainSelectionsByType(),
        ],
      };
    },
    selectedDomain() {
      return mapSelected(this.domain);
    },
    domain() {
      return this.$route?.params?.domain;
    },
    domainToManage() {
      return this.$route?.params?.domainToManage;
    },
    showLabel() {
      const pathParts = this.$route.path.split('/').filter((part) => part);

      return pathParts[0] === 'hosting';
    },
    ...mapGetters(['token', 'activeDomainSelectionsByType']),
  },
  watch: {
    '$route.name'() {
      this.updateSelect(mapSelected(this.domain));
    },
    '$route.params.domain'(value) {
      if (!value) return;
      this.updateSelect(mapSelected(this.domain));
    },
  },
};
</script>
