import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import type { ResponseError } from '@/types';
import { getSldTld } from '@/utils/helpers';

const DOMAIN_NOT_REGISTERED_MESSAGE = 'Domain is not registered.';
const DOMAIN_IS_LOCKED = 'Domain is locked and cannot be transferred.';
const DOMAIN_IS_60_DAYS_LOCKED =
  'Domain is locked for 60 days and cannot be transferred.';
const DOMAIN_ALREADY_TRANSFERRED = `This domain is already transferred, please contact support.`;

export const useTransferValidation = () => {
  const isLockedError = ref(false);
  const is60DaysLockedError = ref(false);
  const isNotRegisteredError = ref(false);
  const isDomainAlreadyInHostinger = ref(false);
  const transferableErrorMessage = ref('');

  const resetTransferValidation = () => {
    isLockedError.value = false;
    is60DaysLockedError.value = false;
    isNotRegisteredError.value = false;
    isDomainAlreadyInHostinger.value = false;
    transferableErrorMessage.value = '';
  };

  const checkIsDomainTransferable = async (
    domain: string,
    config?: { isCustomerResourceCheckSkipped?: boolean },
  ) => {
    const [sld, tld] = getSldTld(domain, {
      omitDot: true,
    });

    const [, err] = await hDomainsRepo.getIsDomainTransferable(
      {
        domain: sld,
        tld,
        skipCustomerResourceCheck: !!config?.isCustomerResourceCheckSkipped,
      },
      {
        hideToastr: true,
      },
    );

    transferableErrorMessage.value =
      (err as { error: ResponseError })?.error?.message || '';

    if (transferableErrorMessage.value) {
      handleDomainTransferableError();
    }

    return !err;
  };

  const handleDomainTransferableError = () => {
    const message = transferableErrorMessage.value;

    isNotRegisteredError.value = message === DOMAIN_NOT_REGISTERED_MESSAGE;
    isLockedError.value = message === DOMAIN_IS_LOCKED;
    is60DaysLockedError.value = message === DOMAIN_IS_60_DAYS_LOCKED;
    isDomainAlreadyInHostinger.value = message === DOMAIN_ALREADY_TRANSFERRED;
  };

  return {
    checkIsDomainTransferable,
    isNotRegisteredError,
    isLockedError,
    is60DaysLockedError,
    isDomainAlreadyInHostinger,
    transferableErrorMessage,
    resetTransferValidation,
  };
};
