import { Currency } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
/**
 * Calculates monthly price from the price for the whole period.
 *
 * @param price price for the whole period
 * @param monthCount number of months in the period
 * @returns monthly price
 */
export const getMonthlyPrice = (price: number | null, monthCount: number) => {
  if (!price || monthCount <= 0) {
    return Number.NaN;
  }

  return price / monthCount;
};

let currenciesConvertRates = {};

export const initCurrenciesConvertRates = async () => {
  if (Object.keys(currenciesConvertRates).length) {
    return;
  }

  try {
    currenciesConvertRates = (
      await import(
        `../../../currencies-convert-rates/currencies-convert-rates.json`
      )
    ).default;
  } catch (error) {
    errorLogger.logError(
      new Error('Error at initCurrenciesConvertRates', { cause: error }),
    );
  }
};

export const convertToEur = (amount: number, currency: Currency.Code) => {
  const currenciesConvertRate = (
    currenciesConvertRates as unknown as Record<Currency.Code, number>
  )[currency];

  if (!(typeof currenciesConvertRate === 'number')) {
    return {
      amount: +(amount * 0.01).toFixed(2),
      currency,
    };
  }

  return {
    amount: +(amount * currenciesConvertRate * 0.01).toFixed(2),
    currency: Currency.Code.EUR,
  };
};
