import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route, Email } from '@/types';

const proEmailOnboardingGuard: NavigationGuard = async (to, from, next) => {
  const subscriptionId = to.params.orderId as string;

  if (!store.getters['emails/getLoaded']) {
    await store.dispatch('emails/fetchEmails');
  }

  const emailOrder = await store.getters[
    'emails/getEmailOrderByResellerOrderId'
  ](subscriptionId);

  if (emailOrder?.status !== Email.OrderStatus.PENDING_SETUP) {
    return next({ name: Route.Email.EMAILS });
  }

  return next();
};

export default proEmailOnboardingGuard;
