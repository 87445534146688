export default (oldVersion = '', newVersion = '') => {
  const oldVersionParts = oldVersion.split('.');
  const newVersionParts = newVersion.split('.');
  for (
    let i = 0;
    i < Math.max(newVersionParts.length, oldVersionParts.length);
    i++
  ) {
    const newPart = parseInt(newVersionParts[i]) || 0;
    const oldPart = parseInt(oldVersionParts[i]) || 0;
    if (newPart > oldPart) return -1;
    if (newPart < oldPart) return 1;
  }

  return 0;
};
