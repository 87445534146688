export default {
  props: {
    currentStep: Number,
    stepsCount: Number,
    showStepsIndicator: {
      type: Boolean,
      default: false,
    },
    modalClosingType: {
      type: String,
      validator: (prop) =>
        ['input', 'notification', 'confirmation', 'payment'].includes(prop),
    },
  },
  beforeUnmount() {
    document.querySelector('body').style.overflowY = 'visible';
  },
  computed: {
    modalTitle() {
      if (this.noTrans) return this.title;

      return this.$t(this.title);
    },
    modalSubtitle() {
      if (this.noTrans) return this.subtitle;

      return this.$t(this.subtitle);
    },
    canBeEscClosed() {
      return (
        ['notification', 'confirmation', 'payment'].includes(
          this.modalClosingType,
        ) || !this.modalClosingType
      );
    },
    canBeOutsideClickClosed() {
      return (
        ['notification', 'confirmation'].includes(this.modalClosingType) ||
        !this.modalClosingType
      );
    },
  },
  methods: {
    clickOutsideClose() {
      if (this.canBeOutsideClickClosed) this.closeModal();
    },
    escClose() {
      if (this.canBeEscClosed) this.closeModal();
    },
  },
  watch: {
    show: {
      handler(isShown) {
        const body = document.querySelector('body');
        if (isShown) {
          body.style.overflowY = 'hidden';

          return;
        }
        body.style.overflowY = 'visible';
      },
      immediate: true,
    },
  },
};
