<template>
  <div @click="$emit('send-amplitude')">
    <OrderRenewSection>
      <li class="h-nav__item cursor-pointer d-flex align-items-center">
        <span v-trans class="h-nav__link-text">
          {{ title }}
        </span>
      </li>
    </OrderRenewSection>
  </div>
</template>

<script>
import OrderRenewSection from '../../views/Hosting/HostingSectionElements/OrderRenewSection';

export default {
  name: 'HMenuRenew',
  emits: ['send-amplitude'],
  props: {
    link: String,
    title: String,
  },
  components: {
    OrderRenewSection,
  },
};
</script>

<style lang="scss" scoped>
.h-nav__item {
  padding: 8px 0;
  border-radius: 0px 32px 32px 0px;
  color: var(--gray);
  cursor: pointer;

  &:hover {
    background-color: rgba(218, 220, 224, 0.24);
  }

  .h-nav__link-text {
    padding-left: 80px;
    padding-right: 12px;
  }
}
</style>
