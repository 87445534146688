<template>
  <div class="d-flex flex-wrap justify-content-center align-items-center">
    <HpIcon class="h-mr-8" icon="icon-neutral" width="16" danger />
    <Trans>v2.domain.status</Trans>
    :
    <Trans
      tag="span"
      class="ml-1"
      :translate-params="{ domain: toUnicode($route.params.domain) }"
    >
      v2.domain.is.not.paid
    </Trans>
    <a
      v-if="!!domainInvoiceLink"
      v-trans
      :href="domainInvoiceLink"
      target="_blank"
      class="ml-2 text-danger text-underline"
    >
      v2.pay.now
    </a>
  </div>
</template>

<script>
//JSON-slugs-migrated
import { mapGetters } from 'vuex';

import { toUnicode } from '@/utils/helpers';

export default {
  name: 'DomainNotPaidBanner',
  computed: {
    ...mapGetters(['domainInvoiceLink']),
  },
  methods: { toUnicode },
};
</script>
