<script lang="ts" setup>
import { computed } from 'vue';

import { getSplittedDomainParts, toUnicode } from '@/utils/helpers';

type Props = {
  tag?: 'h4' | 'h3' | 'h2' | 'h1' | 'span' | 'div' | 'td' | 'p';
  domain: string;
  isStrictDomain?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  tag: 'h3',
});

const displayDomain = computed(() => toUnicode(props.domain) || '');

const displayDomainParts = computed(() =>
  getSplittedDomainParts(displayDomain.value),
);
</script>

<template>
  <Component :is="tag" translate="no" class="text-break-word">
    <template v-if="isStrictDomain">
      <span>{{ displayDomainParts[0] }}</span>
      <span class="h-text-break-keep-all">
        {{ displayDomainParts[1] }}
      </span>
    </template>
    <template v-else>
      {{ displayDomain }}
    </template>
    <slot />
  </Component>
</template>
