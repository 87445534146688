import { amplitudeV2 } from '@/plugins/amplitudeV2';
import store from '@/store';
import type { HToastrInput } from '@/types';
import { HToastrMutations } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
import {
  NETWORK_ERROR_AMPLITUDE_SAMPLE_RATE,
  shouldIgnoreBasedOnSampleRate,
} from '@/utils/services/sampleRateService';

const loggedErrors: string[] = [];
export const hToastrService = {
  n: (title: string, payload?: HToastrInput) =>
    // n is for neutral - when no status icon is needed
    store.commit(HToastrMutations.ADD_HTOASTR_MESSAGE, { title, payload }),
  s: (title: string, payload?: HToastrInput) =>
    store.commit(HToastrMutations.ADD_HTOASTR_MESSAGE, {
      title,
      payload,
      status: 'success',
    }),
  e: (title: string, payload?: HToastrInput) => {
    const { id, customId, message, text, optionalData } = payload || {};

    const toasterIdentifier = title + id;

    if (
      !loggedErrors.includes(toasterIdentifier) &&
      !shouldIgnoreBasedOnSampleRate(NETWORK_ERROR_AMPLITUDE_SAMPLE_RATE)
    ) {
      amplitudeV2('error.toaster.shown', {
        title,
        id,
        customId,
        message,
        text,
        pathname: window.location.pathname,
        query: window.location.search,
        reason: optionalData?.reason,
        lastErrorData: errorLogger.getLastErrorData(),
      });
      loggedErrors.push(toasterIdentifier);
    }

    store.commit(HToastrMutations.ADD_HTOASTR_MESSAGE, {
      title,
      payload,
      status: 'error',
    });
  },
  i: (title: string, payload?: HToastrInput) =>
    store.commit(HToastrMutations.ADD_HTOASTR_MESSAGE, {
      title,
      payload,
      status: 'info',
    }),
  w: (title: string, payload?: HToastrInput) =>
    store.commit(HToastrMutations.ADD_HTOASTR_MESSAGE, {
      title,
      payload,
      status: 'warning',
    }),
  isCurrentlyDisplayed: (customId: string) => {
    const messages = store.state.hToastr.hToastrMessages;
    const filteredMessages = messages.filter((message: any) =>
      message.payload?.customId?.includes(customId),
    );

    return !!filteredMessages.length;
  },
};
