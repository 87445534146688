import { Client, Currency, type IResellerCurrencyInfo } from '@/types';

export const defaultCurrenciesData = {
  [Currency.Code.RUB]: {
    code: Currency.Code.RUB,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price}  руб.',
  },
  [Currency.Code.UAH]: {
    code: Currency.Code.UAH,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} грн.',
  },
  [Currency.Code.IDR]: {
    code: Currency.Code.IDR,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: '.',
    format: 'Rp{price}',
  },
  [Currency.Code.ARS]: {
    code: Currency.Code.ARS,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price} AR$',
  },
  [Currency.Code.MXN]: {
    code: Currency.Code.MXN,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price} MX$',
  },
  [Currency.Code.COP]: {
    code: Currency.Code.COP,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price} CO$',
  },
  [Currency.Code.BRL]: {
    code: Currency.Code.BRL,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: 'R${price}',
  },
  [Currency.Code.PLN]: {
    code: Currency.Code.PLN,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} zł',
  },
  [Currency.Code.PHP]: {
    code: Currency.Code.PHP,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'PHP {price}',
  },
  [Currency.Code.MYR]: {
    code: Currency.Code.MYR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'RM {price}',
  },
  [Currency.Code.KRW]: {
    code: Currency.Code.KRW,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price}원',
  },
  [Currency.Code.VND]: {
    code: Currency.Code.VND,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: '.',
    format: '{price} VNĐ',
  },
  [Currency.Code.THB]: {
    code: Currency.Code.THB,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price}฿',
  },
  [Currency.Code.TRY]: {
    code: Currency.Code.TRY,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} TL',
  },
  [Currency.Code.INR]: {
    code: Currency.Code.INR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '₹ {price}',
  },
  [Currency.Code.JPY]: {
    code: Currency.Code.JPY,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price} 円',
  },
  [Currency.Code.GBP]: {
    code: Currency.Code.GBP,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '£{price}',
  },
  [Currency.Code.CZK]: {
    code: Currency.Code.CZK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} Kč',
  },
  [Currency.Code.HUF]: {
    code: Currency.Code.HUF,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} Ft',
  },
  [Currency.Code.SEK]: {
    code: Currency.Code.SEK,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '{price} kr',
  },
  [Currency.Code.DKK]: {
    code: Currency.Code.DKK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'DKK. {price}',
  },
  [Currency.Code.NOK]: {
    code: Currency.Code.NOK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'Kr {price}',
  },
  [Currency.Code.CNY]: {
    code: Currency.Code.CNY,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price}元',
  },
  [Currency.Code.ILS]: {
    code: Currency.Code.ILS,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: 'ש"ח {price}',
  },
  [Currency.Code.PKR]: {
    code: Currency.Code.PKR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'Rs. {price}',
  },
  [Currency.Code.USD]: {
    code: Currency.Code.USD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '${price}',
  },
  [Currency.Code.EUR]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Currency.Code.CAD]: {
    code: Currency.Code.CAD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'CA${price}',
  },
  [Currency.Code.AUD]: {
    code: Currency.Code.AUD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'A${price}',
  },
  [Currency.Code.CHF]: {
    code: Currency.Code.CHF,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'CHF {price}',
  },
  [Currency.Code.XOF]: {
    code: Currency.Code.XOF,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: 'F CFA {price}',
  },
  [Currency.Code.MAD]: {
    code: Currency.Code.MAD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'MAD {price}',
  },
  [Currency.Code.TND]: {
    code: Currency.Code.TND,
    decimalCount: 3,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'TND {price}',
  },
  [Currency.Code.DZD]: {
    code: Currency.Code.DZD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'DZD {price}',
  },
  [Currency.Code.CLP]: {
    code: Currency.Code.CLP,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: 'CLP {price}',
  },
  [Currency.Code.PEN]: {
    code: Currency.Code.PEN,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'PEN {price}',
  },
} as Record<Currency.Code, Readonly<IResellerCurrencyInfo>>;

export const resellerCurrencyData = {
  [Client.BrandId.Hostinger.RU]: defaultCurrenciesData[Currency.Code.RUB],
  [Client.BrandId.Hostinger.LT]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.COM_UA]: defaultCurrenciesData[Currency.Code.UAH],
  [Client.BrandId.Hostinger.CO_ID]: defaultCurrenciesData[Currency.Code.IDR],
  [Client.BrandId.Hostinger.COM]: defaultCurrenciesData[Currency.Code.USD],
  [Client.BrandId.Hostinger.ES]: defaultCurrenciesData[Currency.Code.EUR],
  [Client.BrandId.Hostinger.COM_AR]: defaultCurrenciesData[Currency.Code.ARS],
  [Client.BrandId.Hostinger.MX]: defaultCurrenciesData[Currency.Code.MXN],
  [Client.BrandId.Hostinger.CO]: defaultCurrenciesData[Currency.Code.COP],
  [Client.BrandId.Hostinger.COM_BR]: defaultCurrenciesData[Currency.Code.BRL],
  [Client.BrandId.Hostinger.RO]: defaultCurrenciesData[Currency.Code.EUR],
  [Client.BrandId.Hostinger.FR]: defaultCurrenciesData[Currency.Code.EUR],
  [Client.BrandId.Hostinger.IT]: defaultCurrenciesData[Currency.Code.EUR],
  [Client.BrandId.Hostinger.PL]: defaultCurrenciesData[Currency.Code.PLN],
  [Client.BrandId.Hostinger.PH]: defaultCurrenciesData[Currency.Code.PHP],
  [Client.BrandId.Hostinger.AE]: {
    ...defaultCurrenciesData[Currency.Code.USD],
    thousandsSep: ',',
  },
  [Client.BrandId.Hostinger.MY]: defaultCurrenciesData[Currency.Code.MYR],
  [Client.BrandId.Hostinger.KR]: defaultCurrenciesData[Currency.Code.KRW],
  [Client.BrandId.Hostinger.VN]: defaultCurrenciesData[Currency.Code.VND],
  [Client.BrandId.Hostinger.IN_TH]: defaultCurrenciesData[Currency.Code.THB],
  [Client.BrandId.Hostinger.COM_TR]: defaultCurrenciesData[Currency.Code.TRY],
  [Client.BrandId.Hostinger.PT]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.DE]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.IN]: defaultCurrenciesData[Currency.Code.INR],
  [Client.BrandId.Hostinger.JP]: defaultCurrenciesData[Currency.Code.JPY],
  [Client.BrandId.Hostinger.NL]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.CO_UK]: defaultCurrenciesData[Currency.Code.GBP],
  [Client.BrandId.Hostinger.GR]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    decimalPoint: '.',
    thousandsSep: '',
  },
  [Client.BrandId.Hostinger.CZ]: defaultCurrenciesData[Currency.Code.CZK],
  [Client.BrandId.Hostinger.HU]: defaultCurrenciesData[Currency.Code.HUF],
  [Client.BrandId.Hostinger.SE]: defaultCurrenciesData[Currency.Code.SEK],
  [Client.BrandId.Hostinger.DK]: defaultCurrenciesData[Currency.Code.DKK],
  [Client.BrandId.Hostinger.FI]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.SK]: defaultCurrenciesData[Currency.Code.EUR],
  [Client.BrandId.Hostinger.NO]: defaultCurrenciesData[Currency.Code.NOK],
  [Client.BrandId.Hostinger.HR]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.COM_HK]: defaultCurrenciesData[Currency.Code.CNY],
  [Client.BrandId.Hostinger.CO_IL]: defaultCurrenciesData[Currency.Code.ILS],
  [Client.BrandId.Hostinger.LV]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.EE]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    decimalPoint: '.',
    thousandsSep: '',
    format: '{price}€',
  },
  [Client.BrandId.Other.WEBLINK]: defaultCurrenciesData[Currency.Code.BRL],
  [Client.BrandId.Other.HOSTMANIA]: {
    ...defaultCurrenciesData[Currency.Code.EUR],
    decimalPoint: '.',
    thousandsSep: '',
    format: '€{price}',
  },
  [Client.BrandId.Other.HOSTING24]: defaultCurrenciesData[Currency.Code.USD],
  [Client.BrandId.Other.NG_CO_ID]: defaultCurrenciesData[Currency.Code.IDR],
  [Client.BrandId.Hostinger.PK]: defaultCurrenciesData[Currency.Code.PKR],
};
