export namespace Experiment {
  export enum ID {
    BEAVER_BUILDER_VS_GUTENBERG = 'wordpress-beaver-builder-vs-gutenberg',
    WORDPRESS_PREVIEW_DOMAIN = 'wordpress-preview-domain',
    HOMEPAGE_MANAGED_ACCOUNTS = 'homepage-managed-accounts-v2',
    RENEW_UPGRADE_MODAL = 'hpanel-renew-vs-upgrade-v3',
    WEBPRO_WP_PRESETS = 'webpro-wp-presets',
    REFUND_BUTTON_SUBSCRIPTIONS = 'hpanel-refunds-flow',
    HPANEL_ADD_WEBSITE_FLOW_V2 = 'hpanel-add-website-flow-v2',
    HPANEL_REFERRALS_REDIRECT_TO_HOMEPAGE = 'hpanel-referrals-redirect-homepage',
    HPANEL_PLATFORM_SELECTION_TEST_V2 = 'hpanel-platform-selection-test-v2',
    EMAILS_HMAIL_UPSELL_TEST = 'emails-hmail-upsell-page-a-b',
    ONBOARDING_WEBPRO_SHORT = 'onboarding-webpro-short',
    WEBPRO_REFERRALS = 'webpro-referrals',
    BUILDER_GAMIFICATION_DOMAIN_STEP = 'website-builder-add-domain-connection-step-to-gamification',
    WEBPRO_WORDPRESS_PRESETS_OFFER = 'webpro-wordpress-presets-offer',
    BE_SLOWDOWN_HANDLER = 'be-slowdown-handler',
    WEBSITE_BUILDER_UPGRADE_OFFER = 'website-builder-upgrade-offer',
    WORDPRESS_REDIRECT_TO_WP_ADMIN = 'wordpress-redirected-to-wp-admin-after-onboarding',
    FRIDAY_SUSPENDED_GUARD_RELEASE = 'friday-suspended-guard-release',
    DOMAINS_ADD_DOMAIN_BUTTON = 'domains-add-domain-button',
    REFERRALS_LAST_REFERRED_PRODUCT = 'webpro-different-referrals-plan-selection',
    EMAILS_DURATION_CHOICE_IN_EMAIL_PRICING_PAGE = 'emails-duration-choice-in-email-pricing-page-v3',
    HPANEL_HOME_PAGE_NUDGES = 'hpanel-home-page-nudges-v2',
    ACCOUNT_SHARING_NAVIGATION = 'account-sharing-navigation-test',
    HPANEL_AI_SOCIAL_TOOL_PROMO_BANNER = 'hpanel-ai-social-tool-promo-banner',
    HOSTING_MIGRATION_FLOW_SIMPLIFIED = 'hosting-migration-flow-simplified',
    FAKE_DOOR_ACCESS_PER_WEBSITE = 'fake-door-access-per-website',
    HOSTING_DOMAIN_TRANSFER = 'hosting-domain-transfer',
  }

  export enum Variant {
    CURRENT_VERSION = 0,
    EXPERIMENT_VERSION_1 = 1,
    EXPERIMENT_VERSION_2 = 2,
  }
}
