import type { HColor } from '@/types';

export const H_LABEL_THEME = {
  WARNING_DARK: 'warningDark',
  SUCCESS_LIGHT: 'successLight',
  DANGER: 'danger',
  DANGER_LIGHT: 'dangerLight',
  PRIMARY: 'primary',
  GRAY: 'gray',
  GRAY_LIGHT: 'grayLight',
  WHITE: 'white',
  METEORITE: 'meteorite',
} as const;

export const H_LABEL_THEME_CONFIGURATION: IHLabelV2Configuration = {
  warningDark: {
    backgroundColor: 'warning-light',
    color: 'warning-dark-2',
  },
  successLight: {
    backgroundColor: 'success-light',
    color: 'success',
  },
  danger: {
    backgroundColor: 'danger',
    color: 'white',
  },
  dangerLight: {
    backgroundColor: 'danger-light',
    color: 'danger-dark',
  },
  primary: {
    backgroundColor: 'primary-light',
    color: 'primary',
  },
  gray: {
    backgroundColor: 'gray',
    color: 'white',
  },
  grayLight: {
    backgroundColor: 'gray-light',
    color: 'gray',
  },
  white: {
    backgroundColor: 'white',
    color: 'dark',
  },
  meteorite: {
    backgroundColor: 'meteorite',
    color: 'white',
  },
} as const;

export interface IHLabelV2Styling {
  backgroundColor: HColor;
  color: HColor;
}
export type HLabelTheme = (typeof H_LABEL_THEME)[keyof typeof H_LABEL_THEME];

export type IHLabelV2Configuration = Record<HLabelTheme, IHLabelV2Styling>;
