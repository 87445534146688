<template>
  <div class="wrapper">
    <div class="column column__left">
      <HpIcon
        icon="icon-graph"
        primary
        class="h-mr-8 icon"
        width="36"
        height="36"
      />
      <div class="column-title column-title__left">
        <span v-trans class="list-item">Most visitors came from:</span>
      </div>
      <div class="unordered-list">
        <p class="list-item">
          <strong>
            <span v-trans>{{ countryFrom }}</span>
            {{ visitorPercentage }}%
          </strong>
        </p>
      </div>
    </div>
    <div class="column column__right">
      <HpIcon
        icon="icon-server-light"
        primary
        class="h-mr-8 icon"
        width="36"
        height="36"
      />
      <div class="column-title column-title__from">
        <span v-trans class="list-item">Move from:</span>
      </div>
      <p class="list-item list-item__from">
        <strong>
          <span v-trans>
            {{ serverLocation }}
          </span>
        </strong>
      </p>
      <HpIcon icon="icon-arrow-forward" class="h-mr-8 icon-to" />
      <div class="column-title column-title__to">
        <span v-trans class="list-item">To:</span>
      </div>
      <p class="list-item list-item__to">
        <strong>
          <span v-trans>
            {{ countryTo }}
          </span>
        </strong>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { countryByCountryCode } from '@/utils/helpers';

export default {
  props: {
    traffic: Array,
  },
  computed: {
    countryTo() {
      return countryByCountryCode(this.traffic[0]?.nearestServer);
    },
    countryFrom() {
      return countryByCountryCode(this.traffic[0]?.country);
    },
    visitorPercentage() {
      return this.traffic[0]?.nearestServerVisitorsPercent;
    },
    serverLocation() {
      const details = this.getWebsiteDetails?.['Server name'];
      const detailsParts = details ? details.split(',').slice(1) : [];

      return detailsParts.join('');
    },
    ...mapGetters(['getWebsiteDetails']),
  },
  methods: {
    countryByCountryCode,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 16px;
  margin-bottom: 16px;
  @media only screen and (max-width: 576px) {
    width: 100%;
    flex-direction: column;
  }
}
.column {
  height: 88px;
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  padding: 16px;
  @media only screen and (max-width: 576px) {
    width: 100%;
    padding-bottom: 16px;
  }
  &__right {
    display: grid;
    height: fit-content;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    grid-template-rows:
      1fr
      1fr;

    .icon {
      grid-row: 1/2;
      grid-column: 1;
      justify-self: center;
      align-self: center;
      height: 16px !important;
    }

    @media only screen and (max-width: 576px) {
      border-top: 1px solid var(--gray-border);
      border-left: 0px;
      padding-left: 0px;
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }
  &__left {
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 1fr 1fr;
    @media only screen and (max-width: 576px) {
      border-bottom: 1px solid var(--gray-border);
    }
  }
}
.icon {
  grid-row: 1/3;
  grid-column: 1;
  justify-self: center;
  align-self: center;
  width: 36px;
  height: 36px;
}

.icon-to {
  grid-row: 1/3;
  grid-column: 3;
  justify-self: left;
  align-self: center;
}
.column-title {
  color: var(--primaryText);
  &__from {
    grid-column: 2;
    grid-row: 1;
  }
  &__to {
    grid-column: 4;
    grid-row: 1;
  }
  &__left {
    grid-column: 2;
    grid-row: 1;
  }
}

.list-item {
  color: var(--primaryText);
  &__right {
    padding-left: 2px;
  }
  &__from {
    grid-column: 2;
    grid-row: 2;
  }
  &__to {
    grid-column: 4;
    grid-row: 2;
  }
}
</style>
