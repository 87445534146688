import { defineStore } from 'pinia';
import { ref } from 'vue';

import { SelectedDomainsList } from '@/types';

export const useDomainsTableStore = defineStore(
  'domainsTableStore',
  () => {
    const selectedDomainsList = ref<SelectedDomainsList>(
      SelectedDomainsList.ALL_DOMAINS,
    );

    return { selectedDomainsList };
  },
  {
    persist: { key: 'domains-table-store' },
  },
);
