import type {
  GetPageSpeedLogsParams,
  PostPageSpeedParams,
  RequestConfig,
  WebsitePageSpeed,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/page-speed`,

  async postSpeedTest(data: PostPageSpeedParams, config: RequestConfig) {
    return await hAsync<WebsitePageSpeed>(
      http.post(this.url, data, {
        ...(config || {}),
        whitelistedStatusCodes: [400],
      }),
    );
  },
  async getSpeedTestLogs(data: GetPageSpeedLogsParams) {
    return await hAsync<WebsitePageSpeed[]>(
      http.get(`${this.url}/list`, {
        params: {
          latest: data?.latest,
          domain: data?.config?.domain,
        },
        ...(data?.config || {}),
      }),
    );
  },
};
