import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import type { NavigationGuard } from 'vue-router';

import {
  useBrands,
  useSaleCampaign,
  useCyberWeekSaleCampaign,
  useNewYearSaleCampaign,
} from '@/composables';
import { NON_UPGRADABLE_HOSTING_PLANS } from '@/data/globalConstants';
import { SALES_CONFIGURATION } from '@/data/sale/saleConfiguration';
import store from '@/store';
import { useProfileStore } from '@/stores';
import type { SaleConfiguration } from '@/types';
import { HBilling, Route, Sale } from '@/types';

export const commonSaleGuard =
  (sale: Sale): NavigationGuard =>
  async (to, from, next) => {
    const saleConfigurationData = getSaleConfiguration(sale);
    if (!saleConfigurationData) return next({ name: Route.Base.HOME });

    const isCookieActive = isSaleActiveByCookie(saleConfigurationData);

    // Sale has not started yet
    if (!isCookieActive && dayjs().isBefore(saleConfigurationData.startsAt)) {
      return next({ name: Route.Base.HOME });
    }

    if (
      !isCookieActive &&
      isSaleEnded(saleConfigurationData.endsAt) &&
      isBrandValid(saleConfigurationData.activeResellers)
    ) {
      return next({
        name: Sale.DEFAULT_POST_SALE,
      });
    }

    const activeSubscriptions = getActiveSubscriptions();

    if (!activeSubscriptions.length) {
      await updateSubscriptions();
    }
    const hostingOrders = getHostingOrders(activeSubscriptions);

    if (
      !isBrandValid(saleConfigurationData.activeResellers) ||
      !hostingOrders.length
    ) {
      return next({ name: Route.Base.HOME });
    }

    next();
  };

export const monsoonSaleGuard = commonSaleGuard(Sale.MONSOON_SALE);
export const independenceDayGuard = commonSaleGuard(Sale.INDEPENDENCE_DAY);
export const septemberSaleGuard = commonSaleGuard(Sale.SEPTEMBER_SALE);
export const summerSaleGuard = commonSaleGuard(Sale.SUMMER_SALE);
export const blackFridaySaleGuard: NavigationGuard = async (to, from, next) => {
  const { isFromAnotherBrand } = useBrands();
  const { isBlackFridayPurchaseAvailable, isUpgradeHostingSaleAvailable } =
    useSaleCampaign();
  const { isCyberWeekSaleAvailable } = useCyberWeekSaleCampaign();

  // Another brand
  if (isFromAnotherBrand.value) {
    return next({ name: Route.Base.HOME });
  }

  // Domain only
  if (isBlackFridayPurchaseAvailable.value) {
    return next({ name: Route.Base.BUY_HOSTING });
  }

  // Non-upgradable hostings
  if (!isUpgradeHostingSaleAvailable.value) {
    return next({ name: Route.Base.HOME });
  }

  if (isCyberWeekSaleAvailable.value) {
    return next({ name: Route.Sale.CYBER_WEEK });
  }

  await commonSaleGuard(Sale.BLACK_FRIDAY)(to, from, next);
};

export const cyberWeekSaleGuard: NavigationGuard = async (to, from, next) => {
  const { isFromAnotherBrand } = useBrands();
  const { isBlackFridayPurchaseAvailable, isUpgradeHostingSaleAvailable } =
    useSaleCampaign();

  if (isFromAnotherBrand.value) {
    return next({ name: Route.Base.HOME });
  }

  // Domain only
  if (isBlackFridayPurchaseAvailable.value) {
    return next({ name: Route.Base.BUY_HOSTING });
  }

  // Non-upgradable hostings
  if (!isUpgradeHostingSaleAvailable.value) {
    return next({ name: Route.Base.HOME });
  }

  await commonSaleGuard(Sale.CYBER_WEEK)(to, from, next);
};

export const newYearSaleGuard: NavigationGuard = async (to, from, next) => {
  const { isFromAnotherBrand } = useBrands();
  const { isEligibleForHostingPurchaseOffer, isEligibleForSaleOffer } =
    useNewYearSaleCampaign();

  if (isFromAnotherBrand.value) {
    return next({ name: Route.Base.HOME });
  }

  // Domain only
  if (isEligibleForHostingPurchaseOffer.value) {
    return next({ name: Route.Base.BUY_HOSTING });
  }

  // Non-upgradable hostings
  if (!isEligibleForSaleOffer.value) {
    return next({ name: Route.Base.HOME });
  }

  await commonSaleGuard(Sale.NEW_YEAR)(to, from, next);
};

const getSaleConfiguration = (saleName: string) =>
  SALES_CONFIGURATION.find(({ name }) => name === saleName);

const isSaleEnded = (endsAt: string): boolean => dayjs().isAfter(dayjs(endsAt));

const getActiveSubscriptions = () =>
  store.getters['subscriptions/activeSubscriptions'];

const updateSubscriptions = async () =>
  await store.dispatch(
    'subscriptions/billingGetSubscriptionsWithScheduledChanges',
  );

const getHostingOrders = (subscriptions: any[]): any[] =>
  subscriptions.filter(
    ({ resourceType, status, cfSubscriptionParams }) =>
      resourceType === 'HOSTING' &&
      [
        HBilling.SubscriptionStatus.ACTIVE,
        HBilling.SubscriptionStatus.NON_RENEWING,
      ].includes(status) &&
      !NON_UPGRADABLE_HOSTING_PLANS.includes(cfSubscriptionParams.plan),
  );

const isBrandValid = (activeResellers?: string[]): boolean => {
  const profileStore = useProfileStore();

  if (!activeResellers?.length) {
    return true;
  }

  if (!profileStore.account?.brand) {
    return false;
  }

  return activeResellers.includes(profileStore.account?.brand.id);
};

const isSaleActiveByCookie = (config: SaleConfiguration) => {
  const cookieName = config.activationCookie;

  return !!cookieName && !!Cookies.get(cookieName);
};
