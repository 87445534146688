import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import vpsActionsMessages from '@/data/hVps/vpsActionsMessages';
import { useVpsServerStore } from '@/stores';
import type {
  IPusherServerNotification,
  IServerNotification,
  ServerNotifications,
} from '@/types';
import { HVps } from '@/types';

export const useVpsNotificationStore = defineStore('vpsNotification', () => {
  const serverStore = useVpsServerStore();

  const serverNotifications = ref<ServerNotifications>({});

  const getErrorMessage = (notification: IPusherServerNotification) => {
    let message =
      notification.state === HVps.ServerState.ERROR
        ? 'Error appeared'
        : 'Changes were successful';

    const actionMessage = vpsActionsMessages[notification.name];
    if (actionMessage) {
      message =
        notification.state === HVps.ServerState.ERROR
          ? actionMessage.error
          : actionMessage.success;
    }

    return message;
  };

  const addServerNotification = (notification: IPusherServerNotification) => {
    const serverId = notification.serverId;

    const message = getErrorMessage(notification);

    const formattedNotification: IServerNotification = {
      id: JSON.stringify({
        id: notification.id,
        serverId,
      }),
      status: notification.state,
      title: message,
    };

    serverNotifications.value[serverId] = [
      ...(serverNotifications.value[serverId] || []),
      formattedNotification,
    ];

    setTimeout(() => {
      serverNotifications.value[serverId] = serverNotifications.value[
        serverId
      ]?.filter(({ id }: { id: string }) => id !== formattedNotification.id);
    }, 4000);

    serverNotifications.value = { ...serverNotifications.value };
  };

  const removeServerNotification = (id: string) => {
    const { serverId } = JSON.parse(id);

    serverNotifications.value[serverId] = serverNotifications.value[
      serverId
    ]?.filter((notification: IServerNotification) => notification.id !== id);
  };

  const clearServerNotifications = () => {
    serverNotifications.value = {};
  };

  const currentServerNotifications = computed(
    () => serverNotifications.value[serverStore.currentServer.id] || [],
  );

  const getServerNotifications: () => IServerNotification[] = () =>
    Object.values(serverNotifications.value).flatMap(
      (notification) => notification,
    );

  return {
    addServerNotification,
    removeServerNotification,
    clearServerNotifications,
    currentServerNotifications,
    getServerNotifications,
  };
});
