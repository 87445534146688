import type {
  ShoppingCartBody,
  AccountDetails,
  TransferRecommendation,
  WebsiteDetails,
  WebsiteTip,
  InodeDiagnostics,
  OnboardingStatusParams,
  OnboardingStatusResponse,
  RequestConfig,
  SetupOnboardingHostingRestReqParams,
  OnboardingCompletionData,
} from '@/types';
import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_REST}/v3/account`;

export default {
  async postProSpaceWelcomeEmail() {
    return await hAsync<boolean>(http.post(`${URL}/pro-dashboard-welcome`));
  },
  async getAccountDetails(params?: {
    domain?: string;
    freshQuotas?: boolean;
    username?: string;
    orderId?: string;
  }) {
    return await hAsync<AccountDetails>(
      http.get(URL, {
        params,
        headers: {
          [Header.USERNAME]: params?.username,
          [Header.ORDER_ID]: params?.orderId,
        },
      }),
    );
  },

  async getTransferRecommendation(params?: { domain?: string }) {
    return await hAsync<TransferRecommendation>(
      http.get(`${URL}/transfer-recommendation`, {
        domain: params?.domain,
      }),
    );
  },

  async boostPlan({ domain }: { domain?: string }) {
    return await hAsync<boolean>(
      http.post(`${URL}/boost`, null, {
        domain,
      }),
    );
  },

  async boostStorage() {
    return await hAsync<boolean>(http.post(`${URL}/boost-storage`));
  },

  async inodeDiagnostic() {
    return await hAsync<InodeDiagnostics[]>(http.get(`${URL}/inode-diag`));
  },

  async websiteDetails(params?: {
    username: string;
    orderId: string;
    domain: string;
  }) {
    return await hAsync<WebsiteDetails>(
      http.get(`${URL}/website-details`, {
        hostingHeaderRequired: !params,
        headers: {
          [Header.USERNAME]: params?.username,
          [Header.ORDER_ID]: params?.orderId,
          [Header.DOMAIN]: params?.domain,
        },
      }),
    );
  },

  async websiteTips() {
    return await hAsync<WebsiteTip>(http.get(`${URL}/website-tips`));
  },

  async notifyForProDashboard() {
    return await hAsync<boolean>(http.post(`${URL}/pro-dashboard-notify`));
  },
  async sendShoppingCart(params: ShoppingCartBody) {
    return await hAsync<boolean>(
      http.post(`${URL}/send-shopping-cart`, params),
    );
  },
  async getOnboardingStatus(
    params: OnboardingStatusParams,
    options: Omit<RequestConfig, 'params'> = {},
  ) {
    return await hAsync<OnboardingStatusResponse>(
      http.get(`${URL}/onboarding/status`, {
        params,
        ...options,
      }),
    );
  },

  async getOnboardingCompletionStatus(
    params: OnboardingStatusParams,
    options: Omit<RequestConfig, 'params'> = {},
  ) {
    return await hAsync<OnboardingCompletionData>(
      http.get(`${URL}/onboarding/completed`, {
        params,
        ...options,
      }),
    );
  },
  async setupOnboarding(
    params: SetupOnboardingHostingRestReqParams,
    orderId: string,
  ) {
    return await hAsync<boolean>(
      http.post(`${URL}/onboarding`, params, {
        headers: {
          [Header.ORDER_ID]: orderId,
        },
        noHostingHeaders: true,
      }),
    );
  },

  importWebsite(file: File, config: RequestConfig) {
    return hAsync<{ storageUrl: string }>(
      http.post(`${URL}/onboarding/import-website`, file, config),
    );
  },
};
