import { isArray } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type {
  IByteFormat,
  IServerUsage,
  ServerUsage,
  ServerUsageData,
} from '@/types';
import { bytesFormat, readableToSnake } from '@/utils/helpers';
import { snakeToCamel } from '@/utils/services/namingConventionsService';

export const useVpsUsageStore = defineStore('vpsUsage', () => {
  const serverStore = useVpsServerStore();

  const serverUsage = ref<ServerUsage>({});

  const currentServerUsage = computed(() => {
    const serverId = serverStore.managedId;
    if (!serverId) return {};

    return serverUsage.value[serverId];
  });

  const setServerUsage = (serverId: number, usageData: IServerUsage) => {
    const formattedUsage: ServerUsageData = {};

    Object.entries(usageData).forEach(([key, value]) => {
      let unit = value.unit;
      let usageObject = value.usage || {};
      const originalUsage = value.usage || {};

      if (unit === 'bytes' && typeof originalUsage === 'object') {
        usageObject = {};
        const maxUsageValue = Math.max(
          ...Object.values(originalUsage).map((value) => value as number),
        );

        const maxUsage = bytesFormat(maxUsageValue, 0, true) as IByteFormat;
        unit = maxUsage.unit;

        Object.keys(originalUsage).forEach((usageKey) => {
          const format = bytesFormat(
            originalUsage[usageKey],
            unit,
            true,
            false,
          ) as IByteFormat;
          usageObject[usageKey] = parseFloat(String(format.value));
        });
      }

      if (isArray(Object.values(originalUsage)[0])) {
        Object.keys(originalUsage).forEach((usageKey) => {
          originalUsage[usageKey] = originalUsage[usageKey][0];
        });
      }

      const formattedKey = readableToSnake(key);
      formattedUsage[snakeToCamel(formattedKey)] = {
        unit,
        originalUsage,
        usage: usageObject,
      };
    });

    serverUsage.value[serverId] = formattedUsage;
  };

  const fetchServerUsage = async ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo: string;
  }) => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    const [{ data }, error] = await hVpsRepo.getMetrics(serverId, {
      dateFrom,
      dateTo,
    });

    if (!error) {
      setServerUsage(serverId, data[0]);
    }
  };

  return {
    serverUsage,
    currentServerUsage,
    setServerUsage,
    fetchServerUsage,
  };
});
