<template>
  <div>
    <Trans tag="p" class="h-px-40">
      The detection process will run automatically.
      <strong>Refresh the dashboard</strong>
      in a few minutes to see the updates.
    </Trans>
    <div class="text-right h-m-24">
      <HButtonV1 no-margin primary @click="close">Close</HButtonV1>
    </div>
  </div>
</template>

<script>
import modalsMixin from '../../../utils/mixins/modalsMixin';

export default {
  mixins: [modalsMixin],
};
</script>
