import type { NavigationGuard } from 'vue-router';

import { useProfileStore } from '@/stores/profile/profileStore';
import { Route } from '@/types';

export const wordpressPresetsGuard: NavigationGuard = async () => {
  const profileStore = useProfileStore();

  if (!profileStore.isProfileLoaded) {
    await profileStore.fetchProfile();
  }

  if (profileStore.isAccessManager) {
    return { name: Route.Base.HOME };
  }
};
