<script setup lang="ts">
import { useCpanelWordpress } from '@/composables';

const { openInstallationModal } = useCpanelWordpress();
</script>

<template>
  <HButtonV1
    primary
    centered
    class="cpanel-wp__button"
    @click="openInstallationModal"
  >
    <template #icon>
      <HpIcon class="icon-light" icon="icon-add" :width="24" :height="24" />
    </template>
    Install new WordPress
  </HButtonV1>
</template>
