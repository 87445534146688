<template>
  <div class="h-section">
    <span class="h-section__title">
      <slot name="sub" />
    </span>
    <span class="h-section__value">
      <slot name="heading" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'DetailListItem',
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/h-section';
</style>
