import type {
  IHostingAccount,
  IFtpDetails,
  IFtpAccount,
  IFtpAccountCreateRequest,
  IAccountChangePasswordRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
};

export default {
  url: ({ username, domain }: HostingWebsiteWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/ftp-accounts`,

  async getFtpDetails(
    accountIdentifier: HostingWebsiteWebsiteAccountIdentifier,
  ) {
    return await hAsync<IFtpDetails>(http.get(this.url(accountIdentifier)));
  },

  async createFtpAccount(
    accountIdentifier: HostingWebsiteWebsiteAccountIdentifier,
    requestData: IFtpAccountCreateRequest,
  ) {
    return await hAsync<IFtpAccount>(
      http.post(this.url(accountIdentifier), requestData, {
        plain: true,
        whitelistedStatusCodes: [422],
      }),
    );
  },

  ftpAccountUrl: ({ username }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/ftp-accounts`,

  async changeFtpAccountPassword(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    ftpUser: IFtpAccount['ftpUsername'],
    changePasswordRequest: IAccountChangePasswordRequest,
  ) {
    return await hAsync<IFtpAccount>(
      http.patch(
        `${this.ftpAccountUrl(accountIdentifier)}/${ftpUser}/change-password`,
        changePasswordRequest,
        { plain: true, whitelistedStatusCodes: [422] },
      ),
    );
  },

  async deleteFtpAccount(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    ftpUser: IFtpAccount['ftpUsername'],
  ) {
    return await hAsync<IFtpAccount>(
      http.delete(`${this.ftpAccountUrl(accountIdentifier)}/${ftpUser}`),
    );
  },
};
