<template>
  <div>
    <ErrorBoundaryComponent>
      <PurchasePaymentMethods
        v-if="
          !loading &&
          !paymentMethodsLoading &&
          !error &&
          showNewPaymentMethodSelect &&
          !hidePaymentMethod &&
          paymentMethods.length
        "
      />
    </ErrorBoundaryComponent>
    <ModalSkeletonLoader
      v-if="loading || paymentMethodsLoading"
      only-details
      :details-count="detailsCount"
    />
    <template v-else>
      <div class="purchase-details">
        <ul class="purchase-details__list">
          <template v-if="!error">
            <DefaultPaymentMethod
              v-if="!isDefaultPaymentHidden && !showNewPaymentMethodSelect"
              :is-payment-disabled="isPaymentDisabled"
              :loading="editLoading"
              :pricing-details="pricingDetails"
              @edit-payment="$emit('edit-payment')"
            />
            <template v-for="(detail, index) in Object.values(details)">
              <PurchaseDetail
                v-if="detail && detail.text !== 'Amount To Pay'"
                :key="index + taxesTooltip"
                :detail="detail"
              />
            </template>

            <slot name="coupon" />
            <PurchaseDetail :detail="details.amountToPay" />
          </template>
          <li v-else class="purchase-details__error">
            <StatusIcon status="error" class="h-mr-8" />
            <NoPurchaseAccessError
              v-if="!permissions.userHasPurchaseAccess()"
            />
            <p v-else v-trans>Error occured while getting final pricing.</p>
          </li>
          <Trans
            v-if="permissions.userHasPurchaseAccess()"
            tag="p"
            class="purchase-details__terms-of-service"
            :translate-params="{
              termsOfService: externalUrl.termsOfService,
              privacyPolicy: externalUrl.privacyPolicy,
            }"
          >
            {{ $options.TERMS_OF_SERVICE_TEXT }}
          </Trans>
        </ul>
        <slot name="summary" />
        <p v-if="showRenew && !loading" class="h-mb-16">
          {{ renewsAtTitle }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';

import ModalSkeletonLoader from '@/components/Loaders/SkeletonCompositions/ModalSkeletonLoader.vue';
import DefaultPaymentMethod from '@/components/Modals/Parts/DefaultPaymentMethod.vue';
import PurchaseDetail from '@/components/Modals/Parts/PurchaseDetail.vue';
import PurchasePaymentMethods from '@/components/Modals/Parts/PurchasePaymentMethods.vue';
import NoPurchaseAccessError from '@/components/NoPurchaseAccessError.vue';
import { useBrands, useGlobals } from '@/composables';
import { usePaymentMethod } from '@/composables/hBilling/usePaymentMethod';
import { toLocalTime } from '@/utils/helpers';
import { getTaxRateFromPricingDetails } from '@/utils/helpers/hBillingHelper';

const TERMS_OF_SERVICE_TEXT = `By checking out, you agree with our <a href="{termsOfService}" target="_blank" data-qa="purchase-details-terms-of-service"><strong>Terms of Service</strong> </a> and confirm that you have read our <a href="{privacyPolicy}" target="_blank" data-qa="purchase-details-privacy-policy"><strong>Privacy Policy</strong></a>. You can cancel recurring payments at any time.`;

export default {
  TERMS_OF_SERVICE_TEXT,
  components: {
    PurchaseDetail,
    DefaultPaymentMethod,
    ModalSkeletonLoader,
    NoPurchaseAccessError,
    PurchasePaymentMethods,
  },
  props: {
    hidePaymentMethod: {
      type: Boolean,
      default: false,
    },
    showNewPaymentMethodSelect: {
      type: Boolean,
      default: false,
    },
    pricingDetails: Object,
    loading: Boolean,
    editLoading: Boolean,
    error: Boolean,
    isDefaultPaymentHidden: Boolean,
    showRenew: Boolean,
    isRenew: Boolean,
    isRenewalFeeShown: Boolean,
    isYearly: Boolean,
    isDomainBundle: Boolean,
    isPaymentDisabled: Boolean,
    totalTooltipText: {
      type: String,
      default: '',
    },
    paymentType: String,
    hasCoupon: Boolean,
    creditsHint: String,
  },
  emits: ['edit-payment', 'on-coupon-change'],
  async mounted() {
    await this.$nextTick();
    this.setTaxesTooltip();
  },
  updated() {
    this.setTaxesTooltip();
  },
  data: () => ({
    taxesTooltip: '',
  }),
  setup() {
    const { externalUrl, isIndianBrand } = useBrands();
    const { permissions } = useGlobals();
    const { allPaymentMethods: paymentMethods } = usePaymentMethod();

    return {
      externalUrl,
      isIndianBrand,
      permissions,
      paymentMethods,
    };
  },
  computed: {
    renewsAtTitle() {
      if (this.isDomainBundle) {
        return this.$t(
          'Domains renew at {renewalPrice}/year on {renewalDate}',
          {
            renewalPrice: this.renewalPrice,
            renewalDate: this.renewalDate,
          },
        );
      }

      return this.$t('Domain renews at {renewalPrice}/year on {renewalDate}', {
        renewalPrice: this.renewalPrice,
        renewalDate: this.renewalDate,
      });
    },
    renewalPrice() {
      if (!this.pricingDetails || !this.pricingDetails.period) return;

      const { period, price } = this.pricingDetails.period;

      return this.$currency.format(price / period, {
        fixedCurrency: null,
        monthly: !this.isYearly,
        isChargebeePrice: true,
      });
    },
    renewalDate() {
      if (!this.pricingDetails || !this.pricingDetails.period) return;

      const { period, periodUnit } = this.pricingDetails.period;

      return toLocalTime(
        dayjs
          .utc()
          .endOf('day')
          .add(periodUnit === 'year' ? period * 12 : period, 'month'),
      );
    },
    renewalFee() {
      const renewalFee = this.pricingDetails?.items?.[0]?.total;

      if (!this.isRenewalFeeShown || !renewalFee) {
        return null;
      }

      return this.$currency.format(renewalFee, {
        fixedCurrency: null,
        monthly: !this.isYearly,
        isChargebeePrice: true,
      });
    },
    detailsCount() {
      let count = 1;
      const { creditsApplied, totalTax, nextBillingAt } = this.pricingDetails;

      if (this.hasCoupon) count++;
      if (this.defaultPaymentMethod) count++;
      if (totalTax) count++;
      if (creditsApplied) count += 2;
      if (nextBillingAt) count++;

      return count < 3 ? 3 : count;
    },
    expirationDate() {
      return this.pricingDetails?.dueTo ?? this.pricingDetails.nextBillingAt;
    },
    details() {
      const { creditsApplied, totalTax, total, finalAmount, amountPaid } =
        this.pricingDetails;
      const creditsAndAmountPaid = amountPaid + creditsApplied;

      return {
        renewalFee: this.renewalFee
          ? {
              text: 'Renewal fee',
              info: this.renewalFee,
              tooltip: '',
            }
          : null,
        taxes:
          totalTax || this.icannFee || this.icannItemFee
            ? {
                text: 'Taxes & Fees',
                info: this.getChargeBeePrice(this.taxesPrice),
                tooltip: this.taxesTooltip,
              }
            : null,
        expirationDate: this.expirationDateTooltipText
          ? {
              text: 'Expiration Date',
              info: toLocalTime(this.expirationDate),
              tooltip: {
                content: this.expirationDateTooltipText,
                classes: 'lg',
              },
            }
          : null,
        redemptionFee: this.redemptionPeriodFee && {
          text: 'Redemption fee',
          info: this.getChargeBeePrice(this.redemptionPeriodFee),
          tooltip: this.$t(
            'Once domain enters redemption period, additional fee is required in order to recover your domain',
          ),
        },
        setupFee: this.getSetupFee && {
          text: 'Setup Fee',
          info: this.getSetupFee,
          tooltip: this.$t(
            'Monthly plans come with a one-time fee. Subscribing to a longer-term plan will remove the setup fee, and save you more money in the long run.',
          ),
        },
        //Remaining time is converted accordingly to the price of the new plan, adding to it as extra hosting time
        total: {
          text: 'Total',
          info: this.getChargeBeePrice(total),
          tooltip: this.totalTooltipText,
        },
        credits:
          creditsAndAmountPaid > 0
            ? {
                text: 'Hostinger balance',
                info: `-${this.getChargeBeePrice(creditsAndAmountPaid)}`,
                tooltip: this.creditsHint ?? this.creditBalanceTooltip,
              }
            : null,
        planName: this.getPlanName
          ? {
              text: 'Plan name',
              info: this.getPlanName,
            }
          : null,
        amountToPay:
          creditsAndAmountPaid > 0
            ? {
                text: 'Amount To Pay',
                info: this.getChargeBeePrice(finalAmount),
                bold: true,
                largerFont: true,
              }
            : null,
      };
    },
    getSetupFee() {
      const { items } = this.pricingDetails;
      //emails quant exception
      if (items?.[0]?.quantity !== 1) {
        return false;
      }

      const addons = items?.[0]?.addons;
      const setupFee = addons?.find((addon) =>
        addon.product?.includes('setup'),
      )?.total;

      return setupFee && setupFee > 0
        ? this.getChargeBeePrice(setupFee)
        : false;
    },
    getPlanName() {
      return this.pricingDetails?.planName;
    },
    taxesPrice() {
      return (
        this.pricingDetails.totalTax + (this.icannFee || this.icannItemFee)
      );
    },
    taxCount() {
      return getTaxRateFromPricingDetails(this.pricingDetails) || 0;
    },
    taxName() {
      if (this.isIndianBrand) return 'GST';

      return '';
    },
    hasIcannFee() {
      return !!(this.icannFee || this.icannItemFee);
    },
    icannFee() {
      let amount = 0;

      this.pricingDetails?.items?.forEach(({ addons }) => {
        const icanFee = addons?.find((addon) =>
          addon.product?.includes('icann'),
        )?.total;

        if (icanFee) amount += icanFee;
      });

      return amount;
    },
    icannItemFee() {
      const icannProduct = this.pricingDetails?.items?.find((item) =>
        item.product?.includes('icann'),
      );

      if (!icannProduct) return null;

      return icannProduct.total;
    },
    redemptionPeriodFee() {
      const addons = this.pricingDetails?.items?.[0]?.addons;
      const redemptionFee = addons?.find((addon) =>
        addon.product?.includes('domainredemption'),
      )?.total;

      return redemptionFee;
    },
    icannFeeAmount() {
      return this.$currency.format(this.icannFee || this.icannItemFee, {
        fixedCurrency: this.fixedCurrency,
        isChargebeePrice: true,
      });
    },
    expirationDateTooltipText() {
      if (this.paymentType === 'renew' || this.paymentType === 'reactivate') {
        return this.$t('New expiration date after payment');
      }

      if (this.paymentType === 'upgrade') {
        return this.$t(
          'Remaining time is converted accordingly to the price of the new plan, adding to it as extra hosting time',
        );
      }

      return null;
    },
    creditBalanceTooltip() {
      if (
        this.paymentType === 'renew' ||
        this.paymentType === 'reactivate' ||
        this.paymentType === 'purchase'
      ) {
        return this.$t(
          'v2.this.is.the.money.that.was.refunded.to.you.for.any.subscriptions.that.you.mightve',
        );
      }

      return this.$t('v2.includes.credits.and.excess.payments');
    },
    ...mapState('paymentMethods', { paymentMethodsLoading: 'loading' }),
    ...mapGetters('paymentMethods', {
      defaultPaymentMethod: 'getDefaultPaymentMethod',
    }),
  },
  methods: {
    getChargeBeePrice(price) {
      return this.$currency.format(price, {
        isChargebeePrice: true,
      });
    },
    setTaxesTooltip() {
      if (!this.taxCount && this.hasIcannFee) {
        this.taxesTooltip = this.$t('{icannFee} ICANN fee', {
          icannFee: this.icannFeeAmount,
        });

        return;
      }

      if (this.taxCount && this.hasIcannFee) {
        this.taxesTooltip = this.$t(
          `Taxes & fees include {taxCount}% {taxName} and {icannFee} ICANN fee`,
          {
            taxCount: this.taxCount,
            taxName: this.$t(this.taxName),
            icannFee: this.icannFeeAmount,
          },
        );

        return;
      }

      this.taxesTooltip = this.$t(
        `Taxes & fees include {taxCount}% {taxName}`,
        {
          taxCount: this.taxCount,
          taxName: this.$t(this.taxName),
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-details {
  margin: 16px 0;

  &__list {
    list-style: none;
    padding-left: 0;
    padding: 16px;
    border-radius: 4px;
    margin: 0;
    background-color: var(--gray-light);
  }

  &__error {
    display: flex;
    align-items: center;

    p {
      color: var(--dark);
    }

    .icon-wrapper .h-icon {
      flex-shrink: 0;
    }
  }

  &__terms-of-service {
    color: var(--dark);
  }
}
</style>
