export const removeFalsyKeysFromObject = (object) => {
  const entries = Object.entries(object);
  const reducerFunction = (accumulator, [key, value]) => {
    if (!value) {
      return accumulator;
    }

    return {
      [key]: value,
      ...accumulator,
    };
  };
  const initialObject = {};

  return entries.reduce(reducerFunction, initialObject);
};
