export const DEVICE_TYPE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  UNKNOWN: 'unknown',
} as const;
export type DeviceType = (typeof DEVICE_TYPE)[keyof typeof DEVICE_TYPE];

export const MOBILE_OS = {
  ANDROID: 'android',
  IOS: 'ios',
  UNKNOWN: 'unknown',
  WINDOWS_PHONE: 'Windows Phone',
} as const;
export type MobileOS = (typeof MOBILE_OS)[keyof typeof MOBILE_OS];

export const DESKTOP_OS = {
  LINUX: 'linux',
  MACOS: 'mac_os',
  UNIX: 'unix',
  UNKNOWN: 'unknown',
  WINDOWS: 'windows',
};
export type DesktopOS = (typeof DESKTOP_OS)[keyof typeof DESKTOP_OS];

export type DeviceOS = DesktopOS | MobileOS;

export const BROWSER_TYPE = {
  CHROME: 'chrome',
  FIREFOX: 'firefox',
  SAFARI: 'safari',
  EDGE: 'edge',
  IE: 'ie',
  OPERA: 'opera',
  UNKNOWN: 'unknown',
} as const;
export type BrowserType = (typeof BROWSER_TYPE)[keyof typeof BROWSER_TYPE];

export const userAgent: string = navigator.userAgent || (window as any).opera;

export const isMobileDevice = (): boolean => {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /BlackBerry/i,
    /iPhone|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ];

  return regexs.some((b) => userAgent.match(b));
};

export const isTabletDevice = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

  return regex.test(userAgent.toLowerCase());
};

export const isDesktopDevice = (): boolean =>
  !isMobileDevice() && !isTabletDevice();

export const getDeviceType = (): DeviceType => {
  if (isDesktopDevice()) return DEVICE_TYPE.DESKTOP;
  if (isMobileDevice()) return DEVICE_TYPE.MOBILE;
  if (isTabletDevice()) return DEVICE_TYPE.TABLET;

  return DEVICE_TYPE.UNKNOWN;
};

/**
 * https://stackoverflow.com/a/21742107
 * Determine the mobile operating system.
 *
 * @returns {MobileOS}
 */
const getMobileOS = (): MobileOS | undefined => {
  if (isMobileDevice()) {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) return MOBILE_OS.WINDOWS_PHONE;
    else if (/android/i.test(userAgent)) return MOBILE_OS.ANDROID;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return MOBILE_OS.IOS;
    }

    return MOBILE_OS.UNKNOWN;
  }

  return undefined;
};

const getDesktopOS = (): DesktopOS | undefined => {
  if (isDesktopDevice()) {
    if (userAgent.indexOf('Win') !== -1) return DESKTOP_OS.WINDOWS;
    else if (userAgent.indexOf('Mac') !== -1) return DESKTOP_OS.MACOS;
    else if (userAgent.indexOf('X11') !== -1) return DESKTOP_OS.UNIX;
    else if (userAgent.indexOf('Linux') !== -1) return DESKTOP_OS.LINUX;

    return DESKTOP_OS.UNKNOWN;
  }

  return undefined;
};

export const getDeviceOS = (): DeviceOS | undefined =>
  getMobileOS() ?? getDesktopOS();

export const getDeviceBrowser = (): BrowserType => {
  const ua = userAgent.toLowerCase();

  if (ua.indexOf('chrome') > -1 && ua.indexOf('edg') === -1) {
    return BROWSER_TYPE.CHROME;
  }
  if (ua.indexOf('firefox') > -1) return BROWSER_TYPE.FIREFOX;
  if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1) {
    return BROWSER_TYPE.SAFARI;
  }
  if (ua.indexOf('edg') > -1) return BROWSER_TYPE.EDGE;
  if (ua.indexOf('trident') > -1) return BROWSER_TYPE.IE;
  if (ua.indexOf('opr') > -1 || ua.indexOf('opera') > -1) {
    return BROWSER_TYPE.OPERA;
  }

  return BROWSER_TYPE.UNKNOWN;
};
