export namespace Service {
  export enum Type {
    DOMAIN = 'domain',
    HOSTING = 'hosting',
    EMAIL = 'email',
    CPANEL_HOSTING = 'cpanel_hosting',
    CPANEL_RESELLER_HOSTING = 'cpanel_reseller_hosting',
    RANKING_COACH = 'ranking_coach',
    DOMAIN_TRANSFER = 'domain_transfer',
    GOOGLE_GSUITE = 'google_gsuite',
    HOSTINGER_MAIL = 'hostinger_mail',
    FLOCK_MAIL = 'flock_mail',
    VPS = 'vps',
    DEVELOPER_TOOLS = 'developer_tools',
  }
}
