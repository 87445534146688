import type { IServerMaintenanceRes } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/server/maintenance`,
  async getServerMaintenanceIndex() {
    return await hAsync<IServerMaintenanceRes>(
      http.get(`${this.url}/incidents`, { cache: 600 }),
    );
  },
};
