import { isocountries } from '@/data/isocountries';
import phoneCcs from '@/data/phoneCC';
import type { IProfileContact, ProfileInformationModalKeyValue } from '@/types';
import { Country, PROFILE_INFORMATION_MODAL_SCHEMA_KEY } from '@/types';

export const formatClientData = (
  fields: {
    value: string | null;
    data: any;
    type: ProfileInformationModalKeyValue;
    label: string;
  }[],
) =>
  fields.map(({ value, data, type, label }) => {
    let displayValue = value && type === 'phone' ? `+${value}` : value;
    if (!value) displayValue = '-';

    return {
      id: type,
      name: type,
      label,
      value: displayValue,
      data,
      type: 'arrow',
      dir: ['phone', 'created'].includes(type) ? 'ltr' : '',
    };
  });

export const findPhoneCCByCountry = (countryIso: string | null) =>
  phoneCcs.find(({ iso }) => iso === countryIso)?.value || null;

export const titleFilter = (data: (string | null)[], separator = ', ') =>
  data.filter((item) => item).join(separator);

export const removeLTWithDashFromZip = (zip: string | null) =>
  zip?.replace(`${Country.Code.LT}-`, '');

export const mapProfileContactData = (
  contactData: IProfileContact,
  fullName: string,
) => {
  const schema = [
    {
      type: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME,
      label: 'Name',
      value: fullName,
      data: {
        firstName: contactData.firstName,
        lastName: contactData.lastName,
      },
    },
    {
      type: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.ADDRESS,
      label: 'Address',
      value: titleFilter([
        contactData.address,
        contactData.city,
        contactData.countryCode,
      ]),
      data: {
        address: contactData.address,
        city: contactData.city,
        state: contactData.state,
        zip: contactData.zip,
        country: contactData.countryCode
          ? isocountries.find(({ value }) => value === contactData.countryCode)
              ?.label
          : '-',
      },
    },
    {
      type: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.PHONE,
      label: 'Phone number',
      value: titleFilter([contactData.phoneCc, contactData.phone], ' '),
      data: {
        phone: contactData.phone,
        phoneCc:
          contactData.phoneCc || findPhoneCCByCountry(contactData.countryCode),
      },
    },
    {
      type: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.COMPANY,
      label: 'Company',
      value: titleFilter([
        contactData.companyName,
        contactData.vatId,
        contactData.companyId,
      ]),
      data: {
        companyName: contactData.companyName,
        vatId: contactData.vatId,
        companyId: contactData.companyId,
        countryCode: contactData.countryCode,
      },
    },
  ];

  return formatClientData(schema);
};
