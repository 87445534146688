import { pageSpeedRepo } from '@/repositories';

export default {
  state: {
    currentPerformance: null,
    pastPerformances: [],
    loadingCurrent: false,
    loadingPast: false,
    error: null,
  },
  mutations: {
    SET_SPEED_TEST_CURRENT(state, payload) {
      state.currentPerformance = payload;
    },
    SET_SPEED_TEST_PAST(state, payload) {
      state.pastPerformances = payload;
    },
    SET_SPEED_TEST_LOADING_CURRENT(state, payload) {
      state.loadingCurrent = payload;
    },
    SET_SPEED_TEST_LOADING_PAST(state, payload) {
      state.loadingPast = payload;
    },
    SET_SPEED_TEST_ERROR(state, payload) {
      state.error = payload;
    },
  },
  getters: {
    getSpeedTestCurrentData: (state) => state.currentPerformance,
    getSpeedTestPastData: (state) => state.pastPerformances,
    getSpeedTestLoadingCurrentStatus: (state) => state.loadingCurrent,
    getSpeedTestLoadingPastStatus: (state) => state.loadingPast,
    getSpeedTestError: (state) => state.error,
  },
  actions: {
    async fetchSpeedTest({ commit }, params) {
      commit('SET_SPEED_TEST_LOADING_CURRENT', true);
      commit('SET_SPEED_TEST_ERROR', null);
      const [{ data }, err] = await pageSpeedRepo.postSpeedTest(params);
      if (err) {
        commit('SET_SPEED_TEST_ERROR', err?.error?.message);
      }
      commit('SET_SPEED_TEST_CURRENT', data);
      commit('SET_SPEED_TEST_LOADING_CURRENT', false);
    },
    async getSpeedTestPast({ commit }, params) {
      commit('SET_SPEED_TEST_LOADING_PAST', true);
      commit('SET_SPEED_TEST_CURRENT', null);
      const [{ data }] = await pageSpeedRepo.getSpeedTestLogs(params);

      if (data) {
        commit('SET_SPEED_TEST_PAST', data);
      }
      commit('SET_SPEED_TEST_LOADING_PAST', false);
    },
  },
};
