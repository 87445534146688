import type { NavigationGuard } from 'vue-router';

import { useBillingStore, useFrontendSettingsStore } from '@/stores';

export const accountInformationGuard: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const { fetchInvoices, fetchUnpaidOrders } = useBillingStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  frontendSettingsStore.setState('pageLoading', true);
  await Promise.all([fetchUnpaidOrders(), fetchInvoices()]);
  frontendSettingsStore.setState('pageLoading', false);

  next();
};
