import { useFeatureFlag } from '@/composables';
import { FeatureFlag } from '@/types';
import {
  addConsoleErrorSpy,
  startVersionCheckTimer,
} from '@/utils/services/versionUpdateChecker';

export const useHpanelVersionUpdate = () => {
  const { isFeatureEnabled: isHpanelVersionUpdateEnabled } = useFeatureFlag(
    FeatureFlag.ID.HPANEL_VERSION_UPDATE,
  );

  const initVersionUpdateCheck = () => {
    if (isHpanelVersionUpdateEnabled.value) {
      startVersionCheckTimer();
    } else {
      addConsoleErrorSpy();
    }
  };

  return {
    initVersionUpdateCheck,
  };
};
