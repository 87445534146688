import type {
  IHDomainResource,
  HDomainResourceType,
  HDomainResourceStatus,
} from '@hostinger/hdomains-status';
import {
  HDOMAIN_RESOURCE_TYPES,
  HDOMAIN_RESOURCE_STATUSES,
} from '@hostinger/hdomains-status';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useManagedDomains } from '@/composables/hostingerPro/useManagedDomains';
import { hDomainsRepo } from '@/repositories';
import { useSubscriptionsStore } from '@/stores';
import { useProfileStore } from '@/stores/profile/profileStore';
import type { HAsyncResponse, ITransferIn } from '@/types';
import { STORE_PERSISTENT_KEYS } from '@/types';
import { stripClientIdToNumber, toLocalTime } from '@/utils/helpers';

export const useHDomainResourceStore = defineStore(
  'hDomainResourceStore',
  () => {
    const subscriptionStore = useSubscriptionsStore();
    const profileStore = useProfileStore();

    const hDomainsResources = ref<IHDomainResource[]>([]);
    const isHDomainResourcesLoaded = ref(false);
    const isHDomainResourcesLoading = ref(false);

    const setHDomainsResources = (data: IHDomainResource[]) => {
      hDomainsResources.value = data.map((resource) => {
        const registeredAt = resource?.additionalDetails?.registeredAt || '';

        if (registeredAt) {
          resource.additionalDetails!.registeredAt = dayjs(registeredAt).unix();
        }

        return resource;
      });
    };

    const setHDomainsResource = (data: IHDomainResource) => {
      const registeredAt = data?.additionalDetails?.registeredAt || '';

      if (data.additionalDetails && registeredAt) {
        data.additionalDetails.registeredAt = dayjs(registeredAt).unix();
      }

      hDomainsResources.value = [
        ...hDomainsResources.value.filter(
          (resource) => resource.id !== data.id,
        ),
        data,
      ];
    };

    const getHDomainResourceByDomain = (domain: string) =>
      hDomainsResources.value.find(
        ({ resourceType, title }) =>
          resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN && domain === title,
      );
    const getHDomainResourceByDomainAndStatus = (
      domain: string,
      status: HDomainResourceStatus,
    ) =>
      hDomainsResources.value.find(
        (resource) =>
          resource.resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN &&
          resource.title === domain &&
          resource.status === status,
      );

    const getHDomainResourceByHResourceId = (hResourceId: string) =>
      hDomainsResources.value.find(
        ({ additionalDetails }) =>
          String(additionalDetails?.hresourceId) === String(hResourceId),
      );

    const getHDomainTransferResourceByDomain = (domain: string) =>
      hDomainsResources.value.find(
        ({ resourceType, title }) =>
          resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN_TRANSFER &&
          domain === title,
      );

    const getHDomainResourceById = (id: number) =>
      hDomainsResources.value.find((resource) => resource.id === id);

    const getHDomainResourcesByType = (type: HDomainResourceType) =>
      hDomainsResources.value.filter(
        (resource) => resource.resourceType === type,
      );

    const getOwnedHDomainResourcesByType = (type: HDomainResourceType) =>
      hDomainsResources.value.filter(
        (resource) =>
          resource.resourceType === type &&
          resource.customerCustomId === profileStore.account?.id,
      );

    const hasHDomainResources = computed(
      () => !!hDomainsResources.value.length,
    );

    const hasUserSinglePendingDomainResource = computed(
      () =>
        hDomainsResources.value.filter(
          (resource) =>
            resource.resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN &&
            resource.status === HDOMAIN_RESOURCE_STATUSES.PENDING_SETUP,
        ).length === 1,
    );

    const getDomainsToMoveList = computed(() =>
      Object.values(hDomainsResources.value).flatMap(
        ({ title, additionalDetails }) =>
          additionalDetails?.isMovable ? title : [],
      ),
    );

    const transferInList = computed<ITransferIn[]>(() => {
      const { getIsManagedDomain } = useManagedDomains();
      const transfers = hDomainsResources.value.flatMap((resource) => {
        if (getIsManagedDomain(resource)) return [];

        const isTransferResource =
          resource.resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN_TRANSFER;

        if (!isTransferResource) return [];

        const initiatedOn = toLocalTime(
          resource.additionalDetails?.initiatedAt || '',
        );

        return {
          subscriptionId: getTransferSubscriptionId(resource),
          domain: resource.title,
          clientId: stripClientIdToNumber(resource.customerCustomId),
          initiatedOn,
          status: resource.status,
        };
      });

      return sortTransfersList(transfers);
    });

    const fetchHDomainsResources = async (params?: {
      searchPattern?: string;
      resourceType?: string;
    }): HAsyncResponse<IHDomainResource[]> => {
      isHDomainResourcesLoading.value = true;

      const { searchPattern, resourceType } = params || {};

      const requestParams = {
        title: searchPattern,
        resourceType,
      };
      const response = await hDomainsRepo.getProResources(requestParams);
      const [{ data }, error] = response;

      if (!error) {
        setHDomainsResources(data);
        isHDomainResourcesLoaded.value = true;
      }

      isHDomainResourcesLoading.value = false;

      return response;
    };

    const fetchHDomainsResource = async (hDomainResourceId: number) => {
      const [{ data }, error] = await hDomainsRepo.getResource(
        hDomainResourceId,
      );

      if (error) return;

      setHDomainsResource(data);
    };

    const sortTransfersList = (transfers: ITransferIn[]) =>
      transfers.sort((a, b) => {
        const order = {
          [HDOMAIN_RESOURCE_STATUSES.PENDING_SETUP]: 1,
          [HDOMAIN_RESOURCE_STATUSES.FAILED]: 2,
          [HDOMAIN_RESOURCE_STATUSES.PENDING_VERIFICATION]: 3,
        };

        return (
          order[a.status as keyof typeof order] -
          order[b.status as keyof typeof order]
        );
      });

    const getTransferSubscriptionId = (resource: IHDomainResource) => {
      const subscription = subscriptionStore.getDomainSubscriptionByProperty({
        hResourceId: resource.additionalDetails?.hresourceId,
      });

      return subscription?.id;
    };

    return {
      hDomainsResources,
      hasHDomainResources,
      isHDomainResourcesLoaded,
      isHDomainResourcesLoading,
      getHDomainResourceByDomain,
      getHDomainTransferResourceByDomain,
      getHDomainResourceById,
      fetchHDomainsResources,
      setHDomainsResources,
      getDomainsToMoveList,
      transferInList,
      getHDomainResourcesByType,
      fetchHDomainsResource,
      getHDomainResourceByHResourceId,
      hasUserSinglePendingDomainResource,
      getHDomainResourceByDomainAndStatus,
      getOwnedHDomainResourcesByType,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.HDOMAIN_RESOURCE },
  },
);
