<template>
  <div v-if="!isHostingV2" class="failed-autorenew-banner">
    <div class="h-container">
      <div class="failed-autorenew-banner__info-wrapper">
        <p class="d-flex align-items-center">
          <HpIcon icon="icon-error" danger class="h-mr-8" />
          <b v-trans>v2.update.your.payment.information.to.continue</b>
        </p>
        <Trans :translate-params="{ productTitle }" tag="p" class="h-mt-8">
          v2.we.were.unable.to.process.your.last.payment.for.producttitle.please.update.your.payment.information
        </Trans>
      </div>
      <HButtonV1 md warning :loading="isLinkLoading" @click="handleSubmit">
        v2.update.payment
      </HButtonV1>
    </div>
  </div>
</template>

<script>
//JSON-slugs-migrated
import { mapActions, mapState, mapGetters } from 'vuex';

import { useHostingV2, useSubscriptions } from '@/composables';
import { hBillingRepo } from '@/repositories';
import {
  useProfileStore,
  useResourcesStore,
  useSubscriptionsStore,
} from '@/stores';
import { AmplitudeEvent, Route } from '@/types';
import { toUnicode } from '@/utils/helpers';

export default {
  async created() {
    if (
      !this.billingGetPaymentMethodsLoading &&
      !this.billingGetPaymentMethodsLoaded
    ) {
      await this.billingGetPaymentMethods();
    }

    this.relatedServiceCheck();
  },
  setup() {
    const { isHostingV2 } = useHostingV2();
    const resourceStore = useResourcesStore();
    const profileStore = useProfileStore();
    const subscriptionsStore = useSubscriptionsStore();
    const { fetchSubscriptionsSilentlyWithCache } = useSubscriptions();

    return {
      profileStore,
      isHostingV2,
      resourceStore,
      subscriptionsStore,
      fetchSubscriptionsSilentlyWithCache,
    };
  },
  data() {
    return {
      relatedService: null,
      failedCbRenewInvoice: null,
      isLinkLoading: false,
      productTitle: '',
    };
  },
  computed: {
    ...mapState({
      unpaidInvoices: (state) => state.sso.billing.unpaidInvoices.data,
      servicesWithoutAutoRenew: (state) =>
        state.sso.billing.services.total_without_auto_renew,
    }),
    ...mapState('subscriptions', ['paymentDueInvoices']),
    ...mapState({
      billingGetPaymentMethodsLoading: (state) => state.paymentMethods.loading,
      billingGetPaymentMethodsLoaded: (state) => state.paymentMethods.loaded,
    }),
    ...mapGetters(['getOrderById', 'getFirstAccountByOrder']),
    ...mapGetters({
      getActivePaymentMethods: 'billing/getActivePaymentMethods',
      getCBActivePaymentMethods: 'paymentMethods/getActivePaymentMethodsCount',
    }),
  },
  emits: ['banner-visibility', 'loading-banner'],
  watch: {
    servicesWithoutAutoRenew() {
      this.relatedServiceCheck();
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.changeBannerVisibility();
      },
    },
  },
  methods: {
    setProductTitle() {
      const serviceName = this.findDomainOrHostingItem(
        this.failedCbRenewInvoice?.lineItems,
      )?.externalName;
      const hostname = this.resourceStore.getResourceBySubscriptionId(
        this.failedCbRenewInvoice?.subscriptionId,
      )?.title;
      this.productTitle = `${serviceName} - ${toUnicode(hostname)}`;
    },
    findDomainOrHostingItem(items) {
      return items?.find(
        ({ entityId }) =>
          entityId.includes('hosting') || entityId.includes('domain'),
      );
    },
    async findFailedAutorenewService() {
      const invoices = this.paymentDueInvoices?.filter(
        ({ lineItems, linkedPayments }) => {
          const isHostingOrDomain = !!this.findDomainOrHostingItem(lineItems);
          const isPaymentFailed =
            linkedPayments[linkedPayments?.length - 1]?.txnStatus === 'failure';

          return isHostingOrDomain && isPaymentFailed;
        },
      );

      if (!invoices.length) return;

      if (!this.subscriptionsStore.subscriptions?.length) {
        await this.fetchSubscriptionsSilentlyWithCache();
      }

      return invoices.find(({ subscriptionId }) =>
        this.subscriptionsStore.subscriptions.find(
          ({ id, status }) => id === subscriptionId && status === 'active',
        ),
      );
    },
    async relatedServiceCheck() {
      this.$emit('loading-banner', true);
      if (this.profileStore?.isAccessManager) {
        this.$emit('loading-banner', false);

        return;
      }

      if (!this.getCBActivePaymentMethods) {
        this.changeBannerVisibility();
        this.$emit('loading-banner', false);

        return;
      }

      if (!this.$store.state.subscriptions.isPaymentDueInvoicesLoaded) {
        await this.fetchSubscriptionPaymentDueInvoices();
      }

      if (this.paymentDueInvoices?.length) {
        this.failedCbRenewInvoice = await this.findFailedAutorenewService();

        if (
          !this.resourceStore.isResourcesLoading &&
          !this.resourceStore.isLoaded
        ) {
          await this.resourceStore.fetchResources();
        }

        if (this.failedCbRenewInvoice) {
          this.setProductTitle();
        }
      }
      this.changeBannerVisibility();
      this.$emit('loading-banner', false);

      return;
    },
    changeBannerVisibility() {
      let isVisible = false;

      if (
        this.productTitle &&
        !this.$route.meta.isOnboarding &&
        this.$route.name !== Route.Base.HOME
      ) {
        isVisible = true;
      }

      if (isVisible && !this.isHostingV2) {
        this.$amplitudeV2(
          AmplitudeEvent.Billing.UPDATE_PAYMENT_INFORMATION_SHOWN,
        );
      }

      this.$emit('banner-visibility', isVisible);
    },
    setRelatedService(data) {
      for (const payment of data) {
        const invoiceFound = this.unpaidInvoices.find(
          (invoice) =>
            invoice.auto_renew && invoice.id === payment.invoiceReference,
        );
        if (['hosting', 'domain'].includes(invoiceFound?.renew_service)) {
          this.relatedService = invoiceFound;

          return true;
        }
      }

      return false;
    },
    async handleSubmit() {
      this.$amplitudeV2(
        AmplitudeEvent.Billing.UPDATE_PAYMENT_INFORMATION_ENTER,
      );
      this.isLinkLoading = true;
      const [{ data }, err] = await hBillingRepo.getOrderInvoicePaymentLink({
        invoiceId: this.failedCbRenewInvoice?.id,
      });

      if (!err && data.paymentLink) {
        window.open(data.paymentLink, '_self');

        return;
      }

      this.isLinkLoading = false;
    },
    ...mapActions({
      billingGetPaymentMethods: 'paymentMethods/billingGetPaymentMethods',
    }),
    ...mapActions('subscriptions', ['fetchSubscriptionPaymentDueInvoices']),
    ...mapActions('v2.billing', ['billingGetUnpaidInvoices']),
  },
};
</script>

<style lang="scss" scoped>
.failed-autorenew-banner {
  width: 100%;
  padding: 16px 0;
  background: var(--light);
  border-bottom: 1px solid var(--gray-border);

  .h-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 576px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info-wrapper {
    padding-bottom: 9px;

    @media (min-width: 576px) {
      margin-right: 16px;
      padding-bottom: 0;
    }

    p {
      color: var(--dark);
    }
  }
}
</style>
