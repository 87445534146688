import type { ModalObject } from '@/types';

export default [
  {
    name: 'DeletePresetModal',
    type: 'HModal',
    subtype: 'WebPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ProPlanSelectionModal',
    type: 'HModal',
    subtype: 'WebPro',
    steps: [
      {
        title: 'Select a Hosting Plan',
        subtitle:
          'Select to which hosting plan you would like to add a new website',
        persistent: true,
        hideX: true,
      },
    ],
  },
  {
    name: 'ProHostingPurchaseModal',
    type: 'HModal',
    subtype: 'WebPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WebsitesUpdateModal',
    type: 'HModal',
    subtype: 'WebPro',
    steps: [
      {
        hideX: true,
      },
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressPluginModal',
    type: 'HModal',
    subtype: 'WebPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
] as ModalObject[];
