<script setup lang="ts">
import type {
  FieldError,
  FieldSchema,
  FormValues,
} from '@hostinger/hcomponents';
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  useGlobals,
  useModal,
  useServiceExpirationReminder,
  useBrands,
} from '@/composables';
import phoneCC from '@/data/phoneCC';
import { useProfileStore } from '@/stores';
import type { ReminderUpdateRequest, ExpirationReminderType } from '@/types';
import { FieldType, AmplitudeEvent } from '@/types';

const store = useStore();
const { t, amplitudeV2 } = useGlobals();
const { closeModal } = useModal();
const { isLoading, updateReminderSettings } = useServiceExpirationReminder();
const { externalUrl } = useBrands();
const profileStore = useProfileStore();

type Props = {
  data?: {
    type?: ExpirationReminderType;
    onSuccess: () => void;
  };
};
const props = defineProps<Props>();

const phoneSchema = computed<FieldSchema>(() => ({
  type: FieldType.text,
  label: t('Phone number'),
  prepend: phoneCC.map(({ full_label, value }, index) => ({
    label: full_label,
    value,
    selected: index === 0,
  })),
  getSplitValue: true,
  validation: {
    custom: (value: string): FieldError | null => {
      const regex =
        /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/;

      if (regex.test(value)) {
        return null;
      }

      return { text: t('Invalid phone number') };
    },
  },
}));

const reminderSubscriptions = computed(() => ({
  sms: !!profileStore.settings?.isSubscribedToSmsReminder,
  whatsapp: !!profileStore.settings?.isSubscribedToWhatsappReminder,
}));

const onFormSubmit = async ({ values: { phone } }: FormValues) => {
  const request: ReminderUpdateRequest = {
    reminderSubscriptions: props.data?.type
      ? { [props.data?.type]: true }
      : { sms: true, whatsapp: true },
    phone: phone.value,
    phoneCc: phone.prepend,
  };

  const [, err] = await updateReminderSettings(request);

  if (err) return;

  amplitudeV2(AmplitudeEvent.PhoneNumber.FORM_COMPLETED);
  if (props.data?.onSuccess) props.data.onSuccess();
  closeModal();

  store.commit('SET_CLIENT_INFO_PROPERTY', {
    name: 'reminderSubscriptions',
    value: props.data?.type
      ? { ...reminderSubscriptions.value, ...{ [props.data?.type]: true } }
      : { sms: true, whatsapp: true },
  });
};
</script>

<template>
  <HForm :loading="isLoading" @on-submit="onFormSubmit($event)">
    <HFormField name="phone" :schema="phoneSchema" />

    <Trans tag="p">
      By enabling, you consent to phone number use for sending reminders as per
      our
      <a
        :href="externalUrl.privacyPolicy"
        target="_blank"
        class="text-primary text-bold-2"
      >
        Privacy Policy.
      </a>
      You can withdraw consent anytime.
    </Trans>

    <div class="d-flex justify-content-end h-mt-24">
      <HButtonV1 v-if="!isLoading" primary text no-margin @click="closeModal">
        Cancel
      </HButtonV1>
      <HButtonV1 primary no-margin h-form-submit :loading="isLoading">
        Enable
      </HButtonV1>
    </div>
  </HForm>
</template>
