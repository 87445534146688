import type { IRankingCoachSetupDomainParams } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_INTEGRATIONS}/api/`,

  async setupDomain(
    referenceId: string,
    params: IRankingCoachSetupDomainParams,
  ) {
    return await hAsync(
      http.post(
        `${this.url}/direct/ranking-coach/${referenceId}/setup`,
        {
          ...params,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },

  async rankingCoachLogin(referenceId: string) {
    return await hAsync<string>(
      http.get(`${this.url}/direct/ranking-coach/${referenceId}/auto-login`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
};
