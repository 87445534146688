import type {
  TrustpilotInvitationLinkRequestBody,
  TrustpilotInvitationLinkResponseBody,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  getTrustpilotInvitationLink(body: TrustpilotInvitationLinkRequestBody) {
    return hAsync<TrustpilotInvitationLinkResponseBody>(
      http.post(
        `${process.env.VITE_API_INTEGRATIONS}/api/direct/trustpilot/invite`,
        body,
        { whitelistedStatusCodes: [400, 422, 429] },
      ),
    );
  },
};
