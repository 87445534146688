import { whPhpRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  phpVersion: '',
  fullPhpVersion: '',
  phpVersions: {},
  buildInExtensions: [],
  extensions: [],
  options: [],
  superOptions: [],
  conflictingExtensions: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_PHP_VERSION: (state, { data, requestDomain }) => {
      setForDomain(state, requestDomain, 'phpVersion', data);
    },
    SET_FULL_PHP_VERSION: (state, { data, requestDomain }) => {
      setForDomain(state, requestDomain, 'fullPhpVersion', data);
    },
    SET_PHP_CONFIG_DATA: (state, data) => {
      setForDomain(state, null, 'phpVersion', data.phpVersion);
      setForDomain(state, null, 'phpVersions', data.phpVersions);

      const buildInExtensions = [];
      const extensions = [];

      Object.keys(data.extensions).forEach((key) => {
        if (data.extensions[key].state === 'build-in') {
          buildInExtensions.push({
            extension: key,
            value: true,
            ...data.extensions[key],
          });
        } else {
          extensions.push({
            extension: key,
            value: data.extensions[key].state === 'enabled',
            ...data.extensions[key],
          });
        }
      });

      setForDomain(state, null, 'buildInExtensions', buildInExtensions);
      setForDomain(state, null, 'extensions', extensions);
      setForDomain(
        state,
        null,
        'conflictingExtensions',
        data.conflictingExtensions,
      );

      const superOptions = [];
      const options = [];

      Object.keys(data.options).forEach((key) => {
        if (data.options[key].type === 'bool') {
          superOptions.push({
            ...data.options[key],
            value:
              (data.options[key].value || data.options[key].default) === 'On',
            key,
          });
        } else {
          options.push({
            ...data.options[key],
            value: data.options[key].value || data.options[key].default,
            key,
          });
        }
      });

      setForDomain(state, null, 'superOptions', superOptions);
      setForDomain(state, null, 'options', options);
      setForDomain(state, null, 'loaded', true);
    },
  },
  getters: {
    getPhpVersion: (state) => getStateVariable(state, 'phpVersion'),
    getFullPhpVersion: (state) => getStateVariable(state, 'fullPhpVersion'),
    getPhpVersions: (state) => getStateVariable(state, 'phpVersions'),
    getPhpConfigState: (state) => getStateVariable(state),
  },
  actions: {
    async loadPhpVersions({ commit }) {
      const [{ data, requestDomain }] = await whPhpRepo.getPhpDetails(
        getCurrentOrderDetails(),
      );
      const phpVersions = data.phpVersions;

      if (data) {
        commit('SET_PHP_VERSION', {
          data: data.phpVersion,
          requestDomain,
        });

        commit('SET_FULL_PHP_VERSION', {
          data: data.phpVersionFull,
          requestDomain,
        });
      }

      return phpVersions;
    },
    async loadPhpConfig({ commit }) {
      const [{ data }, error] = await whPhpRepo.getPhpDetails(
        getCurrentOrderDetails(),
      );

      if (!error) {
        commit('SET_PHP_CONFIG_DATA', data);
      }
    },
    async fetchSinglePhpVersion(_, { data }) {
      const [responseData, error] = await whPhpRepo.updatePhpVersion(
        getCurrentOrderDetails(),
        data,
      );
      if (!error) {
        await whPhpRepo.resetPhpExtensions(getCurrentOrderDetails());
      }

      return [responseData, error];
    },
    async fetchOptions({ dispatch }, phpOptions) {
      const [data, error] = await whPhpRepo.updatePhpOptions(
        getCurrentOrderDetails(),
        phpOptions,
      );
      dispatch('loadPhpConfig');

      return [data, error];
    },
    async fetchPhpVersion({ dispatch }, versionData) {
      const [_, error] = await whPhpRepo.updatePhpVersion(
        getCurrentOrderDetails(),
        versionData,
      );

      if (error) {
        return error;
      }

      await whPhpRepo.resetPhpExtensions(getCurrentOrderDetails());
      dispatch('loadPhpConfig');

      return error;
    },
    async fetchExtensions({ dispatch }, phpExtensions) {
      const [data, error] = await whPhpRepo.updatePhpExtensions(
        getCurrentOrderDetails(),
        phpExtensions,
      );
      dispatch('loadPhpConfig');

      return [data, error];
    },
    async resetExtensions({ dispatch }) {
      await whPhpRepo.resetPhpExtensions(getCurrentOrderDetails());
      dispatch('loadPhpConfig');
    },
  },
};
