import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useSubscriptions } from '@/composables';
import { websiteRepo } from '@/repositories';
import { useProfileStore } from '@/stores/profile/profileStore';
import { type ProWebsiteBase, type ProWebsiteSubscriptionType } from '@/types';
import { Website, STORE_PERSISTENT_KEYS } from '@/types';
import { isEmptyArray } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const ORDER_INITIAL_VALUES = {
  id: '',
  status: '',
  expiresAt: '',
  reason: '',
  suspendAt: '',
};

export const useWebsitesStore = defineStore(
  'websitesStore',
  () => {
    const { getSubscriptionByOrderId } = useSubscriptions();

    const profileStore = useProfileStore();

    const isWebsitesListLoading = ref<boolean | null>(null);
    const websites = ref<ProWebsiteBase[]>([]);
    const removingWebsites = ref<string[]>([]);
    const websitesInSetup = ref<string[]>([]);
    const totalWebsites = ref(0);
    const hasAtLeastOneWebsite = ref<boolean | null>(null);
    const wordPressTabItemsPerPage = ref(5);
    const selectedWebsiteHeaderData = ref<Partial<
      Record<string, string>
    > | null>(null);

    const hasOwnOrManagedWebsites = computed(() => totalWebsites.value > 0);

    const builderWebsites = computed(() =>
      websites.value.filter(({ type }) => type === Website.Type.BUILDER),
    );

    const wordpressWebsites = computed(() =>
      websites.value.filter(({ type }) => type === Website.Type.WORDPRESS),
    );

    const websitesWithSubscriptions = computed<ProWebsiteSubscriptionType[]>(
      () => mapSubscription(websites.value),
    );

    const $reset = () => {
      isWebsitesListLoading.value = null;
      websites.value = [];
      totalWebsites.value = 0;
      wordPressTabItemsPerPage.value = 5;
      removingWebsites.value = [];
    };

    const mapSubscription = (
      values: ProWebsiteBase[],
    ): ProWebsiteSubscriptionType[] =>
      values.map((listItem: ProWebsiteBase) => {
        const { status, expiresAt, reason, suspendAt, id } =
          findWebsiteSubscription(listItem.orderId, listItem.clientId) ||
          ORDER_INITIAL_VALUES;

        return {
          ...listItem,
          status,
          expiresAt,
          reason,
          suspendAt,
          id,
        };
      });

    const addRemovingWebsite = (domain: string) => {
      removingWebsites.value.push(domain);
    };

    const removeWebsite = (domain: string) => {
      const index = removingWebsites.value.indexOf(domain);
      if (index !== -1) {
        removingWebsites.value.splice(index, 1);
      }
    };

    const addWebsitesInSetup = (domain: string) => {
      websitesInSetup.value.push(domain);
    };

    const cleanWebsitesInSetup = () => (websitesInSetup.value = []);

    const isWebsiteBeingRemoved = (domain: string) =>
      removingWebsites.value.includes(domain);

    const isWebsiteBeingSetup = (domain: string) =>
      websitesInSetup.value.includes(domain);

    const fetchWebsitesList = async ({
      page = 1,
      perPage = 5,
      search = '',
      filterOwnership = Website.Filter.ALL,
      filterFavourite = false,
    } = {}) => {
      isWebsitesListLoading.value = true;
      const [res, err] = await websiteRepo.getProList({
        page,
        search,
        perPage,
        filterOwnership,
        filterFavourite,
      });

      if (!err) {
        websites.value = res.data.data;

        findAndLogEmptyResource(res.data.data);
        totalWebsites.value = res.data.total;
      }

      isWebsitesListLoading.value = false;

      return [
        {
          data: res?.data?.data,
          total: res?.data?.total,
        },
        err,
      ];
    };

    const fetchOneWebsite = async () => {
      const [res, err] = await websiteRepo.getProList({
        perPage: 1,
      });

      if (!err) {
        hasAtLeastOneWebsite.value = res.data.data.length > 0;
      }
    };

    const fetchOrGetWebsiteByDomain = async (domain: string) => {
      const foundWebsite = websites.value.find(
        ({ website }) => website === domain,
      );

      if (foundWebsite) {
        return foundWebsite;
      }

      const [{ data }, err] = await websiteRepo.getList(1, 100, domain);

      if (err) {
        return null;
      }

      return data.data.find((website) => website.website === domain);
    };

    const getIsOwnedWebsite = (clientId: string) =>
      clientId === profileStore.account?.id;

    let isSubscriptionSearchFailureLogged = false;
    const findWebsiteSubscription = (orderId: string, clientId: string) => {
      // The subscription data is only returned for owned websites
      if (!getIsOwnedWebsite(clientId)) {
        return {};
      }

      const subscriptionInfo = getSubscriptionByOrderId(orderId);
      if (!subscriptionInfo.resource) {
        if (!isSubscriptionSearchFailureLogged) {
          errorLogger.logError(`No resource found for orderId ${orderId}`);
          isSubscriptionSearchFailureLogged = true;
        }

        return {};
      }
      const {
        status,
        id,
        resource: { expiresAt, reason, suspendedAt },
      } = subscriptionInfo;

      return {
        status,
        id,
        expiresAt,
        reason,
        suspendAt: suspendedAt,
      };
    };

    const findAndLogEmptyResource = (websites: ProWebsiteBase[]) => {
      const websitesMissingResource = websites
        .filter(
          ({ type, resource }) =>
            type === Website.Type.BUILDER && isEmptyArray(resource),
        )
        .map(({ website }) => website);

      if (!websitesMissingResource.length) return;

      errorLogger.logError(
        `List of websites with missing resources: ${websitesMissingResource.join(
          ', ',
        )}`,
      );
    };

    return {
      websites,
      isWebsitesListLoading,
      websitesWithSubscriptions,
      totalWebsites,
      hasOwnOrManagedWebsites,
      hasAtLeastOneWebsite,
      wordPressTabItemsPerPage,
      $reset,
      fetchWebsitesList,
      fetchOrGetWebsiteByDomain,
      fetchOneWebsite,
      addRemovingWebsite,
      removeWebsite,
      addWebsitesInSetup,
      isWebsiteBeingRemoved,
      isWebsiteBeingSetup,
      cleanWebsitesInSetup,
      wordpressWebsites,
      selectedWebsiteHeaderData,
      builderWebsites,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.WEBSITES },
  },
);
