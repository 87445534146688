import dayjs from 'dayjs';

import hLocalStorage from '@/utils/services/localStorageService';

export default {
  data: () => ({
    defaultStorageKey: 'hiddenUpgradeRecommendations',
    hideNotificationOptions: [
      {
        title: 'Remind me tomorrow',
        duration: 1,
      },
      {
        title: 'Remind me in 3 days',
        duration: 3,
      },
      {
        title: 'Remind me in a week',
        duration: 7,
      },
      {
        title: 'Remind me in 2 weeks',
        duration: 14,
      },
    ],
    isHidden: false,
    showHideOptions: false,
  }),
  methods: {
    closeHideOptions() {
      this.showHideOptions = false;
    },
    hideNotification(order, days, storageKey = this.defaultStorageKey) {
      const secondsInDay = 24 * 60 * 60;
      const id = order?.id;

      let hiddenNotifications = hLocalStorage.getValue(storageKey) || [];
      hiddenNotifications = hiddenNotifications.filter(
        (item) => item.orderId !== id,
      );
      hLocalStorage.setValue(storageKey, [
        ...hiddenNotifications,
        {
          id,
          hiddenUntil: days ? dayjs().unix() + days * secondsInDay : null,
        },
      ]);

      this.isHidden = this.checkIsNotificationHidden(id, storageKey);
    },
    setHideNotificationForId(id, days, storageKey = this.defaultStorageKey) {
      const secondsInDay = 24 * 60 * 60;
      const hideUntilTrackers = hLocalStorage.getValue(storageKey) || [];
      const trackerIndex = hideUntilTrackers.findIndex(
        (item) => item.id === id,
      );

      const newTrackerData = {
        id,
        hiddenUntil: days ? dayjs().unix() + days * secondsInDay : null,
      };

      if (trackerIndex === -1) {
        hideUntilTrackers.push(newTrackerData);
      } else {
        hideUntilTrackers[trackerIndex] = newTrackerData;
      }

      hLocalStorage.setValue(storageKey, hideUntilTrackers);

      this.isHidden = this.checkIsNotificationHidden(id, storageKey);
    },
    checkIsNotificationHidden(id, storageKey = this.defaultStorageKey) {
      const hiddenNotifications = hLocalStorage.getValue(storageKey) || [];

      return !!hiddenNotifications.find?.((item) =>
        item.hiddenUntil
          ? item.id === id && item.hiddenUntil > dayjs().unix()
          : true,
      );
    },
  },
};
