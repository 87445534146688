<script setup lang="ts">
import { useGlobals, useModal } from '@/composables';

type Props = {
  data: {
    hostingerNameservers: string[];
    onConfirmAction: () => void;
  };
};

const { closeModal } = useModal();

const { t } = useGlobals();

const props = defineProps<Props>();
</script>

<template>
  <div>
    <h2 v-trans class="h-mb-8">Connect domain automatically?</h2>
    <p v-trans>
      If you click Connect, the system will change your nameserver to:
    </p>
    <ul>
      <li
        v-for="(nameserver, index) in props.data.hostingerNameservers"
        :key="`hostinger-nameserver-modal-${index}`"
        class="ns"
      >
        {{ nameserver }}
      </li>
    </ul>
    <p class="h-mb-24">
      <HSnackbar class="h-mt-16 h-mb-24" variant="info">
        <Trans>
          It might take up to
          <strong>24 hours</strong>
          for the domain to start working properly
        </Trans>
      </HSnackbar>
    </p>
    <div class="text-right">
      <HButton variant="text" class="h-mr-16" @click="closeModal">
        {{ t('Cancel') }}
      </HButton>
      <HButton @click="props.data.onConfirmAction">
        {{ t('Connect') }}
      </HButton>
    </div>
  </div>
</template>
