export default {
  methods: {
    filterSectionByRelease(sections) {
      if (!sections?.length) return [];

      const emailSection = sections.find(({ slug }) => slug === 'emails');

      const overridingSections = ['flock_management'];

      const overridingSection = emailSection?.children?.find(({ url }) =>
        overridingSections.includes(url),
      );

      if (overridingSection) {
        emailSection.children = [overridingSection];

        return sections;
      }

      if (!emailSection?.children?.length) {
        return sections.filter(({ slug }) => slug !== 'emails');
      }

      return sections;
    },
  },
};
