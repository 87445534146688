import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'emails',
    name: Route.HostingEmails.EMAILS,
    meta: {
      title: 'Emails',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#emails' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'email-manage',
        name: Route.HostingEmails.MANAGE,
        meta: {
          title: 'Go to control panel',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingEmails.EMAILS,
          ],
          ...sideMenu,
        },
        redirect: '/404',
      },
      {
        path: 'gsuite',
        name: Route.HostingEmails.GSUITE,
        meta: {
          title: 'GSuite',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingEmails.EMAILS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Emails/Gsuite.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
