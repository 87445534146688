<script setup lang="ts">
import { computed, ref } from 'vue';

import HotSpot from '@/components/HotSpot/HotSpot.vue';
import { useGlobals } from '@/composables';
import { hVpsRepo } from '@/repositories';
import { useHotspotStore, useVpsServerStore } from '@/stores';
import { HIcon, HVps, AmplitudeEvent } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

const { t, amplitudeV2 } = useGlobals();
const hotspotStore = useHotspotStore();
const serverStore = useVpsServerStore();
const isLoading = ref(false);

const redirectToVncTerminal = async () => {
  amplitudeV2(AmplitudeEvent.Vps.TERMINAL_ENTERED);

  isLoading.value = true;
  const [{ data }, error] = await hVpsRepo.getVncLink(serverId.value);
  isLoading.value = false;

  if (error) {
    errorLogger.logError('Redirect to vnc terminal failed');

    return;
  }

  // @ts-ignore
  window.open(data.url, '_blank');
};

const serverId = computed(() => serverStore.managedId || 0);

const canAccessVncTerminal = computed(() => {
  const server = serverStore.currentServer;

  if (server?.actionsLock === HVps.ServerLock.LOCKED) {
    return false;
  }

  return server?.state === HVps.ServerState.RUNNING;
});
</script>

<template>
  <HotSpot v-bind="hotspotStore.hotspots.vcTerminalHotspot">
    <HButton
      v-qa-generate
      :icon-append="HIcon.ICON_LAUNCH"
      variant="outline"
      :is-loading="isLoading"
      :is-disabled="!canAccessVncTerminal"
      @click="redirectToVncTerminal"
    >
      {{ t('Browser terminal') }}
    </HButton>
  </HotSpot>
</template>
