import type { NavigationGuard } from 'vue-router';

import store from '@/store';

export const proTitanDomainSelectGuard: NavigationGuard = async (
  to,
  from,
  next,
) => {
  if (!store.state.emails.loaded) {
    await store.dispatch('emails/fetchEmails');
  }

  next();
};
