import type {
  IHostingAccount,
  IPhpInfo,
  IPhpDetails,
  IPhpVersionRequest,
  IPhpExtensionRequest,
  IPhpOptionsRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/php`,

  async getPhpInfo(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync<IPhpInfo>(
      http.get(`${this.url(accountIdentifier)}/php-info`),
    );
  },

  async getPhpDetails(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync<IPhpDetails>(
      http.get(`${this.url(accountIdentifier)}/details`),
    );
  },

  async updatePhpVersion(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    versionData: IPhpVersionRequest,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/version`, versionData, {
        plain: true,
      }),
    );
  },

  async updatePhpExtensions(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    extensions: IPhpExtensionRequest,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/extensions`, extensions),
    );
  },

  async resetPhpExtensions(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/extensions/reset`),
    );
  },

  async updatePhpOptions(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    options: IPhpOptionsRequest,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/options`, options),
    );
  },
};
