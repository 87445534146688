<script lang="ts" setup>
import { useGlobals } from '@/composables';

const HOW_TO_SHARE_ACCESS_SUPPORT =
  'https://support.hostinger.com/articles/1583777';

const { t } = useGlobals();
</script>

<template>
  <div class="no-access">
    <p class="no-access__error-message text-dark">
      {{ t('v2.no.purchase.access.error') }}
    </p>
    <HHyperlink
      class="h-ml-4"
      :href="HOW_TO_SHARE_ACCESS_SUPPORT"
      variant="bold"
      target="_blank"
    >
      {{ t('v2.learn.more') }}
    </HHyperlink>
  </div>
</template>

<style lang="scss" scoped>
.no-access {
  &__error-message {
    display: inline;
  }
}
</style>
