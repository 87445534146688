import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useDomain } from '@/composables';
import { whMalwareRepo } from '@/repositories';
import {
  Status,
  type IMalwareCleanupStatus,
  type IMalwareFiles,
  type IMalwareFilesRequest,
  type IMalwareMetrics,
  type WebsiteMalware,
} from '@/types';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';
import hLocalStorage from '@/utils/services/localStorageService';

export const useMalwareScannerStore = defineStore('malwareScannerStore', () => {
  const { domainEncoded } = useDomain();

  const malwareOverview = ref<WebsiteMalware>();
  const malwareMetrics = ref<IMalwareMetrics>();
  const malwareList = ref<IMalwareFiles>();
  const malwareCleanupStatusList = ref<IMalwareCleanupStatus>();

  const isLoadingMalwareOverview = ref(true);
  const isLoadingMalwareMetrics = ref(true);
  const isLoadingMalwareList = ref(true);
  const isLoadingMalwareCleanupStatusList = ref(true);

  const malwareCleanupStatus = computed(() => {
    const status = malwareCleanupStatusList.value?.resources?.find(
      (el) => el.domain === domainEncoded.value,
    );
    const statusCookie = getMalwareCleanupPendingStatusCookie();

    if (!status && getMalwareCleanupPendingStatusCookie()) {
      return {
        domain: domainEncoded.value,
        status: statusCookie,
      };
    }

    return status;
  });

  const isMalwareCleanupPending = computed(() => {
    const status = malwareCleanupStatus.value?.status;

    return status === Status.Requested || status === Status.InProgress;
  });

  const isMalwareCleanupCompleted = computed(() => {
    const status = malwareCleanupStatus.value?.status;

    return status === Status.Completed;
  });

  const fetchMalwareOverview = async () => {
    isLoadingMalwareOverview.value = true;
    const [{ data }, err] = await whMalwareRepo.getMalwareOverview(
      getCurrentOrderDetails(),
    );

    if (data && !err) {
      malwareOverview.value = data;
    }
    isLoadingMalwareOverview.value = false;
  };

  const fetchMalwareMetrics = async () => {
    isLoadingMalwareMetrics.value = true;
    const [{ data }, err] = await whMalwareRepo.getMalwareMetrics(
      getCurrentOrderDetails(),
    );

    if (data && !err) {
      malwareMetrics.value = data;
    }
    isLoadingMalwareMetrics.value = false;
  };

  const fetchMalwareList = async (params: IMalwareFilesRequest) => {
    isLoadingMalwareList.value = true;
    const [{ data }, err] = await whMalwareRepo.getMalwareList(
      getCurrentOrderDetails(),
      params,
    );

    if (data && !err) {
      malwareList.value = data;

      removeMalwareCleanupPendingStatusCookie();
    }
    isLoadingMalwareList.value = false;
  };

  const fetchMalwareCleanupStatus = async (domain: string) => {
    isLoadingMalwareCleanupStatusList.value = true;
    const [{ data }, err] = await whMalwareRepo.getMalwareCleanupStatus(
      getCurrentOrderDetails(),
      domain,
    );
    if (data && !err) {
      malwareCleanupStatusList.value = data;
    }
    isLoadingMalwareCleanupStatusList.value = false;
  };

  const setMalwareCleanupPendingStatusCookie = async () => {
    malwareCleanupStatusList.value?.resources?.unshift({
      domain: domainEncoded.value,
      status: Status.Requested,
    });
    const expiresAt = dayjs().unix() + 24 * 60 * 60; // 1 days from now
    hLocalStorage.setValue(domainEncoded.value, Status.Requested, expiresAt);
  };

  const getMalwareCleanupPendingStatusCookie = () =>
    hLocalStorage.getValue(domainEncoded.value);

  const removeMalwareCleanupPendingStatusCookie = () => {
    const pendingCookieStatus = getMalwareCleanupPendingStatusCookie();
    if (pendingCookieStatus && isMalwareCleanupCompleted.value) {
      hLocalStorage.deleteValue(domainEncoded.value);
    }
  };

  const $reset = () => {
    malwareOverview.value = undefined;
    malwareMetrics.value = undefined;
    malwareList.value = undefined;
    malwareCleanupStatusList.value = undefined;
    isLoadingMalwareOverview.value = false;
    isLoadingMalwareMetrics.value = false;
    isLoadingMalwareList.value = false;
    isLoadingMalwareCleanupStatusList.value = false;
  };

  return {
    fetchMalwareOverview,
    fetchMalwareMetrics,
    fetchMalwareList,
    fetchMalwareCleanupStatus,
    malwareOverview,
    isLoadingMalwareOverview,
    malwareMetrics,
    isLoadingMalwareMetrics,
    malwareList,
    isLoadingMalwareList,
    malwareCleanupStatus,
    isMalwareCleanupPending,
    isMalwareCleanupCompleted,
    isLoadingMalwareCleanupStatusList,
    setMalwareCleanupPendingStatusCookie,
    getMalwareCleanupPendingStatusCookie,
    removeMalwareCleanupPendingStatusCookie,
    $reset,
  };
});
