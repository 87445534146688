import { whPasswordProtectedDirectoriesRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  protectedDirs: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingOtherPasswordProtectedDirectoriesProtectedDirs(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'protectedDirs', data);
    },
    setHostingOtherPasswordProtectedDirectoriesLoaded(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingOtherPasswordProtectedDirectoriesProtectedDirs: (state) =>
      getStateVariable(state, 'protectedDirs'),
    getHostingOtherPasswordProtectedDirectoriesLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingOtherPasswordProtectedDirectoriesIndex({ commit }) {
      const [{ data, requestDomain }, error] =
        await whPasswordProtectedDirectoriesRepo.getPasswordProtectedDirectories(
          getCurrentOrderDetails(),
        );

      if (!error) {
        commit('setHostingOtherPasswordProtectedDirectoriesProtectedDirs', {
          data,
          requestDomain,
        });
        commit('setHostingOtherPasswordProtectedDirectoriesLoaded', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingOtherPasswordProtectedDirectoriesStore({ dispatch }, payload) {
      const [{ data }, error] =
        await whPasswordProtectedDirectoriesRepo.postPasswordProtectedDirectories(
          getCurrentOrderDetails(),
          {
            directory: payload.directory,
            password: payload.pass,
            user: payload.user,
          },
        );

      if (!error) {
        dispatch('hostingOtherPasswordProtectedDirectoriesIndex');
      }

      return [{ data }, error];
    },
    async hostingOtherPasswordProtectedDirectoriesDestroy({ dispatch }, pwKey) {
      const [{ data }, error] =
        await whPasswordProtectedDirectoriesRepo.deletePasswordProtectedDirectories(
          getCurrentOrderDetails(),
          pwKey,
        );

      if (!error) {
        dispatch('hostingOtherPasswordProtectedDirectoriesIndex');
      }

      return [{ data }, error];
    },
  },
};
