import type {
  IntercomChatbotProxyMetadata,
  IntercomChatbotEnabled,
  IntercomTicket,
  IntercomTicketFieldData,
} from '@/types/models/intercomChatbotProxyModels';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_INTERCOM_CHATBOT_PROXY_URL}/api`;

export default {
  async initializeChatbotMetadata(metadata: IntercomChatbotProxyMetadata) {
    return await hAsync<void>(
      http.post(`${URL}/metadata`, {
        metadata,
      }),
    );
  },
  async getChatbotEnabledFlag() {
    return await hAsync<IntercomChatbotEnabled>(
      http.get(`${URL}/chatbot-enabled`),
    );
  },
  async getTickets() {
    return await hAsync<IntercomTicket[]>(http.get(`${URL}/tickets`));
  },
  async getTicketFields(ticketTypeId: number) {
    return await hAsync<IntercomTicketFieldData>(
      http.get(`${URL}/tickets/fields`, {
        params: {
          ticketTypeId,
        },
      }),
    );
  },
  async createTicket(
    ticketTypeId: number,
    orderId: string,
    ticketAttributes: Record<string, any>,
    domainName: string | null,
  ) {
    return await hAsync(
      http.post(`${URL}/tickets`, {
        ticketTypeId,
        orderId,
        domainName,
        ticketAttributes,
      }),
    );
  },
};
