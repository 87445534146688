import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useCookies, useGlobals } from '@/composables';
import { h5gOrdersRepo, h5gWordPressRepo } from '@/repositories';
import { useOnboardingStore, useResourcesStore } from '@/stores';
import type { IH5GSetupDuplication } from '@/types';
import {
  CREATE_OR_MIGRATE,
  Cookie,
  DATA_CENTER_NAMES,
  H5G_ONBOARDING_STEPS,
  ONBOARDING_DOMAIN_TAG,
  Route,
  Status,
} from '@/types';
import { toASCII, generatePassword } from '@/utils/helpers';

export const useH5GOnboardingStore = defineStore('h5gOnboardingStore', () => {
  const store = useStore();
  const router = useRouter();
  const { toastr, t } = useGlobals();
  const { setCookies } = useCookies(Cookie.H5G_ONBOARDING_CLOSED);
  const resourcesStore = useResourcesStore();
  const onboardingStore = useOnboardingStore();

  const setupUuid = ref('');
  const setupStatus = ref('');
  const websiteUid = ref<string | null>('');
  const datacenter = ref(
    process.env.VITE_MODE === 'staging'
      ? DATA_CENTER_NAMES.UKFAST
      : DATA_CENTER_NAMES.PHOENIX,
  );
  const availableDuplications = ref<IH5GSetupDuplication[]>();

  const isCopyWebsiteFlow = computed<boolean>(
    () =>
      onboardingStore.state[H5G_ONBOARDING_STEPS.H5G_CHOOSE_ACTION] ===
      CREATE_OR_MIGRATE.COPY,
  );

  const pendingResourceReferenceIds = computed(() =>
    pendingResources.value.map(({ referenceId }) => referenceId),
  );

  const pendingResources = computed(() =>
    resourcesStore.h5gResources.filter(
      (resource) => resource.state === Status.PendingSetup,
    ),
  );

  const handleSetupFinished = async (
    isCompleted: boolean,
    websiteUid: string | null,
  ) => {
    if (!websiteUid || !isCompleted) {
      onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);
      await router.push({ name: Route.Base.HOME });
      resetOnboardingStores();

      return;
    }

    if (isCopyWebsiteFlow.value) {
      onboardingStore.goToStep(H5G_ONBOARDING_STEPS.H5G_CHOOSE_WEBSITE);

      return;
    }
    onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);
    await router.push({
      name: Route.H5G.DASHBOARD,
      params: { websiteUid },
    });
    resetOnboardingStores();
  };

  const getSetupStatus = async (orderId: string, setupUuid: string) => {
    const COUNTER_THRESHOLD = 100;
    let counter = 0;

    const fetchSetupStatus = async () => {
      const [{ data }, error] = await h5gOrdersRepo.getSetupStatus(
        orderId,
        setupUuid,
      );

      if (error) {
        clearTimeout(timeout);
        toastr.e(t('Failed to get setup status'));
        onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);
        router.push({ name: Route.Base.HOME });

        return;
      }
      counter++;
      setupStatus.value = data.status;
      websiteUid.value = data.websiteUid;

      const isTimeout = counter >= COUNTER_THRESHOLD;
      const isCompleted = data.status === Status.Completed;

      if (isTimeout || isCompleted) {
        clearInterval(timeout);
        setCookies(Cookie.H5G_ONBOARDING_CLOSED, 'true');
        handleSetupFinished(isCompleted, data.websiteUid);
      }
    };

    const timeout = setInterval(await fetchSetupStatus, 1000);
  };

  const getOnboardingSetupDetails = () => {
    const wordpressTempData = {
      language: 'en_US',
      email: store.getters.getCmsCredentials.email,
      password: generatePassword(),
    };

    const domain =
      onboardingStore.state.domain?.tag === ONBOARDING_DOMAIN_TAG.TEMPORARY ||
      !onboardingStore.state.domain?.domain
        ? null
        : toASCII(onboardingStore.state.domain?.domain);

    return {
      name: '',
      password: '',
      domain,
      datacenterCode: datacenter.value,
      flavor: 'wp-6.6.2',
      settings: {
        php: {
          version: '8.1',
        },
      },
      wordpress: {
        title: 'WordPress',
        language:
          onboardingStore.state.wordpressAdminSetup?.language ||
          wordpressTempData.language,
        admin: {
          user: 'admin',
          password:
            onboardingStore.state.wordpressAdminSetup?.password ||
            wordpressTempData.password,
          email:
            onboardingStore.state.wordpressAdminSetup?.email ||
            wordpressTempData.email,
        },
      },
    };
  };

  const setupWebsite = async (orderId: string) => {
    const [{ data }, error] = await h5gOrdersRepo.postSetup(
      orderId,
      getOnboardingSetupDetails(),
    );
    if (error) {
      onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);
      toastr.e(t('Failed to setup website'));
      router.push({ name: Route.Base.HOME });

      return;
    }

    setupUuid.value = data;
    getSetupStatus(orderId, data);
  };

  const copyWebsite = async () => {
    if (!websiteUid.value) {
      return;
    }

    const [, error] = await h5gWordPressRepo.postDuplication(
      websiteUid.value,
      onboardingStore.state[H5G_ONBOARDING_STEPS.H5G_CHOOSE_WEBSITE],
    );

    if (error) {
      toastr.e(t('Failed to copy website'));
      onboardingStore.goToStep(H5G_ONBOARDING_STEPS.H5G_CHOOSE_WEBSITE);

      return;
    }
  };

  const getAvailableDuplications = async () => {
    const [{ data }, error] = await h5gWordPressRepo.getAvailableDuplications();

    if (error) {
      return;
    }

    availableDuplications.value = data;
  };

  const resetOnboardingStores = () => {
    onboardingStore.$reset();
    $reset();
  };

  const $reset = () => {
    setupUuid.value = '';
    setupStatus.value = '';
    websiteUid.value = '';
  };

  return {
    setupStatus,
    setupUuid,
    websiteUid,
    datacenter,
    pendingResources,
    pendingResourceReferenceIds,
    isCopyWebsiteFlow,
    availableDuplications,
    getSetupStatus,
    setupWebsite,
    copyWebsite,
    getAvailableDuplications,
    $reset,
  };
});
