import { gitRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

export default {
  state: {
    initial: {
      repositories: [],
      sshKey: null,
      deployShow: {},
      loaded: false,
      output: {},
    },
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_GIT_REPOSITORIES(state, repositories) {
      setForDomain(state, null, 'repositories', repositories);
      setForDomain(state, null, 'loaded', true);
    },
    SET_GIT_SSH_KEY(state, key) {
      setForDomain(state, null, 'sshKey', key);
    },
  },
  getters: {
    getGitState: (state) => getStateVariable(state),
  },
  actions: {
    async fetchGitRepositories({ commit }) {
      const [{ data }, err] = await gitRepo.fetchRepositories();
      commit('SET_GIT_REPOSITORIES', !err ? data : []);
    },
    async addGitRepository({ dispatch }, payload) {
      const [{ data }, err] = await gitRepo.addRepository(payload);
      if (!err) await dispatch('fetchGitRepositories');

      return [{ data }, err];
    },
    async removeGitRepository({ dispatch }, id) {
      const [{ data }, err] = await gitRepo.removeRepository(id);
      if (!err) await dispatch('fetchGitRepositories');

      return [{ data }, err];
    },
    async gitDeploy({ dispatch }, id) {
      const [{ data }, err] = await gitRepo.deploy(id);
      if (!err) dispatch('fetchGitRepositories');

      return [{ data }, err];
    },
  },
};
