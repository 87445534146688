import { NON_UPGRADABLE_HOSTING_PLANS } from '@/data/globalConstants';
import type {
  Hosting,
  BillingSubscription,
  HResourceState,
  HResourceType,
  IHResource,
} from '@/types';
import { HRESOURCES_STATE, HRESOURCES_TYPE } from '@/types';

export const mapResourcesToSubscriptions = ({
  resources,
  subscriptions,
}: {
  resources: IHResource[];
  subscriptions: BillingSubscription[];
}) =>
  resources.flatMap((resource) => {
    const subscription =
      subscriptions.find(
        (subscription) => subscription.id === resource.chargebeeSubscriptionId,
      ) ?? ({} as BillingSubscription);

    return {
      ...resource,
      subscription,
    };
  });

export type ResourcesWithSubscriptions = ReturnType<
  typeof mapResourcesToSubscriptions
>;
export type ResourceWithSubscription = ResourcesWithSubscriptions[0];

export const filterPendingResources = (resource: ResourceWithSubscription) => {
  // google_workspace is a special case, it's state is ACTIVATING
  if (
    resource.state === HRESOURCES_STATE.ACTIVATING &&
    resource.type === HRESOURCES_TYPE.GOOGLE_WORKSPACE
  ) {
    return true;
  }

  return (
    resource.state === HRESOURCES_STATE.PENDING &&
    !(
      [HRESOURCES_TYPE.SSL, HRESOURCES_TYPE.FREE_DOMAIN] as HResourceType[]
    ).includes(resource.type)
  );
};

export const filterResourceByStates = (
  resource: ResourceWithSubscription,
  states: HResourceState[],
) => states.includes(resource.state);

export const filterUpgradableHostingResources = (
  resource: ResourceWithSubscription,
) =>
  resource.type === HRESOURCES_TYPE.HOSTING &&
  resource.state === HRESOURCES_STATE.ACTIVE &&
  !NON_UPGRADABLE_HOSTING_PLANS.includes(
    resource.subscription.cfSubscriptionParams?.plan as Hosting.Plan,
  );

export const filterNonWebsiteBuilderHostingResources = (
  resource: ResourceWithSubscription,
  accounts: { referenceId: number; isWebsiteBuilder: boolean }[],
) => {
  const resourceAccounts = accounts.filter(
    (account) => account.referenceId === Number(resource.referenceId),
  );

  const everyAccountIsWebsiteBuilder = resourceAccounts.every(
    (account) => account.isWebsiteBuilder,
  );

  return !everyAccountIsWebsiteBuilder;
};
