import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import WordpressDetectionModal from '@/components/Modals/HModalFancy/WordpressDetectionModal.vue';
import { useModal } from '@/composables';
import { wordpressRepo, domainsRepo, sslRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { useWordpressStore } from '@/stores/wordpressStore';
import { Header } from '@/types';
import { toASCII, mapSelected } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const initial = {
  wpInstallations: [],
  wpDomains: [],
  selectedWPInstallation: '',
  loaded: null,
  lastUpdate: null,
};

const initialWpInstallation = {
  key: '',
  coreDetails: {},
  plugins: [],
  pluginsLoading: false,
  themes: [],
  themesLoading: false,
  statusChanging: false,
  upsells: [],
  wpVersion: null,
  wpUpdates: [],
  maintenanceMode: null,
  objectCache: null,
  liteSpeedInstalled: null,
  phpVersion: null,
  forceHttps: null,
  lastUpdate: null,
  updateStatus: '',
  wpVersionLoaded: null,
  wpVersionUpdateLoaded: null,
};

export default {
  state: {
    initial,
    data: [],
    wpDetection: {},
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingWordpressAppCoreDetails(state, { data, requestDomain }) {
      const wpInstallationsForDomain = [];

      const existingInstallations = getStateVariable(state, 'wpInstallations');

      data.forEach((app) => {
        const appKey = app.domain + app.directory;

        const wpInstallation = existingInstallations.find(
          ({ key }) => appKey === key,
        );

        if (wpInstallation) {
          wpInstallation.coreDetails = app;
          wpInstallationsForDomain.push(wpInstallation);
        } else {
          const installationObject = cloneDeep(initialWpInstallation);

          installationObject.key = appKey;
          installationObject.coreDetails = app;

          wpInstallationsForDomain.push(installationObject);
        }
      });

      setForDomain(
        state,
        requestDomain,
        'wpInstallations',
        wpInstallationsForDomain,
      );
      setForDomain(state, requestDomain, 'loaded', true);
    },
    setHostingWordpressInstallationValidity(
      state,
      { wpInstallation, valid, errorMessage },
    ) {
      try {
        wpInstallation.coreDetails.isValid = valid;
        wpInstallation.coreDetails.validationError = errorMessage;
      } catch (e) {
        // Do nothing if fails
      }
    },
    SET_HOSTING_WORDPRESS_SELECTED_DIR(state, appKey) {
      setForDomain(state, null, 'selectedWPInstallation', appKey);
    },
    SET_HOSTING_WORDPRESS_UPSELLS(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'upsells', data);
    },
    SET_HOSTING_WORDPRESS_WP_VERSION(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'wpVersion', data);
    },
    SET_HOSTING_WORDPRESS_VULNERABILITIES(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'wpVulnerabilities', data);
    },

    SET_HOSTING_WORDPRESS_WP_UPDATES(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'wpUpdates', data);
    },
    SET_HOSTING_WORDPRESS_PHP_VERSION(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'phpVersion', data);
    },
    SET_HOSTING_WORDPRESS_MAINTENANCE_MODE(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'maintenanceMode', data);
    },
    SET_HOSTING_WORDPRESS_FORCE_HTTPS(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'forceHttps', data);
    },
    SET_HOSTING_WORDPRESS_LITE_SPEED_INSTALLED(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'liteSpeedInstalled', data);
    },
    SET_HOSTING_WORDPRESS_OBJECT_CACHE(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'objectCache', data);
    },
    SET_HOSTING_WORDPRESS_PLUGINS(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'plugins', data);
    },
    SET_WORDPRESS_PLUGIN_ACTIVE(state, { name, isActive, wpKey, plugins }) {
      const currentPlugin = plugins.find((plugin) => plugin.name === name);
      if (currentPlugin) {
        currentPlugin.isActive = isActive;
      }
      setDataForWpInstallation(state, wpKey, 'plugins', plugins);
    },
    TOGGLE_WORDPRESS_PLUGIN_LOADING(state, { name, wpKey, plugins }) {
      const currentPlugin = plugins.find((plugin) => plugin.name === name);
      currentPlugin.loading = !currentPlugin.loading;
      setDataForWpInstallation(state, wpKey, 'plugins', plugins);
    },
    SET_WORDPRESS_PLUGINS_LOADING(state, { wpKey, loading }) {
      setDataForWpInstallation(state, wpKey, 'pluginsLoading', loading);
    },
    SET_HOSTING_WORDPRESS_THEMES(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'themes', data);
    },
    SET_WORDPRESS_THEMES_LOADING(state, { wpKey, loading }) {
      setDataForWpInstallation(state, wpKey, 'themesLoading', loading);
    },
    SET_WORDPRESS_UPDATE_STATUS(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'updateStatus', data);
    },
    SET_WP_VERSION_LOADED(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'wpVersionLoaded', data);
    },
    SET_WP_UPDATE_VERSION_LOADED(state, { data, wpKey }) {
      setDataForWpInstallation(state, wpKey, 'wpVersionUpdateLoaded', data);
    },
    SET_WP_DETECTION(state, { endTime, domain }) {
      state.wpDetection = { ...state.wpDetection, [domain]: endTime };
    },
    SET_WP_DOMAINS(state, { domains }) {
      setForDomain(state, null, 'wpDomains', domains);
    },
    SET_WP_VHOST_LIST(state, { domains }) {
      setForDomain(state, null, 'wpVhostList', domains);
    },
    SET_WP_STATUS_CHANGING(state, status) {
      setForDomain(state, null, 'statusChanging', status);
    },
  },
  getters: {
    getHostingWordpressSelectedAppData: (state, getters) => {
      const selectedAppKey = getters.getHostingWordpressSelectedApp;
      const wpInstallations = getters.getHostingWordpressWpApps;

      return wpInstallations.find(({ key }) => key === selectedAppKey) || {};
    },
    getHostingWordpressSelectedIsInstalled: (state, getters) => {
      const selectedApp = getters.getHostingWordpressSelectedAppData;

      return !!Object.keys(selectedApp).length;
    },
    getHostingWordpressSelectedAppValid: () => {
      const wordPressStore = useWordpressStore();

      return wordPressStore.isWordPressVersionValid;
    },
    getHostingWordpressSelectedApp: (state) =>
      getStateVariable(state, 'selectedWPInstallation'),
    getHostingWordpressWpApps: (state) =>
      getStateVariable(state, 'wpInstallations'),
    getHostingWordpressDirectories:
      (_, { getHostingWordpressWpApps }) =>
      ({ domain }) =>
        getHostingWordpressWpApps
          ?.map((app) => app.coreDetails)
          .filter(
            (coreDetails) =>
              !!coreDetails.directory && coreDetails.domain === domain,
          )
          .map(({ directory, domain }) => {
            const value = `${domain}/${directory}`;

            return mapSelected(value);
          }),
    getHostingWordpressLoaded: (state) => getStateVariable(state, 'loaded'),
    getWordpressPluginsLoading: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'pluginsLoading',
      ),
    getWordpressThemesLoading: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'themesLoading',
      ),
    getHostingWordpressWpVersion: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpVersion',
      ),
    getHostingWordpressVulnerabilities: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpVulnerabilities',
      ),
    getHostingWordPressLastMinorUpdate: (state, getters) => {
      const wpUpdates = getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpUpdates',
      );

      if (!wpUpdates) return null;

      const minorUpdates = wpUpdates?.filter(
        (update) => update.updateType === 'minor',
      );
      const lastMinorUpdate = minorUpdates[minorUpdates.length - 1];

      return lastMinorUpdate ? lastMinorUpdate.version : null;
    },
    getHostingWordpressWpUpdateVersion: (state, getters) => {
      const data = getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpUpdates',
      );

      if (!data) return null;

      const lastItem = [...data].pop();

      return lastItem?.version;
    },

    getHostingWordpressWpUpsell: (state, getters) => (upsellId) => {
      const upsells = getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'upsells',
      );

      return (upsells || []).find(({ title }) => title === upsellId);
    },
    getHostingWordpressPhpVersion: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'phpVersion',
      ),
    getHostingWordpressMaintenanceMode: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'maintenanceMode',
      ),
    getHostingWordpressForceHttps: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'forceHttps',
      ),
    getHostingWordpressLiteSpeedInstalled: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'liteSpeedInstalled',
      ),
    getHostingWordpressObjectCache: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'objectCache',
      ),
    getHostingWordpressPlugins: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'plugins',
      ),
    getWordpressPlugin: (state, getters) => (name) => {
      const plugins = getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'plugins',
      );

      return (plugins || []).find((plugin) => plugin.name === name);
    },
    getHostingWordpressThemes: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'themes',
      ),
    getWordpressUpdateStatus: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'updateStatus',
      ),
    getWordpressVersionLoaded: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpVersionLoaded',
      ),
    getWordpressVersionUpdateLoaded: (state, getters) =>
      getDataForWpInstallation(
        state,
        getters.getHostingWordpressSelectedApp,
        'wpVersionUpdateLoaded',
      ),
    getHostingWordpressDomains: (state) => getStateVariable(state, 'wpDomains'),
    getHostingWordpressVhostList: (state) =>
      getStateVariable(state, 'wpVhostList'),
    getWordpressStatusChanging: (state) =>
      getStateVariable(state, 'statusChanging'),
  },
  actions: {
    async detectWp({}, { domain }) {
      const { openModal } = useModal();

      const [{ data }, err] = await wordpressRepo.postWordpress({
        domain: toASCII(domain),
        command: 'detect_run',
        params: {},
      });

      if (err) {
        errorLogger.logError(
          new Error('WordPress command detect_run failed', {
            cause: err,
          }),
        );
      }

      if (data) {
        openModal({
          component: { WordpressDetectionModal },
        });
      }
    },

    installHostingerPlugin({ getters }, pluginName) {
      const {
        coreDetails: { directory },
      } = getters.getHostingWordpressSelectedAppData;

      const wordPressStore = useWordpressStore();

      return wordpressRepo.installHostingerPlugin(
        directory,
        pluginName,
        wordPressStore.wordPressSubdomainConfig,
      );
    },
    installPlugins({ getters }, name) {
      const wordPressStore = useWordpressStore();

      return wordpressRepo.postWordpress(
        {
          command: 'plugin_install',
          params: {
            directory:
              getters.getHostingWordpressSelectedAppData.coreDetails.directory,
            plugins: [name],
          },
        },
        wordPressStore.wordPressSubdomainConfig,
      );
    },
    async wordpressPluginsToggleInBulk({ getters }, { plugins, isActive }) {
      const wpInstallation = getters.getHostingWordpressSelectedAppData;
      try {
        await wpCall(
          wpInstallation,
          isActive ? 'plugin_deactivate' : 'plugin_activate',
          { plugins },
        );
        if (isActive) {
          toastr.s(
            i18n.t('Selected plugin(s) have been activated successfully'),
          );
        }
      } catch {
        toastr.e(i18n.t('Could not activate plugins'));
      }
    },
    async wordpressPluginToggle({ commit, getters }, { name, isActive }) {
      commit('SET_WP_STATUS_CHANGING', true);
      const plugins = getters.getHostingWordpressPlugins;
      const wpInstallation = getters.getHostingWordpressSelectedAppData;
      const wpKey = wpInstallation.key;

      try {
        commit('TOGGLE_WORDPRESS_PLUGIN_LOADING', { name, wpKey, plugins });

        await wpCall(
          wpInstallation,
          isActive ? 'plugin_deactivate' : 'plugin_activate',
          { plugins: [name] },
        );

        toastr.s(
          i18n
            .t(
              `{pluginName} has been ${isActive ? 'deactivated' : 'activated'}`,
              {
                pluginName: name,
              },
            )
            .replace('{pluginName}', name),
        );

        commit('SET_WORDPRESS_PLUGIN_ACTIVE', {
          name,
          isActive: !isActive,
          wpKey,
          plugins,
        });
        commit('TOGGLE_WORDPRESS_PLUGIN_LOADING', { name, wpKey, plugins });
      } catch {
        toastr.e(i18n.t('Could not activate plugin'));
        commit('TOGGLE_WORDPRESS_PLUGIN_LOADING', { name, wpKey, plugins });
      }
      commit('SET_WP_STATUS_CHANGING', false);
    },
    hostingWordpressSetLSActive({ commit, getters }, wpKey) {
      const wpInstallation = getters.getHostingWordpressSelectedAppData;
      const selectedWpKey = wpInstallation.key;
      if (selectedWpKey !== wpKey) return;

      const plugins = getters.getHostingWordpressPlugins;

      commit('SET_WORDPRESS_PLUGIN_ACTIVE', {
        name: 'litespeed-cache',
        isActive: true,
        wpKey,
        plugins,
      });
    },
    async hostingWordpressCoreDetailsIndex({ commit }) {
      const [{ data, requestDomain }, error] =
        await wordpressRepo.postWordpress({
          command: 'core_list',
          params: {},
        });

      if (error) {
        errorLogger.logError(
          new Error('WordPress command core_list failed', {
            cause: error,
          }),
        );
      }
      if (data) {
        commit('setHostingWordpressAppCoreDetails', {
          data,
          requestDomain,
        });
      }

      return [{ data, requestDomain }, error];
    },
    async hostingWordpressStagingCoreDetailsIndex(
      context,
      { directory = '', domain },
    ) {
      const [{ data, requestDomain }, error] =
        await wordpressRepo.getStagingList(directory, domain);

      context.commit('setHostingWordpressAppCoreDetails', {
        data,
        requestDomain,
      });

      return [{ data, requestDomain }, error];
    },
    async hostingWordpressDomainsIndex(context, includeWpList) {
      const [{ data }] = await domainsRepo.getDomainsList(includeWpList, true);
      context.commit('SET_WP_DOMAINS', { domains: data });
    },
    async hostingWordpressVhostIndex(context, includeWpList = false) {
      const [{ data }] = await domainsRepo.getDomainsList(includeWpList);
      context.commit('SET_WP_VHOST_LIST', { domains: data });
    },
    async hostingWordpressUpsellsIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      if (
        context.getters.getHostingWordpressSelectedAppValid &&
        wpInstallation?.coreDetails
      ) {
        const { domain, directory } = wpInstallation?.coreDetails;
        const callParams = { domain };

        if (directory) callParams.directory = directory;

        const [{ data }, error] = await wordpressRepo.getUpsells();

        if (data) {
          context.commit('SET_HOSTING_WORDPRESS_UPSELLS', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressWpVersionIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      context.commit('SET_WP_UPDATE_VERSION_LOADED', {
        data: false,
        wpKey: wpInstallation.key,
      });

      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wpCall(
          wpInstallation,
          'core_version',
          null,
        );

        if (data) {
          context.commit('SET_WP_VERSION_LOADED', {
            data: true,
            wpKey: wpInstallation.key,
          });

          context.commit('SET_HOSTING_WORDPRESS_WP_VERSION', {
            data: data?.version || data,
            wpKey: wpInstallation.key,
          });
          context.commit('SET_HOSTING_WORDPRESS_VULNERABILITIES', {
            data: data?.vulnerabilities,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressWpUpdateVersionIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      context.commit('SET_WP_UPDATE_VERSION_LOADED', {
        data: false,
        wpKey: wpInstallation.key,
      });

      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wpCall(
          wpInstallation,
          'core_check_update',
          null,
        );

        if (data) {
          context.commit('SET_WP_UPDATE_VERSION_LOADED', {
            data: true,
            wpKey: wpInstallation.key,
          });

          context.commit('SET_HOSTING_WORDPRESS_WP_UPDATES', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressPhpVersionIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wordpressRepo.getWordpressCompatibility(
          {
            domain: wpInstallation?.coreDetails?.domain,
            directory: wpInstallation?.coreDetails?.directory,
          },
          {
            headers: {
              [Header.DOMAIN]: wpInstallation?.coreDetails?.domain,
            },
          },
        );

        if (data) {
          context.commit('SET_HOSTING_WORDPRESS_PHP_VERSION', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressMaintenanceModeIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      const directory = wpInstallation?.coreDetails?.directory;
      const domain = wpInstallation?.coreDetails?.domain;

      if (domain && context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] =
          await wordpressRepo.getWordpressIsMaintenanceEnabled({
            directory,
            domain,
          });

        if (!error) {
          context.commit('SET_HOSTING_WORDPRESS_MAINTENANCE_MODE', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressMaintenanceModeToggle(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      const directory = wpInstallation?.coreDetails?.directory;
      const domain = wpInstallation?.coreDetails?.domain;
      const isMaintenanceEnabled =
        context.getters.getHostingWordpressMaintenanceMode;

      const [, error] =
        await wordpressRepo.postChangeWordpressMaintenanceStatus({
          directory,
          isEnabled: !isMaintenanceEnabled,
          domain,
        });

      if (error) return;

      context.commit('SET_HOSTING_WORDPRESS_MAINTENANCE_MODE', {
        data: !isMaintenanceEnabled,
        wpKey: wpInstallation.key,
      });
      toastr.s(
        i18n.t(
          isMaintenanceEnabled
            ? 'Maintenance mode disabled successfully'
            : 'Maintenance mode enabled successfully',
        ),
      );
    },
    async hostingWordpressForceHttpsIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await sslRepo.getForceHttpsStatus({
          domain: wpInstallation?.coreDetails?.domain,
          directory: wpInstallation?.coreDetails?.directory,
        });

        if (!error) {
          context.commit('SET_HOSTING_WORDPRESS_FORCE_HTTPS', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressLiteSpeedIndex(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wpCall(
          wpInstallation,
          'litespeedCache_is_plugin_enabled',
          null,
        );

        if (data) {
          context.commit('SET_HOSTING_WORDPRESS_LITE_SPEED_INSTALLED', {
            data: data.isInstalled,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }
      }
    },
    async hostingWordpressObjectCacheIndex({ getters, commit }, params) {
      const wpInstallation = getters.getHostingWordpressSelectedAppData;
      if (getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wordpressRepo.getObjectCacheStatus(
          params,
        );

        if (!error) {
          commit('SET_HOSTING_WORDPRESS_OBJECT_CACHE', {
            data,
            wpKey: wpInstallation.key,
          });
        }
      }
    },
    async hostingWordpressObjectCacheStore({ getters, commit }, params) {
      const wpInstallation = getters.getHostingWordpressSelectedAppData;
      if (getters.getHostingWordpressSelectedAppValid) {
        const [, error] = params.status
          ? await wordpressRepo.activateObjectCache(params)
          : await wordpressRepo.deactivateObjectCache(params);

        if (!error) {
          toastr.s(
            i18n.t(
              `Object cache is  ${
                params.status ? 'enabled' : 'disabled'
              } successfully`,
            ),
          );
          commit('SET_HOSTING_WORDPRESS_OBJECT_CACHE', {
            data: params.status,
            wpKey: wpInstallation.key,
          });
        }
      }
    },
    async hostingWordpressPluginsIndex(context, params) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      const wordPressStore = useWordpressStore();

      context.commit('SET_WORDPRESS_PLUGINS_LOADING', {
        wpKey: wpInstallation.key,
        loading: !params?.noLoader,
      });

      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wpCall(
          wpInstallation,
          'plugin_list',
          null,
          wordPressStore.wordPressSubdomainConfig,
        );

        if (data) {
          const plugins = data.map((el) => ({
            ...el,
            loading: false,
            isActive: ['active', 'active-network'].includes(el.status),
          }));

          context.commit('SET_HOSTING_WORDPRESS_PLUGINS', {
            data: plugins,
            wpKey: wpInstallation.key,
          });

          context.commit('SET_WORDPRESS_PLUGINS_LOADING', {
            wpKey: wpInstallation.key,
            loading: false,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);

          context.commit('SET_WORDPRESS_PLUGINS_LOADING', {
            wpKey: wpInstallation.key,
            loading: false,
          });
        }
      }
      context.commit('SET_WORDPRESS_PLUGINS_LOADING', {
        wpKey: wpInstallation.key,
        loading: false,
      });
    },
    async hostingWordpressUpdatePlugin({ commit, dispatch }, params) {
      commit('SET_WP_STATUS_CHANGING', true);
      const [{ data }, err] = await wordpressRepo.postUpdatePlugin(params);

      if (!err) {
        await dispatch('hostingWordpressPluginsIndex', {
          noLoader: true,
        });

        if (params.name === 'litespeed-cache') {
          await dispatch('hostingWordpressLiteSpeedIndex');
        }
      }

      commit('SET_WP_STATUS_CHANGING', false);

      return data;
    },
    async hostingWordpressDeletePlugin(
      { commit, dispatch },
      { directory, domain, name },
    ) {
      commit('SET_WP_STATUS_CHANGING', true);
      const wordPressStore = useWordpressStore();

      const [{ data }, err] = await wordpressRepo.deletePlugin({
        directory,
        domain,
        name,
        config: wordPressStore.wordPressSubdomainConfig,
      });

      if (!err) {
        await dispatch('hostingWordpressPluginsIndex', {
          noLoader: true,
        });

        if (name === 'litespeed-cache') {
          await dispatch('hostingWordpressLiteSpeedIndex');
        }
      }

      commit('SET_WP_STATUS_CHANGING', false);

      return data;
    },
    async hostingWordpressThemesIndex(context, params) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      context.commit('SET_WORDPRESS_THEMES_LOADING', {
        wpKey: wpInstallation.key,
        loading: !params?.noLoader,
      });
      if (context.getters.getHostingWordpressSelectedAppValid) {
        const [{ data }, error] = await wpCall(
          wpInstallation,
          'theme_list',
          null,
        );

        if (data) {
          context.commit('SET_HOSTING_WORDPRESS_THEMES', {
            data,
            wpKey: wpInstallation.key,
          });
        }

        if (error) {
          handleWordPressError(error, context, wpInstallation);
        }

        context.commit('SET_WORDPRESS_THEMES_LOADING', {
          wpKey: wpInstallation.key,
          loading: false,
        });
      }
    },
    hostingWordpressAutologinIndex(
      _,
      { domain, wpDomain, location, directory, isOutsideHosting, options = {} },
    ) {
      if (isOutsideHosting) {
        options.domain = domain;
      }

      const hostingHeaderRequired = !(
        options.headers &&
        options.headers[Header.DOMAIN] &&
        options.headers[Header.USERNAME] &&
        options.headers[Header.ORDER_ID] &&
        options.headers[Header.CLIENT_ID]
      );

      return wordpressRepo.postWordpress(
        {
          domain,
          domain: wpDomain,
          command: 'login_create',
          params: { directory, location },
        },
        options,
        hostingHeaderRequired,
      );
    },
    async hostingWordpressRecheckInstallation(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      const [{ data }, error] = await wpCall(
        wpInstallation,
        'core_check_installation',
        null,
      );

      if (error) {
        handleWordPressError(error, context, wpInstallation);
      }

      return data.valid;
    },

    async hostingWordpressDetect(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      const [{ data }, error] = await wpCall(
        wpInstallation,
        'detect_run',
        null,
      );

      if (data) {
        setTimeout(() => {
          context.dispatch('hostingWordpressCoreDetailsIndex');
        }, 10000);
      }

      if (error) {
        handleWordPressError(error, context, wpInstallation);
      }
    },
    async hostingWordpressCheckUpdateStatus(context) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;
      const domain = wpInstallation?.coreDetails?.domain;

      if (!domain) return;

      const [{ data }, error] = await wordpressRepo.postWordpress({
        command: 'config_get',
        domain,
        params: {
          config: 'WP_AUTO_UPDATE_CORE',
          directory: wpInstallation?.coreDetails?.directory
            ? wpInstallation.coreDetails.directory
            : '/',
        },
      });

      if (error) {
        handleWordPressError(error, context, wpInstallation);
        errorLogger.logError(
          new Error('Check WordPress update status command config_get failed', {
            cause: error,
          }),
        );

        return;
      }

      context.commit('SET_WORDPRESS_UPDATE_STATUS', {
        data,
        wpKey: wpInstallation.key,
      });
    },
    async hostingWordpressChangeUpdateStatus(context, { value }) {
      const wpInstallation = context.getters.getHostingWordpressSelectedAppData;

      const domain = wpInstallation?.coreDetails?.domain;

      const [, error] = await wordpressRepo.postWordpress({
        command: 'config_auto_update',
        domain,
        params: {
          auto_update: value,
          directory: wpInstallation.coreDetails.directory
            ? wpInstallation.coreDetails.directory
            : '/',
        },
      });

      if (error) {
        handleWordPressError(error, context, wpInstallation);
        errorLogger.logError(
          new Error(
            'Change WordPress update status command config_auto_update failed',
            {
              cause: error,
            },
          ),
        );
      }
    },
    async hostingWordpressChangeVersion(
      { getters, commit, dispatch },
      updateVersion,
    ) {
      commit('SET_WP_STATUS_CHANGING', true);
      const wpInstallation = getters.getHostingWordpressSelectedAppData;

      try {
        await wpCall(wpInstallation, 'core_update', {
          version: updateVersion,
        });
        commit('SET_HOSTING_WORDPRESS_WP_VERSION', {
          data: updateVersion,
          wpKey: wpInstallation.key,
        });

        dispatch('hostingWordpressWpVersionIndex');
        dispatch('hostingWordpressWpUpdateVersionIndex');
        toastr.s(i18n.t('WordPress version was successfully updated'));
      } catch {
        toastr.e(i18n.t('Could not update WordPress version'));
      }
      commit('SET_WP_STATUS_CHANGING', false);
    },
    hostingWordpressUpdateSelectedDir(
      { commit, dispatch },
      { value, disableLoad } = {},
    ) {
      const actions = [
        'hostingWordpressUpsellsIndex',
        'hostingWordpressPluginsIndex',
        'hostingWordpressWpVersionIndex',
        'hostingWordpressForceHttpsIndex',
        'hostingWordpressMaintenanceModeIndex',
        'hostingWordpressCheckUpdateStatus',
        'hostingWordpressPhpVersionIndex',
        'hostingWordpressLiteSpeedIndex',
        'hostingWordpressThemesIndex',
        'hostingWordpressWpUpdateVersionIndex',
      ];

      commit('SET_HOSTING_WORDPRESS_SELECTED_DIR', toASCII(value));
      if (value && !disableLoad) {
        actions.forEach((action) => {
          dispatch(action);
        });
      }
    },
    async hostingWordpressUpdateTheme({ commit, dispatch }, params) {
      commit('SET_WP_STATUS_CHANGING', true);
      const [{ data }, err] = await wordpressRepo.updateTheme(params);

      if (!err) {
        await dispatch('hostingWordpressThemesIndex', {
          noLoader: true,
        });
      }

      commit('SET_WP_STATUS_CHANGING', false);

      return data;
    },
    async hostingWordpressDeleteTheme({ commit, dispatch }, params) {
      commit('SET_WP_STATUS_CHANGING', true);
      const [{ data }, err] = await wordpressRepo.deleteTheme(params);

      if (!err) {
        await dispatch('hostingWordpressThemesIndex', {
          noLoader: true,
        });
      }

      commit('SET_WP_STATUS_CHANGING', false);

      return data;
    },
  },
};

const setDataForWpInstallation = (state, wpKey, param, value) => {
  const installations = getStateVariable(state, 'wpInstallations');

  const installation = installations?.find(({ key }) => key === wpKey);

  if (installation) {
    setForDomain(state, null, 'lastUpdate', dayjs());
    installation.lastUpdate = dayjs();
    installation[param] = value;
  }
};

const getDataForWpInstallation = (state, wpKey, param) => {
  const installations = getStateVariable(state, 'wpInstallations');

  const installation = installations?.find(({ key }) => key === wpKey);

  if (installation) {
    return installation[param];
  }

  return null;
};

const hapiMessagePatterns = [
  /[a-zA-Z0-9]{2,4}-[a-zA-Z0-9]{2,4}-[a-zA-Z0-9]{2,6}-[a-zA-Z0-9]{10,20}/gim,
  /\s-\s/gim,
  /lt-office/gim,
  /nl-srv/gim,
  /main-hosting/gim,
];

const hapiErrorFilter = (errorMessage) =>
  hapiMessagePatterns.reduce(
    (acc, hapiMessagePattern) => acc.replace(hapiMessagePattern, ''),
    errorMessage || '',
  );

const handleWordPressError = (e, context, wpInstallation) => {
  const errorMessage = e?.error?.message;

  hapiErrorFilter(errorMessage);

  const cases = [/Your\sWordpress\sinstallation\sis\sinvalid/gim];

  const foundCase = !!cases.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(errorMessage);
  });

  if (foundCase) {
    context.commit('setHostingWordpressInstallationValidity', {
      wpInstallation,
      valid: '0',
      errorMessage,
    });
  }
};

const wpCall = (wpInstallation, command, additionalParams = {}, config = {}) =>
  wordpressRepo.postWordpress(
    {
      command,
      domain: wpInstallation?.coreDetails?.domain,
      params: {
        directory: wpInstallation?.coreDetails?.directory || '',
        ...additionalParams,
      },
    },
    {
      hideToastr: true,
      ...config,
    },
  );
