import { capiRepo } from '@/repositories';
import type { CPanelDomainState, CommitFunction } from '@/types';
import { CpanelStoreEnum } from '@/types';
import type { CPanelDomainModel } from '@/types/models/cpanel/cPanelModels';

const state: CPanelDomainState = {
  loaded: false,
  data: {
    parkedDomains: [],
    addonDomains: [],
    subDomains: [],
    mainDomain: '',
  },
};

const getters = {};

const mutations = {
  [CpanelStoreEnum.Domain.Mutations.SET_DOMAINS](
    state: CPanelDomainState,
    domains: CPanelDomainModel,
  ) {
    state.loaded = true;
    state.data = domains;
  },
};

const actions = {
  async [CpanelStoreEnum.Domain.Actions.FETCH_DOMAINS](
    { commit }: CommitFunction,
    serviceId: string,
  ) {
    const [{ data }, error] = await capiRepo.getDomains(serviceId);
    if (error) {
      return;
    }
    commit(CpanelStoreEnum.Domain.Mutations.SET_DOMAINS, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
