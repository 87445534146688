export namespace BillingOrder {
  export enum Status {
    PENDING_DATA_ENTRY = 'pending_data_entry',
    COMPLETED = 'completed',
  }
  export enum Type {
    SUBSCRIPTION_ORDER = 'subscription_order',
  }
  export enum ServiceName {
    HOSTING = 'hosting',
    DOMAIN = 'domain',
  }
  export enum ItemName {
    SSL = 'SSL',
    DOMAIN = 'Domain',
  }
  export enum Timing {
    REACTIVATION_PERIOD_IN_DAYS = 21,
    RESTORATION_PERIOD_IN_DAYS = 51,
    OLD_BILLING_REACTIVATION_PERIOD_IN_DAYS = 14,
  }
}
