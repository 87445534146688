import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useVps } from '@/composables';
import { hVpsRepo } from '@/repositories';
import type { ServerAction } from '@/types';

export const useVpsActionStore = defineStore('vpsAction', () => {
  const { currentServer } = useVps();

  const actions = ref<Record<string, ServerAction[]>>({});
  const actionsMeta = ref<Record<string, object>>({});

  const setServerActions = (serverId: string, newActions: ServerAction[]) => {
    actions.value = {
      ...actions.value,
      [serverId]: newActions,
    };
  };

  const setServerActionsMeta = (serverId: string, meta: object) => {
    actionsMeta.value = {
      ...actionsMeta.value,
      [serverId]: meta,
    };
  };

  const getServerActions = (serverId?: string) =>
    actions.value[serverId || currentServer.value.id] || [];

  const getServerActionsMeta = (serverId?: string) =>
    actionsMeta.value[serverId || currentServer.value.id] || {};

  const fetchServerActions = async ({
    serverId = '',
    page = 1,
    perPage = 10,
    filterValue = {},
  } = {}) => {
    const pageDefault = page || 1;

    const params = {
      page: pageDefault,
      perPage,
      filterValue,
    };

    const settings = {
      cache: 20,
      plain: true,
    };

    const [{ data, meta }, error] = await hVpsRepo.getActions(
      serverId,
      params,
      settings,
    );

    if (error) return;

    setServerActions(serverId, data);
    setServerActionsMeta(serverId, meta as object);
  };

  return {
    actions,
    actionsMeta,
    getServerActions,
    getServerActionsMeta,
    fetchServerActions,
  };
});
