import { useLanguageStore } from '@/stores';
import { i18n, filterKey } from '@/utils/services/i18nService';

const isTranslatable = (binding) =>
  !binding.value || !(binding.value && binding.value.noTranslate);

const translate = (el, binding, vnode) => {
  if (isTranslatable(binding) && vnode.children) {
    let msgid = vnode.children[0].children;

    if (!msgid) {
      return '';
    }

    msgid = msgid
      .replace(/\r?\n|\r/g, '')
      .replace(/\s{2,}/g, ' ')
      .trim();
    msgid = filterKey(msgid);
    el.dataset.msgid = msgid;

    const params = vnode.props && vnode.props['translate-params'];
    let translated = i18n.t(msgid, params || {});

    const matches = translated.match(/\{(.*?)\}/gim);

    if (matches) {
      matches.forEach((param) => {
        translated = translated?.replace(
          param,
          params?.[param?.replace('{', '')?.replace('}', '')],
        );
      });
    }

    return translated;
  }
};

export default {
  beforeMount(el, binding, vnode) {
    el.innerHTML = translate(el, binding, vnode);

    el.__lang_unwatch__ = useLanguageStore().$subscribe(() => {
      el.innerHTML = translate(el, binding, vnode);
    });
  },

  updated(el, binding, vnode) {
    el.innerHTML = translate(el, binding, vnode);

    if (el.__lang_unwatch__) el.__lang_unwatch__();
    el.__lang_unwatch__ = useLanguageStore().$subscribe(() => {
      el.innerHTML = translate(el, binding, vnode);
    });
  },

  beforeUnmount(el) {
    if (el.__lang_unwatch__) el.__lang_unwatch__();
  },
};
