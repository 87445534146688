import type { ResourceWithSubscription } from './resourcesMapper';
import {
  getMonolithOrderCtaText,
  getMonolithOrderTitle,
} from './resourcesToOrdersMapper';

import {
  type HomepageSection,
  HRESOURCE_ITEM_STATE,
  type IAccessListItem,
} from '@/types';
import {
  ACCESS_LEVEL_TITLE_MAP,
  ACTIVE_STATES_FOR_HOMEPAGE,
  HOMEPAGE_RESOURCE_TO_SECTION_MAP,
  HOMEPAGE_SECTION,
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  LIFETIME_HRESOURCE_TYPES,
  RESOURCE_STATE_TO_MONOLITH_STATUS_MAP,
  RESOURCE_TO_MONOLITH_TYPE_MAP,
} from '@/types';
import { toTitleCase } from '@/utils/helpers';

const MANAGE_ORDERS_THRESHOLD = 5;

const getResourceId = (resource: ResourceWithSubscription) => {
  if (resource.type === 'email') {
    return resource.idempotencyKey;
  }

  if (LIFETIME_HRESOURCE_TYPES.includes(resource.type)) {
    return resource.id;
  }

  return resource.chargebeeSubscriptionId;
};

export const filterActiveResource = (resource: ResourceWithSubscription) => {
  if (
    resource.subscription.canReactivate &&
    resource.type === 'domain' &&
    resource.state !== 'deleted'
  ) {
    return true;
  }

  return ACTIVE_STATES_FOR_HOMEPAGE.includes(resource.state);
};

export const filterCanceledHomepageResources = (
  resource: ResourceWithSubscription,
) => resource.state === HRESOURCES_STATE.DELETED;

export const filterActivatingGoogleWorkspaces = (
  resource: ResourceWithSubscription,
) =>
  !(
    resource.type === HRESOURCES_TYPE.GOOGLE_WORKSPACE &&
    resource.state === HRESOURCES_STATE.ACTIVATING
  );

export const filterActiveResourceItems = (
  resource: ResourceWithSubscription,
) => ({
  ...resource,
  items: resource.items.filter(
    (item) => item.state === HRESOURCE_ITEM_STATE.ACTIVE,
  ),
});

export const groupResourcesToSections = (
  resources: ResourceWithSubscription[],
) =>
  Object.values(
    resources.reduce((acc, resource) => {
      const section = HOMEPAGE_RESOURCE_TO_SECTION_MAP[resource.type];

      if (!section) {
        return acc;
      }

      const order = mapResourceToHomepageOrder(resource);
      const orders = [...(acc[section]?.orders || []), order];

      return {
        ...acc,
        [section]: {
          section,
          orders,
          manageService:
            section !== HOMEPAGE_SECTION.HOSTING &&
            orders.length > MANAGE_ORDERS_THRESHOLD,
        },
      };
    }, {} as Record<HomepageSection, { orders: HomepageOrder[]; manageService: boolean; section: HomepageSection }>),
  );

export type HomepageOrderSection = ReturnType<
  typeof groupResourcesToSections
>[number];

const getAccessLevelTitle = (accessItem: IAccessListItem) =>
  ACCESS_LEVEL_TITLE_MAP[accessItem.accessLevel];

export const mapAccessListItem = (item: IAccessListItem) => {
  const isActive = item.status === 'active';

  return {
    ...item,
    id: item.id,
    clientEmail: item.clientEmail,
    accessLevelTitle: getAccessLevelTitle(item),
    ctaLink: item.ctaLink,
    ctaText: isActive ? 'manage' : null,
    isActive,
    statusTitle: toTitleCase(item.status.replace(/_/g, ' ')),
  };
};

export const mapResourceToHomepageOrder = (
  resource: ResourceWithSubscription,
) => {
  const order = {
    id: getResourceId(resource),
    title: getMonolithOrderTitle(resource),
    serviceName: RESOURCE_TO_MONOLITH_TYPE_MAP[resource.type],
    status: RESOURCE_STATE_TO_MONOLITH_STATUS_MAP[resource.state],
    hresourceId: resource.id,
    referenceId: resource.referenceId,
    ctaText: getMonolithOrderCtaText(resource),
    ctaLink: 'not supported',
    hostname: resource.title || null,
    expiration: resource.expiresAt || null,
    setupMessage: 'Pending setup',
    allowRegister: true,
    serviceId: resource.referenceId,
    cpanelPlan: resource.config?.plan || null,
    planName: '',
    planNameCatalog: resource.planNameCatalog || null,
    subcategoryCatalog: resource.subcategoryCatalog || null,
    config: resource.config,
    websites: [],
  };

  if (resource.type === 'hosting') {
    return {
      ...order,
      planName: resource.metadata?.planTitle || 'Hosting',
      websites: resource.items?.filter((item) => item.type === 'website') || [],
    };
  }

  if (resource.type === 'cpanel_hosting') {
    return {
      ...order,
      planName: resource.metadata?.planTitle || resource.config?.plan,
      websites: resource.items?.filter((item) => item.type === 'website') || [],
    };
  }

  return order;
};

export type HomepageOrder = ReturnType<typeof mapResourceToHomepageOrder>;
