// @ts-nocheck
import type { App } from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

import clickOutside from '@/directives/clickOutside';
import inViewport from '@/directives/inViewport';
import qaGenerate from '@/directives/qaGenerate';
import { reverseChildren } from '@/directives/reverse-children';
import vSafeHtml from '@/directives/safeHtml';
import AnimateOnScroll from '@/directives/scrollAnimation';
import { vTooltip } from '@/directives/tooltipDirective';
import transDirective from '@/directives/trans-directive';

export const setDirectives = (app: App) => {
  app.directive('tooltip', vTooltip);
  app.directive('click-outside', clickOutside);
  app.directive('in-viewport', inViewport);
  app.directive('animate-on-scroll', AnimateOnScroll);
  app.directive('trans', transDirective);
  app.directive('qaGenerate', qaGenerate);
  app.directive('safe-html', vSafeHtml);
  app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
      vnode.context = binding.instance;
      ObserveVisibility.bind(el, binding, vnode);
    },
    updated: (el, binding, vnode) => {
      vnode.context = binding.instance;
      ObserveVisibility.update(el, binding, vnode);
    },
    unmounted: ObserveVisibility.unbind,
  });
  app.directive('reverse-children', reverseChildren);
};
