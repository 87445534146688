import { Cookie, type IJwtPayloadCamelCase } from '@/types';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';

export const parseJwt = (token: string): IJwtPayloadCamelCase => {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    return {} as IJwtPayloadCamelCase;
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  const parsed = JSON.parse(jsonPayload);

  if (parsed) {
    return snakeToCamelObj(parsed);
  }

  return {} as IJwtPayloadCamelCase;
};

export const getJwtToken = () => localStorage.getItem(Cookie.JWT);

export const getParsedJwtToken = () => {
  const token = getJwtToken();

  if (!token) {
    return null;
  }

  return parseJwt(token);
};
