import { router } from '@/router';
import { useProfileStore } from '@/stores';
import deviceIdService from '@/utils/services/deviceIdService';

export const formatGoogleAnalyticsObjectArray = (
  obj: Record<string, string | number>,
) =>
  Object.keys(obj).length
    ? Object.entries(obj).map(([key, value]) => ({
        key,
        value: value ? value.toString() : '',
      }))
    : [];

export const getGoogleAnalyticsPageName = () => router.currentRoute.value.name;

export const getGoogleAnalyticsHostname = () => {
  const profileStore = useProfileStore();

  return `wwww.${
    profileStore.account?.brand?.domain ?? 'hpanel.hostinger.com'
  }`;
};

export const getGoogleAnalyticsDeviceId = () => deviceIdService.getDeviceId();
