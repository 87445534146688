<script lang="ts" setup>
import { computed } from 'vue';

import { getAsset } from '@/utils/helpers/assetsHelpers';

type Props = {
  src: string;
  alt?: string;
  isExternal?: boolean;
};

const props = defineProps<Props>();

const image = computed(() =>
  props.isExternal ? props.src : getAsset(props.src),
);
</script>

<template>
  <img :src="image" :alt="alt" />
</template>
