import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import { sectionsRepo } from '@/repositories';
import { router } from '@/router';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { Route } from '@/types';
import RouteParser from '@/utils/services/navigation/RouteParser';

const initial = {
  sections: [],
  hostingSectionsLastUpdate: null,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    mutateHostingSections(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'sections', data);
    },
    setHostingSectionsLastUpdate(state, { data, requestDomain }) {
      setForDomain(
        state,
        requestDomain,
        'hostingSectionsLastUpdate',
        data || dayjs(),
      );
    },
  },
  getters: {
    activeHostingSections: (state, getters) => (domain) => {
      const sections = getStateVariable(
        state,
        'sections',
        domain || router.currentRoute.value.params.domain,
      );
      const clonedCategories = cloneDeep(sections) || [];

      clonedCategories.forEach((category) => {
        category.children.forEach((section) => {
          section.routeTo = getRouteFromSectionItem(section);
        });

        category.children = category.children.filter((section) => {
          const hidden =
            section.routeTo?.name === Route.HostingDomains.SET_MAIN_DOMAIN &&
            getters.domainSelections.length === 1;

          return (section.routeTo !== null || section.link) && !hidden;
        });
      });

      clonedCategories.sort(sortByOrder);

      return clonedCategories;
    },
    activeHostingSectionsWithoutRenew: (state, getters) => (domain) => {
      const sections = getStateVariable(
        state,
        'sections',
        domain || router.currentRoute.value.params.domain,
      );
      const clonedCategories = cloneDeep(sections) || [];

      clonedCategories.forEach((category) => {
        category.children.forEach((section) => {
          section.routeTo = getRouteFromSectionItem(section);
        });

        category.children = category.children.filter((section) => {
          const isRenew = section.slug === 'renew';
          const hidden =
            (section.routeTo?.name === Route.HostingDomains.SET_MAIN_DOMAIN &&
              getters.domainSelections.length === 1) ||
            isRenew;

          return (section.routeTo !== null || section.link) && !hidden;
        });
      });

      clonedCategories.sort(sortByOrder);

      return clonedCategories;
    },
    getSectionByUrl: (state) => (url, domain) => {
      const sections = getStateVariable(
        state,
        'sections',
        domain || router.currentRoute.value.params.domain,
      );

      return recursiveSearch(sections, 'url', url);
    },
    isSectionAllowed: (state, getters) => (url) => {
      const section = getters.getSectionByUrl(url);

      return section ? !section.notAllowed : true;
    },
    getSectionNotAllowedMsg: (state, getters) => (url) => {
      const section = getters.getSectionByUrl(url);

      return section ? section.notAllowedMsg : '';
    },
    getHostingSections: (state) =>
      getStateVariable(
        state,
        'sections',
        router.currentRoute.value.params.domain,
      ),
  },
  actions: {
    async getHostingSections(context, domain) {
      const [{ data, old, requestDomain }, err] =
        await sectionsRepo.getSections({ domain });

      if (!err) {
        if (!old) {
          context.commit('mutateHostingSections', {
            data,
            requestDomain,
          });
        }

        context.commit('setHostingSectionsLastUpdate', { requestDomain });
      }

      const time = getStateVariable(
        context.state,
        'hostingSectionsLastUpdate',
        domain,
      );

      if (time && dayjs().diff(time, 'm') < 60) {
        return new Promise((r) => r(true));
      }
    },
  },
};

const sortByOrder = (itemA, itemB) => {
  if (itemA.order === itemB.order) {
    return 0;
  }

  return itemA.order > itemB.order ? 1 : -1;
};

const getRouteFromSectionItem = (sectionItem) => {
  let name = sectionItem.url;

  if (RouteParser) {
    if (sectionItem.slug === 'email_accounts') {
      name = 'emails';
    }

    const route = RouteParser.getRouteByName(name);

    return route && route.name ? route.path : null;
  }

  return null;
};

const recursiveSearch = (sections, key, value, children = 'children') => {
  if (!sections) return null;

  let found = null;
  sections.find((sect) => {
    found = checkSection(sect, key, value, children);

    return !!found;
  });

  return found;
};

const checkSection = (section, key, value, children = 'children') => {
  if (section[key] === value) return section;

  return recursiveSearch(section[children], key, value, children);
};
