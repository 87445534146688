import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route } from '@/types';

const cpanelGuard: NavigationGuard = (to, from, next) => {
  const domain = to.params.domain;
  if (!domain) return next({ name: Route.Base.NOT_FOUND });

  const serviceId = store.getters.getCPanelOrderByDomain(
    domain,
    to.fullPath,
  )?.serviceId;
  if (!serviceId) return next({ name: Route.Base.NOT_FOUND });

  next();
};

export default cpanelGuard;
