import { isEmpty } from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import { useOnboarding } from '@/composables';
import { surveyRepo } from '@/repositories';
import type { IHostingSurveyAnswer, SurveyAnswers } from '@/types';
import { Onboarding } from '@/types';
import {
  snakeToCamel,
  snakeToCamelObj,
} from '@/utils/services/namingConventionsService';

const surveyAnswer = ref<SurveyAnswers | null>(null);
const isSurveyAnsweredDuringOnboarding = ref(true);

export const useOnboardingSurveyAnswers = () => {
  const store = useStore();

  const { isAddonOnboarding, addonType } = useOnboarding();

  const isNeedsHelp = computed(() => {
    if (!surveyAnswer.value) {
      return false;
    }

    return ['1', true].includes(surveyAnswer.value.needHelp || false);
  });
  const isProUser = computed(
    () =>
      surveyAnswer.value?.websiteFor === Onboarding.Survey.WebsiteFor.OTHERS,
  );

  const isBusinessBeginner = computed(() => {
    if (!surveyAnswer.value) {
      return false;
    }

    return (
      isNeedsHelp.value &&
      surveyAnswer.value.websiteFor === Onboarding.Survey.WebsiteFor.MYSELF &&
      surveyAnswer.value.websiteCreatedBy ===
        Onboarding.Survey.WebsiteCreatedBy.MYSELF
    );
  });

  const isLearner = computed(() => {
    if (!surveyAnswer.value) {
      return false;
    }

    return (
      isNeedsHelp.value &&
      surveyAnswer.value.workAt === Onboarding.Survey.WorkAt.FREELANCER &&
      surveyAnswer.value.websiteFor === Onboarding.Survey.WebsiteFor.OTHERS
    );
  });
  const isBusinessOwner = computed(() => {
    if (!surveyAnswer.value) {
      return false;
    }

    return (
      surveyAnswer.value.websiteCreatedBy &&
      surveyAnswer.value.websiteFor === Onboarding.Survey.WebsiteFor.MYSELF &&
      [
        Onboarding.Survey.WebsiteCreatedBy.OTHER,
        Onboarding.Survey.WebsiteCreatedBy.DEVELOPER,
      ].includes(surveyAnswer.value.websiteCreatedBy)
    );
  });

  const isBlogType = computed(
    () =>
      surveyAnswer.value?.websiteType === Onboarding.Survey.WebsiteType.BLOG,
  );

  const isOnlineStoreType = computed(
    () =>
      surveyAnswer.value?.websiteType ===
      Onboarding.Survey.WebsiteType.ONLINE_STORE,
  );

  const isAffiliateType = computed(
    () =>
      surveyAnswer.value?.websiteType ===
      Onboarding.Survey.WebsiteType.AFFILIATE_MARKETING,
  );

  const isAffiliateMarketingType = computed(
    () =>
      surveyAnswer.value?.websiteType ===
      Onboarding.Survey.WebsiteType.AFFILIATE_MARKETING,
  );

  const isAffiliateAddonTypeSelected = computed(
    () =>
      addonType.value === Onboarding.Survey.WebsiteType.AFFILIATE_MARKETING &&
      isAddonOnboarding.value,
  );

  const isOnlineStoreAddonType = computed(
    () =>
      isAddonOnboarding.value &&
      addonType.value === Onboarding.Survey.WebsiteType.ONLINE_STORE,
  );

  const isFreelancer = computed(
    () =>
      surveyAnswer.value?.workAt === Onboarding.Survey.WorkAt.FREELANCER &&
      !isNeedsHelp.value,
  );

  const isAgency = computed(
    () => surveyAnswer.value?.workAt === Onboarding.Survey.WorkAt.AGENCY,
  );

  const shouldPromoteHelp = computed(
    () => isBusinessBeginner.value || isLearner.value || isBusinessOwner.value,
  );

  const getSurveyAnswers = async () => {
    const surveyData = store.getters.getSurveyData;
    if (!isEmpty(surveyData)) {
      return (surveyAnswer.value = snakeToCamelObj(surveyData));
    }

    if (surveyAnswer.value !== null) {
      return;
    }

    const [{ data }, err] = await surveyRepo.getSurveyResults();

    if (data && !err) {
      isSurveyAnsweredDuringOnboarding.value = false;

      const surveyAnswers = {
        websiteType: null,
        websiteFor: null,
        experience: null,
        skippedOnboarding: null,
        websiteCreatedBy: null,
        needHelp: null,
        workAt: null,
      } as SurveyAnswers;

      data.answers?.reduce(
        (all: SurveyAnswers, answer: IHostingSurveyAnswer) => {
          all[snakeToCamel(answer.questionSlug)] = answer.answer;

          return all;
        },
        surveyAnswers,
      );

      surveyAnswer.value = surveyAnswers;
    }
  };

  getSurveyAnswers();

  return {
    isBlogType,
    surveyAnswer,
    isBusinessBeginner,
    isLearner,
    isAffiliateAddonTypeSelected,
    isOnlineStoreAddonType,
    isBusinessOwner,
    shouldPromoteHelp,
    getSurveyAnswers,
    isOnlineStoreType,
    isAffiliateType,
    isAffiliateMarketingType,
    isFreelancer,
    isAgency,
    isProUser,
    isSurveyAnsweredDuringOnboarding,
  };
};
