<template>
  <button
    class="h-list-button"
    :disabled="loading"
    @click.prevent="$emit('click', $event)"
  >
    <slot v-if="!loading" />
    <div v-else class="position-relative h-py-12">
      <CircleLoader xs absolute />
    </div>
  </button>
</template>

<script>
// JSON-slugs-migrated
import CircleLoader from '../Loaders/CircleLoader.vue';

export default {
  name: 'HListButton',
  components: { CircleLoader },
  props: {
    loading: Boolean,
  },
  emits: ['click'],
};
</script>
<style lang="scss" scoped>
@import '../../sass/components/h-list-button';
</style>
