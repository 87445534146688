import { useLanguageStore, useProfileStore } from '@/stores';
import { Client, LANGUAGE_CODE } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

const SUPPORT_URL = 'https://support.hostinger.com';
const NIAGAHOSTER_SUPPORT_URL = 'https://support.niagahoster.co.id';

const currentLang = () => {
  const languageStore = useLanguageStore();

  return languageStore.currentLanguage;
};

const clientBrandId = () => {
  const profileStore = useProfileStore();

  return profileStore.account?.brand.id;
};

export const getPhishingArticle = () => {
  switch (currentLang()) {
    default:
      return 'https://www.hostinger.com/blog/beware-of-phishing';
  }
};

export const getNewProPanelClientArticle = () => {
  switch (currentLang()) {
    default:
      return 'https://support.hostinger.com/en/articles/7853892-pro-panel-how-to-add-a-new-client';
  }
};

export const getHowToUseProPanelArticle = () => {
  switch (currentLang()) {
    default:
      return 'https://support.hostinger.com/en/articles/7878020-how-to-use-pro-panel';
  }
};

export const getDomainVerifyArticle = () => {
  switch (currentLang()) {
    default:
      return 'https://support.hostinger.com/en/articles/4771440-how-to-verify-domain-ownership';
  }
};

export const getVpsArticle = () => {
  if (clientBrandId() === Client.BrandId.Other.NG_CO_ID) {
    return `${NIAGAHOSTER_SUPPORT_URL}/category/vps/`;
  }

  switch (currentLang()) {
    case 'ru_RU':
      return 'https://support.hostinger.ru/ru/collections/1937193-vps';
    case 'pt_BR':
      return 'https://support.hostinger.com.br/pt-BR/collections/944923-vps';
    case 'es_ES':
      return 'https://support.hostinger.es/es/collections/1797145-vps';
    case 'fr_FR':
      return 'https://support.hostinger.fr/fr/collections/1539363-vps';
    case 'id_ID':
      return 'https://support.hostinger.co.id/id/collections/3000597-vps';
    default:
      return 'https://support.hostinger.com/en/collections/944797-vps';
  }
};

export const getSuspendedForAbuseArticle = (lang: string) => {
  switch (lang) {
    case 'pt_BR':
      return 'https://support.hostinger.com.br/pt-BR/articles/4536586-por-que-minha-hospedagem-foi-suspensa-ou-cancelada';
    case 'id_ID':
      return 'https://support.hostinger.co.id/id/articles/4589377-kenapa-akun-hosting-saya-di-suspend-atau-terhapus';
    case 'ru_RU':
      return 'https://support.hostinger.ru/ru/articles/4578396-%D0%BF%D0%BE%D1%87%D0%B5%D0%BC%D1%83-%D0%BC%D0%BE%D0%B9-%D1%85%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B3-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD-%D0%B8%D0%BB%D0%B8-%D0%BE%D1%82%D0%BC%D0%B5%D0%BD%D1%91%D0%BD';
    case 'es_ES':
      return 'https://support.hostinger.es/es/articles/4199449-por-que-esta-suspendido-o-cancelado-mi-hosting';
    case 'fr_FR':
      return 'https://support.hostinger.fr/fr/articles/4597353-pourquoi-mon-hebergement-est-il-suspendu-ou-annule';
    default:
      return 'https://support.hostinger.com/en/articles/4146981-why-is-my-hosting-suspended-or-cancelled';
  }
};

export const getSslKnowledgeArticles = (locale: string) => {
  switch (locale) {
    case 'pt_BR':
      return 'https://www.hostinger.com.br/tutoriais/o-que-e-ssl-tls-https/';
    case 'pt_PT':
      return 'https://www.hostinger.com.br/tutoriais/o-que-e-ssl-tls-https/';
    case 'es_ES':
      return 'https://www.hostinger.es/tutoriales/ssl-tls-https/';
    case 'es_AR':
      return 'https://www.hostinger.es/tutoriales/ssl-tls-https/';
    case 'es_CO':
      return 'https://www.hostinger.es/tutoriales/ssl-tls-https/';
    case 'es_MX':
      return 'https://www.hostinger.es/tutoriales/ssl-tls-https/';
    case 'id_ID':
      return 'https://www.hostinger.co.id/tutorial/apa-itu-ssl/';
    case 'tr_TR':
      return 'https://www.hostinger.web.tr/rehberler/https-tls-ve-ssl-nedir-ne-ise-yararlar/';
    case 'ru_RU':
      return 'https://www.hostinger.ru/rukovodstva/shto-takoe-ssl-tls-i-https/';
    case 'fr_FR':
      return 'https://www.hostinger.fr/tutoriels/quest-ce-que-ssl-tls-et-https';
    default:
      return 'https://www.hostinger.com/tutorials/what-is-ssl-tls-https';
  }
};

export const getDomainRedirectTutorial = (locale: string) => {
  switch (locale) {
    case 'pt':
      return 'https://www.hostinger.com.br/tutoriais/como-forcar-acesso-ssl-em-meu-site';
    case 'es':
      return 'https://www.hostinger.es/tutoriales/como-forzar-https-usando-htaccess';
    case 'id':
      return 'https://support.hostinger.co.id/id/articles/5486645-bagaimana-cara-force-https-dengan-htaccess';
    case 'ru':
      return 'https://support.hostinger.ru/ru/articles/5571016';
    case 'fr':
      return 'https://www.hostinger.fr/tutoriels/comment-forcer-le-https-a-laide-de-htaccess';
    default:
      return 'https://www.hostinger.com/tutorials/ssl/forcing-https';
  }
};

export const getSshAccessArticle = (locale: string) => {
  switch (locale) {
    case 'pt':
      return 'https://www.hostinger.com.br/tutoriais/lista-de-comandos-basicos-ssh';
    case 'es':
      return 'https://www.hostinger.es/tutoriales/17-comandos-ssh-basicos-que-debes-conocer';
    case 'id':
      return 'https://www.hostinger.co.id/tutorial/cara-menggunakan-command-ssh';
    case 'ru':
      return 'https://www.hostinger.ru/rukovodstva/bazovie-ssh-komandy';
    case 'fr':
      return 'https://www.hostinger.fr/tutoriels/commandes-ssh-de-base-que-vous-devez-connaitre';
    default:
      return 'https://www.hostinger.com/tutorials/ssh/basic-ssh-commands';
  }
};

export const getSetDNSRecordsArticles = (locale: string) => {
  switch (locale) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/4767973-bagaimana-cara-setup-domain-untuk-email-di-hostinger';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/4767973-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD-%D0%B4%D0%BB%D1%8F-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9-%D0%BF%D0%BE%D1%87%D1%82%D0%BE%D0%B9-hostinger';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/4767973-comment-configurer-votre-domaine-pour-les-emails-chez-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/4767973-how-to-set-up-your-domain-for-emails-at-hostinger';
  }
};

export const getEmailAccountsArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/1583217-bagaimana-cara-membuat-dan-menghapus-akun-hostinger-email';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/1583217-comment-configurer-et-gerer-un-compte-email-pour-les-emails-d-hostinger';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/1583217-%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D1%82%D1%8C-%D0%B8%D0%BB%D0%B8-%D1%83%D0%B4%D0%B0%D0%BB%D0%B8%D1%82%D1%8C-%D0%B0%D0%BA%D0%BA%D0%B0%D1%83%D0%BD%D1%82-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9-%D0%BF%D0%BE%D1%87%D1%82%D1%8B-%D0%B2-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/1583217-how-to-set-up-and-manage-an-email-account-for-hostinger-email';
  }
};

export const getEmailAliasArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/5240877-bagaimana-cara-setup-email-alias-di-hpanel';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/5240877-como-criar-e-mails-alias-no-hpanel';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/5240877-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%BF%D1%81%D0%B5%D0%B2%D0%B4%D0%BE%D0%BD%D0%B8%D0%BC%D1%8B-%D0%BF%D0%BE%D1%87%D1%82%D1%8B-%D0%B2-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-hpanel';
    default:
      return 'https://support.hostinger.com/en/articles/5240877-how-to-set-up-an-email-alias-for-hostinger-email';
  }
};

export const getEmailForwarderArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/1583221-bagaimana-cara-setup-forwarder-untuk-hostinger-email';
    case 'es_ES':
    case 'es_AR':
    case 'es_CO':
    case 'es_MX':
      return 'https://support.hostinger.com/es/articles/1583221-como-configurar-los-reenviadores-en-hpanel';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/1583221-comment-configurer-un-redirecteur-pour-les-emails-d-hostinger';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/1583221-como-criar-encaminhamentos-de-e-mail-no-hpanel';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/1583221-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%BF%D0%B5%D1%80%D0%B5%D0%B0%D0%B4%D1%80%D0%B5%D1%81%D0%B0%D1%86%D0%B8%D1%8E-%D0%B2-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-hpanel';
    default:
      return 'https://support.hostinger.com/en/articles/1583221-how-to-set-up-a-forwarder-for-hostinger-email';
  }
};

export const getEmailCatchAllArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/1583450-bagaimana-cara-catch-all-email-di-hpanel';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/1583450-comment-puis-je-recuperer-tous-les-e-mails-sur-hpanel';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/1583450-como-criar-um-catch-all-e-capturar-todos-os-e-mails-na-hostinger';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/1583450-%D0%BA%D0%B0%D0%BA-%D0%B2%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D1%84%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D1%8E-catch-all-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%BE%D1%87%D1%82%D1%8B-%D0%B2-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F';
    default:
      return 'https://support.hostinger.com/en/articles/1583450-how-to-set-up-a-catch-all-for-hostinger-email';
  }
};

export const getEmailAutoReplyArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/1583449-bagaimana-cara-membuat-auto-reply-di-hpanel';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/1583449-comment-creer-une-reponse-automatique-sur-hpanel';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/1583449-como-criar-uma-resposta-automatica-de-e-mail-no-hpanel';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/1583449-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D0%BE%D1%82%D0%B2%D0%B5%D1%82-%D0%B2-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-hpanel';
    default:
      return 'https://support.hostinger.com/en/articles/1583449-how-to-set-up-an-automatic-reply-for-hostinger-email';
  }
};

export const getEmailDnsSettingsArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/4767973-bagaimana-cara-setup-domain-untuk-hostinger-email';
    case 'es_ES':
    case 'es_AR':
    case 'es_CO':
    case 'es_MX':
      return 'https://support.hostinger.com/es/articles/4767973-como-configurar-un-dominio-para-hostinger-email';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/4767973-comment-configurer-un-nom-de-domaine-pour-hostinger-email';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/4767973-como-configurar-o-dominio-para-usar-os-e-mails-na-hostinger';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/4767973-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD-%D0%B4%D0%BB%D1%8F-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9-%D0%BF%D0%BE%D1%87%D1%82%D0%BE%D0%B9-hostinger';
    case 'uk_UA':
      return 'https://support.hostinger.com/uk/articles/4767973-%D1%8F%D0%BA-%D0%BD%D0%B0%D0%BB%D0%B0%D1%88%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8-%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD-%D0%B4%D0%BB%D1%8F-%D0%B5%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D1%97-%D0%BF%D0%BE%D1%88%D1%82%D0%B8-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/4767973-how-to-set-up-a-domain-for-hostinger-email';
  }
};

export const getEmailConfigDevicesArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/4305847-bagaimana-cara-setup-email-hostinger-di-perangkat-saya';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/4305847-comment-configurer-les-e-mails-hostinger-sur-vos-appareils-et-applications-de-messagerie';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/4305847-como-configurar-os-e-mails-da-hostinger-em-dispositivos-e-plataformas';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/4305847-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%BF%D0%BE%D1%87%D1%82%D1%83-hostinger-%D0%BD%D0%B0-%D1%80%D0%B0%D0%B7%D0%BD%D1%8B%D1%85-%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%81%D1%82%D0%B2%D0%B0%D1%85-%D0%B8-%D0%B2-%D0%BF%D0%BE%D1%87%D1%82%D0%BE%D0%B2%D1%8B%D1%85-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F%D1%85';
    default:
      return 'https://support.hostinger.com/en/articles/4305847-how-to-set-up-hostinger-email-on-your-devices-and-email-applications';
  }
};

export const getEmailAccessLogsArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/5760253-cara-cek-log-akses-email-hostinger';
    case 'es_ES':
    case 'es_AR':
    case 'es_CO':
    case 'es_MX':
      return 'https://support.hostinger.com/es/articles/5760253-como-verificar-los-registros-de-acceso-para-hostinger-email';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/5760253-comment-consulter-les-journaux-d-acces-aux-emails-de-hostinger';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/5760253-como-verificar-o-relatorio-de-acessos-de-e-mail-da-hostinger';
    case 'uk_UA':
      return 'https://support.hostinger.com/uk/articles/5760253-%D1%8F%D0%BA-%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D1%96%D1%80%D0%B8%D1%82%D0%B8-%D1%96%D1%81%D1%82%D0%BE%D1%80%D1%96%D1%8E-%D0%B0%D0%B2%D1%82%D0%BE%D1%80%D0%B8%D0%B7%D0%B0%D1%86%D1%96%D0%B9-%D0%B2-%D0%BF%D0%BE%D1%88%D1%82%D1%96-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/5760253-how-to-check-access-logs-for-hostinger-email';
  }
};

export const getEmailActionLogsArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/5996075-cara-cek-log-tindakan-email-hostinger';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/5996075-comment-verifier-les-journaux-d-action-pour-les-emails-hostinger';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/5996075-como-verificar-os-relatorios-de-acao-no-e-mail-da-hostinger';
    case 'uk_UA':
      return 'https://support.hostinger.com/uk/articles/5996075-%D1%8F%D0%BA-%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D1%96%D1%80%D0%B8%D1%82%D0%B8-%D1%96%D1%81%D1%82%D0%BE%D1%80%D1%96%D1%8E-%D0%B4%D1%96%D0%B9-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%BE%D1%88%D1%82%D0%B8-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/5996075-how-to-check-action-logs-for-hostinger-emails';
  }
};

export const getEmailDeliveryLogsArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/6404796-cara-cek-log-pengiriman-email-hostinger';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/6404796-comment-verifier-les-journaux-de-livraison-pour-les-e-mails-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/6404796-how-to-check-delivery-logs-for-hostinger-email';
  }
};

export const getEmailImportArticle = () => {
  switch (currentLang()) {
    case 'id_ID':
      return 'https://support.hostinger.com/id/articles/5866288-bagaimana-cara-migrasi-layanan-email-ke-hostinger';
    case 'es_ES':
    case 'es_AR':
    case 'es_CO':
    case 'es_MX':
      return 'https://support.hostinger.com/es/articles/5866288-como-migrar-emails-a-hostinger';
    case 'fr_FR':
      return 'https://support.hostinger.com/fr/articles/5866288-comment-migrer-des-emails-vers-hostinger';
    case 'lt_LT':
      return 'https://support.hostinger.com/lt/articles/5866288-kaip-perkelti-el-pasta-i-hostinger';
    case 'pt_PT':
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/5866288-como-migrar-e-mails-para-a-hostinger';
    case 'ru_RU':
      return 'https://support.hostinger.com/ru/articles/5866288-%D0%BA%D0%B0%D0%BA-%D0%BF%D0%B5%D1%80%D0%B5%D0%BD%D0%B5%D1%81%D1%82%D0%B8-%D0%BF%D0%BE%D1%87%D1%82%D1%83-%D0%B2-hostinger';
    case 'uk_UA':
      return 'https://support.hostinger.com/uk/articles/5866288-%D1%8F%D0%BA-%D0%BF%D0%B5%D1%80%D0%B5%D0%BD%D0%B5%D1%81%D1%82%D0%B8-%D0%BF%D0%BE%D1%88%D1%82%D1%83-%D0%B2-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/5866288-how-to-migrate-emails-to-hostinger';
  }
};

export const getServiceProviderForBrArticle = () => {
  switch (currentLang()) {
    case 'pt_BR':
      return 'https://support.hostinger.com/pt/articles/6758798-como-configurar-o-provedor-de-servicos-para-dominios-br';
    default:
      return 'https://support.hostinger.com/en/articles/6758798-how-to-set-up-the-service-provider-for-br-domains';
  }
};

export const getHowToTransferBrDomainArticle = () => {
  switch (currentLang()) {
    case LANGUAGE_CODE.es_ES:
      return 'https://support.hostinger.com/es/articles/8698031-como-transferir-un-dominio-br-a-hostinger';
    case LANGUAGE_CODE.pt_BR:
      return 'https://support.hostinger.com/pt/articles/8698031-como-transferir-seu-dominio-br-para-a-hostinger';
    default:
      return 'https://support.hostinger.com/en/articles/8698031-how-to-transfer-a-br-domain-to-hostinger';
  }
};

export const getDomainTransferDurationArticle = () => {
  if (clientBrandId() === Client.BrandId.Other.NG_CO_ID) {
    return `${NIAGAHOSTER_SUPPORT_URL}/kapan-transfer-domain-saya-selesai-dilakukan/`;
  }

  const languageMap = {
    fr_FR: `fr/articles/1583399-quand-le-transfert-de-domaine-sera-t-il-termine`,
    id_ID: `id/articles/1583399-kapan-transfer-domain-saya-selesai-dilakukan`,
    pt_PT: `pt/articles/1583399-quando-a-transferencia-de-dominio-sera-concluida`,
    pt_BR: `pt/articles/1583399-quando-a-transferencia-de-dominio-sera-concluida`,
    es_ES: `es/articles/1583399-cuando-se-completara-la-transferencia-de-dominio`,
    es_CO: `es/articles/1583399-cuando-se-completara-la-transferencia-de-dominio`,
    es_AR: `es/articles/1583399-cuando-se-completara-la-transferencia-de-dominio`,
    es_MX: `es/articles/1583399-cuando-se-completara-la-transferencia-de-dominio`,
    uk_UA: `uk/articles/1583399-%D0%BA%D0%BE%D0%BB%D0%B8-%D0%B1%D1%83%D0%B4%D0%B5-%D0%B7%D0%B0%D0%B2%D0%B5%D1%80%D1%88%D0%B5%D0%BD%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%BD%D1%8F-%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD%D1%83`,
    default: `en/articles/1583399-when-is-the-domain-transfer-going-to-be-completed`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getDomainTransferUnlockArticle = () => {
  if (clientBrandId() === Client.BrandId.Other.NG_CO_ID) {
    return `${NIAGAHOSTER_SUPPORT_URL}/bagaimana-cara-membuka-kunci-domain-sebelum-transfer-domain-ke-niagahoster`;
  }

  const languageMap = {
    fr_FR: `fr/articles/5465728-comment-deverrouiller-votre-domaine-avant-le-transfert-vers-hostinger`,
    id_ID: `id/articles/5465728-bagaimana-cara-membuka-kunci-domain-sebelum-transfer-domain-ke-hostinger`,
    pt_PT: `pt/articles/5465728-como-desbloquear-um-dominio-para-transferir-para-a-hostinger`,
    pt_BR: `pt/articles/5465728-como-desbloquear-um-dominio-para-transferir-para-a-hostinger`,
    es_ES: `es/articles/5465728-como-desbloquear-tu-dominio-antes-de-transferirlo-a-hostinger`,
    es_CO: `es/articles/5465728-como-desbloquear-tu-dominio-antes-de-transferirlo-a-hostinger`,
    es_AR: `es/articles/5465728-como-desbloquear-tu-dominio-antes-de-transferirlo-a-hostinger`,
    es_MX: `es/articles/5465728-como-desbloquear-tu-dominio-antes-de-transferirlo-a-hostinger`,
    default: `en/articles/5465728-how-to-unlock-your-domain-to-transfer-it-to-hostinger`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getDomainHowToTransferArticle = () => {
  const languageMap = {
    fr_FR: `fr/articles/1583436-comment-transferer-un-nom-de-domaine-vers-hostinger`,
    id_ID: `id/articles/1583436-bagaimana-cara-transfer-domain-ke-hostinger`,
    pt_PT: `pt/articles/1583436-como-transferir-seu-dominio-para-a-hostinger`,
    pt_BR: `pt/articles/1583436-como-transferir-seu-dominio-para-a-hostinger`,
    es_ES: `es/articles/1583436-como-transferir-un-dominio-a-hostinger`,
    es_CO: `es/articles/1583436-como-transferir-un-dominio-a-hostinger`,
    es_AR: `es/articles/1583436-como-transferir-un-dominio-a-hostinger`,
    es_MX: `es/articles/1583436-como-transferir-un-dominio-a-hostinger`,
    uk_UA: `uk/articles/1583436-як-перенести-домен-у-hostinger`,
    default: `en/articles/1583436-how-to-transfer-your-domain-to-hostinger`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getHowToTransferLtDomainArticle = () => {
  const LANGUAGE_MAP = {
    lt_LT: `lt/articles/6719190-kaip-perkelti-lt-domena-i-hostinger`,
    default: `en/articles/6719190-how-to-transfer-a-lt-domain-to-hostinger`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(LANGUAGE_MAP, currentLang())}`;
};

export const getHowToTransferUkDomainArticle = () => {
  const LANGUAGE_MAP = {
    fr_FR: `fr/articles/1771588-comment-transferer-un-nom-de-domaine-uk-vers-hostinger`,
    id_ID: `id/articles/1771588-cara-transfer-domain-uk-ke-hostinger`,
    lt_LT: `lt/articles/1771588-kaip-perkelti-uk-domena-i-hostinger`,
    pt_PT: `pt/articles/1771588-tudo-sobre-transferencia-de-dominio-uk`,
    pt_BR: `pt/articles/1771588-tudo-sobre-transferencia-de-dominio-uk`,
    es_ES: `es/articles/1771588-como-transferir-un-dominio-uk-a-hostinger`,
    es_CO: `es/articles/1771588-como-transferir-un-dominio-uk-a-hostinger`,
    es_AR: `es/articles/1771588-como-transferir-un-dominio-uk-a-hostinger`,
    es_MX: `es/articles/1771588-como-transferir-un-dominio-uk-a-hostinger`,
    default: `en/articles/1771588-how-to-transfer-a-uk-domain-to-hostinger`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(LANGUAGE_MAP, currentLang())}`;
};

export const getDomainTransferStatusArticle = () => {
  const languageMap = {
    fr_FR: `fr/articles/4134118-que-signifie-le-statut-du-transfert-de-domaine`,
    id_ID: `id/articles/4134118-apa-yang-dimaksud-dengan-status-transfer-domain`,
    pt_PT: `pt/articles/4134118-o-que-significa-o-status-de-transferencia-de-dominio`,
    pt_BR: `pt/articles/4134118-o-que-significa-o-status-de-transferencia-de-dominio`,
    es_ES: `es/articles/4134118-que-significa-el-estado-de-transferencia-de-dominio`,
    es_CO: `es/articles/4134118-que-significa-el-estado-de-transferencia-de-dominio`,
    es_AR: `es/articles/4134118-que-significa-el-estado-de-transferencia-de-dominio`,
    es_MX: `es/articles/4134118-que-significa-el-estado-de-transferencia-de-dominio`,
    uk_UA: `uk/articles/4134118-що-означає-статус-трансферу-домену`,
    lt_LT: `lt/articles/4134118-ka-reiskia-domeno-perkelimo-busena`,
    default: `en/articles/4134118-what-does-the-domain-transfer-status-mean`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getEmailsArticle = () => {
  if (clientBrandId() === Client.BrandId.Other.NG_CO_ID) {
    return `${NIAGAHOSTER_SUPPORT_URL}/category/email/`;
  }

  const alpha2code = currentLang().split('_')[0];
  const supportedLanguages = ['en', 'fr', 'id', 'lt', 'pt', 'es', 'uk'];
  const defaultLanguage = 'en';
  const articleLanguage = supportedLanguages.includes(alpha2code)
    ? alpha2code
    : defaultLanguage;

  return `${SUPPORT_URL}/${articleLanguage}/collections/944782`;
};

export const getHowToMoveDomainArticle = () => {
  const languageMap = {
    fr_FR: `fr/articles/4068055-comment-transferer-un-domaine-entre-differents-comptes-hostinger`,
    id_ID: `id/articles/4068055-cara-memindahkan-domain-antar-akun-hostinger-yang-berbeda`,
    lt_LT: `lt/articles/4068055-kaip-perkelti-domena-is-vienos-hostinger-paskyros-i-kita`,
    pt_PT: `pt/articles/4068055-como-mover-um-dominio-entre-diferentes-contas-da-hostinger`,
    pt_BR: `pt/articles/4068055-como-mover-um-dominio-entre-diferentes-contas-da-hostinger`,
    es_ES: `es/articles/4068055-como-transferir-un-dominio-entre-distintas-cuentas-de-hostinger`,
    es_CO: `es/articles/4068055-como-transferir-un-dominio-entre-distintas-cuentas-de-hostinger`,
    es_AR: `es/articles/4068055-como-transferir-un-dominio-entre-distintas-cuentas-de-hostinger`,
    es_MX: `es/articles/4068055-como-transferir-un-dominio-entre-distintas-cuentas-de-hostinger`,
    uk_UA: `uk/articles/4068055-як-перенести-домен-між-різними-хостинг-акаунтами-hostinger`,
    default: `en/articles/4068055-how-to-move-a-domain-between-hostinger-accounts`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getWhatIsDomainPropagationArticle = () => {
  const languageMap = {
    fr_FR: `fr/articles/4146975-qu-est-ce-que-la-propagation-dns`,
    id_ID: `id/articles/4146975-apa-itu-propagasi-dns`,
    lt_LT: `lt/articles/4146975-kas-yra-dns-propagacija`,
    pt_PT: `pt/articles/4146975-o-que-e-propagacao-de-dns`,
    pt_BR: `pt/articles/4146975-o-que-e-propagacao-de-dns`,
    es_ES: `es/articles/4146975-que-es-la-propagacion-de-dns`,
    uk_UA: `uk/articles/4146975-що-таке-розповсюдження-dns`,
    default: `en/articles/4146975-what-is-dns-propagation`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};

export const getAiTroubleshooterArticle = (statusCode: number) => {
  switch (statusCode) {
    case 403:
      return 'https://support.hostinger.com/en/articles/1583304-how-to-fix-a-403-forbidden-error';
    case 404:
      return 'https://support.hostinger.com/en/articles/2425385-how-to-fix-the-404-error';
    case 500:
      return 'https://support.hostinger.com/en/articles/1583187-how-to-fix-500-internal-server-error';
    default:
      return '';
  }
};

export const getHowToChangeDomainContactDetailsArticle = () => {
  const languageMap = {
    fr_FR: `fr/articles/4778256-comment-modifier-les-coordonnees-d-un-nom-de-domaine`,
    id_ID: `id/articles/4778256-bagaimana-cara-mengubah-detail-kontak-domain`,
    pt_PT: `pt/articles/4778256-como-alterar-os-dados-de-contato-do-dominio`,
    pt_BR: `pt/articles/4778256-como-alterar-os-dados-de-contato-do-dominio`,
    es_ES: `es/articles/4778256-como-editar-los-datos-de-contacto-de-un-dominio`,
    uk_UA: `uk/articles/4778256-як-змінити-контактні-дані-домену`,
    default: `en/articles/4778256-how-to-change-domain-contact-details`,
  };

  return `${SUPPORT_URL}/${mapKeyValue(languageMap, currentLang())}`;
};
