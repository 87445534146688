import type { IH5GSetupDuplication, IH5GWordpressSettings } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/h5g`,
  async getWebsiteAdminUrlUrl(websiteUid: string) {
    return await hAsync<string>(
      http.get(`${this.url}/websites/${websiteUid}/wordpress/admin`, {
        noHostingHeaders: true,
      }),
    );
  },
  async getWordpressSettings(websiteUid: string) {
    return await hAsync<IH5GWordpressSettings>(
      http.get(`${this.url}/websites/${websiteUid}/wordpress/settings`, {
        noHostingHeaders: true,
      }),
    );
  },
  async enableLiteSpeed(websiteUid: string) {
    return await hAsync<boolean>(
      http.post(
        `${this.url}/websites/${websiteUid}/wordpress/litespeed-cache`,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async disableLiteSpeed(websiteUid: string) {
    return await hAsync<boolean>(
      http.delete(
        `${this.url}/websites/${websiteUid}/wordpress/litespeed-cache`,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async enableObjectCache(websiteUid: string) {
    return await hAsync<boolean>(
      http.post(`${this.url}/websites/${websiteUid}/wordpress/object-cache`, {
        noHostingHeaders: true,
      }),
    );
  },
  async disableObjectCache(websiteUid: string) {
    return await hAsync<boolean>(
      http.delete(`${this.url}/websites/${websiteUid}/wordpress/object-cache`, {
        noHostingHeaders: true,
      }),
    );
  },
  async flushLiteSpeedCache(websiteUid: string) {
    return await hAsync<boolean>(
      http.delete(
        `${this.url}/websites/${websiteUid}/wordpress/cache/contents`,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async getAvailableDuplications() {
    return await hAsync<IH5GSetupDuplication[]>(
      http.get(`${this.url}/wordpress/duplications/available`, {
        noHostingHeaders: true,
      }),
    );
  },
  async postDuplication(
    websiteUid: string,
    duplicationDetails: IH5GSetupDuplication,
  ) {
    return await hAsync<string[]>(
      http.post(
        `${this.url}/websites/${websiteUid}/wordpress/duplications`,
        duplicationDetails,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
};
