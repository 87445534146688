import type { ModalObject } from '@/types';

export default [
  {
    name: 'ChargebeeRefundConfirmationModal',
    type: 'HModal',
    subtype: 'HRefunds',
    props: {
      title: 'We Are Sad to See You Go',
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'ChargebeeAutoRefundDisableRenewalModal',
    type: 'HModal',
    subtype: 'HRefunds',
    props: {
      title: 'Disable Auto-Renewal for Upcoming Charges',
      persistent: true,
      hideX: true,
    },
  },
] as ModalObject[];
