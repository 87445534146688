import dayjs from 'dayjs';

import type { HResourceType, IHResource } from '@/types';
import { HRESOURCES_STATE, HRESOURCES_TYPE } from '@/types';
import { isEmailResource } from '@/utils/helpers/emailsHelper';
import type { ResourceWithSubscription } from '@/utils/mappers/resourcesMapper';

const EXPIRATION_THRESHOLD = 14;
const EXPIRATION_THRESHOLD_HOSTING = 21;
const EXPIRATION_THRESHOLD_DOMAINS = 45;

export const useResource = () => {
  const canRenewResource = (resource: ResourceWithSubscription) => {
    const {
      subscription: { canRenew, canReactivate },
    } = resource;

    const canRenewOrReactivate = canReactivate || canRenew;

    const expirationThresholdByType = () => {
      if (resource.type === HRESOURCES_TYPE.DOMAIN) {
        return EXPIRATION_THRESHOLD_DOMAINS;
      }
      if (resource.type === HRESOURCES_TYPE.HOSTING) {
        return EXPIRATION_THRESHOLD_HOSTING;
      }

      return EXPIRATION_THRESHOLD;
    };

    const isExpiringSoon = isResourceExpiringInDays(
      resource,
      expirationThresholdByType(),
    );

    const isDomain = HRESOURCES_TYPE.DOMAIN === resource.type;
    const isDomainOrEmail = isDomain || isEmailResource(resource.type);

    if (isDomainOrEmail) {
      return (
        isExpiringSoon &&
        canRenewOrReactivate &&
        resource.state !== HRESOURCES_STATE.DELETED
      );
    }

    const isNotFreeMail = resource.config?.emailPlan !== 'free';
    const isSuspendedOrder = resource.state === HRESOURCES_STATE.SUSPENDED;

    return (
      isNotFreeMail &&
      canRenewOrReactivate &&
      (isExpiringSoon || isSuspendedOrder)
    );
  };

  const isResourceExpiringInDays = (
    resource: ResourceWithSubscription,
    expirationThresholdInDays: number,
  ) => {
    const currentDate = dayjs();
    const expirationDate = dayjs(resource.expiresAt);
    const daysUntilExpiration = expirationDate.diff(currentDate, 'day');

    return daysUntilExpiration <= expirationThresholdInDays;
  };

  const getIsType = ({ type }: IHResource, targetType: HResourceType) =>
    type === targetType;

  return {
    canRenewResource,
    getIsType,
  };
};
