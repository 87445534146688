<script setup lang="ts">
import { computed } from 'vue';

import type { HLabelTheme } from '@/types';

type Props = {
  isActive?: boolean;
  isDisabled?: boolean;
  label?: string;
  title?: string;
  radioTextLabel?: {
    text: string;
    theme: HLabelTheme;
  };
  id?: string;
  isRadioBubbleHidden?: boolean;
  isCentered?: boolean;
};

type Emits = {
  click: [id: string];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const radioText = computed(() => props.label || props.title);
</script>

<template>
  <!-- @vue-skip -->
  <div
    class="h-radio-holder"
    :class="{
      'h-radio__label--disabled': isDisabled,
      'h-radio-holder--centered': isCentered,
    }"
    @click="emit('click', id)"
  >
    <label
      v-show="!isRadioBubbleHidden"
      class="h-radio__element"
      :class="{
        'h-radio__element--primary': isActive,
        'h-radio__element--isDisabled': isDisabled,
      }"
    >
      <input isDisabled type="radio" :checked="isActive" />
      <span />
      <div v-if="!isDisabled" class="h-radio__overlay" />
    </label>
    <div
      class="h-radio__label"
      :class="[
        { 'h-radio__label--bold': title },
        { 'h-radio__label--disabled': isDisabled },
      ]"
    >
      {{ radioText }}
    </div>
    <HLabelV2 v-if="radioTextLabel" :theme="radioTextLabel.theme">
      {{ radioTextLabel.text }}
    </HLabelV2>
  </div>
</template>

<style lang="scss">
.h-radio-holder {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &--centered {
    justify-content: center;
  }
}

.h-radio {
  &__label {
    &--bold {
      font-weight: bold;
    }
    &--disabled {
      color: var(--gray);
      cursor: not-allowed;
    }
  }

  &__element {
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 0;

    &:hover {
      .h-radio__overlay {
        position: absolute;
        left: -6px;
        top: -4px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: var(--primary);
        opacity: 0.08;
      }
    }

    > span {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: none;
      transition: all 0.3s;

      color: var(--gray);
      border: 2px solid var(--primary);

      &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        content: '';
        position: absolute;
        display: none;

        border: solid var(--primary);
        background: var(--primary);
      }
    }

    > input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      margin: 0;
      width: 20px;
      height: 20px;
    }

    > input:checked ~ span:after {
      display: block;
    }

    &--primary {
      > span {
        color: var(--primary);
        border: 2px solid var(--primary);

        &:after {
          border: solid var(--primary);
          background: var(--primary);
        }
      }
    }

    &--isDisabled {
      cursor: not-allowed;
      pointer-events: none;
      > span {
        color: var(--gray);
        border: 2px solid var(--gray);
        background-color: unset;

        &:after {
          border: solid var(--gray);
          background: var(--gray);
        }

        &:hover {
          border: 2px solid var(--gray);
          box-shadow: none;
          background-color: unset;

          &:after {
            border: solid var(--gray);
            background: var(--gray);
          }
        }
      }
    }
  }
}
</style>
