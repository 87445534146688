import type { IWidgetRequest, IWidgetResponse } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_INTEGRATIONS}/api/direct/hpanel-widgets`;

export default {
  getWidgets() {
    return hAsync<IWidgetResponse[]>(
      http.get(URL, { whitelistedStatusCodes: [400, 422, 429] }),
    );
  },
  postWidgets({ data }: { data: { widgets: IWidgetRequest[] } }) {
    return hAsync<IWidgetResponse>(
      http.post(URL, data, { whitelistedStatusCodes: [400, 422, 429] }),
    );
  },
};
