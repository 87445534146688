import { Client, type TBrandId } from '@/types/enums';
import { LANGUAGE_CODE } from '@/types/models';

export const BRAND_LANGUAGE_CODE_MAP = {
  [Client.BrandId.Hostinger.AE]: LANGUAGE_CODE.ar_AR,
  [Client.BrandId.Hostinger.CO_UK]: LANGUAGE_CODE.en_GB,
  [Client.BrandId.Hostinger.FR]: LANGUAGE_CODE.fr_FR,
  [Client.BrandId.Other.NG_CO_ID]: LANGUAGE_CODE.id_ID,
  [Client.BrandId.Hostinger.LT]: LANGUAGE_CODE.lt_LT,
  [Client.BrandId.Hostinger.PT]: LANGUAGE_CODE.pt_PT,
  [Client.BrandId.Hostinger.COM_BR]: LANGUAGE_CODE.pt_BR,
  [Client.BrandId.Hostinger.RU]: LANGUAGE_CODE.ru_RU,
  [Client.BrandId.Hostinger.ES]: LANGUAGE_CODE.es_ES,
  [Client.BrandId.Hostinger.COM_AR]: LANGUAGE_CODE.es_AR,
  [Client.BrandId.Hostinger.CO]: LANGUAGE_CODE.es_CO,
  [Client.BrandId.Hostinger.MX]: LANGUAGE_CODE.es_MX,
  [Client.BrandId.Hostinger.COM_TR]: LANGUAGE_CODE.tr_TR,
  [Client.BrandId.Hostinger.COM_UA]: LANGUAGE_CODE.uk_UA,
  [Client.BrandId.Hostinger.IN]: LANGUAGE_CODE.en_GB,
  [Client.BrandId.Hostinger.DE]: LANGUAGE_CODE.de_DE,
  [Client.BrandId.Hostinger.IT]: LANGUAGE_CODE.it_IT,
  [Client.BrandId.Hostinger.NL]: LANGUAGE_CODE.nl_NL,
  [Client.BrandId.Hostinger.CO_IL]: LANGUAGE_CODE.he_IL,
  [Client.BrandId.Hostinger.PL]: LANGUAGE_CODE.pl_PL,
  [Client.BrandId.Hostinger.VN]: LANGUAGE_CODE.vi_VN,
  [Client.BrandId.Hostinger.IN_TH]: LANGUAGE_CODE.th_TH,
  [Client.BrandId.Hostinger.RO]: LANGUAGE_CODE.ro_RO,
  [Client.BrandId.Hostinger.JP]: LANGUAGE_CODE.ja_JP,
} as Record<TBrandId, TLanguageCode>;

export type TLanguageCode = (typeof LANGUAGE_CODE)[keyof typeof LANGUAGE_CODE];

export const AVAILABLE_LANGUAGE_CONFIG = {
  [LANGUAGE_CODE.id_ID]: {
    id: 26,
    code: 'id_ID',
    locale: 'id',
    localeCapitalized: 'ID',
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 26,
      defaultRequested: 1,
      id: 3925,
    },
  },
  [LANGUAGE_CODE.de_DE]: {
    id: 21,
    code: 'de_DE',
    locale: 'de',
    localeCapitalized: 'DE',
    name: 'German',
    nativeName: 'Deutsch',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 21,
      defaultRequested: 1,
      id: 1,
    },
  },
  [LANGUAGE_CODE.en_GB]: {
    id: 16,
    code: 'en_GB',
    locale: 'en',
    localeCapitalized: 'EN',
    name: 'English (United Kingdom)',
    nativeName: 'English',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 16,
      defaultRequested: 1,
      id: 3923,
    },
  },

  [LANGUAGE_CODE.es_AR]: {
    id: 52,
    code: 'es_AR',
    locale: 'es',
    localeCapitalized: 'ES',
    name: 'Spanish (Argentina)',
    nativeName: 'Español',
    active: 1,
    groupId: 1,
    pivot: {
      brandId: 3,
      languageId: 52,
      defaultRequested: 1,
      id: 3931,
    },
  },

  [LANGUAGE_CODE.es_CO]: {
    id: 53,
    code: 'es_CO',
    locale: 'es',
    localeCapitalized: 'ES',
    name: 'Spanish (Colombia)',
    nativeName: 'Español',
    active: 1,
    groupId: 1,
    pivot: {
      brandId: 3,
      languageId: 53,
      defaultRequested: 1,
      id: 3932,
    },
  },
  [LANGUAGE_CODE.es_MX]: {
    id: 54,
    code: 'es_MX',
    locale: 'es',
    localeCapitalized: 'ES',
    name: 'Spanish (Mexico)',
    nativeName: 'Español',
    active: 1,
    groupId: 1,
    pivot: {
      brandId: 3,
      languageId: 54,
      defaultRequested: 1,
      id: 3933,
    },
  },
  [LANGUAGE_CODE.es_ES]: {
    id: 51,
    code: 'es_ES',
    locale: 'es',
    localeCapitalized: 'ES',
    name: 'Spanish ',
    nativeName: 'Español',
    active: 1,
    groupId: 1,
    pivot: {
      brandId: 3,
      languageId: 51,
      defaultRequested: 1,
      id: 3930,
    },
  },
  [LANGUAGE_CODE.fr_FR]: {
    id: 19,
    code: 'fr_FR',
    name: 'French',
    locale: 'fr',
    localeCapitalized: 'FR',
    nativeName: 'Francais',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 19,
      defaultRequested: 1,
      id: 3924,
    },
  },
  [LANGUAGE_CODE.it_IT]: {
    id: 28,
    code: 'it_IT',
    locale: 'it',
    localeCapitalized: 'IT',
    name: 'Italian',
    nativeName: 'Italiano',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 28,
      defaultRequested: 1,
    },
  },
  [LANGUAGE_CODE.lt_LT]: {
    id: 33,
    code: 'lt_LT',
    locale: 'lt',
    localeCapitalized: 'LT',
    name: 'Lithuanian',
    nativeName: 'Lietuvių',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 33,
      defaultRequested: 1,
      id: 3926,
    },
  },

  [LANGUAGE_CODE.nl_NL]: {
    id: 15,
    code: 'nl_NL',
    locale: 'nl',
    localeCapitalized: 'NL',
    name: 'Dutch',
    nativeName: 'Nederlands',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 15,
      defaultRequested: 1,
    },
  },
  [LANGUAGE_CODE.pt_PT]: {
    id: 44,
    code: 'pt_PT',
    locale: 'pt',
    localeCapitalized: 'PT',
    name: 'Portuguese',
    nativeName: 'Português',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 44,
      defaultRequested: 1,
      id: 3927,
    },
  },
  [LANGUAGE_CODE.pt_BR]: {
    id: 45,
    code: 'pt_BR',
    locale: 'pt',
    localeCapitalized: 'PT',
    name: 'Portuguese (Brazil)',
    nativeName: 'Português',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 45,
      defaultRequested: 1,
      id: 3928,
    },
  },
  [LANGUAGE_CODE.ru_RU]: {
    id: 47,
    code: 'ru_RU',
    locale: 'ru',
    localeCapitalized: 'RU',
    name: 'Russian',
    nativeName: 'Русский',
    active: 1,
    groupId: 4,
    pivot: {
      brandId: 3,
      languageId: 47,
      defaultRequested: 1,
      id: 3929,
    },
  },
  [LANGUAGE_CODE.tr_TR]: {
    id: 57,
    code: 'tr_TR',
    locale: 'tr',
    localeCapitalized: 'TR',
    name: 'Turkish',
    nativeName: 'Türkçe',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 57,
      defaultRequested: 1,
      id: 3934,
    },
  },
  [LANGUAGE_CODE.uk_UA]: {
    id: 58,
    code: 'uk_UA',
    locale: 'uk',
    localeCapitalized: 'UK',
    name: 'Ukrainian',
    nativeName: 'Українська',
    active: 1,
    groupId: 4,
    pivot: {
      brandId: 3,
      languageId: 58,
      defaultRequested: 1,
      id: 3935,
    },
  },
  [LANGUAGE_CODE.zh_CN]: {
    id: 10,
    code: 'zh_CN',
    locale: 'zh',
    localeCapitalized: 'ZH',
    name: 'Chinese',
    nativeName: '中国',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 10,
      defaultRequested: 1,
      id: 3922,
    },
  },
  [LANGUAGE_CODE.hi_IN]: {
    id: 24,
    code: 'hi_IN',
    locale: 'hi',
    localeCapitalized: 'HI',
    name: 'Hindi',
    nativeName: 'हिन्दी',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 24,
      defaultRequested: 1,
      id: 4429,
    },
  },
  [LANGUAGE_CODE.ar_AR]: {
    id: 3,
    code: 'ar_AR',
    locale: 'ar',
    localeCapitalized: 'AR',
    name: 'Arabic',
    nativeName: 'العربية',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 3,
      defaultRequested: 1,
      id: 3921,
    },
  },
  [LANGUAGE_CODE.he_IL]: {
    id: 23,
    code: 'he_IL',
    locale: 'he',
    localeCapitalized: 'HE',
    name: 'Hebrew',
    nativeName: 'עברית',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 23,
      defaultRequested: 1,
      id: 5428,
    },
  },
  [LANGUAGE_CODE.pl_PL]: {
    id: 43,
    code: 'pl_PL',
    locale: 'pl',
    localeCapitalized: 'PL',
    name: 'Polish',
    nativeName: 'polski',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 43,
      defaultRequested: 1,
      id: 5433,
    },
  },
  [LANGUAGE_CODE.vi_VN]: {
    id: 60,
    code: 'vi_VN',
    locale: 'vi',
    localeCapitalized: 'VI',
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 60,
      defaultRequested: 1,
      id: 5445,
    },
  },
  [LANGUAGE_CODE.th_TH]: {
    id: 56,
    code: 'th_TH',
    locale: 'th',
    localeCapitalized: 'TH',
    name: 'Thai',
    nativeName: 'ไทย',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 56,
      defaultRequested: 1,
      id: 5442,
    },
  },
  [LANGUAGE_CODE.ro_RO]: {
    id: 46,
    code: 'ro_RO',
    locale: 'ro',
    localeCapitalized: 'RO',
    name: 'Romanian',
    nativeName: 'română',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 46,
      defaultRequested: 1,
      id: 5436,
    },
  },
  [LANGUAGE_CODE.ja_JP]: {
    id: 29,
    code: 'ja_JP',
    locale: 'ja',
    localeCapitalized: 'JA',
    name: 'Japanese',
    nativeName: '日本語',
    active: 1,
    groupId: null,
    pivot: {
      brandId: 3,
      languageId: 29,
      defaultRequested: 1,
      id: 5816,
    },
  },
} as const;

export type ILanguageConfig =
  (typeof AVAILABLE_LANGUAGE_CONFIG)[keyof typeof AVAILABLE_LANGUAGE_CONFIG];

export type TLocaleCapitalized = ILanguageConfig['localeCapitalized'];
export type TLocale = ILanguageConfig['locale'];

export const AVAILABLE_LANGUAGE_CONFIGURATIONS = Object.values(
  AVAILABLE_LANGUAGE_CONFIG,
);
