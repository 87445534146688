<template>
  <div data-qa="loader" class="data-loader" :class="classes">
    <div
      v-for="pipe in pipes"
      :key="pipe"
      :class="`data-loader__rect${pipe}`"
    />
  </div>
</template>

<script>
import { getClasses } from '@/utils/helpers';

export default {
  name: 'DataLoader',
  props: {
    pipes: {
      type: Number,
      default: 5,
    },
    white: Boolean,
    danger: Boolean,
    info: Boolean,
    dark: Boolean,
    inherit: Boolean,
    sm: Boolean,
    xs: Boolean,
    bottom: Boolean,
    inline: Boolean,
    fixed: Boolean,
  },
  computed: {
    classes() {
      return getClasses('data-loader', this.$props);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/data-loader';
</style>
