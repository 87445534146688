<script setup lang="ts">
import type { FieldError, FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals } from '@/composables';
import { useDomainPurchase } from '@/composables/hDomains/useDomainPurchase';
import { hDomainsRepo } from '@/repositories';
import { useDomainManagementStore } from '@/stores/domain/domainManagementStore';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import type { ResponseErrorHolder } from '@/types';
import { FieldType } from '@/types';
import { getSldTld, toASCII, toUnicode } from '@/utils/helpers';

type Emits = {
  close: [void];
};

const emits = defineEmits<Emits>();
const { fetchHDomainsResources } = useHDomainResourceStore();
const { setDomainsLoadingCompleted } = useDomainManagementStore();
const { t } = useGlobals();
const { validateDomainChars, validateSldLength } = useDomainPurchase();
const store = useStore();

interface Props {
  data: {
    domain: string;
    hResourceId: string;
    headerText: string;
    onOpened?: () => void;
    onSuccess?: (domain: string) => void;
  };
}

const props = defineProps<Props>();

const isSubmitLoading = ref(false);
const customError = ref<FieldError | null>(null);

const domainParts = computed(() => {
  const [sld, tld] = getSldTld(props.data.domain);

  return {
    sld,
    tld,
  };
});

const headerText = computed(
  () =>
    t(props.data.headerText) ||
    t('Domain {domain} is already registered', {
      domain: toUnicode(props.data.domain),
    }),
);

const schema = computed(() => ({
  type: FieldType.text,
  label: 'Choose your domain',
  append: domainParts.value.tld,
  validation: {
    required: true,
    custom: (value: string) => {
      const isSameDomainEntered = value === toUnicode(props.data.domain);

      if (isSameDomainEntered) {
        return { text: 'v2.you.have.entered.the.same.domain' };
      }

      const inputSld = value.replace(domainParts.value.tld, '');

      if (inputSld.includes('.')) {
        return { text: `Name cannot contain the following character '.'` };
      }

      return validateSldLength(value) || validateDomainChars(value);
    },
  },
}));

const submitChangeDomain = async (domain: string) => {
  isSubmitLoading.value = true;
  customError.value = null;
  const domainPunycode = toASCII(domain);
  const [sld, tld] = getSldTld(domainPunycode, {
    omitDot: true,
  });

  const [, err] = await hDomainsRepo.updateSld(props.data.hResourceId, {
    domain: sld,
    tld,
  });

  if (err) {
    customError.value = {
      text: (err as ResponseErrorHolder)?.error?.message || '',
    };
    isSubmitLoading.value = false;

    return;
  }

  setDomainsLoadingCompleted(false);
  await Promise.all([
    store.dispatch('fetchHostingOrders'),
    fetchHDomainsResources(),
  ]);
  setDomainsLoadingCompleted(true);

  isSubmitLoading.value = false;

  props.data?.onSuccess?.(domainPunycode);
};

props.data.onOpened?.();
</script>

<template>
  <div>
    <h2 class="h-mb-8">{{ headerText }}</h2>
    <p class="h-mb-24">
      {{
        t(
          'Select a different domain with the same ending. After confirming you will be forwarded to domain registration.',
        )
      }}
    </p>
    <HForm
      @on-submit="({ values }: FormValues) => submitChangeDomain(values.domain)"
    >
      <HFormField name="domain" :schema="schema" :custom-error="customError" />
      <HModalActions
        no-padding
        mobile-reverse
        class="h-mt-24"
        close-text="Cancel"
        @close="emits('close')"
      >
        <HButton
          h-form-submit
          :is-loading="isSubmitLoading"
          data-qa="submit-change-sld-form"
        >
          {{ t('Confirm') }}
        </HButton>
      </HModalActions>
    </HForm>
  </div>
</template>
