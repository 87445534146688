<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import NetPromoterScore from '@/components/Nps/NetPromoterScore.vue';
import { Onboarding } from '@/types';
import { timeout } from '@/utils/helpers';

const route = useRoute();

type Props = {
  isOpen?: boolean;
  isOpenedByUser?: boolean;
  highlight?: boolean;
};

interface Emits {
  (eventName: 'h-popup-close'): void;
}

const popupRef = ref<HTMLElement | null>(null);
const props = defineProps<Props>();

const isOnboardingRoute = computed(() =>
  route.name?.toString().includes(Onboarding.Type.ONBOARDING),
);

defineEmits<Emits>();

watch(
  () => props.highlight,
  async () => {
    popupRef.value?.classList.add('h-popup--highlight');
    await timeout(1800);
    popupRef.value?.classList?.remove('h-popup--highlight');
  },
);
</script>

<template>
  <Transition name="slide">
    <div
      v-if="isOpen"
      ref="popupRef"
      class="h-popup"
      :class="{
        'h-popup--left': isOpenedByUser,
        'h-popup--left-corner': isOnboardingRoute,
      }"
    >
      <div class="h-popup-content">
        <NetPromoterScore
          :is-opened-by-user="isOpenedByUser"
          @h-popup-close="$emit('h-popup-close')"
        />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.h-popup {
  position: fixed;
  right: 100px;
  bottom: -2px;
  max-height: 500px;
  width: 492px;
  background: var(--light);
  border: 1px solid var(--gray-border);
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 0px 12px rgba(29, 30, 32, 0.16);
  max-width: 100%;
  z-index: var(--z-index-3);

  &--highlight {
    animation: highlightBorder 1.8s linear;
  }

  &--left {
    left: 295px;
    @media (max-width: 806px) {
      left: 0;
    }
  }

  &--left-corner {
    left: 24px;
  }

  @media (max-width: 590px) {
    left: 0;
  }

  &-content {
    padding: 24px 32px;
    width: 100%;
    @media (max-width: 590px) {
      padding: 16px;
    }
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}

.slide-enter-active {
  transition: all 0.9s ease-out;
}
.slide-leave-active {
  transition: all 0.9s ease-out;
}

.slide-enter,
.slide-leave-to {
  max-height: 0px;
}

@keyframes highlightBorder {
  0% {
    border-color: rgba(103, 61, 230, 1);
  }
  25% {
    border-color: rgba(103, 61, 230, 0);
  }
  50% {
    border-color: rgba(103, 61, 230, 1);
  }
  75% {
    border-color: rgba(103, 61, 230, 0);
  }
  to {
    border-color: rgba(103, 61, 230, 1);
  }
}
</style>
