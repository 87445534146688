import dayjs from 'dayjs';
import cookies from 'js-cookie';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  useBrands,
  useResourceFilters,
  useResourceMapper,
} from '@/composables';
import {
  UpgradePerk,
  mapCommonPerksHostingPlanByName,
} from '@/data/hosting/hostingUpgradePerks';
import { defaultSaleTheme } from '@/data/sale/bannerData';
import { SALES_CONFIGURATION } from '@/data/sale/saleConfiguration';
import { useOrdersStore, useProfileStore } from '@/stores';
import type { ISubscriptionOrder, TBrandId } from '@/types';
import { Client, Hosting, Route, Sale } from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import {
  convertReadableToUnixMs,
  isCurrentTimeWithinDates,
  isDateAfterSecondDate,
} from '@/utils/helpers/timeHelpers';

export const useSaleCampaign = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const profileStore = useProfileStore();

  const clientBrandId = computed(() => profileStore.account?.brand.id || '');

  const { isFromAnotherBrand, hasBrand } = useBrands();
  const {
    upgradableHostingResources,
    activeResources,
    activeHostingResources,
  } = useResourceMapper();
  const ordersStore = useOrdersStore();
  const { isDomainOnlyUser } = useResourceFilters();

  const isCampaignActiveForReseller = (
    activeResellers: TBrandId[] | undefined,
    excludedResellers: TBrandId[] | undefined,
  ): boolean => {
    const brandId = clientBrandId.value;

    if (!brandId) return false;

    if (!activeResellers?.length && !excludedResellers?.length) return true;

    if (excludedResellers?.includes(brandId)) {
      return false;
    }

    return !activeResellers?.length || activeResellers.includes(brandId);
  };

  const isSaleCampaignActivatedByCookie = (name: string | undefined) =>
    !!(name && cookies.get(name));

  const activeSale = computed(() =>
    SALES_CONFIGURATION.find(
      ({
        startsAt,
        endsAt,
        activeResellers,
        activationCookie,
        excludedResellers,
      }) =>
        isCampaignActiveForReseller(activeResellers, excludedResellers) &&
        (isSaleCampaignActivatedByCookie(activationCookie) ||
          isCurrentTimeWithinDates({ startsAt, endsAt })),
    ),
  );

  const lastActiveSaleEndDate = computed(() =>
    dayjs.max(SALES_CONFIGURATION.map(({ endsAt }) => dayjs(endsAt))),
  );

  const isSaleOfferAvailable = computed(() => !!activeSale.value);

  const userHasOneHostingAndUpgraded = computed(() => {
    if (upgradableHostingResources.value.length > 1) {
      return false;
    }

    return hasUserUpgradedHosting.value;
  });

  const hasUserUpgradedHosting = computed(() => {
    const upgradedOrders: ISubscriptionOrder[] =
      store.getters['orders/getUpgradedOrders'];
    const upgradedOrdersLoaded =
      store.getters['orders/getUpgradedOrdersLoaded'];

    if (!upgradedOrders.length || !upgradedOrdersLoaded) return false;

    const ordersUpgradedAfterSaleStart = upgradedOrders.filter((order) =>
      isDateAfterSecondDate(order.updatedAt, activeSale.value?.startsAt || ''),
    );

    const allOrderIds = ordersUpgradedAfterSaleStart.flatMap((order) =>
      order.items.map((item) => item.chargebeeSubscriptionId),
    );

    const hasAnyHostingPlanUpgradedAfterSaleStart =
      activeHostingResources.value.filter(
        (resource) =>
          resource?.chargebeeSubscriptionId &&
          allOrderIds.includes(resource.chargebeeSubscriptionId),
      );

    return (
      upgradableHostingResources.value.length === 1 &&
      hasAnyHostingPlanUpgradedAfterSaleStart.length
    );
  });

  const isReseller = (brandId: string) => clientBrandId.value === brandId;

  const isTimerVisible = computed(() => {
    const { showTimer, timerStartsAt, timerEndsAt } = activeSale.value || {};

    if (!timerStartsAt || !timerEndsAt) return false;

    return (
      !!showTimer &&
      isCurrentTimeWithinDates({ startsAt: timerStartsAt, endsAt: timerEndsAt })
    );
  });

  const countdownEndDate = computed(() => {
    if (!activeSale.value) return undefined;

    const timerEndDate = dayjs(activeSale.value.timerEndsAt);
    const timerStartDate = dayjs(activeSale.value.timerStartsAt);

    const today = dayjs();

    if (timerEndDate.isBefore(today)) return undefined;
    if (today.isBefore(timerStartDate)) return undefined;

    return timerEndDate.toISOString();
  });

  const redirectToOffer = () => {
    router.push({
      name: activeSale.value?.offerRouteName,
      // @ts-ignore
      query: { f: route.name },
    });
  };

  const getSaleColorId = computed(() => {
    if (!activeSale.value) return;

    const COLOR_MAP = {
      [Sale.NEW_YEAR]: 'primary',
      [Sale.MONSOON_SALE]: 'white-blue',
      [Sale.INDEPENDENCE_DAY]: 'primary',
      [Sale.SEPTEMBER_SALE]: 'primary-light',
      [Sale.BLACK_FRIDAY]: isEligibleForBlackFridayDarkMode.value
        ? 'black'
        : 'meteorite',
      [Sale.CYBER_WEEK]: isEligibleForBlackFridayDarkMode.value
        ? 'black'
        : 'meteorite',
      default: 'white-blue',
    };

    return mapKeyValue(COLOR_MAP, activeSale.value.name);
  });

  const getSaleOfferTheme = computed(() => {
    if (!activeSale.value) return defaultSaleTheme;

    const THEME_MAP = {
      default: 'default',
      [Sale.BLACK_FRIDAY]: isEligibleForBlackFridayDarkMode.value
        ? 'default'
        : 'meteorite',
      [Sale.CYBER_WEEK]: isEligibleForBlackFridayDarkMode.value
        ? 'default'
        : 'meteorite',
    };

    return mapKeyValue(THEME_MAP, activeSale.value.name);
  });

  type SaleRoutes = Route.Base.HOME | Route.Base.WEBSITES | Route.Base.HOSTING;

  const redirectedFrom = computed(() => {
    const eligibleRouteNames = [
      Route.Base.WEBSITES,
      Route.Base.HOSTING,
      Route.Base.HOME,
    ];

    const fromQuery = route.query?.f as SaleRoutes;

    return eligibleRouteNames.includes(fromQuery) ? fromQuery : undefined;
  });

  const mapHostingPerksByPlanName = (planName: string) => {
    const plan = mapCommonPerksHostingPlanByName(planName);

    const perksMap = {
      [Hosting.Plan.STARTER]: [
        UpgradePerk.WEBSITES,
        UpgradePerk.VISITS_MONTHLY,
        UpgradePerk.DISK_SPACE,
        UpgradePerk.EMAIL_ACCOUNTS,
        UpgradePerk.DATABASES,
      ],
      [Hosting.Plan.PREMIUM]: [
        UpgradePerk.PERFORMANCE,
        UpgradePerk.DISK_SPACE,
        UpgradePerk.DAILY_BACKUPS,
        UpgradePerk.FREE_CDNS,
        UpgradePerk.WORDPRESS_AI_TOOLS,
        UpgradePerk.WORDPRESS_STAGING,
        UpgradePerk.DDOS_PROTECTION,
      ],
      [Hosting.Plan.BUSINESS]: [
        UpgradePerk.PERFORMANCE,
        UpgradePerk.WEBSITES,
        UpgradePerk.PRIORITY_SUPPORT,
        UpgradePerk.DEDICATED_IP,
        UpgradePerk.FILES_AND_DIRECTORIES,
        UpgradePerk.PHP_WORKERS_LIMIT,
        UpgradePerk.WOO_COMMERCE,
      ],
      [Hosting.Plan.CLOUD_ECONOMY]: [
        UpgradePerk.DISK_SPACE,
        UpgradePerk.RAM,
        UpgradePerk.CPU_CORES,
        UpgradePerk.FILES_AND_DIRECTORIES,
        UpgradePerk.PHP_WORKERS_LIMIT,
      ],
      [Hosting.Plan.CLOUD_PROFESSIONAL]: [
        UpgradePerk.DISK_SPACE,
        UpgradePerk.RAM,
        UpgradePerk.CPU_CORES,
        UpgradePerk.FILES_AND_DIRECTORIES,
        UpgradePerk.PHP_WORKERS_LIMIT,
      ],
      default: [
        UpgradePerk.VISITS_MONTHLY,
        UpgradePerk.DISK_SPACE,
        UpgradePerk.DATABASES,
        UpgradePerk.WEBSITES,
        UpgradePerk.BANDWIDTH,
      ],
    };

    return mapKeyValue(perksMap, plan);
  };

  const timerEndDate = computed(() => {
    if (!activeSale.value?.timerEndsAt || !activeSale.value?.timerStartsAt) {
      return undefined;
    }

    return convertReadableToUnixMs(activeSale.value.timerEndsAt);
  });

  const isBlackFridayPurchaseAvailable = computed(() => {
    const restrictedBrands: TBrandId[] = [
      Client.BrandId.Other.HOSTING24,
      Client.BrandId.Other.WEBLINK,
      Client.BrandId.Other.HOSTMANIA,
      Client.BrandId.Other.NG_CO_ID,
    ];

    return (
      isDomainOnlyUser.value ||
      (!activeResources.value.length && !hasBrand(restrictedBrands))
    );
  });

  const isUpgradeHostingSaleAvailable = computed(
    () => !!ordersStore.upgradableOrders.length,
  );

  const isSummerSaleAvailable = computed(
    () =>
      activeSale.value?.name === Sale.SUMMER_SALE &&
      isUpgradeHostingSaleAvailable.value,
  );

  const isEligibleForSale2024 = computed(() => !isFromAnotherBrand.value);

  const isBlackFridaySaleAvailable = computed(() => {
    const blackFridaySales2024 = [
      Sale.BLACK_FRIDAY,
      Sale.BLACK_FRIDAY_COM,
      Sale.BLACK_FRIDAY_MX,
      Sale.BLACK_FRIDAY_AR,
    ];

    return (
      isEligibleForSale2024.value &&
      activeSale.value?.name &&
      blackFridaySales2024.includes(activeSale.value.name)
    );
  });

  const isCyberWeekSaleAvailable = computed(() => {
    const cyberWeek2024 = [Sale.CYBER_WEEK, Sale.CYBER_WEEK_AR];

    return (
      isEligibleForSale2024.value &&
      activeSale.value?.name &&
      cyberWeek2024.includes(activeSale.value.name) &&
      !isBlackFridaySaleAvailable.value
    );
  });

  const blackFridayTheme = computed<'black' | 'white' | undefined>(() => {
    if (!isBlackFridaySaleAvailable.value && !isCyberWeekSaleAvailable.value) {
      return undefined;
    }
    if (isEligibleForBlackFridayDarkMode.value) return 'black';

    return 'white';
  });

  const isEligibleForDarkMode = computed(() => {
    const cyberWeek2024 = [Sale.CYBER_WEEK, Sale.CYBER_WEEK_AR];

    if (
      activeSale.value?.name &&
      cyberWeek2024.includes(activeSale.value.name)
    ) {
      return false;
    }

    return !hasBrand([
      Client.BrandId.Hostinger.PK,
      Client.BrandId.Hostinger.AE,
      Client.BrandId.Hostinger.COM_TR,
      Client.BrandId.Hostinger.MX,
      Client.BrandId.Hostinger.COM_AR,
    ]);
  });

  const isEligibleForBlackFridayDarkMode = computed(
    () => !!(isEligibleForDarkMode.value && isBlackFridaySaleAvailable.value),
  );

  const blackFridayHeadlineText = computed(() => {
    const defaultHeadlineText =
      'The Biggest Ever <mark>Black Friday</mark> Sale';

    // Special case for CO and COM_AR brands as they suppose to show cyber week
    // in advance (Oct 30 - Nov 13) and then - black friday - going further
    const isCyberWeekActive = isCurrentTimeWithinDates({
      startsAt: '2023-10-30T07:00:00Z',
      endsAt: '2023-11-13T08:00:00Z',
    });

    const specialCaseHeadline = isCyberWeekActive
      ? 'The Biggest Ever <mark>Cyber Week</mark> Sale'
      : defaultHeadlineText;

    const HEADLINE_TEXT_MAP = {
      [Client.BrandId.Hostinger.MX]: 'End of the year price',
      [Client.BrandId.Hostinger.AE]: defaultHeadlineText.replace(
        'Black',
        'White',
      ),
      [Client.BrandId.Hostinger.PK]: defaultHeadlineText.replace(
        'Black',
        'Blessed',
      ),
      [Client.BrandId.Hostinger.COM_TR]: defaultHeadlineText.replace(
        'Black',
        'Amazing',
      ),
      [Client.BrandId.Hostinger.CO]: specialCaseHeadline,
      [Client.BrandId.Hostinger.COM_AR]: specialCaseHeadline,
      default: defaultHeadlineText,
    };

    return mapKeyValue(HEADLINE_TEXT_MAP, clientBrandId.value) as string;
  });

  return {
    activeSale,
    redirectToOffer,
    getSaleColorId,
    isReseller,
    isEligibleForDarkMode,
    isEligibleForBlackFridayDarkMode,
    isCampaignActiveForReseller,
    redirectedFrom,
    isTimerVisible,
    isBlackFridaySaleAvailable,
    isCyberWeekSaleAvailable,
    isBlackFridayPurchaseAvailable,
    isUpgradeHostingSaleAvailable,
    isSaleOfferAvailable,
    isSummerSaleAvailable,
    mapHostingPerksByPlanName,
    countdownEndDate,
    timerEndDate,
    blackFridayHeadlineText,
    getSaleOfferTheme,
    userHasOneHostingAndUpgraded,
    lastActiveSaleEndDate,
    blackFridayTheme,
  };
};
