import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import { useAppStore } from '@/stores';
import type { Experiment } from '@/types';
import experimentService from '@/utils/services/experimentService';

export interface UseExperimentReturn<Step extends number> {
  experimentVariant: Ref<Step>;
  isExperimentVariantActive: ComputedRef<Record<Step, boolean>>;
  isExperimentActive: ComputedRef<boolean>;
  isAnyExperimentVariantActive: ComputedRef<boolean>;
}

/**
 * Usage 0 / 1 variants
 * const { isExperimentActive } = useExperiment(Experiment.ID.SOME_EXPERIMENT_ID);
 *
 * Usage with multiple variants
 * const { isExperimentVariantActive } = useExperiment(Experiment.ID.SOME_EXPERIMENT_ID, [0, 1, 2, 3, 4]);
 *
 * isExperimentVariantActive.value['3'] // true if variant is 3
 * isExperimentVariantActive.value['2'] // false if variant is 3
 */
export const useExperiment = <T extends number>(
  experimentId: Experiment.ID,
  variants?: T[],
): UseExperimentReturn<T> => {
  const appStore = useAppStore();
  const experimentVariant = computed<any>(
    () =>
      (appStore.isAppLoaded &&
        experimentService.getExperimentVariantByID(experimentId)) ||
      0,
  );

  const isExperimentActive = computed(() => experimentVariant.value > 0);

  const constructExperimentVariant = (activeVariant: T) =>
    (variants || []).reduce(
      (acc, variant) => ({
        ...acc,
        [variant]: activeVariant === variant,
      }),
      {},
    );

  const isExperimentVariantActive = computed((): any =>
    constructExperimentVariant(experimentVariant.value),
  );

  const isAnyExperimentVariantActive = computed((): boolean => {
    const variant = experimentService.getExperimentById(experimentId)?.key;

    if (!variant) {
      return false;
    }

    return Object.keys(isExperimentVariantActive.value).includes(variant);
  });

  return {
    experimentVariant,
    isExperimentActive,
    isExperimentVariantActive,
    isAnyExperimentVariantActive,
  };
};
