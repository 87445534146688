<template>
  <div
    v-if="loading"
    class="circle-loader"
    data-qa="loader"
    :class="{ ...classes, ...colorClasses }"
  >
    <svg class="circular">
      <circle
        class="full-circle"
        cx="25"
        cy="25"
        stroke-width="3"
        fill="none"
        r="20"
      />
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="3"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
//JSON-slugs-migrated
export default {
  name: 'CircleLoader',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    sm: Boolean,
    xs: Boolean,
    centered: Boolean,
    absolute: Boolean,
    success: Boolean,
    info: Boolean,
    warning: Boolean,
    danger: Boolean,
    brand: Boolean,
    gray: Boolean,
    secondary: Boolean,
    primary: Boolean,
    dark: Boolean,
    outline: Boolean,
    text: Boolean,
  },
  computed: {
    hasColor() {
      return (
        this.success ||
        this.info ||
        this.warning ||
        this.danger ||
        this.gray ||
        this.brand ||
        this.secondary ||
        this.primary ||
        this.dark
      );
    },
    classes() {
      return {
        'circle-loader--sm': this.sm,
        'circle-loader--xs': this.xs,
        'circle-loader--centered': this.centered,
        'circle-loader--absolute': this.absolute,
      };
    },
    colorClasses() {
      return {
        'circle-loader--light': !this.text && !this.outline && this.hasColor,
        'circle-loader--primary':
          this.primary ||
          !this.hasColor ||
          (this.text && this.hasColor) ||
          (this.outline && this.hasColor),
        'circle-loader--danger':
          (!this.text && this.danger) || (this.outline && this.danger),
        'circle-loader--dark':
          (this.text && this.dark) || (this.outline && this.dark),
        'circle-loader--success':
          (this.text && this.success) || (this.outline && this.success),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/*!rtl:begin:ignore*/
.circle-loader {
  .full-circle {
    stroke-dasharray: 200, 200;
    stroke: var(--primary-light);
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 50px;
    width: 50px;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;

    stroke: var(--primary);
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  &--primary .path {
    stroke: var(--primary) !important;
  }

  &--secondary .path {
    stroke: var(--secondary) !important;
  }

  &--success .path {
    stroke: var(--success) !important;
  }

  &--info .path {
    stroke: var(--primary-hostinger) !important;
  }

  &--warning .path {
    stroke: var(--warning) !important;
  }

  &--warning-regular .path {
    stroke: var(--warning-regular) !important;
  }

  &--danger .path {
    stroke: var(--danger) !important;
  }

  &--light .path {
    stroke: var(--light) !important;
  }

  &--dark .path {
    stroke: var(--dark) !important;
  }

  &--black .path {
    stroke: var(--dark) !important;
  }

  &--gray .path {
    stroke: var(--gray) !important;
  }

  &--gray-light .path {
    stroke: var(--gray-light) !important;
  }

  &--header-bg .path {
    stroke: var(--header-bg) !important;
  }

  &--danger-light .path {
    stroke: var(--danger-light) !important;
  }

  &--success-dark .path {
    stroke: var(--success-dark) !important;
  }

  &--success-light .path {
    stroke: var(--success-light) !important;
  }

  &--warning-light .path {
    stroke: var(--warning-light) !important;
  }

  &--warning-dark .path {
    stroke: var(--warning-dark) !important;
  }

  &--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px !important;
    margin-top: -25px !important;
  }

  &--absolute-right {
    left: auto;
    right: 0;
  }

  &--sm {
    transform: scale(0.7);
  }

  &--xs {
    transform: scale(0.5);
  }

  &--centered {
    margin: auto;
    display: block;
    text-align: center;
  }
}
/*!rtl:end:ignore*/
</style>
