import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useCookies } from '@/composables';
import { hVpsRepo, surveyRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type {
  IDataCenter,
  IServerSetupRequest,
  ServerOS,
  VpsServer,
} from '@/types';
import {
  Cookie,
  HVps,
  ONBOARDING_SURVEY_IDENTIFIER,
  STORE_PERSISTENT_KEYS,
} from '@/types';

interface IOnboardingData {
  dataCenter?: IDataCenter | null;
  osType: string;
  template: ServerOS | null;
  hostname: string;
  rootPassword: string;
  osPassword: string;
  sshKey: {
    name: string;
    sshKey: string;
  };
  vmId: string | number | null;
  setupInitiated: boolean;
  domain: string;
  installMonarx?: boolean;
}

export const useVpsOnboardingStore = defineStore(
  'vpsOnboarding',
  () => {
    const serverStore = useVpsServerStore();
    const { cookies, setCookies } = useCookies();

    const initialData = {
      dataCenter: null,
      osType: '',
      template: null,
      hostname: '',
      rootPassword: '',
      osPassword: '',
      sshKey: {
        name: '',
        sshKey: '',
      },
      vmId: null,
      setupInitiated: false,
      domain: '',
      installMonarx: false,
    };

    const orderId = ref('');
    const onboardingData = ref<IOnboardingData[]>([]);

    const isSurveyEligible = ref(false);

    const defaultHostname = computed(
      () =>
        HVps.ServerHost.PREFIX +
        currentOnboarding.value.vmId +
        HVps.ServerHost.SUFFIX,
    );

    const setSurveyEligibility = (isEligible: boolean) => {
      isSurveyEligible.value = isEligible;
    };

    const fillOnboardingData = (orderId: string, data: IOnboardingData) => {
      setOrderId(orderId);

      const index = onboardingData.value.findIndex(
        (item) => item.vmId === orderId,
      );

      if (index !== -1) {
        onboardingData.value[index] = data;

        return;
      }

      onboardingData.value.push(data);
    };

    const domainToFind = (domain: string) =>
      onboardingData.value.findIndex(({ domain: d }) => d === domain);

    const updateExistingDomainState = (
      domainIndex: number,
      param: string,
      data: object,
    ) => {
      const domainState = onboardingData.value[domainIndex];
      onboardingData.value[domainIndex] = { ...domainState, [param]: data };
    };

    const createNewDomainState = (
      requestDomain: string,
      param: string,
      data: object,
    ) => {
      const newDomainState = {
        ...cloneDeep(initialData),
        domain: requestDomain,
        [param]: data,
      };
      onboardingData.value.push(newDomainState);
    };

    const setOnboardingData = (
      requestDomain: string,
      param: string,
      data: any,
    ) => {
      const domainIndex = domainToFind(requestDomain);

      if (domainIndex !== -1) {
        updateExistingDomainState(domainIndex, param, data);
      } else {
        createNewDomainState(requestDomain, param, data);
      }
    };

    const getOnboardingData = <T extends keyof IOnboardingData>(
      requestDomain: string,
      param?: T,
    ) => {
      const domainState = onboardingData.value.find(
        ({ domain }) => domain === requestDomain,
      );

      if (param) {
        return domainState ? domainState[param] : cloneDeep(initialData[param]);
      }

      return domainState || cloneDeep(initialData);
    };

    const setOrderId = (newOrderId: string) => {
      orderId.value = newOrderId;
    };

    const setState = (key: string, value: any) =>
      setOnboardingData(orderId.value, key, value);

    const currentOnboarding = computed(
      () => getOnboardingData(orderId.value) as IOnboardingData,
    );

    const isOnboardingCompleted = computed(() => {
      const vmState = serverStore.servers.find(
        ({ id }: VpsServer) => id === currentOnboarding.value.vmId,
      )?.state;

      return vmState === HVps.ServerState.RUNNING;
    });

    const serverSetup = async ({
      serverId,
      details,
    }: {
      serverId: string;
      details: IServerSetupRequest;
    }) => {
      const [{ data }, error] = await hVpsRepo.setup(
        Number(serverId),
        details,
        {},
      );

      if (error) {
        serverStore.setServerLock(Number(serverId), false);

        throw new Error(JSON.stringify(error));
      }

      serverStore.setServerLock(Number(serverId), true);
      serverStore.setUpdatingServer({
        serverId: Number(serverId),
        duration: data.template.installTime,
        isCreating: true,
      });
    };

    const fetchSurveyEligibility = async (orderId: string) => {
      const [{ data }, error] = await surveyRepo.getIsEligibleForSurvey(
        orderId,
        ONBOARDING_SURVEY_IDENTIFIER.VPS,
      );

      if (error) return;

      setSurveyEligibility(data.isEligible);

      if (cookies[Cookie.VPS_ONBOARDING_SURVEY_SKIP]) {
        setSurveyEligibility(false);
      }
    };

    const skipSurvey = () => {
      setCookies(Cookie.VPS_ONBOARDING_SURVEY_SKIP, 'true');

      setSurveyEligibility(false);
    };

    return {
      orderId,
      onboardingData,
      isOnboardingCompleted,
      currentOnboarding,
      setState,
      setOrderId,
      serverSetup,
      defaultHostname,
      fillOnboardingData,
      isSurveyEligible,
      fetchSurveyEligibility,
      skipSurvey,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_ONBOARDING },
  },
);
