export const preloadImages = (
  imageSources: string[],
  onImageLoad?: (onImageLoad: string) => void,
): void => {
  imageSources.forEach((src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => onImageLoad?.(src);

    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'prefetch');
    linkEl.setAttribute('href', src);
    linkEl.setAttribute('as', 'image');
    document.head.appendChild(linkEl);
  });
};

export const promisePreloadImages = async (imageSources: string[]) => {
  const promises = imageSources.map(
    (imageSource) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image.height);
        image.onerror = () => {
          reject(new Error(`Failed to load image: ${imageSource}`));
        };
        image.src = imageSource;
      }),
  );

  await Promise.all(promises);
};
