export const ExternalRoutes = {
  Websites: {
    WEBSITE_BUILDER: 'website-builder',
    WORDPRESS_HOSTING: 'wordpress',
    WORDPRESS: 'wordpress-hosting',
    WOO_COMMERCE: 'woocommerce-hosting',
    ECOMMERCE_WEBSITE: 'ecommerce-website',
    LANDING_PAGE_BUILDER: 'landing-page-builder',
    WEDDING_WEBSITE: 'wedding-website',
    RESTAURANT_WEBSITE: 'restaurant-website',
    AI_LANDING_PAGE_BUILDER: 'ai-landing-page-builder',
    PHOTOGRAPHY_WEBSITE: 'photography-website',
    BLOG_MAKER: 'blog-maker',
    WEBSITE_DESIGN: 'website-design',
    PORTFOLIO_WEBSITE: 'portfolio-website',
    BUSINESS_WEBSITE: 'business-website',
    WEBSITE_TEMPLATES: 'website-templates',
    EVENT_WEBSITE: 'event-website',
    FITNESS_WEBSITE: 'fitness-website',
  },
} as const;

export const WORDPRESS_HOSTING_BUY_PAGES: string[] = [
  ExternalRoutes.Websites.WORDPRESS,
  ExternalRoutes.Websites.WORDPRESS_HOSTING,
  ExternalRoutes.Websites.WOO_COMMERCE,
];

export const WEBSITE_BUILDER_BUY_PAGES: string[] = [
  ExternalRoutes.Websites.WEBSITE_BUILDER,
  ExternalRoutes.Websites.ECOMMERCE_WEBSITE,
  ExternalRoutes.Websites.LANDING_PAGE_BUILDER,
  ExternalRoutes.Websites.WEDDING_WEBSITE,
  ExternalRoutes.Websites.RESTAURANT_WEBSITE,
  ExternalRoutes.Websites.AI_LANDING_PAGE_BUILDER,
  ExternalRoutes.Websites.PHOTOGRAPHY_WEBSITE,
  ExternalRoutes.Websites.BLOG_MAKER,
  ExternalRoutes.Websites.WEBSITE_DESIGN,
  ExternalRoutes.Websites.PORTFOLIO_WEBSITE,
  ExternalRoutes.Websites.BUSINESS_WEBSITE,
  ExternalRoutes.Websites.WEBSITE_TEMPLATES,
  ExternalRoutes.Websites.EVENT_WEBSITE,
  ExternalRoutes.Websites.FITNESS_WEBSITE,
];
