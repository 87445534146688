<template>
  <HBreadcrumbs
    :breadcrumbs="breadcrumbs"
    @navigate-to-home="navigateToHome"
    @navigate-to-route="navigateToRoute"
  />
</template>

<script>
import { isObject, isArray } from 'lodash';
import { mapGetters } from 'vuex';

import HBreadcrumbs from '@/components/HBreadcrumbs.vue';
import { RedirectMutations, AmplitudeLocation, Route as Routes } from '@/types';
import { toUnicode, replaceParameters } from '@/utils/helpers';
import Route from '@/utils/services/navigation/RouteClass';
import RouteParser from '@/utils/services/navigation/RouteParser';

export default {
  props: {
    breadCrumbs: [Array, Boolean],
  },
  components: {
    HBreadcrumbs,
  },
  data() {
    return {
      routerCurrent: this.$router.currentRoute.value,
    };
  },
  computed: {
    currentRoute() {
      return new Route(this.routerCurrent);
    },

    currentRouteBreadTitle() {
      return this.generateBreadcrumbTitle(this.currentRoute);
    },

    isCustomBreads() {
      return (
        isArray(this.breadCrumbs) &&
        this.breadCrumbs.find((bread) => bread.url && bread.title)
      );
    },

    breadcrumbs() {
      if (
        !this.getAccountAllowManage(this.$route.params.domain) &&
        this.$route.name === Routes.Order.ORDER_UPGRADE
      ) {
        return [];
      }

      const breads = this.isCustomBreads
        ? this.breadCrumbs.map((bread) => {
            if ((bread.url || bread.url === null) && bread.title) return bread;

            return RouteParser.getRouteByName(bread);
          })
        : [
            ...this.currentRoute.breadcrumbs,
            {
              name: toUnicode(this.currentRouteBreadTitle),
              url: this.currentRoute.url,
            },
          ];

      return breads.map((bread) => ({
        title: this.breadTitle(bread) || bread.name,
        url: {
          url: bread.url,
          name: bread.path?.name,
        },
      }));
    },
    ...mapGetters(['getAccountAllowManage']),
  },
  watch: {
    $route() {
      this.routerCurrent = this.$router.currentRoute.value;
    },
  },
  methods: {
    breadTitle(breadItem) {
      const title = this.generateBreadcrumbTitle(breadItem);

      return breadItem.element ? toUnicode(title) : breadItem.title;
    },
    navigateToHome() {
      this.$router.push({
        name: Routes.Base.HOME,
        query: { amplitudeLocation: AmplitudeLocation.Custom.BREADCRUMBS },
      });
    },
    navigateToRoute(e) {
      if (e.url.includes('showIframe')) {
        this.$store.commit(RedirectMutations.UNSET_REDIRECT);
      }

      if (e.name) {
        this.$router.push({
          name: e.name,
          query: {
            redirectLocation: AmplitudeLocation.Custom.BREADCRUMBS,
          },
        });

        return;
      }

      this.$router.push(e.url);
    },
    generateBreadcrumbTitle(breadcrumb) {
      const breadcrumbTitle = breadcrumb.breadcrumbsTitle;
      const routeParams = {
        ...this.$router.currentRoute.value.params,
        ...this.$redirect.params,
      };

      if (isObject(breadcrumbTitle)) {
        return breadcrumbTitle?.translate
          ? this.$t(breadcrumbTitle.title, routeParams)
          : replaceParameters(breadcrumbTitle.title, routeParams);
      }

      return breadcrumbTitle && this.$router.currentRoute.value.params
        ? this.$t(breadcrumbTitle, this.$router.currentRoute.value.params)
        : '';
    },
  },
};
</script>

<style lang="scss">
@import '../sass/components/nav';
</style>
