<script setup lang="ts">
import { computed } from 'vue';

type Props = {
  title: string;
  value?: string;
  active: boolean;
  error: string;
  loading: boolean;
};

interface Emits {
  (eventName: 'on-toggle'): void;
  (eventName: 'on-for-free'): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const toggleCoupon = () => emit('on-toggle');

const toggleText = computed(() => {
  if (props.active) return 'Cancel';

  return props.title !== 'Coupon code' ? 'Add coupon code' : 'Add';
});
</script>

<template>
  <div class="inactive-coupon">
    <div class="inactive-coupon__header">
      <h4 v-trans>{{ title }}</h4>
      <p
        v-trans
        class="inactive-coupon__action"
        data-qa="toggle-coupon"
        @click="toggleCoupon"
      >
        {{ toggleText }}
      </p>
    </div>
    <p class="text-dark" data-qa="price-total">{{ value }}</p>
  </div>
  <p v-if="!active" v-trans class="inactive-coupon-error">{{ error }}</p>
</template>

<style lang="scss" scoped>
.inactive-coupon {
  display: flex;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: left;
  }

  &__action {
    color: var(--primary);
    font-weight: 700;
    margin-left: 8px;
    cursor: pointer;
  }
}

.inactive-coupon-error {
  margin-top: -8px;
  margin-bottom: 8px;
  color: var(--danger);
}
</style>
