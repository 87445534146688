export enum BreadcrumbCategory {
  REQUEST_ERROR = 'xhr-error',
  CUSTOM_BREADCRUMB = 'custom-breadcrumb',
}

export enum BreadcrumbLogLevel {
  FATAL = 'fatal',
  CRITICAL = 'critical',
  ERROR = 'error',
  WARNING = 'warning',
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug',
}
