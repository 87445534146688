import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  BACKUP_STATUS,
  STORE_PERSISTENT_KEYS,
  type WordpressPage,
  Website,
} from '@/types';

export const useBackupsStore = defineStore(
  'useBackupsStore',
  () => {
    const store = useStore();

    const wordpressDomains = computed(
      () => store.getters.getHostingWordpressDomains,
    );

    const backupsState = computed(() => store.getters.getBackupsState);
    const isBackupsLocked = computed(
      () => store.getters.getBackupsState?.lock?.locked,
    );
    const isBackupRestoreInProgress = computed(() => {
      const status = store.getters.getBackupsState.tracking?.status;

      return (
        status === BACKUP_STATUS.PENDING || status === BACKUP_STATUS.PROCESSING
      );
    });

    const isFullRestoreAvailable = computed(
      () =>
        !!wordpressDomains.value?.filter(
          (domain: WordpressPage) =>
            domain.application === Website.Type.WORDPRESS,
        )?.length,
    );

    return {
      backupsState,
      isBackupRestoreInProgress,
      isBackupsLocked,
      isFullRestoreAvailable,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.BACKUPS_STORE },
  },
);
