import { toASCII as punyASCII, toUnicode as punyUnicode } from 'punycode';

import { sanitizeUrl as braintreeSanitizeUrl } from '@braintree/sanitize-url';
import type { AxiosResponse } from 'axios';
import * as ct from 'countries-and-timezones';
import dayjs from 'dayjs';
import { isNumber, isObject } from 'lodash';
import psl, { type ParsedDomain } from 'psl';

import { updateFavicon, setThemedVariables } from '@/config';
import { allCountries } from '@/data/allCountriesList';
import { isocountries } from '@/data/isocountries';
import {
  LATIN_CHARACTER_MAP,
  latinCharacterRegex,
} from '@/data/latinCharacterMap';
import { sanctionedCountries } from '@/data/sanctionedCountries';
import type {
  KeyValueMap,
  QueryObject,
  HResourceType,
  IByteFormat,
  HAsyncError,
  HAsyncResponse,
  ResponseError,
  PlanPricingDetails,
  PlanPricing,
} from '@/types';
import {
  Status,
  Client,
  CPANEL_ORDER_RESOURCE_TYPES,
  TEMPORARY_DOMAIN_TYPE,
  PeriodUnit,
} from '@/types';
import { isCanceledRequestError } from '@/utils/helpers/modelsHelpers';
import {
  isCurrentTimeWithinDates,
  DEFAULT_DATE_FORMAT,
} from '@/utils/helpers/timeHelpers';

export const setBrandColor = (brandId: string) => {
  updateFavicon(brandId);
  setThemedVariables(brandId);
};

// eslint-disable-next-line func-style
export async function hAsync<T>(
  promise: Promise<AxiosResponse<T>>,
): HAsyncResponse<T> {
  try {
    const data: any = await promise;

    return [data, null];
  } catch (er) {
    return [{} as any, er as HAsyncError];
  }
}

export const mockPromise = async <T>(
  responseData: T,
  errorData?: HAsyncError,
): Promise<HAsyncResponse<T>> => {
  await timeout(500);

  return new Promise<HAsyncResponse<T>>((resolve) => {
    if (errorData) {
      resolve([{}, errorData] as unknown as HAsyncResponse<T>);
    } else {
      resolve([{ data: responseData }, null] as unknown as HAsyncResponse<T>);
    }
  });
};

export const extractHAsyncErrorMessage = (error: HAsyncError) => {
  if (!error) return '';

  if (isCanceledRequestError(error)) {
    return error.error;
  }

  if ('error' in error && error?.error?.message) {
    return error.error.message;
  }

  if ('response' in error) {
    return error.response?.data?.message;
  }

  return '';
};

const extractFromError = (error: HAsyncError, key: keyof ResponseError) => {
  // handles null and CanceledRequestError case
  if (!error || isCanceledRequestError(error)) return null;

  // handles ResponseError case
  if (key in error) {
    return (error as ResponseError)[key];
  }

  // handles ResponseErrorHolder case
  if ('error' in error && error.error?.[key]) {
    return error.error[key];
  }

  return null;
};

export const extractHAsyncResponseCode = (error: HAsyncError) => {
  if (error && 'response' in error) {
    return error?.response.status;
  }

  return null;
};

export const extractHAsyncErrorCode = (error: HAsyncError) =>
  extractFromError(error, 'code');

export const extractHAsyncErrorMessageParams = (error: HAsyncError) =>
  extractFromError(error, 'params');

export const splitAfterEveryFourthCharacter = (str: string) => {
  const regex = /.{4}/g;

  return str.match(regex)?.join(' ');
};

export const isBoolean = (val: any): boolean => 'boolean' === typeof val;

export const camelToDash = (str: string): string =>
  str
    .replace(/(^[A-Z])/, ([first]) => first.toLowerCase())
    .replace(/([A-Z])/g, ([letter]) => `-${letter.toLowerCase()}`);

export const getClasses = (
  element: string,
  props: object,
  delimiter = '--',
  prepend = '',
  camelToDashKey = false,
): (undefined | string)[] =>
  Object.entries(props).map(([key, value]: [string, boolean]) => {
    // eslint-disable-next-line no-param-reassign
    if (camelToDashKey) key = camelToDash(key);
    if (!value || !isBoolean(value)) return;

    return `${element}${delimiter}${prepend ? prepend : ''}${camelToDash(key)}`;
  });

/**
 * Calculate the percentage of a value relative to a total.
 *
 * @param value - The value to calculate the percentage for.
 * @param total - The total value that 'value' is a part of.
 * @param round - Whether to round the percentage to the nearest whole number.
 * @returns The calculated percentage. If total is 0, 0 is returned.
 *
 * @example
 * const value = 50;
 * const total = 180;
 *
 * // Calculate the percentage without rounding (default)
 * const percentage1 = getPercentage(value, total);
 * // percentage1 will be 27
 *
 * // Calculate the percentage with rounding
 * const percentage2 = getPercentage(value, total, true);
 * // percentage2 will be 28
 */
export const getPercentage = (
  value: number,
  total: number,
  round = false,
): number => {
  if (total === 0) return 0;

  const perc = (value / total) * 100;

  if (round) return Math.round(perc);

  return Math.floor(perc);
};

export const commaSeparator = (string: string | number) =>
  string?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const decodeHTMLCodes = (string: string) =>
  string.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
    String.fromCharCode(charCode),
  );

export const toTitleCase = (word: string = '') =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const isStringDomain = (string: string): boolean => {
  const regex =
    /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/gm;

  return regex.test(toASCII(string));
};

export const forceHeader = {
  force: true,
  headers: { 'H-Cache-Control-Force-Flush': 1 },
};

export const stringifyCompare = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);

export const isLinuxUserAgent =
  navigator.userAgent.match(/android/i) || navigator.userAgent.match(/linux/i);

export const bytesFormat = (
  bytes: string | number,
  level: any = 0,
  returnObj: boolean,
  siStandart?: boolean,
): string | IByteFormat => {
  let baseUnit = 1024;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];

  const isLevelString = typeof level === 'string' || level instanceof String;

  sizes[1] = siStandart ? 'kB' : 'KB';

  if (siStandart) baseUnit = 1000;

  if (typeof bytes === 'string') {
    // eslint-disable-next-line no-param-reassign
    bytes = parseInt(bytes);
  }

  if (isLevelString) {
    // eslint-disable-next-line no-param-reassign
    level = sizes.findIndex((size) => size === level);
  }

  if (bytes === 0) {
    return returnObj ? { value: 0, unit: sizes[level] } : `0 ${sizes[level]}`;
  }

  const i = isLevelString
    ? level
    : Math.floor(Math.log(bytes) / Math.log(baseUnit));
  const size = bytes / Math.pow(baseUnit, i);
  const value = sizes[i + level] === 'Bytes' ? size : size.toFixed(2);
  const unit = sizes[i + level];

  return returnObj ? { value, unit } : `${value} ${unit}`;
};

export const kebabToReadable = (string: string) => string.replace('-', ' ');

export const readableToSnake = (string: string) =>
  string.trim().toLowerCase().replaceAll(' ', '_');

export const snakeToTitle = (string: string) =>
  string.replace(/^_*(.)|_+(.)/g, (match, firstLetter, nextLetter) =>
    firstLetter ? firstLetter.toUpperCase() : ` ${nextLetter.toUpperCase()}`,
  );

const statuses = {
  active: [
    Status.Active,
    Status.Running,
    Status.Sent,
    Status.Success,
    Status.Created,
    Status.Finished,
    Status.Refunded,
  ],
  inactive: [
    Status.Failed,
    Status.Inactive,
    Status.Error,
    Status.VerificationFailed,
  ],
  pending: [
    Status.Pending,
    Status.PendingSetup,
    Status.PendingDataEntry,
    Status.PendingEmailConfirmation,
    Status.Unsuspending,
    Status.Suspending,
    Status.Verification,
    Status.PendingVerifications,
  ],
  requested: [Status.PendingVerification, Status.Requested],
  transfering: [Status.Transfering, Status.Transferring, Status.Creating],
  canceled: [Status.Canceled, Status.Cancel, Status.Unpaid],
  gracePeriod: [Status.GracePeriod],
  expired: [Status.Expire, Status.Expired, Status.Archived],
  destroy: [Status.Destroying, Status.Destroyed],
  initial: [Status.Initial, Status.Info],
  recover: [Status.Recovery],
  recreate: [Status.Recreating],
  restoring: [Status.Restoring],
  starting: [Status.Starting, Status.Started],
  stopped: [Status.Stopped],
  stopping: [Status.Stopping, Status.Conflict, Status.Missing],
  installing: [Status.Installing],
  notFound: [Status.NotFound],
  serverDown: [Status.ServerDown],
  suspended: [Status.Suspended],
};

export const statusOrder = (status: Status) => {
  // eslint-disable-next-line no-param-reassign
  status = status.toLowerCase() as Status;

  switch (true) {
    case statuses.pending.includes(status):
      return 0;
    case statuses.suspended.includes(status):
      return 1;
    case statuses.inactive.includes(status):
      return 2;
    case statuses.canceled.includes(status):
      return 3;
    case statuses.requested.includes(status):
      return 4;
    case statuses.transfering.includes(status):
      return 5;
    case statuses.gracePeriod.includes(status):
      return 6;
    case statuses.active.includes(status):
      return 7;
    case statuses.expired.includes(status):
      return 8;
  }
};

export const replaceParameters = (string: string, parameters: any = {}) => {
  Object.keys(parameters).forEach((key) => {
    const regexp = new RegExp(`{${key}}`);
    // eslint-disable-next-line no-param-reassign
    string = string.replace(regexp, parameters[key]);
  });

  return string;
};

export const mapSelected = (label = '', value?: any) => ({
  label: toUnicode(label),
  value: value ? value : label,
});

export const sortArrayByAlphabet = (a: any, b: any, name: string) => {
  if (a[name] < b[name]) {
    return -1;
  }
  if (a[name] > b[name]) {
    return 1;
  }

  return 0;
};

export const formatPassword = (password: string, isHidden: boolean) => {
  if (!isHidden) return password;

  let hiddenPassword = '*';

  let i;
  for (i = 0; i < password.length; i++) {
    hiddenPassword += '*';
  }

  return hiddenPassword;
};

export const removeLeadingDot = (domain: string) => domain.replace(/^\./, '');

export const getDomainParts = (domain: string) => [
  domain.substring(0, domain.indexOf('.')),
  domain.substring(domain.indexOf('.')),
];

export const getFullDomainFromOrder = (order: {
  domainSld?: string;
  domainTld?: string;
  hostname?: string;
}) => {
  if (order.domainSld && order.domainTld) {
    return `${order.domainSld}${order.domainTld}`;
  }

  return order.hostname || null;
};

export const periodToYearLength = (period: string) => {
  switch (period) {
    case 'monthly':
      return 1 / 12;
    case 'quarterly':
      return 1 / 4;
    case 'biannually':
      return 1 / 2;
    case 'annually':
      return 1;
    case 'biennially':
      return 2;
    case 'triennially':
      return 3;
    case 'quadrennially':
      return 4;
    default:
      return null;
  }
};

export const periodToBillingPeriod = ({
  period,
  periodUnit,
}: {
  period: number;
  periodUnit: string;
}) => {
  if (periodUnit === 'year') {
    // eslint-disable-next-line no-param-reassign
    period = period * 12;
    // eslint-disable-next-line no-param-reassign
    periodUnit = 'Month';
  }

  const units = period > 1 ? ` ${periodUnit}s` : periodUnit;
  const num = period > 1 ? period : '';

  return `${monthsToPeriod(period)} (Pay Every ${num}${capitalize(units)})`;
};

const monthsToPeriod = (months: number) => {
  switch (months) {
    case 1:
      return 'Monthly';
    case 3:
      return 'Quarterly';
    case 6:
      return 'Biannually';
    case 12:
      return 'Annually';
    case 24:
      return 'Biennially';
    case 36:
      return 'Triennially';
    case 48:
      return 'Quadrennially';
    case 60:
      return 'Quinquennially';
    default:
      return '';
  }
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.substring(1);

export const periodName = (period: string) => {
  switch (period) {
    case 'none':
      return 'Lifetime';
    case 'monthly':
      return '1 month';
    case 'quarterly':
      return '3 months';
    case 'biannually':
      return '6 months';
    case 'annually':
      return '12 months';
    case 'biennially':
      return '24 months';
    case 'triennially':
      return '36 months';
    case 'quadrennially':
      return '48 months';
    case 'quinquennially':
      return '60 months';
    default:
      return '';
  }
};

// TODO: Use getSldTld instead of getSplittedDomainParts
export const getSplittedDomainParts = (
  value: string,
  options?: { omitDot?: boolean; withDefaults?: boolean },
) => {
  const DEFAULT_TLD = 'com';
  let domain = value;

  if (options?.withDefaults && !value.includes('.')) {
    domain = `${value}.${DEFAULT_TLD}`;
  }

  const splittedValue = domain.toLowerCase()?.split('.');

  let processedValue =
    splittedValue.length > 3
      ? splittedValue.slice(splittedValue.length - 3).join('.')
      : domain.toLowerCase();

  if (
    /^(w)\1+$/.test(processedValue.split('.')[0].toLowerCase()) &&
    splittedValue.length > 2
  ) {
    processedValue = processedValue.split('w.').slice(1).join('w.');
  }

  if (
    /^(w)\1+$/.test(processedValue.split('.')[0].toLowerCase()) &&
    processedValue.split('.').length > 2
  ) {
    processedValue = processedValue.split('w.').slice(1).join('w.');
  }
  let tld = processedValue.includes('.')
    ? processedValue.substr(processedValue.indexOf('.')).toLowerCase()
    : processedValue.toLowerCase();

  const sld = processedValue.replace(tld, '');

  if (options?.omitDot) {
    tld = tld.replace('.', '');
  }

  return [sld, tld];
};

// TODO: there's a bug in getSplittedDomainParts when passing domain without tld
// TODO: this function will be used to replace getSplittedDomainParts
export const getSldTld = (
  value: string,
  options?: { omitDot?: boolean; withDefaults?: boolean },
) => {
  const DEFAULT_TLD = 'com';
  let domain = value;

  if (options?.withDefaults && !value.includes('.')) {
    domain = `${value}.${DEFAULT_TLD}`;
  }

  const splittedValue = domain.toLowerCase().split('.');

  let processedValue =
    splittedValue.length > 3
      ? splittedValue.slice(splittedValue.length - 3).join('.')
      : domain.toLowerCase();

  if (
    /^(w)\1+$/.test(processedValue.split('.')[0].toLowerCase()) &&
    splittedValue.length > 2
  ) {
    processedValue = processedValue.split('w.').slice(1).join('w.');
  }

  if (
    /^(w)\1+$/.test(processedValue.split('.')[0].toLowerCase()) &&
    processedValue.split('.').length > 2
  ) {
    processedValue = processedValue.split('w.').slice(1).join('w.');
  }
  let tld = processedValue.includes('.')
    ? processedValue.substr(processedValue.indexOf('.')).toLowerCase()
    : '';

  const sld = processedValue.replace(tld, '');

  if (options?.omitDot) {
    tld = tld.replace('.', '');
  }

  return [sld, tld];
};

export const decodeUrl = (url: string) => {
  const urlInstance = new URL(url);

  return urlInstance.href.replace(
    `${urlInstance.protocol}//${urlInstance.hostname}`,
    `${urlInstance.protocol}//${toUnicode(urlInstance.hostname)}`,
  );
};

export const hasPeriodUnitInName = (str: string) =>
  /^\d+\s(?:month|year)/.test(str);

export const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const repeatWhile = async <T>(
  callback: () => Promise<T>,
  shouldContinue: (result: Awaited<T>) => boolean,
  ms: number = 5000,
): Promise<T> => {
  const result = await callback();

  if (shouldContinue(result)) {
    await timeout(ms);

    return await repeatWhile(callback, shouldContinue, ms);
  }

  return result;
};

export const urlRegexp =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

export const secondsLeftToRemainingTime = (secondsLeft: number) => {
  const d = Math.floor(secondsLeft / (3600 * 24));
  const h = Math.floor((secondsLeft % (3600 * 24)) / 3600);
  const m = Math.floor((secondsLeft % 3600) / 60);
  const s = Math.floor(secondsLeft % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const leadingZero = (num: number) => `0${num}`.slice(-2);

  return `Time Left: ${dDisplay}${leadingZero(h)}:${leadingZero(
    m,
  )}:${leadingZero(s)}`;
};

export const getItemIdFromItemPriceId = (itemPriceId: string) => {
  if (!itemPriceId) return '';

  const arr = itemPriceId.split('-');
  arr.splice(arr.length - 2, 2);

  return arr.join('-');
};

export const findByKeyInNestedObj = (
  theObj: { [key: string]: any },
  key: string,
  val: string,
) => {
  const findKey = (theObject: { [key: string]: any }): any => {
    let result = null;
    if (theObject instanceof Array) {
      for (let i = 0; i < theObject.length; i++) {
        result = findKey(theObject[i]);
        if (result) {
          break;
        }
      }
    } else {
      for (const prop in theObject) {
        if (prop === key && theObject[prop] === val) {
          return theObject;
        }
        if (
          theObject[prop] instanceof Object ||
          theObject[prop] instanceof Array
        ) {
          result = findKey(theObject[prop]);
          if (result) {
            break;
          }
        }
      }
    }

    return result;
  };

  return findKey(theObj);
};

export const formatNotInteger = (number: number, formatPoint: number) => {
  if (number % 1 === 0) return number;

  return number.toFixed(formatPoint);
};

export const mapKeyValue = (keyValueMap: KeyValueMap, key: string | number) => {
  if (!keyValueMap[key]) {
    return keyValueMap.default;
  }

  return keyValueMap[key];
};

export const capitalizeAllWords = (string: string) =>
  string
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');

export const replaceNonLatinCharacters = (str: string) =>
  str.replace(
    latinCharacterRegex,
    (matched: string) => LATIN_CHARACTER_MAP[matched],
  );

export const isMobileBrowser = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const isProductionEnvironment = process.env.NODE_ENV === 'production';

export const getBrandNameWithoutTLD = (brand: string) => {
  const parts = brand.split('.');

  return parts[0].toLowerCase();
};

export const isBrandNiagahoster = (brand: string) => {
  const brandWithoutTLD = getBrandNameWithoutTLD(brand);

  return brandWithoutTLD === Client.Brand.NG_CO_ID.toLowerCase();
};

// ---CAPITALISE FILTER---
export const capitalise = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

// ---COUNTRY CODE FILTER---
export const countryByCountryCode = (
  code: string,
  withSanctionedCountries?: boolean,
) => {
  const countryList = withSanctionedCountries
    ? [...isocountries, ...sanctionedCountries]
    : isocountries;

  const countryArr = countryList
    .filter(({ value }) => code?.toUpperCase() === value)
    .pop();

  return countryArr ? countryArr.label : '';
};

// ---DATE FILTER---
export const toLocalTime = (
  date: Date | string | number,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  if (!date) return '';

  return isNumber(date)
    ? dayjs.unix(date).utc().local().format(format)
    : dayjs.utc(date).local().format(format);
};

export const toTime = (
  date: Date | string | null,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  if (!date) return '';

  return isNumber(date)
    ? dayjs.unix(date).utc().format(format)
    : dayjs.utc(date).format(format);
};

// ---IF CONDITION FILTER---
export const ifConditionFilter = (value: any) =>
  value !== undefined ? value : true;

// ---KEY FILTER---
export const keyFilter = (value: any) => {
  if (isObject(value)) {
    const keyString = [];
    for (const key in value) {
      keyString.push(`${key}=${value[key as keyof typeof value]}`);
    }

    return keyString.join(',');
  }

  if (value) {
    return value?.toLowerCase()?.replace(/\s/g, '_');
  }

  return value;
};

// ---PUNNY CODE FILTER---
export const toUnicode = (str: string) => (str ? punyUnicode(str) : str);

export const toASCII = (str: string) => (str ? punyASCII(str) : str);

export const transformEveryWordToUnicode = (str: string) =>
  str
    .split(' ')
    .map((word) => toUnicode(word))
    .join(' ');

export const scrollIntoView = (
  selectors: keyof HTMLElementTagNameMap | string,
  timeout: number = 100,
  options?: ScrollIntoViewOptions,
) => {
  const element = document.querySelector(selectors) as HTMLInputElement;
  setTimeout(() => {
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', ...options });
  }, timeout);
};

export const isCPanelHosting = (service: HResourceType): boolean =>
  CPANEL_ORDER_RESOURCE_TYPES.includes(service);

const shuffle = (array: string[]) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const generatePassword = (
  uppercase = 1,
  lowercase = 1,
  numbers = 1,
  symbols = 1,
  either?: number,
) => {
  const eitherDefault = either || Math.floor(Math.random() * (8 - 4 + 1) + 4);

  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // uppercase letters,
    'abcdefghijklmnopqrstuvwxyz', //lowercase letters,
    '0123456789', // numbers,
    '[]?/~#!@$^&*+=|:;>', // symbols
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789[]?/~#!@$^&*+=|:;>', // either
  ];

  return shuffle(
    [uppercase, lowercase, numbers, symbols, eitherDefault].map((len, i) =>
      Array(len)
        .fill(chars[i])
        .map((x) => x[Math.floor(Math.random() * x.length)])
        .join(''),
    ),
  ).join('');
};

export const sortByCondition = <T>(
  values: T[],
  conditions: ((value: T) => boolean)[],
) =>
  values.sort((a, b) => {
    const sortingOrder = conditions;

    for (const condition of sortingOrder) {
      if (condition(a) && !condition(b)) {
        return -1;
      }

      if (!condition(a) && condition(b)) {
        return 1;
      }
    }

    return 0;
  });

/**
 * Calculate the discount percentage between an old price and a current price.
 *
 * @param oldPrice - The original price before the discount.
 * @param currentPrice - The price after the discount.
 * @returns The discount percentage, rounded to two decimal places.
 *
 * @example
 * const oldPrice = 100;
 * const currentPrice = 80;
 * const discount = getDiscount(oldPrice, currentPrice);
 * // discount will be 20
 */
export const getDiscount = (oldPrice: number, currentPrice: number) =>
  getPercentage(oldPrice - currentPrice, oldPrice, true);

export const minBy = <T>(
  array: T[],
  callback: (item: T) => number,
): T | undefined => {
  if (array.length === 0) {
    return undefined;
  }

  let minItem = array[0];
  let minValue = callback(minItem);

  for (let i = 1; i < array.length; i++) {
    const currentItem = array[i];
    const currentValue = callback(currentItem);

    if (currentValue < minValue) {
      minValue = currentValue;
      minItem = currentItem;
    }
  }

  return minItem;
};

export const convertObjectToQuery = ({
  queryObject,
}: {
  queryObject: QueryObject;
}) => encodeURIComponent(JSON.stringify(queryObject));

export const referralCommissionPercentage = isCurrentTimeWithinDates({
  startsAt: '2023-10-16T09:00:00Z',
  endsAt: '2023-10-29T23:59:59Z',
})
  ? '30%'
  : '20%';

export const decodeHtmlEntities = (inputString: string) => {
  const doc = new DOMParser().parseFromString(inputString, 'text/html');

  return doc.documentElement.textContent;
};

export const isEmptyArray = <T>(value: T) => {
  if (!value) return true;

  return Array.isArray(value) && value.length === 0;
};

/**
 * This function takes a clientId string as input and returns a number string.
 * The clientId string is expected to be in one of two formats: 'h_123' or '123'.
 *
 * @param {string} clientId - The client ID to be processed.
 * @returns {string} - The number string from the client ID.
 */
export const stripClientIdToNumber = (clientId: `h_${string}` | string) => {
  // TODO this function will not be needed after https://hostingers.atlassian.net/browse/DMNS-2311 is implemented and we will make frontend adjustments for it
  if (!clientId) return clientId;
  // Split the clientId string into parts using the underscore ('_') as the delimiter.
  const splitClientId = clientId.split('_');

  // If the clientId string has more than one part (i.e., it's in the format 'h_123'),
  // return the second part (i.e., '123').
  // If the clientId string has only one part (i.e., it's already in the format '123'),
  // return that part.
  return splitClientId.length > 1 ? splitClientId[1] : splitClientId[0];
};

export const formatNumber = (value: number) =>
  value.toString().replace(/(?=\B(\d{3})+(?!\d))/g, ' ');

/**
 * Creates a script element with the given url and adds it to html body.
 * Can be used to load external scripts and wait for them to be loaded.
 *
 * @param url script url
 */
export const loadScript = (url: string) =>
  new Promise<void>((resolve, reject) => {
    // Create a script element
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    // Set up the script event handlers
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${url}`));

    document.body.appendChild(script);
  });

export const retryAsyncFunction = async ({
  triesCount,
  timeoutInMilliseconds,
  getIsRetryNeeded,
  functionToRetry,
}: {
  triesCount: number;
  timeoutInMilliseconds: number;
  getIsRetryNeeded: () => boolean;
  functionToRetry: () => Promise<any>;
}) => {
  let currentTry = 0;

  while (currentTry < triesCount && getIsRetryNeeded()) {
    await functionToRetry();

    if (!getIsRetryNeeded()) return;

    await timeout(timeoutInMilliseconds);
    currentTry++;
  }
};

export const assignLocationAsync = (href: string | URL) =>
  new Promise<void>((resolve) => {
    setInterval(() => {
      if (window.location.href !== href) {
        return;
      }
      resolve();
    }, 100);
    window.location.assign(href);
  });

/**
 * Sanitizes a query object by recursively sanitizing its values.
 *
 * @param queryObj - The query object to sanitize.
 * @returns The sanitized query object.
 */
const sanitizeQueryObject = (queryObj: Record<string, any>) => {
  const sanitizedQueryObj: Record<string, any> = {};
  Object.entries(queryObj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      sanitizedQueryObj[key] = sanitizeQueryObject(value);
    } else {
      // https://v2.vuejs.org/v2/guide/security#Injecting-URLs
      sanitizedQueryObj[key] = braintreeSanitizeUrl(String(value));
    }
  });

  return sanitizedQueryObj;
};

/**
 * Sanitizes a URL by applying specific transformations based on the provided options.
 *
 * @param {object} options - The options for sanitizing the URL.
 * @param {any} options.input - The input URL or query object to be sanitized.
 * @param {boolean} [options.isQueryObject] - Specifies whether the input is a query object.
 * @param {boolean} [options.isUrlString] - Specifies whether the input is a URL string.
 * @returns {any} The sanitized URL.
 */
export const sanitizeUrl = ({
  input,
  isQueryObject,
  isUrlString,
}: {
  input: any;
  isQueryObject?: boolean;
  isUrlString?: boolean;
}) => {
  if (!input) return;

  if (isQueryObject) {
    return sanitizeQueryObject(input);
  }

  if (isUrlString) {
    // https://v2.vuejs.org/v2/guide/security#Injecting-URLs
    return braintreeSanitizeUrl(String(input));
  }

  return input;
};

/**
 * Check if URL is temporary domain
 *
 * @param {string} url - The URL to check.
 * @returns {boolean}
 */

export const isTemporaryDomain = (url: string) =>
  url.includes(TEMPORARY_DOMAIN_TYPE.WORDPRESS) ||
  url.includes(TEMPORARY_DOMAIN_TYPE.BUILDER);

export const getDomain = (website: string) => {
  const [, ...domainParts] = website.split('.');

  return domainParts.join('.');
};

export const getDomainAndSubdomain = (
  website: string,
): {
  subdomain: string;
  domain: string;
} => {
  const [subdomain, ...domainParts] = website.split('.');

  return {
    subdomain,
    domain: domainParts.join('.'),
  };
};

export const getCountryNameByCodeFromAllCountries = (code: string) => {
  const country = allCountries.find(({ value }) => value === code);

  if (country) {
    return country.label;
  }

  return '';
};

export const isDefined = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

export const stripUrlProtocol = (url: string): string => {
  if (!url) return '';
  const normalizedUrl = url
    .toLowerCase()
    .replace(/^(?:https?:\/\/)?(?:www\.)?/, '');

  const domainWithOptionalSlash = normalizedUrl.split('/')[0];
  const remainingPart = normalizedUrl.split('/').slice(1).join('/');

  return remainingPart ? normalizedUrl : domainWithOptionalSlash;
};

export const areDomainsDifferent = (
  userUrl: string,
  referenceUrl: string,
): boolean => {
  const normalizedUserUrl = stripUrlProtocol(userUrl);
  const normalizedReferenceUrl = stripUrlProtocol(referenceUrl);

  return normalizedUserUrl !== normalizedReferenceUrl;
};

/**
 * Converts the given period to months.
 *
 * @param count count of period units (e.g., 1, 2)
 * @param unit periods unit (e.g., month, year)
 */
export const convertToMonths = (count: number | null, unit: string | null) => {
  const finalCount = count ?? 0;

  return unit === PeriodUnit.YEAR ? finalCount * 12 : finalCount;
};

export const isYoutubeLink = (link: string) => link.includes('youtube.com');

const getCountryCode = async () => {
  const isFallbackHandle = !window.location.host.match(/hpanel.hostinger.com/);
  const targetUrlPrefix = isFallbackHandle
    ? 'https://hpanel.hostinger.com'
    : '';
  // We are fetching data from Cloudflare appended page to get user's country code
  const response = await fetch(`${targetUrlPrefix}/cdn-cgi/trace`);
  const text = await response.text();

  return text.match(/\nloc=(..)/)?.[1] || '';
};

export const isUsingVPN = async () => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countriesInTimezone = ct.getCountriesForTimezone(userTimezone);
  const userCountry = await getCountryCode();

  return !countriesInTimezone.find((country) => country.id === userCountry);
};

export const getGAPlanPricing = ({
  price,
  period,
  renewalPrice,
  regularPrice,
}: PlanPricing): PlanPricingDetails => ({
  price: price || 0,
  discountPercentage: getDiscount(regularPrice || 0, price || 0),
  period: period?.toString(),
  renewalPrice: renewalPrice || 0,
  coupon: '',
});

export const isSubdomainPsl = (domain: string) => {
  const parsedDomain = psl.parse(domain) as ParsedDomain;

  return parsedDomain.subdomain !== null;
};

export const parseDomainPsl = (domain: string) => {
  const parsedDomain = (psl.parse(domain) as ParsedDomain).domain;

  return parsedDomain ?? domain;
};

export const getPropagationHours = (propagationTimeMins: string | null) => {
  const propagationTimeInMins = parseInt(propagationTimeMins || '', 10);

  return !isNaN(propagationTimeInMins)
    ? `${Math.ceil(propagationTimeInMins / 60)} ${
        Math.ceil(propagationTimeInMins / 60) === 1 ? 'hour' : 'hours'
      }`
    : '24 hours';
};
