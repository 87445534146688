import type { TBrandId } from '@/types';

export interface IntercomChatbotProxyMetadata {
  current_location_url: string;
  team_label: string;
  brand: TBrandId;
  language: {
    language: string;
    locale: string;
  };
}

export interface IntercomChatbotEnabled {
  enabled: number;
}

export enum IntercomTicketStates {
  SUBMITTED = 'submitted',
  RESOLVED = 'resolved',
  WAITING_ON_CUSTOMER = 'waiting_on_customer',
  IN_PROGRESS = 'in_progress',
}

export interface IntercomTicket {
  ticketId: string;
  ticketState: IntercomTicketStates;
  domainName: string;
  title: string;
  ticketTypeTitle: string;
  type: string;
  open: boolean;
  createdAt: number;
  updatedAt: number;
}

export const IntercomTicketFieldTypes = {
  DOMAIN_SUSPENDED: 54,
  DOMAIN_NOT_VERIFIED: 53,
  ORDER_SUSPENDED: 51,
  EMAIL_SUSPENDED: 67,
  TEST: 1,
};

export enum IntercomTicketFieldDataTypes {
  STRING = 'string',
  LIST = 'list',
}

export interface IntercomTicketField {
  dataType: IntercomTicketFieldDataTypes;
  name: string;
  multiline: boolean;
}
export interface IntercomTicketFieldData {
  id: number;
  name: string;
  requiredFields: IntercomTicketField[];
}
