import type {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { useH5GOnboarding } from '@/composables';
import { useH5GWebsitesStore } from '@/stores';
import { Route } from '@/types';

const h5gWebsiteGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const h5gWebsitesStore = useH5GWebsitesStore();
  const { isH5GFeatureEnabled } = useH5GOnboarding();

  if (!isH5GFeatureEnabled.value) {
    return next({ name: Route.Base.HOME });
  }

  const websiteUid = to.params.websiteUid as string;

  const response = await h5gWebsitesStore.fetchH5GWebsiteDetails(websiteUid);

  if (response?.error) {
    return next({ name: Route.Base.HOME });
  }

  await h5gWebsitesStore.fetchH5GWebsiteProcesses(websiteUid);

  next();
};

export default h5gWebsiteGuard;
