import { HDOMAIN_RESOURCE_TYPES } from '@hostinger/hdomains-status';
import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';

import { domainsRepo } from '@/repositories';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import type { Vhost, Hosting } from '@/types';

export const useVHostsStore = defineStore('vHostsStore', () => {
  const vHosts = ref<Vhost[]>([]);
  const { hDomainsResources } = storeToRefs(useHDomainResourceStore());

  const fetchVHosts = async (params: {
    types?: Hosting.Vhost[];
    includePreviews?: 1 | 0;
    skipSubdomains?: 1 | 0;
  }) => {
    params.includePreviews = params.includePreviews ? 1 : 0;
    const [{ data }, err] = await domainsRepo.getManagedVHostsList(params);
    if (err) return;

    vHosts.value = data;
  };

  const externalDomains = computed(() =>
    vHosts.value.filter(
      ({ vhost }) =>
        !hDomainsResources.value.find(
          ({ title, resourceType }) =>
            title === vhost && resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN,
        ),
    ),
  );

  const internalDomains = computed(() =>
    vHosts.value.filter(({ vhost }) =>
      hDomainsResources.value.find(
        ({ title, resourceType }) =>
          title === vhost && resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN,
      ),
    ),
  );

  const getIsDomainExternal = (domain: string) =>
    externalDomains.value.some(({ vhost }) => vhost === domain);

  const getVHostByDomain = (domain: string): Vhost | undefined =>
    vHosts.value.find(({ vhost }) => vhost === domain);

  return {
    vHosts,
    fetchVHosts,
    externalDomains,
    internalDomains,
    getIsDomainExternal,
    getVHostByDomain,
  };
});
