import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useHostingV2 = () => {
  const route = useRoute();

  const isHostingV2 = computed(() => route.fullPath.includes('/hosting-v2/'));

  return {
    isHostingV2,
  };
};
