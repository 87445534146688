<template>
  <div class="d-flex justify-content-center">
    <div class="text-center">
      <HImage
        src="@/images/search/data-not-found.svg"
        :alt="$t('Nothing found')"
        class="mb-2"
      />
      <h3 v-if="title" v-trans>
        {{ title }}
      </h3>
      <p v-if="subtitle" v-trans>
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subtitle: String,
    title: String,
  },
};
</script>
