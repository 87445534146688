<script setup lang="ts">
type Props = {
  feature: string | LabelledFeature;
};

interface LabelledFeature {
  label: string | { text: string };
  text: string;
  type?: string;
}

const props = defineProps<Props>();
const labelledFeature = props.feature as LabelledFeature;

const labelClass = labelledFeature?.type && [`label-${labelledFeature?.type}`];
const label =
  typeof labelledFeature?.label === 'string'
    ? labelledFeature.label
    : labelledFeature?.label?.text;
</script>

<template>
  <div class="purchase-feature">
    <HImage
      :src="`@/images/green-check-outline.svg`"
      :alt="$t('product feature')"
      class="h-mr-8"
    />
    <Trans>
      {{ labelledFeature?.text || props.feature }}
    </Trans>
    <HLabelDeprecated
      v-if="labelledFeature.label"
      class="text-uppercase h-ml-8"
      :body-class="labelClass"
      no-arrow
      padding-sm
    >
      {{ label }}
    </HLabelDeprecated>
  </div>
</template>

<style lang="scss" scoped>
.purchase-feature {
  padding-bottom: 8px;
}
</style>
