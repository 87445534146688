import { QueryClient, type FetchQueryOptions } from '@tanstack/query-core';

export const queryClient = new QueryClient();

export const fetchQuery = async <T>(
  options: FetchQueryOptions<T> & { overrideCache?: boolean },
): Promise<T> => {
  if (options?.overrideCache) {
    await queryClient.refetchQueries({
      queryKey: options.queryKey,
      exact: true,
    });
  }

  return await queryClient.fetchQuery(options);
};
