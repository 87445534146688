<template>
  <div class="d-flex flex-wrap justify-content-center align-items-center">
    <HpIcon class="h-mr-8" icon="icon-neutral" width="16" danger />
    <Trans>v2.domain.status</Trans>
    :
    <Trans
      tag="span"
      class="ml-1"
      :translate-params="{ domain: $route.params.domain }"
    >
      v2.domain.is.not.registered.yet
    </Trans>
    <a
      v-if="!!domainRegistrationUrl"
      v-trans
      :href="domainRegistrationUrl"
      target="_blank"
      class="ml-2 text-danger text-underline"
    >
      Register Now
    </a>
  </div>
</template>

<script>
//JSON-slugs-migrated
import { mapGetters } from 'vuex';

export default {
  name: 'DomainNotRegisteredBanner',
  computed: {
    ...mapGetters(['domainRegistrationUrl']),
  },
};
</script>
