export namespace Website {
  export enum Type {
    BUILDER = 'builder',
    WORDPRESS = 'wordpress',
    OTHER = 'other',
  }

  export enum Error {
    DOMAIN_ALREADY_HOSTED = 'Domain is already hosted',
    DOMAIN_NAME_CANNOT_BE_CHANGED = 'Domain name cannot be changed',
    DOMAIN_IS_INVALID = 'Domain is invalid',
    WEBSITE_BUILDER_NOT_FOUND = 'Website builder not found',
    SUBDOMAINS_MAIN_DOMAIN_IS_ALREADY_OWNED = 'The subdomains main domain is already owned by another user',
    CANNOT_CREATE_DOMAIN = 'Unable to create the website with the specified domain',
  }

  export enum Action {
    REMOVING = 'removing',
    CHANGING_DOMAIN = 'changing_domain',
  }

  export enum Filter {
    ALL = 'all',
    OWNED = 'owned',
    MANAGED = 'managed',
  }
}
