import type { ModalObject } from '@/types';

export default [
  {
    name: 'UpdatePersonalInformationV2',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      persistent: true,
      lg: true,
      withPadding: true,
    },
  },
  {
    name: 'VerifyPersonalInformationChangeModalV2',
    type: 'HModal',
    closingType: 'input',
    props: {
      withPadding: true,
      lg: true,
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'VerifyPasswordChangeModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      withPadding: true,
      lg: true,
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'VerifyUnlinkSocialModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      withPadding: true,
      lg: true,
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'ConfirmPasswordModalV2',
    type: 'HModal',
    subtype: 'PersonalInformation',
    props: {
      title: 'Verify password',
      subtitle: 'Verify password to activate two factor authentication',
      hideX: true,
    },
  },
  {
    name: 'TwoFactorSuccessModal',
    type: 'HModalFancy',
    subtype: 'PersonalInformation',
  },
  {
    name: 'TwoFactorWarningModal',
    type: 'HModalFancy',
    subtype: 'PersonalInformation',
    props: {
      title: 'Disable two-factor authentication',
      subtitle:
        'To disable your two-factor authentication please enter code from your application:',
      imgSrc: 'modalFancy/alertWarning.svg',
      persistent: true,
    },
  },
  {
    name: 'UnlinkSocialsConfirmationModal',
    type: 'HModal',
    subtype: 'PersonalInformation',
    props: {
      title: 'Unlink Account From Social Login',
      subtitle:
        'After unlinking your account from social login, you will receive an email to reset your password, allowing you to create a new password for your account',
      imgSrc: 'modalFancy/share-with-bg.svg',
      persistent: true,
      withPadding: true,
      hideX: true,
    },
  },
  {
    name: 'UnlinkSocialsConfirmationModalV2',
    type: 'HModal',
    subtype: 'PersonalInformation',
    props: {
      title: 'Unlink Account From Social Login',
      subtitle:
        'After unlinking your account from social login, you will receive an email to reset your password, allowing you to create a new password for your account',
      imgSrc: 'modalFancy/share-with-bg.svg',
      persistent: true,
      withPadding: true,
      hideX: true,
    },
  },
  {
    name: 'DeleteAccountConditionsModal',
    type: 'HModal',
    subtype: 'PersonalInformation',
    props: {
      title: 'About Your Account',
      subtitle:
        'We can delete your profile only if the following conditions are met:',
      modalTitleIcon: {
        name: 'icon-error',
        color: 'warning',
      },
      persistent: true,
      withPadding: true,
      hideX: true,
    },
  },
  {
    name: 'DownloadProfileDataModal',
    type: 'HModalFancy',
    subtype: 'PersonalInformation',
    props: {
      title: 'Download profile data?',
      imgSrc: 'modalFancy/folderPurple.svg',
      persistent: true,
    },
  },
] as ModalObject[];
