import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import type { WebsiteType, ProWebsiteResource } from '@/types';
import { Hosting, BillingOrder, SubscriptionStatus } from '@/types';

dayjs.extend(utc);

type GenericWebsiteType = WebsiteType | ProWebsiteResource;

export const useHostingStatus = (
  defaultHostingOrder: GenericWebsiteType | null = null,
) => {
  const store = useStore();

  const hostingOrder = ref<GenericWebsiteType | null>(defaultHostingOrder);
  const isSuspended = computed(() => {
    const orderStatus =
      (hostingOrder.value as ProWebsiteResource)?.state ||
      (hostingOrder.value as WebsiteType)?.status;

    const isWebsiteSuspended =
      (hostingOrder.value as WebsiteType)?.active === '0';

    return (
      [
        Hosting.OrderStatus.SUSPENDED,
        SubscriptionStatus.CANCELLED,
        SubscriptionStatus.PAUSED,
      ].includes(orderStatus as Hosting.OrderStatus | SubscriptionStatus) ||
      isWebsiteSuspended
    );
  });

  const isDomainPropagating = computed(
    () => !!store.state.hostingDashboard?.domainInfo?.id,
  );
  const suspendReason = computed(
    () => isSuspended.value && hostingOrder.value?.reason,
  );
  const doesRequireCSHelp = computed(() =>
    [
      Hosting.SuspendReason.ABUSE,
      Hosting.SuspendReason.FRAUD,
      Hosting.SuspendReason.FRAUD_PAYMENT,
    ].includes(suspendReason.value as Hosting.SuspendReason),
  );
  const nonPaymentSuspension = computed(
    () => Hosting.CBSuspendReason.NOT_PAID === suspendReason.value,
  );
  const canSelfUnsuspend = computed(
    () =>
      suspendReason.value === Hosting.SuspendReason.EXPIRED ||
      nonPaymentSuspension.value,
  );
  const isSelfSuspension = computed(() =>
    [
      Hosting.SuspendReason.CUSTOMER_REQUESTED,
      Hosting.SuspendReason.SELF_CANCELED,
      Hosting.SuspendReason.VERIFICATION_FAILED,
      Hosting.SuspendReason.INACTIVITY,
    ].includes(suspendReason.value as Hosting.SuspendReason),
  );

  const isExpiringOrder = (data: GenericWebsiteType) => {
    const { expiresAt } = data;
    const suspendDate =
      (data as WebsiteType).suspendAt ||
      (data as ProWebsiteResource).suspendedAt;

    const unparsedSuspensionDate = suspendDate || expiresAt;

    const suspensionDate =
      typeof unparsedSuspensionDate === 'number'
        ? dayjs.unix(unparsedSuspensionDate)
        : dayjs(unparsedSuspensionDate, { utc: true });
    const currentDate = dayjs();
    const daysBeforeSuspension = suspensionDate.diff(currentDate, 'day');
    const hoursBeforeSuspension = suspensionDate.diff(currentDate, 'hour');

    return (
      daysBeforeSuspension >= 0 &&
      daysBeforeSuspension <
        BillingOrder.Timing.OLD_BILLING_REACTIVATION_PERIOD_IN_DAYS &&
      hoursBeforeSuspension >= 0
    );
  };

  const getExpirationInDays = (expirationDate: string) => {
    const currentDate = dayjs().utc();
    const expirationDateUTC = dayjs(expirationDate, { utc: true });
    const daysRemaining = dayjs(expirationDateUTC).diff(currentDate, 'day');

    return daysRemaining < 1 ? 0 : daysRemaining;
  };

  const getIsExpiryWithinDays = (days: number) => {
    if (hostingOrder.value) {
      const expiresAt = String(hostingOrder.value.expiresAt);

      return getExpirationInDays(expiresAt) <= days;
    }

    return false;
  };

  return {
    doesRequireCSHelp,
    canSelfUnsuspend,
    isDomainPropagating,
    isSelfSuspension,
    nonPaymentSuspension,
    isSuspended,
    isExpiringOrder,
    getExpirationInDays,
    getIsExpiryWithinDays,
  };
};
