<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue';

import type { HIconUnion } from '@/types';
import { toTitleCase } from '@/utils/helpers';
import { kebabToCamel } from '@/utils/services/namingConventionsService';

type Props = {
  icon: HIconUnion;
  type?: string;
  title?: string;
  iconName?: string;
  viewBox?: string;
  safeAreaViewBox?: boolean;
  staticViewBox?: boolean;
  danger?: boolean;
  gray?: boolean;
  grayLight?: boolean;
  grayBorder?: boolean;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
  warningDark?: boolean;
  dark?: boolean;
  light?: boolean;
  primary?: boolean;
  headerBg?: boolean;
  customClass?: string;
  noSelect?: boolean;
  noOutline?: boolean;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  meteoriteDark?: boolean;
  color?: string;
  filled?: boolean;
  backgroundColor?: string;
};
const props = withDefaults(defineProps<Props>(), { viewBox: '0 0 24 24' });

const iconColor = computed(() => {
  if (!props.color) return '';

  return `var(--${props.color})`;
});

const currentIcon = computed(
  () =>
    props.icon &&
    defineAsyncComponent((): any => {
      if (props.icon.includes('icon') || props.icon.includes('Icon')) {
        return import(
          `../Icons/icons/${kebabToCamel(toTitleCase(props.icon))}.vue`
        );
      }

      if (props.type) {
        return import(
          `../Icons/${kebabToCamel(props.type)}/${toTitleCase(props.icon)}.vue`
        );
      }

      if (props.staticViewBox) {
        return import(
          `../Icons/icons_old/24x24/${kebabToCamel(
            toTitleCase(props.icon),
          )}.vue`
        );
      }

      if (props.safeAreaViewBox) {
        return import(
          `../Icons/icons_old/32x32/${kebabToCamel(
            toTitleCase(props.icon),
          )}.vue`
        );
      }

      return import(
        `../Icons/icons_old/${kebabToCamel(toTitleCase(props.icon))}.vue`
      );
    }),
);

const iconClass = computed(() => ({
  'icon-danger': props.danger,
  'icon-success': props.success,
  'icon-info': props.info,
  'icon-warning': props.warning,
  'icon-warning-dark': props.warningDark,
  'icon-dark': props.dark,
  'icon-light': props.light,
  'icon-primary': props.primary,
  'icon-gray': props.gray,
  'icon-gray-light': props.grayLight,
  'icon-gray-border': props.grayBorder,
  'icon-meteorite-dark': props.meteoriteDark,
  'icon-header-bg': props.headerBg,
  'prevent-select': props.noSelect,
  'prevent-outline': props.noOutline,
  'icon-disabled': props.disabled,
  'icon-static-view-box': props.staticViewBox,
  'icon-safe-area-view-box': props.safeAreaViewBox,
  'h-icon--no-custom-width': !props.width,
  'h-icon--default': props.color,
  'icon-filled': props.filled,
}));

const wrapperClass = computed(() => {
  const classes = [];

  if (props.backgroundColor) {
    classes.push(`icon-background icon-background-${props.backgroundColor}`);
  }

  return classes.join(' ');
});
</script>

<template>
  <div class="icon-wrapper" :class="wrapperClass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :aria-hidden="!title"
      :aria-labelledby="iconName || icon"
      focusable="false"
      :viewBox="!type ? viewBox : undefined"
      :class="[iconClass, customClass]"
      class="h-icon"
      :width="width"
      :height="height || width"
    >
      <title v-if="title" :id="iconName || icon" lang="en_GB">
        {{ title }}
      </title>
      <g>
        <Component :is="currentIcon" />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-flex;
  vertical-align: text-bottom;

  .h-icon {
    align-items: center;

    &--no-custom-width {
      width: 24px;
      height: 24px;
    }

    &.icon-static-view-box {
      align-items: center;
    }

    &--no-custom-width:not(.icon-static-view-box) {
      min-width: 24px;
    }

    &--no-custom-width {
      &.icon-safe-area-view-box {
        width: 32px;
        height: 32px;
      }
    }

    &--default {
      --icon-color: v-bind(iconColor);
      fill: v-bind(iconColor);
    }

    &.icon-disabled {
      fill: var(--secondaryText);
      cursor: default !important;
    }
  }
}
.prevent-select {
  user-select: none;
}

.prevent-outline {
  outline: none;
}

.icon-primary:not(.icon-disabled) {
  fill: var(--primary) !important;

  path {
    fill: var(--primary) !important;
  }
}

.icon-secondary:not(.icon-disabled) {
  fill: var(--secondary) !important;

  path {
    fill: var(--secondary) !important;
  }
}

.icon-success:not(.icon-disabled) {
  fill: var(--success) !important;
}

.icon-info:not(.icon-disabled) {
  fill: var(--primary-hostinger) !important;
}

.icon-warning:not(.icon-disabled) {
  fill: var(--warning) !important;

  path {
    fill: var(--warning) !important;
  }
}

.icon-warning-regular:not(.icon-disabled) {
  fill: var(--warning-regular) !important;
}

.icon-danger:not(.icon-disabled) {
  fill: var(--danger) !important;

  path {
    fill: var(--danger) !important;
  }
}

.icon-light:not(.icon-disabled) {
  fill: var(--light) !important;
}

.icon-dark:not(.icon-disabled) {
  fill: var(--dark) !important;
}

.icon-black:not(.icon-disabled) {
  fill: var(--dark) !important;
}

.icon-gray:not(.icon-disabled) {
  fill: var(--gray) !important;
}

.icon-gray-border:not(.icon-disabled) {
  fill: var(--gray-border) !important;
}

.icon-gray-light:not(.icon-disabled) {
  fill: var(--border_gray) !important;
}

.icon-header-bg:not(.icon-disabled) {
  fill: var(--header-bg) !important;
}

.icon-danger-light:not(.icon-disabled) {
  fill: var(--danger-light) !important;
}

.icon-success-dark:not(.icon-disabled) {
  fill: var(--success-dark) !important;
}

.icon-success-light:not(.icon-disabled) {
  fill: var(--success-light) !important;
}

.icon-warning-light:not(.icon-disabled) {
  fill: var(--warning-light) !important;
}

.icon-warning-dark:not(.icon-disabled) {
  fill: var(--warning-dark) !important;
}

.icon-meteorite-dark:not(.icon-disabled) {
  fill: var(--meteorite-dark) !important;
}

.icon-filled:not(.icon-disabled) {
  path {
    fill-rule: nonzero;
  }
}

.icon-background {
  padding: 12px;
  border-radius: 50%;
}

.icon-background-gray {
  background-color: var(--gray-light);
}
</style>
