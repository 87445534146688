import type {
  IHostingAccount,
  IHostingCronJob,
  IHostingCronJobRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingAccountIdentifier = {
  username: IHostingAccount['username'];
};

export default {
  url: ({ username }: HostingAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}`,

  async getCronJobs(accountIdentifier: HostingAccountIdentifier) {
    return await hAsync<IHostingCronJob[]>(
      http.get(`${this.url(accountIdentifier)}/cron-jobs`),
    );
  },

  async storeCronJob(
    accountIdentifier: HostingAccountIdentifier,
    data: IHostingCronJobRequest,
  ) {
    return await hAsync<IHostingCronJob>(
      http.post(`${this.url(accountIdentifier)}/cron-jobs`, data, {
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async deleteCronJob(
    accountIdentifier: HostingAccountIdentifier,
    pwKey: IHostingCronJob['pwkey'],
  ) {
    return await hAsync<IHostingCronJob[]>(
      http.delete(`${this.url(accountIdentifier)}/cron-jobs/${pwKey}`),
    );
  },

  async getCronJobOutput(
    accountIdentifier: HostingAccountIdentifier,
    pwKey: IHostingCronJob['pwkey'],
  ) {
    return await hAsync<{ output: string }>(
      http.get(`${this.url(accountIdentifier)}/cron-jobs/${pwKey}/output`),
    );
  },
};
