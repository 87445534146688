<template>
  <div>
    <p v-trans class="h-px-40">
      To install SSL on your domain name - you must have an active hosting plan
      and domain added to the hosting plan.
    </p>

    <h-modal-actions @close="close($event)">
      <HButtonV1 primary @click="goToStore">Select a hosting plan</HButtonV1>
    </h-modal-actions>
  </div>
</template>

<script>
import modalsMixin from '../../../utils/mixins/modalsMixin';

import { useModal, useResourceMapper } from '@/composables';
import { Route } from '@/types';

export default {
  name: 'NoActiveHostingPlan',
  mixins: [modalsMixin],
  setup() {
    const { mappedHomepageOrders } = useResourceMapper();
    const { closeModal } = useModal();

    return {
      closeModal,
      mappedHomepageOrders,
    };
  },
  computed: {
    pendingHostingOrder() {
      return this.mappedHomepageOrders.pendingOrders.find(
        ({ serviceName }) => serviceName === 'hosting',
      );
    },
  },
  methods: {
    goToStore() {
      if (this.pendingHostingOrder) {
        this.$router.push({
          name: 'onboarding_start',
          params: { order_id: this.pendingHostingOrder.id },
        });
      } else {
        this.$router.push({ name: Route.Base.HOSTING });
      }

      this.closeModal();
    },
  },
};
</script>
