import { wordpressRepo } from '@/repositories';
import {
  currentOrderId,
  getStateVariable,
  setForDomain,
} from '@/store/storeHelper';
import { Hosting } from '@/types';

const initial = {
  templateSelected: '',
  paletteId: '',
  layoutId: '',
  fontId: '',
  palettes: [],
  images: [],
  layouts: [],
  fonts: [],
};

export default {
  state: {
    initial,
    data: [],
    astraTemplates: null,
    astraTemplatesLoaded: false,
    searchedTemplate: null,
    templatesShownCount: 0,
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setAstraTemplates(state, data) {
      state.astraTemplates = data;
    },
    setAstraTemplatesLoaded(state, data) {
      state.astraTemplatesLoaded = data;
    },
    setCmsTemplate(state, data) {
      setForDomain(state, currentOrderId(), 'templateSelected', data);
    },
    setPaletteId(state, data) {
      setForDomain(state, currentOrderId(), 'paletteId', data);
    },
    setLayoutId(state, data) {
      setForDomain(state, currentOrderId(), 'layoutId', data);
    },
    setFontId(state, data) {
      setForDomain(state, currentOrderId(), 'fontId', data);
    },
    setPalettes(state, palettes) {
      setForDomain(state, currentOrderId(), 'palettes', palettes);
    },
    setLayouts(state, layouts) {
      setForDomain(state, currentOrderId(), 'layouts', layouts);
    },
    setFonts(state, fonts) {
      setForDomain(state, currentOrderId(), 'fonts', fonts);
    },
    setImages(state, images) {
      setForDomain(state, currentOrderId(), 'images', images);
    },
    setTemplatesShownCount(state, data = 0) {
      state.templatesShownCount = data;
    },
  },
  getters: {
    getTemplateLink: (state) => (searchId) => {
      if (!state.astraTemplates) return null;

      const template = [...Object.values(state.astraTemplates)]
        .flat()
        .find(({ slug }) => slug.toString() === searchId);

      return template ? template.link : null;
    },
    getCmsTemplate: (state) =>
      getStateVariable(state, 'templateSelected', currentOrderId()),
    templatePaletteId: (state) =>
      getStateVariable(state, 'paletteId', currentOrderId()),
    templateLayoutId: (state) =>
      getStateVariable(state, 'layoutId', currentOrderId()),
    templateFontId: (state) =>
      getStateVariable(state, 'fontId', currentOrderId()),
    wordpressTemplatePalettes: (state) =>
      getStateVariable(state, 'palettes', currentOrderId()),
    wordpressTemplateImages: (state) =>
      getStateVariable(state, 'images', currentOrderId()),
    wordpressTemplateLayouts: (state) =>
      getStateVariable(state, 'layouts', currentOrderId()),
    wordpressTemplateFonts: (state) =>
      getStateVariable(state, 'fonts', currentOrderId()),
    getCmsTemplateName: (state) => (searchId) => {
      if (!state.astraTemplates) return null;

      const searchIdDefault =
        searchId ||
        getStateVariable(state, 'templateSelected', currentOrderId());

      if (Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG === searchIdDefault) {
        return 'Hostinger Blog';
      }
      const template = [...Object.values(state.astraTemplates)]
        .flat()
        .find(({ slug }) => slug === searchIdDefault);

      return template ? template.title : null;
    },
    getAstraTemplates(state) {
      return state.astraTemplates;
    },
    getAstraTemplatesLoaded(state) {
      return state.astraTemplatesLoaded;
    },
    getTemplatesShownCount: (state) => state.templatesShownCount,
    getAmazonAffiliateTemplate(state) {
      const templateSlug =
        Hosting.WordpressTemplateSlugs.HOSTINGER_AFFILIATE_THEME;

      return state.astraTemplates['affiliate-marketing']?.find(
        ({ slug }) => slug === templateSlug,
      );
    },

    getHostingerDefaultTemplate: (state) => {
      const templateSlug = Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG;

      return state.astraTemplates?.default.find(
        ({ slug }) => slug === templateSlug,
      );
    },
    getHostingerBlogTemplate: (state) => {
      const templateSlug = Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG;

      return state.astraTemplates?.blog.find(
        ({ slug }) => slug === templateSlug,
      );
    },
  },
  actions: {
    async fetchAstraTemplates({ commit }, params) {
      const [{ data }] = await wordpressRepo.getWordpressThemes({
        orderId: currentOrderId() || params?.orderId,
        websiteType: params?.websiteType,
      });

      if (data) {
        commit('setAstraTemplates', data);
      }
      commit('setAstraTemplatesLoaded', true);
    },
  },
};
