import { computed } from 'vue';
import { useStore } from 'vuex';

import { useResourceMapper } from './useResourceMapper';

import { useSubscriptions } from '@/composables/useSubscriptions';
import { NON_UPGRADABLE_HOSTING_PLANS } from '@/data/globalConstants';
import { useOrdersStore } from '@/stores/ordersStore';
import type {
  Order,
  IHResource,
  BillingSubscription,
  SubscriptionCancelReason,
} from '@/types';
import {
  Hosting,
  NON_RENEWABLE_REASONS,
  BUSINESS_OR_ABOVE_PLANS,
  LOW_LEVEL_PLANS,
} from '@/types';

export const useHostingOrders = (subscription?: BillingSubscription) => {
  const store = useStore();
  const { canServiceBeReactivated } = useSubscriptions();
  const { mappedHomepageOrders } = useResourceMapper();
  const ordersStore = useOrdersStore();

  const hostingOrdersLoaded = computed(() => store.getters.getOrdersLoaded);

  const currentPlan = computed(
    () =>
      store?.getters?.currentOrderDetails?.plan ||
      subscription?.cfSubscriptionParams?.plan,
  );

  const hostingOrders = computed(
    (): Order[] => store.state.hosting.order.orders,
  );
  const pendingSetupHostingOrders = computed(
    () =>
      hostingOrders.value?.filter(
        ({ status }) => status === Hosting.OrderStatus.PENDING_DATA_ENTRY,
      ) || [],
  );

  const hasPendingSetupHostingOrders = computed(
    () => !!pendingSetupHostingOrders.value?.length,
  );
  const getHostingCardData = (hostingOrder: Order) => ({
    ...hostingOrder,
    websites: filteredAccountsByOrderId(hostingOrder?.id),
  });

  const getOrderOrManagedOrderByReferenceId = (referenceId: string) => {
    const managedOrderWithStatus = (() => {
      const order = ordersStore.getManagedOrderByOrderId(referenceId);

      if (!order) return null;

      return {
        ...order,
        status: order.resource.state,
      };
    })();

    return (
      store.getters.getOrderByReferenceId(referenceId) || managedOrderWithStatus
    );
  };

  const getHostingCardDataByReferenceId = (referenceId: string) =>
    getHostingCardData(getOrderOrManagedOrderByReferenceId(referenceId));

  const filteredAccountsByOrderId = (id: string) =>
    store.getters
      .getAccountsByOrder(id)
      ?.filter(({ removed }: Order) => !removed) || [];

  const mainIsWebsiteBuilder = ({ id, status }: Order) => {
    const account = filteredAccountsByOrderId(id);

    return (
      (!account.length && status === Hosting.OrderStatus.ACTIVE) ||
      account[0]?.isWebsiteBuilder === true
    );
  };

  const areAllWebsitesBuilder = (order: Order) => {
    const accounts = filteredAccountsByOrderId(order.id);

    return !!(
      accounts?.length &&
      accounts.every(
        (account: { isWebsiteBuilder: boolean }) => account.isWebsiteBuilder,
      )
    );
  };

  const filteredHostingOrders = computed(() =>
    hostingOrders.value.filter((order: any) => {
      const isPastRenewalWindow =
        !canServiceBeReactivated(order) &&
        order.status === Hosting.OrderStatus.SUSPENDED;

      if (isPastRenewalWindow) {
        return false;
      }

      const isOrderPendingDataEntry =
        order.status === Hosting.OrderStatus.PENDING_DATA_ENTRY;

      return (
        !!store.getters.getAccountsByOrder(order.id).length ||
        mainIsWebsiteBuilder(order) ||
        !isOrderPendingDataEntry ||
        (canServiceBeReactivated(order) && !isOrderPendingDataEntry)
      );
    }),
  );

  const hasHostingOrders = computed(
    () =>
      hostingOrdersLoaded.value &&
      (filteredHostingOrders.value.length ||
        hasPendingSetupHostingOrders.value),
  );

  const activeHostingOrders = computed(
    () =>
      mappedHomepageOrders.value.activeOrders?.find(
        (activeOrder) => activeOrder.section === 'Hosting',
      )?.orders,
  );

  const isSingleOrPremiumPlan = computed(() =>
    LOW_LEVEL_PLANS.includes(currentPlan.value),
  );
  const isAmazonAffiliateEligiblePlan = computed(() =>
    BUSINESS_OR_ABOVE_PLANS.includes(currentPlan.value),
  );
  const isPlanUpgradable = computed(
    () => !NON_UPGRADABLE_HOSTING_PLANS.includes(currentPlan.value),
  );

  const canceledHostingOrders = computed((): IHResource[] =>
    mappedHomepageOrders.value.canceledOrders.filter(
      (order) =>
        !(
          NON_RENEWABLE_REASONS as unknown as SubscriptionCancelReason[]
        ).includes(order.subscription.cancelReasonCode),
    ),
  );

  const getActiveOrderByOrderId = (id: string) =>
    activeHostingOrders.value?.find((activeOrder) => activeOrder.id === id);

  return {
    getHostingCardData,
    getHostingCardDataByReferenceId,
    hostingOrdersLoaded,
    hasPendingSetupHostingOrders,
    hostingOrders,
    hasHostingOrders,
    filteredHostingOrders,
    mainIsWebsiteBuilder,
    filteredAccountsByOrderId,
    pendingSetupHostingOrders,
    activeHostingOrders,
    canceledHostingOrders,
    getActiveOrderByOrderId,
    areAllWebsitesBuilder,
    isSingleOrPremiumPlan,
    isPlanUpgradable,
    isAmazonAffiliateEligiblePlan,
  };
};
