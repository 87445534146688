<template>
  <div
    class="h-aside-left"
    :class="{
      'h-aside-left--show': frontendSettingsStore.state.mAsideLeftShow,
    }"
  >
    <HCloseButton
      class="d-lg-none"
      right
      @click="frontendSettingsStore.toggleAsideLeftShow"
    />
    <HostingAccountSelect class="h-mb-20 h-mt-8" />
    <Component :is="sideMenuComponentFile" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import HCloseButton from '../HCloseButton';
import HostingAccountSelect from '../Hosting/HostingAccountSelect';
import HostingAccountSidebar from '../SideMenu/HostingAccountSidebar';
import SectionSideMenu from '../SideMenu/SectionSideMenu';

import { useFrontendSettingsStore } from '@/stores';
import { kebabToCamel } from '@/utils/services/namingConventionsService';

export default {
  name: 'HAsideLeft',
  components: {
    HCloseButton,
    HostingAccountSelect,
    HostingAccountSidebar,
    SectionSideMenu,
  },
  setup() {
    const frontendSettingsStore = useFrontendSettingsStore();

    return {
      frontendSettingsStore,
    };
  },
  computed: {
    sideMenuComponentFile() {
      return defineAsyncComponent(() =>
        import(
          `@/components/SideMenu/${kebabToCamel(
            this.frontendSettingsStore.state.asideComponent,
            true,
          )}.vue`
        ),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/layout/h-aside-left';
</style>
