import { isArray, isObject } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { useModal } from '@/composables';
import { getThreeDsVerifyErrors } from '@/data/billing/paymentData';
import { paymentsRepo, storedPaymentsRepo } from '@/repositories';
import { usePaymentMethodStore } from '@/stores';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';
import { initProcessout } from '@/utils/services/processout';

export default {
  data() {
    return {
      step: '',
      showModal: false,
      purchaseCompleted: false,
      preventRedirect: false,
      error: '',
      redirectLink: '',
      threeDsPublicKey: null,
      preventClose: false,
    };
  },
  computed: {
    isProcessingPayment() {
      return this.step === 'processingPayment';
    },
    isPurchasing() {
      return this.currentStep > 0 && !this.error;
    },
    isError() {
      return this.currentStep > 0 && this.error;
    },
    ...mapGetters('billing', ['getActivePaymentMethods', 'getDefaultPayment']),
  },
  created() {
    this.getBillingInformation();
  },
  methods: {
    getBillingInformation() {
      const paymentMethodStore = usePaymentMethodStore();
      this.billingGetPaymentMethods();
      paymentMethodStore.fetchAndSetPaymentMethods();
    },
    handlePayment(resolve, data, preventRedirect) {
      if (data.error && !preventRedirect) {
        if (data.redirect) {
          this.redirectLink = data.redirect;
        }
        if (!this.getActivePaymentMethods) {
          window.location.href = this.redirectLink;

          return;
        }
        throw new Error(data.error);
      }

      if (!this.getActivePaymentMethods && !data.redirect) {
        window.location = data;

        return;
      }

      if (!preventRedirect && data.redirect) {
        window.location = data.redirect;

        return;
      }

      if (!preventRedirect && this.$route?.params?.redirect_url) {
        window.location = this.$route?.params?.redirect_url;

        return resolve(true);
      }

      if (data.result) {
        if (!preventRedirect) this.purchaseCompleted = true;

        return resolve(data || true);
      }

      if (this.$route?.params?.redirect_url) {
        window.location = this.$route?.params?.redirect_url;

        return resolve(true);
      }

      if (preventRedirect && data.redirect) {
        return resolve(data);
      }

      return resolve(false);
    },
    resetError() {
      this.error = '';
      this.redirectLink = '';
    },

    async verify3dsPayment(paymentToken) {
      const [, err] = await storedPaymentsRepo.verifyPayment({
        paymentToken,
      });
      if (err) {
        this.error =
          getThreeDsVerifyErrors(err?.error.code) || err?.error.message || '';

        if (this.goToNextStep) {
          this.goToNextStep();
        }
        throw new Error();
      }
    },

    async process3dsPayment(resolve, data, cardToken, preventRedirect) {
      await initProcessout();
      // eslint-disable-next-line no-undef
      const client = new ProcessOut.ProcessOut(this.threeDsPublicKey);
      client.makeCardPayment(
        data.invoiceId,
        cardToken,
        {
          authorize_only: false,
        },
        async () => {
          await this.verify3dsPayment(data.paymentToken);
          this.handlePayment(resolve, data, preventRedirect);
          resolve(true);
        },
        async (err) => {
          this.error = err.message;
          if (this.goToNextStep) {
            this.goToNextStep();
          }
          setTimeout(() => {
            window.location.href = data.result || data.redirect;
          }, 2000);
          throw new Error(err);
        },
      );
    },

    async goToProcessingPayment(
      paymentDetails,
      isBundle,
      orderName,
      preventRedirect = null,
      invoiceId,
      preventClose,
    ) {
      this.resetError();

      if (preventClose) this.preventClose = preventClose;

      if (!this.preventProcessing) this.step = 'processingPayment';

      let paymentCall = paymentsRepo.instantPay;

      if (invoiceId) {
        paymentCall = paymentsRepo.instantInvoicePay;
      }

      if (this.paymentCall) paymentCall = this.paymentCall;

      return new Promise((resolve, reject) => {
        paymentCall(
          invoiceId ? invoiceId : paymentDetails,
          isBundle ? orderName : undefined,
        )
          .then((response) =>
            isObject(response) && !isArray(response) ? [response] : response,
          )
          .then(async ([{ data }, err]) => {
            this.threeDsPublicKey = snakeToCamelObj(
              this.getDefaultPayment,
            )?.features?.processoutJsSdk?.publicKey;

            const cardToken = this.getDefaultPayment?.token;

            if (err || data?.error) {
              this.error = err?.message || data?.error;
              if (data.redirect) this.redirectLink = data.redirect;
              if (this.goToNextStep) this.goToNextStep();

              return reject(err || { message: data.error });
            }

            return this.threeDsPublicKey
              ? this.process3dsPayment(
                  resolve,
                  data,
                  cardToken,
                  preventRedirect,
                )
              : this.handlePayment(resolve, data, preventRedirect);
          })
          .catch((error) => {
            this.$logError(error);

            this.error = error.message;
            if (this.goToNextStep) {
              this.goToNextStep();
            }

            return reject(error);
          });
      });
    },
    ...mapActions('billing', ['billingGetPaymentMethods']),
  },
  watch: {
    showModal(show) {
      const { closeModal } = useModal();

      if (!show) {
        this.step = '';
        this.purchaseCompleted = false;
        this.$emit('close');
        closeModal();
      }
    },
    purchaseCompleted(value) {
      const { closeModal } = useModal();

      if (value) {
        if (!this.preventClose) {
          setTimeout(() => {
            this.$emit('close');
            closeModal();
          }, 3000);
        }
      }
    },
    error(value) {
      if (value && !this.getActivePaymentMethods) {
        if (this.redirectLink) {
          window.location.href = this.redirectLink;
        }
      }
    },
  },
};
