<template>
  <HpIcon
    v-if="schema.hint"
    v-tooltip="fieldHint(schema)"
    icon="icon-info"
    gray
    width="18"
    height="18"
  />
</template>

<script>
import abstractField from '@/utils/mixins/form/abstractField';

export default {
  name: 'FieldPartHint',
  mixins: [abstractField],
};
</script>
