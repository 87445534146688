const CHUNK_SIZE = 2097152; // ~ 0.26MB

/**
 * @param file { File }
 * @param uploadRepo - Pass a repo function
 * @returns [{data}, error]
 */
export const uploadFile = async (
  file: File,
  uploadRepo: Function,
): Promise<[any, any] | undefined> => {
  const totalCount =
    file.size % CHUNK_SIZE === 0
      ? file.size / CHUNK_SIZE
      : Math.ceil(file.size / CHUNK_SIZE);

  for (let step = 0; step < totalCount; step++) {
    const count = step + 1;
    const startChunk = step * CHUNK_SIZE;
    const endChunk =
      CHUNK_SIZE * count >= file.size ? file.size : CHUNK_SIZE * count;
    const isFinalChunk = step === totalCount - 1;

    const [{ data }, error] = await uploadChunk(
      file,
      uploadRepo,
      startChunk,
      endChunk,
    );

    if (isFinalChunk || error) {
      return [{ data }, error];
    }
  }
};

const uploadChunk = async (
  file: File,
  uploadRepo: Function,
  startChunk: number,
  endChunk: number,
) => {
  const formData = new FormData();

  formData.append('file', file.slice(startChunk, endChunk));
  formData.append('start', String(startChunk));
  formData.append('end', String(endChunk));
  formData.append('file_size', String(file.size));
  formData.append('file_name', file.name);
  formData.append('file_ext', file.type);

  const response = await uploadRepo(formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    plain: true,
  });

  return response;
};
