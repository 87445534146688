<script setup lang="ts">
import { computed } from 'vue';

import { useBrands } from '@/composables';
import type { IDataCenterLocation } from '@/types';
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

type Props = {
  coordinates?: {
    x: number;
    y: number;
  };
  locations: IDataCenterLocation[];
  selectedColor?: string;
};

const props = withDefaults(defineProps<Props>(), {
  selectedColor: 'danger',
});

interface Emits {
  (eventName: 'select-location', payload: IDataCenterLocation): void;
}

const emit = defineEmits<Emits>();

const { isFromNiagahoster } = useBrands();

const pointSelectedColor = computed(() => wrapInCssVar(props.selectedColor));

const onSelect = (selectedLocation: IDataCenterLocation) => {
  emit('select-location', selectedLocation);
};
</script>

<template>
  <div dir="ltr">
    <div class="map-wrapper">
      <HImage
        :src="
          isFromNiagahoster
            ? '@/images/niagahoster/onboarding/world-map.svg'
            : '@/images/onboarding/onboarding-map.png'
        "
        :alt="$t('Select server location')"
        class="map"
      />
      <div>
        <div
          v-for="(location, index) in locations"
          :key="index"
          class="icon-wrapper"
          :style="{
            top: location.positions.y + 'px',
            left: location.positions.x + 'px',
          }"
          @click="onSelect(location)"
        >
          <HpIcon
            v-tooltip="location.label"
            :icon="('ic-map-pointer' as any)"
            class="point"
            :class="{
              'point--selected': location.selected,
            }"
            view-box="0 0 28 28"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .map-wrapper {
    display: none;
  }
}

.map-wrapper {
  position: relative;

  .map {
    width: 100%;
  }
  .icon-wrapper {
    position: absolute !important;
    top: var(--position-top);
    left: var(--position-left);
    cursor: pointer;
    width: 14px;
    height: 20px;
    &:hover {
      z-index: 1;
    }
    .point {
      fill: var(--primary);

      &--selected {
        fill: v-bind(pointSelectedColor) !important;
      }
      &:hover {
        fill: v-bind(pointSelectedColor) !important;
      }
    }
  }
}
</style>
