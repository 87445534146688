<template>
  <div class="purchase-buttons">
    <HButtonV1 no-margin text primary :disabled="loading" @click="close">
      {{ isBackButton ? 'Back' : 'Cancel' }}
    </HButtonV1>
    <HButtonV1
      v-tooltip="$permissions.tooltipDisabled()"
      no-margin
      primary
      :loading="loading"
      :disabled="disabled"
      :data-qa="`purchase-button-${step}`"
      @click="$emit('on-success')"
    >
      {{ successBtnText }}
    </HButtonV1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { useModal } from '@/composables';

export default {
  props: {
    loading: Boolean,
    error: Boolean,
    isConfirm: Boolean,
    isFirstStep: Boolean,
    isSecondStep: Boolean,
    hasTwoSteps: Boolean,
    creditPay: Boolean,
    customButtonText: String,
    hasCoupon: Boolean,
    step: String,
  },
  emits: ['on-close', 'on-success'],
  setup() {
    const { closeModal } = useModal();

    return {
      closeModal,
    };
  },
  computed: {
    successBtnText() {
      if (this.isFirstStep) return 'Continue';
      if (this.creditPay && !this.hasCoupon) {
        return 'Complete payment with Hostinger balance';
      }

      return this.isConfirm ? 'Confirm' : this.continueWithPaymentText;
    },
    completeButtonText() {
      return this.customButtonText || 'Complete Payment';
    },
    continueWithPaymentText() {
      return this.paymentMethod
        ? this.completeButtonText
        : 'Choose payment method';
    },
    isBackButton() {
      return this.isSecondStep && this.hasTwoSteps;
    },
    disabled() {
      return this.loading || this.error || this.$permissions.paymentDisabled();
    },
    ...mapGetters('paymentMethods', {
      paymentMethod: 'getDefaultPaymentMethod',
    }),
  },
  methods: {
    close() {
      this.isBackButton ? this.$emit('on-close') : this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-buttons {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 460px) {
    flex-direction: column-reverse;
  }
}
</style>
