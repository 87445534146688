import type { INpsAnswer, SNOOZE_DAYS } from '@/types';
import { SURVEY_TYPE } from '@/types';
import { hAsync } from '@/utils/helpers';
import cacheService from '@/utils/services/http/httpCacheService';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`,

  async getNpsEligible(surveyIdentifier: SURVEY_TYPE = SURVEY_TYPE.NPS) {
    const requestUrl = `${this.url}/eligibility`;
    const requestParams = {
      params: {
        surveyIdentifier,
      },
      cache: 24 * 60 * 60, // 24 hours
      whitelistedStatusCodes: [400, 422, 429],
      hideNetworkError: true,
    };

    const response = await hAsync<{ isEligible: boolean }>(
      http.get(requestUrl, requestParams),
    );

    // Forget cache if user is eligible. If not eligible, keep cache for 24 hours
    if (response[0].data?.isEligible !== false) {
      cacheService.forgetHttpCall(requestUrl, requestParams);
    }

    return response;
  },
  async createNps(answers: INpsAnswer[]) {
    return await hAsync(
      http.post(
        `${this.url}/response`,
        {
          answers,
          surveyIdentifier: SURVEY_TYPE.NPS,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },
  async closeNps(surveyIdentifier: SURVEY_TYPE, snoozeDays: SNOOZE_DAYS) {
    return await hAsync(
      http.post(
        `${this.url}/snooze`,
        {
          surveyIdentifier,
          snoozeDays,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },

  async createNpsManual(answers: INpsAnswer[]) {
    return await hAsync(
      http.post(
        `${this.url}/response`,
        {
          answers,
          surveyIdentifier: SURVEY_TYPE.CSAT,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },
};
