<template>
  <div>
    <div :id="detached ? 'input_container_detached' : 'input_container'">
      <input
        :id="!!searchId ? searchId : 'hpanel_tracking-search-bar'"
        v-model="search"
        type="text"
        class="form-control"
        :class="{
          'search-input--icon-left': searchIconLeft,
          'search-input--detached': detached,
          'form-control--error': searchError,
        }"
        :placeholder="placeholder ? placeholder : $t('Search')"
        autocomplete="off"
      />
      <span
        v-if="searching"
        key="circle"
        :class="{
          'icon-detached__loading': detached,
        }"
      >
        <HpIcon
          id="icon-loading"
          icon="icon-refresh"
          gray
          width="18"
          height="18"
        />
      </span>
      <span
        v-else-if="search"
        key="times"
        :class="{
          'icon-detached': detached,
        }"
        @click="clearInput"
      >
        <HpIcon id="icon-clear" icon="icon-close" gray width="18" height="18" />
      </span>
      <span
        v-if="(!search && !searching) || searchIconLeft"
        key="search"
        :class="{
          'icon-left': searchIconLeft,
          'icon-detached': detached,
        }"
      >
        <HpIcon
          id="icon-search"
          icon="icon-search"
          gray
          width="18"
          height="18"
        />
      </span>
    </div>
    <Trans v-if="searchError" class="h-data-table-search__search-error">
      {{ searchError }}
    </Trans>
  </div>
</template>

<script>
export default {
  name: 'HDataTableSearch',
  props: {
    hSearch: String,
    searchIconLeft: Boolean,
    searchError: String,
    searching: Boolean,
    searchId: String,
    detached: Boolean,
    placeholder: String,
  },
  data() {
    return {
      search: '',
    };
  },
  emits: ['update:hSearch'],
  watch: {
    hSearch(value) {
      this.search = value;
    },
    search(value) {
      this.$emit('update:hSearch', value);
    },
  },
  methods: {
    clearInput() {
      this.search = '';
    },
  },
};
</script>
<style lang="scss" scoped>
#input_container {
  position: relative;
  padding-bottom: 21px;
  margin-bottom: -4px;
  box-sizing: border-box;

  @media (max-width: 575px) {
    padding: 24px;
  }
}
#input_container_detached {
  position: relative;
  box-sizing: border-box;
}

.form-control--error {
  &.form-control {
    border: 1px solid var(--danger);
  }
}

.h-data-table-search__search-error {
  display: block;

  font-size: 12px;
  color: var(--danger);
  padding-left: 16px;
  padding-right: 16px;
  line-height: 18px;
  margin-top: -16px;
  padding-bottom: 10px;
}

.icon-wrapper {
  color: var(--secondaryText);
  position: absolute;
  right: 15px;
  width: auto;
  height: 16px;
  top: calc(50% - 10px);
  transform: translateY(-50%);
  max-width: 20px;

  @media (max-width: 575px) {
    right: 40px;
    top: 50%;
  }
}

.search-input--icon-left {
  padding-left: 48px;
}

.search-input--detached {
  height: 40px;
}

.icon-left {
  .icon-wrapper {
    left: 16px;

    @media (max-width: 575px) {
      left: 40px;
    }
  }
}

.icon-detached {
  .icon-wrapper {
    top: 20px;
  }

  &__loading {
    .icon-wrapper {
      top: 12px !important;
    }
  }
}

#icon-loading {
  top: calc(50% - 116px);
  animation: spin 6s infinite linear;

  @media (max-width: 575px) {
    top: calc(50% - 8px);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}

#icon-clear {
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  #input_container {
    border-bottom: none !important;
  }
}
</style>
