import { Client } from '@/types';

const NIAGA_COLOR_CONFIG = {
  primary: '#007aff',
  'primary-dark': '#002199',
  'primary-light': '#e0efff',
  'primary-lightest': '#ebf4ff',
  'primary-chart': '#80bdff',

  meteorite: '#43ccff',
  'meteorite-dark': '#01012e',
  'meteorite-light': '#f5faff',

  warning: '#ff9800',
  'warning-dark': '#ff5a00',
  'warning-chart': '#ffcb80',

  'white-blue': '#f5faff',
  'header-bg': '#ffffff',

  'darken-light': '#e0efff',
  'blue-dark': '#265AB2',
} as const;

const DEFAULT_CONFIG = {
  id: null,
  key: 'hostinger',
  name: 'Hostinger',
  faviconLinks: {
    regular: '/favicons/hostinger.png',
    appleTouch: '/favicons/hostinger-apple-touch-icon.png',
  },
  colorConfig: undefined,
  hasCustomLoader: undefined,
  loaders: undefined,
} as const;

export const BRAND_CONFIGURATION = {
  [Client.BrandId.Other.HOSTING24]: {
    ...DEFAULT_CONFIG,
    id: Client.BrandId.Other.HOSTING24,
    key: 'hosting24',
    name: 'Hosting24',
    faviconLinks: {
      regular: '/favicons/hosting24.png',
      appleTouch: '/favicons/hosting24-apple-touch-icon.png',
    },
  },
  [Client.BrandId.Other.HOSTMANIA]: {
    ...DEFAULT_CONFIG,
    id: Client.BrandId.Other.HOSTMANIA,
    key: 'hostmania',
    name: 'Hostmania',
    faviconLinks: {
      regular: '/favicons/hostmania.png',
      appleTouch: '/favicons/hostmania-apple-touch-icon.png',
    },
  },
  [Client.BrandId.Other.WEBLINK]: {
    ...DEFAULT_CONFIG,
    id: Client.BrandId.Other.WEBLINK,
    key: 'weblink',
    name: 'Weblink',
    faviconLinks: {
      regular: '/favicons/weblink.png',
      appleTouch: '/favicons/weblink-apple-touch-icon.png',
    },
  },
  [Client.BrandId.Other.NG_CO_ID]: {
    ...DEFAULT_CONFIG,
    id: Client.BrandId.Other.NG_CO_ID,
    key: 'niagahoster',
    name: 'Niagahoster',
    faviconLinks: {
      regular: '/favicons/niagahoster.png',
      appleTouch: '/favicons/niagahoster-apple-touch-icon.png',
    },
    colorConfig: NIAGA_COLOR_CONFIG,
    hostname: 'www.niagahoster.co.id',
    hasCustomLoader: true,
    loaders: {
      circle: 'loaders/niagahoster-loader-circle.svg',
      animated: 'loaders/niagahoster-loader-logo.svg',
      static: 'loaders/niagahoster-loader-logo.svg',
    },
    onboarding: {
      setupSteps: [
        {
          title: 'Easy Collaboration With Your Partners',
          description:
            'The Account Sharing feature allows you to safely grant access to another person, for example, a developer.',
          image: '@/images/niagahoster/onboarding/setup-carousel-1.svg',
          step: 0,
        },
        {
          title: 'SSL Installed Automatically',
          description:
            'Protect your website and gain customers’ trust with a free SSL certificate. The certificate encrypts data, making sure it won’t be compromised.',
          image: '@/images/niagahoster/onboarding/setup-carousel-2.svg',
          step: 1,
        },
      ],
    },
    nameservers: ['ns1.niagahoster.com', 'ns2.niagahoster.com'],
  },
  default: DEFAULT_CONFIG,
} as const;

export type BrandConfiguration =
  (typeof BRAND_CONFIGURATION)[keyof typeof BRAND_CONFIGURATION];
