import { serverInfoRepo, whDatabasesRepo } from '@/repositories';
import { router } from '@/router';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { toLocalTime } from '@/utils/helpers';

const getCurrentOrderDetails = (getters) => {
  const currentDomain = router.currentRoute.value.params.domain;
  const currentAccount = !currentDomain
    ? getters.getCurrentAccount
    : getters.getCurrentAccountWithDomain(currentDomain);

  const { username, domain } = currentAccount || {};

  return {
    username,
    domain,
  };
};
export default {
  state: {
    initial: {
      databases: [],
      pagination: {
        page: 1,
        perPage: 25,
        total: 0,
        totalPages: 1,
      },
      mysqlServerInfo: {},
      loaded: false,
      serverInfoLoaded: false,
      databasesLoading: false,
    },
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_DATABASES_PAGINATION(state, data) {
      setForDomain(state, null, 'pagination', data);
    },
    SET_DATABASES_DATA(state, data) {
      setForDomain(state, null, 'databases', data);
      setForDomain(state, null, 'loaded', true);
    },
    DELETE_DATABASES(state, dbName) {
      let databases = getStateVariable(state, 'databases');
      databases = databases.filter((db) => db.dbName !== dbName);
      setForDomain(state, null, 'databases', databases);
    },
    SET_MYSQL_DATABASE_SERVER_INFO(state, data) {
      setForDomain(state, null, 'mysqlServerInfo', data);
    },
    SET_DATABASES_LOADING(state, data) {
      setForDomain(state, null, 'databasesLoading', data);
    },
  },
  getters: {
    getSqlDatabasesListPagination: (state) =>
      getStateVariable(state, 'pagination'),
    getSqlDatabasesList: (state) =>
      getStateVariable(state, 'databases').sort(
        (a, b) => toLocalTime(b.createdAt, 'x') - toLocalTime(a.createdAt, 'x'),
      ),
    getDatabaseLoadingStatus: (state) =>
      getStateVariable(state, 'databasesLoading'),
    getSqlDbState: (state) => getStateVariable(state),
    getMaxLimitDatabase: (state) => {
      let max = '0';
      getStateVariable(state, 'databases').forEach(({ mysqlMaxSize }) => {
        const maxSize = Number(mysqlMaxSize);
        if (max < maxSize) max = maxSize;
      });

      return max;
    },
    getFullLimitReachedDatabases: (state) =>
      getStateVariable(state, 'databases')
        .map(({ diskUsage, mysqlMaxSize, dbName, dbUser, password }) => {
          if (diskUsage >= mysqlMaxSize) return { dbName, dbUser, password };
        })
        .filter((value) => value),
    getPartialLimitReachedDatabases: (state) =>
      getStateVariable(state, 'databases')
        .map(({ diskUsage, mysqlMaxSize, dbName, dbUser, password }) => {
          if (diskUsage >= mysqlMaxSize * 0.8 && diskUsage < mysqlMaxSize) {
            return { dbName, dbUser, password };
          }
        })
        .filter((value) => value),
  },
  actions: {
    async fetchSqlDatabases({ commit, getters, dispatch }, params) {
      dispatch('fetchMysqlDatabaseServerInfo');

      const [{ data }, err] = await whDatabasesRepo.getDatabases(
        getCurrentOrderDetails(getters),
        params,
      );

      commit('SET_DATABASES_DATA', !err ? data.resources : []);
      commit('SET_DATABASES_PAGINATION', !err ? data.pagination : []);

      return data;
    },
    async setDatabaseDomain({ commit, getters }, request) {
      commit('SET_DATABASES_LOADING', true);
      const [, err] = await whDatabasesRepo.changeDatabaseVhost(
        getCurrentOrderDetails(getters),
        request.name,
        request.vhost,
      );

      commit('SET_DATABASES_LOADING', false);

      return [err];
    },
    async fetchMysqlDatabaseServerInfo({ commit, rootGetters }) {
      const serverId = rootGetters.getCurrentAccountServerID;

      const [{ data }, err] = await serverInfoRepo.getMySqlServerInfo(serverId);

      commit('SET_MYSQL_DATABASE_SERVER_INFO', !err ? data : {});
    },
    async createMySQLDatabase({ getters }, request) {
      const accountDetails = getCurrentOrderDetails(getters);
      const [_, err] = await whDatabasesRepo.createDatabase(accountDetails, {
        vhost: accountDetails.domain,
        ...request,
      });

      return [_, err];
    },
    async deleteMySQLDatabase({ commit, getters }, dbName) {
      const [_, err] = await whDatabasesRepo.deleteDatabase(
        getCurrentOrderDetails(getters),
        dbName,
      );

      if (!err) commit('DELETE_DATABASES', dbName);

      return [_, err];
    },
  },
};
