import { computed } from 'vue';

import { useSaleCampaign } from '@/composables';
import { Sale } from '@/types';

export const useCyberWeekSaleCampaign = () => {
  const { isBlackFridaySaleAvailable, activeSale } = useSaleCampaign();

  const isCyberWeekSaleAvailable = computed(
    () =>
      isBlackFridaySaleAvailable.value &&
      activeSale.value?.name === Sale.CYBER_WEEK,
  );

  return {
    isCyberWeekSaleAvailable,
  };
};
