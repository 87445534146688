<template>
  <div class="container">
    <HImage
      class="h-mb-24"
      :src="
        success ? '@/images/loader-check.svg' : '@/images/loader-failed.svg'
      "
      :alt="$t(`${success ? 'success' : 'Error'}`)"
    />
    <h3 v-trans class="h-mb-0">
      {{ title }}
    </h3>
    <p class="h-mb-0">
      {{ text }}
    </p>
    <HButtonV1
      text
      primary
      outline
      class="h-mt-24"
      no-margin
      @click="$emit('close')"
    >
      Close
    </HButtonV1>
  </div>
</template>

<script>
export default {
  props: {
    success: Boolean,
    err: String,
  },
  emits: ['close'],
  computed: {
    imagePath() {
      return this.success
        ? '@/images/loader-check.svg'
        : '@/images/loader-failed.svg';
    },
    title() {
      return this.success
        ? 'Server Transfer Started'
        : 'Server Transfer Failed';
    },
    text() {
      return this.success
        ? this.$t(
            'The process can take up to 12 hours to complete the transfer',
          )
        : this.err;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 288px;
  align-items: center;
  justify-content: center;
}
</style>
