import type { NavigationGuard } from 'vue-router';

import { errorLogger } from '@/utils/services/errorLogging';

const pageNotFoundGuard: NavigationGuard = (_, from, next) => {
  if (from.path === '/') {
    return next();
  }

  errorLogger.logError('Page not found is shown');

  return next();
};

export default pageNotFoundGuard;
