import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import ExpirationReminderModal from '@/components/Modals/HModal/ExpirationReminderModal.vue';
import { useCookies, useGlobals, useModal, useBrands } from '@/composables';
import { profileRepo } from '@/repositories';
import { useProfileStore, useResourcesStore } from '@/stores';
import type { ReminderUpdateRequest, HToastrInput } from '@/types';
import { Cookie, AmplitudeEvent } from '@/types';
import { timeout } from '@/utils/helpers';

export const useServiceExpirationReminder = () => {
  const store = useStore();
  const { toastr, t, amplitudeV2 } = useGlobals();
  const profileStore = useProfileStore();
  const { openModal } = useModal();
  const { setCookie, cookie: closedCookie } = useCookies(
    Cookie.SERVICE_EXPIRATION_REMINDER_CLOSED,
  );
  const { isFromAnotherBrand } = useBrands();
  const resourceStore = useResourcesStore();

  const isLoading = ref(false);

  const setClosedCookie = () => setCookie('true', { expires: 7 });

  const isReminderSubscribed = computed(
    () =>
      !!profileStore.settings?.isSubscribedToSmsReminder ||
      !!profileStore.settings?.isSubscribedToWhatsappReminder,
  );

  const hasPhoneNumber = computed(() => !!profileStore.contact?.phone);

  const hasActiveDomainResource = computed(
    () => !!resourceStore.activeDomainResources.length,
  );

  const isToasterVisible = computed(
    () =>
      !closedCookie.value &&
      !isReminderSubscribed.value &&
      !profileStore.isAccessManager &&
      hasActiveDomainResource.value &&
      !isFromAnotherBrand.value,
  );

  const showExpirationReminderToaster = async () => {
    if (!isToasterVisible.value) return;

    const ONE_MINUTE = 60 * 1000;

    await timeout(ONE_MINUTE);
    amplitudeV2(AmplitudeEvent.PhoneNumber.NOTIFICATION_SHOWN);
    toastr.i(
      t('Turn on your service expiration reminders via WhatsApp and SMS'),
      {
        text: t(
          'By enabling, you consent to phone number use for sending reminders as per our Privacy Policy. You can withdraw consent anytime.',
        ),
        imagePath: `@/images/product-icons/hostinger_mail.svg`,
        timeout: ONE_MINUTE,
        buttons: [
          {
            text: t('Later'),
            props: { primary: true, text: true },
            callback: onLater,
          },
          {
            text: t('Enable now'),
            props: { primary: true, text: true },
            callback: onEnable,
          },
        ],
        closeCallback: onClose,
      } as HToastrInput,
    );
  };

  const onEnable = () => {
    amplitudeV2(AmplitudeEvent.PhoneNumber.ENABLE_CLICKED);

    if (!hasPhoneNumber.value) {
      openModal({ component: { ExpirationReminderModal } });

      return;
    }

    const phone = profileStore.contact?.phone || '';
    const phoneCc = profileStore.contact?.phoneCc || '';

    updateReminderSettings({
      reminderSubscriptions: { sms: true, whatsapp: true },
      phone,
      phoneCc,
    });
  };

  const updateReminderSettings = async ({
    reminderSubscriptions,
    phone,
    phoneCc,
    toggleReminder,
  }: ReminderUpdateRequest) => {
    isLoading.value = true;

    const [{ data }, err] = await profileRepo.updateReminderSettings({
      reminderSubscriptions,
      phone,
      phoneCc,
    });

    if (err) {
      isLoading.value = false;

      return [{ data }, err];
    }

    toggleReminder
      ? showToggledSuccessToast(reminderSubscriptions)
      : showSuccessToast();

    store.commit('SET_CLIENT_INFO_PROPERTY', {
      name: 'reminderSubscriptions',
      value: reminderSubscriptions,
    });

    return [{ data }, err];
  };

  const showSuccessToast = () => {
    amplitudeV2(AmplitudeEvent.PhoneNumber.COMPLETED_SHOWN);

    toastr.s(t('You have enabled service expiration reminders'), {
      text: t(
        'You can disable reminders at any time on the Account Information page',
      ),
    });
  };

  const showToggledSuccessToast = (reminderSubscriptions: {
    sms?: boolean;
    whatsapp?: boolean;
  }) =>
    reminderSubscriptions.sms || reminderSubscriptions.whatsapp
      ? toastr.s(
          t('You have enabled service expiration notices/renewal reminders'),
        )
      : toastr.s(
          t('You have disabled service expiration notices/renewal reminders'),
        );

  const onLater = () => {
    amplitudeV2(AmplitudeEvent.PhoneNumber.NOTIFICATION_LATER_CLICKED);
    setClosedCookie();
  };

  const onClose = () => {
    amplitudeV2(AmplitudeEvent.PhoneNumber.NOTIFICATION_CLOSE_CLICKED);
    setClosedCookie();
  };

  return { showExpirationReminderToaster, updateReminderSettings, isLoading };
};
