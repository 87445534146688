import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';

import { Route } from '@/types';

const proPanelRegistrationGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  next({ name: Route.Base.HOME });
};

export default proPanelRegistrationGuard;
