import { billingRepo } from '@/repositories';

export default {
  namespaced: true,
  state: {
    selectedItems: [],
    invoice: {
      data: {},
      survey: {},
      hash: null,
      loaded: false,
    },
    selectedRefundObject: {
      subscriptionIdList: [],
      selectedMethod: null,
    },
  },
  getters: {
    getSelectedServices(state) {
      return state.invoice.data.items?.filter((item) => item.isSelected);
    },
    getRefundObject(state) {
      return {
        refundReason: state.invoice.survey?.selectedFeedback || 'N/A',
        clientNote: state.invoice.survey?.customFeedback || 'N/A',
        subscriptions: state.selectedRefundObject.subscriptionIdList,
      };
    },
    getSelectedRefundMethod(state) {
      return state.selectedRefundObject.selectedMethod;
    },
  },
  mutations: {
    SET_SELECTED_REFUND_OBJECT(state, payload) {
      state.selectedRefundObject = payload;
    },
    SET_LOADED(state, { type, value }) {
      state[type].loaded = value;
    },
    SET_INVOICE(state, payload) {
      state.invoice.data = {
        ...payload,
        items: payload.items.map((item) => ({
          ...item,
          isSelected: false,
        })),
      };
    },
    SET_INVOICE_SURVEY(state, surveyResponse) {
      state.invoice.survey = surveyResponse;
    },
    SET_INVOICE_HASH(state, hash) {
      state.invoice.hash = hash;
    },
    TOGGLE_SERVICE_SELECT(state, id) {
      const item =
        state.invoice?.data?.items?.find((service) => service.id === id) || {};
      item.isSelected = !item.isSelected;

      if (item.service !== 'hosting') return;

      state.invoice?.data.items?.forEach((service) => {
        if (
          service.parentCartItemId === item.cartItemId &&
          service.price === '0.00'
        ) {
          service.isSelected = !service.isSelected;
        }
      });
    },
    SET_INVOICE_SELECTED_ITEMS(state, selectedItems = []) {
      state.selectedItems = [...selectedItems];
    },
  },
  actions: {
    async getInvoice({ commit }, hash) {
      const [{ data }, err] = await billingRepo.getRefundInvoice(hash);
      if (!err) {
        commit('SET_INVOICE', data);
        commit('SET_INVOICE_HASH', hash);
      }

      commit('SET_LOADED', { type: 'invoice', value: true });

      return [{ data }, err];
    },
    async requestRefund({ state, getters }) {
      const getRefundType = () => {
        const items = state.invoice.data.items;
        const selectedItems = getters.getSelectedServices;
        const invoiceItems = items.filter(
          ({ invoiceId }) => state.invoice.data.invoiceId === invoiceId,
        );

        return invoiceItems.length === selectedItems.length
          ? 'full'
          : 'partial';
      };

      const payload = {
        invoiceId: state.invoice.data.invoiceId,
        relatedInvoiceId: state.invoice.data.relatedInvoiceId,
        type: getRefundType(),
        clientNote: state.invoice.survey.customFeedback,
        refundReason: state.invoice.survey.selectedFeedback,
        toBalance: false,
        servicesToRefund: getters.getSelectedServices.map((service) => ({
          ...service,
          name: service.title,
        })),
        paymentGateway: state.invoice.data.gateway,
        survey: {
          response: state.invoice.survey.selectedFeedback,
          feedback: state.invoice.survey.customFeedback,
        },
      };

      return await billingRepo.requestRefund(payload);
    },
  },
};
