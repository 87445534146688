<template>
  <div>
    <Trans
      tag="p"
      class="h-px-40"
      :translate-params="{
        hostingName: data.hostingName,
        startDate: toLocalTime(data.startDate, 'LLL'),
      }"
    >
      As a result, your website(s) under
      <strong>{hostingName}</strong>
      plan might be unavailable for 60 minutes on {startDate} your local time.
      We're performing server maintenance to increase your website load speed.
    </Trans>
    <div class="d-flex justify-content-end h-p-24">
      <HButtonV1 no-margin primary text @click="close">Close</HButtonV1>
    </div>
  </div>
</template>

<script>
import modalsMixin from '../../../utils/mixins/modalsMixin';

import { toLocalTime } from '@/utils/helpers';

export default {
  name: 'PlannedMaintenanceModal',
  mixins: [modalsMixin],
  methods: { toLocalTime },
};
</script>
