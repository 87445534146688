import type {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

export interface RequestConfig<D = any> extends AxiosRequestConfig<D> {
  plain?: boolean;
  withIdempotencyKey?: boolean;
  domain?: string;
  /**
   * Indicates how long the response should be cached.
   * When this value is set, the response will be taken
   * from the cache if it is not expired.
   */
  cache?: number;
  track?: number;
  hideToastr?: boolean;
  hideNetworkError?: boolean;
  noHostingHeaders?: boolean;
  cacheOnly?: boolean;
  hBillingGaTracking?: boolean;
  overrideCache?: boolean;
  hostingHeaderRequired?: boolean;
  whitelistedErrorCodes?: (number | string)[];
  whitelistedStatusCodes?: number[];
  getIsResponseWhitelisted?: (response: AxiosResponse) => boolean;
  /**
   * Indicates that request returns an updated token,
   * which has to replace the current one.
   */
  withTokenUpdate?: boolean;
}

export type InternalRequestConfig = InternalAxiosRequestConfig<RequestConfig> &
  RequestConfig;

export interface Response extends AxiosResponse {
  config: InternalRequestConfig;
}

export interface HAxiosInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalRequestConfig>;
    response: AxiosInterceptorManager<Response>;
  };
}

export class CustomApiError extends Error {
  error: any;
  response?: any;
  url?: string;
  isCanceledRequest?: boolean;

  constructor({
    error,
    response,
    url,
    isCanceledRequest,
  }: {
    error: any;
    response?: any;
    url?: string;
    isCanceledRequest?: boolean;
  }) {
    const defaultMessage = 'An error occurred';
    const message = typeof error === 'string' ? error : error?.message;
    // Provide a default message
    super(message || defaultMessage);
    this.name = 'CustomApiError';
    this.error = error;
    this.response = response;
    this.url = url;
    this.isCanceledRequest = isCanceledRequest;
  }
}
