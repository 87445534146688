import type { App } from 'vue';
import { mapState } from 'vuex';

export const setMixins = (app: App) => {
  app.mixin({
    computed: {
      ...mapState({
        $redirect: (state: any) => state.redirect,
      }),
    },
  });
};
