import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  restUrl: `${process.env.VITE_API_REST}/v2/git`,

  async fetchRepositories() {
    return await hAsync(http.get(this.restUrl));
  },
  async addRepository(payload) {
    return await hAsync(http.post(this.restUrl, payload));
  },
  async removeRepository(id) {
    return await hAsync(http.delete(`${this.restUrl}/${id}`));
  },
  async confirmOutput(hash) {
    return await hAsync(
      http.get(`${this.restUrl}/output`, { params: { hash } }),
    );
  },
  async deploy(id) {
    return await hAsync(http.post(`${this.restUrl}/${id}/deploy`));
  },
  async getAutoDeploy(id) {
    return await hAsync(http.get(`${this.restUrl}/${id}/auto-deploy`));
  },
};
