import type {
  CanceledOrder,
  OrderWithLimits,
  IManagedOrderWithLimits,
} from '@/types';
import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  ssoUrl: `${process.env.VITE_API_SSO}/v1/orders`,
  restUrl: `${process.env.VITE_API_REST}/v2`,
  restUrlV3: `${process.env.VITE_API_REST}/v3`,

  async getOrdersAccounts(id: string) {
    return await hAsync(
      http.get(`${this.restUrl}/orders/accounts`, {
        noHostingHeaders: true,
        params: {
          orderId: id,
        },
        headers: {
          [Header.ORDER_ID]: id,
        },
        hideToastr: true,
      }),
    );
  },
  async getManagedOrders(includeSuspended: boolean = false) {
    return await hAsync<IManagedOrderWithLimits[]>(
      http.get(
        `${this.restUrlV3}/orders/list-managed-with-limits?include_suspended=${
          includeSuspended ? 1 : 0
        }`,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async getCanceledHostingOrders() {
    return await hAsync<CanceledOrder[]>(
      http.get(`${this.restUrl}/orders/cancelled-orders`),
    );
  },
  // @ts-ignore
  async toggleAutoRenew(id, autoRenew) {
    return await hAsync(
      http.patch(`${this.ssoUrl}/${id}/auto-renew`, { autoRenew }),
    );
  },

  // @ts-ignore
  async getWebsitesToMigrate(id, params) {
    return await hAsync(http.get(`${this.ssoUrl}/${id}/accounts`, params));
  },

  // @ts-ignore
  async transferDataCenter(datacenter) {
    return await hAsync(
      http.post(`${this.restUrl}/orders/transfer`, { datacenter }),
    );
  },

  // @ts-ignore
  getRenewLink() {
    return hAsync(http.get(`${this.restUrl}/orders/renew-link`));
  },
  // @ts-ignore
  async restoreHostingAccountIndex({ domain }) {
    return await hAsync(
      http.post(
        `${this.restUrl}/orders/account/restore`,
        { domain },
        { domain },
      ),
    );
  },

  // @ts-ignore
  async deleteHostingAccountIndex({ domain }) {
    return await hAsync(
      http.delete(`${this.restUrl}/other/deactivate`, {
        params: {
          domain,
          delete: 1,
        },
        domain,
      }),
    );
  },

  // @ts-ignore
  async postKillRunningProcesses() {
    return await hAsync(
      http.post(`${this.restUrl}/orders/account/kill-running-processes`),
    );
  },
  // @ts-ignore
  async getListWithLimits(includeSuspended: boolean = false) {
    return await hAsync<OrderWithLimits[]>(
      http.get(
        `${this.restUrl}/orders/list-with-limits?include_suspended=${
          includeSuspended ? 1 : 0
        }`,
      ),
    );
  },

  // @ts-ignore
  async getBoostedOrders(domain) {
    return await hAsync(
      http.get(`${this.restUrl}/orders/plan-boost`, {
        domain,
        hideToastr: true,
        hostingHeaderRequired: true,
      }),
    );
  },

  async customizePlanName({
    planName,
    domain,
  }: {
    planName: string;
    domain: string;
  }) {
    return await hAsync<boolean>(
      http.patch(
        `${this.restUrl}/orders/custom-plan-name`,
        {
          planName,
        },
        { domain },
      ),
    );
  },

  async getHostingOrderUsage(orderId: string) {
    return await hAsync<number>(
      http.get(`${this.restUrl}/orders/usage`, {
        headers: {
          [Header.ORDER_ID]: orderId,
        },
        noHostingHeaders: true,
        hideToastr: true,
      }),
    );
  },
};
