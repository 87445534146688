<template>
  <transition-group name="fade-slow" tag="span">
    <div v-if="password" key="requirements">
      <div
        v-for="(requirement, index) in requirements"
        :key="`requirement-${index}`"
        class="requirement"
      >
        <div>
          <div>
            <HpIcon
              v-if="requirement.passed"
              :key="requirement.passed"
              icon="ic-check"
              success
              view-box="-8 -6 36 36"
            />
            <HpIcon
              v-else
              :key="`${requirement.passed}-1`"
              icon="ic-circle"
              gray
              view-box="-12 -12 32 36"
            />
          </div>
          <div class="h-ml-8">
            {{ $t(requirement.title) }}
            {{ requirement.titleAppend && requirement.titleAppend }}
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  data() {
    return {
      requirementsModel: {
        oneNumber: {
          passed: false,
          title: 'One number',
          regExp: /\d/,
          default: true,
        },
        oneSymbol: {
          passed: false,
          title: 'One symbol',
          // eslint-disable-next-line no-useless-escape
          regExp: /[\\!"#$%&'`()*+,-.\/:;<=>?@[\]^_{|}~]/,
          default: true,
        },
        oneAllowedSymbol: {
          passed: false,
          title: 'One symbol',
          titleAppend: ' #%+:=?@',
          // eslint-disable-next-line no-useless-escape
          regExp: /[#%+:=?@]/,
          default: false,
        },
        oneLowerCase: {
          passed: false,
          title: 'One lowercase letter',
          regExp: /[a-z]/,
          default: true,
        },
        oneUpperCase: {
          passed: false,
          title: 'One uppercase letter',
          regExp: /[A-Z]/,
          default: true,
        },
        use8to50chars: {
          passed: false,
          title: 'Use 8-50 characters',
          regExp: /^(?=.{8,50}$).*/,
          default: true,
        },
        use12to50chars: {
          passed: false,
          title: 'Use 12-50 characters',
          regExp: /^(?=.{12,50}$).*/,
          default: false,
        },
        onlyLatin: {
          passed: false,
          title: 'Only Latin letters',
          // eslint-disable-next-line no-control-regex
          regExp: /^((?![^\x00-\x7Fa-zA-Z]).)*$/,
          default: true,
        },
        match: {
          passed: false,
          verification: true,
          title: 'Passwords match',
          default: true,
        },
      },
    };
  },
  props: {
    password: String,
    confirmedPassword: String,
    passwordVerification: Boolean,
    passwordTags: Boolean,
    focused: Boolean,
    customRequirements: Array,
  },
  computed: {
    requirements() {
      const requirements = [];

      Object.entries(this.requirementsModel).forEach(([title, requirement]) => {
        const matchesPassVerification =
          requirement.verification === this.passwordVerification;
        const matchesCustomRequirements = this.customRequirements
          ? this.customRequirements.includes(title)
          : requirement.default;

        if (matchesPassVerification && matchesCustomRequirements) {
          requirements.push(requirement);
        }
      });

      return requirements;
    },
  },
  methods: {
    passwordsMatch() {
      if (!this.password || !this.confirmedPassword) return false;

      return this.password === this.confirmedPassword;
    },
    requirementsCheck(password) {
      Object.keys(this.requirementsModel).forEach((key) => {
        if (
          this.passwordVerification &&
          this.requirementsModel[key].verification
        ) {
          this.requirementsModel[key].passed = this.passwordsMatch();
        } else {
          this.requirementsModel[key].passed = new RegExp(
            this.requirementsModel[key].regExp,
          ).test(password);
        }
      });
    },
  },
  watch: {
    password: {
      deep: true,
      handler(value) {
        this.requirementsCheck(value);
      },
    },
    confirmedPassword: {
      deep: true,
      handler(value) {
        this.requirementsCheck(value);
      },
    },
  },
};
</script>
<style lang="scss">
.requirement {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 50%;
  margin: 4px 0;

  color: var(--gray);

  div {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 460px) {
    width: 100%;
  }
}

.inactive {
  fill: #c4c4c4;
}
</style>
