import RouteParser from './RouteParser';

export default class Route {
  constructor(element, level = 0) {
    this.element = element || { name: '', path: '', meta: { title: '' } };
    this.level = level;
  }

  get path() {
    return {
      name: this.element.name,
    };
  }

  get url() {
    return this.element.path;
  }

  get title() {
    return this.element.meta.title;
  }

  get breadcrumbsTitle() {
    return this.element.meta.breadcrumbsTitle || this.element.meta.title;
  }

  get name() {
    return this.element.name;
  }

  get icon() {
    return this.element.meta.icon || null;
  }

  get badge() {
    return this.element.meta.badge || null;
  }

  get special() {
    return this.element.meta.special || null;
  }

  get component() {
    if (this.level === 0) {
      if (this.element.children === undefined) {
        return 'm-horizontal-menu-list-item';
      }

      return 'm-horizontal-menu-list-item-with-submenu';
    }

    return 'm-horizontal-menu-list-item-submenu-list-item';
  }

  get children() {
    if (!this.element.children) {
      return null;
    }

    return this.element.children.map(
      (element) => new Route(element, this.level + 1),
    );
  }

  get breadcrumbs() {
    const breadcrumbs = this.element.meta.breadcrumbs || [];

    return breadcrumbs.map((routeName) => {
      let route = RouteParser.getRouteByName(routeName);
      if (routeName.url && routeName.title) {
        route = routeName;
      }

      if (route !== null) {
        return route;
      }
    });
  }

  belongsTo(path) {
    const regex = new RegExp(`^${this.url}`);

    return regex.test(path);
  }

  getParamValue(parameter) {
    if (this.element.params) {
      if (this.element.params[parameter]) {
        return this.element.params[parameter];
      }
    }
    if (RouteParser.currentRoute().element.params) {
      return RouteParser.currentRoute().element.params[parameter];
    }

    return null;
  }
}
