import type { HBilling } from '@/types/enums';

export const BILLING_COUPON_ERROR_MAP = {
  'Provided coupon is not supported for multi item purchase':
    'This coupon can only be used to purchase a single service',
  default: "This coupon doesn't exist",
};

export interface ISubscriptionOrder {
  id: number;
  orderToken: string;
  customerId: string;
  agentId: null;
  status: string;
  type: string;
  currency: string;
  subtotal: number;
  total: number;
  amountPaid: number;
  amountDue: number;
  creditsApplied: number;
  taxAmount: number;
  discountAmount: number;
  refundedAmount: number;
  paymentToken: null;
  paymentLink: null;
  completionToken: null;
  updatedAt: string;
  createdAt: string;
  analyticsData: IBillingAnalyticsData[];
  billingAddress: IBillingAddress;
  metadata: IOrderMetadata;
  items: IOrderItem[];
  coupons: string[];
}

export interface ISubscriptionOrderWithSubscriptionId
  extends ISubscriptionOrder {
  subscriptionId: string;
}

export interface AutoRenewItem {
  imageSvg: string;
  title: string;
  text: string;
}

export interface EstimatePlanRenew {
  currencyCode: string;
  subTotal: number;
  total: number;
  totalDiscount: number;
  totalTax: number;
  creditsApplied: number;
  amountPaid: number;
  finalAmount: number;
  nextBillingAt: number;
  dueTo: number;
  items: Item[];
  taxes: Tax[];
}

export interface Item {
  product: string;
  total: number;
  totalDiscount: number;
  totalTax: number;
  taxRate: number;
  quantity: number;
  addons: Addon[];
}

export interface Addon {
  product: string;
  total: number;
  totalDiscount: number;
  totalTax: number;
}

export interface Tax {
  name: string;
  description: string;
  amount: number;
}

export interface ICartEstimateItemAddon {
  product: string;
  quantity: number;
}

interface ICartEstimateItem {
  product: string;
  quantity: number;
  unitPrice?: number;
  addons?: ICartEstimateItemAddon[];
}

export interface ICartEstimateRequest {
  coupons?: string[];
  items: ICartEstimateItem[];
}

export interface IAddonEstimateRequest {
  itemPriceId: string;
  quantity: number;
  coupons: string[];
}

export interface IRenewalEstimateRequest {
  subscriptionId: string;
  itemPriceId?: string;
  coupons?: string[];
}

export interface IReactivationEstimateRequest {
  subscriptionId: string;
  itemPriceId: string;
  chargeItemPriceId: string;
  coupons: string[];
}

export interface IUpgradeEstimateRequest {
  itemPriceId: string;
  quantity: number;
  coupons: string[];
}

export interface ICustomerInvoicesParams {
  status: HBilling.InvoiceStatus;
}

export const BILLING_ANALYTICS_DATA_KEY = {
  ITEM_LIST_ID: 'item_list_id',
  ITEM_LIST_NAME: 'item_list_name',
  DEVICE_ID: 'device_id',
  GA: '_ga',
  PAGE_NAME: 'page_name',
} as const;

type BillingAnalyticsDataKey =
  (typeof BILLING_ANALYTICS_DATA_KEY)[keyof typeof BILLING_ANALYTICS_DATA_KEY];

interface IBillingAnalyticsData {
  key: BillingAnalyticsDataKey;
  value: string;
}

interface IBillingAddress {
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  state: string;
  stateCode: string;
  city: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
}

interface IOrderAddon {
  product: string;
  quantity: number;
}

interface IOrderItem {
  itemId: string;
  unitPrice: number;
  quantity: string;
  addons: IOrderAddon[];
  chargebeeSubscriptionId: string;
  metadata: {
    domain: string;
    domainNamePrompt?: string;
  };
}

interface IOrderMetadata {
  creationSource: string;
  creationLocation: string;
  analyticsData: IBillingAnalyticsData[];
  domainNamePrompt?: string;
}

export interface IOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  customerId: string;
  currency: string;
  billingAddress: IBillingAddress;
  items: IOrderItem[];
  merchantPaymentMethod: string;
  redirectReturn: string;
  redirectCancel: string;
  isTrial?: boolean;
}

export interface IOneClickPayOrderRequestRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  customerId: string;
  items: IOrderItem[];
  methodId: number;
  riskjsToken?: string;
  isTrial?: boolean;
}

export interface IAddonOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  customerId: string;
  subscriptionId: string;
  itemPriceId: string;
  quantity: number;
  merchantPaymentMethod: string;
  redirectReturn: string;
  redirectCancel: string;
}

export interface IOneClickPayAddonOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  methodId: number;
  subscriptionId: string;
  itemPriceId: string;
  quantity: number;
  riskjsToken?: string;
}

export interface IUpgradeOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  customerId: string;
  subscriptionId: string;
  itemPriceId: string;
  quantity: number;
  merchantPaymentMethod: string;
  redirectReturn: string;
  redirectCancel: string;
}

export interface IOneClickPayUpgradeOrderRequest extends IUpgradeOrderRequest {
  methodId: number;
  riskjsToken?: string;
}

export interface IRenewalOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  itemPriceId: string;
  subscriptionId: string;
  merchantPaymentMethod: string;
  redirectReturn: string;
  redirectCancel: string;
}

export interface IOneClickPayRenewalOrderRequest extends IRenewalOrderRequest {
  methodId: number;
  riskjsToken?: string;
}

export interface IReactivationOrderRequest {
  coupons: string[];
  metadata: IOrderMetadata;
  agentId: string;
  subscriptionId: string;
  itemPriceId: string;
  chargeItemPriceId: string;
  merchantPaymentMethod: string;
  redirectReturn: string;
  redirectCancel: string;
}

export interface IOneClickPayReactivationOrderRequest
  extends IReactivationOrderRequest {
  methodId: number;
  riskjsToken?: string;
}

export interface IInvoiceOrderRequest {
  coupons?: string[];
  metadata?: IOrderMetadata;
  agentId?: string;
  invoiceId?: string;
  redirectReturn?: string;
  redirectCancel?: string;
}

export interface IOneClickPayInvoiceOrderRequest extends IInvoiceOrderRequest {
  methodId: number;
  riskjsToken?: string;
}

export interface IDataLayerEvent {
  id: number;
  dataLayer: IDataLayer;
}

export interface IDataLayer {
  event: string;
  transactionHitData: ITransactionHitData;
}

export interface ITransactionHitData {
  products: ITransactionHitDataProduct[];
  purchase: ITransactionHitDataPurchase;
  currencyCode: string;
}

export interface ITransactionHitDataProduct {
  id: string;
  name: string;
  brand: string;
  price: number;
  coupon: string;
  variant: string;
  category: string;
  quantity: number;
}

export interface ITransactionHitDataPurchase {
  id: string;
  tax: number;
  coupon: string;
  revenue: number;
  shipping: string;
  subtotal: number;
  affiliation: string;
}

export interface IPurchasePriceEstimationRequest {
  priceItemId: string;
  quantity: number;
  coupon?: string;
  addons?: ICartEstimateItemAddon[];
}

export interface IUpgradePriceEstimationRequest
  extends IPurchasePriceEstimationRequest {
  subscriptionId: string;
}

interface IBillingRequestItemMetadata {
  domain?: string;
  resourceId?: number;
}

interface IBillingRequestItem {
  itemId: string;
  quantity: number;
  metadata: IBillingRequestItemMetadata;
}

export interface IBaseBillingRequest {
  customerId?: string;
  redirectReturn: string;
  redirectCancel: string;
  transactionDetails: {
    metadata: {
      cvcRefreshRequired: boolean;
    };
  };
  methodId?: string;
  coupons: string[];
  agentId?: string;
}

export interface IBillingPurchaseRequest extends IBaseBillingRequest {
  billingAddress: Partial<{
    [K in keyof IBillingAddress]: IBillingAddress[K] | null;
  }>;
  currency: string;
  items: IBillingRequestItem[];
}

export interface IBillingUpgradeRequest extends IBaseBillingRequest {
  subscriptionId: string;
  itemPriceId: string;
  quantity: number;
}

export type BillingRequest = IBillingPurchaseRequest | IBillingUpgradeRequest;
