import { useDomainPricing } from '@/composables/hDomains/useDomainPricing';
import { useCatalogStore } from '@/stores/catalogStore';
import type { IDomainAvailability } from '@/types';
import { getSplittedDomainParts } from '@/utils/helpers';
import currency from '@/utils/services/currencyService';

export const hasDomainPriceFilter = (domainsList: string[]) => {
  const { getDomainPricingByTld } = useCatalogStore();

  return domainsList?.filter((domain) => {
    const [, tld] = getSplittedDomainParts(domain);
    const doesPricingExist = !!getDomainPricingByTld(tld)?.length;

    return doesPricingExist;
  });
};

export const domainPriceMapper = (domain: string) => {
  const [, tld] = getSplittedDomainParts(domain);
  const { getDomainOfferPriceDetails } = useDomainPricing();

  const price = getDomainOfferPriceDetails(tld);

  return {
    price,
    tld,
    domain,
  };
};

export const getFreeCurrentDomainPrice = () =>
  currency.format(0, {
    isChargebeePrice: true,
    fixedCurrency: false,
    monthly: false,
  });

export const domainFreeMapper = (domain: string) => {
  const [, tld] = getSplittedDomainParts(domain);
  const { getDomainOfferPriceDetails } = useDomainPricing();

  const price = getDomainOfferPriceDetails(tld);

  price!.currentPrice = getFreeCurrentDomainPrice();

  return {
    price,
    domain,
    tld,
  };
};

export const availableDomainListMapper = (
  domainAvailability: IDomainAvailability[],
) =>
  domainAvailability.flatMap((domainAvailability) =>
    domainAvailability.available ? domainAvailability.domain : [],
  );
