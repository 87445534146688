<template>
  <div>
    <Trans
      v-if="decision === 'move_to_nearest_dc'"
      :translate-params="{ country }"
      class="recomendation-text"
      tag="p"
    >
      Move your hosting to the
      <strong>{country}</strong>
      data center to improve website loading speed for your visitors
    </Trans>
    <Trans
      v-if="decision === 'requires_human_decision'"
      class="recomendation-text"
      tag="p"
    >
      Transfer your hosting account to
      <strong>one of the closest servers</strong>
      to improve website loading speed for your visitors
    </Trans>

    <Trans
      v-if="decision === 'leave_same_dc'"
      :translate-params="{ country }"
      class="recomendation-text"
      tag="p"
    >
      We recommend keeping your hosting account in the
      <strong>{country}</strong>
      data center for the best website loading time
    </Trans>
  </div>
</template>
<script>
export default {
  props: {
    country: String,
    decision: String,
  },
};
</script>
<style lang="scss" scoped>
.recomendation-text {
  width: 100%;
  background: var(--gray-light);
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--primaryText);
}
</style>
