<script lang="ts" setup>
import { computed } from 'vue';

import { useGlobals } from '@/composables';
import { HIcon } from '@/types';
import { countryByCountryCode } from '@/utils/helpers';

type Props = {
  isLoading: boolean;
  selectedDataCenter?: {
    value: string;
    label: string;
  };
  currentDataCenter?: string;
  preselectedServer?: boolean;
  currentPlan?: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  close: [];
  submit: [];
  'go-back': [];
}>();

const { t } = useGlobals();

const title = computed(() =>
  props.preselectedServer
    ? "We're Going to Transfer your Hosting Shortly"
    : 'Important Information About the Transfer',
);

const country = computed(() => {
  let country = '';
  if (props.selectedDataCenter) {
    country =
      props.selectedDataCenter?.label.substring(
        props.selectedDataCenter.label.lastIndexOf('(') + 1,
        props.selectedDataCenter.label.lastIndexOf(')'),
      ) || '';
    if (country.includes(',')) {
      country = countryByCountryCode(
        country.split(',').shift()?.substring(0, 2) ?? '',
      );
    }
  }

  return t(country);
});

const isSameDataCenter = computed(
  () => props.selectedDataCenter?.label === props.currentDataCenter,
);

const transferDetails = [
  {
    text: 'The server transfer is free of cost',
  },
  {
    text: 'All websites under the hosting plan will be moved to the <strong>{country}</strong> data center',
    translateParams: {
      country: country.value,
    },
    isHidden: !country.value,
  },
  {
    text: 'The <strong>IP address</strong> of your hosting plan will be changed',
    isHidden: isSameDataCenter.value && props.currentPlan?.includes('cloud'),
  },
  {
    text: "Changes during the transfer process won't be saved and are not recommended",
  },
  {
    text: 'Server transfer can take up to 12 hours to complete',
  },
  {
    text: 'Hosting will be locked during the transfer',
  },
];
</script>

<template>
  <div>
    <div
      v-if="!preselectedServer"
      class="back-container"
      @click="emit('go-back')"
    >
      <div class="back-icon-container">
        <HpIcon :icon="HIcon.ICON_ARROW_BACK" gray />
      </div>
      <Trans>Back to server location</Trans>
    </div>
    <div>
      <h2>
        {{ t(title) }}
      </h2>
      <p class="text-dark">
        {{ t('Here is some important information regarding the transfer:') }}
      </p>
      <ul class="text-dark">
        <template v-for="details in transferDetails" :key="details.text">
          <li v-if="!details.isHidden">
            <Trans :translate-params="details.translateParams">
              {{ details.text }}
            </Trans>
          </li>
        </template>
      </ul>
    </div>
    <div class="d-flex justify-content-end">
      <HButton
        v-if="!isLoading"
        data-qa="hpanel_tracking-transfer-modal-cancel_button"
        variant="text"
        @click="emit('close')"
      >
        {{ t('Cancel') }}
      </HButton>
      <HButton
        data-qa="hpanel_tracking-transfer-modal-start_button"
        :loading="isLoading"
        @click="emit('submit')"
      >
        {{ t('Start') }}
      </HButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.back-container {
  display: flex;
  flex-direction: row;
  margin: -20px 0px 20px -20px;
  padding-right: 14px;
  height: 24px;
  max-width: max-content;
  cursor: pointer;
  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: row;
    margin: -10px 0px 20px -10px;
    padding-right: 14px;
    height: 24px;
    max-width: max-content;
    cursor: pointer;
  }
}
.back-icon-container {
  display: flex;
  justify-content: 'center';
  margin-right: 14px;
  padding-right: 18px;
  border-right: 1px solid var(--gray-border);
}
.text-dark {
  color: var(--text-primary);
}
</style>
