import { wordpressRepo, accountRepo, websitesRepo } from '@/repositories';
import { setForDomain, getStateVariable } from '@/store/storeHelper';

const initial = {
  data: {},
  isLoading: false,
};

export default {
  state: {
    initial,
    data: [],
    websites: [],
    websitesList: [],
    guidesCount: 0,
    selectedWebsite: null,
    websiteDashboardDetails: null,
    websiteDashboardDetailsLoading: true,
    websiteDashboardTips: null,
    websiteDashboardTipsLoading: true,
    websiteVisitorsCount: null,
    websiteVisitorsCountLoading: true,
    websiteCopies: null,
    websiteCopiesLoading: true,
  },
  mutations: {
    SET_WEBSITES(state, website) {
      const websitesList = [...state.websites, website];
      state.websites = websitesList;
    },
    SET_WEBSITES_LIST(state, list) {
      state.websitesList = list;
    },
    SET_IS_LOADING(state, { domain, isLoading }) {
      setForDomain(state, domain, 'isLoading', isLoading);
    },
    SET_WEBSITE_GUIDE_DATA(state, { websiteData, domain }) {
      setForDomain(state, domain, 'data', websiteData);
    },
    CLEAR_WEBSITES_LIST(state) {
      state.websites = [];
    },
    SET_SELECTED_WEBSITE(state, website) {
      state.selectedWebsite = website;
    },
    SET_WEBSITE_DASHBOARD_DETAILS(state, websiteDashboardDetails) {
      state.websiteDashboardDetails = websiteDashboardDetails;
    },
    SET_WEBSITE_DASHBOARD_DETAILS_LOADING(state, status) {
      state.websiteDashboardDetailsLoading = status;
    },
    SET_WEBSITE_DASHBOARD_TIPS(state, websiteDashboardTips) {
      state.websiteDashboardTips = websiteDashboardTips;
    },
    SET_WEBSITE_DASHBOARD_TIPS_LOADING(state, status) {
      state.websiteDashboardTipsLoading = status;
    },
    SET_WEBSITE_VISITORS_COUNT(state, websiteVisitorsCount) {
      state.websiteVisitorsCount = websiteVisitorsCount;
    },
    SET_WEBSITE_VISITORS_COUNT_LOADING(state, status) {
      state.websiteVisitorsCountLoading = status;
    },
    SET_WEBSITE_COPIES(state, websiteCopies) {
      state.websiteCopies = websiteCopies;
    },
    SET_WEBSITE_COPIES_LOADING(state, status) {
      state.websiteCopiesLoading = status;
    },
  },
  getters: {
    isWebsiteGuideCompleted: (state) => (domain) => {
      const website = state.data.find((website) => website.domain === domain);

      return isCompleted(website);
    },
    getWebsites(state) {
      return state.websites;
    },
    getWebsitesList(state) {
      return state.websitesList;
    },
    getWebsiteGuideData: (state) => (domain) =>
      getStateVariable(state, 'data', domain),
    getIsWebsiteLoading: (state) => (domain) =>
      getStateVariable(state, 'isLoading', domain),
    getSelectedWebsite(state) {
      return state.selectedWebsite;
    },
    getWebsiteDashboardDetails(state) {
      return state.websiteDashboardDetails;
    },
    getWebsiteDashboardDetailsLoading(state) {
      return state.websiteDashboardDetailsLoading;
    },
    getWebsiteDashboardTips(state) {
      return state.websiteDashboardTips;
    },
    getWebsiteDashboardTipsLoading(state) {
      return state.websiteDashboardTipsLoading;
    },
    getWebsiteVisitorsCount(state) {
      return state.websiteVisitorsCount;
    },
    getWebsiteVisitorsCountLoading(state) {
      return state.websiteVisitorsCountLoading;
    },
    getWebsiteCopies(state) {
      return state.websiteCopies;
    },
    getWebsiteCopiesLoading(state) {
      return state.websiteCopiesLoading;
    },
  },
  actions: {
    async fetchWebsiteDashboardDetails(context, params) {
      context.commit('SET_WEBSITE_DASHBOARD_DETAILS_LOADING', true);
      const [{ data }, err] = await accountRepo.websiteDetails(params);
      if (!err) context.commit('SET_WEBSITE_DASHBOARD_DETAILS', data);

      context.commit('SET_WEBSITE_DASHBOARD_DETAILS_LOADING', false);
    },
    async fetchWebsiteTips(context, params) {
      context.commit('SET_WEBSITE_DASHBOARD_TIPS_LOADING', true);
      const [{ data }, err] = await accountRepo.websiteTips(params);
      if (!err) context.commit('SET_WEBSITE_DASHBOARD_TIPS', data);
      context.commit('SET_WEBSITE_DASHBOARD_TIPS_LOADING', false);
    },
    async fetchVisitorCount(context) {
      context.commit('SET_WEBSITE_VISITORS_COUNT_LOADING', true);
      const [{ data }, err] = await websitesRepo.getVisitorsCount();
      if (!err) context.commit('SET_WEBSITE_VISITORS_COUNT', data);

      context.commit('SET_WEBSITE_VISITORS_COUNT_LOADING', false);
    },
    async installWordpressPlugin({ dispatch }, requestBody) {
      const [{ data }, err] = await wordpressRepo.postWpPlugin(requestBody);
      if (!err) await dispatch('fetchWebsiteTips');

      return [{ data }, err];
    },
    async postWebsiteCopy(context, { targetDomain, domain }) {
      context.commit('SET_WEBSITE_COPIES_LOADING', true);
      const [{ data }] = await websitesRepo.postCopyWebsite({
        targetDomain,
        domain,
      });
      context.commit('SET_WEBSITE_COPIES_LOADING', false);

      return data;
    },
    async fetchWebsiteCopies(context) {
      context.commit('SET_WEBSITE_COPIES_LOADING', true);
      const [{ data }, err] = await websitesRepo.getCopyWebsiteList();
      if (!err) context.commit('SET_WEBSITE_COPIES', data);

      context.commit('SET_WEBSITE_COPIES_LOADING', false);

      return err;
    },
  },
};

const isCompleted = (website) => {
  const stepKeys = [
    'createMailbox',
    'setupDeviceMail',
    'createCatchAll',
    'createLogo',
    'firstPost',
    'contactForm',
    'googleAnalytics',
    'seo',
  ];
  const steps = website.data.additionalData?.guideSteps;

  return (
    (website.data?.dailyBackups === '1' || steps?.dailyBackups === 'skipped') &&
    (website.data.isPointing ||
      website.data.domainInfo.purchaseInfo.boughtFromUs) &&
    ((website.data?.ssl?.status === 'active' &&
      website.data?.additionalData?.additionalData !== 'installing') ||
      steps?.ssl === 'skipped') &&
    stepKeys.every((key) => {
      if (!steps) return;
      if (typeof steps[key] === 'boolean') return steps[key];

      return typeof steps[key] === 'object' && steps[key] !== null
        ? Object.keys(steps[key]).length > 1
        : steps[key];
    })
  );
};
