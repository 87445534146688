import { defineStore } from 'pinia';
import { ref } from 'vue';

import { websiteRepo } from '@/repositories';
import type { IWebsiteMonitoring } from '@/types';

export const useWebsiteMonitoringStore = defineStore(
  'websiteMonitoringStore',
  () => {
    const websites = ref<IWebsiteMonitoring[] | null>(null);
    const isLoading = ref(false);

    const $reset = () => {
      websites.value = null;
      isLoading.value = false;
    };

    const findWebsiteByVhost = (targetVHost: string) =>
      websites.value?.find(({ vhost }) => vhost === targetVHost);

    const fetchWebsitesMonitoringStatus = async () => {
      isLoading.value = true;
      const [{ data }, error] = await websiteRepo.getWebsitesMonitoringStatus();
      if (error) {
        return;
      }

      isLoading.value = false;
      websites.value = data;
    };

    const changeMonitoringStatus = async (vhost: string, enabled: boolean) => {
      const website = findWebsiteByVhost(vhost);
      if (!website) {
        return;
      }

      const headers = {
        username: website.username,
        vhost: website.vhost,
      };
      const params = {
        enabled: Number(enabled) as 0 | 1,
      };

      const [{ data }, error] = await websiteRepo.putWebsiteMonitoringStatus({
        params,
        headers,
      });

      return [{ data }, error];
    };

    return {
      isLoading,
      websites,
      $reset,
      fetchWebsitesMonitoringStatus,
      changeMonitoringStatus,
      findWebsiteByVhost,
    };
  },
);
