<template>
  <li class="period-wrapper" :data-qa="dataQa" @click="changePeriod">
    <div>
      <Trans
        v-if="item.topLabel && !hideDiscount && !isPlan"
        tag="div"
        :class="active ? 'period-label--active' : 'period-label'"
      >
        {{ item.topLabel }}
      </Trans>
      <div
        class="period"
        :class="{
          'period--active': active,
          'period--static': isStaticOption,
          'period--has-label': item.topLabel && !hideDiscount && !isPlan,
        }"
      >
        <div class="period__left">
          <HRadioCheck v-if="!isStaticOption && !hideRadio" :active="active" />
          <div class="period__title-holder">
            <p :data-qa="`${dataQa}-title`" class="period__title">
              {{ periodTitle }}
            </p>
            <HLabelDeprecated
              v-if="isDiscount && !isDomainPurchase && !hideDiscount"
              class="period__label"
              no-arrow
              sm
              padding-sm
              v-bind="{ [labelColor]: true }"
              :data-qa="`${dataQa}-discount-label`"
            >
              {{ $t('Save {discount}%', { discount }) }}
            </HLabelDeprecated>
            <HLabelDeprecated
              v-if="isMostPopular"
              class="period__label period__label--most-popular"
              no-arrow
              sm
              padding-sm
              danger
            >
              Most popular
            </HLabelDeprecated>
          </div>
        </div>

        <div class="period__right">
          <p
            v-if="isDiscount"
            class="period__old-price"
            :data-qa="`${dataQa}-old-price`"
          >
            {{ getPrice(item.basePrice) }}
          </p>
          <p class="period__price" :data-qa="`${dataQa}-price`">
            <span v-if="isDomainPurchase">
              {{
                isPurchase
                  ? $currency.format(item.firstYrPrice, {
                      isChargebeePrice: true,
                    })
                  : getPrice(getPriceType(item))
              }}
            </span>
            <span v-else>
              {{ getPrice(getPriceType(item)) }}
            </span>
            <small v-if="!isStaticOption">{{ periodUnitText }}</small>
          </p>
        </div>
      </div>
    </div>
    <PurchaseFeatures v-if="showItemFeatures" :features="item.features" />
  </li>
</template>

<script>
import HRadioCheck from '@/components/HRadioCheck.vue';
import PurchaseFeatures from '@/components/Modals/Parts/PurchaseFeatures';
import { getDiscount } from '@/utils/helpers';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';

export default {
  components: { HRadioCheck, PurchaseFeatures },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: Boolean,
    isPurchase: Boolean,
    isUpgrade: Boolean,
    isPlan: Boolean,
    isYearly: Boolean,
    isStaticOption: Boolean,
    labelColor: String,
    hasMailbox: Boolean,
    showFeatures: Boolean,
    isDomainPurchase: Boolean,
    optionsNumber: Number,
    isEstimationLoading: Boolean,
    hideDiscount: Boolean,
    hideRadio: Boolean,
    forceShowFeatures: Boolean,
  },
  emits: ['change'],
  computed: {
    isMostPopular() {
      return (
        this.item?.id === 'year2' &&
        this.isDomainPurchase &&
        this.optionsNumber > 1
      );
    },
    isMonthly() {
      return (
        !this.isYearly &&
        this.item.periodUnit !== 'month' &&
        !this.isStaticOption
      );
    },
    showItemFeatures() {
      return (
        this.item.features &&
        this.item.features.length > 0 &&
        (this.active || this.forceShowFeatures) &&
        this.showFeatures
      );
    },
    periodUnitText() {
      const periodUnit = this.isYearly ? 'yr' : 'mo';

      if (this.isPurchase && this.isDomainPurchase && this.item.period > 1) {
        return this.$t(`/1st ${periodUnit}`);
      }

      if (!this.hasMailbox) {
        return this.$t(`/${periodUnit}`);
      }

      return this.$t(`/account/${periodUnit}`);
    },
    isDiscount() {
      const price = this.isPurchase
        ? this.item.firstPeriodPrice
        : this.item.price;

      return this.item.basePrice > price;
    },
    discount() {
      const finalBasePrice = this.getFinalPrice(this.item.basePrice);
      const finalDiscountedPrice = this.getFinalPrice(
        this.getPriceType(this.item),
      );

      return getDiscount(finalBasePrice, finalDiscountedPrice);
    },
    periodTitle() {
      if (this.item.planName) return this.item.planName;

      let { period, periodUnit } = this.item;

      period = !this.isYearly && periodUnit === 'year' ? period * 12 : period;
      periodUnit = this.isYearly ? periodUnit : 'month';

      const unit = `${periodUnit}${period !== 1 ? 's' : ''}`;

      return `${period} ${this.$t(unit)}`;
    },
    dataQa() {
      return this.periodTitle.replace(/\s/g, '-').toLowerCase();
    },
  },
  methods: {
    changePeriod() {
      if (this.isEstimationLoading) return;
      this.$emit('change', this.item.id);
    },
    getPriceType(item) {
      if (this.isUpgrade) return item.upgradePrice || item.price;
      if (this.isPurchase) return item.firstPeriodPrice;

      return item.price;
    },
    getFinalPrice(price) {
      const periodCountPerPrice = this.isMonthly
        ? this.item.period * monthsPerPeriodUnit(this.item.periodUnit)
        : this.item.period;

      const periodPrice = price / periodCountPerPrice;

      return this.isStaticOption ? price : periodPrice;
    },
    getPrice(price) {
      return this.$currency.format(this.getFinalPrice(price), {
        fixedCurrency: null,
        monthly: this.isMonthly,
        isChargebeePrice: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.period-wrapper {
  list-style: none;
}

.period-label {
  color: var(--light);
  background: var(--success);

  padding: 4px 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px 4px 0px 0px;

  &--active {
    background: var(--primary);
    color: var(--light);
    transition: background 0.3s;
    padding: 4px 24px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px 4px 0px 0px;
  }
}
.period {
  padding: 8px 16px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 1px solid var(--gray-border);

  &--active {
    transition: border-color 0.3s;
    padding: 7px 15px;

    border: 2px solid var(--primary);
  }

  &--has-label {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none !important;
  }

  &--static {
    border: 1px solid #dad9da !important;
    cursor: default;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  &__right {
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-basis: 50%;
  }

  &__title-holder {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 14px;
    color: var(--dark);
  }

  &__label {
    margin-left: 8px;
    text-transform: uppercase;

    &--most-popular {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__old-price {
    font-weight: 700;
    margin-left: 8px;
    text-decoration: line-through;
  }

  &__price {
    font-weight: 700;
    border-radius: 8px;
    padding: 8px;
    margin-left: 16px;

    background-color: var(--gray-light);
    color: var(--dark);
  }

  &__domain-renew-price {
    width: 100%;
    text-align: right;
  }

  @media screen and (max-width: 560px) {
    &__title-holder {
      flex-direction: column;
    }

    &__label {
      margin-left: 0px;
    }
  }
}
</style>
