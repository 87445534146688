import { useGlobals } from '@/composables';

export const useDynamicDomainText = () => {
  const { t } = useGlobals();

  const getIsPlural = (amount: number) => amount > 1;

  const getFailedBulkActionText = (amount: number) => {
    const isPlural = getIsPlural(amount);
    const params = { amount };
    const text = isPlural
      ? `Failed: <strong>{amount} domains</strong>`
      : `Failed: <strong>1 domain</strong>`;

    return t(text, params);
  };

  const getSuccessfulBulkActionText = (amount: number) => {
    const isPlural = getIsPlural(amount);
    const params = { amount };
    const text = isPlural
      ? `Successful: <strong>{amount} domains</strong>`
      : `Successful: <strong>1 domain</strong>`;

    return t(text, params);
  };

  const getCompletedBulkActionText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains have this action completed`
      : `1 domain has this action completed`;

    return t(text, params);
  };

  const getAvailableBulkActionText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains are available for this action`
      : `1 domain is available for this action`;

    return t(text, params);
  };

  const getUnavailableBulkActionText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains are not available for this action`
      : `1 domain is not available for this action`;

    return t(text, params);
  };

  const getActionAlreadyCompletedText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains already have the action completed`
      : `1 domain already has the action completed`;

    return t(text, params);
  };

  const getActionCanBeCompletedText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains are able to complete the action`
      : `1 domain is able to complete the action`;

    return t(text, params);
  };

  const getActionCannotBeCompletedText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural
      ? `{count} domains unable to complete the action`
      : `1 domain unable to complete the action`;

    return t(text, params);
  };

  const getSelectedDomainsText = (count: number) => {
    const isPlural = getIsPlural(count);
    const params = { count };
    const text = isPlural ? `{count} domains selected` : `1 domain selected`;

    return t(text, params);
  };

  return {
    getFailedBulkActionText,
    getSuccessfulBulkActionText,
    getCompletedBulkActionText,
    getAvailableBulkActionText,
    getUnavailableBulkActionText,
    getActionAlreadyCompletedText,
    getActionCanBeCompletedText,
    getActionCannotBeCompletedText,
    getSelectedDomainsText,
  };
};
