import { amplitudeV2 } from '@/plugins/amplitudeV2';
import { currencyService, permissionsService } from '@/utils/services';
import { hotjar } from '@/utils/services/Hotjar';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { $t } from '@/utils/services/i18nService';

export const useGlobals = () => ({
  toastr,
  t: $t,
  amplitudeV2: amplitudeV2 as typeof amplitudeV2,
  logError: errorLogger.logError,
  permissions: permissionsService,
  currency: currencyService,
  hj: hotjar,
});
