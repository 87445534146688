import type { IH5GResourceUsageResponse, ResourceUsagePeriod } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/h5g/websites`,
  async getWebsiteResourceUsage(
    websiteUid: string,
    timeframeDays: ResourceUsagePeriod,
  ) {
    return await hAsync<IH5GResourceUsageResponse>(
      http.get(`${this.url}/${websiteUid}/resource-usage-metrics`, {
        params: {
          timeframeDays,
        },
        noHostingHeaders: true,
      }),
    );
  },
};
