import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useCookies, useFeatureFlag, useH5GWebsite } from '@/composables';
import { useH5GOnboardingStore } from '@/stores';
import { Cookie, FeatureFlag, Route } from '@/types';

export const useH5GOnboarding = () => {
  const router = useRouter();
  const h5gOnboardingStore = useH5GOnboardingStore();
  const { h5gWebsiteOrderId } = useH5GWebsite();

  const { isFeatureEnabled: isH5GFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.ONBOARDING_H5G,
  );

  const isEligibleForH5GOnboarding = computed(
    () =>
      h5gOnboardingStore.pendingResources?.length && isH5GFeatureEnabled.value,
  );

  const { cookie, setCookies } = useCookies(Cookie.H5G_ONBOARDING_CLOSED);
  const isH5GOnboardingClosed = ref(!!cookie.value);

  const closeOnboarding = () => {
    isH5GOnboardingClosed.value = true;
    setCookies(Cookie.H5G_ONBOARDING_CLOSED, 'true');
  };

  const openOnboarding = (orderId?: string) => {
    const h5gId =
      orderId ||
      h5gWebsiteOrderId.value ||
      h5gOnboardingStore.pendingResourceReferenceIds?.[0];

    if (!h5gId) return;

    router.push({
      name: Route.H5G.ONBOARDING,
      params: {
        h5gId,
      },
    });
  };

  return {
    openOnboarding,
    closeOnboarding,
    isEligibleForH5GOnboarding,
    isH5GOnboardingClosed,
    isH5GFeatureEnabled,
  };
};
