import { get as objGet, forEach, isFunction, isString } from 'lodash';

import { ifConditionFilter } from '@/utils/helpers';

const attributesDirective = (el, binding, vnode) => {
  let attrs = objGet(vnode.context, 'schema.attributes', {});
  const container = binding.value || 'input';

  if (isString(container)) {
    attrs = objGet(attrs, container) || attrs;
  }
  forEach(attrs, (val, key) => {
    el.setAttribute(key, val);
  });
};

export default {
  props: ['model', 'schema'],

  directives: {
    attributes: {
      bind: attributesDirective,
      updated: attributesDirective,
    },
  },

  computed: {
    isValid() {
      return !this.fieldErrors(this.schema).length > 0;
    },

    formValidity() {
      return `${this.isValid ? 'form_valid' : 'form_error'} ${
        this.schema.containerClasses || ''
      }`;
    },

    showExtra() {
      return this.schema.extra !== false;
    },

    ifCondition() {
      return ifConditionFilter(this.schema.if);
    },
    parent() {
      if (!this.$parent.fieldErrors) {
        return this.$parent.$parent;
      }

      return this.$parent;
    },
  },

  methods: {
    fieldErrors(field) {
      return this.parent.fieldErrors(field);
    },

    fieldErrorClasses(field) {
      return {
        form_error: this.fieldErrors(field).length > 0,
        form_valid: this.fieldErrors(field).length <= 0,
      };
    },

    buttonClickHandler(btn, field, event) {
      return btn.onclick.call(this, this.model, field, event, this);
    },

    fieldHint(field) {
      if (isFunction(field.hint)) {
        return field.hint.call(this, this.model, field, this);
      }

      return field.hint;
    },

    fieldExplanation(field) {
      if (isFunction(field.explanation)) {
        return field.explanation.call(this, this.model, field, this);
      }

      return field.explanation;
    },

    getExtra(button) {
      return button.function.call(this, this.model, button, this);
    },

    getField(type) {
      return `field-${type}`;
    },

    validate(isAsync = null) {
      return this.parent.validate(isAsync);
    },

    modelUpdate(val, schemaField) {
      this.$emit('model-updated', val, schemaField);
    },
  },
};
