import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};
export default [
  {
    path: 'order',
    name: Route.HostingOrder.ORDER,
    meta: {
      title: 'Hosting',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#order' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'order-usage',
        name: Route.HostingOrder.ORDER_USAGE,
        meta: {
          title: 'Resources Usage',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingOrder.ORDER,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Order/OrderStatistics.vue'),
      },
      {
        path: 'upgrade',
        name: Route.Order.ORDER_UPGRADE,
        meta: {
          title: 'Upgrade',
          hideTitle: true,
          sideMenuExists: 'hidden',
          breadcrumbs: false,
        },
        props: {
          isWebsiteOrder: true,
        },
        component: () =>
          import('@/views/Hosting/Order/Upgrade/OrderUpgrade.vue'),
      },
      {
        path: 'renew',
        name: Route.HostingOrder.RENEW,
        meta: {
          title: 'Renew',
          menu: [],
          sideMenuExists: true,
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingOrder.ORDER,
          ],
        },
        component: () => import('@/views/Hosting/Order/OrderRenew.vue'),
      },
      {
        path: 'details',
        name: Route.HostingOrder.DETAILS,
        meta: {
          title: 'Plan details',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingOrder.ORDER,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Order/AccountDetails.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
