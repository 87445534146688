<template>
  //JSON-slugs-migrated
  <div class="ua-support-banner">
    <div class="ua-support-banner__wrapper">
      <div class="ua-support-banner__images">
        <HImage
          src="@/images/illustrations/UkraineSupport/UkraineOutline.svg"
        />
        <HImage
          class="ua-support-banner__image"
          src="@/images/illustrations/UkraineSupport/UkraineFlag.svg"
        />
      </div>
      <div class="ua-support-banner__content">
        <div v-trans class="ua-support-banner__title">
          v2.important.notice.regarding.events.in.ukraine
        </div>
        <p v-trans>
          v2.we.have.stopped.accepting.payments.and.offering.our.services.in.your.country.to.bring.awareness
        </p>
      </div>
      <HButtonV1 primary class="ua-support-banner__action" @click="handleCta">
        v2.more.information
      </HButtonV1>
    </div>
  </div>
</template>

<script>
//JSON-slugs-migrated
export default {
  name: 'UkraineSupportBanner',
  methods: {
    handleCta() {
      window.open(
        'https://www.bbc.com/news/live/world-europe-60517447',
        '_blank',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ua-support-banner {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px rgba(29, 30, 32, 0.22);

  background-color: var(--light);

  p {
    color: var(--dark);
  }

  &__wrapper {
    max-width: 1110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__images {
    margin-right: 48px;
  }

  &__content {
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__action {
    margin-left: 16px;

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  &__images {
    position: relative;

    @media (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    top: 10px;
  }

  :deep(.h-button) {
    border: none;
    transition: background 1s ease-out;
    background-color: #1865ac;

    &:hover {
      background: linear-gradient(
        180deg,
        #025bbd 7.5%,
        #3c758f 53.14%,
        #d9ba11 53.15%,
        #ecc302 100%
      );
    }
  }
}
</style>
