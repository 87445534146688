import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import type { IExternalDomain } from '@/types';

export const useVpsDomainStore = defineStore('vpsDomainStore', () => {
  const externalDomains = ref<IExternalDomain[]>([]);

  const fetchExternalDomains = async () => {
    const [{ data }] = await hVpsRepo.getExternalDomains();

    if (data) {
      externalDomains.value = data;
    }
  };

  return {
    externalDomains,
    fetchExternalDomains,
  };
});
