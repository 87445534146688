import { getBrandConfigById } from '@/utils/services/brandConfigService';

// favicon based on brand
export const updateFavicon = (brandId: string) => {
  const brandConfig = getBrandConfigById(brandId);
  const link: any = document.querySelector("link[rel*='icon']");
  if (link) link.href = brandConfig.faviconLinks.regular;

  const appleTouchIcon: any = document.querySelector(
    "link[rel*='apple-touch-icon']",
  );
  if (appleTouchIcon) {
    appleTouchIcon.href = brandConfig.faviconLinks.appleTouch;
  }
};

// Themed Css variables
export const setThemedVariables = (brandId: string) => {
  const root = document.documentElement;
  interface Theme {
    [key: string]: string;
  }

  const setCustomThemeVariables = (theme: Theme) => {
    Object.keys(theme).forEach((key: string) => {
      const value = theme[key];
      root.style.setProperty(`--${key}`, value);
    });
  };
  const brandConfig = getBrandConfigById(brandId);
  if (brandConfig.colorConfig) {
    return setCustomThemeVariables(brandConfig.colorConfig);
  }
};
