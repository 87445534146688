import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { useTldCategoriesStore } from '@/stores/domain/tldCategoriesStore';
import {
  type ResponseError,
  type IAllEndings,
  type IDomainAvailabilityDetails,
  type IDomainPurchaseData,
  type IDomainLookupDetails,
  TLD_CATEGORY,
} from '@/types';
import { toASCII, getSplittedDomainParts, getSldTld } from '@/utils/helpers';
import {
  hasDomainPriceFilter,
  availableDomainListMapper,
} from '@/utils/mappers/domainsMapper';

type AiDomainDescription = {
  domains: string[];
  description: string;
};

export const useDomainCheckerStore = defineStore(
  'domainCheckerStore',
  () => {
    const All_ENDINGS: IAllEndings = {
      listPaginated: [],
      listAvailable: [],
      page: 0,
      perPage: 20,
    };

    const isDomainAvailabilityLoading = ref(false);
    const isPromotionBundleTldsLoading = ref(false);
    const domainAvailability = ref<IDomainAvailabilityDetails | null>(null);
    const promotionBundleTlds = ref<string[] | null>(null);
    const isDomainOptionsLoading = ref(false);
    const isAiDomainAlternativesLoading = ref(false);
    const moreOptionsDomainList = ref<string[]>([]);
    const aiAlternativesDomainList = ref<string[]>([]);
    const allEndingsData = ref<IAllEndings>({ ...All_ENDINGS });
    const moreOptionsCancelToken = ref<CancelTokenSource | null>(null);
    const allEndingsCancelToken = ref<CancelTokenSource | null>(null);
    const domainCheckerErrorMessage = ref('');
    const loadedDomainsPurchaseData = ref<IDomainPurchaseData[]>([]);
    const domainLookup = ref<IDomainLookupDetails | null>(null);
    const allTlds = ref<string[]>([]);
    const aiDomainDescription = ref<AiDomainDescription | null>(null);

    const hasPromotionBundle = computed(
      () => promotionBundleTlds.value?.length,
    );

    const setAiDomainDescription = (data: AiDomainDescription) => {
      aiDomainDescription.value = data;
    };

    const resetAiDomainDescription = () => {
      aiDomainDescription.value = null;
    };

    const getAiDomainDescriptionByDomain = (domain: string) =>
      aiDomainDescription.value?.domains.includes(domain)
        ? aiDomainDescription.value?.description
        : '';

    const setAllEndingsData = (items: string[]) => {
      allEndingsData.value.listPaginated = items.reduce(
        (resultArr, item, index: number) => {
          const key = Math.floor(index / allEndingsData.value.perPage);

          if (!resultArr[key]) resultArr[key] = [];

          resultArr[key].push(item);

          return resultArr;
        },
        [] as string[][],
      );
    };

    const setAllEndingsListAvailable = (items: string[]) => {
      allEndingsData.value.listAvailable = [
        ...allEndingsData.value.listAvailable,
        ...items,
      ];
    };

    const setAIAlternativeDomainsList = (items: string[]) => {
      aiAlternativesDomainList.value = items;
    };

    const incrementAllEndingsPage = () => {
      allEndingsData.value.page += 1;
    };

    const setAllEndingsCancelToken = () => {
      allEndingsCancelToken.value = axios.CancelToken.source();
    };

    const setMoreOptionsCancelToken = () => {
      moreOptionsCancelToken.value = axios.CancelToken.source();
    };

    const setDomainCheckerErrorMessage = (errorMessage: string) => {
      domainCheckerErrorMessage.value = errorMessage;
    };

    const setDomainAvailabilityLoading = (isLoading: boolean) => {
      isDomainAvailabilityLoading.value = isLoading;
    };

    const setAiDomainAlternativesLoading = (isLoading: boolean) => {
      isAiDomainAlternativesLoading.value = isLoading;
    };

    const setDomainAvailability = ({
      domain,
      isAvailable,
      restriction,
    }: IDomainAvailabilityDetails) => {
      domainAvailability.value = {
        isAvailable,
        domain: toASCII(domain),
        restriction,
      };
    };

    const setMoreOptionsDomainList = (domains: string[]) => {
      moreOptionsDomainList.value = domains;
    };

    const setLoadedDomainsPurchaseData = (data: IDomainPurchaseData[]) => {
      loadedDomainsPurchaseData.value = [
        ...loadedDomainsPurchaseData.value,
        ...data,
      ];
    };

    const resetLoadedDomainsPurchaseData = () => {
      loadedDomainsPurchaseData.value = [];
    };

    const resetDomainChecker = () => {
      domainCheckerErrorMessage.value = '';
      domainAvailability.value = null;
      moreOptionsDomainList.value = [];
      isDomainAvailabilityLoading.value = false;
      allEndingsData.value = {
        ...All_ENDINGS,
        listPaginated: allEndingsData.value.listPaginated,
      };
      aiAlternativesDomainList.value = [];
      resetLoadedDomainsPurchaseData();
    };

    const getLoadedDomainPurchaseData = (domain: string) =>
      loadedDomainsPurchaseData.value.find(
        ({ domain: domainName }) => toASCII(domain) === toASCII(domainName),
      );

    const fetchAvailableMoreOptions = async ({
      domain,
      excludedTld,
    }: {
      domain: string;
      excludedTld: string;
    }) => {
      setMoreOptionsCancelToken();
      isDomainOptionsLoading.value = true;

      const [{ data: tldCategoriesData }, tldCategoriesErr] =
        await hDomainsRepo.getTldCategories();

      if (tldCategoriesErr) {
        return;
      }

      const categoryPopularTlds =
        tldCategoriesData.find(({ type }) => type === TLD_CATEGORY.POPULAR)
          ?.tlds || [];

      const exactMatchTlds = categoryPopularTlds.filter(
        (tld) => tld !== excludedTld,
      );
      const [{ data }, err] = await hDomainsRepo.getDomainPurchaseData(
        {
          domain: toASCII(domain),
          tlds: exactMatchTlds,
        },
        moreOptionsCancelToken.value!.token,
      );

      isDomainOptionsLoading.value = false;

      if (err) return;

      const mappedDomains =
        hasDomainPriceFilter(availableDomainListMapper(data)) || [];
      setMoreOptionsDomainList(mappedDomains);
    };

    const fetchAvailableAllEndings = async (sld: string) => {
      setAllEndingsCancelToken();

      const tlds =
        allEndingsData.value.listPaginated[allEndingsData.value.page];

      const [{ data }, err] = await hDomainsRepo.getDomainPurchaseData(
        {
          domain: sld,
          tlds,
        },
        allEndingsCancelToken.value!.token,
      );

      incrementAllEndingsPage();

      if (err) return;

      setLoadedDomainsPurchaseData(data);
      const mappedDomains =
        hasDomainPriceFilter(availableDomainListMapper(data)) || [];

      setAllEndingsListAvailable(mappedDomains);
    };

    const fetchAiDomainAlternatives = async (domain: string) => {
      const [sld] = getSplittedDomainParts(domain, {
        withDefaults: true,
      });

      setAiDomainAlternativesLoading(true);
      const [{ data }, error] = await hDomainsRepo.postAIDomainAlternatives({
        domain: sld,
        limit: 5,
      });
      setAiDomainAlternativesLoading(false);

      if (error) {
        return;
      }

      const generatedDomains = data?.generated || [];

      setAIAlternativeDomainsList(hasDomainPriceFilter(generatedDomains));
    };

    const fetchAllTlds = async () => {
      const [{ data }, err] = await hDomainsRepo.getAllTlds();

      if (err) return;

      allTlds.value = data;
    };

    const fetchDomainAvailability = async ({
      domain,
      isAlternativeAllowed,
    }: {
      domain: string;
      isAlternativeAllowed?: boolean;
    }) => {
      setDomainAvailabilityLoading(true);
      const { primarySearchTld } = storeToRefs(useTldCategoriesStore());
      const [domainSld, tld] = getSldTld(domain, { omitDot: true });
      const domainTld = tld || primarySearchTld.value;

      const [{ data }, err] = await hDomainsRepo.getDomainPurchaseData({
        domain: toASCII(domainSld),
        tlds: [domainTld],
        withAlternatives: !!isAlternativeAllowed,
      });

      if (err) {
        resetDomainChecker();
        setDomainCheckerErrorMessage(
          (err as { error: ResponseError }).error?.message || '',
        );

        return;
      }

      const {
        domain: domainName,
        available,
        restriction,
      } = data.find(({ available }) => !!available) || data[0] || {};

      if (domainName) {
        setDomainAvailability({
          domain: toASCII(domainName),
          isAvailable: !!available,
          restriction,
        });
      }

      setDomainAvailabilityLoading(false);
    };

    const fetchDomainPromotionsBundle = async (domain: string) => {
      const [sld, tld] = getSldTld(domain, { omitDot: true });

      isPromotionBundleTldsLoading.value = true;
      const [{ data }, err] = await hDomainsRepo.getDomainPromotionsBundle({
        domain: sld,
        tld,
      });
      isPromotionBundleTldsLoading.value = false;

      if (err) {
        return;
      }

      promotionBundleTlds.value = data;
    };

    const fetchDomainLookup = async (domain: string) => {
      const [{ data }, err] = await hDomainsRepo.lookupDomain(domain);

      if (err) {
        domainLookup.value = null;
        setDomainCheckerErrorMessage(
          (err as { error: ResponseError }).error.message || '',
        );

        return;
      }

      domainLookup.value = data;
    };

    const isSubdomain = (domain: string) => {
      const parts = toASCII(domain).split('.');

      if (parts.length < 2) return false;

      const tldFromParts = parts[2]
        ? `${parts[1]}.${parts[2]}`
        : parts[1].toString();

      return !allTlds.value.includes(tldFromParts);
    };

    return {
      hasPromotionBundle,
      promotionBundleTlds,
      isDomainAvailabilityLoading,
      domainAvailability,
      isDomainOptionsLoading,
      isAiDomainAlternativesLoading,
      isPromotionBundleTldsLoading,
      moreOptionsDomainList,
      allEndingsData,
      moreOptionsCancelToken,
      allEndingsCancelToken,
      domainCheckerErrorMessage,
      setAllEndingsData,
      setAllEndingsListAvailable,
      setDomainAvailability,
      incrementAllEndingsPage,
      setDomainCheckerErrorMessage,
      resetDomainChecker,
      fetchAvailableMoreOptions,
      fetchAvailableAllEndings,
      fetchDomainAvailability,
      fetchDomainPromotionsBundle,
      aiAlternativesDomainList,
      fetchAiDomainAlternatives,
      setLoadedDomainsPurchaseData,
      getLoadedDomainPurchaseData,
      domainLookup,
      fetchDomainLookup,
      fetchAllTlds,
      allTlds,
      isSubdomain,
      setAiDomainDescription,
      resetAiDomainDescription,
      getAiDomainDescriptionByDomain,
    };
  },
  {
    persist: { key: 'domain-checker-store' },
  },
);
