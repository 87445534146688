export default {
  beforeMount(el, binding, vNode) {
    addEventListener('scroll', () => {
      if (isInViewport(el) && binding && vNode) {
        if (vNode.context[binding.expression]) {
          vNode.context[binding.expression]();
        }
      }
    });
  },
};

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
