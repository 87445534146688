import { HDOMAIN_RESOURCE_TYPES } from '@hostinger/hdomains-status';

import { hDomainsRepo } from '@/repositories';
import { useResourcesStore } from '@/stores';

export default {
  namespaced: true,
  state: {
    loading: false,
    billingItems: [],
    mappedSubscriptions: [],
    mappedBillingItems: [],
  },
  mutations: {
    SET_BILLING_ITEMS(state, payload) {
      state.billingItems = payload;
    },
    SET_MAPPED_SUBSCRIPTIONS(state, payload) {
      state.mappedSubscriptions = payload;
    },
    SET_MAPPED_BILLING_ITEMS(state, payload) {
      state.mappedBillingItems = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    UPDATE_MAPPED_BILLING_ITEM_SUBSCRIPTION(state, payload) {
      const updated = state.mappedBillingItems.map((billingItem) => {
        if (!billingItem?.subscription) return billingItem;
        if (billingItem.subscription.id === payload.id) {
          return {
            ...billingItem,
            subscription: payload,
          };
        }

        return billingItem;
      });
      state.mappedBillingItems = [...updated];
    },
  },
  actions: {
    async getBillingItems(
      { state, rootState, rootGetters, commit, dispatch },
      options = {
        isSilentRefetch: false,
        requestConfig: {
          overrideCache: true,
        },
      },
    ) {
      const resourceStore = useResourcesStore();

      commit('SET_LOADING', true);
      const subscriptionsCall = dispatch(
        'subscriptions/billingGetSubscriptionsWithScheduledChanges',
        options,
        { root: true },
      );
      const ordersCall = dispatch(
        'orders/billingGetOrders',
        {
          params: {
            statuses: ['awaiting_payment', 'payment_initiated'],
            type: 'subscription_order',
            excludeOneClick: 1,
          },
          ...options.requestConfig,
        },
        { root: true },
      );

      await Promise.all([
        ordersCall,
        subscriptionsCall,
        resourceStore.fetchResources(options.requestConfig),
      ]);

      const getPendingSubscriptionOrders =
        rootGetters['orders/getPendingSubscriptionOrders'];
      commit(
        'SET_BILLING_ITEMS',
        addOrdersToBillingItems(getPendingSubscriptionOrders),
      );

      const subscriptions = rootState.subscriptions.subscriptions;

      commit(
        'SET_MAPPED_SUBSCRIPTIONS',
        addResourcesToSubscriptions(
          resourceStore.billingResources,
          subscriptions,
        ),
      );

      const mappedBillingItems = addMappedSubscriptionsToBillingItems(
        state.billingItems,
        state.mappedSubscriptions,
      );

      commit('SET_MAPPED_BILLING_ITEMS', sortByPriority(mappedBillingItems));

      commit('SET_LOADING', false);

      return true;
    },
    async mapDomainResources({ state, commit }) {
      const [{ data }, err] = await hDomainsRepo.getResources();

      if (err) return;

      const domainList = data.filter(({ resourceType }) =>
        [
          HDOMAIN_RESOURCE_TYPES.DOMAIN,
          HDOMAIN_RESOURCE_TYPES.FREE_DOMAIN,
        ].includes(resourceType),
      );

      const billingItemsWithDomainResources = addDomainResourcesToBillingItems(
        domainList,
        state.mappedBillingItems,
      );

      commit('SET_MAPPED_BILLING_ITEMS', billingItemsWithDomainResources);

      return true;
    },
  },
};

const addOrdersToBillingItems = (getPendingSubscriptionOrders) => {
  const items = [...getPendingSubscriptionOrders];

  return items.map((item) => ({
    order: { ...item },
  }));
};

const addResourcesToSubscriptions = (getOrders, subscriptions) =>
  subscriptions.map((subscription) => {
    const resourceFound = getOrders.find(
      (resource) => resource.chargebeeSubscriptionId === subscription.id,
    );
    if (resourceFound) {
      subscription.resource = resourceFound;
    }

    return subscription;
  });

const addMappedSubscriptionsToBillingItems = (
  billingItems,
  mappedSubscriptions,
) => {
  // Add subscriptions to billing items
  const mappedBillingItems = billingItems.map((item) => {
    const subscriptionId = item.order?.items
      ? item.order.items[0]?.chargebeeSubscriptionId
      : null;

    if (!subscriptionId) return item;

    const subscriptionFound = mappedSubscriptions.find(
      (subscription) => subscriptionId === subscription.id,
    );

    if (subscriptionFound) {
      item.subscription = subscriptionFound;
    }

    return item;
  });

  // find subscriptions without orders
  const subscriptionsWithoutPendingOrders = mappedSubscriptions.map(
    (subscription) => {
      const orderFound = billingItems.filter((item) => {
        const subscriptionId = item.order?.items
          ? item.order.items[0]?.chargebeeSubscriptionId
          : null;

        return subscriptionId === subscription.id;
      });

      if (!orderFound.length) {
        return { subscription };
      }
    },
  );

  // add subscription without orders to the billing items list
  return [...mappedBillingItems, ...subscriptionsWithoutPendingOrders];
};

const sortByPriority = (list) => {
  const unpaidOrders = [];
  const expiredDomains = [];
  const pendingSetup = [];
  const activeSubscriptions = [];

  list.forEach((item) => {
    if (item.order && !item.subscription) {
      return unpaidOrders.push(item);
    }

    const resource = item?.subscription?.resource;

    if (resource?.type === 'domain' && resource?.state === 'expired') {
      return expiredDomains.push(item);
    }

    if (resource?.state === 'pending_setup') {
      return pendingSetup.push(item);
    }

    activeSubscriptions.push(item);
  });

  return [
    ...unpaidOrders,
    ...expiredDomains,
    ...pendingSetup,
    ...activeSubscriptions,
  ];
};

const addDomainResourcesToBillingItems = (domainResources, billingItems) =>
  billingItems.map((billingItem) => {
    if (!billingItem?.subscription?.resource) {
      return billingItem;
    }
    const domainFound = domainResources.find(
      (domain) =>
        domain.additionalDetails.hresourceId ===
        String(billingItem.subscription?.resource.id),
    );
    if (domainFound) {
      billingItem.domainResource = domainFound;
    }

    return billingItem;
  });
