export default {
  computed: {
    isCreditPay() {
      const creditsAndAmountPaid =
        this.pricingDetails.creditsApplied + this.pricingDetails.amountPaid;

      return (
        creditsAndAmountPaid === this.pricingDetails.total &&
        this.pricingDetails.finalAmount === 0
      );
    },
  },
};
