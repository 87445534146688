export default {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      const except_id = binding?.arg;
      let except_el = null;

      if (except_id) except_el = document.getElementById(except_id);
      if (
        except_el &&
        (except_el === event.target || except_el?.contains(event.target))
      ) {
        return;
      }

      if (el !== event.target && !el.contains(event.target)) {
        if (binding.value) binding.value(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },

  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
