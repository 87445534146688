import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { useResourcesStore } from '@/stores';
import { Email, Route } from '@/types';

const emailOnboardingGuard: NavigationGuard = async (to) => {
  const { getResourceBySubscriptionId } = useResourcesStore();

  const domain = to.query.domain as string;
  const subscriptionId = to.params.orderId as string;

  if (!store.getters['emails/getLoaded']) {
    await store.dispatch('emails/fetchEmails');
  }

  if (domain) {
    const resource = getResourceBySubscriptionId(subscriptionId);
    const hasHostingerOrder = !!store.getters['emails/getPremiumMail'](
      Email.EmailProvider.HOSTINGER,
      domain,
    );
    const hasTitanOrder = !!store.getters['emails/getPremiumMail'](
      Email.EmailProvider.TITAN,
      domain,
    );
    const hasActiveOrder = hasHostingerOrder || hasTitanOrder;

    if (
      !resource ||
      hasActiveOrder ||
      !store.getters['emails/getActiveSetup']
    ) {
      return { name: Route.Domain.MY_DOMAINS };
    }
  }

  const emailOrder = await store.getters['emails/getHostingerPendingSetupById'](
    subscriptionId,
  );

  if (!emailOrder) return { name: Route.Email.EMAILS };
};

export default emailOnboardingGuard;
