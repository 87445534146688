<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';

import type { HAction } from './hpActionModel';

import HpActionBody from '@/components/HpActions/HpActionBody.vue';
import HpActionButtons from '@/components/HpActions/HpActionButtons.vue';
import HpActionIcon from '@/components/HpActions/HpActionIcon.vue';
import HpIcon from '@/components/Icons/HpIcon.vue';

interface Props {
  action: HAction;
}

interface Emits {
  (eventName: 'close-action', payload: HAction): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const shouldDisplayNotifications = ref(false);
let displayCheckInterval: any;

const showNotification = () => {
  shouldDisplayNotifications.value = props.action.payload?.shouldDisplay
    ? props.action.payload?.shouldDisplay()
    : true;
};

const visibilityCondition = computed(
  () =>
    (props.action.payload?.shouldDisplay && shouldDisplayNotifications.value) ||
    !props.action.payload?.shouldDisplay,
);

// Workaround for `shouldDisplay` check, because it is not possible to make it reactive from single method execution
if (props.action.payload?.shouldDisplay) {
  displayCheckInterval = setInterval(() => showNotification(), 1000);
}

onUnmounted(() => {
  if (displayCheckInterval) clearInterval(displayCheckInterval);
});

const closeActionWithCallback = (action: HAction) => {
  if (typeof action?.payload?.closeCallback === 'function') {
    action.payload.closeCallback();
  }
  emit('close-action', action);
};
</script>

<template>
  <div
    v-show="visibilityCondition"
    :id="`h_message_${action.status || 'neutral'}_${action.id}`"
    class="h-actions__action"
  >
    <div class="h-actions__content">
      <div class="d-flex">
        <HpActionIcon :action="action" />
        <HpActionBody :action="action" />
      </div>
      <div class="h-actions__close-icon-wrapper">
        <HpIcon
          icon="icon-clear"
          static-view-box
          class="h-actions__close-icon"
          @click="closeActionWithCallback(action)"
        />
      </div>
    </div>
    <HpActionButtons
      :action="action"
      @close-action="emit('close-action', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}

.h-actions {
  &__close-icon-wrapper {
    display: flex;
    margin-left: 8px;

    > * {
      fill: var(--gray-border);
    }
  }

  &__close-icon {
    cursor: pointer;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    left: 0;
  }

  &__action {
    position: relative;
    padding: 16px 16px 16px 24px;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(29, 30, 32, 0.16);
    background: var(--light);
    max-width: calc(100vw - 28px);

    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 576px) {
      margin-left: 14px;
      margin-right: 14px;
    }
  }
}
</style>
