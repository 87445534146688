import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useGlobals } from '@/composables';
import { h5gDatabasesRepo } from '@/repositories';
import type { IH5GDatabase } from '@/types';

export const useH5GDatabasesStore = defineStore('h5gDatabasesStore', () => {
  const { toastr } = useGlobals();

  const databasesList = ref<IH5GDatabase[]>([]);
  const phpMyAdminUrl = ref('');

  const isDatabasesListLoading = ref(false);
  const isDatabaseCreateLoading = ref(false);
  const isPhpMyAdminLoading = ref(false);

  const fetchDatabases = async (websiteUid: string) => {
    isDatabasesListLoading.value = true;

    const [{ data }, error] = await h5gDatabasesRepo.getWebsiteDatabasesList(
      websiteUid,
    );

    isDatabasesListLoading.value = false;

    if (!data || error) {
      return;
    }

    databasesList.value = data.data;
  };

  const createDatabase = async (websiteUid: string, name: string) => {
    isDatabaseCreateLoading.value = true;

    const [, error] = await h5gDatabasesRepo.createDatabase(websiteUid, name);

    isDatabaseCreateLoading.value = false;

    if (error) {
      return;
    }
    await fetchDatabases(websiteUid);
    toastr.s('Database created successfully');
  };

  const createDatabaseUser = async (
    websiteUid: string,
    database: string,
    userData: {
      name: string;
      password: string;
    },
  ) => {
    const [, error] = await h5gDatabasesRepo.createUser(
      websiteUid,
      database,
      userData,
    );

    if (error) {
      return;
    }
    await fetchDatabases(websiteUid);

    toastr.s('Database user created successfully');
  };

  const deleteDatabaseUser = async (
    websiteUid: string,
    database: string,
    user: string,
  ) => {
    const [, error] = await h5gDatabasesRepo.deleteUser(
      websiteUid,
      database,
      user,
    );

    if (error) {
      return;
    }

    toastr.s('Database user deleted successfully');
  };

  const fetchPhpMyAdminUrl = async (websiteUid: string, database?: string) => {
    isPhpMyAdminLoading.value = true;

    if (!databasesList.value.length) {
      await fetchDatabases(websiteUid);
    }

    const selectedDatabaseName = database || databasesList.value?.[0]?.name;

    if (!selectedDatabaseName) {
      toastr.e('No database found');
      isPhpMyAdminLoading.value = false;

      return;
    }

    const [{ data }, error] = await h5gDatabasesRepo.getPhpMyAdminUrl(
      websiteUid,
      selectedDatabaseName,
    );

    isPhpMyAdminLoading.value = false;

    if (!data || error) {
      return;
    }

    phpMyAdminUrl.value = data;
  };

  const $reset = () => {
    databasesList.value = [];
    phpMyAdminUrl.value = '';
    isDatabasesListLoading.value = false;
    isDatabaseCreateLoading.value = false;
  };

  return {
    $reset,
    fetchDatabases,
    createDatabase,
    createDatabaseUser,
    deleteDatabaseUser,
    fetchPhpMyAdminUrl,
    databasesList,
    phpMyAdminUrl,
    isPhpMyAdminLoading,
    isDatabasesListLoading,
    isDatabaseCreateLoading,
  };
});
