<script setup lang="ts">
import { toUnicode } from '@/utils/helpers';

interface Breadcrumb {
  title: string;
  url: string;
  isInactive?: boolean;
}

type Props = {
  breadcrumbs: Breadcrumb[];
};

interface Emits {
  (eventName: 'navigate-to-home'): void;
  (eventName: 'navigate-to-route', payload: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const navigateToRoute = (route: Breadcrumb, index: number) =>
  !getIsInactive(route, index) && emit('navigate-to-route', route.url);

const getIsInactive = (route: Breadcrumb, index: number) =>
  props.breadcrumbs.length === index + 1 || route.isInactive;
</script>

<template>
  <div class="h-breadcrumb">
    <HpIcon
      :icon="'icon-home'"
      :width="20"
      class="h-breadcrumb__icon"
      static-view-box
      @click="$emit('navigate-to-home')"
    />
    <span
      v-for="(route, index) in breadcrumbs"
      :key="index"
      class="text-body-2 breadcrumb__route"
      :class="{ 'breadcrumb__route--inactive': getIsInactive(route, index) }"
      @click="navigateToRoute(route, index)"
    >
      -
      <span
        class="h-breadcrumb__route__label"
        :class="{
          'breadcrumb__route__label--inactive': getIsInactive(route, index),
        }"
      >
        {{ toUnicode(route.title) }}
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.h-breadcrumb {
  @media only screen and (max-width: 992px) {
    display: none !important;
  }

  &__icon {
    fill: var(--gray);
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      fill: var(--primary);
    }
  }

  &__route {
    padding-right: 2px;
    transition: 0.3s;
    cursor: pointer;
    &__label {
      &:hover {
        color: var(--primary);
      }
      &--inactive:hover {
        cursor: default;
        color: var(--gray);
      }
    }
    &--inactive {
      cursor: default;
      color: var(--gray);
      &:hover {
        color: var(--gray);
      }
    }
  }
}
</style>
