import { activityLogRepo, accountRepo } from '@/repositories';

export default {
  state: {
    logs: {},
    loaded: false,
    busy: true,
    error: null,
  },
  mutations: {
    SET_HOSTING_OTHER_ACTIVITYLOGS_DATA(state, payload) {
      state.logs = payload;
      state.loaded = true;
      state.busy = false;
    },
    SET_HOSTING_OTHER_ACTIVITYLOGS_BUSY(state, payload) {
      state.busy = payload;
    },
  },
  getters: {
    getHostingOtherActivityLog: (state) => ({
      loaded: state.loaded,
      logs: state.logs,
      busy: state.busy,
    }),
  },
  actions: {
    async hostingOtherActivityLogIndex(
      { commit, getters },
      { limit, offset, type },
    ) {
      commit('SET_HOSTING_OTHER_ACTIVITYLOGS_BUSY', true);
      let username = getters.currentOrderDetails.username;
      if (username === null && type !== 'dns') {
        const [{ data: orderData }, orderError] =
          await accountRepo.getAccountDetails();
        if (!orderError) {
          username = orderData.username;
        }
      }
      const [{ data }] = await activityLogRepo.getHostingOtherActivityLogIndex({
        type,
        limit,
        page: offset === 0 ? offset + 1 : offset / limit + 1,
        username: type === 'dns' ? null : username,
      });
      if (data) {
        commit('SET_HOSTING_OTHER_ACTIVITYLOGS_DATA', data);
      }
    },
  },
};
