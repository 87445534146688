export namespace WordPress {
  export const CompatibilityStatus = {
    COMPATIBLE: 'compatible',
    COMPATIBLE_WITH_EXCEPTIONS: 'compatible_with_exceptions',
    BETA_SUPPORT: 'beta_support',
    INCOMPATIBLE: 'not_compatible',
  } as const;

  export const SectionRouteSlug = {
    WORDPRESS: 'wordpress',
    ORDER: 'order',
    PERFORMANCE: 'performance',
    ACCESS_LOGS: 'access_logs',
    SECURITY: 'security',
    EMAILS: 'emails',
    DOMAINS: 'domains',
    WEBSITE: 'website',
    FILES: 'files',
    DATABASES: 'databases',
    ADVANCED: 'advanced',
    ACCOUNTS: 'accounts',
  } as const;

  export type SectionRouteSlugType =
    (typeof SectionRouteSlug)[keyof typeof SectionRouteSlug];

  export const SectionRouteChildSlug = {
    DASHBOARD: 'dashboard',
    SECURITY: 'security',
    AI_TROUBLESHOOTER: 'ai_troubleshooter',
    STAGING: 'staging',
    PRESETS: 'presets',
    LEARN_WITH_US: 'learn_with_us',
    DETAILS: 'details',
    ORDER_USAGE: 'order_usage',
    RENEW: 'renew',
    UPGRADE: 'upgrade',
    OBJECT_CACHE: 'object_cache',
    PAGE_SPEED: 'page_speed',
    CDN: 'cdn',
    SEO: 'seo',
    WORDPRESS_AUTO_UPDATES: 'wordpress_auto_updates',
    MALWARE_SCANNER: 'malware_scanner',
    SSL: 'ssl',
    EMAIL_ACCOUNTS: 'email_accounts',
    SUBDOMAINS: 'subdomains',
    PARKED_DOMAINS: 'parked_domains',
    REDIRECTS: 'redirects',
    WORDPRESS_INSTALL: 'wordpress_install',
    AUTO_INSTALLER: 'auto_installer',
    ADD_WEBSITE: 'add_website',
    MIGRATE_WEBSITE: 'migrate_website',
    COPY_WEBSITE: 'copy_website',
    ERROR_PAGES: 'error_pages',
    LOGO_MAKER: 'logo_maker',
    WEBSITE_DEVELOPMENT: 'website_development',
    FILE_MANAGER: 'file_manager',
    BACKUPS: 'backups',
    FTP_ACCOUNTS: 'ftp_accounts',
    CHANGE_PASSWORD: 'change_password',
    MY_SQL_DATABASES: 'my_sql_databases',
    PHP_MY_ADMIN: 'php_my_admin',
    REMOTE_MY_SQL: 'remote_my_sql',
    DNS_ZONE_EDITOR: 'dns_zone_editor',
    PHP_CONFIGURATION: 'php_configuration',
    CRON_JOBS: 'cron_jobs',
    SSH_ACCESS: 'ssh_access',
    PHP_INFO: 'php_info',
    CACHE_MANAGER: 'cache_manager',
    GIT: 'git',
    PASSWORD_PROTECT_DIRECTORIES: 'password_protect_directories',
    IP_MANAGER: 'ip_manager',
    HOTLINK_PROTECTION: 'hotlink_protection',
    FOLDER_INDEX_MANAGER: 'folder_index_manager',
    FIX_FILE_OWNERSHIP: 'fix_file_ownership',
    ACTIVITY_LOG: 'activity_log',
    RELOAD_ACCOUNT: 'reload_account',
    DEACTIVATE_ACCOUNT: 'deactivate_account',
  } as const;

  export type SectionRouteChildSlugType =
    (typeof SectionRouteChildSlug)[keyof typeof SectionRouteChildSlug];
}
