import type {
  IHostingAccount,
  IPasswordProtectedDirectoryRequest,
  IPasswordProtectedDirectory,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/password-protected-directories`,

  async getPasswordProtectedDirectories(
    accountIdentifier: HostingWebsiteAccountIdentifier,
  ) {
    return await hAsync<IPasswordProtectedDirectory[]>(
      http.get(this.url(accountIdentifier)),
    );
  },

  async postPasswordProtectedDirectories(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: IPasswordProtectedDirectoryRequest,
  ) {
    return await hAsync<IPasswordProtectedDirectory>(
      http.post(this.url(accountIdentifier), requestData, {
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async deletePasswordProtectedDirectories(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    pwkey: IPasswordProtectedDirectory['pwkey'],
  ) {
    return await hAsync<IPasswordProtectedDirectory>(
      http.delete(`${this.url(accountIdentifier)}/${pwkey}`),
    );
  },
};
