import { ref, computed, type ComputedRef, watch, unref } from 'vue';

interface IHRadioItem {
  id: string;
  title?: string;
  isActive?: boolean;
}

type ExtendedHRadioItem<T, ItemType> = IHRadioItem & { id: T } & ItemType;

const addIsActiveToFirstItem = <T, ItemType>(
  items: ExtendedHRadioItem<T, ItemType>[],
  defaultValue: T | undefined,
  shouldAddDefaultValue: boolean | undefined,
  emptyDefaultValue: boolean | undefined,
) => {
  if (shouldAddDefaultValue) {
    return items.map((item) => ({
      ...item,
      isActive: item.id === defaultValue,
    }));
  }

  if (emptyDefaultValue) {
    return items;
  }

  return items.map((item, index) =>
    index === 0 ? { ...item, isActive: true } : item,
  );
};

export const useHRadio = <T extends string, ItemType = {}>(
  itemsGeneric:
    | ComputedRef<ExtendedHRadioItem<T, ItemType>[]>
    | ExtendedHRadioItem<T, ItemType>[],
  options: {
    defaultValue?: T;
    emptyDefaultValue?: boolean;
  } = {},
) => {
  const shouldAddDefaultValue = 'defaultValue' in options;

  const items = computed(() => unref(itemsGeneric));

  const values = ref<ExtendedHRadioItem<T, ItemType>[]>([]);

  const setActiveValue = (id: string) => {
    values.value = values.value.map((item) => ({
      ...item,
      isActive: item.id === id,
    }));
  };

  const activeValueId = computed(
    () =>
      (values.value.find(({ isActive }) => isActive)?.id ??
        values.value[0]?.id) as T,
  );

  const activeValue = computed(
    (): ExtendedHRadioItem<T, ItemType> | undefined =>
      values.value.find(({ isActive }) => isActive) as ExtendedHRadioItem<
        T,
        ItemType
      >,
  );

  const hasActiveValue = computed(() =>
    values.value.some(({ isActive }) => isActive),
  );

  const setItems = (
    newItems: ExtendedHRadioItem<T, ItemType>[],
    newDefaultValue?: T | undefined,
  ) => {
    values.value = [
      ...addIsActiveToFirstItem<T, ItemType>(
        newItems,
        newDefaultValue || options.defaultValue,
        shouldAddDefaultValue,
        options.emptyDefaultValue,
      ),
    ] as any;
  };

  watch(
    () => items.value,
    (value) => {
      if (!value || !value?.length) {
        return;
      }

      setItems(value, hasActiveValue.value ? activeValueId.value : undefined);
    },
    {
      immediate: true,
    },
  );

  return {
    values,
    setActiveValue,
    setItems,
    hasActiveValue,
    activeValue,
    activeValueId,
  };
};
