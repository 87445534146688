import dayjs from 'dayjs';
import { computed } from 'vue';

import RequiredNiagahosterOnboardingVideoModal from '@/components/Modals/HModal/RequiredNiagahosterOnboardingVideoModal.vue';
import RequiredPasswordChangeModalV2 from '@/components/Modals/HModal/RequiredPasswordChangeModalV2.vue';
import UpdatePersonalInformationV2 from '@/components/Modals/HModal/UpdatePersonalInformationV2.vue';
import { useModal, useGlobals, useBrands, useRedirects } from '@/composables';
import { profileInformationV2ModalDataById } from '@/data/profile/profileInformationV2';
import { useProfileStore } from '@/stores';
import type {
  ProfileInformationModalKeyValue,
  ProfileInformationV2Values,
} from '@/types';
import {
  Client,
  Country,
  SessionStorage,
  PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY,
  PROFILE_INFORMATION_MODAL_SCHEMA_KEY,
} from '@/types';
import { toTitleCase, DEFAULT_DATE_FORMAT } from '@/utils/helpers';

export const useProfile = () => {
  const { openModal, setModalClosingType } = useModal();
  const { toastr, t } = useGlobals();
  const profileStore = useProfileStore();
  const { isFromNiagahoster } = useBrands();
  const { performCookieRedirect } = useRedirects();

  const openUpdateEmailModal = () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.EMAIL,
      {
        [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.EMAIL]:
          profileStore.contact?.email || '',
      },
    );
  };

  const openUpdateAddressModal = async () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.ADDRESS,
    );
  };

  const openUnableToUnlinkSocialToaster = () => {
    toastr.e(t('We are unable to unlink your social login'), {
      html: t(
        'Your account is linked to {socialLogin} social login. To unlink your social login, please create a password.',
        { socialLogin: toTitleCase(profileStore.linkedSocialLogin.value) },
      ),
      buttons: [
        {
          text: t('Add password'),
          props: {
            primary: true,
            text: true,
          },
          callback: () => {
            openModal({
              component: { RequiredPasswordChangeModalV2 },
              data: { isCloseable: true },
            });
          },
        },
      ],
      timeout: Math.max(),
    });
  };

  const openUpdateProfileInformationModal = (
    modalId: ProfileInformationModalKeyValue,
    values?: Partial<ProfileInformationV2Values>,
    onSuccess?: () => void,
  ) => {
    const modalData = profileInformationV2ModalDataById(
      modalId,
      values || (profileStore.contact as unknown as ProfileInformationV2Values),
    );

    openModal({
      component: { UpdatePersonalInformationV2 },
      steps: [
        {
          title: modalData.title,
          subtitle: modalData?.subtitle,
          noTrans: modalData?.noTrans,
        },
      ],
      data: {
        id: modalId,
        formSchema: modalData.formSchema,
        modalData,
        onSuccess,
      },
    });
  };

  const openUpdateNameAndAddressModal = async () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME_ADDRESS,
      {
        firstName: profileStore.contact?.firstName || '',
        lastName: profileStore.contact?.lastName || '',
        countryCode: profileStore.contact?.countryCode || '',
        state: profileStore.contact?.state || '',
        address: profileStore.contact?.address || '',
        city: profileStore.contact?.city || '',
        zip: profileStore.contact?.zip || '',
      },
    );

    sessionStorage.setItem(SessionStorage.NAME_ADDRESS_MODAL_SHOWN, 'true');
  };

  const onSuccessPasswordChange = async () => {
    if (!isFromNiagahoster) {
      await performCookieRedirect();

      return;
    }

    openModal({
      component: { RequiredNiagahosterOnboardingVideoModal },
      data: {
        onClosed: performCookieRedirect,
      },
    });
  };

  const openChangePasswordModal = () => {
    openModal({
      component: { RequiredPasswordChangeModalV2 },
      data: {
        onSuccess: onSuccessPasswordChange,
        isOnSuccessOpenOtherModal: isFromNiagahoster,
        email: profileStore.contact?.email,
      },
    });

    setModalClosingType('input');
  };

  const accountDaysRegistered = computed(() => {
    const datePattern = DEFAULT_DATE_FORMAT;

    const currentDate = dayjs(dayjs().format(datePattern), datePattern);
    const createdAt = dayjs(profileStore.account?.createdAt, datePattern);

    return currentDate.diff(createdAt, 'd');
  });

  const impersonatingDisabledTooltip = computed(() =>
    profileStore.isStaff
      ? 'v2.impersonating_account_tooltip_disabled'
      : undefined,
  );

  const isPasswordChangeRequired = computed(() => {
    if (profileStore.isStaff || profileStore.isAccessManager) {
      return false;
    }

    if (!profileStore.isProfileLoaded) return false;

    return !profileStore.hasOauth && !profileStore.account?.isPasswordSet;
  });

  const isAddressUpdateRequired = computed(() => {
    const isFromUS = profileStore.contact?.countryCode === Country.Code.US;
    const isAddressFilled =
      profileStore.contact?.city &&
      profileStore.contact?.countryCode &&
      profileStore.contact?.zip &&
      profileStore.contact?.state &&
      profileStore.contact?.address;

    return (
      !profileStore.isStaff &&
      !profileStore.isAccessManager &&
      !isAddressFilled &&
      isFromUS
    );
  });

  const isAddressOrNameUpdateRequired = computed(() => {
    const isFromIndia =
      profileStore.account?.brand.id &&
      parseInt(profileStore.account.brand.id, 10) ===
        parseInt(Client.BrandId.Hostinger.IN, 10);
    const isNameFilled =
      profileStore.contact?.firstName && profileStore.contact?.lastName;
    const isAddressFilled =
      profileStore.contact?.city &&
      profileStore.contact?.countryCode &&
      profileStore.contact?.zip &&
      profileStore.contact?.address &&
      profileStore.contact?.state;
    const isDataMissing = !isNameFilled || !isAddressFilled;
    const isModalShownAlready = sessionStorage.getItem(
      SessionStorage.NAME_ADDRESS_MODAL_SHOWN,
    );

    return (
      !profileStore.isStaff &&
      !profileStore.isAccessManager &&
      !isModalShownAlready &&
      isFromIndia &&
      isDataMissing
    );
  });

  return {
    openUpdateEmailModal,
    openUpdateProfileInformationModal,
    openUnableToUnlinkSocialToaster,
    openUpdateAddressModal,
    accountDaysRegistered,
    openUpdateNameAndAddressModal,
    openChangePasswordModal,
    impersonatingDisabledTooltip,
    isPasswordChangeRequired,
    isAddressUpdateRequired,
    isAddressOrNameUpdateRequired,
  };
};
