export namespace SoftaculousEnum {
  export enum SoftwareId {
    WORDPRESS = 26,
  }

  export enum SoftwareName {
    WORDPRESS = 'wordpress',
  }

  export enum Protocol {
    HTTP = '1',
    HTTP_WWW = '2',
    HTTPS = '3',
    HTTPS_WWW = '4',
  }
}
