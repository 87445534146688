<script setup lang="ts">
import { computed } from 'vue';

import { useModal } from '@/composables';

type Props = {
  data: {
    title: string;
    subtitle?: string;
    primaryActionLabel: string;
    secondaryActionLabel: string;
    primaryAction: () => Promise<void>;
    secondaryAction: () => Promise<void>;
    shouldCloseAfterConfirmAction?: boolean;
  };
};

const { closeModal } = useModal();

const props = defineProps<Props>();

const modalData = computed(() => ({
  title: props.data.title,
  subtitle: props.data.subtitle,
  primaryActionLabel: props.data.primaryActionLabel,
  secondaryActionLabel: props.data.secondaryActionLabel,
  primaryAction: async () => {
    if (props.data.primaryAction) {
      await props.data.primaryAction();

      if (props.data.shouldCloseAfterConfirmAction ?? true) {
        closeModal();
      }
    }
  },
  secondaryAction: props.data.secondaryAction,
}));
</script>

<template>
  <div>
    <h2 class="h-mb-8">
      {{ modalData.title }}
    </h2>
    <p v-if="modalData.subtitle" class="h-mb-24">
      {{ modalData.subtitle }}
    </p>
    <div class="text-right two-choice-prompt-modal__cta-wrapper">
      <HButton
        v-qa-generate
        variant="text"
        class="h-mr-16"
        color="primary"
        @click="modalData.secondaryAction"
      >
        {{ modalData.secondaryActionLabel }}
      </HButton>
      <HButton v-qa-generate color="primary" @click="modalData.primaryAction">
        {{ modalData.primaryActionLabel }}
      </HButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.two-choice-prompt-modal {
  &__title-icon {
    padding: 4px;
    margin-right: 4px;
    vertical-align: bottom;
  }
  &__cta-wrapper {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
}
</style>
