import cookies from 'js-cookie';
import { computed, ref } from 'vue';

import { FeatureFlag, Cookie } from '@/types';
import experimentService from '@/utils/services/experimentService';

export const useFeatureFlag = (featureFlagId: FeatureFlag.ID) => {
  const allowedQAFeatureFlags = () => {
    try {
      const allowedFeatureFlags = cookies.get(
        Cookie.ALLOWED_FEATURE_FLAGS_QA_COOKIE,
      );

      if (!allowedFeatureFlags) {
        return [];
      }

      return JSON.parse(allowedFeatureFlags);
    } catch (err) {
      return [];
    }
  };

  const featureFlag = ref<string | undefined>(
    experimentService.getAmplitudeFeatureFlagById(featureFlagId),
  );

  const isFeatureEnabled = computed(() => {
    if (allowedQAFeatureFlags().includes(featureFlagId)) {
      return true;
    }

    return featureFlag.value === FeatureFlag.Value.ENABLED;
  });

  return {
    isFeatureEnabled,
  };
};
