import type {
  HRefundInvoice,
  HRefundInvoiceEstimate,
  IHRefundHistoryItem,
  HRefundInvoiceEstimatePayload,
} from '@/types';
import { hAsync, toASCII } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const DEFAULT_HREFUNDS_PER_PAGE = 5;

export default {
  url: `${process.env.VITE_API_HREFUNDS}/api/v1`,

  async getInvoice(invoiceId: string) {
    return await hAsync<HRefundInvoice>(
      http.get(`${this.url}/invoice/${invoiceId}`),
    );
  },

  async getRefundEstimate(
    invoiceId: string,
    refundData: HRefundInvoiceEstimatePayload,
  ) {
    return await hAsync<HRefundInvoiceEstimate>(
      http.post(`${this.url}/refund/${invoiceId}/estimate`, refundData),
    );
  },

  async requestRefund(
    invoiceId: string,
    refundData: {
      refundReason: string;
      clientNote: string;
      subscriptions: string[];
    },
  ) {
    return await hAsync<HRefundInvoice>(
      http.post(`${this.url}/refund/${invoiceId}`, refundData),
    );
  },

  async requestRefundToBalance(
    invoiceId: string,
    refundData: {
      refundReason: string;
      clientNote: string;
      subscriptions: string[];
    },
  ) {
    return await hAsync<HRefundInvoice>(
      http.post(`${this.url}/refund/${invoiceId}/balance`, refundData),
    );
  },

  getRefundHistory() {
    return hAsync<IHRefundHistoryItem[]>(
      http.get(`${this.url}/refund/history`),
    );
  },

  getRefundableInvoices({
    page,
    perPage = DEFAULT_HREFUNDS_PER_PAGE,
    search = '',
  }: {
    page: number;
    perPage?: number;
    search?: string;
  }) {
    return hAsync<{
      data: HRefundInvoice[];
      page: number;
      total: number;
      totalPages: number;
    }>(
      http.get(`${this.url}/customer/refundable-invoices`, {
        params: { page, perPage, search: toASCII(search) },
      }),
    );
  },
};
