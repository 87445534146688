import type { IH5GProcess, IH5GWebsite } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/h5g/websites`,
  async getWebsiteFileManagerUrl(websiteUid: string) {
    return await hAsync<string>(
      http.get(`${this.url}/${websiteUid}/file-manager`, {
        noHostingHeaders: true,
      }),
    );
  },

  async getWebsiteDetails(websiteUid: string) {
    return await hAsync<IH5GWebsite>(
      http.get(`${this.url}/${websiteUid}`, {
        noHostingHeaders: true,
      }),
    );
  },

  async getWebsiteProcesses(websiteUid: string) {
    return await hAsync<IH5GProcess[]>(
      http.get(`${this.url}/${websiteUid}/processes`, {
        noHostingHeaders: true,
      }),
    );
  },
  async deleteWebsite(websiteUid: string) {
    return await hAsync<boolean>(
      http.delete(`${this.url}/${websiteUid}`, {
        noHostingHeaders: true,
      }),
    );
  },

  async changeDomain(domain: string, newDomain: string, websiteUid?: string) {
    return await hAsync<boolean>(
      http.put(`${this.url}/${websiteUid}/domains/${domain}`, {
        domain: newDomain,
        websiteUid,
      }),
    );
  },
};
