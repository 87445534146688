import dayjs from 'dayjs';

import { useResourceMapper } from '@/composables/useResourceMapper';
import { cPanelEmailHostingPackages } from '@/data/cpanel/cPanelEmailHosting';
import {
  accountRepo,
  freeUpgradeRepo,
  lveRepo,
  ordersRepo,
  planRepo,
} from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { HRESOURCES_TYPE, MONOLITH_RESOURCE_STATUS } from '@/types';
import { isHostingPlanUpgradable } from '@/utils/helpers';
import { isCpanelEmailHostingDashboard } from '@/utils/helpers/emailsHelper';

const initialOrderDetails = {
  orderDetails: {
    createdAt: null,
    databaseVersion: null,
    ftpUser: null,
    inTransfer: null,
    ip: null,
    lifetimeSslEnabled: null,
    nameservers: null,
    order: null,
    orderId: null,
    plan: null,
    resellerId: null,
    server: null,
    serverId: null,
    shellEnabled: null,
    status: null,
    usage: null,
    username: null,
    vhosts: [],
    orderVhosts: [],
    webServer: null,
  },
  orderDetailsLoaded: false,
  orderDetailsLoading: false,
  upgradeRecommendation: {},
};

export default {
  state: {
    orders: [],
    ordersLoaded: false,
    orderDetailsLoaded: false,
    initial: initialOrderDetails,
    data: [],
    restorableOrders: [],
    restorableHostingOrdersLoaded: false,
    hostingOrdersLastUpdate: null,
    initialLoad: true,
    lveFaults: [],
    ordersLoadedForRedirect: false,
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_INITIAL_LOAD: (state) => (state.initialLoad = false),
    DELETE_ORDER_VHOST: (state, domain) => {
      const orderDetails = getStateVariable(state, 'orderDetails');
      orderDetails.orderVhosts = orderDetails.orderVhosts.filter(
        ({ vhost }) => vhost !== domain,
      );

      setForDomain(state, null, 'orderDetails', orderDetails);
    },
    ADD_ORDER_DETAILS: (state, { response, getters }) => {
      setForDomain(
        state,
        response.requestDomain,
        'orderDetails',
        response.data,
      );
      const currentOrder = getCurrentOrder(state, getters);
      if (currentOrder) {
        currentOrder.orderCancelationDate = response.data?.orderCancelationDate;
      }
    },
    SET_HOSTING_ORDERS(state, hostingOrders) {
      state.orders = hostingOrders?.map((order) => {
        const foundOrder = state.orders?.find(({ id }) => id === order.id);
        order.accounts = foundOrder ? foundOrder.accounts : [];

        return order;
      });
    },
    SET_ORDERS_LVE_FAULTS(state, data) {
      state.lveFaults = data;
    },
    REMOVE_ORDER_LVE_FAULTS(state, faultOrderId) {
      state.lveFaults = state.lveFaults.filter(
        ({ orderId }) => orderId !== faultOrderId,
      );
    },
    SET_RESTORABLE_ORDERS(state, restorableOrders) {
      state.restorableOrders = restorableOrders;
    },
    SET_RESTORABLE_ORDERS_LOADED(state, value) {
      state.restorableHostingOrdersLoaded = value;
    },
    setOrdersLoaded(state, data) {
      state.ordersLoaded = data;
    },
    SET_ORDER_DETAILS_LOADED: (state, { data, requestDomain }) => {
      setForDomain(state, requestDomain, 'orderDetailsLoaded', data);
    },
    SET_ORDER_DETAILS_LOADING: (state, data) => {
      setForDomain(state, null, 'orderDetailsLoading', data);
    },
    SET_HOSTING_ORDERS_LAST_UPDATE: (state, time) => {
      state.hostingOrdersLastUpdate = time || dayjs();
    },
    SET_ORDER_UPGRADE_RECOMMENDATION: (state, data) => {
      setForDomain(state, null, 'upgradeRecommendation', data);
    },
    setOrdersLoadedForRedirect(state, data) {
      state.ordersLoadedForRedirect = data;
    },
  },
  getters: {
    getInitialLoad: (state) => state.initialLoad,
    getOrders: (state) => state.orders,
    getRestorableOrders: (state) => state.restorableOrders,
    restorableHostingOrdersLoaded: (state) =>
      state.restorableHostingOrdersLoaded,
    getOrderLveFaults: (state) => (faultOrderId) => {
      const order = state.lveFaults.find(
        ({ orderId }) => orderId === faultOrderId,
      );

      return order?.faults;
    },
    getOrderLveFaultsExist: (state, getters) => (faultOrderId) => {
      const order = getters.getOrderLveFaults(faultOrderId);

      return order ? !!Object.keys(order).length : false;
    },
    getCurrentOrder: (state, getters) => getCurrentOrder(state, getters),
    getIsHostingPlanOptimal: (state, getters) => (planName) => {
      const planNameDefault =
        planName || getCurrentOrder(state, getters)?.productName;

      return !isHostingPlanUpgradable(planNameDefault);
    },
    getCurrentOrderWithDomain: (state, getters) => (domain) =>
      getCurrentOrder(state, getters, domain),
    getOrderById: (state) => (orderId) =>
      state.orders.find(({ id }) => id === orderId),
    getOrderByReferenceId: (state) => (referenceIdToFind) =>
      state.orders.find(({ referenceId }) => referenceId === referenceIdToFind),
    getCurrentOrderCancelationDate: (state, getters) => {
      const currentOrderDetails = getCurrentOrder(state, getters);

      return currentOrderDetails
        ? currentOrderDetails.expiresAt
        : dayjs().add(1, 'year').unix();
    },
    getCurrentOrderRenewLink: (state, getters) => {
      const order = getCurrentOrder(state, getters);

      if (order && order.links) {
        return order.links.find((link) => link.name === 'renew')?.link;
      }

      return null;
    },
    getOrdersLoaded: (state) => state.ordersLoaded,
    getOrderDetailsLoaded: (state) =>
      getStateVariable(state, 'orderDetailsLoaded'),
    getOrderDetailsLoading: (state) =>
      getStateVariable(state, 'orderDetailsLoading'),
    currentOrderDetails: (state) => getStateVariable(state, 'orderDetails'),
    getOrderUpgradeRecommendation: (state) =>
      getStateVariable(state, 'upgradeRecommendation') || {},
    getCPanelOrderByDomain:
      (_state, getters) =>
      (domain, routePath = undefined) => {
        if (!domain) return null;

        const orders = getters.getCPanelHostingFilteredByPlanType(
          isCpanelEmailHostingDashboard(routePath),
        );

        return orders.find((order) => order.cpanelHostname === domain);
      },
    getCPanelHostingFilteredByPlanType:
      (_state, getters) =>
      (isCpanelEmailHosting = false) => {
        const orders = [
          ...getters.getCPanelActiveOrders,
          ...getters.getCPanelResellerActiveOrders,
        ];

        if (isCpanelEmailHosting) {
          return orders.filter((order) =>
            cPanelEmailHostingPackages.includes(order.cpanelPlan),
          );
        }

        return orders.filter(
          (order) => !cPanelEmailHostingPackages.includes(order.cpanelPlan),
        );
      },
    getCPanelActiveOrders: (state) =>
      state.orders.filter(
        (order) =>
          order.status === 'active' &&
          order.service === 'cpanel_hosting' &&
          order.cpanelHostname,
      ),
    getCPanelResellerActiveOrders: (state) =>
      state.orders.filter(
        (order) =>
          order.status === 'active' &&
          order.service === HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING &&
          order.cpanelHostname,
      ),
    getOrdersLoadedForRedirect: (state) => state.ordersLoadedForRedirect,
  },
  actions: {
    async hostingOrdersDetailsIndex({ commit, getters }) {
      const [{ data, requestDomain }] = await accountRepo.getAccountDetails();

      commit('SET_ORDER_DETAILS_LOADED', {
        data: true,
        requestDomain,
      });

      commit('ADD_ORDER_DETAILS', {
        response: { requestDomain, data },
        getters,
      });
    },
    async hostingOrdersDetailsInTransferIndex({ commit, getters }) {
      const [{ data, requestDomain }] = await accountRepo.getAccountDetails();

      commit('SET_ORDER_DETAILS_LOADED', {
        data: true,
        requestDomain,
      });
      data.inTransfer = true;

      commit('ADD_ORDER_DETAILS', {
        response: { requestDomain, data },
        getters,
      });
    },
    async hostingOrdersDetailsBoostedIndex({ commit, getters }) {
      const [{ data, requestDomain }] = await accountRepo.getAccountDetails();

      commit('SET_ORDER_DETAILS_LOADED', {
        data: true,
        requestDomain,
      });
      const boostDetails = {
        activated: true,
        boostedAt: dayjs().add(24, 'hours'),
        canBeBoosted: false,
        wasRecentlyCompleted: false,
      };
      data.planBoost = boostDetails;

      commit('ADD_ORDER_DETAILS', {
        response: { requestDomain, data },
        getters,
      });
    },
    async hostingOrdersLveFaultsIndex({ commit }) {
      const [{ data }] = await lveRepo.getLveFaults({ monthly: 1 });

      commit('SET_ORDERS_LVE_FAULTS', data || []);
    },
    hostingOrderUpgradeCovertStore(context, data) {
      return freeUpgradeRepo.ssoFreeUpgradeStore(data);
    },
    hostingOrderUpgradeExpirationIndex(context, data) {
      return freeUpgradeRepo.hostingFreeUpgradeIndex(data);
    },
    async hostingOrderUpgradeRecommendationIndex(
      { commit },
      { fault, hideToastr },
    ) {
      const [{ data }] = await planRepo.hostingUpgradeRecommendations({
        fault,
        hideToastr,
      });
      commit('SET_ORDER_UPGRADE_RECOMMENDATION', data);
    },
    async hostingOrdersDetailsHardRefresh({ commit, getters }) {
      const [{ data, requestDomain }] = await accountRepo.getAccountDetails({
        fresh_quotas: 1,
      });

      commit('ADD_ORDER_DETAILS', {
        response: { requestDomain, data },
        getters,
      });
    },
    async fetchHostingOrders(
      { commit, dispatch },
      requestConfig = { overrideCache: true },
    ) {
      const resourceMapper = useResourceMapper();

      commit('setOrdersLoaded', false);
      commit('SET_ORDER_DETAILS_LOADING', true);
      commit('setOrdersLoadedForRedirect', false);

      await resourceMapper.fetchResourcesAndSubscriptions(requestConfig);

      const { getMappedMonolithOrderByType } = useResourceMapper();

      const orders = getMappedMonolithOrderByType([
        'hosting',
        'cpanel_hosting',
        'cpanel_reseller_hosting',
      ]);

      if (orders.length) {
        commit('SET_HOSTING_ORDERS', orders);

        const accountsCalls = orders.flatMap((order) =>
          ![
            MONOLITH_RESOURCE_STATUS.PENDING_DATA_ENTRY,
            MONOLITH_RESOURCE_STATUS.IN_PROGRESS,
          ].includes(order.status)
            ? dispatch('hostingOrderAccountsIndex', order)
            : [],
        );

        await Promise.all(accountsCalls);
      }

      commit('setOrdersLoaded', true);

      commit('setOrdersLoadedForRedirect', true);
      commit('SET_ORDER_DETAILS_LOADING', false);

      commit('SET_INITIAL_LOAD');
    },
    async fetchRestorableHostingOrders({ commit }) {
      commit('SET_RESTORABLE_ORDERS_LOADED', false);

      const [{ data }, error] = await ordersRepo.getCanceledHostingOrders();

      if (!error) {
        commit('SET_RESTORABLE_ORDERS', data);
      }

      commit('SET_RESTORABLE_ORDERS_LOADED', true);
    },
  },
};

const getCurrentOrder = (state, getters, domain) => {
  const acc = domain
    ? getters.getCurrentAccountWithDomain(domain)
    : getters.getCurrentAccount;
  const orderId = acc?.orderId;

  return state.orders.find(({ id }) => id === orderId);
};
