import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  useBrands,
  useResourceFilters,
  useResourceMapper,
  useSaleCampaign,
  useVpsSaleCampaign,
} from '@/composables';
import { Client, Sale } from '@/types';

export const useNewYearSaleCampaign = () => {
  const { activeSale, userHasOneHostingAndUpgraded } = useSaleCampaign();
  const { hasBrand, isFromAnotherBrand } = useBrands();
  const { upgradableHostingResources, activeResources } = useResourceMapper();
  const { isDomainOnlyUser } = useResourceFilters();
  const { isVpsSaleAvailable, isEligibleForKVMSale } = useVpsSaleCampaign();
  const store = useStore();

  const isSaleActive = computed(
    () => !!activeSale.value && activeSale.value?.name === Sale.NEW_YEAR,
  );

  const isEligibleForHostingPurchaseOffer = computed(() => {
    const includedBrands = [
      Client.BrandId.Hostinger.COM,
      Client.BrandId.Hostinger.CO_UK,
      Client.BrandId.Hostinger.IN,
      Client.BrandId.Hostinger.FR,
      Client.BrandId.Hostinger.ES,
      Client.BrandId.Hostinger.PK,
      Client.BrandId.Hostinger.DE,
      Client.BrandId.Hostinger.COM_BR,
      Client.BrandId.Hostinger.NL,
      Client.BrandId.Hostinger.MX,
      Client.BrandId.Hostinger.LT,
      Client.BrandId.Hostinger.IT,
      Client.BrandId.Hostinger.CO_ID,
      Client.BrandId.Hostinger.COM_TR,
      Client.BrandId.Hostinger.AE,
    ];

    return (
      isSaleActive.value &&
      (isDomainOnlyUser.value || !activeResources.value.length) &&
      hasBrand(includedBrands)
    );
  });

  const isEligibleForHostingUpgradeOffer = computed(
    () =>
      isSaleActive.value &&
      !userHasOneHostingAndUpgraded.value &&
      !!upgradableHostingResources.value.length &&
      !isFromAnotherBrand.value &&
      !store.getters['orders/getIsFetchingUpgradedOrders'],
  );

  const isEligibleForSaleOffer = computed(
    () =>
      isEligibleForHostingPurchaseOffer.value ||
      isEligibleForHostingUpgradeOffer.value ||
      isEligibleForVpsSaleOffer.value,
  );

  const isEligibleForVpsSaleOffer = computed(
    () =>
      isSaleActive.value &&
      isVpsSaleAvailable.value &&
      isEligibleForKVMSale.value,
  );

  return {
    isSaleActive,
    isEligibleForHostingPurchaseOffer,
    isEligibleForHostingUpgradeOffer,
    isEligibleForSaleOffer,
    isEligibleForVpsSaleOffer,
  };
};
