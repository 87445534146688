import { computed } from 'vue';

import { useModalsStore } from '@/stores';
import type { ModalAction, ModalEmits } from '@/types';

export const useModal = (emits: ModalEmits = () => {}) => {
  const modalsStore = useModalsStore();

  const openModal = (action: ModalAction) => {
    modalsStore.openModal(action);
  };

  const currentData = computed((): any => modalsStore.currentData);
  const currentStep = computed(() => modalsStore.currentStep);
  const currentModalName = computed(() => modalsStore.currentModalName);
  const isFirstStep = computed(() => currentStep.value === 0);
  const isSecondStep = computed(() => currentStep.value === 1);

  const closeModal = () => {
    modalsStore.closeModal();
  };

  const setModalClosingType = (closingType: string) => {
    modalsStore.setModalClosingType(closingType);
  };

  const updateModalStep = (options: { step: number; data: any }) => {
    modalsStore.updateModalStep(options);
  };

  const goToNextStep = (step?: number) => emits('next-step', step);

  const goToPreviousStep = (step?: number) => emits('previous-step', step);

  const isAfterStep = (step: number) => currentStep.value > step;

  const isBeforeStep = (step: number) => currentStep.value < step;

  const close = () => emits('close');

  const setModalData = (data: Record<string, any>) => {
    modalsStore.setModalData(data);
  };

  return {
    openModal,
    closeModal,
    goToNextStep,
    goToPreviousStep,
    isAfterStep,
    isBeforeStep,
    close,
    setModalClosingType,
    currentData,
    currentModalName,
    currentStep,
    isFirstStep,
    isSecondStep,
    updateModalStep,
    setModalData,
  };
};
