<template>
  <transition name="fade-slow">
    <HCard
      v-if="openMaintenance && !inTransition"
      class="maintenance-notification"
      body-classes="h-p-0"
    >
      <template #body>
        <div class="d-flex h-px-24 h-pt-16">
          <HpIcon
            v-if="isMaintenanceActive()"
            warning
            icon="ic-timelapse"
            class="h-mr-8"
          />
          <HpIcon
            v-else
            success
            icon="billing-payment-history"
            class="h-mr-8"
          />
          <h4 class="h-mb-0 flex-grow-1">
            {{ getHeaderText }}
          </h4>

          <span class="float-right h-ml-8" @click="setAsSeen">
            <HpIcon gray icon="ic-clear" class="cursor-pointer" />
          </span>
        </div>

        <p class="h-px-24 h-pt-8">
          {{ getContentText }}
        </p>

        <HButtonV1
          primary
          text
          no-margin
          class="float-right h-mt-32"
          @click="openMaintenanceDetailsModal"
        >
          v2.more.information
        </HButtonV1>
      </template>
    </HCard>
  </transition>
</template>

<script>
//JSON-slugs-migrated
import HCard from '../../HCard';

import MaintenanceInProgressModal from '@/components/Modals/HModalFancy/MaintenanceInProgressModal.vue';
import PlannedMaintenanceModal from '@/components/Modals/HModalFancy/PlannedMaintenanceModal.vue';
import { useServerMaintenanceStore } from '@/stores';
import { toLocalTime } from '@/utils/helpers';
import hLocalStorage from '@/utils/services/localStorageService';

const STORAGE_PROP_NAME = 'seenMaintenanceDetails';

export default {
  name: 'MaintenanceNotification',
  components: {
    HCard,
  },
  data: () => ({
    activeIndex: null,
    inTransition: false,
  }),
  setup() {
    const serverMaintenanceStore = useServerMaintenanceStore();

    return {
      serverMaintenanceStore,
    };
  },
  computed: {
    allMaintenances() {
      return [
        ...this.serverMaintenanceStore.activeBuilderMaintenances,
        ...this.serverMaintenanceStore.scheduledBuilderMaintenances,
        ...this.serverMaintenanceStore.activeMaintenances,
        ...this.serverMaintenanceStore.scheduledMaintenances,
      ];
    },
    openMaintenance() {
      return this.notificationsToShow[this.activeIndex];
    },
    hostingPlanName() {
      if (!this.openMaintenance?.orders?.length) return '';

      const orderId = this.openMaintenance.orders[0];
      const order = this.$store.getters.getOrderByReferenceId(orderId);

      return order?.title || '';
    },
    notificationsToShow() {
      return (
        this.allMaintenances.filter((item) => !this.isAlreadyShown(item.id)) ||
        []
      );
    },
    getHeaderText() {
      if (this.isWebsiteBuilderMaintenance(this.openMaintenance)) {
        return this.$t('v2.planned.maintenance.for.website.builder.editor');
      }

      let title = 'v2.planned.maintenance.for.plantitle.server';

      if (this.isMaintenanceActive() && this.openMaintenance?.scheduledFor) {
        title = '{planTitle} server maintenance is in progress';
      } else if (this.isMaintenanceActive()) {
        title = 'Server maintenance for {planTitle} server';
      }

      return this.$t(title, { planTitle: this.hostingPlanName });
    },
    getContentText() {
      if (this.isWebsiteBuilderMaintenance(this.openMaintenance)) {
        return this.$t(
          'v2.maintenance.will.take.place.time.on.your.local.time',
          {
            date: toLocalTime(this.openMaintenance?.scheduledFor, 'LLL'),
          },
        );
      }

      if (this.isMaintenanceActive() && this.openMaintenance?.scheduledFor) {
        return this.$t(
          'v2.scheduled.maintenance.is.currently.in.progress.your.websites.might.be.unavailable.for.3060.minutes',
        );
      }

      if (this.isMaintenanceActive()) {
        return this.$t(
          'v2.currently.we.are.performing.server.maintenance.during.this.time.your.websites.might.be.unavailable',
        );
      }

      return this.$t('v2.maintenance.will.take.place.on.date.your.local.time', {
        date: toLocalTime(this.openMaintenance?.scheduledFor, 'LLL'),
      });
    },
  },
  methods: {
    isWebsiteBuilderMaintenance(maintenance) {
      return maintenance?.name
        ?.toLocaleLowerCase()
        .includes('hostinger website builder');
    },
    isMaintenanceActive(maintenanceId) {
      return !!this.serverMaintenanceStore.activeMaintenances.find(
        ({ id }) => id === maintenanceId || this.openMaintenance?.id,
      );
    },
    isAlreadyShown(maintenanceId) {
      return !!this.getStorageDetails().find(
        (item) =>
          item.id === maintenanceId &&
          ((this.isMaintenanceActive(item.id) && item.seenAsActive) ||
            !this.isMaintenanceActive(item.id)),
      );
    },
    openMaintenanceDetailsModal() {
      if (this.isWebsiteBuilderMaintenance(this.openMaintenance)) {
        return window.open(
          `https://statuspage.hostinger.com/incidents/${this.openMaintenance?.id}`,
          '_blank',
        );
      }

      this.$openModal({
        component: this.isMaintenanceActive()
          ? { MaintenanceInProgressModal }
          : { PlannedMaintenanceModal },
        data: {
          hostingName: this.hostingPlanName,
          startDate:
            this.openMaintenance?.startedAt ||
            this.openMaintenance?.scheduledFor,
        },
      });
    },
    setAsSeen() {
      this.setToStorage([
        ...this.getStorageDetails().filter(
          ({ id }) => id !== this.openMaintenance?.id,
        ),
        {
          id: this.openMaintenance?.id,
          seenAsActive: this.isMaintenanceActive(),
        },
      ]);

      this.activeIndex++;

      this.inTransition = true;
      setTimeout(() => (this.inTransition = false), 500);
    },
    clearOldStorageData() {
      const details = this.getStorageDetails().filter(({ id }) =>
        this.allMaintenances.find((item) => item.id === id),
      );

      if (!details.length) {
        hLocalStorage.deleteValue(STORAGE_PROP_NAME);

        return;
      }

      this.setToStorage(details);
    },
    setToStorage(data) {
      hLocalStorage.setValue(STORAGE_PROP_NAME, data);
    },
    getStorageDetails() {
      return hLocalStorage.getValue(STORAGE_PROP_NAME) || [];
    },
  },
  watch: {
    allMaintenances: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        if (value.length) this.activeIndex = 0;
        // Do cleanup when data is set from API response
        if (oldValue !== undefined) {
          this.clearOldStorageData();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance-notification {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  position: fixed;
  z-index: 10;
  top: 88px;
  right: 16px;
  max-width: 445px;
  width: 100%;
  padding: 8px;

  @media only screen and (max-width: 576px) {
    max-width: 300px;
  }
}
</style>
