import { registerElements } from '@hostinger/hpanel-components/dist/index.esm';

import { getBrandConfigById } from '@/utils/services/brandConfigService';

export const registerWebComponents = (t: Function, brandId: string) => {
  const brandConfig = getBrandConfigById(brandId);
  if (brandConfig.colorConfig) {
    return registerElements(brandConfig, t);
  }
  registerElements('hostinger', t);
};
