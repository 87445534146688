import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useOnboardingWordPressThemesStore } from '@/stores';
import type { IWordPressTemplate } from '@/types';
import { WORDPRESS_TEMPLATE_PAGE_BUILDER, Hosting } from '@/types';
import { preloadImages } from '@/utils/helpers/imagesHelper';

export const useOnboardingWordPressThemesV2 = () => {
  const onboardingWordPressThemesStore = useOnboardingWordPressThemesStore();
  const isTemplatesLoading = ref(false);

  const route = useRoute();

  const subscriptionId = computed(() => route.params.order_id as string);

  const fetchAndPreloadWordPressThemes = async () => {
    isTemplatesLoading.value = true;
    await onboardingWordPressThemesStore.fetchWordPressThemes({
      subscriptionId: subscriptionId.value,
    });
    isTemplatesLoading.value = false;
    if (!onboardingWordPressThemesStore.wordPressThemes) return;

    const images = Object.values(
      onboardingWordPressThemesStore.wordPressThemes,
    ).flatMap((theme) => theme.map(({ featuredImageUrl }) => featuredImageUrl));

    preloadImages(images);
  };

  const findKeysWithBeaverBuilder = (templates: {
    [key: string]: IWordPressTemplate[];
  }) => {
    const resultKeys = [];
    for (const key in templates) {
      const hasBeaverBuilder = templates[key].some(
        (item: IWordPressTemplate) =>
          item.pageBuilder === WORDPRESS_TEMPLATE_PAGE_BUILDER.BEAVER_BUILDER,
      );

      // Hosting Blog theme/template should be allowed in any case
      const isHostingerBlogTemplate = templates[key].some(
        (item: IWordPressTemplate) =>
          item.slug === Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG,
      );

      if (hasBeaverBuilder || isHostingerBlogTemplate) {
        resultKeys.push(key);
      }
    }

    return resultKeys;
  };

  return {
    isTemplatesLoading,
    findKeysWithBeaverBuilder,
    fetchAndPreloadWordPressThemes,
  };
};
