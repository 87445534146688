import type { App } from 'vue';

import type { ModalAction } from './types';

import { useModal } from '@/composables';
import { hotjar } from '@/utils/services/Hotjar';
import currencyService from '@/utils/services/currencyService';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService } from '@/utils/services/hToastrService';
import { $t, i18n } from '@/utils/services/i18nService';
import permissionsService from '@/utils/services/permissionsService';

export const setGlobals = (app: App) => {
  const { openModal } = useModal();

  app.config.globalProperties.$t = $t;
  app.config.globalProperties.i18n = i18n;
  app.config.globalProperties.$toastr = hToastrService;
  app.config.globalProperties.$logError = errorLogger.logError;
  app.config.globalProperties.i18n = i18n;
  app.config.globalProperties.$tc = i18n?.tc;
  app.config.globalProperties.$currency = currencyService;
  app.config.globalProperties.$hj = (name: string) => hotjar(name);
  app.config.globalProperties.$permissions = permissionsService;
  app.config.globalProperties.$openModal = (data: ModalAction) =>
    openModal(data);
};
