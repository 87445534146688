import {
  dnsRecordsBuilder,
  dnsRecordsBuilderNs,
} from '@/data/dns/dns-records-builder';
import { dnsRecordsHosting } from '@/data/dns/dns-records-hosting';
import { hDnsRepo } from '@/repositories';
import type {
  EntriConnectionCallback,
  EntriConfigModel,
  DnsRecord,
} from '@/types';
import { Entri } from '@/types';

const HOSTINGER_PROVIDER_ID_ON_ENTRI = 'Hostinger';
const ENTRI_TRIAL_CUTOFF = '2023-02-24T00:00:00Z';

type ServiceType =
  | Entri.ServiceType.WEBSITE_BUILDER
  | Entri.ServiceType.WEB_HOSTING;

const ENTRI_SCRIPT_SRC = 'https://cdn.goentri.com/entri.js';
const ENTRI_SCRIPT_LOAD_TIMEOUT = 1000 * 20;
const SERVICE_DNS_RECORD_MAP: any = {
  [Entri.ServiceType.WEBSITE_BUILDER]: {
    dnsRecords: dnsRecordsBuilderNs,
    isVariableReplacementRequired: false,
  },
  [Entri.ServiceType.WEB_HOSTING]: {
    dnsRecords: dnsRecordsHosting,
    isVariableReplacementRequired: true,
  },
};
const BUILDER_NOT_NS_RECORDS_PROVIDERS = ['GoDaddy', 'Google Domains'];

const loadEntriScriptCallback = (onReady: Function): HTMLScriptElement => {
  const doc: Document = document;
  const script: HTMLScriptElement = doc.createElement('script');
  const scriptLoadListener: (event: Event) => void = () => {
    onReady();

    script.removeEventListener('load', scriptLoadListener);
  };

  script.addEventListener('load', scriptLoadListener);

  script.src = ENTRI_SCRIPT_SRC;
  const parentElement: HTMLElement = doc.body;

  parentElement.appendChild(script);

  return script;
};

export const loadEntriScript = () =>
  new Promise<boolean>((resolve, reject) => {
    try {
      let scriptLoaded = false;

      window.setTimeout(() => {
        if (scriptLoaded) {
          return;
        }
        reject(new Error('Failed to load entri script'));
      }, ENTRI_SCRIPT_LOAD_TIMEOUT);

      loadEntriScriptCallback(() => {
        scriptLoaded = true;
        resolve(true);
      });
    } catch (error) {
      reject(error);
    }
  });

export const getAutoConnectDNSRecords = async ({
  domain,
  serviceType,
}: {
  domain: string;
  serviceType: ServiceType;
}) => {
  const serviceDNSConfig: any = SERVICE_DNS_RECORD_MAP[serviceType];

  if (!serviceDNSConfig) {
    throw new Error('serviceType does not have defined DNS records');
  }
  if (!serviceDNSConfig.isVariableReplacementRequired) {
    return serviceDNSConfig.dnsRecords;
  }

  const [{ data }, error] = await hDnsRepo.getDNSRecordConfiguration(domain);

  if (error) {
    throw error;
  }
  const { variables } = data;

  if (!variables.ip) {
    throw new Error(
      `Entri connection for ${domain} failed to start because ip was not provided in DNS configuration`,
    );
  }

  const dnsRecordsWithValues = serviceDNSConfig.dnsRecords.map(
    (dnsRecord: any) => ({
      type: dnsRecord.type,
      host: dnsRecord.host,
      priority: dnsRecord.priority,
      ttl: dnsRecord.ttl,
      value: dnsRecord.value.replace('%ip%', variables.ip),
    }),
  );

  return dnsRecordsWithValues;
};

export const replaceRecordsValuesWithDomain = (
  domain: string,
  dnsRecords: DnsRecord[],
) =>
  dnsRecords.map((record: DnsRecord) => ({
    ...record,
    value: record.value === '@' ? domain : record.value,
  }));

export const checkAutomaticConnectionAvailability = async ({
  domain,
  clientId,
  serviceType,
}: {
  domain: string;
  clientId: string;
  serviceType: ServiceType;
}) => {
  if (new Date() > new Date(ENTRI_TRIAL_CUTOFF)) {
    return { isAutomaticSetupAvailable: false };
  }
  if (typeof process.env.VITE_ENTRI_APPLICATION_ID !== 'string') {
    throw new Error('VITE_ENTRI_APPLICATION_ID not provided');
  }
  let isAutomaticSetupAvailable = false;
  const [entriJWTResponse, dnsRecords] = await Promise.all([
    hDnsRepo.getEntriJWT(),
    getAutoConnectDNSRecords({ domain, serviceType }),
    loadEntriScript(),
  ]);

  const [{ data: jwtData }, jwtRequestError] = entriJWTResponse;

  if (jwtRequestError) {
    throw jwtRequestError;
  }

  const { token } = jwtData;
  const prefilledDomain = domain;

  let config = {
    token,
    dnsRecords: replaceRecordsValuesWithDomain(prefilledDomain, dnsRecords),
    prefilledDomain,
    applicationId: process.env.VITE_ENTRI_APPLICATION_ID,
    hostRequired: false,
    userId: clientId,
    whiteLabel: {
      hideEntriLogo: true,
      logo: `${process.env.VITE_ASSET_HOST}/hostinger-logo-entri.png`,
      logoBackgroundColor: '#fff',
      theme: {
        fg: '#fff',
        bg: '#673de6',
      },
    },
  };

  const { setupType, provider } = await window.entri.checkDomain(
    prefilledDomain,
    config,
  );

  if (setupType === Entri.SetupType.AUTOMATIC) {
    isAutomaticSetupAvailable = provider !== HOSTINGER_PROVIDER_ID_ON_ENTRI;
  }

  if (
    serviceType === Entri.ServiceType.WEBSITE_BUILDER &&
    BUILDER_NOT_NS_RECORDS_PROVIDERS.includes(provider || '')
  ) {
    config = {
      ...config,
      dnsRecords: dnsRecordsBuilder,
    };
  }

  return { config, isAutomaticSetupAvailable, provider };
};

export const performEntriConnection = (config: EntriConfigModel) =>
  new Promise<string>((resolve, reject) => {
    const onEntriCloseCallback = (event: Event) => {
      window.removeEventListener(
        Entri.EventType.ONE_ENTRI_CLOSE,
        onEntriCloseCallback,
        false,
      );
      resolve((event as EntriConnectionCallback).detail.lastStatus);
    };

    try {
      window.addEventListener(
        Entri.EventType.ONE_ENTRI_CLOSE,
        onEntriCloseCallback,
        false,
      );
      window.entri.showEntri(config);
    } catch (error) {
      window.removeEventListener(
        Entri.EventType.ONE_ENTRI_CLOSE,
        onEntriCloseCallback,
        false,
      );
      reject(error);
    }
  });
