import type {
  IHostingAccount,
  IIpAccessRuleRequest,
  IIpAccessRule,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/ip-access-rules`,

  async getIps(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync<IIpAccessRule[]>(http.get(this.url(accountIdentifier)));
  },

  async postAllowedIps(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: IIpAccessRuleRequest,
  ) {
    return await hAsync<IIpAccessRule>(
      http.post(this.url(accountIdentifier), requestData, {
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async postDeniedIps(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: IIpAccessRuleRequest,
  ) {
    return await hAsync<IIpAccessRule>(
      http.post(this.url(accountIdentifier), requestData, {
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async deleteAllowedIps(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    pwKey: IIpAccessRule['pwkey'],
  ) {
    return await hAsync<IIpAccessRule>(
      http.delete(`${this.url(accountIdentifier)}/${pwKey}`),
    );
  },
};
