import type { NavigationGuard } from 'vue-router';

import { amplitudeV2 } from '@/plugins/amplitudeV2';
import { useFrontendSettingsStore, useVpsServerStore } from '@/stores';
import { AmplitudeEvent, HVps, Route } from '@/types';

const hVpsOnboardingGuard: NavigationGuard = async (to, from, next) => {
  const serverStore = useVpsServerStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!serverStore.servers.length) {
    frontendSettingsStore.setState('pageLoading', true);
    await serverStore.fetchServers();
    frontendSettingsStore.setState('pageLoading', false);
  }

  const hasActiveServer = serverStore.servers.some(
    ({ state }) => state === HVps.ServerState.RUNNING,
  );

  if (hasActiveServer) {
    amplitudeV2(AmplitudeEvent.Vps.ONBOARDING_START, {
      isNewClient: false,
    });

    next({
      name: Route.VpsOnboarding.SERVER_ONBOARDING_LOCATION,
      params: to.params,
      query: to.query,
    });

    return;
  }

  next();
};

export default hVpsOnboardingGuard;
