import { computed } from 'vue';
import { useRoute, type RouteRecordName, useRouter } from 'vue-router';

import { useBrands } from '@/composables/useBrands';
import { useGlobals } from '@/composables/useGlobals';
import { useProfile } from '@/composables/useProfile';
import { useReferrals } from '@/composables/useReferrals';
import { useProfileStore, useReferralStore } from '@/stores';
import type {
  KeyValueMap,
  ReferralPromotionType,
  ReferralPromotionTemplateType,
  AmplitudeLocation,
} from '@/types';
import {
  AmplitudeEvent,
  Country,
  HIcon,
  Referral,
  Route,
  SIMPLE_TIMER_VARIANTS,
} from '@/types';
import { isCurrentTimeWithinDates, mapKeyValue } from '@/utils/helpers';

const REFERRAL_ROUTES = [
  Route.Referral.REFFER_AND_EARN,
  Route.Referral.MY_REFERRALS,
] as RouteRecordName[];

export const useReferralsPromotion = () => {
  const { t, toastr, amplitudeV2 } = useGlobals();
  const route = useRoute();
  const router = useRouter();
  const { accountDaysRegistered } = useProfile();
  const { isFromAnotherBrand } = useBrands();
  const referralsStore = useReferralStore();
  const profileStore = useProfileStore();
  const { referralDiscountRate } = useReferrals();

  const COMMISSION_RATE_AND_AMOUNT_MAP: KeyValueMap = {
    [Referral.CommissionRate.COMMISSION_35]: Referral.CommissionAmount.USD_400,
    [Referral.CommissionRate.COMMISSION_40]: Referral.CommissionAmount.USD_450,
    [Referral.CommissionRate.COMMISSION_30]: Referral.CommissionAmount.USD_345,
    [Referral.CommissionRate.COMMISSION_20]: Referral.CommissionAmount.USD_200,
    default: Referral.CommissionAmount.USD_200,
  };

  const isWordCampPromotionActive = computed(
    () =>
      referralsStore.rewardRate !== Referral.CommissionRate.COMMISSION_20 &&
      referralsStore.rewardRate !== undefined,
  );

  const wordCampCommissionRate = computed(() => {
    if (referralsStore.rewardRate === undefined) {
      return Referral.CommissionRate.COMMISSION_20;
    }

    return referralsStore.rewardRate;
  });

  // TODO: move wordcamp promo to promotions once EP supports it
  const wordcampPromotion = computed<ReferralPromotionType | null>(() => {
    if (!isWordCampPromotionActive.value) return null;

    const startsAt = '2024-09-01T00:00:00Z';
    const endsAt = '2025-03-31T23:59:59Z';

    if (!isCurrentTimeWithinDates({ startsAt, endsAt })) {
      return null;
    }

    return {
      commissionRate: wordCampCommissionRate.value,
      key: Referral.PromotionalTopBannerKey.WORDCAMP,
      promotionType: Referral.PromotionType.ALL_PLANS,
      banner: {
        placements: [],
        title: t('v2.referrals.wordcamp.offer', {
          commissionInDollars: mapKeyValue(
            COMMISSION_RATE_AND_AMOUNT_MAP,
            wordCampCommissionRate.value,
          ),
          friendDiscountPercentage: `${referralDiscountRate.value * 100}%`,
        }),
        backgroundColor: 'dark',
        textColor: 'white',
        noTimer: true,
        isCloseButtonVisible: false,
        isButtonVisible: false,
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'teal',
        },
      },
      homepageButton: {
        text: t('v2.refer.and.earn.up.to.commissionInDollars.usd', {
          commissionInDollars: mapKeyValue(
            COMMISSION_RATE_AND_AMOUNT_MAP,
            wordCampCommissionRate.value,
          ),
        }),
        subtitle: t(
          'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
          {
            friendCommissionPercentage: `${referralDiscountRate.value * 100}%`,
          },
        ),
        isAnimated: false,
      },
      topBarLink: {
        isAnimated: true,
      },
    };
  });

  const promotions = computed<ReferralPromotionTemplateType[]>(() => {
    if (!referralsStore.activePromotion) {
      return [];
    }

    const commissionPercentage = `${
      referralsStore.activePromotion.rewardRate * 100
    }%`;

    const commissionInDollars = mapKeyValue(
      COMMISSION_RATE_AND_AMOUNT_MAP,
      referralsStore.activePromotion.rewardRate,
    );

    return [
      {
        key: Referral.PromotionalTopBannerKey.BLACK_FRIDAY_40,
        banner: {
          title: t('v2.referrals.black_friday_40.offer', {
            commissionOpenTag: '<strong>',
            commissionCloseTag: '</strong>',
            paidOutOpenTag: '<strong>',
            paidOutCloseTag: '</strong>',
            commissionInDollars,
          }),
          placements: [],
          backgroundColor: 'dark',
          textColor: 'white',
          noTimer: true,
          isCloseButtonVisible: false,
          isButtonVisible: false,
          icon: {
            name: HIcon.ICON_BADGE_DISCOUNT,
            color: 'danger',
          },
        },
        homepageButton: {
          text: t('v2.refer.and.earn.up.to.commissionInDollars.usd', {
            commissionInDollars,
          }),
          subtitle: t(
            'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
            {
              friendCommissionPercentage: `${
                referralDiscountRate.value * 100
              }%`,
            },
          ),
          isAnimated: true,
        },
        topBarLink: {
          isAnimated: true,
          color: 'dark',
          backgroundColor: 'gray-light',
          hoverBackgroundColor: 'gray-border',
        },
        pricingCard: {
          withSaleDiscountTag: true,
          commissionText: t('v2.referrals.black_friday.commission_text', {
            commissionPercentage,
          }),
        },
      },
      {
        key: Referral.PromotionalTopBannerKey.BLACK_FRIDAY_30,
        banner: {
          title: t('v2.referrals.black_friday_30.offer', {
            commissionOpenTag: '<strong>',
            commissionCloseTag: '</strong>',
            paidOutOpenTag: '<strong>',
            paidOutCloseTag: '</strong>',
            commissionInDollars,
          }),
          placements: [],
          backgroundColor: 'dark',
          textColor: 'white',
          noTimer: true,
          isCloseButtonVisible: false,
          isButtonVisible: false,
          icon: {
            name: HIcon.ICON_BADGE_DISCOUNT,
            color: 'danger',
          },
        },
        homepageButton: {
          text: t('v2.refer.and.earn.up.to.commissionInDollars.usd', {
            commissionInDollars,
          }),
          subtitle: t(
            'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
            {
              friendCommissionPercentage: `${
                referralDiscountRate.value * 100
              }%`,
            },
          ),
          isAnimated: true,
        },
        topBarLink: {
          isAnimated: true,
          color: 'dark',
          backgroundColor: 'gray-light',
          hoverBackgroundColor: 'gray-border',
        },
        pricingCard: {
          withSaleDiscountTag: true,
          commissionText: t('v2.referrals.black_friday.commission_text', {
            commissionPercentage,
          }),
        },
      },
      {
        key: Referral.PromotionalTopBannerKey.ALL_PLANS,
        promotionType: Referral.PromotionType.ALL_PLANS,
        banner: {
          title: t(
            'Earn up to {openTag}400 USD{closeTag} per referral. Your referral will get a 20% discount too.',
            {
              openTag: '<strong>',
              closeTag: '</strong>',
            },
          ),
          placements: [
            Route.Websites.WEBSITE_DASHBOARD,
            Route.Websites.BUILDER_DASHBOARD,
            Route.Base.SERVERS,
          ],

          backgroundColor: 'meteorite-dark',
          textColor: 'white',
          timerVariant: SIMPLE_TIMER_VARIANTS.WHITE,
          isCloseButtonVisible: !isInReferralsRoute.value,
          isButtonVisible: !isInReferralsRoute.value,
          icon: {
            name: HIcon.ICON_BADGE_DISCOUNT,
            color: 'white',
          },
          closeButtonColor: 'white',
        },
      },
    ];
  });

  const isInReferralsRoute = computed(() =>
    REFERRAL_ROUTES.includes(route.name as RouteRecordName),
  );

  const isBannerVisibleInRoute = (promotion: ReferralPromotionType) => {
    if (isInReferralsRoute.value) {
      return true;
    }

    const placements = promotion.banner.placements as RouteRecordName[];

    return placements.includes(route.name as RouteRecordName);
  };

  const isNonEligibleBrand = computed(
    () => isFromAnotherBrand.value || profileStore.country === Country.Code.RU,
  );

  const isClientEligibleForPromotion = computed(() => {
    if (
      (accountDaysRegistered.value < 7 && !isWordCampPromotionActive.value) ||
      isNonEligibleBrand.value ||
      profileStore.isAccessManager
    ) {
      return false;
    }

    return true;
  });

  const getActivePromotionType = (eligiblePlans: Referral.EligiblePlan[]) => {
    const isWebHostingPlan = eligiblePlans.includes(
      Referral.EligiblePlan.WEB_HOSTING,
    );
    const isCloudHostingPlan = eligiblePlans.includes(
      Referral.EligiblePlan.CLOUD_HOSTING,
    );
    const isVpsPlan = eligiblePlans.includes(Referral.EligiblePlan.VPS_HOSTING);

    if (!isWebHostingPlan && isCloudHostingPlan && isVpsPlan) {
      return Referral.PromotionType.CLOUD_VPS;
    }

    return Referral.PromotionType.ALL_PLANS;
  };

  const activePromotion = computed<ReferralPromotionType | null>(() => {
    if (!isClientEligibleForPromotion.value) return null;

    if (wordcampPromotion.value) {
      return wordcampPromotion.value;
    }

    if (!referralsStore.activePromotion) return null;

    const promotion = promotions.value.find(
      ({ key }) => key === referralsStore.activePromotion?.key,
    );

    if (!promotion) return null;

    return {
      ...promotion,
      commissionRate: referralsStore.activePromotion.rewardRate,
      promotionType: getActivePromotionType(
        referralsStore.activePromotion.eligiblePlans,
      ),
    };
  });

  const isActivePromotionLoaded = computed(
    () => referralsStore.activePromotion !== undefined,
  );

  const showReferralNudgeToastr = (
    location: AmplitudeLocation.Base.RENEWAL | AmplitudeLocation.Base.UPGRADE,
  ) => {
    if (profileStore.isAccessManager || isNonEligibleBrand.value) {
      return;
    }

    amplitudeV2(AmplitudeEvent.Referral.REFERRALS_NUDGE_SHOWN, {
      nudge: location,
    });

    toastr.i(
      t('v2.referrals.nudge.title', {
        commissionAmountInDollars: activePromotionCommissionInDollars.value,
        discountPercentage: 20,
      }),
      {
        imagePath: '@/images/referrals/gift-nudge-image.png',
        buttons: [
          {
            text: t('v2.referrals.nudge.button'),
            props: { primary: true },
            callback: async () => {
              referralsStore.referralsEntryLocation = location;

              router.push({ name: Route.Referral.REFERRALS });
            },
          },
        ],
      },
    );
  };
  const isCSATPromoActive = computed(() => {
    if (isNonEligibleBrand.value) return false;

    const SKIPPED_ROUTES = [
      Route.Referral.REFERRALS,
      Route.DeveloperTools.DASHBOARD,
    ];

    const isInSkippedRoutes = route.matched.some(({ name }) =>
      SKIPPED_ROUTES.includes(name as Route.Referral | Route.DeveloperTools),
    );

    return !isInSkippedRoutes;
  });

  const activePromotionCommissionInDollars = computed(() =>
    mapKeyValue(
      COMMISSION_RATE_AND_AMOUNT_MAP,
      activePromotion.value?.commissionRate ||
        Referral.CommissionRate.COMMISSION_20,
    ),
  );

  return {
    isInReferralsRoute,
    activePromotion,
    isCSATPromoActive,
    activePromotionCommissionInDollars,
    isActivePromotionLoaded,
    isBannerVisibleInRoute,
    showReferralNudgeToastr,
  };
};
