import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';

import { websiteRepo } from '@/repositories';
import { useWebsiteTagsStore } from '@/stores';
import { Header, type ExtendedProWebsite } from '@/types';
import type { AssignedTags } from '@/types/models/websiteTagModel';

export const useAssignTagStore = defineStore('assignTagStore', () => {
  const websiteTagsStore = useWebsiteTagsStore();

  const assignedTags = ref<AssignedTags[]>([]);

  const websitesLoadingStatus = reactive<Record<string, boolean>>({});
  const optimisticTagStatus = reactive<Record<string, boolean>>({});

  const getIsLoadingForWebsite = (targetWebsite: string) =>
    websitesLoadingStatus[targetWebsite] || false;

  const setTagsLocal = (
    targetWebsite: string,
    tags: string[] = [],
    replace = false,
  ) => {
    const item = assignedTags.value.find(
      ({ website }: AssignedTags) => website === targetWebsite,
    );

    if (item && !replace) {
      item.tags.push(...tags);

      return;
    }
    assignedTags.value.push({ website: targetWebsite, tags });
  };

  const removeTagsLocal = (targetWebsite: string, tag: string) => {
    const itemIndex = assignedTags.value.findIndex(
      ({ website }: AssignedTags) => website === targetWebsite,
    );

    if (itemIndex === -1) return;

    const tagIndex = assignedTags.value[itemIndex].tags.indexOf(tag);
    if (tagIndex === -1) return;

    assignedTags.value[itemIndex].tags.splice(tagIndex, 1);
  };

  const getWebsiteHeaders = (websiteObject: ExtendedProWebsite) => ({
    [Header.USERNAME]: websiteObject.username,
    [Header.DOMAIN]: websiteObject.website,
  });

  const assignTagToWebsite = async (
    websiteObject: ExtendedProWebsite,
    tag: string,
  ) => {
    const { website } = websiteObject;
    websitesLoadingStatus[website] = true;
    optimisticTagStatus[tag] = true;
    const headers = getWebsiteHeaders(websiteObject);
    const [, error] = await websiteRepo.assignTag(tag, headers);

    websitesLoadingStatus[website] = false;
    delete optimisticTagStatus[tag];

    if (error) return;

    setTagsLocal(website, [tag]);
  };

  const removeTagFromWebsite = async (
    websiteObject: ExtendedProWebsite,
    tag: string,
  ) => {
    const { website } = websiteObject;

    removeTagsLocal(website, tag);
    websitesLoadingStatus[website] = true;
    optimisticTagStatus[tag] = false;
    const headers = getWebsiteHeaders(websiteObject);
    const [, error] = await websiteRepo.deleteAssignedTag(tag, headers);
    if (error) {
      // Add back removed tag if error occurs during API call
      setTagsLocal(website, [tag]);
    }
    websitesLoadingStatus[website] = false;
    delete optimisticTagStatus[tag];
  };

  const getLocalTagsByWebsite = (targetWebsite: string) => {
    const item = assignedTags.value.find(
      ({ website }: AssignedTags) => website === targetWebsite,
    );

    if (item) {
      // Do not show deleted tags
      return item.tags.filter((tagName) =>
        websiteTagsStore.tags.find((tag) => tag.id === tagName),
      );
    }

    return [];
  };

  const updateTagNames = (updatedTags: { id: string; newName: string }[]) => {
    updatedTags.forEach(({ id, newName }) => {
      assignedTags.value.forEach((item) => {
        const tagIndex = item.tags.indexOf(id);
        if (tagIndex !== -1) {
          item.tags[tagIndex] = newName;
        }
      });
    });
  };

  const $reset = () => {
    assignedTags.value = [];
    Object.keys(websitesLoadingStatus).forEach((key) => {
      websitesLoadingStatus[key] = false;
    });
  };

  return {
    $reset,
    assignTagToWebsite,
    removeTagFromWebsite,
    getLocalTagsByWebsite,
    setTagsLocal,
    getIsLoadingForWebsite,
    optimisticTagStatus,
    updateTagNames,
  };
});
