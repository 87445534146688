import type { CPanelWordpress } from '@/types';

const CPanelWordPressData: CPanelWordpress[] = [];

for (let iterate: number = 1; iterate <= 12; iterate++) {
  const newWordpressData: CPanelWordpress = {
    image: 'WordPress',
    title: `Website Title ${iterate}`,
    description: `Description Wordpress Website ${iterate}`,
  };

  CPanelWordPressData.push(newWordpressData);
}

export { CPanelWordPressData };

export const applicationDetails = {
  appid: 'wordpress',
  softid: 26,
  name: 'WordPress',
  description:
    'WordPress is a tool that makes it easy to manage critical aspects of your website – without needing to know anything about programming. Choose from hundreds of beautiful designs and customize them for your business site, blog, or portfolio.',
  fields: {
    language: {
      options: {
        en: 'English',
        ar: 'Arabic',
        sq: 'Albanian',
        bg_BG: 'Bulgarian',
        ca: 'Catalan',
        zh_CN: 'Chinese(Simplified)',
        zh_TW: 'Chinese(Traditional)',
        hr: 'Croatian',
        cs_CZ: 'Czech',
        da_DK: 'Danish',
        nl_NL: 'Dutch',
        fi: 'Finnish',
        fr_FR: 'French',
        de_DE: 'German',
        el: 'Greek',
        he_IL: 'Hebrew',
        hu_HU: 'Hungarian',
        id_ID: 'Indonesian',
        it_IT: 'Italian',
        ja: 'Japanese',
        ko_KR: 'Korean',
        nb_NO: 'Norwegian',
        fa_IR: 'Persian',
        pl_PL: 'Polish',
        pt_PT: 'Portuguese',
        pt_BR: 'Portuguese-BR',
        ro_RO: 'Romanian',
        ru_RU: 'Russian',
        sl_SI: 'Slovenian',
        es_ES: 'Spanish',
        sv_SE: 'Swedish',
        th: 'Thai',
        tr_TR: 'Turkish',
        uk: 'Ukrainian',
      },
    },
  },
};
