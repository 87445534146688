export namespace Error {
  export enum Domain {
    DOMAIN_NOT_REGISTERED = 2036,
    ERROR_CODE_IDN_NOT_SUPPORTED = 2038,
    UNDER_10_DAYS_LOCK = 2049,
    UNDER_60_DAYS_LOCK = 2060,
  }
  export enum AccessRequest {
    CLIENT_NOT_FOUND = 6202,
    CLIENT_ON_DIFFERENT_RESELLER = 6203,
    MAXIMUM_COUNT_OF_MANAGER = 6204,
  }

  export enum StatusCode {
    FORBIDDEN = 403,
  }

  export enum Email {
    ACCOUNT_ALREADY_EXISTS = 'ERR_ACC_EXISTS',
    INSECURE_PASSWORD = 'ERR_PASSWORD_INSECURE',
    ALIAS_DUPLICATE = 'ERR_ALIAS_EXISTS',
  }
}
