<script lang="ts" setup>
import { watch } from 'vue';

import HVideo from '@/components/HVideo.vue';
import { useGlobals, useModal, useScreen } from '@/composables';
import { useHotspotStore } from '@/stores';

const hotspotStore = useHotspotStore();

const { t } = useGlobals();
const { closeModal } = useModal();
const { isLarge } = useScreen();

const handleClose = () => {
  closeModal();

  hotspotStore.closeHotspot('navigationHotspot');
};

watch(
  () => isLarge.value,
  (isLarge) => {
    if (!isLarge) {
      closeModal();
    }
  },
);
</script>

<template>
  <div class="explore-navigation-modal">
    <h3 class="text-heading-2 mb-16">
      {{ t('New menu: get things done faster') }}
    </h3>
    <p class="explore-navigation-modal__subtitle text-dark">
      {{ t('Easily find what you’re looking for.') }}
    </p>

    <HVideo
      class="explore-navigation-modal__video"
      autoplay
      loop
      muted
      playsinline
      poster="@/images/navigation-intro-mobile-poster.jpg"
      src="@/videos/navigation-intro-mobile.mp4"
      type="video/mp4"
    />

    <HButton class="explore-navigation-modal__action" @click="handleClose">
      {{ t('Got it') }}
    </HButton>
  </div>
</template>

<style lang="scss">
.video {
  width: 100%;
  margin-bottom: 16px;
}

.explore-navigation-modal {
  text-align: center;

  &__video {
    width: 200px;
    margin-bottom: 16px;
  }

  &__subtitle {
    @extend .text-dark !optional;

    margin-bottom: 16px;
  }

  &__action:not(.s) {
    width: 100%;
  }
}
</style>
