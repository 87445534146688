import store from '@/store/index';

type WordpressStagingPusherActionData = {
  body: string;
  variables: {
    staging_domain: string;
    error: string | null;
    domain: string;
    status: string;
  };
};
export default (event: string, data: WordpressStagingPusherActionData) => {
  const variables = data.variables;

  if (!variables.domain || !variables.status || !variables.staging_domain) {
    return;
  }

  store.dispatch('hostingStagingListIndex', {
    domain: variables?.domain,
    directory: '',
    withNotification: true,
    stagingDomain: variables.staging_domain,
    notificationType: data?.variables?.status,
    eventName: event,
  });
};
