import type {
  ReportStateResponse,
  ReportStateRequest,
  ReportListResponse,
  GetReportTemplateResponse,
  CreateReportRequest,
  ReportTemplateRequest,
  ScheduleReportRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/report`,
  whApiUrl: `${process.env.VITE_API_WH_API}/api/hapi/v1`,
  async postGenerateReport({ params, headers }: CreateReportRequest) {
    return await hAsync(
      http.post(`${this.url}/website/generate`, params, {
        headers: { ...headers },
      }),
    );
  },

  async getReportState({ params, headers }: ReportStateRequest) {
    return await hAsync<ReportStateResponse>(
      http.get(`${this.url}/website/state`, {
        params: { directory: params.directory },
        headers: {
          ...headers,
        },
      }),
    );
  },

  async postSchedule({ params, headers }: ScheduleReportRequest) {
    return await hAsync(
      http.post(`${this.url}/website/schedule`, params, {
        headers,
      }),
    );
  },

  async getList(params: { page: number; perPage: number }) {
    return await hAsync<ReportListResponse>(
      http.get(`${this.url}/website/list`, {
        params,
      }),
    );
  },

  async deleteReport(params: { reportId: string }) {
    return await hAsync(
      http.delete(`${this.url}/website`, {
        params,
      }),
    );
  },

  async getTemplates() {
    return await hAsync<GetReportTemplateResponse[]>(
      http.get(`${this.url}/website/template`),
    );
  },

  async postTemplate({ params }: { params: ReportTemplateRequest }) {
    return await hAsync<boolean>(
      http.post(`${this.url}/website/template`, params, {
        hideToastr: true,
      }),
    );
  },

  async editTemplate({
    id,
    params,
  }: {
    id: number;
    params: ReportTemplateRequest;
  }) {
    return await hAsync<boolean>(
      http.put(`${this.url}/website/template/${id}`, params, {
        hideToastr: true,
      }),
    );
  },

  async deleteTemplate(id: number) {
    return await hAsync<boolean>(
      http.delete(`${this.url}/website/template/${id}`),
    );
  },
};
