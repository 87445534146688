import dayjs from 'dayjs';
import { ref, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useRouter } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { useHostingMigrationsStore } from '@/stores';
import type { IMigration, IMigrationCapsule } from '@/types';
import { Route, MigrationStatus, HIcon } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

const DAYS_COMPLETED_AND_REJECTED_CAPSULE_SHOWN = 7;

export const useMigrationCapsule = (
  defaultMigrationRequest: IMigration | null = null,
) => {
  const hostingMigrationsStore = useHostingMigrationsStore();
  const migrationRequest = ref<IMigration | null>(defaultMigrationRequest);

  const { t } = useGlobals();
  const router = useRouter();

  hostingMigrationsStore.fetchExistingHiddenMigrationCapsules();

  const isMigrationCapsuleVisible = computed(() => {
    if (!migrationRequest.value) return false;

    if (
      !migrationRequest.value ||
      hostingMigrationsStore.hiddenMigrationCapsules?.[
        MigrationStatus.COMPLETED
      ].includes(migrationRequest.value.id)
    ) {
      return false;
    }

    const { status, finishedAt } = migrationRequest.value;

    const isTimeLimitShown = [
      MigrationStatus.COMPLETED,
      MigrationStatus.REJECTED,
    ].includes(status);

    const isStatusShouldBeShown = [
      MigrationStatus.IN_PROGRESS,
      MigrationStatus.UPDATED,
      MigrationStatus.PENDING,
    ].includes(status);

    if (
      status === MigrationStatus.REJECTED &&
      hostingMigrationsStore.hiddenMigrationCapsules?.[
        MigrationStatus.REJECTED
      ].includes(migrationRequest.value.id)
    ) {
      return false;
    }

    if (isStatusShouldBeShown) {
      return true;
    }

    if (isTimeLimitShown) {
      const localFinishedAt = dayjs.utc(finishedAt).local();
      const localCurrent = dayjs();
      const daysDiff = localFinishedAt.diff(localCurrent, 'day', true);
      const daysPassed = Math.abs(daysDiff);

      return daysPassed < DAYS_COMPLETED_AND_REJECTED_CAPSULE_SHOWN;
    }

    return false;
  });

  const getMigrationCapsule: ComputedRef<IMigrationCapsule> = computed(() => {
    const migration = migrationRequest.value;

    const MIGRATION_CAPSULE_MAP = {
      [MigrationStatus.COMPLETED]: {
        props: {
          text: t('v2.migration.completed'),
          fullText: t(
            'v2.migration.complete.you.can.now.edit.and.access.your.website',
          ),
          iconColor: 'success',
          icon: HIcon.ICON_CHECK_CIRCLE,
          isCapsuleCloseVisible: true,
        },
        action: () => {},
        closeAction: () => {
          if (!migration) return;

          hostingMigrationsStore.setHiddenMigrationCapsules(
            migration.id,
            MigrationStatus.COMPLETED,
          );
        },
      },
      [MigrationStatus.REJECTED]: {
        props: {
          text: t('v2.migration.rejected'),
          fullText: t(
            'v2.migration.rejected.we.were.unable.to.complete.your.migration.request',
          ),
          iconColor: 'danger',
          icon: HIcon.ICON_CANCEL,
          actionText: t('v2.more.details'),
          isCapsuleCloseVisible: true,
        },
        action: () => {
          router.push({ name: Route.Websites.MIGRATIONS_REQUESTS });
        },
        closeAction: () => {
          if (!migration) return;

          hostingMigrationsStore.setHiddenMigrationCapsules(
            migration.id,
            MigrationStatus.REJECTED,
          );
        },
      },
      [MigrationStatus.UPDATED]: {
        props: {
          text: t('v2.migration.in.progress'),
          fullText: t(
            'v2.migration.in.progress.process.might.take.approximately.24.hours',
          ),
          iconColor: 'gray',
          icon: HIcon.ICON_TIMELAPSE,
        },
        action: () => {},
      },
      [MigrationStatus.PENDING]: {
        props: {
          text: t('v2.migration.in.progress'),
          fullText: t(
            'v2.migration.in.progress.process.might.take.approximately.24.hours',
          ),
          iconColor: 'gray',
          icon: HIcon.ICON_TIMELAPSE,
        },
        action: () => {},
      },
      [MigrationStatus.IN_PROGRESS]: {
        props: {
          text: t('v2.migration.in.progress'),
          fullText: t(
            'v2.migration.in.progress.process.might.take.approximately.24.hours',
          ),
          iconColor: 'gray',
          icon: HIcon.ICON_TIMELAPSE,
        },
        action: () => {},
      },
      default: {
        text: '',
        iconColor: null,
        icon: null,
      },
    };

    return mapKeyValue(MIGRATION_CAPSULE_MAP, migration?.status || '');
  });

  return {
    isMigrationCapsuleVisible,
    getMigrationCapsule,
  };
};
