import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import {
  websiteRepo,
  referralRepo,
  wordpressRepo,
  accessRepo,
} from '@/repositories';
import { router } from '@/router';
import { useProfileStore } from '@/stores';
import type {
  WordpressBulkUpdateWebsite,
  WordpressBulkUpdateStatusParams,
  WordPressPreset,
  WordpressTheme,
  SubmitRequestAccessParams,
  WordPressPresetPlugin,
  ProWebsiteType,
  CatalogItemPrice,
} from '@/types';
import { Status, AccountAccess, Header } from '@/types';
import { timeout } from '@/utils/helpers';

type InitialValue = {
  selectedTheme: WordpressTheme;
  selectedPlan: CatalogItemPrice;
};

const initialValue: InitialValue = {
  selectedTheme: {
    featuredImageUrl: '',
    link: '',
    logo: '',
    slug: '',
    title: '',
    palettes: [],
  },
  selectedPlan: {
    basePrice: 0,
    currencyCode: '',
    description: '',
    externalName: '',
    firstPeriodPrice: 0,
    firstYearPrice: 0,
    id: '',
    metadata: { upgradesTo: [] },
    name: '',
    period: 0,
    periodUnit: '',
    price: 0,
    pricingModel: '',
    status: '',
    upgradePrice: 0,
  },
};

export const useHostingerProStore = defineStore(
  'hostingerProStore',
  () => {
    const { isAccessManager } = storeToRefs(useProfileStore());
    const { account } = storeToRefs(useProfileStore());
    const { hasAccessToDeveloperToolsDashboard } = useDeveloperTools();

    const selectedPlan = ref<CatalogItemPrice>(initialValue.selectedPlan);
    const recipientEmail = ref('');
    const isNewClient = ref<boolean | null>(null);
    const proMembershipDate = ref<string | null>(null);
    const isProMember = ref<boolean | null>(null);
    const websites = ref<ProWebsiteType[]>([]);
    const isWebsitesBulkActionsInProgress = ref(false);
    const updateActionsList = ref<WordpressBulkUpdateWebsite[]>([]);
    const selectedTheme = ref<WordpressTheme>({
      ...initialValue.selectedTheme,
    });
    const presetName = ref('');
    const presetsList = ref<WordPressPreset[] | []>([]);
    const previewTheme = ref<WordpressTheme>({
      ...initialValue.selectedTheme,
    });
    const selectedPreset = ref('');
    const selectedPlugins = ref<WordPressPresetPlugin[] | []>([]);
    const referralUrl = ref<string | null>('');
    const foundThemes = ref<WordpressTheme[]>([]);

    const currentStep = computed(() =>
      String(router.currentRoute.value.meta.step).slice(0, 1),
    );

    const resetAllData = () => {
      resetMainDisplayedData();
      resetPresetsState();
      resetProUserInfo();
    };

    const resetPresetsState = () => {
      selectedTheme.value = initialValue.selectedTheme;
      selectedPreset.value = '';
      selectedPlugins.value = [];
    };
    const resetProUserInfo = () => {
      proMembershipDate.value = null;
      isProMember.value = null;
      referralUrl.value = '';
    };

    const resetMainDisplayedData = () => {
      selectedPlan.value = initialValue.selectedPlan;
      recipientEmail.value = '';
      isNewClient.value = null;
      websites.value = [];
      isWebsitesBulkActionsInProgress.value = false;
    };
    const setWebsites = (proWebsites: ProWebsiteType[]) => {
      websites.value = proWebsites;
    };
    const fetchWebsitesList = async () => {
      const [
        {
          data: { data },
        },
        err,
      ] = await websiteRepo.getProList();

      if (err) return;
      websites.value = data;

      return data;
    };
    const fetchBulkUpdateStatuses = async (
      params: WordpressBulkUpdateStatusParams,
    ) => {
      if (!params.websites?.length) return;

      isWebsitesBulkActionsInProgress.value = true;

      const [{ data }, err] = await wordpressRepo.postBulkUpdateStatus(params);

      if (err) {
        isWebsitesBulkActionsInProgress.value = false;

        return;
      }
      const isWebsitesBulkActionsInProgressValue = data?.some(
        (item: WordpressBulkUpdateWebsite) =>
          [Status.InProgress, Status.Scheduled].includes(item.state),
      );

      updateActionsList.value = data;

      isWebsitesBulkActionsInProgress.value =
        isWebsitesBulkActionsInProgressValue;

      if (!isWebsitesBulkActionsInProgress.value) return;

      await timeout(5000);

      await fetchBulkUpdateStatuses(params);
    };

    const postAccessRequest = async (params: SubmitRequestAccessParams) => {
      const { email, inviteUser, proPanel, accessLevel } = params;
      const isPurchasesAllowed =
        accessLevel === AccountAccess.AccessLevel.PURCHASE;
      const isRequesterProPanelUser = proPanel;
      const shouldInviteUser = inviteUser;

      return await accessRepo.postRequest(
        {
          email,
          shouldInviteUser,
          isRequesterProPanelUser,
          isPurchasesAllowed,
        },
        { hideToastr: true },
      );
    };

    const fetchIsProMember = async ({ isForced = false } = {}) => {
      if (isProMember.value !== null && !isForced) return;

      const [{ data }, err] = await referralRepo.getIsProMember();

      if (err) return;
      proMembershipDate.value = data.createdAt;
      referralUrl.value = data.referralUrl;
      isProMember.value = !!data.referralCode;
    };
    const resetPresetValue = () => {
      selectedTheme.value = {
        featuredImageUrl: '',
        link: '',
        slug: '',
        logo: '',
        title: '',
        palettes: [],
      };
      selectedPlugins.value = [];
      presetName.value = '';
    };

    const getIsStepActive = (step: number) =>
      currentStep.value === String(step);
    const getIsStepCompleted = (step: number) =>
      currentStep.value > String(step);

    const fetchPresets = async () => {
      if (!account.value) {
        return;
      }

      const [{ data }, err] = await wordpressRepo.getWordpressCollection({
        [Header.CLIENT_ID]: account.value.id,
      });
      if (err) return;
      presetsList.value = data;
    };

    const canUsePresetsInOnboarding = computed(
      () => !isAccessManager.value && hasAccessToDeveloperToolsDashboard.value,
    );

    const deletePreset = async (presetId: string) => {
      const [_, err] = await wordpressRepo.deleteWordpressCollection({
        collectionId: presetId,
      });

      if (err) return;

      presetsList.value = presetsList.value.filter(
        (preset) => preset.id !== presetId,
      );
    };

    return {
      selectedPlan,
      recipientEmail,
      isNewClient,
      proMembershipDate,
      isProMember,
      resetPresetValue,
      websites,
      setWebsites,
      foundThemes,
      isWebsitesBulkActionsInProgress,
      selectedTheme,
      selectedPreset,
      presetName,
      selectedPlugins,
      referralUrl,
      previewTheme,
      resetAllData,
      resetMainDisplayedData,
      resetPresetsState,
      resetProUserInfo,
      fetchWebsitesList,
      presetsList,
      fetchBulkUpdateStatuses,
      fetchIsProMember,
      getIsStepActive,
      postAccessRequest,
      updateActionsList,
      getIsStepCompleted,
      fetchPresets,
      canUsePresetsInOnboarding,
      deletePreset,
    };
  },
  {
    persist: { storage: localStorage, key: 'hostinger-pro-store' },
  },
);
