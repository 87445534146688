<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

type Props = {
  to: RouteLocationRaw;
  active?: boolean;
  target?: string;
};

defineProps<Props>();

interface Emits {
  (eventName: 'click'): void;
}
const emit = defineEmits<Emits>();
</script>

<template>
  <RouterLink
    v-if="active"
    :to="to"
    :active="active"
    :target="target"
    @click="emit('click')"
  >
    <slot />
  </RouterLink>
  <slot v-else />
</template>
