import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { cPanelEmailHostingPackages } from '@/data/cpanel/cPanelEmailHosting';
import store from '@/store/index';
import type { CPanelDetailsState } from '@/types';
import { CpanelStoreEnum, StoreEnum } from '@/types';
import type { CPanelDomainModel } from '@/types/models/cpanel/cPanelModels';

export const useCpanel = () => {
  const route = useRoute();

  const serviceId = computed(
    () => store.getters.getCPanelOrderByDomain(route.params.domain)?.serviceId,
  );

  const cpanelDetails = computed<CPanelDetailsState>(
    () => store.getters['cpanel/getCPanelDetails'],
  );

  const cpanelPlan = computed(() => cpanelDetails.value?.data.plan);

  const orderDetails = computed(() =>
    store.getters.getCPanelOrderByDomain(route.params.domain),
  );

  const activeOrders = computed(() => store.getters.getCPanelActiveOrders);

  const cPanelDomains = computed(
    (): CPanelDomainModel =>
      store.state.hosting[StoreEnum.ModuleNames.CPANEL_DOMAIN].data,
  );

  const isCpanelEmailHostingPackage = (cpanelPlan: string) =>
    cPanelEmailHostingPackages.includes(cpanelPlan);

  const fetchDomains = async () => {
    await store.dispatch(
      `${StoreEnum.ModuleNames.CPANEL_DOMAIN}/${CpanelStoreEnum.Domain.Actions.FETCH_DOMAINS}`,
      serviceId.value,
    );
  };

  return {
    serviceId,
    cpanelPlan,
    cpanelDetails,
    activeOrders,
    orderDetails,
    cPanelDomains,
    isCpanelEmailHostingPackage,
    fetchDomains,
  };
};
