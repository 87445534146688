export namespace Redirect {
  export enum Destination {
    BUILDER_ONBOARDING = 'builder-onboarding',
  }

  export enum Section {
    PANEL = 'panel',
    BILLING = 'billing',
    EMAIL_FORWARDERS = 'email_forwarders',
    EMAIL_FLOCK_MANAGEMENT = 'flock_management',
    DOMAIN_REGISTER = 'register-domain',
    SERVER = 'server',
    ORDER_DETAILS = 'order_details',
    STAGING = 'staging',
    BACKUPS = 'backups',
    WORDPRESS_SECURITY = 'wordpress-security',
    EXTERNAL_DOMAIN_DNS = 'external-domain',
    CONNECT_DOMAIN = 'connect-domain',
    WEBSITES_LIST = 'websites-list',
    WEBSITES = 'websites',
    PHP_CONFIGURATION = 'php-configuration',
    DOMAIN_CHECKER = 'domain-checker',
    ACCOUNT_SHARING = 'account-sharing',
    ORDER_UPGRADE = 'order-upgrade',
    UPGRADE_HOSTING = 'upgrade-hosting',
    UPGRADE_EMAIL = 'upgrade-email',
  }

  export enum Action {
    RENEW = 'renew',
    CREATE_EMAIL = 'create-mail',
    TRANSFER = 'transfer',
    GENERATE = 'generate',
    CONNECT_DOMAIN = 'connect-domain',
    RENEW_DOMAIN = 'renew-domain',
    RESTORE_DOMAIN = 'restore-domain',
    BUY_DOMAIN = 'buy-domain',
  }

  export const Category = {
    REQUEST_ACCESS: 'request-access',
    GRANT_ACCESS: 'grant-access',
  } as const;
}
