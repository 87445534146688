export namespace Upsell {
  export enum ActionType {
    CONNECTED_HOSTING = 'connectedHosting',
    CONNECT_HOSTING = 'connectHosting',
    GET_EMAIL = 'getEmail',
    SETUP_EMAIL = 'setupEmail',
    MANAGE_ACCOUNTS = 'manageAccounts',
  }

  export enum ProductTitle {
    CLOUD_HOSTING = 'Cloud Hosting',
    SHARED_HOSTING = 'Shared Hosting',
    NEW_DOMAIN_NAME = 'New Domain Name',
    ENTERPRISE_EMAIL = 'Enterprise Email',
    BUSINESS_EMAIL = 'Business Email',
    PROFESSIONAL_EMAIL = 'Professional Email',
    BASIC_EMAIL = 'Basic Email',
    FREE_EMAIL = 'Free Email',
    VPS = 'VPS',
    HOSTINGER_MAIL = 'Hostinger Mail',
  }
}
