<script setup lang="ts">
import { HButton } from '@hostinger/hcomponents';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useGlobals } from '@/composables/useGlobals';
import { useHostingOrders } from '@/composables/useHostingOrders';
import { AmplitudeEvent, AmplitudeLocation, Route } from '@/types';

defineProps<{
  variant: 'text' | 'outline';
}>();

const { t, amplitudeV2 } = useGlobals();
const store = useStore();
const route = useRoute();
const router = useRouter();

const { isPlanUpgradable } = useHostingOrders();
const currentOrderDetails = computed(() => store.getters.currentOrderDetails);
const isInTransfer = computed(() => currentOrderDetails.value?.isInTransfer);

const goToUpgrade = () => {
  amplitudeV2(AmplitudeEvent.Hosting.UPGRADE, {
    location: AmplitudeLocation.Base.DASHBOARD,
  });
  router.push({
    name: Route.Order.ORDER_UPGRADE,
    params: {
      domain: route.params.domain,
    },
  });
};
</script>
<template>
  <div v-if="isPlanUpgradable">
    <HButton
      :variant="variant"
      :is-disabled="isInTransfer"
      data-qa="hosting-dashboard-upgrade-button"
      class="upgrade-button"
      @click="goToUpgrade()"
    >
      {{ t('v2.upgrade') }}
    </HButton>
  </div>
</template>
