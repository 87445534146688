import { useOnboardingSubmit, useResourceMapper } from '@/composables';
import {
  accountRepo,
  domainsRepo,
  hBillingRepo,
  hDomainsRepo,
  hResourcesRepo,
  surveyRepo,
  websitesRepo,
  wordpressRepo,
} from '@/repositories';
import { router } from '@/router';
import onboardingAppNameModule from '@/store/modules/hosting/onboarding/onboardingAppNameModule';
import onboardingCpanelModule from '@/store/modules/hosting/onboarding/onboardingCpanelModule';
import onboardingDatacenterModule from '@/store/modules/hosting/onboarding/onboardingDatacenterModule';
import onboardingMigrationModule from '@/store/modules/hosting/onboarding/onboardingMigrationModule';
import onboardingSelectToolModule from '@/store/modules/hosting/onboarding/onboardingSelectToolModule';
import onboardingTemplateModule from '@/store/modules/hosting/onboarding/onboardingTemplateModule';
import {
  currentOrderId,
  currentUsername,
  getStateVariable,
  setForDomain,
} from '@/store/storeHelper';
import {
  BillingOrder,
  ExternalRoutes,
  HRESOURCES_STATE,
  Header,
  Onboarding,
  Route,
  WORDPRESS_HOSTING_BUY_PAGES,
} from '@/types';
import { getSplittedDomainParts, toASCII } from '@/utils/helpers';
import { removeFalsyKeysFromObject } from '@/utils/helpers/requestHelper';
import { mapOnboardingGettersToAddonOnboardingRequest } from '@/utils/mappers/onboardingMapper';
import { errorLogger } from '@/utils/services/errorLogging';
import {
  onboardingRoutingByStatus,
  getOnboardingAccountStatus,
} from '@/utils/services/onboarding';

const initial = {
  domain: null,
  selectedWebsiteType: undefined,
  domainName: null,
  showProgressBar: false,
  setupStatus: null,
  account_create_submitted: false,
  onboardingOrderDetails: {},
  onboardingComplete: {},
  domainToSetup: null,
  domainTxtVerification: null,
  freeDomainInvoice: false,
  steps: [],
  loaded: false,
  willPayLater: false,
  survey: {
    show: false,
    answers: [],
  },
  skippedOnboarding: false,
  selectedPurchasePeriod: 1,
  purchaseInvoice: null,
  experimentVariant: null,
  isFromBuilderDemo: false,
  addonType: null,
  existingDomainSummaryData: {
    txtRecordToVerify: '',
    domain: '',
    goBackRoute: null,
    redirectTo: null,
    isWBAddDomainFlow: false,
  },
  managedSubscriptionOrder: {},
  domainSelectType: null,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    REMOVE_COMPLETED_ONBOARDING: (state) => {
      state.data = [
        ...state.data.filter(({ domain }) => domain !== currentOrderId()),
      ];

      const dataToRemove = [
        'appName',
        'datacenters',
        'migrate',
        'selectTool',
        'template',
      ];

      dataToRemove.forEach((param) => {
        state[param].data = [
          ...(state[param]?.data || []).filter(
            ({ domain }) => domain !== currentOrderId(),
          ),
        ];
      });
    },
    SET_FIRST_ONBOARDING_STEP: (state) => {
      const routeName = router.currentRoute.value.name;
      const isNewHosting = router.currentRoute.value.query.isNewHosting;
      const type = router.currentRoute.value.params.type;
      const isInitialStep = [
        Route.Onboarding.START,
        Route.Onboarding.WEBSITE_TYPE_SURVEY,
        Route.Onboarding.BUILD_OR_MIGRATE,
      ].includes(routeName);
      const steps = getStateVariable(state, 'steps', currentOrderId());

      const getPayload = () => {
        if (isNewHosting) {
          return [
            Route.Onboarding.HOSTING_V2,
            Route.Onboarding.BUILD_OR_MIGRATE,
          ];
        }

        if (type) {
          return [
            Route.Onboarding.WEBSITES,
            Route.Onboarding.WEBSITE_TYPE_SURVEY,
          ];
        }

        return [Route.Onboarding.START];
      };

      if (isInitialStep || !steps.length) {
        setForDomain(state, currentOrderId(), 'steps', getPayload());

        return;
      }

      const stepIndex = steps.indexOf(routeName);
      setForDomain(
        state,
        currentOrderId(),
        'steps',
        steps.slice(0, stepIndex + 1),
      );
    },
    ONBOARDING_STATE_RELOAD: (state) => (state.data = [...state.data]),
    ONBOARDING_SET_TXT_VERIFICATION: (state, payload) => {
      setForDomain(state, currentOrderId(), 'domainTxtVerification', payload);
    },
    setShowProgressBar(state, data) {
      setForDomain(state, currentOrderId(), 'showProgressBar', data);
    },
    setSetupStatus(state, data) {
      setForDomain(state, currentOrderId(), 'setupStatus', data);
    },
    setOnboardingDomainName(state, data) {
      setForDomain(state, currentOrderId(), 'domainName', data.toLowerCase());
    },
    setOnboardingDomainSelectType(state, data) {
      setForDomain(
        state,
        currentOrderId(),
        'domainSelectType',
        data.toLowerCase(),
      );
    },

    setAddStep(state, step) {
      const order_id = currentOrderId();
      const steps = getStateVariable(state, 'steps', order_id);

      if (steps[steps.length - 1] !== step) {
        steps.push(step);
      }
      setForDomain(state, order_id, 'steps', steps);
    },
    setSteps(state, updatedSteps) {
      setForDomain(state, currentOrderId(), 'steps', updatedSteps);
    },

    setAccountCreateSubmitted(state, data) {
      setForDomain(state, currentOrderId(), 'account_create_submitted', data);
    },
    setOnboardingDomainToSetup(state, data) {
      setForDomain(state, currentOrderId(), 'domainToSetup', data);
    },
    SET_IS_EXPERIENCED(state, data) {
      setForDomain(state, currentOrderId(), 'isExperienced', data);
    },
    SET_WILL_PAY_LATER(state, data) {
      setForDomain(state, currentOrderId(), 'willPayLater', data);
    },
    SET_SHOW_SURVEY(state, data) {
      const survey = getStateVariable(state, 'survey', currentOrderId());
      survey.show = data;
      setForDomain(state, currentOrderId(), 'survey', survey);
    },
    SET_SKIPPED_ONBOARDING(state, data) {
      setForDomain(state, currentOrderId(), 'skippedOnboarding', data);
    },
    SET_SURVEY_ANSWER(state, answer) {
      const survey = getStateVariable(state, 'survey', currentOrderId());
      survey.answers = [...survey.answers, answer];
      setForDomain(state, currentOrderId(), 'survey', survey);
    },
    SET_ONBOARDING_COMPLETE(state, data) {
      setForDomain(state, currentOrderId(), 'onboardingComplete', data);
    },
    RESET_SURVEY_ANSWER(state, index) {
      const survey = getStateVariable(state, 'survey', currentOrderId());
      survey.answers = survey.answers.slice(0, index);
      setForDomain(state, currentOrderId(), 'survey', survey);
    },
    RESET_SURVEY(state) {
      const survey = getStateVariable(state, 'survey', currentOrderId());
      survey.answers = [];
      setForDomain(state, currentOrderId(), 'survey', survey);
    },
    SET_SELECTED_PURCHASE_PERIOD(state, data) {
      setForDomain(state, currentOrderId(), 'selectedPurchasePeriod', data);
    },
    SET_ONBOARDING_PURCHASE_INVOICE(state, data) {
      setForDomain(state, currentOrderId(), 'purchaseInvoice', data);
    },
    SET_FREE_DOMAIN_INVOICE_CREATED(state, data) {
      setForDomain(state, currentOrderId(), 'freeDomainInvoice', data);
    },
    SET_EXPERIMENT_VARIANT: (state, payload) => {
      setForDomain(state, currentOrderId(), 'experimentVariant', payload);
    },
    SET_IS_FROM_BUILDER_DEMO: (state, payload) => {
      setForDomain(state, currentOrderId(), 'isFromBuilderDemo', payload);
    },
    SET_ADDON_WEBSITE_TYPE: (state, payload) => {
      setForDomain(state, currentOrderId(), 'addonType', payload);
    },
    SET_ONBOARDING_EXISTING_DOMAIN_SUMMARY: (state, payload) => {
      setForDomain(
        state,
        currentOrderId(),
        'existingDomainSummaryData',
        payload,
      );
    },
    SET_MANAGED_SUBSCRIPTION_ORDER(state, payload) {
      state.managedSubscriptionOrder = payload;
    },
  },
  getters: {
    getOnboardingComplete: (state) =>
      getStateVariable(state, 'onboardingComplete', currentOrderId()),
    getDomainTxtVerification: (state) =>
      getStateVariable(state, 'domainTxtVerification', currentOrderId()),
    getExistingDomainSummary: (state) =>
      getStateVariable(state, 'existingDomainSummaryData', currentOrderId()),
    getSetupStatus: (state) =>
      getStateVariable(state, 'setupStatus', currentOrderId()),
    getOnboardingDomainName: (state) =>
      getStateVariable(state, 'domainName', currentOrderId()),
    getOnboardingDomainId: () => currentOrderId(),
    getOnboardingDomainToSetup: (state) =>
      getStateVariable(state, 'domainToSetup', currentOrderId()),
    showProgressBar: (state) =>
      getStateVariable(state, 'showProgressBar', currentOrderId()),
    isStepActive: () => (step) =>
      String(router.currentRoute.value.meta.step).slice(0, 1) === String(step),
    isStepCompleted: () => (step) =>
      String(router.currentRoute.value.meta.step).slice(0, 1) > String(step),
    lastStep: (state) => {
      const steps = getStateVariable(state, 'steps', currentOrderId());

      return steps[steps.length - 1];
    },
    onboardingSteps: (state) =>
      getStateVariable(state, 'steps', currentOrderId()),

    getAccountCreateSubmitted: (state) =>
      getStateVariable(state, 'account_create_submitted', currentOrderId()),
    isOrderWordPressPlan: () => {
      const { getResourcesByIdempotencyKey } = useResourceMapper();

      const orderDetails = getResourcesByIdempotencyKey(
        router.currentRoute.value.params.order_id,
      );

      if (
        [
          'wp_hostinger_single',
          'wp_hostinger_premium',
          'wp_hostinger_business',
          'wp_cloud_economy',
          'wp_cloud_professional',
        ].includes(orderDetails?.config?.plan)
      ) {
        return true;
      }

      const chargebeePartialProductNames = [
        'hosting-wpbusiness',
        'hosting-wpsingle',
        'hosting-wpcloudprofessional',
        'hosting-wpcloudeconomy',
        'hosting-wppremium',
      ];

      return chargebeePartialProductNames.some((name) =>
        orderDetails?.config?.plan.includes(name),
      );
    },
    isOrderWooPlan: () => {
      const { getResourcesByIdempotencyKey } = useResourceMapper();

      const orderDetails = getResourcesByIdempotencyKey(
        router.currentRoute.value.params.order_id,
      );

      if (
        [
          'woo_hostinger_premium',
          'woo_cloud_economy',
          'woo_cloud_professional',
        ].includes(orderDetails?.config?.plan)
      ) {
        return true;
      }

      const chargebeePartialProductNames = [
        'hosting-woocloudprofessional',
        'hosting-woocloudeconomy',
        'hosting-woopremium',
      ];

      return chargebeePartialProductNames.some((name) =>
        orderDetails?.config?.plan.includes(name),
      );
    },
    getSelectedWebsiteType: (state) =>
      getStateVariable(state, 'selectedWebsiteType', currentOrderId()),
    getIsExperienced: (state) =>
      getStateVariable(state, 'isExperienced', currentOrderId()),
    getWillPayLater: (state) =>
      getStateVariable(state, 'willPayLater', currentOrderId()),
    getSurvey: (state) => getStateVariable(state, 'survey', currentOrderId()),
    getSurveyData: (state) => {
      const survey = getStateVariable(state, 'survey', currentOrderId());

      const surveyData = {};
      survey.answers.forEach(
        (answer) => (surveyData[answer.type] = answer.value),
      );

      return surveyData;
    },
    getSkippedOnboarding: (state) =>
      getStateVariable(state, 'skippedOnboarding', currentOrderId()),
    getSelectedPurchasePeriod: (state) =>
      getStateVariable(state, 'selectedPurchasePeriod', currentOrderId()),
    getPurchaseInvoice: (state) =>
      getStateVariable(state, 'purchaseInvoice', currentOrderId()),
    isFreeDomainInvoiceCreated: (state) =>
      getStateVariable(state, 'freeDomainInvoice', currentOrderId()),
    getExperimentVariant: (state) =>
      getStateVariable(state, 'experimentVariant', currentOrderId()),
    getIsFromBuilderDemo: (state) =>
      getStateVariable(state, 'isFromBuilderDemo', currentOrderId()),
    getAddonType(state) {
      return getStateVariable(state, 'addonType', currentOrderId());
    },
    getSelectedPlatformByRedirect(state) {
      const redirectedFrom =
        state?.managedSubscriptionOrder?.analyticsData?.find(
          (data) => data.key === 'page_name',
        )?.value;

      if (WORDPRESS_HOSTING_BUY_PAGES.includes(redirectedFrom)) {
        return Onboarding.Platform.WORDPRESS;
      }
      if (redirectedFrom === ExternalRoutes.Websites.WEBSITE_BUILDER) {
        return Onboarding.Platform.BUILDER;
      }
    },
    getOnboardingDomainSelectType: (state) =>
      getStateVariable(state, 'domainSelectType', currentOrderId()),
  },
  actions: {
    async createFreeDomainInvoice({ commit, getters, dispatch }) {
      commit('SET_FREE_DOMAIN_INVOICE_CREATED', false);
      const [domainSld, domainTld] = getSplittedDomainParts(
        getters.getFreeDomainValue,
      );

      const [{ data }, err] = await hDomainsRepo.claimDomain({
        domain: domainSld,
        tld: domainTld.substring(1),
      });

      const domainOrderId = data?.orderId;
      commit('setOnboardingDomainToSetup', domainOrderId);

      if (!err) {
        // To avoid `onboardingRoutingByStatus` and this redirect at the same time
        dispatch('stepIntoDomainSetup', domainOrderId);
        commit('SET_FREE_DOMAIN_INVOICE_CREATED', true);
      }

      return [{}, err];
    },
    async submitOnboarding({ commit, getters }) {
      commit('setAccountCreateSubmitted', true);
      const finalObj = {
        accountConfig: {
          domain: toASCII(getters.getOnboardingDomainName),
          datacenter: getters.getSelectedDatacenterKey,
        },
        survey: {
          skippedOnboarding: getters.getSkippedOnboarding,
        },
      };

      finalObj.survey = {
        ...finalObj.survey,
        ...getters.getSurveyData,
      };

      if (getters.getFileStorageUrl) {
        finalObj.websiteImportConfig = {
          storageUrl: getters.getFileStorageUrl,
        };
      }

      const wordpressPresetTheme =
        getters.getSelectedWordpressPreset?.themes?.[0].slug || '';
      const hasSelectedWordpressTemplate =
        getters.getCmsTemplate || wordpressPresetTheme;
      if (hasSelectedWordpressTemplate) {
        finalObj.themeConfig = {
          slug: hasSelectedWordpressTemplate,
        };
      }

      if (getters.getAiGenerateContent) {
        finalObj.aiGenerateContent = getters.getAiGenerateContent;
      }

      if (
        getters.getMigrationType &&
        !getters['hostingMigrations/getSkippedMigrationsIds'].includes(
          getters.getOnboardingDomainId,
        )
      ) {
        const migrationConfig = {
          panel: getters.getMigrationType,
          migrateTo: toASCII(getters.getOnboardingDomainName),
          ...getters.getMigrationDetailsForSubmit,
        };

        const cleanedMigrationConfig =
          removeFalsyKeysFromObject(migrationConfig);

        finalObj.migrationConfig = cleanedMigrationConfig;
      }

      if (
        getters.getSelectedCms &&
        getters.getSelectedCms !== 'website-builder'
      ) {
        finalObj.autoinstallerConfig = {
          application: getters.getSelectedCms,
          login: getters.getCmsCredentials.email,
          ...(getters.templatePaletteId && {
            themePalette: getters.templatePaletteId,
            themeLayout: getters.templateLayoutId,
            themeFont: getters.templateFontId,
          }),
          plugins: [
            ...getters.getSelectedWordpressPlugins.map((item) => item.slug),
            ...getters.getSelectedWordpressPresetPlugins,
          ],
          ...getters.getCmsCredentials,
        };

        if (getters.isOrderWordPressPlan) {
          finalObj.autoinstallerConfig.application = 'wordpress';
        }

        if (getters.isOrderWooPlan || getters.isWooCmsSelected) {
          finalObj.autoinstallerConfig.application = 'wordpress';
          finalObj.autoinstallerConfig.plugins = ['woocommerce'];
        }
      }

      if (getters.getSelectedCms === 'website-builder') {
        finalObj.type = 'website_builder';
      }

      const { submitSetupOnboarding } = useOnboardingSubmit();

      const [{ data }, err] = await submitSetupOnboarding(
        finalObj,
        currentOrderId(),
      );

      if (err) {
        commit('setShowProgressBar', false);
        commit('setAccountCreateSubmitted', false);

        return [{}, err];
      }
      commit('setShowProgressBar', true);

      return [{ data }, err];
    },
    async submitAddonOnboarding({ getters, commit }) {
      const request = mapOnboardingGettersToAddonOnboardingRequest(getters);
      const account = getters.getMainAccountByUsername(currentUsername());

      const [{ data }, err] = await websitesRepo.submitAddonOnboarding(
        request,
        {
          username: account.username,
          orderId: account.referenceId,
          domain: account.domain,
        },
      );

      if (err) {
        commit('setShowProgressBar', false);
        commit('setAccountCreateSubmitted', false);

        return [{}, err];
      }

      commit('setShowProgressBar', true);

      return [{ data }, err];
    },
    backInSteps(context, step = '') {
      const steps = getStateVariable(context.state, 'steps', currentOrderId());
      if (step) {
        const index = steps.indexOf(step);
        context.commit('setSteps', steps.slice(0, index + 1));

        return step;
      }

      steps.pop();

      return steps[steps.length - 1];
    },

    async getOnboardingState(context, orderId = currentOrderId()) {
      const [{ data }, error] = await getOnboardingAccountStatus(
        orderId,
        context.getters.getOnboardingDomainName,
      );

      context.commit('setSetupStatus', data);

      const isDomainSetupInProgress =
        context.getters.getOnboardingDomainToSetup &&
        router.currentRoute.value.name === 'onboarding_domain_setup';

      onboardingRoutingByStatus(
        data,
        context.getters.getAccountCreateSubmitted && !isDomainSetupInProgress,
        false,
      );

      return [{ data }, error];
    },
    async getWpLink(context, { type, username, domain, orderId }) {
      if (type !== 'wordpress') return false;

      const [{ data: wpLoginUrl }, wpLoginError] =
        await wordpressRepo.postWordPressLogin({
          username,
          domain,
          orderId,
          directory: '',
        });

      if (wpLoginError) {
        throw wpLoginError;
      }

      return wpLoginUrl;
    },
    async getOnboardingStatus({ dispatch, getters }, { orderId }) {
      const logOnboardingCompleteError = (specificErrorName) => {
        errorLogger.logError(
          `Onboarding complete error - ${specificErrorName}`,
        );
      };

      const [{ data }, resourceError] = await hResourcesRepo.getResources(
        { subscriptionId: orderId },
        { overrideCache: true },
      );

      if (resourceError) {
        logOnboardingCompleteError('resource fetch failed');

        return [{ data: {} }, resourceError];
      }

      const resource = data?.find(
        ({ chargebeeSubscriptionId }) => chargebeeSubscriptionId === orderId,
      );

      if (resource?.state !== HRESOURCES_STATE.ACTIVE) {
        logOnboardingCompleteError(`resource status is ${resource?.state}`);

        return [
          { data: {} },
          { error: `resource status is ${resource?.state}` },
        ];
      }

      const username = resource?.config.username;
      const domain = getters.getOnboardingDomainName;

      const [{ data: hapiStatus }, hapiStatusError] =
        await accountRepo.getOnboardingStatus({ orderId, domain });

      if (hapiStatusError) {
        logOnboardingCompleteError('hapi status error');

        return [{ data: {} }, hapiStatusError];
      }

      let isWpInstall = false;

      try {
        isWpInstall = await dispatch('getWpLink', {
          type: hapiStatus.type,
          username,
          domain: hapiStatus.domain,
          orderId: resource.referenceId,
        });
      } catch (wpLoginError) {
        logOnboardingCompleteError('wpLoginError');

        return [{ data: {} }, wpLoginError];
      }

      const [{ data: previewLink }, previewLinkError] =
        await domainsRepo.getPreviewLink(hapiStatus.domain || resource.title, {
          headers: {
            [Header.USERNAME]: username,
            [Header.ORDER_ID]: resource.referenceId,
          },
        });

      if (previewLinkError) {
        logOnboardingCompleteError('preview link error');

        return [{ data: {} }, previewLinkError];
      }

      const response = {
        previewUrl: previewLink.previewLink,
        previewUrlEnabled: previewLink.enabled,
        account: {
          id: resource.referenceId,
          domain: hapiStatus.domain,
          isDomainPointing: false,
        },
        wp: isWpInstall,
      };

      return [{ data: response }, null];
    },
    async getOnboardingCompleteData(context, { orderId }) {
      if (context.getters.getOnboardingComplete?.previewUrl) {
        return [{ data: context.getters.getOnboardingComplete }, null];
      }

      const [{ data }, error] = await context.dispatch('getOnboardingStatus', {
        orderId,
      });

      context.dispatch('updateAccountsBySubscriptionId', orderId);

      context.commit('SET_ONBOARDING_COMPLETE', data);

      return [{ data }, error];
    },
    stepIntoDomainSetup({ getters }, domainOrderId = null) {
      const domainOrderIdDefault =
        domainOrderId || getters.getOnboardingDomainToSetup;

      let domainSld, domainTld;

      if (getters.getOnboardingDomainName) {
        [domainSld, domainTld] = getSplittedDomainParts(
          getters.getOnboardingDomainName,
        );
      }

      router.push({
        name: 'onboarding_domain_setup',
        params: {
          ...router.currentRoute.value.params,
          domain_order_id: domainOrderIdDefault,
        },
        query: {
          domainTld,
          domainSld,
        },
      });
    },
    async isSurveyEligible({ commit }) {
      const [{ data }] = await surveyRepo.getIsEligibleForSurvey(
        currentOrderId(),
      );

      commit('SET_SHOW_SURVEY', data.isEligible);
    },
    async fetchBillingCompletedSubscriptionOrder({ commit }, payload) {
      const [{ data }, error] = await hBillingRepo.getSubscriptionOrders(
        payload,
        {
          statuses: [BillingOrder.Status.COMPLETED],
          types: [BillingOrder.Type.SUBSCRIPTION_ORDER],
        },
      );

      if (error || !data?.length) return;

      const [managedSubscriptionOrder] = data;
      commit('SET_MANAGED_SUBSCRIPTION_ORDER', managedSubscriptionOrder);
    },
  },
  modules: {
    selectTool: onboardingSelectToolModule,
    migrate: onboardingMigrationModule,
    datacenters: onboardingDatacenterModule,
    appName: onboardingAppNameModule,
    template: onboardingTemplateModule,
    cpanel: onboardingCpanelModule,
  },
};
