<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<script setup lang="ts">
import { onMounted, useSlots, ref, watch, computed } from 'vue';

import { useGlobals } from '@/composables';
import { useLanguageStore } from '@/stores';
import type { InputFileEvent } from '@/types';
import { filterKey } from '@/utils/services/i18nService';
import { vnodeToString } from '@/utils/services/vnodeFunctions';

type Props = {
  tag?: string;
  translateParams?: Object;
  translateText?: string;
};

interface Emits {
  (eventName: 'click', payload: InputFileEvent): void;
}

const props = withDefaults(defineProps<Props>(), { tag: 'span' });
const languageStore = useLanguageStore();

const { t } = useGlobals();
const translation = ref('');

const emit = defineEmits<Emits>();

const slots = useSlots();
const slotContent = computed(() => (slots.default ? slots.default()?.[0] : ''));
const paramsContent = computed(() => props.translateParams);

const translate = () => {
  if (!slots.default && !props.translateText) {
    return;
  }

  let msgid;
  if (props.translateText) {
    msgid = props.translateText;
  } else if (slots.default) {
    msgid = vnodeToString(slots.default());
  }

  // @ts-ignore
  msgid = filterKey(msgid);
  let translated = t(msgid.trim(), props.translateParams);

  const matches = translated.match(/\{(.*?)\}/gim);

  if (matches) {
    matches.forEach((param) => {
      translated = translated?.replace(
        param,
        //@ts-ignore
        props.translateParams?.[param?.replace('{', '')?.replace('}', '')],
      );
    });
  }

  translation.value = translated;
};

translate();

onMounted(() => translate());

watch(slotContent, () => translate(), { deep: true });
watch(paramsContent, () => translate(), { deep: true });
watch(
  () => languageStore.currentLanguage,
  () => translate(),
);
</script>

<template>
  <Component
    :is="tag"
    v-safe-html="translation"
    class="trans"
    @click="emit('click', $event)"
  />
</template>

<style lang="scss" scoped>
.trans {
  word-break: break-word;
}
</style>
