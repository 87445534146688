export enum AI_TROUBLESHOOT_USER {
  AI = 'ai',
  USER = 'user',
  STATUS_FAILED = 'failed',
  STATUS_SUCCESS = 'success',
}

export enum AI_TROUBLESHOOT_LOADING_ACTION_ID {
  initDomain = 'initDomain',
  init = 'init',
  applyingSolution = 'applyingSolution',
  autoFix = 'autoFix',
}

export enum AI_TROUBLESHOOT_ACTION_NAME {
  retry = 'retry',
  survey = 'survey',
  surveyAnswer = 'surveyAnswer',
  openWebsite = 'openWebsite',
  temporaryDomain = 'temporaryDomain',
  buyDomain = 'buyDomain',
  finishRegistration = 'finishRegistration',
  restoreDomain = 'restoreDomain',
  autoFix = 'autoFix',
  openKnowledgeBase = 'openKnowledgeBase',
}
