import type {
  ResourceWithSubscription,
  ResourcesWithSubscriptions,
} from './resourcesMapper';

import type { BillingSubscription, BillingItem, IHResource } from '@/types';
import {
  ACTIVE_STATES_FOR_ORDERS,
  SUBSCRIPTION_TITLE_MAP,
  RESOURCE_STATE_TO_MONOLITH_STATUS_MAP,
  RESOURCE_TO_MONOLITH_TYPE_MAP,
  HOSTING_ORDER_RESOURCE_TYPES,
  HRESOURCES_STATE,
  ALLOW_UPGRADE_STATES_FOR_HOSTING_ORDER,
  VPS_ORDER_RESOURCE_TYPES,
  HVps,
  CPANEL_ORDER_RESOURCE_TYPES,
  Hosting,
  HRESOURCES_TYPE,
} from '@/types';

export const getMonolithOrderTitle = (resource: ResourceWithSubscription) => {
  if (resource.type === 'domain') {
    return `Domain registration - ${resource.title}`;
  }

  if (resource.type === 'domain_transfer') {
    return `Domain transfer - ${resource.title}`;
  }

  if (resource.type === 'hosting' && resource.title) {
    return resource.title;
  }

  return (
    resource.metadata?.planTitle ||
    SUBSCRIPTION_TITLE_MAP[resource.type] ||
    resource.subscription.items?.[0]?.name
  );
};

export const getHostingPlanName = (resource: ResourceWithSubscription) => {
  const subscription = resource.subscription;

  if (subscription) {
    return getFirstSubscriptionItem(subscription)?.name;
  }

  return resource.title;
};

export const getMonolithOrderCtaText = (resource: ResourceWithSubscription) => {
  if (
    resource.type === HRESOURCES_TYPE.GOOGLE_WORKSPACE &&
    resource.state === HRESOURCES_STATE.ACTIVATING
  ) {
    return 'setup';
  }

  return resource.state === 'pending_setup' ? 'setup' : 'manage';
};

export const mapGenericOrderResource = (
  resource: ResourceWithSubscription,
) => ({
  id: resource.subscription.id,
  hresourceId: resource.id,
  referenceId: resource.referenceId,
  serviceId: resource.state !== 'pending_setup' ? resource.id : null,
  status: RESOURCE_STATE_TO_MONOLITH_STATUS_MAP[resource.state],
  service: RESOURCE_TO_MONOLITH_TYPE_MAP[resource.type],
  serviceStatus: resource.state === 'active' ? 'active' : 'inactive',
  expiresAt: resource.expiresAt,
  title: getMonolithOrderTitle(resource),
  productName: resource.subscription.items?.[0]?.itemPriceId || null,
  isAutoRenew: resource.subscription.nextBillingAt ? '1' : '0',
  canBeRestored: resource.state === 'active',
  reason: resource.reason,
  suspendedAt: resource.suspendedAt,
  ctaText: getMonolithOrderCtaText(resource),
  ctaLink: null,
  links: [],
});

export const getSubscriptionPeriod = (subscription: BillingSubscription) =>
  subscription.billingPeriodUnit || subscription.billingPeriod;

export const getIsResourceConfigPlanIncludesCloudKeyword = (
  resource: IHResource,
) => {
  const plan = resource.config?.plan;

  if (!plan) {
    return false;
  }

  return plan.includes('cloud');
};

export const getFirstSubscriptionItem = (
  subscription: BillingSubscription,
): BillingItem | undefined => subscription.items?.[0];

export const getHostingLinks = (resource: ResourceWithSubscription) => {
  const links = [];
  const firstSubscriptionItem = getFirstSubscriptionItem(resource.subscription);
  const upgradesToCount =
    firstSubscriptionItem?.itemPriceMetadata?.upgradesTo.length || 0;

  if (resource.subscription.canRenew || resource.subscription.canReactivate) {
    links.push({
      name: Hosting.HostingActionType.RENEW,
      link: '',
    });
  }

  if (
    ALLOW_UPGRADE_STATES_FOR_HOSTING_ORDER.includes(resource.state) ||
    upgradesToCount
  ) {
    links.push({
      name: Hosting.HostingActionType.UPGRADE,
      link: '',
    });
  }

  return links;
};

export const getVpsType = (resource: ResourceWithSubscription) => {
  const firstSubscriptionItem = getFirstSubscriptionItem(resource.subscription);
  const itemPriceId = firstSubscriptionItem?.itemPriceId || '';

  if (itemPriceId.includes(HVps.OrderType.MINECRAFT)) {
    return HVps.OrderType.MINECRAFT;
  }

  if (itemPriceId.includes(HVps.OrderType.CYBERPANEL)) {
    return HVps.OrderType.CYBERPANEL;
  }

  return 'vps';
};

export const mapHostingOrderResource = (resource: ResourceWithSubscription) => {
  const genericResource = mapGenericOrderResource(resource);
  const period = getSubscriptionPeriod(resource.subscription);

  return {
    ...genericResource,
    isMonthly: period === 'month',
    period,
    isCloud: getIsResourceConfigPlanIncludesCloudKeyword(resource),
    links: getHostingLinks(resource),
    title: getHostingPlanName(resource),
    serviceId:
      resource.state !== HRESOURCES_STATE.PENDING ? resource.referenceId : null,
  };
};

export const mapVpsOrderResource = (resource: ResourceWithSubscription) => {
  const genericResource = mapGenericOrderResource(resource);

  return {
    ...genericResource,
    vpsType: getVpsType(resource),
  };
};

export const mapCpanelOrderResource = (resource: ResourceWithSubscription) => {
  const genericResource = mapGenericOrderResource(resource);

  return {
    ...genericResource,
    cpanelPlan: resource.config?.plan || null,
    serviceId: resource.referenceId,
    cpanelHostname: resource.title,
  };
};

export const mapResourcesToMonolithOrder = (
  resources: ResourcesWithSubscriptions,
) =>
  resources.flatMap((resource) => {
    const isResourceActive = ACTIVE_STATES_FOR_ORDERS.includes(resource.state);
    const hasSubscription = !!resource.subscription;

    if (!isResourceActive || !hasSubscription) {
      return [];
    }

    if (HOSTING_ORDER_RESOURCE_TYPES.includes(resource.type)) {
      return mapHostingOrderResource(resource);
    }

    if (VPS_ORDER_RESOURCE_TYPES.includes(resource.type)) {
      return mapVpsOrderResource(resource);
    }

    if (CPANEL_ORDER_RESOURCE_TYPES.includes(resource.type)) {
      return mapCpanelOrderResource(resource);
    }

    return mapGenericOrderResource(resource);
  });
