import { capiRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

export default {
  namespaced: true,
  state: {
    initial: {
      details: {
        loaded: false,
        loading: false,
        data: {},
      },
    },
    data: [],
  },
  getters: {
    getCPanelDetails: (state) => getStateVariable(state, 'details'),
  },
  mutations: {
    SET_LOADED_DATA: (state, { type, data }) => {
      const items = getStateVariable(state, type);
      items.loaded = true;
      items.data = data;
      setForDomain(state, null, type, items);
    },
    SET_CPANEL_DETAILS_LOADING: (state, payload) => {
      const details = getStateVariable(state, 'details');
      details.loading = payload;
      setForDomain(state, null, 'details', details);
    },
    SET_CPANEL_DETAILS_SHELL: (state, payload) => {
      const details = getStateVariable(state, 'details');
      details.data.shell = payload;
      setForDomain(state, null, 'details', details);
    },
  },
  actions: {
    async getCPanelDetailsByServiceId({ commit }, serviceId) {
      commit('SET_CPANEL_DETAILS_LOADING', true);
      const [{ data }, err] = await capiRepo.getCPanelServiceById(serviceId);
      if (!err) commit('SET_LOADED_DATA', { type: 'details', data });
      commit('SET_CPANEL_DETAILS_LOADING', false);

      return [data, err];
    },
    setCPanelDetailsShell({ commit }, status) {
      commit('SET_CPANEL_DETAILS_SHELL', status);
    },
  },
};
