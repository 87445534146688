import { computed } from 'vue';

import CPanelInstallWordPressModal from '@/components/Modals/HModal/Cpanel/CPanelInstallWordPressModal.vue';
import { useCpanel, useModal } from '@/composables';
import store from '@/store/index';
import { CpanelWordPressStoreEnum, StoreEnum } from '@/types';
import { processDomainName } from '@/utils/helpers/domainsHelper';

export const useCpanelWordpress = () => {
  const { openModal } = useModal();
  const { serviceId, cPanelDomains } = useCpanel();
  const wordpressInstallations = computed(
    () =>
      store.state.hosting[StoreEnum.ModuleNames.CPANEL_WORDPRESS].installation,
  );

  const fetchWordpressInstallations = async () =>
    await store.dispatch(
      `${StoreEnum.ModuleNames.CPANEL_WORDPRESS}/${CpanelWordPressStoreEnum.Actions.FETCH_INSTALLATIONS}`,
      serviceId.value,
    );

  const openInstallationModal = () => {
    openModal({
      component: { CPanelInstallWordPressModal },
    });
  };

  const selectedWordpress = computed(
    () =>
      store.getters[
        `${StoreEnum.ModuleNames.CPANEL_WORDPRESS}/${CpanelWordPressStoreEnum.Getters.GET_CURRENT_MANAGED_SITE}`
      ],
  );

  const selectedWordpressDirectory = computed((): string => {
    const PROTOCOL = /(^\w+:|^)\/\//;
    const url = selectedWordpress.value?.softurl;

    return url ? url.replace(PROTOCOL, '') : '';
  });

  const getInstallationByDirectory = (domain: string, directory: string) =>
    wordpressInstallations.value.data.find((application: any) => {
      const applicationDirectory = application.softurl.split('/').pop();
      const existingDomain = application.softdomain.replace('www.', '');

      return existingDomain === domain && directory === applicationDirectory;
    });

  const isCurrentDomainDeleted = computed((): boolean => {
    const currentDomain = processDomainName(
      selectedWordpress.value?.softdomain ?? '',
    );

    return (
      cPanelDomains.value.mainDomain !== currentDomain &&
      !cPanelDomains.value.parkedDomains?.includes(currentDomain) &&
      !cPanelDomains.value.addonDomains?.includes(currentDomain) &&
      !cPanelDomains.value.subDomains?.includes(currentDomain)
    );
  });

  return {
    wordpressInstallations,
    fetchWordpressInstallations,
    openInstallationModal,
    selectedWordpress,
    selectedWordpressDirectory,
    getInstallationByDirectory,
    isCurrentDomainDeleted,
  };
};
