import type { RouteLocation, RouteRecordRaw } from 'vue-router';

import { websiteDashboardGuard } from '@/guards/websiteDashboardGuard';
import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'website',
    name: Route.HostingWebsite.WEBSITE,
    meta: {
      title: 'Website',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#website' },
    beforeEnter: websiteDashboardGuard,
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'auto-installer',
        name: Route.HostingWebsite.AUTO_INSTALLER,
        meta: {
          title: 'Auto Installer',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            'website',
          ],
          rateServicesTitle: 'How would you rate auto-installer experience?',
          ...sideMenu,
        },
        children: [
          {
            path: 'install/:appName',
            props: (route: RouteLocation) => {
              const [appName, plugin] = (route.params.appName as string).split(
                '+',
              );

              return {
                appName,
                plugin,
                parentName: Route.HostingWebsite.AUTO_INSTALLER,
              };
            },
            meta: {
              title: 'Auto Installer',
              breadcrumbs: [
                Route.Base.WEBSITES,
                Route.Websites.WEBSITE_DASHBOARD,
                Route.HostingWebsite.WEBSITE,
              ],
              ...sideMenu,
            },
            component: () =>
              import('@/views/Hosting/Website/Autoinstaller/AppModal.vue'),
          },
        ],
        component: () => import('@/views/Hosting/Website/AutoInstaller.vue'),
      },
      {
        path: 'error-pages',
        name: 'website_error_pages',
        meta: {
          title: 'Error Pages',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWebsite.WEBSITE,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Website/ErrorPages.vue'),
      },
      {
        path: 'error-pages/:code/edit',
        name: Route.HostingWebsite.ERROR_PAGES_EDIT,
        meta: {
          title: 'Edit Error Page',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWebsite.WEBSITE,
            Route.HostingWebsite.ERROR_PAGES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Website/EditErrorPage.vue'),
      },
      {
        path: 'copy-website',
        name: Route.HostingWebsite.COPY_WEBSITE,
        meta: {
          title: 'Copy Website',
          sidemenu: true,
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWebsite.WEBSITE,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Files/CopyWebsite/CopyWebsite.vue'),
      },
      {
        path: 'add-website',
        name: Route.HostingWebsite.ADD_WEBSITE,
        meta: {
          title: 'Add Website',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDomains.DOMAINS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Website/AddWebsite.vue'),
      },
      {
        path: 'website-builder',
        name: Route.HostingWebsite.WEBSITE_BUILDER,
        meta: {
          title: 'Website Builder',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWebsite.WEBSITE,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Website/WebsiteBuilder.vue'),
      },
      {
        path: 'website-bitdegree',
        name: 'website_bitdegree',
        meta: {
          title: 'Learn to Code',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWebsite.WEBSITE,
          ],
          ...sideMenu,
        },
        redirect: '/404',
      },
    ],
  },
  {
    path: 'wordpress/dashboard',
    name: Route.HostingWebsite.WORDPRESS_INSTALL,
    meta: {
      title: 'WordPress',
    },
    redirect: { name: Route.HostingWordpress.DASHBOARD },
  },
] as RouteRecordRaw[];
