import { mapGetters, mapActions } from 'vuex';

import ChangeServerLocationModal from '@/components/Modals/HModal/ChangeServerLocationModal.vue';
import { AmplitudeEvent } from '@/types';

export default {
  created() {
    if (
      !this.getTransferDatacentersLoaded &&
      this.currentOrderDetails?.orderId
    ) {
      this.transferDatacentersIndex();
    }
  },
  computed: {
    iconClassNameVariable() {
      return this.isDcTransferAllowed ? 'primary' : 'gray';
    },
    isDcTransferAllowed() {
      const isOrderDataTransferAllowed =
        this.currentOrderDetails?.transferData?.allowTransfer &&
        !this.currentOrderDetails?.inTransfer;
      const isAccountDataTransferAllowed =
        this.getHostingAccountDetails?.transferData?.allowTransfer &&
        !this.getHostingAccountDetails?.inTransfer;

      return isOrderDataTransferAllowed || isAccountDataTransferAllowed;
    },
    availableServers() {
      if (!this.getTransferDatacentersLoaded) {
        return false;
      }

      return !!Object.values(this.getTransferDatacenters?.dataCenters).filter(
        (value) => value !== this.currentServerTitle,
      ).length;
    },
    currentServerTitle() {
      return this.currentOrderDetails?.server?.title;
    },
    recommendedDataCenters() {
      const recommendedDataCenterArray = [];
      this.traffic?.forEach((record) => {
        record.recommendedDatacenters?.forEach((center) => {
          if (!recommendedDataCenterArray.includes(center.code)) {
            recommendedDataCenterArray.push(center.code);
          }
        });
      });

      return recommendedDataCenterArray;
    },
    isTransferRecommended() {
      return (
        this.isDcTransferAllowed &&
        this.recommendedDataCenters &&
        this.recommendedDataCenters.length &&
        this.decision === 'move_to_nearest_dc'
      );
    },
    showTransferRecommendation() {
      let selectedDataCenter;
      if (this.isTransferRecommended) {
        if (
          this.getTransferDatacenters?.dataCenters &&
          Object.keys(this.getTransferDatacenters?.dataCenters).length
        ) {
          for (const dataCenter of this.recommendedDataCenters) {
            if (
              Object.keys(this.getTransferDatacenters?.dataCenters).includes(
                dataCenter,
              )
            ) {
              selectedDataCenter = dataCenter;
              break;
            }
          }
        }
      }

      return !!selectedDataCenter && this.isDcTransferAllowed;
    },
    dcTooltipText() {
      if (
        this.currentOrderDetails?.inTransfer ||
        this.getHostingAccountDetails?.inTransfer
      ) {
        return this.$t('Server transfer is already in progress');
      }
      if (
        this.currentOrderDetails?.transferData?.allowTransfer ||
        this.getHostingAccountDetails?.transferData?.allowTransfer
      ) {
        return this.$t('Edit');
      }

      return this.$t(
        'Server transfer will be enabled 30 days after the last transfer, days left: {days}',
        {
          days:
            this.currentOrderDetails?.transferData?.lastTransfer?.daysLeft ||
            this.getHostingAccountDetails?.transferData?.lastTransfer?.daysLeft,
        },
      );
    },
    ...mapGetters([
      'currentOrderDetails',
      'getTransferDatacentersLoaded',
      'getTransferDatacenters',
      'getHostingAccountDetails',
    ]),
    ...mapGetters({
      decision: 'getTransferRecommendationDecision',
      traffic: 'getTransferRecommendationTraffic',
    }),
  },
  methods: {
    openChangeDcModal(server, onFinish) {
      if (this.isDcTransferAllowed) {
        this.$amplitudeV2(AmplitudeEvent.Hosting.DATA_CENTER_ENTER);
        this.$openModal({
          component: { ChangeServerLocationModal },
          data: {
            preselectedServer: !!server,
            onFinish,
          },
          steps: [
            {
              hideX: true,
              isAboveIntercom: true,
            },
            {
              hideX: true,
              isAboveIntercom: true,
            },
            {
              hideX: true,
              isAboveIntercom: true,
            },
            {
              hideX: true,
              isAboveIntercom: true,
            },
          ],
        });
      }
    },
    ...mapActions(['transferDatacentersIndex']),
  },
  watch: {
    'currentOrderDetails.orderId'(value, oldValue) {
      if (value && value !== oldValue) {
        this.transferDatacentersIndex();
      }
    },
    'getHostingAccountDetails.orderId'(value, oldValue) {
      if (value && value !== oldValue) {
        this.transferDatacentersIndex();
      }
    },
  },
};
