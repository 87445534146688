import dayjs from 'dayjs';

import type { ResourceWithSubscription } from './resourcesMapper';

import type { IHostingAccount } from '@/types';
import {
  ACCOUNT_STATUS,
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  Hosting,
} from '@/types';

const getSuspendAt = (resource: ResourceWithSubscription) => {
  if (resource.expiresAt) {
    return dayjs(resource.expiresAt).add(14, 'day').toISOString();
  }

  return null;
};

const getPlan = (
  resource: ResourceWithSubscription,
): Hosting.Plan | undefined => resource.config?.plan;

const getEmailList = (accountWithResource: AccountWithResource) => {
  if (
    accountWithResource.plan &&
    [Hosting.Plan.STARTER, Hosting.Plan.WEBLINK_BRONZE].includes(
      accountWithResource.plan,
    )
  ) {
    return 1;
  }

  return 100;
};

export const mapAccountWithResources = (
  account: IHostingAccount,
  resource: ResourceWithSubscription,
) => {
  const suspendAt = getSuspendAt(resource);
  const plan = getPlan(resource);
  const status =
    resource.state === HRESOURCES_STATE.SUSPENDED
      ? HRESOURCES_STATE.SUSPENDED
      : account.status;

  return {
    ...account,
    referenceId: resource.referenceId,
    suspendAt,
    plan,
    status,
  };
};
type AccountWithResource = ReturnType<typeof mapAccountWithResources>;

const getAccountType = (accountWithResource: AccountWithResource) => {
  if (accountWithResource?.type === Hosting.Vhost.MAIN) {
    return Hosting.AccountType.ACCOUNT;
  }

  return accountWithResource?.type;
};

const getAllowManage = (accountWithResource: AccountWithResource) => {
  if (accountWithResource.status === ACCOUNT_STATUS.SUSPENDED) {
    return {
      allowManage: false,
      allowManageMessage: 'Account is suspended and can not be managed',
    };
  }

  if (accountWithResource.status === ACCOUNT_STATUS.PENDING_DELETE) {
    return {
      allowManage: false,
      allowManageMessage: 'Account is pending delete and can not be managed',
    };
  }

  if (accountWithResource.locked) {
    return {
      allowManage: false,
      allowManageMessage:
        "We're transferring your account! The service will return shortly, once the process is complete.",
    };
  }

  return {
    allowManage: true,
    allowManageMessage: null,
  };
};

export const mapHostingAccountResource = (
  account: IHostingAccount,
  resource: ResourceWithSubscription,
) => {
  if (
    resource.type === HRESOURCES_TYPE.CPANEL_HOSTING ||
    resource.type === HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING
  ) {
    return {
      manage: 'unsupported',
      domain: resource.title,
      status: resource.state,
      orderId: resource.id,
      id: account.id,
    };
  }

  const accountWithResource = mapAccountWithResources(account, resource);
  const emailLimit = getEmailList(accountWithResource);
  const type = getAccountType(accountWithResource);
  const { allowManage, allowManageMessage } =
    getAllowManage(accountWithResource);

  return {
    ...accountWithResource,
    emailLimit,
    type,
    allowManage,
    allowManageMessage,
    referenceId: Number(accountWithResource.referenceId),
    isFreeDomain: accountWithResource.freeDomain,
    isWebsiteBuilder: type === Hosting.AccountType.BUILDER,
  };
};
