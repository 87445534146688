export const WH_ORDER_OWNERSHIP = {
  OWNED: 'owned',
  MANAGED: 'managed',
  ALL: 'all',
} as const;

export type WHOrderOwnership =
  (typeof WH_ORDER_OWNERSHIP)[keyof typeof WH_ORDER_OWNERSHIP];

export const WH_ORDER_WEBSITE_TYPE = {
  WORDPRESS: 'wordpress',
  BUILDER: 'builder',
  OTHER: 'other',
} as const;

export type WHOrderWebsiteType =
  (typeof WH_ORDER_WEBSITE_TYPE)[keyof typeof WH_ORDER_WEBSITE_TYPE];

export const WH_ORDER_WEBSITE_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
} as const;

export type WHOrderWebsiteStatus =
  (typeof WH_ORDER_WEBSITE_STATUS)[keyof typeof WH_ORDER_WEBSITE_STATUS];

export const WH_ORDER_WEBSITE_VHOST_TYPE = {
  MAIN: 'main',
  ADDON: 'addon',
  SUBDOMAIN: 'subdomain',
  BUILDER: 'builder',
} as const;

export type WHOrderWebsiteVhostType =
  (typeof WH_ORDER_WEBSITE_VHOST_TYPE)[keyof typeof WH_ORDER_WEBSITE_VHOST_TYPE];

export type WHOrderWebsite = {
  orderId: number;
  username: string;
  type: WHOrderWebsiteType;
  domain: string;
  status: WHOrderWebsiteStatus;
  vhostType: WHOrderWebsiteVhostType;
  createdAt: Date;
  updatedAt: Date;
};

export type WHOrderResource = {
  orderId: number;
  clientId: number;
  planName: string;
  planDisplayableName: string;
  websites: WHOrderWebsite[];
};

export interface WHOrder {
  pagination: {
    page: number;
    perPage: number;
    total: number;
    totalPages: number;
  };
  resources: WHOrderResource[];
}
