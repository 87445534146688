import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEmailServiceStore = defineStore('emailServiceStore', () => {
  const domainsInRenewal = ref<string[]>([]);

  const isInRenewal = (domain: string) =>
    domainsInRenewal.value.includes(domain);

  const addDomainInRenewal = (domain: string) => {
    domainsInRenewal.value.push(domain);
  };

  const removeDomainInRenewal = (domain: string) => {
    domainsInRenewal.value = domainsInRenewal.value.filter(
      (domainInRenewal) => domainInRenewal !== domain,
    );
  };

  return {
    isInRenewal,
    addRenewalFlag: addDomainInRenewal,
    removeRenewalFlag: removeDomainInRenewal,
  };
});
