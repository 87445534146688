import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { accessRepo } from '@/repositories';
import { useProfileStore } from '@/stores/profile/profileStore';
import type {
  IAccessManager,
  IInviteeManager,
  IGrantAccessRequest,
  IRequestAccessRequest,
  RequestConfig,
} from '@/types';
import { InvitationType } from '@/types';

interface IAccessParams {
  requestAccessRequest: IRequestAccessRequest;
  config?: RequestConfig;
}

export const useAccessStore = defineStore(
  'accessStore',
  () => {
    const managedByMe = ref<IAccessManager[]>([]);
    const managingMe = ref<IAccessManager[]>([]);
    const invitationsManagingMe = ref<IInviteeManager[]>([]);
    const invitationsManagedByMe = ref<IInviteeManager[]>([]);
    const profileStore = useProfileStore();
    const isLoading = ref(false);
    const isAccessLoaded = ref(false);

    const getManagedClientEmailById = (id: string) => {
      const account = managedByMe.value.find(
        ({ clientId }: IAccessManager) => clientId === id,
      );

      return account?.email || '';
    };

    const fetchAccess = async () => {
      if (profileStore.isAccessManager) {
        $reset();

        return;
      }
      isLoading.value = true;

      const [{ data }, error] = await accessRepo.getAccess();

      isLoading.value = false;

      if (error) {
        return;
      }

      isAccessLoaded.value = true;
      managedByMe.value = data.managedByMe;
      managingMe.value = data.managingMe;
    };

    const fetchInvitations = async () => {
      isLoading.value = true;

      const [{ data }, error] = await accessRepo.getInvitations();

      isLoading.value = false;
      if (error) {
        return;
      }

      invitationsManagingMe.value = data.filter(
        ({ type }) => type === InvitationType.GRANT,
      );
      invitationsManagedByMe.value = data.filter(
        ({ type }) => type === InvitationType.REQUEST,
      );
    };

    const activeAccountsManagedByMe = computed(() =>
      managedByMe.value.filter((acccount) => acccount.status === 'active'),
    );

    const hasActiveAccountsManagedByMe = computed(
      () => activeAccountsManagedByMe.value.length > 0,
    );

    const accountsWaitingForAccessConfirmation = computed(() =>
      managingMe.value.filter(({ isConfirmable }) => isConfirmable),
    );

    const grantAccess = async (grantAccessRequest: IGrantAccessRequest) => {
      const [, error] = await accessRepo.postGrant(grantAccessRequest);

      if (error) {
        return [, error];
      }

      await fetchAccess();
      await fetchInvitations();

      return [, error];
    };

    const requestAccess = async (accessParams: IAccessParams) => {
      const [, error] = await accessRepo.postRequest(
        accessParams.requestAccessRequest,
        accessParams?.config,
      );

      if (error) {
        return [, error];
      }

      await fetchAccess();
      await fetchInvitations();

      return [, error];
    };

    const revokeAccess = async (id: number) => {
      const [, error] = await accessRepo.deleteAccess(id);

      if (error) {
        return [, error];
      }

      await fetchAccess();

      return [, error];
    };

    const revokeInvitation = async (id: number) => {
      const [, error] = await accessRepo.deleteInvitation(id);

      if (error) {
        return [, error];
      }

      await fetchInvitations();

      return [, error];
    };

    const confirmAccessRequest = async (id: number) => {
      const [, error] = await accessRepo.patchConfirmAccessRequest(id);

      if (error) {
        return [, error];
      }

      await Promise.all([fetchAccess(), fetchInvitations()]);

      return [, error];
    };

    const $reset = () => {
      managedByMe.value = [];
      managingMe.value = [];
      invitationsManagingMe.value = [];
      invitationsManagedByMe.value = [];
      isAccessLoaded.value = false;
      isLoading.value = false;
    };

    return {
      managedByMe,
      managingMe,
      activeAccountsManagedByMe,
      hasActiveAccountsManagedByMe,
      accountsWaitingForAccessConfirmation,
      isLoading,
      invitationsManagingMe,
      invitationsManagedByMe,
      isAccessLoaded,
      fetchAccess,
      grantAccess,
      requestAccess,
      revokeAccess,
      fetchInvitations,
      revokeInvitation,
      confirmAccessRequest,
      getManagedClientEmailById,
    };
  },
  {
    persist: { storage: localStorage, key: 'access-store' },
  },
);
