import { defineStore } from 'pinia';
import { ref } from 'vue';

import { ONE_MINUTE_IN_MS } from '@/data/globalConstants';
import { whOrderRepo } from '@/repositories';
import type {
  WHOrder,
  WHOrderOwnership,
  WHOrderWebsite,
  WHOrderWebsiteStatus,
} from '@/types';
import { QUERY_CACHE_KEYS } from '@/types/models/queryServiceModels';
import { fetchQuery } from '@/utils/services/queryService';

export const useWHOrdersStore = defineStore('whOrdersStore', () => {
  const websitesTotal = ref<Record<WHOrderOwnership, WHOrderWebsite[]>>({
    owned: [],
    managed: [],
    all: [],
  });

  const getWebsitesResources = async ({
    page,
    perPage,
    ownership,
  }: {
    page?: number;
    perPage?: number;
    ownership: WHOrderOwnership;
  }): Promise<WHOrder | null> => {
    const [{ data }, error] = await fetchQuery({
      queryKey: [
        QUERY_CACHE_KEYS.GET_WEBSITES_RESOURCES,
        page,
        perPage,
        ownership,
      ].filter(Boolean),
      queryFn: async () =>
        whOrderRepo.getWebsitesResources({ page, perPage, ownership }),
      staleTime: ONE_MINUTE_IN_MS / 2,
    });

    if (error) return null;

    return data;
  };

  const getWebsitesTotal = async (ownership: WHOrderOwnership) => {
    const requestPage = (page: number) =>
      getWebsitesResources({ page, ownership });

    const data = await requestPage(1);

    if (!data) return;

    websitesTotal.value[ownership] = data.resources.flatMap(
      (resource) => resource.websites,
    );

    const { totalPages } = data.pagination;

    if (totalPages > 1) {
      const pagesToFetch = Array.from({ length: totalPages - 1 }, (_, index) =>
        requestPage(index + 2),
      );
      const dataAll = await Promise.all(pagesToFetch);

      dataAll.forEach((data) => {
        if (!data) return;

        websitesTotal.value[ownership] = [
          ...websitesTotal.value[ownership],
          ...data.resources.flatMap((resource) => resource.websites),
        ];
      });
    }
  };

  const getWebsitesByOwnershipAndStatus = (
    ownership: WHOrderOwnership,
    status: WHOrderWebsiteStatus,
  ) =>
    websitesTotal.value[ownership].filter(
      (website) => website.status === status,
    );

  const getWebsiteByOwnershipAndDomain = (
    ownership: WHOrderOwnership,
    domain: string,
  ) =>
    websitesTotal.value[ownership].find((website) => website.domain === domain);

  return {
    websitesTotal,
    getWebsitesResources,
    getWebsitesTotal,
    getWebsiteByOwnershipAndDomain,
    getWebsitesByOwnershipAndStatus,
  };
});
