import type { CatalogItem, CatalogItemPrice } from '@/types';
import { convertToMonths } from '@/utils/helpers';
import {
  getLowestMonthlyPurchasePrice,
  getItemMonthlyPrice,
  findPriceByPeriod,
} from '@/utils/helpers/catalogHelpers';
import { mapCSCoupon } from '@/utils/mappers/csCouponMapper';

export const getCouponRequest = (enteredCoupon?: string) => {
  if (!enteredCoupon) {
    return { coupons: [] as string[], agentId: undefined };
  }

  const { coupon, agentId } = mapCSCoupon(enteredCoupon);

  return { coupons: [coupon], agentId };
};

const convertToMonthlyPurchasePricing = (item: CatalogItemPrice) => ({
  regularPrice: getItemMonthlyPrice(item, 'basePrice'),
  price: getItemMonthlyPrice(item, 'firstPeriodPrice'),
  renewalPrice: getItemMonthlyPrice(item, 'price'),
  period: convertToMonths(item.period, item.periodUnit),
});

export const getLowestMonthlyPurchasePricing = (catalogItem: CatalogItem) => {
  const lowestPrice = getLowestMonthlyPurchasePrice(catalogItem);

  return {
    ...convertToMonthlyPurchasePricing(lowestPrice),
  };
};

/**
 * Returns the monthly purchase price for the provided period (months).
 * If the price for the period is not found, the function will try to
 * resolve the lowest available price.
 */
export const getMonthlyPurchasePricingByPeriod = (
  catalogItem: CatalogItem,
  period: number,
) => {
  const price = findPriceByPeriod(catalogItem.prices, period);

  if (!price) {
    return getLowestMonthlyPurchasePricing(catalogItem);
  }

  return convertToMonthlyPurchasePricing(price);
};

/**
 * Returns a list of all unique periods for the provided catalog items.
 */
export const getAvailablePeriods = (catalogItems: CatalogItem[]): number[] => {
  const allOptions = catalogItems.flatMap((item) =>
    item.prices.map((price) => convertToMonths(price.period, price.periodUnit)),
  );

  return Array.from(new Set(allOptions));
};

const getAdjustedByUnitsCountPrice = (price: number | null, count: number) => {
  if (!price) {
    return null;
  }

  return price * count;
};

export const getAdjustedByUnitsCountPricing = (
  pricing: ReturnType<typeof getMonthlyPurchasePricingByPeriod>,
  count: number,
) => ({
  ...pricing,
  price: getAdjustedByUnitsCountPrice(pricing.price, count),
  regularPrice: getAdjustedByUnitsCountPrice(pricing.regularPrice, count),
  renewalPrice: getAdjustedByUnitsCountPrice(pricing.renewalPrice, count),
});
