<script setup lang="ts">
import WordPressScanModal from '@/components/Modals/HModal/Cpanel/WordPress/WordPressScanModal.vue';
import { useModal } from '@/composables';

type Props = {
  block: boolean;
};

withDefaults(defineProps<Props>(), {
  block: false,
});

const { openModal } = useModal();

const openScanModal = () => {
  openModal({
    component: { WordPressScanModal },
  });
};
</script>

<template>
  <HButtonV1 outline primary centered :block="block" @click="openScanModal">
    Check installation
  </HButtonV1>
</template>
