import { datacentersRepo, hResourcesRepo } from '@/repositories';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';
import { useResourcesStore } from '@/stores';

const initial = {
  datacenters: {},
  bestDatacenter: null,
  coordinates: null,
  selectedDatacenter: null,
  savedDatacenter: false,
  latencies: [],
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_DATA_CENTERS(state, data) {
      setForDomain(state, currentOrderId(), 'datacenters', data);
    },
    SET_BEST_DATA_CENTER(state, data) {
      setForDomain(state, currentOrderId(), 'bestDatacenter', data);
    },
    SET_SELECTED_DATA_CENTER(state, data) {
      setForDomain(state, currentOrderId(), 'selectedDatacenter', data);
    },
    SET_COORDINATES(state, data) {
      setForDomain(state, currentOrderId(), 'coordinates', data);
    },
    setSaveDatacenter(state, data) {
      setForDomain(state, currentOrderId(), 'savedDatacenter', data);
    },
    SET_LATENCIES(state, data) {
      setForDomain(state, currentOrderId(), 'latencies', data);
    },
  },
  getters: {
    getDatacenters: (state) =>
      getStateVariable(state, 'datacenters', currentOrderId()),
    getBestDatacenter: (state) =>
      getStateVariable(state, 'bestDatacenter', currentOrderId()),
    getDatacentersCoordinates: (state) =>
      getStateVariable(state, 'coordinates', currentOrderId()),
    getSelectedDatacenter: (state) =>
      getStateVariable(state, 'selectedDatacenter', currentOrderId()) ||
      getStateVariable(state, 'bestDatacenter', currentOrderId()),
    getSavedDatacenter: (state) =>
      getStateVariable(state, 'savedDatacenter', currentOrderId()),
    getSelectedDatacenterKey: (state) =>
      Object.keys(
        getStateVariable(state, 'selectedDatacenter', currentOrderId()) ||
          getStateVariable(state, 'bestDatacenter', currentOrderId()) ||
          {},
      ).pop(),
    getLatencies: (state) =>
      getStateVariable(state, 'latencies', currentOrderId()),
  },
  actions: {
    async onboardingDatacentersIndex(context, orderId) {
      const { getResourceBySubscriptionId, getResourceByReferenceId } =
        useResourcesStore();

      let resource =
        getResourceBySubscriptionId(orderId) ||
        getResourceByReferenceId(orderId);

      if (!resource || !resource?.config?.plan) {
        const [{ data }] = await hResourcesRepo.getResources(
          {},
          { overrideCache: true },
        );

        resource = data.find(
          (resource) =>
            resource.subscriptionId === orderId ||
            resource.referenceId === orderId,
        );

        if (!resource || !resource?.config?.plan) return;
      }

      const [{ data }, error] = await datacentersRepo.getAvailableDatacenters(
        resource.config.plan,
        resource.referenceId,
      );
      if (!error) {
        context.commit('SET_DATA_CENTERS', data.dataCenters);
        context.commit('SET_COORDINATES', data.coordinates);
        context.commit('SET_BEST_DATA_CENTER', data.bestDataCenter);
      }
    },
    async updateLatencies(context, latencies) {
      const sortedLatencies = latencies.sort((a, b) => a.latency - b.latency);
      const ObjectIsNotEmpty =
        Object.keys(context.getters.getDatacenters).length > 0;
      if (!ObjectIsNotEmpty) {
        const orderId = currentOrderId();
        await context.dispatch('onboardingDatacentersIndex', orderId);
      }

      const dataCenters = context.getters.getDatacenters;

      const mappedLatencies = sortedLatencies.map((latency) => {
        const label = dataCenters[latency.datacenter];
        if (label) {
          return { ...latency, label };
        }

        return latency;
      });

      context.commit('SET_LATENCIES', mappedLatencies);
    },
  },
};
