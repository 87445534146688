<script setup lang="ts">
import { useGlobals } from '@/composables';
import { getDomainTransferUnlockArticle } from '@/utils/services/supportArticleService';

const { t } = useGlobals();

const emits = defineEmits<{
  close: [];
}>();
</script>

<template>
  <div class="transfer-locked-modal__header">
    <HpIcon icon="icon-cancel-light" danger class="h-mr-8" />
    <h2 class="h-m-0">{{ t('Domain is locked') }}</h2>
  </div>
  <Trans
    tag="p"
    :translate-params="{
      url: getDomainTransferUnlockArticle(),
    }"
    class="transfer-locked-modal__content"
  >
    Your domain must be unlocked from your
    <span class="transfer-locked-modal__bold">current provider</span>
    before transferring. You can find how to do it in
    <a href="{url}" target="_blank" class="transfer-locked-modal__bold">
      this article.
    </a>
  </Trans>
  <div class="d-flex justify-content-end">
    <HButton @click="emits('close')">
      {{ t('Close') }}
    </HButton>
  </div>
</template>

<style lang="scss">
.transfer-locked-modal {
  &__header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__content {
    margin-bottom: 24px;
  }

  &__bold {
    font-weight: 700 !important;
  }
}
</style>
