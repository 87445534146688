<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useGlobals } from '@/composables';
import { Route } from '@/types';
import type { AccountVhost } from '@/types';

type Props = {
  isDisabled: boolean;
  subtitle: {
    type: String;
    required: true;
  };
  vhostsWithDeprecatedPhpVersion?: AccountVhost[];
};

type Emits = {
  close: [];
  next: [];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useGlobals();
const router = useRouter();

const isTransferDisabled = computed(() => props.isDisabled);

const deprecatedVhostsList = computed(
  () =>
    props.vhostsWithDeprecatedPhpVersion?.map((vhostObj) => vhostObj.vhost) ??
    [],
);

const snackbarMessage = computed(() => {
  const vhosts = props.vhostsWithDeprecatedPhpVersion ?? [];
  const vhostList = vhosts.map((vhostObj) => vhostObj.vhost);

  if (vhostList.length) {
    if (vhostList.length === 1) {
      return {
        mainMessage: t(
          'Your website <strong>{url}</strong> is using an outdated PHP version. To change your server location, <strong>update the PHP version to 5.6 or higher first</strong>.',
          { url: vhostList[0] },
        ),
        vhostList: [],
      };
    } else if (vhostList.length <= 5) {
      return {
        mainMessage: t(
          'Your websites are using an outdated PHP version. To change your server location, <strong>update the PHP version to 5.6 or higher first</strong>.',
        ),
        vhostList,
      };
    }

    return {
      mainMessage: t(
        'Your websites are using an outdated PHP version. To change your server location, <strong>update the PHP version to 5.6 or higher first</strong>.',
      ),
      vhostList: vhostList.slice(0, 4),
      extraCount: vhostList.length - 4,
    };
  }

  return {
    mainMessage: t('You can initiate one server transfer in {days} days', {
      days: '<strong>30</strong>',
    }),
    vhostList: [],
    extraCount: 0,
  };
});

const handleUpdatePhpVersion = () => {
  const firstVhost = props.vhostsWithDeprecatedPhpVersion?.[0]?.vhost;

  if (firstVhost) {
    router.push({
      name: Route.HostingAdvanced.PHP_CONFIGURATION,
      params: {
        domain: firstVhost,
      },
    });
  }
};
</script>

<template>
  <div>
    <div>
      <h1 class="h-mb-8">{{ t('Change Your Server Location') }}</h1>
      <p v-trans class="h-mb-16">
        {{ subtitle }}
      </p>
      <slot />
      <HSnackbar variant="warning" class="datacenter__warning">
        <Trans>{{ snackbarMessage.mainMessage }}</Trans>
        <ul v-if="snackbarMessage.vhostList.length">
          <li v-for="vhost in snackbarMessage.vhostList" :key="vhost">
            {{ vhost }}
          </li>
          <li v-if="snackbarMessage.extraCount">
            {{
              t('And {count} others.', { count: snackbarMessage.extraCount })
            }}
          </li>
        </ul>
      </HSnackbar>
    </div>
    <div class="button-container">
      <HButton variant="text" class="h-mr-16" @click="emit('close')">
        {{ t('Cancel') }}
      </HButton>
      <HButton
        v-if="deprecatedVhostsList.length"
        @click="handleUpdatePhpVersion"
      >
        {{ t('Update PHP version') }}
      </HButton>
      <HButton v-else :is-disabled="isTransferDisabled" @click="emit('next')">
        {{ t('Next') }}
      </HButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.datacenter__warning {
  background: var(--warning-light);
  margin-top: 16px;
  border-radius: 4px;
  width: 100%;
  display: block;
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}
</style>
