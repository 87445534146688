export namespace CPanel {
  export enum ResellerStatus {
    ACTIVE = 'active',
  }

  export namespace WordPressManager {
    export enum TabType {
      DASHBOARD = 'wordpress-manager-dashboard',
      STAGING = 'wordpress-manager-staging',
      CLONE = 'wordpress-manager-clone',
    }
  }

  export namespace WordPress {
    export enum Sort {
      DEFAULT = 'default',
      ASC = 'asc',
      DESC = 'desc',
    }

    export enum Filter {
      WEBSITE = 'website',
      STAGING = 'staging',
    }
  }

  export enum Plan {
    CPANEL_SILVER = 'cpanel_silver',
    CPANEL_GOLD = 'cpanel_gold',
    CPANEL_PRATA = 'cpanel_prata',
    CPANEL_OURO = 'cpanel_ouro',

    CPANEL_BAYI = 'cpanel_bayi',
    CPANEL_PELAJAR = 'cpanel_pelajar',
    CPANEL_PERSONAL = 'cpanel_personal',
    CPANEL_BISNIS = 'cpanel_bisnis',

    CPANEL_MAIL_PRO = 'cpanel_mailpro',
    CPANEL_MAIL_BUSINESS = 'cpanel_mailbusiness',
    CPANEL_MAIL_BUSINESS_EXTRA = 'cpanel_mailbusinessext',
    CPANEL_MAIL_ENTERPRISE = 'cpanel_mailenterprise',

    CPANEL_CLOUD_PREMIUM = 'cpanel_cloudpremium',
    CPANEL_CLOUD_BASIC = 'cpanel_cloudbasic',
    CPANEL_CLOUD_STANDARD = 'cpanel_cloudstandard',
    CPANEL_CLOUD_CORPORATE = 'cpanel_cloudcorporate',

    MAIL_BUSINESS = 'mail_bisnis',
    MAIL_BUSINESS_EXTRA = 'mail_bisnis_extra',
  }

  export const EmailHostingPlans = [
    Plan.CPANEL_MAIL_PRO,
    Plan.CPANEL_MAIL_BUSINESS,
    Plan.CPANEL_MAIL_BUSINESS_EXTRA,
    Plan.CPANEL_MAIL_ENTERPRISE,
  ] as const;

  export enum SSH_STATUS {
    ENABLED = 'jailshell',
    DISABLED = 'noshell',
    PROCESSING = 'processing',
  }

  export enum DnsStatus {
    EXISTS = 'exists',
  }
}
