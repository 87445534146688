import { mapGetters } from 'vuex';

export default {
  methods: {
    getPreviewDomain(defaultDomain) {
      if (!defaultDomain || !this.getDomainIsPointing(defaultDomain)) {
        return null;
      }
      const validDefaultDomain = this.urlWithHttp(defaultDomain);
      const defaultUrl = new URL(validDefaultDomain);

      if (this.getDomainIsPointing(defaultDomain) || !this.isPreviewLinkValid) {
        return defaultUrl?.href;
      }
    },
    urlWithHttp(url) {
      return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    },
  },
  computed: {
    ...mapGetters([
      'getDomainIsPointing',
      'getHostingWordpressSelectedAppData',
    ]),
  },
};
