import { Route } from '@/types';

export type ProRouteMapType = {
  [key in Route.RouteType]?: Route.RouteType;
};

const emailRouteMap = {
  [Route.Email.EMAILS]: Route.HostingerPro.EMAILS_LIST,
};

const hostingerEmailRouteMap = {
  [Route.Email.EMAIL_ONBOARDING_SETUP_DOMAIN]:
    Route.EmailPro.EMAIL_ONBOARDING_SETUP_DOMAIN,
  [Route.Email.EMAIL_ONBOARDING_EXISTING_DOMAIN]:
    Route.EmailPro.EMAIL_ONBOARDING_EXISTING_DOMAIN,
  [Route.Email.EMAIL_ONBOARDING_BUY_DOMAIN]:
    Route.EmailPro.EMAIL_ONBOARDING_BUY_DOMAIN,
  [Route.Email.EMAIL_ONBOARDING_CREATE_ACCOUNT]:
    Route.EmailPro.EMAIL_ONBOARDING_CREATE_ACCOUNT,
  [Route.Email.EMAIL_ONBOARDING_SUCCESS]:
    Route.EmailPro.EMAIL_ONBOARDING_SUCCESS,
  [Route.Email.EMAIL_ONBOARDING_CHOOSE_DOMAIN]:
    Route.EmailPro.EMAIL_ONBOARDING_CHOOSE_DOMAIN,
  [Route.Email.EMAIL_ONBOARDING_CONFIRM]:
    Route.EmailPro.EMAIL_ONBOARDING_CONFIRM,
  [Route.Email.EMAIL_ONBOARDING_DNS]: Route.EmailPro.EMAIL_ONBOARDING_DNS,
  [Route.Email.EMAIL_ONBOARDING_CONNECT_DOMAIN]:
    Route.EmailPro.EMAIL_ONBOARDING_CONNECT_DOMAIN,
};
const titanEmailRouteMap = {
  [Route.Email.TITAN_ONBOARDING_FINISH]: Route.EmailPro.TITAN_ONBOARDING_FINISH,
  [Route.Email.TITAN_ONBOARDING_DOMAIN_EXISTING]:
    Route.EmailPro.TITAN_ONBOARDING_DOMAIN_EXISTING,
  [Route.Email.TITAN_ONBOARDING_DOMAIN_BUY]:
    Route.EmailPro.TITAN_ONBOARDING_DOMAIN_BUY,
};

export const proRouteMap: ProRouteMapType = {
  ...hostingerEmailRouteMap,
  ...titanEmailRouteMap,
  ...emailRouteMap,
};
