<script setup lang="ts">
import { ref } from 'vue';

import HCheckbox from '@/components/HCheckbox.vue';
import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal } from '@/composables';

type Props = {
  data: {
    onSuccess: () => void;
  };
};

const { t } = useGlobals();
const { closeModal } = useModal();

const props = defineProps<Props>();

const isCheckActive = ref(false);

const LEARN_MORE_LINK =
  'https://support.hostinger.com/en/articles/1864454-how-to-manage-mysql-databases';

const toggleCheck = () => {
  isCheckActive.value = !isCheckActive.value;
};
</script>

<template>
  <div>
    <div>
      <h2>{{ t('Domain name will not change in databases') }}</h2>
      <span class="h-mt-8 h-mb-24 text-gray">
        {{
          t(
            ' It might result in getting redirects to your old temporary domain, which will not work anymore.',
          )
        }}
      </span>
      <a
        class="text-bold-2 text-primary h-ml-4 h-mb-32"
        :href="LEARN_MORE_LINK"
        target="_blank"
      >
        {{ t('Learn more') }}
      </a>
      <HSnackbar
        background-color="danger-light"
        class="h-mt-24"
        border-color="danger-light"
        @click="toggleCheck"
      >
        <div class="warning__holder">
          <HCheckbox
            :active="isCheckActive"
            checkbox-color="danger"
            class="warning__checkbox"
          />
          <Trans tag="span">
            I understand that I will have to
            <strong>manually update</strong>
            the domain name in website's database
          </Trans>
        </div>
      </HSnackbar>

      <div class="d-flex justify-content-end">
        <HModalActions close-text="Cancel" @close="closeModal">
          <HButton
            :is-disabled="!isCheckActive"
            data-qa="temporary-domain-disclaimer-modal-connect-domain-button"
            @click="props.data.onSuccess"
          >
            {{ t('Change') }}
          </HButton>
        </HModalActions>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.warning {
  align-items: top;
  cursor: pointer;

  &__holder {
    display: flex;
  }

  &__checkbox {
    margin-right: 8px;
  }
}
</style>
