export const Client = {
  BrandId: {
    Hostinger: {
      RU: '1',
      LT: '2',
      COM_UA: '3',
      CO_ID: '4',
      COM: '5',
      ES: '6',
      COM_AR: '7',
      MX: '8',
      CO: '10',
      COM_BR: '11',
      RO: '12',
      FR: '13',
      IT: '14',
      PL: '15',
      PH: '16',
      AE: '17',
      MY: '18',
      KR: '19',
      VN: '20',
      IN_TH: '21',
      COM_TR: '22',
      PT: '23',
      DE: '24',
      IN: '25',
      JP: '26',
      NL: '28',
      CO_UK: '29',
      GR: '30',
      CZ: '31',
      HU: '32',
      SE: '33',
      DK: '34',
      FI: '35',
      SK: '36',
      NO: '37',
      HR: '38',
      COM_HK: '39',
      CO_IL: '40',
      LV: '41',
      EE: '42',
      PK: '56',
    },

    Other: {
      HOSTMANIA: '51',
      WEBLINK: '45',
      HOSTING24: '54',
      NG_CO_ID: '55',
    },
  },

  Brand: {
    HOSTINGER: 'hostinger',
    HOSTINGER_BR: 'hostinger.com.br',
    WEBLINK: 'weblink',
    WEBLINK_BR: 'weblink.com.br',
    HOSTING24: 'hosting24',
    HOSTMANIA: 'hostmania',
    NG_CO_ID: 'niagahoster',
  },
} as const;

export type THostingerBrandId =
  (typeof Client.BrandId.Hostinger)[keyof typeof Client.BrandId.Hostinger];
export type TOtherBrandId =
  (typeof Client.BrandId.Other)[keyof typeof Client.BrandId.Other];

export type TBrandId = THostingerBrandId | TOtherBrandId;
