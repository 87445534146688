import cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { YEAR_IN_DAYS } from '@/data/globalConstants';
import { Cookie } from '@/types';

export const useDeveloperToolsIntroductionStore = defineStore(
  'developerToolsIntroductionStore',
  () => {
    const isIntroductionPopoverActive = ref(
      cookies.get(Cookie.DEVELOPER_TOOLS_INTRODUCTION_CLOSED) !== '1',
    );

    const setIntroductionSeen = () => {
      isIntroductionPopoverActive.value = false;
      cookies.set(Cookie.DEVELOPER_TOOLS_INTRODUCTION_CLOSED, '1', {
        expires: YEAR_IN_DAYS,
      });
    };

    return {
      isIntroductionPopoverActive,
      setIntroductionSeen,
    };
  },
);
