<template>
  <div
    :key="completed"
    class="purchase-processing d-flex align-items-center justify-content-center"
    :style="{ minHeight: height + 'px' }"
  >
    <div class="text-center d-flex flex-column align-items-center">
      <CircleLoader v-if="!completed" class="h-mb-24" />
      <HImage
        v-else
        class="h-mb-24"
        :src="!error ? '@/images/loader-check.svg' : failedImage"
        :alt="$t('success')"
      />
      <h3 v-trans class="h-mb-0" data-qa="purchase-proccessing-title">
        {{ error ? title : completed ? 'Payment Successful' : 'Processing' }}
      </h3>
      <p v-if="showContinueSubtitle">
        {{ successContinue.subtitle }}
      </p>
      <p v-else-if="inProgress" v-trans class="h-mb-0">
        Loading payment methods
      </p>
      <p v-else-if="completed && !error" v-trans class="h-mb-0">
        You can now close this window
      </p>
      <p v-else-if="error" v-trans>
        {{ errorMessage }}
      </p>
      <HButtonV1
        v-if="isPropsButtonVisible"
        primary
        class="h-mt-24"
        @click="$emit('on-continue')"
      >
        {{ successContinue.actionText }}
      </HButtonV1>
      <HButtonV1
        v-else-if="isDefaultButtonVisible"
        primary
        outline
        md
        class="h-mt-24"
        @click="close"
      >
        Close
      </HButtonV1>
    </div>
  </div>
</template>

<script>
import CircleLoader from '@/components/Loaders/CircleLoader';
import { useModal } from '@/composables';
import { getThreeDsVerifyErrorMsg } from '@/data/billing/paymentData';

export default {
  props: {
    completed: Boolean,
    error: [Object, Boolean],
    successContinue: Object,
    showCloseButtonOnSuccess: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 560,
    },
  },
  emits: ['close', 'on-continue'],
  components: {
    CircleLoader,
  },
  setup() {
    const { closeModal } = useModal();

    return {
      closeModal,
    };
  },
  computed: {
    isPropsButtonVisible() {
      if (!this.showCloseButtonOnSuccess) return false;

      return this.completed && this.successContinue && !this.error;
    },
    isDefaultButtonVisible() {
      if (this.error) return true;

      return this.completed && this.showCloseButtonOnSuccess;
    },
    failedImage() {
      return this.is3dsVerifyError
        ? '@/images/loader-processing.svg'
        : '@/images/loader-failed.svg';
    },
    title() {
      return this.is3dsVerifyError
        ? 'Payment is Being Processed'
        : 'Payment Failed';
    },
    inProgress() {
      return !this.completed && !this.error;
    },
    errorMessage() {
      return (
        this.error?.error?.message ||
        'Whoops, something went wrong, please try again'
      );
    },
    is3dsVerifyError() {
      return getThreeDsVerifyErrorMsg.includes(this.errorMessage);
    },
    showContinueSubtitle() {
      return this.completed && !this.error && this.successContinue;
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.closeModal();
    },
  },
};
</script>
