import type { RequestConfig } from '@hpanel-components';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { wordpressRepo } from '@/repositories';
import type { WordpressTheme } from '@/types';

export const useWordPressThemesStore = defineStore(
  'wordPressThemesStore',
  () => {
    const themesData = ref<WordpressTheme[]>([]);

    const installWordPressTheme = async ({
      directory,
      theme,
      palette,
      layout,
      font,
      config,
    }: {
      directory: string;
      theme: string;
      palette?: string;
      layout?: string;
      font?: string;
      config?: RequestConfig;
    }) => {
      const [, err] = await wordpressRepo.postHostingerThemeInstall({
        directory,
        theme,
        palette,
        layout,
        font,
        config,
      });

      if (err) return;

      themesData.value = themesData.value.filter((t) => t.slug !== theme);
    };

    const fetchWordPressHostingerThemes = async ({
      orderId,
      directory,
      config,
    }: {
      orderId: string;
      directory: string;
      config?: RequestConfig;
    }) => {
      themesData.value = [];

      const [{ data }, err] = await wordpressRepo.getHostingerThemes({
        orderId,
        directory,
        config,
      });

      if (!err || data) {
        themesData.value = data;
      }
    };

    return { fetchWordPressHostingerThemes, installWordPressTheme, themesData };
  },
);
