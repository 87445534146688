export namespace Entri {
  export enum SetupType {
    AUTOMATIC = 'Automatic',
    MANUAL = 'Manual',
  }
  export enum EventType {
    ONE_ENTRI_CLOSE = 'onEntriClose',
  }
  export enum CloseStatus {
    FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
    IN_PROGRESS = 'IN_PROGRESS',
    EXISTING_RECORDS = 'EXISTING_RECORDS',
    LOGIN = 'LOGIN',
    MANUAL_CONFIGURATION = 'MANUAL_CONFIGURATION',
    EXIT_WITH_ERROR = 'EXIT_WITH_ERROR',
    DKIM_SETUP = 'DKIM_SETUP',
  }
  export enum SetupStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
  }
  export enum ServiceCategory {
    HOSTING = 'hosting',
    DOMAIN = 'domain',
  }
  export enum ServiceType {
    WEBSITE_BUILDER = 'website-builder',
    WEB_HOSTING = 'web-hosting',
  }
}
