import type { NavigationGuard } from 'vue-router';

import { useProfileStore } from '@/stores/profile/profileStore';
import { useVpsPurchaseStore } from '@/stores/vps/vpsPurchaseStore';
import { ACCESS_LEVEL, Route, VPS_PURCHASE_STEP } from '@/types';

const hVpsPurchaseGuard: NavigationGuard = async (to) => {
  const purchaseStore = useVpsPurchaseStore();
  const profileStore = useProfileStore();

  if (!profileStore.access) {
    await profileStore.fetchProfile();
  }

  if (
    profileStore.isAccessManager &&
    profileStore.access?.level !== ACCESS_LEVEL.PURCHASE
  ) {
    return { name: Route.Base.SERVERS };
  }

  if (to.name === VPS_PURCHASE_STEP.GET_PLAN) {
    if (!purchaseStore.state?.template) {
      purchaseStore.resetStep();
      purchaseStore.goToStep(VPS_PURCHASE_STEP.START);

      return;
    }

    const isTemplateRequirePanelPassword =
      purchaseStore.state?.template?.isPanelPassResetSupported &&
      !purchaseStore.state?.panelPassword;

    if (
      (!purchaseStore.isGamePanelPurchase &&
        !purchaseStore.state?.rootPassword) ||
      isTemplateRequirePanelPassword
    ) {
      purchaseStore.goToStep(VPS_PURCHASE_STEP.FEATURES);

      return;
    }
  }

  if (
    to.name === VPS_PURCHASE_STEP.FEATURES &&
    !purchaseStore.state?.template
  ) {
    purchaseStore.resetStep();
    purchaseStore.goToStep(VPS_PURCHASE_STEP.START);

    return;
  }
};

export default hVpsPurchaseGuard;
