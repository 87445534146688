import { router } from '@/router';
import type { HostingPlan } from '@/types';
import { getLocationParams } from '@/utils/getLocationParams';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const BILLING_API_URL = `${process.env.VITE_API_SSO}/v1/billing`;

export default {
  getHostingPlans() {
    return hAsync<HostingPlan[]>(http.get(`${BILLING_API_URL}/hosting`));
  },
  async getRefundInvoice(hash: string) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/refund/invoice/${hash}`, {
        hideToastr: true,
      }),
    );
  },
  async getRefundOrder(id: string) {
    return await hAsync(http.get(`${BILLING_API_URL}/refund/order/${id}`));
  },
  // @ts-ignore
  async requestRefund(payload) {
    return await hAsync(http.post(`${BILLING_API_URL}/refund`, { ...payload }));
  },
  async getPaidInvoices() {
    return await hAsync(http.get(`${BILLING_API_URL}/refund`));
  },
  // @ts-ignore
  async getProductBilling(product) {
    return await hAsync(http.get(`${BILLING_API_URL}/${product}`));
  },
  // @ts-ignore
  async getUnpaidInvoices({ limit, offset }) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/section/unpaid-invoices`, {
        params: {
          limit,
          offset,
        },
      }),
    );
  },
  // @ts-ignore
  async cancelUnpaidInvoice(invoiceId) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/cancel-unpaid-invoice`, {
        invoiceId,
      }),
    );
  },
  // @ts-ignore
  async getServices({ limit, offset, search }) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/section/get-services`, {
        params: { limit, offset, search },
      }),
    );
  },
  // @ts-ignore
  async getGraceDomains({ limit, offset, search }) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/section/get-grace-domains`, {
        params: { limit, offset, search },
      }),
    );
  },
  // @ts-ignore
  async changeServicePeriod({ order_id, period }) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/change-order-period`, {
        order_id,
        period,
      }),
    );
  },
  // @ts-ignore
  async changeServiceAutorenew(payload, params) {
    return await hAsync(
      http.post(
        `${BILLING_API_URL}/section/change-order-auto-renew`,
        payload,
        params,
      ),
    );
  },
  // @ts-ignore
  async getHostingRenewData(id) {
    return await hAsync(http.get(`${BILLING_API_URL}/renew/${id}`, {}));
  },
  // @ts-ignore
  async getHostingUpgradeData(id) {
    return await hAsync(http.get(`${BILLING_API_URL}/hosting-upgrade/${id}`));
  },
  // @ts-ignore
  async getRenewBanner(orderId) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/order/renew/banner/auto-renew`, {
        params: { orderId },
      }),
    );
  },
  // @ts-ignore
  async renewServiceInstant({ order_id, selected_period }) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/order/renew/instant`, {
        order_id,
        selected_period,
        creation_location: router.currentRoute.value.name,
      }),
    );
  },
  // @ts-ignore
  async renewServiceCreateOrder({ order_id, selected_period }) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/order/renew`, {
        order_id,
        selected_period,
      }),
    );
  },
  async upgradeServiceCreateOrder({
    // @ts-ignore
    order_id,
    // @ts-ignore
    selected_period,
    // @ts-ignore
    selected_product_id,
  }) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/order/upgrade`, {
        order_id,
        selected_period,
        selected_product_id,
      }),
    );
  },
  // @ts-ignore
  async renewServiceOrder(order_id) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/renew-service`, { order_id }),
    );
  },
  // @ts-ignore
  async autoRenewAllServices(status) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/change-all-auto-renew`, {
        status,
      }),
    );
  },
  async getPaymentHistory({
    limit = 0,
    offset = 0,
    search = '',
    from_date = '',
    to_date = '',
    pending = 0,
  }) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/section/payment-history`, {
        params: {
          limit,
          offset,
          search,
          from_date,
          to_date,
          pending,
        },
      }),
    );
  },
  async getBlackFridayData() {
    return await hAsync(
      http.get(`${BILLING_API_URL}/order/upgrade/hosting/black-friday`),
    );
  },
  async getBlackFridayAvailability() {
    return await hAsync(http.get(`${BILLING_API_URL}/black-friday`));
  },
  // @ts-ignore
  async addPaymentMethod({ redirectCancel, redirectReturn, merchantAccounts }) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/add-payment-method`, {
        redirectCancel: `${window.location.origin}${redirectCancel}`,
        redirectReturn: `${window.location.origin}${redirectReturn}`,
        merchantAccounts,
      }),
    );
  },
  // @ts-ignore
  async removePaymentMethod(paymentMethodId) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/remove-payment-method`, {
        paymentMethodId,
      }),
    );
  },
  // @ts-ignore
  async setDefaultPaymentMethod(paymentMethodId) {
    return await hAsync(
      http.post(`${BILLING_API_URL}/section/set-default-payment-method`, {
        paymentMethodId,
      }),
    );
  },
  // @ts-ignore
  async renewServiceShow(params, settings = {}) {
    return await hAsync(
      http.get(`${BILLING_API_URL}/order/renew`, { params, ...settings }),
    );
  },
  // @ts-ignore
  instantVpsRenew(data) {
    return hAsync(
      http.post(`${BILLING_API_URL}/order/renew/instant`, {
        ...data,
        ...getLocationParams(),
      }),
    );
  },
  // @ts-ignore
  instantVpsUpgrade(data) {
    return hAsync(
      http.post(`${BILLING_API_URL}/order/upgrade/instant`, {
        ...data,
        ...getLocationParams(),
      }),
    );
  },

  getCpanelHosting() {
    return hAsync(http.get(`${BILLING_API_URL}/cpanelHosting`));
  },
};
