<script lang="ts" setup>
import { onBeforeUnmount } from 'vue';

type Props = {
  data: {
    iframeUrl: string;
  };
};

interface Emits {
  (e: 'success'): void;
  (e: 'cancel'): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const onMessage = ({ data }: MessageEvent) => {
  if (data.event === 'cvc_refreshed') {
    emit('success');

    return;
  }

  if (data.event === 'close_modal') {
    emit('cancel');

    return;
  }
};

window.addEventListener('message', onMessage);

onBeforeUnmount(() => {
  window.removeEventListener('message', onMessage);
});
</script>

<template>
  <iframe :src="data.iframeUrl" class="cvc-iframe" />
</template>

<style lang="scss" scoped>
.cvc-iframe {
  border: none;
  overflow: hidden;
  width: calc(100% + 5px);
  min-height: 416px;
}
</style>
