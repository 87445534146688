import { onboardingRepo, wordpressRepo } from '@/repositories';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';
import { useProfileStore } from '@/stores';
import { Onboarding } from '@/types';

const initial = {
  cms: {
    email: '',
    password: '',
    language: '',
  },
  selectedCms: '',
  selectedCmsName: '',

  freeDomainSelected: false,
  autoinstallerApps: [],
  autoinstallerAppsLoaded: false,
  suggestedWordpressPlugins: [],
  selectedWordpressPlugins: [],
  aiGenerateContent: null,
  wordpressPluginsStepViewed: false,
  selectedWordpressPreset: null,
  selectedTemplate: null,
};

export default {
  state: {
    initial,
    autoinstallerApps: [],
    autoinstallerAppsLoaded: false,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setInitialCmsCredentials(state) {
      setForDomain(state, currentOrderId(), ' ', initial.selectedCms);
      setForDomain(state, currentOrderId(), 'cms', initial.cms);
    },
    setCmsCredentials(state, { cmsDetails, cms }) {
      setForDomain(state, currentOrderId(), 'selectedCms', cms);
      setForDomain(state, currentOrderId(), 'cms', cmsDetails);
    },
    setSelectedCms(state, data) {
      setForDomain(state, currentOrderId(), 'selectedCms', data);
    },
    setSelectedCmsName(state, data) {
      setForDomain(state, currentOrderId(), 'selectedCmsName', data);
    },
    setAutoinstallerApps(state, data) {
      state.autoinstallerApps = data;
    },
    setAutoinstallerAppsLoaded(state, data) {
      state.autoinstallerAppsLoaded = data;
    },
    SET_FREE_DOMAIN_VALUE(state, data) {
      setForDomain(state, currentOrderId(), 'freeDomainSelected', data);
    },
    SET_SUGGESTED_WORDPRESS_PLUGINS(state, data) {
      setForDomain(state, currentOrderId(), 'suggestedWordpressPlugins', data);
    },
    SET_SELECTED_WORDPRESS_PLUGINS(state, data) {
      setForDomain(state, currentOrderId(), 'selectedWordpressPlugins', data);
    },
    SET_AI_GENERATE_CONTENT(state, data) {
      setForDomain(state, currentOrderId(), 'aiGenerateContent', data);
    },
    SET_WORDPRESS_PLUGINS_STEP_VIEWED(state, data) {
      setForDomain(state, currentOrderId(), 'wordpressPluginsStepViewed', data);
    },
    SET_SELECTED_WORDPRESS_PRESET(state, data) {
      setForDomain(state, currentOrderId(), 'selectedWordpressPreset', data);
    },
    SET_SELECTED_TEMPLATE_DETAILS(state, data) {
      setForDomain(state, currentOrderId(), 'selectedTemplate', data);
    },
  },
  getters: {
    getFreeDomainValue: (state) =>
      getStateVariable(state, 'freeDomainSelected', currentOrderId()),

    getSelectedCms: (state) =>
      getStateVariable(state, 'selectedCms', currentOrderId()),
    isWooCmsSelected: (state) =>
      getStateVariable(state, 'selectedCms', currentOrderId()) === 'woo',
    getAutoinstallerApps: (state) => state.autoinstallerApps,
    getAutoinstallerAppsLoaded: (state) =>
      getStateVariable(state, 'autoinstallerAppsLoaded', currentOrderId()),
    getCmsCredentials: (state) => {
      const cmsObj = getStateVariable(state, 'cms', currentOrderId());
      const profileStore = useProfileStore();

      if (!cmsObj.email) {
        cmsObj.email = profileStore.contact?.email;
      }

      return cmsObj;
    },
    getAiGenerateContent: (state) =>
      getStateVariable(state, 'aiGenerateContent', currentOrderId()),
    isOtherCMS: (state) => {
      const selectedCms = getStateVariable(
        state,
        'selectedCms',
        currentOrderId(),
      );

      return (
        selectedCms &&
        ![
          Onboarding.Platform.WORDPRESS,
          Onboarding.Platform.WOO,
          Onboarding.Platform.BUILDER,
        ].includes(selectedCms)
      );
    },
    isWordpressCMS: (state) => {
      const selectedCms = getStateVariable(
        state,
        'selectedCms',
        currentOrderId(),
      );

      return selectedCms && selectedCms === Onboarding.Platform.WORDPRESS;
    },
    isWebsiteBuilderCMS: (state) => {
      const selectedCms = getStateVariable(
        state,
        'selectedCms',
        currentOrderId(),
      );

      return selectedCms && selectedCms === Onboarding.Platform.BUILDER;
    },
    getSelectedCmsName: (state) =>
      getStateVariable(state, 'selectedCmsName', currentOrderId()),
    getSuggestedWordpressPlugins: (state) =>
      getStateVariable(state, 'suggestedWordpressPlugins', currentOrderId()),
    getSelectedWordpressPlugins: (state) =>
      getStateVariable(state, 'selectedWordpressPlugins', currentOrderId()),
    getWordpressPluginsStepViewed: (state) =>
      getStateVariable(state, 'wordpressPluginsStepViewed', currentOrderId()),
    getSelectedWordpressPreset: (state) =>
      getStateVariable(state, 'selectedWordpressPreset', currentOrderId()),
    getSelectedTemplate: (state) =>
      getStateVariable(state, 'selectedTemplate', currentOrderId()),
    getSelectedWordpressPresetPlugins: (state) => {
      const preset = getStateVariable(
        state,
        'selectedWordpressPreset',
        currentOrderId(),
      );
      if (!preset) return [];

      return preset.plugins
        .filter((plugin) => Boolean(plugin.slug))
        .map((plugin) => plugin.slug);
    },
  },
  actions: {
    async fetchAutoinstallerApps({ commit }) {
      const [{ data }, err] = await onboardingRepo.getOnboardingAutoInstaller(
        currentOrderId(),
      );

      if (!err) {
        commit('setAutoinstallerApps', data);
        commit('setAutoinstallerAppsLoaded', true);
      }

      return [{ data }, err];
    },
    async fetchSuggestedWordpressPlugins({ commit }, websiteType) {
      const [{ data }, err] =
        await wordpressRepo.getSuggestedPluginsBasedOnWebsiteType();
      if (!err) {
        commit(
          'SET_SUGGESTED_WORDPRESS_PLUGINS',
          data[websiteType] || data.default,
        );
      }

      return data;
    },
    clearCmsData({ commit }) {
      commit('setSelectedCms');
      commit('setSelectedCmsName');
      commit('setInitialCmsCredentials');
    },
  },
};
