import type {
  IHostingAccount,
  IHostingRedirect,
  IHostingRedirectCreateRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/redirects`,

  async getRedirects(
    accountIdentifier: HostingWebsiteWebsiteAccountIdentifier,
  ) {
    return await hAsync<IHostingRedirect>(
      http.get(this.url(accountIdentifier)),
    );
  },

  async createRedirect(
    accountIdentifier: HostingWebsiteWebsiteAccountIdentifier,
    requestData: IHostingRedirectCreateRequest,
  ) {
    return await hAsync<IHostingRedirect>(
      http.post(this.url(accountIdentifier), requestData, {
        plain: true,
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async deleteRedirect(
    accountIdentifier: HostingWebsiteWebsiteAccountIdentifier,
    redirectId: IHostingRedirect['id'],
  ) {
    return await hAsync(
      http.delete(`${this.url(accountIdentifier)}/${redirectId}`),
    );
  },
};
