<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import WrapperALink from '@/components/WrapperLinks/WrapperALink.vue';
import type Router from '@/utils/services/navigation/RouteClass';

type Props = {
  item: Router;
  customClass?: string;
  customActiveClass?: string;
  link: string;
  title?: string | boolean;
  isDisabled?: boolean;
};

const props = defineProps<Props>();

type Emits = {
  click: [];
  'send-amplitude': [];
};

const emit = defineEmits<Emits>();

const route = useRoute();

const activeClass = computed(
  () => props.customActiveClass || 'h-nav__item--active',
);

const linkTitle = computed(
  () => props.title || props.item.breadcrumbsTitle || props.item.title,
);

const linkTo = computed(() =>
  props.link?.replace('{domain}', route.params?.domain as string),
);

const isActive = computed(() => route.name === props.item?.element?.name);

const tooltip = computed(() => (props.isDisabled ? 'Coming soon' : null));

const onClick = () => {
  if (props.isDisabled) return;

  emit('click');
  emit('send-amplitude');
};
</script>
<template>
  <li
    v-tooltip="tooltip"
    class="h-nav__item"
    :class="[{ [activeClass]: isActive }, customClass]"
    @click="onClick"
  >
    <WrapperALink
      :href="linkTo"
      :active="true"
      target="_blank"
      class="h-nav__link"
      :class="{ 'h-nav__link--disabled': isDisabled }"
    >
      <slot name="icon" />
      <Trans v-if="linkTitle" class="h-nav__link-text">
        {{ linkTitle }}
      </Trans>
    </WrapperALink>
  </li>
</template>

<style lang="scss" scoped>
.h-nav__item.h-nav__submenu {
  &-item {
    padding: 8px 0;
    border-radius: 0px 32px 32px 0px;
    color: var(--gray);
    cursor: pointer;

    &:hover {
      background-color: rgba(218, 220, 224, 0.24);
    }

    .h-nav__link {
      padding-left: 80px;
      padding-right: 12px;

      &:hover > * {
        color: var(--gray);
      }

      &-text {
        white-space: pre-wrap;
      }

      &--disabled {
        cursor: not-allowed;
        text-decoration: none;
      }
    }
  }

  &--active {
    background-color: var(--primary-hover);
    color: var(--primary);
  }
}
</style>
