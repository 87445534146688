<script lang="ts" setup>
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { isObject } from 'lodash';
import { computed, ref } from 'vue';

import DCLocationsMap from '@/components/Modals/HModalFancy/Onboarding/DCLocationsMap.vue';
import { useGlobals, useModal } from '@/composables';
import { FieldType } from '@/types';

type Props = {
  data: {
    selectedDataCenter: {
      label: string;
      value: string;
    };
    dataCenter: Record<string, string>;
    dataCenters: Record<string, string>;
    coordinates: { [key: string]: string };
    onSuccess: (param: any) => void;
  };
};

const props = defineProps<Props>();
const { t } = useGlobals();
const { closeModal } = useModal();

const selectedDataCenter = ref(props.data.selectedDataCenter);

const locationsSchema = computed(
  (): FieldSchema => ({
    type: FieldType.select,
    value: selectedDataCenter.value.value,
    values: dcOptions.value,
    custom: {
      itemsPerList: 5,
    },
  }),
);

const dcOptions = computed(() => {
  const { dataCenter } = props.data;
  const dcOptionsArray = Object.keys(props.data.dataCenters).map((dcKey) => ({
    label: t(props.data.dataCenters[dcKey]),
    value: dcKey,
  }));
  if (isObject(dataCenter)) {
    dcOptionsArray.sort((x, y) => {
      if (x.value === Object.keys(dataCenter)[0]) return -1;
      if (y.value === Object.keys(dataCenter)[0]) return 1;

      return 0;
    });
  }

  return dcOptionsArray;
});

const locations = computed(() =>
  dcOptions.value
    .map((location) => {
      const isSelected = selectedDataCenter.value.value === location.value;

      return {
        ...location,
        positions: getMapPosition(props.data.coordinates[location.value]),
        selected: isSelected,
      };
    })
    .filter((location) => location),
);

const getMapPosition = (coordinates: string) => {
  const [lat, lng] = coordinates.split(',');

  const mapWidth = 518;
  const mapHeight = 340;
  const x = Number(lng) * (mapWidth / 360);

  const latRad = (Number(lat) * Math.PI) / 180;
  const mercN = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
  const y = mapHeight / 2 - (mapWidth * mercN) / (2 * Math.PI);

  return { x: x + 206, y: y + 39.8 };
};

const onLocationChange = (selectedDC?: string) => {
  if (!selectedDC) return;

  selectedDataCenter.value =
    dcOptions.value.find(({ value }) => value === selectedDC) ??
    selectedDataCenter.value;
};

const saveDataCenter = ({ values }: FormValues) => {
  const selectedOption = dcOptions.value.find(
    ({ value }) => value === values.location,
  );
  if (!selectedOption) return;

  closeModal();

  props.data?.onSuccess(selectedOption.value);
};
</script>

<template>
  <div>
    <HForm
      ref="form"
      class="text-right"
      @on-submit="saveDataCenter($event)"
      @on-change="onLocationChange($event.values.location)"
    >
      <p class="text-left">
        {{
          t(
            'To get the best website speed, choose the server location which is nearest to your target audience',
          )
        }}
      </p>
      <DCLocationsMap
        class="h-mt-24 h-mb-16"
        :locations="locations"
        @select-location="onLocationChange($event.value)"
      />

      <HFormField name="location" :schema="locationsSchema" />

      <div class="button-container">
        <HButton h-form-submit>
          {{ t('Confirm') }}
        </HButton>
      </div>
    </HForm>
  </div>
</template>

<style lang="scss" scoped>
.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
