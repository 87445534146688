<script lang="ts" setup>
import { ref, nextTick, onMounted } from 'vue';

import HMenuItemWrapper from '@/components/HMenuV2/HMenuItemWrapper.vue';
import { useGlobals } from '@/composables';
import { HIcon, type NavigationMenuItem } from '@/types';

const { t } = useGlobals();

type Props = {
  feedbackOpen?: boolean;
  feedbackDisabled?: boolean;
  menu?: HTMLElement;
};

type Emits = {
  (eventName: 'toggle-feedback'): void;
};
const isFeedbackButtonFixed = ref(true);

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const getFeedbackButtonType = () => {
  const headerOffset = 150;
  isFeedbackButtonFixed.value =
    window.innerHeight - (props.menu?.clientHeight || 0) > headerOffset;
};

const feedbackButton = ref<NavigationMenuItem>({
  title: 'Give feedback',
  slug: 'feedback',
});

onMounted(async () => {
  await nextTick();
  getFeedbackButtonType();
  window.addEventListener('resize', getFeedbackButtonType);
});
</script>

<template>
  <HMenuItemWrapper
    :id="feedbackButton.slug"
    :navigation-item="feedbackButton"
    class="hp-menu__footer hp-menu__item-link"
    :class="{
      'hp-menu__item-link--active': !feedbackDisabled && feedbackOpen,
      'hp-menu__item-link--disabled': feedbackDisabled,
    }"
    @click="!feedbackDisabled && emit('toggle-feedback')"
  >
    <template #icon>
      <HpIcon
        view-box="0 0 16 16"
        height="16"
        width="16"
        :color="feedbackOpen ? 'primary' : 'gray'"
        class="hp-menu__item-link-icon"
        :icon="HIcon.IC_SPEECH_BUBBLE_16"
      />
    </template>
    <span
      v-tooltip.top="
        feedbackDisabled && {
          content:
            'We have received your last feedback. Feedback can be left once an hour.',
          size: 200,
        }
      "
      class="hp-menu__item-title"
    >
      {{ t(feedbackButton.title) }}
    </span>
  </HMenuItemWrapper>
</template>
