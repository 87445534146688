import HButtonV1 from '@/components/HButtons/HButtonV1';
import HModalActions from '@/components/HModals/HModalActions';

export default {
  components: {
    HButtonV1,
    HModalActions,
  },
  props: {
    currentStep: Number,
    data: [Object, String, Number, Boolean, Array],
  },
  methods: {
    goToNextStep(step) {
      this.$emit('next-step', step);
    },
    goToPreviousStep(step) {
      this.$emit('previous-step', step);
    },
    close() {
      this.$emit('close');
    },
  },
};
