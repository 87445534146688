<template>
  <a
    href
    class="close-btn"
    :class="classes"
    @click.prevent="$emit('click', $event)"
  >
    <span v-if="showText" v-trans class="close-btn__wording">Close</span>
    <HpIcon class="header-menu__icon" primary static-view-box icon="ic-close" />
  </a>
</template>
<script>
export default {
  name: 'HCloseButton',
  props: {
    right: Boolean,
    light: Boolean,
    showText: Boolean,
  },
  emits: ['click'],
  computed: {
    classes() {
      return {
        'close-btn--right': this.right,
        'close-btn--light': this.light,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/components/close-btn';
</style>
