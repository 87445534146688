import type { NavigationGuard } from 'vue-router';

import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { useFrontendSettingsStore } from '@/stores/frontendSettingsStore';
import { Route } from '@/types';

export const transferListGuard: NavigationGuard = async () => {
  const hDomainResourceStore = useHDomainResourceStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!hDomainResourceStore.hasHDomainResources) {
    frontendSettingsStore.setState('pageLoading', true);
    await hDomainResourceStore.fetchHDomainsResources();
  }

  const canShowTransferList =
    !!hDomainResourceStore.getDomainsToMoveList.length ||
    !!hDomainResourceStore.transferInList.length;

  if (!canShowTransferList) {
    return { name: Route.Domain.TRANSFER_DOMAIN };
  }
};
