import type { NavigationGuard } from 'vue-router';

import { useFrontendSettingsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';

export const domainResourceGuard: NavigationGuard = async (to, from, next) => {
  const { fetchHDomainsResources, hDomainsResources } =
    useHDomainResourceStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!hDomainsResources.length) {
    frontendSettingsStore.setState('pageLoading', true);
    await fetchHDomainsResources();
    frontendSettingsStore.setState('pageLoading', false);
  }

  next();
};
