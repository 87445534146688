// MOVED TO MAIN PROJECT

import { isObject } from 'lodash';

/**
 * @param obj - Object to remove keys from
 * @param keysToRemove - Array of keys to remove
 * @returns - Object with keys removed
 */
export const removeKeysFromObject = (
  obj: Record<string, any>,
  keysToRemove: Readonly<string[]>,
) =>
  Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
    if (isObject(obj[key])) {
      acc[key] = removeKeysFromObject(obj[key], keysToRemove);
    } else if (!keysToRemove.includes(key)) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
