<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useCpanelWordpress, useDomain } from '@/composables';
import { Route } from '@/types';

const router = useRouter();
const { domain } = useDomain();

const { fetchWordpressInstallations } = useCpanelWordpress();

const isLoading = ref(true);

const refresh = async () => {
  isLoading.value = true;
  await fetchWordpressInstallations();
  router.push({
    name: Route.CPanel.WORDPRESS,
    params: {
      domain: domain.value,
    },
  });
  window.location.reload();
  isLoading.value = false;
};
</script>

<template>
  <HButtonV1 text primary centered @click="refresh">
    <template #icon>
      <HpIcon
        class="icon-primary"
        icon="icon-refresh"
        :width="18"
        :height="18"
      />
    </template>
    Refresh
  </HButtonV1>
</template>
