<script lang="ts" setup>
import { FieldType } from '@hostinger/hcomponents';
import { computed, ref, onUnmounted } from 'vue';

import PurchasePaymentMethod from '@/components/Modals/Parts/PurchasePaymentMethod.vue';
import { usePaymentMethod } from '@/composables/hBilling/usePaymentMethod';
import { useGlobals } from '@/composables/useGlobals';
import { usePaymentMethodStore } from '@/stores/paymentMethodStore';
import { Payment } from '@/types/enums/PaymentEnum';
import type { PaymentMethod } from '@/types/models/billing/paymentMethodModels';

const { t } = useGlobals();

const { allPaymentMethods: paymentMethods, getPaymentMethodById } =
  usePaymentMethod();

const paymentMethodStore = usePaymentMethodStore();

const getInitialPaymentMethod = () => {
  if (paymentMethodStore.paymentMethod) {
    return String(paymentMethodStore.paymentMethod.id);
  }

  return Payment.NEW_PAYMENT_METHOD;
};

const selectedPaymentMethod = ref<string>(getInitialPaymentMethod());

const paymentSelectSchema = computed(() => ({
  type: FieldType.select,
  values: [
    ...paymentMethods.value.map((paymentMethod) => ({
      label: paymentMethod.identifier,
      value: String(paymentMethod.id),
      disabled: paymentMethod.isExpired,
    })),
    {
      label: t('v2.choose.a.different.payment.method'),
      value: Payment.NEW_PAYMENT_METHOD,
    },
  ],
  disabled: !paymentMethods.value.length,
  value: selectedPaymentMethod.value,
  skipDisplayReq: true,
  custom: {
    customElement: true,
    hideOptionalLabel: true,
    customSelectedComponent: true,
  },
}));

const onPaymentMethodChange = () => {
  paymentMethodStore.setSelectedPaymentMethod(selectedPaymentMethod.value);
};

const handlePaymentChange = (event: { values: { payment: string } }) => {
  const paymentMethodId = event.values.payment;

  if (paymentMethodId === Payment.NEW_PAYMENT_METHOD) {
    selectedPaymentMethod.value = paymentMethodId;
    onPaymentMethodChange();

    return;
  }

  const selectedInputPaymentMethod: PaymentMethod | null =
    getPaymentMethodById(paymentMethodId);

  if (selectedInputPaymentMethod) {
    selectedPaymentMethod.value = paymentMethodId;
    onPaymentMethodChange();
  }
};
const isSelected = (id: string) => paymentSelectSchema.value.value === id;

onUnmounted(() => {
  paymentMethodStore.clearAllRazorpayData();
});
</script>

<template>
  <span class="payment-methods__header">{{ t('v2.payment.method') }}</span>
  <HForm @on-change="handlePaymentChange($event)">
    <HFormField name="payment" :schema="paymentSelectSchema">
      <template #selectedField>
        <PurchasePaymentMethod
          qa-tag="selected-payment-method"
          :payment-method="getPaymentMethodById(selectedPaymentMethod)"
          :highlighted="false"
        />
      </template>

      <template #default="{ value }">
        <PurchasePaymentMethod
          :qa-tag="`payment-method-${value}`"
          :payment-method="getPaymentMethodById(value)"
          :highlighted="isSelected(value)"
        />
      </template>
    </HFormField>
  </HForm>
</template>
<style lang="scss" scoped>
.payment-methods__header {
  color: var(--dark);
  font-weight: 700;
}

:deep(.hp-list-item--active) {
  background: rgba(103, 61, 230, 0.08) !important;
}

:deep(.field-border--focused) {
  border: 1px solid var(--gray-border) !important;
}
</style>
