import cookies from 'js-cookie';
import { isArray, isObject, isString } from 'lodash';

import { toUnicode } from '@/utils/helpers';
import { i18n } from '@/utils/services/i18nService';

export default {
  arrayDeepSearch(array, key, value, childrenKey) {
    if (!array) return false;

    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
      const result = this.arrayDeepSearch(
        array[i][childrenKey],
        key,
        value,
        childrenKey,
      );
      if (result) {
        return result;
      }
    }
  },

  arrayFilterWithRegex(elem, key, pattern, childrenKey) {
    let string = elem[key];

    if (cookies.get('language') !== 'en_GB') string = i18n.t(string);
    if (pattern.test(string)) return true;

    if (elem[childrenKey]) {
      return (elem[childrenKey] = elem[childrenKey].filter((elem) =>
        this.arrayFilterWithRegex(elem, key, pattern, childrenKey),
      )).length;
    }
  },

  arrayUpdateByRegex(elem, key, pattern, childrenKey, update) {
    elem[update] = false;
    let string = elem[key];

    if (cookies.get('language') !== 'en_GB') string = i18n.t(string);
    if (pattern.test(string)) {
      elem[update] = true;
    } else if (elem[childrenKey]) {
      const filteredChildren = elem[childrenKey].filter((elem) =>
        this.arrayUpdateByRegex(elem, key, pattern, childrenKey, update),
      );

      elem[update] =
        filteredChildren.filter((elem) => elem[update] === true).length > 0;

      elem[childrenKey] = filteredChildren;
    }

    return true;
  },

  filterObjectByRegex(object, regex, keysNames) {
    for (const key in object) {
      if (!keysNames.includes(key)) continue;

      if (isObject(object[key]) || isArray(object[key])) {
        if (this.filterObjectByRegex(object[key], regex, keysNames)) {
          return true;
        }
      } else if (isString(object[key])) {
        let string = object[key];
        if (string.match('.+..+')) {
          string = toUnicode(object[key]);
        }
        if (string.match(regex)) return true;
      }
    }

    return false;
  },

  generate_passsword() {
    const letters = 6;

    let randomstring = '';
    for (let i = 0; i < letters; i++) {
      randomstring += Math.floor(Math.random() * letters);
    }

    return randomstring;
  },
};
