import { accountRepo, hResourcesRepo } from '@/repositories';
import { router } from '@/router';
import store from '@/store/index';
import type {
  IHResource,
  OnboardingStatusData,
  OnboardingStatus,
  HAsyncError,
} from '@/types';
import {
  Route,
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  ONBOARDING_STATUSES,
} from '@/types';
import { extractHAsyncErrorMessage } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const HAPI_EXCEPTION_ONBOARIDNG_DOES_NOT_EXISTS = 'Onboarding does not exist';
const STATUS_COMPLETED = 'completed';
const STATUS_IN_PROGRESS = 'started:website_builder';
const ONBOARDING_TYPE_WEBSITE_BUILDER = 'website_builder';

export const onboardingRoutingByStatus = (
  data: { status: OnboardingStatus } | 0 | false,
  allowRouting = true,
  isCpanelOnboarding = false,
) => {
  if (data === false && !isCpanelOnboarding) {
    return router.push({ name: Route.Base.HOSTING });
  }

  if (data === 0) return;

  if (typeof data === 'boolean') return;

  if (
    allowRouting &&
    [Route.Onboarding.COMPLETED, Route.Onboarding.DOMAIN_SETUP].indexOf(
      router.currentRoute.value.name as Route.Onboarding,
    ) < 0
  ) {
    // Account create failed (failed_account_create)
    if (data.status === ONBOARDING_STATUSES.FAILED_ACCOUNT_CREATE) {
      router.push({
        name: 'being_built',
        params: router.currentRoute.value.params,
      });

      return;
    }

    // Any Other Failure
    if (
      (data.status.includes(ONBOARDING_STATUSES.GENERAL_FAILED) ||
        (
          [
            ONBOARDING_STATUSES.ACTIVATED,
            ONBOARDING_STATUSES.COMPLETED,
          ] as OnboardingStatus[]
        ).includes(data.status)) &&
      router.currentRoute.value.name !== Route.Onboarding.BEING_BUILT
    ) {
      setTimeout(() => {
        store.commit('setShowProgressBar', false);
        router.push({
          name: Route.Onboarding.COMPLETED,
          params: router.currentRoute.value.params,
        });
      }, 2000);

      return;
    }

    // Migration completed (completed_migration)
    if (data.status === ONBOARDING_STATUSES.COMPLETED_MIGRATION) {
      store.commit('setShowProgressBar', false);
      router.push({
        name: Route.Onboarding.BEING_TRANSFERRED,
        params: router.currentRoute.value.params,
      });

      return;
    }

    // Building in progress
    if (
      data.status.includes(ONBOARDING_STATUSES.GENERAL_COMPLETED) ||
      data.status === ONBOARDING_STATUSES.PENDING
    ) {
      router.push({
        name: Route.Onboarding.BEING_BUILT,
        params: router.currentRoute.value.params,
      });
    }
  }
};

export const getOnboardingAccountStatus = async (
  subscriptionId: string,
  domain: string,
): Promise<[{ data: OnboardingStatusData | 0 }, HAsyncError]> => {
  const [{ data }, hapiStatusError] = await accountRepo.getOnboardingStatus({
    orderId: subscriptionId,
    domain,
  });

  if (hapiStatusError) {
    if (
      extractHAsyncErrorMessage(hapiStatusError) ===
      HAPI_EXCEPTION_ONBOARIDNG_DOES_NOT_EXISTS
    ) {
      return [{ data: 0 }, null];
    }

    return [{ data: 0 }, hapiStatusError];
  }

  if (typeof data === 'boolean') return [{ data: 0 }, null];

  if (data.type !== ONBOARDING_TYPE_WEBSITE_BUILDER) {
    return [{ data }, null];
  }

  const [resources, resourceError] = await hResourcesRepo.getResources(
    { subscriptionId },
    { overrideCache: true },
  );

  if (resourceError) {
    errorLogger.logError(`Onboarding complete error - 'resource fetch failed'`);

    return [{ data: 0 }, resourceError];
  }

  const [resource] = resources.data;

  if (!resource) {
    return [{ data: 0 }, null];
  }

  return getOnboardingWebsiteBuilderStatus(resource, domain, data);
};

export const getOnboardingWebsiteBuilderStatus = async (
  resource: IHResource,
  domain: string,
  onboardingStatusData: OnboardingStatusData,
): Promise<
  [{ data: { status: string } & OnboardingStatusData }, HAsyncError]
> => {
  const existingOnboardingStatusData = { ...onboardingStatusData };

  const searchQuery = {
    type: [HRESOURCES_TYPE.WEBSITE_BUILDER],
    status: HRESOURCES_STATE.ACTIVE,
    relatedResourceId: resource.id,
  };

  const [{ data }, websiteBuilderResourcesError] =
    await hResourcesRepo.getResources(searchQuery, { overrideCache: true });

  const websiteBuilderResource = data?.find(
    ({ title, state }) => title === domain && state === HRESOURCES_STATE.ACTIVE,
  );

  if (websiteBuilderResource) {
    existingOnboardingStatusData.websiteBuilderResourceId =
      websiteBuilderResource.id;
  }

  return [
    {
      data: {
        ...existingOnboardingStatusData,
        status: websiteBuilderResource ? STATUS_COMPLETED : STATUS_IN_PROGRESS,
      },
    },
    websiteBuilderResourcesError,
  ];
};
