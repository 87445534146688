<template>
  <div class="label" :class="classes">
    <slot v-if="noTrans" />
    <Trans v-else :translate-params="translateParams">
      <slot />
    </Trans>
  </div>
</template>

<script>
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

export default {
  props: {
    noTrans: Boolean,
    noArrow: Boolean,
    outline: Boolean,
    paddingXs: Boolean,
    top: Boolean,
    md: Boolean,
    lg: Boolean,
    paddingSm: Boolean,
    borderRadiusLg: Boolean,
    gray: Boolean,
    darkGray: Boolean,
    success: Boolean,
    primary: Boolean,
    primaryLight: Boolean,
    yellow: Boolean,
    secondary: Boolean,
    info: Boolean,
    warning: Boolean,
    warningDark: Boolean,
    danger: Boolean,
    dangerLight: Boolean,
    meteorite: Boolean,
    light: Boolean,
    dark: Boolean,
    fontDark: Boolean,
    slim: Boolean,
    translateParams: {
      type: Object,
    },
    bodyClass: [String, Object],
    backgroundColor: String,
  },

  computed: {
    classes() {
      return {
        'label--no-arrow': this.noArrow,
        'label--padding-xs': this.paddingXs,
        'label--outline': this.outline,
        'label--top': this.top,
        'label--md': this.md,
        'label--lg': this.lg,
        'label--padding-sm': this.paddingSm,
        'label--border-radius-lg': this.borderRadiusLg,
        'label-success': this.success && !this.outline,
        'label-primary': this.primary && !this.outline,
        'label-primary-light': this.primaryLight && !this.outline,
        'label-secondary': this.secondary && !this.outline,
        'label-info': this.info && !this.outline,
        'label-warning': this.warning && !this.outline,
        'label-warning-dark': this.warningDark && !this.outline,
        'label-danger': this.danger && !this.outline,
        'label-danger-light': this.dangerLight && !this.outline,
        'label-meteorite': this.meteorite && !this.outline,
        'label-light': this.light && !this.outline,
        'label-dark': this.dark && !this.outline,
        'label-gray': this.gray && !this.outline,
        'label-yellow': this.yellow && !this.outline,
        'label-dark-gray': this.darkGray && !this.outline,
        'label--outline-success': this.outline && this.success,
        'label--outline-primary': this.outline && this.primary,
        'label--outline-secondary': this.outline && this.secondary,
        'label--outline-info': this.outline && this.info,
        'label--outline-warning': this.outline && this.warning,
        'label--outline-warning-dark': this.warningDark && this.outline,
        'label--outline-danger': this.outline && this.danger,
        'label--outline-light': this.outline && this.light,
        'label--outline-dark': this.outline && this.dark,
        'label--outline-gray': this.outline && this.gray,
        'label-slim': this.slim,
        'label--font-dark': this.fontDark,
        'label--custom-style': this.backgroundColor,
        ...this.bodyClass,
      };
    },
    style() {
      return {
        backgroundColor: this.backgroundColor
          ? wrapInCssVar(this.backgroundColor)
          : '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/label';
.label--custom-style {
  background-color: v-bind('style.backgroundColor');
}
</style>
