import { defineStore } from 'pinia';
import { ref } from 'vue';

import { wordpressRepo } from '@/repositories';
import type {
  WordPressPluginsBasedOnWebsiteType,
  WordPressPluginsBasedOnSearch,
} from '@/types';
import { Header } from '@/types';

export const useOnboardingWordPressPluginsStore = defineStore(
  'onboardingWordPressPluginsStore',
  () => {
    const searchedPlugins = ref<WordPressPluginsBasedOnSearch>();
    const isLoadingSearchedPlugins = ref(false);
    const isSearchedPluginsLoaded = ref(false);
    const suggestedPlugins = ref<WordPressPluginsBasedOnWebsiteType>();
    const isLoadingSuggestedPlugins = ref(false);
    const isSuggestedPluginsLoaded = ref(false);
    const plugins = ref<WordPressPluginsBasedOnSearch>({});

    const fetchSuggestedPlugins = async () => {
      if (suggestedPlugins.value) return;

      isLoadingSuggestedPlugins.value = true;
      isSuggestedPluginsLoaded.value = false;
      const [{ data }, err] =
        await wordpressRepo.getSuggestedPluginsBasedOnWebsiteType();
      isLoadingSuggestedPlugins.value = false;

      if (err) return;

      const allSuggested = Object.values(data).flat();

      allSuggested.forEach((plugin) => {
        plugins.value[plugin.slug] = plugin;
      });

      suggestedPlugins.value = data;
      isSuggestedPluginsLoaded.value = true;
    };

    const fetchSearchedPlugins = async ({
      search,
      clientId,
    }: {
      search: string;
      clientId: string;
    }) => {
      isLoadingSearchedPlugins.value = true;
      isSearchedPluginsLoaded.value = false;

      const [{ data }, err] = await wordpressRepo.getSearchedPlugins({
        params: { search },
        headers: { [Header.CLIENT_ID]: clientId },
      });
      isLoadingSearchedPlugins.value = false;

      if (err) return;

      Object.entries(data).forEach(([key, value]) => {
        plugins.value[key] = value;
      });

      searchedPlugins.value = data;
      isSearchedPluginsLoaded.value = true;
    };

    return {
      fetchSearchedPlugins,
      searchedPlugins,
      isLoadingSearchedPlugins,
      isSearchedPluginsLoaded,
      fetchSuggestedPlugins,
      suggestedPlugins,
      isLoadingSuggestedPlugins,
      isSuggestedPluginsLoaded,
      plugins,
    };
  },
);
