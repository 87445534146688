import { Route, type IArticleCard } from '@/types';

export const VPS_ARTICLES: Record<string, IArticleCard[]> = {
  onboarding: [
    {
      title: 'How to Use the {templateName} on VPS',
      link: '',
    },
    {
      title: 'How to Point a Domain to VPS',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'How to Migrate Your Website From Shared Hosting to VPS',
      link: 'https://www.hostinger.com/tutorials/migrate-website-from-shared-hosting-to-vps',
    },
  ],
  onboardingGamePanel: [
    {
      title: 'Quick Start Guide for Game Panel',
      link: 'https://support.hostinger.com/articles/4541961',
    },
    {
      title: 'How to Install Mods in Minecraft Using Game Panel',
      link: 'https://support.hostinger.com/articles/5379405',
    },
    {
      title: 'What Game Servers Are Supported on Game Panel?',
      link: 'https://support.hostinger.com/articles/7877961',
    },
  ],
  settings: [
    {
      title: 'How to Change the DNS Resolvers on VPS',
      link: 'https://support.hostinger.com/articles/5726596',
    },
    {
      title: 'How to Solve the Most Common Errors in SSH',
      link: 'https://support.hostinger.com/articles/5634587',
    },
    {
      title: 'How to Change your VPS SSH Password',
      link: 'https://support.hostinger.com/articles/8942826',
    },
    {
      title: 'How to Change a Password in Linux for Root and Other Users',
      link: 'https://www.hostinger.com/tutorials/how-to-change-password-in-linux',
    },
    {
      title:
        'How to Fix the "Your Connection Is Not Private" Error on VPS Login',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  emergencyMode: [
    {
      title: 'How to Use Emergency Mode on Your VPS',
      link: 'https://support.hostinger.com/articles/5726577',
    },
    {
      title: 'VPS Emergency Mode: Where to Find Files',
      link: 'https://support.hostinger.com/articles/8600853',
    },
    {
      title: 'VPS Emergency Mode: How to Change Apparent Root on Your Disk',
      link: 'https://support.hostinger.com/articles/8611503',
    },
    {
      title: 'How to Recover MySQL Databases From MySQL Data Files in VPS',
      link: 'https://support.hostinger.com/articles/4635603',
    },
    {
      title: 'What to Do if Your VPS Is in Grub Rescue Mode',
      link: 'https://support.hostinger.com/articles/8624545',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  dnsManager: [
    {
      title: 'How to Point a Domain to Your VPS',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'How to Find Your VPS IP Address',
      link: 'https://support.hostinger.com/articles/5139756',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  licenses: [
    {
      title: 'Why Is the VPS License Blocked',
      link: 'https://support.hostinger.com/articles/7193948',
    },
    {
      title: 'How to Purchase a Control Panel License for VPS',
      link: 'https://support.hostinger.com/articles/7034536',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  snapshotsBackups: [
    {
      title: 'How to Back Up or Restore a VPS Server',
      link: 'https://support.hostinger.com/articles/1583232',
    },
    {
      title: 'How to Connect to VPS Using SFTP',
      link: 'https://support.hostinger.com/articles/8868611',
    },
    {
      title: 'How to Activate Daily Backups',
      link: 'https://support.hostinger.com/articles/1665153',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  serverUsage: [
    {
      title: 'What Is the CPU Use Limit for VPS?',
      link: 'https://support.hostinger.com/articles/6899741',
    },
    {
      title: 'How to Check Detailed RAM Usage on Your VPS',
      link: 'https://support.hostinger.com/articles/6867460',
    },
    {
      title: 'What Is the Difference Between PHP Memory Limit and RAM?',
      link: 'https://support.hostinger.com/articles/4466613',
    },
    {
      title: 'How to kill a process in Linux from the command line',
      link: 'https://www.hostinger.com/tutorials/how-to-kill-a-process-in-linux',
    },
    {
      title: 'How to Check VPS Resources Usage',
      link: 'https://support.hostinger.com/articles/4725768',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  malwareScanner: [
    {
      title: 'VPS Malware Scanner',
      link: 'https://support.hostinger.com/articles/8450363',
    },
    {
      title: 'How to Secure Your VPS From Abusive Activity',
      link: 'https://support.hostinger.com/articles/8224050',
    },
    {
      title: '16 VPS Security Tips to Prevent Attacks on Your Server',
      link: 'https://www.hostinger.com/tutorials/vps-security',
    },
    {
      title: 'How to Secure Your VPS in 2024: Maximize Your VPS Security',
      link: 'https://www.youtube.com/watch?v=K4YOTAI5IeI',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  overview: [
    {
      title: 'v2.vps_articles.steps_to_follow',
      link: 'https://www.hostinger.com/tutorials/i-bought-a-vps-now-what',
    },
    {
      title: 'v2.vps_articles.how_to_use_ssh_keys',
      link: 'https://support.hostinger.com/articles/4792364',
    },
    {
      title: 'v2.vps_articles.how_to_point_a_domain',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'v2.vps_articles.how_to_change_ssh_port',
      link: 'https://www.hostinger.com/tutorials/how-to-change-ssh-port-vps',
    },
    {
      title: 'v2.vps_articles.how_to_use_managed_firewall',
      link: 'https://support.hostinger.com/articles/8172641',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  latestActions: [
    {
      title: 'v2.vps_articles.how_to_backup_or_restore_vps',
      link: 'https://support.hostinger.com/articles/1583232',
    },
    {
      title: 'v2.vps_articles.how_to_fix_error_status',
      link: 'https://support.hostinger.com/articles/9453536',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  sshKeys: [
    {
      title: 'v2.vps_articles.how_to_generate_ssh_keys',
      link: 'https://support.hostinger.com/articles/5634532',
    },
    {
      title: 'v2.vps_articles.how_to_solve_ssh_common_errors',
      link: 'https://support.hostinger.com/articles/5634587',
    },
    {
      title: 'v2.vps_articles.how_to_fix_host_key_verification_failed',
      link: 'https://support.hostinger.com/articles/7266279',
    },
    {
      title: 'v2.vps_articles.how_to_connect_vps_via_ssh',
      link: 'https://support.hostinger.com/articles/5723772',
    },
    {
      title: 'v2.vps_articles.how_to_add_ssh_key_to_github',
      link: 'https://support.hostinger.com/articles/1583773',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
};

export const VPS_OS_ARTICLES = {
  cloudpanel: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.how_to_set_up_node_js_using_cloudpanel',
      link: 'https://support.hostinger.com/articles/9553137',
    },
    {
      title: 'v2.vps_articles.how_to_install_lets_encrypt_with_cloudpanel',
      link: 'https://support.hostinger.com/articles/8592551',
    },
    {
      title:
        'v2.vps_articles.troubleshooting_wordpress_errors_on_cyberpanel_or_cloudpanel',
      link: 'https://support.hostinger.com/articles/9625894',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  cyberpanel: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.how_to_add_website_to_cyberpanel',
      link: 'https://support.hostinger.com/articles/5387244',
    },
    {
      title: 'v2.vps_articles.how_to_assign_login_page_to_domain_at_cyberpanel',
      link: 'https://support.hostinger.com/articles/5387340',
    },
    {
      title: 'v2.vps_articles.how_to_install_wordpress_on_cyberpanel',
      link: 'https://support.hostinger.com/articles/5387532',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  directadmin: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title:
        'v2.vps_articles.how_to_migrate_website_from_cpanel_to_directadmin',
      link: 'https://support.hostinger.com/articles/5891034',
    },
    {
      title:
        'v2.vps_articles.how_to_migrate_website_from_directadmin_to_hostinger_directadmin',
      link: 'https://support.hostinger.com/articles/5891011',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  plesk: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.how_to_use_plesk',
      link: 'https://www.hostinger.com/tutorials/how-to-use-plesk',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  webmin: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.how_to_install_lets_encrypt_on_webmin',
      link: 'https://www.hostinger.com/tutorials/how-to-install-lets-encrypt-ssl-on-virtualmin',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  hestiacp: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.how_to_reset_login_credentials_on_vps',
      link: 'https://support.hostinger.com/articles/6484943',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  applications: [
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title:
        'v2.vps_articles.how_to_point_domain_to_vps_using_a_records_and_nameservers',
      link: 'https://www.hostinger.com/tutorials/dns/how-to-point-domain-to-vps',
    },
    {
      title: 'v2.vps_articles.iptables_tutorial',
      link: 'https://www.hostinger.com/tutorials/iptables-tutorial',
    },
    {
      title: 'v2.vps_articles.protect_your_linux_with_best_malware_scanners',
      link: 'https://www.hostinger.com/tutorials/best-linux-malware-scanners',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  gamePanel: [
    {
      title: 'v2.vps_articles.how_to_optimize_a_dedicated_game_server',
      link: 'https://www.hostinger.com/tutorials/how-to-optimize-a-dedicated-game-server',
    },
    {
      title: 'v2.vps_articles.what_game_servers_are_supported_on_game_panel',
      link: 'https://support.hostinger.com/articles/7877961',
    },
    {
      title: 'v2.vps_articles.how_to_upgrade_the_game_panel',
      link: 'https://support.hostinger.com/articles/8226990',
    },
    {
      title:
        'v2.vps_articles.how_to_install_mods_in_minecraft_using_game_panel',
      link: 'https://support.hostinger.com/articles/5379405',
    },
    {
      title: 'v2.vps_articles.how_to_allocate_more_ram_to_minecraft_server',
      link: 'https://www.hostinger.com/tutorials/how-to-allocate-more-ram-to-minecraft',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  default: [
    {
      title: 'v2.vps_articles.how_to_migrate_vps_to_hostinger',
      link: 'https://support.hostinger.com/articles/2774885',
    },
    {
      title: 'v2.vps_articles.how_to_fix_connection_not_private',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
};
