<template>
  <ul class="periods">
    <PurchasePeriod
      v-for="(item, index) in periods"
      :key="index"
      :item="item"
      :is-purchase="isPurchase"
      :is-yearly="isYearly"
      :label-color="labelColor"
      :show-features="showFeatures"
      :has-mailbox="hasMailbox"
      :is-static-option="isStaticOption"
      :is-upgrade="isUpgrade"
      :is-domain-purchase="isDomainPurchase"
      :active="selectedPriceId === item.id"
      :options-number="periods.length"
      :is-estimation-loading="isEstimationLoading"
      :is-plan="isPlan"
      @change="periodSelected"
      @change-whois-privacy="changeWhoisPrivacy"
    />
  </ul>
</template>

<script>
import PurchasePeriod from '@/components/Modals/Parts/PurchasePeriod.vue';

export default {
  components: { PurchasePeriod },
  props: {
    prices: {
      type: Array,
      required: true,
    },
    isPurchase: Boolean,
    isYearly: Boolean,
    isAddon: Boolean,
    isStaticOption: Boolean,
    labelColor: String,
    showFeatures: Boolean,
    hasMailbox: Boolean,
    isDomainPurchase: Boolean,
    isEstimationLoading: Boolean,
    isPlan: Boolean,
    preSelectedPeriod: {
      type: String,
      default: null,
    },
    isUpgrade: Boolean,
  },
  emits: ['change', 'change-whois-privacy'],
  data: () => ({
    selectedPriceId: null,
  }),
  created() {
    if (this.preSelectedPeriod) {
      return this.periodSelected(this.preSelectedPeriod);
    }

    const preSelectedPeriod = this.isDomainPurchase
      ? this.sortedPeriods[this.sortedPeriods.length - 1]
      : this.sortedPeriods[0];
    this.periodSelected(preSelectedPeriod?.id);
  },
  computed: {
    sortedPeriods() {
      return [...this.prices].sort((a, b) => b.price - a.price);
    },
    periods() {
      if (this.hasMailbox) return this.setRecommendedPeriod();

      return this.sortedPeriods;
    },
  },
  methods: {
    periodSelected(id) {
      if (this.selectedPriceId === id) return;
      this.selectedPriceId = id;
      const pricing = this.periods.find((price) => price.id === id);
      this.$emit('change', pricing);
    },
    changeWhoisPrivacy(value) {
      this.$emit('change-whois-privacy', value);
    },
    setRecommendedPeriod() {
      const period48 = this.sortedPeriods.findIndex(
        (period) => period.period === 4 && period.periodUnit === 'year',
      );
      const newSortedPeriods = [...this.sortedPeriods];
      if (period48 > -1) {
        newSortedPeriods[period48].topLabel = 'RECOMMENDED';
      }

      return newSortedPeriods;
    },
  },
};
</script>

<style lang="scss" scoped>
.periods {
  padding-left: 0;
}
</style>
