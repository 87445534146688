import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_PAYMENTS_V2}/jwt/api/v1/razorpay`,

  async getRazorpayCardLastFourDigits(validMethodId) {
    return await hAsync(http.get(`${this.url}/last-digits/${validMethodId}`));
  },
};
