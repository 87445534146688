import cookies from 'js-cookie';

import { pingRepo } from '@/repositories';
import store from '@/store/index';
import { hToastrID, HToastrMutations, Cookie } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { $t } from '@/utils/services/i18nService';

interface OptionalData {
  reason?: string;
  hideNetworkError?: boolean;
}

export const handleGenericNetworkError = (optionalData?: OptionalData) => {
  errorLogger.ignoreSentryErrors();

  if (
    toastr.isCurrentlyDisplayed(hToastrID.NETWORK_ERROR_TOASTR) ||
    optionalData?.hideNetworkError ||
    cookies.get(Cookie.HIDE_NETWORK_NOTIFICATION)
  ) {
    return;
  }

  cookies.set(Cookie.HIDE_NETWORK_NOTIFICATION, 'true', { expires: 1 });

  toastr.e($t('Network error'), {
    html: $t(
      'One or more network errors occurred this might happen due to some connectivity issues. Reload the page and try again.',
    ),
    buttons: [
      {
        text: $t('Reload page'),
        props: {
          primary: true,
          text: true,
        },
        callback: () => {
          window.location.reload();
        },
      },
    ],
    optionalData,
    customId: hToastrID.NETWORK_ERROR_TOASTR,
    timeout: Math.max(),
  });
};

export const handleBrowserOfflineError = (optionalData?: OptionalData) => {
  try {
    if (toastr.isCurrentlyDisplayed(hToastrID.BROWSER_OFFLINE)) {
      return;
    }

    toastr.e($t('Connection lost'), {
      html: $t(
        'It looks like there is an issue with the connection. Please check your internet connection and try again.',
      ),
      buttons: [],
      optionalData,
      customId: hToastrID.BROWSER_OFFLINE,
      timeout: Math.max(),
    });

    errorLogger.ignoreSentryErrors();
  } catch (error) {
    errorLogger.logError(
      new Error('handleBrowserOfflineError', { cause: error }),
    );
  }
};

export const handleBlacklistedIpError = (optionalData?: OptionalData): void => {
  const BLACKLISTED_IP_BLOG_POST_URL =
    'https://support.hostinger.com/en/articles/6820458-how-to-fix-403-error-in-hpanel';

  if (toastr.isCurrentlyDisplayed(hToastrID.BLACKLISTED_IP)) {
    return;
  }
  toastr.e($t('It seems that your network request failed'), {
    html: $t(
      'This issue can occur due to many reasons, including a suspicious IP address, a browser error, or too many network requests being sent in a short time frame. Find out how to resolve it.',
    ),
    buttons: [
      {
        text: $t('Learn more'),
        props: {
          primary: true,
          text: true,
        },
        callback: () => {
          window.open(BLACKLISTED_IP_BLOG_POST_URL, '_blank', 'noopener');
        },
      },
    ],
    optionalData,
    customId: hToastrID.BLACKLISTED_IP,
    timeout: Math.max(),
  });

  errorLogger.ignoreSentryErrors();
};

export const handleSomethingWentWrongWhileLoadingChunkError = () => {
  if (toastr.isCurrentlyDisplayed(hToastrID.BROWSER_OFFLINE)) {
    store.commit(
      HToastrMutations.REMOVE_HTOASTR_MESSAGE_CUSTOM_ID,
      hToastrID.BROWSER_OFFLINE,
    );
  }

  if (
    toastr.isCurrentlyDisplayed(hToastrID.NEW_HPANEL_VERSION) ||
    toastr.isCurrentlyDisplayed(hToastrID.BLACKLISTED_IP)
  ) {
    return;
  }

  toastr.s($t('New hPanel version available'), {
    html: $t('Reload for the best experience'),
    buttons: [
      {
        text: $t('Reload page'),
        props: {
          primary: true,
          text: true,
        },
        callback: () => {
          window.location.reload();
        },
      },
    ],
    customId: hToastrID.NEW_HPANEL_VERSION,
    timeout: Math.max(),
  });

  errorLogger.ignoreSentryErrors();
};

export const handleChunkLoadError = async () => {
  if (!window.navigator.onLine) {
    return handleSomethingWentWrongWhileLoadingChunkError();
  }

  try {
    /**
     * Pinging Hostinger API to check if the user is blacklisted as ChunkLoadError
     * might be thrown when the user is blacklisted and not only when the chunk is not loaded
     */
    const response = await pingRepo.pingHostingerAPI();

    if (response !== undefined) {
      return handleSomethingWentWrongWhileLoadingChunkError();
    }
  } catch {}
};

const GENERIC_NETWORK_ERROR_MESSAGES = [
  'Network Error',
  'timeout of 150000ms exceeded',
  'Request aborted',
];

export const handleCommonErrors = (error: any) => {
  const responseConfig = error.response?.config;
  const requestUrl = responseConfig?.url || error?.config?.url;
  const status = error.response?.status;
  const dataError = error.response?.data?.error;
  const message =
    dataError?.inputs || dataError?.validation_messages || dataError?.message;

  const requestPathname = requestUrl ? new URL(requestUrl).pathname : undefined;

  if (!window.navigator.onLine) {
    handleBrowserOfflineError({ reason: requestPathname });
  }

  if (status === 418) {
    handleGenericNetworkError({
      reason: requestPathname,
      hideNetworkError: true,
    });
  }

  if (
    GENERIC_NETWORK_ERROR_MESSAGES.includes(error.message) &&
    window.navigator.onLine
  ) {
    const hideNetworkError = !!(
      responseConfig?.hideNetworkError || error?.config?.hideNetworkError
    );

    handleGenericNetworkError({ reason: requestPathname, hideNetworkError });
  }

  const isIpBlockedError = !message && status === 403;

  if (isIpBlockedError) {
    handleBlacklistedIpError({ reason: requestPathname });
  }
};
