import type { NavigationGuard } from 'vue-router';

import { useFrontendSettingsStore } from '@/stores';
import { useDomainCheckerStore } from '@/stores/domain/domainCheckerStore';
import { useTldCategoriesStore } from '@/stores/domain/tldCategoriesStore';

const domainCheckerGuard: NavigationGuard = async (to, from, next) => {
  const { fetchTldCategories, tldCategories } = useTldCategoriesStore();
  const { fetchAllTlds, allTlds } = useDomainCheckerStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const calls = [
    tldCategories.length ? Promise.resolve() : fetchTldCategories(),
    allTlds.length ? Promise.resolve() : fetchAllTlds(),
  ];

  frontendSettingsStore.setState('pageLoading', true);
  await Promise.all(calls);
  frontendSettingsStore.setState('pageLoading', false);

  next();
};

export default domainCheckerGuard;
