<template>
  <div v-if="isOpen" class="h-select__select-box">
    <ul class="h-select__select-box-list">
      <li
        v-for="(option, i) in options"
        :id="getGaTrackingId(option.title)"
        :key="i"
        v-trans
        class="h-select__select-box-list-item"
        @mousedown="$emit('selected-option', option)"
      >
        {{ option.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HOptionsList',
  props: {
    isOpen: Boolean,
    options: Array,
  },
  emits: ['selected-option'],
  methods: {
    getGaTrackingId(title) {
      const optionText =
        title.trim().replace(/[\W_]/g, '_').toLowerCase() || '';
      const route = this.$route.name || this.$route.path || '';

      return `hpanel_tracking-${route}-${optionText}_option`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/components/h-select';

.h-select__select-box {
  opacity: 1;
  height: auto;
  border-radius: 4px;
}

.h-select__select-box-list-item:hover {
  background: var(--darken-light);
  color: var(--primary);
}
</style>
