export const PAYPAL_CONFIGURATION = {
  iconName: 'ic-paypal',
  method: 'paypal',
};
export const GOOGLEPAY_CONFIGURATION = {
  iconName: 'ic-google-pay',
  method: 'googlepay',
};
export const NETBANKING_CONFIGURATION = {
  iconName: 'ic-netbanking',
  method: 'netbanking',
};

export const ICON_CONFIGURATION = [
  PAYPAL_CONFIGURATION,
  GOOGLEPAY_CONFIGURATION,
  NETBANKING_CONFIGURATION,
];
