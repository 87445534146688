import { type RouteLocationNormalized } from 'vue-router';

import { getPublicPath } from '../helpers/assetsHelpers';

import { ONE_MINUTE_IN_MS } from '@/data/globalConstants';
import { Route, SessionStorage } from '@/types';
import { assignLocationAsync, timeout } from '@/utils/helpers';
import {
  ERROR_FETCH_DYNAMIC_MODULE,
  ERROR_LOAD_DYNAMIC_MODULE,
} from '@/utils/services/errorLogging';
import { handleChunkLoadError } from '@/utils/services/http/httpErrorHandlers';
import http from '@/utils/services/http/httpService';

export type VersionUpdaterModel = {
  shouldUpdate: boolean;
  currentVersion: string;
  latestVersion: string;
};

const VERSION_CHECK_INTERVAL_MINUTES = 5 * ONE_MINUTE_IN_MS;
const VERSION_FILE_PATH = `${getPublicPath()}hp-version.json`;

const WHITELISTED_ROUTES_FOR_VERSION_UPDATE: Route.RouteType[] = [
  Route.Base.MARKETPLACE,
  Route.Base.HELP,
];

let versionCheckInterval: NodeJS.Timer;

export const startVersionCheckTimer = async () => {
  if (versionCheckInterval) {
    clearInterval(versionCheckInterval);
  }

  if (process.env.VITE_VERSION_CHECKER_ENABLED !== 'true') {
    return;
  }

  const checkForVersionAndSetToSession = async () => {
    if (!window.navigator.onLine) {
      return;
    }

    const { data } = await getHpanelVersion();

    if (!data) {
      return;
    }

    const currentVersion = process.env.VITE_VERSION;
    const latestVersion = data.version;

    if (!currentVersion || !latestVersion) {
      return;
    }

    const shouldUpdate = shouldUpdateHpanel(currentVersion, latestVersion);
    const versionUpdaterInfo: VersionUpdaterModel = {
      shouldUpdate,
      currentVersion,
      latestVersion,
    };

    sessionStorage.setItem(
      SessionStorage.VERSION_UPDATER,
      JSON.stringify(versionUpdaterInfo),
    );

    if (shouldUpdate && versionCheckInterval) {
      clearInterval(versionCheckInterval);
    }
  };

  await timeout(VERSION_CHECK_INTERVAL_MINUTES);

  checkForVersionAndSetToSession();

  versionCheckInterval = setInterval(
    checkForVersionAndSetToSession,
    VERSION_CHECK_INTERVAL_MINUTES,
  );
};

const getHpanelVersion = async () =>
  await http.get(VERSION_FILE_PATH, {
    whitelistedStatusCodes: [403],
    hideToastr: true,
  });

const shouldUpdateHpanel = (currentVersion: string, latestVersion: string) => {
  const parsedCurrentVersion = Number(currentVersion);
  const parsedLatestVersion = Number(latestVersion);

  return parsedCurrentVersion !== parsedLatestVersion;
};

const isWhitelistedRouteForVersionUpdate = (route: Route.RouteType): boolean =>
  WHITELISTED_ROUTES_FOR_VERSION_UPDATE.includes(route);

export const checkAndUpdateHpanelVersion = async (
  route: RouteLocationNormalized,
) => {
  const routeName = route.name as Route.RouteType;
  if (!isWhitelistedRouteForVersionUpdate(routeName)) {
    return;
  }

  const versionUpdaterInfoString = sessionStorage.getItem(
    SessionStorage.VERSION_UPDATER,
  );

  if (!versionUpdaterInfoString) {
    return;
  }

  const { shouldUpdate } = JSON.parse(versionUpdaterInfoString);

  if (!shouldUpdate) {
    return;
  }
  await updateHpanelVersion(route);
};

export const updateHpanelVersion = async (route: RouteLocationNormalized) => {
  sessionStorage.removeItem(SessionStorage.VERSION_UPDATER);
  await assignLocationAsync(route.fullPath);
};

export const addConsoleErrorSpy = () => {
  const originalConsole = console.error;
  console.error = (error) => {
    originalConsole(error);
    if (
      error.message?.includes(ERROR_FETCH_DYNAMIC_MODULE) ||
      error.message?.includes(ERROR_LOAD_DYNAMIC_MODULE)
    ) {
      handleChunkLoadError();
    }
  };
};
