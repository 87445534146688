export const getPublicPath = () => {
  const publicPath = process.env.VITE_PUBLIC_PATH;
  const isLastCharSlash =
    publicPath && publicPath?.lastIndexOf('/') === publicPath?.length - 1;

  if (isLastCharSlash) {
    return publicPath;
  }

  return `${publicPath}/`;
};

export const getAsset = (path: string) => {
  if (typeof path !== 'string') {
    return path;
  }

  const srcPath = path.replace('@', 'assets');

  return `${getPublicPath()}${srcPath}`;
};
