import type { RouteRecordRaw } from 'vue-router';

import playgroundGuard from '@/guards/playgroundGuard';
import { Route } from '@/types';

export default [
  {
    path: '/playground',
    meta: {
      title: 'Playground',
    },
    beforeEnter: (to, from, next) => playgroundGuard(to, from, next),
    component: () => import('@/playground/Playground.vue'),
    children: [
      {
        path: 'hp-table',
        name: Route.Playground.HPTable,
        meta: {
          title: 'HP Table Playground',
        },
        component: () => import('@/playground/HPTable/HPTablePlayground.vue'),
      },
      {
        path: 'h-circle-loader',
        name: Route.Playground.H_CIRCLE_LOADER,
        meta: {
          title: 'H Circle loader Playground',
        },
        component: () =>
          import('@/playground/HCircleLoader/HCircleLoaderPlayground.vue'),
      },
      {
        path: 'h-radio-card',
        name: Route.Playground.H_RADIO_CARD,
        meta: {
          title: 'H Radio Card Playground',
        },
        component: () =>
          import('@/playground/HRadioCard/HRadioCardPlayground.vue'),
      },
      {
        path: 'h-radio',
        name: Route.Playground.H_RADIO,
        meta: {
          title: 'H Radio',
        },
        component: () => import('@/playground/HRadio/HRadioPlayground.vue'),
      },
      {
        path: 'hp-dropdown',
        name: Route.Playground.HP_DROPDOWN,
        meta: {
          title: 'HP Dropdown',
        },
        component: () => import('@/playground/HPDropdwon/HPDropdown.vue'),
      },
      {
        path: 'h-popover',
        name: Route.Playground.H_POPOVER,
        meta: {
          title: 'H Popover',
        },
        component: () => import('@/playground/HPopover/HPopover.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
