import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordName,
} from 'vue-router';

import { useHostingOrders } from '@/composables';
import { Route } from '@/types';

const hostingPageGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { activeHostingOrders } = useHostingOrders();

  // id is set to be domain when coming from redirectService
  const currentHostingOrder = activeHostingOrders.value?.find((hostingOrder) =>
    [hostingOrder.id, hostingOrder.title].includes(to.params.id as string),
  );

  const routesMap: Record<RouteRecordName, RouteRecordName> = {
    [Route.Hosting.DETAILS_V2]: Route.HostingOrder.DETAILS,
    [Route.Hosting.RESOURCES_USAGE_V2]: Route.HostingOrder.ORDER_USAGE,
    [Route.Hosting.HOSTING_UPGRADE_V2]: Route.Order.ORDER_UPGRADE,
  };
  const routeToName = routesMap[to.name ?? ''];

  const domain =
    currentHostingOrder?.title === to.params.id
      ? to.params.id
      : currentHostingOrder?.websites[0].domain;

  if (domain && routeToName) {
    return next({
      name: routeToName,
      params: {
        domain,
      },
    });
  }

  return next({ name: Route.Base.WEBSITES });
};

export default hostingPageGuard;
