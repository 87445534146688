import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useGlobals } from '@/composables';
import { hostingEmailsRepo } from '@/repositories';
import type { DkimRecord, IDkimChangeRequest } from '@/types';
import { Email } from '@/types';
import { extractHAsyncErrorMessage } from '@/utils/helpers';

export const useDkimStore = defineStore('dkimStore', () => {
  const { toastr, t } = useGlobals();

  const dkimRecordsMap = ref<Partial<Record<string, DkimRecord[]>>>({});
  const isLoading = ref(false);
  const isRemoving = ref(false);
  const isGenerating = ref(false);
  const isVerifying = ref(false);

  const getDkimRecordsByDomainResourceId = (domainResourceId: string) =>
    dkimRecordsMap.value[domainResourceId];

  const setDkimRecordsByDomainResourceId = (
    domainResourceId: string,
    record: DkimRecord,
  ) => {
    const recordWithDefaults = {
      ...record,
      status: record.status ?? Email.DkimRecordStatus.UNVERIFIED,
    };

    dkimRecordsMap.value[domainResourceId] = [recordWithDefaults];
  };

  const fetchDkimRecords = async (domainResourceId: string) => {
    isLoading.value = true;

    const [{ data }, error] = await hostingEmailsRepo.getDkim({
      domainResourceId,
    });

    if (error) {
      toastr.e(t(extractHAsyncErrorMessage(error)));
    }

    if (data) {
      dkimRecordsMap.value[domainResourceId] = data;
    }

    isLoading.value = false;

    return { data, error };
  };

  const removeDkimRecord = async (params: IDkimChangeRequest) => {
    isRemoving.value = true;

    const [{ data }, error] = await hostingEmailsRepo.deleteDkim(params);

    if (error) {
      toastr.e(t(extractHAsyncErrorMessage(error)));
    }

    if (data) {
      delete dkimRecordsMap.value[params.domainResourceId];
    }

    isRemoving.value = false;

    return { data, error };
  };

  const generateCustomDkim = async (domainResourceId: string) => {
    isGenerating.value = true;

    const [{ data }, error] = await hostingEmailsRepo.createDkim({
      domainResourceId,
    });

    if (error) {
      toastr.e(t(extractHAsyncErrorMessage(error)));
    }

    if (data) {
      setDkimRecordsByDomainResourceId(domainResourceId, data);
    }

    isGenerating.value = false;

    return { data, error };
  };

  const verifyDkimRecord = async (params: IDkimChangeRequest) => {
    isVerifying.value = true;
    const [{ data }, error] = await hostingEmailsRepo.verifyDkim(params);

    if (error) {
      toastr.e(t(extractHAsyncErrorMessage(error)));
    }

    if (data) {
      setDkimRecordsByDomainResourceId(params.domainResourceId, data);
    }

    isVerifying.value = false;

    return { data, error };
  };

  return {
    getDkimRecordsByDomainResourceId,
    fetchDkimRecords,
    isLoading,
    removeDkimRecord,
    isRemoving,
    generateCustomDkim,
    isGenerating,
    verifyDkimRecord,
    isVerifying,
  };
});
