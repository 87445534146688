import type { NavigationGuard } from 'vue-router';

import { useHostingerMail } from '@/composables';
import store from '@/store';
import { useResourcesStore } from '@/stores';
import { Email, Route } from '@/types';

const emailsTrialGuard: NavigationGuard = async (to, from, next) => {
  const { getDomainResourceByDomain } = useResourcesStore();
  const { isHostingerMailAvailable } = useHostingerMail();

  const domain = to.params.domain as string;
  const resource = getDomainResourceByDomain(domain);

  if (!store.getters['emails/getLoaded']) {
    await store.dispatch('emails/fetchEmails');
  }

  const hasHostingerOrder = !!store.getters['emails/getPremiumMail'](
    Email.EmailProvider.HOSTINGER,
    domain,
  );
  const hasTitanOrder = !!store.getters['emails/getPremiumMail'](
    Email.EmailProvider.TITAN,
    domain,
  );
  const hasActiveOrder = hasHostingerOrder || hasTitanOrder;

  if (
    !resource ||
    !isHostingerMailAvailable.value ||
    hasActiveOrder ||
    !!store.getters['emails/getActiveSetup'].length
  ) {
    return next({ name: Route.Domain.MY_DOMAINS });
  }

  next();
};

export default emailsTrialGuard;
