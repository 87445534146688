<template>
  <Component
    :is="getButtonType"
    :id="formGeneratorId || id || getButtonGaTrackingId()"
    :target="target"
    :to="to"
    :disabled="loading ? true : null"
    class="h-button"
    :class="buttonClass"
    @click="clickButton"
  >
    <i v-if="icon" aria-hidden="true" :class="icon" />

    <HpIcon
      v-if="isHpIconVisible && !iconRight"
      class="h-button__icon"
      :icon="hpIcon"
      v-bind="iconColor"
      :width="22"
    />

    <slot name="icon" />
    <Trans v-if="$slots.default && !noTrans">
      <slot />
    </Trans>
    <slot v-else />
    <slot name="icon-right" />
    <HpIcon
      v-if="isHpIconVisible && iconRight"
      class="h-button__icon"
      :icon="hpIcon"
      v-bind="iconColor"
      :width="22"
    />
    <slot v-if="loading" name="loading">
      <CircleLoader
        xs
        centered
        absolute
        v-bind="{
          success,
          info,
          warning,
          danger,
          primary,
          gray,
          secondary,
          dark,
          outline,
          text,
        }"
      />
    </slot>
  </Component>
</template>

<script>
// JSON-slugs-migrated
import { isObject } from 'lodash';

import HButtonLink from './HButtonLink';
import HButtonRouterLink from './HButtonRouterLink';
import HButtonSimple from './HButtonSimple';

import hButtonProps from '../../utils/mixins/hButtonProps';
import CircleLoader from '../Loaders/CircleLoader';

export default {
  name: 'HButtonV1',
  components: {
    HButtonLink,
    HButtonRouterLink,
    HButtonSimple,
    CircleLoader,
  },
  mixins: [hButtonProps],
  data: () => ({
    modalId: null,
    child: '',
  }),
  async created() {
    this.modalId = await this.getModalId();
  },
  emits: ['click'],
  computed: {
    iconColor() {
      if (!this.outline) return { light: true };

      return {
        primary: this.primary || null,
        success: this.success || null,
        warning: this.warning || null,
        danger: this.danger || null,
        info: this.info || null,
      };
    },
    isHpIconVisible() {
      return this.hpIcon && !this.loading;
    },
    getButtonType() {
      if (this.to && !this.disabled && isObject(this.to)) {
        return 'h-button-router-link';
      }
      if (this.to && !this.disabled) return 'h-button-link';

      return 'h-button-simple';
    },
    getComponent() {
      return this.noTrans ? 'span' : 'trans';
    },
    buttonClass() {
      return {
        'h-button-danger': this.danger,
        'h-button-success': this.success,
        'h-button-info': this.info,
        'h-button-warning-regular': this.warning,
        'h-button-dark': this.dark,
        'h-button-outline': this.outline,
        'h-button-light': this.light || this.default,
        'h-button-primary': this.primary,
        'h-button-secondary': this.secondary,
        'h-button-gray': this.gray,
        'h-button-text': this.text,
        'h-button--active': this.active,
        'my-2': !this.noMargin,
        'w-100': this.block,
        'h-button--no-padding': this.noPadding,
        'h-button--no-border': this.noBorder,
        'h-button-sm--no-padding': this.noPadding,
        'h-button-md--no-padding': this.noPadding,
        'h-button-lg--no-padding': this.noPadding,
        'h-button--loading': this.loading,
        'h-button--disabled': this.disabled,
        'h-button-sm': this.sm,
        'h-button-md': this.md,
        'h-button-lg': this.lg,
        'h-button--nowrap': this.nowrap,
        'h-button--no-hover-effect': this.noDefaultHoverEffect,
        'h-button-underline-hover': this.underlineHover,
        'h-button--no-state-styling': this.noStateStyling,
        'h-button--centered': this.centered,
      };
    },
  },
  methods: {
    getButtonGaTrackingId() {
      const buttonText =
        typeof this.findButtonText(this.$slots) === 'string'
          ? this.findButtonText(this.$slots)
          : '';

      const buttonTextId =
        buttonText?.trim().replace(/[\W_]/g, '_').toLowerCase() || '';

      const routeId =
        this.$route?.name?.replace(/_/g, '-') ||
        this.$route?.path?.replace(/\//g, '-') ||
        '';

      return `hpanel_tracking-${routeId}-${
        this.modalId ? `${this.modalId}-` : ''
      }${buttonTextId}_button`;
    },
    async getModalId() {
      await this.$nextTick();

      return document.getElementsByClassName('h-modal__wrapper')[0]?.id;
    },

    findButtonText(slots) {
      if (!slots.default) return;

      const defaultSlots = slots.default();

      if (defaultSlots.length === 1) {
        return (
          defaultSlots[0].text ||
          defaultSlots[0].children[0]?.text ||
          defaultSlots[0].children[0]?.children ||
          defaultSlots[0].children
        );
      }

      if (defaultSlots.length > 1) {
        return slots
          .default()
          .find((item) => item.children)
          ?.children.default()?.[0]?.children;
      }

      return '';
    },
    clickButton() {
      if (!this.disabled) this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/components/h-button';
</style>
