<script setup lang="ts">
import { computed } from 'vue';

import { useGlobals } from '@/composables';

export type ErrorStateProps = {
  /**
   * list-item view - https://www.figma.com/file/WKIs2k86P7pDumHabqvDKp/Homepage-v.2?type=design&node-id=8125-10477&mode=design&t=gYnl9hivHZwZAuJX-4
   * card view - https://www.figma.com/file/WKIs2k86P7pDumHabqvDKp/Homepage-v.2?type=design&node-id=7741-62651&mode=design&t=gYnl9hivHZwZAuJX-4
   * table-row - same as list-item view but for placing inside HPTableRow.
   */
  variant?: 'list-item' | 'table-row' | 'card';
  errorElementClass?: string;
};

const props = withDefaults(defineProps<ErrorStateProps>(), {
  variant: 'card',
});

const { t } = useGlobals();

const iconSize = computed(() => {
  if (['list-item', 'table-row'].includes(props.variant)) {
    return 24;
  }

  return 48;
});
</script>

<template>
  <div
    class="error-boundary"
    :class="[`error-boundary--${props.variant}`, props.errorElementClass]"
  >
    <HpIcon :width="iconSize" color="gray" icon="icon-error" />
    <h2 class="error-boundary__title">
      {{ t('Oops! This section didn’t load, but we’re fixing it.') }}
    </h2>
    <p class="error-boundary__subtitle">
      {{ t('In the meantime, try refreshing the page or explore other areas') }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.error-boundary {
  $this: &;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-border);
  margin-bottom: 24px;

  &--list-item {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
    padding: 16px 24px;
    border-radius: 0;
    justify-content: flex-start;
  }

  &--table-row {
    @extend .error-boundary--list-item;

    border: none;
    grid-column: 1 / -1;
  }

  &--list-item,
  &--table-row {
    #{$this}__title {
      font-size: 14px;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
</style>
