import { computed } from 'vue';

import { useBrands } from '@/composables/useBrands';
import { useAccessStore } from '@/stores/accessStore';
import { stripClientIdToNumber } from '@/utils/helpers';

export const useAccessList = () => {
  const accessStore = useAccessStore();
  const { isFromNiagahoster } = useBrands();

  const accessList = computed(() => accessStore.managedByMe);

  const getCustomerAccessObjectByClientId = (customerClientId: string) => {
    const clientIdMatchingClientModule = isFromNiagahoster.value
      ? customerClientId
      : stripClientIdToNumber(customerClientId);

    return accessList.value.find(
      ({ clientId }) => clientId === clientIdMatchingClientModule,
    );
  };
  const getAccessIdByClientId = (customerClientId: string) =>
    getCustomerAccessObjectByClientId(customerClientId)?.id;

  return {
    accessList,
    getCustomerAccessObjectByClientId,
    getAccessIdByClientId,
  };
};
