import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hBillingRepo } from '@/repositories';
import { HBilling, SubscriptionStatus } from '@/types';
import type { BillingInvoice, ISubscriptionOrder } from '@/types';

export const useBillingStore = defineStore('billingStore', () => {
  const invoices = ref<BillingInvoice[]>([]);
  const invoicesLoaded = ref(false);
  const unpaidOrdersLoaded = ref(false);
  const unpaidOrders = ref<ISubscriptionOrder[]>([]);

  const isInvoiceUnpaid = (entry: BillingInvoice) =>
    ![HBilling.InvoiceStatus.PAID, HBilling.InvoiceStatus.VOIDED].includes(
      entry?.status,
    );

  const unpaidInvoices = computed(() => invoices.value.filter(isInvoiceUnpaid));

  const fetchInvoices = async () => {
    const [{ data }, error] = await hBillingRepo.getInvoices();

    if (error) return;

    invoices.value = data;
    invoicesLoaded.value = true;
  };

  const fetchUnpaidOrders = async () => {
    const [{ data }, error] = await hBillingRepo.getOrders({
      params: {
        statuses: [
          SubscriptionStatus.AWAITING_PAYMENT,
          SubscriptionStatus.PAYMENT_INITIATED,
        ],
      },
    });

    if (error) return;

    unpaidOrdersLoaded.value = true;
    unpaidOrders.value = data;
  };

  return {
    fetchInvoices,
    unpaidInvoices,
    invoicesLoaded,
    unpaidOrdersLoaded,
    unpaidOrders,
    fetchUnpaidOrders,
    invoices,
  };
});
