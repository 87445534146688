import { isDefined } from '@vueuse/core';

import type { CatalogItem, CatalogItemPrice } from '@/types';
import { convertToMonths } from '@/utils/helpers';
import { getMonthlyPrice } from '@/utils/helpers/currencyHelpers';

/**
 * Searches for a price in the pricing data that matches the provided period.
 *
 * @param prices pricing data to search in
 * @param period period expressed in months
 */
export const findPriceByPeriod = (
  prices: CatalogItem['prices'],
  period: number,
) =>
  prices.find((price) => {
    if (!price.period || !price.periodUnit) {
      return false;
    }

    const pricePeriod = convertToMonths(price.period, price.periodUnit);

    return pricePeriod === period;
  });

export const getItemMonthlyPrice = (
  item: CatalogItemPrice,
  key: 'price' | 'basePrice' | 'firstPeriodPrice' | 'firstYearPrice',
) => {
  const value = item[key];

  if (!isDefined(value)) {
    return null;
  }

  const periodInMonths = convertToMonths(item.period, item.periodUnit);

  return getMonthlyPrice(value, periodInMonths);
};

export const getLowestMonthlyPurchasePrice = (catalogItem: CatalogItem) =>
  [...catalogItem.prices].sort((aItemPrice, bItemPrice) => {
    const aPrice =
      getItemMonthlyPrice(aItemPrice, 'firstPeriodPrice') ?? Infinity;
    const bPrice =
      getItemMonthlyPrice(bItemPrice, 'firstPeriodPrice') ?? Infinity;

    return aPrice - bPrice;
  })[0];
