import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/server`,
  urlV3: `${process.env.VITE_API_REST}/v3/server`,

  async getMySqlServerInfo(serverId) {
    return await hAsync(
      http.get(`${this.url}/mysql`, { params: { serverId } }),
    );
  },

  async postDCsPinger({ params, config }) {
    const headers = {
      [Header.ORDER_ID]: config.orderId,
    };

    return await hAsync(
      http.post(`${this.url}/pinger`, params, {
        headers,
        hideToastr: true,
      }),
    );
  },

  async getDCsPinger(params) {
    return await hAsync(
      http.get(`${this.urlV3}/pinger`, { params, hideToastr: true }),
    );
  },
};
