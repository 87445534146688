import type { ModalObject } from '@/types';

export default [
  {
    name: 'BillingChangeOrderPeriodModal',
    type: 'HModalFancy',
    subtype: 'Billing',
  },
  {
    name: 'BillingDisableAutoRenewalModal',
    type: 'HModalFancy',
    subtype: 'Billing',
  },
  {
    name: 'BillingDeleteUnpaidInvoiceModal',
    type: 'HModalFancy',
    subtype: 'Billing',
    props: {
      title: 'Delete Unpaid Invoice?',
      subtitle: 'You will delete the unpaid invoice that was selected',
      imgSrc: 'modal/modal-warning-circle.svg',
    },
  },
  {
    name: 'BillingAddPaymentMethodModal',
    type: 'HModal',
    subtype: 'Billing',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'BillingRemovePaymentMethodModal',
    type: 'HModal',
    subtype: 'Billing',
    props: {
      title: 'Remove Payment Method',
    },
  },
  {
    name: 'BillingRemovePaymentMethodModalOld',
    type: 'HModalFancy',
    subtype: 'Billing',
    props: {
      title: 'Remove Payment Method?',
      imgSrc: 'modal/modal-trash.svg',
    },
  },
  {
    name: 'BillingAutoRenewalFailedModal',
    type: 'HModalFancy',
    subtype: 'Billing',
  },
  {
    name: 'BillingManagedAccountPaymentMethodModal',
    type: 'HModalFancy',
    subtype: 'Billing',
    props: {
      title: 'You’re adding a payment method to a managed account',
      subtitle:
        'Keep in mind that account owner can remove you from their  account anytime and you would lose all access to the services.',
      imgSrc: 'modal/modal-warning-circle.svg',
    },
  },
  {
    name: 'BillingRenewServiceModal',
    type: 'HModal',
    subtype: 'Billing',
  },
  {
    name: 'BillingPayInvoiceModal',
    type: 'HModal',
    subtype: 'Billing',
  },
  {
    name: 'BillingUnableAutoRenewModal',
    type: 'HModal',
    subtype: 'Billing',
    steps: [
      {
        hideX: true,
      },
    ],
  },
] as ModalObject[];
