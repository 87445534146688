import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useCookies, useGlobals } from '@/composables';
import { websiteRepo } from '@/repositories';
import { useAssignTagStore } from '@/stores';
import { AmplitudeEvent, Cookie } from '@/types';
import { AmplitudeParam, type HAsyncError } from '@/types';
import type { WebsiteTagType } from '@/types/models/websiteTagModel';
import { extractHAsyncErrorMessage } from '@/utils/helpers';

type TagRequestResponse = {
  id: string;
  error: HAsyncError;
};

export const useWebsiteTagsStore = defineStore('websiteTagsStore', () => {
  const assignTagStore = useAssignTagStore();
  const { toastr, t, amplitudeV2 } = useGlobals();
  const {
    isCookieValueTruthyNumber: hideTagsCookie,
    setCookie: setHideTagsCookie,
  } = useCookies(Cookie.HIDE_TAGS_IN_WEBSITES_LIST);

  const tags = ref<WebsiteTagType[]>([]);
  const isSaving = ref(false);
  const isLoading = ref(false);
  const isAlreadyFetched = ref(false);
  const showTags = ref(!hideTagsCookie.value);

  const hasTags = computed(() => tags.value.length > 0);

  const fetchTags = async (refetch = false) => {
    if (isAlreadyFetched.value && !refetch) return;
    isLoading.value = true;
    const [{ data }, error] = await websiteRepo.getTags();
    if (error) {
      // Tags are re-fetched when modal is opened, so we want to show error message there
      if (refetch) {
        toastr.e(t('Failed to fetch tags'));
      }

      return;
    }
    isAlreadyFetched.value = true;
    // Name is unique, use it as identifier
    tags.value = data.map(({ name }) => ({
      name,
      id: name,
      isOwnershipTag: false,
    }));
    isLoading.value = false;
  };

  const getDeletedTags = (tagsToSave: WebsiteTagType[]) =>
    tags.value
      .filter(({ id }) => !tagsToSave.some((tag) => tag.id === id))
      .map(({ id }) => id);

  const deleteTags = async (tagIdsToDelete: string[]) =>
    await Promise.all(
      tagIdsToDelete.map(async (id) => websiteRepo.deleteTag(id)),
    );

  const getChangedTags = (tagsToSave: WebsiteTagType[]) =>
    // If tag name and id are different, it means that tag has been modified
    tagsToSave.filter((tag) => !tag.isNew && tag.id !== tag.name);

  const updateTags = async (tagsToUpdate: WebsiteTagType[]) =>
    await Promise.all(
      tagsToUpdate.map(async ({ id, name: newName }) => {
        const [_, error] = await websiteRepo.patchTag(id, newName);

        return { id, error };
      }),
    );

  const getNewTags = (tagsToSave: WebsiteTagType[]) =>
    tagsToSave.filter(({ isNew }) => isNew);

  const createTags = async (tagsToCreate: WebsiteTagType[]) => {
    const responses: TagRequestResponse[] = [];

    for (const { name, id } of tagsToCreate) {
      const [_, error] = await websiteRepo.postTag(name);
      responses.push({ id, error });
    }

    return responses;
  };

  const formatErrorResponses = (errorResponses: TagRequestResponse[]) =>
    errorResponses
      .filter((res) => res.error)
      .map(({ id, error }) => ({
        id,
        error: extractHAsyncErrorMessage(error),
      }));

  const updateTagsAfterSaving = (
    tagsToSave: WebsiteTagType[],
    createResponses: TagRequestResponse[],
    updateResponses: TagRequestResponse[],
  ) => {
    tags.value = tagsToSave
      .filter(({ id }) => {
        // Filter out tags that were not successfully created
        const res = createResponses.find((res) => res.id === id);

        return !res || !res.error;
      })
      .map(({ name, id }) => {
        // If tag has error, it means that it was not saved, so we need to keep original name
        const errorResponse = updateResponses.find(
          (res) => res.id === id && res.error,
        );
        const newId = !errorResponse ? name : errorResponse.id;

        return {
          name: newId,
          id: newId,
          isOwnershipTag: false,
        };
      });
  };

  const updateAssignedTags = (changedTags: WebsiteTagType[]) => {
    if (changedTags.length) {
      // Update names in assigned tags
      assignTagStore.updateTagNames(
        changedTags.map(({ id, name }) => ({ id, newName: name })),
      );
    }
  };

  const handleTagChangedAmplitudeEvent = (action: AmplitudeParam.TagAction) => {
    amplitudeV2(AmplitudeEvent.Websites.TAG_MANAGER_TAG_CHANGED, { action });
  };

  const saveTags = async (tagsToSave: WebsiteTagType[]) => {
    isSaving.value = true;

    const deletedTags = getDeletedTags(tagsToSave);
    await deleteTags(deletedTags);
    if (deletedTags.length) {
      handleTagChangedAmplitudeEvent(AmplitudeParam.TagAction.REMOVED);
    }

    const changedTags = getChangedTags(tagsToSave);
    const updateResponses = await updateTags(changedTags);
    if (changedTags.length) {
      handleTagChangedAmplitudeEvent(AmplitudeParam.TagAction.MODIFIED);
    }

    const newTags = getNewTags(tagsToSave);
    const createResponses = await createTags(newTags);
    if (newTags.length) {
      handleTagChangedAmplitudeEvent(AmplitudeParam.TagAction.ADDED);
    }

    updateTagsAfterSaving(tagsToSave, createResponses, updateResponses);

    updateAssignedTags(changedTags);
    isSaving.value = false;

    return formatErrorResponses([...updateResponses, ...createResponses]);
  };

  const setShowTags = (visibility: boolean) => {
    showTags.value = visibility;
    setHideTagsCookie(visibility ? '0' : '1', { expires: 365 });
  };

  const clearTags = () => {
    tags.value = [];
    assignTagStore.$reset();
    isAlreadyFetched.value = false;
  };

  return {
    tags,
    isSaving,
    isLoading,
    hasTags,
    showTags,
    fetchTags,
    saveTags,
    setShowTags,
    clearTags,
  };
});
