import type { ModalObject } from '@/types';

export default [
  {
    name: 'CPanelChangePasswordModal',
    type: 'HModal',
    subtype: 'Cpanel',
    steps: [{ title: '', hideX: true }],
  },
  {
    name: 'CPanelUpgradeCompareModal',
    type: 'HModal',
    subtype: 'Cpanel',
    steps: [
      {
        title: 'Feature comparison',
      },
    ],
  },
  {
    name: 'WordPressScanModal',
    type: 'HModal',
    subtype: 'Cpanel/WordPress',
    steps: [
      {
        title: 'Check for new WordPress installation',
        subtitle:
          'This feature enables users to efficiently verify the status of any WordPress installation that has been manually uploaded via FTP.With just a few clicks, users can ensure that their installation is up- to - date, properly configured, and functioning as intended.',
      },
      {
        hideX: true,
      },
      {
        title: 'New installation detected',
        subtitle: 'You can manage your new installation from this dashboard',
      },
    ],
  },
  {
    name: 'CPanelUninstallWordpressModal',
    type: 'HModal',
    subtype: 'Cpanel',
    props: {
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'WordPressStagingModal',
    type: 'HModal',
    subtype: 'Cpanel/WordPress',
  },
  {
    name: 'WordPressStagingPublishModal',
    type: 'HModal',
    subtype: 'Cpanel/WordPress',
  },
  {
    name: 'WordPressDeleteInstallationModal',
    type: 'HModal',
    subtype: 'Cpanel/WordPress',
  },
  {
    name: 'WordPressCloneModal',
    type: 'HModal',
    subtype: 'Cpanel/WordPress',
    props: {
      hideX: true,
    },
  },
  {
    name: 'CPanelUnblockSMTPModal',
    type: 'HModal',
    subtype: 'Cpanel',
    props: {
      hideX: true,
      persistent: true,
    },
  },
] as ModalObject[];
