import { isObject } from 'lodash';

import Route from './RouteClass';

import functions from '../functions';

import { router } from '@/router';

export default {
  get(menu) {
    return this.routes()
      .filter((route) => route.meta && route.meta.menu)
      .filter((route) => route.meta.menu.includes(menu))
      .map((element) => new Route(element));
  },
  getByMenu(menuName) {
    const routes = findRoutesRecursively(this.routes(), menuName);

    return routes.sort((aRoute, bRoute) => {
      const aRouteMenu = aRoute?.meta?.menu?.find(
        (menu) => (isObject(menu) ? menu.name : menu) === menuName,
      );
      const aRouteOrder = isObject(aRouteMenu) ? aRouteMenu.order : 0 ?? 0;
      const bRouteMenu = bRoute?.meta?.menu?.find(
        (menu) => (isObject(menu) ? menu.name : menu) === menuName,
      );
      const bRouteOrder = isObject(bRouteMenu) ? bRouteMenu.order : 0 ?? 0;

      if (aRouteOrder === bRouteOrder) return 0;
      if (aRouteOrder > bRouteOrder) return 1;

      return -1;
    });
  },
  getByName(name) {
    return this.routes().find((route) => route.name === name) ?? {};
  },
  routes() {
    return router?.options?.routes || [];
  },
  breadcrumbs() {
    return this.currentRoute();
  },
  currentRoute() {
    return new Route(router.currentRoute);
  },

  getRouteByName(name) {
    return new Route(
      functions.arrayDeepSearch(
        router.options.routes,
        'name',
        name,
        'children',
      ),
    );
  },

  getRouteByUrl(url) {
    return router.options.routes.find((route) => route.path === url);
  },

  getRouteChildren(name) {
    return this.getRouteByName(name).children;
  },
};

const findRoutesRecursively = (routes = [], menuName) => {
  if (routes.length <= 0) return [];

  let foundRoutes =
    routes.filter((route) =>
      route?.meta?.menu?.find((menu) => {
        if (isObject(menu)) {
          return menu.name === menuName;
        }

        return menu === menuName;
      }),
    ) || [];

  const routesWhichHasChildren = routes.filter((route) => route.children);

  foundRoutes = routesWhichHasChildren.reduce(
    (newFoundRoutes, route) =>
      newFoundRoutes.concat(findRoutesRecursively(route.children, menuName)),
    foundRoutes,
  );

  return foundRoutes;
};
