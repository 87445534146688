import type { RouteRecordRaw } from 'vue-router';

import domainResourceRegisterGuard from '@/guards/domains/domainResourceRegisterGuard';
import { Route } from '@/types';

const baseMetaData = {
  scrollToTop: false,
  header: false,
  showSubheader: false,
  fullscreen: true,
  isOnboarding: true,
};

const HostingSetupOnboarding = [
  {
    path: '/hostinger-pro/hosting-setup-onboarding/:orderId/:type?/:slug?/:subscriptionId?/:username?',
    meta: {
      title: 'Hostinger Pro',
    },
    component: () =>
      import('@/views/HostingerPro/HostingSetup/HostingSetupWrapper.vue'),
    children: [
      {
        path: 'hosting-select-platform',
        name: Route.HostingerPro.SELECT_PLATFORM,
        meta: {
          step: 1,
          isAccessRedirect: true,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/HostingSetup/HostingSetupPlatformSelection.vue'
          ),
      },
      {
        path: 'hosting-select-wordpress',
        name: Route.HostingerPro.SELECT_WORDPRESS,
        meta: {
          step: 2,
          ...baseMetaData,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordpress.vue'),
      },
      {
        path: 'hosting-select-preset',
        name: Route.HostingerPro.PRO_SELECT_WORDPRESS_PRESET,
        meta: {
          step: 2,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/HostingSetup/HostingSetupWordpressPresetWrapper.vue'
          ),
      },

      {
        path: 'hosting-select-plugin',
        name: Route.HostingerPro.PRO_SELECT_WORDPRESS_PLUGIN,
        meta: {
          step: 2,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/Onboarding/WordPressPlugins/OnboardingSelectWordpressPluginsV2.vue'
          ),
      },
      {
        path: 'hosting-select-theme',
        name: Route.HostingerPro.PRO_SELECT_WORDPRESS_THEME,
        meta: {
          step: 2,
          ...baseMetaData,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWPThemeV2.vue'),
      },
      {
        path: 'hosting-choose-website',
        name: Route.HostingerPro.CHOOSE_WEBSITE,
        meta: {
          step: 3,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/HostingSetup/HostingSetupDomainSearch.vue'
          ),
      },
      {
        path: 'hosting-existing-domain-summary',
        name: Route.HostingerPro.EXISTING_DOMAIN_SUMMARY,
        meta: {
          title: 'Choose Application Name',
          step: 3,
          ...baseMetaData,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingExistingDomainSummary.vue'),
      },
      {
        path: 'prosumer-buy-new-domain',
        name: Route.HostingerPro.PRO_BUY_DOMAIN,
        meta: {
          title: 'Buy new domain',
          step: 4,
          ...baseMetaData,
        },
        component: () =>
          import('@/views/Onboarding/Domain/OnboardingBuyNewDomainV2.vue'),
      },
      {
        path: 'template-preview',
        name: Route.HostingerPro.PREVIEW_WORDPRESS_TEMPLATE,
        meta: {
          title: 'Preview template',
          step: 5,
          ...baseMetaData,
        },
        component: () => import('@/views/Onboarding/OnboardingPreview.vue'),
      },
      {
        path: 'register-domain-resource-pro/:domain',
        name: Route.HostingerPro.PRO_REGISTER_DOMAIN_RESOURCE,
        meta: {
          title: 'Register domain',
          step: 5,
          ...baseMetaData,
        },
        beforeEnter: (to, from, next) =>
          domainResourceRegisterGuard(to, from, next),
        component: () =>
          import('@/views/HDomains/JWT/RegisterDomainResource.vue'),
      },
      {
        path: 'hosting-overview',
        name: Route.HostingerPro.HOSTING_OVERVIEW,
        meta: {
          step: 6,
          ...baseMetaData,
        },
        component: () =>
          import('@/views/HostingerPro/HostingSetup/HostingSetupOverview.vue'),
      },
      {
        path: 'hosting-being-built',
        name: Route.HostingerPro.BEING_BUILT,
        meta: baseMetaData,
        component: () =>
          import(
            '@/views/HostingerPro/HostingSetup/HostingSetupBeingBuilt.vue'
          ),
      },

      {
        path: 'hosting-setup-completed',
        name: Route.HostingerPro.SETUP_COMPLETED,
        meta: baseMetaData,
        component: () =>
          import('@/views/HostingerPro/HostingSetup/HostingSetupCompleted.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
export default HostingSetupOnboarding;
