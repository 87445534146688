import type { FieldOption, GroupedFieldOption } from '@hostinger/hcomponents';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useGlobals } from '@/composables';
import { PING_ENDPOINT, VPS_DATA_CENTERS } from '@/data/hVps';
import { hVpsRepo } from '@/repositories';
import type {
  IDataCenter,
  DataCenterLatencies,
  IDataCenterPingResponse,
  DataCenterName,
} from '@/types';
import { STORE_PERSISTENT_KEYS } from '@/types';
import { countryByCountryCode } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';

export const useVpsDataCenterStore = defineStore(
  'vpsDataCenterStore',
  () => {
    const { t } = useGlobals();
    const dataCenters = ref<IDataCenter[]>([]);
    const activeDataCenters = ref<IDataCenter[]>([]);
    const dataCenterLatencies = ref<DataCenterLatencies>();

    const setDataCenters = (data: IDataCenter[]) => {
      dataCenters.value = data.map((dataCenter) => {
        dataCenter.country = countryByCountryCode(dataCenter.location);

        return dataCenter;
      });
    };

    const setActiveDataCenters = (data: IDataCenter[]) => {
      activeDataCenters.value = data.map((dataCenter) => {
        dataCenter.country = countryByCountryCode(dataCenter.location);

        return dataCenter;
      });
    };

    const getDataCenterById = (dataCenterId: number) =>
      dataCenters.value.find(({ id }) => id === dataCenterId);

    const mapDataCenterOptions = (
      dataCenter: IDataCenter,
      serverProviderCode: string,
    ): FieldOption => {
      const { id, country, city, fullName } = dataCenter;
      if (!country) {
        return {
          label: fullName,
          value: String(id),
        };
      }

      const dataCentersInCountry = getActiveDataCentersByCountry(
        country,
        serverProviderCode,
      );
      const isMultipleDataCenters = dataCentersInCountry.length > 1;

      if (isMultipleDataCenters && city) {
        return {
          label: `${t(country)} - ${t(city)}`,
          value: String(id),
        };
      }

      return {
        label: t(country),
        value: String(id),
      };
    };

    const getDataCenterOptions = (serverProviderCode: string): FieldOption[] =>
      activeDataCenters.value
        .filter(({ providerCode }) => providerCode === serverProviderCode)
        .map((dataCenter) =>
          mapDataCenterOptions(dataCenter, serverProviderCode),
        );

    const getGroupedDataCenterOptions = (
      serverProviderCode: string,
    ): GroupedFieldOption[] => {
      const options = getDataCenterOptions(serverProviderCode);

      const groupedOptions = VPS_DATA_CENTERS.reduce((acc, { continent }) => {
        const existingContinent = acc.find(
          (item) => item.name === t(continent),
        );

        if (!existingContinent) {
          const continentOptions = options.filter(({ label }) => {
            const countryLabel = label.split(' - ')[0];

            const country = VPS_DATA_CENTERS.find(
              ({ country }) => t(country) === countryLabel,
            );

            return country?.continent === continent;
          });

          if (continentOptions.length) {
            return [
              ...acc,
              {
                name: t(continent),
                values: continentOptions,
              },
            ];
          }
        }

        return acc;
      }, [] as GroupedFieldOption[]);

      return groupedOptions;
    };

    const fetchDataCenters = async () => {
      const [{ data }, error] = await hVpsRepo.getDataCentersAll();

      if (!error) setDataCenters(data);
    };

    const fetchActiveDataCenters = async () => {
      const [{ data }, error] = await hVpsRepo.getDataCentersActive();

      if (!error) setActiveDataCenters(data);
    };

    const fetchDataCenterLatencies = async () => {
      try {
        await Promise.all(
          VPS_DATA_CENTERS.map(async ({ name, code }) => {
            const endpoint = getPingEndpoint(code);

            const response = await axios.get<IDataCenterPingResponse>(endpoint);

            const data = response.data;
            data.dataCenter = name;

            addLatencyData(snakeToCamelObj(data));
          }),
        );
      } catch (err) {
        errorLogger.logError(
          new Error('VPS data center ping error', {
            cause: err,
          }),
        );
      }
    };

    const addLatencyData = (data: IDataCenterPingResponse) => {
      if (!dataCenterLatencies.value) {
        dataCenterLatencies.value = {} as DataCenterLatencies;
      }

      dataCenterLatencies.value[data.dataCenter as DataCenterName] = Math.round(
        data.latencyMs,
      );
    };

    const getDataCenterLatencies = (serverProviderCode: string) => {
      const latencies = dataCenterLatencies.value;

      if (!latencies) return;

      return Object.keys(latencies)
        .filter((key) => {
          const dataCenter = getActiveDataCenterByName(
            key as DataCenterName,
            serverProviderCode,
          );

          return dataCenter?.providerCode === serverProviderCode;
        })
        .map((key) => ({
          dataCenter: key as DataCenterName,
          latency: latencies[key as keyof DataCenterLatencies],
        }))
        .sort((a, b) => a.latency - b.latency);
    };

    const getActiveDataCenterByName = (
      dataCenterName: DataCenterName,
      serverProviderCode: string,
    ) => {
      const data = VPS_DATA_CENTERS.find(({ name }) => name === dataCenterName);

      if (!data) return;

      return activeDataCenters.value.find(
        ({ name, providerCode }) =>
          name === data.code && providerCode === serverProviderCode,
      );
    };

    const getPingEndpoint = (code: string) =>
      PING_ENDPOINT.replace('{code}', code);

    const getActiveDataCentersByCountry = (
      country: string,
      providerCode: string,
    ) =>
      activeDataCenters.value.filter(
        (dataCenter) =>
          dataCenter.country === country &&
          dataCenter.providerCode === providerCode,
      );

    const getDataCenterCoordinates = (serverProviderCode: string) =>
      VPS_DATA_CENTERS.reduce((acc, { country, city, coordinates }) => {
        const countryDataCenters = getActiveDataCentersByCountry(
          country,
          serverProviderCode,
        );

        if (countryDataCenters.length > 1 && city) {
          const label = `${t(country)} - ${t(city)}`;

          return { ...acc, [label]: coordinates };
        }

        return { ...acc, [t(country)]: coordinates };
      }, {} as Record<string, string>);

    return {
      dataCenters,
      activeDataCenters,
      dataCenterLatencies,
      getDataCenterById,
      getDataCenterOptions,
      getGroupedDataCenterOptions,
      getDataCenterLatencies,
      getActiveDataCenterByName,
      fetchDataCenters,
      fetchActiveDataCenters,
      fetchDataCenterLatencies,
      getActiveDataCentersByCountry,
      getDataCenterCoordinates,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.VPS_DATA_CENTER } },
);
