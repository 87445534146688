import type { HToastrMessage, HToastrState } from '@/types';
import { HToastrMutations } from '@/types';

const state: HToastrState = {
  index: 0,
  hToastrMessages: [],
};

const mutations = {
  [HToastrMutations.ADD_HTOASTR_MESSAGE](
    state: HToastrState,
    { title, payload, status }: HToastrMessage,
  ) {
    if (!title) return;

    const id = state.index + 1;
    const timeout = payload?.timeout || 4000;

    if (payload?.dropdowns) {
      for (const dropdown of payload.dropdowns) {
        // set show initially for every dropdown
        dropdown.show = false;
      }
    }

    state.hToastrMessages = [
      { title, payload, status, id },
      ...state.hToastrMessages,
    ];

    // if there is no buttons/dropdowns, it can be closed after the timeout
    if ((!payload?.buttons && !payload?.dropdowns) || payload.forceTimeout) {
      setTimeout(() => {
        state.hToastrMessages = state.hToastrMessages.filter(
          (item) => item.id !== id,
        );
      }, timeout);
    }

    state.index++;
  },
  [HToastrMutations.REMOVE_HTOASTR_MESSAGE](
    state: HToastrState,
    action: HToastrMessage,
  ) {
    state.hToastrMessages = state.hToastrMessages.filter(
      (item) => item.id !== action.id,
    );
  },
  [HToastrMutations.REMOVE_HTOASTR_MESSAGE_CUSTOM_ID](
    state: HToastrState,
    customId: string,
  ) {
    state.hToastrMessages = state.hToastrMessages.filter(
      (item) => item.payload.customId !== customId,
    );
  },
};

export default {
  state,
  mutations,
};
