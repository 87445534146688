export namespace CpanelPhpStoreEnum {
  export enum Getters {
    GET_PHP_MANAGED = 'GET_PHP_MANAGED',
  }

  export enum Mutations {
    SET_PHP_DATA = 'SET_PHP_DATA',
    SET_PHP_LOADING = 'SET_PHP_LOADING',
  }

  export enum Actions {
    FETCH_PHP_DATA = 'FETCH_PHP_DATA',
  }
}
