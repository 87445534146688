export namespace Onboarding {
  export namespace Survey {
    export enum WebsiteFor {
      MYSELF = 'myself',
      OTHERS = 'others',
    }
    export enum WebsiteCreatedBy {
      MYSELF = 'myself',
      OTHER = 'other',
      DEVELOPER = 'developer',
    }
    export enum WorkAt {
      FREELANCER = 'freelancer',
      AGENCY = 'agency',
    }

    export enum WebsiteType {
      ONLINE_STORE = 'online-store',
      AFFILIATE_MARKETING = 'affiliate-marketing',
      BLOG = 'blog',
      BUSINESS = 'business',
      PORTFOLIO = 'portfolio',
      OTHER = 'other',
      DEFAULT = 'default',
    }
  }

  export enum CompletedStatus {
    COMPLETED = 'completed',
    ACTIVATED = 'activated',
  }

  export enum Platform {
    BUILDER = 'website-builder',
    WORDPRESS = 'wordpress',
    WOO = 'woo',
    JOOMLA = 'joomla',
    NONE = 'none',
    OTHER = 'other',
    EMPTY = '',
  }

  export const QUICK_INSTALL_SELECTIONS = {
    WORDPRESS: 'wordpress',
    BUILDER: 'website-builder',
    MIGRATE: 'migrate',
    REST_OPTIONS: 'rest-options',
    EMPTY_WEBSITE: 'empty-website',
  } as const;

  export type QuickInstallSelections =
    (typeof QUICK_INSTALL_SELECTIONS)[keyof typeof QUICK_INSTALL_SELECTIONS];

  export enum Event {
    PLATFORM_SELECTED = 'onboarding.website.platform_selected',
  }

  export enum CmsName {
    BUILDER = 'Hostinger Website Builder',
  }

  export enum Step {
    CREATE_WEBSITE = 'create-website',
    SURVEY = 'survey',
    BUILD_OR_MIGRATE = 'build-or-migrate',
    WEBSITE_TYPE_SURVEY = 'website_type_survey',
    RECOMMMENDED_TOOLS = 'recommended-tools',
    SELECT_TRANSFER = 'select-transfer',
    CHOOSEN_APP = 'choosen-app',
    EXISTING_DOMAIN = 'existing-domain',
    BUY_NEW_DOMAIN = 'buy-new-domain',
    EXISTING_DOMAIN_SUMMARY = 'existing-domain-summary',
    CREATE_CPANEL_PASSWORD = 'create-cpanel-password',
    CHOOSE_TEMPLATE = 'choose-template',
    OVERVIEW = 'overview',
    BEING_BUILT = 'being-built',
    ONBOARDING_COMPLETED = 'onboarding-completed',
    ONBOARDING_DOMAIN_SETUP = 'onboarding-domain-setup',
    CHOOSE_APP_NAME = 'choose-app-name',
    SELECT_WORDPRESS = 'select-wordpress',
    OTHER_PLATFORMS = 'other-platforms',
    IS_EXPERIENCED = 'is-experienced',
    SELECT_APPLICATION = 'select-application',
    SELECT_WORDPRESS_PLUGIN = 'select-wordpress-plugins',
    SELECT_WORDPRESS_PRESETS = 'select-wordpress-presets',
    SELECT_WORDPRESS_AI_CONTENT = 'select-wordpress-ai-content',
    SELECT_WORDPRESS_THEME_COLOR = 'select-wordpress-theme-color',
    SELECT_WORDPRESS_THEME_FONT = 'select-wordpress-theme-font',
    ONBOARDING_CPANEL_GETTING_READY = 'onboarding-cpanel-getting-ready',
    THEME_CARD_PREVIEW = 'theme-card-preview',
  }

  export enum Type {
    ONBOARDING = 'onboarding',
    EMAIL_ONBOARDING = 'email-onboarding',
    TITAN_EMAIL_ONBOARDING = 'titan-email-onboarding',
    BUILDER_DOMAIN_ONBOARDING = 'builder-domain-onboarding',
    PRO_PANEL_HOSTING_ONBOARDING = 'pro-panel-hosting-onboarding',
  }

  export enum WordPressPluginSlug {
    WOOCOMMERCE = 'woocommerce',
    GOOGLE_ANALYTICS_FOR_WORDPRESS = 'google-analytics-for-wordpress',
    OPTINMONSTER = 'optinmonster',
    ALL_IN_ONE_SEO_PACK = 'all-in-one-seo-pack',
    GOOGLE_SITE_KIT = 'google-site-kit',
    WP_FORMS = 'wpforms-lite',
    HOSTINGER_AFFILIATE_PLUGIN = 'hostinger-affiliate-plugin',
  }
}
