<script setup lang="ts">
import { useLanguages } from '@/composables/useLanguages';
import type { TLanguageCode } from '@/types/models/languageModels';

defineProps<{
  currentLanguageCode: TLanguageCode;
  languageCode: TLanguageCode | 'slug';
  active: boolean;
}>();

const emit = defineEmits<{
  (e: 'language-click', languageCode: TLanguageCode | 'slug'): void;
}>();

const { getLanguageByCode, getLanguageNameByCode } = useLanguages();
</script>
<template>
  <button
    class="language"
    :class="{
      'language--active': active,
    }"
    @click="emit('language-click', languageCode)"
  >
    <template v-if="languageCode !== 'slug'">
      <span class="language__title text-body-2">
        {{ getLanguageByCode(languageCode)?.nativeName }}
      </span>
      <span class="language__subtitle text-body-3">
        {{ getLanguageNameByCode(languageCode) }}
      </span>
    </template>
    <span v-else class="language__title text-body-2">Slug</span>
  </button>
</template>
<style lang="scss" scoped>
.language {
  background-color: unset;
  border: none;
  outline: none;

  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 12px;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-dark-hover);
  }
  &--active {
    background-color: var(--primary-hover);
    border-radius: 8px;
  }

  &--active :is(&__title, &__subtitle) {
    color: var(--primary-dark);
  }

  &__title {
    color: var(--dark);
  }
}
</style>
