import type { DnsRecord } from '@/types';

export const dnsRecordsHosting = [
  {
    value: '%ip%',
    type: 'A',
    host: '@',
    ttl: 3600,
  },
  {
    value: '%ip%',
    type: 'A',
    host: 'ftp',
    ttl: 3600,
  },
  {
    value: '@',
    type: 'CNAME',
    host: 'www',
    ttl: 3600,
  },
] as DnsRecord[];
