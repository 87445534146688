import type { OnboardingStep } from '@/types';

export interface AccountDetails {
  username: string;
  serverId: string;
  orderId: string;
  resellerId: string;
  ip: string;
  plan: string;
  planId: string;
  enabled: string;
  shellEnabled: boolean;
  backupInterval: string;
  createdAt: string;
  updatedAt: string;
  webServer: string;
  databaseVersion: string;
  inTransfer: boolean;
  lifetimeSslEnabled: boolean;
  reseller: string;
  nameservers: string[];
  ftpUser: string;
  server: AccountServer;
  usage: AccountUsage;
  planName: string;
  planLimits: AccountPlanLimits;
  vhosts: AccountVhost[];
  orderVhosts: AccountOrderVhost[];
  transferData: AccountTransferData;
  planBoost: AccountPlanBoost;
  order: AccountOrder;
  domain: string;
}

export interface AccountServer {
  hostname: string;
  country: string;
  title: string;
  database: AccountDatabase;
}

export interface AccountDatabase {
  webServer: string;
  mariadbVersion: any;
  ip: string;
  lanIp: string;
  hostname: string;
}

export interface AccountUsage {
  subdomains: AccountUsageItem;
  parkedDomains: AccountUsageItem;
  ftpAccounts: AccountUsageItem;
  databases: AccountUsageItem;
  storage: AccountUsageItem;
  inodes: AccountUsageItem;
}

export interface AccountUsageItem {
  value: number;
  limit: number;
}

export interface AccountPlanLimits {
  diskSpace: number;
  ram: number;
  cpuCores: number;
  maxAddons: number;
  activeProcesses: number;
  bandwidth: number;
  inodes: number;
  entryProcesses: number;
}

export interface AccountVhost {
  vhost: string;
  username: string;
  rootdir: string;
  vhostType: string;
  phpVersion: string;
  sslCertificateId: any;
  additionalData: AccountAdditionalData;
  parkedDomains: any[];
  subdomains: any[];
  ssl: Ssl;
  cloudflare: AccountCloudflare;
  nameservers: AccountNameservers;
  isFreeSubdomain: boolean;
  enabled: string;
}

export interface AccountAdditionalData {
  entries: number;
  'default.php': boolean;
  sslInstallInfo: SslInstallInfo;
}

export interface SslInstallInfo {
  status: string;
  attempts: number;
  provider: string;
  lastError: string;
  maxAttempts: number;
  nextAttempt: any;
}

export interface Ssl {
  status: string;
  isLifetimeSsl: boolean;
  provider: string;
}

export interface AccountCloudflare {
  active: boolean;
  subdomains: any;
}

export interface AccountNameservers {
  'ns1.dns-parking.com': string;
  'ns2.dns-parking.com': string;
}

export interface AccountOrderVhost {
  username: string;
  vhost: string;
  vhostType: string;
  enabled: string;
}

export interface AccountTransferData {
  allowTransfer: boolean;
  lastTransfer: any;
}

export interface AccountPlanBoost {
  orderId: string;
  boostedAt: any;
  canBeBoosted: boolean;
  activated: boolean;
  wasRecentlyCompleted: boolean;
}

export interface AccountOrder {
  title: string;
}
export interface ShoppingCartBody {
  resellerId: string;
  destinationEmail: string;
  cartId: string;
  paymentConfig: {
    orderDate: string;
    plan: string;
    price: number;
    quantity: number;
    totalPrice: number;
    discount: number;
    discountCode?: string;
  };
  customNote?: string;
}

export interface WebsiteTip {
  inactiveLitespeed: any;
  performanceDrop: any;
  orderUsageData: OrderUsageData;
}

export interface OrderUsageData {
  orderDiskUsageMb: string;
  orderDiskLimit: number;
  orderInodesUsage: string;
  orderInodesLimit: number;
}

export interface WebsiteDetails {
  sslEnabled: boolean;
  sslStatus: string;
  displayName: string;
  dailyBackupsEnabled: boolean;
  isWordpress: boolean;
  createdAt: string;
  accountEnabled: boolean;
  notifications: WebsiteNotification[];
  pendingRequests: boolean;
  serviceRestic: boolean;
}

export interface WebsiteNotification {
  type: string;
}

export interface TransferRecommendation {
  decision: string;
  traffic: TransferRecommendationTraffic[];
}

export interface TransferRecommendationTraffic {
  country: string;
  nearestServer: string;
  nearestServerVisitorsPercent: string;
  recommendedDatacenters: RecommendedDatacenter[];
}

export interface RecommendedDatacenter {
  code: string;
  title: string;
}

export interface InodeDiagnostics {
  size: number;
  path: string;
}

export const ACCOUNT_STATUS = {
  SUSPENDED: 'suspended',
  PENDING_DELETE: 'pending_delete',
} as const;

type AccountStatus = (typeof ACCOUNT_STATUS)[keyof typeof ACCOUNT_STATUS];

export interface IHostingAccount {
  id: string;
  serverId: string;
  datacenterName: string;
  inTransfer: boolean;
  username: string;
  domain: string;
  status: AccountStatus;
  created_at: string;
  type: string;
  subdomains: any[];
  parked_domains: any[];
  locked: boolean;
  freeDomain: boolean;
  customPlanName: null;
  plan_usage_pct: number;
  accountId: string;
  vhostSuspended: boolean;
  orderId: string;
  isWebsiteBuilder?: boolean;
}

export const ONBOARDING_STATUSES = {
  FAILED_ACCOUNT_CREATE: 'failed_account_create',
  GENERAL_FAILED: 'failed_',
  COMPLETED_MIGRATION: 'completed_migration',
  GENERAL_COMPLETED: 'completed_',
  PENDING: 'pending',
  ACTIVATED: 'activated',
  COMPLETED: 'completed',
} as const;

export type OnboardingStatus =
  (typeof ONBOARDING_STATUSES)[keyof typeof ONBOARDING_STATUSES];

export interface OnboardingStatusParams {
  orderId: string;
  domain?: string;
  redirectLocation?: string;
}

export interface OnboardingCompletionParams {
  orderId: string;
  domain?: string;
}

export interface OnboardingStatusData {
  domain: string;
  status: string;
  type: string;
  steps: OnboardingStep[];
  current_step: string | null;
  websiteBuilderResourceId?: string | number;
}

export interface OnboardingCompletionData {
  domain: string;
  status: string;
  type: string;
  steps: string[];
  currentStep: string | null;
  isPointing: boolean;
  isPreviewEnabled: boolean;
  previewLink: string | null;
  autoLoginLink: string;
}

export type OnboardingStatusResponse = boolean | OnboardingStatusData;

export interface IAccountChangePasswordRequest {
  password: string;
  passwordConfirmation?: string;
  vhost?: IHostingAccount['domain'];
}
