import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordRaw,
} from 'vue-router';

import { vpsFirewallManageGuard } from '@/guards/hVpsFirewallManageGuard';
import hVpsManagementGuard from '@/guards/hVpsManagementGuard';
import { Route } from '@/types';

export default [
  {
    path: '/servers',
    meta: {
      title: 'Servers',
      menu: ['horizontal'],
    },
    component: () => import('@/views/Servers/ServersWrapper.vue'),
    children: [
      {
        path: '',
        name: Route.Base.SERVERS,
        meta: {
          title: 'VPS',
        },
        component: () => import('@/views/Servers/MyServers.vue'),
      },
      {
        path: 'plans',
        name: Route.Vps.VPS_PLANS,
        meta: {
          title: 'VPS',
          showSubheader: false,
        },
        component: () => import('@/views/Servers/ServersPlans.vue'),
      },
    ],
  },

  {
    path: '/server/:id/upgrade',
    name: Route.Vps.VPS_UPGRADE,
    meta: {
      title: 'Upgrade plan',
      hideTitle: true,
      breadcrumbs: false,
    },
    component: () => import('@/views/Servers/Order/VpsOrderUpgrade.vue'),
  },
  {
    path: '/server/:id',
    meta: {
      title: 'Server',
      wrapperName: 'VPS',
      usePageWithSideMenu: true,
    },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => hVpsManagementGuard(to, from, next),
    component: () => import('@/views/Servers/ServerManagementWrapper.vue'),
    children: [
      {
        path: 'overview',
        name: Route.Vps.SERVER_OVERVIEW,
        redirect: { name: Route.Vps.SERVER_DETAILS },
        meta: {
          breadcrumbs: ['servers'],
          breadcrumbsTitle: {
            title: '{hostname}',
            translate: true,
          },
          title: 'Overview',
        },
        component: () => import('@/views/Servers/Overview/OverviewWrapper.vue'),
        children: [
          {
            path: '',
            name: Route.Vps.SERVER_DETAILS,
            meta: {
              subHeaderTitle: 'Overview',
              breadcrumbs: ['servers'],
              breadcrumbsTitle: {
                title: '{hostname} - Overview',
                translate: true,
              },
              title: 'Overview',
            },
            component: () =>
              import('@/views/Servers/Overview/ServerOverview.vue'),
          },
        ],
      },
      {
        path: 'assistant',
        name: Route.Vps.AI_ASSISTANT,
        meta: {
          subHeaderTitle: 'v2.kodee',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'v2.kodee',
          wrapperName: null,
          rateServicesTitle: 'v2.rate.ai_assistant',
        },
        component: () => import('@/views/Servers/Assistant/AiAssistant.vue'),
      },
      {
        path: 'settings',
        name: Route.Vps.SERVER_SETTINGS,
        meta: {
          subHeaderTitle: 'Settings',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Settings',
        },
        component: () => import('@/views/Servers/Settings/ServerSettings.vue'),
      },
      {
        path: 'usage',
        name: Route.Vps.SERVER_USAGE,
        meta: {
          subHeaderTitle: 'Server Usage',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Server Usage',
        },
        component: () => import('@/views/Servers/Usage/ServerUsage.vue'),
      },
      {
        path: 'snapshots-&-backups',
        name: Route.Vps.SNAPSHOTS_AND_BACKUPS,
        meta: {
          subHeaderTitle: 'Snapshots & Backups',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Snapshots & Backups',
        },
        component: () =>
          import('@/views/Servers/Snapshots/Snapshots&Backups.vue'),
      },
      {
        path: 'latest-actions',
        name: Route.Vps.LATEST_ACTIONS,
        meta: {
          subHeaderTitle: 'Latest Actions',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Latest Actions',
        },
        component: () =>
          import('@/views/Servers/LatestActions/LatestActions.vue'),
      },
      {
        path: 'operating-system',
        name: Route.Vps.OPERATING_SYSTEM,
        meta: {
          subHeaderTitle: 'Operating System',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Operating System',
        },
        component: () =>
          import('@/views/Servers/OperatingSystem/OperatingSystem.vue'),
      },
      {
        path: 'licenses',
        name: Route.Vps.LICENSES,
        meta: {
          subHeaderTitle: 'Licenses',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Licenses',
        },
        component: () => import('@/views/Servers/Licenses.vue'),
      },
      {
        path: 'firewall',
        children: [
          {
            path: '',
            name: Route.Vps.FIREWALL,
            meta: {
              subHeaderTitle: 'Firewall',
              breadcrumbs: ['servers', 'server-overview'],
              title: 'Firewall',
            },
            component: () => import('@/views/Servers/Firewall/Firewall.vue'),
          },
          {
            path: ':groupId',
            name: Route.Vps.FIREWALL_MANAGE,
            meta: {
              breadcrumbs: false,
              title: 'Firewall Configuration',
              hideBackWord: true,
            },
            beforeEnter: (to, from, next) =>
              vpsFirewallManageGuard(to, from, next),
            component: () =>
              import('@/views/Servers/Firewall/FirewallManage.vue'),
          },
        ],
      },
      {
        path: 'malware-scanner',
        name: Route.Vps.MALWARE_SCANNER,
        meta: {
          subHeaderTitle: 'Malware Scanner',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'Malware Scanner',
        },
        component: () =>
          import('@/views/Servers/MalwareScanner/MalwareScanner.vue'),
      },
      {
        path: 'dns-manager',
        name: Route.Vps.DNS_MANAGER,
        meta: {
          subHeaderTitle: 'DNS Manager',
          breadcrumbs: ['servers', 'server-overview'],
          title: 'DNS Manager',
        },
        component: () => import('@/views/Servers/Domain/DnsManager.vue'),
      },
      {
        path: 'vps-help',
        name: Route.Vps.VPS_HELP,
        meta: {
          title: 'Help',
          showSubheader: false,
        },
      },
    ],
  },
] as RouteRecordRaw[];
