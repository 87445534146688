import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordRaw,
} from 'vue-router';

import setMainDomainGuard from '@/guards/hosting/setMainDomainGuard';
import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'domains',
    name: Route.HostingDomains.DOMAINS,
    meta: {
      title: 'Domains',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#domains' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'subdomains',
        name: Route.HostingDomains.SUBDOMAINS,
        meta: {
          title: 'Subdomains',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDomains.DOMAINS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Domains/Subdomains.vue'),
      },
      {
        path: 'set-main-domain',
        name: Route.HostingDomains.SET_MAIN_DOMAIN,
        meta: {
          title: 'Set Main Domain',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDomains.DOMAINS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Domains/ChangeDomain.vue'),
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext,
        ) => setMainDomainGuard(to, from, next),
      },
      {
        path: 'parked-domains',
        name: Route.HostingDomains.PARKED_DOMAINS,
        meta: {
          title: 'Parked Domains',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDomains.DOMAINS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Domains/ParkedDomains.vue'),
      },

      {
        path: 'redirects',
        name: Route.HostingDomains.REDIRECTS,
        meta: {
          title: 'Redirects',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDomains.DOMAINS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Domains/Redirects.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
