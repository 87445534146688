export const INDIA_STATES_SELECT = [
  { value: 'AN', label: 'Andaman and Nicobar Islands' },
  { value: 'AP', label: 'Andhra Pradesh' },
  { value: 'AR', label: 'Arunāchal Pradesh' },
  { value: 'AS', label: 'Assam' },
  { value: 'BR', label: 'Bihār' },
  { value: 'CH', label: 'Chandīgarh' },
  { value: 'CT', label: 'Chhattīsgarh' },
  { value: 'DL', label: 'Delhi' },
  { value: 'DH', label: 'Dādra and Nagar Haveli and Damān and Diu' },
  { value: 'GA', label: 'Goa' },
  { value: 'GJ', label: 'Gujarāt' },
  { value: 'HR', label: 'Haryāna' },
  { value: 'HP', label: 'Himāchal Pradesh' },
  { value: 'JK', label: 'Jammu and Kashmīr' },
  { value: 'JH', label: 'Jhārkhand' },
  { value: 'KA', label: 'Karnātaka' },
  { value: 'KL', label: 'Kerala' },
  { value: 'LA', label: 'Ladākh' },
  { value: 'LD', label: 'Lakshadweep' },
  { value: 'MP', label: 'Madhya Pradesh' },
  { value: 'MH', label: 'Mahārāshtra' },
  { value: 'MN', label: 'Manipur' },
  { value: 'ML', label: 'Meghālaya' },
  { value: 'MZ', label: 'Mizoram' },
  { value: 'NL', label: 'Nāgāland' },
  { value: 'OR', label: 'Odisha' },
  { value: 'PY', label: 'Puducherry' },
  { value: 'PB', label: 'Punjab' },
  { value: 'RJ', label: 'Rājasthān' },
  { value: 'SK', label: 'Sikkim' },
  { value: 'TN', label: 'Tamil Nādu' },
  { value: 'TG', label: 'Telangāna' },
  { value: 'TR', label: 'Tripura' },
  { value: 'UP', label: 'Uttar Pradesh' },
  { value: 'UT', label: 'Uttarākhand' },
  { value: 'WB', label: 'West Bengal' },
];
