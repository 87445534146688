import type { Directive } from 'vue';

import { router } from '@/router';

const generateId = (element: HTMLElement, value: string) => {
  const currentRoute = router.currentRoute.value;

  const textId =
    element.innerText?.trim().replace(/[\W_]/g, '_').toLowerCase() || '';
  const routeId =
    (currentRoute?.name as string)?.replace(/_/g, '-') ||
    currentRoute?.path?.replace(/\//g, '-') ||
    '';

  return `hpanel_tracking-${routeId}-${textId}_${value}`;
};

const qaGenerate: Directive = {
  async mounted(element: HTMLElement, binding) {
    const value = binding.value || 'button';

    if (element.getAttribute('data-qa')) {
      return;
    }

    element.setAttribute('data-qa', generateId(element, value));
  },
};

export default qaGenerate;
