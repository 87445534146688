import { computed, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useFeatureFlag } from '@/composables';
import { useLanguageStore } from '@/stores';
import {
  ELIGIBLE_LANGUAGES_FOR_SALES_CALL,
  FeatureFlag,
  HOSTINGER_CONSULTATION,
  Route,
  type TEligibleLanguages,
} from '@/types';
import { SessionStorage } from '@/types/enums/storageEnum';

const SALES_MODAL_TIMER_SHOW_DELAY = 5000;

const shouldShowSalesCallCTA = ref(false);

export const useSalesCallModal = () => {
  const route = useRoute();
  const languageStore = useLanguageStore();
  const { isFeatureEnabled } = useFeatureFlag(FeatureFlag.ID.SALES_CTA);

  const wasSalesCallModalOpened = sessionStorage.getItem(
    SessionStorage.SALES_CALL_MODAL_SHOWN,
  );

  const isLanguageEligible = computed(
    () =>
      !!ELIGIBLE_LANGUAGES_FOR_SALES_CALL.some(
        (lang) => lang === languageStore.currentLocaleUppercase,
      ),
  );

  const isEligibleForSalesCallModal = computed(
    () =>
      isLanguageEligible.value &&
      route.name === Route.Base.BUY_HOSTING &&
      !wasSalesCallModalOpened &&
      isFeatureEnabled.value,
  );

  const timer = ref<NodeJS.Timeout>();

  const initSalesCallTimer = () => {
    if (!isEligibleForSalesCallModal.value) {
      return;
    }

    timer.value = setTimeout(() => {
      shouldShowSalesCallCTA.value = true;
    }, SALES_MODAL_TIMER_SHOW_DELAY);
  };

  const clearSalesCallTimer = () => {
    shouldShowSalesCallCTA.value = false;
    clearTimeout(timer.value);
  };

  const salesLinkForCurrentLocale = computed(() => {
    if (isLanguageEligible.value) {
      return HOSTINGER_CONSULTATION[
        languageStore.currentLocaleUppercase as TEligibleLanguages
      ];
    }

    return '';
  });

  onBeforeUnmount(() => {
    clearSalesCallTimer();
  });

  return {
    initSalesCallTimer,
    clearSalesCallTimer,
    shouldShowSalesCallCTA,
    salesLinkForCurrentLocale,
  };
};
