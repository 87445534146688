import type { amplitudeV2 as amplitudeV2Original } from '@/plugins/amplitudeV2';
import { router } from '@/router';
import { AmplitudeLocation, AmplitudeEvent, Route } from '@/types';
import { getSldTld, mapKeyValue, toUnicode } from '@/utils/helpers';

export const dispatchChangeSldModalShownAmplitudeEvent = (
  amplitudeV2: typeof amplitudeV2Original,
  {
    domain,
    subscriptionId,
  }: {
    domain: string;
    subscriptionId: string;
  },
) => {
  const location = getAmplitudeLocation(
    router.currentRoute.value.name as string,
  );

  const [sld, tld] = getSldTld(toUnicode(domain), { omitDot: true });

  amplitudeV2(AmplitudeEvent.Domain.CHANGE_SLD_SHOWN, {
    tld,
    sld,
    location,
    subscriptionId,
  });
};

export const dispatchChangeSldModalSuccessAmplitudeEvent = (
  amplitudeV2: typeof amplitudeV2Original,
  {
    previousDomain,
    newDomain,
    subscriptionId,
  }: {
    previousDomain: string;
    newDomain: string;
    subscriptionId: string;
  },
) => {
  const location = getAmplitudeLocation(
    router.currentRoute.value.name as string,
  );

  const [previousSld, tld] = getSldTld(toUnicode(previousDomain), {
    omitDot: true,
  });
  const [newSld] = getSldTld(toUnicode(newDomain), { omitDot: true });

  amplitudeV2(AmplitudeEvent.Domain.SLD_CHANGED, {
    tld,
    location,
    subscriptionId,
    previousSld,
    newSld,
  });
};

const getAmplitudeLocation = (routeName: string) =>
  mapKeyValue(
    {
      [Route.Domain.MY_DOMAINS]: AmplitudeLocation.Base.DASHBOARD,
      [Route.Base.HOME]: AmplitudeLocation.Base.HOME_PAGE,
      default: '',
    },
    routeName,
  );
