import { getLocationParams } from '@/utils/getLocationParams';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const STORE_API_URL = `${process.env.VITE_API_SSO}/v1/store`;

export default {
  async googleWorkspaceInstaUpgrade(params) {
    return await hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/google-gsuite/instant`, {
        ...params,
      }),
    );
  },

  async googleWorkspaceUpgradeInvoice(params) {
    return await hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/google-gsuite`, {
        ...params,
      }),
    );
  },

  async getTransferPricing(productSlug) {
    return await hAsync(
      http.get(`${STORE_API_URL}/domain-transfer/pricing`, {
        params: { productSlug },
      }),
    );
  },
  async getHostingPricing(productSlug) {
    return await hAsync(
      http.get(`${STORE_API_URL}/pricing`, {
        params: { productSlug },
      }),
    );
  },
  async getTldPricing(params) {
    return await hAsync(
      http.get(`${STORE_API_URL}/domain-tld/pricing`, {
        params: {
          tlds: params.tlds,
          allTld: params.allTld,
        },
      }),
    );
  },

  async createFlockInvoice(params) {
    return await hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/flock`, {
        ...params,
      }),
    );
  },

  async upgradeFlockOrder(params) {
    return await hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/flock/instant`, {
        ...params,
      }),
    );
  },

  async purchaseHvpsLicenseInstant(params) {
    return await hAsync(
      http.post(`${STORE_API_URL}/vps-license/instant`, {
        ...params,
      }),
    );
  },

  vpsDailyBackupsShow(params) {
    return hAsync(
      http.get(`${STORE_API_URL}/upsell/vps-daily-backup`, {
        params,
        cache: 36000,
      }),
    );
  },

  flockUpgradeInstaPay(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/flock/instant`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  flockInstaPay(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/flock/instant`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  flockPaymentInvoice(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/flock`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  flockUpgradePaymentInvoice(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/flock`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  emailPurchaseInvoice(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/email`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  emailPurchaseInstantPay(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/email/instant`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  emailUpgradeInvoice(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/email`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  emailUpgradeInstantPay(invoiceData) {
    return hAsync(
      http.post(`${STORE_API_URL}/email/upgrade/email/instant`, {
        ...invoiceData,
        ...getLocationParams(),
      }),
    );
  },

  upsell(upsellName) {
    return hAsync(
      http.get(`${STORE_API_URL}/upsell`, {
        params: { upsellName },
      }),
    );
  },
};
