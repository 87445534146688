import type { ModalObject } from '@/types';

export default [
  {
    name: 'DisableAutoRenewModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'confirmation',
    props: {
      title: 'Disable auto-renewal?',
      imgSrc: 'modal/modal-renew.svg',
    },
  },
  {
    name: 'AutoRenewEnabledModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'notification',
    props: {
      title: 'Auto-renewal enabled',
      subtitle:
        'Auto-renewal has been succesfully enabled. The subscription will renew automatically.',
      imgSrc: 'ic_check_full.svg',
    },
  },
  {
    name: 'RemoveAddonModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'confirmation',
    props: {
      imgSrc: 'modal/modal-renew.svg',
    },
  },
  {
    name: 'AutoRenewEnabledAddonModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'notification',
    props: {
      title: 'Auto-renewal enabled',
      subtitle:
        'Auto-renewal has been succesfully enabled. The addon will renew automatically.',
      imgSrc: 'ic_check_full.svg',
    },
  },
  {
    name: 'ChangeBillingPeriodModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'input',
    props: {
      title: 'Change billing period?',
      subtitle: 'You will change your next renewal period.',
      imgSrc: 'modal/modal-warning-circle-primary.svg',
    },
  },
  {
    name: 'RemovePaymentMethodModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'confirmation',
    props: {
      title: 'Remove Payment Method?',
      imgSrc: 'modal/modal-trash.svg',
    },
  },
  {
    name: 'CantRemovePaymentMethodModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'notification',
    props: {
      title: 'Can’t remove payment method',
      subtitle:
        'Please make sure you don’t have any subscriptions that renews automatically before removing payment method',
      imgSrc: 'modal/modal-trash.svg',
    },
  },
  {
    name: 'HBillingPayInvoiceModal',
    type: 'HModal',
    subtype: 'HBilling',
    closingType: 'payment',
  },
  {
    name: 'CantRenewDomainModal',
    type: 'HModalFancy',
    subtype: 'HBilling',
    closingType: 'notification',
    props: {
      title: 'Can’t Renew Your Domain',
      subtitle:
        'The subscription is too close to the expiration date. Please contact us to renew the service.',
      imgSrc: 'modal/modal-warning-circle.svg',
    },
  },
  {
    name: 'HBillingHostingUpgradeRecommendationModal',
    type: 'HModal',
    subtype: 'HBilling',
    closingType: 'payment',
  },
  {
    name: 'RequestIndonesianTaxInvoiceModal',
    type: 'HModal',
    subtype: 'HBilling',
    props: {
      hideX: true,
    },
  },
] as ModalObject[];
