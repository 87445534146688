import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useOnboardingStore } from './onboardingStore';

import { wordpressRepo } from '@/repositories';
import type { IWordPressThemeResponseV3, WordpressTheme } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

export const useOnboardingWordPressThemesStore = defineStore(
  'onboardingWordPressThemesStore',
  () => {
    const wordPressThemes = ref<IWordPressThemeResponseV3>();
    const isLoadingWordPressThemes = ref(false);
    const onboardingStore = useOnboardingStore();

    const themesByWebsiteType = computed((): WordpressTheme[] =>
      mapKeyValue(
        wordPressThemes.value || { default: [] },
        onboardingStore.state.typeOfWebsite || '',
      ),
    );

    const selectedTheme = computed(() => {
      const emptyWordPressTheme = {
        featuredImageUrl: '',
        link: '',
        slug: '',
        title: '',
        isCustom: undefined,
        imgUrl: '',
        fullImageUrl: '',
        description: '',
        images: [],
        layouts: [],
        palettes: [],
        fonts: [],
      };

      const selectedTheme =
        themesByWebsiteType.value.find(
          (theme) => theme.slug === onboardingStore.state.wordpressThemeSelect,
        ) || emptyWordPressTheme;

      return selectedTheme;
    });

    const selectedThemePreviewImage = computed(() => {
      const paletteId = onboardingStore.state.hostingerThemeSelect?.paletteId;
      const layoutId = onboardingStore.state.hostingerThemeSelect?.layoutId;

      if (!paletteId || !layoutId) return undefined;

      const found = selectedTheme.value.images?.find(
        ({ palette, layout }) => palette === paletteId && layout === layoutId,
      );

      if (!found) return undefined;

      return {
        src: found.image,
        alt: 'Wordpress theme preview',
        isExternal: true,
      };
    });

    const fetchWordPressThemes = async ({
      subscriptionId,
    }: {
      subscriptionId: string;
    }) => {
      isLoadingWordPressThemes.value = true;
      const [{ data }, err] = await wordpressRepo.getWordpressThemesV3({
        orderId: subscriptionId,
      });
      isLoadingWordPressThemes.value = false;

      if (err || !data) return;

      wordPressThemes.value = data;
    };

    return {
      fetchWordPressThemes,
      themesByWebsiteType,
      selectedThemePreviewImage,
      selectedTheme,
      wordPressThemes,
      isLoadingWordPressThemes,
    };
  },
);
