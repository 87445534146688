import { computed, onBeforeUnmount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useHostingSetupStore } from '@/stores';
import { Onboarding, Route } from '@/types';
import { timeout } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const ONBOARDING_INTERVAL_TIMEOUT = 5000;

enum OnboardingPath {
  HOTING_SETUP_ONBOARDING = 'hosting-setup-onboarding',
}

export const useOnboardingStatusCheck = () => {
  const store = useStore();
  const hostingSetupStore = useHostingSetupStore();
  const route = useRoute();
  const router = useRouter();
  const isStatusPollStopped = ref(false);

  const isProHostingSetupOnboarding = computed(() =>
    route.path.includes(OnboardingPath.HOTING_SETUP_ONBOARDING),
  );

  const isOnboardingStatusCompleted = (status: Onboarding.CompletedStatus) =>
    [
      Onboarding.CompletedStatus.ACTIVATED,
      Onboarding.CompletedStatus.COMPLETED,
    ].includes(status);

  const checkOnboardingStatusOnce = async () => {
    if (isProHostingSetupOnboarding.value) {
      const [data, error] = await hostingSetupStore.getOnboardingStatus(
        String(route.params.subscriptionId || route.params.orderId),
      );

      return [{ data }, error];
    }

    const [{ data }, error] = store.getters.getIsCpanelOnboarding
      ? await store.dispatch('getCpanelOnboardingState')
      : await store.dispatch('getOnboardingState', route.params.order_id);

    return [{ data }, error];
  };

  const pollOnboardingUntilCompletion = (
    timeoutInterval = ONBOARDING_INTERVAL_TIMEOUT,
  ) =>
    new Promise<
      [{ data: { status: Onboarding.CompletedStatus } } | undefined, any]
    >(async (resolve, reject) => {
      try {
        const loop = async () => {
          if (isStatusPollStopped.value) {
            return resolve([undefined, new Error('Polling stopped')]);
          }

          const [{ data }, error] = await checkOnboardingStatusOnce();

          if (error) {
            errorLogger.logError('Onboarding status check error');

            resolve([{ data }, error]);

            router.push({ name: Route.Base.HOME });

            return;
          }
          if (isOnboardingStatusCompleted(data.status)) {
            return resolve([{ data }, error]);
          }

          await timeout(timeoutInterval);

          loop();
        };

        loop();
      } catch (error) {
        reject(error);
      }
    });

  onBeforeUnmount(() => {
    isStatusPollStopped.value = true;
  });

  return {
    pollOnboardingUntilCompletion,
    isOnboardingStatusCompleted,
    checkOnboardingStatusOnce,
    isProHostingSetupOnboarding,
  };
};
