import type { RouteRecordRaw } from 'vue-router';

import h5gWebsiteGuard from '@/guards/h5g/h5gWebsiteGuard';
import { Route } from '@/types';

export default [
  {
    path: '/h5g/website/:websiteUid',
    name: Route.H5G.BASE_PATH,
    meta: {
      menu: ['horizontal'],
      title: 'Website',
      sideMenuExists: true,
      sideMenuComponent: 'H5GSidebar',
      breadcrumbs: [Route.H5G.WEBSITES],
      wrapperName: 'hosting',
    },
    beforeEnter: h5gWebsiteGuard,
    component: () => import('@/views/H5G/H5GWebsiteWrapper.vue'),
    children: [
      {
        path: 'dashboard',
        name: Route.H5G.DASHBOARD,
        meta: {
          breadcrumbsTitle: {
            title: 'Dashboard',
            translate: true,
          },
          title: 'Dashboard',
          menu: ['horizontal'],
          breadcrumbs: [Route.Base.WEBSITES],
        },
        component: () => import('@/views/H5G/components/H5GWebsite.vue'),
      },
      {
        path: 'databases',
        name: Route.H5G.DATABASES,
        meta: {
          breadcrumbsTitle: {
            title: 'Databases',
            translate: true,
          },
          title: 'Databases',
          menu: ['horizontal'],
          breadcrumbs: [Route.Base.WEBSITES],
        },
        component: () => import('@/views/H5G/components/H5GDatabases.vue'),
      },
      {
        path: 'resource-usage',
        name: Route.H5G.RESOURCE_USAGE,
        meta: {
          breadcrumbsTitle: {
            title: 'Resource Usage',
            translate: true,
          },
          title: 'Resource Usage',
          menu: ['horizontal'],
          breadcrumbs: [Route.Base.WEBSITES],
        },
        component: () => import('@/views/H5G/components/H5GResourceUsage.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
