import { CPanel } from '@/types';
import type { CPanelEmailHostingSpecs } from '@/types/models/cpanel/cPanelModels';

export const cPanelEmailSpecs: CPanelEmailHostingSpecs = {
  [CPanel.Plan.CPANEL_MAIL_PRO]: {
    packageName: 'Mail Pro',
    diskSpace: '25 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
  [CPanel.Plan.CPANEL_MAIL_BUSINESS]: {
    packageName: 'Mail Business',
    diskSpace: '50 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
  [CPanel.Plan.CPANEL_MAIL_BUSINESS_EXTRA]: {
    packageName: 'Mail Business Extra',
    diskSpace: '100 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
  [CPanel.Plan.CPANEL_MAIL_ENTERPRISE]: {
    packageName: 'Mail Enterprise',
    diskSpace: '200 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
  [CPanel.Plan.MAIL_BUSINESS]: {
    packageName: 'Mail Business',
    diskSpace: '50 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
  [CPanel.Plan.MAIL_BUSINESS_EXTRA]: {
    packageName: 'Mail Business Extra',
    diskSpace: '100 GB',
    emailAccounts: 'Unlimited',
    maximumAttachmentSize: '30 MB',
    emailDeliveryLimit: 400,
  },
};

export const cPanelEmailHostingPackages = [
  CPanel.Plan.CPANEL_MAIL_PRO,
  CPanel.Plan.CPANEL_MAIL_BUSINESS,
  CPanel.Plan.CPANEL_MAIL_BUSINESS_EXTRA,
  CPanel.Plan.CPANEL_MAIL_ENTERPRISE,
  CPanel.Plan.MAIL_BUSINESS,
  CPanel.Plan.MAIL_BUSINESS_EXTRA,
] as const;
