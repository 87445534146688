<script lang="ts" setup>
interface Props {
  title: string;
}

defineProps<Props>();

const emit = defineEmits<{
  'send-amplitude': [];
}>();
</script>

<template>
  <div @click="emit('send-amplitude')">
    <li class="h-nav__item cursor-pointer d-flex align-items-center">
      <span v-trans class="h-nav__link">
        {{ title }}
      </span>
    </li>
  </div>
</template>

<style lang="scss" scoped>
.h-nav {
  &__item {
    padding: 8px 0;
    border-radius: 0px 32px 32px 0px;
    color: var(--gray);
    cursor: pointer;

    &:hover {
      background-color: rgba(218, 220, 224, 0.24);
    }
  }

  &__link {
    padding-left: 80px;
    padding-right: 12px;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
