export namespace CpanelWordPressStoreEnum {
  export enum Getters {
    GET_CURRENT_MANAGED_SITE = 'GET_CURRENT_MANAGED_SITE',
    GET_STAGING_LIST_BY_INSTALLATION_ID = 'GET_STAGING_LIST_BY_INSTALLATION_ID',
    GET_CLONE_LIST_BY_INSTALLATION_ID = 'GET_CLONE_LIST_BY_INSTALLATION_ID',
  }

  export enum Mutations {
    SET_INSTALLATIONS = 'SET_INSTALLATIONS',
    SET_INSTALLATION_LOADING = 'SET_INSTALLATION_LOADING',
    SET_CURRENT_MANAGED_ID = 'SET_CURRENT_MANAGED_ID',
    DELETE_CURRENT_MANAGED_ID = 'DELETE_CURRENT_MANAGED_ID',
    SET_FORCE_HTTPS = 'SET_FORCE_HTTPS',
    UPDATE_FORCE_HTTPS = 'UPDATE_FORCE_HTTPS',
    SET_LITE_SPEED = 'SET_LITE_SPEED',
  }

  export enum Actions {
    FETCH_INSTALLATIONS = 'FETCH_WORDPRESS_INSTALLATIONS',
    FETCH_FORCE_HTTPS = 'FETCH_FORCE_HTTPS',
    FETCH_LITE_SPEED = 'FETCH_LITE_SPEED',
  }
}

export namespace CpanelWordPressEnum {
  export enum State {
    FORCE_HTTPS = 'forceHttps',
    LITE_SPEED = 'liteSpeed',
  }

  export enum liteSpeedStatus {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
  }
}
