import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useH5GWebsite } from '@/composables';
import { h5gResourceUsageRepo, h5gOrdersRepo } from '@/repositories';
import type {
  IH5GDiskUsageResponse,
  IH5GResourceUsageResponse,
  ResourceUsagePeriod,
} from '@/types';

export const useH5GResourceUsageStore = defineStore(
  'h5gResourceUsageStore',
  () => {
    const { h5gWebsiteUid, h5gWebsiteOrderId } = useH5GWebsite();

    const websiteResourceUsage = ref<IH5GResourceUsageResponse | null>(null);
    const websiteDiskUsage = ref<IH5GDiskUsageResponse | null>(null);

    const isResourceUsageLoading = ref(false);
    const isDiskUsageLoading = ref(false);
    const period = ref<ResourceUsagePeriod | null>();

    const fetchResourceUsage = async () => {
      if (!period.value) return;

      isResourceUsageLoading.value = true;
      const [{ data }, error] =
        await h5gResourceUsageRepo.getWebsiteResourceUsage(
          h5gWebsiteUid.value,
          period.value,
        );

      isResourceUsageLoading.value = false;

      if (!data || error) {
        return;
      }

      websiteResourceUsage.value = data;
    };

    const fetchDiskUsage = async () => {
      if (!period.value || !h5gWebsiteOrderId.value) return;

      isDiskUsageLoading.value = true;
      const [{ data }, error] = await h5gOrdersRepo.getOrderDiskUsage(
        h5gWebsiteOrderId.value,
        period.value,
      );

      isDiskUsageLoading.value = false;

      if (!data || error) {
        return;
      }

      websiteDiskUsage.value = data;
    };

    const $reset = () => {
      period.value = null;
      isResourceUsageLoading.value = false;
      websiteResourceUsage.value = null;
      isDiskUsageLoading.value = false;
      websiteDiskUsage.value = null;
    };

    return {
      $reset,
      fetchResourceUsage,
      fetchDiskUsage,
      websiteResourceUsage,
      websiteDiskUsage,
      period,
      isResourceUsageLoading,
      isDiskUsageLoading,
    };
  },
);
