import type { NavigationGuard, RouteLocationNormalized } from 'vue-router';

import { useMenu } from '@/composables';
import { hResourcesRepo } from '@/repositories';
import store from '@/store';
import { useHostingDashboardStore, useWordpressStore } from '@/stores';
import type { WebsiteType } from '@/types';
import { Route, Website } from '@/types';
import { toASCII } from '@/utils/helpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { authRedirectService } from '@/utils/services/http/authRedirectService';
import { i18n } from '@/utils/services/i18nService';

const PAID_DOMAIN_ROUTES = [
  Route.HostingDomains.SUBDOMAINS,
  Route.HostingDomains.PARKED_DOMAINS,
  Route.HostingAdvanced.DNS_ZONE_EDITOR,
  Route.HostingSecurity.SSL,
];

const accountAllowedToManage = (
  domain: string,
  to: RouteLocationNormalized,
) => {
  const allowToManage = store.getters.getAccountAllowManage(domain);

  return (
    allowToManage ||
    (!allowToManage &&
      [Route.Order.ORDER_UPGRADE_TYPE, Route.Order.ORDER_UPGRADE].includes(
        to.name as Route.Order,
      ))
  );
};

const zyroBuilderNotFound = () => {
  toastr.e(i18n.t('Website not found'));

  return { name: Route.Base.HOME };
};

const freeSubdomainNotAllowed = (websiteDetails: WebsiteType) => {
  toastr.e(i18n.t('You have no access for the page'));
  const isWebsiteBuilder = websiteDetails.type === Website.Type.BUILDER;

  return {
    name: Route.Websites.WEBSITE_DASHBOARD,
    params: {
      domain: websiteDetails.website,
      isWebsiteBuilder: String(isWebsiteBuilder),
    },
  };
};

const getIsFreeSubdomain = (website: WebsiteType) =>
  website.isFreeSubdomain === '1';

const hostingGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  const hostingDashboardStore = useHostingDashboardStore();
  const wordPressStore = useWordpressStore();

  const hasDomainChanged = to.params.domain !== from.params.domain;

  if (hasDomainChanged && typeof to.params.domain === 'string') {
    wordPressStore.isWordPressVersionValid = null;
    store.dispatch('getDomainResources', to.params.domain);

    wordPressStore.isWordPressVersionValidLoading = true;

    await hostingDashboardStore.fetchWebsiteDetails(to.params.domain);

    if (!hostingDashboardStore.websiteDetails) {
      return { name: Route.Base.WEBSITES };
    }

    wordPressStore.isWordPressVersionValidLoading =
      hostingDashboardStore.isWordPressWebsite;
  }

  if (PAID_DOMAIN_ROUTES.includes(to.name as Route.HostingDomains)) {
    if (!hostingDashboardStore.websiteDetails) {
      return { name: Route.Base.HOSTING };
    }

    if (getIsFreeSubdomain(hostingDashboardStore.websiteDetails)) {
      return freeSubdomainNotAllowed(hostingDashboardStore.websiteDetails);
    }
  }

  const { getMenuItemById } = useMenu();

  if (getMenuItemById('websites') && to.query.wb === 'true') {
    const [resources, resourcesError] = await hResourcesRepo.getResources(
      {
        type: ['website_builder'],
      },
      { hideToastr: true },
    );

    if (resourcesError) return zyroBuilderNotFound();

    const zyroResourceId = resources.data.find(
      ({ title, state }) =>
        title === toASCII(to.params.domain as string) && state !== 'deleted',
    )?.id;

    if (!zyroResourceId) return zyroBuilderNotFound();

    const result = await authRedirectService.redirectToBuilder(zyroResourceId);

    if (!result) return zyroBuilderNotFound();

    const [{ data }, err] = result;

    if (err || !data.redirectUrl) return zyroBuilderNotFound();

    const amplitudeLocation = to.query.amplitudeLocation?.toString() || '';

    const url = new URL(data.redirectUrl);

    url.searchParams.set('amplitudeLocation', amplitudeLocation);

    store.commit('SET_REDIRECT', { showGlobalAppLoader: true });
    window.open(url, '_self');

    return;
  }

  const domain = to.params.domain || to.params.manage_domain;
  if (!store.getters.accountExists(domain)) {
    return { name: Route.Base.HOSTING };
  }

  if (!accountAllowedToManage(domain as string, to)) {
    toastr.i(
      i18n.t(
        store.getters.getAccountAllowManageMessage(domain) ||
          'You have no access for the page {title}',
        { title: to?.meta?.title },
      ),
    );

    return { name: Route.Base.HOSTING };
  }
};

export default hostingGuard;
