<template>
  <div class="intercom-ticket-mode" :class="{ priority: isPriorityClient }">
    <RouterLink
      id="hpanel_tracking-intercom-link-help"
      class="d-block intercom-ticket-mode__logo"
      :to="{
        name: 'help',
        query: {
          amplitudeLocation: AmplitudeLocation.Action.BOTTOM_LEFT_CORNER_BUTTON,
        },
      }"
    >
      <HImage class="w-100" src="@/images/intercom.svg" :alt="$t('Help')" />
    </RouterLink>
  </div>
</template>

<script>
import { useIntercom } from '@/composables';
import { useProfileStore } from '@/stores';
import { AmplitudeLocation } from '@/types';

export default {
  name: 'IntercomTicketMode',
  setup() {
    const { intercomStyles } = useIntercom();
    const profileStore = useProfileStore();

    return { intercomStyles, profileStore, AmplitudeLocation };
  },
  data() {
    return {
      intercomDimensions: this.intercomStyles.INTERCOM_DIMENSIONS,
      intercomPosition: this.intercomStyles.INTERCOM_POSITION,
    };
  },

  computed: {
    isPriorityClient() {
      return !!this.profileStore?.account?.hasPrioritySupport;
    },
  },
};
</script>

<style lang="scss" scoped>
.intercom-ticket-mode {
  position: fixed;
  bottom: v-bind(intercomPosition);
  right: v-bind(intercomPosition);

  &__logo {
    width: v-bind(intercomDimensions);
    height: v-bind(intercomDimensions);
  }
}
</style>
