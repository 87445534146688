<template>
  <div class="d-flex flex-wrap justify-content-center align-items-center">
    <HpIcon class="h-mr-8" icon="icon-neutral" :width="16" warning />
    <Trans>v2.domain.status</Trans>
    :
    <Trans tag="span" class="ml-1">
      v2.waiting.for.activation.the.activation.process.should.take.up.to.48.hours
    </Trans>
  </div>
</template>

<script>
//JSON-slugs-migrated
export default {
  name: 'DomainInactiveBanner',
};
</script>
