import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useWebsiteCardActionsStore = defineStore(
  'websiteCardActionsStore',
  () => {
    const isRenewed = ref(false);

    return {
      isRenewed,
    };
  },
);
