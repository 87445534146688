import type { NavigationGuard } from 'vue-router';

import { googleVouchersRepo } from '@/repositories';
import { Route } from '@/types';

const googleAdsGuard: NavigationGuard = async (to, from, next) => {
  const [{ data }, err] = await googleVouchersRepo.getVoucherEligible();
  if (!data || err) return next({ name: Route.Base.HOME });

  next();
};

export default googleAdsGuard;
