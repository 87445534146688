import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import {
  useDomain,
  useGlobals,
  useWordpressCompatibility,
} from '@/composables';
import { useWordpressStore } from '@/stores';
import { Header } from '@/types';

export const useWordpress = ({
  domain,
  directory,
}: {
  domain?: string;
  directory?: string;
} = {}) => {
  const { toastr, t } = useGlobals();
  const route = useRoute();
  const store = useStore();

  const { domain: currentDomain, domainToManage } = useDomain();
  const { fetchWordpressCompatibility, suggestedWordpressVersion } =
    useWordpressCompatibility();
  const wordpressStore = useWordpressStore();

  const isLoadingWpVersionChange = ref(false);

  const selectedDomain = computed(
    () => domain || domainToManage.value || currentDomain.value || '',
  );

  const selectedDirectory = computed(
    () => directory || (route.params.directory as string) || '',
  );

  const hasWordPressVulnerabilities = computed(
    () => !!store.getters.getHostingWordpressVulnerabilities?.length,
  );

  const handleWordPressWebsiteValidation = async ({
    domain,
    directory,
    domainToManage,
    isForcedCheck,
  }: {
    domain: string;
    directory: string;
    domainToManage?: string;
    isForcedCheck?: boolean;
  }) => {
    if (wordpressStore.isWordPressVersionValid !== null && !isForcedCheck) {
      return;
    } // Already validated

    const domainName = domainToManage || domain;

    const currentAccount = store.getters.getCurrentAccountWithDomain(domain);

    const wordPressValidationHeaders = {
      [Header.DOMAIN]: domainName,
      [Header.USERNAME]: currentAccount?.username,
      [Header.ORDER_ID]: currentAccount?.referenceId,
    };

    if (!currentAccount && !isForcedCheck) {
      wordpressStore.isWordPressVersionValid = true;

      return;
    }

    await wordpressStore.fetchIsWordPressVersionValid({
      domain: domainName,
      isForcedCheck,
      ...(domainToManage &&
        currentAccount && { headers: wordPressValidationHeaders }),
      directory,
    });
  };

  const updateWordpressVersion = async (
    version?: string,
    domain?: string,
    directory?: string,
  ) => {
    isLoadingWpVersionChange.value = true;
    const [, error] = await wordpressStore.updateWordPressVersion({
      version: version || suggestedWordpressVersion.value,
      domain: domain || selectedDomain.value,
      directory: directory || selectedDirectory.value,
    });

    if (error) {
      isLoadingWpVersionChange.value = false;

      return;
    }

    await fetchWordpressCompatibility();

    toastr.s(t('WordPress version updated successfully'));
    isLoadingWpVersionChange.value = false;
  };

  return {
    handleWordPressWebsiteValidation,
    updateWordpressVersion,
    isLoadingWpVersionChange,
    hasWordPressVulnerabilities,
  };
};
