import type { NavigationGuard } from 'vue-router';

import store from '@/store';
import type { BillingSubscription } from '@/types';
import { Route, SubscriptionStatus } from '@/types';

export const titanDomainSelectGuard: NavigationGuard = async (
  to,
  from,
  next,
) => {
  if (!store.state.emails.loaded) {
    await store.dispatch('emails/fetchEmails');
  }

  const isActiveEmail = () => {
    const subscription = store.getters[
      'subscriptions/getSubscriptionWithResourcesById'
    ](to.params.orderId) as BillingSubscription;

    const state = subscription?.resource?.state;

    return state !== SubscriptionStatus.PENDING_SETUP;
  };

  return isActiveEmail() ? next({ name: Route.Email.EMAILS }) : next();
};
