<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';

import { useGlobals, useModal, useSubscriptions } from '@/composables';
import { useVpsServerStore } from '@/stores';
import { AmplitudeLocation } from '@/types';

interface IProps {
  data: {
    orderId: string;
    expirationDate: string;
    lastBackupDate: string;
  };
}
const props = defineProps<IProps>();

const { closeModal } = useModal();
const { t, toastr } = useGlobals();
const { openRestoreSubscriptionModalByOrderId } = useSubscriptions();

const serverStore = useVpsServerStore();

const daysUntilVpsRemoved = computed(() => {
  const lastRestoreDate = dayjs(props.data.expirationDate).add(14, 'day');
  const now = dayjs();
  const diff = lastRestoreDate.diff(now, 'day');
  const postfix = diff === 1 ? t('day') : t('days');

  return `${diff} ${postfix}`;
});

const restoreVps = () => {
  const server = serverStore.getServerByOrderId(props.data.orderId);
  if (!server) return;

  openRestoreSubscriptionModalByOrderId(
    props.data.orderId,
    AmplitudeLocation.Status.VPS_CANCELED,
    {
      title: t('Restore {service}', {
        service: server.hostname,
      }),
      subtitle: t('Review your selected invoice and proceed to checkout'),
      labelColor: 'primaryLight',
      onSuccess: () => {
        serverStore.fetchServers();

        toastr.s(t('VPS Restoration Initiated'), {
          html: t(
            'We are currently in the process of restoring <strong>{hostname}</strong>. This operation may take approximately 1 hour to complete. You’ll receive an email notification once the process is completed',
            {
              hostname: server.hostname,
            },
          ),
        });
      },
    },
  );
};
</script>

<template>
  <div class="restore-canceled-plan-modal">
    <h2 v-trans class="h-mb-8">Restore Canceled Plan</h2>
    <Trans
      tag="p"
      class="h-mb-24"
      :translate-params="{
        lastBackupDate: data.lastBackupDate,
      }"
    >
      Your VPS will be completely restored using the backup file from
      {lastBackupDate}. If the IP address assigned to your VPS has not been
      reassigned to another user, it will remain unchanged.
    </Trans>
    <HSnackbar v-if="data.expirationDate" variant="warning" class="h-mb-24">
      <Trans :translate-params="{ days: daysUntilVpsRemoved }">
        You have
        <strong>{days}</strong>
        to restore your expired plan. Later it will be removed permanently.
      </Trans>
    </HSnackbar>
    <div class="text-right h-mt-24">
      <HButton
        v-qa-generate
        variant="text"
        color="primary"
        class="h-mr-8"
        data-qa="vps-restore-modal-cancel"
        @click="closeModal"
      >
        {{ t('Cancel') }}
      </HButton>
      <HButton
        v-qa-generate
        data-qa="vps-restore-modal-next"
        @click="restoreVps"
      >
        {{ t('Restore') }}
      </HButton>
    </div>
  </div>
</template>
