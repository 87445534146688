import dayjs from 'dayjs';

import {
  usePlanBoost,
  useHostingCardActions,
  useHostingOrders,
  useHostingResources,
  useSubscriptions,
} from '@/composables';
import type { HostingCardOrder } from '@/types';
import { Hosting, BillingOrder } from '@/types';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const REACTIVATION_PERIOD =
  BillingOrder.Timing.REACTIVATION_PERIOD_IN_DAYS * DAY_IN_MILLISECONDS;

export const useHostingStatusNotification = () => {
  const { getHostingCardData } = useHostingOrders();
  const { getIsViewResourcesVisible, getIsPlanMaintenance } =
    useHostingCardActions();
  const { getIsCurrentlyBoosted } = usePlanBoost();
  const { canRenewOrReactivateSubscription } = useSubscriptions();

  const getIsExpiresSoon = (order: HostingCardOrder) => {
    const timeLeft = new Date(order.expiresAt).getTime() - Date.now();

    return (
      order.status === Hosting.OrderStatus.ACTIVE &&
      timeLeft <= REACTIVATION_PERIOD &&
      timeLeft > 0
    );
  };

  const isMaintenanceStatusActive = (order: HostingCardOrder) =>
    getIsPlanMaintenance({
      maintenanceStatus: Hosting.MaintenanceStatus.ACTIVE,
      order,
    });

  const isMaintenanceStatusUpcoming = (order: HostingCardOrder) =>
    getIsPlanMaintenance({
      maintenanceStatus: Hosting.MaintenanceStatus.UPCOMING,
      order,
    });

  const getIsSomeResourceLimitReached = (order: HostingCardOrder) => {
    const { isSomeResourceLimitReached } = useHostingResources(order);

    return isSomeResourceLimitReached.value;
  };

  const isExpired = (order: HostingCardOrder) =>
    order.status === Hosting.OrderStatus.EXPIRED;

  const isSuspended = (order: HostingCardOrder) =>
    order.status === Hosting.OrderStatus.SUSPENDED;

  const isBeforeRenewPeriod = (order: HostingCardOrder) => {
    const expiresAt = dayjs(order.expiresAt);
    const renewUntil = dayjs(expiresAt).add(
      BillingOrder.Timing.REACTIVATION_PERIOD_IN_DAYS,
      'day',
    );

    return dayjs().isBefore(renewUntil);
  };

  const paymentSuspensionRenewable = (order: HostingCardOrder) => {
    const notPaid =
      order.reason === Hosting.SuspendReason.NON_PAYMENT ||
      order.reason === Hosting.CBSuspendReason.NOT_PAID;

    return (
      (notPaid ||
        canRenewOrReactivateSubscription(order.id) ||
        getIsExpiresSoon(order)) &&
      isSuspended(order)
    );
  };

  const getIsTransferring = (order: HostingCardOrder) =>
    getHostingCardData(order)?.websites[0]?.inTransfer;

  const getOrderNotifications = (order: HostingCardOrder) => {
    type NotificationsMap = {
      [Hosting.NotificationOrderStatus.REMOVING]: boolean;
      [Hosting.NotificationOrderStatus.SUSPENDED]: boolean;
      [Hosting.NotificationOrderStatus.EXPIRED]: boolean;
      [Hosting.NotificationOrderStatus.SOME_RESOURCE_LIMIT_REACHED]: boolean;
      [Hosting.NotificationOrderStatus.PERFORMANCE_DROP]: boolean;
      [Hosting.NotificationOrderStatus.BOOSTED]: boolean;
      [Hosting.NotificationOrderStatus.TRANSFERRING]: boolean;
      [Hosting.NotificationOrderStatus.MAINTENANCE_UPCOMING]: boolean;
      [Hosting.NotificationOrderStatus.MAINTENANCE_ACTIVE]: boolean;
      [Hosting.NotificationOrderStatus.ACTIVE_WILL_EXPIRE_SOON]: boolean;
      [Hosting.NotificationOrderStatus.EXPIRED_RENEWABLE]: boolean;
      [Hosting.NotificationOrderStatus.EXPIRED_RESTORABLE]: boolean;
    };

    const NOTIFICATIONS_MAP: NotificationsMap = {
      [Hosting.NotificationOrderStatus.REMOVING]:
        order.status === Hosting.OrderStatus.REMOVING,
      [Hosting.NotificationOrderStatus.SUSPENDED]:
        order.status === Hosting.OrderStatus.SUSPENDED &&
        !paymentSuspensionRenewable(order),
      [Hosting.NotificationOrderStatus.ACTIVE_WILL_EXPIRE_SOON]:
        order.status === Hosting.OrderStatus.ACTIVE && getIsExpiresSoon(order),
      [Hosting.NotificationOrderStatus.EXPIRED]:
        order.status === Hosting.OrderStatus.EXPIRED,
      [Hosting.NotificationOrderStatus.SOME_RESOURCE_LIMIT_REACHED]:
        getIsSomeResourceLimitReached(order),
      [Hosting.NotificationOrderStatus.PERFORMANCE_DROP]:
        getIsViewResourcesVisible(order),
      [Hosting.NotificationOrderStatus.BOOSTED]: getIsCurrentlyBoosted(order),
      [Hosting.NotificationOrderStatus.TRANSFERRING]: getIsTransferring(order),
      [Hosting.NotificationOrderStatus.MAINTENANCE_UPCOMING]:
        isMaintenanceStatusUpcoming(order),
      [Hosting.NotificationOrderStatus.MAINTENANCE_ACTIVE]:
        isMaintenanceStatusActive(order),
      [Hosting.NotificationOrderStatus.EXPIRED_RENEWABLE]:
        paymentSuspensionRenewable(order) || isExpired(order),
      [Hosting.NotificationOrderStatus.EXPIRED_RESTORABLE]:
        isExpired(order) &&
        !paymentSuspensionRenewable(order) &&
        isBeforeRenewPeriod(order),
    };

    return Object.keys(NOTIFICATIONS_MAP).filter(
      (key) => NOTIFICATIONS_MAP[key as keyof NotificationsMap],
    );
  };

  return {
    getOrderNotifications,
    getIsExpiresSoon,
    isMaintenanceStatusActive,
    getIsSomeResourceLimitReached,
  };
};
