import { computed } from 'vue';

import { useCatalogStore } from '@/stores';
import { HBilling } from '@/types';

export const useVpsPlan = () => {
  const catalog = useCatalogStore();

  const vpsPlans = computed(
    () => catalog.getCatalogByCategory(HBilling.CatalogCategory.VPS) || [],
  );

  const getVpsPlanById = (id: string) =>
    vpsPlans.value.find((item) => item.id.includes(id));

  return {
    vpsPlans,
    getVpsPlanById,
  };
};
