export interface IFrontendSettingsState {
  sideMenuExists: boolean | 'hidden';
  sideMenuHiddenLinks: string[];
  hideScroll: boolean;
  asideComponent: boolean;
  showSshLogin: string | boolean;
  showBrowserTerminal: boolean;
  sideMenuComponent: any;
  sideMenuBack: any;
  feedback: boolean;
  feedbackOpen: boolean;
  sideMenuFixed: boolean;
  isSideMenuFullHeight: boolean;
  showSubheader: boolean;
  breadcrumbs: boolean | { title: string; url: string }[];
  hideTitle: boolean;
  backArrowPath: any;
  fullHeight: boolean;
  fullscreen: boolean;
  mobileFullscreen: boolean;
  header:
    | boolean
    | 'onboarding'
    | 'template-preview'
    | 'cart-header'
    | 'simple-header'
    | 'h-header';
  mAsideLeftShow: boolean;
  asideHeaderMenuMobile: boolean;
  headerBackButton: boolean;
  pageLoading: boolean;
  hideBackButton: boolean;
  backButtonLabel: any;
}

export const INITIAL_STATE: IFrontendSettingsState = {
  sideMenuExists: false,
  sideMenuHiddenLinks: [],
  hideScroll: false,
  asideComponent: false,
  showSshLogin: '',
  showBrowserTerminal: false,
  sideMenuComponent: null,
  sideMenuBack: null,
  feedback: false,
  feedbackOpen: false,
  sideMenuFixed: false,
  isSideMenuFullHeight: false,
  showSubheader: true,
  breadcrumbs: true,
  hideTitle: false,
  backArrowPath: null,
  fullHeight: false,
  fullscreen: false,
  mobileFullscreen: false,
  header: true,
  mAsideLeftShow: false,
  asideHeaderMenuMobile: false,
  headerBackButton: true,
  pageLoading: true,
  hideBackButton: false,
  backButtonLabel: null,
};

export type TFrontendSettingsStateKey = keyof typeof INITIAL_STATE;
