import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'databases',
    name: Route.HostingDatabases.DATABASES,
    meta: {
      title: 'Databases',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#databases' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'my-sql-databases',
        name: Route.HostingDatabases.MYSQL_DATABASES,
        meta: {
          title: 'Management',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDatabases.DATABASES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Databases/MySqlDatabases.vue'),
      },
      {
        path: 'php-my-admin',
        name: Route.HostingDatabases.PHP_MY_ADMIN,
        meta: {
          title: 'PHP My Admin',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDatabases.DATABASES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Databases/PhpMyAdmin.vue'),
      },
      {
        path: 'remote-my-sql',
        name: Route.HostingDatabases.REMOTE_MYSQL,
        meta: {
          title: 'Remote MySQL',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingDatabases.DATABASES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Databases/RemoteMySql.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
