import cookies from 'js-cookie';
import { computed } from 'vue';

import { useProfileStore } from '@/stores';

// TODO: Remove this and replace usage from profile store
export const useHostingerMail = () => {
  const profileStore = useProfileStore();

  const bundledHostingerEmail = computed(() => profileStore.isHostingerEmail);

  const isHostingerMailAvailable = computed(
    () => profileStore.isHostingerEmail || !!cookies.get('hostinger-mail'),
  );

  return {
    bundledHostingerEmail,
    isHostingerMailAvailable,
  };
};
