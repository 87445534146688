<template>
  <div>
    <HForm @on-submit="onSubmit($event)">
      <HFormField
        name="selectedHosting"
        :schema="hostingSelectSchema"
        class="h-mt-8 h-mb-16"
      />
      <div class="text-right">
        <HButton v-qa-generate class="h-mr-16" variant="text" @click="close">
          {{ $t('Cancel') }}
        </HButton>
        <HButton
          v-qa-generate
          h-form-submit
          :is-loading="isLoadingConfirmAction"
        >
          {{ $t('Select plan') }}
        </HButton>
      </div>
    </HForm>
  </div>
</template>

<script>
import { Route, AmplitudeEvent } from '@/types';
import { toUnicode } from '@/utils/helpers';
import modalsMixin from '@/utils/mixins/modalsMixin';
import purchaseProductMixin from '@/utils/mixins/purchaseProductMixin';

export default {
  mixins: [modalsMixin, purchaseProductMixin],
  computed: {
    isBuyOptionAvailable() {
      return this.data.hasPurchaseOption !== false;
    },
    hostingSelectSchema() {
      return {
        type: 'select',
        label: this.$t('Select Hosting Plan'),
        values: this.createSelectionList(),
        validation: {
          required: true,
        },
      };
    },
  },
  data() {
    return {
      isLoadingConfirmAction: false,
    };
  },
  methods: {
    createSelectionList() {
      const buyNewHosting = {
        label: this.$t('Get new hosting plan'),
        value: Route.Base.BUY_HOSTING,
      };

      const orders = this.data.hostingOrders.map((hostingOrder) => {
        if (this.data.format?.selectionListItem) {
          return this.data.format.selectionListItem(hostingOrder);
        }

        const { title, domain, username, orderId } = hostingOrder;

        return {
          label: `${title} - ${toUnicode(domain) || this.$t('pending setup')}`,
          value: String(username || orderId),
        };
      });

      const selectionList = orders;

      if (this.isBuyOptionAvailable) {
        selectionList.unshift(buyNewHosting);
      }

      return selectionList;
    },
    async onSubmit({ values }) {
      if (this.data.quickInstallSelection) {
        this.$amplitudeV2(
          AmplitudeEvent.Websites.WEBSITES_ADD_WEBSITE_PLAN_CHOSEN,
          {
            option_chosen: this.data.quickInstallSelection,
          },
        );
      }

      if (values.selectedHosting === Route.Base.BUY_HOSTING) {
        this.$router.push({
          name: Route.Base.BUY_HOSTING,
          params: this.data.isWebsiteOwnershipTransfer
            ? { flow: 'ownership-transfer' }
            : {},
        });
        this.$amplitudeV2(
          AmplitudeEvent.Websites.WEBSITES_REDIRECT_BUY_HOSTING,
        );

        return;
      }

      const currentOrder = this.data.hostingOrders.find((hostingOrder) => {
        const { username, orderId } = hostingOrder;

        const identifier = this.data.format?.identifier
          ? this.data.format?.identifier(hostingOrder)
          : String(username || orderId);

        return identifier === values.selectedHosting;
      });

      if (this.data.shouldLoadOnConfirmAction) {
        this.isLoadingConfirmAction = true;
      }

      await this.data.onSuccess(currentOrder);
      this.close();

      this.isLoadingConfirmAction = true;
    },
  },
};
</script>
