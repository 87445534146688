import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type { IServerSnapshot, ServerSnapshots } from '@/types';
import { HVps } from '@/types';

export const useVpsSnapshotStore = defineStore('vpsSnapshotStore', () => {
  const serverStore = useVpsServerStore();

  const snapshots = ref<ServerSnapshots>({});

  const setServerSnapshots = (serverId: number, details: IServerSnapshot[]) => {
    snapshots.value[serverId] = details;
  };

  const getServerSnapshots = (serverId?: number) => {
    const managedId = serverStore.managedId;
    const index = managedId ? managedId : serverId;
    if (!index) return [];

    return snapshots.value[index];
  };

  const fetchServerSnapshots = async (serverId: number) => {
    const [{ data }, error] = await hVpsRepo.getSnapshots(serverId);

    if (!error) {
      setServerSnapshots(serverId, data);
    }
  };

  const createSnapshot = async () => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    serverStore.setServerLock(serverId, true);

    const [_, error] = await hVpsRepo.createSnapshot(serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => fetchServerSnapshots(serverId),
    });
  };

  const restoreSnapshot = async () => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    serverStore.setServerLock(serverId, true);

    const [_, error] = await hVpsRepo.restoreSnapshot(serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    serverStore.setServerState(serverId, HVps.ServerState.RESTORING);
    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => fetchServerSnapshots(serverId),
    });
  };

  const deleteSnapshot = async () => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    serverStore.setServerLock(serverId, true);

    const [_, error] = await hVpsRepo.deleteSnapshot(serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => fetchServerSnapshots(serverId),
    });
  };

  return {
    snapshots,
    setServerSnapshots,
    getServerSnapshots,
    fetchServerSnapshots,
    createSnapshot,
    restoreSnapshot,
    deleteSnapshot,
  };
});
