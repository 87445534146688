import type { HIconUnion } from '@hostinger/hcomponents';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { usePaymentMethodStore } from '@/stores/paymentMethodStore';
import { Payment } from '@/types/enums/PaymentEnum';
import type { PaymentMethod } from '@/types/models/billing/paymentMethodModels';

export const usePaymentMethod = () => {
  const store = useStore();
  const paymentMethodStore = usePaymentMethodStore();

  const paymentMethodsLoaded = computed(
    () => store.state.paymentMethods.loaded,
  );
  const paymentMethodsLoading = computed(
    () => store.state.paymentMethods.loading,
  );
  const defaultPaymentMethod = computed(
    () => store.getters['paymentMethods/getDefaultPaymentMethod'],
  );
  const allPaymentMethods = computed<PaymentMethod[]>(
    () => store.getters['paymentMethods/getAllPaymentMethods'],
  );
  const getPaymentMethodById = (id: number | string): PaymentMethod | null => {
    if (!id || id === Payment.NEW_PAYMENT_METHOD) {
      return null;
    }

    return store.getters['paymentMethods/getPaymentMethodById'](Number(id));
  };

  const isPaymentMethodRazorpayCard = (paymentMethod: PaymentMethod) =>
    paymentMethod.merchantAccount === Payment.MerchantAccount.RAZORPAY &&
    paymentMethod.paymentMethod === Payment.Method.CARD;

  const maskedIdentifier = (paymentMethod: PaymentMethod) =>
    paymentMethod.identifier?.replace(/\*/g, Payment.DOT) ??
    paymentMethod.identifier;

  const identifier = (paymentMethod: PaymentMethod) => {
    if (isPaymentMethodRazorpayCard(paymentMethod)) {
      return paymentMethodStore.razorPayDetails(paymentMethod).revealed
        ? paymentMethodStore.razorPayDetails(paymentMethod).digits
        : Payment.DOTS;
    }
    if (isCard(paymentMethod)) {
      return maskedIdentifier(paymentMethod).slice(-4);
    }

    return maskedIdentifier(paymentMethod);
  };

  const isCard = (paymentMethod: PaymentMethod) =>
    [
      Payment.Method.CARD,
      Payment.Method.APPLEPAY,
      Payment.Method.GOOGLEPAY,
    ].includes(paymentMethod.paymentMethod as Payment.Method);

  const paymentMethodIcon = (paymentMethod: PaymentMethod): HIconUnion =>
    paymentMethodStore.getPaymentIconName(paymentMethod) as HIconUnion;

  onMounted(() => {
    if (paymentMethodsLoaded.value || paymentMethodsLoading.value) return;
    store.dispatch('paymentMethods/billingGetPaymentMethods');
  });

  return {
    paymentMethodIcon,
    isCard,
    identifier,
    maskedIdentifier,
    isPaymentMethodRazorpayCard,
    allPaymentMethods,
    getPaymentMethodById,
    paymentMethodsLoading,
    defaultPaymentMethod,
  };
};
