import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useH5GWebsite } from '@/composables';
import { h5gOrdersRepo, h5gWebsitesRepo } from '@/repositories';
import {
  STORE_PERSISTENT_KEYS,
  type IH5GOrdersList,
  type IH5GWebsitesList,
} from '@/types';

export const useH5GWebsitesStore = defineStore(
  'h5gWebsitesStore',
  () => {
    const INIT_LIST_STATE = {
      currentPage: 1,
      data: [],
      lastPage: 1,
      perPage: 5,
      total: 0,
    };

    const { h5gWebsiteUid } = useH5GWebsite();

    const websitesList = ref<IH5GWebsitesList>(INIT_LIST_STATE);
    const ordersList = ref<IH5GOrdersList>(INIT_LIST_STATE);
    const isOrdersListLoading = ref(false);
    const isWebsitesListLoading = ref(false);

    const fetchH5GOrders = async () => {
      isOrdersListLoading.value = true;
      const [{ data }, error] = await h5gOrdersRepo.getOrders();

      isOrdersListLoading.value = false;

      if (!data || error) {
        return;
      }

      ordersList.value = data;
    };

    const fetchH5GWebsitesList = async (
      orderId: string,
      pagination: {
        page: number;
        perPage: number;
      },
    ) => {
      isWebsitesListLoading.value = true;
      const [{ data }, error] = await h5gOrdersRepo.getWebsites(
        orderId,
        pagination,
      );

      isWebsitesListLoading.value = false;

      if (!data || error) {
        return;
      }

      websitesList.value = data;
    };

    const fetchH5GWebsiteDetails = async (websiteUid?: string) => {
      const uid = websiteUid || h5gWebsiteUid.value;
      const [{ data }, error] = await h5gWebsitesRepo.getWebsiteDetails(uid);

      if (!data || error) {
        return { data, error };
      }

      const websiteIndex = websitesList.value.data.findIndex(
        (website) => website.uid === uid,
      );

      if (websiteIndex !== -1) {
        websitesList.value.data[websiteIndex] = data;
      } else {
        websitesList.value.data.push(data);
      }
    };

    const fetchH5GWebsiteProcesses = async (websiteUid: string) => {
      const [{ data }, error] = await h5gWebsitesRepo.getWebsiteProcesses(
        websiteUid,
      );

      if (!data || error) {
        return;
      }

      const websiteIndex = websitesList.value.data.findIndex(
        (website) => website.uid === websiteUid,
      );

      if (websiteIndex !== -1) {
        websitesList.value.data[websiteIndex].processes = data;
      }
    };

    const deleteWebsite = async (websiteUid: string) =>
      await h5gWebsitesRepo.deleteWebsite(websiteUid);

    const $reset = () => {
      websitesList.value = INIT_LIST_STATE;
      ordersList.value = INIT_LIST_STATE;
      isOrdersListLoading.value = false;
    };

    return {
      $reset,
      fetchH5GOrders,
      fetchH5GWebsitesList,
      fetchH5GWebsiteDetails,
      fetchH5GWebsiteProcesses,
      deleteWebsite,
      websitesList,
      ordersList,
      isOrdersListLoading,
      isWebsitesListLoading,
    };
  },
  {
    persist: {
      key: STORE_PERSISTENT_KEYS.H5G_WEBSITES_STORE,
      storage: sessionStorage,
    },
  },
);
