import { hInvoicingRepo } from '@/repositories';

export default {
  namespaced: true,
  state: {
    loading: false,
    invoices: [],
  },
  getters: {
    getInvoiceById: (state) => (id) =>
      state.invoices.find((invoice) => invoice.id === id),
    getFirstInvoiceBySubscriptionId: (state) => (subscriptionId) =>
      state.invoices.find(
        (invoice) => invoice.subscriptionId === subscriptionId,
      ),
  },
  mutations: {
    SET_INVOICES(state, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(
          (invoice) => (invoice.items = formatItems(invoice.items)),
        );
      }

      state.invoices = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async billingGetInvoices({ commit }) {
      commit('SET_LOADING', true);

      const [{ data }, err] = await hInvoicingRepo.getInvoices();

      if (err) return commit('SET_LOADING', false);

      return new Promise((resolve) => {
        commit('SET_INVOICES', data);
        commit('SET_LOADING', false);
        resolve(true);
      });
    },
  },
};

const formatItems = (items) => {
  items.forEach((item) => {
    if (item?.externalId?.includes('hostingermail')) {
      item.description = `${item.description} Email`;
    }
  });

  return items;
};
