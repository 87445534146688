//@ts-nocheck
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { RequestConfig } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

dayjs.extend(utc);

interface CachedRequest {
  key: string;
  urlKey: string;
  data: any;
  timestamp: number;
  cacheExpire: number;
}

const DB_NAME = 'hPanelNew';
const DB_VERSION = 1;
const DEFAULT_CACHETIME = 60;

if (!window.indexedDB) {
  // @ts-ignore
  window.indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB;
  // @ts-ignore
  window.IDBTransaction = window.IDBTransaction ||
    // @ts-ignore
    window.webkitIDBTransaction ||
    // @ts-ignore
    window.msIDBTransaction || { READ_WRITE: 'readwrite' };
  // @ts-ignore
  window.IDBKeyRange =
    window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
}

const httpCacheIndexedDB = {
  getDatabase: async () =>
    new Promise((resolve, reject) => {
      try {
        const request = window.indexedDB?.open(DB_NAME, DB_VERSION);

        if (!request) reject(new Error('No indexedDB'));

        request.onerror = (event: Event) => {
          reject(
            new Error('getDatabase error', {
              cause: event.target.error,
            }),
          );
        };

        request.onsuccess = (e) => {
          // @ts-ignore
          resolve(e.target.result);
        };

        request.onupgradeneeded = (e) => {
          // @ts-ignore
          const db = e.target.result;
          const objectStore = db.createObjectStore('requests', {
            keyPath: 'key',
          });
          objectStore.createIndex('key', 'key', { unique: true });
        };
      } catch (e) {
        reject(e);
      }
    }),

  getCachedRequest: async (key: string): Promise<CachedRequest> => {
    const db = await httpCacheIndexedDB.getDatabase();

    return new Promise((resolve, reject) => {
      if (!db) reject(new Error('No indexedDB'));

      const store = requestsStore(db, resolve, reject);

      if (!store) {
        reject(new Error('No requests store'));

        return;
      }

      const getRequest = store.get(key);

      getRequest.onsuccess = (e: Event) => {
        // @ts-ignore
        resolve(e.target.result);
      };
    });
  },

  addCachedRequest: async (request: CachedRequest) => {
    const db = await httpCacheIndexedDB.getDatabase();

    return new Promise((resolve, reject) => {
      if (!db) reject(new Error('No indexedDB'));

      const store = requestsStore(db, resolve, reject);

      if (!store) {
        reject(new Error('No requests store'));

        return;
      }

      const getRequest = store.get(request.key);

      getRequest.onsuccess = (e: Event) => {
        // @ts-ignore
        const result = e.target.result;
        if (result) {
          const deleteRequest = store.delete(request.key);

          deleteRequest.onsuccess = () => {
            store.add(request);
          };
        } else store.add(request);
      };
    });
  },

  removeCachedRequest: async (requestKey: CachedRequest['key']) => {
    const db = await httpCacheIndexedDB.getDatabase();

    return new Promise((resolve, reject) => {
      if (!db) reject(new Error('No indexedDB'));

      const store = requestsStore(db, resolve, reject);

      if (!store) {
        reject(new Error('No requests store'));

        return;
      }

      store.delete(requestKey);
    });
  },
};

const requestsStore = (db: any, resolve: any, reject: any) => {
  if (!db?.objectStoreNames.contains('requests')) return false;

  const trans = db?.transaction('requests', 'readwrite');
  trans.oncomplete = () => {
    resolve();
  };

  trans.onerror = (e: Event) => {
    reject(new Error('Transaction error', { cause: e.target.error }));
  };

  return trans.objectStore('requests');
};

const httpCache = {
  getHttpCall: async (url: string, config?: RequestConfig) => {
    const key = httpCache.generateKey(url, config);
    const oldResponse = await httpCacheIndexedDB.getCachedRequest(key);

    if (oldResponse && !httpCache.isExpired(oldResponse.cacheExpire)) {
      return oldResponse;
    }

    return false;
  },
  storeHttpCall: async (url: string, config: RequestConfig, response: any) => {
    try {
      const key = httpCache.generateKey(url, config);
      await httpCacheIndexedDB.addCachedRequest({
        key,
        urlKey: url,
        data: response,
        timestamp: dayjs().unix(),
        cacheExpire: dayjs()
          .add(config.cache ?? DEFAULT_CACHETIME, 'second')
          .unix(),
      } as CachedRequest);
    } catch (error) {
      error.name = 'Error storing http call response to IndexedDB';
      errorLogger.logError(error);
    }
  },
  forgetHttpCall: async (url: string, config: RequestConfig) => {
    try {
      const key = httpCache.generateKey(url, config);
      await httpCacheIndexedDB.removeCachedRequest(key);
    } catch (error) {
      error.name = 'Error removing http call response from IndexedDB';
      errorLogger.logError(error);
    }
  },
  generateKey: (url: string, config?: RequestConfig) => {
    const configCopy = { ...config };
    delete configCopy?.cacheOnly;
    delete configCopy?.cache;
    delete configCopy?.overrideCache;

    return `${btoa(unescape(encodeURIComponent(url)))}-${btoa(
      unescape(encodeURIComponent(JSON.stringify(configCopy))),
    )}`;
  },
  isExpired: (cacheExpire: number) =>
    dayjs().diff(dayjs.unix(cacheExpire)) >= 0,
  deleteDB: () => {
    try {
      window?.indexedDB?.deleteDatabase(DB_NAME);
    } catch (e) {
      // Well, things happen
    }
  },
};

export default httpCache;
