import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route } from '@/types';

const emailCreateAccountGuard: NavigationGuard = async (to, from, next) => {
  const orderId = to.params.orderId;

  if (!store.getters['emails/getLoaded']) {
    await store.dispatch('emails/fetchEmails');
  }

  const emailOrder = await store.getters[
    'emails/getEmailOrderByResellerOrderId'
  ](orderId);

  if (!emailOrder) return next({ name: Route.Base.NOT_FOUND });

  return next();
};

export default emailCreateAccountGuard;
