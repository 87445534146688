import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hostingEmailsRepo } from '@/repositories';
import type { IEmailDomainDetails } from '@/types';

interface DomainDetailsState {
  data?: IEmailDomainDetails;
  isLoading: boolean;
  isLoaded: boolean;
}

export const useHMailDomainDetailsStore = defineStore(
  'hMailDomainDetailsStore',
  () => {
    const domainsMap = ref(new Map<string, DomainDetailsState>());

    const getDomainDetailsState = (domainResourceId: string) =>
      domainsMap.value.get(domainResourceId);

    const getDomainDetails = (domainResourceId: string) =>
      getDomainDetailsState(domainResourceId)?.data;

    const isLoaded = (domainResourceId: string) =>
      getDomainDetailsState(domainResourceId)?.isLoaded ?? false;

    const isLoading = (domainResourceId: string) =>
      getDomainDetailsState(domainResourceId)?.isLoading ?? false;

    const fetchDomainDetailsById = async (domainResourceId: string) => {
      let currentDomainDetails = domainsMap.value.get(domainResourceId);

      if (!currentDomainDetails) {
        currentDomainDetails = domainsMap.value
          .set(domainResourceId, {
            isLoading: false,
            isLoaded: false,
          })
          .get(domainResourceId);
      }

      if (!currentDomainDetails) {
        throw new Error('Domain details not found after initialization');
      }

      currentDomainDetails.isLoading = true;

      const [{ data }] = await hostingEmailsRepo.getEmailDomainDetails(
        domainResourceId,
      );

      if (data) {
        currentDomainDetails.data = data;
        currentDomainDetails.isLoaded = true;
      }

      currentDomainDetails.isLoading = false;
    };

    return {
      getDomainDetails,
      isLoading,
      isLoaded,
      fetchDomainDetailsById,
    };
  },
);
