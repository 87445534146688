import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'accounts',
    name: Route.HostingAccount.ACCOUNTS,
    meta: {
      title: 'Account',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
    },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#accounts' },
    children: [
      {
        path: 'billing-cycle',
        name: Route.HostingAccount.BILLING_CYCLE,
        meta: {
          title: 'Billing Cycle',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Hosting.vue'),
      },
      {
        path: 'getting-started',
        name: Route.HostingAccount.GETTING_STARTED,
        meta: {
          title: 'Getting Started',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Hosting.vue'),
      },
      {
        path: 'renew',
        name: Route.HostingAccount.RENEW,
        meta: {
          title: 'Renew',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Hosting.vue'),
      },
      {
        path: 'transfer-account',
        name: Route.HostingAccount.TRANSFER_ACCOUNT,

        meta: {
          title: 'Transfer Account',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Hosting.vue'),
      },
      {
        path: 'reset-account',
        name: Route.HostingAccount.RELOAD_ACCOUNT,
        meta: {
          title: 'Reset Account',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAccount.ACCOUNTS,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Accounts/ReloadAccount.vue'),
      },
      {
        path: 'deactivate-account/:step?',
        name: Route.HostingAccount.DEACTIVATE_ACCOUNT,
        meta: {
          title: 'Deactivate Account',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAccount.ACCOUNTS,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Accounts/DeactivateAccount.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
