<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import AnimateHeight from '@/components/AnimateHeight.vue';
import HMenuItem from '@/components/HMenuV2/HMenuItem.vue';
import type { NavigationMenuItem } from '@/types';

const FIRST_LEVEL_GAP = 16;
const SECOND_LEVEL_GAP = 8;

type Props = {
  navigationItems: NavigationMenuItem[];
  currentRoute?: string;
  firstLevel?: boolean;
  isMultiExpand?: boolean;
};

type Emits = {
  (eventName: 'click', event: Event, payload: NavigationMenuItem): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const getActiveChild = () =>
  props.navigationItems.find(
    (route) =>
      !!route &&
      route?.children?.find((child) => props.currentRoute === child.to?.name),
  );

const getExpandedItemsInitialValue = () => {
  const activeChild = getActiveChild();

  return activeChild?.slug ? [activeChild.slug] : [];
};

const expandedItems = ref<string[]>(getExpandedItemsInitialValue());

const isExpanded = (slug: string) => expandedItems.value?.includes(slug);

const menuSpacing = computed<number>(() =>
  props.firstLevel ? FIRST_LEVEL_GAP : SECOND_LEVEL_GAP,
);

const toggleExpanded = (event: Event, slug: string) => {
  event.stopImmediatePropagation();

  if (expandedItems.value.includes(slug)) {
    expandedItems.value = props.isMultiExpand
      ? expandedItems.value.filter((item) => item !== slug)
      : [];
  } else {
    expandedItems.value = props.isMultiExpand
      ? [...expandedItems.value, slug]
      : [slug];
  }
};

watch(
  () => [props.isMultiExpand, props.navigationItems],
  ([isMultiExpand]) => {
    if (!isMultiExpand) {
      expandedItems.value = getExpandedItemsInitialValue();

      return;
    }

    expandedItems.value = props.navigationItems.map((item) => item.slug);
  },
);
</script>

<template>
  <nav>
    <ul class="hp-menu__list">
      <template
        v-for="(route, routeKey) in props.navigationItems"
        :key="`route-${routeKey}-${route.slug}`"
      >
        <HMenuItem
          v-if="!route.isHidden"
          :navigation-item="route"
          :currentRoute="currentRoute"
          :expanded="isExpanded(route.slug)"
          :gap="`${menuSpacing}px`"
          @toggle-expanded="toggleExpanded"
          @click="(event, route) => emit('click', event, route)"
        />
        <AnimateHeight
          :is-expanded="!!(route.children && isExpanded(route.slug))"
        >
          <li class="hp-menu__nested-routes-wrapper">
            <ul class="hp-menu__nested-routes">
              <template
                v-for="nestedRoute in route.children"
                :key="nestedRoute.title"
              >
                <HMenuItem
                  v-if="!route.isHidden"
                  is-child
                  :navigation-item="nestedRoute"
                  :currentRoute="currentRoute"
                  :gap="`${SECOND_LEVEL_GAP}px`"
                  @click="(event, route) => emit('click', event, route)"
                />
              </template>
            </ul>
          </li>
        </AnimateHeight>
      </template>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.hp-menu {
  &__list,
  &__nested-routes {
    list-style: none;
    padding-left: 0;
    width: 100%;
  }

  &__list {
    margin-top: 16px;
  }
}
</style>
