export namespace CpanelStoreEnum {
  export namespace Domain {
    export enum Getters {}
    export enum Mutations {
      SET_DOMAINS = 'SET_DOMAINS',
    }

    export enum Actions {
      FETCH_DOMAINS = 'FETCH_DOMAINS',
    }
  }
}
