import { difference } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { useResourcesStore } from './resourcesStore';
import { useWHOrdersStore } from './whOrdersStore';

import { useWidgets } from '@/composables';
import widgetsRepo from '@/repositories/widgetsRepo';
import type {
  IWidget,
  IWidgetConfiguration,
  IWidgetRequest,
  IWidgetWithoutIndex,
  WidgetType,
  IWidgetResponse,
} from '@/types';
import {
  MAX_WIDGETS_PER_ROW,
  MAX_WIDGET_COUNT,
  WIDGET_CONFIGURATION,
  BUILDER_WIDGETS,
  Hosting,
  HRESOURCES_STATE,
} from '@/types';
import {
  WH_ORDER_OWNERSHIP,
  WH_ORDER_WEBSITE_STATUS,
} from '@/types/models/whOrderModels';

const PLACEHOLDER_WIDGET = {
  type: 'placeholder' as const,
  component: 'placeholder' as IWidgetConfiguration['component'],
  title: 'placeholder' as IWidgetConfiguration['title'],
  previewFooterTitle:
    'placeholder' as IWidgetConfiguration['previewFooterTitle'],
  resourceId: 'placeholder',
  domainName: 'placeholder',
  indexPosition: -1,
  customerId: 'placeholder',
  createdAt: 'placeholder',
  updatedAt: 'placeholder',
};

export const useWidgetsStore = defineStore('widgetsStore', () => {
  const isEditMode = ref(false);
  const widgets = ref<IWidget[]>([]);
  const widgetsCopy = ref<IWidget[]>([]);
  const isLoading = ref(false);
  const isLoaded = ref(false);

  const whOrdersStore = useWHOrdersStore();
  const resourcesStore = useResourcesStore();
  const store = useStore();
  const { getIsWebsiteWidget } = useWidgets();

  const visiblePlaceholderWidgets = computed(() => {
    if (!isEditMode.value && widgetsWithoutPlaceholders.value.length <= 3) {
      return widgetsWithPlaceholders.value.slice(0, MAX_WIDGETS_PER_ROW);
    }

    if (widgets.value.length >= MAX_WIDGETS_PER_ROW) {
      return widgetsWithPlaceholders.value;
    }

    return widgetsWithPlaceholders.value.slice(0, MAX_WIDGETS_PER_ROW);
  });

  const placeholderWidgetsCount = computed(
    () =>
      widgetsWithPlaceholders.value.filter(
        (widget) => widget?.type === 'placeholder',
      ).length,
  );

  const widgetsWithoutPlaceholders = computed(() =>
    widgets.value.filter((widget) => widget.type !== 'placeholder'),
  );

  const widgetsWithPlaceholders = computed(() => {
    const widgetsCopy = [...widgets.value];

    for (let i = widgetsCopy.length; i < MAX_WIDGET_COUNT; i++) {
      const currentWidget = widgetsCopy[i];

      if (currentWidget?.type !== 'placeholder' || !currentWidget) {
        widgetsCopy[i] = PLACEHOLDER_WIDGET;

        continue;
      }
    }

    return widgetsCopy;
  });

  const isOnlyPlaceholderWidgets = computed(() =>
    widgetsWithPlaceholders.value.every(
      (widget) => widget.type === 'placeholder',
    ),
  );

  const availableWidgetToAddCount = computed(
    () => MAX_WIDGET_COUNT - widgetsWithoutPlaceholders.value.length,
  );

  const isWidgetTypeAlreadyPlaced = (widgetType: WidgetType) =>
    widgets.value.some((widget) => widget.type === widgetType);

  const formWidgetsFromResponse = (widgets: IWidgetResponse[]) =>
    widgets.flatMap((widget) => {
      const widgetConfiguration = WIDGET_CONFIGURATION[widget.type];

      return {
        ...widget,
        ...widgetConfiguration,
      };
    });

  const formWidgetsForRequest = (): IWidgetRequest[] =>
    widgetsWithoutPlaceholders.value.flatMap((widget) => {
      if (widget.type === 'placeholder') return [];

      return {
        type: widget.type,
        indexPosition: widget.indexPosition,
        ...(widget.resourceId && {
          resourceId: widget.resourceId,
        }),
        ...(widget.domainName && {
          domainName: widget.domainName,
        }),
      };
    });

  const enterEditMode = () => {
    if (!isEditMode.value) {
      widgetsCopy.value = [...widgets.value];
    }

    isEditMode.value = true;
  };

  const exitEditMode = () => {
    widgets.value = [...widgetsCopy.value];

    isEditMode.value = false;
  };

  const saveWidgets = () => {
    filterPlaceholderWidgets();

    isEditMode.value = false;

    widgetsRepo.postWidgets({
      data: { widgets: formWidgetsForRequest() },
    });
  };

  const getRemovedWidgets = () => {
    if (!isEditMode.value) return [];

    return difference(widgetsCopy.value, widgets.value);
  };

  const getAddedWidgets = () => {
    if (!isEditMode.value) return [];

    return difference(widgets.value, widgetsCopy.value).filter(
      ({ type }) => type !== 'placeholder',
    );
  };

  const getWidgetsChanges = () => {
    const removed = getRemovedWidgets();
    const added = getAddedWidgets();

    const removedNames = removed?.map(({ type }) => type);
    const addedNames = added?.map(({ type }) => type);

    if (
      !difference(removedNames, addedNames).length &&
      !difference(addedNames, removedNames).length
    ) {
      return { removedNames: [], addedNames: [] };
    }

    return { removedNames, addedNames };
  };

  const filterPlaceholderWidgets = () => {
    widgets.value = widgetsWithoutPlaceholders.value;
  };

  const addWidget = (widget: IWidgetWithoutIndex) => {
    const firstPlaceholderIndex = widgetsWithPlaceholders.value.findIndex(
      (widget) => widget.type === 'placeholder',
    );

    widgets.value[firstPlaceholderIndex] = {
      ...widget,
      indexPosition: firstPlaceholderIndex,
    };
  };

  const addWidgets = (widgets: IWidgetWithoutIndex[]) => {
    widgets.forEach((widget) => {
      addWidget(widget);
    });
  };

  const deleteWidgetByIndex = (index: number) => {
    widgets.value[index] = PLACEHOLDER_WIDGET;
  };

  const changeWidgetResource = (
    data: {
      resourceId: IWidget['resourceId'];
      domainName: IWidget['domainName'];
    },
    index: number,
  ) => {
    widgets.value[index].resourceId = data.resourceId;
    widgets.value[index].domainName = data.domainName;
  };

  const isWebsiteBuilder = (domain: string) => {
    const account = store.getters.getAccountByDomainWithoutCpanel(domain);

    return account?.type === Hosting.AccountType.BUILDER;
  };

  const setAvailableResourceWidgets = async (data: IWidgetResponse[]) => {
    await whOrdersStore.getWebsitesTotal(WH_ORDER_OWNERSHIP.OWNED);

    const activeWebsites = whOrdersStore.getWebsitesByOwnershipAndStatus(
      WH_ORDER_OWNERSHIP.OWNED,
      WH_ORDER_WEBSITE_STATUS.ENABLED,
    );

    const builderWebsites = activeWebsites.filter((website) =>
      isWebsiteBuilder(website.domain),
    );
    const builderWebsitesDomainNames = builderWebsites.map(
      ({ domain }) => domain,
    );
    const builderWidgetsToBeRemoved = data.filter(
      (widget) =>
        builderWebsitesDomainNames.includes(widget.domainName ?? '') &&
        !BUILDER_WIDGETS.includes(widget.type),
    );
    const builderWidgetsToBeRemovedIds = builderWidgetsToBeRemoved.map(
      ({ id }) => id,
    );

    const existingWidgets = data.filter((widget) => {
      if (!widget.domainName && !widget.resourceId) {
        return true;
      }

      if (getIsWebsiteWidget(widget.type)) {
        return activeWebsites.some(
          (website) => website.domain === widget.domainName,
        );
      }

      const resource = resourcesStore.getResourceById(
        Number(widget.resourceId),
      );

      return resource?.items.some(
        (item) => item.state !== HRESOURCES_STATE.DELETED,
      );
    });

    const validWidgets = existingWidgets.filter(
      (widget) => !builderWidgetsToBeRemovedIds.includes(widget.id),
    );

    if (validWidgets.length !== data.length) {
      const existingWidgetsWithIndex = validWidgets.map((item, index) => ({
        ...item,
        indexPosition: index,
      }));

      widgets.value = formWidgetsFromResponse(existingWidgetsWithIndex);

      saveWidgets();

      return;
    }

    widgets.value = formWidgetsFromResponse(data);
  };

  const fetchWidgets = async () => {
    isLoaded.value = false;
    isLoading.value = true;

    const [{ data }, error] = await widgetsRepo.getWidgets();

    isLoading.value = false;

    if (error) {
      return;
    }

    setAvailableResourceWidgets(data);

    isLoaded.value = true;
  };

  return {
    isEditMode,
    widgets,
    isOnlyPlaceholderWidgets,
    widgetsWithoutPlaceholders,
    placeholderWidgetsCount,
    visiblePlaceholderWidgets,
    availableWidgetToAddCount,
    isWidgetTypeAlreadyPlaced,
    saveWidgets,
    addWidget,
    addWidgets,
    changeWidgetResource,
    deleteWidgetByIndex,
    enterEditMode,
    exitEditMode,
    fetchWidgets,
    isLoaded,
    getWidgetsChanges,
  };
});
