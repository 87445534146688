<template>
  <div v-if="$route.params?.domainToManage || $route.params?.domain">
    <HostingAccountSelect class="h-mb-24 h-mt-8" />
    <Search v-model="searchForm" class="search-input" />
    <AsideMenu
      class="h-mt-24"
      :menu="filteredSideMenu"
      :search-in-progress="searchInProgress"
    />
  </div>
</template>

<script>
import { cloneDeep, escapeRegExp } from 'lodash';
import { mapGetters } from 'vuex';

import AsideMenu from './AsideMenu';

import RouteParser from '../../utils/services/navigation/RouteParser';
import HostingAccountSelect from '../Hosting/HostingAccountSelect';
import Search from '../Search';

import { useProfileStore } from '@/stores';
import { AmplitudeLocation } from '@/types';
import emailsReleaseMixin from '@/utils/mixins/emailsReleaseMixin';
import subscriptionMixin from '@/utils/mixins/hbilling/subscriptionMixin';
import functions from '@/utils/services/functions';

export default {
  name: 'SectionSideMenu',
  mixins: [emailsReleaseMixin, subscriptionMixin],
  components: {
    HostingAccountSelect,
    Search,
    AsideMenu,
  },
  data() {
    return {
      searchForm: '',
      menu: 'hosting-section',
      routes: [],
    };
  },
  setup() {
    const profileStore = useProfileStore();

    return {
      profileStore,
    };
  },
  computed: {
    searchInProgress() {
      return this.searchForm.length >= 3;
    },
    hostingSections() {
      const sections = this.isSubscriptionRenewable
        ? this.activeHostingSections()
        : this.activeHostingSectionsWithoutRenew();

      const sectionsWithQuery = sections.map((section) => ({
        ...section,
        children: section.children.map((child) => {
          if (child.slug === 'logo_maker') {
            return {
              ...child,
              link: this.addBrandToUrl(child.link),
              query: {
                redirectLocation: AmplitudeLocation.Base.SIDE_MENU,
              },
            };
          }

          return {
            ...child,
            query: {
              redirectLocation: AmplitudeLocation.Base.SIDE_MENU,
            },
          };
        }),
      }));

      return sectionsWithQuery;
    },
    filteredSideMenu() {
      const sections = this.hostingSections;

      return this.searchInProgress
        ? this.filterSectionByRelease(this.filterSideMenu(cloneDeep(sections)))
        : this.filterSectionByRelease(cloneDeep(sections));
    },
    subscription() {
      if (!this.getCurrentOrder) return null;

      return this.getSubscriptionById(this.getCurrentOrder.id);
    },
    ...mapGetters([
      'activeHostingSections',
      'activeHostingSectionsWithoutRenew',
      'getCurrentOrder',
    ]),
    ...mapGetters('subscriptions', ['getSubscriptionById']),
  },
  methods: {
    addBrandToUrl(url) {
      const urlObject = new URL(url);

      const brandUrl = `https://www.${this.profileStore.account.brand.domain}`;

      urlObject.searchParams.append('websiteUrl', brandUrl);

      return decodeURIComponent(urlObject.href);
    },
    getRouteByName(routeName) {
      return RouteParser.getRouteByName(routeName);
    },
    filterSideMenu(sections) {
      const pattern = new RegExp(escapeRegExp(this.searchForm), 'gi');

      return sections.filter((elem) =>
        functions.arrayFilterWithRegex(elem, 'title', pattern, 'children'),
      );
    },
  },
  mounted() {
    this.routes = RouteParser.get(this.menu);
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  min-width: unset;
}
</style>
