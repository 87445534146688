import { setHComponentsTFunction } from '@hostinger/hcomponents';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  useFeatureFlag,
  useHpanelVersionUpdate,
  useOwnershipTransferActions,
  useRedirects,
  useProfile,
} from '@/composables';
import { ONE_MINUTE_IN_SECONDS } from '@/data/globalConstants';
import { initAmplitude } from '@/plugins/amplitudeV2';
import store from '@/store';
import {
  useAccessStore,
  useCatalogStore,
  useFeedbackStore,
  useLanguageStore,
  useProfileStore,
  useReferralStore,
  useWebsitesStore,
} from '@/stores';
import { useWordpressStore } from '@/stores/wordpressStore';
import { FeatureFlag } from '@/types';
import { setBrandColor, timeout, isUsingVPN } from '@/utils/helpers';
import { getJwtToken, parseJwt } from '@/utils/helpers/authHelpers';
import { loadHotjar } from '@/utils/services/Hotjar';
import initPusher from '@/utils/services/PusherWorker/pusher';
import {
  appForcedLogout,
  authenticate,
  refreshTokenIfInitiallyStale,
} from '@/utils/services/authService';
import { errorLogger } from '@/utils/services/errorLogging';
import experimentService from '@/utils/services/experimentService';
import { initAnalytics } from '@/utils/services/google';
import {
  EXTENDED_TIMEOUT,
  OPTIMIZED_TIMEOUT,
  axiosInstance,
} from '@/utils/services/http/httpService';
import { $t } from '@/utils/services/i18nService';
import { handleCRMRedirects } from '@/utils/services/redirectService';
import { getWindowQueryParams } from '@/utils/services/windowService';
import { registerWebComponents } from '@/webComponents';

export const useAppStore = defineStore('appStore', () => {
  const websitesStore = useWebsitesStore();
  const profileStore = useProfileStore();
  const accessStore = useAccessStore();
  const catalogStore = useCatalogStore();
  const languageStore = useLanguageStore();
  const feedbackStore = useFeedbackStore();
  const referralStore = useReferralStore();
  const { handleOwnershipTransferRedirect } = useOwnershipTransferActions();
  const { performCookieRedirect } = useRedirects();
  const {
    openUpdateAddressModal,
    openUpdateNameAndAddressModal,
    openChangePasswordModal,
    isPasswordChangeRequired,
    isAddressUpdateRequired,
    isAddressOrNameUpdateRequired,
  } = useProfile();
  const wordpressStore = useWordpressStore();

  const isRedirectLoading = ref(false);
  const isAppLoaded = ref(false);

  const setIsRedirectLoading = (value: boolean) =>
    (isRedirectLoading.value = value);

  const initApp = async ({
    shouldCheckRequiredActions,
  }: {
    shouldCheckRequiredActions: boolean;
  }) => {
    authenticate();
    await refreshTokenIfInitiallyStale();

    const jwtToken = getJwtToken();

    if (!jwtToken) {
      await appForcedLogout();

      return;
    }

    const { sub: clientId, access, brandDomain } = parseJwt(jwtToken);
    const managerClientId = access?.managerClientId;

    await initAmplitude({
      clientId,
      brand: brandDomain,
      managerClientId,
    });

    await experimentService.initExperimentService({
      clientId,
      brand: brandDomain,
    });

    const { isFeatureEnabled: isBESlowdownHandlerFeatureEnabled } =
      useFeatureFlag(FeatureFlag.ID.BE_SLOWDOWN_HANDLER);

    axiosInstance.defaults.timeout = isBESlowdownHandlerFeatureEnabled.value
      ? OPTIMIZED_TIMEOUT
      : EXTENDED_TIMEOUT;

    const { initVersionUpdateCheck } = useHpanelVersionUpdate();
    initVersionUpdateCheck();

    await Promise.all([
      catalogStore.setPreloadedCatalogOrFetch(),
      profileStore.fetchProfile(),
    ]);

    store.dispatch('fetchHostingOrders', {
      cache: ONE_MINUTE_IN_SECONDS,
      overrideCache: true,
    });
    accessStore.fetchAccess();
    profileStore.fetchUserEmailProvider();

    referralStore.fetchAndUpdateRewardRate();
    referralStore.fetchActivePromotion();

    // used to determine if websites section should be displayed
    websitesStore.fetchOneWebsite();

    // used to determine if addons section should be displayed
    wordpressStore.fetchManagedAndOwnedWordpressWebsites({ cache: 0 });

    const brandId = profileStore.account?.brand.id;

    if (brandId) {
      await languageStore.setLanguageByBrandId(brandId);
      await languageStore.initMessages();
      setBrandColor(brandId);
      store.commit('mutateTheme', brandId);
      store.commit('SET_PUSHER', initPusher(clientId));
      registerWebComponents($t, brandId);
    }
    setHComponentsTFunction($t);
    initAnalytics();
    errorLogger.setClientForErrorLogger({
      id: clientId,
    });
    errorLogger.setTag('isWebPro', `${profileStore.isPro}`);
    errorLogger.setTag('isAccessManager', `${profileStore.isAccessManager}`);

    setCSATDisplayProbabilityForSession();

    (window.dataLayer || []).push({
      clientId: profileStore.account?.id,
    });

    if (shouldCheckRequiredActions) {
      await checkRequiredActions();
    }

    getVPNUsage();

    isAppLoaded.value = true;

    // timeout is necessary for updating catalog if it's already stored in indexedDb
    // and will be updated ten seconds after initial load so initial load will be faster.
    await timeout(10000);

    await catalogStore.fetchCatalog();
  };

  const getVPNUsage = async () => {
    const usingVPN = await isUsingVPN();

    profileStore.isUsingVPN = usingVPN;
  };

  const checkRequiredActions = async () => {
    const params = getWindowQueryParams();

    if (window.location.pathname !== '/auth' && params.transferToken) {
      handleOwnershipTransferRedirect(params as { transferToken: string });
    }

    if (window.location.pathname !== '/auth' && params.section) {
      await handleCRMRedirects(params);
    }

    const isRedirected = await performCookieRedirect();

    if (isRedirected) return;

    if (isPasswordChangeRequired.value) {
      openChangePasswordModal();
    } else if (isAddressUpdateRequired.value) {
      openUpdateAddressModal();
    } else if (isAddressOrNameUpdateRequired.value) {
      openUpdateNameAndAddressModal();
    }

    loadHotjar('', true);
  };

  const setCSATDisplayProbabilityForSession = () => {
    feedbackStore.sessionCSATDisplayProbability = Math.random();
  };

  return {
    isAppLoaded,
    isRedirectLoading,
    setIsRedirectLoading,
    initApp,
  };
});
