import { domainsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  pendingOwnership: {
    hash: null,
    domain: null,
  },
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_LOADED(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
    SET_PENDING_OWNERSHIP(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'pendingOwnership', data);
    },
    RESET_PENDING_OWNERSHIP(state, requestDomain) {
      setForDomain(
        state,
        requestDomain,
        'pendingOwnership',
        initial.pendingOwnership,
      );
    },
  },
  getters: {
    getHostingDomainsAddWebsiteLoaded: (state) =>
      getStateVariable(state, 'loaded'),
    getHostingDomainsAddWebsitePendingOwnership:
      (state) =>
      (domain = null) =>
        getStateVariable(state, 'pendingOwnership', domain),
  },
  actions: {
    async hostingDomainsAddWebsiteStore(context, params) {
      const [{ data }, err] = await domainsRepo.addAddon(params);
      context.dispatch('hostingOrdersDetailsIndex');
      context.dispatch('hostingOrderAccountsIndex', {});

      return [{ data }, err];
    },
    async hostingDomainsAddWebsiteDestroy(context, domain) {
      const [{ data }, err] = await domainsRepo.deleteAddon(domain);
      if (err) return [{ data }, err];
      context.dispatch('hostingOrdersDetailsIndex');

      return [{ data }, err];
    },
    async hostingDomainsAddWebsiteOwnershipHash(context, domain) {
      const [{ data }, err] = await domainsRepo.postOwnershipData(domain);

      if (err) return;

      context.commit('SET_PENDING_OWNERSHIP', {
        data: { hash: data, domain },
        requestDomain: domain,
      });
    },
  },
};
