import type {
  CanceledRequestError,
  HostingPlanPerk,
  IFlatErrorResponse,
  PerkGroup,
} from '@/types';

export const isCanceledRequestError = (
  error: any,
): error is CanceledRequestError => error.isCanceledRequest;

export const isFlatErrorResponse = (
  response: any,
): response is IFlatErrorResponse =>
  !!response &&
  typeof response === 'object' &&
  'error' in response &&
  typeof response.error === 'string' &&
  'code' in response &&
  ['number', 'string'].includes(typeof response.code);

export const isPerkGroup = (value: any): value is PerkGroup => 'title' in value;

export const isHostingPlanPerk = (value: any): value is HostingPlanPerk =>
  'text' in value && 'state' in value;
