<script lang="ts" setup>
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal } from '@/composables';
import { oauthRepo, twoFactorRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import type { ModalEmits, Oauth } from '@/types';
import { FieldType } from '@/types';

type Props = {
  data: {
    socialLogin: Oauth;
  };
};

interface Emits extends ModalEmits {}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { closeModal } = useModal(emits);
const { t } = useGlobals();
const profileStore = useProfileStore();

const isLoadingSubmit = ref(false);

const formSchema = computed(
  (): Record<'twoFactorAuthCode', FieldSchema> => ({
    twoFactorAuthCode: {
      type: FieldType.text,
      label: 'Enter code',
      custom: { hideOptionalLabel: true },
      validation: {
        required: true,
        twoFactorAuthentication: true,
      },
    },
  }),
);
const onSuccess = () => {
  closeModal();
};

const handleTwoFactorSubmit = async ({
  values: { twoFactorAuthCode },
}: FormValues) => {
  isLoadingSubmit.value = true;

  const [, error] = await oauthRepo.postUnlink(props.data.socialLogin, {
    twoFactorAuthCode,
  });

  await profileStore.fetchProfile();

  isLoadingSubmit.value = false;

  if (error) {
    return;
  }

  onSuccess();
};

if (profileStore.isTwoFactorMethodEmail) {
  twoFactorRepo.patchSendEmailCode();
}
</script>

<template>
  <HForm @on-submit="handleTwoFactorSubmit($event)">
    <HFormField
      name="twoFactorAuthCode"
      :schema="formSchema.twoFactorAuthCode"
    />
  </HForm>

  <HModalActions no-padding is-hidden>
    <HButton v-qa-generate h-form-submit :is-loading="isLoadingSubmit">
      {{ t('Confirm') }}
    </HButton>
  </HModalActions>
</template>
