<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { capitalize } from '@/utils/helpers';

const route = useRoute();

const breadcrumbs = computed(() => {
  const path = route.matched?.toReversed?.()[0]?.path;

  if (!path) return;

  const pathArray = path.slice(1).split('/');
  const formatted = pathArray
    .map((path) => {
      // keep dynamic path entry as it is
      if (path.startsWith(':')) return path;

      return capitalize(path).replaceAll('-', ' ');
    })
    .join(' / ');

  return { formatted, path };
});
</script>

<template>
  <span v-if="breadcrumbs" class="hidden" ai-search="breadcrumbs">
    {{ breadcrumbs.formatted }}
  </span>
  <span v-if="breadcrumbs" class="hidden" ai-search="path">
    {{ breadcrumbs.path }}
  </span>
</template>

<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>
