<script lang="ts" setup>
//JSON-slugs-migrated
import dayjs from 'dayjs';
import { computed, onBeforeUnmount } from 'vue';

import { useBrands } from '@/composables';
import { useProfileStore } from '@/stores';
import { errorLogger } from '@/utils/services/errorLogging';

const FOUR_MINUTES = 240;
const THREE_MINUTES = 180;
const TWO_MINUTES = 120;

type Props = {
  initial?: boolean;
  fullScreen?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  initial: false,
});

const { brandConfiguration } = useBrands();

const profileStore = useProfileStore();

let startTime: dayjs.Dayjs;

const loaderSrc = computed(() =>
  props.initial
    ? `@/images/${loaders.value.animated}`
    : `@/images/${loaders.value.static}`,
);

const loaders = computed(
  () =>
    brandConfiguration.value.loaders ?? {
      circle: 'circle-only.svg',
      animated: 'H-outline-animated.svg',
      static: 'H-outline-static.svg',
    },
);

const longLoaderLogError = (message: string, elapsedTimeInSeconds: number) => {
  if (Number(profileStore?.account?.id) % 100 === 0) {
    errorLogger.logError(
      new Error(
        `${message}, time taken: ${elapsedTimeInSeconds}. Brand: ${profileStore.clientBrandTitle}`,
      ),
    );
  }
};

onBeforeUnmount(() => {
  const endTime = dayjs();
  const elapsedTimeInSeconds = endTime.diff(startTime, 'seconds');
  if (elapsedTimeInSeconds > FOUR_MINUTES) {
    longLoaderLogError(
      'Loader shown for longer than 4 minutes',
      elapsedTimeInSeconds,
    );

    return;
  }
  if (elapsedTimeInSeconds > THREE_MINUTES) {
    longLoaderLogError(
      'Loader shown for longer than 3 minutes',
      elapsedTimeInSeconds,
    );

    return;
  }
  if (elapsedTimeInSeconds > TWO_MINUTES) {
    longLoaderLogError(
      'Loader shown for longer than 2 minutes',
      elapsedTimeInSeconds,
    );

    return;
  }
});

(() => {
  startTime = dayjs();
})();
</script>

<template>
  <div
    class="animation-loader__wrapper animation-loader__wrapper--absolute"
    data-qa="loader"
    :class="{
      'animation-loader__wrapper--full-screen': props.fullScreen,
    }"
  >
    <div v-if="profileStore.account?.brand.id" class="animation-loader">
      <HImage
        class="animation-loader__circle"
        :src="`@/images/${loaders.circle}`"
        alt=""
      />
      <HImage class="animation-loader__outline" :src="loaderSrc" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animation-loader {
  position: relative;
  width: 176px;
  height: 176px;

  &__wrapper {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: var(--white-blue);
    height: 100%;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-3);

    &--full-screen {
      width: 100vw;
      height: 100%;
    }
  }

  &__circle {
    width: 176px;
    height: 176px;
  }
  &__outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 176px;
    height: 176px;
  }

  @media (max-width: 992px) {
    &___wrapper {
      width: 100vw !important;
    }
  }
}
</style>
