import type { CVCRefreshRequest } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_PAYMENTS_V2}/jwt/api/v1/host-cvc-refresh`;

export default {
  async refreshCVC(payload: CVCRefreshRequest) {
    return hAsync<{ iframeUrl: string; token: string }>(
      http.post(URL, payload),
    );
  },
};
