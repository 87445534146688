import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hBillingRepo } from '@/repositories';
import {
  type Hosting,
  type HBilling,
  type CatalogCategory,
  type CatalogItem,
  INDEXED_DB_STORAGE,
} from '@/types';
import { getSplittedDomainParts } from '@/utils/helpers';
import indexedDBStorage from '@/utils/services/indexedDBStorageService';

const filterCatalogItemWithPrices = (item: CatalogItem) =>
  item.prices && item.prices.length;

export const useCatalogStore = defineStore('catalogStore', () => {
  const isLoading = ref(false);
  const isLoaded = ref(false);
  const catalog = ref<CatalogItem[]>([]);

  const catalogBrandId = computed<string>(() => catalog.value[0]?.itemFamilyId);

  const getCatalogItemById = (id: string) =>
    catalog.value.find((item) => item.id === id);

  const getCatalogItemsByPlan = (plan: string) =>
    catalog.value.find((item) => item.metadata?.plan === plan);

  const getCatalogItemsBySlug = (slug: string) =>
    catalog.value.find((item) => item.slug === slug);

  const getCatalogItemId = (productName: string) =>
    `${catalogBrandId.value}-${productName}`;

  const getPriceByItemIdAndPeriod = (itemId: string, period: string) => {
    const item = getCatalogItemById(itemId);
    const prices = item?.prices || [];

    return prices.find(({ id }) => id.endsWith(`-${period}`));
  };

  const getCatalogDomainItemIdByTld = (tld: string) =>
    getCatalogItemId(`domain-${tld.replaceAll('.', '')}`);

  const getAddon = (itemId: string, addon: string) => {
    const item = getCatalogItemById(itemId);

    if (!item?.addons) return undefined;

    const addonItem = item?.addons.find(({ slug }) => slug?.includes(addon));

    return addonItem;
  };

  const getDomainPricingByTld = (tld: string) => {
    const catalogItemId = getCatalogDomainItemIdByTld(tld);
    const catalogItem = getCatalogItemById(catalogItemId);

    return catalogItem?.prices || [];
  };

  const getDomainPricingByDomain = (domain: string) => {
    const [, tld] = getSplittedDomainParts(domain);

    return getDomainPricingByTld(tld);
  };

  const getItemByProductName = (plan: string) =>
    catalog.value.find((item) => plan.startsWith(item.name));

  const getCatalogByCategory = (category: CatalogCategory) =>
    catalog.value.filter((item) => item.category === category);

  const getCatalogByCategories = (categories: CatalogCategory[]) =>
    catalog.value.filter((item) =>
      categories.includes(item.category as CatalogCategory),
    );

  const getCatalogByCategoryAndSubcategory = (
    category: CatalogCategory,
    subcategory: HBilling.CatalogSubcategory,
  ) =>
    catalog.value.filter(
      (item) => item.category === category && item.subcategory === subcategory,
    );

  const getCatalogByCategoryAndSlug = (
    category: CatalogCategory,
    slug: Hosting.PlanSlug,
  ) =>
    catalog.value.find(
      ({ category: itemCategory, slug: itemSlug }) =>
        itemCategory === category && itemSlug === slug,
    );

  const getCatalogItemsBySubcategory = (
    subcategory: HBilling.CatalogSubcategory,
  ) => catalog.value.filter((item) => item.subcategory === subcategory);

  const getDomainRedemptionPeriodCatalogPriceId = (itemId: string) =>
    getCatalogItemById(itemId)?.metadata?.redemptionCharge;

  const getPriceById = (priceId: string) => {
    const splittedId = priceId.split('-');
    const itemId = splittedId.slice(0, splittedId.length - 2).join('-');
    const item = getCatalogItemById(itemId);

    return item?.prices.find(({ id }) => id === priceId);
  };

  const setPreloadedCatalogOrFetch = async () => {
    const preloadedCatalog = await indexedDBStorage.getValue(
      INDEXED_DB_STORAGE.PRELOADED_CATALOG,
    );

    if (preloadedCatalog) {
      catalog.value = preloadedCatalog;
      isLoaded.value = true;
    } else {
      await fetchCatalogWithLoader();
    }
  };

  const fetchCatalog = async () => {
    const [{ data }, error] = await hBillingRepo.getCatalog();

    if (!error) {
      /**
       * Filter out catalog items without prices, because some items are without prices
       * this is needed otherwise some hpanel places will fail
       * !Note we should probably not filter it here but think of some other solution or talk with backend
       * !to always return prices
       */
      const filteredCatalog = data.filter(filterCatalogItemWithPrices);
      catalog.value = filteredCatalog;

      const expiresAt = dayjs().unix() + 24 * 60 * 60; // 1 day from now

      indexedDBStorage.setValue(
        INDEXED_DB_STORAGE.PRELOADED_CATALOG,
        filteredCatalog,
        expiresAt,
      );

      isLoaded.value = true;
    }
  };

  const fetchCatalogWithLoader = async () => {
    isLoading.value = true;
    await fetchCatalog();
    isLoading.value = false;
  };

  return {
    catalog,
    isLoading,
    isLoaded,
    getAddon,
    getDomainPricingByTld,
    getCatalogItemsByPlan,
    getCatalogItemsBySlug,
    getItemByProductName,
    getDomainPricingByDomain,
    getCatalogItemById,
    getPriceByItemIdAndPeriod,
    getCatalogItemId,
    getCatalogByCategory,
    getCatalogByCategories,
    getCatalogItemsBySubcategory,
    getCatalogByCategoryAndSubcategory,
    getPriceById,
    getCatalogDomainItemIdByTld,
    getDomainRedemptionPeriodCatalogPriceId,
    getCatalogByCategoryAndSlug,
    fetchCatalog,
    setPreloadedCatalogOrFetch,
  };
});
