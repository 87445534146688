import type { ModalObject } from '@/types';

export default [
  {
    name: 'RemindersConfirm',
    type: 'HModalInputs',
    steps: [
      {
        icon: 'icons/profile/profileLetter.svg',
      },
    ],
  },
] as ModalObject[];
