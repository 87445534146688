<script setup lang="ts">
import { computed } from 'vue';
import {
  useRoute,
  type RouteLocationNamedRaw,
  type RouteLocationRaw,
} from 'vue-router';

type Props = {
  routeTo: RouteLocationNamedRaw | string;
  linkClass?: string;
  target?: string;
  disabled?: boolean;
};

interface Emits {
  (eventName: 'click'): void;
}

defineEmits<Emits>();
const props = defineProps<Props>();

const route = useRoute();

const isRouteTargetObject = (
  value: RouteLocationRaw | string,
): value is RouteLocationRaw => typeof value === 'object' && 'name' in value;

const isRoute = computed(() => isRouteTargetObject(props.routeTo));

const routeName = computed(() =>
  isRouteTargetObject(props.routeTo) && typeof props.routeTo !== 'string'
    ? props.routeTo.name
    : props.routeTo,
);

const isDisabled = computed(() => props.disabled || !props.routeTo);

const isActive = computed(() => {
  const matchedNames = route.matched
    ? route.matched.map(({ name }) => name)
    : [];

  return (
    route.name === routeName.value || matchedNames.includes(routeName.value)
  );
});
</script>
<template>
  <span v-if="isDisabled" class="link link--disabled" :class="[linkClass]">
    <slot />
  </span>
  <RouterLink
    v-else-if="isRoute"
    :to="routeTo as RouteLocationRaw"
    :class="[linkClass, isActive && 'router-link-active']"
    :target="target"
    exact
    @click="$emit('click')"
  >
    <slot />
  </RouterLink>
  <a
    v-else
    :href="routeTo as string"
    class="link"
    :class="linkClass"
    :target="target"
    @click="$emit('click')"
  >
    <slot />
  </a>
</template>

<style lang="scss" scoped>
@import '../sass/components/link';
</style>
