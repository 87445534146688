export default [
  {
    path: '/billing/refund-request/:invoice_id',
    name: 'refund_request',
    meta: {
      title: 'Refund Request',
      sideMenuExists: false,
      showSubheader: true,
      header: 'simple-header',
      breadcrumbs: ['billing'],
    },
    component: () => import('@/views/Refunds/RequestRefund.vue'),
  },
];
