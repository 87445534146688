import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/metrics`,

  async getLveFaults(params) {
    return await hAsync(
      http.get(`${this.url}/lve-faults`, { params, cache: 300 }),
    );
  },
  async getLveMetricsByRangeAndInterval({ params }) {
    return await hAsync(http.get(`${this.url}/lve`, { params }));
  },
};
