import type { IHostingAccount, WHOrderOwnership, WHOrder } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  orderId: IHostingAccount['orderId'];
};

const WH_ORDERS_URL = `${process.env.VITE_API_WH_API}/api/hapi/v1/orders`;

export default {
  url: ({ orderId }: HostingWebsiteAccountIdentifier) =>
    `${WH_ORDERS_URL}/${orderId}`,

  async renameOrder(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    customPlanName: string,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/rename`, {
        name: customPlanName,
      }),
    );
  },

  async getWebsitesResources({
    page,
    perPage,
    ownership,
  }: {
    page?: number;
    perPage?: number;
    ownership: WHOrderOwnership;
  }) {
    return await hAsync<WHOrder>(
      http.get(`${WH_ORDERS_URL}/websites`, {
        plain: true,
        params: {
          page,
          perPage,
          ownership,
        },
      }),
    );
  },
};
