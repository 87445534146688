import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';

import { useFrontendSettingsStore, useReferralStore } from '@/stores';
import { Route } from '@/types';

const proPanelCartGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const referralStore = useReferralStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const isReferralsDataLoaded = referralStore.isProUserReferralsDataLoaded;

  if (!isReferralsDataLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await referralStore.fetchProUserReferralData({
      hideToastr: true,
    });
    frontendSettingsStore.setState('pageLoading', false);
  }
  const referralCode = referralStore.proUserReferralData?.referralCode;
  if (!referralCode) {
    next({
      name: Route.HostingerPro.PARTNER_BENEFITS,
    });

    return;
  }

  next();
};

export default proPanelCartGuard;
