import { Hosting } from '@/types';

export const NON_UPGRADEABLE_PLANS = [
  Hosting.LegacyPlan.GCP_BUSINESS_8,
  Hosting.Plan.CLOUD_ENTERPRISE,
];

export const isHostingPlanUpgradable = (
  planId: Hosting.LegacyPlan | Hosting.Plan,
) => !NON_UPGRADEABLE_PLANS.includes(planId);
