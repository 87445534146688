import type { RouteRecordName } from 'vue-router';

import { router } from '@/router';

export const getLocationParams = (): {
  creationLocation: RouteRecordName | null | undefined;
} => ({
  creationLocation: router.currentRoute.value.name,
});

export const getHBillingMeta = () => ({
  ravelinDeviceId: window.ravelinDeviceId,
  metadata: {
    creationSource: 'hpanel',
    ...getLocationParams(),
  },
});
