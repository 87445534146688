export enum Status {
  Ok = 'ok',
  Paid = 'paid',
  Active = 'active',
  Running = 'running',
  Sent = 'sent',
  Success = 'success',
  Created = 'created',
  Finished = 'finished',
  Refunded = 'refunded',
  Verified = 'verified',
  Completed = 'completed',
  Installing = 'installing',
  Transfering = 'transfering',
  Transferring = 'transferring',
  Creating = 'creating',
  Requested = 'requested',
  Starting = 'starting',
  Started = 'started',
  Destroying = 'destroying',
  InProgress = 'in_progress',
  Pending = 'pending',
  PendingSetup = 'pending_setup',
  PendingDataEntry = 'pending_data_entry',
  PendingEmailConfirmation = 'pending_email_confirmation',
  PendingVerification = 'pending_verification',
  PendingVerifications = 'pending verification',
  Suspended = 'suspended',
  Unsuspending = 'unsuspending',
  Suspending = 'suspending',
  Verification = 'verification',
  Stopping = 'stopping',
  StoppingRecovery = 'stopping_recovery',
  Initial = 'initial',
  Unpaid = 'unpaid',
  RedemptionPeriod = 'redemption_period',
  Unverified = 'unverified',
  ActionRequired = 'action_required',
  PendingEmailVerification = 'pending_email_verification',
  Reviewing = 'reviewing',
  Warning = 'warning',
  SetupNotFinished = 'setup_not_finished',
  Scheduled = 'scheduled',
  Expire = 'expire',
  Expired = 'expired',
  Archived = 'archived',
  Stopped = 'stopped',
  GracePeriod = 'grace_period',
  Inactive = 'inactive',
  Delayed = 'delayed',
  PendingPayment = 'pending_payment',
  Restoring = 'restoring',
  Recovery = 'recovery',
  Recovering = 'recovering',
  Recreating = 'recreating',
  PasswordMismatch = 'password_mismatch',
  Failed = 'failed',
  Error = 'error',
  NotFound = 'not_found',
  Conflict = 'conflict',
  Missing = 'missing',
  VerificationFailed = 'verification_failed',
  FailInstall = 'fail_install',
  Rejected = 'rejected',
  Destroyed = 'destroyed',
  Deleted = 'deleted',
  Canceled = 'canceled',
  Cancel = 'cancel',
  Info = 'info',
  RequestStart = 'request_start',
  ServerDown = 'server_down',
  WaitingForRetry = 'waiting_for_retry',
  RestoreInProgress = 'restore_in_progress',
  RestoreFailed = 'restore_failed',
  RestoreCompleted = 'restore_completed',
}
