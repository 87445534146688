import type { FieldOption } from '@hostinger/hcomponents';
import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import {
  useOnboardingSteps,
  useDomain,
  useSubscriptions,
  useOnboardingStatusCheckV2,
  useResourceMapper,
  useOnboardingV2,
  useExperiment,
  useAddWebsite,
  useGlobals,
} from '@/composables';
import { BUILDER_CMS } from '@/data/migration/migrationRestrictedDomains';
import {
  domainsRepo,
  accountRepo,
  websiteRepo,
  websitesRepo,
} from '@/repositories';
import {
  useOnboardingDataCenterStore,
  useOnboardingStore,
  useOrdersStore,
  useResourcesStore,
} from '@/stores';
import type {
  WordPressHowToMigrateAnswer,
  OrderWithLimits,
  UploadedFile,
  MigrationPlatform,
  ArrayElementType,
  IHResource,
} from '@/types';
import {
  MIGRATIONS_ONBOARDING_STEPS,
  STORE_PERSISTENT_KEYS,
  MigrationStatus,
  WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS,
  MIGRATION_PLATFORMS,
  Header,
  Hosting,
  Status,
  Experiment,
  ONBOARDING_SCENARIO,
} from '@/types';
import { mapKeyValue, toASCII } from '@/utils/helpers';
import {
  convertDomainToUrl,
  convertStringToUrl,
  stripDomainUrl,
} from '@/utils/helpers/domainsHelper';

const EMPTY_HOSTING_PLAN = {
  plan: {} as Hosting.Plan,
  title: '',
  domain: '',
  username: '',
  hasAccount: false,
  orderId: '',
  productName: '',
  limits: {
    addons: {
      limit: 0,
      count: 0,
    },
  },
  status: '' as Hosting.OrderStatus,
};

const SQL_EXTENSIONS = ['.sql', '.sql.gz'];
const WPRESS_EXTENSION = '.wpress';

export const useMigrationsOnboardingStore = defineStore(
  'migrationsOnboardingStore',
  () => {
    const { t, toastr } = useGlobals();
    const { domain } = useDomain();
    const { getSubscriptionIdByOrderId } = useSubscriptions();
    const { pollOnboardingUntilCompletion } = useOnboardingStatusCheckV2();
    const onboardingStore = useOnboardingStore();
    const onboardingDCStore = useOnboardingDataCenterStore();
    const store = useStore();
    const route = useRoute();
    const ordersStore = useOrdersStore();
    const { getResourcesByIdempotencyKey } = useResourceMapper();
    const { subscriptionId } = useOnboardingV2();
    const { getOrdersWithLimits } = useOrdersStore();
    const resourcesStore = useResourcesStore();
    const { getIsLimitReached } = useAddWebsite();

    const { isExperimentActive: isOnboardingSubmitOnDemand } = useExperiment(
      Experiment.ID.HOSTING_MIGRATION_FLOW_SIMPLIFIED,
    );

    const isRedirectedFromOnboarding = computed(() => !!domain.value);
    const isRedirectedFromAddonOnboarding = computed(() => {
      const addonScenarios = ['quick-install-migration-addon'];

      if (
        onboardingRedirectionParams.value?.order_id !== route.params.order_id
      ) {
        return false;
      }

      return !!addonScenarios.includes(
        onboardingRedirectionParams.value?.scenario,
      );
    });

    const isRedirectedFromChangeDomain = computed(() => {
      if (
        onboardingRedirectionParams.value?.order_id !== route.params.order_id
      ) {
        return false;
      }

      return (
        onboardingRedirectionParams.value?.scenario ===
        ONBOARDING_SCENARIO.MIGRATION_CHANGE_DOMAIN
      );
    });

    const onboardingRedirectionParams = ref();

    const migrationsOnboardingSteps = computed(() =>
      Object.values(MIGRATIONS_ONBOARDING_STEPS),
    );

    const isAccountSetupCompleted = ref(false);

    const enteredWebsiteName = ref('');

    const isUsingTemporaryDomain = ref(false);

    const isUploadingInProgress = ref(false);
    const uploadingProgress = ref({
      totalSize: 0,
      uploadedSize: 0,
      isUploading: false,
    });
    const detectedCms = ref('');

    const isWordPressAutoDetected = ref(false);

    const isCpanelAutoDetected = ref(false);

    const isCreatingNewAddon = ref(false);

    const isCreatingNewWebsite = ref(false);

    const websitesToMigrateOptions = ref<FieldOption[]>([]);

    const selectedWpMigrationType = ref<WordPressHowToMigrateAnswer>(
      WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS.WORDPRESS_ADMIN_DETAILS,
    );

    const selectedMigrationPlatform = ref<MigrationPlatform>(
      MIGRATION_PLATFORMS.OTHER,
    );
    const whereToMigrateSelection = ref('');

    const isFromMigrationRequestForm = ref(false);

    const newWebsiteMigrationDetails = reactive<{
      hostingPlan: OrderWithLimits | null;
      newWebsiteName: string;
      existingWebsiteName: string;
    }>({
      hostingPlan: EMPTY_HOSTING_PLAN,
      newWebsiteName: '',
      existingWebsiteName: '',
    });

    const wordPressCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
    });

    const migrationBackupUrl = ref('');

    const cPanelCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
    });

    const otherPanelCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
      additionalNotes: '',
    });

    const uploadedFiles = ref<UploadedFile[]>([]);

    const isOnlySqlFileUploaded = computed(
      () =>
        uploadedFiles.value.length === 1 &&
        SQL_EXTENSIONS.some((extension) =>
          uploadedFiles.value[0].name.endsWith(extension),
        ),
    );

    const isWpressFileUploaded = computed(
      () =>
        uploadedFiles.value.length &&
        uploadedFiles.value[0].name.endsWith(WPRESS_EXTENSION),
    );

    const getMigrationRequestBody = async () => {
      const { existingWebsiteName, newWebsiteName } =
        newWebsiteMigrationDetails;

      const enteredDomain =
        domain.value || existingWebsiteName || stripDomainUrl(newWebsiteName);

      const staticValues = {
        domain: toASCII(enteredDomain),
        overwrite: 0,
        panel: isCpanelAutoDetected.value
          ? MIGRATION_PLATFORMS.CPANEL
          : selectedMigrationPlatform.value,
        status: MigrationStatus.PENDING,
      };

      const isWordPressMigrationWithBackup =
        selectedMigrationPlatform.value === MIGRATION_PLATFORMS.WORDPRESS &&
        !wordPressCredentials.loginUrl;

      if (isWordPressMigrationWithBackup) {
        return {
          ...staticValues,
          backupLink: convertDomainToUrl(migrationBackupUrl.value),
        };
      }

      const REQUEST_BODY_CONFIG = {
        [MIGRATION_PLATFORMS.WORDPRESS]: {
          ...staticValues,
          adminLink: convertStringToUrl(wordPressCredentials.loginUrl),
          config: {
            wpUsername: wordPressCredentials.username,
            wpPassword: wordPressCredentials.password,
          },
        },
        [MIGRATION_PLATFORMS.CPANEL]: {
          ...staticValues,
          adminLink: convertStringToUrl(cPanelCredentials.loginUrl),
          config: {
            username: cPanelCredentials.username,
            password: cPanelCredentials.password,
          },
        },
        [MIGRATION_PLATFORMS.OTHER]: {
          ...staticValues,
          adminLink: convertStringToUrl(enteredWebsiteName.value),
          panelLink: convertStringToUrl(otherPanelCredentials.loginUrl),
          config: {
            username: otherPanelCredentials.username,
            password: otherPanelCredentials.password,
          },
          ...(otherPanelCredentials.additionalNotes && {
            info: otherPanelCredentials.additionalNotes,
          }),
        },
        default: staticValues,
      };

      return mapKeyValue(
        REQUEST_BODY_CONFIG,
        isCpanelAutoDetected.value
          ? MIGRATION_PLATFORMS.CPANEL
          : selectedMigrationPlatform.value || 'default',
      );
    };

    const setUploadedFiles = (files: UploadedFile[]) => {
      const updatedFiles = new Map(
        uploadedFiles.value.map((file) => [file.name, file]),
      );

      files.forEach((file) => {
        updatedFiles.set(file.name, file);
      });

      uploadedFiles.value = Array.from(updatedFiles.values());
    };

    const clearUploadedFiles = () => {
      uploadedFiles.value = [];
    };

    const fetchCmsDetect = async (url: string) => {
      const [{ data }, err] = await websiteRepo.getCmsDetect(
        convertStringToUrl(url),
      );

      if (data && !err) {
        detectedCms.value = data.technology.replace('_', '');

        isWordPressAutoDetected.value =
          detectedCms.value === Hosting.CMS.WORDPRESS;
      } else {
        isWordPressAutoDetected.value = false;

        detectedCms.value = '';
      }
    };

    const getDataCenter = async (subscriptionId: string) => {
      const datacenter =
        Object.keys(onboardingDCStore.bestDataCenter)?.[0] || '';

      if (!datacenter) {
        await onboardingDCStore.fetchAvailableDataCenters(subscriptionId);
      }

      return Object.keys(onboardingDCStore.bestDataCenter)?.[0] || '';
    };

    const addNewWebsiteAddon = async () => {
      if (
        !newWebsiteMigrationDetails?.hostingPlan?.orderId ||
        !newWebsiteMigrationDetails?.hostingPlan?.username
      ) {
        return [];
      }

      isCreatingNewAddon.value = true;

      const [_, error] = await websitesRepo.submitAddonOnboarding(
        {
          domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName),
        },
        {
          username: newWebsiteMigrationDetails.hostingPlan.username,
          orderId: newWebsiteMigrationDetails.hostingPlan.orderId,
          domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName),
        },
      );
      isAccountSetupCompleted.value = true;
      isCreatingNewAddon.value = false;

      return [_, error];
    };

    const addNewWebsiteAddonForMigration = async () => {
      if (
        !newWebsiteMigrationDetails.hostingPlan?.orderId ||
        !newWebsiteMigrationDetails.hostingPlan.username
      ) {
        return [];
      }

      const requestHeaders = {
        [Header.USERNAME]: newWebsiteMigrationDetails.hostingPlan.username,
        [Header.ORDER_ID]: newWebsiteMigrationDetails.hostingPlan.orderId,
      };
      isCreatingNewAddon.value = true;
      const [data, err] = await domainsRepo.addAddon(
        { domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName) },
        requestHeaders,
      );

      if (data && !err) {
        whereToMigrateSelection.value =
          newWebsiteMigrationDetails.newWebsiteName;

        isAccountSetupCompleted.value = true;
        // Fetch accounts to update the list of accounts for order
        await store.dispatch(
          'updateAccountsByReferenceId',
          newWebsiteMigrationDetails.hostingPlan.orderId,
        );
      }

      isCreatingNewAddon.value = false;

      return [data, err];
    };

    const addNewWebsiteForMigration = async () => {
      if (!newWebsiteMigrationDetails.hostingPlan?.orderId) return [];

      const subscriptionId = getSubscriptionIdByOrderId(
        newWebsiteMigrationDetails.hostingPlan?.orderId?.toString(),
      );

      if (!subscriptionId) return [null, true];

      isCreatingNewWebsite.value = true;

      const datacenter =
        selectedDataCenter.value?.name || (await getDataCenter(subscriptionId));

      const [data, err] = await accountRepo.setupOnboarding(
        {
          orderId: subscriptionId,
          accountConfig: {
            datacenter,
            domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName),
          },
        },
        newWebsiteMigrationDetails.hostingPlan.orderId,
      );

      if (data && !err) {
        isAccountSetupCompleted.value = true;
        whereToMigrateSelection.value =
          newWebsiteMigrationDetails.newWebsiteName;

        await pollOnboardingUntilCompletion(5000, {
          subscriptionId,
          domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName),
        });
      }

      onboardingStore.$reset();
      store.dispatch('fetchHostingOrders');
      isCreatingNewWebsite.value = false;

      return [data, err];
    };
    const isSqlUploaded = computed(() =>
      uploadedFiles.value.some((file) =>
        SQL_EXTENSIONS.some((extension) => file.name.endsWith(extension)),
      ),
    );

    const accountSetupResource = ref<IHResource | null>();

    const isAccountSetupNeeded = computed(() => {
      if (!accountSetupResource.value || !isOnboardingSubmitOnDemand.value) {
        return false;
      }

      const activeVhosts = accountSetupResource.value?.items?.filter(
        (item) => item.state === Status.Active,
      );

      const activeBuilderVhosts = activeVhosts.filter((item) =>
        resourcesStore.isBuilderWebsiteResourceByDomain(item.domain),
      );

      const isResourceActive =
        accountSetupResource.value?.state !== Status.Active;

      const isOnlyBuilderActiveVhost =
        activeVhosts.length === activeBuilderVhosts.length;

      return (
        !activeVhosts?.length || isResourceActive || isOnlyBuilderActiveVhost
      );
    });

    const fetchAccountSetupResource = async () => {
      await resourcesStore.fetchResources();

      accountSetupResource.value = getResourcesByIdempotencyKey(
        subscriptionId.value,
      );
    };

    const setupOnboardingAccount = async () => {
      if (!isOnboardingSubmitOnDemand.value) {
        return [];
      }

      await getOrdersWithLimits();
      await fetchAccountSetupResource();

      if (!accountSetupResource.value) return [];

      newWebsiteMigrationDetails.hostingPlan =
        ordersStore.orderListWithLimits?.find(
          (hostingOrder) =>
            String(hostingOrder.orderId) ===
            accountSetupResource.value?.referenceId,
        ) || EMPTY_HOSTING_PLAN;

      newWebsiteMigrationDetails.existingWebsiteName = enteredWebsiteName.value;
      newWebsiteMigrationDetails.newWebsiteName = domain.value;

      if (
        !isAccountSetupNeeded.value &&
        getIsLimitReached(newWebsiteMigrationDetails.hostingPlan)
      ) {
        toastr.e(t('v2.hosting.plan.reached.available.addon.limits'));

        return [, true];
      }

      if (
        isAccountSetupNeeded.value ||
        !newWebsiteMigrationDetails.hostingPlan.username
      ) {
        return await addNewWebsiteForMigration();
      }
      const isDomainAlreadyHosted = accountSetupResource.value.items?.some(
        (item) =>
          item.domain === newWebsiteMigrationDetails.newWebsiteName &&
          item.state === Status.Active,
      );

      if (isDomainAlreadyHosted) return [];

      return await addNewWebsiteAddon();
    };

    const isBuilderCms = computed(() =>
      BUILDER_CMS.includes(detectedCms.value),
    );

    const selectedDataCenter = ref<{ label: string; name: string } | null>(
      null,
    );

    const fetchBestDataCenter = async () => {
      const defaultDataCenter = (await getDataCenter(
        subscriptionId.value,
      )) as keyof typeof onboardingDCStore.dataCenters;

      if (!defaultDataCenter || !onboardingDCStore.dataCenters) {
        selectedDataCenter.value = null;

        return;
      }

      selectedDataCenter.value = {
        label: onboardingDCStore.dataCenters[defaultDataCenter],
        name: defaultDataCenter,
      };
    };

    const resetEnteredCredentials = () => {
      wordPressCredentials.username = '';
      wordPressCredentials.password = '';
      wordPressCredentials.loginUrl = '';
      cPanelCredentials.username = '';
      cPanelCredentials.password = '';
      cPanelCredentials.loginUrl = '';
      otherPanelCredentials.username = '';
      otherPanelCredentials.password = '';
      otherPanelCredentials.loginUrl = '';
      otherPanelCredentials.additionalNotes = '';
    };

    const $reset = () => {
      currentStep.value = MIGRATIONS_ONBOARDING_STEPS.WEBSITE_TO_MIGRATE_NAME;
      completedSteps.value = [];
      migrationBackupUrl.value = '';
      whereToMigrateSelection.value = '';
      isUsingTemporaryDomain.value = false;
      enteredWebsiteName.value = '';
      transition.value = 'slide-left';
      newWebsiteMigrationDetails.hostingPlan = EMPTY_HOSTING_PLAN;
      newWebsiteMigrationDetails.newWebsiteName = '';
      newWebsiteMigrationDetails.existingWebsiteName = '';
      selectedWpMigrationType.value =
        WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS.WORDPRESS_ADMIN_DETAILS;
      selectedMigrationPlatform.value = MIGRATION_PLATFORMS.OTHER;
      websitesToMigrateOptions.value = [];
      isWordPressAutoDetected.value = false;
      isCpanelAutoDetected.value = false;
      uploadedFiles.value = [];
      isFromMigrationRequestForm.value = false;
      isUploadingInProgress.value = false;
      resetEnteredCredentials();
      uploadingProgress.value = {
        totalSize: 0,
        uploadedSize: 0,
        isUploading: false,
      };
      onboardingRedirectionParams.value = null;
      selectedDataCenter.value = null;
      isAccountSetupCompleted.value = false;
    };

    const {
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      goToStep,
      goToPrevious,
      completionPercentage,
      removeCompletedStep,
      setStep,
      transition,
      setTransition,
      addCompletedStep,
      removePreviousStep,
    } = useOnboardingSteps<
      ArrayElementType<typeof migrationsOnboardingSteps.value>
    >(migrationsOnboardingSteps);

    return {
      removeCompletedStep,
      transition,
      completionPercentage,
      currentStep,
      goToStep,
      goToPrevious,
      completedSteps,
      selectedWpMigrationType,
      getMigrationRequestBody,
      removePreviousStep,
      resetEnteredCredentials,
      addCompletedStep,
      whereToMigrateSelection,
      wordPressCredentials,
      hasPreviousSteps,
      previousStep,
      newWebsiteMigrationDetails,
      setTransition,
      selectedMigrationPlatform,
      cPanelCredentials,
      otherPanelCredentials,
      setStep,
      $reset,
      migrationBackupUrl,
      enteredWebsiteName,
      websitesToMigrateOptions,
      fetchCmsDetect,
      isWordPressAutoDetected,
      isCpanelAutoDetected,
      isRedirectedFromOnboarding,
      uploadedFiles,
      setUploadedFiles,
      isFromMigrationRequestForm,
      isRedirectedFromAddonOnboarding,
      isRedirectedFromChangeDomain,
      clearUploadedFiles,
      isUploadingInProgress,
      addNewWebsiteAddonForMigration,
      addNewWebsiteForMigration,
      isCreatingNewAddon,
      isCreatingNewWebsite,
      isSqlUploaded,
      isOnlySqlFileUploaded,
      isWpressFileUploaded,
      isUsingTemporaryDomain,
      uploadingProgress,
      setupOnboardingAccount,
      isOnboardingSubmitOnDemand,
      detectedCms,
      isBuilderCms,
      onboardingRedirectionParams,
      selectedDataCenter,
      fetchBestDataCenter,
      accountSetupResource,
      fetchAccountSetupResource,
      isAccountSetupNeeded,
      isAccountSetupCompleted,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.MIGRATIONS_ONBOARDING_STORE } },
);
