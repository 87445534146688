import dayjs from 'dayjs';

import { useExperiment } from '@/composables';
import type { BillingSubscription } from '@/types';
import { Experiment, SubscriptionResourceType, Hosting } from '@/types';

export const useUpgradeOrRenewExperiment = () => {
  const { isExperimentActive: isRenewUpgradeModalExperimentActive } =
    useExperiment(Experiment.ID.RENEW_UPGRADE_MODAL);

  const isOneYearSubscription = ({
    billingPeriod,
    billingPeriodUnit,
  }: {
    billingPeriod: string | number;
    billingPeriodUnit: string;
  }) => {
    const period =
      typeof billingPeriod === 'string'
        ? parseInt(billingPeriod, 10)
        : billingPeriod;

    return period === 1 && billingPeriodUnit === 'year';
  };

  const is21dayUntilExpire = (currentTermEnd: number) => {
    const today = dayjs();
    const expiresAtDate = dayjs.unix(currentTermEnd);

    const diff = expiresAtDate.diff(today, 'day');

    return diff <= 21 && diff >= 0;
  };

  const isHosting = (subscription: BillingSubscription) =>
    subscription.resourceType.toLowerCase() ===
    SubscriptionResourceType.HOSTING;

  const isIncludedPlan = (subscription: BillingSubscription) => {
    const currentPlan = subscription.cfSubscriptionParams?.plan;

    if (!currentPlan) {
      return false;
    }

    // These two plans are not included because both of them ar the top tier plans
    const excludedPlans = [
      Hosting.Plan.CLOUD_ENTERPRISE,
      Hosting.Plan.BUSINESS_TOP,
    ];

    return !excludedPlans.includes(currentPlan);
  };

  const isUpgradeOrRenewConditionsPassed = (
    subscription: BillingSubscription,
  ) =>
    isHosting(subscription) &&
    isIncludedPlan(subscription) &&
    is21dayUntilExpire(subscription.currentTermEnd) &&
    isOneYearSubscription(subscription);

  const eligibleForUpgradeOrRenew = (subscription: BillingSubscription) =>
    isUpgradeOrRenewConditionsPassed(subscription) &&
    isRenewUpgradeModalExperimentActive.value;

  return {
    eligibleForUpgradeOrRenew,
    isUpgradeOrRenewConditionsPassed,
  };
};
