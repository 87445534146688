<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="index"
      class="purchase-option"
    >
      <div class="purchase-option__details">
        <div class="d-flex align-items-center">
          <div class="purchase-option__title">
            {{ option.description }}
          </div>
        </div>

        <div class="text-right">
          <h3 class="purchase-option__price">
            {{ formatPrice(option.unitAmount) }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PurchaseLineItems',
  props: {
    options: Array,
  },
  methods: {
    formatPrice(price) {
      return this.$currency.format(price / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-option {
  &__details {
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;

    border-radius: 4px;

    border: 1px solid var(--gray-border);
    display: grid;
    grid-template-columns: auto minmax(100px, auto);
    align-items: center;
  }

  &__title {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  &__price {
    font-size: 14px;
    padding: 8px;
    margin-bottom: 0;
    margin-left: 16px;
    display: inline-block;

    background: var(--gray-light);
    border-radius: 8px;
  }
}
</style>
