<template>
  <div
    class="h-portlet__head flex-column"
    :class="[
      {
        'h-px-0': noHeadPaddingX,
      },
      bodyClass,
    ]"
    @click="$emit('portlet-head-clicked')"
  >
    <div
      class="h-portlet__head-wrapper"
      :class="{ 'h-portlet__head-wrapper--no-wrap': noHeadWrap }"
    >
      <div
        v-if="$slots.tools && toolPosition === 'left'"
        class="h-portlet__head-tools d-block d-md-flex justify-content-end"
        :class="toolsClass"
        @click.stop
      >
        <slot name="tools" />
      </div>
      <div
        v-if="$slots.icon || $slots.title"
        class="h-portlet__head-caption flex-column justify-content-center flex-grow-1"
        :class="[
          {
            'h-portlet__head-caption--noMargin': noHeadMargin,
          },
        ]"
      >
        <div class="h-portlet__head-title">
          <h3 class="h-portlet__head-text" :class="{ 'w-100': fullWidthTitle }">
            <span v-if="$slots.icon" class="h-portlet__head-icon">
              <slot name="icon" />
            </span>
            <span v-if="$slots.title" :class="{ 'w-100': fullWidthTitle }">
              <slot name="title" />
            </span>
          </h3>
        </div>
        <div v-if="$slots.status" class="h-portlet__status">
          <slot name="status" />
        </div>
      </div>
      <div
        v-if="$slots.tools && toolPosition !== 'left'"
        class="h-portlet__head-tools d-block d-md-flex justify-content-end"
        :class="toolsClass"
        @click.stop
      >
        <slot name="tools" />
      </div>
    </div>
    <div v-if="$slots.subtitle" class="h-portlet__subtitle">
      <slot name="subtitle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HPortletHead',
  props: {
    noHeadPaddingX: Boolean,
    noHeadMargin: Boolean,
    bodyClass: String,
    toolsClass: String,
    fullWidthTitle: Boolean,
    noMarginBottom: Boolean,
    noHeadWrap: Boolean,
    toolPosition: String,
  },
  emits: ['portlet-head-clicked'],
};
</script>

<style lang="scss">
.h-portlet {
  @media (max-width: 768px) {
    &__head-caption {
      margin-bottom: 24px;
      &--noMargin {
        margin-bottom: 0;
      }
    }
  }
}
</style>
