import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: process.env.VITE_API_REST,

  async getDetailsSnapshot(username) {
    return await hAsync(
      http.get(`${this.url}/v3/account/snapshot`, {
        params: {
          username,
          json: 1,
          order: 1,
        },
      }),
    );
  },

  async getStatistics(params) {
    return await hAsync(
      http.get(`${this.url}/v2/statistics`, { cache: 300, params }),
    );
  },
  async getStatisticsAccessLogsStatus(params) {
    return await hAsync(
      http.get(`${this.url}/v2/statistics/access-logs-status`, {
        cache: 300,
        params,
      }),
    );
  },
  async getStatisticsAccessLogs(params) {
    return await hAsync(
      http.get(`${this.url}/v2/statistics/access-logs`, { cache: 300, params }),
    );
  },
  async getStatisticsMonthly(params) {
    return await hAsync(
      http.get(`${this.url}/v2/statistics/monthly`, { cache: 300, params }),
    );
  },
};
