<template>
  <div>
    <HPortlet
      no-background
      no-margin
      no-body-padding
      :disabled-loader="isLoaderDisabled"
    >
      <HForm
        submit-id="application-setup"
        @on-submit="onFormSubmit($event)"
        @on-change="onFormChange($event)"
      >
        <div
          v-if="!noVersion"
          class="field"
          :class="{ 'field--half': !!language.values }"
        >
          <div v-trans class="title">Select application version</div>
          <HFormField
            data-qa="application-setup-version"
            class="h-mb-0 h-mt-8"
            name="version"
            :schema="versionsSelect"
            @on-change="handleAppVersion"
          />
        </div>
        <div
          v-if="language.values"
          class="field"
          :class="{ 'field--half': !noVersion }"
        >
          <div v-trans class="title">Select language</div>
          <HFormField
            data-qa="autoinstaller-language"
            name="language"
            class="h-mb-0 h-mt-8"
            :schema="language"
          />
        </div>
        <div v-if="!noRequirement">
          <div v-trans class="requirements-title">Requirements</div>
          <div>
            <div
              v-if="!!applicationDetails.requirementDiskspace"
              class="field field--half"
            >
              <HpIcon
                v-if="isDiskSpaceEnough()"
                icon="icon-check-circle"
                success
                class="h-mr-4 align-middle"
                height="20"
                width="20"
              />
              <HpIcon
                v-else
                icon="icon-cancel"
                danger
                class="h-mr-4 align-middle"
                height="20"
                width="20"
              />
              <Trans>Disk space:</Trans>
              <strong class="h-mr-4">
                &nbsp;{{ `${applicationDetails.requirementDiskspace} MB` }}
              </strong>
              <span
                v-if="!isDiskSpaceEnough()"
                v-tooltip="
                  `There is not enough disk space. You would need to clear space before installing.`
                "
                class="explanation"
              >
                <HpIcon gray icon="icon-info" height="20" width="20" />
              </span>
            </div>

            <div class="field field--half">
              <span
                v-if="
                  (!!applicationDetails.requirementPhpVersionMinimum &&
                    !getIsCompatiblePhpVersion()) ||
                  isBetaPhpWordPressVersion
                "
              >
                <span v-if="isBetaPhpWordPressVersion">
                  <HpIcon
                    icon="icon-info"
                    class="h-mr-4 align-middle"
                    color="warning"
                    height="20"
                    width="20"
                  />
                  <span>{{ $t('Beta-compatible PHP Version') }}</span>
                  <span
                    v-tooltip="
                      $t(
                        'PHP version is beta-compatible with the application version',
                      )
                    "
                    class="h-ml-4 explanation"
                  >
                    <HpIcon gray icon="icon-info" height="20" width="20" />
                  </span>
                </span>
                <span v-else-if="!getIsCompatiblePhpVersion()">
                  <HpIcon
                    icon="icon-cancel"
                    class="h-mr-4 align-middle"
                    danger
                    height="20"
                    width="20"
                  />
                  <Trans>Min. PHP Version:</Trans>
                  <strong>
                    &nbsp;{{ applicationDetails.requirementPhpVersionMinimum }}
                    &nbsp;
                  </strong>
                </span>
                <span v-else>
                  <HpIcon
                    icon="icon-check-circle"
                    class="h-mr-4 align-middle"
                    success
                    height="20"
                    width="20"
                  />
                  <Trans>Min. PHP Version:</Trans>
                  <strong>
                    {{ applicationDetails.requirementPhpVersionMinimum }}
                  </strong>
                </span>
              </span>
              <span
                v-else-if="
                  !applicationDetails.requirementPhpVersionMaximum &&
                  getIsCompatiblePhpVersion()
                "
              >
                <HpIcon
                  icon="icon-check-circle"
                  class="h-mr-4 align-middle"
                  success
                  height="20"
                  width="20"
                />
                <Trans>Min. PHP Version:</Trans>
                <strong>
                  {{ applicationDetails.requirementPhpVersionMinimum }}
                </strong>
              </span>
              <span
                v-else-if="!!applicationDetails.requirementPhpVersionMaximum"
              >
                <HpIcon
                  v-if="isPhpVersionLowEnough()"
                  icon="icon-check-circle"
                  class="h-mr-4 align-middle"
                  success
                  height="20"
                  width="20"
                />
                <HpIcon
                  v-else
                  icon="icon-cancel"
                  class="h-mr-4 align-middle"
                  danger
                  height="20"
                  width="20"
                />
                <Trans>Max. PHP Version:</Trans>
                <strong>
                  &nbsp;{{
                    applicationDetails.requirementPhpVersionMaximum
                  }}&nbsp;
                </strong>
              </span>

              <Trans
                v-if="!isBetaPhpWordPressVersion"
                :translate-params="{
                  phpversion: currentPhpVersion,
                }"
                class="h-mr-4 text-gray"
              >
                (Current: {phpversion})
              </Trans>
              <span
                v-if="!isPhpVersionLowEnough() || !getIsCompatiblePhpVersion()"
                v-tooltip="
                  `PHP version is incompatible with the application version`
                "
                class="explanation"
              >
                <HpIcon gray icon="icon-info" height="20" width="20" />
              </span>
            </div>
            <HFormField
              data-qa="setup-php-version"
              name="phpVersion"
              class="h-mb-0 h-mt-16"
              :schema="
                isVersionedWordPressApplication
                  ? wordPressPhpVersionsSchema
                  : otherAppPhpVersionSchema
              "
              @on-change="handleAppPhpVersion"
            />
          </div>
        </div>
        <div class="updates">
          <div v-trans class="field field--half h-mt-8 title">
            Choose update schedule
          </div>

          <h4 v-trans>Application Updates</h4>
          <div
            v-for="radio in applicationUpdateValues"
            :key="radio.id"
            class="d-flex"
          >
            <HRadio
              :id="radio.id"
              :is-active="radio.isActive"
              :label="radio.title"
              @click="onApplicationUpdateSelect"
            />

            <span v-if="radio.id === 1" class="h-ml-4 text-gray">
              ({{ $t('Recommended') }})
            </span>
          </div>
        </div>
      </HForm>
    </HPortlet>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import HRadio from '@/components/HRadio.vue';
import HPortlet from '@/components/Portlet/HPortlet.vue';
import { useHRadio, useGlobals } from '@/composables';
import { useWordpressStore } from '@/stores';
import versionCompare from '@/utils/services/versionCompareService';

export default {
  name: 'ApplicationSetup',
  components: {
    HPortlet,
    HRadio,
  },
  props: {
    applicationDetails: Object,
    versionList: Array,
    versions: Array,
    noVersion: Boolean,
    noRequirement: Boolean,
    savedForm: Object,
  },
  data() {
    return {
      applicationSetup: {},
      phpVersions: [],
      loadingVersion: false,
    };
  },
  setup() {
    const selectedWordPressPhpVersion = ref('');

    const { fetchSelectedWordpressCompatibility } = useWordpressStore();

    const { selectedWordpressCompatibility } = storeToRefs(useWordpressStore());

    const { t } = useGlobals();

    const applicationUpdates = computed(() => [
      {
        title: t('Turn off automatic application updates'),
        id: 0,
      },
      {
        title: t('Update only to minor version'),
        id: 1,
      },
      {
        title: t('Always update to latest available version'),
        id: 2,
      },
    ]);

    const {
      values: applicationUpdateValues,
      setActiveValue: setApplicationUpdates,
      activeValueId: selectedAplicationUpdateOption,
    } = useHRadio(applicationUpdates, {
      defaultValue: 1,
    });

    return {
      fetchSelectedWordpressCompatibility,
      selectedWordpressCompatibility,
      selectedWordPressPhpVersion,
      setApplicationUpdates,
      selectedAplicationUpdateOption,
      applicationUpdateValues,
    };
  },
  async mounted() {
    if (this.noVersion) return;
    this.phpVersions = await this.loadPhpVersions();

    if (this.savedForm?.autoup) {
      setApplicationUpdates(this.savedForm.autoup);
    }

    if (this.isVersionedWordPressApplication) {
      this.fetchSelectedWordpressCompatibility({
        wpVersion: this.applicationSetup.values?.version,
        directory: this.directory,
      });
    }
  },
  emits: ['on-change'],
  computed: {
    currentPhpVersion() {
      if (this.isVersionedWordPressApplication) {
        return (
          this.selectedWordPressPhpVersion || this.firstNonBetaSupportVersion
        );
      }

      return this.getFullPhpVersion;
    },
    isBetaPhpWordPressVersion() {
      return (
        this.wordPressPhpCompatibilities[this.selectedWordPressPhpVersion] ===
        'beta_support'
      );
    },
    wordPressPhpCompatibilities() {
      return this.selectedWordpressCompatibility?.allPhpCompatibilities || {};
    },
    wordPressPhpCompatiblityList() {
      const phpVersionValues = Object.keys(this.wordPressPhpCompatibilities);

      return phpVersionValues.map((version) => ({
        label: `PHP ${version}`,
        value: version,
      }));
    },
    isVersionedWordPressApplication() {
      return this.applicationDetails.appid === 'wordpress' && !this.noVersion;
    },
    directory() {
      return this.$route.params.directory || '';
    },
    isLoaderDisabled() {
      return (
        !this.noVersion && (!this.versionList.length || this.loadingVersion)
      );
    },
    versionsSelect() {
      const versionOptions =
        this.versionList
          .find(({ appid }) => appid === this.applicationDetails.appid)
          ?.versionList.map((version, index) => ({
            label:
              index === 0 ? `${version} (${this.$t('Recommended')})` : version,
            value: version,
          })) || [];

      return {
        type: 'select',
        value: versionOptions[0]?.value,
        values: versionOptions,
      };
    },
    phpVersionChanged() {
      return (
        this.applicationSetup.values?.phpVersion !== this.getFullPhpVersion
      );
    },
    language() {
      const languageOptions = this.optionsToArray(
        this.applicationDetails?.fields?.language?.options,
      );

      let initLanguageOptions = ['en_US', 'enUS', 'en'];
      if (this.savedForm) {
        initLanguageOptions = [this.savedForm.language];
      }

      const selectedLanguage = languageOptions
        ? languageOptions.filter((language) =>
            initLanguageOptions.includes(language.value),
          )[0]
        : null;

      return {
        type: 'select',
        maxHeight: 'auto',
        value: selectedLanguage?.value,
        noTrans: true,
        values: languageOptions,
        extra: false,
      };
    },

    firstNonBetaSupportVersion() {
      const nonBetaSupportVersions = Object.entries(
        this.wordPressPhpCompatibilities,
      ).filter(([, status]) => status !== 'beta_support');

      return nonBetaSupportVersions.length ? nonBetaSupportVersions[0][0] : '';
    },
    wordPressPhpVersionsSchema() {
      return {
        type: 'select',
        validation: {
          required: true,
        },
        disabled: this.wordPressPhpCompatiblityList.length === 0,
        value: this.firstNonBetaSupportVersion,
        values: this.wordPressPhpCompatiblityList,
      };
    },

    otherAppPhpVersionSchema() {
      const phpVersionsList = this.phpVersions
        ? this.optionsToArray({
            ...this.phpVersions.supported,
            ...this.phpVersions.unsupported,
          }).sort((curr, next) => next.value - curr.value)
        : [];

      const preselectedVersionIndex = phpVersionsList.findIndex(
        (version) => version.value === this.getPhpConfigState.phpVersion,
      );

      phpVersionsList[preselectedVersionIndex] = {
        label: `PHP ${this.getFullPhpVersion}`,
        value: this.getFullPhpVersion,
      };

      return {
        type: 'select',
        validation: {
          required: true,
        },
        value: phpVersionsList.length ? this.getFullPhpVersion : '',
        values: phpVersionsList,
      };
    },

    isPhpVersionChangeRequired() {
      if (this.isVersionedWordPressApplication) {
        return this.phpVersionChanged;
      }

      return (
        !this.getIsCompatiblePhpVersion(this.getFullPhpVersion) ||
        !this.isPhpVersionLowEnough(this.getFullPhpVersion)
      );
    },
    ...mapGetters([
      'currentOrderDetails',
      'getPhpConfigState',
      'getFullPhpVersion',
    ]),
  },
  methods: {
    onApplicationUpdateSelect(id) {
      this.setApplicationUpdates(id);
      this.applicationSetup.values.autoup = id;

      this.$emit('on-change', {
        applicationSetup: this.applicationSetup.values,
      });
    },

    async onFormChange({ values }) {
      this.applicationSetup.values = {
        ...values,
        autoup: this.selectedAplicationUpdateOption,
      };

      this.$emit('on-change', {
        applicationSetup: this.applicationSetup.values,
      });

      if (!this.noVersion) {
        this.validatePhpVersion();
      }
      if (!this.noRequirement) {
        this.validateDiskSpace();
      }
    },

    handleAppVersion({ value }) {
      // Only needed when handling wordpress app versions
      if (!this.isVersionedWordPressApplication) return;

      this.fetchSelectedWordpressCompatibility({
        wpVersion: value,
        directory: this.directory,
      });
    },
    handleAppPhpVersion({ value }) {
      // Only needed when handling wordpress app php version
      if (!this.isVersionedWordPressApplication) return;

      this.selectedWordPressPhpVersion = value;
    },
    validatePhpVersion() {
      const isValid =
        this.getIsCompatiblePhpVersion() && this.isPhpVersionLowEnough();

      this.$emit('on-change', {
        phpVersionValid: isValid,
        phpVersionChangeRequired: this.isPhpVersionChangeRequired,
      });
    },
    validateDiskSpace() {
      this.$emit('on-change', {
        diskSpaceValid: this.isDiskSpaceEnough(),
      });
    },
    getIsCompatiblePhpVersion(version) {
      const reqVersion = this.applicationDetails?.requirementPhpVersionMinimum;

      if (this.isVersionedWordPressApplication) {
        return true; // All wordpress app php selections are valid, only some of them are beta
      }

      return (
        versionCompare(
          reqVersion,
          version || this.applicationSetup.values?.phpVersion,
        ) < 0
      );
    },
    isPhpVersionLowEnough(version) {
      const reqVersion = this.applicationDetails?.requirementPhpVersionMaximum;
      if (!reqVersion) return true;

      return (
        versionCompare(
          reqVersion,
          version || this.applicationSetup.values?.phpVersion,
        ) > 0
      );
    },
    isDiskSpaceEnough() {
      const limit = this.currentOrderDetails.usage.storage.limit;
      const value = this.currentOrderDetails.usage.storage.value;
      const valueAfterInstall =
        parseInt(this.applicationDetails.requirementDiskspace) + value;

      return valueAfterInstall < limit;
    },
    optionsToArray(object) {
      if (!object) return;

      return Object.keys(object).map((key) => ({
        label: object[key],
        value: key,
      }));
    },
    ...mapActions(['loadPhpVersions']),
  },
};
</script>

<style lang="scss" scoped>
.requirements-title {
  margin: 16px 0;
  font-weight: bold;
}

.title {
  padding-top: 8px;
  color: var(--gray);
}

.field {
  &--half {
    display: inline-block;
    width: 100%;
    vertical-align: text-bottom;

    @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
      width: 50%;
      &:nth-of-type(odd) {
        padding-right: 8px;
      }
      &:nth-of-type(even) {
        padding-left: 8px;
      }
    }
  }
}

@media (min-width: 992px) {
  :deep(.field-path).field {
    display: block;
    flex: 0 0 80%;
    max-width: 80%;
    width: 80%;
    &--protocol,
    &--directory {
      padding-left: 0;
      padding-right: 0;
    }
    &--protocol {
      flex: 0 0 20%;
      max-width: 20%;
      width: 20%;
      .form-field {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
        height: 48px;

        input {
          height: 45px;
        }
      }
    }
    &--directory {
      .form-field {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
:deep(.inline-field .label-holder) {
  z-index: 1;
}
:deep(.updates .form-field-holder) {
  margin-top: 8px;
}

:deep(.form-field-checkbox__holder) {
  min-height: 32px;
  cursor: pointer;
}
</style>
