<script setup lang="ts">
import { onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';

import { Route } from '@/types';
import {
  errorBoundaryHandler,
  ERROR_HANDLER_VARIANT,
} from '@/utils/helpers/errorBoundaryHelpers';

const router = useRouter();

onErrorCaptured((error, instance, info) =>
  errorBoundaryHandler({
    error,
    instance,
    info,
    variant: ERROR_HANDLER_VARIANT.PAGE,
    onFailToRender: () => router.replace({ name: Route.Base.SERVER_DOWN }),
  }),
);
</script>
<template>
  <slot />
</template>
