import { toUnicode } from 'punycode';

import { computed } from 'vue';

import { useDeveloperToolsWebsites } from '@/composables/developerTools/useDeveloperToolsWebsites';
import { useGlobals } from '@/composables/useGlobals';
import { reportRepo } from '@/repositories';
import whReportRepo from '@/repositories/whApi/whReportRepo';
import { router } from '@/router';
import { useCreateReportStore } from '@/stores/developerTools/createReportStore';
import { useWebsiteReportsStore } from '@/stores/developerTools/websiteReportsStore';
import { ScheduleTypeParam, ScheduleType, Route } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

export const useWebsiteReportActions = () => {
  const { t, toastr } = useGlobals();
  const createReportStore = useCreateReportStore();
  const websiteReportsStore = useWebsiteReportsStore();
  const { enabledDeveloperToolsWebsites } = useDeveloperToolsWebsites();

  const IS_WEBSITE_ADDRESS_ENABLED = false;

  const formattedWebsitesList = computed(() => {
    if (!enabledDeveloperToolsWebsites.value.length) {
      return [{ label: '', value: '' }];
    }

    return enabledDeveloperToolsWebsites.value.map(({ website }) => ({
      label: toUnicode(website),
      value: website,
    }));
  });

  const fetchReportFile = (key: string) => {
    const fileLink = `${process.env.VITE_API_REST}/v3/report/website/download?document_key=${key}`;
    window.open(fileLink, '_blank');
  };

  const SCHEDULE_TYPE_PARAM_MAP = {
    [ScheduleType.MONTHLY]: ScheduleTypeParam.DAY_OF_MONTH,
    [ScheduleType.WEEKLY]: ScheduleTypeParam.DAY_OF_WEEK,
    [ScheduleType.NONE]: null,
  };

  const SCHEDULE_TYPE_MAP = {
    [ScheduleTypeParam.DAY_OF_WEEK]: ScheduleType.WEEKLY,
    [ScheduleTypeParam.DAY_OF_MONTH]: ScheduleType.MONTHLY,
    default: ScheduleType.NONE,
  };

  const getScheduleTypeParamFromOption = (type: ScheduleType) =>
    SCHEDULE_TYPE_PARAM_MAP[type];

  const getScheduleTypeOptionFromParam = (type: string | number) =>
    mapKeyValue(SCHEDULE_TYPE_MAP, type);

  const createReport = async (isDownloadFlow = false) => {
    const baseParams = createReportStore.getReportBaseParams(true);
    const headers = createReportStore.reportRequestHeaders;
    const deliverToEmail = createReportStore.get('deliverToEmail') || null;

    if (!headers || !baseParams) return false;

    const params = {
      ...baseParams,
      deliverToEmail: isDownloadFlow ? null : deliverToEmail,
    };

    const [_, err] = await reportRepo.postGenerateReport({
      params,
      headers,
    });

    if (err) return false;

    return true;
  };

  const saveTemplate = async (name: string) => {
    const baseParams = createReportStore.getReportBaseParams();
    const scheduleParams = createReportStore.scheduleParams;

    if (!baseParams) return null;

    const params = {
      name,
      ...baseParams,
      ...scheduleParams,
    };

    const [_, error] = await reportRepo.postTemplate({
      params,
    });

    return error;
  };

  const editTemplate = async (id: number, name: string) => {
    const baseParams = createReportStore.getReportBaseParams();
    const scheduleParams = createReportStore.scheduleParams;

    if (!baseParams) return null;

    const params = {
      name,
      ...baseParams,
      ...scheduleParams,
    };

    const [_, error] = await reportRepo.editTemplate({
      id,
      params,
    });

    return error;
  };

  const sendReportToClient = async (email: string) => {
    createReportStore.set('deliverToEmail', email);
    const isSuccessful = await createReport();
    if (!isSuccessful) return false;
    createReportStore.$reset();

    return true;
  };

  const scheduleReport = async (
    scheduleType: ScheduleTypeParam,
    scheduleValue: string | null,
    scheduleHour: string,
  ) => {
    const scheduleParams = {
      scheduleType,
      scheduleValue,
      scheduleHour,
    };

    const baseParams = createReportStore.getReportBaseParams(true);
    const deliverToEmail = createReportStore.get('deliverToEmail') || null;
    const headers = createReportStore.reportRequestHeaders;
    if (!headers || !baseParams) return false;

    const [_, err] = await reportRepo.postSchedule({
      params: { ...baseParams, ...scheduleParams, deliverToEmail },
      headers,
    });

    if (err) return false;

    return true;
  };

  const editSchedule = async (
    id: number,
    scheduleType: ScheduleTypeParam,
    scheduleValue: number | null,
    scheduleHour: number,
  ) => {
    const scheduleParams = {
      scheduleType,
      scheduleValue,
      scheduleHour,
    };

    const baseParams = createReportStore.getReportBaseParams(true);
    const deliverToEmail = createReportStore.get('deliverToEmail') || null;
    if (!baseParams) return false;

    const [_, err] = await whReportRepo.editSchedule({
      id,
      data: {
        ...baseParams,
        ...scheduleParams,
        deliverToEmail,
        whiteLabel: null,
      },
    });

    if (err) return false;

    return true;
  };

  const displayReportGeneratingToastr = (domain: string) => {
    toastr.s(
      t('v2.reports.generating.report.for.website', {
        domain: toUnicode(domain),
      }),
      {
        text: t('v2.reports.generating.report.for.website.subtitle', {
          number: 2,
        }),
        timeout: 15000,
        icon: 'icon-timelapse',
        iconType: 'primary',
      },
    );
  };

  const showSentViaEmailToastrAndRefetchData = ({
    domain,
    email,
  }: {
    domain: string;
    email: string;
  }) => {
    websiteReportsStore.fetchReportList();

    toastr.s(
      t('v2.reports.report.sent.to.email', {
        domain: toUnicode(domain),
        email,
      }),
    );
  };

  const showGeneratedToastrAndRefetchData = ({
    domain,
    documentKey,
  }: {
    domain: string;
    documentKey: string;
  }) => {
    websiteReportsStore.fetchReportList();

    toastr.s(
      t('v2.reports.report.downloaded.successfully', {
        domain: toUnicode(domain),
      }),
      {
        timeout: 15000,
        forceTimeout: true,
        buttons: [
          {
            text: t('v2.preview'),
            props: {
              primary: true,
              text: true,
              'data-qa': 'hpanel_tracking-websites-preview-pdf',
            },
            callback: () => fetchReportFile(documentKey),
          },
        ],
      },
    );
  };

  const exitCreateReportFlow = () => {
    createReportStore.$reset();
    router.push({ name: Route.DeveloperTools.REPORTS });
  };

  return {
    IS_WEBSITE_ADDRESS_ENABLED,
    formattedWebsitesList,
    getScheduleTypeParamFromOption,
    getScheduleTypeOptionFromParam,
    fetchReportFile,
    createReport,
    scheduleReport,
    editSchedule,
    sendReportToClient,
    displayReportGeneratingToastr,
    showSentViaEmailToastrAndRefetchData,
    showGeneratedToastrAndRefetchData,
    exitCreateReportFlow,
    saveTemplate,
    editTemplate,
  };
};
