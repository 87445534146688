import { hDomainsRepo } from '@/repositories';

export default {
  state: {
    domainsAdditionalDetails: {},
    domainLookupInfo: null,
  },
  mutations: {
    setDomainsAdditionalDetails(state, { domainName, requirements }) {
      state.domainsAdditionalDetails = {
        ...state.domainsAdditionalDetails,
        [domainName]: Object.values(requirements),
      };
    },
    SET_DOMAIN_LOOKUP_INFO: (state, payload) => {
      state.domainLookupInfo = payload;
    },
  },
  getters: {
    getDomainsAdditionalDetails: (state) => (domain) =>
      state.domainsAdditionalDetails[domain] || [],
    getDomainLookupInfo(state) {
      return state.domainLookupInfo;
    },
  },
  actions: {
    async fetchDomainRegisterAdditionalDetails({ commit }, domainName) {
      const [{ data }] =
        await hDomainsRepo.getDomainRegisterAdditionalRequirements(domainName);

      commit('setDomainsAdditionalDetails', { domainName, requirements: data });
    },
    async fetchLookupDomain({ commit }, domain) {
      const [{ data }, err] = await hDomainsRepo.lookupDomain(domain);

      commit('SET_DOMAIN_LOOKUP_INFO', err ? null : data);

      return [{ data }, err];
    },
  },
};
