export const mapCSCoupon = (userCoupon: string) => {
  const coupon = userCoupon.toUpperCase();

  const CS_COUPON_REGEX = /(CS)(.*)(F|T2|T4|L|OT)/;

  if (!CS_COUPON_REGEX.test(coupon)) {
    return { coupon };
  }

  const [, start, agentId, type] = CS_COUPON_REGEX.exec(coupon) as string[];

  return { coupon: `${start}${type}`, agentId };
};
