import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'advanced',
    name: Route.HostingAdvanced.ADVANCED,
    meta: {
      title: 'Advanced',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#advanced' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'cron-jobs',
        name: Route.HostingAdvanced.CRON_JOBS,
        meta: {
          title: 'Cron Jobs',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/CronJobs.vue'),
      },
      {
        path: 'ssh-access',
        name: Route.HostingAdvanced.SSH_ACCESS,
        meta: {
          title: 'SSH Access',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/SshAccess.vue'),
      },

      {
        path: 'git',
        name: Route.HostingAdvanced.GIT,
        meta: {
          title: 'GIT',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/Git.vue'),
      },
      {
        path: 'cache-manager',
        name: Route.HostingAdvanced.CACHE_MANAGER,
        meta: {
          title: 'Cache Manager',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/CacheManager.vue'),
      },
      {
        path: 'php-info',
        name: Route.HostingAdvanced.PHP_INFO,
        meta: {
          title: 'PHP info',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/PhpInfo.vue'),
      },
      {
        path: 'php-configuration',
        name: Route.HostingAdvanced.PHP_CONFIGURATION,
        meta: {
          title: 'PHP Configuration',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Advanced/PhpConfiguration.vue'),
      },
      {
        path: 'dns-zone-editor',
        name: Route.HostingAdvanced.DNS_ZONE_EDITOR,
        meta: {
          title: 'DNS Zone Editor',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/DnsZoneEditor.vue'),
      },
      {
        path: 'password-protect-directories',
        name: Route.HostingAdvanced.PASSWORD_PROTECT_DIRECTORIES,
        meta: {
          title: 'Password Protect Directories',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Advanced/PasswordProtectedDirectories.vue'),
      },
      {
        path: 'ip-manager',
        name: Route.HostingAdvanced.IP_MANAGER,
        meta: {
          title: 'IP Manager',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/IpManager.vue'),
      },
      {
        path: 'hotlink-protection',
        name: Route.HostingAdvanced.HOTLINK_PROTECTION,
        meta: {
          title: 'Hotlink Protection',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Advanced/HotlinkProtection.vue'),
      },
      {
        path: 'folder-index-manager',
        name: Route.HostingAdvanced.FOLDER_INDEX_MANAGER,
        meta: {
          title: 'Folder Index Manager',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Advanced/FolderIndexManager.vue'),
      },
      {
        path: 'fix-file-ownership',
        name: Route.HostingAdvanced.FIX_FILE_OWNERSHIP,
        meta: {
          title: 'Fix File Ownership',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Advanced/FixFileOwnership.vue'),
      },
      {
        path: 'activity-log',
        name: Route.HostingAdvanced.ACTIVITY_LOG,
        meta: {
          title: 'Activity Log',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingAdvanced.ADVANCED,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Advanced/ActivityLog.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
