<script setup lang="ts">
import { ref } from 'vue';

import { razorpayRepo } from '@/repositories';

type Props = {
  method: any;
  revealIcon?: Boolean;
};

const props = defineProps<Props>();

const dots = ref(4);
const loading = ref(false);
const lastFourDigits = ref(null);

const seeDetails = async () => {
  if (lastFourDigits.value) return;

  loading.value = true;
  dots.value = 1;
  const intervalId = setInterval(load, 300);

  const [{ data }, err] = await razorpayRepo.getRazorpayCardLastFourDigits(
    props.method.id,
  );

  if (data) lastFourDigits.value = data.digits;

  clearInterval(intervalId);
  loading.value = false;
  if (err) clearDetails();
};

const clearDetails = () => {
  dots.value = 4;
  lastFourDigits.value = null;
};

const load = () => {
  if (dots.value === 4) dots.value = 0;
  dots.value += 1;
};
</script>

<template>
  <div class="razor-card">
    <div class="razor-card__number">
      <span class="text-bold-2">•••• •••• ••••</span>
      <div v-if="lastFourDigits" class="text-bold-2 razor-card__last-digits">
        {{ lastFourDigits }}
      </div>
      <div v-else class="text-bold-2">
        <span v-for="dot in dots" :key="dot">•</span>
      </div>
    </div>

    <HpIcon
      v-if="!lastFourDigits && revealIcon"
      icon="icon-visibility"
      static-view-box
      class="razor-card__visability"
      @click="seeDetails"
    />
    <HpIcon
      v-if="lastFourDigits && revealIcon"
      icon="icon-visibility-off"
      static-view-box
      class="razor-card__visability"
      @click="clearDetails"
    />

    <p
      v-if="!lastFourDigits && !revealIcon"
      v-trans
      class="razor-card__reveal text-bold-2"
      @click="seeDetails"
    >
      Reveal last digits
    </p>
  </div>
</template>

<style lang="scss" scoped>
.razor-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__number {
    display: flex;
    div {
      min-width: 34px;
      margin-left: 4px;
    }
  }

  &__visability {
    margin: 0 8px 0 4px;
    cursor: pointer;
    fill: var(--primary);
    &:hover {
      fill: var(--primary-dark);
    }
  }

  &__reveal {
    color: var(--primary);
    border-bottom: 1px solid none;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      opacity: 0;
      height: 1px;
      background: var(--primary);
      transition: opacity 0.1s;
    }

    &:hover::after {
      opacity: 100%;
    }
  }
}
</style>
