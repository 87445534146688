import type { ModalObject } from '@/types';

export default [
  {
    name: 'RenewModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'RenewModalV2',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'UpgradeModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'UpgradeModalV2',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'PurchaseModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'DomainPurchaseModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'MailboxPurchaseModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'OneTimePurchaseModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'PurchaseAddonModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'PaymentProcessingModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
  },
  {
    name: 'DeveloperToolsPurchaseModal',
    type: 'HModal',
    subtype: 'Purchase',
    closingType: 'payment',
    props: {
      hideX: true,
    },
  },
] as ModalObject[];
