const w = window;
const ic = w.Intercom;

export let launchIntercom;

if (typeof ic === 'function') {
  ic('reattach_activator');
  ic('update', w.intercomSettings);
} else {
  const d = document;
  const i = function () {
    // eslint-disable-next-line prefer-rest-params
    i.c(arguments);
  };
  i.q = [];
  i.c = function (args) {
    i.q.push(args);
  };
  w.Intercom = i;
  launchIntercom = function () {
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/' + 'e5dnas5h';
    const x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };
}
