import type { ModalObject } from '@/types';

export default [
  {
    name: 'DCSelectionModal',
    type: 'HModalFancy',
    subtype: 'Onboarding',
    props: {
      title: 'Change Your Server Location',
      imgSrc: 'modalFancy/map.svg',
    },
  },
  {
    name: 'CpanelLanguageModal',
    type: 'HModalFancy',
    subtype: 'Onboarding',
    props: {
      title: 'Change cPanel Language',
      imgSrc: 'modalFancy/language.svg',
    },
  },
  {
    name: 'RequiredNiagahosterOnboardingVideoModal',
    type: 'HModal',
    props: {
      hideX: true,
      persistent: true,
      xl: true,
    },
  },
  {
    name: 'FreeDomainListModal',
    type: 'HModal',
  },
] as ModalObject[];
