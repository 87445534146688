import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { toTitleCase } from '@/utils/helpers';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  mixins: [modalsMixin],
  methods: {
    fullOrderTitle(orderTitle, hostname) {
      return hostname ? `${orderTitle} - ${hostname}` : orderTitle;
    },
    setTitle(type) {
      return type === 'renew'
        ? `${toTitleCase(type)} your {order}`
        : `${toTitleCase(type)} to {order_title} plan`;
    },
    hostingRenewOrUpgradeModal({ orderTitle, onSuccess, additionalData }) {
      this.$openModal({
        component: { RenewModal },
        data: {
          title: this.$t('Renew your {service}', {
            service: orderTitle,
          }),
          subtitle: this.$t(
            'Review your selected invoice and proceed to checkout',
          ),
          itemPriceId: additionalData.planName,
          subscriptionId: additionalData.id,
          reactivate: additionalData.reactivate,
          redirect: `${window.origin}`,
          onSuccess: () => {
            this.$store.dispatch('fetchHostingOrders');
            onSuccess?.();
          },
        },
        steps: [
          {
            hideX: true,
          },
        ],
      });
    },
  },
};
