import { groupBy, isEmpty } from 'lodash';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { useDomain } from '@/composables';
import { useWordpressStore } from '@/stores';
import type { IWordpressCompatibility, IWordpressComponent } from '@/types';
import { Status, WordPress } from '@/types';
import { toASCII } from '@/utils/helpers';
import versionCompare from '@/utils/services/versionCompareService';

const INSECURE_PHP_VERSION = '5.5';

enum ComponentCategory {
  'analytics' = 'Analytics Plugins',
  'caching_and_performance' = 'Caching Plugins',
  'page_builders' = 'Page Builder',
  'security' = 'Security Plugins',
  'social' = 'Social Plugins',
}

export const useWordpressCompatibility = ({
  domain,
  directory,
}: {
  domain?: string;
  directory?: string;
} = {}) => {
  const route = useRoute();
  const store = useStore();
  const { domain: currentDomain, domainToManage } = useDomain();
  const wordpressStore = useWordpressStore();

  const isLoadingCompatibility = ref(false);
  const isLoadingComponentsCompatibility = ref(false);

  const wordpressComponentsCompatibility = computed<IWordpressComponent[] | []>(
    () => wordpressStore?.wordpressComponentsCompatibility || [],
  );

  const wordpressComponentsCompatibilityGrouped = computed(
    () =>
      groupBy(
        filterSingleComponentsInCategory(
          filterActiveComponents(wordpressComponentsCompatibility.value),
        ),
        (component) => getCategoryName(component.category),
      ) || {},
  );

  const isComponentCompatibilityIssuesFound = computed(
    () => !isEmpty(wordpressComponentsCompatibilityGrouped.value),
  );

  const wordpressCompatibility = computed<IWordpressCompatibility | null>(
    () => {
      const wordPressUpdateVersion =
        store.getters.getHostingWordPressLastMinorUpdate;

      const additionalWpUpdate = wordPressUpdateVersion
        ? { action: 'upgrade', version: wordPressUpdateVersion }
        : null;

      if (!wordpressStore?.wordpressCompatibility) return null;

      const wordPressCompatibilityWithMinorUpdates = {
        ...wordpressStore?.wordpressCompatibility,
        suggestedWpAction:
          wordpressStore?.wordpressCompatibility?.suggestedWpAction ||
          additionalWpUpdate,
      };

      return wordPressCompatibilityWithMinorUpdates;
    },
  );

  const compatibilityStatus = computed(
    () => wordpressCompatibility.value?.compatibility || '',
  );

  const isPhpActionAvailable = computed(
    () => !!wordpressCompatibility.value?.suggestedPhpAction?.action,
  );

  const isWordpressActionAvailable = computed(
    () => !!wordpressCompatibility.value?.suggestedWpAction?.action,
  );

  const phpVersion = computed(
    () => wordpressCompatibility.value?.phpVersion || '',
  );

  const availableWpActions = computed(
    () => wordpressCompatibility.value?.availableWpActions || [],
  );

  const wordpressVersion = computed(
    () => wordpressCompatibility.value?.wpVersion || '',
  );

  const suggestedWordpressAction = computed(
    () => wordpressCompatibility.value?.suggestedWpAction?.action || '',
  );

  const availablePhpActions = computed(
    () => wordpressCompatibility.value?.availablePhpActions || [],
  );

  const suggestedPhpAction = computed(
    () => wordpressCompatibility.value?.suggestedPhpAction?.action || '',
  );

  const suggestedPhpVersion = computed(
    () => wordpressCompatibility.value?.suggestedPhpAction?.version || '',
  );

  const suggestedWordpressVersion = computed(
    () => wordpressCompatibility.value?.suggestedWpAction?.version || '',
  );

  const isVersionIncompatible = computed(
    () =>
      compatibilityStatus.value === WordPress.CompatibilityStatus.INCOMPATIBLE,
  );

  const isVersionCompatible = computed(() => {
    const compatibleStatuses = [
      WordPress.CompatibilityStatus.COMPATIBLE,
      WordPress.CompatibilityStatus.COMPATIBLE_WITH_EXCEPTIONS,
    ] as string[];

    return compatibleStatuses.includes(compatibilityStatus.value);
  });

  const selectedDomain = computed(
    () => domain || domainToManage.value || currentDomain.value || '',
  );

  const selectedDirectory = computed(
    () => directory || (route.params.directory as string) || '',
  );

  const fetchWordpressCompatibility = async (domain?: string) => {
    if (isLoadingCompatibility.value) {
      return;
    }

    isLoadingCompatibility.value = true;

    await wordpressStore.fetchWordpressCompatibility(
      selectedDirectory.value,
      domain || selectedDomain.value,
    );

    isLoadingCompatibility.value = false;
  };

  const fetchWordpressComponentsCompatibility = async () => {
    if (isLoadingComponentsCompatibility.value) return;

    isLoadingComponentsCompatibility.value = true;
    await wordpressStore.fetchWordpressComponentsCompatibility(
      selectedDirectory.value,
      toASCII(selectedDomain.value),
    );

    isLoadingComponentsCompatibility.value = false;
  };

  const isMinimumCompatiblePhpVersion = computed(
    () => versionCompare(INSECURE_PHP_VERSION, phpVersion.value) >= 0,
  );

  const filterActiveComponents = (components: IWordpressComponent[] = []) =>
    components.filter((component) => component.status === Status.Active);

  const filterSingleComponentsInCategory = (
    components: IWordpressComponent[] = [],
  ) =>
    components.filter(
      (
        component: IWordpressComponent,
        _index: number,
        array: IWordpressComponent[],
      ) =>
        array.filter(
          (value: IWordpressComponent) => value.category === component.category,
        ).length > 1,
    );

  const getCategoryName = (category: string) => {
    if (!category) {
      return '';
    }

    return (
      (ComponentCategory as { [key: string]: string })[category] || category
    );
  };

  const getComponentsByCategory = (
    category: string,
    onlyActiveComponents: true,
  ) => {
    const componentsList = onlyActiveComponents
      ? filterActiveComponents(wordpressComponentsCompatibility.value)
      : wordpressComponentsCompatibility.value;

    return componentsList?.filter(
      (component) => component.category === category,
    );
  };

  const isComponentHasCompatibilityIssue = (
    slug: string,
    onlyActiveComponents: boolean,
  ) => {
    if (!wordpressComponentsCompatibility.value) {
      return false;
    }

    const componentCompatibility = wordpressComponentsCompatibility.value?.find(
      (component) => component.slug === slug,
    ) as IWordpressComponent;

    const componentsList = onlyActiveComponents
      ? filterActiveComponents(wordpressComponentsCompatibility.value)
      : wordpressComponentsCompatibility.value;

    const compatibilityConflictsCount = componentsList?.filter(
      (component) =>
        componentCompatibility?.category === component.category &&
        component.slug !== slug,
    )?.length;

    const compatibilityConflictsThreshold = onlyActiveComponents ? 1 : 0;

    return compatibilityConflictsCount >= compatibilityConflictsThreshold;
  };

  return {
    fetchWordpressCompatibility,
    fetchWordpressComponentsCompatibility,
    getComponentsByCategory,
    getCategoryName,
    compatibilityStatus,
    phpVersion,
    wordpressVersion,
    suggestedPhpVersion,
    suggestedWordpressVersion,
    suggestedPhpAction,
    isMinimumCompatiblePhpVersion,
    suggestedWordpressAction,
    availablePhpActions,
    availableWpActions,
    wordpressComponentsCompatibility,
    wordpressComponentsCompatibilityGrouped,
    isComponentCompatibilityIssuesFound,
    isLoadingCompatibility,
    isLoadingComponentsCompatibility,
    isPhpActionAvailable,
    isWordpressActionAvailable,
    isVersionCompatible,
    isVersionIncompatible,
    isComponentHasCompatibilityIssue,
  };
};
