<script lang="ts" setup>
import dayjs, { type ManipulateType } from 'dayjs';
import { computed } from 'vue';

import { toLocalTime } from '@/utils/helpers';
import { currencyService } from '@/utils/services';
import { getMonthlyPrice } from '@/utils/services/chargebeeCurrencyService';

type Props = {
  period?: number | null;
  periodUnit?: string | null;
  price?: number | null;
  isEmail?: boolean;
  customRenewalPriceSuffix?: string;
};

const props = defineProps<Props>();

const renewalPrice = computed(() => {
  const monthlyPrice = getMonthlyPrice(
    props.period,
    props.periodUnit,
    props.price,
  );

  return currencyService.format(monthlyPrice, {
    fixedCurrency: currencyService.symbol(),
    isChargebeePrice: true,
    monthly: false,
  });
});

const renewalDate = computed(() => {
  if (!props.period || !props.periodUnit) {
    return '';
  }

  return toLocalTime(
    dayjs()
      .add(props.period, props.periodUnit as ManipulateType)
      .toDate(),
  );
});

const renewalPriceSuffix = computed(() => {
  if (props.customRenewalPriceSuffix) {
    return props.customRenewalPriceSuffix;
  }

  const monthSuffix = '/mo';

  if (props.isEmail) {
    return `/account${monthSuffix}`;
  }

  return monthSuffix;
});
</script>

<template>
  <Trans
    tag="p"
    :translate-params="{
      // wrapping with span element to allow ignoring only date in visual tests
      renewalDate: `<span>${renewalDate}</span>`,
      renewalPrice,
    }"
    class="h-mb-16"
    data-qa="upgrade-modal-plan-renews-for"
  >
    Plan renews on {renewalDate} for {renewalPrice}
    <small>{{ renewalPriceSuffix }}</small>
  </Trans>
</template>
