import { useResourcesStore } from '@/stores';
import type { SubscriptionResourceType } from '@/types';
import { SubscriptionStatus } from '@/types';

export const useResources = () => {
  const resourceStore = useResourcesStore();

  const getMostRecentPendingResourceByTypes = (
    types: SubscriptionResourceType[],
  ) => {
    const filteredResources = resourceStore.resources.filter(
      (resource) =>
        types.includes(resource.type as SubscriptionResourceType) &&
        resource.state === SubscriptionStatus.PENDING_SETUP,
    );
    const mostRecentResource = filteredResources.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1,
    )?.[0];

    return mostRecentResource;
  };

  return {
    getMostRecentPendingResourceByTypes,
  };
};
