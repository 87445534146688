import type {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import store from '@/store/index';
import { Route, Email, HBilling } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const flockOrder = (domain: string) =>
  store.getters['emails/getPremiumMail'](Email.EmailProvider.TITAN, domain);

const showErrorAndRedirect = (
  next: NavigationGuardNext,
  to: RouteLocationNormalized,
) => {
  toastr.e(i18n.t('Order not found'));
  next({ name: Route.Email.EMAILS, query: to.query });
};

const getFlockDetails = (to: RouteLocationNormalized) => {
  const domain = to.params.domain as string;

  return store.getters['emails/getFlockDetails'](domain);
};

export const flockGuard: NavigationGuard = async (to, _from, next) => {
  if (to.params.order_id) return next();

  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const order = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.TITAN,
    to.params.domain,
  );

  if (!order) {
    return showErrorAndRedirect(next, to);
  }

  const [, err] = await store.dispatch('emails/flockOrderIndex', {
    orderId: order.id,
  });

  if (err) {
    return showErrorAndRedirect(next, to);
  }

  return next();
};

export const flockUpgradeGuard: NavigationGuard = (to, _from, next) => {
  const domain = to.params.domain as string;
  const flockDetails = getFlockDetails(to);
  const order = flockOrder(to.params.domain as string);
  const isExpired = order.status === HBilling.SubscriptionStatus.EXPIRED;
  const isNotUpgradable =
    (flockDetails?.flockOrder?.planType || flockDetails.emailPlan) ===
    Email.Plan.TITAN_ULTRA_EMAIL;

  if (isNotUpgradable || isExpired) {
    return next({
      name: Route.Email.TITAN_MANAGEMENT,
      params: { domain },
      query: { action: 'billing' },
    });
  }

  return next();
};

export const flockUpsellGuard: NavigationGuard = (to, from, next) => {
  const domain = to.params.domain as string;
  const flockDetails = getFlockDetails(to);

  const isTitanChosen =
    flockDetails && 'titanChosen' in flockDetails && flockDetails?.titanChosen;
  const isFreePlan =
    (flockDetails?.flockOrder?.planType || flockDetails.emailPlan) ===
    Email.Plan.TITAN_FREE;
  const showUpgradePage = !isTitanChosen && isFreePlan;

  if (showUpgradePage) {
    return next({
      name: Route.Email.TITAN_UPGRADE_PLAN,
      params: { domain },
      query: to.query,
    });
  }

  return next();
};
