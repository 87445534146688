import { ref, computed } from 'vue';

import { useWebsiteMonitoringStore } from '@/stores';
import type { IWebsiteMonitoring } from '@/types';

export const useWebsiteMonitoring = () => {
  const websiteMonitoringStore = useWebsiteMonitoringStore();

  const websitesToUpdate = ref<IWebsiteMonitoring[]>([]);

  const resetWebsitesToUpdate = () => {
    websitesToUpdate.value = [];
  };

  const onSelectWebsite = (website: IWebsiteMonitoring) => {
    if (getIsInUpdatesList(website)) removeFromUpdatesList(website);

    if (getIsMonitoredTheSameAsInitial(website)) return;

    websitesToUpdate.value.push(website);
  };

  const getIsInUpdatesList = ({ vhost }: IWebsiteMonitoring) =>
    websitesToUpdate.value.some((website) => website.vhost === vhost);

  const removeFromUpdatesList = ({ vhost }: IWebsiteMonitoring) => {
    websitesToUpdate.value = websitesToUpdate.value.filter(
      (website) => website.vhost !== vhost,
    );
  };

  const getIsMonitoredTheSameAsInitial = ({
    vhost,
    isMonitored,
  }: IWebsiteMonitoring) => {
    const initialWebsiteMonitoringObject =
      websiteMonitoringStore.findWebsiteByVhost(vhost);

    if (!initialWebsiteMonitoringObject) return false;

    return initialWebsiteMonitoringObject.isMonitored === isMonitored;
  };

  const updateMonitoringValues = async () => {
    if (!websitesToUpdate.value.length) return true;

    const monitoringPromises = websitesToUpdate.value.map(
      ({ vhost, isMonitored }) =>
        websiteMonitoringStore.changeMonitoringStatus(vhost, isMonitored),
    );
    const results = await Promise.allSettled(monitoringPromises);

    const isAllFailed = results.every(
      (result) => result.status === 'fulfilled' && result?.value?.[1] !== null,
    );

    return !isAllFailed;
  };

  const isAllWebsitesBeingDisabled = computed(() => {
    if (!websitesToUpdate.value.length) return false;

    return websitesToUpdate.value.every(({ isMonitored }) => !isMonitored);
  });

  return {
    websitesToUpdate,
    isAllWebsitesBeingDisabled,
    resetWebsitesToUpdate,
    updateMonitoringValues,
    onSelectWebsite,
    removeFromUpdatesList,
    getIsInUpdatesList,
    getIsMonitoredTheSameAsInitial,
  };
};
