<script lang="ts" setup>
import HImage from '@/components/HImage.vue';
import { useGlobals, useModal } from '@/composables';

const { t } = useGlobals();
const { closeModal } = useModal();
</script>

<template>
  <div class="transfer-ownership-success">
    <HImage class="h-mb-24" src="@/images/processing.svg" alt="" />
    <h2 class="text-heading-2">{{ t('Ownership Transfer In Progress') }}</h2>
    <p>
      {{
        t(
          'Action can take up to {number} minutes to complete. You can close this window. You will receive an email once the process is completed.',
          { number: 30 },
        )
      }}
    </p>
    <HButton class="h-mt-24" @click="closeModal">{{ t('Close') }}</HButton>
  </div>
</template>

<style lang="scss" scoped>
.transfer-ownership-success {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 48px;
    height: auto;
  }
}
</style>
