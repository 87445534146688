import { ref } from 'vue';

import { useGlobals } from '@/composables/useGlobals';
import { ActionToSchedule, Hotjar } from '@/types';

const LOCAL_STORAGE_KEY_SCHEDULED_ACTIONS = 'scheduled_actions';
const SCHEDULED_ACTION_TIMEOUT = 1000 * 10;

const updateLocalStorageWithScheduledItems = (
  scheduledActions: Record<string, number>,
) => {
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_SCHEDULED_ACTIONS,
    JSON.stringify(scheduledActions),
  );
};

const getScheduledItemsFromLocalStorage = () => {
  let scheduledActions = {};
  try {
    scheduledActions = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY_SCHEDULED_ACTIONS) || '{}',
    );
  } catch (e) {
    return {};
  }

  return scheduledActions as Record<string, number>;
};

export const useActionScheduling = () => {
  const { hj } = useGlobals();

  const ACTION_MAP = {
    [ActionToSchedule.REQUEST_WP_AI_CONTENT_FEEDBACK]: () =>
      hj(Hotjar.Onboarding.ONBOARDING_WP_AI_GENERATED_CONTENT),
  };
  const scheduledActions = ref(getScheduledItemsFromLocalStorage());

  const scheduleAction = ({
    actionId,
    timeoutInMs,
  }: {
    actionId: ActionToSchedule;
    timeoutInMs: number;
  }) => {
    scheduledActions.value[actionId] = Date.now() + timeoutInMs;
    updateLocalStorageWithScheduledItems(scheduledActions.value);
    setTimeout(() => onScheduledActionActivation(actionId), timeoutInMs);
  };

  const onScheduledActionActivation = (actionID: string) => {
    ACTION_MAP[actionID as ActionToSchedule]?.();
    delete scheduledActions.value[actionID];
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_SCHEDULED_ACTIONS,
      JSON.stringify(scheduledActions.value),
    );
  };
  const startScheduledActions = () => {
    Object.keys(scheduledActions.value).forEach((actionId: string) => {
      const timeoutInMs = scheduledActions.value[actionId] - Date.now();
      if (timeoutInMs > 0) {
        setTimeout(() => onScheduledActionActivation(actionId), timeoutInMs);

        return;
      }

      onScheduledActionActivation(actionId);
    });
  };

  const initScheduledActions = () => {
    setTimeout(() => startScheduledActions(), SCHEDULED_ACTION_TIMEOUT);
  };

  return {
    scheduleAction,
    initScheduledActions,
  };
};
