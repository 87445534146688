<template>
  <div class="form-field-hint" :class="hintPositionClass">
    <Trans v-if="hint" tag="span">
      {{ hint }}
    </Trans>
    <span v-if="limit" class="form-field-hint__limit">
      {{ value.length }} / {{ limit.value }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    hint: String,
    limit: Object,
  },
  computed: {
    hintPositionClass() {
      return { 'form-field-hint--right': !this.hint };
    },
  },
};
</script>
