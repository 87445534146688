<script setup lang="ts">
import HSkeletonLoader from '@/components/Loaders/HSkeletonLoader.vue';
import { useGlobals } from '@/composables';

defineProps<{
  mailboxCount: number;
  mailboxPrice: string;
  loading: boolean;
  isUpgrade?: boolean;
}>();

const { t } = useGlobals();
</script>

<template>
  <div class="mailbox-info">
    <div
      :class="['mailbox-count', { 'mailbox-count--without-title': !isUpgrade }]"
    >
      <p v-if="isUpgrade" class="text-body-2 mailbox-count__title">
        {{ t('Current number of accounts:') }}
      </p>
      <strong data-qa="accounts-count">
        {{ mailboxCount }}
        <span v-trans>
          {{ `${mailboxCount > 1 ? 'accounts' : 'account'}` }}
        </span>
      </strong>
    </div>
    <HLabelDeprecated gray no-arrow slim font-dark md no-trans>
      <HSkeletonLoader v-if="loading" :width="120" :height="24" rounded />
      <template v-else>
        <strong>{{ mailboxPrice }}</strong>
        <span v-trans>/account/mo</span>
      </template>
    </HLabelDeprecated>
  </div>
</template>

<style lang="scss" scoped>
.mailbox-info {
  display: flex;
  align-items: end;
  margin: 8px 0 16px 0;
  gap: 30px;
}

.mailbox-count {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;

  &__title {
    color: var(--gray);
  }

  &--without-title {
    align-self: center;
  }
}
</style>
