import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const selectText = (element) => {
  let range;
  if (document.selection) {
    range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    range = document.createRange();
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

export default function copy(element, successMessage, icon = 'i') {
  try {
    selectText(element);

    document.execCommand('copy');

    if (successMessage !== false) {
      toastr[icon](i18n.t(successMessage || 'Copied successfully'));
    }
  } catch (e) {
    // Do nothing
  }
}

export const copyString = async (
  string,
  message = 'Copied successfully',
  success = false,
  toastrParams = {},
) => {
  const attemptCopy = async () => {
    try {
      await navigator.clipboard.writeText(string);

      if (message !== false) {
        const copyMessage = i18n.t(message);

        success
          ? toastr.s(copyMessage, toastrParams)
          : toastr.i(copyMessage, toastrParams);
      }
    } catch (e) {
      errorLogger.logError(`Error in copyString function: ${e}`);
    }
  };

  if (!document.hasFocus()) {
    window.addEventListener('focus', attemptCopy, { once: true });
  } else {
    await attemptCopy();
  }
};

export const copyStringRequest = async (
  getCopyStringFunction,
  message = 'Copied successfully',
  isSuccess = false,
  toastrParams = {},
) => {
  try {
    const clipboardItem = new ClipboardItem({
      'text/plain': getCopyStringFunction(),
    });
    await navigator.clipboard.write([clipboardItem]);

    const copyMessage = i18n.t(message);

    if (message === false) return;
    isSuccess
      ? toastr.s(copyMessage, toastrParams)
      : toastr.i(copyMessage, toastrParams);
  } catch (error) {
    errorLogger.logError(`Error in copyStringRequest function: ${error}`);
  }
};
