import { SubscriptionName } from '@/types';
import { isAutorenewOn } from '@/utils/billingItemHelper';

export default function statusText(billingItem) {
  if (hasOrder(billingItem)) return orderStatuses(billingItem);
  if (hasSubscription(billingItem)) return subscriptionStatuses(billingItem);

  return '';
}

export const hasOrder = (billingItem) => !!billingItem.order;

export const hasSubscription = (billingItem) => !!billingItem.subscription;

export const hasPendingOrderSetup = (billingItem) =>
  billingItem.subscription?.resource?.state === 'pending_setup';

export const hasResource = (billingItem) =>
  !!billingItem.subscription?.resource;

export const hasDomainResource = (billingItem) => !!billingItem?.domainResource;

export const isResourseInProgress = (billingItem) => {
  if (!hasResource(billingItem)) return false;

  return [
    'updating',
    'suspending',
    'deleting',
    'unsuspending',
    'activating',
  ].includes(billingItem.subscription.resource.state);
};

export const didResourseFailed = (billingItem) => {
  if (!hasResource(billingItem)) return false;

  return ['failed', 'created'].includes(
    billingItem.subscription.resource.state,
  );
};

export const hasInvoice = (billingItem) =>
  !!billingItem.subscription?.invoices?.length;

export const hasUnpaidInvoice = (billingItem) => {
  if (!billingItem.subscription) return false;
  if (hasInvoice(billingItem)) {
    return billingItem.subscription?.invoices[0]?.status !== 'paid';
  }

  return (
    billingItem.subscription.dueInvoicesCount &&
    !isCanceled(billingItem) &&
    !hasPendingOrderSetup(billingItem)
  );
};
export const isCanceled = (billingItem) => {
  if (hasSubscription(billingItem)) {
    return billingItem.subscription.status === 'cancelled';
  }
  if (hasOrder(billingItem)) return billingItem.order.status === 'cancelled';

  return false;
};

export const isDomain = (billingItem) =>
  billingItem.subscription?.resource?.type === 'domain';

export const orderStatuses = (billingItem) => {
  if (billingItem.order.status === 'awaiting_payment') return 'Pending payment';
  if (billingItem.order.status === 'payment_initiated') {
    return 'Payment processing';
  }
};

export const getCanceledSubscriptionStatus = (billingItem) => {
  if (billingItem.subscription.canRenew) {
    return SubscriptionName.SERVICE_SUSPENDED;
  }

  return 'Service canceled';
};

export const subscriptionStatuses = (billingItem) => {
  if (
    !hasDomainResource(billingItem) &&
    isResourceExpired(billingItem) &&
    isResourceDomain(billingItem)
  ) {
    return null;
  }
  if (isRedemptionPeriod(billingItem)) return 'Redemption period';
  if (isGracePeriod(billingItem)) return 'Grace period';

  if (isCanceled(billingItem)) {
    return getCanceledSubscriptionStatus(billingItem);
  }

  if (hasPendingOrderSetup(billingItem) && isDomain(billingItem)) {
    return 'Pending registration';
  }

  if (hasPendingOrderSetup(billingItem)) return 'Pending setup';
  if (!isAutorenewOn(billingItem.subscription)) return 'Auto-renewal off';
  if (isResourceSuspendedAbuse(billingItem)) {
    return SubscriptionName.SERVICE_SUSPENDED;
  }
  if (billingItem.subscription.status === 'paused') return 'Service expired';
  if (hasUnpaidInvoice(billingItem)) return 'Payment failed';
  if (isResourseInProgress(billingItem)) return 'In progress';
  if (didResourseFailed(billingItem)) return 'Failed';
  if (
    isAutorenewOn(billingItem.subscription) &&
    !hasUnpaidInvoice(billingItem) &&
    !hasPendingOrderSetup(billingItem)
  ) {
    return 'Auto-renewal on';
  }
};

export const isResourceExpired = (billingItem) => {
  if (!hasResource(billingItem)) return false;

  return billingItem.subscription.resource.state === 'expired';
};

export const isResourceDomain = (billingItem) => {
  if (!hasResource(billingItem)) return false;

  return billingItem.subscription.resource.type === 'domain';
};

export const isGracePeriod = (billingItem) => {
  if (!hasDomainResource(billingItem)) return false;

  return billingItem.domainResource.additionalDetails.isGracePeriod;
};

export const isRedemptionPeriod = (billingItem) => {
  if (!hasDomainResource(billingItem)) return false;

  return billingItem.domainResource.additionalDetails.isRedemptionPeriod;
};

export const isResourceSuspendedAbuse = (billingItem) => {
  if (!hasResource(billingItem)) return false;

  return (
    billingItem.subscription.resource.state === 'suspended' &&
    billingItem.subscription.resource.reason === 'abuse'
  );
};
