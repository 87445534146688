export const sanctionedCountries = [
  { label: 'Cuba', value: 'CU' },
  { label: 'Iran', value: 'IR' },
  { label: 'North Korea', value: 'KP' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Syrian Arab Republic', value: 'SY' },
];
