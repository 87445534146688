<script setup lang="ts">
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { watch, ref, computed } from 'vue';

type Props = {
  modelValue: string | undefined | null;
  placeholder?: string;
};

interface Emits {
  (eventName: 'update:modelValue', payload: string): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const localSearch = ref(props.modelValue);

const formSchema = computed(
  () =>
    ({
      search: {
        type: 'text',
        label: props.placeholder || 'Search',
        value: '',
        icon: {
          left: 'icon-search',
        },
        custom: {
          hideOptionalLabel: true,
        },
      },
    } as Record<string, FieldSchema>),
);

const onFormChange = ({ values }: FormValues) => {
  const { search } = values;

  if (search === undefined) {
    return;
  }

  emit('update:modelValue', search);
};

watch(
  () => props.modelValue,
  (value) => {
    localSearch.value = value;
  },
);
</script>

<template>
  <HForm class="search" @on-change="onFormChange($event)">
    <HFormField class="h-mb-0" name="search" :schema="formSchema.search" />
  </HForm>
</template>

<style lang="scss" scoped>
.search {
  min-width: 255px;

  @media (max-width: 576px) {
    margin-top: 8px;
    margin-bottom: 24px;
    max-width: 255px;
    min-width: unset;
    width: 100%;
  }

  @media (max-width: 400px) {
    max-width: unset;
  }
}
</style>
