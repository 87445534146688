<script setup lang="ts">
//JSON-slugs-migrated
import { useGlobals } from '@/composables';

type Props = {
  noPadding?: boolean;
  isHidden?: boolean;
  closeButtonId?: string;
  mobileReverse?: boolean;
  closeText?: string;
};
type Emits = {
  close: [void];
};
const { t } = useGlobals();

defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  closeText: 'v2.close',
});
</script>

<template>
  <div
    class="modal-actions"
    :class="{
      'modal-actions--no-padding': noPadding,
      'modal-actions--mobile-reverse': mobileReverse,
    }"
  >
    <HButton
      v-if="!isHidden"
      :id="closeButtonId"
      v-qa-generate
      variant="text"
      @click="$emit('close')"
    >
      {{ t(closeText) }}
    </HButton>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  &--no-padding {
    padding: unset;
  }

  &--mobile-reverse {
    @media only screen and (max-width: 576px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
}
</style>
