import store from '@/store';
import { REFETCH_SERVICE_KEY, type RefetchServiceKey } from '@/types';

const REFETCH_KEY_MAP = {
  [REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS]: () =>
    store.dispatch('fetchHostingOrders'),
  default: () => {},
} as const;

export const addRefetch = (refetchKey: RefetchServiceKey) => {
  const refetchKeys = JSON.parse(
    localStorage.getItem(REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS) || '[]',
  );

  if (refetchKeys.includes(refetchKey)) {
    localStorage.removeItem(REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS);
    localStorage.setItem(
      REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS,
      JSON.stringify(refetchKeys),
    );

    return;
  }

  localStorage.removeItem(REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS);

  localStorage.setItem(
    REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS,
    JSON.stringify([...refetchKeys, refetchKey]),
  );
};

export const onStorageChange = (event: StorageEvent) => {
  const value = event.newValue;
  const key = event.key;

  if (key !== REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS) {
    return;
  }

  const valueParsed = JSON.parse(value || '[]') as string[];

  if (valueParsed.length === 0) {
    return;
  }

  const keyToRefetch = valueParsed.pop() as RefetchServiceKey;

  REFETCH_KEY_MAP[keyToRefetch || 'default']();

  localStorage.setItem(
    REFETCH_SERVICE_KEY.FETCH_HOSTING_ORDERS,
    JSON.stringify(valueParsed),
  );
};

export const initStorageListener = () => {
  window.addEventListener('storage', onStorageChange);
};
