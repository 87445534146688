/* eslint-disable */
import store from '@/store';
import { NIAGAHOSTER_CLIENT_ID_PREFIX } from '@/data/globalConstants';
import { useProfileStore } from '@/stores';

let hotjarLoaded = false;

const formatClientId = (clientId: string) => {
  return Number(clientId.replace(NIAGAHOSTER_CLIENT_ID_PREFIX, ''));
};

const getShouldTriggerForUser = () => {
  const isManuallyEnabled = window.localStorage.getItem('hotjarEnabled') === 'true';
  const profileStore = useProfileStore()

  if (isManuallyEnabled) {
    return true;
  }
  
  const clientId = profileStore.account?.id;
  return clientId && formatClientId(clientId) % 100 === 0; // run hotjar only for 1% of the users.
}

export const loadHotjar = (name: string, isHighOccurrence = false) => {
  if (
    !hotjarLoaded &&
    (!isHighOccurrence || getShouldTriggerForUser()) &&
    process.env.NODE_ENV === 'production' &&
    process.env.VITE_MODE !== 'staging'
  ) {
    (function (window, document, url, appendix) {
      window.hj =
        window.hj ||
        function () {
          (window.hj.q = window.hj.q || []).push(arguments);
        };
      window._hjSettings = { hjid: 1427583, hjsv: 6 };
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.async = true;
      script.src = url + window._hjSettings.hjid + appendix + window._hjSettings.hjsv;
      head.appendChild(script);
      script.onload = () => {
        hotjarLoaded = true;
        if(!name) {
          return
        };
        hotjar(name, isHighOccurrence);
      }
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
};

export const hotjar = (name: string, isHighOccurrence = false) => {
  if (!hotjarLoaded) {
    return loadHotjar(name, isHighOccurrence);
  }

  if (isHighOccurrence && !getShouldTriggerForUser()){
    return;
  }

  try {
    // eslint-disable-next-line
    window.hj("trigger", name);
  } catch {;
    //  do nothing
  }
};

