export const SessionStorage = {
  SALES_CALL_MODAL_SHOWN: 'salesCallModal',
  NAME_ADDRESS_MODAL_SHOWN: 'nameAddressModalShown',
  VERSION_UPDATER: 'versionUpdater',
} as const;

export type SessionStorageKey =
  (typeof SessionStorage)[keyof typeof SessionStorage];

export const LocalStorage = {
  WEBSITES_FILTER: 'websitesFilter',
} as const;
