import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import OverrideDirectoryModal from '@/components/Modals/HModal/OverrideDirectoryModal.vue';
import { useModal, useGlobals } from '@/composables';
import { BACKUP_FILE_LINK } from '@/data/globalConstants';
import { domainsRepo, migrationRepo, wordpressRepo } from '@/repositories';
import {
  useHostingMigrationsStore,
  useProfileStore,
  useMigrationsOnboardingStore,
} from '@/stores';
import type {
  IMigration,
  IRequestMigration,
  IMigrationDomainVHost,
  MigrationPlatform,
  UploadedFile,
} from '@/types';
import {
  Route,
  MigrationStatus,
  MIGRATION_PLATFORMS,
  Hosting,
  Header,
} from '@/types';
import { toUnicode } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

export const useMigrationRequest = (
  defaultMigrationRequest: IMigration | null = null,
) => {
  const { openModal } = useModal();
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const profileStore = useProfileStore();
  const { toastr, t } = useGlobals();

  const { uploadedFiles, whereToMigrateSelection } = storeToRefs(
    useMigrationsOnboardingStore(),
  );

  const migrationRequest = ref<IMigration | null>(defaultMigrationRequest);
  const migrationDomainsList = ref<IMigrationDomainVHost[]>([]);

  const fetchMigrations = async () => {
    store.dispatch('hostingMigrations/fetchExistingMigrations');
  };

  const existingMigrations = computed(() => {
    const { existingMigrations } = storeToRefs(useHostingMigrationsStore());

    return existingMigrations.value.sort(
      (a: IMigration, b: IMigration) =>
        new Date(b.updatedAt || '').getTime() -
        new Date(a.updatedAt || '').getTime(),
    );
  });

  const migrationsInProgress = computed(() =>
    existingMigrations.value.filter((existingMigration: IMigration) =>
      [
        MigrationStatus.PENDING,
        MigrationStatus.UPDATED,
        MigrationStatus.IN_PROGRESS,
      ].includes(existingMigration.status),
    ),
  );

  const uploadedFilesNames = computed(() =>
    uploadedFiles.value.map((file: UploadedFile) => file.name),
  );

  const postUploadedFilesToRestore = async () => {
    if (!uploadedFiles.value.length) return;
    const account = store.getters.getAccountByDomain(
      whereToMigrateSelection.value,
    );
    const requestHeaders = {
      [Header.USERNAME]: account?.username,
      [Header.ORDER_ID]: account?.referenceId,
      [Header.DOMAIN]: account?.domain,
    };
    const [, err] = await wordpressRepo.postExtractWebsiteFiles(
      {
        directory: (route.params.directory || '') as string,
        files: uploadedFilesNames.value,
      },
      requestHeaders,
    );
    if (err) {
      errorLogger.logError(
        new Error('Migrations: Error on website files extraction', {
          cause: err,
        }),
      );

      return;
    }
  };

  const postMigration = async (migrationRequestBody: IRequestMigration) => {
    const [data, submitMigrationError] = await migrationRepo.createMigration(
      migrationRequestBody,
      false,
    );

    if (!submitMigrationError) {
      toastr.s(t('Your migration request has been successfully submitted'));
    }

    if (
      // @ts-ignore //TODO Will have this here till we get update for hasync types
      submitMigrationError?.response?.status === 400 &&
      // @ts-ignore //TODO Will have this here till we get proper error code
      submitMigrationError?.error?.message?.includes('already installed')
    ) {
      openModal({
        component: { OverrideDirectoryModal },
        data: {
          onSuccess: async () => {
            await postMigration({
              ...migrationRequestBody,
              overwrite: 1,
            });

            await postUploadedFilesToRestore();

            router.push({ name: Route.Websites.MIGRATIONS_REQUESTS });
          },
        },
      });
    }

    return [data, submitMigrationError];
  };

  const getMigrationRequestByDomain = (domain: string) =>
    existingMigrations.value.find(
      (existingMigration: IMigration) => existingMigration.domain === domain,
    );

  const postEditMigration = async (migrationRequestBody: IRequestMigration) => {
    const formattedMigrationRequestBody = {
      id: migrationRequestBody.id,
      domain: migrationRequestBody.domain,
      status: MigrationStatus.UPLOADED_FILES,
      overwrite: 0,
      panel: 'wordpress',
      staff_id: 0,
      config: {
        username: '',
        password: '',
        wpUsername: '',
        wpPassword: '',
      },
      backup_link: BACKUP_FILE_LINK,
    };
    whereToMigrateSelection.value = migrationRequestBody.domain;
    const [, err] = await migrationRepo.editMigration(
      formattedMigrationRequestBody,
    );

    if (err) {
      errorLogger.logError(
        new Error('Migrations: Error on migration edit', {
          cause: err,
        }),
      );

      return;
    }

    await postUploadedFilesToRestore();
    await fetchMigrations();

    toastr.s(t('You have successfully submitted migration request'));
  };

  const isMigrationInProgress = computed(() => {
    if (!migrationRequest.value) return false;

    const { status } = migrationRequest.value;

    return [
      MigrationStatus.PENDING,
      MigrationStatus.IN_PROGRESS,
      MigrationStatus.UPDATED,
    ].includes(status);
  });

  const getDomainListByClientId = async () => {
    const [{ data }, err] = await domainsRepo.getDomainsListByClientId(
      profileStore.account?.id,
    );

    if (err) return;

    migrationDomainsList.value = data.filter(
      (domain) => domain.vhostType !== Hosting.Vhost.SUBDOMAIN,
    );
  };

  const getAccountOptionsForMigration = (
    selectedMigrationPlatform?: MigrationPlatform,
  ) => {
    if (selectedMigrationPlatform === MIGRATION_PLATFORMS.WORDPRESS) {
      return migrationDomainsList.value?.map(
        ({ vhost }: { vhost: string }) => ({
          label: `${toUnicode(vhost)}`,
          value: vhost,
        }),
      );
    }

    return (
      store.getters.getAllAccounts
        .filter(
          ({ isWebsiteBuilder }: { isWebsiteBuilder: string }) =>
            !isWebsiteBuilder,
        )
        .map(({ domain }: { domain: string }) => ({
          label: `${toUnicode(domain)}`,
          value: domain,
        })) || []
    );
  };

  return {
    migrationRequest,
    isMigrationInProgress,
    getDomainListByClientId,
    fetchMigrations,
    existingMigrations,
    migrationsInProgress,
    getMigrationRequestByDomain,
    postMigration,
    getAccountOptionsForMigration,
    postUploadedFilesToRestore,
    postEditMigration,
  };
};
