import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { Route } from '@/types';
import { proRouteMap } from '@/utils/helpers/proRouteHelper';

export const useProPanelRoutes = () => {
  const route = useRoute();

  const isProPanelRoute = computed(
    () => route.path.includes('hostinger-pro') || route.path.includes('pro'),
  );

  const isProPanelHelpPage = computed(
    () => route.name === Route.HostingerPro.GET_HELP,
  );
  const getProOrRegularRouteObject = (
    regularRoute: Route.RouteType,
    options = {},
  ) => {
    const proRoute = proRouteMap[regularRoute];
    const targetRouteName =
      isProPanelRoute.value && proRoute ? proRoute : regularRoute;

    return { name: targetRouteName, ...options };
  };

  return {
    isProPanelRoute,
    isProPanelHelpPage,
    getProOrRegularRouteObject,
  };
};
