<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import { useGlobals } from '@/composables';
import currency from '@/utils/services/currencyService';

const { t } = useGlobals();
const store = useStore();

onBeforeMount(async () => {
  await store.dispatch('paymentMethods/getCustomer');
});
</script>

<template>
  <div class="h-billing-credits-balance">
    <div class="h-billing-credits-balance__info">
      {{ t('v2.credits.balance') }}:
      <span>
        {{
          currency.format(store.getters['paymentMethods/getCreditsBalance'], {
            isChargebeePrice: true,
            fixedCurrency: false,
            monthly: false,
          })
        }}
      </span>
    </div>
    <HpIcon
      v-tooltip.bottom-left.full-width="t('v2.credits.balance.tooltip')"
      icon="icon-question-mark-circle"
      width="16"
      class="h-billing-credits-balance__icon"
    />
  </div>
</template>

<style lang="scss" scoped>
.h-billing-credits-balance {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: var(--light);
  border-radius: 8px;
  border: 1px solid var(--gray-border);

  &__info {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      font-weight: bold;
    }
  }

  &__icon {
    cursor: pointer;
  }
}
</style>
