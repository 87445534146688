import { hostingEmailsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingEmailsMxRecordsLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingEmailsMxRecordsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingEmailsMxRecordsIndex({ commit }) {
      const [{ requestDomain }, error] = await hostingEmailsRepo.getMxRecords();

      if (!error) {
        commit('setHostingEmailsMxRecordsLoaded', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingEmailsMxRecordsGoogleMxRecordStore({ dispatch }, payload) {
      const [{ data }, error] = await hostingEmailsRepo.setGoogleMxRecord({
        zoneName: payload.domain,
      });

      dispatch('hostingEmailsMxRecordsIndex');

      return [{ data }, error];
    },
  },
};
