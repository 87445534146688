import { defineStore } from 'pinia';
import { ref } from 'vue';

import { logoutWithTokenInvalidation } from '@/utils/services/authService';

export const useAuthStore = defineStore('authStore', () => {
  const loggingOut = ref(false);

  const userInitiatedLogout = () => {
    loggingOut.value = true;
    logoutWithTokenInvalidation();
  };

  return { loggingOut, userInitiatedLogout };
});
