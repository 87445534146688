<template>
  <div v-if="isPageCorrect" class="link-banner">
    <div class="link-banner__left">
      <HpIcon
        class="link-banner__icon"
        icon="ic-globe"
        primary
        view-box="0 -2 24 24"
      />
      <Trans tag="p" class="link-banner__message">
        v2.boost.your.project.visibility.with.a.new.domain.exclamation
      </Trans>
    </div>
    <HButtonV1 no-margin primary @click="componentClicked">
      v2.find.domain
    </HButtonV1>
  </div>
</template>

<script>
//JSON-slugs-migrated
export default {
  created() {
    this.componentViewed();
  },
  computed: {
    isPageCorrect() {
      return this.$route.name === 'my-domains';
    },
  },
  methods: {
    componentViewed() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'eec.promoView',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: 'LINK_DOMAIN_PROMO',
                name: 'Link Domain Promo',
                creative: 'hpanel_domain_section_top_banner',
                position: 'hpanel',
              },
            ],
          },
        },
      });
    },
    componentClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'eec.promoClick',
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: 'LINK_DOMAIN_PROMO',
                name: 'Link Domain Promo',
                creative: 'hpanel_domain_section_top_banner',
                position: 'hpanel',
              },
            ],
          },
        },
      });
      this.$router.push({
        name: 'domain-checker',
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../sass/components/_domain-link-banner.scss';
</style>
