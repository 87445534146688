import type { NavigationGuard } from 'vue-router';

import { useProfileStore } from '@/stores';
import { Route } from '@/types';

const paidInvoicesGuard: NavigationGuard = (to, from, next) => {
  const profileStore = useProfileStore();

  if (profileStore.isAccessManager) {
    return next({ name: Route.Base.NOT_FOUND });
  }

  next();
};

export default paidInvoicesGuard;
