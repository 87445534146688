import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const OTHER_REPO_URL = `${process.env.VITE_API_REST}/v2/other`;

export default {
  async getActivityLog(params) {
    return await hAsync(http.get(`${OTHER_REPO_URL}/activity-log`, { params }));
  },

  importWebsite(file, config) {
    return hAsync(http.post(`${OTHER_REPO_URL}/import-website`, file, config));
  },

  async postAccontReload() {
    return await hAsync(http.post(`${OTHER_REPO_URL}/account-reload`));
  },

  async getDeactivateAcc(params) {
    return await hAsync(http.get(`${OTHER_REPO_URL}/deactivate`, { params }));
  },

  async deleteDeactivate(data) {
    return await hAsync(http.delete(`${OTHER_REPO_URL}/deactivate`, data));
  },

  async getZyroBuilder(params) {
    return await hAsync(
      http.get(`${OTHER_REPO_URL}/zyro-builder`, {
        params,
        domain: params.domain,
      }),
    );
  },

  async getZyroBuilderNew(params) {
    return await hAsync(
      http.get(`${OTHER_REPO_URL}/zyro-builder-new`, { params }),
    );
  },
};
