import type { RouteRecordRaw } from 'vue-router';

import {
  flockGuard,
  flockUpsellGuard,
  flockUpgradeGuard,
} from '@/guards/emails/flockGuard';
import { Route } from '@/types';

const titanEmailRoutes = [
  {
    path: '/titan/choose-plan',
    name: Route.Email.TITAN_CHOOSE_PLAN,
    meta: {
      sideMenuExists: false,
      title: 'Select Your Email Plan',
      breadcrumbs: false,
      hideBackWord: true,
    },
    component: () => import('@/views/Emails/Flock/TitanChoosePlan.vue'),
  },
  {
    path: '/titan/:domain',
    name: Route.Email.TITAN_MANAGEMENT,
    meta: {
      breadcrumbsTitle: {
        title: '@{domain}',
        translate: false,
      },
      breadcrumbs: ['emails'],
      title: "{'@'}{domain}",
      sideMenuExists: false,
    },
    beforeEnter: (to, from, next) => flockGuard(to, from, next),
    redirect: { name: Route.Email.TITAN_DASHBOARD },
    component: () => import('@/views/Emails/Flock/FlockManagement.vue'),
    children: [
      {
        path: 'management',
        name: Route.Email.TITAN_DASHBOARD,
        meta: {
          breadcrumbsTitle: {
            title: '@{domain}',
            translate: false,
          },
          breadcrumbs: ['emails'],
          title: "{'@'}{domain}",
          sideMenuExists: false,
        },
        beforeEnter: (to, from, next) => flockUpsellGuard(to, from, next),
        component: () => import('@/views/Emails/Flock/FlockDashboard.vue'),
      },
      {
        path: 'upgrade',
        name: Route.Email.TITAN_UPGRADE_PLAN,
        meta: {
          sideMenuExists: false,
          breadcrumbs: false,
          hideBackWord: true,
          hideTitle: true,
          hideBackButton: true,
          backArrowPath: {
            name: Route.Base.EMAILS,
          },
        },
        beforeEnter: (to, from, next) => flockUpgradeGuard(to, from, next),
        component: () => import('@/views/Emails/Flock/FlockUpgradePage.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

export default titanEmailRoutes;
