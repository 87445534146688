export enum SURVEY_TYPE {
  NPS = 'net_promoter_score',
  CSAT = 'customer_satisfaction_score',
}

export const CSAT_CUSTOM_IDENTIFIER = {
  ONBOARDING: 'onboarding',
  ONBOARDING_V2: 'onboarding-v2',
  ONBOARDING_WEB_PRO: 'onboarding-web-pro',
  EMAIL_ONBOARDING: 'email-onboarding',
  EMAIL_CREATE_ACCOUNT: 'email-create-account',
  WEBSITE_AUTO_INSTALLER: 'website-auto-installer',
  WEBSITE_BACKUP_RESTORE: 'website-backup-restore',
  WEBSITE_MIGRATION_SUBMISSION: 'website-migration-submission',
  WEBSITE_MIGRATION_COMPLETED: 'website-migration-completed',
  EMAIL_SETUP_DEVICES: 'email-setup-devices',
  EMAIL_CONNECT_DOMAIN: 'email-connect-domain',
  EMAIL_LOGS_ACCESS: 'email-logs-access',
  EMAIL_LOGS_ACTIONS: 'email-logs-actions',
  EMAIL_LOGS_DELIVERY: 'email-logs-delivery',
  EMAIL_OVERVIEW: 'email-overview',
  DOMAINS_OVERVIEW: 'domains-overview',
  REFERRALS: 'referrals',
  WEBSITES_LIST: 'websites-list',
  HOMEPAGE_WIDGETS: 'homepage-widgets',
  VPS_ONBOARDING: 'vps-onboarding',
  AI_TROUBLESHOOTER: 'ai-troubleshooter',
  H5G_SIDEBAR: 'h5g-sidebar',
  WEBSITE_DASHBOARD: 'website-dashboard',
  HOSTING_MANAGE: 'hosting-manage',
  NEW_NAVIGATION: 'new-navigation',
  CONNECT_DOMAIN: 'connect-domain',
  BILLING_SUBSCRIPTIONS: 'billing/subscriptions',
  WORDPRESS_ADDONS: 'wordpress-addons',
} as const;

export enum SNOOZE_DAYS {
  NPS = 30,
  CSAT = 7,
}

export type CsatCustomIdentifierType =
  (typeof CSAT_CUSTOM_IDENTIFIER)[keyof typeof CSAT_CUSTOM_IDENTIFIER];
