import type { AxiosResponse } from 'axios';

import { ONE_MINUTE_IN_MS } from '@/data/globalConstants';
import type {
  IFlatErrorResponse,
  RequestConfig,
  ResponseWithError,
} from '@/types';
import { Header } from '@/types';
import { isFlatErrorResponse } from '@/utils/helpers/modelsHelpers';
import { errorLogger } from '@/utils/services/errorLogging';

const clearAxiosErrorTags = () => {
  errorLogger.setTag('axiosCode', undefined);
  errorLogger.setTag('axiosMessage', undefined);
  errorLogger.setTag('serviceName', undefined);
  errorLogger.setTag('backendPathname', undefined);
  errorLogger.setTag('errorResponseMessage', undefined);
  errorLogger.setTag('responseStatus', undefined);
  errorLogger.setTag('responseCode', undefined);
  errorLogger.setTag(Header.USERNAME, undefined);
  errorLogger.setTag(Header.DOMAIN, undefined);
  errorLogger.setTag(Header.ORDER_ID, undefined);
  errorLogger.setTag(Header.CORRELATION_ID, undefined);
  errorLogger.setTag(Header.CLIENT_ID, undefined);
};

const getServiceName = (urlObject: URL) => {
  const isSameOrigin = window.location.origin === urlObject.origin;
  if (isSameOrigin) {
    const [, servicePrefix, serviceName] = urlObject.pathname.split('/');

    return `/${servicePrefix}/${serviceName}`;
  }

  return urlObject.origin;
};

const stringifyErrorObject = (error: any) => {
  if (typeof error === 'object') {
    return JSON.stringify(error);
  }

  return error;
};

const logNonWhitelistedAxiosError = (error: any) => {
  if (!error?.config) {
    return errorLogger.logError('No axios error config found');
  }
  const errorConfig = error.config as RequestConfig;

  if (!errorConfig.url) {
    return errorLogger.logError('No request url provided');
  }

  const urlObject = new URL(errorConfig.url);
  const serviceName = getServiceName(urlObject);
  errorLogger.setTag('axiosCode', error.code);
  errorLogger.setTag('axiosMessage', error.message);
  errorLogger.setTag('serviceName', serviceName);
  errorLogger.setTag('backendPathname', urlObject.pathname);
  errorLogger.setTag(Header.USERNAME, errorConfig?.headers?.[Header.USERNAME]);
  errorLogger.setTag(Header.DOMAIN, errorConfig?.headers?.[Header.DOMAIN]);
  errorLogger.setTag(Header.ORDER_ID, errorConfig?.headers?.[Header.ORDER_ID]);
  errorLogger.setTag(
    Header.CORRELATION_ID,
    errorConfig?.headers?.[Header.CORRELATION_ID],
  );

  errorLogger.setTag(
    Header.CLIENT_ID,
    errorConfig?.headers?.[Header.CLIENT_ID],
  );

  if (!error.response) {
    errorLogger.logError(`${serviceName} ${errorConfig.method} request error`);
    errorLogger.ignoreSentryErrors(ONE_MINUTE_IN_MS);

    return;
  }
  const response = error.response as AxiosResponse<
    | {
        error?: {
          code?: number;
          inputs?: string;
          validation_messages?: string;
          message?: string;
        };
      }
    | ResponseWithError['response']['data']
    | IFlatErrorResponse
    | undefined
  >;

  const responseStatus = response.status;
  const isStatusWhitelisted =
    !responseStatus ||
    errorConfig.whitelistedStatusCodes?.includes(responseStatus);
  if (isStatusWhitelisted) {
    return;
  }

  let responseCode = undefined;
  if (
    typeof response.data === 'object' &&
    response.data &&
    'error' in response.data
  ) {
    responseCode = isFlatErrorResponse(response.data)
      ? response.data.code
      : response.data?.error?.code;
    const isCodeWhitelisted =
      responseCode && errorConfig.whitelistedErrorCodes?.includes(responseCode);
    if (isCodeWhitelisted) {
      return;
    }
  }

  if (errorConfig?.getIsResponseWhitelisted?.(response)) {
    return;
  }

  let errorResponseMessage = undefined;

  if (isFlatErrorResponse(response.data)) {
    errorResponseMessage = response.data.error;
  } else if (
    typeof response.data === 'object' &&
    response.data &&
    'error' in response.data
  ) {
    const dataError = response.data?.error;
    errorResponseMessage = stringifyErrorObject(
      dataError?.inputs || dataError?.validation_messages || dataError?.message,
    );
  } else if (
    typeof response.data === 'object' &&
    response.data &&
    'message' in response.data
  ) {
    errorResponseMessage = stringifyErrorObject(response.data?.message);
  }

  errorLogger.setTag('errorResponseMessage', errorResponseMessage);
  errorLogger.setTag('responseStatus', responseStatus);
  errorLogger.setTag('responseCode', responseCode);

  errorLogger.logError(`${serviceName} ${errorConfig.method} request error`);
  errorLogger.ignoreSentryErrors(ONE_MINUTE_IN_MS);
};
export default (error: any) => {
  try {
    errorLogger.addAxiosErrorBreadcrumb(error);
    logNonWhitelistedAxiosError(error);
  } catch (caughtError) {
    errorLogger.logError(
      new Error('Error in axios logger', { cause: caughtError }),
    );
  }
  clearAxiosErrorTags();
};
