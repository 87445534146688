import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_PAYMENTS_V2}/jwt/api/v1/stored-payment-methods`,

  async verifyPayment(params) {
    return await hAsync(
      http.post(`${this.url}/charge/verify`, params, {
        hideToastr: true,
      }),
    );
  },
};
