import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_H_SSO}/v1/2fa`,
  postEmailInitiate(data: { currentPassword?: string }) {
    return hAsync<boolean>(
      http.post(`${this.url}/email/initiate`, data, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  postCodeInitiate(data: { currentPassword?: string }) {
    return hAsync<boolean>(
      http.post(`${this.url}/google/initiate`, data, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  patchEmailVerify(code: string) {
    return hAsync<{ code: string }>(
      http.patch(`${this.url}/email/secret/${code}`, undefined, {
        withTokenUpdate: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  patchVerifyCode(code: string) {
    return hAsync<{ code: string }>(
      http.patch(`${this.url}/google/secret/${code}`, null, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  patchVerifyOTP(otp: string) {
    return hAsync<boolean>(
      http.patch(`${this.url}/google/otp/${otp}`, undefined, {
        withTokenUpdate: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  deleteDisableOtp(otp: string) {
    return hAsync<boolean>(
      http.delete(`${this.url}/disable/otp/${otp}`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  deleteDisableEmail(code: string) {
    return hAsync<boolean>(
      http.delete(`${this.url}/disable/email/${code}`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  deleteDisableBackup(code: string) {
    const trimmedCode = code.replace(/\s/g, '');

    return hAsync<{ isValid: boolean; attemptsLeft: number }>(
      http.delete(`${this.url}/disable/backup/${trimmedCode}`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  getBackupCodes() {
    return hAsync<string[]>(
      http.get(`${this.url}/backup-codes`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  patchSendEmailCode() {
    return hAsync<boolean>(
      http.patch(`${this.url}/send-email-code`, null, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
};
