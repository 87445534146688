<template>
  <div>
    <div class="w-100">
      <template v-if="!onlyDetails">
        <div v-for="index in periodCount" :key="index" class="fake-period">
          <div class="fake-period__left">
            <HSkeletonLoader circle :height="16" :width="16" />
            <HSkeletonLoader
              rounded
              :height="24"
              :width="getWidth(100, index)"
              class="h-ml-8"
            />
          </div>
          <div class="fake-period__right">
            <HSkeletonLoader rounded :height="41" :width="89" />
          </div>
        </div>
      </template>
      <div class="fake-details">
        <div v-for="index in detailsCount" :key="index" class="fake-detail">
          <HSkeletonLoader rounded :height="24" :width="getWidth(120, index)" />
          <HSkeletonLoader
            rounded
            :height="24"
            :width="getWidth(60, index)"
            class="h-ml-8"
          />
        </div>
      </div>
    </div>
    <div v-if="!onlyDetails" class="w-100 text-right h-mb-4-minus">
      <HSkeletonLoader
        rounded
        :height="42"
        :width="113"
        class="h-mr-16 d-inline-block"
      />
      <HSkeletonLoader
        rounded
        :height="42"
        :width="196"
        class="d-inline-block"
      />
    </div>
  </div>
</template>
<script>
import HSkeletonLoader from '@/components/Loaders/HSkeletonLoader';

export default {
  components: {
    HSkeletonLoader,
  },
  props: {
    periodCount: {
      type: Number,
      default: 4,
    },
    detailsCount: {
      type: Number,
      default: 2,
    },
    onlyDetails: Boolean,
  },
  methods: {
    getWidth(currentLength, index) {
      if (Math.random() >= 0.5) {
        return currentLength + 5 * index;
      }

      return currentLength - 5 * index;
    },
  },
};
</script>

<style lang="scss" scoped>
.fake-period {
  display: flex;
  justify-content: space-between;
  height: 59px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px 16px;

  border: 1px solid var(--gray-border);

  &__left {
    display: flex;
    align-items: center;
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.fake-details {
  padding: 16px;
  border-radius: 4px;
  margin: 16px 0;

  border: 1px solid var(--gray-border);
}

.fake-detail {
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
</style>
