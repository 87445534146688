import hostingAccountsData from '@/data/hostingAccountsDetails';
import { accountRepo, datacentersRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { useResourcesStore } from '@/stores';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const initial = {
  nameservers: {
    ns1: '',
    ns2: '',
    ns3: '',
    ns4: '',
  },
  websiteDetails: {
    'Access your website at': '',
    'Access your website with www': '',
    'Server name': '',
    'Website IP address': '',
  },
  eMailDetails: {
    'IMAP host': '',
    'IMAP port (secure)': '',
    'Login password': '',
    'Login username': '',
    'MX record': '',
    'POP3 host': '',
    'POP3 port (secure)': '',
    'SMTP host': '',
    'SMTP port (secure)': '',
    'Webmail address': '',
  },
  fileUploadDetails: {
    'FTP IP': '',
    'FTP hostname': '',
    'FTP port': '',
    'FTP username': '',
    'Folder to upload files': '',
  },
  domain: null,
  loaded: false,
  transferRecommendationLoaded: false,
  transferRecommendationDecision: '',
  transferRecommendationTraffic: [],
  transferDatacentersLoaded: false,
  transferDatacenters: [],
  hostingAccountDetails: {},
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingAccountDetails(state, { data, requestDomain }) {
      const hostingAccountDetails =
        hostingAccountsData.getHostingAccountDetailsFormat(data, requestDomain);

      setForDomain(state, null, 'hostingAccountDetails', data);
      Object.keys(hostingAccountDetails).forEach((title) => {
        setForDomain(state, null, title, hostingAccountDetails[title]);
      });
    },
    setHostingAccountDetailsLoaded(state, data) {
      setForDomain(state, null, 'loaded', data);
    },
    SET_TRANSFER_RECOMMENDATION_LOADED: (state, data) => {
      setForDomain(state, null, 'transferRecommendationLoaded', data);
    },
    SET_TRANSFER_RECOMMENDATION_DECISION(state, data) {
      setForDomain(state, null, 'transferRecommendationDecision', data);
    },
    SET_TRANSFER_RECOMMENDATION_TRAFFIC(state, data) {
      setForDomain(state, null, 'transferRecommendationTraffic', data);
    },
    SET_TRANSFER_DATACENTERS_LOADED: (state, data) => {
      setForDomain(state, null, 'transferDatacentersLoaded', data);
    },
    SET_TRANSFER_DATACENTERS(state, data) {
      setForDomain(state, null, 'transferDatacenters', data);
    },
  },
  getters: {
    getHostingAccountDetails: (state) =>
      getStateVariable(state, 'hostingAccountDetails'),
    getNameservers: (state) => getStateVariable(state, 'nameservers'),
    getWebsiteDetails: (state) => getStateVariable(state, 'websiteDetails'),
    getEMailDetails: (state) => getStateVariable(state, 'eMailDetails'),
    getFileUploadDetails: (state) =>
      getStateVariable(state, 'fileUploadDetails'),
    getHostingAccountDetailsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
    getTransferRecommendationLoaded: (state) =>
      getStateVariable(state, 'transferRecommendationLoaded'),
    getTransferRecommendationDecision: (state) =>
      getStateVariable(state, 'transferRecommendationDecision'),
    getTransferRecommendationTraffic: (state) =>
      getStateVariable(state, 'transferRecommendationTraffic'),
    getTransferDatacentersLoaded: (state) =>
      getStateVariable(state, 'transferDatacentersLoaded'),
    getTransferDatacenters: (state) =>
      getStateVariable(state, 'transferDatacenters'),
  },
  actions: {
    async hostingAccountsDetailsIndex(context, params) {
      const [response, err] = await accountRepo.getAccountDetails(params);
      if (!err) {
        context.commit('setHostingAccountDetails', response);
        context.commit('setHostingAccountDetailsLoaded', {
          data: true,
          requestDomain: response.requestDomain,
        });
        if (params?.freshQuotas) {
          toastr.s(i18n.t('Disk and inodes usage is recalculated'));
        }
      }
    },
    async transferRecommendationIndex({ commit }) {
      commit('SET_TRANSFER_RECOMMENDATION_LOADED', false);
      const [{ data, requestDomain }, err] =
        await accountRepo.getTransferRecommendation();

      if (!err && data) {
        commit(
          'SET_TRANSFER_RECOMMENDATION_DECISION',
          data.decision,
          requestDomain,
        );
        commit(
          'SET_TRANSFER_RECOMMENDATION_TRAFFIC',
          data.traffic,
          requestDomain,
        );
      }
      commit('SET_TRANSFER_RECOMMENDATION_LOADED', true);
    },
    async transferDatacentersIndex({ commit, getters }) {
      commit('SET_TRANSFER_DATACENTERS_LOADED', false);

      const { getResourceByReferenceId } = useResourcesStore();

      const resource = getResourceByReferenceId(
        getters?.currentOrderDetails?.orderId ||
          getters?.getHostingAccountDetails?.orderId,
      );

      if (!resource || !resource?.config?.plan) return;

      const [{ data, requestDomain }] =
        await datacentersRepo.getAvailableDatacenters(
          resource?.config?.plan,
          resource?.referenceId,
        );

      if (data) {
        commit('SET_TRANSFER_DATACENTERS', data, requestDomain);
      }
      commit('SET_TRANSFER_DATACENTERS_LOADED', true);
    },
  },
};
