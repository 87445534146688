<template>
  <HostingOrderDetailsList />
</template>

<script>
import HostingOrderDetailsList from '../Hosting/HostingOrderDetailsList';

export default {
  name: 'HostingAccountSidebar',
  components: {
    HostingOrderDetailsList,
  },
};
</script>
