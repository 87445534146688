import { domainsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { isTemporaryDomain, toASCII } from '@/utils/helpers';

const initial = {
  isPointing: null,
  loaded: false,
  preview: {
    enabled: false,
    previewLink: '',
  },
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setDomainIsPointing(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'isPointing', data);
    },
    setDomainIsPointingLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
    setDomainPointingIssues(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'pointingIssues', data);
    },
    setDomainPointingIssuesLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'pointingIssuesLoaded', data);
    },
    setDomainPreview(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'preview', data);
    },
  },
  getters: {
    getDomainIsPointing:
      (state) =>
      (domain = null) =>
        getStateVariable(state, 'isPointing', toASCII(domain)) === true,
    getDomainIsPointingLoaded: (state) => getStateVariable(state, 'loaded'),
    getDomainPointingIssues:
      (state) =>
      (domain = null) =>
        getStateVariable(state, 'pointingIssues', toASCII(domain)),
    getDomainPointingIssuesLoaded: (state) =>
      getStateVariable(state, 'pointingIssuesLoaded'),
    getDomainPreview:
      (state) =>
      (domain = null) =>
        getStateVariable(state, 'preview', domain),
  },
  actions: {
    async domainIsPointingIndex(context, domainToCheck) {
      const commitContext = (data, requestDomain) => {
        context.commit('setDomainIsPointing', {
          data,
          requestDomain,
        });
        context.commit('setDomainIsPointingLoaded', {
          data: true,
          requestDomain,
        });
      };

      if (isTemporaryDomain(domainToCheck)) {
        commitContext(true, domainToCheck);

        return;
      }

      const params = domainToCheck ? { domain: toASCII(domainToCheck) } : null;
      let domain = domainToCheck;

      context.getters.getCurrentAccountSubdomains.forEach((subdomain) => {
        if (subdomain.vhost.includes(toASCII(domainToCheck))) {
          domain = subdomain.domain;
        }
      });

      const accountFromStore =
        context.getters.getCurrentAccountWithDomain(domain);
      if (domainToCheck && !accountFromStore) {
        domain = null;
      }
      const [{ data, requestDomain }, err] = await domainsRepo.isDomainPointing(
        params,
        domain,
      );

      if (!err) {
        commitContext(data, domainToCheck || requestDomain);
      }
    },
    async domainPointingIssuesIndex(context, domainToCheck) {
      const params = domainToCheck ? { domain: toASCII(domainToCheck) } : null;
      let domain = domainToCheck;

      context.getters.getCurrentAccountSubdomains.forEach((subdomain) => {
        if (subdomain.vhost.includes(toASCII(domainToCheck))) {
          domain = subdomain.domain;
        }
      });

      const accountFromStore =
        context.getters.getCurrentAccountWithDomain(domain);
      if (domainToCheck && !accountFromStore) {
        domain = null;
      }
      const [{ data, requestDomain }, err] =
        await domainsRepo.getDomainPointingIssues(params, domain);

      if (!err) {
        context.commit('setDomainPointingIssues', {
          data,
          requestDomain: domainToCheck || requestDomain,
        });
        context.commit('setDomainPointingIssuesLoaded', {
          data: true,
          requestDomain: domainToCheck || requestDomain,
        });
      }
    },
    async domainPreviewIndex(context, request) {
      const [{ data }, err] = await domainsRepo.getPreviewLink(
        request.domain,
        request.params,
      );
      if (!err) {
        context.commit('setDomainPreview', {
          data,
          requestDomain: request.domain,
        });
      }
    },
    async domainPreviewLink() {
      return await domainsRepo.postPreviewLink();
    },
  },
};
