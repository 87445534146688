import { defineStore } from 'pinia';
import { ref } from 'vue';

import { isLinuxUserAgent } from '@/utils/helpers';

export const useHPanelControlsStore = defineStore('hpanelControlsStore', () => {
  const isAISearchOpen = ref(false);
  const pressedKeys = ref<Record<string, any>>({});
  const isLanguageSelectionOpen = ref(false);
  const isLanguageSelectionInHeader = ref(false);
  const isAccountDropdownOpen = ref(false);
  const navigationLevel = ref(1);
  const hasSecondLevelNavigation = ref(false);
  const isMobileNavigationOpen = ref(false);

  const toggleAISearch = () => {
    isAISearchOpen.value = !isAISearchOpen.value;
  };

  const setPressedKeys = (key: string) => {
    pressedKeys.value[key] = true;
  };

  const setNavigationLevel = (level: number) => {
    navigationLevel.value = level;
  };

  const setHasSecondLevelNavigation = (value: boolean) => {
    hasSecondLevelNavigation.value = value;
  };

  const isAISearchCommand = (key: string) =>
    pressedKeys.value.Meta && (key === 'k' || key === 'K');

  const initHPanelControls = () => {
    window.addEventListener('keydown', (event) => {
      setPressedKeys(event.key);

      if (isAISearchCommand(event.key) && !isLinuxUserAgent) {
        event.preventDefault();

        toggleAISearch();
      }
    });

    window.addEventListener('keyup', (event) => {
      pressedKeys.value[event.key] = false;
    });
  };

  const toggleLanguageSelection = (inHeader?: boolean) => {
    isLanguageSelectionOpen.value = !isLanguageSelectionOpen.value;
    if (isLanguageSelectionOpen.value) {
      isLanguageSelectionInHeader.value = !!inHeader;
    }
  };

  const toggleAccountDropdown = () => {
    isAccountDropdownOpen.value = !isAccountDropdownOpen.value;
  };

  const toggleMobileNavigation = () => {
    isMobileNavigationOpen.value = !isMobileNavigationOpen.value;
  };

  return {
    isAISearchOpen,
    isLanguageSelectionOpen,
    isLanguageSelectionInHeader,
    isAccountDropdownOpen,
    navigationLevel,
    hasSecondLevelNavigation,
    setHasSecondLevelNavigation,
    setNavigationLevel,
    isMobileNavigationOpen,
    toggleAISearch,
    initHPanelControls,
    toggleLanguageSelection,
    toggleAccountDropdown,
    toggleMobileNavigation,
  };
});
