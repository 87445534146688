import type { ExperimentClient } from '@amplitude/experiment-js-client';
import { Experiment as AmplitudeExperiment } from '@amplitude/experiment-js-client';
import cookies from 'js-cookie';

import type { Experiment, FeatureFlag } from '@/types';
import deviceIdService from '@/utils/services/deviceIdService';
import { errorLogger } from '@/utils/services/errorLogging';

const EXPERIMENT_QA_COOKIE = 'hostinger-qa-no-experiments';
const ALLOWED_EXPERIMENTS_QA_COOKIE = 'hostinger-qa-enable-experiments';

type Experiment = {
  experimentID: string;
  experimentVariant: number;
};

const isExperimentsIgnored = () => !!cookies.get(EXPERIMENT_QA_COOKIE);

const allowedQAExperiments = () => {
  try {
    const allowedExperiments = cookies.get(ALLOWED_EXPERIMENTS_QA_COOKIE);

    if (!allowedExperiments) {
      return [];
    }

    return JSON.parse(allowedExperiments);
  } catch {
    return [];
  }
};

const getExperimentVariantByID = (experimentId: Experiment.ID) => {
  if (allowedQAExperiments().includes(experimentId)) {
    return 1;
  }
  if (isExperimentsIgnored()) {
    return 0;
  }
  try {
    if (!amplitudeExperimentInstance) {
      return;
    }

    const value = amplitudeExperimentInstance.variant(experimentId)?.value;
    if (!value) {
      return;
    }

    return parseInt(value);
  } catch (error) {
    errorLogger.logError(
      new Error('AmplitudeExperiment error', { cause: error }),
    );
  }
};

let amplitudeExperimentInstance: ExperimentClient | null;

const getAmplitudeFeatureFlagById = (featureFlagId: FeatureFlag.ID) =>
  amplitudeExperimentInstance?.variant(featureFlagId)?.value;

const initExperimentService = async ({
  clientId,
  brand,
}: {
  clientId: string;
  brand: string;
}) => {
  amplitudeExperimentInstance = process.env.VITE_AMPLITUDE_EXPERIMENT_KEY
    ? AmplitudeExperiment.initialize(
        process.env.VITE_AMPLITUDE_EXPERIMENT_KEY,
        {
          serverUrl: process.env.VITE_AMPLITUDE_EXPERIMENT_URL,
        },
      )
    : null;
  const deviceId = deviceIdService.getDeviceId();

  await amplitudeExperimentInstance?.fetch({
    user_id: clientId,
    device_id: deviceId,
    user_properties: {
      brand,
      reseller_client_id: clientId,
    },
  });
};

const getExperimentById = (experimentId: Experiment.ID) =>
  amplitudeExperimentInstance?.variant(experimentId);

export default {
  getAmplitudeFeatureFlagById,
  getExperimentVariantByID,
  getExperimentById,
  initExperimentService,
};
