export namespace Hotjar {
  export enum TopBar {
    UPDATED_TOPBAR = 'updated_topbar',
  }

  export enum Emails {
    CUSTOM_DKIM_VERIFY = 'action_hemail-custom_dkim_verify',
    ACCOUNT_ONBOARDING_COMPLETE = 'action_hemail-account_onboarding_complete',
  }

  export enum Onboarding {
    ONBOARDING_WP_AI_GENERATED_CONTENT = 'wp_website-ai_generated_content',
    ONBOARDING_THEMES_PLUGINS_SELECTION = 'themes_plugins_selection_survey',
  }

  export enum FakeDoor {
    FAKE_DOOR_POPUP = 'fake_door_popup',
  }

  export enum Hosting {
    CDN_BUSINESS_CSAT = 'cdn_business_csat',
  }

  export enum Vps {
    ONE_MONTH_REFUND_SURVEY = 'vps1m_refund_survey',
  }

  export enum Widget {
    HOME_WIDGETS_EDIT_ACTION = 'home_widgets_edit_action',
  }

  export enum WebPro {
    ADDONS_SURVEY = 'webpro_addons_survey',
  }
}
