import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  ssoUrl: `${process.env.VITE_API_SSO}/v1/free-upgrade`,

  async hostingFreeUpgradeIndex(params) {
    return await hAsync(http.get(`${this.ssoUrl}`, { params, cache: 60 }));
  },
  async ssoFreeUpgradeStore(request) {
    return await hAsync(http.post(`${this.ssoUrl}`, request));
  },
};
