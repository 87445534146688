import type { DeveloperToolType, DeveloperToolsWebsite } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_WH_API}/api/hapi/v1`,

  async getAssignedWebsites(resourceId: number) {
    return await hAsync<DeveloperToolsWebsite[]>(
      http.get(`${this.url}/client-addon-bundles/${resourceId}/websites`),
    );
  },

  async assignWebsite(resourceId: number, website: DeveloperToolsWebsite) {
    return await hAsync<void>(
      http.post(
        `${this.url}/client-addon-bundles/${resourceId}/websites`,
        website,
      ),
    );
  },

  async deleteAssignedWebsite(
    resourceId: number,
    website: DeveloperToolsWebsite,
  ) {
    return await hAsync<void>(
      http.delete(`${this.url}/client-addon-bundles/${resourceId}/websites`, {
        data: website,
      }),
    );
  },

  async getActiveTools() {
    return await hAsync<DeveloperToolType[]>(
      http.get(`${this.url}/client-addons/active-tools`),
    );
  },
};
