import { toASCII } from 'punycode';

import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  urlZyro: `${process.env.VITE_API_ZYRO_BACKEND}`,
  urlRest: `${process.env.VITE_API_REST}/v3/website-builder`,

  async getRedirectLink(hResourceId, params) {
    return await hAsync(
      http.get(`${this.urlZyro}/hpanel/site/${hResourceId}`, {
        params,
        withCredentials: true,
        plain: true,
      }),
    );
  },
  async migrateToBuilder({ username, orderId, domain }) {
    return await hAsync(
      http.post(
        `${this.urlRest}/migrate`,
        { domain },
        {
          domain,
          headers: {
            [Header.USERNAME]: username,
            [Header.ORDER_ID]: orderId,
          },
        },
      ),
    );
  },
  async postPlanChange(request, { username, orderId, domain }) {
    return await hAsync(
      http.post(`${this.urlRest}/plan-change`, request, {
        headers: {
          [Header.USERNAME]: username,
          [Header.ORDER_ID]: orderId,
          [Header.DOMAIN]: domain,
        },
      }),
    );
  },

  async undoMigrationToBuilder(domain) {
    return await hAsync(
      http.post(
        `${this.urlRest}/switch-back`,
        { domain },
        {
          domain,
          hostingHeaderRequired: true,
        },
      ),
    );
  },
  async getWebsiteResourceId({ username, orderId, domain }) {
    return await hAsync(
      http.get(`${this.urlRest}/get-resource-id`, {
        params: { domain },
        hideToastr: true,
        domain,
        headers: {
          [Header.USERNAME]: username,
          [Header.ORDER_ID]: orderId,
        },
      }),
    );
  },
  async changeWebsiteDomain(domain, newDomain) {
    return await hAsync(
      http.post(
        `${this.urlRest}/domain-change`,
        {
          domain,
          newDomain,
        },
        { domain },
      ),
    );
  },
  async changeWebsiteDomainWithHeaders({ domain, newDomain }, headers) {
    return await hAsync(
      http.post(
        `${this.urlRest}/domain-change`,
        {
          domain,
          newDomain,
        },
        {
          domain,
          noHostingHeaders: true,
          headers: {
            [Header.USERNAME]: headers.username,
            [Header.ORDER_ID]: headers.orderId,
            [Header.DOMAIN]: domain,
          },
        },
      ),
    );
  },
  async categorySuggestion(input) {
    return await hAsync(
      http.post(`${this.urlZyro}/hpanel/category-suggestion`, {
        input,
        limit: 30,
      }),
    );
  },
  async getWebsites() {
    return await hAsync(
      http.get(`${this.urlZyro}/hpanel/billing/sites`, {
        withCredentials: true,
      }),
    );
  },
  async getAnalytics(domain) {
    return await hAsync(
      http.get(`${this.urlZyro}/hpanel/domains/traffic/${toASCII(domain)}`, {
        withCredentials: true,
      }),
    );
  },
  async postSession() {
    return await hAsync(
      http.post(`${this.urlZyro}/hpanel/session/create`, undefined, {
        withCredentials: true,
      }),
    );
  },
  async updateTemplate({ siteId, templateId }) {
    return await hAsync(
      http.patch(
        `${this.urlZyro}/hpanel/update-template`,
        {
          siteId,
          templateId,
        },
        { withCredentials: true, plain: true },
      ),
    );
  },
  async getSiteAchievements(hResourceId) {
    return await hAsync(
      http.get(`${this.urlZyro}/hpanel/site/${hResourceId}/achievements`),
    );
  },
  async deleteSiteAchievements({ hResourceId }) {
    return await hAsync(
      http.delete(`${this.urlZyro}/hpanel/site/${hResourceId}/achievements`),
    );
  },
  async duplicateWebsite(hResourceId, { domain, hostingReferenceId }) {
    return await hAsync(
      http.post(
        `${this.urlZyro}/hpanel/site/${hResourceId}/duplicate`,
        {
          domain,
          hostingReferenceId,
        },
        { withCredentials: true, plain: true },
      ),
    );
  },
};
