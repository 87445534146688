<script setup lang="ts">
import { useRouter } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { useAccessSharingIntroductionStore } from '@/stores/accessSharingIntroductionStore';
import { Route } from '@/types';

const { t } = useGlobals();
const router = useRouter();
const accessSharingIntroductionStore = useAccessSharingIntroductionStore();

const handleTryNow = () => {
  accessSharingIntroductionStore.setIntroductionSeen();
  router.push({
    name: Route.Profile.ACCOUNT_SHARING,
  });
};
</script>
<template>
  <div class="access-sharing-introduction-popover">
    <div class="access-sharing-introduction-popover__content">
      <h3 class="h-m-0">
        {{ t('v2.access.sharing.menu.introduction.title') }}
      </h3>
      <span>{{ t('v2.access.sharing.menu.introduction.subtitle') }}</span>
    </div>
    <div class="access-sharing-introduction-popover__actions">
      <HButton
        variant="text"
        class="access-sharing-introduction-popover__close-button"
        size="small"
        @click="accessSharingIntroductionStore.setIntroductionSeen"
      >
        {{ t('v2.close') }}
      </HButton>

      <HButton size="small" @click="handleTryNow">
        {{ t('v2.try.now') }}
      </HButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.access-sharing-introduction-popover {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--white);
  }

  &__actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  &__close-button {
    color: var(--white);
    &:hover:not(&--disabled):not([disabled]) {
      background-color: var(--meteorite-dark);
    }
  }
}
</style>
