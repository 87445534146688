import type { NavigationGuard } from 'vue-router';

import { useAccessManagerStore, useProfileStore } from '@/stores';
import { Route } from '@/types';

export const profileGuard: NavigationGuard = async () => {
  const { redirectParams } = useAccessManagerStore();
  const { isAccessManager } = useProfileStore();

  const isRedirectedToHome = !redirectParams?.name && isAccessManager;

  if (isRedirectedToHome) {
    return { name: Route.Base.HOME };
  }
};
