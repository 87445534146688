export enum DeveloperToolType {
  REPORTS = 'website_reports',
  MONITORING = 'monitoring',
  BULK_UPDATES = 'bulk_updates',
  PRESETS = 'blueprints', // temporary, will be renamed to 'presets'
  OWNERSHIP_TRANSFER = 'ownership_transfer',
}

export enum DeveloperToolsStartTrialMethod {
  START_TRIAL = 'startTrial',
  EDIT_PAYMENT = 'editPayment',
}
