import { router } from '@/router';
import store from '@/store';
import {
  AmplitudeEvent,
  AmplitudeLocation,
  AmplitudeParam,
  Route,
} from '@/types';
import { getDomainParts } from '@/utils/helpers';

const DOMAIN_SIDEMENU_EVENTS = {
  [Route.Domain.DOMAIN_MANAGEMENT]: AmplitudeEvent.Domain.MANAGEMENT_ENTERED,
  [Route.Domain.DOMAIN_DNS]: AmplitudeEvent.Domain.DNS_MANAGEMENT_ENTERED,
  [Route.Domain.DOMAIN_SETTINGS]:
    AmplitudeEvent.Domain.CONTACT_INFORMATION_ENTERED,
  [Route.Domain.DOMAIN_CHECKER]: AmplitudeEvent.Domain.CHECKER_ENTERED,
  [Route.Domain.TRANSFER_DOMAIN]: AmplitudeEvent.Domain.TRANSFER_ENTERED,
};

const VPS_SIDEMENU_EVENTS = {
  [Route.Vps.SERVER_DETAILS]: AmplitudeEvent.Vps.OVERVIEW_ENTERED,
  [Route.Vps.AI_ASSISTANT]: AmplitudeEvent.Vps.AI_ASSISTANT_ENTERED,
  [Route.Vps.SERVER_SETTINGS]: AmplitudeEvent.Vps.SETTINGS_ENTERED,
  [Route.Vps.OPERATING_SYSTEM]: AmplitudeEvent.Vps.OPERATING_SYSTEM_ENTERED,
  [Route.Vps.LICENSES]: AmplitudeEvent.Vps.LICENSES_ENTERED,
  [Route.Vps.SNAPSHOTS_AND_BACKUPS]:
    AmplitudeEvent.Vps.SNAPSHOTS_AND_BACKUPS_ENTERED,
  [Route.Vps.SERVER_USAGE]: AmplitudeEvent.Vps.SERVER_USAGE_ENTERED,
  [Route.Vps.LATEST_ACTIONS]: AmplitudeEvent.Vps.LATEST_ACTIONS_ENTERED,
  [Route.Vps.MALWARE_SCANNER]: AmplitudeEvent.Vps.MALWARE_SCANNER_ENTERED,
  [Route.Vps.FIREWALL]: AmplitudeEvent.Vps.FIREWALL_ENTERED,
  [Route.Vps.VPS_HELP]: AmplitudeEvent.Vps.TUTORIALS_ENTERED,
  [Route.Referral.REFERRALS]: AmplitudeEvent.Vps.REFERRAL_ENTERED,
  [Route.Referral.WELCOME_TO_REFERRALS]: AmplitudeEvent.Vps.REFERRAL_ENTERED,
};
const ROUTE_TO_AMPLITUDE_LOCATION_MAP = {
  [Route.Base.HOME]: AmplitudeLocation.Base.HOME_PAGE,
  [Route.Base.STORE]: AmplitudeLocation.Base.POWER_STORE,
  [Route.Base.HOSTING]: AmplitudeLocation.Base.HOSTING,
};

export const getAmplitudeLocation = (routeName) =>
  ROUTE_TO_AMPLITUDE_LOCATION_MAP[routeName];

export const handleSidemenuAmplitude = (
  pageName,
  amplitudeLogger,
  currentRoute = '',
) => {
  const domainEventName = DOMAIN_SIDEMENU_EVENTS[pageName];
  if (domainEventName) {
    sendSidemenuDomainAmplitude(domainEventName, amplitudeLogger);

    return;
  }

  const vpsEventName = VPS_SIDEMENU_EVENTS[pageName];
  const isVpsPage = Object.values(Route.Vps).includes(currentRoute);
  if (vpsEventName && isVpsPage) {
    sendSidemenuVpsAmplitude(vpsEventName, amplitudeLogger);
  }
};

const sendSidemenuDomainAmplitude = (eventName, amplitudeLogger) => {
  let params = { location: AmplitudeLocation.Base.SIDE_MENU };

  const domain = router.currentRoute?.params?.domain;
  if (domain) {
    const [sld, tld] = getDomainParts(domain);

    params = {
      ...params,
      tld,
      sld,
    };
  }

  amplitudeLogger(eventName, params);
};

const sendSidemenuVpsAmplitude = (eventName, amplitudeLogger) => {
  amplitudeLogger(eventName, {
    location: AmplitudeLocation.Base.SIDE_MENU,
  });
};

export const getDomainCheckerAmplitudeLocation = () =>
  store.state.redirect?.params?.amplitudeLocation ||
  AmplitudeLocation.Base.DOMAIN_CHECKER;

export const convertBooleanToAmplitudeState = (param) =>
  param
    ? AmplitudeParam.ParamState.ENABLED
    : AmplitudeParam.ParamState.DISABLED;
