<script lang="ts" setup>
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal } from '@/composables';
import { authRepo, twoFactorRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import type { IChangePasswordRequest, ModalEmits } from '@/types';
import { FieldType } from '@/types';

type Props = {
  data: {
    submitData: Omit<IChangePasswordRequest, 'twoFactorAuthCode'>;
  };
};

interface Emits extends ModalEmits {}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { closeModal } = useModal(emits);
const { toastr, t } = useGlobals();
const profileStore = useProfileStore();

const isLoadingSubmit = ref(false);

const confirmText = computed(() => t('Confirm'));

const formSchema = computed(
  (): Record<'twoFactorAuthCode', FieldSchema> => ({
    twoFactorAuthCode: {
      type: FieldType.text,
      label: 'Enter code',
      custom: {
        hideOptionalLabel: true,
      },
      validation: {
        required: true,
        twoFactorAuthentication: true,
      },
    },
  }),
);

const showSuccessMessage = () => {
  toastr.s(t('Password has been updated successfully'));
};

const onSuccess = () => {
  showSuccessMessage();
  closeModal();
};

const handleTwoFactorSubmit = async ({
  values: { twoFactorAuthCode },
}: FormValues) => {
  isLoadingSubmit.value = true;

  const [, error] = await authRepo.patchPassword({
    ...props.data.submitData,
    twoFactorAuthCode,
  });

  isLoadingSubmit.value = false;

  if (error) {
    return;
  }

  onSuccess();
};

if (profileStore.isTwoFactorMethodEmail) {
  twoFactorRepo.patchSendEmailCode();
}
</script>

<template>
  <HForm @on-submit="handleTwoFactorSubmit($event)">
    <HFormField
      name="twoFactorAuthCode"
      :schema="formSchema.twoFactorAuthCode"
    />
  </HForm>

  <HModalActions no-padding is-hidden>
    <HButton v-qa-generate h-form-submit :is-loading="isLoadingSubmit">
      {{ confirmText }}
    </HButton>
  </HModalActions>
</template>
