<template>
  <div
    ref="field"
    class="form-field-holder"
    :class="{
      'form-field-holder--focused': focused && !error,
      'form-field-holder--error': error,
    }"
  >
    <div
      class="form-field"
      :class="[
        {
          'form-field--focused': focused && !error && !customError,
          'form-field--error': (error || customError) && !schema.prepend,
          'form-field--disabled': schema.disabled && schema.type !== 'radio',
        },
        `form-field--${schema.type}`,
      ]"
    >
      <Component
        :is="fieldComponent"
        ref="fieldComponent"
        :schema="schemaData"
        :reset="reset"
        :placeholder="schema.label && !focused ? '' : schema.placeholder"
        :no-trans="noTrans"
        :custom-error="schema.prepend && error ? error.text : customError"
        :append-field-tooltip="appendFieldTooltip"
        @on-change="onChange($event)"
        @on-blur="focused = false"
        @on-focus="focused = true"
      />
    </div>
    <Trans
      v-if="errorText && !hideErrors"
      class="form-field-error"
      :class="errorClass"
      :translate-params="errorParams"
    >
      {{ errorText }}
    </Trans>
    <HFormFieldHint
      v-if="showHint"
      :hint="schema.hint"
      :limit="limit"
      :value="value"
    />
    <HFormPasswordTags
      v-if="schema.type === 'password'"
      v-show="schema.passwordTags || schema.passwordVerification"
      :focused="focused"
      :password="value"
      :confirmed-password="schema.confirmedPassword"
      :message="schema.message"
      :password-tags="schema.passwordTags"
      :password-verification="schema.passwordVerification"
      :custom-requirements="schema.customRequirements"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import HFormFieldHint from './HFormFieldHint';
import HFormPasswordTags from './HFormPasswordTags';

import { useModal } from '@/composables';
import { toTitleCase } from '@/utils/helpers';

export default {
  components: {
    HFormFieldHint,
    HFormPasswordTags,
  },
  inject: {
    modalOpened: {
      default: false,
    },
  },
  props: {
    schema: Object,
    name: String,
    customError: String,
    appendFieldTooltip: String,
    noTrans: { type: Boolean, default: true },
    absoluteError: Boolean,
    hideErrors: Boolean,
  },
  emits: ['change'],
  data: () => ({
    error: null,
    focused: false,
    reset: false,
    value: '',
    fieldComponent: undefined,
    schemaData: {},
  }),
  watch: {
    schema(newValue, oldValue) {
      if (JSON.stringify(oldValue) === JSON.stringify(newValue)) return;
      this.schemaData = newValue;
    },
  },
  setup() {
    const { setModalClosingType } = useModal();

    return {
      setModalClosingType,
    };
  },
  computed: {
    showHint() {
      const hasHint = this.schema.hint || this.schema.hint === '';
      const hasVisibleLimit = this.limit?.visible;

      return (hasHint || hasVisibleLimit) && !this.error;
    },
    errorClass() {
      return {
        'form-field--error-absolute': this.absoluteError,
      };
    },
    limit() {
      if (!this.schema?.validation?.max?.visible) return undefined;

      return this.schema.validation.max;
    },
    errorParams() {
      if (this.error && this.error.params) return this.error.params;

      return {};
    },
    errorText() {
      return (this.error && this.error.text) || this.error || this.customError;
    },
  },
  created() {
    this.getFieldComponent();
    this.schemaData = this.schema;

    if (this.modalOpened) {
      this.setModalClosingType('input');
    }
  },
  mounted() {
    this.$refs.field.name = this.name || this.schema.name;
    this.$refs.field.validate = this.validate;
    this.$refs.field.resetField = this.resetField;
  },
  methods: {
    toTitleCase,
    onChange(schema) {
      let value = schema.value.value ?? schema.value;

      if (Object.keys(schema.value).length === 0) {
        value = '';
      }
      this.value = value;
      this.error = schema.error;

      if (schema.type !== 'checkbox') {
        this.value = `${schema.selectedPrepend ?? ''}${this.value}${
          schema.selectedAppend ?? ''
        }`;
      }

      const eventValue = {
        ...schema,
        value: this.value,
        label: schema.value.label,
      };

      this.$emit('change', eventValue);
      const event = new CustomEvent('on-change', {
        detail: eventValue,
      });
      this.$refs.field.dispatchEvent(event);
    },
    getFieldComponent() {
      if (['text', 'password', 'number', 'date'].includes(this.schema.type)) {
        return (this.fieldComponent = defineAsyncComponent(() =>
          import(`@/components/HFormDeprecated/HFormFieldInput.vue`),
        ));
      }

      const getType = () => {
        const type = this.toTitleCase(this.schema.type);

        if (type === '') return 'HFormFieldDeprecated';

        return `HFormField${type}`;
      };

      this.fieldComponent = defineAsyncComponent(() =>
        import(`@/components/HFormDeprecated/${getType()}.vue`),
      );
    },
    async validate() {
      this.schemaData.touched = false;
      await this.$nextTick();
      this.schemaData.touched = true;
    },
    resetField() {
      this.reset = true;
      this.$nextTick(() => (this.reset = false));
    },
  },
};
</script>
