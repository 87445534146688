import { defineStore } from 'pinia';
import { ref } from 'vue';

import { h5gWebsitesRepo } from '@/repositories';

export const useH5GFileManagerStore = defineStore('h5gFileManagerStore', () => {
  const websiteFileManagerUrl = ref('');
  const isFileManagerUrlLoading = ref(false);

  const fetchFileManagerUrl = async (websiteUid: string) => {
    isFileManagerUrlLoading.value = true;
    const [{ data }, error] = await h5gWebsitesRepo.getWebsiteFileManagerUrl(
      websiteUid,
    );

    isFileManagerUrlLoading.value = false;

    if (!data || error) {
      return;
    }

    websiteFileManagerUrl.value = data;
  };

  const $reset = () => {
    websiteFileManagerUrl.value = '';
    isFileManagerUrlLoading.value = false;
  };

  return {
    $reset,
    fetchFileManagerUrl,
    websiteFileManagerUrl,
    isFileManagerUrlLoading,
  };
});
