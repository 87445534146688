import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const EXPECTED_ERROR_MESSAGES = [
  'The given passwd has appeared in a data leak. Please choose a different passwd.',
];

export default {
  url: `${process.env.VITE_API_REST}/v2/auto-installer`,

  async getAutoInstaller(params) {
    return await hAsync(http.get(`${this.url}`, { params }));
  },

  async postAutoInstaller(data) {
    return await hAsync(
      http.post(`${this.url}`, data, {
        getIsResponseWhitelisted: (response) =>
          EXPECTED_ERROR_MESSAGES.some(
            (message) => message === response.data?.error?.message,
          ),
      }),
    );
  },

  async getAutoInstallerApplication(params) {
    return await hAsync(http.get(`${this.url}/application`, { params }));
  },

  async getAppInstallations(params) {
    return await hAsync(http.get(`${this.url}/installed`, { params }));
  },

  async destroyAutoInstaller(id) {
    return await hAsync(http.delete(`${this.url}/installed/${id}`));
  },

  async patchAutoInstallerUpdate({ id, autoup, domain }) {
    return await hAsync(
      http.patch(`${this.url}/installed/${id}`, {
        params: { domain },
        autoup,
      }),
    );
  },
};
