import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route, Email } from '@/types';

const hostingEmailsGuard: NavigationGuard = async (to) => {
  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const currentEmail = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.HOSTINGER,
    to.params.domain,
  );

  const currentId = getCurrentEmailId(currentEmail);

  store.commit('SET_EMAIL_ORDER_ID', {
    currentId,
    domain: to.params.domain,
  });

  if (currentId) {
    to.params.id = currentId;
  }

  if (!currentEmail || currentEmail.status === 'suspended') {
    return { name: Route.Email.EMAILS, query: to.query };
  }
};

const getCurrentEmailId = (currentEmail: any) =>
  currentEmail?.idempotencyKey || currentEmail?.orderId;

export default hostingEmailsGuard;
