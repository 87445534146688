import dayjs from 'dayjs';

import hdomainsModule from './hdomainsModule';

import { domainsRepo } from '@/repositories';
import freeDomainModule from '@/store/modules/domain/freeDomainModule';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';

const initial = {
  domainInfo: null,
  domainInfoLoaded: false,
  domainNotPointingBannerHidden: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setDomainInfo(state, { requestDomain, data }) {
      setForDomain(state, requestDomain, 'domainInfo', data);
    },
    setDomainInfoLoaded(state, { requestDomain, data }) {
      setForDomain(state, requestDomain, 'domainInfoLoaded', data);
    },
    SET_DOMAIN_NOT_POINTING_HIDDEN(state, data) {
      setForDomain(
        state,
        currentOrderId(),
        'domainNotPointingBannerHidden',
        data,
      );
    },
  },
  getters: {
    domainRegistrationUrl: (state) => {
      const info = getStateVariable(state, 'domainInfo');

      return info?.registrationCta;
    },
    getDomainInfo: (state) => getStateVariable(state, 'domainInfo'),
    getDomainInfoLoaded: (state) => getStateVariable(state, 'domainInfoLoaded'),
    isNewDomain: (state) => {
      const info = getStateVariable(state, 'domainInfo');

      return info ? dayjs().diff(dayjs.unix(info.regTime), 'day') <= 5 : false;
    },
    isDomainRegistered: (state) => {
      const info = getStateVariable(state, 'domainInfo');

      return !!info?.regTime;
    },
    domainInvoiceLink: (state) => {
      const info = getStateVariable(state, 'domainInfo');

      return info?.purchaseInfo?.invoiceLink;
    },
    getDomainNotPointingHidden: (state) =>
      getStateVariable(state, 'domainNotPointingBannerHidden'),
  },

  actions: {
    async domainInfoIndex(context) {
      const [{ data, requestDomain }, err] = await domainsRepo.getDomainInfo();

      if (!err) {
        context.commit('setDomainInfo', { requestDomain, data });
        context.commit('setDomainInfoLoaded', { requestDomain, data: true });
      }
    },
  },
  modules: {
    freeDomain: freeDomainModule,
    hdomainsModule,
  },
};
