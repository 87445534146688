import { whIpAccessRuleRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  ipRules: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingOtherIpManagerIpRules(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'ipRules', data);
    },
    addHostingOtherIpManagerAllowedIps(state, data) {
      const currentStateVariable = getStateVariable(state, 'ipRules');
      data.allow = true;
      currentStateVariable.push(data);
      setForDomain(state, null, 'ipRules', currentStateVariable);
    },
    addHostingOtherIpManagerDeniedIps(state, data) {
      const currentStateVariable = getStateVariable(state, 'ipRules');
      data.allow = false;
      currentStateVariable.push(data);
      setForDomain(state, null, 'ipRules', currentStateVariable);
    },
    setHostingOtherIpManagerLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingOtherIpManagerIpRules: (state) =>
      getStateVariable(state, 'ipRules'),
    getHostingOtherIpManagerLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingOtherIpManagerIndex(context) {
      const [{ data, requestDomain }, error] = await whIpAccessRuleRepo.getIps(
        getCurrentOrderDetails(),
      );

      if (!error) {
        context.commit('setHostingOtherIpManagerIpRules', {
          data,
          requestDomain,
        });
      }
    },
    async hostingOtherIpManagerAllowIPStore(context, data) {
      context.commit('addHostingOtherIpManagerAllowedIps', data);

      const response = await whIpAccessRuleRepo.postAllowedIps(
        getCurrentOrderDetails(),
        data,
      );

      context.dispatch('hostingOtherIpManagerIndex', true);

      return response;
    },
    async hostingOtherIpManagerDenyIPStore(context, data) {
      context.commit('addHostingOtherIpManagerDeniedIps', data);

      const response = await whIpAccessRuleRepo.postDeniedIps(
        getCurrentOrderDetails(),
        data,
      );

      context.dispatch('hostingOtherIpManagerIndex', true);

      return response;
    },
    async hostingOtherIpManagerDestroy(context, data) {
      await whIpAccessRuleRepo.deleteAllowedIps(
        getCurrentOrderDetails(),
        data.pwkey,
      );

      context.dispatch('hostingOtherIpManagerIndex', true);
    },
  },
};
