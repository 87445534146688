import { computed } from 'vue';
import { useRoute } from 'vue-router';

import {
  useBrands,
  useResourceFilters,
  useResourceMapper,
  useVpsPlan,
} from '@/composables';
import { Client, PeriodUnit, Route, HRESOURCES_TYPE } from '@/types';
import {
  getMonthlyPrice,
  minorUnitCurrencyData,
} from '@/utils/services/chargebeeCurrencyService';
import currencyService from '@/utils/services/currencyService';

export const useVpsSaleCampaign = () => {
  const { hasBrand } = useBrands();
  const { getVpsPlanById } = useVpsPlan();
  const { activeResources, upgradableHostingResources } = useResourceMapper();
  const route = useRoute();

  const { isDomainOnlyUser } = useResourceFilters();

  const buyVpsPages = [Route.Base.BUY_VPS, Route.Vps.VPS_PLANS] as string[];

  const isEligibleForKVMSale = computed(() =>
    hasBrand([
      Client.BrandId.Hostinger.COM,
      Client.BrandId.Hostinger.CO_UK,
      Client.BrandId.Hostinger.IN,
      Client.BrandId.Hostinger.FR,
      Client.BrandId.Hostinger.ES,
      Client.BrandId.Hostinger.COM_BR,
    ]),
  );

  const isHostingResourceAvailable = computed(() =>
    activeResources.value.some(({ type }) => type === HRESOURCES_TYPE.HOSTING),
  );

  const isWebsiteBuilderAvailable = computed(() =>
    activeResources.value.some(
      ({ type }) => type === HRESOURCES_TYPE.WEBSITE_BUILDER,
    ),
  );

  const isVpsResourceAvailable = computed(() =>
    activeResources.value.some(
      ({ type }) => type === HRESOURCES_TYPE.VIRTUAL_MACHINE,
    ),
  );

  const isVpsSaleAvailable = computed(() => {
    const hasHosting = isHostingResourceAvailable.value;
    const hasVps = isVpsResourceAvailable.value;
    if (!hasHosting && hasVps) return true;

    const isServerPage = route.path.includes('server');
    const isBuyVpsPage = buyVpsPages.includes(route.name as string);
    const allowedRoutes = isServerPage || isBuyVpsPage;

    const hasOnlyWebsiteBuilder =
      !upgradableHostingResources.value.length &&
      isWebsiteBuilderAvailable.value;
    if ((isDomainOnlyUser || hasOnlyWebsiteBuilder) && isBuyVpsPage) {
      return true;
    }

    return hasHosting && hasVps && allowedRoutes;
  });

  const getVpsSalePrice = (id: string) => {
    const plan = getVpsPlanById(id);
    const price = plan?.prices.find(
      ({ period, periodUnit }) =>
        period === 2 && periodUnit === PeriodUnit.YEAR,
    );
    if (!price) return null;

    const oldPrice = getMonthlyPrice(
      price.period,
      price.periodUnit,
      price.basePrice,
    );
    const newPrice = getMonthlyPrice(
      price.period,
      price.periodUnit,
      price.firstPeriodPrice,
    );

    return {
      old: currencyService.format(
        minorUnitCurrencyData({
          minorCurrencyUnit: oldPrice,
          currencyCode: null,
        })?.price,
      ),
      new: currencyService.format(
        minorUnitCurrencyData({
          minorCurrencyUnit: newPrice,
          currencyCode: null,
        })?.price,
      ),
    };
  };

  return {
    isEligibleForKVMSale,
    isVpsSaleAvailable,
    isVpsResourceAvailable,
    isHostingResourceAvailable,
    getVpsSalePrice,
  };
};
