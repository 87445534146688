import { computed } from 'vue';

import {
  HIcon,
  HostingerProPanel,
  Referral,
  type ReferralsListItem,
} from '@/types';

const DENIAL_REASON_MAP = {
  [Referral.RewardDenialReason.INVALID_PRODUCT]:
    'v2.referrals.payout.tooltip.denial_reason.invalid_product',
  [Referral.RewardDenialReason.INVALID_PERIOD]:
    'v2.referrals.payout.tooltip.denial_reason.invalid_period',
  [Referral.RewardDenialReason.SUBSCRIPTION_CANCELED]:
    'v2.referrals.payout.tooltip.denial_reason.subscription_canceled',
  [Referral.RewardDenialReason.NOT_FIRST_HOSTING_PURCHASE]:
    'v2.referrals.payout.tooltip.denial_reason.not_first_hosting_purchase',
};

export const useEarningsStatusMapper = (item: ReferralsListItem) => {
  const text = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return 'Approved';
      case HostingerProPanel.ProRewardStatus.DENIED:
        return 'Declined';
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return 'Qualified';
      case HostingerProPanel.ProRewardStatus.EXPIRED:
        return 'Expired';
      default:
        return 'Pending';
    }
  });

  const iconMetadata = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return {
          icon: HIcon.ICON_CHECK_CIRCLE_LIGHT,
          'icon-name': 'Approved',
          color: 'success',
        };
      case HostingerProPanel.ProRewardStatus.DENIED:
        return {
          icon: HIcon.ICON_HIGHLIGHT_OFF_OUTLINE,
          'icon-name': 'Declined',
          color: 'danger',
        };
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return {
          icon: HIcon.ICON_CHECK_CIRCLE_LIGHT,
          'icon-name': 'Qualified',
          color: 'warning-dark',
        };
      case HostingerProPanel.ProRewardStatus.EXPIRED:
        return {
          icon: HIcon.ICON_CALENDAR_EXPIRED,
          'icon-name': 'Expired',
          color: 'danger',
        };
      default:
        return {
          icon: HIcon.ICON_UPDATE,
          'icon-name': 'Pending',
          color: 'gray',
        };
    }
  });

  const tooltip = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return '';
      case HostingerProPanel.ProRewardStatus.DENIED:
        return item.denialReason && DENIAL_REASON_MAP[item.denialReason]
          ? DENIAL_REASON_MAP[item.denialReason]
          : 'v2.referrals.payout.tooltip.denial_reason.generic';
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return 'v2.referrals.payout.tooltip.qualified';
      case HostingerProPanel.ProRewardStatus.EXPIRED:
        return 'v2.referrals.payout.tooltip.expired';
      default:
        return 'v2.referrals.payout.tooltip.pending';
    }
  });

  return { text, iconMetadata, tooltip };
};
