import type { NavigationGuard } from 'vue-router';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { amplitudeV2 } from '@/plugins/amplitudeV2';
import { AmplitudeEvent, Route } from '@/types';

export const developerToolsGuard: NavigationGuard = async (to) => {
  const { isDeveloperToolsEnabled, hasAccessToDeveloperToolsDashboard } =
    useDeveloperTools();
  const {
    isSubscriptionsAndResourcesLoaded,
    fetchResourcesAndSubscriptions,
    isSubscriptionsAndResourcesLoading,
  } = useResourceMapper();

  if (
    !isSubscriptionsAndResourcesLoaded.value &&
    !isSubscriptionsAndResourcesLoading.value
  ) {
    await fetchResourcesAndSubscriptions();
  }

  if (isDeveloperToolsEnabled.value) {
    if (!hasAccessToDeveloperToolsDashboard.value) {
      amplitudeV2(AmplitudeEvent.DeveloperTools.NEW_OFFER_SHOWN);

      return to.name === Route.DeveloperTools.PURCHASE
        ? undefined
        : { name: Route.DeveloperTools.PURCHASE };
    }

    amplitudeV2(AmplitudeEvent.DeveloperTools.ENTER);

    return;
  }

  return { name: Route.Base.HOME };
};

export const developerToolsDashboardGuard: NavigationGuard = async () => {
  const { hasAccessToDeveloperToolsDashboard } = useDeveloperTools();
  if (!hasAccessToDeveloperToolsDashboard.value) {
    return { name: Route.DeveloperTools.PURCHASE };
  }
};
