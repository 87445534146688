import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/access-logs`,

  async getAccessLogs(
    {
      perPage = 10,
      page = 1,
      orderId,
      username,
      domain,
      dateFrom,
      sortBy,
      ...params
    } = {},
    provideHeaders = false,
  ) {
    const headers = provideHeaders
      ? {
          headers: {
            [Header.USERNAME]: username,
            [Header.ORDER_ID]: orderId,
          },
          noHostingHeaders: true,
        }
      : {};

    return await hAsync(
      http.get(this.url, {
        params: {
          username,
          orderId,
          perPage,
          page,
          domain,
          dateFrom,
          sortBy,
          ...params,
        },
        ...headers,
      }),
    );
  },
  async getTopRequests(
    orderId,
    username,
    domain,
    dateFrom,
    groupBy,
    provideHeaders,
  ) {
    const headers = provideHeaders
      ? {
          headers: {
            [Header.USERNAME]: username,
            [Header.ORDER_ID]: orderId,
          },
          noHostingHeaders: true,
        }
      : {};

    return await hAsync(
      http.get(`${this.url}/top-requests`, {
        params: {
          orderId,
          dateFrom,
          groupBy,
          ...(groupBy !== 'domain' && { username, domain }),
        },
        ...headers,
      }),
    );
  },
};
