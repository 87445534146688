import dayjs from 'dayjs';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import HBillingPayInvoiceModal from '@/components/Modals/HModal/HBilling/HBillingPayInvoiceModal.vue';
import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { useGlobals, useModal } from '@/composables';
import { ONE_MINUTE_IN_SECONDS } from '@/data/globalConstants';
import { hBillingRepo } from '@/repositories';
import { useResourcesStore, useWebsitesStore } from '@/stores';
import type {
  BillingSubscription,
  EstimatePlanRenew,
  HModalProps,
  Order,
  ReactivateModalData,
  RequestConfig,
  HAsyncError,
  SubscriptionReactivation,
  SubscriptionResourceType,
} from '@/types';
import {
  ALLOWED_CB_REACTIVATION_SERVICES,
  ALLOWED_CB_RESTORE_SERVICES,
  ALLOWED_CB_SUSPENDED_STATUSES,
  BillingOrder,
  HBilling,
  HBillingSubscriptionResourceType,
  HRESOURCES_TYPE,
  INVALID_REASONS,
  SubscriptionName,
  SubscriptionStatus,
} from '@/types';
import { timeout, toUnicode } from '@/utils/helpers';
import { getServiceType } from '@/utils/helpers/hBillingHelper';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';
import { errorLogger } from '@/utils/services/errorLogging';

export const useSubscriptions = () => {
  const { openModal } = useModal();
  const store = useStore();
  const route = useRoute();
  const resourcesStore = useResourcesStore();
  const { t } = useGlobals();
  const websitesStore = useWebsitesStore();

  const isLoadingSubscriptions = computed(
    () => store.state.subscriptions.loading,
  );

  const canServiceBeReactivated = ({
    status,
    service,
    reason,
    suspendedAt,
  }: SubscriptionReactivation) => {
    if (
      !INVALID_REASONS.includes(reason) &&
      ALLOWED_CB_SUSPENDED_STATUSES.includes(status) &&
      ALLOWED_CB_REACTIVATION_SERVICES.includes(
        service?.toLowerCase() as SubscriptionResourceType,
      )
    ) {
      const suspendedAtDate = dayjs(suspendedAt);
      const daysPassed = dayjs().diff(suspendedAtDate, 'day');

      return daysPassed < BillingOrder.Timing.REACTIVATION_PERIOD_IN_DAYS;
    }

    return false;
  };

  const isDomainTransfer = (subscription: BillingSubscription) =>
    subscription?.resourceType === HRESOURCES_TYPE.DOMAIN_TRANSFER;

  const isDomainSubscription = (subscription: BillingSubscription) =>
    subscription?.resourceType === HBillingSubscriptionResourceType.DOMAIN ||
    subscription?.resource?.type === HRESOURCES_TYPE.DOMAIN;

  const getResourceById = (id: string) =>
    resourcesStore?.getResourceByIdempotencyKey(id) ||
    resourcesStore?.getResourceByReferenceId(id);

  const getSubscriptionById = (
    id: string | null | undefined,
  ): BillingSubscription =>
    store.getters['subscriptions/getSubscriptionById'](id);

  const getSubscriptionIdByOrderId = (orderId: string) =>
    getResourceById(orderId)?.chargebeeSubscriptionId;

  const getSubscriptionByOrderId = (orderId: string) => {
    const subscription =
      getSubscriptionById(orderId) ||
      getSubscriptionById(getSubscriptionIdByOrderId(orderId));

    const resource = getResourceById(orderId);

    return {
      ...subscription,
      resource,
    };
  };

  const canOrderBeReactivated = (orderId: string) => {
    const subscription = getSubscriptionByOrderId(
      orderId,
    ) as BillingSubscription;

    return subscription?.canReactivate;
  };

  const canRenewOrReactivateSubscription = (orderId: string) => {
    const subscription = getSubscriptionByOrderId(
      orderId,
    ) as BillingSubscription;

    if (!subscription) {
      return false;
    }

    return canServiceBeRenewed(orderId) || canOrderBeReactivated(orderId);
  };

  const canServiceBeRenewed = (serviceId: string) => {
    const subscription = getSubscriptionByOrderId(
      serviceId,
    ) as BillingSubscription;

    return subscription?.canRenew;
  };

  const subscriptionTitle = (subscription: BillingSubscription) => {
    const { items, metadata, resource } = subscription;

    const subscriptionName = isDomainTransfer(subscription)
      ? SubscriptionName.DOMAIN_TRANSFER
      : items[0].name;
    const domain = metadata?.domain || resource?.metadata?.domain;

    return domain
      ? t(`${subscriptionName} - {domainName}`, {
          domainName: toUnicode(domain),
        })
      : t(subscriptionName);
  };

  const getItemPriceId = (subscription?: BillingSubscription) => {
    if (!subscription?.items?.length) return;

    return subscription?.items[0].itemPriceId;
  };

  const hasSubscriptionUnpaidInvoices = (
    order: Order | BillingSubscription,
  ) => {
    const subscription = getSubscriptionByOrderId(
      order.id,
    ) as BillingSubscription;

    if (!subscription) {
      return false;
    }

    if (subscription?.invoices) {
      return subscription?.invoices.some(
        (invoice) => invoice.status !== HBilling.InvoiceStatus.PAID,
      );
    }

    return (
      subscription.dueInvoicesCount &&
      !isSubscriptionCancelled(subscription, order)
    );
  };

  const hasSubscriptionPendingInvoice = (subscriptionId: string) =>
    !!store.getters['subscriptions/getSubscriptionPaymentDueInvoices'](
      subscriptionId,
    )?.length;

  const isSubscriptionCancelled = (
    subscription: BillingSubscription,
    order: Order | BillingSubscription,
  ) => {
    if (
      [subscription?.status, order?.status].includes(
        SubscriptionStatus.CANCELLED,
      )
    ) {
      return true;
    }

    return false;
  };

  const openReactivateSubscriptionModalBySubscription = (
    subscription: BillingSubscription,
    amplitudeSource?: string,
    amplitudeLocation?: string,
    onSuccess?: Function,
  ) => {
    const item = subscription?.items?.find(({ resourceType }) =>
      [...ALLOWED_CB_REACTIVATION_SERVICES].includes(
        resourceType.toLowerCase() as SubscriptionResourceType,
      ),
    );

    if (!item) return;

    openReactivateSubscriptionModal({
      amplitudeSource,
      amplitudeLocation,
      itemPriceId: item?.itemPriceId,
      serviceType: getServiceType(item?.resourceType),
      subscriptionId: subscription.id,
      service: subscriptionTitle(subscription),
      onSuccess: async () => {
        if (onSuccess) {
          onSuccess();

          return;
        }
        await timeout(9000);
        store.dispatch('fetchHostingOrders');
        store.dispatch('emails/fetchEmails');
        fetchSubscriptionsForceSilently();
        await timeout(2000);
        websitesStore.fetchWebsitesList();
      },
    });
  };

  const openReactivateSubscriptionModalByOrderId = (
    orderId: string,
    amplitudeSource?: string,
    amplitudeLocation?: string,
    onSuccess?: Function,
  ) => {
    const subscription = getSubscriptionByOrderId(orderId);
    openReactivateSubscriptionModalBySubscription(
      subscription,
      amplitudeSource,
      amplitudeLocation,
      onSuccess,
    );
  };

  const openRestoreSubscriptionModal = (
    subscription: BillingSubscription,
    amplitudeLocation?: string,
    options?: HModalProps,
    amplitudeSource?: string,
  ) => {
    const item = subscription?.items?.find(({ resourceType }) =>
      [...ALLOWED_CB_RESTORE_SERVICES].includes(
        resourceType.toLowerCase() as SubscriptionResourceType,
      ),
    );

    if (!item) return;

    openModal({
      component: { RenewModal },
      data: {
        title: t('Restore Your {service}', {
          service: subscriptionTitle(subscription),
        }),
        subtitle: t('Choose a billing period and finish the renewal process'),
        itemPriceId: item?.itemPriceId,
        subscriptionId: subscription.id,
        reactivate: canOrderBeReactivated(subscription.id),
        quantity: 1,
        serviceType: getServiceType(item?.resourceType),
        onSuccess: async () => {
          await timeout(1000);
          await store.dispatch('fetchHostingOrders');

          options?.onSuccess?.();
        },
        amplitudeSource,
        amplitudeLocation,
        ...options,
      },
      steps: [
        {
          hideX: true,
        },
      ],
    });
  };

  const openRestoreSubscriptionModalByOrderId = (
    orderId: string,
    amplitudeSource?: string,
    options?: HModalProps,
  ) => {
    const subscription = getSubscriptionByOrderId(orderId);

    openRestoreSubscriptionModal(subscription, amplitudeSource, options);
  };
  const openReactivateSubscriptionModal = ({
    itemPriceId,
    serviceType,
    subscriptionId,
    service,
    onSuccess,
    amplitudeSource,
    amplitudeLocation,
  }: ReactivateModalData) => {
    const reactivate = canOrderBeReactivated(subscriptionId);

    const title = reactivate
      ? t('Reactivate your {service}', { service })
      : t('Renew your {service}', { service });

    openModal({
      component: { RenewModal },
      data: {
        title,
        subtitle: t('Review the details and proceed to checkout'),
        itemPriceId,
        subscriptionId,
        reactivate: canOrderBeReactivated(subscriptionId),
        quantity: 1,
        serviceType,
        onSuccess,
        amplitudeSource,
        amplitudeLocation,
      },
      steps: [
        {
          hideX: true,
        },
      ],
    });
  };

  const openHBillingPayInvoiceModal = (
    subscriptionId: string,
    onSuccess: () => void,
  ) => {
    openModal({
      component: { HBillingPayInvoiceModal },
      data: {
        subscriptionId,
        onSuccess,
      },
    });
  };

  const enableAutoRenew = async (subscriptionId: string) => {
    const [data, error] = await store.dispatch(
      'subscriptions/billingSubscriptionsEnableAutoRenew',
      {
        subscriptionId,
      },
    );

    if (error) {
      errorLogger.logError(
        new Error('Failed to enable auto renew', { cause: error }),
      );
    }

    return [data, error];
  };

  const fetchSubscriptions = async (
    options: {
      requestConfig?: RequestConfig;
      isSilentRefetch?: boolean;
    } = {},
  ) => await store.dispatch('fetchHostingOrders', options);

  const fetchSubscriptionsSilentlyWithCache = async () =>
    fetchSubscriptions({
      isSilentRefetch: true,
      requestConfig: {
        cache: ONE_MINUTE_IN_SECONDS,
      },
    });

  const fetchSubscriptionsForceSilently = async () =>
    fetchSubscriptions({
      isSilentRefetch: true,
      requestConfig: {
        overrideCache: true,
      },
    });

  const checkIsRenewInitiatedAndOpenModal = () => {
    if (!route.query?.renewSubscriptionId) return;

    const subscription = getSubscriptionById(
      route.query.renewSubscriptionId as BillingSubscription['id'],
    );

    if (!subscription) return;
    openReactivateSubscriptionModal({
      itemPriceId: subscription.items[0].itemPriceId,
      serviceType: getServiceType(subscription.items[0].resourceType),
      subscriptionId: subscription.id,
      service: subscriptionTitle(subscription),
      onSuccess: () => store.dispatch('billingItems/getBillingItems'),
    });
  };

  interface ISubscriptionRenewalEstimate extends EstimatePlanRenew {
    monthlyAmount: number;
  }

  const getSubscriptionRenewalEstimate = async (
    subscriptionId: string,
    hideToastr: boolean,
  ): Promise<
    | [{ data: ISubscriptionRenewalEstimate } | { data: null }, HAsyncError]
    | null
  > => {
    await store.dispatch(
      'subscriptions/billingGetSubscriptionsWithScheduledChanges',
    );

    const subscription = getSubscriptionById(subscriptionId);

    if (!subscription) return null;

    const [{ data }, error] = await hBillingRepo.estimatePlanRenew(
      { subscriptionId },
      hideToastr,
    );

    if (error) {
      return [{ data }, error];
    }

    const billingPeriodInMonths =
      monthsPerPeriodUnit(subscription.billingPeriodUnit) *
      parseInt(subscription.billingPeriod);

    const monthlyAmount = data.subTotal / billingPeriodInMonths;

    return [
      {
        data: { ...data, monthlyAmount },
      },
      error,
    ];
  };

  return {
    isLoadingSubscriptions,
    checkIsRenewInitiatedAndOpenModal,
    getItemPriceId,
    openRestoreSubscriptionModal,
    openRestoreSubscriptionModalByOrderId,
    openReactivateSubscriptionModalBySubscription,
    openReactivateSubscriptionModalByOrderId,
    canServiceBeReactivated,
    canOrderBeReactivated,
    canServiceBeRenewed,
    openHBillingPayInvoiceModal,
    getSubscriptionByOrderId,
    hasSubscriptionUnpaidInvoices,
    getSubscriptionIdByOrderId,
    getSubscriptionById,
    hasSubscriptionPendingInvoice,
    enableAutoRenew,
    fetchSubscriptions,
    fetchSubscriptionsForceSilently,
    fetchSubscriptionsSilentlyWithCache,
    getSubscriptionRenewalEstimate,
    canRenewOrReactivateSubscription,
    isDomainSubscription,
  };
};
