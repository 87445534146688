import type { NavigationGuard } from 'vue-router';

import TransferLockedErrorModal from '@/components/Modals/HModal/HDomains/TransferLockedErrorModal.vue';
import { useTransferValidation } from '@/composables/hDomains/useTransferValidation';
import { useGlobals } from '@/composables/useGlobals';
import { useModal } from '@/composables/useModal';
import { useSubscriptionsStore } from '@/stores/subscriptionsStore';
import { Route } from '@/types';

const retrieveTransferSubscription = async (domain: string) => {
  const { getDomainTransferSubscriptionByDomain, fetchAllSubscriptions } =
    useSubscriptionsStore();

  const subscription = getDomainTransferSubscriptionByDomain(domain as string);

  if (subscription) {
    return subscription;
  }

  await fetchAllSubscriptions({
    overrideCache: true,
  });

  return getDomainTransferSubscriptionByDomain(domain as string);
};

const transferSetupGuard: NavigationGuard = async (to, from, next) => {
  const { toastr, t } = useGlobals();

  const { subscriptionId, domain } = to.params;
  const { checkIsDomainTransferable, isLockedError, transferableErrorMessage } =
    useTransferValidation();
  const { openModal } = useModal();

  await checkIsDomainTransferable(domain as string, {
    isCustomerResourceCheckSkipped: true,
  });

  if (isLockedError.value) {
    next(from);

    openModal({ component: { TransferLockedErrorModal } });

    return;
  }

  if (transferableErrorMessage.value) {
    next(from);

    toastr.e(t(transferableErrorMessage.value));

    return;
  }

  if (subscriptionId) {
    next();

    return;
  }

  const transferSubscription = await retrieveTransferSubscription(
    domain as string,
  );
  const transferSubscriptionId = transferSubscription?.id as string;

  if (!transferSubscriptionId) {
    next({ name: Route.Base.HOME });

    return;
  }

  next({
    ...to,
    params: { ...to.params, subscriptionId: transferSubscriptionId },
  } as any);
};

export default transferSetupGuard;
