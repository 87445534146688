import {
  type HAsyncResponse,
  type IDataCenter,
  type IExternalDomain,
  type IExternalDomainRequest,
  type IMalwareAutoProtectionRequest,
  type IMalwareScannerCredentials,
  type IMalwareScannerFileResponse,
  type IMalwareScannerMetrics,
  type IRequestAccapted,
  type IServerOngoingAction,
  type IServerSetupRequest,
  type IServerSnapshot,
  type IServerUsage,
  type ISshKey,
  type ITransferAvailability,
  type IVpsFirewall,
  type IVpsFirewallRules,
  type MalwareScannerTimeline,
  type RequestConfig,
  type ServerAction,
  type ServerBackup,
  type ServerBackupSchedule,
  type ServerOS,
  type VpsServer,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  root: process.env.VITE_API_VPS,
  url: `${process.env.VITE_API_VPS}/v1/virtual-machine`,
  urlPro: `${process.env.VITE_API_VPS}/v1/virtual-machine-pro`,

  async getProVpsList(config?: RequestConfig) {
    return await hAsync<VpsServer[]>(http.get(`${this.urlPro}`, config));
  },
  async getActions(
    serverId: string,
    params: any,
    settings: RequestConfig,
  ): HAsyncResponse<ServerAction[]> {
    return await hAsync(
      http.get(`${this.url}/${serverId}/actions-log`, {
        params,
        ...settings,
      }),
    );
  },
  async getProcessList(serverId: string) {
    return await hAsync(http.get(`${this.url}/${serverId}/process-list`));
  },
  async promptAiAssistant(serverId: string, payload: any) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/ai-prompt`, payload),
    );
  },
  async getBackups(
    serverId: string,
    params: any,
    settings: RequestConfig,
  ): HAsyncResponse<ServerBackup[]> {
    return await hAsync(
      http.get(`${this.url}/${serverId}/backups`, {
        ...params,
        ...settings,
      }),
    );
  },
  async restoreBackup({
    serverId,
    backupId,
  }: {
    serverId: string;
    backupId: string;
  }) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/backups/${backupId}`, null),
    );
  },
  async getDataCentersActive() {
    return await hAsync<IDataCenter[]>(
      http.get(`${this.root}/v1/data-center`, {
        cache: 3600,
      }),
    );
  },
  async getDataCentersAll() {
    return await hAsync<IDataCenter[]>(
      http.get(`${this.root}/v1/data-centers`, {
        cache: 3600,
      }),
    );
  },
  async getTemplates() {
    return await hAsync<ServerOS[]>(
      http.get(`${this.root}/v1/template`, {
        cache: 600,
      }),
    );
  },
  async setup(serverId: number, payload: IServerSetupRequest, params: any) {
    return await hAsync<VpsServer>(
      http.post(`${this.url}/${serverId}/setup`, payload, {
        params,
      }),
    );
  },
  async getSnapshots(serverId: number) {
    return await hAsync<IServerSnapshot[]>(
      http.get(`${this.url}/${serverId}/snapshot`),
    );
  },
  async createSnapshot(serverId: number) {
    return await hAsync(http.post(`${this.url}/${serverId}/snapshot`, null));
  },
  async restoreSnapshot(serverId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/snapshot/restore`, null),
    );
  },
  async deleteCpuLimit(serverId: number) {
    return await hAsync(
      http.delete(`${this.url}/${serverId}/cpu-limit`, {
        whitelistedErrorCodes: [2006],
      }),
    );
  },
  async deleteSnapshot(serverId: number) {
    return await hAsync(http.delete(`${this.url}/${serverId}/snapshot`));
  },
  async getSshKeys(serverId: string, settings: RequestConfig) {
    return await hAsync<ISshKey[]>(
      http.get(`${this.url}/${serverId}/public-keys`, { ...settings }),
    );
  },
  async createSshKey(serverId: number, payload: any) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/public-keys`, payload),
    );
  },
  async deleteSshKey(serverId: number, data: any) {
    return await hAsync(
      http.delete(`${this.url}/${serverId}/public-keys`, {
        data,
      }),
    );
  },
  async getMetrics(serverId: number, params: any) {
    return await hAsync<IServerUsage[]>(
      http.get(`${this.url}/${serverId}/metrics`, {
        params,
        cache: 300,
      }),
    );
  },
  async getServersData() {
    return await hAsync<VpsServer[]>(http.get(this.url));
  },
  async getServerData(serverId: number) {
    return await hAsync<VpsServer>(http.get(`${this.url}/${serverId}`));
  },
  async updateServer(serverId: number, payload: any) {
    return await hAsync(http.patch(`${this.url}/${serverId}`, payload));
  },
  async stopServer(serverId: number) {
    return await hAsync(http.post(`${this.url}/${serverId}/stop`, null));
  },
  async restartServer(serverId: number) {
    return await hAsync(http.post(`${this.url}/${serverId}/restart`, null));
  },
  async startServer(serverId: number) {
    return await hAsync(http.post(`${this.url}/${serverId}/start`, null));
  },
  async resetServer(serverId: number, payload: any) {
    return await hAsync(http.post(`${this.url}/${serverId}/recreate`, payload));
  },
  async updateFeatures(serverId: number, payload: any) {
    return await hAsync(
      http.patch(`${this.url}/${serverId}/features`, payload),
    );
  },
  async resetFeatures(serverId: number, payload: any) {
    return await hAsync(http.post(`${this.url}/${serverId}/reset`, payload));
  },
  async createReverseIp(serverId: number, payload: any) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/ip-address-reverse`, payload),
    );
  },
  async deleteReverseIp(serverId: number, data: any) {
    return await hAsync(
      http.delete(`${this.url}/${serverId}/ip-address-reverse`, {
        data,
      }),
    );
  },
  async turnOnRecoveryMode(serverId: number, payload: any) {
    return await hAsync(http.post(`${this.url}/${serverId}/recovery`, payload));
  },
  async turnOffRecoveryMode(serverId: number) {
    return await hAsync(http.delete(`${this.url}/${serverId}/recovery`));
  },
  async diskCleanup(serverId: number, payload: any) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/disk-cleanup`, payload),
    );
  },
  async getBackupsSchedule(
    serverId: string,
    config: RequestConfig,
  ): HAsyncResponse<ServerBackupSchedule[]> {
    return await hAsync(http.get(`${this.url}/${serverId}/schedule`, config));
  },
  async enableBackups(serverId: string, scheduleId: string) {
    return await hAsync(
      http.patch(`${this.url}/${serverId}/schedule/${scheduleId}/enable`, null),
    );
  },
  async disableBackups(serverId: string, scheduleId: string) {
    return await hAsync(
      http.patch(
        `${this.url}/${serverId}/schedule/${scheduleId}/disable`,
        null,
      ),
    );
  },
  async getTransferAvailability(serverId: number) {
    return await hAsync<ITransferAvailability>(
      http.get(`${this.url}/${serverId}/transfer/availability`, {
        cache: 20,
      }),
    );
  },
  async transferServer(serverId: number, dataCenterId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/transfer`, { dataCenterId }),
    );
  },
  async moveServer(serverId: number, dataCenterId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/move`, { dataCenterId }),
    );
  },
  async resetOsPassword(serverId: string, panelPassword: string) {
    return await hAsync(
      http.patch(`${this.url}/${serverId}/reset-panel-password`, {
        panelPassword,
      }),
    );
  },
  async getVncLink(serverId: number) {
    return await hAsync(http.get(`${this.url}/${serverId}/enable-vnc`));
  },
  async getOngoingAction(serverId: number) {
    return await hAsync<IServerOngoingAction>(
      http.get(`${this.url}/${serverId}/ongoing-action`),
    );
  },
  async activateOrSyncLicenses(serverId: string, hResourceId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/panel-licenses/${hResourceId}/sync`),
    );
  },

  async getFirewallGroups() {
    return await hAsync<IVpsFirewall[]>(http.get(`${this.root}/v1/firewall`));
  },

  async getFirewallGroupRules(serverId: string, groupId: string) {
    return await hAsync<IVpsFirewallRules[]>(
      http.get(`${this.url}/${serverId}/firewall/${groupId}/rules`),
    );
  },

  async createFirewallGroup(serverId: string, name: string) {
    return await hAsync<IVpsFirewall>(
      http.post(`${this.url}/${serverId}/firewall`, {
        name,
      }),
    );
  },

  async postAddFirewallRule(
    serverId: string,
    groupId: string,
    rules: IVpsFirewallRules,
  ) {
    return await hAsync<IVpsFirewallRules>(
      http.post(`${this.url}/${serverId}/firewall/${groupId}/rules`, rules),
    );
  },

  patchEditFirewallRule(
    serverId: string,
    groupId: string,
    rules: IVpsFirewallRules,
  ) {
    return hAsync<IVpsFirewallRules>(
      http.patch(
        `${this.url}/${serverId}/firewall/${groupId}/rules/${rules.id}`,
        {
          action: rules.action,
          protocol: rules.protocol,
          port: rules.port,
          source: rules.source,
          sourceDetail: rules.sourceDetail,
        },
      ),
    );
  },

  deleteFirewallRule(serverId: string, groupId: string, ruleId: string) {
    return hAsync(
      http.delete(
        `${this.url}/${serverId}/firewall/${groupId}/rules/${ruleId}`,
      ),
    );
  },

  async editFirewallGroup(serverId: string, groupId: string, name: string) {
    return await hAsync<IVpsFirewall>(
      http.patch(`${this.url}/${serverId}/firewall/${groupId}`, {
        name,
      }),
    );
  },

  async deleteFirewallGroup(serverId: string, groupId: number) {
    return await hAsync(
      http.delete(`${this.url}/${serverId}/firewall/${groupId}`),
    );
  },

  async activateFirewallGroup(serverId: number, groupId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/firewall/${groupId}/activate`),
    );
  },

  async deactivateFirewallGroup(serverId: number, groupId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/firewall/${groupId}/deactivate`),
    );
  },

  async syncFirewallRules(serverId: string, groupId: string) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/firewall/${groupId}/sync`),
    );
  },

  async installMalwareScanner(serverId: number) {
    return await hAsync<IVpsFirewall>(
      http.post(`${this.url}/${serverId}/monarx`),
    );
  },

  async getMalwareScannerMetrics(serverId: number) {
    return await hAsync<IMalwareScannerMetrics>(
      http.get(`${this.url}/${serverId}/monarx`, {
        hideToastr: true,
      }),
    );
  },

  async getMalwareScannerCredentials(serverId: number) {
    return await hAsync<IMalwareScannerCredentials>(
      http.get(`${this.url}/${serverId}/monarx/credentials`, {
        hideToastr: true,
      }),
    );
  },

  async getMalwareScannerTimeline(serverId: number) {
    return await hAsync<MalwareScannerTimeline>(
      http.get(`${this.url}/${serverId}/monarx/files/statistics`),
    );
  },

  async getMalwareScannerFiles(serverId: number, params: any) {
    return await hAsync<IMalwareScannerFileResponse>(
      http.get(`${this.url}/${serverId}/monarx/files`, {
        params,
      }),
    );
  },

  async uninstallMalwareScanner(serverId: number) {
    return await hAsync<IVpsFirewall>(
      http.delete(`${this.url}/${serverId}/monarx`),
    );
  },

  async activateMalwareAutoProtection(
    serverId: number,
    payload: IMalwareAutoProtectionRequest,
  ) {
    return await hAsync(http.patch(`${this.url}/${serverId}/monarx`, payload));
  },

  async syncMalwareScannerInstallation(serverId: number) {
    return await hAsync(http.get(`${this.url}/${serverId}/monarx/sync`));
  },

  async addExternalDomain(payload: IExternalDomainRequest) {
    return await hAsync<IExternalDomain>(
      http.post(`${this.root}/v1/external-domains`, payload),
    );
  },

  async deleteExternalDomain(domainId: number) {
    return await hAsync<IRequestAccapted>(
      http.delete(`${this.root}/v1/external-domains/${domainId}`),
    );
  },

  async getExternalDomains() {
    return await hAsync<IExternalDomain[]>(
      http.get(`${this.root}/v1/external-domains`),
    );
  },

  async assignIpv6(serverId: number) {
    return await hAsync(
      http.post(`${this.url}/${serverId}/ip-address/assign-ipv6`),
    );
  },

  async getAiCommands(serverId: number) {
    return await hAsync<string[]>(
      http.get(`${this.url}/${serverId}/kodee/commands`, {
        cache: 300,
      }),
    );
  },
};
