import { whDatabasesRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

export default {
  state: {
    initial: {
      databases: [],
      loaded: false,
    },
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_REMOTE_DATABASES_DATA(state, data) {
      setForDomain(state, null, 'databases', data);
      setForDomain(state, null, 'loaded', true);
    },
    DELETE_REMOTE_DATABASES(state, dbName) {
      let databases = getStateVariable(state, 'databases');
      databases = databases.filter((db) => db.dbName !== dbName);
      setForDomain(state, null, 'databases', databases);
    },
  },
  getters: {
    getRemoteDbState: (state) => getStateVariable(state),
  },
  actions: {
    async fetchRemoteDatabases({ commit }, params) {
      const [{ data }, err] = await whDatabasesRepo.getRemoteDatabases(
        getCurrentOrderDetails(),
        params,
      );

      commit('SET_REMOTE_DATABASES_DATA', !err ? data : []);
    },
    async createRemoteDatabase({ dispatch }, request) {
      const [data, err] = await whDatabasesRepo.createRemoteDatabase(
        getCurrentOrderDetails(),
        request.dbName,
        { ip: request.ip },
      );
      if (!err) dispatch('fetchRemoteDatabases');

      return [data, err];
    },
    async deleteRemoteDatabase({ commit }, request) {
      const [response, err] = await whDatabasesRepo.deleteRemoteDatabase(
        getCurrentOrderDetails(),
        request.dbName,
        { ip: request.ip },
      );

      if (!err) commit('DELETE_REMOTE_DATABASES', request.dbName);

      return [response, err];
    },
  },
};
