import dayjs from 'dayjs';
import { isObject } from 'lodash';

import { getParsedJwtToken } from '@/utils/helpers/authHelpers';

export default {
  getStorageName() {
    const { sub } = getParsedJwtToken() || {};

    return `h-client-${sub}`;
  },
  setValue(name, value, expiresAt) {
    localStorage.setItem(
      this.getStorageName(),
      JSON.stringify({
        ...this.getStorage(),
        [name]: {
          value,
          createdAt: dayjs().unix(),
          ...(expiresAt ? { expiresAt } : {}),
        },
      }),
    );
  },
  getValue(name) {
    const data = JSON.parse(localStorage.getItem(this.getStorageName()));

    if (!data || !isObject(data[name])) return undefined;

    return data[name].value;
  },
  deleteValue(name) {
    const data = this.getStorage();

    if (!data) return;

    delete data[name];

    localStorage.setItem(this.getStorageName(), JSON.stringify(data));
  },
  getStorage() {
    return JSON.parse(localStorage.getItem(this.getStorageName()));
  },
  cleanUpOldData() {
    const storage = this.getStorage();

    if (!isObject(storage)) return;

    const maxDataAge = 30 * 24 * 60 * 60;

    Object.keys(storage).forEach((key) => {
      if (storage[key]?.expiresAt && storage[key].expiresAt < dayjs().unix()) {
        delete storage[key];
      }

      if (
        storage[key]?.createdAt &&
        storage[key].createdAt + maxDataAge < dayjs().unix()
      ) {
        delete storage[key];
      }
    });

    localStorage.setItem(this.getStorageName(), JSON.stringify(storage));

    if (!Object.keys(storage).length) {
      localStorage.removeItem(this.getStorageName());
    }
  },
};
