import { ONE_MINUTE_IN_SECONDS } from '@/data/globalConstants';
import type {
  ClientReferrals,
  ReferralClientInfo,
  CartInviteInfo,
  BankAccountRequestParams,
  CartStatusItem,
  IUserReferralData,
  RequestConfig,
  IReferralReward,
  IReferralCartUrl,
  ILastReferredPlan,
  ICustomRewardRate,
  IReferralPromotion,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const WHITELISTED_STATUS_CODES = [400, 422, 429];

export default {
  url: `${process.env.VITE_API_INTEGRATIONS}/api/direct/referral-rock`,
  urlV2: `${process.env.VITE_API_INTEGRATIONS}/api/direct/v2/referral-rock`,
  //@ts-ignore
  async getIsProMember() {
    return await hAsync<ReferralClientInfo>(
      http.get(`${this.url}/pro/member`, {
        hideToastr: true,
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  //@ts-ignore
  async registerProMember() {
    return await hAsync(
      http.post(
        `${this.url}/pro/register`,
        {},
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        },
      ),
    );
  },
  async getReferralClientInfo(hideToastr?: boolean) {
    return await hAsync<IUserReferralData>(
      http.get(`${this.urlV2}/member`, {
        hideToastr,
        whitelistedErrorCodes: [2008],
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  async getClientReferrals(config?: RequestConfig) {
    return await hAsync<ClientReferrals>(
      http.get(`${this.url}/referrals`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        ...config,
      }),
    );
  },

  async postProPaypalEmail(paypalEmail: string) {
    return await hAsync<boolean>(
      http.post(
        `${this.url}/pro/paypal-email`,
        { paypalEmail },
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        },
      ),
    );
  },

  async getPaypalEmails() {
    return await hAsync<string[]>(
      http.get(`${this.url}/paypal-emails`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async addPaypalEmail(email: string) {
    return await hAsync<IUserReferralData>(
      http.post(
        `${this.url}/paypal-email`,
        { paypal_email: email },
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        },
      ),
    );
  },

  async patchPaypalEmail(email: string) {
    return await hAsync<IUserReferralData>(
      http.patch(
        `${this.url}/paypal-email`,
        { paypal_email: email },
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        },
      ),
    );
  },

  async setPaypalEmails(data: { email: string }) {
    return await hAsync(
      http.patch(
        `${this.url}/paypal-email`,
        { paypal_email: data.email },
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
        },
      ),
    );
  },

  async deletePaypal() {
    return await hAsync(
      http.delete(`${this.url}/paypal-email`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async deleteBankAccount() {
    return await hAsync(
      http.delete(`${this.url}/bank-account`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  //@ts-ignore
  async setInviteEmail(data) {
    return await hAsync(
      http.post(`${this.url}/referral/invite`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  // Pro Space Referrals
  async getReferralProClientInfo(hideToastr: boolean) {
    return await hAsync<ReferralClientInfo>(
      http.get(`${this.url}/pro/member`, {
        hideToastr,
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  async getProClientReferrals(hideToastr = false) {
    return await hAsync<ClientReferrals>(
      http.get(`${this.url}/pro/referrals`, {
        hideToastr,
        whitelistedErrorCodes: [2008],
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async getProPaypalEmails() {
    return await hAsync<string[]>(
      http.get(`${this.url}/pro/paypal-emails`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async postProBankAccount(params: BankAccountRequestParams) {
    return hAsync<boolean>(
      http.post(`${this.url}/pro/bank-account`, params, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  //@ts-ignore
  addBankAccount(data) {
    return hAsync(
      http.post(`${this.url}/bank-account`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async postToProReferralRock(data: { referralCustomerId: string }) {
    return await hAsync(
      http.post(`${this.url}/pro/referral`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async postToProReferralRockByEmail(data: { email: string }) {
    return await hAsync(
      http.post(`${this.url}/pro/referral/by-email`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async postRegularReferralRockCartInvite(data: CartInviteInfo) {
    return await hAsync(
      http.post(`${this.url}/referral/cart/invite`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  async postReferralRockCartInvite(data: CartInviteInfo) {
    return await hAsync(
      http.post(`${this.url}/pro/v2/referral/cart/invite`, data, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },
  async getProCartStatus() {
    return await hAsync<CartStatusItem[]>(
      http.get(`${this.url}/pro/referral/cart/invite`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async getRewards() {
    return await hAsync<IReferralReward[]>(
      http.get(`${this.url}/rewards`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async generateCartLink(itemPriceId: string) {
    return await hAsync<IReferralCartUrl>(
      http.post(
        `${this.url}/referral/cart/generate`,
        {
          itemPriceId,
          quantity: '1',
        },
        { whitelistedStatusCodes: WHITELISTED_STATUS_CODES },
      ),
    );
  },

  async getLastReferredPlan() {
    return await hAsync<ILastReferredPlan>(
      http.get(`${this.url}/member/last-referred-plan`, {
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async getRewardRate() {
    return await hAsync<ICustomRewardRate>(
      http.get(`${this.url}/customer/reward-rate`, {
        hideToastr: true,
        whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
      }),
    );
  },

  async postCustomRewardRate(rewardRateId: string) {
    return await hAsync<ICustomRewardRate>(
      http.post(
        `${this.url}/customer/custom-reward-rate`,
        {
          customRewardRateToken: rewardRateId,
        },
        {
          whitelistedStatusCodes: WHITELISTED_STATUS_CODES,
          hideToastr: true,
        },
      ),
    );
  },

  async getReferralPromotion() {
    return await hAsync<IReferralPromotion | null>(
      http.get(`${this.url}/campaigns`, {
        cache: ONE_MINUTE_IN_SECONDS * 60,
        hideToastr: true,
      }),
    );
  },
};
