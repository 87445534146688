import cpanelGuard from '@/guards/cpanelGuard';
import { Route } from '@/types';

const sidemenu = {
  sideMenuExists: true,
  sideMenuComponent: {
    component: 'CPanelSidebarMenu',
    menu: 'cPanel',
  },
};

const cPanelRoutes = [
  {
    path: '/cpanel/:domain',
    name: 'cpanel',
    meta: {
      title: 'Hosting',
      menu: ['horizontal'],
      ...sidemenu,
    },
    component: () => import('@/views/CPanel/CPanelWrapper.vue'),
    beforeEnter: (to, from, next) => cpanelGuard(to, from, next),
    redirect: (to) => ({
      query: to.query,
      params: to.params,
      name: Route.Base.HOSTING,
    }),
    children: [
      {
        path: 'dashboard',
        name: 'cpanel-index',
        meta: {
          title: 'Dashboard',
          breadcrumbs: ['cpanel'],
          breadcrumbsTitle: {
            title: '{domain} - Dashboard',
            translate: true,
          },
          menu: ['cPanel'],
          icon: 'overview',
          ...sidemenu,
        },
        component: () => import('@/views/CPanel/CPanelIndex.vue'),
      },
      {
        path: 'wordpress',
        name: Route.CPanel.WORDPRESS,
        meta: {
          title: 'WordPress',
          breadcrumbs: ['cpanel'],
          breadcrumbsTitle: {
            title: '{domain} - WordPress',
            translate: true,
          },
          menu: ['cPanel'],
          icon: 'icon-wordpress-padded',
          ...sidemenu,
        },
        component: () => import('@/views/CPanel/CPanelWordPress.vue'),
      },
      {
        path: 'details',
        name: 'cpanel-details',
        meta: {
          title: 'Details',
          breadcrumbs: ['cpanel'],
          breadcrumbsTitle: {
            title: '{domain} - Details',
            translate: true,
          },
          menu: ['cPanel'],
          icon: 'IcInfo',
          ...sidemenu,
        },
        component: () => import('@/views/CPanel/CPanelDetails.vue'),
      },
      {
        path: 'security',
        name: 'cpanel-security',
        meta: {
          title: 'Security',
          breadcrumbs: ['cpanel'],
          breadcrumbsTitle: {
            title: '{domain} - Security and protection',
            translate: true,
          },
          menu: ['cPanel'],
          icon: 'IconSecurity',
          ...sidemenu,
        },
        component: () => import('@/views/CPanel/CPanelSecurity.vue'),
      },
      {
        path: 'ssh-access',
        name: 'cpanel-ssh-access',
        meta: {
          title: 'SSH and Automation',
          breadcrumbs: ['cpanel'],
          breadcrumbsTitle: {
            title: '{domain} - SSH and Automation',
            translate: true,
          },
          menu: ['cPanel'],
          icon: 'icon-server',
          ...sidemenu,
        },
        component: () => import('@/views/CPanel/CPanelSSHAccess.vue'),
      },
      {
        path: 'upgrade',
        name: 'cpanel-order-upgrade',
        meta: {
          title: 'Upgrade',
          hideTitle: true,
          sideMenuExists: false,
        },
        component: () => import('@/views/CPanel/cPanelUpgrade.vue'),
      },
      {
        path: 'wordpress/manage',
        name: Route.CPanel.WORDPRESS_MANAGER,
        meta: {
          hideTitle: true,
          sideMenuExists: false,
        },
        component: () => import('@/views/CPanel/CPanelWordPressManager.vue'),
      },
      {
        path: 'wordpress/manage/staging',
        name: Route.CPanel.WORDPRESS_STAGING,
        meta: {
          hideTitle: true,
          sideMenuExists: false,
        },
        component: () => import('@/views/CPanel/CPanelWordPressStaging.vue'),
      },
    ],
  },
];

export default cPanelRoutes;
