import { defineStore } from 'pinia';

import VerifyPasswordChangeModal from '@/components/Modals/HModal/VerifyPasswordChangeModal.vue';
import VerifyPersonalInformationChangeModalV2 from '@/components/Modals/HModal/VerifyPersonalInformationChangeModalV2.vue';
import VerifyUnlinkSocialModal from '@/components/Modals/HModal/VerifyUnlinkSocialModal.vue';
import { useModal, useProfile } from '@/composables';
import { useProfileStore } from '@/stores';
import type { HModalProps, IChangePasswordRequest, Oauth } from '@/types';

export const useTwoFactorStore = defineStore('twoFactorStore', () => {
  const profileStore = useProfileStore();

  const { openUpdateEmailModal } = useProfile();

  const { openModal, closeModal, setModalClosingType } = useModal();

  const getSteps = (isEmailChangeModal: boolean) => {
    const passwordStep = {
      title: 'Enter password',
      subtitle: 'Enter the account password to continue',
    };

    const isPasswordSet = profileStore.account?.isPasswordSet;

    let steps = [passwordStep] as HModalProps[];

    if (isEmailChangeModal) {
      steps = [];

      steps.push({
        ...passwordStep,
        goBackText: 'Back to changing email address',
        goBackCustom: () => {
          closeModal();
          openUpdateEmailModal();
        },
      });
    }

    if (profileStore.isTwoFactorMethodEmail) {
      steps.push({
        ...(isPasswordSet && { goBackText: 'Password' }),
        title: 'Enter 2FA code from your email',
        subtitle: 'Enter 2FA code from your email in order to make changes',
      });
    }

    if (profileStore.isTwoFactorMethodCode) {
      steps.push({
        ...(isPasswordSet && { goBackText: 'Password' }),
        title: 'Enter two-factor authentication code',
        subtitle:
          'Enter two-factor authentication code in order to make changes',
      });
    }

    return steps;
  };

  const getPasswordChangeSteps = ({
    customPasswordBack,
    goBackText,
  }: {
    customPasswordBack?: () => void;
    goBackText?: string;
  }) => {
    const goBackCustom = () => {
      if (customPasswordBack) {
        closeModal();
        customPasswordBack();
        setModalClosingType('input');
      }
    };

    if (profileStore.isTwoFactorMethodCode) {
      return [
        {
          goBackText: goBackText || 'Password',
          title: 'Enter two-factor authentication code',
          subtitle:
            'Enter two-factor authentication code in order to make changes',
          closingType: 'input',
          withPadding: true,
          lg: true,
          persistent: true,
          hideX: true,
          goBackCustom,
        },
      ];
    }

    return [
      {
        goBackText: 'Password',
        title: 'Enter 2FA code from your email',
        subtitle: 'Enter 2FA code from your email in order to make changes',
        closingType: 'input',
        withPadding: true,
        lg: true,
        persistent: true,
        hideX: true,
        goBackCustom,
      },
    ];
  };

  const openVerifyPersonalInformationModal = ({
    submitData,
    onSuccessCallback,
  }: {
    submitData: {
      email?: string;
      [key: string]: string | undefined;
    };
    onSuccessCallback?: () => Promise<void>;
  }) => {
    const isEmailChangeModal = !!submitData.email;

    openModal({
      component: { VerifyPersonalInformationChangeModalV2 },
      steps: getSteps(isEmailChangeModal),
      data: {
        submitData,
        onSuccessCallback,
      },
    });
  };

  const openVerifyPasswordModal = ({
    submitData,
    customBack,
  }: {
    submitData: Omit<IChangePasswordRequest, 'twoFactorAuthCode'>;
    customBack?: () => void;
  }) => {
    openModal({
      component: { VerifyPasswordChangeModal },
      steps: getPasswordChangeSteps({
        customPasswordBack: customBack,
      }),
      data: {
        submitData,
      },
    });
  };

  const openVerifyUnlinkModal = ({
    socialLogin,
    customBack,
  }: {
    socialLogin: Oauth;
    customBack?: () => void;
  }) => {
    openModal({
      component: { VerifyUnlinkSocialModal },
      steps: getPasswordChangeSteps({
        customPasswordBack: customBack,
        goBackText: 'Unlink Account',
      }),
      data: {
        socialLogin,
      },
    });
  };

  return {
    openVerifyPersonalInformationModal,
    openVerifyPasswordModal,
    openVerifyUnlinkModal,
  };
});
