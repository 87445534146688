<script setup lang="ts">
import { computed } from 'vue';

import { useGlobals } from '@/composables/useGlobals';
import { useModal } from '@/composables/useModal';
import { usePaymentMethodStore } from '@/stores/paymentMethodStore';
import type { PaymentMethod } from '@/types/models/billing/paymentMethodModels';

type Props = {
  loading?: boolean;
  error?: boolean;
  isConfirm?: boolean;
  isFirstStep?: boolean;
  isSecondStep?: boolean;
  hasTwoSteps?: boolean;
  creditPay?: boolean;
  customButtonText?: string;
  hasCoupon?: boolean;
  step?: string;
  backStep?: string;
};

type Emits = {
  'on-close': [];
  'on-success': [];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { closeModal } = useModal();
const { permissions, t } = useGlobals();

const paymentMethodStore = usePaymentMethodStore();

const successBtnText = computed(() => {
  if (props.isFirstStep) return t('v2.continue');

  if (props.creditPay && !props.hasCoupon) {
    return t('v2.complete.payment.with.hostinger.balance');
  }

  return props.isConfirm ? t('v2.confirm') : continueWithPaymentText.value;
});

const continueWithPaymentText = computed(() =>
  paymentMethod.value && !paymentMethod.value.isExpired
    ? completeButtonText.value
    : t('v2.choose.payment.method'),
);

const completeButtonText = computed(
  () => props.customButtonText || t('v2.complete.payment'),
);

const paymentMethod = computed<PaymentMethod | null>(
  () => paymentMethodStore.paymentMethod,
);

const isBackButton = computed(() => props.isSecondStep && props.hasTwoSteps);

const disabled = computed(
  () => props.loading || props.error || permissions.paymentDisabled(),
);

const close = () => {
  isBackButton.value ? emit('on-close') : closeModal();
};
</script>

<template>
  <div class="purchase-buttons">
    <HButton
      variant="text"
      primary
      :disabled="loading"
      :data-qa="`purchase-button-${
        backStep ?? isBackButton ? 'back' : 'cancel'
      }`"
      @click="close"
    >
      {{ isBackButton ? t('v2.back') : t('v2.cancel') }}
    </HButton>
    <HButton
      v-tooltip="permissions.tooltipDisabled()"
      :loading="loading"
      :disabled="disabled"
      :data-qa="`purchase-button-${step ?? 'complete'}`"
      @click="emit('on-success')"
    >
      {{ successBtnText }}
    </HButton>
  </div>
</template>

<style lang="scss" scoped>
.purchase-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: 460px) {
    flex-direction: column-reverse;
  }
}
</style>
