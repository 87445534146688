import type { IHButtonProps, FieldSchema } from '@hostinger/hcomponents';

export enum HPTablePaginationType {
  LOCAL = 'local',
  BACKEND = 'backend',
}

export enum HPTableHeaderKey {
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  ACTION_SLOT = 'actionSlot',
}

export enum HPTableCellAction {
  TOGGLE = 'toggle',
  CHECKBOX = 'checkbox',
}

export enum HPTableSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IHPTableHeaderTogglePayload {
  isToggled: boolean;
  visibleRows: any[];
}

export interface IHPTableSort {
  key: string;
  direction: HPTableSortDirection;
  type: 'string' | 'number' | 'date';
}

export interface HPTableConfig {
  isLoading?: boolean;
  headers: HPTableHeader;
  paginationType?: HPTablePaginationType;
  isHeaderDropdownVisible?: boolean;
  itemsPerPage?: number;
  searchKey?: string | string[];
  isSearchVisible?: boolean;
  isSearchActive?: boolean;
  isPaginationVisible?: boolean;
  isItemsPerPageVisible?: boolean;
  searchPlaceholder?: string;
  isHeaderVisible?: boolean;
  isBorderVisible?: boolean;
  isHeaderVisibleOnMobile?: boolean;
  noResults?: {
    src?: string;
    title?: string;
    subtitle?: string;
    extraImageClasses?: Record<string, boolean>;
  };
  headerDropdownSchema?: FieldSchema;
  customPaginationSizes?: number[];
  dynamicItemsPerPage?: number;
  collapseHeader?: boolean;
}

export type HPTableHeader = Record<
  string | HPTableHeaderKey,
  {
    label?: string;
    isSortable?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    column: string;
    buttonProps?: HPTableHeaderButton;
    tooltip?: string;
    isPositionLeft?: boolean;
  }
>;
type HPTableHeaderButton = {
  text: string;
  action: Function;
} & IHButtonProps;

export interface HPTableSortParams {
  key: string;
  direction: HPTableSortDirection;
}

export interface HPTablePaginationParams {
  page: number;
  pageSize: number;
}
