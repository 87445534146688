import { useCatalogStore } from '@/stores/catalogStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { TLD_PURCHASE_PERIODS } from '@/types';
import type { Currency, CatalogItemPrice } from '@/types';
import { convertToEur } from '@/utils/helpers/currencyHelpers';
import { getDiscount, getSplittedDomainParts } from '@/utils/helpers/helpers';
import currency from '@/utils/services/currencyService';

const getGaTldItemCommonInfo = (brand?: string, coupon?: string) => ({
  coupon: coupon ?? '',
  item_brand: brand ?? '',
  item_category: 'DOMAIN',
  item_list_id: 'hpanel_domain_name_search-domain_checker_card-domain',
  item_list_name: 'hpanel domain name search - domain checker card-domain',
  quantity: 1,
});

export const useDomainPricing = () => {
  const CURRENCY_FORMAT_CONFIG = {
    isChargebeePrice: true,
    fixedCurrency: false,
    monthly: false,
  };

  const { getDomainPricingByTld } = useCatalogStore();
  const profileStore = useProfileStore();

  const getShortestPeriodPrice = (pricingData: CatalogItemPrice[]) =>
    pricingData.reduce(
      (
        shortestPeriodPrice: CatalogItemPrice | null,
        item: CatalogItemPrice,
      ) => {
        if (
          !shortestPeriodPrice ||
          (item.period ?? 1) < (shortestPeriodPrice.period ?? 1)
        ) {
          return item;
        }

        return shortestPeriodPrice;
      },
      null,
    );

  const getDomainOldFirstYearPrice = (priceData: CatalogItemPrice) => {
    const oldPrice = priceData.basePrice ?? 0;
    const periodCount = priceData.period ?? 1;

    return oldPrice / periodCount;
  };

  const getOptimalMultiYearOffer = (pricingData: CatalogItemPrice[]) => {
    const oneYearFirstPeriodPrice = pricingData.find(
      ({ period }) => period === 1,
    )?.firstPeriodPrice;

    if (!oneYearFirstPeriodPrice) return null;

    const multiYearOffers = pricingData.filter(
      (priceData) =>
        priceData.firstYearPrice &&
        priceData.firstYearPrice < oneYearFirstPeriodPrice &&
        (priceData.period ?? 1) > 1,
    );

    return multiYearOffers.reduce(
      (
        lowestPricedItem: CatalogItemPrice | null,
        currentItem: CatalogItemPrice,
      ) => {
        const isCurrentCheaper =
          !lowestPricedItem ||
          currentItem.firstYearPrice! < lowestPricedItem.firstYearPrice!;
        const isPriceEqualButCurrentHasShorterPeriod =
          currentItem.firstYearPrice === lowestPricedItem?.firstYearPrice &&
          lowestPricedItem &&
          (lowestPricedItem.period ?? 1) > (currentItem.period ?? 1);

        if (isCurrentCheaper || isPriceEqualButCurrentHasShorterPeriod) {
          return currentItem;
        }

        return lowestPricedItem;
      },
      null,
    );
  };

  const getDomainCurrentFirstYearPrice = (priceData: CatalogItemPrice) =>
    priceData.firstYearPrice ||
    Math.round((priceData.firstPeriodPrice ?? 0) / (priceData.period ?? 1));

  const getIsDomainPriceDiscounted = (priceData: CatalogItemPrice) =>
    (priceData.basePrice ?? 0) > (priceData.firstPeriodPrice ?? 0);

  const getDomainDiscount = (priceData: CatalogItemPrice) => {
    const oldPrice = getDomainOldFirstYearPrice(priceData);
    const currentPrice = getDomainCurrentFirstYearPrice(priceData);

    if (!currentPrice || !oldPrice) return 0;

    return getDiscount(oldPrice, currentPrice);
  };

  const getDomainRenewalPrice = (tld: string) => {
    const tldPricing = getDomainPricingByTld(tld);
    const shortestPeriod = getShortestPeriodPrice(tldPricing);

    if (!shortestPeriod?.price || !shortestPeriod?.period) {
      return null;
    }

    return currency.format(
      shortestPeriod.price / shortestPeriod.period,
      CURRENCY_FORMAT_CONFIG,
    );
  };

  const getDomainOfferPriceDetails = (tld: string) => {
    const tldPricing = getDomainPricingByTld(tld);
    const shortestPeriodPrice = getShortestPeriodPrice(tldPricing);
    const multiYearOffer = getOptimalMultiYearOffer(tldPricing);
    const periodPrice = multiYearOffer || shortestPeriodPrice;

    if (!periodPrice) {
      return null;
    }

    const basePrice = currency.format(
      getDomainOldFirstYearPrice(periodPrice),
      CURRENCY_FORMAT_CONFIG,
    );
    const firstPeriodPrice = currency.format(
      getDomainCurrentFirstYearPrice(periodPrice),
      CURRENCY_FORMAT_CONFIG,
    );

    return {
      isDiscounted: getIsDomainPriceDiscounted(periodPrice),
      isMultiYear: !!multiYearOffer,
      period: periodPrice?.period,
      shortestPeriod: shortestPeriodPrice?.period,
      discount: getDomainDiscount(periodPrice),
      basePrice,
      firstPeriodPrice,
      oldPrice: basePrice,
      currentPrice: firstPeriodPrice,
    };
  };

  const getShortedPeriodPriceByTld = (tld: string) => {
    const tldPrice = getDomainPricingByTld(tld);
    const periodPrice = getShortestPeriodPrice(tldPrice);

    return periodPrice;
  };

  const getDomainsBundlePricing = (tlds: string[]) => {
    const firstTld = tlds[0];

    const firstTldPricing = getDomainPricingByTld(firstTld);
    const periodPrice = getShortestPeriodPrice(firstTldPricing);

    const totalOldPrice = tlds.reduce((total, tld) => {
      const periodPrice = getShortedPeriodPriceByTld(tld);

      if (!periodPrice) return total;

      const price = getDomainOldFirstYearPrice(periodPrice);

      return total + price;
    }, 0);

    const totalCurrentPrice = tlds.reduce((total, tld) => {
      const periodPrice = getShortedPeriodPriceByTld(tld);

      if (!periodPrice) return total;

      const price = getDomainCurrentFirstYearPrice(periodPrice);

      return total + price;
    }, 0);

    return { totalCurrentPrice, totalOldPrice, periodPrice };
  };

  const getDomainsBundlePriceDetails = (tlds: string[]) => {
    const { totalCurrentPrice, totalOldPrice, periodPrice } =
      getDomainsBundlePricing(tlds);

    if (!totalCurrentPrice || !totalOldPrice) return null;

    const basePrice = currency.format(totalOldPrice, CURRENCY_FORMAT_CONFIG);
    const firstPeriodPrice = currency.format(
      totalCurrentPrice,
      CURRENCY_FORMAT_CONFIG,
    );

    return {
      ...periodPrice,
      firstPeriodPrice,
      price: totalOldPrice,
      basePrice,
      oldPrice: basePrice,
      currentPrice: firstPeriodPrice,
      isDiscounted: false,
      isMultiYear: false,
      period: periodPrice?.period,
      shortestPeriod: periodPrice?.period,
      id: TLD_PURCHASE_PERIODS.YEAR_1,
    };
  };

  const getGaTldPricing = (domain: string, listName: string) => {
    const [, tld] = getSplittedDomainParts(domain);
    const tldPricing = getDomainPricingByTld(tld);
    const shortestPeriodPrice = getShortestPeriodPrice(tldPricing);
    const multiYearOffer = getOptimalMultiYearOffer(tldPricing);
    const periodPrice = multiYearOffer || shortestPeriodPrice;

    if (!periodPrice) {
      return null;
    }

    const currencyCode = (periodPrice?.currencyCode ||
      profileStore.currency.code) as Currency.Code;
    const firstPeriodPrice = getDomainCurrentFirstYearPrice(periodPrice);

    return {
      item_category2: tld,
      item_id: `domain:${domain}_${listName}`,
      item_name: `${domain} ${listName}`,
      discount: getDomainDiscount(periodPrice),
      price: convertToEur(firstPeriodPrice, currencyCode).amount,
      currency: periodPrice.currencyCode,
      ...getGaTldItemCommonInfo(profileStore.account?.brand?.domain),
    };
  };

  const getGaBundleTldPricing = (domain: string, bundleTlds: string[]) => {
    const { totalCurrentPrice, periodPrice } =
      getDomainsBundlePricing(bundleTlds);

    if (!periodPrice) {
      return null;
    }

    const currencyCode = (periodPrice?.currencyCode ||
      profileStore.currency.code) as Currency.Code;

    return {
      price: convertToEur(totalCurrentPrice, currencyCode).amount,
      currency: currencyCode,
      discount: 0,
      item_category2: bundleTlds.map((tld) => `.${tld}`) || '',
      item_id: `domain:${domain}_bundle`,
      item_name: `${domain} bundle`,
      ...getGaTldItemCommonInfo(profileStore.account?.brand?.domain),
    };
  };

  return {
    getIsDomainPriceDiscounted,
    getDomainDiscount,
    getShortestPeriodPrice,
    getDomainCurrentFirstYearPrice,
    getDomainsBundlePriceDetails,
    getDomainOldFirstYearPrice,
    getDomainOfferPriceDetails,
    getDomainRenewalPrice,
    getGaTldPricing,
    getGaBundleTldPricing,
  };
};
