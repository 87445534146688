import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hRefundsRepo } from '@/repositories';
import type { IHRefundHistoryItem, CreditNoteId } from '@/types';

export const useRefundHistoryStore = defineStore('refundHistoryStore', () => {
  const refundHistoryList = ref<IHRefundHistoryItem[]>([]);
  const isRefundHistoryListLoading = ref(false);

  const fetchRefundHistory = async () => {
    isRefundHistoryListLoading.value = true;
    const [{ data }, err] = await hRefundsRepo.getRefundHistory();
    isRefundHistoryListLoading.value = false;

    if (err) return;

    refundHistoryList.value = data;
  };

  const getRefundItemByCreditNoteId = (creditNoteId: CreditNoteId) =>
    refundHistoryList.value.find((item) => item.creditNoteId === creditNoteId);

  return {
    fetchRefundHistory,
    getRefundItemByCreditNoteId,
    isRefundHistoryListLoading,
    refundHistoryList,
  };
});
