import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/details`,

  async getHostingUpsellIndex() {
    return await hAsync(http.get(`${this.url}/upsells`));
  },
  async updatePassword(params) {
    return await hAsync(http.patch(`${this.url}/password`, params));
  },
};
