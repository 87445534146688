<script setup lang="ts">
import type { FormValues, FieldSchema } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import { useModal } from '@/composables';
import { whOrderRepo } from '@/repositories';
import type { ModalEmits, IHostingAccount } from '@/types';
import { FieldType } from '@/types';

type Props = {
  data: {
    planName: string;
    domain: string;
    orderReferenceId: IHostingAccount['orderId'];
    onSuccess: Function;
  };
};
interface Emits extends ModalEmits {}
const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const { close } = useModal(emits);

const loading = ref(false);

const onFormSubmit = async ({ values }: FormValues) => {
  if (props.data.planName !== values.planName) {
    loading.value = true;

    const [_, err] = await whOrderRepo.renameOrder(
      { orderId: props.data.orderReferenceId },
      values.planName,
    );

    loading.value = false;
    close();

    if (err) return;

    if (props.data.onSuccess) props.data.onSuccess();
  }
};
const changePlanNameSchema = computed<FieldSchema>(() => ({
  type: FieldType.text,
  label: 'Plan name',
  value: props.data.planName,
  validation: {
    required: true,
    maxlength: 80,
  },
}));
</script>

<template>
  <div class="change-hosting-plan-modal">
    <h2 v-trans class="h-mb-8">Change hosting plan name</h2>
    <p v-trans class="h-mb-16">Edit hosting plan name for easier management</p>
    <HForm @on-submit="onFormSubmit($event)">
      <HFormField name="planName" :schema="changePlanNameSchema" />
    </HForm>
    <div class="text-right h-mt-24">
      <HButtonV1 no-margin text primary @click="close">Cancel</HButtonV1>
      <HButtonV1 no-margin primary h-form-submit :loading="loading">
        Confirm
      </HButtonV1>
    </div>
  </div>
</template>
