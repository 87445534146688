<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import Navigate from '@/components/Navigate.vue';
import { AmplitudeLocation, RedirectMutations, Route } from '@/types';

type Props = {
  brand?: boolean;
  isLogoLinkDisabled?: boolean;
  src?: string;
  srcMobile?: string;
  isDarkMode?: boolean;
  forceDesktopLogo?: boolean;
  centered?: boolean;
};

const props = defineProps<Props>();
const store = useStore();

const getLogoUrl = (mobile = false) => {
  const useMobileSrc = props.forceDesktopLogo ? false : mobile;

  try {
    if (theme.value === 'hostinger') {
      if (props.forceDesktopLogo) {
        return `@/images/logos/hostinger-black.svg`;
      }

      return `@/images/logos/hostinger-v2-logo.svg`;
    }

    if (!theme.value) {
      return '';
    }

    const filename = props.brand
      ? `${theme.value}-brand`
      : `${theme.value}${useMobileSrc ? '--mobile' : ''}`;

    if (!filename) {
      return '';
    }

    return `@/images/logos/${filename}.svg`;
  } catch (e) {
    return '';
  }
};

const theme = computed(() => store.state.theme);
const desktopImageSource = computed(() => props.src || getLogoUrl());
const mobileImageSource = computed(
  () => props.srcMobile || props.src || getLogoUrl(true),
);

const logoWidth = computed(() => {
  if (theme.value === 'hostinger' && !props.forceDesktopLogo) {
    return '40';
  }

  return '150';
});

const setRedirectParams = () => {
  store.commit(RedirectMutations.SET_REDIRECT, {
    amplitudeLocation: AmplitudeLocation.Action.HOMEPAGE_LOGO,
    amplitudeCreationPath: AmplitudeLocation.Action.HOMEPAGE_LOGO,
  });
};
</script>

<template>
  <div
    class="header__logo"
    :class="{
      'header__logo--force-desktop': forceDesktopLogo,
      'header__logo--center': centered,
    }"
  >
    <Navigate
      v-if="desktopImageSource || mobileImageSource"
      id="hpanel_tracking-h_header-header_logo-link-home"
      :disabled="isLogoLinkDisabled"
      :route-to="{
        name: Route.Base.HOME,
      }"
      @click="setRedirectParams"
    >
      <HImage
        class="d-none d-md-block"
        :alt="theme || ''"
        :width="logoWidth"
        :src="desktopImageSource"
      />
      <HImage class="d-md-none" :alt="theme || ''" :src="mobileImageSource" />
    </Navigate>
  </div>
</template>

<style lang="scss" scoped>
.header__logo {
  &--center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
