<template>
  <div>
    <Trans tag="p">
      Make sure to use a valid domain name below. The domain
      <strong>can’t be changed</strong>
      once the plan is activated.
    </Trans>
    <HFormDeprecated ref="form" v-model="form">
      <HFormFieldDeprecated name="domain" :schema="domain" class="h-my-24" />
    </HFormDeprecated>
    <div class="d-flex actions">
      <HButtonV1 text primary class="h-my-0" :disabled="loading" @click="close">
        Cancel
      </HButtonV1>
      <HButtonV1 primary class="h-my-0" :loading="loading" @click="onSubmit">
        Create
      </HButtonV1>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  mixins: [modalsMixin],

  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed: {
    domain() {
      return {
        name: 'domain',
        type: 'text',
        label: 'Domain name',
        disabled: this.loading,
        validation: {
          isDomain: true,
          required: false,
        },
      };
    },
  },

  methods: {
    async onSubmit() {
      this.$refs.form.validate();

      await this.$nextTick();

      if (!this.form.isValid) return;

      if (!this.form.values.domain) {
        this.$toastr.e(this.$t('Domain must be provided'));

        return this.close();
      }

      this.loading = true;

      const [{ data }] = await this.setupRankingCoach({
        ...this.data.order,
        domain: this.form.values.domain,
      });

      if (data) {
        const id = this.data.order.id;
        const domain = this.form.values.domain;

        this.$store.dispatch('fetchHostingOrders');

        this.$router.push({
          name: 'marketing-tools',
          params: { id, domain },
        });
      }

      this.loading = false;

      this.close();
    },
    ...mapActions('marketingPanel', [
      'setupRankingCoach',
      'getMarketingPanelLoading',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 576px) {
    justify-content: center;
  }
}
</style>
