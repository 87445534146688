import { sslRepo } from '@/repositories';

export default {
  state: {
    sslCertificates: [],
    autoinstallSslCertificates: [],
    setupDomains: [],
    dataUpdateToggle: false,
  },
  mutations: {
    SET_SSL_CERTIFICATES(state, data) {
      state.sslCertificates = data || [];
    },
    SET_AUTOINSTALL_SSL_CERTIFICATES(state, data) {
      data.forEach((ssl) => {
        ssl.status = 'installing';
      });

      state.autoinstallSslCertificates = data || [];
    },
    TOGGLE_DATA_UPDATE(state) {
      state.dataUpdateToggle = !state.dataUpdateToggle;
    },
    setSetupDomains(state, data) {
      state.setupDomains = data || [];
    },
    removeSetupDomain(state, domainToRemove) {
      state.setupDomains = [
        ...state.setupDomains.filter(({ domain }) => domain !== domainToRemove),
      ];
    },
  },
  actions: {
    async sslCertificateIndex({ commit }) {
      const [{ data }, error] = await sslRepo.getSsls();
      if (data) {
        data.custom.forEach(
          (ssl) =>
            (ssl.expiresAt = ssl.expiresAt || ssl?.sslInfo?.validity?.to),
        );
        const [orders, failedOrders] = [
          ...data.lifetime,
          ...data.custom,
          ...data.comodo,
          ...data.comodoPending,
        ].reduce(
          ([passedValues, failedValues], currentValue) =>
            currentValue.status !== 'failed'
              ? [[...passedValues, currentValue], failedValues]
              : [passedValues, [...failedValues, currentValue]],
          [[], []],
        );
        commit('SET_SSL_CERTIFICATES', orders);
        commit('SET_AUTOINSTALL_SSL_CERTIFICATES', failedOrders);
      }

      return [{ data }, error];
    },
    async setupDomainIndex({ commit }) {
      const [{ data }] = await sslRepo.getDomainsToSetupSsl();
      commit('setSetupDomains', data);
    },
    async setupSslStore(context, sslData) {
      return await sslRepo.activateSsl(sslData);
    },
  },
};
