<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue';

import ErrorState from '@/components/ErrorBoundary/ErrorState.vue';
import type { ErrorStateProps } from '@/components/ErrorBoundary/ErrorState.vue';
import {
  errorBoundaryHandler,
  ERROR_HANDLER_VARIANT,
} from '@/utils/helpers/errorBoundaryHelpers';

const props = defineProps<ErrorStateProps>();

const hasError = ref(false);

onErrorCaptured((error, instance, info) =>
  errorBoundaryHandler({
    error,
    instance,
    info,
    variant: ERROR_HANDLER_VARIANT.COMPONENT,
    onFailToRender: () => {
      hasError.value = true;
    },
  }),
);
</script>

<template>
  <ErrorState v-if="hasError" v-bind="props" />
  <slot v-else />
</template>
