import { useProfileStore } from '@/stores';
import { Client } from '@/types';

export const minorUnitCurrencyData = ({
  minorCurrencyUnit,
  currencyCode,
} = {}) => {
  const { displayCents, actualCents } =
    currencyDecimalPlacesFunction(currencyCode);

  const divideBy = Number('1'.padEnd(actualCents + 1, '0'));

  const price = minorCurrencyUnit / divideBy;

  return { price, precision: displayCents };
};

const currencyDecimalPlacesFunction = (currencyCode) => {
  const currencyCodeValue = currencyCode || mapBrandIdToCurrencyCode();
  const currencyExceptions = {
    KRW: {
      displayCents: 0,
      actualCents: 0,
    },
    VND: {
      displayCents: 0,
      actualCents: 0,
    },
    JPY: {
      displayCents: 0,
      actualCents: 0,
    },
    CLP: {
      displayCents: 0,
      actualCents: 0,
    },
    COP: {
      displayCents: 0,
      actualCents: 2,
    },
    IDR: {
      displayCents: 0,
      actualCents: 2,
    },
  };
  const defaultDecimals = {
    displayCents: 2,
    actualCents: 2,
  };

  return currencyExceptions[currencyCodeValue] || defaultDecimals;
};

const mapBrandIdToCurrencyCode = () => {
  const profileStore = useProfileStore();
  const brandId = profileStore.account?.brand.id;

  const currencies = {
    [Client.BrandId.Hostinger.CO]: 'COP',
    [Client.BrandId.Hostinger.CO_ID]: 'IDR',
    [Client.BrandId.Hostinger.VN]: 'VND',
    [Client.BrandId.Hostinger.JP]: 'JPY',
    [Client.BrandId.Hostinger.KR]: 'KRW',
    [Client.BrandId.Other.NG_CO_ID]: 'IDR',
  };

  return currencies[brandId];
};

export const monthsPerPeriodUnit = (periodUnit) =>
  periodUnit === 'year' ? 12 : 1;

export const getMonthlyPrice = (period, periodUnit, price) => {
  const itemPeriod = periodUnit === 'year' ? period * 12 : period;

  return price / itemPeriod;
};

export const symbol = () => {
  const profileStore = useProfileStore();
  profileStore.currency?.format?.replace('{price}', '');
};
