import { isocountries } from '@/data/isocountries';
import { i18n } from '@/utils/services/i18nService';

export const filterISOCountries = (countriesToFilter: string[] = []) =>
  isocountries.flatMap(({ label, value }) => {
    if (countriesToFilter.includes(value)) return [];

    return {
      label: i18n.t(label),
      value,
    };
  });

export const getISOCountriesOptions = (allowedCountries: string[] = []) =>
  isocountries.flatMap(({ label, value }) => {
    if (!allowedCountries.includes(value)) return [];

    return {
      label: i18n.t(label),
      value,
    };
  });

export const isoCountryToSelect = (country: string) => {
  const selected = isocountries.find(
    ({ value, label }) => value === country || label === country,
  );

  if (selected) {
    return {
      ...selected,
      selected: true,
    };
  }

  return {
    label: '',
    value: '',
  };
};

export const getCountryNameByCode = (code: string) => {
  const country = isocountries.find(({ value }) => value === code);

  if (country) {
    return i18n.t(country.label);
  }

  return '';
};
