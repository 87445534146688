import { hBillingRepo } from '@/repositories';
import { useResourcesStore, useSubscriptionsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { HBilling, SubscriptionStatus } from '@/types';

export default {
  namespaced: true,
  state: {
    loading: false,
    subscriptions: [],
    fetched: false,
    paymentDueInvoices: [],
    isPaymentDueInvoicesLoaded: false,
  },
  getters: {
    getSubscriptionById: (state) => (id) =>
      state.subscriptions.find((subscription) => subscription.id === id),

    getSubscriptionWithResourcesById: (state, _, __) => (id) => {
      const resourceStore = useResourcesStore();

      const subscription = state.subscriptions.find(
        (subscription) =>
          subscription.id === id || subscription.resource?.referenceId === id,
      );

      const resource =
        resourceStore.getResourceByIdempotencyKey(id) ||
        resourceStore.getResourceByReferenceId(id);

      return {
        ...subscription,
        resource,
      };
    },
    activeSubscriptions: (state) =>
      state.subscriptions.filter((subscription) =>
        [SubscriptionStatus.ACTIVE, SubscriptionStatus.NON_RENEWING].includes(
          subscription.status,
        ),
      ),
    hasActiveSubscriptions: (state, getters) =>
      getters.activeSubscriptions.length > 0,
    getUnpaidSubscriptionInvoice: (state, getters) => (id) => {
      const subscription = getters.getSubscriptionById(id);

      return subscription.invoices.find((invoice) => invoice.paidAt === null);
    },
    getSubscriptionPaymentDueInvoices: (state) => (id) =>
      state.paymentDueInvoices.filter(
        ({ subscriptionId }) => subscriptionId === id,
      ),

    getSubscriptionByHResourceId: (_, getters) => (hResourceId) => {
      const resourceStore = useResourcesStore();
      const hResource = resourceStore.getResourceById(Number(hResourceId));
      const subscriptionID = hResource?.chargebeeSubscriptionId;

      return getters.getSubscriptionById(subscriptionID);
    },

    getSubscriptionByDomain: (state, getters) => (domain) => {
      const { getResourceById } = useResourcesStore();
      const { getHDomainResourceByDomain } = useHDomainResourceStore();
      const hDomainResource = getHDomainResourceByDomain(domain);

      if (!hDomainResource) {
        return null;
      }

      const hResourceId = hDomainResource?.additionalDetails?.hresourceId;
      const hResource = getResourceById(Number(hResourceId));
      const subscriptionID = hResource?.chargebeeSubscriptionId;

      return getters.getSubscriptionById(subscriptionID);
    },

    getDomainSubscriptionByProperty:
      (state, getters) =>
      ({ hResourceId, domain, subscriptionId }) => {
        if (subscriptionId) {
          return getters.getSubscriptionById(subscriptionId);
        }

        if (hResourceId) {
          return getters.getSubscriptionByHResourceId(hResourceId);
        }

        return getters.getSubscriptionByDomain(domain);
      },
  },
  mutations: {
    SET_PAYMENT_DUE_INVOICES(state, payload) {
      state.paymentDueInvoices = payload;
      state.isPaymentDueInvoicesLoaded = true;
    },
    SET_SUBSCRIPTIONS(state, payload) {
      state.subscriptions = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FETCHED(state, payload) {
      state.fetched = payload;
    },
    SET_SUBSCRIPTION_STATUS(state, { id, status }) {
      const updatedSubscriptions = state.subscriptions.map((item) =>
        item.id === id
          ? {
              ...item,
              status,
            }
          : item,
      );
      state.subscriptions = updatedSubscriptions;
    },
    ADD_SUBSCRIPTION_BILLING_PERIODS(state, { id, periods }) {
      const updatedSubscriptions = state.subscriptions.map((item) =>
        item.id === id
          ? {
              ...item,
              periods,
            }
          : item,
      );
      state.subscriptions = updatedSubscriptions;
    },
    ADD_SUBSCRIPTIONS_SCHEDULED_CHANGES(state, payload) {
      const updatedSubscriptions = state.subscriptions.map((item) => ({
        ...item,
        ...payload.find((subscription) => subscription.id === item.id),
      }));
      state.subscriptions = updatedSubscriptions;
    },
    UPDATE_SUBSCRIPTION_BY_ID(state, payload) {
      const { updateSingleSubscription } = useSubscriptionsStore();
      updateSingleSubscription(payload);

      const updatedSubscriptions = state.subscriptions.map((item) =>
        item.id === payload.id
          ? {
              ...item,
              status: payload.status,
              scheduledChanges: payload,
              nextBillingAt: payload.nextBillingAt,
            }
          : item,
      );
      state.subscriptions = [...updatedSubscriptions];
    },
    FULL_UPDATE_SUBSCRIPTION_BY_ID(state, payload) {
      state.subscriptions = state.subscriptions.map((item) =>
        item.id === payload.id
          ? {
              ...payload,
              scheduledChanges: payload,
            }
          : item,
      );
    },
    ADD_SUBSCRIPTION_INVOICES(state, { id, invoices }) {
      const updatedSubscriptions = state.subscriptions.map((item) =>
        item.id === id
          ? {
              ...item,
              invoices,
            }
          : item,
      );
      state.subscriptions = updatedSubscriptions;
    },
  },
  actions: {
    async billingGetSubscriptionsWithScheduledChanges(
      { commit, dispatch, rootGetters },
      options = {
        isSilentRefetch: false,
        requestConfig: {
          overrideCache: true,
        },
      },
    ) {
      const subscriptionStore = useSubscriptionsStore();

      if (options.isSilentRefetch) {
        commit('SET_LOADING', true);
      }

      const [{ data }, error] = await subscriptionStore.fetchAllSubscriptions(
        options.requestConfig,
      );

      if (!error) {
        commit('SET_SUBSCRIPTIONS', data);
        commit('SET_FETCHED', true);
      }

      const unpaidInvoices = data?.filter(
        (subscription) => subscription.dueInvoicesCount === 1,
      );

      if (!unpaidInvoices?.length) {
        commit('SET_LOADING', false);

        return;
      }

      await fetchSubscriptionInvoices({
        unpaidInvoices,
        commit,
        dispatch,
        rootGetters,
      });

      commit('SET_LOADING', false);
    },
    async billingSubscriptionsEnableAutoRenew({ commit }, { subscriptionId }) {
      const [{ data }, error] = await hBillingRepo.enableSubscriptionAutoRenew(
        subscriptionId,
      );
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }

      return [data, error];
    },
    async billingSubscriptionsDisableAutoRenew({ commit }, { subscriptionId }) {
      const [{ data }, error] = await hBillingRepo.disableSubscriptionAutoRenew(
        subscriptionId,
      );
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }

      return [data, error];
    },
    async getListOfAvailablePeriods({ commit }, { id, priceId }) {
      const [{ data }, error] = await hBillingRepo.getItemByPrice(priceId);
      if (!error) {
        commit('ADD_SUBSCRIPTION_BILLING_PERIODS', {
          id,
          periods: data.prices,
        });
      }
    },
    async changePlan({ commit }, { subscriptionId, itemPriceId, quantity }) {
      const [{ data }, error] = await hBillingRepo.changeSubscriptionPlan({
        subscriptionId,
        itemPriceId,
        quantity,
      });
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }

      return [data, error];
    },
    async getSubscriptionWithScheduledChanges({ commit }, { subscriptionId }) {
      const [{ data }, error] =
        await hBillingRepo.getSubscriptionWithScheduledChanges(subscriptionId);
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }
    },
    async getSubscriptionInvoices({ commit }, { subscriptionId }) {
      const [{ data }, error] = await hBillingRepo.getInvoicesBySubscriptionId(
        subscriptionId,
      );

      return new Promise((resolve) => {
        if (!error) {
          commit('ADD_SUBSCRIPTION_INVOICES', {
            id: subscriptionId,
            invoices: data,
          });
        }
        resolve(true);
      });
    },
    async removeSubscriptionAddon(
      { commit },
      { subscriptionId, itemPriceId, quantity },
    ) {
      const [{ data }, error] = await hBillingRepo.removeSubscriptionAddon({
        subscriptionId,
        itemPriceId,
        quantity,
      });
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }

      return [data, error];
    },
    async enableAddonAutorenew({ commit }, { subscriptionId, itemPriceId }) {
      const [{ data }, error] = await hBillingRepo.rollBackAddonCancellation({
        subscriptionId,
        itemPriceId,
      });
      if (!error) {
        commit('UPDATE_SUBSCRIPTION_BY_ID', data);
      }

      return [data, error];
    },
    async fetchSubscriptionPaymentDueInvoices({ commit }) {
      const [{ data }, error] = await hBillingRepo.getInvoices({
        status: HBilling.InvoiceStatus.PAYMENT_DUE,
      });

      if (error) return;

      commit('SET_PAYMENT_DUE_INVOICES', data);
    },
  },
};

const fetchSubscriptionInvoices = async ({
  unpaidInvoices,
  commit,
  dispatch,
  rootGetters,
}) => {
  if (unpaidInvoices?.length > 1) {
    await dispatch('invoices/billingGetInvoices', {}, { root: true });

    unpaidInvoices.forEach((subscription) => {
      const firstInvoice = rootGetters[
        'invoices/getFirstInvoiceBySubscriptionId'
      ](subscription.id);
      if (firstInvoice) {
        commit('ADD_SUBSCRIPTION_INVOICES', {
          id: subscription.id,
          invoices: [firstInvoice],
        });
      }
    });

    return;
  }

  await Promise.all(
    unpaidInvoices?.map(async (subscription) => {
      await dispatch('getSubscriptionInvoices', {
        subscriptionId: subscription.id,
      });
    }),
  );
};
