import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { useDomainCheckerStore } from '@/stores/domain/domainCheckerStore';
import type { TldCategoryType, ITldCategory, ResponseError } from '@/types';
import { TLD_CATEGORY, TLDS } from '@/types';
import { toASCII } from '@/utils/helpers';
import {
  hasDomainPriceFilter,
  availableDomainListMapper,
} from '@/utils/mappers/domainsMapper';

const REQUEST_CANCEL_MESSAGE = 'Request canceled';

export const useTldCategoriesStore = defineStore('tldCategoriesStore', () => {
  const domainCheckerStore = useDomainCheckerStore();
  const tldCategoryCancelToken = ref<CancelTokenSource | null>(null);
  const tldCategories = ref<ITldCategory[]>([]);
  const loadingCategoriesList = ref<TldCategoryType[]>([]);

  const resetCategoriesStore = () => {
    tldCategoryCancelToken.value?.cancel?.();
    loadingCategoriesList.value = [];
  };

  const addLoadingCategory = (category: TldCategoryType) => {
    loadingCategoriesList.value = [...loadingCategoriesList.value, category];
  };

  const removeLoadingCategory = (category: TldCategoryType) => {
    loadingCategoriesList.value = loadingCategoriesList.value.filter(
      (item) => item !== category,
    );
  };

  const isCategoryLoading = (category: TldCategoryType) =>
    loadingCategoriesList.value.includes(category);

  const setTldCategoryCancelToken = () => {
    tldCategoryCancelToken.value = axios.CancelToken.source();
  };

  const fetchTldCategories = async () => {
    const [{ data }, err] = await hDomainsRepo.getTldCategories();

    if (err) return;

    tldCategories.value = data;
  };

  const getIsCategoryRequestCancelled = (errorMessage: string) =>
    errorMessage === REQUEST_CANCEL_MESSAGE;

  const primarySearchTld = computed(() => {
    const popularCategory = tldCategories.value.find(
      ({ type }) => type === TLD_CATEGORY.POPULAR,
    );

    return popularCategory?.tlds[0] || TLDS.COM;
  });

  const fetchAvailableDomainsPerCategory = async ({
    sld,
    category,
    categoryTlds,
    isWithPromotions,
  }: {
    sld: string;
    category: TldCategoryType;
    categoryTlds: string[];
    isWithPromotions: boolean;
  }): Promise<
    [
      { data: string[] },
      {
        error: string | ResponseError;
      } | null,
    ]
  > => {
    tldCategoryCancelToken.value?.cancel?.(REQUEST_CANCEL_MESSAGE);

    setTldCategoryCancelToken();

    addLoadingCategory(category);

    const [{ data }, err] = await hDomainsRepo.getDomainPurchaseData(
      {
        domain: toASCII(sld),
        tlds: categoryTlds,
        tag: category,
        withPromotions: isWithPromotions,
      },
      tldCategoryCancelToken.value?.token,
    );

    let mappedDomains: string[] = [];

    if (!err) {
      domainCheckerStore.setLoadedDomainsPurchaseData(data);

      mappedDomains =
        hasDomainPriceFilter(availableDomainListMapper(data)) || [];
    }

    removeLoadingCategory(category);

    return [{ data: mappedDomains }, err];
  };

  return {
    fetchTldCategories,
    fetchAvailableDomainsPerCategory,
    resetCategoriesStore,
    tldCategories,
    getIsCategoryRequestCancelled,
    isCategoryLoading,
    loadingCategoriesList,
    primarySearchTld,
  };
});
