import Ravelin from 'ravelinjs/core+track+encrypt+promise';

const initRavelin = async (publicKey: string, apiBase?: string) => {
  if (window.ravelinDeviceId) return;

  const ravelin = new Ravelin({
    key: publicKey,
    ...(apiBase && { api: apiBase }),
  });

  window.ravelinDeviceId = await ravelin.core.id();
};

export default initRavelin;
