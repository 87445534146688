import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useLoading } from '@/composables';
import { profileRepo } from '@/repositories';
import type { IProfileActivity, IProfileActivityMapped } from '@/types';
import {
  countryByCountryCode,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@/utils/helpers';

export const useProfileActivityStore = defineStore(
  'profileActivityStore',
  () => {
    const { isLoading, setIsLoading } = useLoading();
    const activities = ref<IProfileActivity[]>([]);

    const profileActivityMapped = computed((): IProfileActivityMapped[] =>
      activities.value.map((activity) => {
        const createdAt = dayjs(activity.createdAt);
        const time = createdAt.format(DEFAULT_TIME_FORMAT);
        const date = createdAt.format(DEFAULT_DATE_FORMAT);

        return {
          currentLogin: `${date} ${time}`,
          device: activity.deviceType,
          location: {
            country: countryByCountryCode(activity.countryCode),
            ip: activity.ipAddress,
          },
          firstLogin: `${date} ${time}`,
          loginTimeForDisplay: {
            time,
            date,
          },
          loginType: activity.oauth,
        };
      }),
    );

    const hasActivities = computed(() => activities.value.length > 0);

    const fetchProfileActivity = async () => {
      setIsLoading(true);
      const [{ data }, error] = await profileRepo.getProfileActivity();
      setIsLoading(false);

      if (error) {
        return;
      }

      activities.value = data;
    };

    return {
      isLoading,
      activities,
      profileActivityMapped,
      hasActivities,
      fetchProfileActivity,
    };
  },
);
