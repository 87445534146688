import { useStore } from 'vuex';

import { useOnboarding } from './useOnboarding';
import { useOnboardingCompleted } from './useOnboardingCompleted';

import { usePaymentMethodStore } from '@/stores';
import { Onboarding } from '@/types';

export const useOnboardingDomain = () => {
  const store = useStore();
  const paymentMethodStore = usePaymentMethodStore();

  const { onboardingSelectedAppNameSubmit } = useOnboardingCompleted();
  const { nextStep } = useOnboarding();

  const onOnboardingNewDomainPurchase = (
    orderId: string,
    domain: string,
    isOneClickPay: boolean,
  ) => {
    store.commit('setOnboardingDomainName', domain);

    if (isOneClickPay) {
      return store.dispatch('stepIntoDomainSetup', orderId);
    }

    if (
      !paymentMethodStore.defaultCard &&
      store.getters.getIsCpanelOnboarding
    ) {
      return nextStep(Onboarding.Step.CREATE_CPANEL_PASSWORD);
    }

    onboardingSelectedAppNameSubmit();
  };

  return {
    onOnboardingNewDomainPurchase,
  };
};
