import type { RouteRecordRaw } from 'vue-router';

import { wordpressPresetsGuard } from '@/guards/wordpressPresetsGuard';
import { Route } from '@/types';

const sideMenu = {
  sideMenuComponent: 'SectionSideMenu',
  sideMenuExists: true,
};

export default [
  {
    path: 'wordpress',
    name: Route.HostingWordpress.WORDPRESS,
    meta: {
      title: 'WordPress',
      showSubheader: false,
      hideTitle: true,
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      rateServicesTitle: 'How would you rate auto-installer experience?',
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#wordpress' },
    component: () => import('@/views/Hosting/Wordpress/WordPressMain.vue'),
    children: [
      {
        path: 'wordpress',
        name: Route.HostingWordpress.WORDPRESS_WORDPRESS,
        meta: {
          title: 'WordPress',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          ...sideMenu,
        },
        redirect: '/404',
      },
      {
        path: 'ai-troubleshooter/:domainToManage?/:directory?',
        name: Route.HostingWordpress.WORDPRESS_AI_TROUBLESHOOTER,
        meta: {
          title: 'AI Troubleshooter',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: false,
          rateServicesTitle: 'How would you rate AI Troubleshooter experience?',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/AITroubleshooter/AITroubleshooter.vue'),
      },
      {
        path: 'learn/:domainToManage?/:directory?',
        name: Route.HostingWordpress.LEARN_WITH_US,
        meta: {
          title: 'Learn with us',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: true,
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Wordpress/Sections/LearnWithUs.vue'),
      },

      {
        path: 'staging/:domainToManage?/:directory?',
        name: Route.HostingWordpress.STAGING,
        meta: {
          title: 'Staging',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: true,
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Wordpress/Sections/Staging.vue'),
      },
      {
        path: 'presets/:domainToManage?/:directory?',
        meta: {
          title: 'Presets',
          hideTitle: true,

          ...sideMenu,
        },
        beforeEnter: wordpressPresetsGuard,
        component: () => import('@/views/DeveloperTools/Wrapper.vue'),
        children: [
          {
            path: '',
            name: Route.HostingWordpress.WORDPRESS_PRESETS,
            meta: {
              createRoute: Route.HostingWordpress.WORDPRESS_PRESET_CREATE,
              editRoute: Route.HostingWordpress.WORDPRESS_PRESET_EDIT,
            },
            component: () =>
              import(
                '@/views/DeveloperTools/WordPressPresets/WordPressPresets.vue'
              ),
          },
          {
            path: 'create',
            meta: {
              title: 'Create preset',
              hideTitle: false,
              showSubheader: true,
              breadcrumbs: false,
              hideBackButton: false,
              backArrowPath: {
                name: Route.HostingWordpress.WORDPRESS_PRESETS,
              },
              previewRoute:
                Route.HostingWordpress.PRESETS_PREVIEW_WORDPRESS_TEMPLATE,
            },
            name: Route.HostingWordpress.WORDPRESS_PRESET_CREATE,
            component: () =>
              import(
                '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
              ),
          },
          {
            path: 'edit/:presetId',
            name: Route.HostingWordpress.WORDPRESS_PRESET_EDIT,
            meta: {
              title: 'Edit preset',
              hideTitle: false,
              showSubheader: true,
              breadcrumbs: false,
              hideBackButton: false,
              backArrowPath: {
                name: Route.HostingWordpress.WORDPRESS_PRESETS,
              },
              previewRoute:
                Route.HostingWordpress.PRESETS_PREVIEW_WORDPRESS_TEMPLATE,
            },
            component: () =>
              import(
                '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
              ),
          },
        ],
      },
      {
        path: 'dashboard/:domainToManage?/:directory?',
        name: Route.HostingWordpress.DASHBOARD,
        meta: {
          title: 'Overview',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: true,

          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Wordpress/Dashboard.vue'),
        children: [
          {
            path: 'install-new',
            name: Route.HostingWordpress.INSTALL_NEW_WP,
            props: () => ({
              appName: 'WordPress',
            }),
            meta: {
              hideTitle: true,
              ...sideMenu,
            },
            component: () =>
              import('@/views/Hosting/Website/Autoinstaller/AppModal.vue'),
          },
        ],
      },
      {
        name: Route.HostingWordpress.INSTALL,
        path: 'install/:preselectedDomain',
        meta: {
          title: 'WordPress Dashboard',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: true,
          ...sideMenu,
        },
        props: {
          install: {
            appName: 'WordPress',
          },
        },
        components: {
          install: () =>
            import('@/views/Hosting/Website/Autoinstaller/AppModal.vue'),
        },
      },
      {
        path: 'security/:domainToManage?/:directory?',
        name: Route.HostingWordpress.SECURITY,
        meta: {
          title: 'Security',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingWordpress.WORDPRESS,
          ],
          hideTitle: true,
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Wordpress/Security.vue'),
      },
    ],
  },
  {
    path: 'manage-wp-staging',
    name: Route.HostingWordpress.MANAGE_STAGING,
    meta: {
      title: 'Staging',
    },
    component: () => import('@/views/Hosting/Wordpress/StagingDashboard.vue'),
    redirect: { name: Route.HostingWordpress.MANAGE_STAGING_DASHBOARD },
    children: [
      {
        path: ':parent?/:directory?/dashboard/:domainToManage?',
        name: Route.HostingWordpress.MANAGE_STAGING_DASHBOARD,
        meta: {
          title: 'Staging dashboard',
          hideTitle: true,
          sideMenuExists: false,
          breadcrumbs: false,
        },
        component: () => import('@/views/Hosting/Wordpress/Sections/Core.vue'),
      },
      {
        path: ':parent?/:directory?/security/:domainToManage?',
        name: Route.HostingWordpress.MANAGE_STAGING_SECURITY,
        meta: {
          title: 'Staging security',
          hideTitle: true,
          sideMenuExists: false,
          breadcrumbs: false,
        },
        component: () => import('@/views/Hosting/Wordpress/Security.vue'),
      },
    ],
  },
  {
    path: ':domainToManage?/:directory?/litespeed-settings',
    name: Route.HostingWordpress.LITESPEED_SETTINGS,
    meta: {
      title: 'LiteSpeed Settings',
      hideTitle: true,
      sideMenuExists: 'hidden',
      breadcrumbs: false,
    },
    component: () => import('@/views/Hosting/Wordpress/LiteSpeedSettings.vue'),
  },
  {
    path: ':domainToManage?/:directory?/presets/template-preview/:slug',
    name: Route.HostingWordpress.PRESETS_PREVIEW_WORDPRESS_TEMPLATE,
    meta: {
      title: 'Preview Template',
      header: false,
      showSubheader: false,
      fullHeight: true,
      fullscreen: true,
      usePageWithSideMenu: false,
      createRoute: Route.HostingWordpress.WORDPRESS_PRESET_CREATE,
      editRoute: Route.HostingWordpress.WORDPRESS_PRESET_EDIT,
    },
    component: () =>
      import(
        '@/views/DeveloperTools/WordPressPresets/WordPressThemePreview.vue'
      ),
  },
] as RouteRecordRaw[];
