import { cloneDeep } from 'lodash';

import { router } from '@/router';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

export const getCurrentDomain = () => router.currentRoute.value.params.domain;

export const currentOrderId = () => router.currentRoute.value.params.order_id;

export const currentUsername = () => router.currentRoute.value.params.username;

export const getCurrentId = () => router.currentRoute.value.params.id;

export const getStateVariable = (state, param, requestDomain = null) => {
  const requestDomainDefault =
    requestDomain || getCurrentDomain() || getCurrentId();

  const stateForDomain = state.data.find(
    ({ domain }) => domain === requestDomainDefault,
  );

  if (param) {
    return stateForDomain?.[param] || cloneDeep(state.initial[param]);
  }

  return stateForDomain || cloneDeep(state.initial);
};

export const setForDomain = (state, requestDomain, param, data) => {
  const requestDomainDefault =
    requestDomain || getCurrentDomain() || getCurrentId();

  const domainIndex = state.data.findIndex(
    ({ domain }) => domain === requestDomainDefault,
  );
  const stateForDomain = domainIndex !== -1 ? state.data[domainIndex] : null;

  if (stateForDomain) {
    state.data[domainIndex] = { ...stateForDomain, [param]: data };
  } else {
    const newDomainState = {
      ...cloneDeep(state.initial),
      domain: requestDomainDefault,
      [param]: data,
    };
    state.data = [...state.data, newDomainState];
  }
};

export const handleDuplicateHvpsRequest = (commit, serverId) => {
  commit('SET_SERVER_LOCK', { serverId, isLocked: false });
  toastr.e(i18n.t('Finishing previous changes, please wait'));
};
