import type { FieldError, FieldSchema } from '@hostinger/hcomponents';

import { INDIA_STATES_SELECT } from '@/data/indiaStatesSelect';
import { US_STATES_SELECT } from '@/data/usStatesSelect';
import { useProfileStore } from '@/stores';
import type {
  ProfileInformationV2FormSchemaMap,
  ProfileInformationModalKeyValue,
  ProfileInformationV2Values,
  HPTableConfig,
} from '@/types';
import {
  Country,
  FieldType,
  PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY,
  PROFILE_INFORMATION_MODAL_SCHEMA_KEY,
  HPTablePaginationType,
} from '@/types';
import {
  filterISOCountries,
  isoCountryToSelect,
  phoneCCSelect,
  mapKeyValue,
} from '@/utils/helpers';
import { COMPANY_CODE_VALIDATION_MAP } from '@/utils/mappers/companyCodeRegexMapper';
import { i18n } from '@/utils/services/i18nService';

export const updateNameFormSchema = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}): { firstName: FieldSchema; lastName: FieldSchema } => ({
  [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.FIRST_NAME]: {
    type: FieldType.text,
    label: 'First name',
    id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.FIRST_NAME,
    value: firstName,
    validation: {
      required: true,
      userNameAndSurnameLength: true,
      userNameAndSurnameCharacters: true,
    },
  },
  [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.LAST_NAME]: {
    type: FieldType.text,
    label: 'Last name',
    id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.LAST_NAME,
    value: lastName,
    validation: {
      required: true,
      userNameAndSurnameLength: true,
      userNameAndSurnameCharacters: true,
    },
  },
});

export const updateEmailFormSchema = ({
  email,
}: {
  email?: string;
}): { email: FieldSchema } => ({
  [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.EMAIL]: {
    type: FieldType.text,
    label: 'New email',
    value: email,
    id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.EMAIL,
    validation: {
      required: true,
      email: true,
    },
  },
});

export const updateAddressFormSchema = ({
  address,
  zip,
  city,
  state,
  countryCode,
}: {
  address?: string;
  zip?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  id?: ProfileInformationModalKeyValue;
}): {
  zip: FieldSchema;
  state?: FieldSchema;
  countryCode: FieldSchema;
  city: FieldSchema;
  address: FieldSchema;
} => {
  const REGION_SCHEME_MAP = {
    [Country.Code.US]: {
      type: FieldType.select,
      label: 'State',
      values: US_STATES_SELECT,
      validation: {
        required: true,
      },
    },
    [Country.Code.IN]: {
      type: FieldType.select,
      label: 'State',
      values: INDIA_STATES_SELECT,
      validation: {
        required: true,
      },
    },
    default: {
      type: FieldType.text,
      label: 'State/Region/Province',
      validation: {
        max: 255,
        required: true,
      },
    },
  };

  const CITY_SCHEME_MAP = {
    [Country.Code.IN]: {
      validation: {
        indiaCity: true,
        required: true,
      },
    },
    default: {
      validation: {
        max: 255,
        required: true,
      },
    },
  };

  const ADDRESS_SCHEME_MAP = {
    [Country.Code.US]: {
      validation: {
        address: true,
        max: 64,
        required: true,
      },
      hint: 'Use this street format: 1234 Main Avenue CCV',
    },
    [Country.Code.IN]: {
      validation: {
        indiaAddress: true,
        max: 64,
        required: true,
      },
      label: 'Street address',
    },
    default: {
      validation: {
        max: 64,
        required: true,
      },
    },
  };

  const ZIP_SCHEME_MAP = {
    [Country.Code.IN]: {
      label: 'ZIP/PIN code',
      validation: {
        indiaZip: true,
        required: true,
      },
    },
    default: {
      label: 'ZIP code',
      validation: {
        max: 15,
        required: true,
      },
    },
  };

  const currentCountry = isoCountryToSelect(countryCode || '');

  return {
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COUNTRY_CODE]: {
      label: 'Country',
      type: FieldType.select,
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COUNTRY_CODE,
      values: [currentCountry, ...filterISOCountries([Country.Code.UK])],
      validation: {
        required: true,
      },
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.STATE]: {
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.STATE,
      value: state,
      ...mapKeyValue(REGION_SCHEME_MAP, countryCode || ''),
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.CITY]: {
      type: FieldType.text,
      label: 'City',
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.CITY,
      value: city,
      ...mapKeyValue(CITY_SCHEME_MAP, countryCode || ''),
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.ADDRESS]: {
      type: FieldType.text,
      label: 'Address',
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.ADDRESS,
      value: address,
      ...mapKeyValue(ADDRESS_SCHEME_MAP, countryCode || ''),
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.ZIP]: {
      type: FieldType.text,
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.ZIP,
      value: zip,
      ...mapKeyValue(ZIP_SCHEME_MAP, countryCode || ''),
    },
  };
};

export const updatePhoneFormSchema = ({
  phoneCc,
  phone,
}: {
  phoneCc?: string;
  phone?: string;
}): { phoneCc: FieldSchema; phone: FieldSchema } => {
  const phoneCCSelectMapped = phoneCCSelect().reduce(
    (acc: any, curr: any) => [
      ...acc,
      { ...curr, selected: curr.value === phoneCc },
    ],
    [],
  );

  return {
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.PHONE_CC]: {
      type: FieldType.select,
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.PHONE_CC,
      values: phoneCCSelectMapped,
      validation: {
        min: 1,
        max: 4,
      },
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.PHONE]: {
      type: FieldType.text,
      label: 'v2.phone.number',
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.PHONE,
      value: phone,
      validation: {
        max: 16,
        custom: (value: string): FieldError | null => {
          if (!value) {
            return { text: 'v2.enter.a.phone.number' };
          }

          const VALID_PHONE_NUMBER_REGEX = /^\d+$/;

          if (!VALID_PHONE_NUMBER_REGEX.test(value)) {
            return { text: 'v2.enter.a.number.that.contains.numeric.values' };
          }

          if (value.length < 6) {
            return { text: 'v2.enter.a.number.thats.at.least.six.digits.long' };
          }

          return null;
        },
      },
    },
  };
};

export const updateCompanyFormSchema = ({
  companyName,
  vatId,
  companyId,
}: {
  companyName?: string;
  vatId?: string;
  companyId?: string;
}): {
  companyName: FieldSchema;
  vatId: FieldSchema;
  companyId: FieldSchema;
} => {
  const profileStore = useProfileStore();
  const countryCode = profileStore.contact?.countryCode;

  const CPF_SCHEMA_MAP = {
    [Country.Code.BR]: {
      label: 'CPF',
      placeholder:
        'CPF (Cadastro de Pessoas Físicas) code of 11 numbers. E.g. 12312312312',
    },
    [Country.Code.IN]: {
      label: 'PAN',
      placeholder: 'PAN (Permanent Account Number) of 10 characters',
    },
    default: {
      label: 'Company code',
      placeholder: '',
    },
  };

  const vatLabel = countryCode === Country.Code.IN ? 'GST' : 'VAT';
  const vatHint = i18n.t(
    countryCode === Country.Code.IN ? 'GST code format: 22AAAAA0000A1Z5' : '',
  );

  const cpfSchema = mapKeyValue(CPF_SCHEMA_MAP, countryCode || '');

  return {
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COMPANY_NAME]: {
      type: FieldType.text,
      label: 'Company',
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COMPANY_NAME,
      value: companyName,
      validation: {
        max: 255,
      },
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.VAT_ID]: {
      type: FieldType.text,
      label: vatLabel,
      hint: vatHint,
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.VAT_ID,
      value: vatId,
      validation: {
        max: 255,
      },
    },
    [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COMPANY_ID]: {
      type: FieldType.text,
      label: cpfSchema.label,
      placeholder: cpfSchema.placeholder,
      id: PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.COMPANY_ID,
      value: companyId,
      validation: {
        max: 255,
        custom: (value: string) => {
          const validator = mapKeyValue(
            COMPANY_CODE_VALIDATION_MAP,
            countryCode || '',
          );

          if (!validator || !value) {
            return null;
          }

          return validator(value)
            ? null
            : { text: 'Wrong company code format' };
        },
      },
    },
  };
};

export const profileInformationV2ModalData = <
  ID extends keyof ProfileInformationV2FormSchemaMap,
>({
  firstName,
  lastName,
  email,
  address,
  phoneCc,
  phone,
  companyName,
  countryCode,
  city,
  state,
  zip,
  companyId,
  vatId,
}: Partial<ProfileInformationV2Values>): Record<
  ProfileInformationModalKeyValue,
  {
    id: ProfileInformationModalKeyValue;
    title: string;
    subtitle?: string;
    noTrans?: boolean;
    isInline?: boolean;
    formSchema: Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>;
  }
> => ({
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME,
    title: 'Update Your Name',
    formSchema: updateNameFormSchema({
      firstName,
      lastName,
    }) as Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>,
  },
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.EMAIL]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.EMAIL,
    noTrans: true,
    title: i18n.t('v2.change.email.address'),
    subtitle: `${i18n.t('v2.enter.email.for.account')} <br />${i18n.t(
      'v2.misstyped.your.email',
    )} <a href='https://auth.hostinger.com/account-recovery' target='_blank'>${i18n.t(
      'v2.create.account.recovery.request',
    )}</a>`,
    formSchema: updateEmailFormSchema({ email }) as Record<
      ProfileInformationV2FormSchemaMap[ID],
      FieldSchema
    >,
  },
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.ADDRESS]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.ADDRESS,
    title: 'Update Your Address',
    formSchema: updateAddressFormSchema({
      address,
      city,
      countryCode,
      zip,
      state,
    }) as Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>,
  },
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.PHONE]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.PHONE,
    title: 'Update Your Phone Number',
    isInline: true,
    formSchema: updatePhoneFormSchema({
      phoneCc,
      phone,
    }) as Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>,
  },
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.COMPANY]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.COMPANY,
    title: 'Update Your Company Information',
    formSchema: updateCompanyFormSchema({
      companyName,
      vatId,
      companyId,
    }) as Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>,
  },
  [PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME_ADDRESS]: {
    id: PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME_ADDRESS,
    title: 'Update Your Personal information',
    subtitle:
      'This information is needed to process your payments and generate invoices.',
    formSchema: {
      ...updateNameFormSchema({
        firstName,
        lastName,
      }),
      ...updateAddressFormSchema({
        address,
        city,
        countryCode,
        state,
        zip,
      }),
    } as Record<ProfileInformationV2FormSchemaMap[ID], FieldSchema>,
  },
});

export const profileInformationV2ModalDataById = (
  id: ProfileInformationModalKeyValue,
  values: Partial<ProfileInformationV2Values>,
) => profileInformationV2ModalData<typeof id>(values)[id];

export const ACCOUNT_ACTIVITY_TABLE_CONFIG: HPTableConfig = {
  headers: {
    currentLogin: {
      label: 'Current Login',
      column: '20%',
      isSortable: true,
    },
    device: {
      label: 'Device',
      column: '20%',
    },
    location: {
      label: 'Location',
      column: '20%',
    },
    firstLogin: {
      label: 'First Login',
      column: '20%',
      isSortable: true,
    },
    loginType: {
      label: 'Login Type',
      column: '20%',
    },
  },
  paginationType: HPTablePaginationType.LOCAL,
  itemsPerPage: 5,
  isSearchVisible: true,
  isPaginationVisible: true,
  searchKey: 'location.country',
};
