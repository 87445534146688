<template>
  <div id="app">
    <template v-if="!$redirect.params.showGlobalAppLoader && isAppLoaded">
      <!--  All new pages should be created under isNewPage meta prop -->
      <RouterView v-if="$route.meta.isPageWrapperV2" />

      <Wrapper v-else>
        <template #body>
          <RouterView />
        </template>
      </Wrapper>

      <IntercomTicketMode v-if="showIntercomTicketMode" />
      <Modals />
      <HPopup
        :is-open="regularNpsOpen"
        :highlight="frontendSettingsStore.state.feedbackOpen"
        @h-popup-close="hPopupClose"
      />
      <HPopup :is-open="customNpsOpen" is-opened-by-user />
      <LanguageSelectionWrapper />
    </template>
    <HLoader v-else full-screen />
  </div>

  <HpActions
    id="actions-global"
    position-y="top"
    :actions="toastrMessages.slice(0, 2)"
    @close-action="
      (event) => store.commit(HToastrMutations.REMOVE_HTOASTR_MESSAGE, event)
    "
  />
</template>

<script>
import cookies from 'js-cookie';
import { computed } from 'vue';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import HPopup from '@/components/HPopup.vue';
import IntercomTicketMode from '@/components/Help/IntercomTicketMode';
import HpActions from '@/components/HpActions/HpActions.vue';
import LanguageSelectionWrapper from '@/components/Language/LanguageSelectionWrapper.vue';
import HLoader from '@/components/Loaders/HLoader';
import Modals from '@/components/Modals/Modals';
import Wrapper from '@/components/Wrapper';
import {
  useActionScheduling,
  useBrands,
  useCookies,
  useIntercom,
  useProPanelRoutes,
  useServiceExpirationReminder,
} from '@/composables';
import {
  useFeedbackStore,
  useProfileStore,
  useResourcesStore,
  useAppStore,
  useFrontendSettingsStore,
} from '@/stores';
import { useHelpStore } from '@/stores/helpStore';
import { Cookie, HToastrMutations, Route } from '@/types';
import { timeout } from '@/utils/helpers';
import { initStorageListener } from '@/utils/services/localStorageRefetchService';
import { getPhishingArticle } from '@/utils/services/supportArticleService';

export default {
  data() {
    return {
      regularNpsOpen: false,
      accountsLoaded: false,
      HToastrMutations,
    };
  },
  components: {
    IntercomTicketMode,
    Wrapper,
    Modals,
    HPopup,
    HLoader,
    LanguageSelectionWrapper,
    HpActions,
  },

  setup() {
    const resourceStore = useResourcesStore();
    const helpStore = useHelpStore();
    const { cookies, setCookies } = useCookies();
    const store = useStore();
    const toastrMessages = computed(() => store.state.hToastr.hToastrMessages);
    const feedbackStore = useFeedbackStore();
    const { initScheduledActions } = useActionScheduling();
    const { showExpirationReminderToaster } = useServiceExpirationReminder();
    const { loadIntercom } = useIntercom();
    const { isProPanelRoute, isProPanelHelpPage } = useProPanelRoutes();
    const appStore = useAppStore();
    const frontendSettingsStore = useFrontendSettingsStore();

    const profileStore = useProfileStore();

    const { isIntercomProPanelEnabled } = useBrands();

    (() => {
      appStore.initApp({ shouldCheckRequiredActions: true });

      initStorageListener();
      showExpirationReminderToaster();
    })();

    return {
      frontendSettingsStore,
      appStore,
      resourceStore,
      helpStore,
      cookies,
      setCookies,
      loadIntercom,
      isProPanelHelpPage,
      profileStore,
      feedbackStore,
      initScheduledActions,
      toastrMessages,
      isProPanelRoute,
      store,
      isIntercomProPanelEnabled,
    };
  },
  async created() {
    this.initScheduledActions();
  },
  computed: {
    hPopupOpen() {
      return (
        (this.feedbackStore.isNPSVisible &&
          !cookies.get('skip-nps') &&
          !cookies.get('nps-closed')) ||
        cookies.get('open-nps')
      );
    },
    showIntercomTicketMode() {
      const isWidgetAvailableProPanel =
        this.isProPanelRoute &&
        this.isIntercomProPanelEnabled && // isIntercomProPanelEnabled will be removed after it's fully tested
        this.helpStore.clientIntercomSettings.data;

      return (
        !this.profileStore.account?.hasPrioritySupport &&
        !this.isProPanelHelpPage &&
        this.$route?.name !== 'help' &&
        !isWidgetAvailableProPanel &&
        !this.$route?.fullPath?.includes('lets-start') &&
        !this.$route?.fullPath?.includes('start-setup') &&
        !this.$route.meta.hideIntercom &&
        !this.$route.matched.some(
          (route) => route.name === Route.OnboardingV2.BASE_PATH,
        ) &&
        !this.$route.params.slug
      );
    },
    hToastrMessages() {
      return this.$store.state.hToastr.hToastrMessages;
    },
    customNpsOpen() {
      return (
        this.frontendSettingsStore.state.feedbackOpen && !this.regularNpsOpen
      );
    },
    isAppLoaded() {
      return this.appStore.isAppLoaded;
    },
    ...mapGetters(['clientOldPanelLink', 'getLoggingOut']),
    ...mapState('home', { homeOrders: (state) => state.orders }),
  },
  methods: {
    openPhishingToaster() {
      const activeDomainResources = this.resourceStore.domainResources.filter(
        ({ state }) => state === 'active',
      );

      if (
        this.cookies[Cookie.PHISHING_BANNER_CLOSED] ||
        !activeDomainResources.length
      ) {
        return;
      }

      this.$toastr.w(this.$t('Beware of phishing attacks'), {
        text: this.$t(
          'Don’t click on suspicious links in emails and don’t reveal sensitive information',
        ),
        buttons: [
          {
            text: this.$t('Read the notice'),
            props: { primary: true, text: true },
            callback: () => {
              window.open(getPhishingArticle(), '_blank');
              this.setCookies(Cookie.PHISHING_BANNER_CLOSED, 'true');
            },
          },
        ],
        closeCallback: () => {
          this.setCookies(Cookie.PHISHING_BANNER_CLOSED, 'true');
        },
      });
    },
    async initializeIntercom(settings) {
      if (!settings) return;
      const isPriority = this.profileStore.account?.hasPrioritySupport;
      const hasConversations = await this.hasConversationTicketsWithSupport();

      if (hasConversations || isPriority) {
        this.loadIntercom();
      }
    },
    async hasConversationTicketsWithSupport() {
      const tickets = await this.helpStore.fetchHelpTickets();

      if (!tickets.length) return false;

      const conversations = tickets.filter(
        (conversations) => !conversations.isTicket,
      );

      return !!conversations.length;
    },
    async manageNPSdisplay() {
      // `trustpilot=1` is a query param that is added to the URL when the user comes back from Trustpilot.
      // in such case - nps needs to be shown without a delay where success message is displayed.
      const isComingFromTrustpilot =
        window.location.search?.includes('trustpilot=1');

      if (isComingFromTrustpilot) {
        this.regularNpsOpen = true;

        return;
      }

      if (this.feedbackStore.isNPSEligibilityLoaded) return;

      await this.feedbackStore.fetchNPSData();

      if (!this.hPopupOpen) return;

      await timeout(10000);

      if (this.showIntercomTicketMode) this.regularNpsOpen = true;
    },
    hPopupClose() {
      this.regularNpsOpen = false;
    },
    ...mapActions(['loadSso']),
  },
  watch: {
    isAppLoaded: {
      handler(value) {
        if (!value) return;
        this.manageNPSdisplay();
      },
      deep: true,
      immediate: true,
    },
    showIntercomTicketMode: {
      handler(value) {
        this.regularNpsOpen = this.hPopupOpen && value;
      },
    },
    'helpStore.clientIntercomSettings': {
      handler(settings) {
        this.initializeIntercom(settings);
      },
    },
  },
  beforeCreate() {
    window.onpageshow = (event) => {
      if (event.persisted) window.location.reload();
    };
  },
};
</script>

<style lang="scss">
@import '@/sass/layout/h-container.scss';

table caption {
  visibility: hidden;
  position: absolute;
}
</style>
