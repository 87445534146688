import { billingRepo, hBillingRepo, hPaymentsRepo } from '@/repositories';
import { usePaymentMethodStore } from '@/stores';
import { camelToSnakeObj } from '@/utils/services/namingConventionsService';

export default {
  namespaced: true,
  state: {
    hosting: [],
    renew: [],
    hostingUpgrade: [],
    cpanelHosting: [],
    renewAll: false,
    refundInvoiceInfo: null,
    refundInvoiceInfoLoaded: false,
    fullRefundList: null,
    itemsToRefund: null,
    selectedItemsToRefund: null,
    refundTotalAmount: null,
    refundReason: null,
    paymentGatewayForm: null,
    itemsToDeduct: null,
    refundSubTotalAmount: null,
    refundTotalTaxAmount: null,
    selectedToDisableRenew: null,
    userPayments: null,
    unpaidInvoices: {
      loading: false,
      data: [],
      error: null,
      canceling: false,
      buyer: null,
      seller: null,
      total: null,
      limit: 5,
      offset: 0,
    },
    services: {
      loading: false,
      data: [],
      error: null,
      total: null,
      limit: 5,
      offset: 0,
      failedToRenew: [],
      total_without_auto_renew: 0,
    },
    graceDomains: {
      loading: false,
      data: [],
      error: null,
      total: null,
      limit: 5,
      offset: 0,
    },
    pendingOrders: [],
    paymentHistory: {
      loading: false,
      data: [],
      error: null,
      total: null,
      limit: 5,
      offset: 0,
      buyer: null,
      seller: null,
      pendingInvoices: [],
    },
    paymentMethods: {
      loading: false,
      data: [],
      error: null,
      hpaymentURL: null,
    },
  },
  mutations: {
    SET_HOSTING_BILLING(state, data) {
      const hostingArray = [];
      for (const hostingID in data) {
        hostingArray.push({
          ...data[hostingID],
        });
      }
      state.hosting = hostingArray;
    },
    SET_VPS_BILLING(state, data) {
      state.vps = data;
    },
    SET_CPANEL_HOSTING_BILLING(state, data) {
      state.cpanelHosting = data;
    },
    SET_REFUND_INVOICE_INFO(state, data) {
      state.refundInvoiceInfo = data;
    },
    SET_REFUND_INVOICE_INFO_LOADED(state, data) {
      state.refundInvoiceInfoLoaded = data;
    },
    SET_ITEMS_TO_REFUND(state, data) {
      state.itemsToRefund = data;
    },
    SET_FULL_REFUND_LIST(state, data) {
      state.fullRefundList = data;
    },
    SET_SELECTED_ITEMS_TO_REFUND(state, data) {
      state.selectedItemsToRefund = data;
    },
    SET_ITEMS_TO_DEDUCT(state, data) {
      state.itemsToDeduct = data;
    },
    SET_REFUND_SUBTOTAL_AMOUNT(state, data) {
      state.refundSubTotalAmount = data;
    },
    SET_REFUND_TOTAL_TAX_AMOUNT(state, data) {
      state.refundTotalTaxAmount = data;
    },
    SET_REFUND_TOTAL_AMOUNT(state, data) {
      state.refundTotalAmount = data;
    },
    SET_REFUND_REASON(state, data) {
      state.refundReason = data;
    },
    SET_PAYMENT_GATEWAY_FORM(state, data) {
      state.paymentGatewayForm = data;
    },
    SET_UNPAID_INVOICES_CANCELING(state, data) {
      state.unpaidInvoices.canceling = data;
    },
    SET_BILLING_DATA(state, { data, type }) {
      state[type].data = data;
    },
    SET_BILLING_UNPAID_INVOICES_DATA({ unpaidInvoices }, data) {
      unpaidInvoices.buyer = data.buyer;
      unpaidInvoices.seller = data.seller;
      unpaidInvoices.data = data.invoices;
      unpaidInvoices.total = data.total;
      unpaidInvoices.limit = parseInt(data.limit);
      unpaidInvoices.offset = parseInt(data.offset);
    },
    SET_BILLING_LOADING(state, { data, type }) {
      state[type].loading = data;
    },
    SET_BILLING_ERROR(state, { data, type }) {
      state[type].error = data;
    },
    SET_BILLING_PAYMENT_HISTORY_DATA({ paymentHistory }, data) {
      paymentHistory.buyer = data.buyer;
      paymentHistory.seller = data.seller;
      paymentHistory.data = data.invoices;
      paymentHistory.total = data.total;
      paymentHistory.limit = parseInt(data.limit);
      paymentHistory.offset = parseInt(data.offset);
    },
    SET_BILLING_SERVICES_DATA(state, data) {
      state.services.data = data.services;
      state.services.total = data.total;
      state.services.total_without_auto_renew = parseInt(
        data.total_without_auto_renew,
      );
      state.services.limit = parseInt(data.limit);
      state.services.offset = parseInt(data.offset);
      state.pendingOrders = data.pending_orders;
    },
    SET_GRACE_DOMAINS_DATA({ graceDomains }, { data }) {
      graceDomains.data = data.records;
      graceDomains.total = data.total;
      graceDomains.limit = parseInt(data.limit);
      graceDomains.offset = parseInt(data.offset);
    },
    SET_BILLING_SERVICE_DATA_BY_ID({ services }, { data }) {
      const updatedServices = services.data.map((service) => {
        if (service.order_id === data.order_id) {
          return data;
        }

        return service;
      });
      services.data = updatedServices;
    },
    SET_SERVICES_CHANGING(state, data) {
      const { order_id } = data;
      const updatedServices = state.services.data.map((item) => {
        if (item.order_id === order_id) {
          return {
            ...item,
            changing: data.changing,
          };
        }

        return item;
      });
      state.services.data = updatedServices;
    },
    SET_FAILED_TO_RENEW_SERVICES(state, data) {
      state.services.failedToRenew = data;
    },
    ADD_EXTRA_DATA_TO_SERVICE(state, data) {
      const { order_id } = data;
      const updatedServices = state.services.data.map((item) => {
        if (item.order_id === order_id) {
          return {
            ...item,
            ...data,
          };
        }

        return item;
      });
      state.services.data = updatedServices;
    },
    ADD_EXTRA_DATA_TO_GRACE_DOMAIN(state, data) {
      const { order_id } = data;
      const updatedServices = state.graceDomains.data.map((item) => {
        if (item.order_id === order_id) {
          return {
            ...item,
            ...data,
          };
        }

        return item;
      });
      state.graceDomains.data = updatedServices;
    },
    SET_HPAYMENT_URL(state, data) {
      state.paymentMethods.hpaymentURL = data;
    },
    INCREMENT_SERVICES_TOTAL_WITHOUT_AUTORENEW(state) {
      state.services.total_without_auto_renew =
        state.services.total_without_auto_renew + 1;
    },
    DECREMENT_SERVICES_TOTAL_WITHOUT_AUTORENEW(state) {
      state.services.total_without_auto_renew =
        state.services.total_without_auto_renew - 1;
    },
    UPDATE_DEFAULT_PAYMENT_METHOD({ paymentMethods }, id) {
      const updatedPaymentMethods = paymentMethods.data.cards.map((method) => ({
        ...method,
        is_default: method.id === id ? 1 : 0,
      }));

      paymentMethods.data.cards = [...updatedPaymentMethods];
    },
    SET_PENDING_INVOICES(state, payload) {
      state.paymentHistory.pendingInvoices = payload;
    },
    SET_SELECTED_TO_DISABLE_RENEW(state, payload) {
      state.selectedToDisableRenew = payload;
    },
    SET_USER_PAYMENTS(state, payload) {
      state.userPayments = payload;
    },
  },
  getters: {
    getHostingBilling(state) {
      return state.hosting || {};
    },
    getRenewBilling(state) {
      return state.renew;
    },
    getRenewBillingAll(state) {
      return state.renewAll;
    },
    getHostingUpgradeBilling(state) {
      return state.hostingUpgrade;
    },
    getCpanelHostingBilling(state) {
      return state.cpanelHosting;
    },
    getRefundInvoiceInfo: (state) => state.refundInvoiceInfo,
    getRefundInvoiceInfoLoaded: (state) => state.refundInvoiceInfoLoaded,
    getItemsToRefund: (state) => state.itemsToRefund,
    getFullRefundList: (state) => state.fullRefundList,
    getSelectedItemsToRefund: (state) => state.selectedItemsToRefund,
    getItemsToDeduct: (state) => state.itemsToDeduct,
    getRefundTotalAmount: (state) => state.refundTotalAmount,
    getRefundSubTotalAmount: (state) => state.refundSubTotalAmount,
    getRefundTotalTaxAmount: (state) => state.refundTotalTaxAmount,
    getRefundReason: (state) => state.refundReason,
    getPaymentGatewayForm: (state) => state.paymentGatewayForm,
    getPendingAndActiveServiceByOrderId: (state) => (order_id) => {
      const activeService = state.services.data.find(
        (service) => service.order_id === order_id,
      );
      const pendingService = state.pendingOrders.find(
        (service) => service.order_id === order_id,
      );

      return activeService || pendingService;
    },
    getServiceByOrderId: (state) => (order_id) =>
      state.services.data.find((service) => service.order_id === order_id),
    getGraceDomainByOrderId: (state) => (order_id) =>
      state.graceDomains.data.find((service) => service.order_id === order_id),
    getServiceOrGraceDomainByOrderId: (state, getters) => (order_id) =>
      getters.getServiceByOrderId(order_id) ||
      getters.getGraceDomainByOrderId(order_id),
    getActivePaymentMethods: (state) => {
      if (!state.paymentMethods.data?.cards) return 0;

      return state.paymentMethods.data.cards.map((card) => !card.is_suspended)
        .length;
    },
    getPaymentMethods: (state) => {
      if (!state.paymentMethods.data?.cards) return 0;

      return state.paymentMethods.data.cards.length;
    },
    getFullPaymentMethods: (state) => state.paymentMethods.data.cards,
    getPaymentMethodById: (state) => (id) => {
      const payment_method_id = parseInt(id);
      if (!state.paymentMethods.data?.cards) return null;

      return state.paymentMethods?.data?.cards?.find(
        (card) => card.id === payment_method_id,
      );
    },
    getDefaultPayment: (state) => {
      if (!state.paymentMethods.data?.cards) return null;

      return state.paymentMethods.data.cards.find(
        (card) => card.is_default === 1,
      );
    },
    getPaymentsHistory: (state) => (id) =>
      state.paymentHistory.data.find(
        ({ order_to_renew_id }) => id === order_to_renew_id,
      ),
    getReceiptById: (state) => (id) => {
      if (
        !state.paymentHistory.data.length &&
        !state.unpaidInvoices.data.length
      ) {
        return null;
      }
      const invoice = state.paymentHistory.data.find((item) => item.id === id);
      if (invoice) {
        return {
          ...invoice,
          buyer: state.paymentHistory.buyer,
        };
      }
      const unpaid = state.unpaidInvoices.data.find((item) => item.id === id);
      if (unpaid) {
        return {
          ...unpaid,
          buyer: state.unpaidInvoices.buyer,
        };
      }

      return null;
    },
    getUnpaidRenewReceiptById: (state) => (id) => {
      if (!state.unpaidInvoices.data.length) return null;

      const unpaid = state.unpaidInvoices.data.find((item) => {
        if (item.is_renewal && item.order_to_renew_id) {
          return item.order_to_renew_id === id;
        }
      });

      if (unpaid) {
        return {
          ...unpaid,
          buyer: state.unpaidInvoices.buyer,
        };
      }

      return null;
    },
    getSelectedToDisableRenew: (state) => state.selectedToDisableRenew,
    getUserPayments: (state) => (id) =>
      state.userPayments.recurrent.find(
        (payment) => payment.creationDetails.transaction.invoiceNumber === id,
      ),
  },
  actions: {
    async hostingBillingIndex(context) {
      const [{ data }, error] = await billingRepo.getHostingPlans();

      if (!error) {
        const removeCamelCase = data.map((plan) => ({
          ...camelToSnakeObj(plan),
          isCloud: plan?.isCloud,
        }));
        context.commit('SET_HOSTING_BILLING', removeCamelCase);
      }
    },
    async cpanelHostingIndex(context) {
      const [{ data }, error] = await billingRepo.getCpanelHosting();

      if (!error) {
        context.commit('SET_CPANEL_HOSTING_BILLING', camelToSnakeObj(data));
      }

      return [{ data }, error];
    },
    async refundInvoiceIndex(context, id) {
      const [{ data }, error] = await billingRepo.getRefundInvoice(id);

      if (!error) {
        context.commit('SET_REFUND_INVOICE_INFO', data);
        context.commit('SET_FULL_REFUND_LIST', data);
        context.commit('SET_ITEMS_TO_REFUND', data.items);
        context.commit('SET_REFUND_INVOICE_INFO_LOADED', true);
      }

      return [{ data }, error];
    },
    async hostingRefundAccountIndex(context, id) {
      const [{ data }, error] = await billingRepo.getRefundOrder(id);

      if (!error) {
        context.commit('SET_REFUND_INVOICE_INFO', data);
        context.commit('SET_FULL_REFUND_LIST', data);
        context.commit('SET_ITEMS_TO_REFUND', data.items);
      }

      return [{ data }, error];
    },
    async billingGetUnpaidInvoices(context, { limit, offset }) {
      context.commit('SET_BILLING_LOADING', {
        data: true,
        type: 'unpaidInvoices',
      });
      const [data, err] = await billingRepo.getUnpaidInvoices({
        limit,
        offset,
      });
      if (!err) {
        context.commit(
          'SET_BILLING_UNPAID_INVOICES_DATA',
          camelToSnakeObj(data.data),
        );
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'unpaidInvoices',
        });
      }
      context.commit('SET_BILLING_LOADING', {
        data: false,
        type: 'unpaidInvoices',
      });
    },
    async billingCancelUnpaidInvoice({ commit, dispatch, state }, invoiceId) {
      commit('SET_UNPAID_INVOICES_CANCELING', true);
      const [, err] = await billingRepo.cancelUnpaidInvoice(invoiceId);
      let result = false;
      if (!err) {
        dispatch('billingGetUnpaidInvoices', {
          limit: state.unpaidInvoices.limit,
          offset: state.unpaidInvoices.offset,
        });
        result = true;
      } else {
        commit('SET_BILLING_ERROR', {
          data: err,
          type: 'unpaidInvoices',
        });
      }
      commit('SET_UNPAID_INVOICES_CANCELING', false);

      return result;
    },
    async billingGetServices(context, { limit = 0, offset = 0, search = '' }) {
      context.commit('SET_BILLING_LOADING', {
        data: true,
        type: 'services',
      });
      const [data, err] = await billingRepo.getServices({
        limit,
        offset,
        search,
      });
      if (!err) {
        context.commit('SET_BILLING_SERVICES_DATA', camelToSnakeObj(data.data));
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'services',
        });
      }
      context.commit('SET_BILLING_LOADING', {
        data: false,
        type: 'services',
      });
    },
    async billingGetGraceDomains(
      context,
      { limit = 0, offset = 0, search = '' },
    ) {
      const type = 'graceDomains';
      context.commit('SET_BILLING_LOADING', {
        data: true,
        type,
      });
      const [data, err] = await billingRepo.getGraceDomains({
        limit,
        offset,
        search,
      });
      if (!err) {
        context.commit('SET_GRACE_DOMAINS_DATA', camelToSnakeObj(data));
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type,
        });
      }
      context.commit('SET_BILLING_LOADING', {
        data: false,
        type,
      });
    },
    async billingChangeServicePeriod(context, { order_id, period }) {
      context.commit('SET_SERVICES_CHANGING', { order_id, changing: true });
      const [data, err] = await billingRepo.changeServicePeriod({
        order_id,
        period,
      });
      if (!err) {
        context.commit('SET_BILLING_SERVICE_DATA_BY_ID', camelToSnakeObj(data));
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'services',
        });
      }
      context.commit('SET_SERVICES_CHANGING', {
        order_id,
        changing: false,
      });
    },
    async billingChangeServiceAutorenew(
      context,
      { order_id, response, feedback },
    ) {
      context.commit('SET_SERVICES_CHANGING', { order_id, changing: true });
      const [data, err] = await billingRepo.changeServiceAutorenew({
        order_id,
        response,
        feedback,
      });
      if (!err) {
        context.commit('SET_BILLING_SERVICE_DATA_BY_ID', camelToSnakeObj(data));
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'services',
        });
      }
      context.commit('SET_SERVICES_CHANGING', {
        order_id,
        changing: false,
      });

      return { data, err };
    },
    async billingOrderRenewInstant(context, { order_id, selected_period }) {
      context.commit('SET_SERVICES_CHANGING', { order_id, changing: true });
      const [{ data }, error] = await billingRepo.renewServiceInstant({
        order_id,
        selected_period,
      });

      context.commit('SET_SERVICES_CHANGING', {
        order_id,
        changing: false,
      });

      return [{ data }, error];
    },
    async billingOrderRenew(context, { order_id }) {
      context.commit('SET_SERVICES_CHANGING', { order_id, changing: true });
      const [data, err] = await billingRepo.renewServiceOrder(order_id);
      if (!err && !data.error) {
        // Update services or graceDomains
        if (context.getters.getServiceByOrderId(order_id)) {
          context.commit('ADD_EXTRA_DATA_TO_SERVICE', {
            order_id,
            renew_redirect_url: data.data,
          });
        } else if (context.getters.getGraceDomainByOrderId(order_id)) {
          context.commit('ADD_EXTRA_DATA_TO_GRACE_DOMAIN', {
            order_id,
            renew_redirect_url: data.data,
          });
        }
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err || data.error,
          type: 'services',
        });
      }
      context.commit('SET_SERVICES_CHANGING', {
        order_id,
        changing: false,
      });
    },
    async billingOrderAutoRenewAll(context, { status }) {
      const [{ data }, err] = await billingRepo.autoRenewAllServices(status);
      if (!err) {
        if (data.length) {
          context.commit('SET_FAILED_TO_RENEW_SERVICES', data);
        } else if (!data.error) {
          return true;
        }
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'services',
        });
      }
    },
    async billingGetPaymentHistory(
      context,
      { limit = 0, offset = 0, search = '', from_date = '', to_date = '' },
    ) {
      context.commit('SET_BILLING_LOADING', {
        data: true,
        type: 'paymentHistory',
      });
      const [{ data }, err] = await billingRepo.getPaymentHistory({
        limit,
        offset,
        search,
        from_date,
        to_date,
      });
      if (!err) {
        context.commit(
          'SET_BILLING_PAYMENT_HISTORY_DATA',
          camelToSnakeObj(data),
        );
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'paymentHistory',
        });
      }
      context.commit('SET_BILLING_LOADING', {
        data: false,
        type: 'paymentHistory',
      });
    },
    async billingFetchDelayedPayments(context) {
      const [{ data }, err] = await billingRepo.getPaymentHistory({
        pending: 1,
      });
      if (!err) {
        context.commit('SET_PENDING_INVOICES', camelToSnakeObj(data.invoices));
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type: 'paymentHistory',
        });
      }
    },
    async billingGetPaymentMethods(context, payload) {
      const type = 'paymentMethods';
      !payload?.preventLoading &&
        context.commit('SET_BILLING_LOADING', {
          data: true,
          type,
        });
      const [{ data }, err] = await hBillingRepo.getPaymentMethods();
      if (!err) {
        context.commit('SET_BILLING_DATA', {
          data: { cards: data },
          type,
        });
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type,
        });
      }
      !payload?.preventLoading &&
        context.commit('SET_BILLING_LOADING', {
          data: false,
          type,
        });

      return true;
    },
    async billingAddPaymentMethod(context, payload) {
      const type = 'paymentMethods';
      const [{ data }, err] = await billingRepo.addPaymentMethod(payload);
      if (!err) {
        context.commit('SET_HPAYMENT_URL', data);
      } else {
        context.commit('SET_BILLING_ERROR', {
          data: err,
          type,
        });
      }
    },
    async billingRemovePaymentMethod(context, { paymentMethodId }) {
      const type = 'paymentMethods';
      const [, err] = await billingRepo.removePaymentMethod(paymentMethodId);
      if (!err) {
        context.dispatch('billingGetPaymentMethods');
        // Fetch services again: Server will disable auto renewal for all services when removing payment method
        context.dispatch('billingGetServices', { limit: 1, offset: 0 });

        return true;
      }
      context.commit('SET_BILLING_ERROR', {
        data: err,
        type,
      });

      return false;
    },
    async billingSetDefaultPaymentMethod(
      { commit },
      { paymentMethodId, methodIdentifier },
    ) {
      const type = 'paymentMethods';
      const [, err] = await billingRepo.setDefaultPaymentMethod(
        paymentMethodId,
      );
      const paymentMethodStore = usePaymentMethodStore();

      paymentMethodStore.setDefaultCard(methodIdentifier, paymentMethodId);

      if (err) {
        commit('SET_BILLING_ERROR', {
          data: err,
          type,
        });
      }
    },
    async getUserPayments({ commit }) {
      const [{ data, err }] = await hPaymentsRepo.getUserPayments();
      if (!err) commit('SET_USER_PAYMENTS', data);
    },
  },
};
