import {
  ONBOARDING_SURVEY_IDENTIFIER,
  type IHostingSurveyAnswer,
  type ISurveyAnswer,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import cacheService from '@/utils/services/http/httpCacheService';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`;

export default {
  async getSurveyResults() {
    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.get(`${URL}/response/${ONBOARDING_SURVEY_IDENTIFIER.HOSTING}`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },

  async postSurveyResponse({
    answers,
    identifier,
  }: {
    answers: ISurveyAnswer[];
    identifier?: string;
  }) {
    const surveyIdentifier = identifier ?? ONBOARDING_SURVEY_IDENTIFIER.HOSTING;

    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.post(
        `${URL}/response`,
        {
          surveyIdentifier,
          answers,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },

  async showSurveyAnswers() {
    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.get(`${URL}/show`, {
        params: {
          identifier: ONBOARDING_SURVEY_IDENTIFIER.HOSTING,
        },
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },

  async getIsEligibleForSurvey(orderId: string, identifier?: string) {
    const surveyIdentifier = identifier ?? ONBOARDING_SURVEY_IDENTIFIER.HOSTING;

    const requestUrl = `${URL}/eligibility`;
    const requestParams = {
      params: { surveyIdentifier, orderId },
      cache: 60 * 60, // 1 hours
      whitelistedStatusCodes: [400, 422, 429],
    };

    const response = await hAsync<{ isEligible: boolean }>(
      http.get(requestUrl, requestParams),
    );

    // Forget cache if user is eligible. If not eligible, keep cache for what is set above
    if (response[0].data.isEligible !== false) {
      cacheService.forgetHttpCall(requestUrl, requestParams);
    }

    return response;
  },
};
