import { onUnmounted } from 'vue';

import { useFeedbackStore } from '@/stores';
import type { CsatCustomIdentifierType, IFeedbackProps } from '@/types';

const DEFAULT_DELAY = 2000;

type LocationOption =
  | CsatCustomIdentifierType
  | (() => CsatCustomIdentifierType);

interface IOptions extends Omit<IFeedbackProps, 'location'> {
  /**
   * Unique identifier for the location where CSAT is displayed.
   * It can be a function when location should be resolved after the delay.
   */
  location: LocationOption;
  /**
   * Delay in milliseconds.
   */
  delay?: number;
}

/**
 * Utility composable, when CSAT has to be displayed with some delay (e.g. 10 seconds after page load).
 * It automatically cancels the timeout when the component is unmounted. This composable should not be
 * used outside Vue context due to lifecycle hooks.
 */
export const useDelayedCSAT = () => {
  const feedbackStore = useFeedbackStore();

  let csatTimeout: NodeJS.Timeout;

  /**
   * Starts the timer and opens the feedback form at the end.
   */
  const startTimer = ({
    location,
    delay = DEFAULT_DELAY,
    ...rest
  }: IOptions) => {
    csatTimeout = setTimeout(() => {
      feedbackStore.openCSATFeedback({
        location: typeof location === 'string' ? location : location(),
        ...rest,
      });
    }, delay);
  };

  onUnmounted(() => {
    clearTimeout(csatTimeout);
  });

  return {
    startTimer,
  };
};
