import type { IHResource } from '@/types';
import { NON_RENEWABLE_REASONS } from '@/types';

const OK_STATUS = [200, 301];

export const isWebsiteStatusOk = (status: number) => OK_STATUS.includes(status);

export const filterNonRestorableHostingResources = (
  hostingResource: IHResource,
) => !NON_RENEWABLE_REASONS.includes(hostingResource.reason);

export const isResourceRestorable = (resource: { disabledReason?: string }) => {
  if (!resource.disabledReason) return true;

  return !NON_RENEWABLE_REASONS.includes(resource.disabledReason);
};
