<script setup lang="ts">
//JSON-slugs-migrated
import { computed } from 'vue';

import BulkActionProgressModal from '@/components/Modals/HModal/HDomains/BulkActionProgressModal.vue';
import { useGlobals, useModal } from '@/composables';
import { useDomainBulkActionsStore } from '@/stores/domainBulkActionsStore';

const { t } = useGlobals();

const store = useDomainBulkActionsStore();
const { openModal } = useModal();

const infoText = computed(() =>
  store.isBulkActionCompleted
    ? 'v2.bulk.actions.were.completed'
    : 'v2.bulk.actions.are.in.progress',
);

const infoIcon = computed(() =>
  store.isBulkActionCompleted ? 'icon-check-circle' : 'icon-timelapse',
);

const handleViewProgressClick = () => {
  openModal({ component: { BulkActionProgressModal } });
};

const handleBannerCloseClick = () => {
  store.deleteBulkAction();
  store.resetBulkActions();
};
</script>

<template>
  <div class="bulk-action-banner">
    <div class="bulk-action-banner__details">
      <HpIcon :icon="infoIcon" success class="h-mr-8" />
      <Trans class="h-mr-8">{{ infoText }}</Trans>
      <hp-hyper-link bold color.prop="primary" @click="handleViewProgressClick">
        {{ t('v2.view.summary') }}
      </hp-hyper-link>
    </div>
    <HpIcon
      v-if="store.isBulkActionCompleted"
      class="close-icon"
      icon="icon-close"
      role="button"
      gray
      @click="handleBannerCloseClick"
    />
  </div>
</template>

<style scoped lang="scss">
.bulk-action-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  background: var(--light);
  width: 100%;
  padding: 16px 20px;
  position: relative;

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 24px;
    min-height: 26px;
  }
}

.close-icon {
  position: absolute;
  right: 20px;
  margin-left: -24px;
  cursor: pointer;
}
</style>
