import { rankingCoachRepo } from '@/repositories';
import { useResourcesStore } from '@/stores';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

export default {
  namespaced: true,
  state: {
    hresourceId: null,
    orderId: null,
    rcIndexResponse: null,
    intervalId: null,
    isLoading: false,
  },
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_HRESOURCEID(state, value) {
      state.hresourceId = value;
    },
    SET_ORDERID(state, value) {
      state.orderId = value;
    },
    SET_INTERVAL_ID(state, value) {
      state.intervalId = value;
    },
    SET_RC_INDEX_RESPONSE(state, value) {
      state.rcIndexResponse = value;
    },
  },
  getters: {
    getMarketingPanelLoading(state) {
      return state.isLoading;
    },
    getRankingCoachIndexResponse(state) {
      return state.rcIndexResponse;
    },
    getRankingCoachOrderId(state) {
      return state.orderId;
    },
  },
  actions: {
    async setupRankingCoach({ commit, dispatch }, request) {
      commit('SET_IS_LOADING', true);
      commit('SET_HRESOURCEID', request.hresourceId);
      commit('SET_ORDERID', request.id);

      const [, err] = await rankingCoachRepo.setupDomain(request.referenceId, {
        hresourceId: request.hresourceId,
        domain: request.domain,
      });

      if (!err) {
        commit('SET_IS_LOADING', false);

        await this.dispatch('fetchHostingOrders');
        const data = await dispatch('loopRankingCoachIndex');

        return [{ data }, err];
      }
      err.error.inputs ? toastr.e(i18n.t(err.error.inputs.domain[0])) : null;
      commit('SET_IS_LOADING', false);

      return [{}, err];
    },

    async loopRankingCoachIndex({ commit, dispatch, state }) {
      return await new Promise((resolve) => {
        const statusInterval = setInterval(async () => {
          const response = await dispatch(
            'createRankingCoachData',
            state.orderId,
          );

          if (response) {
            clearInterval(state.intervalId);
            toastr.s(i18n.t('Your Marketing Panel is ready!'));
            commit('SET_HRESOURCEID', response.hresourceId || response.id);
            commit('SET_RC_INDEX_RESPONSE', response);

            return resolve(response);
          }
        }, 3000);

        commit('SET_INTERVAL_ID', statusInterval);
      });
    },

    async createRankingCoachData(_, id) {
      const resourceStore = useResourcesStore();

      return resourceStore.getResourceBySubscriptionId(id);
    },

    async fetchRankingCoachIndex({ commit, dispatch }, id) {
      commit('SET_IS_LOADING', true);

      const response = await dispatch('createRankingCoachData', id);

      if (response) {
        commit('SET_HRESOURCEID', response.hresourceId || response.id);
        commit('SET_RC_INDEX_RESPONSE', response);
      }

      commit('SET_IS_LOADING', false);
    },

    async rankingCoachLogin({ commit, dispatch }, id) {
      commit('SET_IS_LOADING', true);

      const resource = await dispatch('createRankingCoachData', id);

      const [{ data }, err] = await rankingCoachRepo.rankingCoachLogin(
        resource.referenceId,
      );

      if (data) {
        return [{ data }, err];
      }
      commit('SET_IS_LOADING', false);
    },
  },
};
