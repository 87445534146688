export enum MigrationStatus {
  COMPLETED = 'completed',
  DELETED = 'deleted',
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending',
  REJECTED = 'rejected',
  SOFT_REJECT = 'soft_reject',
  UPDATED = 'updated',
  HARD_REJECT = 'hard_reject',
  UPLOADED_FILES = 'uploaded_files',
}
