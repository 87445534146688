export namespace Pro {
  export enum CartStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
  }

  export enum CartStatusDisplay {
    PENDING = 'Pending',
    COMPLETED = 'Completed',
    NO_PAYMENTS = 'No payments',
  }
}
