import type { NavigationGuard } from 'vue-router';

import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useFrontendSettingsStore, useSubscriptionsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { Route } from '@/types';
import { retryAsyncFunction } from '@/utils/helpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

let domain = '';
let subscriptionId = '';
let routeName = '';

const domainGuard: NavigationGuard = async (to, from, next) => {
  const hDomainResourceStore = useHDomainResourceStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  domain = to.params.domain as string;
  subscriptionId = to.params.subscriptionId as string;
  routeName = to.name as string;

  if (!hDomainResourceStore.isHDomainResourcesLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await hDomainResourceStore.fetchHDomainsResources();
    frontendSettingsStore.setState('pageLoading', false);
  }

  frontendSettingsStore.setState('pageLoading', true);
  const requiredResourcesList = await Promise.all([
    checkDoesHDomainResourceExist(),
    checkDoesSubscriptionExist(),
  ]);
  frontendSettingsStore.setState('pageLoading', false);

  const isAllRequiredResourcesExist = requiredResourcesList.every(Boolean);

  const name = isTransferRoute()
    ? Route.Domain.TRANSFERS
    : Route.Domain.MY_DOMAINS;

  if (!isAllRequiredResourcesExist) {
    toastr.e(i18n.t('Order not found'));

    return next({ name });
  }

  const isAllowedToEnterDomainManagementByStatus =
    checkIsAllowedToEnterDomainManagementByStatus();

  if (!isAllowedToEnterDomainManagementByStatus) {
    return next({ name });
  }

  return next();
};

const checkDoesSubscriptionExist = async () => {
  const subscriptionStore = useSubscriptionsStore();

  if (
    !!subscriptionStore.getDomainSubscriptionByProperty({
      domain,
      subscriptionId,
    })
  ) {
    return true;
  }

  await subscriptionStore.fetchAllSubscriptions();

  return !!subscriptionStore.getDomainSubscriptionByProperty({
    domain,
    subscriptionId,
  });
};

const checkDoesHDomainResourceExist = async () => {
  const { fetchHDomainsResources } = useHDomainResourceStore();

  if (getIsHDomainResourceExist()) {
    return true;
  }

  await retryAsyncFunction({
    functionToRetry: fetchHDomainsResources,
    getIsRetryNeeded: () => !getIsHDomainResourceExist(),
    triesCount: 3,
    timeoutInMilliseconds: 2000,
  });

  return getIsHDomainResourceExist();
};

const getIsHDomainResourceExist = () => {
  const { getHDomainResourceByDomain, getHDomainTransferResourceByDomain } =
    useHDomainResourceStore();

  return isTransferRoute()
    ? !!getHDomainTransferResourceByDomain
    : !!getHDomainResourceByDomain(domain);
};

const checkIsAllowedToEnterDomainManagementByStatus = () => {
  if (isTransferRoute()) {
    return true;
  }

  const { checkCanEnterDomainManagement } = useHDomainState();
  const { getHDomainResourceByDomain } = useHDomainResourceStore();
  const hDomainResource = getHDomainResourceByDomain(domain);

  if (!hDomainResource) {
    return false;
  }

  return checkCanEnterDomainManagement(hDomainResource);
};

const isTransferRoute = () =>
  routeName === Route.Domain.TRANSFER_DOMAIN_MANAGEMENT;

export default domainGuard;
