<script lang="ts" setup>
import HMenuItemWrapper from '@/components/HMenuV2/HMenuItemWrapper.vue';
import { useGlobals } from '@/composables';
import { HIcon, type NavigationMenuItem } from '@/types';

const { t } = useGlobals();

type Props = {
  navigationItem?: NavigationMenuItem;
};

type Emits = {
  click: [NavigationMenuItem];
};

defineProps<Props>();
const emit = defineEmits<Emits>();
</script>

<template>
  <HMenuItemWrapper
    v-if="navigationItem"
    :id="navigationItem.slug"
    :navigation-item="navigationItem"
    class="hp-menu__item-link"
    is-button
    @click="navigationItem && emit('click', navigationItem)"
  >
    <template #icon>
      <HpIcon
        class="hp-menu__item-link-icon"
        color="gray-darker"
        view-box="0 0 16 16"
        height="16"
        width="16"
        :icon="HIcon.IC_CHEVRON_SMALL_LEFT_16"
      />
    </template>
    <span class="hp-menu__item-title">
      {{ t(navigationItem.title || 'Main menu') }}
    </span>
  </HMenuItemWrapper>
</template>
