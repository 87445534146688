<script setup lang="ts">
type Props = {
  data: {
    provider?: string;
  };
};

const props = defineProps<Props>();
</script>

<template>
  <div class="loader-content">
    <Trans tag="h2" class="font-weight-bold">Connnecting</Trans>

    <p v-if="!data.provider" v-trans>
      For a successful connection, finish the steps in the domain provider’s
      window
    </p>

    <Trans
      v-else
      :translate-params="{
        provider: props.data.provider,
      }"
      class="text-gray"
    >
      For a successful connection, finish the steps in the
      <strong>{provider}</strong>
      window
    </Trans>
  </div>
</template>

<style lang="scss" scoped>
.loader-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
}
</style>
