import type { ModalObject } from '@/types';

export default [
  {
    name: 'SendReportViaEmailModal',
    type: 'HModal',
    subtype: 'Addons',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'SaveReportAsTemplateModal',
    type: 'HModal',
    subtype: 'Addons',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ScheduleReportModal',
    type: 'HModal',
    subtype: 'Addons',
    steps: [
      {
        hideX: true,
      },
    ],
  },
] as ModalObject[];
