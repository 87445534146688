<template>
  <div
    v-if="show"
    class="h-modal__wrapper h-modal-input d-flex align-items-center justify-content-center"
    @click.self="closeModal()"
  >
    <div class="h-modal__body h-modal-input__body h-pt-48">
      <div v-if="showStepsIndicator" class="d-flex h-modal-input__stepper">
        <div
          v-for="(_, index) of new Array(stepsCount)"
          :key="index"
          class="h-modal-input__stepper-indicator"
          :class="{
            'h-modal-input__stepper-indicator--active': index <= currentStep,
          }"
        />
      </div>
      <div
        v-if="icon"
        class="h-modal-input__icon"
        :class="{
          noBackground: 'h-modal-input__transparent',
          'h-modal-input__icon--danger': iconBackground === 'danger',
        }"
      >
        <HImage :src="`@/images/${icon}`" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import hModalMixin from '@/utils/mixins/hModalMixin';

export default {
  mixins: [hModalMixin],
  props: {
    hideX: Boolean,
    icon: String,
    iconBackground: String,
    noBackground: Boolean,
    show: Boolean,
    noTrans: Boolean,
  },
  emits: ['close', 'update:show'],
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/h-modal';
@import '../../sass/components/h-input-modal';
</style>
