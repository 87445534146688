import type { INpsRequest, INpsKeyMap, INpsAnswer } from '@/types';

export const getMappedKey = (key: string, keyMap: INpsKeyMap) =>
  keyMap[key] || key;

export const mapNpsAnswers = (
  request: INpsRequest,
  keyMap: INpsKeyMap = {},
): INpsAnswer[] => {
  const answers: INpsAnswer[] = [];

  for (const key in request) {
    answers.push({
      questionSlug: getMappedKey(key, keyMap),
      answer: request[key],
    });
  }

  return answers;
};
