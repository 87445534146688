import type {
  IHostingAccount,
  IFolderIndexManagerDeleteRequest,
  IFolderIndexManagerRecord,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/directory-indexes`,

  async getDirectoryIndexes(
    accountIdentifier: HostingWebsiteAccountIdentifier,
  ) {
    return await hAsync<IFolderIndexManagerRecord[]>(
      http.get(this.url(accountIdentifier)),
    );
  },

  async createDirectoryIndexes(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: IFolderIndexManagerRecord,
  ) {
    return await hAsync<IFolderIndexManagerRecord>(
      http.post(this.url(accountIdentifier), requestData, {
        whitelistedStatusCodes: [422],
      }),
    );
  },

  async deleteDirectoryIndexes(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: IFolderIndexManagerDeleteRequest,
  ) {
    return await hAsync<IFolderIndexManagerRecord>(
      http.delete(this.url(accountIdentifier), { params: requestData }),
    );
  },
};
