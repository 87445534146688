<script setup lang="ts">
import type { HActionDropdownOptions } from './HpActions/hpActionModel';

interface Props {
  isOpen: boolean;
  options: HActionDropdownOptions[];
}

interface Emits {
  (eventName: 'selected-option', payload: HActionDropdownOptions): void;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const getGaTrackingId = (title: string) => {
  const optionText = title.trim().replace(/[\W_]/g, '_').toLowerCase() || '';

  return `hpanel_tracking-${optionText}_option`;
};
</script>

<template>
  <div v-if="isOpen" class="h-select__select-box">
    <ul class="h-select__select-box-list">
      <li
        v-for="(option, i) in options"
        :id="getGaTrackingId(option.title)"
        :key="i"
        class="h-select__select-box-list-item"
        @mousedown="emit('selected-option', option)"
      >
        {{ option.title }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.h-select {
  &__select-box {
    height: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    min-width: 100%;
    padding-bottom: 0;
    transition: opacity 0.3s;
    z-index: var(--z-index-child-1);
    box-shadow: 0 0 12px rgba(29, 30, 32, 0.16);
    background: var(--light);
    border-radius: 8px;
    opacity: 1;
    height: auto;
    border-radius: 4px;
  }

  &__select-box-list {
    margin-top: 0;
    list-style: none;
    max-height: 300px;
    padding: 0;
    margin-bottom: 0;
    overflow: auto;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
    padding: 8px 0;
    box-shadow: unset;
  }

  &__select-box-list-item {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0.7rem 1rem;
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    padding: 12px 16px;
    &.active {
      background: var(--darken-light);
      color: var(--primary);
    }

    &.special {
      font-size: 16px;
      font-weight: 700;
      color: var(--primary);
    }

    &--disabled {
      cursor: not-allowed;
    }

    &:hover {
      background: var(--darken-light);
      color: var(--primary);
    }
  }
}
</style>
