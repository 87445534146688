import type { IDataCentersResponse } from '@/types';
import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/datacenters`,
  async getAvailableDatacenters(plan: string, orderId: string) {
    return await hAsync<IDataCentersResponse>(
      http.get(`${this.url}/availability`, {
        params: { plan },
        noHostingHeaders: true,
        headers: {
          [Header.ORDER_ID]: orderId,
        },
      }),
    );
  },
};
