import type {
  IHostingAccount,
  IDatabase,
  IResponseWithPages,
  ICreateDatabaseRequest,
  IRemoteDatabase,
  ICreateRemoteDatabaseRequest,
  IGetRemoteDatabaseRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
};

export default {
  url: ({ username }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/databases`,

  async getDatabases(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    paginationParams: {
      page: number;
      perPage: number;
    },
  ) {
    return await hAsync<IResponseWithPages<IDatabase[]>>(
      http.get(this.url(accountIdentifier), {
        params: paginationParams,
        plain: true,
      }),
    );
  },

  async createDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: ICreateDatabaseRequest,
  ) {
    return await hAsync(http.post(this.url(accountIdentifier), requestData));
  },

  async deleteDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
  ) {
    return await hAsync(http.delete(`${this.url(accountIdentifier)}/${name}`));
  },

  async updatePasswordDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
    password: string,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/${name}/change-password`, {
        password,
      }),
    );
  },

  async updateDatabasePermissions(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
    permissions: IDatabase['permissions'],
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/${name}/change-permissions`, {
        permissions,
      }),
    );
  },

  async repairDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/${name}/repair`),
    );
  },

  async changeDatabaseVhost(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
    vhost: IDatabase['vhost'],
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/${name}/change-vhost`, {
        vhost,
      }),
    );
  },

  async getMyAdminLinkDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IDatabase['name'],
  ) {
    return await hAsync(
      http.get(`${this.url(accountIdentifier)}/${name}/phpmyadmin-link`),
    );
  },

  async getRemoteDatabases(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    params: IGetRemoteDatabaseRequest,
  ) {
    return await hAsync<IRemoteDatabase[]>(
      http.get(`${this.url(accountIdentifier)}/remote-connections`, { params }),
    );
  },

  async createRemoteDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IRemoteDatabase['databaseName'],
    requestData: ICreateRemoteDatabaseRequest,
  ) {
    return await hAsync(
      http.post(
        `${this.url(accountIdentifier)}/${name}/remote-connections`,
        requestData,
      ),
    );
  },

  async deleteRemoteDatabase(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    name: IRemoteDatabase['databaseName'],
    requestData: ICreateRemoteDatabaseRequest,
  ) {
    return await hAsync(
      http.delete(`${this.url(accountIdentifier)}/${name}/remote-connections`, {
        data: requestData,
      }),
    );
  },
};
