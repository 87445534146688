<script setup lang="ts">
import type { Ref } from 'vue';
import { ref } from 'vue';
import { useStore } from 'vuex';

import WordPressScanResult from '@/components/CPanel/WordPress/WordPressScanResult.vue';
import WordPressScanningInProgress from '@/components/CPanel/WordPress/WordPressScanningInProgress.vue';
import HButtonV1 from '@/components/HButtons/HButtonV1.vue';
import { useCpanel, useGlobals, useModal } from '@/composables';
import { capiRepo } from '@/repositories';
import type { ModalEmits } from '@/types';
import { SoftaculousEnum, CpanelWordPressStoreEnum, StoreEnum } from '@/types';

interface Emits extends ModalEmits {}

const emit = defineEmits<Emits>();

const store = useStore();

const { toastr, t } = useGlobals();
const { goToNextStep, close, currentStep } = useModal(emit);
const { serviceId } = useCpanel();

const scanResults: Ref = ref([]);

const startScan = async () => {
  goToNextStep(1);
  const [{ data }] = await capiRepo.softaculousImportManualInstallations(
    serviceId.value,
  );

  if (data?.list?.[SoftaculousEnum.SoftwareId.WORDPRESS]) {
    scanResults.value = Object.values(
      data.list[SoftaculousEnum.SoftwareId.WORDPRESS],
    );

    await store.dispatch(
      `${StoreEnum.ModuleNames.CPANEL_WORDPRESS}/${CpanelWordPressStoreEnum.Actions.FETCH_INSTALLATIONS}`,
      serviceId.value,
    );

    goToNextStep(2);

    return;
  }

  close();
  toastr.e(t('No WordPress installation found'));
};

const finish = () => {
  close();
  toastr.s(t('New WordPress installations has been added into your dashboard'));
};
</script>

<template>
  <WordPressScanningInProgress v-if="currentStep === 1" />

  <WordPressScanResult v-if="currentStep === 2" :results="scanResults" />

  <div v-if="currentStep !== 1" class="scan-modal__actions">
    <template v-if="currentStep === 0">
      <HButtonV1 primary text @click="close">Cancel</HButtonV1>
      <HButtonV1 primary @click="startScan">Start scan</HButtonV1>
    </template>

    <HButtonV1 v-if="currentStep === 2" primary @click="finish">
      Finish
    </HButtonV1>
  </div>
</template>

<style lang="scss" scoped>
.scan-modal {
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
</style>
