export const BUILDER_DOMAINS = [
  'sites.google.com',
  'blogspot.com',
  'blogger.com',
  'wix.com',
  'wixsite.com',
  'shopify.com',
  'myshopify.com',
  'weebly.com',
  'squarespace.com',
  'godaddysites.com',
  'webflow.io',
  'webflow.com',
  'nuvemshop.com.br',
  'jimdo.com',
  'jimdofree.com',
  'duda.co',
  'dudaone.com',
  'univer.se',
  'onuniverse.com',
  'builder-preview.com',
  'canva.site',
  'canva.com',
  'odoo.com',
  'dns-parking.com',
  'site123.com',
] as const;

export const INVALID_BLOCKED_WORDS = ['shopify', 'gdrive'];

export const INVALID_HOSTINGER_DOMAINS = [
  'hostinger.com',
  'builder.hostinger.com',
  'auth.hostinger.com',
] as const;

export const UNSUPPORTED_DOMAINS = ['wordpress.com'] as const;

export const INVALID_MIGRATION_DOMAINS = [
  ...UNSUPPORTED_DOMAINS,
  ...BUILDER_DOMAINS,
];

export const BUILDER_CMS = [
  'blogger',
  'wix',
  'shopify',
  'weebly',
  'webflow',
  'jimdo',
  'duda',
  'godaddywebsitebuilder',
  'squarespace',
] as const;
