import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useAccessManagerStore = defineStore(
  'accessManagerStore',
  () => {
    const redirectParams = reactive({ name: '', params: {} });

    const resetAllData = () => {
      redirectParams.name = '';
      redirectParams.params = {};
    };

    return {
      redirectParams,
      resetAllData,
    };
  },
  {
    persist: { storage: localStorage, key: 'access-manager-store' },
  },
);
