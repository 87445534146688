import { otherRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  accountInfo: {},
  account_status: String,
  is_last_order_account: false,
  reason: null,
  comment: null,
  delete: true,
  loaded: false,
};

export default {
  state: {
    initial,
    hostingRefundAccountInfo: null,
    data: [],
    refundDataLoaded: false,
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    addHostingOtherDeactivateAccountInfo(state, { data, request_domain }) {
      setForDomain(
        state,
        request_domain,
        'account_status',
        data.account_status,
      );
      setForDomain(
        state,
        request_domain,
        'is_last_order_account',
        data.is_last_order_account,
      );
    },
    setHostingOtherDeactivateAccountLoaded(state, { data, request_domain }) {
      setForDomain(state, request_domain, 'loaded', data);
    },
    setHostingOtherDeactivateAccountReasons(state, data) {
      setForDomain(state, null, 'reason', data.reason);
      setForDomain(state, null, 'comment', data.comment);
    },
    setHostingRefundAccountInfo(state, data) {
      state.hostingRefundAccountInfo = data;
    },
    setHostingRefundAccountInfoLoaded(state, data) {
      state.refundDataLoaded = data;
    },
  },
  getters: {
    getHostingOtherDeactivateAccountStatus: (state) =>
      getStateVariable(state, 'account_status'),
    getDeactivateAccountIsLastOrder: (state) =>
      getStateVariable(state, 'is_last_order_account'),
    getHostingOtherDeactivateAccountLoaded: (state) =>
      getStateVariable(state, 'loaded'),
    getHostingRefundAccountInfo: (state) => state.hostingRefundAccountInfo,
    getHostingRefundAccountInfoLoaded: (state) => () => state.refundDataLoaded,
  },
  actions: {
    async hostingOtherDeactivateAccountIndex(context) {
      const [{ data, requestDomain }, error] =
        await otherRepo.getDeactivateAcc();
      if (!error) {
        context.commit('addHostingOtherDeactivateAccountInfo', {
          data,
          requestDomain,
        });
        context.commit('setHostingOtherDeactivateAccountLoaded', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingOtherDeactivateAccountDestroy(context, deleteAccount) {
      const body = {
        reason: deleteAccount.reason,
        comment: deleteAccount.comment,
        delete: deleteAccount.delete,
      };

      const [{ data }, error] = await otherRepo.deleteDeactivate(body);

      if (!error) {
        context.dispatch('hostingOtherDeactivateAccountIndex', true);
        context.dispatch('fetchHostingOrders');
      }

      return [{ data }, error];
    },
  },
};
