import type { RouteRecordRaw } from 'vue-router';

import emailDkimVerifyGuard from '@/guards/emails/emailDkimVerifyGuard';
import { emailIsUpgradingGuard } from '@/guards/emails/emailIsUpgradingGuard';
import emailsTrialGuard from '@/guards/emails/emailsTrialGuard';
import emailsUpsellGuard from '@/guards/emails/emailsUpsellGuard';
import hostingEmailsGuard from '@/guards/emails/hostingEmailsGuard';
import { premiumPlanNudgeGuard } from '@/guards/emails/premiumPlanNudgeGuard';
import { Route, HIcon } from '@/types';
import { getEmailsArticle } from '@/utils/services/supportArticleService';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: {
    component: 'EmailsSideBar',
    menu: 'hosting-emails',
  },
  sideMenuBack: {
    text: 'Back to emails',
    route: 'emails',
  },
  feedback: true,
  wrapperName: 'email',
};

export default [
  {
    path: '/emails/choose-plan',
    name: Route.Email.CHOOSE_PLAN,
    meta: {
      sideMenuExists: false,
      title: 'Select Your Email Plan',
      breadcrumbs: false,
      hideBackWord: true,
    },
    component: () => import('@/views/Emails/EmailPurchasePage.vue'),
  },
  {
    path: '/emails/:domain/upgrade-plan',
    name: Route.Email.UPGRADE_PLAN,
    meta: {
      sidemenu: false,
      title: 'Upgrade Your Email Plan',
      breadcrumbs: false,
      hideBackWord: true,
    },
    beforeEnter: [emailIsUpgradingGuard, emailsUpsellGuard, hostingEmailsGuard],
    component: () =>
      import(`@/views/Emails/HostingEmails/HostingerEmailUpgrade.vue`),
  },
  {
    path: '/email/:domain/plan-selection',
    name: Route.Email.UPSELL,
    meta: {
      sideMenuExists: false,
      title: 'Select Your Email Plan',
      breadcrumbs: false,
      hideBackWord: true,
    },
    beforeEnter: [hostingEmailsGuard, premiumPlanNudgeGuard],
    component: () => import('@/views/Emails/HostingEmails/SelectPlan.vue'),
  },
  {
    path: '/emails/:domain/trial',
    name: Route.Email.HMAIL_TRIAL,
    meta: {
      sideMenuExists: false,
      title: 'Get a Domain-Based Email Address for {domain}',
      breadcrumbs: false,
      hideBackWord: true,
    },
    beforeEnter: emailsTrialGuard,
    component: () =>
      import('@/views/Emails/HostingEmails/HostingerMailTrial.vue'),
  },
  {
    path: '/email/:domain',
    name: Route.Email.HMAIL_MANAGEMENT,
    meta: {
      breadcrumbs: ['emails'],
    },
    beforeEnter: [hostingEmailsGuard, emailsUpsellGuard],
    redirect: { name: Route.Email.EMAIL_OVERVIEW },
    component: () => import('@/views/Emails/HostingEmails/EmailsWrapper.vue'),
    children: [
      {
        path: 'overview',
        name: Route.Email.EMAIL_OVERVIEW,
        meta: {
          title: 'Overview',
          breadcrumbsTitle: {
            title: '{domain} - Overview',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'overview',
          labelContent: 'New',
          labelStyles: {
            primary: true,
          },
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/Overview/EmailOverview.vue'),
      },
      {
        path: 'accounts',
        name: Route.Email.EMAIL_ACCOUNTS,
        meta: {
          title: 'Email Accounts',
          breadcrumbsTitle: {
            title: '{domain} - Email Accounts',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: HIcon.ICON_LIST,
          rateServicesTitle: 'How would you rate the Email Client setup?',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailAccounts.vue'),
      },
      {
        path: 'forwarders',
        name: Route.Email.EMAIL_FORWARDERS,
        meta: {
          title: 'Forwarders',
          breadcrumbsTitle: {
            title: '{domain} - Forwarders',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'email-forwarders',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailForwarders.vue'),
      },
      {
        path: 'alias',
        name: Route.Email.EMAIL_ALIAS,
        meta: {
          title: 'Email Alias',
          breadcrumbsTitle: {
            title: '{domain} - Email Alias',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'share',
          ...sideMenu,
        },
        component: () => import('@/views/Emails/HostingEmails/EmailAlias.vue'),
      },
      {
        path: 'automatic-replies',
        name: Route.Email.AUTOMATIC_REPLIES,
        meta: {
          title: 'Automatic Reply',
          breadcrumbsTitle: {
            title: '{domain} - Automatic Reply',
            translate: true,
          },
          breadcrumbs: [Route.Email.EMAILS],
          menu: ['hosting-emails'],
          icon: 'chat',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/AutomaticReplies.vue'),
      },
      {
        path: 'automatic-replies/create/:account?',
        name: Route.Email.CREATE_AUTOMATIC_REPLY,
        meta: {
          title: 'Create Automatic Reply',
          breadcrumbsTitle: {
            title: 'Create New',
            translate: true,
          },
          breadcrumbs: [Route.Email.EMAILS, Route.Email.AUTOMATIC_REPLIES],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/CreateAutomaticReply.vue'),
      },
      {
        path: 'automatic-replies/edit/:account',
        name: Route.Email.EDIT_AUTOMATIC_REPLY,
        meta: {
          title: 'Edit Automatic Reply',
          breadcrumbsTitle: {
            title: 'Edit',
            translate: true,
          },
          breadcrumbs: [Route.Email.EMAILS, Route.Email.AUTOMATIC_REPLIES],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/CreateAutomaticReply.vue'),
      },
      {
        path: 'connect-domain',
        name: Route.Email.CONNECT_DOMAIN,
        meta: {
          title: 'Connect Domain',
          breadcrumbsTitle: {
            title: '{domain} - Connect Domain',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'icon-chain-linked',
          warning: true,
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailConnectDomain.vue'),
      },
      {
        path: 'setup-devices',
        name: Route.Email.EMAIL_SETUP_DEVICES,
        meta: {
          title: 'Connect Apps & Devices',
          breadcrumbsTitle: {
            title: '{domain} - Connect Apps & Devices',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'setup-device',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailsSetupDevices.vue'),
      },
      {
        path: 'email-logs',
        name: Route.Email.EMAIL_LOGS,
        meta: {
          title: 'Email Logs',
          breadcrumbsTitle: {
            title: '{domain} - Email Logs',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'email-logs',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailLogs/EmailLogs.vue'),
      },
      {
        path: 'email-import',
        name: Route.Email.EMAIL_IMPORT,
        meta: {
          title: 'Email Import',
          breadcrumbsTitle: {
            title: '{domain} - Email Import',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'ic-get-app',
          ...sideMenu,
        },
        component: () => import('@/views/Emails/EmailImport.vue'),
      },
      {
        path: 'email-import/add-request',
        name: Route.Email.ADD_EMAIL_IMPORT_REQUEST,
        meta: {
          title: 'Email Import Request',
          breadcrumbs: false,
          hideBackWord: true,
          ...sideMenu,
        },
        component: () => import('@/views/Emails/NewEmailImportRequest.vue'),
      },
      {
        path: 'custom-dkim',
        name: Route.Email.CUSTOM_DKIM,
        meta: {
          title: 'Custom DKIM',
          breadcrumbsTitle: {
            title: '{domain} - Custom DKIM',
            translate: true,
          },
          breadcrumbs: ['emails'],
          menu: ['hosting-emails'],
          icon: 'icon-settings',
          ...sideMenu,
        },
        component: () =>
          import('@/views/Emails/HostingEmails/EmailCustomDkim.vue'),
      },
      {
        path: 'custom-dkim/verify/:domainResourceId',
        name: Route.Email.VERIFY_CUSTOM_DKIM,
        meta: {
          title: 'Verify DKIM record',
          breadcrumbs: false,
          ...sideMenu,
        },
        beforeEnter: emailDkimVerifyGuard,
        component: () =>
          import('@/views/Emails/HostingEmails/EmailVerifyDkim.vue'),
      },
      {
        path: 'emails-help',
        name: Route.Email.EMAILS_HELP,
        meta: {
          menu: ['hosting-emails'],
          title: 'Help',
          icon: 'ic-question-mark-outline',
          menuLink: getEmailsArticle,
        },
      },
    ],
  },
] as RouteRecordRaw[];
