<script setup lang="ts">
import cookies from 'js-cookie';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  useGlobals,
  useReferralsPromotion,
  useResourceFilters,
  useScreen,
} from '@/composables';
import {
  useFrontendSettingsStore,
  useReferralStore,
  useWebsitesStore,
} from '@/stores';
import { AmplitudeEvent, AmplitudeLocation, Route } from '@/types';
import { isOlderThanUnitsInUtc } from '@/utils/helpers';
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

const { t, amplitudeV2 } = useGlobals();
const screen = useScreen();
const router = useRouter();
const route = useRoute();
const { activePromotion, isInReferralsRoute, isBannerVisibleInRoute } =
  useReferralsPromotion();
const frontendSettingsStore = useFrontendSettingsStore();
const { hasVpsOlderThan7Days } = useResourceFilters();
const websitesStore = useWebsitesStore();
const referralStore = useReferralStore();

const isPromoClosedByClick = ref(false);
const websiteCreationDate = ref<string | undefined>();

const isWebsiteOlderThan7Days = computed(() =>
  websiteCreationDate.value
    ? isOlderThanUnitsInUtc(websiteCreationDate.value, 'day', 7)
    : false,
);

const isInWebsiteDashboard = computed(() =>
  [Route.Websites.WEBSITE_DASHBOARD, Route.Websites.BUILDER_DASHBOARD].includes(
    route.name as Route.Websites,
  ),
);

const isInVPSList = computed(() => route.name === Route.Base.SERVERS);

const isBannerVisible = computed(() => {
  if (activePromotion.value && !isBannerVisibleInRoute(activePromotion.value)) {
    return false;
  }

  if (!activePromotion.value) return false;

  if (isInReferralsRoute.value) return true;

  if (isInWebsiteDashboard.value && !isWebsiteOlderThan7Days.value) {
    return false;
  }

  if (isInVPSList.value && !hasVpsOlderThan7Days.value) {
    return false;
  }

  return (
    !isPromoClosedByClick.value &&
    !cookies.get(`${activePromotion.value?.key}-closed`)
  );
});

const backgroundColor = computed(() =>
  wrapInCssVar(
    activePromotion.value?.banner?.backgroundColor ?? 'primary-light',
  ),
);

const textColor = computed(() =>
  wrapInCssVar(activePromotion.value?.banner?.textColor ?? 'meteorite-dark'),
);

const buttonColor = computed(() =>
  wrapInCssVar(activePromotion.value?.banner?.buttonColor ?? 'danger'),
);

const closeButtonColor = computed(() =>
  wrapInCssVar(activePromotion.value?.banner?.closeButtonColor ?? 'gray'),
);

const fetchWebsitesCreationDate = async () => {
  const fetchedWebsite = await websitesStore.fetchOrGetWebsiteByDomain(
    route.params.domain as string,
  );
  websiteCreationDate.value = fetchedWebsite?.createdAt;
};

const handleReferClick = () => {
  referralStore.referralsEntryLocation =
    AmplitudeLocation.Base.PROMOTIONAL_BANNER;
  router.push({ name: Route.Referral.REFFER_AND_EARN });
  amplitudeV2(AmplitudeEvent.Referral.BANNER_ENTER);
};

const handleCloseClick = () => {
  cookies.set(`${activePromotion.value?.key}-closed`, '1', {
    expires: 21,
  });
  isPromoClosedByClick.value = true;
  amplitudeV2(AmplitudeEvent.Referral.BANNER_CLOSED);
};

watch(
  route,
  async () => {
    if (isInWebsiteDashboard.value) {
      await fetchWebsitesCreationDate();
    }

    if (isBannerVisible.value && !isInReferralsRoute.value) {
      amplitudeV2(AmplitudeEvent.Referral.BANNER_SHOWN);
    }
  },
  {
    immediate: true,
  },
);

// When reloading page in VPS page, hasVpsOlderThan7Days is not yet set before route change event.
watch(hasVpsOlderThan7Days, (curr, prev) => {
  if (curr && !prev && isInVPSList.value) {
    amplitudeV2(AmplitudeEvent.Referral.BANNER_SHOWN);
  }
});
</script>

<template>
  <div
    v-if="isBannerVisible && activePromotion"
    class="referral-promotional-bar"
    :class="{
      'referral-promotional-bar--sidemenu':
        frontendSettingsStore.isNavigationVisible && !screen.isLarge.value,
    }"
  >
    <div class="referral-promotional-bar__text-wrapper">
      <HpIcon
        v-if="activePromotion?.banner?.icon"
        :icon="activePromotion.banner.icon.name"
        :color="activePromotion.banner.icon.color"
      />
      <p
        class="referral-promotional-bar__text"
        data-qa="banner-text"
        v-html="activePromotion.banner.title"
      />
    </div>
    <div
      v-reverse-children="screen.isSmall.value"
      class="referral-promotional-bar__action-wrapper"
    >
      <HButton
        v-if="activePromotion.banner.isButtonVisible"
        size="small"
        class="referral-promotional-bar__action"
        data-qa="refer-cta"
        @click="handleReferClick"
      >
        {{ t('Refer a friend') }}
      </HButton>
    </div>
    <button
      v-if="activePromotion.banner.isCloseButtonVisible"
      data-qa="close-cta"
      class="referral-promotional-bar__close-button"
      :title="$t('Close')"
      @click="handleCloseClick"
    >
      <HpIcon icon="icon-close" class="referral-promotional-bar__close-icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '@/sass/_global-mixins.scss';

// Banner breakouts of parents padding constrains.
// Changing Wrapper broke visual tests as it removed empty space at the bottom of the page.
.referral-promotional-bar {
  position: relative;
  background-color: v-bind(backgroundColor);
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 24px;
  margin-top: -32px;
  margin-bottom: 32px;

  padding: 12px 32px;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
  }

  &--sidemenu {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media (min-width: 768px) {
    padding: 12px 32px;
    &--sidemenu {
      margin-left: -32px;
      margin-right: -32px;
      padding: 12px 48px;
    }
  }

  &__text-wrapper {
    display: flex;
    gap: 8px;
  }

  &__text {
    color: v-bind(textColor);
  }

  &__action-wrapper {
    display: flex;
    gap: 24px;
    flex-direction: row;
    align-items: center;
    @media (max-width: 576px) {
      gap: 16px;
    }
  }

  &__action {
    line-height: 20px;
    background-color: v-bind(buttonColor);
  }

  &__close-button {
    @include unstyledButton();
    height: 24px;
    @media (max-width: 576px) {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  &__close-icon {
    fill: v-bind(closeButtonColor);
  }
}
</style>
