import { usePinger } from '@/composables';
import { serverInfoRepo } from '@/repositories';
import type { IDataCenterPingRequest } from '@/types';

export const useDataCenter = () => {
  const { pingDCs } = usePinger();

  const pingDataCenters = async (params: IDataCenterPingRequest) => {
    const [{ data }, err] = await serverInfoRepo.getDCsPinger({
      plan: params.plan,
    });

    if (data && !err) return pingDCs(data, params);
  };

  return { pingDataCenters };
};
