const PROCESSOUT_SCRIPT_URL = 'https://js.processout.com/processout.js';
let isProcessoutLoaded = false;

const loadScript = (onReady: Function): void => {
  const doc: Document = document;
  const script: HTMLScriptElement = doc.createElement('script');

  const scriptLoadListener: (event: Event) => void = () => {
    onReady?.();
    isProcessoutLoaded = true;
    script.removeEventListener('load', scriptLoadListener);
  };

  script.addEventListener('load', scriptLoadListener);
  script.async = true;
  script.src = `${PROCESSOUT_SCRIPT_URL}`;

  const parentElement: HTMLElement = doc.body;
  parentElement.appendChild(script);
};

export const initProcessout = () =>
  new Promise<void>((resolve, reject) => {
    if (isProcessoutLoaded) {
      resolve();
    }

    try {
      loadScript(() => resolve());
    } catch (error) {
      reject(error);
    }
  });
