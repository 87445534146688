<template>
  <div class="go-next-step-view">
    <HImage class="h-mb-24" src="@/images/modal/modal-success-outline.svg" />
    <Trans tag="h3" class="font-weight-bold">
      {{ title }}
    </Trans>
    <Trans class="text-center text-gray h-mb-16">
      {{ description }}
    </Trans>

    <HButtonV1 primary @click="nextStep">Next step</HButtonV1>
  </div>
</template>

<script>
import { Payment } from '@/types';
import modalsMixin from '@/utils/mixins/modalsMixin';
import newPurchaseMixin from '@/utils/mixins/newPurchaseMixin';

export default {
  mixins: [modalsMixin, newPurchaseMixin],
  methods: {
    nextStep() {
      this.data.onSuccess(this.successfulInstantPay);
      this.close();
    },
  },
  computed: {
    isRazorPay() {
      return (
        this.paymentMethod.merchantAccount === Payment.MerchantAccount.RAZORPAY
      );
    },
    successfulInstantPay() {
      return this.paymentMethod && !this.isRazorPay;
    },
    title() {
      return this.successfulInstantPay ? 'Payment Successful' : 'Domain added';
    },
    description() {
      return this.successfulInstantPay
        ? 'Domain was purchased. Please fill in domain registration information'
        : 'The payment is pending. You will be able to complete the domain purchase after the website setup.';
    },
  },
};
</script>

<style lang="scss" scoped>
.go-next-step-view {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 583px;
}
</style>
