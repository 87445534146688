export namespace Home {
  export enum Section {
    HOSTING = 'Hosting',
    DOMAIN = 'Domain',
    VPS = 'VPS',
    SEO = 'SEO',
    EMAIL = 'Email',
    WEBSITE = 'Website',
  }
}
