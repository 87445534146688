<script setup lang="ts">
import { toUnicode } from '@/utils/helpers';

type Props = {
  data: {
    title: string;
    subtitle: string;
    domains: string[];
  };
};

const props = defineProps<Props>();
</script>

<template>
  <h2 class="h-mb-8">{{ props.data.title }}</h2>
  <p class="h-mb-24">{{ props.data.subtitle }}</p>
  <div class="domains-list">
    <div v-for="domain in props.data.domains" :key="domain">
      <strong>{{ toUnicode(domain) }}</strong>
    </div>
  </div>
</template>

<style scoped lang="scss">
.domains-list {
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  max-height: 216px;
  overflow-y: auto;
  padding: 16px 24px;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
