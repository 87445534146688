<script setup lang="ts">
//JSON-slugs-migrated
import { useGlobals } from '@/composables';

const { t } = useGlobals();
</script>
<template>
  <div class="hosting-deprecation-banner">
    <div class="hosting-deprecation-banner__content">
      <HpIcon icon="icon-error" warning-dark class="h-mr-8" />
      <p class="text-body-2 text-dark">
        {{
          t(
            `v2.this.page.will.be.removed.soon.youll.find.your.hosting.information.on.the.websites.page.instead`,
          )
        }}
        <a
          class="text-bold-2 text-primary h-ml-8"
          href="https://support.hostinger.com/en/articles/9323509-changes-in-hpanel-navigation"
          target="_blank"
        >
          {{ t('v2.learn.more') }}
        </a>
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hosting-deprecation-banner {
  display: flex;
  justify-content: center;
  padding: 16px 32px;
  background: var(--light);
  border-bottom: 1px solid var(--gray-border);

  &__content {
    display: flex;
  }
}
</style>
