import {
  monsoonSaleGuard,
  independenceDayGuard,
  summerSaleGuard,
  septemberSaleGuard,
  blackFridaySaleGuard,
  cyberWeekSaleGuard,
  newYearSaleGuard,
} from '@/guards/saleGuard';
import { Route, Sale } from '@/types';

const saleRoutes = [
  {
    path: '/september-sale-offer',
    name: Route.Sale.SEPTEMBER_SALE,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      showSubheader: false,
      title: 'September Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: septemberSaleGuard,
  },
  {
    path: '/monsoon-upgrade',
    name: Route.Sale.MONSOON_SALE,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      showSubheader: false,
      title: 'Monsoon Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: monsoonSaleGuard,
  },
  {
    path: '/independence-day-offer',
    name: Route.Sale.INDEPENDENCE_DAY,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      showSubheader: false,
      title: 'Independence Day Offer',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: independenceDayGuard,
  },
  {
    path: '/upgrade-summer',
    name: Route.Sale.SUMMER_SALE,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      showSubheader: false,
      title: 'Cyber Fiesta Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: summerSaleGuard,
  },
  {
    path: '/black-friday',
    name: Route.Sale.BLACK_FRIDAY,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      hideScroll: false,
      showSubheader: false,
      title: 'Black Friday Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: blackFridaySaleGuard,
  },
  {
    path: '/cyber-week',
    name: Route.Sale.CYBER_WEEK,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      hideScroll: false,
      showSubheader: false,
      title: 'Cyber Week Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: cyberWeekSaleGuard,
  },
  {
    path: '/new-year',
    name: Route.Sale.NEW_YEAR,
    meta: {
      subheader: false,
      fullscreen: true,
      fullHeight: true,
      overflowHidden: true,
      breadcrumbs: false,
      hideScroll: false,
      showSubheader: false,
      title: 'New Year Sale',
    },
    component: () => import('@/views/Sale/SaleOffer.vue'),
    beforeEnter: newYearSaleGuard,
  },
  {
    path: '/upgrades-offer',
    name: Sale.DEFAULT_POST_SALE,
    meta: {
      subheader: false,
      fullscreen: true,
      overflowHidden: true,
      breadcrumbs: false,
      hideScroll: true,
      showSubheader: false,
      title: 'Upgrades Offer',
    },
    component: () => import('@/views/Sale/PostSaleOffer.vue'),
  },
];

export default saleRoutes;
