import { isEqual } from 'lodash';
import type { Directive, VNode, VNodeArrayChildren } from 'vue';
import { isVNode } from 'vue';

import { isDefined } from '@/utils/helpers';

type SupportedVNode = VNode<Element, Element>;

const orderElementsByChildrenNodes = (
  element: Element,
  vnode: SupportedVNode,
  getOrderedChildrenNodes: (
    originalNodes: VNodeArrayChildren,
  ) => VNodeArrayChildren,
) => {
  if (!Array.isArray(vnode.children)) {
    return;
  }

  const childrenNodes = vnode.children;

  if (childrenNodes.length === 1) {
    return;
  }

  const orderedNodes = getOrderedChildrenNodes(childrenNodes);

  const orderedElements = orderedNodes
    .map((childNode) => {
      if (Array.isArray(childNode) || !isVNode(childNode) || !childNode.el) {
        return;
      }

      return childNode.el as Element;
    })
    .filter(isDefined);

  const childrenElements = Array.from(element.children);

  const hasSameOrder = isEqual(orderedElements, childrenElements);

  if (hasSameOrder) {
    return;
  }

  orderedElements.forEach((orderedElement) => {
    // Ignore nodes that are not yet linked to the parent
    if (!orderedElement.parentNode) {
      return;
    }

    element.removeChild(orderedElement);
    element.appendChild(orderedElement);
  });
};

const reverseChildrenElements = (element: Element, vnode: SupportedVNode) => {
  orderElementsByChildrenNodes(element, vnode, (originalNodes) =>
    [...originalNodes].reverse(),
  );
};

const restoreOriginalOrder = (element: Element, vnode: SupportedVNode) => {
  orderElementsByChildrenNodes(
    element,
    vnode,
    (originalNodes) => originalNodes,
  );
};

export const reverseChildren: Directive<Element, boolean> = {
  beforeMount(el, binding, vnode) {
    if (binding.value) {
      reverseChildrenElements(el, vnode);
    }
  },
  updated(el, binding, vnode) {
    if (binding.value) {
      reverseChildrenElements(el, vnode);
    } else {
      restoreOriginalOrder(el, vnode);
    }
  },
};
