import type { IOfferData, OfferDataTheme } from '@/types';
import { Sale } from '@/types';
import { getAsset } from '@/utils/helpers/assetsHelpers';

/**
 * Sale banner configuration
 */
export const bannerData = [
  {
    name: Sale.SEPTEMBER_SALE,
    title: 'Hosting upgrades clearance',
    subtitle: 'Get a better plan before upgrade prices increase',
    showLabel: false,
    button: 'Get Deal',
    style: {
      color: 'var(--light)',
      backgroundColor: 'var(--primary-dark)',
      bannerPadding: '24px',
      backgroundImage: `url(${getAsset(
        '@/images/blackFriday/septemberSaleBanner.svg',
      )})`,
      border: '1px solid var(--primary-dark)',
      buttonColor: 'danger',
      timer: {
        field: 'primary',
        label: 'light',
        theme: 'primary-dark',
      },
    },
  },
  {
    name: Sale.MONSOON_SALE,
    title: 'Monsoon Super Sale',
    subtitle: 'Get a higher plan for a fraction of its price',
    showLabel: false,
    button: 'Unlock Offer',
    style: {
      color: 'var(--meteorite-dark)',
      backgroundColor: 'var(--meteorite-light)',
      bannerPadding: '24px',
      backgroundImage: `url(${getAsset(
        '@/images/blackFriday/monsoonBanner.svg',
      )})`,
      border: '1px solid var(--meteorite-dark)',
      buttonColor: 'danger',
      timer: {
        field: 'dark',
        label: 'meteorite-dark',
        theme: 'meteorite-dark',
      },
    },
  },
  {
    name: Sale.INDEPENDENCE_DAY,
    title: 'Independence Day Super Sale',
    subtitle: 'Get a higher plan for a fraction of its price',
    showLabel: false,
    button: 'Unlock Offer',
    style: {
      color: 'var(--light)',
      backgroundColor: 'var(--primary)',
      bannerPadding: '36px 40px',
      backgroundImage: `url(${getAsset(
        '@/images/blackFriday/IndependenceDayBanner.svg',
      )})`,
      border: '1px solid var(--meteorite-dark)',
      buttonColor: 'danger',
      timer: {
        field: 'primary',
        label: 'light',
        theme: 'primary-dark',
      },
    },
  },
];

const blackFridayOfferData = {
  name: Sale.BLACK_FRIDAY,
  title: 'The biggest ever <mark>Black Friday</mark> Sale',
  subtitle:
    'Hosting upgrades prices at their lowest levels. Get more speed, more resources and better features.',
  timerTitle: 'Time left:',
  themes: {
    default: {
      color: 'var(--light)',
      backgroundColor: 'var(--black)',
      backgroundImage: `url(${getAsset(
        '@/images/blackFriday/bf-offer-bg-dark.png',
      )})`,
      backArrowColor: 'white',
      discountBackgroundColor: 'var(--dark)',
      markColor: 'var(--meteorite)',
      perksSectionBackgroundColor: 'var(--dark)',
      border: '1px solid var(--primary)',
      buttonColor: 'danger',
      timer: {
        field: 'light',
        label: 'light',
        theme: 'black-light',
      },
    },
    meteorite: {
      color: 'var(--meteorite-dark)',
      backgroundColor: 'lavender',
      backgroundImage: `url(${getAsset(
        '@/images/blackFriday/bf-offer-bg-light.png',
      )})`,
      discountBackgroundColor: 'var(--primary-charts)',
      markColor: 'var(--primary)',
      backArrowColor: 'meteorite-dark',
      perksSectionBackgroundColor: 'var(--light)',
      border: '1px solid var(--meteorite-dark)',
      buttonColor: 'danger',
      timer: {
        label: 'meteorite-dark',
        theme: 'meteorite-light',
      },
    },
  },
};

const cyberWeekOfferData = {
  ...blackFridayOfferData,
  name: Sale.CYBER_WEEK,
  title: 'The Biggest Ever <mark>Cyber Week</mark> Sale',
};

const newYearOfferData: IOfferData = {
  name: Sale.NEW_YEAR,
  title: 'New Year Super Sale',
  subtitle:
    'Get more speed, more resources and better features - all for just a fraction of the regular price',
  timerTitle: 'Deal ends in:',
  themes: {
    default: {
      color: 'var(--light)',
      backgroundColor: 'var(--primary)',
      backgroundImage: `url(${getAsset(
        '@/images/sales/ny-offer-page-background.png',
      )})`,
      backArrowColor: 'light',
      timer: {
        field: 'light',
        label: 'light',
        theme: 'primary',
      },
    },
  },
};

/**
 * Sale page configuration
 */
export const offerData: IOfferData[] = [
  {
    name: Sale.SEPTEMBER_SALE,
    title: 'Limited-time price drop on hosting upgrades',
    subtitle:
      'Last chance to get a better plan for less - prices will soon increase',
    timerTitle: 'Deal ends in:',
    themes: {
      default: {
        color: 'var(--light)',
        backgroundColor: 'var(--meteorite-light)',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/septemberSaleBackground.svg',
        )})`,
        backArrowColor: 'light',
        timer: {
          field: 'primary',
          label: 'light',
          theme: 'light-blue',
        },
      },
    },
  },
  {
    name: Sale.MONSOON_SALE,
    title: 'Monsoon Sale Ending Soon',
    subtitle:
      'Hosting upgrades prices at their lowest levels: Get more speed, more resources and better features',
    timerTitle: 'Deal ends in:',
    themes: {
      default: {
        color: 'var(--meteorite-dark)',
        backgroundColor: 'var(--meteorite-light)',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/MonsoonOfferBackground.svg',
        )})`,
        backArrowColor: 'meteorite-dark',
        timer: {
          field: 'dark',
          label: 'meteorite-dark',
          theme: 'meteorite-dark',
        },
      },
    },
  },
  {
    name: Sale.INDEPENDENCE_DAY,
    title: 'Unbeatable Independence Day Sale Ending Soon',
    subtitle:
      'Hosting upgrades prices at their lowest levels: Get more speed, more resources, and better features',
    timerTitle: 'Deal ends in:',
    themes: {
      default: {
        color: 'var(--light)',
        backgroundColor: 'var(--primary)',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/IndependenceDayOfferBackground.svg',
        )})`,
        backArrowColor: 'light',
        timer: {
          field: 'primary',
          label: 'light',
          theme: 'primary-dark',
        },
      },
    },
  },
  blackFridayOfferData,
  cyberWeekOfferData,
  newYearOfferData,
];

export const defaultSaleTheme: OfferDataTheme = {
  color: 'var(--meteorite-dark)',
  backgroundColor: 'var(--light)',
  backgroundImage: `url(${getAsset(
    '@/images/blackFriday/SummerSaleOfferBackground.svg',
  )})`,
  backArrowColor: 'meteorite-dark',
};
