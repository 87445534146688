import { hBillingRepo } from '@/repositories';

export default {
  namespaced: true,
  state: {
    loading: false,
    orders: [],
    needsOrderFetch: true,
    isFetchingUpgradedOrders: false,
    upgradedOrdersLoaded: false,
    upgradedOrders: [],
  },
  getters: {
    getAwaitingForPaymentOrders(state) {
      return state.orders.filter(
        (order) => order.status === 'awaiting_payment',
      );
    },
    getPendingSubscriptionOrders(state) {
      return state.orders.filter(
        (order) =>
          ['awaiting_payment', 'payment_initiated'].includes(order.status) &&
          order.type === 'subscription_order',
      );
    },
    getOrderByToken: (state) => (orderToken) =>
      state.orders.find((order) => order.orderToken === orderToken),
    getUpgradedOrders: (state) => state.upgradedOrders,
    getUpgradedOrdersLoaded: (state) => state.upgradedOrdersLoaded,
    getIsFetchingUpgradedOrders: (state) => state.isFetchingUpgradedOrders,
  },
  mutations: {
    SET_ORDERS(state, payload) {
      state.orders = payload;
    },
    SET_UPGRADED_ORDERS(state, payload) {
      state.upgradedOrders = payload;
    },
    SET_NEEDS_FETCH(state, payload) {
      state.needsOrderFetch = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_UPGRADED_ORDERS_LOADED(state, payload) {
      state.upgradedOrdersLoaded = payload;
    },
    SET_IS_FETCHING_UPGRADED_ORDERS(state, payload) {
      state.isFetchingUpgradedOrders = payload;
    },
    ADD_OR_UPDATE_ORDER_BY_TOKEN(state, payload) {
      const index = state.orders.findIndex(
        (item) => item.orderToken === payload.orderToken,
      );
      if (index !== -1) {
        state.orders.splice(index, 1, payload);

        return;
      }
      state.orders.push(payload);
    },
    UPDATE_ORDER(state, payload) {
      const index = state.orders.findIndex((item) => item.id === payload.id);
      if (index !== -1) state.orders.splice(index, 1, payload);
    },
  },
  actions: {
    async billingGetOrders({ commit }, payload) {
      commit('SET_LOADING', true);
      const [{ data }, error] = await hBillingRepo.getOrders(payload);
      if (!error) {
        commit('SET_ORDERS', data);
        commit('SET_NEEDS_FETCH', false);
      }
      commit('SET_LOADING', false);
    },
    async billingGetOrderByToken({ commit }, orderToken) {
      commit('SET_LOADING', true);
      const [{ data }, err] = await hBillingRepo.getOrderByOrderToken(
        orderToken,
      );
      if (!err) {
        commit('ADD_OR_UPDATE_ORDER_BY_TOKEN', data);
      }
      commit('SET_LOADING', false);

      return [{ data }, err];
    },
    async payInvoice({ commit }, { invoiceId, methodId }) {
      const [{ data }, error] = await hBillingRepo.payOrderInvoice({
        invoiceId,
        methodId,
      });
      if (!error) {
        commit('UPDATE_ORDER', data);

        return data;
      }
    },
    async fetchBillingUpgradedOrders({ state, commit }) {
      if (state.upgradedOrdersLoaded) return;
      commit('SET_IS_FETCHING_UPGRADED_ORDERS', true);
      const [{ data }, error] = await hBillingRepo.getOrders({
        params: {
          statuses: ['completed'],
          types: ['upgrade_order'],
        },
      });

      if (!error) {
        commit('SET_UPGRADED_ORDERS', data);
      }
      commit('SET_IS_FETCHING_UPGRADED_ORDERS', false);
      commit('SET_UPGRADED_ORDERS_LOADED', true);
    },
  },
};
