const getCssVariable = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name);

export const setModalOpenZIndexes = () => {
  const isMobileView = window.innerWidth <= 576;
  if (isMobileView) {
    const aboveIntercom = getCssVariable('--z-index-intercom-2');
    const hpActionMobile = getCssVariable('--z-index-intercom-3');

    document.documentElement.style.setProperty(
      '--z-index-modal',
      aboveIntercom,
    );
    document.documentElement.style.setProperty(
      '--z-index-hp-action',
      hpActionMobile,
    );

    return;
  }

  const modalOpenHpActionZIndex = getCssVariable('--z-index-hp-action-modal');
  document.documentElement.style.setProperty(
    '--z-index-hp-action',
    modalOpenHpActionZIndex,
  );
};

export const setModalCloseZIndexes = () => {
  const defaultHpActionZIndex = getCssVariable('--z-index-4');
  const defaultModalZIndex = getCssVariable('--z-index-6');

  document.documentElement.style.setProperty(
    '--z-index-hp-action',
    defaultHpActionZIndex,
  );
  document.documentElement.style.setProperty(
    '--z-index-modal',
    defaultModalZIndex,
  );
};
