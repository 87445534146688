<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import RazorPayCard from '@/components/Billing/RazorPayCard.vue';
import CircleLoader from '@/components/Loaders/CircleLoader.vue';
import PayPalCurrencyWarning from '@/components/Modals/Parts/PayPalCurrencyWarning.vue';
import { useGlobals } from '@/composables';
import { usePaymentMethodStore } from '@/stores';
import type { PricingDetails, HIconUnion } from '@/types';

type Props = {
  loading: boolean;
  pricingDetails: PricingDetails;
  isPaymentDisabled: boolean;
};

interface Emits {
  (eventName: 'edit-payment'): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { permissions, t } = useGlobals();

const store = useStore();
const paymentMethodStore = usePaymentMethodStore();
const paymentMethodsLoaded = computed(() => store.state.paymentMethods.loaded);
const defaultPaymentMethod = computed(
  () => store.getters['paymentMethods/getDefaultPaymentMethod'],
);
const showPaymentMethod = computed(
  () =>
    paymentMethodsLoaded.value &&
    defaultPaymentMethod.value &&
    props.pricingDetails.finalAmount !== 0,
);
const defaultMethod = computed(() => defaultPaymentMethod.value?.identifier);
const isRazorpayCard = computed(() => {
  const method = defaultPaymentMethod.value;

  return (
    method.merchantAccount === 'razorpay' && method.paymentMethod === 'card'
  );
});

onMounted(() => {
  if (paymentMethodsLoaded.value) return;
  store.dispatch('paymentMethods/billingGetPaymentMethods');
});
</script>

<template>
  <div class="payment-method-wrapper">
    <li
      v-if="showPaymentMethod && permissions.userHasPurchaseAccess()"
      class="payment-method"
    >
      <div class="payment-method__left">
        <h4 v-trans class="payment-method__title">Payment method</h4>
        <p
          v-tooltip="permissions.tooltipDisabled()"
          data-qa="payment-method-edit"
          class="payment-method__edit"
          :class="{
            'payment-method__edit--disabled': props.isPaymentDisabled,
          }"
          @click="emits('edit-payment')"
        >
          <CircleLoader
            v-if="loading"
            xs
            absolute
            class="payment-method__edit-spinner"
          />
          <template v-else>
            {{ t('Edit') }}
          </template>
        </p>
      </div>
      <span class="payment-method__right">
        <RazorPayCard
          v-if="isRazorpayCard"
          :method="defaultPaymentMethod"
          :reveal-icon="true"
        />
        <span v-else data-qa="payment-method-card-number">
          {{ defaultMethod }}
        </span>
        <HpIcon
          data-qa="payment-method-icon"
          :icon="paymentMethodStore.getPaymentIconName(defaultPaymentMethod) as HIconUnion"
          static-view-box
          class="payment-method__type"
        />
      </span>
    </li>
    <PayPalCurrencyWarning />
  </div>
</template>

<style lang="scss" scoped>
.payment-method-wrapper {
  margin-bottom: 8px;
}
.payment-method {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 8px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  &__left,
  &__right {
    display: flex;
    position: relative;
  }

  &__edit-spinner {
    left: 12px;

    @media screen and (max-width: 767px) {
      left: -12px;
    }
  }

  &__right {
    align-items: center;
  }

  &__type {
    margin-left: 8px;
  }

  &__title {
    margin: 0 8px 0 0;
    flex-grow: 1;
  }

  &__edit {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    font-weight: 700;
    color: var(--primary);

    &--disabled {
      color: var(--gray);
    }
  }
}
</style>
