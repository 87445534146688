import type {
  ClaimFreeTrialRequest,
  EmailServiceList,
  EmailServiceListParams,
  FreeEmailAvailableDomain,
  HAsyncError,
  IEmailDNSRecordOverview,
  IEmailDNSStatusOverview,
  ITitanMailSetupRequest,
  RequestConfig,
  ITitanEmailOrder,
  Email,
  ICustomerTitanEmailOrder,
} from '@/types';
import { hAsync, toASCII } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_HMAIL_INTEGRATION}/public/api/v1`,

  async getFreeAvailableDomains() {
    return await hAsync<FreeEmailAvailableDomain[]>(
      http.get(`${this.url}/services/setup`),
    );
  },

  async postClaimFreeEmail({
    hostingId,
    domain,
  }: {
    hostingId: number;
    domain: string;
  }) {
    return await hAsync(
      http.post(`${this.url}/services/setup/claim`, { hostingId, domain }),
    );
  },

  async claimFreeTrial(payload: ClaimFreeTrialRequest) {
    return await hAsync(http.post(`${this.url}/trial`, payload));
  },

  async getDnsStatus(domain: string) {
    return await hAsync<IEmailDNSStatusOverview>(
      http.get(`${this.url}/dns`, {
        params: {
          domain: toASCII(domain),
        },
      }),
    );
  },

  async getAllServices(
    params: EmailServiceListParams,
    options?: Omit<RequestConfig, 'params'>,
  ) {
    return (await hAsync(
      http.get(`${this.url}/services/all`, { params, ...options }),
    )) as unknown as Promise<[EmailServiceList, HAsyncError]>;
  },

  async getDnsRecords(domain: string) {
    return await hAsync<IEmailDNSRecordOverview>(
      http.get(`${this.url}/dns/check/${toASCII(domain)}`, {
        params: { provider: 'hostinger' },
      }),
    );
  },

  async getTitanMailAvailability(domain: string) {
    return await hAsync<null>(
      http.get(`${this.url}/titan-mail/availability/${domain}`, {
        whitelistedStatusCodes: [404],
      }),
    );
  },

  async getTitanOrders() {
    return await hAsync<ICustomerTitanEmailOrder[]>(
      http.get(`${this.url}/titan-mail/customer/orders`),
    );
  },

  async getTitanOrder(titanMailId: string) {
    return await hAsync<ITitanEmailOrder>(
      http.get(`${this.url}/titan-mail/${titanMailId}`),
    );
  },

  async setTitanPlanChosen(titanMailId: string) {
    return await hAsync<boolean>(
      http.post(`${this.url}/titan-mail/${titanMailId}/plan-chosen`),
    );
  },

  async setupTitanEmail(params: ITitanMailSetupRequest) {
    return await hAsync<any>(
      http.post(`${this.url}/titan-mail/setup`, params, { plain: true }),
    );
  },

  async getUserDefaultEmailProvider() {
    return await hAsync<{
      emailProvider: Email.EmailProvider.HOSTINGER | Email.EmailProvider.TITAN;
    }>(http.get(`${this.url}/customer/provider`));
  },
};
