import type {
  IH5GOrdersList,
  IH5GDiskUsageResponse,
  ResourceUsagePeriod,
  IH5GWebsitesList,
  IH5GSetupStatus,
  IH5GSetupDetails,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/h5g/orders`,
  async getSetupStatus(orderId: string, setupUuid: string) {
    return await hAsync<IH5GSetupStatus>(
      http.get(`${this.url}/${orderId}/websites/setups/${setupUuid}`, {
        noHostingHeaders: true,
      }),
    );
  },

  async postSetup(orderId: string, payload: IH5GSetupDetails) {
    return await hAsync<string>(
      http.post(`${this.url}/${orderId}/websites/setups`, payload, {
        noHostingHeaders: true,
      }),
    );
  },
  async getOrders() {
    return await hAsync<IH5GOrdersList>(
      http.get(this.url, {
        params: {
          websitesPerPage: 10,
        },
        noHostingHeaders: true,
      }),
    );
  },

  async getWebsites(
    orderId: string,
    pagination: { page: number; perPage: number },
  ) {
    return await hAsync<IH5GWebsitesList>(
      http.get(`${this.url}/${orderId}/websites`, {
        params: {
          ...pagination,
        },
        noHostingHeaders: true,
      }),
    );
  },

  async getOrderDiskUsage(orderId: number, timeframeDays: ResourceUsagePeriod) {
    return await hAsync<IH5GDiskUsageResponse>(
      http.get(`${this.url}/${orderId}/disk-usage-metrics`, {
        params: {
          timeframeDays,
        },
        noHostingHeaders: true,
      }),
    );
  },
};
