<template>
  <div
    class="purchase-features h-p-16 h-mb-16"
    :class="{ 'purchase-features--light': light }"
  >
    <ul :class="`list-unstyled h-mb-0 list-cols-${featuresColNumber}`">
      <li v-for="(feature, index) in features" :key="index">
        <PurchaseFeature :feature="feature" />
      </li>
    </ul>
  </div>
</template>

<script>
import PurchaseFeature from '@/components/Modals/Parts/PurchaseFeature.vue';

export default {
  components: { PurchaseFeature },
  props: {
    features: Array,
    light: {
      type: Boolean,
      default: false,
    },
    featuresColNumber: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/components/purchase-features';
</style>
