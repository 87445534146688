import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useStore } from 'vuex';

import { useVps } from '@/composables';
import { hVpsRepo } from '@/repositories';
import { handleDuplicateHvpsRequest } from '@/store/storeHelper';
import { useVpsServerStore } from '@/stores';
import type { ServerBackup, ServerBackupSchedule } from '@/types';
import { HVps } from '@/types';

export const useVpsBackupStore = defineStore('vpsBackup', () => {
  const { currentServer } = useVps();

  const serverStore = useVpsServerStore();

  const store = useStore();

  const serverBackups = ref<Record<string, ServerBackup[]>>({});
  const backupSchedules = ref<Record<string, ServerBackupSchedule[]>>({});

  const getServerBackups = (serverId?: string | number) =>
    serverBackups.value[serverId || currentServer.value.id] || [];

  const getServerBackupSchedules = (serverId?: string | number) =>
    backupSchedules.value[serverId || currentServer.value.id] || [];

  const setServerBackups = (serverId: string, details: ServerBackup[]) => {
    serverBackups.value = {
      ...serverBackups.value,
      [serverId]: details,
    };
  };

  const setServerBackupSchedules = (
    serverId: string,
    details: ServerBackupSchedule[],
  ) => {
    backupSchedules.value = {
      ...backupSchedules.value,
      [serverId]: details,
    };
  };

  const toggleServerBackupState = (serverId: string, scheduleId: string) => {
    const ENABLED = 'enabled';
    const DISABLED = 'disabled';

    const schedule = backupSchedules.value[serverId]?.find(
      ({ id }: any) => id === scheduleId,
    );

    if (!schedule) return;

    schedule.state = schedule.state === ENABLED ? DISABLED : ENABLED;

    backupSchedules.value = { ...backupSchedules.value };
  };

  const fetchServerBackups = async ({
    serverId,
    cached,
  }: {
    serverId: string;
    cached?: boolean;
  }) => {
    const [{ data }, error] = await hVpsRepo.getBackups(serverId, null, {
      cache: cached ? 3600 : undefined,
    });

    if (!error) {
      setServerBackups(serverId, data);
    }

    return data;
  };

  const fetchServerBackupSchedules = async () => {
    const serverId = currentServer.value.id;

    const [{ data }, error] = await hVpsRepo.getBackupsSchedule(
      String(serverId),
      {},
    );

    if (!error) {
      setServerBackupSchedules(String(serverId), data);
    }
  };

  const serverBackupRestore = async (backupId: string) => {
    const server = currentServer.value;
    const serverId = server.id;

    serverStore.setServerLock(serverId, true);

    const [{ isReplay }, error] = await hVpsRepo.restoreBackup({
      backupId,
      serverId: String(serverId),
    });

    if (isReplay) return handleDuplicateHvpsRequest(store.commit, serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    if (server.state === HVps.ServerState.ERROR) {
      serverStore.addFixingServer(serverId);
    }

    serverStore.setServerState(serverId, HVps.ServerState.RESTORING);
    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => {
        fetchServerBackups({ serverId: String(serverId) });
      },
    });
  };

  const serverBackupScheduleEnable = async (scheduleId: string) => {
    const serverId = currentServer.value.id;

    const [, error] = await hVpsRepo.enableBackups(
      String(serverId),
      scheduleId,
    );

    if (!error) {
      toggleServerBackupState(String(serverId), scheduleId);
    }
  };

  const serverBackupScheduleDisable = async (scheduleId: string) => {
    const serverId = currentServer.value.id;

    const [, error] = await hVpsRepo.disableBackups(
      String(serverId),
      scheduleId,
    );

    if (!error) {
      toggleServerBackupState(String(serverId), scheduleId);
    }
  };

  return {
    serverBackups,
    backupSchedules,
    getServerBackups,
    getServerBackupSchedules,
    setServerBackups,
    setServerBackupSchedules,
    fetchServerBackups,
    fetchServerBackupSchedules,
    toggleServerBackupState,
    serverBackupRestore,
    serverBackupScheduleEnable,
    serverBackupScheduleDisable,
  };
});
