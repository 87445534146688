import dayjs from 'dayjs';
import {
  defaults,
  isNil,
  isNumber,
  isInteger,
  isString,
  isArray,
  isFunction,
} from 'lodash';

import { toUnicode, toASCII } from '@/utils/helpers';

const resources = {
  fieldIsRequired: 'This field is required!',
  invalidFormat: 'Invalid data format',

  numberTooSmall: 'The number is too small! Minimum: {0}',
  numberTooBig: 'The number is too big! Maximum: {0}',
  invalidNumber: 'Invalid number',
  invalidInteger: 'The value is not an integer',

  textTooSmall: 'The length of text is too small! Current: {0}, Minimum: {1}',
  textTooBig: 'The length of text is too big! Current: {0}, Maximum: {1}',
  thisNotText: 'This is not a text!',

  thisNotArray: 'This is not an array!',

  selectMinItems: 'Select minimum {0} items!',
  selectMaxItems: 'Select maximum {0} items!',

  invalidDate: 'Invalid date!',
  dateIsEarly: 'The date is too early! Current: {0}, Minimum: {1}',
  dateIsLate: 'The date is too late! Current: {0}, Maximum: {1}',

  invalidIP: 'Value must be valid IPv4 or IPv6 address',
  invalidIPv4: 'Value must be valid IPv4 address',
  invalidIPv6: 'Value must be valid IPv6 address',
  invalidNamserver: 'Invalid nameserver structure',

  invalidEmail: 'Invalid email address',
  invalidURL: 'Invalid URL!',

  invalidAdress: 'Address contains invalid special character',

  invalidCard: 'Invalid card format!',
  invalidCardNumber: 'Invalid card number!',

  invalidTextContainNumber:
    'Invalid text! Cannot contains numbers or special characters',
  invalidTextContainSpec: 'Invalid text! Cannot contain special characters',
  noStringSpaceAllowed: 'Invalid text! Cannot contain whitespace',

  invalidDomainStructure: 'Invalid domain structure',
  isMoreThanOneDomain: 'This field cannot contain more than one domain',
  notJustValidDomain: 'Must contain one valid domain',

  passwordTooShort: 'Password is too short',
  passwordTooEasy:
    'Your password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter',
  passwordNoCapital: 'Password must contain capital letters',
  passwordNoNumber: 'Password must contain numbers',
  passwordsNotMatching: 'Passwords do not match',
  dateIsAfter: 'Date cannot be that big',
  dateIsBefore: 'Date cannot be that small',
  noDashes: "Text can't contain '-'",
  onlyNumbers: 'Field must contain only numbers',
  onlyName: 'Invalid {0}: input contains invalid char',
  nameTooShort:
    '{0} is too short. Please enter a name of at least 2 characters.',
  selectAccessLevel: 'Select access level',
  invalidSshFormat: 'Invalid SSH key format',
  invalidGoogleWorkspaceUsername:
    'Only letters (a-z), numbers(0-9) and periods (.) are allowed',
  invalidDBNameUsername:
    'Only letters, numbers, or underscores are allowed (first character cannot be an underscore)',
};

const checkEmpty = (value, required, messages = resources) => {
  // remove whitespace
  let valueWithoutWhiteSpace = value;

  if (isString(valueWithoutWhiteSpace)) {
    valueWithoutWhiteSpace = valueWithoutWhiteSpace.trim();
    valueWithoutWhiteSpace.replace(/^\s+|\s+$/, '');
  }

  if (isNil(valueWithoutWhiteSpace) || valueWithoutWhiteSpace === '') {
    if (required) {
      return [msg(messages.fieldIsRequired)];
    }

    return [];
  }

  return null;
};

const msg = (text, ...rest) => {
  let tempText = text;

  if (tempText !== null && rest?.length > 1) {
    for (let i = 1; i < rest.length; i++) {
      tempText = tempText?.replace(`{${i - 1}}`, rest[i]);
    }
  }

  return tempText;
};

const validators = {
  resources,

  required(value, field, model, messages = resources) {
    return checkEmpty(value, field.required, messages);
  },

  accessLevelValidation(value, field, model, messages = resources) {
    const valueWithoutWhiteSpace = value.trim() || '';
    if (isString(valueWithoutWhiteSpace)) {
      valueWithoutWhiteSpace.replace(/^\s+|\s+$/, '');
    }

    if (isNil(valueWithoutWhiteSpace) || valueWithoutWhiteSpace === '') {
      return [msg(messages.selectAccessLevel)];
    }

    return null;
  },

  checked(value, field, model, messages = resources) {
    const err = [];
    if (value !== true) {
      err.push(msg(messages.fieldIsRequired));
    }

    return err;
  },

  number(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const err = [];
    if (isNumber(value)) {
      if (!isNil(field.min) && value < field.min) {
        err.push(msg(messages.numberTooSmall, field.min));
      }

      if (!isNil(field.max) && value > field.max) {
        err.push(msg(messages.numberTooBig, field.max));
      }
    } else {
      err.push(msg(messages.invalidNumber));
    }

    return err;
  },

  isValidIp(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const ipv4Res =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
    const ipv6Res =
      /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/g;
    if (!ipv4Res.test(value) && !ipv6Res.test(value)) {
      return [msg(messages.invalidIP)];
    }
  },

  onlyNumbers(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^\d+$/;
    if (!re.test(value)) {
      return [msg(messages.onlyNumbers)];
    }
  },

  address(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^[a-zA-Z0-9\s,.'#-]{3,}$/;
    if (!re.test(value)) {
      return [msg(messages.invalidAdress)];
    }
  },

  onlyName(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;
    const re = /^[^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/; // eslint-disable-line no-useless-escape
    if (value.length === 1) {
      return [msg(messages.nameTooShort, field.label)];
    }

    if (!re.test(value)) {
      return [msg(messages.onlyName, field.label)];
    }
  },

  isIPv4(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
    if (!re.test(value)) {
      return [msg(messages.invalidIPv4)];
    }
  },
  isIPv6(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re =
      /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/g;
    if (!re.test(value)) {
      return [msg(messages.invalidIPv6)];
    }
  },
  nameserver(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (/\s/g.test(value)) {
      return [msg(messages.noStringSpaceAllowed)];
    }

    const re =
      /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/;
    if (!re.test(value)) {
      return [msg(messages.invalidNamserver)];
    }
  },
  integer(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;
    const errs = validators.number(value, field, model, messages);

    if (!isInteger(value)) {
      errs.push(msg(messages.invalidInteger));
    }

    return errs;
  },

  double(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (!isNumber(value) || isNaN(value)) {
      return [msg(messages.invalidNumber)];
    }
  },

  string(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const err = [];
    if (isString(value)) {
      if (!isNil(field.min) && value.length < field.min) {
        err.push(msg(messages.textTooSmall, value.length, field.min));
      }

      if (!isNil(field.max) && value.length > field.max) {
        err.push(msg(messages.textTooBig, value.length, field.max));
      }
    } else {
      err.push(msg(messages.thisNotText));
    }

    return err;
  },

  array(value, field, model, messages = resources) {
    if (field.required) {
      if (!isArray(value)) {
        return [msg(messages.thisNotArray)];
      }

      if (value.length === 0) {
        return [msg(messages.fieldIsRequired)];
      }
    }

    if (!isNil(value)) {
      if (!isNil(field.min) && value.length < field.min) {
        return [msg(messages.selectMinItems, field.min)];
      }

      if (!isNil(field.max) && value.length > field.max) {
        return [msg(messages.selectMaxItems, field.max)];
      }
    }
  },

  date(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const m = new Date(value);
    if (!m) {
      return [msg(messages.invalidDate)];
    }

    const err = [];

    if (!isNil(field.min)) {
      const min = new Date(field.min);
      if (m.valueOf() < min.valueOf()) {
        err.push(msg(messages.dateIsEarly, dayjs.format(m), dayjs.format(min)));
      }
    }

    if (!isNil(field.max)) {
      const max = new Date(field.max);
      if (m.valueOf() > max.valueOf()) {
        err.push(msg(messages.dateIsLate, dayjs.format(m), dayjs.format(max)));
      }
    }

    return err;
  },

  regexp(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (!isNil(field.pattern)) {
      const re = new RegExp(field.pattern);
      if (!re.test(value)) {
        return [msg(messages.invalidFormat)];
      }
    }
  },

  email(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    if (!re.test(value) || value?.length > 60) {
      return [msg(messages.invalidEmail)];
    }
  },

  url(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g; // eslint-disable-line no-useless-escape
    if (!re.test(value)) {
      return [msg(messages.invalidURL)];
    }
  },

  creditCard(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    /*  From validator.js code
            https://github.com/chriso/validator.js/blob/master/src/lib/isCreditCard.js
        */
    const creditCard =
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
    const sanitized = value?.replace(/[^0-9]+/g, '');
    if (!creditCard.test(sanitized)) {
      return [msg(messages.invalidCard)];
    }
    let sum = 0;
    let digit;
    let tmpNum;
    let shouldDouble;
    for (let i = sanitized.length - 1; i >= 0; i--) {
      digit = sanitized.substring(i, i + 1);
      tmpNum = parseInt(digit, 10);
      if (shouldDouble) {
        tmpNum *= 2;
        if (tmpNum >= 10) {
          sum += (tmpNum % 10) + 1;
        } else {
          sum += tmpNum;
        }
      } else {
        sum += tmpNum;
      }
      shouldDouble = !shouldDouble;
    }

    if (!(sum % 10 === 0 ? sanitized : false)) {
      return [msg(messages.invalidCardNumber)];
    }
  },

  alpha(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^[a-zA-Z]*$/;
    if (!re.test(value)) {
      return [msg(messages.invalidTextContainNumber)];
    }
  },
  isDomain(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);

    if (value) {
      let tempValue = value.toLowerCase();

      try {
        tempValue = toUnicode(tempValue);
      } catch {
        return [msg(messages.invalidDomainStructure)];
      }

      const domainArray = tempValue?.toLowerCase().split('.');

      domainArray[0] = domainArray[0].includes('://')
        ? domainArray[0].split('://')[1]
        : domainArray[0];

      if (domainArray.length < 2) return [msg(messages.invalidDomainStructure)];

      const domainTld = domainArray.pop();
      let valid = true;
      const reTld = /([^0-9()[\]{}*&^%$#@!]{2,63}$)/g;

      if (!reTld.test(domainTld)) return [msg(messages.invalidDomainStructure)];
      domainArray.forEach((subString) => {
        const re = /(^[a-zA-Z0-9-]{1,}$)/g;
        if (toUnicode(subString.length > 64)) valid = false;
        if (!re.test(toASCII(subString))) valid = false;
      });

      if (!valid) return [msg(messages.invalidDomainStructure)];
    }
    if (res !== null) return res;
  },

  isOneDomain(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    // Match domains
    const re =
      /((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])/gim;

    // Match value ending with domain
    const re2 =
      /((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])$/gim;

    if (value.match(re)?.length > 1) {
      return [msg(messages.isMoreThanOneDomain)];
    }

    if (!value.match(re2)) {
      return [msg(messages.notJustValidDomain)];
    }
  },
  stringNoSpaces(value, field, model, messages = resources) {
    if (/\s/.test(value.trim())) {
      return [msg(messages.noStringSpaceAllowed)];
    }
  },
  sshKey(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const keyParts = value.trim().split(' ');
    const allowedAlgorithms = [
      'ssh-rsa',
      'ssh-dss',
      'ssh-ed25519',
      'ecdsa-sha2',
    ];
    const regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (
      keyParts.length < 2 ||
      keyParts.length > 3 ||
      !allowedAlgorithms.includes(keyParts[0]) ||
      !regex.test(keyParts[1])
    ) {
      return [msg(messages.invalidSshFormat)];
    }
  },
  alphaNumeric(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^[a-zA-Z0-9]*$/;
    if (!re.test(value)) {
      return [msg(messages.invalidTextContainSpec)];
    }
  },
  dbNameUsername(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^(?!_)[a-zA-Z0-9_]+$/;
    if (!re.test(value)) {
      return [msg(messages.invalidDBNameUsername)];
    }
  },
  password(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    // Complexity
    if (!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      return [msg(messages.passwordTooEasy)];
    }
  },
  passwordWeak(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    // Password Length
    if (value.length < 6) {
      return [msg(messages.passwordTooShort)];
    }
  },

  passwordMatch(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (model.password && value !== model.password) {
      return [msg(messages.passwordsNotMatching)];
    }
  },

  passwordLength(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (field.passwordLength && value.length < field.passwordLength) {
      return [`Password must have at least ${field.passwordLength} symbols`];
    }
  },

  dateBefore(value, value2, field, model, messages = resources) {
    if (!dayjs(value).isBefore(value2)) {
      return [msg(messages.dateIsAfter)];
    }

    return [];
  },

  dateAfter(value, value2, field, model, messages = resources) {
    if (!dayjs(value).isAfter(value2)) {
      return [msg(messages.dateIsBefore)];
    }

    return [];
  },

  hdomainsRegexp(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const flags = field.regExp.replace(/.*\/([gimyu]*)$/, '$1');
    const source = field.regExp.replace(new RegExp(`^/(.*?)/${flags}$`), '$1');
    const pattern = new RegExp(source, flags);

    const fullValue = (field.prefill ?? '') + value;

    if (!pattern.test(fullValue)) {
      return [field.constraints || msg(messages.invalidFormat)];
    }
  },

  noSpecialChar(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /^[a-zA-Z0-9\s-]*$/;
    if (!re.test(value)) {
      return [msg(messages.invalidTextContainSpec)];
    }
  },

  dnsNameValidator(value, field, model, messages = resources) {
    if (value !== '@') {
      const res = checkEmpty(value, field.required, messages);
      if (res !== null) return res;

      const re = /^[a-zA-Z0-9\s-]*$/;
      if (!re.test(value)) {
        return [msg(messages.invalidTextContainSpec)];
      }
    }
  },

  noDashes(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    const re = /(-)/;
    if (re.test(value)) {
      return [msg(messages.noDashes)];
    }
  },

  emailAccountName(value, field, model, messages = resources) {
    const res = checkEmpty(value, field.required, messages);
    if (res !== null) return res;

    if (value?.length > 60) {
      return [msg(messages.invalidGoogleWorkspaceUsername)];
    }

    const re = /^([a-z0-9]+[\.a-z0-9]*[a-z0-9]|[a-z0-9]){1,50}$/i;
    if (!re.test(value)) {
      return [msg(messages.invalidGoogleWorkspaceUsername)];
    }
  },
};

Object.keys(validators).forEach((name) => {
  const fn = validators[name];
  if (isFunction(fn)) {
    fn.locale = (customMessages) => (value, field, model) =>
      fn(value, field, model, defaults(customMessages, resources));
  }
});

export default validators;
