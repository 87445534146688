import type {
  IHostingAccount,
  IHostingHotlinkProtection,
  IHostingHotlinkProtectionPatchRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}`,

  async getHotlinkProtection(
    accountIdentifier: HostingWebsiteAccountIdentifier,
  ) {
    return await hAsync<IHostingHotlinkProtection[]>(
      http.get(`${this.url(accountIdentifier)}/hotlink-protection`),
    );
  },

  async destroyHotlinkProtection(
    accountIdentifier: HostingWebsiteAccountIdentifier,
  ) {
    return await hAsync<IHostingHotlinkProtection>(
      http.delete(`${this.url(accountIdentifier)}/hotlink-protection`),
    );
  },

  async patchHotlinkProtection(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    data: IHostingHotlinkProtectionPatchRequest,
  ) {
    return await hAsync<IHostingHotlinkProtection>(
      http.put(`${this.url(accountIdentifier)}/hotlink-protection`, data, {
        whitelistedStatusCodes: [422],
      }),
    );
  },
};
