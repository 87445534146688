import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useBrands, useH5GOnboarding, useResourceFilters } from '@/composables';
import { useCookies } from '@/composables/useCookies';
import { intercomChatbotProxyRepo, wordpressRepo } from '@/repositories';
import {
  useH5GWebsitesStore,
  useHelpStore,
  useProfileStore,
  useWebsitesStore,
} from '@/stores';
import { Cookie } from '@/types';
import { launchIntercom } from '@/utils/services/intercom';
import { camelToSnakeObj } from '@/utils/services/namingConventionsService';

enum IntercomActions {
  BOOT = 'boot',
  HIDE = 'hide',
  SHOW = 'show',
  UPDATE = 'update',
  ON_SHOW = 'onShow',
  ON_HIDE = 'onHide',
  SHOW_CONVERSATION = 'showConversation',
  SHOW_TICKET = 'showTicket',
}
const intercomStyles = {
  INTERCOM_DIMENSIONS: '48px',
  INTERCOM_POSITION: '20px',
};

const INTERCOM_APP_ID = 'wx2ij3ry';
const WEBSITE_FETCH_SIZE = 20;

export const useIntercom = () => {
  const route = useRoute();
  const helpStore = useHelpStore();
  const profileStore = useProfileStore();
  const websitesStore = useWebsitesStore();
  const { isH5GFeatureEnabled } = useH5GOnboarding();
  const h5gWebsitesStore = useH5GWebsitesStore();
  const { isIndianBrand } = useBrands();
  const { removeCookies } = useCookies();

  const { hasActivePrioritySupport, hasCloudHosting } = useResourceFilters();

  const hasPrioritySupport = computed(() => hasActivePrioritySupport.value);

  const allowContact = computed(() => !profileStore.isStaff);

  const isSelfServiceEnabled = computed(
    () => profileStore.settings?.intercom.app.id !== INTERCOM_APP_ID,
  );

  const hasTickets = computed(
    () => !!(helpStore.helpTickets.length && helpStore.helpTickets[0]?.id),
  );
  const hasEmailTickets = computed(
    () => hasTickets.value && helpStore.helpTickets[0].isTicket,
  );
  const hasLiveChatTickets = computed(
    () => hasTickets.value && !helpStore.helpTickets[0].isTicket,
  );
  const isLiveChatVisible = computed(() => hasLiveChatTickets.value);

  const setPrioritySupportCrown = () => {
    const div = document.createElement('DIV');
    div.setAttribute('id', 'priority-crown');
    div.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 55 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M9.502,35l-0.035,0l-6.557,-20.495c-0.068,0.005 -0.136,0.007 -0.205,0.007c-1.493,0 -2.705,-1.147 -2.705,-2.561c0,-1.413 1.212,-2.561 2.705,-2.561c1.493,0 2.705,1.148 2.705,2.561c-0,0.872 -0.461,1.642 -1.164,2.105c2.294,1.37 5.012,2.163 7.926,2.163c6.557,0 12.124,-4.016 14.104,-9.592c-1.389,-0.474 -2.383,-1.734 -2.383,-3.212c0,-1.885 1.616,-3.415 3.607,-3.415c1.99,-0 3.606,1.53 3.606,3.415c0,1.477 -0.993,2.737 -2.382,3.212c1.981,5.576 7.547,9.592 14.104,9.592c2.914,0 5.632,-0.793 7.927,-2.163c-0.704,-0.463 -1.165,-1.233 -1.165,-2.105c0,-1.413 1.212,-2.561 2.705,-2.561c1.493,0 2.705,1.148 2.705,2.561c-0,1.414 -1.212,2.561 -2.705,2.561c-0.069,0 -0.137,-0.002 -0.204,-0.007l-6.558,20.495l-0.035,0c-5.345,-2.932 -11.479,-4.6 -17.998,-4.6c-6.519,0 -12.653,1.668 -17.998,4.6Z" style="fill:#FEA419;"/></svg>`;

    return div;
  };
  const loadIntercom = async () => {
    const isEnabled =
      helpStore.clientIntercomSettings?.enabled &&
      route?.meta?.intercom !== false;

    if (isIndianBrand.value || profileStore.isStaff) {
      return;
    }

    if (isEnabled || hasPrioritySupport.value) {
      launchIntercom();
      window.intercomSettings = await getIntercomSettings();

      window.Intercom(IntercomActions.BOOT);
      window.Intercom(IntercomActions.UPDATE);
    }

    checkIsIntercomBooted();

    window.Intercom(IntercomActions.ON_HIDE, () => {
      if (hasPrioritySupport.value) {
        addPriorityCrownToIntercomLauncher();
      }
    });

    window.Intercom(IntercomActions.ON_SHOW, async () => {
      await helpStore.fetchChatbotMetadata();
    });
  };

  const checkIsIntercomBooted = () => {
    const isIntercomBooted =
      window.Intercom.booted &&
      document.querySelector('.intercom-lightweight-app');

    const timeout = setTimeout(() => clearInterval(interval), 90000);
    const interval = setInterval(() => {
      if (!isIntercomBooted) return;

      // Intercom is booted
      clearInterval(interval);
      clearTimeout(timeout);
      setZIndexToIntercom();

      if (hasPrioritySupport.value) {
        addPriorityCrownToIntercomLauncher();
      } else {
        removePriorityCrown();
      }
    }, 1000);
  };

  const setZIndexToIntercom = () => {
    const zIndex = getComputedStyle(document.documentElement).getPropertyValue(
      '--z-index-intercom-1',
    );
    const intercomLightWeightApp = document.querySelector<HTMLElement>(
      '.intercom-lightweight-app',
    );
    const intercomLauncher =
      document.querySelector<HTMLElement>('.intercom-launcher');
    const intercomApp = document.querySelector<HTMLElement>('.intercom-app');
    const intercomButtonElement =
      intercomLightWeightApp || intercomApp || intercomLauncher;

    if (intercomButtonElement) intercomButtonElement.style.zIndex = zIndex;
  };

  const addPriorityCrownToIntercomLauncher = () => {
    const div: HTMLElement = setPrioritySupportCrown();

    const intercomLauncher =
      document.querySelector<HTMLElement>('.intercom-launcher');

    const intercomApp = document.querySelector<HTMLElement>('.intercom-app');

    let intercomButtonElement = null;

    intercomButtonElement = intercomApp || intercomLauncher;

    if (intercomButtonElement) {
      setIntercomButtonStyles(intercomButtonElement);
    }

    setDivStyles(div);

    if (
      intercomButtonElement &&
      !document.getElementById('priority-crown') &&
      hasPrioritySupport.value
    ) {
      intercomButtonElement.appendChild(div);
    }
  };

  const setIntercomButtonStyles = (intercomButtonElement: HTMLElement) => {
    intercomButtonElement.style.position = 'fixed';
    setZIndexToIntercom();
    intercomButtonElement.style.bottom = intercomStyles.INTERCOM_POSITION;
    intercomButtonElement.style.right = intercomStyles.INTERCOM_POSITION;
    intercomButtonElement.style.width = intercomStyles.INTERCOM_DIMENSIONS;
    intercomButtonElement.style.height = intercomStyles.INTERCOM_DIMENSIONS;
  };

  const setDivStyles = (div: HTMLElement) => {
    div.style.position = 'absolute';
    div.style.top = '-30px';
  };

  const removePriorityCrown = () => {
    const crownElement = document.getElementById('priority-crown');

    if (crownElement) {
      crownElement?.remove();
    }
  };

  const isWoocommercePluginInstalled = async () => {
    try {
      const [{ data: isWoocommercePluginInstalled }] =
        await wordpressRepo.getIsWooCommercePluginInstalled();

      return isWoocommercePluginInstalled;
    } catch (er) {
      return false;
    }
  };

  const getIntercomSettings = async () => {
    const [{ data }] = await intercomChatbotProxyRepo.getChatbotEnabledFlag();

    if (!h5gWebsitesStore.ordersList.total && isH5GFeatureEnabled.value) {
      await h5gWebsitesStore.fetchH5GOrders();
    }

    if (!websitesStore.websites.length) {
      await websitesStore.fetchWebsitesList({ perPage: WEBSITE_FETCH_SIZE });
    }

    const hasH5GOrders = !!h5gWebsitesStore.ordersList.total;

    const intercomData = {
      ...helpStore.clientIntercomSettings?.data,
      chatbotEnabled: hasH5GOrders ? false : !!data?.enabled,
      betaUser: hasH5GOrders,
      woocommercePluginInstalled: await isWoocommercePluginInstalled(),
      hasOnlyWp: !!(
        websitesStore.wordpressWebsites.length &&
        !websitesStore.builderWebsites.length
      ),
      hasCloudPlan: hasCloudHosting.value,
    };

    return camelToSnakeObj(intercomData);
  };
  const openIntercom = async ({
    isInstantlyOpened,
    conversationId,
    ticketId,
  }: {
    isInstantlyOpened: boolean;
    conversationId?: string;
    ticketId?: string;
  }) => {
    removeCookies(Cookie.NO_TICKETS_COOKIE);

    launchIntercom();
    window.intercomSettings = await getIntercomSettings();
    window.Intercom(IntercomActions.BOOT);
    window.Intercom(IntercomActions.UPDATE);
    if (isInstantlyOpened) window.Intercom(IntercomActions.SHOW);
    if (conversationId) {
      window.Intercom(IntercomActions.SHOW_CONVERSATION, conversationId);
    } else if (ticketId) {
      window.Intercom(IntercomActions.SHOW_TICKET, ticketId);
    }
    checkIsIntercomBooted();
    window.Intercom(IntercomActions.ON_HIDE, async () => {
      await helpStore.fetchHelpTickets();
      addPriorityCrownToIntercomLauncher();
    });

    window.Intercom(IntercomActions.ON_SHOW, async () => {
      await helpStore.fetchChatbotMetadata();
    });
  };
  const closeIntercom = async () => {
    await helpStore.fetchHelpTickets();
    await helpStore.fetchChatbotMetadata();
    if (!helpStore.helpTickets.length) {
      window.Intercom('shutdown');
    }
  };

  return {
    allowContact,
    hasTickets,
    hasEmailTickets,
    hasLiveChatTickets,
    isLiveChatVisible,
    isSelfServiceEnabled,
    closeIntercom,
    loadIntercom,
    openIntercom,
    checkIsIntercomBooted,
    addPriorityCrownToIntercomLauncher,
    intercomStyles,
    hasPrioritySupport,
    getIntercomSettings,
  };
};
