import { accountRepo } from '@/repositories';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';
import { uploadFile } from '@/utils/services/uploadFileService';

const initial = {
  migrationDetails: {},
  migrationType: '',
  storageUrl: '',
  storageName: '',
  isFIleImportedSuccessfully: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setMigrationDetails(state, { form, type }) {
      setForDomain(state, currentOrderId(), 'migrationDetails', form);
      setForDomain(state, currentOrderId(), 'migrationType', type);
    },
    storeFileUploadLink(state, link) {
      setForDomain(state, currentOrderId(), 'storageUrl', link);
    },
    storeFileUploadName(state, link) {
      setForDomain(state, currentOrderId(), 'storageName', link);
    },
    resetFileUploadManually(state, orderId) {
      setForDomain(state, orderId, 'storageUrl', '');
      setForDomain(state, orderId, 'storageName', '');
      setForDomain(state, orderId, 'isFileImportedSuccessfully', false);
    },
    setIsFileImportedSuccessfully(state, value) {
      setForDomain(
        state,
        currentOrderId(),
        'isFileImportedSuccessfully',
        value,
      );
    },
  },
  getters: {
    getMigrationDetails: (state) =>
      getStateVariable(state, 'migrationDetails', currentOrderId()),
    getMigrationDetailsForSubmit: (state) => {
      const details = getStateVariable(
        state,
        'migrationDetails',
        currentOrderId(),
      );
      if (!details) {
        return;
      }

      details.domain = details.domain || details.panel_link || '';

      details.config = {
        wpUsername: details.usernamewp,
        wpPassword: details.passwordwp,
      };

      if (details.usernamewp) {
        delete details.usernamewp;
      }

      if (details.passwordwp) {
        delete details.passwordwp;
      }

      return details;
    },
    getMigrationType: (state) =>
      getStateVariable(state, 'migrationType', currentOrderId()),
    getFileStorageUrl: (state) =>
      getStateVariable(state, 'storageUrl', currentOrderId()),
    getFileStorageName: (state) =>
      getStateVariable(state, 'storageName', currentOrderId()),
    getIsFileImportedSuccessfully: (state) =>
      getStateVariable(state, 'isFileImportedSuccessfully', currentOrderId()),
  },
  actions: {
    async uploadOnboardingMigrationFile({ commit }, file) {
      commit('storeFileUploadName', file?.name);

      const [{ data }, error] = await uploadFile(
        file,
        accountRepo.importWebsite,
      );

      if (data) {
        commit('storeFileUploadLink', data.storageUrl);
        commit('setIsFileImportedSuccessfully', true);
      }

      if (error) {
        throw error;
      }
    },
    clearWebsiteImport({ commit }) {
      commit('storeFileUploadLink');
      commit('storeFileUploadName');
    },
    clearProfessionalImport({ commit }) {
      commit('setMigrationDetails', {});
    },
  },
};
