<template>
  <div
    v-if="show"
    ref="modalContainer"
    class="h-modal__wrapper d-flex align-items-center justify-content-center"
    tabindex="0"
    @click.self="clickOutsideClose()"
    @keydown.esc="escClose()"
  >
    <div class="h-modal__container" @mousedown="setIsModalClicked(true)">
      <div v-if="showStepsIndicator" class="d-flex h-modal__stepper">
        <div
          v-for="(_, index) of new Array(stepsCount)"
          :key="index"
          class="h-modal__stepper-indicator"
          :class="{
            'h-modal__stepper-indicator--active': index <= currentStep,
          }"
        />
      </div>
      <div class="h-modal h-p-0" :class="{ 'h-pt-32': !noPadding }">
        <h2 v-if="modalTitle" class="h-mb-0 h-mb-8 h-px-40">
          {{ modalTitle }}
        </h2>
        <p
          v-if="modalSubtitle"
          class="h-mb-0 h-mb-16 h-px-40 d-inline-block w-100"
        >
          {{ modalSubtitle }}
        </p>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import hModalMixin from '@/utils/mixins/hModalMixin';

export default {
  name: 'HModalFancy',
  mixins: [hModalMixin],
  props: {
    title: String,
    subtitle: String,
    iconBackground: String,
    show: {
      type: Boolean,
      default: false,
    },
    noTrans: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
    },
    persistent: Boolean,
    noPadding: Boolean,
  },
  data: () => ({
    isModalClicked: false,
  }),
  provide: {
    modalOpened: true,
  },
  mounted() {
    if (this.canBeEscClosed) this.$refs.modalContainer?.focus();

    document.addEventListener(
      'mouseup',
      () => {
        setTimeout(() => {
          this.setIsModalClicked(false);
        }, 0);
      },
      true,
    );
  },
  emits: ['close', 'update:show'],
  methods: {
    setIsModalClicked(status) {
      this.isModalClicked = status;
    },
    closeModal() {
      if (!this.isModalClicked && !this.persistent) {
        this.$emit('update:show', false);
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/h-modal';
</style>
