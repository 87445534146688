<template>
  <div :class="notificationClass" class="notification">
    <HpIcon
      :icon="icon"
      v-bind="{ [iconTheme]: true }"
      class="notification__icon"
    />
    <Trans v-if="$slots.default && !noTrans">
      <slot />
    </Trans>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    icon: String,
    iconTheme: String,
    warning: Boolean,
    warningLight: Boolean,
    noTrans: Boolean,
    noMargin: Boolean,
    dangerLight: Boolean,
  },
  computed: {
    notificationClass() {
      return {
        'notification-noMargin': this.noMargin,
        'notification-warning': this.warning,
        'notification-warning-light': this.warningLight,
        'notification-danger-light': this.dangerLight,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-primary {
  background-color: var(--primary) !important;
}

.notification-secondary {
  background-color: var(--secondary) !important;
}

.notification-success {
  background-color: var(--success) !important;
}

.notification-info {
  background-color: var(--primary-hostinger) !important;
}

.notification-warning {
  background-color: var(--warning) !important;
}

.notification-warning-regular {
  background-color: var(--warning-regular) !important;
}

.notification-danger {
  background-color: var(--danger) !important;
}

.notification-light {
  background-color: var(--light) !important;
}

.notification-dark {
  background-color: var(--dark) !important;
}

.notification-black {
  background-color: var(--dark) !important;
}

.notification-gray {
  background-color: var(--gray) !important;
}

.notification-gray-light {
  background-color: var(--gray-light) !important;
}

.notification-header-bg {
  background-color: var(--header-bg) !important;
}

.notification-danger-light {
  background-color: var(--danger-light) !important;
}

.notification-success-dark {
  background-color: var(--success-dark) !important;
}

.notification-success-light {
  background-color: var(--success-light) !important;
}

.notification-warning-light {
  background-color: var(--warning-light) !important;
}

.notification-warning-dark {
  background-color: var(--warning-dark) !important;
}
.notification {
  display: flex;
  padding: 8px 16px;
  margin-top: 16px;
  border-radius: 4px;

  &__icon {
    margin-right: 10px;
  }

  &-noMargin {
    margin: unset;
  }
}
</style>
