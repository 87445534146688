import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { useCookies, useFeatureFlag } from '@/composables';
import { profileRepo, cvcRepo } from '@/repositories';
import type { CVCRefreshRequest } from '@/types';
import { Cookie, FeatureFlag } from '@/types';

export const useCVCRefresh = () => {
  const { isFeatureEnabled: isCvcFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.CVC_REFRESH,
  );

  const { cookie: refreshedIpList, setCookie } = useCookies(
    Cookie.REFRESHED_CVC_IPS,
  );

  const { getters } = useStore();

  const isCVCRefreshNeeded = ref(false);

  const defaultPaymentMethod = computed(
    () => getters['paymentMethods/getDefaultPaymentMethod'],
  );

  const cvcIframeData = reactive({
    iframeUrl: '',
    isLoading: false,
    isLoaded: false,
    isError: false,
  });

  const isCvcRefreshed = ref(false);

  const isCvcRefreshVisible = computed(
    () =>
      cvcIframeData.isLoaded && !cvcIframeData.isError && !isCvcRefreshed.value,
  );

  const lastLoginIp = ref<string>('');

  const getClientLogs = async () => {
    const [{ data }, err] = await profileRepo.getProfileActivity();

    if (err) return;

    const loginIps = data.map(({ ipAddress }) => ipAddress);
    const firstLoginIp = loginIps[0];
    lastLoginIp.value = [...loginIps].pop() as string;

    const isIpAlreadyRefreshed = JSON.parse(
      refreshedIpList.value || '[]',
    ).includes(lastLoginIp.value);

    if (firstLoginIp !== lastLoginIp.value && !isIpAlreadyRefreshed) {
      isCVCRefreshNeeded.value = true;
    }
  };

  const getCvcIframe = async () => {
    const { customerCustomId, id } = defaultPaymentMethod.value;

    const payload: CVCRefreshRequest = {
      customerCustomId,
      validPaymentMethodId: id,
      details: {
        target: location.origin,
      },
    };

    cvcIframeData.isLoading = true;

    const [{ data }, err] = await cvcRepo.refreshCVC(payload);

    cvcIframeData.isLoading = false;

    if (err) {
      cvcIframeData.isError = true;

      return;
    }

    cvcIframeData.iframeUrl = data.iframeUrl;
    cvcIframeData.isLoaded = true;
  };

  const addIpToWhitelist = () => {
    if (!refreshedIpList.value) {
      return setCookie(JSON.stringify([lastLoginIp.value]));
    }

    setCookie(
      JSON.stringify([...JSON.parse(refreshedIpList.value), lastLoginIp.value]),
    );
  };

  watch(
    () => defaultPaymentMethod.value,
    (method) => {
      const isCardPaymentMethod = method?.paymentMethod === 'card';
      const isNotRazorpay = method?.merchantAccount !== 'razorpay';

      const isCardPaymentMethodAndNotRazorpay =
        isCardPaymentMethod && isNotRazorpay;

      if (!isCardPaymentMethodAndNotRazorpay || !isCvcFeatureEnabled.value) {
        return;
      }

      getClientLogs();
    },
    { immediate: true, deep: true },
  );

  return {
    getCvcIframe,
    isCVCRefreshNeeded,
    isCvcRefreshVisible,
    cvcIframeData,
    isCvcRefreshed,
    addIpToWhitelist,
  };
};
