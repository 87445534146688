// @ts-nocheck
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import type { App } from 'vue';

import { routerWithRoutes } from '@/router/routerWithRoutes';
import store from '@/store/index';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const loadPlugins = (app: App) => {
  app.use(store);
  app.use(pinia);
  app.use(routerWithRoutes);
  app.use(HighchartsVue, { Highcharts, tagName: 'Highcharts' });
};
