import type { RouteRecordRaw } from 'vue-router';
import { useStore } from 'vuex';

import { useExperiment, useGlobals, useResourceMapper } from '@/composables';
import onboardingGuard from '@/guards/onboardingGuard';
import { useProfileStore } from '@/stores';
import { Experiment, HRESOURCE_ITEM_TYPE, Onboarding, Route } from '@/types';

const baseMeta = {
  showSubheader: false,
  fullscreen: true,
  header: false,
  scrollToTop: false,
  isOnboarding: true,
};

const getIsProUser = () => {
  const { isExperimentActive: isWpPresetsExperimentActive } = useExperiment(
    Experiment.ID.WEBPRO_WP_PRESETS,
  );
  const { account } = useProfileStore();

  return isWpPresetsExperimentActive.value && account?.isPro;
};

export default [
  {
    path: '/:order_id/:username?/:type?/:website_type?/lets-start',
    meta: {
      title: "Let's Go",
      ...baseMeta,
    },
    component: () => import('@/views/Onboarding.vue'),
    beforeEnter: onboardingGuard,
    children: [
      {
        path: '',
        name: Route.Onboarding.START,
        meta: {
          step: 10,
          ...baseMeta,
          headerBackButton: false,
        },
        component: () => import('@/views/Onboarding/OnboardingLetsStart.vue'),
      },
      {
        path: 'survey',
        name: Route.Onboarding.SURVEY,
        meta: {
          title: 'What type of website are you creating?',
          step: 20,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSurveyWrapper.vue'),
      },
      {
        path: 'Website-type-survey',
        name: Route.Onboarding.WEBSITE_TYPE_SURVEY,
        meta: {
          title: 'What type of website are you creating?',
          step: 19,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingWebsiteTypeSurvey.vue'),
      },
      {
        path: 'build-or-migrate',
        name: Route.Onboarding.BUILD_OR_MIGRATE,
        meta: {
          title: 'Migrate or Create a New Website',
          step: 21,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingBuildOrMigrate.vue'),
      },
      {
        path: 'select-tool',
        name: Route.Onboarding.TOOL,
        meta: {
          title: 'Select a Tool',
          step: 22,
          ...baseMeta,
        },
        beforeEnter: async (to, from, next) => {
          const { getters } = useStore();
          const { getResourceBySubscriptionId } = useResourceMapper();

          const resource = getResourceBySubscriptionId(
            from.params.order_id as string,
          );

          const hasWebsites = resource?.items.some(
            (item) => item.type === HRESOURCE_ITEM_TYPE.WEBSITE,
          );

          if (
            !hasWebsites &&
            getters.getSelectedPlatformByRedirect ===
              Onboarding.Platform.WORDPRESS
          ) {
            const { amplitudeV2 } = useGlobals();

            amplitudeV2(Onboarding.Event.PLATFORM_SELECTED, {
              platformSelected: Onboarding.Platform.WORDPRESS,
              preselectedAutomatically: true,
            });

            return next({
              name: Route.Onboarding.SELECT_WORDPRESS,
              params: to.params,
              query: to.query,
            });
          }

          return next();
        },
        component: () =>
          import('@/views/Onboarding/OnboardingToolComparisonWrapper.vue'),
      },
      {
        path: 'other-platforms',
        name: Route.Onboarding.OTHER_PLATFORMS,
        meta: {
          title: 'Other platforms',
          step: 23,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingOtherPlatforms.vue'),
      },
      {
        path: 'select-wordpress',
        name: Route.Onboarding.SELECT_WORDPRESS,
        meta: {
          title: 'WordPress',
          step: 24,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordpress.vue'),
      },

      {
        path: 'migrate-website',
        name: Route.Onboarding.MIGRATION,
        meta: {
          title: 'Migrate Website',
          step: 25,
          ...baseMeta,
        },
        component: () => import('@/views/Onboarding/OnboardingMigrate.vue'),
      },
      {
        path: 'select-wordpress-preset',
        name: Route.Onboarding.SELECT_WORDPRESS_PRESETS,
        meta: {
          title: 'Select WordPress preset',
          step: 30,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordpressPreset.vue'),
      },
      {
        path: 'select-wordpress-plugins',
        name: 'onboarding_select_wordpress_plugins',
        meta: {
          title: 'Select WordPress plugins',
          step: 36,
          ...baseMeta,
        },
        component: () =>
          getIsProUser()
            ? import(
                '@/views/Onboarding/WordPressPlugins/OnboardingSelectWordpressPluginsV2.vue'
              )
            : import('@/views/Onboarding/OnboardingSelectWordpressPlugins.vue'),
      },

      {
        path: '/:order_id/:username?/:type?/:website_type?/theme-card-preview',
        name: Route.Onboarding.THEME_CARD_PREVIEW,
        meta: {
          ...baseMeta,
          title: 'Preview Theme',
          step: 37,
          sideMenuExists: false,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingThemeCardPreview.vue'),
      },

      {
        path: 'choose-template',
        name: Route.Onboarding.WP_TEMPLATES,
        meta: {
          title: 'Select Website Template',
          step: 37,
          ...baseMeta,
        },
        component: () =>
          getIsProUser()
            ? import('@/views/Onboarding/OnboardingSelectWPThemeV2.vue')
            : import('@/views/Onboarding/OnboardingSelectWPTemplate.vue'),
      },
      {
        path: 'select-theme-color',
        name: Route.Onboarding.SELECT_WORDPRESS_THEME_COLOR,
        meta: {
          title: 'Select Your Preferred Style',
          step: 38,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordPressStyle.vue'),
      },
      {
        path: 'select-theme-font',
        name: Route.Onboarding.SELECT_WORDPRESS_THEME_FONT,
        meta: {
          title: 'Personalize Your Text Style',
          step: 38,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordPressFont.vue'),
      },
      {
        path: 'select-wordpress-ai',
        name: Route.Onboarding.SELECT_WORDPRESS_AI_CONTENT,
        meta: {
          title: 'Automate Your Text Creation with AI',
          step: 39,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingSelectWordPressAIContent.vue'),
      },
      {
        path: 'choose-app-name',
        name: Route.Onboarding.CHOOSE_APP_NAME,
        meta: {
          title: 'Choose Application Name',
          step: 40,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingChooseAppName.vue'),
      },
      {
        path: 'existing-domain',
        name: Route.Onboarding.EXISTING_DOMAIN,
        meta: {
          title: 'Use existing domain',
          step: 41,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/Domain/OnboardingExistingDomain.vue'),
      },
      {
        path: 'buy-new-domain',
        name: Route.Onboarding.BUY_NEW_DOMAIN,
        meta: {
          title: 'Buy new domain',
          step: 42,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/Domain/OnboardingBuyNewDomainV2.vue'),
      },
      {
        path: 'existing-domain-summary',
        name: Route.Onboarding.EXISTING_DOMAIN_SUMMARY,
        meta: {
          title: 'Choose Application Name',
          step: 43,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingExistingDomainSummary.vue'),
      },
      {
        path: 'create-cpanel-password',
        name: Route.Onboarding.CPANEL_PASSWORD,
        meta: {
          title: 'Create Password',
          step: 44,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingCpanelPassword.vue'),
      },
      {
        path: 'overview',
        name: Route.Onboarding.OVERVIEW,
        meta: {
          title: 'Overview',
          step: 50,
          ...baseMeta,
        },
        component: () => import('@/views/Onboarding/OnboardingOverview.vue'),
      },
      {
        path: 'being-built',
        name: Route.Onboarding.BEING_BUILT,
        meta: {
          title: 'Is being built',
          headerBackButton: false,
          step: 60,
          ...baseMeta,
        },
        component: () => import('@/views/Onboarding/OnboardingBeingBuilt.vue'),
      },
      {
        path: 'completed',
        name: Route.Onboarding.COMPLETED,
        meta: {
          title: 'Website is Ready',
          headerBackButton: false,
          step: 62,
          ...baseMeta,
        },
        component: () => import('@/views/Onboarding/OnboardingCompleted.vue'),
      },
      {
        path: 'being-transferred',
        name: Route.Onboarding.BEING_TRANSFERRED,
        meta: {
          title: 'Is being transferred',
          headerBackButton: false,
          step: 63,
          ...baseMeta,
        },
        component: () =>
          import('@/views/Onboarding/OnboardingBeingTransferred.vue'),
      },
      {
        path: 'domain-setup/:domain_order_id?',
        name: Route.Onboarding.DOMAIN_SETUP,
        meta: {
          title: 'Finish Domain Registration',
          headerBackButton: false,
          step: 70,
          ...baseMeta,
        },
        component: () => import('@/views/Onboarding/OnboardingDomainSetup.vue'),
      },
      {
        path: 'cpanel-getting-ready',
        name: Route.Onboarding.CPANEL_GETTING_READY,
        meta: {
          title: 'Almost There! Your Hosting Is Getting Ready',
        },
        component: () =>
          import('@/views/Onboarding/OnboardingCpanelGettingReady.vue'),
      },
    ],
  },
  {
    path: '/:order_id/:username?/:type?/:website_type?/preview/:slug',
    name: Route.Onboarding.PREVIEW_TEMPLATE,
    meta: {
      ...baseMeta,
      title: 'Preview Template',
      sideMenuExists: false,
      header: 'template-preview',
    },
    component: () => import('@/views/Onboarding/OnboardingPreview.vue'),
  },
] as RouteRecordRaw[];
