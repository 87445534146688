import { toUnicode } from '@/utils/helpers';

export default {
  getHostingAccountDetailsFormat: (
    { server, ip, reseller, orderVhosts, username },
    requestDomain = '',
  ) => {
    const isMainVHost =
      orderVhosts.find((item) => item.vhost === requestDomain)?.vhostType ===
      'main';
    const ftpUsername = isMainVHost
      ? username
      : `${username}.${toUnicode(requestDomain)}`;

    return {
      websiteDetails: {
        'Access your website at': `http://${toUnicode(requestDomain)}`,
        'Access your website with www': `http://www.${toUnicode(
          requestDomain,
        )}`,
        'Server name': `${server.hostname}, ${server.title}`,
        'Website IP address': ip,
      },
      eMailDetails: {
        'Alternative MX record': `mx2.${reseller}`,
        'IMAP host': `imap.${reseller}`,
        'IMAP port (secure)': '993',
        'Login password': "User's email password",
        'Login username': "User's email address",
        'MX record': `mx1.${reseller}`,
        'POP3 host': `pop.${reseller}`,
        'POP3 port (secure)': '995',
        'SMTP host': `smtp.${reseller}`,
        'SMTP port (secure)': '587',
        'Webmail address': `https://webmail.${reseller}`,
      },
      fileUploadDetails: {
        'FTP IP': `ftp://${ip}`,
        'FTP hostname': `ftp://${toUnicode(requestDomain)}`,
        'FTP port': '21',
        'FTP username': ftpUsername,
        'Folder to upload files': 'public_html',
      },
    };
  },
};
