import { defineAsyncComponent } from 'vue';

const fieldElementDynamicImports = {
  'field-element-button': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementButton.vue'),
  ),
  'field-element-checkbox': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementCheckbox.vue'),
  ),
  'field-element-date': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementDate.vue'),
  ),
  'field-element-group-text': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementGroupText.vue'),
  ),
  'field-element-input': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementInput.vue'),
  ),
  'field-element-input-group': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementInputGroup.vue'),
  ),
  'field-element-password': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementPassword.vue'),
  ),
  'field-element-radio': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementRadio.vue'),
  ),
  'field-element-select': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementSelect.vue'),
  ),
  'field-element-slider': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementSlider.vue'),
  ),
  'field-element-submit': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementSubmit.vue'),
  ),
  'field-element-text': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementText.vue'),
  ),
  'field-element-textarea': defineAsyncComponent(() =>
    import('@/components/Form/FieldElements/FieldElementTextarea.vue'),
  ),
};

const fieldDynamicImports = {
  'field-checkbox': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldCheckbox.vue'),
  ),
  'field-ifelse': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldIfelse.vue'),
  ),
  'field-input': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldInput.vue'),
  ),
  'field-input-group': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldInputGroup.vue'),
  ),
  'field-multiple-input': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldMultipleInput.vue'),
  ),
  'field-password': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldPassword.vue'),
  ),
  'field-radio': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldRadio.vue'),
  ),
  'field-repeat': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldRepeat.vue'),
  ),
  'field-select': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldSelect.vue'),
  ),
  'field-slider': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldSlider.vue'),
  ),
  'field-submit': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldSubmit.vue'),
  ),
  'field-switch': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldSwitch.vue'),
  ),
  'field-text': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldText.vue'),
  ),
  'field-textarea': defineAsyncComponent(() =>
    import('@/components/Form/Fields/FieldTextarea.vue'),
  ),
};

export { fieldDynamicImports, fieldElementDynamicImports };
