import { whFtpRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  ftpAccountsInfo: {},
  ftpAccounts: [],
  ftpAccountsPrepend: '',
  lockedActions: [],
  availableAccounts: '999999999',
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingFilesFtpAccounts(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'ftpAccounts', data);
    },
    removeHostingFilesFtpAccounts(state, removeUsername) {
      const currentStateVariable = getStateVariable(state, 'ftpAccounts');
      setForDomain(
        state,
        null,
        'ftpAccounts',
        currentStateVariable.filter(
          ({ username }) => username !== removeUsername,
        ),
      );
    },
    addHostingFilesFtpAccounts(state, data) {
      const newData = Object.assign({}, data);

      const currentStateVariable = getStateVariable(state, 'ftpAccounts');

      newData.username = newData.user;

      currentStateVariable.push(newData);

      setForDomain(state, null, 'ftpAccounts', currentStateVariable);
    },
    setHostingFilesFtpAccountsInfo(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'ftpAccountsInfo', data);
    },
    setHostingFilesFtpAccountsPrepend(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'ftpAccountsPrepend', data);
    },
    setHostingFilesFtpAccountsLockedActions(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'lockedActions', data);
    },
    setHostingFilesFtpAccountsAvailableAccounts(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'availableAccounts', data);
    },
    setHostingFilesFtpAccountsLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingFilesFtpAccounts: (state) =>
      getStateVariable(state, 'ftpAccounts'),
    getHostingFilesFtpAccountsInfo: (state) =>
      getStateVariable(state, 'ftpAccountsInfo'),
    getHostingFilesFtpAccountsPrepend: (state) =>
      getStateVariable(state, 'ftpAccountsPrepend'),
    getHostingFilesFtpAccountsLockedActions: (state) =>
      getStateVariable(state, 'lockedActions'),
    getHostingFilesFtpAccountsAvailableAccounts: (state) =>
      getStateVariable(state, 'availableAccounts'),
    getHostingFilesFtpAccountsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingFilesFtpAccountsIndex({ commit }) {
      const [{ data, requestDomain }] = await whFtpRepo.getFtpDetails(
        getCurrentOrderDetails(),
      );

      if (data) {
        commit('setHostingFilesFtpAccountsLoaded', {
          data: true,
          requestDomain,
        });
        commit('setHostingFilesFtpAccounts', {
          data: data.ftpAccounts,
          requestDomain,
        });
        commit('setHostingFilesFtpAccountsInfo', {
          data: {
            ...data.accessDetails,
            ip: `ftp://${data.accessDetails.ip}`,
            hostname: `ftp://${data.accessDetails.hostname}`,
          },
          requestDomain,
        });
        commit('setHostingFilesFtpAccountsPrepend', {
          data: data.homeDirectoryPrefix,
          requestDomain,
        });
        commit('setHostingFilesFtpAccountsAvailableAccounts', {
          data: data.ftpAccountsLimit,
          requestDomain,
        });
        commit('setHostingFilesFtpAccountsLockedActions', {
          data: data.lockedActions,
          requestDomain,
        });
      }
    },
    async hostingFilesFtpAccountsDestroy(context, username) {
      context.commit('removeHostingFilesFtpAccounts', username);

      const [data, err] = await whFtpRepo.deleteFtpAccount(
        getCurrentOrderDetails(),
        encodeURIComponent(username),
      );
      if (!err) context.dispatch('hostingFilesFtpAccountsIndex');

      return [data, err];
    },
    async hostingFilesFtpAccountsStore(context, data) {
      const [response, err] = await whFtpRepo.createFtpAccount(
        getCurrentOrderDetails(),
        {
          user: data.user,
          password: data.password,
          uploadsDirectory: data.directory,
        },
      );
      if (!err) await context.dispatch('hostingFilesFtpAccountsIndex');

      return [response, err];
    },
    async hostingFilesFtpAccountChangePassword(context, data) {
      const [response, err] = await whFtpRepo.changeFtpAccountPassword(
        getCurrentOrderDetails(),
        data.user,
        {
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        },
      );

      return [response, err];
    },
  },
};
