<script setup lang="ts">
import { useModal } from '@/composables';
import type { ModalEmits } from '@/types';

interface IProps {
  data: {
    onClosed?: () => void;
  };
}
interface IEmits extends ModalEmits {}

const props = defineProps<IProps>();
const emit = defineEmits<IEmits>();
const { close } = useModal(emit);
const onBoardingTitle = 'Selamat Datang di Panel Terbaru Niagahoster!';
const EMBED_VIDEO_URL = 'https://www.youtube.com/embed/7M4KtvvilVU?autoplay=1;';

const closeModal = () => {
  close();
  props.data.onClosed?.();
};
</script>

<template>
  <div class="niagahoster-onboarding-video">
    <h2 class="niagahoster-onboarding-video__title">{{ onBoardingTitle }}</h2>
    <p class="niagahoster-onboarding-video__description">
      Untuk memudahkan proses adaptasi Anda, silahkan lihat video pengenalan
      panel baru di bawah ini. Anda dapat melihat ulang video ini pada channel
      <a href="https://www.youtube.com/@NiagahosterIndonesia" target="_blank">
        YouTube Niagahoster.
      </a>
    </p>

    <div class="h-py-40">
      <iframe
        class="niagahoster-onboarding-video__iframe"
        :src="EMBED_VIDEO_URL"
        :title="onBoardingTitle"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>

    <div class="niagahoster-onboarding-video__footer">
      <HButton variant="text" @click="closeModal">Lewati</HButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.niagahoster-onboarding-video {
  text-align: center;

  &__iframe {
    width: 100%;
    max-width: 576px;
    height: 100vw;
    max-height: 324px;
    border: 0;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}
</style>
