import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type {
  IMalwareScannerFileResponse,
  IMalwareScannerMetrics,
  MalwareScannerTimeline,
} from '@/types';
import { HVps } from '@/types';

export const useVpsMalwareScannerStore = defineStore(
  'vpsMalwareScanner',
  () => {
    const FAILED_INSTALLATION_CODE = 2034;

    const serverStore = useVpsServerStore();

    const scannerMetrics = ref<IMalwareScannerMetrics>();
    const scannerTimeline = ref<MalwareScannerTimeline>();
    const malwareFiles = ref<IMalwareScannerFileResponse>();
    const isInstallationFailed = ref(false);

    const setScannerMetrics = (metrics: IMalwareScannerMetrics | undefined) => {
      scannerMetrics.value = metrics;
    };

    const setFailedInstallation = (isFailed: boolean) => {
      isInstallationFailed.value = isFailed;
    };

    const fetchMalwareScannerMetrics = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerMetrics(
        serverId,
      );

      // @ts-ignore
      if (error?.error?.code === FAILED_INSTALLATION_CODE) {
        setFailedInstallation(true);

        return;
      }

      if (error) return;

      setScannerMetrics(data);
    };

    const installMalwareScanner = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.installMalwareScanner(serverId);

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setServerState(serverId, HVps.ServerState.INSTALLING);
      serverStore.setUpdatingServer({ serverId });

      serverStore.fetchServerById({ serverId });
    };

    const setMalwareAutoProtection = async (isEnabled: boolean) => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.activateMalwareAutoProtection(
        serverId,
        {
          enabled: isEnabled,
          malicious: 'quarantine',
          compromised: 'clean',
        },
      );

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setServerState(serverId, HVps.ServerState.INSTALLING);
      serverStore.setUpdatingServer({ serverId });

      serverStore.fetchServerById({ serverId });
    };

    const uninstallMalwareScanner = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.uninstallMalwareScanner(serverId);

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setUpdatingServer({ serverId });
      serverStore.fetchServerById({ serverId });
    };

    const fetchMalwareScannerTimeline = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerTimeline(
        serverId,
      );

      if (error) {
        return;
      }

      scannerTimeline.value = data;
    };

    const fetchMalwareFiles = async (params: any) => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerFiles(
        serverId,
        params,
      );

      if (error) {
        return;
      }

      malwareFiles.value = data;
    };

    const syncInstallation = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [_, error] = await hVpsRepo.syncMalwareScannerInstallation(
        serverId,
      );

      if (error) {
        throw new Error('Failed to sync installation', {
          cause: error,
        });
      }

      serverStore.setServerLock(serverId, true);
      serverStore.setUpdatingServer({ serverId });
    };

    return {
      scannerMetrics,
      scannerTimeline,
      malwareFiles,
      setScannerMetrics,
      fetchMalwareScannerMetrics,
      installMalwareScanner,
      uninstallMalwareScanner,
      setMalwareAutoProtection,
      fetchMalwareScannerTimeline,
      fetchMalwareFiles,
      isInstallationFailed,
      syncInstallation,
      setFailedInstallation,
    };
  },
);
