import type { Module } from 'vuex';

import { router } from '@/router';

interface State {
  totalSteps: number;
  selectedDomain: string;
  emailAccount: string;
  password: string;
  lockedDomain: string;
  /**
   * The list of domains that were selected for automatic domain connection
   */
  domainsWithAutomaticDomainConnection: string[];
}

export default {
  namespaced: true,
  state: {
    totalSteps: 4,
    selectedDomain: '',
    emailAccount: '',
    password: '',
    lockedDomain: '',
    domainsWithAutomaticDomainConnection: [],
  },
  mutations: {
    SET_INITIAL_STATE(state) {
      state.totalSteps = 4;
      state.selectedDomain = '';
      state.emailAccount = '';
      state.password = '';
      state.lockedDomain = '';
      state.domainsWithAutomaticDomainConnection = [];
    },
    SET_STATE<K extends keyof State>(
      state: State,
      { param, data }: { param: K; data: State[K] },
    ) {
      state[param] = data;
    },
    ADD_DOMAIN_WITH_AUTOMATIC_DOMAIN_CONNECTION(state, domain: string) {
      state.domainsWithAutomaticDomainConnection.push(domain);
    },
  },
  getters: {
    isStepActive: () => (step: number) =>
      String(router.currentRoute.value.meta.step).slice(0, 1) === String(step),
    isStepCompleted: () => (step: number) =>
      String(router.currentRoute.value.meta.step).slice(0, 1) > String(step),
    isAutomaticDomainConnectionSelected: (state) =>
      state.domainsWithAutomaticDomainConnection.includes(state.selectedDomain),
  },
} as Module<State, any>;
