import type { RouteRecordRaw } from 'vue-router';

import domainCheckerGuard from '@/guards/domains/domainCheckerGuard';
import domainGuard from '@/guards/domains/domainGuard';
import domainMoveRegisterGuard from '@/guards/domains/domainMoveRegisterGuard';
import domainOverviewGuard from '@/guards/domains/domainOverviewGuard';
import { domainResourceGuard } from '@/guards/domains/domainResourceGuard';
import domainResourceRegisterGuard from '@/guards/domains/domainResourceRegisterGuard';
import externalDomainGuard from '@/guards/domains/externalDomainGuard';
import { transferListGuard } from '@/guards/domains/transferListGuard';
import transferSetupGuard from '@/guards/domains/transferSetupGuard';
import { useResourcesStore } from '@/stores';
import { Route, RoutePath } from '@/types';

const mainMenu = {
  sideMenuExists: true,
  sideMenuFixed: false,
  sideMenuComponent: {
    menu: 'domains-side',
  },
  feedback: true,
  wrapperName: 'domain',
};

const managementMenu = {
  sideMenuExists: true,
  sideMenuFixed: false,
  sideMenuComponent: {
    component: 'DomainSelect',
    menu: 'domain-management',
  },
  sideMenuBack: {
    text: 'Back to domains',
    route: 'my-domains',
  },
  feedback: true,
  wrapperName: 'domain',
};

export default [
  {
    path: '/register-domain-resource/:domain',
    name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
    meta: {
      title: 'Domains',
      menu: ['horizontal'],
      showSubheader: false,
      fullscreen: true,
      header: false,
      isPageWrapperV2: true,
    },
    beforeEnter: (to, from, next) =>
      domainResourceRegisterGuard(to, from, next),
    component: () =>
      import('@/views/HDomains/JWT/RegisterDomainResourceView.vue'),
  },
  {
    path: '/domain-move-register/:domain',
    name: Route.Domain.DOMAIN_MOVE_REGISTER,
    meta: {
      title: 'Domains',
      menu: ['horizontal'],
      showSubheader: false,
      fullscreen: true,
    },
    beforeEnter: (to, from, next) => domainMoveRegisterGuard(to, from, next),
    component: () => import('@/views/HDomains/JWT/DomainMoveRegister.vue'),
  },
  {
    path: '/transfer/:domain/:subscriptionId?',
    name: Route.Domain.TRANSFER,
    meta: {
      title: 'Domains',
      menu: ['horizontal'],
      showSubheader: false,
      header: false,
      isPageWrapperV2: true,
    },
    beforeEnter: (to, from, next) => transferSetupGuard(to, from, next),
    component: () => import('@/views/HDomains/RegisterTransfer.vue'),
  },
  {
    path: '/domains',
    meta: {
      title: 'Domains',
    },
    component: () => import('@/views/HDomains/Domains.vue'),
    children: [
      {
        path: '',
        name: Route.Domain.MY_DOMAINS,
        meta: {
          title: 'Domain portfolio',
          menu: [{ name: 'domains-side', order: 1 }],
          icon: 'ic-world',
          ...mainMenu,
        },
        component: () => import('@/views/HDomains/MyDomains.vue'),
      },
      {
        path: 'transfer-domain',
        name: Route.Domain.TRANSFER_DOMAIN,
        beforeEnter: (to, from, next) => domainResourceGuard(to, from, next),
        meta: {
          title: 'Transfer Domain',
          breadcrumbs: ['my-domains'],
          icon: 'transfer-domain',
          ...mainMenu,
        },

        component: () => import('@/views/HDomains/DomainTransfer.vue'),
      },
      {
        path: 'transfers',
        name: Route.Domain.TRANSFERS,
        beforeEnter: transferListGuard,
        meta: {
          title: 'Transfers',
          menu: [{ name: 'domains-side', order: 3 }],
          breadcrumbs: ['my-domains'],
          icon: 'transfer-domain',
          ...mainMenu,
        },

        component: () => import('@/views/HDomains/Transfers.vue'),
      },
      {
        path: RoutePath.Domain.TRANSFERS,
        beforeEnter: (to, from, next) => domainResourceGuard(to, from, next),
        meta: {
          title: 'Transfer',
        },
        component: () =>
          import(
            '@/views/HDomains/DomainManagement/TransferManagementWrapper.vue'
          ),
        children: [
          {
            path: ':domain/:subscriptionId',
            name: Route.Domain.TRANSFER_DOMAIN_MANAGEMENT,
            beforeEnter: (to, from, next) => domainGuard(to, from, next),
            meta: {
              breadcrumbsTitle: { title: '{domain}', translate: false },
              breadcrumbs: [Route.Domain.MY_DOMAINS, Route.Domain.TRANSFERS],
              title: 'Transfer Overview',
              ...managementMenu,
              sideMenuComponent: {
                component: 'DomainTransferSelect',
                menu: 'TransferSidebar',
              },
              sideMenuBack: {
                text: 'Back to transfers',
                route: Route.Domain.TRANSFERS,
              },
              menu: ['TransferSidebar'],
              icon: 'overview',
            },
            component: () =>
              import(
                '@/views/HDomains/DomainManagement/TransferDomainOverview.vue'
              ),
          },
        ],
      },
      {
        path: 'domain-checker',
        name: Route.Domain.DOMAIN_CHECKER,
        beforeEnter: (to, from, next) => domainCheckerGuard(to, from, next),
        meta: {
          title: 'Get a New Domain',
          menu: [{ name: 'domains-side', order: 2 }],
          icon: 'new-domain',
          hideSubheaderMobile: true,
          mobileFullscreen: true,
          showSubheader: false,
          ...mainMenu,
        },
        component: () => import('@/views/HDomains/DomainChecker.vue'),
      },
    ],
  },
  {
    path: RoutePath.Domain.DOMAIN,
    meta: {
      title: 'Domain',
    },
    beforeEnter: async (to, from, next) => await domainGuard(to, from, next),
    component: () =>
      import('@/views/HDomains/DomainManagement/DomainsManagement.vue'),
    children: [
      {
        path: 'domain-overview/:subscriptionId?',
        name: Route.Domain.DOMAIN_MANAGEMENT,
        beforeEnter: (to, from, next) => domainOverviewGuard(to, from, next),
        meta: {
          breadcrumbsTitle: { title: '{domain}', translate: false },
          breadcrumbs: ['my-domains'],
          title: 'Domain Overview',
          menu: ['domain-management'],
          icon: 'squares-gray',
          ...managementMenu,
        },
        component: () =>
          import('@/views/HDomains/DomainManagement/DomainOverview.vue'),
      },
      {
        path: 'dns',
        name: Route.Domain.DOMAIN_DNS,
        meta: {
          title: 'DNS / Nameservers',
          breadcrumbs: ['my-domains', 'domain-management'],
          menu: ['domain-management'],
          icon: 'domain-dns',
          ...managementMenu,
        },
        component: () =>
          import('@/views/HDomains/DomainManagement/DomainDns.vue'),
      },
      {
        path: 'settings',
        name: Route.Domain.DOMAIN_SETTINGS,
        meta: {
          title: 'Domain Ownership',
          breadcrumbs: ['my-domains', 'domain-management'],
          menu: ['domain-management'],
          icon: 'ic-info-contained',
          ...managementMenu,
        },
        component: () =>
          import(
            '@/views/HDomains/DomainManagement/WhoIsProfile/WhoIsProfileWrapper.vue'
          ),
      },
      {
        path: 'dns/snapshot/:snapshotId/restore',
        name: Route.Domain.DOMAIN_DNS_HISTORY_RESTORE,
        meta: {
          title: 'Restore DNS Snapshot',
          breadcrumbs: false,
        },
        component: () =>
          import(
            '@/views/HDomains/DomainManagement/DnsHistory/DnsSnapshotRestore.vue'
          ),
      },
    ],
  },
  {
    path: '/external-domain/:domain/dns',
    name: Route.Domain.EXTERNAL_DOMAIN_DNS,
    beforeEnter: (to, from, next) => externalDomainGuard(to, next),
    meta: {
      subHeaderTitle: { title: '{domain}', translate: false },
      title: 'DNS / Nameservers',
      breadcrumbs: ['my-domains'],
      menu: ['external-domain'],
      icon: 'domain-dns',
      ...managementMenu,
      sideMenuComponent: {
        component: 'ExternalDomainSelect',
        menu: 'external-domain',
      },
    },
    component: () =>
      import('@/views/HDomains/DomainManagement/ExternalDomainManagement.vue'),
  },
  {
    path: '/add-domain/:domain',
    name: Route.Domain.ADD_DOMAIN,
    meta: {
      isPageWrapperV2: true,
      title: 'Add domain',
    },
    redirect: { name: Route.Domain.ADD_DOMAIN_SELECT },
    component: () => import('@/views/Domains/AddDomain.vue'),
    beforeEnter: async (to, from, next) => {
      const resourcesStore = useResourcesStore();

      if (!resourcesStore.isLoaded) {
        await resourcesStore.fetchResources();
      }

      next();
    },
    children: [
      {
        path: 'select',
        name: Route.Domain.ADD_DOMAIN_SELECT,
        component: () => import('@/views/Domains/AddDomainSelect.vue'),
      },
      {
        path: 'register',
        name: Route.Domain.ADD_DOMAIN_REGISTER,
        component: () => import('@/views/Domains/AddDomainRegister.vue'),
      },
      {
        path: 'complete',
        name: Route.Domain.ADD_DOMAIN_COMPLETE,
        component: () => import('@/views/Domains/AddDomainComplete.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
