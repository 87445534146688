import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import {
  useExperiment,
  useOnboardingSteps,
  useOnboardingV2,
  useResourceMapper,
} from '@/composables';
import { wordpressRepo, accountRepo } from '@/repositories';
import onboardingRepo from '@/repositories/onboardingRepo';
import { useHostingerProStore, useProfileStore } from '@/stores';
import {
  type ArrayElementType,
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  type IWordPressTemplate,
  type OnboardingCompletionData,
} from '@/types';
import { Experiment, STORE_PERSISTENT_KEYS } from '@/types';
import type {
  OnboardingState,
  OnboardingStep,
  WordPressWebsiteTypeAnswer,
} from '@/types/models/onboardingV2Models';
import {
  ONBOARDING_STEPS,
  H5G_ONBOARDING_STEPS_ARRAY,
  ONBOARDING_STEPS_ARRAY,
  ONBOARDING_STEPS_WITHOUT_SURVEY,
  SHORTER_WEBPRO_ONBOARDING_STEPS_ARRAY,
} from '@/types/models/onboardingV2Models';
import { errorLogger } from '@/utils/services/errorLogging';

export const useOnboardingStore = defineStore(
  'onboardingStore',
  () => {
    const state = ref({} as OnboardingState);
    const isEligibleForSurvey = ref(true);
    const onboardingCompletionStatus = ref<OnboardingCompletionData | null>(
      null,
    );
    const wordPressTemplates = ref<
      Record<WordPressWebsiteTypeAnswer, IWordPressTemplate[]> | undefined
    >(undefined);

    const wordPressWebsiteTypes = ref<WordPressWebsiteTypeAnswer[]>([]);

    const isOnboardingOngoing = ref(false);
    const isAddon = ref(false);
    const isLeaveOnboardingEventShouldBeSent = ref<boolean>(true);
    const shouldContinueOnboarding = ref(false);
    const subscriptionsWithOnboardingProgress = ref<string[]>([]);

    const { isExperimentActive: isShortOnboardingExperimentActive } =
      useExperiment(Experiment.ID.ONBOARDING_WEBPRO_SHORT);
    const hostingerProStore = useHostingerProStore();
    const { canUsePresetsInOnboarding, presetsList } =
      storeToRefs(hostingerProStore);
    const profileStore = useProfileStore();
    const { isAccessManager } = storeToRefs(profileStore);
    const { isH5GOnboarding } = useOnboardingV2();
    const { getResourcesByTypesAndStates } = useResourceMapper();

    const isIdentifiedPro = computed(
      () =>
        state.value[ONBOARDING_STEPS.SURVEY_FOR_WHO] === 'others' ||
        profileStore.isPro,
    );

    const isShorterWebproOnboarding = computed(() => {
      const createOrMigrate = state.value[ONBOARDING_STEPS.CREATE_OR_MIGRATE];

      if (createOrMigrate === 'migrate') return false;

      const platform = state.value[ONBOARDING_STEPS.PLATFORM_SELECT];

      if (platform !== 'wordpress') return false;

      return (
        isShortOnboardingExperimentActive.value &&
        isIdentifiedPro.value &&
        !isAccessManager.value
      );
    });

    const onboardingSteps = computed(() => {
      if (isH5GOnboarding.value) {
        return H5G_ONBOARDING_STEPS_ARRAY;
      }

      if (isShorterWebproOnboarding.value) {
        return SHORTER_WEBPRO_ONBOARDING_STEPS_ARRAY;
      }

      const steps = isEligibleForSurvey.value
        ? ONBOARDING_STEPS_ARRAY
        : ONBOARDING_STEPS_WITHOUT_SURVEY;

      return canUsePresetsInOnboarding.value && presetsList.value.length > 0
        ? steps
        : steps.filter((step) => step !== ONBOARDING_STEPS.WORDPRESS_PRESETS);
    });

    const currentStepValue = computed(() => state.value[currentStep.value]);

    const setState = <
      Key extends OnboardingStep,
      Value extends OnboardingState[Key],
    >(
      step: Key,
      value: Value,
    ) => {
      state.value[step] = value;
    };

    const removeState = (step: OnboardingStep) => {
      delete state.value[step];
    };

    const setIsEligibleForSurvey = (value: boolean) => {
      isEligibleForSurvey.value = value;
    };

    const setIsAddon = (value: boolean) => {
      isAddon.value = value;
    };

    const setIsOnboardingOngoing = (value: boolean) => {
      isOnboardingOngoing.value = value;
    };

    const setIsLeaveOnboardingEventShouldBeSent = (value: boolean) => {
      isLeaveOnboardingEventShouldBeSent.value = value;
    };

    const setShouldContinueOnboarding = (value: boolean) => {
      shouldContinueOnboarding.value = value;
    };

    const getIsSubscriptionWithOnboardingProgress = (
      subscriptionId: string,
    ): boolean =>
      !!subscriptionsWithOnboardingProgress.value.find(
        (id) => subscriptionId === id,
      );

    const fetchOnboardingCompletionStatus = async ({
      domain,
      orderId,
      redirectLocation,
    }: {
      domain: string;
      orderId: string;
      redirectLocation?: string;
    }) => {
      const [{ data }, err] = await accountRepo.getOnboardingCompletionStatus({
        orderId,
        domain,
        redirectLocation,
      });

      if (err) {
        errorLogger.logError(err);

        return;
      }

      onboardingCompletionStatus.value = data;
    };

    const fetchWordPressTemplates = async () => {
      const [{ data }, err] = await wordpressRepo.getAstraTemplates();
      if (err || !data) return;

      wordPressTemplates.value = data;
    };

    const fetchWordPressWebsiteTypes = async (orderId: string) => {
      const [{ data }, err] = await wordpressRepo.getWordPressWebsiteTypes(
        orderId,
      );
      if (err || !data) return;

      wordPressWebsiteTypes.value = data;
    };

    const $reset = () => {
      currentStep.value = ONBOARDING_STEPS.SURVEY_FOR_WHO;
      completedSteps.value = [];
      state.value = {} as OnboardingState;
      transition.value = 'slide-left';
      isAddon.value = false;
      setIsOnboardingOngoing(false);
      setIsLeaveOnboardingEventShouldBeSent(true);
      setShouldContinueOnboarding(false);
    };

    const saveProgress = async (subscriptionId: string) => {
      const data: Partial<OnboardingState> = {
        ...state.value,
        [ONBOARDING_STEPS.COMPLETE]: {
          currentStep: currentStep.value,
          domain: state.value[ONBOARDING_STEPS.DOMAIN]?.domain ?? '',
          status: '',
          steps: completedSteps.value,
          type: '',
          websiteBuilderResourceId: '',
        },
      };

      await onboardingRepo.putOnboardingProgress(subscriptionId, data);
    };

    const loadProgress = async (subscriptionId: string) => {
      const [data] = await onboardingRepo.getOnboardingProgress(subscriptionId);

      const sanitizedData = Object.fromEntries(
        Object.entries(data.data).filter(([_, value]) => value !== null),
      ) as OnboardingState;

      if (data.data) {
        state.value = sanitizedData;
        currentStep.value =
          data.data.complete.currentStep ?? ONBOARDING_STEPS.CREATE_OR_MIGRATE;
        completedSteps.value = data.data.complete.steps;

        return data.data;
      }
    };

    const fetchProgresses = async () => {
      const pendingSubscriptionIds = getResourcesByTypesAndStates({
        types: [HRESOURCES_TYPE.HOSTING],
        states: [HRESOURCES_STATE.PENDING],
      })
        .map(({ chargebeeSubscriptionId }) => chargebeeSubscriptionId)
        .filter((subscriptionId): subscriptionId is string => !!subscriptionId);

      const subscriptionsWithProgress = await Promise.all(
        pendingSubscriptionIds.map(async (subscriptionId) => {
          const [data] = await onboardingRepo.getOnboardingProgress(
            subscriptionId,
          );

          if (data.data) {
            return subscriptionId;
          }
        }),
      );

      subscriptionsWithOnboardingProgress.value =
        subscriptionsWithProgress.filter(
          (subscriptionId): subscriptionId is string => !!subscriptionId,
        );
    };

    const {
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      goTo,
      goToStep,
      goToPrevious,
      completionPercentage,
      removeCompletedStep,
      removeAllCompletedSteps,
      setStep,
      transition,
      setTransition,
      addCompletedStep,
      removePreviousStep,
    } =
      useOnboardingSteps<ArrayElementType<typeof onboardingSteps.value>>(
        onboardingSteps,
      );

    return {
      goTo,
      goToStep,
      goToPrevious,
      transition,
      fetchOnboardingCompletionStatus,
      currentStep,
      completedSteps,
      wordPressTemplates,
      currentStepValue,
      previousStep,
      hasPreviousSteps,
      completionPercentage,
      state,
      isEligibleForSurvey,
      isAddon,
      isOnboardingOngoing,
      isLeaveOnboardingEventShouldBeSent,
      onboardingCompletionStatus,
      shouldContinueOnboarding,
      subscriptionsWithOnboardingProgress,
      setIsAddon,
      setIsOnboardingOngoing,
      setIsLeaveOnboardingEventShouldBeSent,
      isShorterWebproOnboarding,
      isIdentifiedPro,
      setState,
      setIsEligibleForSurvey,
      removeState,
      removeCompletedStep,
      removeAllCompletedSteps,
      setStep,
      addCompletedStep,
      removePreviousStep,
      wordPressWebsiteTypes,
      fetchWordPressWebsiteTypes,
      setTransition,
      setShouldContinueOnboarding,
      getIsSubscriptionWithOnboardingProgress,
      fetchWordPressTemplates,
      $reset,
      saveProgress,
      loadProgress,
      fetchProgresses,
    };
  },
  {
    persist: {
      key: STORE_PERSISTENT_KEYS.ONBOARDING_STORE,
      storage: sessionStorage,
    },
  },
);
