export default [
  {
    value: '1',
    label: '+1',
    full_label: '+1 (United States / Canada)',
    iso: 'CA',
  },
  {
    value: '7',
    label: '+7',
    full_label: '+7 (Russia)',
    iso: 'RU',
  },
  {
    value: '20',
    label: '+20',
    full_label: '+20 (Egypt)',
    iso: 'EG',
  },
  {
    value: '27',
    label: '+27',
    full_label: '+27 (South Africa)',
    iso: 'ZA',
  },
  {
    value: '30',
    label: '+30',
    full_label: '+30 (Greece)',
    iso: 'GR',
  },
  {
    value: '31',
    label: '+31',
    full_label: '+31 (Netherlands)',
    iso: 'NL',
  },
  {
    value: '32',
    label: '+32',
    full_label: '+32 (Belgium)',
    iso: 'BE',
  },
  {
    value: '33',
    label: '+33',
    full_label: '+33 (France)',
    iso: 'FR',
  },
  {
    value: '34',
    label: '+34',
    full_label: '+34 (Spain)',
    iso: 'ES',
  },
  {
    value: '36',
    label: '+36',
    full_label: '+36 (Hungary)',
    iso: 'HU',
  },
  {
    value: '39',
    label: '+39',
    full_label: '+39 (Italy)',
    iso: 'IT',
  },
  {
    value: '40',
    label: '+40',
    full_label: '+40 (Romania)',
    iso: 'RO',
  },
  {
    value: '41',
    label: '+41',
    full_label: '+41 (Switzerland)',
    iso: 'CH',
  },
  {
    value: '43',
    label: '+43',
    full_label: '+43 (Austria)',
    iso: 'AT',
  },
  {
    value: '44',
    label: '+44',
    full_label: '+44 (United Kingdom)',
    iso: 'UK',
  },
  {
    value: '45',
    label: '+45',
    full_label: '+45 (Denmark)',
    iso: 'DK',
  },
  {
    value: '46',
    label: '+46',
    full_label: '+46 (Sweden)',
    iso: 'SE',
  },
  {
    value: '47',
    label: '+47',
    full_label: '+47 (Svalbard and Jan Mayen)',
    iso: 'SJ',
  },
  {
    value: '48',
    label: '+48',
    full_label: '+48 (Poland)',
    iso: 'PL',
  },
  {
    value: '49',
    label: '+49',
    full_label: '+49 (Germany)',
    iso: 'DE',
  },
  {
    value: '51',
    label: '+51',
    full_label: '+51 (Peru)',
    iso: 'PE',
  },
  {
    value: '52',
    label: '+52',
    full_label: '+52 (Mexico)',
    iso: 'MX',
  },
  {
    value: '53',
    label: '+53',
    full_label: '+53 (Cuba)',
    iso: 'CU',
  },
  {
    value: '54',
    label: '+54',
    full_label: '+54 (Argentina)',
    iso: 'AR',
  },
  {
    value: '55',
    label: '+55',
    full_label: '+55 (Brazil)',
    iso: 'BR',
  },
  {
    value: '56',
    label: '+56',
    full_label: '+56 (Chile)',
    iso: 'CL',
  },
  {
    value: '57',
    label: '+57',
    full_label: '+57 (Colombia)',
    iso: 'CO',
  },
  {
    value: '58',
    label: '+58',
    full_label: '+58 (Venezuela, Bolivarian Republic of Venezuela)',
    iso: 'VE',
  },
  {
    value: '60',
    label: '+60',
    full_label: '+60 (Malaysia)',
    iso: 'MY',
  },
  {
    value: '61',
    label: '+61',
    full_label: '+61 (Australia)',
    iso: 'AU',
  },
  {
    value: '62',
    label: '+62',
    full_label: '+62 (Indonesia)',
    iso: 'ID',
  },
  {
    value: '63',
    label: '+63',
    full_label: '+63 (Philippines)',
    iso: 'PH',
  },
  {
    value: '64',
    label: '+64',
    full_label: '+64 (New Zealand)',
    iso: 'NZ',
  },
  {
    value: '65',
    label: '+65',
    full_label: '+65 (Singapore)',
    iso: 'SG',
  },
  {
    value: '66',
    label: '+66',
    full_label: '+66 (Thailand)',
    iso: 'TH',
  },
  {
    value: '81',
    label: '+81',
    full_label: '+81 (Japan)',
    iso: 'JP',
  },
  {
    value: '82',
    label: '+82',
    full_label: '+82 (Korea, Republic of South Korea)',
    iso: 'KR',
  },
  {
    value: '84',
    label: '+84',
    full_label: '+84 (Vietnam)',
    iso: 'VN',
  },
  {
    value: '86',
    label: '+86',
    full_label: '+86 (China)',
    iso: 'CN',
  },
  {
    value: '90',
    label: '+90',
    full_label: '+90 (Turkey)',
    iso: 'TR',
  },
  {
    value: '91',
    label: '+91',
    full_label: '+91 (India)',
    iso: 'IN',
  },
  {
    value: '92',
    label: '+92',
    full_label: '+92 (Pakistan)',
    iso: 'PK',
  },
  {
    value: '93',
    label: '+93',
    full_label: '+93 (Afghanistan)',
    iso: 'AF',
  },
  {
    value: '94',
    label: '+94',
    full_label: '+94 (Sri Lanka)',
    iso: 'LK',
  },
  {
    value: '95',
    label: '+95',
    full_label: '+95 (Myanmar)',
    iso: 'MM',
  },
  {
    value: '98',
    label: '+98',
    full_label: '+98 (Iran, Islamic Republic of Persian Gulf)',
    iso: 'IR',
  },
  {
    value: '212',
    label: '+212',
    full_label: '+212 (Morocco)',
    iso: 'MA',
  },
  {
    value: '213',
    label: '+213',
    full_label: '+213 (Algeria)',
    iso: 'DZ',
  },
  {
    value: '216',
    label: '+216',
    full_label: '+216 (Tunisia)',
    iso: 'TN',
  },
  {
    value: '218',
    label: '+218',
    full_label: '+218 (Libyan Arab Jamahiriya)',
    iso: 'LY',
  },
  {
    value: '220',
    label: '+220',
    full_label: '+220 (Gambia)',
    iso: 'GM',
  },
  {
    value: '221',
    label: '+221',
    full_label: '+221 (Senegal)',
    iso: 'SN',
  },
  {
    value: '222',
    label: '+222',
    full_label: '+222 (Mauritania)',
    iso: 'MR',
  },
  {
    value: '223',
    label: '+223',
    full_label: '+223 (Mali)',
    iso: 'ML',
  },
  {
    value: '224',
    label: '+224',
    full_label: '+224 (Guinea)',
    iso: 'GN',
  },
  {
    value: '225',
    label: '+225',
    full_label: "+225 (Cote d'Ivoire)",
    iso: 'CI',
  },
  {
    value: '226',
    label: '+226',
    full_label: '+226 (Burkina Faso)',
    iso: 'BF',
  },
  {
    value: '227',
    label: '+227',
    full_label: '+227 (Niger)',
    iso: 'NE',
  },
  {
    value: '228',
    label: '+228',
    full_label: '+228 (Togo)',
    iso: 'TG',
  },
  {
    value: '229',
    label: '+229',
    full_label: '+229 (Benin)',
    iso: 'BJ',
  },
  {
    value: '230',
    label: '+230',
    full_label: '+230 (Mauritius)',
    iso: 'MU',
  },
  {
    value: '231',
    label: '+231',
    full_label: '+231 (Liberia)',
    iso: 'LR',
  },
  {
    value: '232',
    label: '+232',
    full_label: '+232 (Sierra Leone)',
    iso: 'SL',
  },
  {
    value: '233',
    label: '+233',
    full_label: '+233 (Ghana)',
    iso: 'GH',
  },
  {
    value: '234',
    label: '+234',
    full_label: '+234 (Nigeria)',
    iso: 'NE|NG',
  },
  {
    value: '235',
    label: '+235',
    full_label: '+235 (Chad)',
    iso: 'TD',
  },
  {
    value: '236',
    label: '+236',
    full_label: '+236 (Central African Republic)',
    iso: 'CF',
  },
  {
    value: '237',
    label: '+237',
    full_label: '+237 (Cameroon)',
    iso: 'CM',
  },
  {
    value: '238',
    label: '+238',
    full_label: '+238 (Cape Verde)',
    iso: 'CV',
  },
  {
    value: '239',
    label: '+239',
    full_label: '+239 (Sao Tome and Principe)',
    iso: 'ST',
  },
  {
    value: '240',
    label: '+240',
    full_label: '+240 (Equatorial Guinea)',
    iso: 'GN|GQ',
  },
  {
    value: '241',
    label: '+241',
    full_label: '+241 (Gabon)',
    iso: 'GA',
  },
  {
    value: '242',
    label: '+242',
    full_label: '+242 (Congo)',
    iso: 'CG',
  },
  {
    value: '243',
    label: '+243',
    full_label: '+243 (Congo, The Democratic Republic of the Congo)',
    iso: 'CG',
  },
  {
    value: '244',
    label: '+244',
    full_label: '+244 (Angola)',
    iso: 'AO',
  },
  {
    value: '245',
    label: '+245',
    full_label: '+245 (Guinea-Bissau)',
    iso: 'GN|GW',
  },
  {
    value: '246',
    label: '+246',
    full_label: '+246 (British Indian Ocean Territory)',
    iso: 'IN|IO',
  },
  {
    value: '248',
    label: '+248',
    full_label: '+248 (Seychelles)',
    iso: 'SC',
  },
  {
    value: '249',
    label: '+249',
    full_label: '+249 (Sudan)',
    iso: 'SD',
  },
  {
    value: '250',
    label: '+250',
    full_label: '+250 (Rwanda)',
    iso: 'RW',
  },
  {
    value: '251',
    label: '+251',
    full_label: '+251 (Ethiopia)',
    iso: 'ET',
  },
  {
    value: '252',
    label: '+252',
    full_label: '+252 (Somalia)',
    iso: 'SO',
  },
  {
    value: '253',
    label: '+253',
    full_label: '+253 (Djibouti)',
    iso: 'DJ',
  },
  {
    value: '254',
    label: '+254',
    full_label: '+254 (Kenya)',
    iso: 'KE',
  },
  {
    value: '255',
    label: '+255',
    full_label: '+255 (Tanzania, United Republic of Tanzania)',
    iso: 'TZ',
  },
  {
    value: '256',
    label: '+256',
    full_label: '+256 (Uganda)',
    iso: 'UG',
  },
  {
    value: '257',
    label: '+257',
    full_label: '+257 (Burundi)',
    iso: 'BI',
  },
  {
    value: '258',
    label: '+258',
    full_label: '+258 (Mozambique)',
    iso: 'MZ',
  },
  {
    value: '260',
    label: '+260',
    full_label: '+260 (Zambia)',
    iso: 'ZM',
  },
  {
    value: '261',
    label: '+261',
    full_label: '+261 (Madagascar)',
    iso: 'MG',
  },
  {
    value: '262',
    label: '+262',
    full_label: '+262 (Reunion)',
    iso: 'RE',
  },
  {
    value: '263',
    label: '+263',
    full_label: '+263 (Zimbabwe)',
    iso: 'ZW',
  },
  {
    value: '264',
    label: '+264',
    full_label: '+264 (Namibia)',
    iso: 'NA',
  },
  {
    value: '265',
    label: '+265',
    full_label: '+265 (Malawi)',
    iso: 'MW',
  },
  {
    value: '266',
    label: '+266',
    full_label: '+266 (Lesotho)',
    iso: 'LS',
  },
  {
    value: '267',
    label: '+267',
    full_label: '+267 (Botswana)',
    iso: 'BW',
  },
  {
    value: '268',
    label: '+268',
    full_label: '+268 (Swaziland)',
    iso: 'SZ',
  },
  {
    value: '269',
    label: '+269',
    full_label: '+269 (Comoros)',
    iso: 'KM',
  },
  {
    value: '290',
    label: '+290',
    full_label: '+290 (Saint Helena, Ascension and Tristan Da Cunha)',
    iso: 'SH',
  },
  {
    value: '291',
    label: '+291',
    full_label: '+291 (Eritrea)',
    iso: 'ER',
  },
  {
    value: '297',
    label: '+297',
    full_label: '+297 (Aruba)',
    iso: 'AW',
  },
  {
    value: '298',
    label: '+298',
    full_label: '+298 (Faroe Islands)',
    iso: 'FO',
  },
  {
    value: '299',
    label: '+299',
    full_label: '+299 (Greenland)',
    iso: 'GL',
  },
  {
    value: '345',
    label: '+345',
    full_label: '+345 (Cayman Islands)',
    iso: 'KY',
  },
  {
    value: '350',
    label: '+350',
    full_label: '+350 (Gibraltar)',
    iso: 'GI',
  },
  {
    value: '351',
    label: '+351',
    full_label: '+351 (Portugal)',
    iso: 'PT',
  },
  {
    value: '352',
    label: '+352',
    full_label: '+352 (Luxembourg)',
    iso: 'LU',
  },
  {
    value: '353',
    label: '+353',
    full_label: '+353 (Ireland)',
    iso: 'IE',
  },
  {
    value: '354',
    label: '+354',
    full_label: '+354 (Iceland)',
    iso: 'IS',
  },
  {
    value: '355',
    label: '+355',
    full_label: '+355 (Albania)',
    iso: 'AL',
  },
  {
    value: '356',
    label: '+356',
    full_label: '+356 (Malta)',
    iso: 'MT',
  },
  {
    value: '357',
    label: '+357',
    full_label: '+357 (Cyprus)',
    iso: 'CY',
  },
  {
    value: '358',
    label: '+358',
    full_label: '+358 (Finland)',
    iso: 'FI',
  },
  {
    value: '359',
    label: '+359',
    full_label: '+359 (Bulgaria)',
    iso: 'BG',
  },
  {
    value: '370',
    label: '+370',
    full_label: '+370 (Lithuania)',
    iso: 'LT',
  },
  {
    value: '371',
    label: '+371',
    full_label: '+371 (Latvia)',
    iso: 'LV',
  },
  {
    value: '372',
    label: '+372',
    full_label: '+372 (Estonia)',
    iso: 'EE',
  },
  {
    value: '373',
    label: '+373',
    full_label: '+373 (Moldova)',
    iso: 'MD',
  },
  {
    value: '374',
    label: '+374',
    full_label: '+374 (Armenia)',
    iso: 'AM',
  },
  {
    value: '375',
    label: '+375',
    full_label: '+375 (Belarus)',
    iso: 'BY',
  },
  {
    value: '376',
    label: '+376',
    full_label: '+376 (Andorra)',
    iso: 'AD',
  },
  {
    value: '377',
    label: '+377',
    full_label: '+377 (Monaco)',
    iso: 'MC',
  },
  {
    value: '378',
    label: '+378',
    full_label: '+378 (San Marino)',
    iso: 'SM',
  },
  {
    value: '379',
    label: '+379',
    full_label: '+379 (Holy See (Vatican City State))',
    iso: 'VA',
  },
  {
    value: '380',
    label: '+380',
    full_label: '+380 (Ukraine)',
    iso: 'UA',
  },
  {
    value: '381',
    label: '+381',
    full_label: '+381 (Serbia)',
    iso: 'RS',
  },
  {
    value: '382',
    label: '+382',
    full_label: '+382 (Montenegro)',
    iso: 'ME',
  },
  {
    value: '383',
    label: '+383',
    full_label: '+383 (Kosovo)',
    iso: 'XK',
  },
  {
    value: '385',
    label: '+385',
    full_label: '+385 (Croatia)',
    iso: 'HR',
  },
  {
    value: '386',
    label: '+386',
    full_label: '+386 (Slovenia)',
    iso: 'SI',
  },
  {
    value: '387',
    label: '+387',
    full_label: '+387 (Bosnia and Herzegovina)',
    iso: 'BA',
  },
  {
    value: '389',
    label: '+389',
    full_label: '+389 (Macedonia)',
    iso: 'MK',
  },
  {
    value: '420',
    label: '+420',
    full_label: '+420 (Czech Republic)',
    iso: 'CZ',
  },
  {
    value: '421',
    label: '+421',
    full_label: '+421 (Slovakia)',
    iso: 'SK',
  },
  {
    value: '423',
    label: '+423',
    full_label: '+423 (Liechtenstein)',
    iso: 'LI',
  },
  {
    value: '500',
    label: '+500',
    full_label: '+500 (South Georgia and the South Sandwich Islands)',
    iso: 'GE|GS',
  },
  {
    value: '501',
    label: '+501',
    full_label: '+501 (Belize)',
    iso: 'BZ',
  },
  {
    value: '502',
    label: '+502',
    full_label: '+502 (Guatemala)',
    iso: 'GT',
  },
  {
    value: '503',
    label: '+503',
    full_label: '+503 (El Salvador)',
    iso: 'SV',
  },
  {
    value: '504',
    label: '+504',
    full_label: '+504 (Honduras)',
    iso: 'HN',
  },
  {
    value: '505',
    label: '+505',
    full_label: '+505 (Nicaragua)',
    iso: 'NI',
  },
  {
    value: '506',
    label: '+506',
    full_label: '+506 (Costa Rica)',
    iso: 'CR',
  },
  {
    value: '507',
    label: '+507',
    full_label: '+507 (Panama)',
    iso: 'PA',
  },
  {
    value: '508',
    label: '+508',
    full_label: '+508 (Saint Pierre and Miquelon)',
    iso: 'PM',
  },
  {
    value: '509',
    label: '+509',
    full_label: '+509 (Haiti)',
    iso: 'HT',
  },
  {
    value: '590',
    label: '+590',
    full_label: '+590 (Saint Martin)',
    iso: 'MF',
  },
  {
    value: '591',
    label: '+591',
    full_label: '+591 (Bolivia, Plurinational State of)',
    iso: 'BO',
  },
  {
    value: '593',
    label: '+593',
    full_label: '+593 (Ecuador)',
    iso: 'EC',
  },
  {
    value: '594',
    label: '+594',
    full_label: '+594 (French Guiana)',
    iso: 'GF',
  },
  {
    value: '595',
    label: '+595',
    full_label: '+595 (Paraguay)',
    iso: 'PY',
  },
  {
    value: '596',
    label: '+596',
    full_label: '+596 (Martinique)',
    iso: 'MQ',
  },
  {
    value: '597',
    label: '+597',
    full_label: '+597 (Suriname)',
    iso: 'SR',
  },
  {
    value: '598',
    label: '+598',
    full_label: '+598 (Uruguay)',
    iso: 'UY',
  },
  {
    value: '599',
    label: '+599',
    full_label: '+599 (Netherlands Antilles)',
    iso: 'NL',
  },
  {
    value: '670',
    label: '+670',
    full_label: '+670 (Timor-Leste)',
    iso: 'TL',
  },
  {
    value: '672',
    label: '+672',
    full_label: '+672 (Norfolk Island)',
    iso: 'NF',
  },
  {
    value: '673',
    label: '+673',
    full_label: '+673 (Brunei Darussalam)',
    iso: 'BN',
  },
  {
    value: '674',
    label: '+674',
    full_label: '+674 (Nauru)',
    iso: 'NR',
  },
  {
    value: '675',
    label: '+675',
    full_label: '+675 (Papua New Guinea)',
    iso: 'GN|PG',
  },
  {
    value: '676',
    label: '+676',
    full_label: '+676 (Tonga)',
    iso: 'TO',
  },
  {
    value: '677',
    label: '+677',
    full_label: '+677 (Solomon Islands)',
    iso: 'SB',
  },
  {
    value: '678',
    label: '+678',
    full_label: '+678 (Vanuatu)',
    iso: 'VU',
  },
  {
    value: '679',
    label: '+679',
    full_label: '+679 (Fiji)',
    iso: 'FJ',
  },
  {
    value: '680',
    label: '+680',
    full_label: '+680 (Palau)',
    iso: 'PW',
  },
  {
    value: '681',
    label: '+681',
    full_label: '+681 (Wallis and Futuna)',
    iso: 'WF',
  },
  {
    value: '682',
    label: '+682',
    full_label: '+682 (Cook Islands)',
    iso: 'CK',
  },
  {
    value: '683',
    label: '+683',
    full_label: '+683 (Niue)',
    iso: 'NU',
  },
  {
    value: '685',
    label: '+685',
    full_label: '+685 (Samoa)',
    iso: 'WS',
  },
  {
    value: '686',
    label: '+686',
    full_label: '+686 (Kiribati)',
    iso: 'KI',
  },
  {
    value: '687',
    label: '+687',
    full_label: '+687 (New Caledonia)',
    iso: 'NC',
  },
  {
    value: '688',
    label: '+688',
    full_label: '+688 (Tuvalu)',
    iso: 'TV',
  },
  {
    value: '689',
    label: '+689',
    full_label: '+689 (French Polynesia)',
    iso: 'PF',
  },
  {
    value: '690',
    label: '+690',
    full_label: '+690 (Tokelau)',
    iso: 'TK',
  },
  {
    value: '691',
    label: '+691',
    full_label: '+691 (Micronesia, Federated States of Micronesia)',
    iso: 'FM',
  },
  {
    value: '692',
    label: '+692',
    full_label: '+692 (Marshall Islands)',
    iso: 'MH',
  },
  {
    value: '850',
    label: '+850',
    full_label: "+850 (Korea, Democratic People's Republic of Korea)",
    iso: 'KP',
  },
  {
    value: '852',
    label: '+852',
    full_label: '+852 (Hong Kong)',
    iso: 'HK',
  },
  {
    value: '853',
    label: '+853',
    full_label: '+853 (Macao)',
    iso: 'MO',
  },
  {
    value: '855',
    label: '+855',
    full_label: '+855 (Cambodia)',
    iso: 'KH',
  },
  {
    value: '856',
    label: '+856',
    full_label: '+856 (Laos)',
    iso: 'LA',
  },
  {
    value: '872',
    label: '+872',
    full_label: '+872 (Pitcairn)',
    iso: 'PN',
  },
  {
    value: '880',
    label: '+880',
    full_label: '+880 (Bangladesh)',
    iso: 'BD',
  },
  {
    value: '886',
    label: '+886',
    full_label: '+886 (Taiwan)',
    iso: 'TW',
  },
  {
    value: '960',
    label: '+960',
    full_label: '+960 (Maldives)',
    iso: 'MV',
  },
  {
    value: '961',
    label: '+961',
    full_label: '+961 (Lebanon)',
    iso: 'LB',
  },
  {
    value: '962',
    label: '+962',
    full_label: '+962 (Jordan)',
    iso: 'JO',
  },
  {
    value: '963',
    label: '+963',
    full_label: '+963 (Syrian Arab Republic)',
    iso: 'SY',
  },
  {
    value: '964',
    label: '+964',
    full_label: '+964 (Iraq)',
    iso: 'IQ',
  },
  {
    value: '965',
    label: '+965',
    full_label: '+965 (Kuwait)',
    iso: 'KW',
  },
  {
    value: '966',
    label: '+966',
    full_label: '+966 (Saudi Arabia)',
    iso: 'SA',
  },
  {
    value: '967',
    label: '+967',
    full_label: '+967 (Yemen)',
    iso: 'YE',
  },
  {
    value: '968',
    label: '+968',
    full_label: '+968 (Oman)',
    iso: 'OM',
  },
  {
    value: '970',
    label: '+970',
    full_label: '+970 (Palestinian Territory, Occupied)',
    iso: 'PS',
  },
  {
    value: '971',
    label: '+971',
    full_label: '+971 (United Arab Emirates)',
    iso: 'AE',
  },
  {
    value: '972',
    label: '+972',
    full_label: '+972 (Israel)',
    iso: 'IL',
  },
  {
    value: '973',
    label: '+973',
    full_label: '+973 (Bahrain)',
    iso: 'BH',
  },
  {
    value: '974',
    label: '+974',
    full_label: '+974 (Qatar)',
    iso: 'QA',
  },
  {
    value: '975',
    label: '+975',
    full_label: '+975 (Bhutan)',
    iso: 'BT',
  },
  {
    value: '976',
    label: '+976',
    full_label: '+976 (Mongolia)',
    iso: 'MN',
  },
  {
    value: '977',
    label: '+977',
    full_label: '+977 (Nepal)',
    iso: 'NP',
  },
  {
    value: '992',
    label: '+992',
    full_label: '+992 (Tajikistan)',
    iso: 'TJ',
  },
  {
    value: '993',
    label: '+993',
    full_label: '+993 (Turkmenistan)',
    iso: 'TM',
  },
  {
    value: '994',
    label: '+994',
    full_label: '+994 (Azerbaijan)',
    iso: 'AZ',
  },
  {
    value: '995',
    label: '+995',
    full_label: '+995 (Georgia)',
    iso: 'GE',
  },
  {
    value: '996',
    label: '+996',
    full_label: '+996 (Kyrgyzstan)',
    iso: 'KG',
  },
  {
    value: '998',
    label: '+998',
    full_label: '+998 (Uzbekistan)',
    iso: 'UZ',
  },
  {
    value: '1242',
    label: '+1242',
    full_label: '+1242 (Bahamas)',
    iso: 'BS',
  },
  {
    value: '1246',
    label: '+1246',
    full_label: '+1246 (Barbados)',
    iso: 'BB',
  },
  {
    value: '1264',
    label: '+1264',
    full_label: '+1264 (Anguilla)',
    iso: 'AI',
  },
  {
    value: '1268',
    label: '+1268',
    full_label: '+1268 (Antigua and Barbuda)',
    iso: 'AG',
  },
  {
    value: '1284',
    label: '+1284',
    full_label: '+1284 (Virgin Islands, British)',
    iso: 'VG',
  },
  {
    value: '1340',
    label: '+1340',
    full_label: '+1340 (Virgin Islands, U.S.)',
    iso: 'VI',
  },
  {
    value: '1441',
    label: '+1441',
    full_label: '+1441 (Bermuda)',
    iso: 'BM',
  },
  {
    value: '1473',
    label: '+1473',
    full_label: '+1473 (Grenada)',
    iso: 'GD',
  },
  {
    value: '1649',
    label: '+1649',
    full_label: '+1649 (Turks and Caicos Islands)',
    iso: 'TC',
  },
  {
    value: '1664',
    label: '+1664',
    full_label: '+1664 (Montserrat)',
    iso: 'MS',
  },
  {
    value: '1670',
    label: '+1670',
    full_label: '+1670 (Northern Mariana Islands)',
    iso: 'MP',
  },
  {
    value: '1671',
    label: '+1671',
    full_label: '+1671 (Guam)',
    iso: 'GU',
  },
  {
    value: '1684',
    label: '+1684',
    full_label: '+1684 (AmericanSamoa)',
    iso: 'WS',
  },
  {
    value: '1758',
    label: '+1758',
    full_label: '+1758 (Saint Lucia)',
    iso: 'LC',
  },
  {
    value: '1767',
    label: '+1767',
    full_label: '+1767 (Dominica)',
    iso: 'DM',
  },
  {
    value: '1784',
    label: '+1784',
    full_label: '+1784 (Saint Vincent and the Grenadines)',
    iso: 'VC',
  },
  {
    value: '1849',
    label: '+1849',
    full_label: '+1849 (Dominican Republic)',
    iso: 'DM|DO',
  },
  {
    value: '1829',
    label: '+1829',
    full_label: '+1829 (Dominican Republic)',
    iso: 'DM|DO',
  },
  {
    value: '1809',
    label: '+1809',
    full_label: '+1809 (Dominican Republic)',
    iso: 'DM|DO',
  },
  {
    value: '1868',
    label: '+1868',
    full_label: '+1868 (Trinidad and Tobago)',
    iso: 'TT',
  },
  {
    value: '1869',
    label: '+1869',
    full_label: '+1869 (Saint Kitts and Nevis)',
    iso: 'KN',
  },
  {
    value: '1876',
    label: '+1876',
    full_label: '+1876 (Jamaica)',
    iso: 'JM',
  },
  {
    value: '1939',
    label: '+1939',
    full_label: '+1939 (Puerto Rico)',
    iso: 'PR',
  },
];
