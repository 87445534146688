import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useWebsiteReportActions } from '@/composables';
import { useGlobals } from '@/composables/useGlobals';
import { useReportTemplatesStore } from '@/stores/developerTools/reportTemplatesStore';
import { useScheduledReportsStore } from '@/stores/developerTools/scheduledReportsStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import {
  CreateReportStep,
  Header,
  type ReportContactDetails,
  type ReportDetails,
  type ReportBaseSchedule,
  type ReportStructure,
  Route,
  ScheduleType,
} from '@/types';

export const useCreateReportStore = defineStore('createReportStore', () => {
  const { t } = useGlobals();
  const profileStore = useProfileStore();
  const route = useRoute();
  const scheduledReportStore = useScheduledReportsStore();
  const wordpressStore = useWordpressStore();
  const reportTemplateStore = useReportTemplatesStore();

  const {
    IS_WEBSITE_ADDRESS_ENABLED,
    getScheduleTypeOptionFromParam,
    getScheduleTypeParamFromOption,
  } = useWebsiteReportActions();

  const shouldIncludeContactDetails = ref(true);
  const selectedTemplateId = ref<number | null>(null);

  const hasSupportForSpecialChars = false;

  const activeStepId = ref(CreateReportStep.SELECT_WEBSITE);
  const setActiveStep = (step: CreateReportStep) => (activeStepId.value = step);

  const DEFAULT_TITLE = computed(() =>
    hasSupportForSpecialChars
      ? t('v2.reports.default.title')
      : 'Maintenance report',
  );

  const DEFAULT_INTRO_MESSAGE = computed(() =>
    hasSupportForSpecialChars
      ? t('v2.reports.default.intro.message')
      : 'This is the summary of your website’s health and all the work we have done to keep it up do date and well performing for the last 30 days. If you have any questions, please feel free to contact us.',
  );

  const defaultCompanyName = computed(
    () => profileStore.contact?.companyName || '',
  );
  const defaultEmail = computed(() => profileStore.contact?.email || '');

  const defaultPhoneNumber = computed(() => {
    if (!profileStore.contact?.phone || !profileStore.contact?.phoneCc) {
      return '';
    }

    return `${profileStore.contact.phoneCc}${profileStore.contact.phone}`;
  });

  const defaultWebsiteAddress = computed(() => '');

  const emptyContactDetails = computed(() => ({
    companyName: '',
    email: '',
    phone: '',
    ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
  }));

  const isEditScheduleFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_SCHEDULE,
  );

  const isEditTemplateFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_TEMPLATE,
  );

  const DETAILS_INITIAL_STATE = {
    domain: '',
    title: DEFAULT_TITLE.value,
    introMessage: DEFAULT_INTRO_MESSAGE.value,
    contactDetails: {
      companyName: defaultCompanyName.value,
      email: defaultEmail.value,
      phone: defaultPhoneNumber.value,
      ...(IS_WEBSITE_ADDRESS_ENABLED
        ? [{ websiteAddress: defaultWebsiteAddress.value }]
        : []),
    },
    structure: {},
    deliverToEmail: '',
    schedule: {
      scheduleType: ScheduleType.NONE,
      scheduleValue: undefined,
      scheduleHour: undefined,
    },
  };

  const details = ref<ReportDetails>(DETAILS_INITIAL_STATE);

  const setShouldIncludeContactDetails = (value: boolean) =>
    (shouldIncludeContactDetails.value = value);

  const setTemplateId = (id: number | null) => {
    selectedTemplateId.value = id;
  };

  const set = <K extends keyof ReportDetails>(
    field: K,
    value: ReportDetails[K],
  ) => {
    details.value = {
      ...details.value,
      [field]: value,
    };
  };

  const get = <K extends keyof ReportDetails>(field: K) => details.value[field];

  const setMultiple = (params: Partial<ReportDetails>) => {
    Object.entries(params).forEach(([key, value]) => {
      set(key as keyof ReportDetails, value);
    });
  };

  const setDomain = (targetDomain: string) => {
    set('domain', targetDomain);
  };

  const setContactDetails = <K extends keyof ReportContactDetails>(
    field: K,
    value: ReportContactDetails[K],
  ) => {
    details.value.contactDetails = {
      ...details.value.contactDetails,
      [field]: value,
    };
  };

  const setStructureDetails = <K extends keyof ReportStructure>(
    field: K,
    value: ReportStructure[K],
  ) => {
    details.value.structure = {
      ...details.value.structure,
      [field]: value,
    };
  };

  const setScheduleDetails = <K extends keyof ReportBaseSchedule>(
    field: K,
    value: ReportBaseSchedule[K],
  ) => {
    if (field === 'scheduleType') {
      details.value.schedule = {
        scheduleType: value as ScheduleType,
        scheduleValue: null,
        scheduleHour: null,
      };

      return;
    }

    details.value.schedule = {
      ...details.value.schedule,
      [field]: value,
    };
  };

  const setDeliverToEmail = (receiverEmail: string) => {
    set('deliverToEmail', receiverEmail);
  };

  const resetContactDetails = () => {
    details.value.contactDetails = {
      companyName: '',
      email: '',
      phone: '',
      ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
    };
  };

  const getIsDetailsScheduleTypeSelected = (type: ScheduleType) =>
    details.value.schedule.scheduleType === type;

  const prefillScheduledReportData = async (id: number) => {
    const schedule = await scheduledReportStore.getScheduleById(id);

    if (!schedule) {
      return false;
    }

    setMultiple({
      domain: schedule?.domain,
      title: schedule?.title,
      introMessage: schedule?.introMessage,
      contactDetails: schedule.contactDetails,
      structure: schedule.structure,
      deliverToEmail: schedule?.deliverToEmail,
      schedule: {
        scheduleType: getScheduleTypeOptionFromParam(schedule.scheduleType),
        scheduleValue: `${schedule.scheduleValue}`,
        scheduleHour: `${schedule.scheduleHour}`,
      },
    });

    return true;
  };

  const prefillTemplateData = (id: number) => {
    const template = reportTemplateStore.getTemplateById(id);
    if (!template) {
      return;
    }

    setMultiple({
      title: template.title,
      contactDetails: template.contactDetails,
      introMessage: template.introMessage,
      structure: template.structure,
      schedule: {
        scheduleType: getScheduleTypeOptionFromParam(
          template?.scheduleType || 'default',
        ),
        scheduleValue: `${template.scheduleValue}`,
        scheduleHour: `${template.scheduleHour}`,
      },
    });
  };

  const resetDetails = () => {
    setMultiple(DETAILS_INITIAL_STATE);
  };

  const $reset = () => {
    activeStepId.value = CreateReportStep.SELECT_WEBSITE;
    shouldIncludeContactDetails.value = true;
    selectedTemplateId.value = null;
    resetDetails();
  };

  const reportRequestHeaders = computed(() => {
    const domain = details.value.domain || '';
    const website =
      wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(domain);

    if (!website) return null;

    return {
      [Header.USERNAME]: website.username,
      [Header.ORDER_ID]: website.orderId,
      [Header.DOMAIN]: domain,
    };
  });

  const getReportBaseParams = (shouldIncludeDirectory = false) => {
    const website = wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(
      details.value.domain || '',
    );
    const { title, introMessage, contactDetails, structure } = details.value;

    return {
      title,
      introMessage,
      contactDetails: shouldIncludeContactDetails.value
        ? contactDetails
        : emptyContactDetails.value,
      structure,
      ...(shouldIncludeDirectory
        ? { directory: website?.directory || '' }
        : {}),
    };
  };

  const scheduleParams = computed(() => {
    const { schedule } = details.value;
    const scheduleType = getScheduleTypeParamFromOption(schedule.scheduleType);

    if (!scheduleType) {
      return {
        scheduleType: null,
        scheduleValue: null,
        scheduleHour: null,
      };
    }

    return {
      scheduleType,
      scheduleValue: schedule.scheduleValue || null,
      scheduleHour: schedule.scheduleHour || null,
    };
  });

  return {
    activeStepId,
    details,
    isEditScheduleFlow,
    isEditTemplateFlow,
    shouldIncludeContactDetails,
    emptyContactDetails,
    reportRequestHeaders,
    selectedTemplateId,
    scheduleParams,
    get,
    setActiveStep,
    setTemplateId,
    setDomain,
    set,
    setContactDetails,
    setShouldIncludeContactDetails,
    setStructureDetails,
    setScheduleDetails,
    setDeliverToEmail,
    resetContactDetails,
    getIsDetailsScheduleTypeSelected,
    prefillScheduledReportData,
    prefillTemplateData,
    getReportBaseParams,
    $reset,
    resetDetails,
  };
});
