<template>
  <HProgress
    v-if="isObject(data)"
    v-tooltip="getTooltip(data)"
    sm
    :danger="isDanger"
    :primary="!isDanger"
    :value="calculateUsage(data)"
    data-skin="dark"
  />
  <span v-else>{{ data }}</span>
</template>
<script>
import { isObject, isNumber } from 'lodash';

import HProgress from '../HProgress/HProgress';

export default {
  name: 'DetailListItemProgressbar',
  components: { HProgress },
  props: {
    data: {
      type: [String, Object, Number],
      required: true,
    },
  },
  computed: {
    isDanger() {
      return isNumber(this.data.value) && isNumber(this.data.limit)
        ? (100 / this.data.limit) * this.data.value > 80
        : false;
    },
  },
  methods: {
    isObject,
    calculateUsage(details) {
      return details ? (details.value / details.limit) * 100 : 0;
    },
    getTooltip(data) {
      if (isObject(data) && data.value >= 0) {
        return data.value + (data.limit ? ` / ${data.limit}` : '');
      }

      return '';
    },
  },
};
</script>
