<script setup lang="ts">
type Props = {
  title: string;
  value?: string;
  activeCoupon: string;
};

interface Emits {
  (eventName: 'on-remove'): void;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const removeCoupon = () => emit('on-remove');
</script>

<template>
  <div class="active-coupon">
    <div class="active-coupon__left">
      <Trans
        tag="h4"
        class="active-coupon__text"
        :translate-params="{ coupon: activeCoupon }"
      >
        {{ title }} ({coupon})
      </Trans>
      <p class="active-coupon__remove" @click="removeCoupon">Remove</p>
    </div>
    <p class="text-dark">{{ value }}</p>
  </div>
</template>

<style lang="scss" scoped>
.active-coupon {
  display: flex;
  justify-content: space-between;

  &__remove {
    color: var(--primary);
    font-weight: 700;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
  }

  &__text {
    display: inline-block;
  }
}
</style>
