import cookies from 'js-cookie';

import { useProfileStore } from '@/stores';
import { AccountAccess } from '@/types';

const permissionsServiceObject = {
  userHasPurchaseAccess() {
    const profileStore = useProfileStore();

    return profileStore.isAccessManager
      ? profileStore.access?.level === AccountAccess.AccessLevel.PURCHASE
      : true;
  },
  isPayDisabled() {
    const profileStore = useProfileStore();
    if (this.allowPaymentsCookie()) return false;

    return profileStore.isStaff;
  },
  tooltipDisabled() {
    return this.isPayDisabled()
      ? `Disabled because you are impersonating user account`
      : undefined;
  },
  allowPaymentsCookie() {
    return cookies.get('allowPayments');
  },
  paymentDisabled() {
    return this.isPayDisabled() || !this.userHasPurchaseAccess();
  },
};

export default permissionsServiceObject;
