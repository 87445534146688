<template>
  <div ref="content" :class="{ 'disabled-pointer': disabled }">
    <slot />
  </div>
</template>

<script>
import { useModal } from '@/composables';
import { getAsset } from '@/utils/helpers/assetsHelpers';

export default {
  inject: {
    modalOpened: {
      default: false,
    },
  },
  props: {
    modelValue: Object,
    disabled: Boolean,
  },
  data: () => ({
    formIsValid: true,
    formFields: [],
    values: {},
    watchers: [],
  }),
  emits: ['update:modelValue', 'error', 'submit'],
  computed: {
    errorFields() {
      return Object.values(this.values).filter(({ error }) => error);
    },
    calendarAsset() {
      return `url(${getAsset('@/images/form/calendar.svg')})`;
    },
    isFormValid() {
      return !Object.values(this.values).filter(({ error }) => error).length;
    },
    formValues() {
      const formValues = {};
      Object.entries(this.values).forEach(
        ([key, value]) => (formValues[key] = value.value),
      );

      return formValues;
    },
  },
  watch: {
    values: {
      handler() {
        this.$emit('update:modelValue', {
          isValid: this.isFormValid,
          values: this.formValues,
        });
      },
      deep: true,
      immediate: true,
    },
    errorFields: {
      handler(value) {
        this.$emit('error', value);
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.manageFields();
  },
  created() {
    if (this.modalOpened) {
      this.setModalClosingType('input');
    }
  },
  setup() {
    const { setModalClosingType } = useModal();

    return {
      setModalClosingType,
    };
  },
  beforeUnmount() {
    this.formFields.forEach((field) => {
      field.removeEventListener('on-change', this.onChangeEvent);
      field.removeEventListener('keypress', this.onEnterEvent);
    });
  },
  methods: {
    manageFields() {
      this.formFields =
        this.$refs.content.querySelectorAll('.form-field-holder');

      this.formFields.forEach((field) => {
        field.addEventListener('on-change', (e) =>
          this.onChangeEvent(e, field),
        );
        field.addEventListener('keypress', (e) => this.onEnterEvent(e));
      });
    },
    onChangeEvent(e, field) {
      const { value, error } = e.detail;
      this.values[field.name] = { value: value || '', error };
    },
    onEnterEvent(e) {
      if (e.key === 'Enter' && !this.disabled) this.$emit('submit');
    },
    validate() {
      this.formFields.forEach((field) => field.validate && field.validate());
    },
    reset(resetFieldsArray = []) {
      this.formFields.forEach((field) => {
        if (resetFieldsArray.includes(field.name) || !resetFieldsArray.length) {
          field.resetField();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.form-field-holder {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.form-field {
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  width: 100%;
  line-height: 20px;
  width: 100%;
  display: flex;

  &__no-border-radius {
    .form-field {
      border-radius: 0px !important;
    }
  }

  &__no-border-top-right-radius {
    .form-field {
      border-top-right-radius: 0px !important;
    }
  }

  &__no-border-top-left-radius {
    .form-field {
      border-top-left-radius: 0px !important;
    }
  }

  &__no-border-bottom-right-radius {
    .form-field {
      border-bottom-right-radius: 0px !important;
    }
  }

  &__no-border-bottom-left-radius {
    .form-field {
      border-bottom-left-radius: 0px !important;
    }
  }

  &--checkbox,
  &--radio {
    border: none !important;
  }

  .h-select__selected-option {
    line-height: 42px;
  }

  &__prepend,
  &__prepend {
    .h-select {
      border: none;
      min-height: 42px;
    }

    background-color: var(--gray-light);
  }

  &__prepend,
  &__append {
    padding: 12px 16px;
    color: var(--gray);
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60%;
  }

  &__prepend {
    /*!rtl:begin:ignore*/
    border-right: 1px solid var(--gray-border);
    /*!rtl:end:ignore*/
  }

  &__append {
    /*!rtl:begin:ignore*/
    border-left: 1px solid var(--gray-border);
    /*!rtl:end:ignore*/
  }

  &__input-wrapper {
    flex-grow: 1;
    display: flex;
  }

  &__input-holder {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 20px;
    flex-grow: 1;
  }

  &__textarea-holder {
    position: relative;
    padding: 12px 16px;
    flex-grow: 1;

    .form-field-label {
      top: 24px;
      left: 16px;
    }
  }

  &__select-holder {
    position: relative;
    display: flex;
    width: 100%;

    .h-select {
      border: none !important;
      text-align: left;
    }

    .form-field-label {
      left: 16px;
    }
  }

  &__select-wrapper {
    width: 100%;
    flex-grow: 1;
    position: relative;
  }

  &__icon {
    max-width: 22px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &--right {
      margin-right: 16px;
    }

    &--left {
      margin-left: 16px;
    }
  }

  &__input {
    border: none;
    padding: 0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: var(--dark);
    width: 100%;
    appearance: none;
    padding: 12px 16px;
    -moz-appearance: textfield;
    box-sizing: border-box;

    &:focus,
    &:hover {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }
  }

  &__textarea {
    border: none;
    padding: 0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: var(--dark);
    width: 100%;
    padding: 0;
    display: block;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  input[type='date'] {
    line-height: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 12px;
      /*!rtl:begin:ignore*/
      right: 16px;
      /*!rtl:end:ignore*/
      background: v-bind(calendarAsset);
      width: 22px;
      height: 22px;
      background-size: contain;
      pointer-events: none;
    }
  }

  &--focused {
    border: 2px solid var(--primary);

    .form-field__input-wrapper {
      .form-field__input {
        padding-right: 16px;
      }
    }
    .form-field__prepend,
    .form-field__append {
      .h-select {
        min-height: 42px;
      }
      .h-select__selected-option {
        line-height: 40px;
      }
    }

    .form-field__prepend {
      .h-select__selected-option {
        padding: 0 44px 0 15px;
      }
    }

    .form-field__append {
      .h-select__selected-option {
        padding: 0 43px 0 16px;
      }
      .h-select__select-image {
        right: 15px;
      }
    }

    .form-field__prepend {
      padding: 11px 15px;
      padding-right: 16px;
    }

    .form-field__append {
      padding: 11px 15px;
      padding-left: 16px;
    }

    input[type='date'] {
      &::after {
        top: 11px;
        right: 15px;
      }
    }

    .form-field__icon--right {
      margin-right: 15px;
    }

    .form-field__icon--left {
      margin-left: 15px;
    }

    .form-field__input {
      padding: 11px 15px;
    }

    .form-field__prepend + .form-field__input-holder {
      .form-field-label {
        left: 16.5px;
      }
      .form-field__input {
        padding: 11px 16px;
      }
    }

    .form-field-label {
      left: 15px;
    }

    .form-field__textarea-holder {
      padding: 11px 15px;

      .form-field-label {
        top: 23px;
        left: 15px;
      }
    }
  }

  &--error {
    border: 1px solid var(--danger);

    .form-field__prepend,
    .form-field__append {
      border-color: var(--danger);
    }
  }

  &--error-absolute {
    position: absolute;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: var(--gray-light);

    .form-field__input {
      background-color: var(--gray-light);
      color: var(--gray);
    }

    .form-field-label {
      background: linear-gradient(
        180deg,
        var(--light) 0%,
        var(--light) 10%,
        var(--gray-light) 100%
      );
    }
  }
}

.form-field-label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
  z-index: 10;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), width 0ms;
  pointer-events: none;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  margin-bottom: 0;

  @media only screen and (max-width: 767px) {
    max-width: 82%;
  }

  color: var(--gray);

  &__required {
    font-size: 14px;

    /*!rtl:raw:
      margin: 0;
    */
  }

  &--active,
  &--filled {
    transform: translate(-8px, -34px) scale(0.85);
    transform-origin: 0 0;
    padding: 0 4px;
    max-width: calc(130% - 16px);
    width: auto;

    background-color: var(--light);

    label {
      text-overflow: initial;
    }
  }

  &--active {
    color: var(--primary);
  }

  &--active.label-holder--select {
    color: var(--placeholder);
  }

  &--error {
    color: var(--danger);
  }
}

.form-field-error {
  display: block;
  text-align: left;

  font-size: 12px;
  color: var(--danger);
  padding-left: 16px;
  padding-right: 16px;
  line-height: 18px;
  margin-top: 4px;
}

.form-field-hint {
  @extend .form-field-error;
  color: var(--gray);

  display: flex;
  justify-content: space-between;

  &--right {
    justify-content: flex-end;
  }

  &__limit {
    color: var(--gray);
    margin-left: 12px;
    white-space: nowrap;
    min-width: 48px;
    text-align: right;
  }

  span {
    line-break: auto;
  }
}

.form-field-checkbox {
  &__holder {
    min-height: 40px;
    cursor: pointer;
    &--disabled {
      cursor: default;
    }
    &--rounded-border {
      border-radius: 8px;
      padding: 16px;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 1px var(--gray-border);
      &.radio__element--active {
        box-shadow: inset 0 0 0 2px var(--primary);
      }
    }
    &--greyed-out {
      background-color: var(--gray-light);
    }
  }

  &__label,
  &__item {
    display: inline-block;
    vertical-align: middle;
  }

  &__label {
    padding-left: 8px;
    white-space: normal;
  }

  &__description {
    padding-left: 33px;
  }
}

@media only screen and (max-width: 576px) {
  .form-field__input-wrapper {
    flex-wrap: wrap;
    width: 100%;
  }

  .form-field__append,
  .form-field__prepend {
    min-width: 100% !important;
    text-align: left;
  }

  .form-field__append {
    border-left: none !important;
    border-top: 1px solid var(--gray-border);
  }

  .form-field__prepend {
    border-right: none !important;
    border-bottom: 1px solid var(--gray-border);
  }
}
</style>
