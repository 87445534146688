import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  useGlobals,
  useHostingStatus,
  useProPanelRoutes,
  useSubscriptions,
  useAccessList,
  useImpersonation,
} from '@/composables';
import { websitesRepo } from '@/repositories';
import { useProfileStore, useWebsitesStore } from '@/stores';
import type { ProWebsiteType, Route, AmplitudeLocationTypes } from '@/types';
import {
  AmplitudeEvent,
  HIcon,
  Header,
  Hosting,
  Website,
  AmplitudeLocation,
} from '@/types';
import { getDomain, isHostingPlanUpgradable, toASCII } from '@/utils/helpers';
import {
  assignLocationAsync,
  isTemporaryDomain as getIsTemporaryDomain,
} from '@/utils/helpers/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { authRedirectService } from '@/utils/services/http/authRedirectService';

interface AmplitudeProperties {
  websiteId: string | number | null;
  websiteType: Website.Type;
  domain: string;
  location?: string;
  h5gBetaTest?: boolean;
}

type RedirectRoute =
  | Route.Websites
  | Route.HostingAdvanced
  | Route.HostingFiles
  | Route.HostingDomains
  | Route.HostingWordpress;

export const useWebsite = (websiteObject: ProWebsiteType) => {
  const store = useStore();
  const website = ref(websiteObject);

  const { amplitudeV2 } = useGlobals();

  const { isProPanelRoute } = useProPanelRoutes();
  const {
    isSuspended,
    nonPaymentSuspension,
    isSelfSuspension,
    canSelfUnsuspend,
    doesRequireCSHelp,
    getExpirationInDays,
  } = useHostingStatus(websiteObject);
  const { getAccessIdByClientId } = useAccessList();
  const router = useRouter();
  const { canRenewOrReactivateSubscription, canOrderBeReactivated } =
    useSubscriptions();
  const { enterImpersonationMode } = useImpersonation();

  const profileStore = useProfileStore();
  const websitesStore = useWebsitesStore();

  const resourceId = computed(
    () => website.value?.resource?.id || website.value?.resourceId,
  );

  const isOwnWebsite = computed(
    () => website.value.clientId === profileStore.account?.id,
  );

  const accessId = computed(() =>
    getAccessIdByClientId(websiteObject.resource.customerCustomId),
  );

  const isSubdomain = computed(
    () => website.value.vhostType === Hosting.Vhost.SUBDOMAIN,
  );

  const timeToSwitchCmsBack = computed(() => websiteObject.canSwitchBackUntil);

  const timeToSwitchCmsBackDifferences = computed(() => {
    const expireAt = dayjs(timeToSwitchCmsBack.value);
    const timeNow = dayjs();

    return {
      differenceInDays: expireAt.diff(timeNow, 'day') + 1,
      differenceInHours: expireAt.diff(timeNow, 'hour') + 1,
    };
  });

  const expiresAtDate = String(website.value.resource.expiresAt);
  const expirationInDays = getExpirationInDays(expiresAtDate) + 1;

  const timeLeftToSwitch = computed(() => {
    if (timeToSwitchCmsBackDifferences.value.differenceInDays === 1) {
      return timeToSwitchCmsBackDifferences.value.differenceInHours === 2
        ? 1
        : timeToSwitchCmsBackDifferences.value.differenceInHours;
    }

    return timeToSwitchCmsBackDifferences.value.differenceInDays;
  });
  const timePeriodLeft = computed(() => {
    if (timeToSwitchCmsBackDifferences.value.differenceInDays !== 1) {
      return timeLeftToSwitch.value === 1 ? 'day' : 'days';
    }

    return timeLeftToSwitch.value === 1 ? 'hour' : 'hours';
  });

  const domain = computed(() => {
    const subdomain =
      website.value?.parentDomain ?? getDomain(website.value?.website);

    return isSubdomain.value ? subdomain : website.value?.website;
  });

  const encodedDomain = computed(() => toASCII(domain.value));

  const canSwitchBackFromBuilder = computed(() =>
    Boolean(websiteObject.canSwitchBackUntil),
  );

  const isDomainChangeAvailable = computed(() => websiteObject.domainChange);

  const isChangingDomain = computed(
    () => websiteObject.action === Website.Action.CHANGING_DOMAIN,
  );

  const isBeingDeleted = computed(
    () =>
      websiteObject.action === Website.Action.REMOVING ||
      websitesStore.isWebsiteBeingRemoved(website.value.website),
  );

  const isWebsiteSuspendedDueToAbuse = computed(
    () =>
      website.value.resource?.reason === Hosting.SuspendReason.ABUSE ||
      website.value?.disabledReason?.toLocaleLowerCase() ===
        Hosting.SuspendReason.ABUSE,
  );

  const canDeleteWebsite = computed(() => {
    const canSwitchBackFromBuilder = Boolean(websiteObject.canSwitchBackUntil);
    const isAuthorizedToDelete = doesRequireCSHelp.value
      ? profileStore.isStaff
      : true;

    return (
      isAuthorizedToDelete &&
      !canSwitchBackFromBuilder &&
      !isWebsiteSuspendedDueToAbuse.value
    );
  });

  const isChangeDomainVisible = computed(() => {
    const canBuilderChangeDomain =
      isWebsiteTypeBuilder.value && !canSwitchBackFromBuilder.value;

    const canWordpressChangeDomain =
      isWordPressType.value &&
      isDomainChangeAvailable.value &&
      !isChangingDomain.value;

    return canBuilderChangeDomain || canWordpressChangeDomain;
  });

  const isWebsiteTypeBuilder = computed(() => getIsType(Website.Type.BUILDER));

  const isWordPressType = computed(() => getIsType(Website.Type.WORDPRESS));

  const isTemporaryDomain = computed(() => getIsTemporaryDomain(domain.value));

  const isSuspendedNonRenewable = computed(
    () => isSelfSuspension.value || doesRequireCSHelp.value,
  );

  const isSuspendedRenewable = computed(
    () =>
      canSelfUnsuspend.value &&
      canRenewOrReactivateSubscription(website.value.orderId),
  );

  const isWebsiteUpgradeable = computed(
    () =>
      !isSuspended.value &&
      isHostingPlanUpgradable(websiteObject.planName) &&
      !canOrderBeReactivated(websiteObject.orderId),
  );

  const isWebsiteRenewable = computed(() => {
    if (isSuspended.value && !nonPaymentSuspension.value) {
      return false;
    }

    return canRenewOrReactivateSubscription(website.value.orderId);
  });

  const isFavourite = computed(() => Number(website.value.isFavourite) > 0);

  const toggleFavourite = async () => {
    const { username, website: domain } = website.value;
    const headers = {
      [Header.USERNAME]: username,
      [Header.DOMAIN]: domain,
    };
    const [_, err] = !isFavourite.value
      ? await websitesRepo.addFavourite(headers)
      : await websitesRepo.removeFavourite(headers);

    if (err) {
      return;
    }

    website.value.isFavourite = isFavourite.value ? '0' : '1';
    amplitudeV2(
      isFavourite.value
        ? AmplitudeEvent.Websites.WEBSITES_FAVORITES_WEBSITE_ADDED
        : AmplitudeEvent.Websites.WEBSITES_FAVORITES_WEBSITE_REMOVED,
    );
  };

  const adminPanelLogo = computed(() => {
    if (getIsType(Website.Type.BUILDER)) {
      return HIcon.ICON_BUILDER;
    }
    if (getIsType(Website.Type.WORDPRESS)) {
      return 'icon-wordpress';
    }

    return '';
  });

  const isWebsiteBuilderMissingRedirectId = computed(() => {
    const isMissingId = isOwnWebsite.value
      ? typeof websiteObject?.resource.id !== 'number'
      : !accessId.value || !websiteObject.resource.id;

    return getIsType(Website.Type.BUILDER) && isMissingId;
  });

  const getIsType = (type: Website.Type) => website.value?.type === type;

  const sendEditAmplitudeEvent = () => {
    if (isProPanelRoute.value) {
      amplitudeV2(AmplitudeEvent.HostingerProSpace.EDIT_WEBSITE_ENTER, {
        location: AmplitudeLocation.Base.WEBSITES,
      });

      return;
    }
    sendAmplitudeEvent(AmplitudeEvent.Websites.WEBSITES_EDIT_WEBSITE_ENTER, {
      ...additionalAmplitudeProperties.value,
      h5gBetaTest: false,
      location: AmplitudeLocation.Base.WEBSITES,
    });
  };

  const sendManageAmplitudeEvent = (location?: string) => {
    if (isProPanelRoute.value) {
      amplitudeV2(AmplitudeEvent.HostingerProSpace.WEBSITE_MANAGE_ENTER);

      return;
    }

    sendAmplitudeEvent(AmplitudeEvent.Hosting.DASHBOARD_ENTER, {
      ...additionalAmplitudeProperties.value,
      location,
    });
  };

  const sendDeleteWebsiteDeletedAmplitudeEvent = () => {
    if (isProPanelRoute.value) {
      amplitudeV2(AmplitudeEvent.HostingerProSpace.DELETE_WEBSITE_DELETED);

      return;
    }
  };

  const sendDeleteWebsiteAmplitudeEvent = () => {
    if (isProPanelRoute.value) {
      amplitudeV2(AmplitudeEvent.HostingerProSpace.DELETE_WEBSITE_ENTER);

      return;
    }
    sendAmplitudeEvent(
      AmplitudeEvent.Websites.WEBSITES_DELETE_ENTER,
      additionalAmplitudeProperties.value,
    );
  };

  const createOrMigrateAWebsite = () => {
    sendAmplitudeEvent(
      AmplitudeEvent.Websites.WEBSITES_CREATE_OR_MIGRATE_CREATE,
    );
  };

  const sendFileManagerEnterAmplitudeEvent = () => {
    if (isProPanelRoute.value) {
      amplitudeV2(AmplitudeEvent.HostingerProSpace.PRO_FILE_MANAGER_ENTER);

      return;
    }
    sendAmplitudeEvent(AmplitudeEvent.Websites.WEBSITES_FILE_MANAGER_ENTER);
  };

  const additionalAmplitudeProperties = computed(() => {
    const { orderId, type, website: domain } = website.value;
    const websiteId = getIsType(Website.Type.BUILDER)
      ? resourceId.value
      : orderId;

    return {
      websiteId,
      websiteType: type,
      domain,
    };
  });

  const sendAmplitudeEvent = (
    eventName: string,
    properties?: AmplitudeProperties,
  ) => {
    amplitudeV2(eventName, {
      ...(properties ?? {}),
    });
  };

  const getFormattedHostingHeaders = () => {
    const { username, orderId, website: domain, clientId } = website.value;

    return {
      headers: {
        [Header.USERNAME]: username,
        [Header.DOMAIN]: domain,
        [Header.ORDER_ID]: orderId,
        [Header.CLIENT_ID]: clientId,
      },
      noHostingHeaders: true,
    };
  };

  const redirectToBuilder = async (
    amplitudeLocation?: AmplitudeLocationTypes,
  ) => {
    if (!isOwnWebsite.value) {
      const queryObject = {
        resourceId: websiteObject.resource.id,
      };

      await enterImpersonationMode({
        accessId: accessId.value,
        queryObject,
        isSameRedirectRoute: true,
      });

      return;
    }

    const result = await authRedirectService.redirectToBuilder(
      websiteObject.resource.id,
    );

    if (!result) {
      return;
    }

    const [{ data }, error] = result;

    if (error) {
      return;
    }

    const url = new URL(data.redirectUrl);

    if (amplitudeLocation) {
      url.searchParams.set(
        'amplitudeLocation',
        amplitudeLocation as unknown as string,
      );
    }

    await assignLocationAsync(url);
  };

  const redirectToPage = async (route: RedirectRoute) => {
    if (!encodedDomain.value) {
      errorLogger.addBreadcrumb({
        name: 'No domain to redirect to',
        data: website.value,
      });
    }

    const routeObject: RouteLocationRaw = {
      name: route,
      params: { domain: encodedDomain.value },
    };

    if (isOwnWebsite.value) {
      router.push(routeObject);

      return;
    }

    await enterImpersonationMode({
      accessId: accessId.value,
      queryObject: routeObject,
      isSameRedirectRoute: true,
    });
  };

  const fetchDomainPreview = async () => {
    const headers = {
      [Header.USERNAME]: website.value?.username,
      [Header.ORDER_ID]: website.value?.resource.referenceId,
    };

    store.dispatch('domainPreviewIndex', {
      domain: website.value?.website,
      params: { headers },
    });
  };

  return {
    isTemporaryDomain,
    adminPanelLogo,
    canDeleteWebsite,
    canSwitchBackFromBuilder,
    createOrMigrateAWebsite,
    domain,
    expirationInDays,
    getFormattedHostingHeaders,
    getIsType,
    isBeingDeleted,
    isChangeDomainVisible,
    isChangingDomain,
    isDomainChangeAvailable,
    isFavourite,
    isOwnWebsite,
    isSubdomain,
    isSuspendedNonRenewable,
    isSuspendedRenewable,
    isWebsiteBuilderMissingRedirectId,
    isWebsiteRenewable,
    isWebsiteUpgradeable,
    isWordPressType,
    redirectToBuilder,
    redirectToPage,
    resourceId,
    sendDeleteWebsiteAmplitudeEvent,
    sendDeleteWebsiteDeletedAmplitudeEvent,
    sendEditAmplitudeEvent,
    sendFileManagerEnterAmplitudeEvent,
    sendManageAmplitudeEvent,
    timeLeftToSwitch,
    timePeriodLeft,
    timeToSwitchCmsBack,
    timeToSwitchCmsBackDifferences,
    toggleFavourite,
    fetchDomainPreview,
  };
};
