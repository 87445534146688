import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route } from '@/types';

const emailAccountLimitsGuard: NavigationGuard = async (to, from, next) => {
  const { orderId } = to.params;

  const domain = to.params.domain as string;

  const emailOrder = await store.getters[
    'emails/getEmailOrderByResellerOrderId'
  ](orderId);

  if (emailOrder.domain !== domain.toLowerCase()) {
    return next({ name: Route.Base.NOT_FOUND });
  }

  if (!store.getters.getEmailsDetails.loaded) {
    await store.dispatch('fetchEmailDetails', {
      domain: to.params.domain,
      orderId,
      reload: true,
    });
  }

  const details = await store.getters.getEmailsDetails;
  const usedAccountNumber = details.data.domain.accountCount;

  if (usedAccountNumber >= emailOrder.seats) {
    return next({
      name: Route.Email.HMAIL_MANAGEMENT,
      params: { domain: to.params.domain },
    });
  }

  return next();
};

export default emailAccountLimitsGuard;
