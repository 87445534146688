<template>
  <div
    v-tooltip="tooltipText"
    class="chip"
    :class="additionalClasses"
    @click="$emit('select', value)"
  >
    <div class="d-flex align-items-center justify-content-center">
      <HpIcon
        v-if="icon"
        :icon="icon"
        :primary="!isDisabled"
        :gray="isDisabled"
        class="h-mr-8"
      />
      <slot v-if="noTrans" />
      <Trans v-else>
        <slot />
      </Trans>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    tooltip: String,
    noTrans: Boolean,
    isActive: Boolean,
    isDisabled: Boolean,
    icon: String,
  },
  emits: ['select'],
  computed: {
    tooltipText() {
      return this.tooltip ? this.$t(this.tooltip) : '';
    },
    additionalClasses() {
      return {
        active: this.isActive,
        disabled: this.isDisabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  display: inline-block;
  border-radius: 21px;
  padding: 4px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  color: var(--primary);
  border: 1px solid var(--gray-border);

  &:hover {
    background-color: var(--primary-light);
    border-color: var(--primary);
  }

  &.active {
    border-color: var(--gray-border);
    background-color: var(--primary-light);
  }
  &.disabled {
    cursor: default;
    color: var(--gray);
    border-color: var(--gray-border);
    background-color: unset;
  }
}
</style>
