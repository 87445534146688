import { toASCII } from '@/utils/helpers';

export default {
  state: {
    hMailOrders: [],
    loading: false,
  },
  mutations: {
    SET_HMAIL_ORDER(state, data) {
      state.hMailOrders = state.hMailOrders.filter(
        ({ domain }) => domain !== data.domain,
      );

      state.hMailOrders.push(data);
    },
  },
  getters: {
    getHMailDetails: (state) => (findDomain) =>
      state.hMailOrders.find(
        ({ domain }) =>
          toASCII(domain?.toLowerCase()) === toASCII(findDomain?.toLowerCase()),
      ),
  },
};
