<script setup lang="ts">
import HModalActions from '@/components/HModals/HModalActions.vue';
import { getServiceProviderForBrArticle } from '@/utils/services/supportArticleService';

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const openLearnMore = () => {
  window.open(getServiceProviderForBrArticle(), '_blank');
};
</script>

<template>
  <Trans tag="p" class="h-mt-8-minus">
    Your CPF/CNPJ is set up for another service provider at your
    <a href="https://registro.br/" target="_blank">Registro.BR</a>
    account. To register this domain at Hostinger, follow a few easy steps
    explained in the article.
  </Trans>
  <HModalActions
    class="h-mt-24"
    no-padding
    mobile-reverse
    @close="emits('close')"
  >
    <HButtonV1 primary no-margin @click="openLearnMore">Learn more</HButtonV1>
  </HModalActions>
</template>
