<script setup lang="ts">
import { ref, watch } from 'vue';

import { DEFAULT_ITEMS_PER_PAGE_SIZES } from '@/utils/services/paginationService';

const props = defineProps<{
  hPerPage?: number;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  'update-per-page': [newValue: number];
}>();

const perPage = ref(5);

watch(perPage, (value) => {
  emits('update-per-page', value);
});

watch(
  () => props.hPerPage,
  (value) => {
    if (value) {
      perPage.value = value;
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="h-data-table-per-page">
    {{ $t('Items per page:') }}
    <select
      v-model="perPage"
      data-qa="h-data-table-per-page-select"
      class="h-data-table-per-page__select"
      :disabled="isDisabled"
    >
      <option
        v-for="value in DEFAULT_ITEMS_PER_PAGE_SIZES"
        :key="value"
        :value="value"
      >
        {{ value }}
      </option>
    </select>
  </div>
</template>

<style lang="scss">
@import '@/sass/components/_h-data-table';
</style>
