<script setup lang="ts">
import type { Alignment } from '@floating-ui/dom';
import {
  useFloating,
  autoPlacement,
  shift,
  offset,
  arrow,
  limitShift,
  autoUpdate,
  size,
} from '@floating-ui/vue';
import { computed, ref, watch, onMounted } from 'vue';

import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

export type Placement = 'bottom' | 'top' | 'left' | 'right' | 'right-start';

export interface HPopoverProps {
  isActive: boolean;
  placement?: Placement;
  variant?: 'default' | 'compact' | 'small';
  offsetValue?: number;
  customAutoPlacementOptions?: {
    allowedPlacements: Placement[];
    alignment: Alignment | null;
  };
  backgroundColor?: string;
}

const props = withDefaults(defineProps<HPopoverProps>(), {
  placement: 'bottom',
  variant: 'default',
  offsetValue: 10,
  backgroundColor: 'ghost-white',
});

const OFFSET_PADDING = {
  top: 80,
  left: 20,
  right: 20,
  bottom: 20,
};

const POPOVER_BODY_RADIUS = 16;

const popover = ref<HTMLElement | null>(null);
const popoverBody = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);

const isMounted = ref(false);

onMounted(() => (isMounted.value = true));

const backgroundColor = computed(() => {
  if (!props.backgroundColor) return '';

  return wrapInCssVar(props.backgroundColor);
});

const popoverRadiusInPixels = computed(() => `${POPOVER_BODY_RADIUS}px`);

const { floatingStyles, middlewareData, placement } = useFloating(
  popover,
  popoverBody,
  {
    middleware: [
      offset(props.offsetValue),
      shift({
        limiter: limitShift(),
        padding: OFFSET_PADDING,
      }),
      autoPlacement({
        padding: OFFSET_PADDING,
        alignment: 'start',
        allowedPlacements: ['right', 'left', 'bottom', 'top'],
        ...props.customAutoPlacementOptions,
      }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
      arrow({ element: floatingArrow, padding: POPOVER_BODY_RADIUS }),
    ],
    placement: props.placement,
    whileElementsMounted: autoUpdate,
  },
);

const getArrowPosition = (placement: Placement, x?: number, y?: number) => {
  if (placement === 'top') {
    return {
      left: `${x}px`,
      bottom: `${-10}px`,
      top: 'unset',
    };
  }

  if (placement === 'bottom') {
    return {
      left: `${x}px`,
      top: `${-10}px`,
      bottom: 'unset',
    };
  }

  if (placement === 'left') {
    return {
      right: `${-10}px`,
      top: `${y}px`,
      left: 'unset',
    };
  }

  if (placement === 'right') {
    return {
      left: `${-10}px`,
      top: `${y}px`,
      right: 'unset',
    };
  }
};

watch(
  [middlewareData, placement],
  ([newData, newPlacement]) => {
    if (newData.arrow && floatingArrow.value) {
      const { x, y } = newData.arrow;

      const calculatedPosition = getArrowPosition(
        newPlacement as Placement,
        x,
        y,
      );

      Object.assign(floatingArrow.value.style, calculatedPosition);
    }
  },
  { deep: true },
);
</script>

<template>
  <div ref="popover" class="h-popover__wrapper">
    <slot name="trigger" />
    <Teleport v-if="isActive && isMounted" :to="'#app'">
      <div
        ref="popoverBody"
        class="h-popover-body"
        :class="`h-popover-body--${variant}`"
        :style="floatingStyles"
      >
        <div ref="floatingArrow" class="h-popover-body__arrow" />
        <slot name="content" />
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
$backgroundColor: v-bind('backgroundColor');

.h-popover-body {
  position: absolute;
  text-align: left;
  background-color: $backgroundColor;
  box-shadow: 0px 0px 49px rgba(29, 30, 32, 0.16);
  z-index: var(--z-index-6);
  visibility: visible;
  border-radius: v-bind(popoverRadiusInPixels);

  &--default {
    width: 490px;
    padding: 24px 32px;
  }

  &--compact {
    width: 420px;
    padding: 16px;
  }

  &--small {
    width: 320px;
    padding: 24px;
  }

  &__arrow {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: $backgroundColor;
    transform: rotate(45deg);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (max-width: 576px) {
  .h-popover-body {
    width: 90vw;
  }
}
</style>
