<script setup lang="ts">
import HDataTable from '@/components/HDataTable/HDataTable.vue';
import HPortlet from '@/components/Portlet/HPortlet.vue';

type Props = {
  results: any[];
};

defineProps<Props>();

const tableHeader = { url: { text: 'URL' } };
</script>

<template>
  <HPortlet no-body-padding>
    <HDataTable
      :striped="false"
      :header="tableHeader"
      :body="results"
      no-search
    >
      <template #[`items`]="{ item }">
        <tr>
          <td>{{ item.url }}</td>
        </tr>
      </template>
    </HDataTable>
  </HPortlet>
</template>
