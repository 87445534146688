import type { CookieAttributes } from 'js-cookie';
import jsCookie from 'js-cookie';
import { reactive, ref, computed } from 'vue';

export const useCookies = (cookieName?: string) => {
  const cookies = reactive(jsCookie.get());

  const setCookies = (
    name: string,
    value: string,
    options?: CookieAttributes,
  ) => {
    jsCookie.set(name, value, options);
    cookies[name] = value;
  };

  const removeCookies = (name: string, options?: CookieAttributes) => {
    jsCookie.remove(name, options);
    delete cookies[name];
  };

  const cookieExists = (name: string) => !!jsCookie.get(name);

  const cookie = ref(jsCookie.get(cookieName || ''));

  const isCookieValueTruthyNumber = computed(() => !!Number(cookie.value));

  const setCookie = (value: string, options?: CookieAttributes) => {
    if (!cookieName) return;

    jsCookie.set(cookieName, value, options);
    cookie.value = value;
  };

  const removeCookie = (options?: CookieAttributes) => {
    if (!cookieName) return;

    jsCookie.remove(cookieName, options);
    cookie.value = undefined;
  };

  return {
    cookies,
    cookie,
    isCookieValueTruthyNumber,
    cookieExists,
    setCookies,
    setCookie,
    removeCookies,
    removeCookie,
  };
};
