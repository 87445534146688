<template>
  <div class="h-data-table-pagination h-data-table-pagination-in-line">
    <Trans
      class="h-data-table-pagination__item-count"
      tag="div"
      :translate-params="{
        from: fromNumber,
        to,
        total,
      }"
    >
      {from}-{to} of {total}
    </Trans>
    <div class="h-data-table-pagination__arrow-wrapper" @click="goLeft">
      <HpIcon
        icon="ic-chevron-left"
        class="h-data-table-pagination__arrow"
        static-view-box
        :class="{
          'h-data-table-pagination__arrow--disabled': disabledBack,
        }"
      />
    </div>

    <div class="h-data-table-pagination__arrow-wrapper" @click="goRight">
      <HpIcon
        icon="ic-chevron-right"
        class="h-data-table-pagination__arrow"
        static-view-box
        :class="{
          'h-data-table-pagination__arrow--disabled': disabledNext,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HDataTablePagination',
  emits: ['update:hPage'],
  props: {
    hPage: Number,
    maxPages: Number,
    totalPages: Number,
    from: Number,
    to: Number,
    total: Number,
    maxNextPage: Number,
    isDisabled: Boolean,
  },
  data() {
    return {
      page: 1,
    };
  },
  created() {
    this.page = this.hPage || 1;
  },
  computed: {
    fromNumber() {
      return this.from + 1;
    },
    toNumber() {
      if (this.page < this.totalPages) return this.to + 1;

      return this.to;
    },
    disabledBack() {
      return this.page === 1 || this.hPage === 1 || this.isDisabled;
    },
    disabledNext() {
      return (
        this.page === this.totalPages ||
        this.hPage === this.totalPages ||
        this.page === this.maxNextPage ||
        this.isDisabled
      );
    },
  },
  methods: {
    goLeft() {
      if (this.disabledBack) return;
      if (this.page > 1) this.page--;
    },
    goRight() {
      if (this.disabledNext) return;
      if (this.page < this.totalPages) this.page++;
    },
  },
  watch: {
    page(value) {
      this.$emit('update:hPage', value);
    },
    hPage(value) {
      if (this.page !== value) {
        this.page = value;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/components/_h-data-table';

.h-data-table-pagination-in-line {
  padding: 8px 0 8px 0;
  .h-data-table-pagination__arrow-wrapper {
    padding: unset !important;
    line-height: normal;
  }
  .h-data-table-pagination__pagination {
    padding: 12px;
  }
  .h-data-table-pagination__arrow {
    fill: var(--primary);

    &--disabled {
      fill: var(--gray);
    }
  }
}
</style>
