<template>
  <div
    id="hpanel_tracking-performance_drop_banner"
    class="performance-drop-banner"
  >
    <div class="d-flex align-items-center">
      <div class="performance-drop-banner__icon">
        <HpIcon warning-dark icon="icon-error" />
      </div>
      <span v-trans class="h-mr-16">
        v2.your.hosting.plan.is.experiencing.a.performance.drop
      </span>
    </div>
    <span v-trans class="performance-drop-banner__more-info" @click="goToUsage">
      v2.more.information
    </span>
    <div
      id="hideOptions"
      v-click-outside:hideOptions="closeHideOptions"
      class="performance-drop-banner__close"
    >
      <span @click="showHideOptions = !showHideOptions">
        <HpIcon gray icon="ic-clear" view-box="-2 -2 24 24" />
      </span>
      <HOptionsList
        class="performance-drop-banner__close-options"
        :is-open="showHideOptions"
        :options="hideNotificationOptions"
        @selected-option="
          hideNotification($store.getters.getCurrentOrder, $event.duration)
        "
      />
    </div>
  </div>
</template>

<script>
//JSON-slugs-migrated
import HOptionsList from '@/components/HOptionsList';
import reminderMixin from '@/utils/mixins/reminderMixin';

export default {
  name: 'PerformanceDropBanner',
  components: { HOptionsList },
  mixins: [reminderMixin],
  created() {
    const orderId = this.$store.getters.getCurrentOrder?.id;
    const isHiddenByUser = this.checkIsNotificationHidden(orderId);
    this.$emit(
      'banner-visibility',
      this.$store.getters.getOrderLveFaultsExist(orderId) && !isHiddenByUser,
    );
  },
  emits: ['banner-visibility'],
  methods: {
    goToUsage() {
      this.$router.push({
        name: 'order_order_usage',
        params: {
          domain: this.$route.params.domain,
        },
      });
    },
  },
  watch: {
    isHidden(value) {
      if (value) this.$emit('banner-visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/components/header-promotion';

.performance-drop-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  padding: 0 40px;
  background: var(--light);

  @media (min-width: 576px) {
    flex-direction: row;
  }

  &__more-info {
    cursor: pointer;
    font-weight: 700;
    white-space: nowrap;
    color: var(--primary);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__close-options {
    width: 200px;
    position: absolute;
    right: 0;
    top: 28px;
  }

  &__icon {
    margin-right: 8px;
    width: 24px;
  }
}
</style>
