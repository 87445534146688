import hLocalStorage from '@/utils/services/localStorageService';

const ITEMS_PER_PAGE = 'itemsPerPage';

export const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEFAULT_ITEMS_PER_PAGE_SIZES = [5, 10, 25, 50, 75, 100];

export const setItemsPerPage = (data: number) => {
  hLocalStorage.setValue(ITEMS_PER_PAGE, data);
};

export const getItemsPerPage = (
  defaultValue: number = DEFAULT_ITEMS_PER_PAGE,
): number => hLocalStorage.getValue(ITEMS_PER_PAGE) || defaultValue;
