import { domainsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { toASCII } from '@/utils/helpers';

const initial = {
  data: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_SUBDOMAINS(state, { data, request_domain }) {
      setForDomain(state, request_domain, 'data', data);
      setForDomain(state, request_domain, 'loaded', true);
    },
    REMOVE_SUBDOMAIN(state, subdomain) {
      const data = getStateVariable(state, 'data');
      const subdomains = data.filter((item) => item.subdomain !== subdomain);
      setForDomain(state, null, 'data', subdomains);
    },
  },
  getters: {
    getHostingSubdomains: (state) => getStateVariable(state),
  },
  actions: {
    async fetchHostingSubdomains({ commit }) {
      const [{ data, requestDomain }] = await domainsRepo.getSubdomains();

      commit('SET_SUBDOMAINS', { data, request_domain: requestDomain });
      commit('SET_ACCOUNT_SUBDOMAINS', {
        acc_domain: requestDomain,
        subdomains: data,
      });
    },
    async addHostingSubdomain({ dispatch }, subdomainData) {
      const [data, error] = await domainsRepo.addSubdomain(subdomainData);

      if (!error) {
        await dispatch('fetchHostingSubdomains');
        await dispatch('hostingOrdersDetailsIndex');
      }

      return [data, error];
    },
    async removeHostingSubdomain(
      { commit, getters, dispatch },
      { domain, subdomain },
    ) {
      const [data, error] = await domainsRepo.deleteSubdomain({
        params: {
          domain: toASCII(domain),
          subdomain,
        },
      });

      if (!error) {
        commit('REMOVE_SUBDOMAIN', subdomain);
        commit('SET_ACCOUNT_SUBDOMAINS', {
          acc_domain: domain,
          subdomains: getters.getHostingSubdomains.data,
        });
        await dispatch('fetchHostingSubdomains');
        await dispatch('hostingOrdersDetailsIndex');
      }

      return [data, error];
    },
  },
};
