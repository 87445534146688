import dayjs from 'dayjs';
import type {
  RouteLocationNamedRaw,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

import { EmailLegacyPlans } from '@/data/emails/emailPlans';
import { router } from '@/router';
import store from '@/store';
import {
  EMAIL_LOGS_STATUS,
  Email,
  Route,
  HBillingSubscriptionResourceType,
  HRESOURCES_TYPE,
} from '@/types';
import type {
  AmplitudeLocation,
  BillingSubscription,
  HResourceType,
  IEmailLogsFilterRequestParams,
  IEmailLogsFilters,
} from '@/types';
import { toASCII, toLocalTime, toUnicode } from '@/utils/helpers/helpers';
import { DEFAULT_DATE_TIME_FORMAT } from '@/utils/helpers/timeHelpers';

export const getEmailOrderId = (domain?: string): { orderId: string } => {
  const orderId = (parseInt(router.currentRoute.value.params.id as string) ||
    router.currentRoute.value.params.orderId) as string;
  const currentEmailOrderId = store?.getters.getEmailCurrentOrderId;
  const orderIdFromQuery = String(
    parseInt(router.currentRoute.value.query.orderId as string),
  );

  if (currentEmailOrderId && typeof currentEmailOrderId !== 'object') {
    return { orderId: currentEmailOrderId };
  }

  if (orderId) return { orderId };

  if (domain) {
    const orderIdByDomain = store.getters['emails/getPremiumMail'](
      Email.EmailProvider.HOSTINGER,
      domain,
    )?.orderId as string;

    if (orderIdByDomain) return { orderId: orderIdByDomain };
  }

  return { orderId: orderIdFromQuery };
};

export const transformPlanName = (plan: string) => {
  switch (plan) {
    case 'pro':
    case 'business-email':
    case 'Business Starter':
      return 'business_s';
    case 'premium':
    case 'Business Premium':
      return 'business_l';
    case 'free':
    case 'free-email':
      return 'free';
    case 'Google Workspace Email':
      return 'basic';
    default:
      return plan;
  }
};

export const transformServiceName = (serviceName: string) => {
  switch (serviceName) {
    case 'flock_mail':
    case 'titan_mail':
      return 'titan_email';
    case 'google_gsuite':
      return 'google_email';
    case 'hostinger_mail':
      return 'hostinger_email';
    default:
      return serviceName;
  }
};

export const isLegacyEmailPlan = (
  planName: Email.LegacyPlan | string,
): boolean => EmailLegacyPlans.includes(planName as Email.LegacyPlan);

export const isTopPlan = (plan: string) => {
  const EMAIL_HIGHEST_PLANS = [
    Email.Plan.HOSTINGER_BUSINESS_L,
    Email.Plan.TITAN_ULTRA_EMAIL,
    Email.Plan.GOOGLE_WORKSPACE_BUSINESS_PLUS,
  ] as const;

  return EMAIL_HIGHEST_PLANS.includes(plan);
};

export const isFreePlan = (plan: Email.Plan | string) => {
  const HOSTINGER_EMAIL_FREE_PLANS = [
    Email.Plan.HOSTINGER_BUSINESS_FREE,
    Email.Plan.HOSTINGER_STARTER,
    Email.Plan.HOSTINGER_PREMIUM,
    Email.Plan.TITAN_FREE,
  ] as const;

  return HOSTINGER_EMAIL_FREE_PLANS.includes(plan);
};

export const isTrialPlan = (planKey: string) =>
  planKey === Email.Plan.HOSTINGER_TRIAL;

const hasWwwInHostname = (domain: string) => domain.startsWith('www.');

export const removeDomainPrefix = (domain: string) => {
  if (hasWwwInHostname(domain) && domain.split('.').length >= 3) {
    return domain.replace('www.', '');
  }

  return domain;
};

export const isCpanelEmailHostingDashboard = (routePath?: string): boolean => {
  const regex = new RegExp(Route.EmailCpanel.BASE_PATH, 'gi');

  const fullPath = routePath ?? router.currentRoute.value.fullPath;

  return !!fullPath.match(regex);
};

export const getWebmailLink = () =>
  `${process.env.VITE_HOSTINGER_WEBMAIL_URI}?clearSession=true`;

export const getWebmailLinkForAccount = (accountName: string) =>
  `${process.env.VITE_HOSTINGER_WEBMAIL_URI}?clearSession=true&_user=${accountName}`;

export const formatEmailLogValue = (
  value: string | number | { value: string | number },
) => {
  let tempValue = value;

  if (typeof tempValue === 'object') {
    tempValue = tempValue.value;
  } else if (typeof tempValue === 'string') {
    const isTimestamp =
      /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)(.{5})$/.test(tempValue);

    if (isTimestamp) {
      tempValue = toLocalTime(new Date(tempValue), DEFAULT_DATE_TIME_FORMAT);
    }
  }

  if (tempValue === '') {
    tempValue = '-';
  }

  return typeof tempValue === 'string' ? toUnicode(tempValue) : tempValue;
};

const formatDate = (date: ReturnType<typeof dayjs>) => {
  if (!date.isValid()) {
    return '';
  }

  // Current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00'
  return date.format();
};

export const convertToEmailLogsFilterParams = (
  filters: IEmailLogsFilters,
): IEmailLogsFilterRequestParams => {
  const params = { ...filters } as Partial<IEmailLogsFilterRequestParams>;

  if (filters.fromDate) {
    params.fromDate = formatDate(dayjs(filters.fromDate).startOf('day'));
  }

  if (filters.toDate) {
    params.toDate = formatDate(dayjs(filters.toDate).endOf('day'));
  }

  if (filters.status === EMAIL_LOGS_STATUS.HAS_DELETIONS) {
    params.hasDeletions = true;
    params.status = undefined;
  }

  params.account = toASCII(filters.account ?? '');

  return params as IEmailLogsFilterRequestParams;
};

export const adjustEmailPlanTitle = (subscription: BillingSubscription) => {
  if (
    subscription.resourceType === HBillingSubscriptionResourceType.EMAIL &&
    Array.isArray(subscription?.items)
  ) {
    return {
      ...subscription,
      items: subscription.items.map((item) => {
        const isPlan = item.itemType === 'plan';
        const hasEmailSuffix = !!item.name?.endsWith(' Email');

        if (isPlan && !hasEmailSuffix) {
          return { ...item, name: `${item.name} Email` };
        }

        return item;
      }),
    };
  }

  return subscription;
};

export const getOriginRoute = (
  route: RouteLocationNormalizedLoaded,
  domain: string,
): RouteLocationNamedRaw => {
  const name =
    (route.query.from as string | undefined) ?? Route.Email.HMAIL_MANAGEMENT;

  return {
    name,
    params: {
      domain: toASCII(domain),
    },
  };
};

export const setLocationParamForOverviewPage = (
  route: RouteLocationNamedRaw,
  location: AmplitudeLocation.Email,
) => {
  if (
    [Route.Email.HMAIL_MANAGEMENT, Route.Email.EMAIL_OVERVIEW].includes(
      route.name as Route.Email,
    )
  ) {
    route.query = {
      ...route.query,
      location,
    };
  }
};

export const getProductByServiceProvider = (
  serviceProvider: Email.EmailProvider,
) => {
  const providerToProductMap = {
    [Email.EmailProvider.GOOGLE]: Email.Product.GOOGLE_WS,
    [Email.EmailProvider.TITAN]: Email.Product.TITAN_MAIL,
    [Email.EmailProvider.HOSTINGER]: Email.Product.HOSTINGER_MAIL,
  };

  return providerToProductMap[serviceProvider];
};

export const getProviderTitleByKey = (key: Email.EmailProvider) => {
  const providersMap = {
    [Email.EmailProvider.GOOGLE]: 'Google Workspace',
    [Email.EmailProvider.TITAN]: 'Titan',
    [Email.EmailProvider.HOSTINGER]: 'Hostinger',
  };

  return providersMap[key];
};

export const getPlanTitleWithProvider = (
  planName: string,
  provider: Email.EmailProvider,
) => {
  if (provider === Email.EmailProvider.GOOGLE) {
    return planName;
  }

  return `${getProviderTitleByKey(provider)} ${planName}`;
};

export const isEmailResource = (type: HResourceType) =>
  (
    [
      HRESOURCES_TYPE.EMAIL,
      HRESOURCES_TYPE.TITAN_MAIL,
      HRESOURCES_TYPE.GOOGLE_WORKSPACE,
    ] as HResourceType[]
  ).includes(type);
