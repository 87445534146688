<template>
  <p v-trans class="plan-boost-container">Boosting is activated</p>
</template>
<style lang="scss" scoped>
.plan-boost-container {
  color: var(--light);
  background: var(--success);
  padding: 0px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
  max-width: min-content;
  white-space: nowrap;
  text-transform: uppercase;
}
</style>
