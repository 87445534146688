import cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { errorLogger } from '@/utils/services/errorLogging';

const DEVICE_ID_COOKIE = 'hostingerDeviceId';
const DEVICE_ID_COOKIE_CONFIG = {
  path: '/',
  domain: process.env.VITE_COOKIE_DOMAIN,
  expires: 365,
};

const syncDeviceIdWithCookie = () => {
  const newDeviceId = uuidv4();
  try {
    cookies.set(DEVICE_ID_COOKIE, newDeviceId, DEVICE_ID_COOKIE_CONFIG);
  } catch (e) {
    errorLogger.logError(`Error setting hostingerDeviceId cookie, error: ${e}`);
  }

  return newDeviceId;
};

const getDeviceId = () => {
  let deviceId;
  try {
    deviceId = cookies.get(DEVICE_ID_COOKIE);
  } catch (e) {
    errorLogger.logError(`Error getting hostingerDeviceId cookie, error: ${e}`);
  }
  if (deviceId) {
    return deviceId;
  }

  return syncDeviceIdWithCookie();
};

export default {
  getDeviceId,
};
