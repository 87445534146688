export const MONARX_SUPPORTED_OS = [
  'AlmaLinux 8',
  'AlmaLinux 9',
  'CentOS 6',
  'CentOS 7',
  'CentOS 8',
  'Debian 10',
  'Debian 11',
  'Debian 12',
  'Rocky Linux 8',
  'Rocky Linux 9',
  'Ubuntu 18.04',
  'Ubuntu 20.04',
  'Ubuntu 22.04',
  'Ubuntu 23.04',
  'Ubuntu 24.04',
];

export const MONARX_SIZE = '15.4MB';
