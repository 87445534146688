import { whHotlinkProtectionRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const initial = {
  directRequests: false,
  enabled: false,
  redirect: null,
  redirectProtocol: 'http://',
  type: [],
  availableTypes: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingOtherHotlinkProtectionDirectRequests(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'directRequests', data);
    },
    setHostingOtherHotlinkProtectionEnabled(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'enabled', data);
    },
    setHostingOtherHotlinkProtectionRedirect(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'redirect', data);
    },
    setHostingOtherHotlinkProtectionRedirectProtocol(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'redirectProtocol', data);
    },
    setHostingOtherHotlinkProtectionRedirectType(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'type', data);
    },
    setHostingOtherHotlinkProtectionAvailableTypes(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'availableTypes', data);
    },
    setHostingOtherHotlinkProtectionLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingOtherHotlinkProtectionDirectRequests: (state) =>
      getStateVariable(state, 'directRequests'),
    getHostingOtherHotlinkProtectionEnabled: (state) =>
      getStateVariable(state, 'enabled'),
    getHostingOtherHotlinkProtectionRedirect: (state) =>
      getStateVariable(state, 'redirect'),
    getHostingOtherHotlinkProtectionRedirectProtocol: (state) =>
      getStateVariable(state, 'redirectProtocol'),
    getHostingOtherHotlinkProtectionRedirectType: (state) =>
      getStateVariable(state, 'type'),
    getHostingOtherHotlinkProtectionAvailableTypes: (state) =>
      getStateVariable(state, 'availableTypes'),
    getHostingOtherHotlinkProtectionLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingOtherHotlinkProtectionIndex({ commit }) {
      const [{ data, requestDomain }, error] =
        await whHotlinkProtectionRepo.getHotlinkProtection(
          getCurrentOrderDetails(),
        );

      if (data) {
        commit('setHostingOtherHotlinkProtectionDirectRequests', {
          data: data.allowDirectRequests,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionEnabled', {
          data: data.enabled,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionRedirect', {
          data: data.redirect,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionRedirectProtocol', {
          data: data.redirectProtocol,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionRedirectType', {
          data: data.extensions,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionAvailableTypes', {
          data: data.availableExtensions,
          requestDomain,
        });
        commit('setHostingOtherHotlinkProtectionLoaded', {
          data: true,
          requestDomain,
        });
      }

      return [{ data, requestDomain }, error];
    },
    async hostingOtherHotlinkProtectionStore({ dispatch }, payload) {
      const [{ data }, error] =
        await whHotlinkProtectionRepo.patchHotlinkProtection(
          getCurrentOrderDetails(),
          payload,
        );

      if (data) {
        toastr.s(i18n.t('Your link protection successfully updated'));
      } else {
        toastr.e(error.response.data.message);
      }

      dispatch('hostingOtherHotlinkProtectionIndex');

      return [{ data }, error];
    },
    async hostingOtherHotlinkProtectionDestroy({ dispatch }) {
      const [{ data }] = await whHotlinkProtectionRepo.destroyHotlinkProtection(
        getCurrentOrderDetails(),
      );

      dispatch('hostingOtherHotlinkProtectionIndex');

      return [{ data }];
    },
  },
};
