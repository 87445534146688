import { Header, Website, LISTING_MODE_PARAMETER } from '@/types';
import type {
  DisplayProWebsitesRequestParams,
  IWebsiteMonitoring,
  IWebsiteMonitoringPutRequestParams,
  IWebsiteOwnershipResponse,
  IWebsiteOwnershipRequestData,
  RequestConfig,
  WebsiteType,
  IOwnershipTransferEligibilityResponse,
  IOwnershipTransfer,
  OwnershipTransferListingParam,
  IWebsiteTransferAcceptRequestData,
  PartialHeaders,
  IChangeWebsiteDomainRequest,
} from '@/types';
import { toASCII, hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  urlV2: `${process.env.VITE_API_REST}/v2/website`,
  urlV3: `${process.env.VITE_API_REST}/v3/website`,

  async getList(page: number, perPage = 7, search = '') {
    return await hAsync<{
      data: WebsiteType[];
      page: number;
      total: number;
      totalPages: number;
    }>(
      http.get(`${this.urlV2}/list`, {
        params: { page, perPage, search: toASCII(search) },
      }),
    );
  },
  async getProList({
    page = 1,
    search = '',
    perPage = 5,
    filterOwnership = Website.Filter.ALL,
    filterFavourite = false,
    filterOrders = null as null | string[],
    filterTags = [] as string[],
  } = {}) {
    return await hAsync<{
      data: any;
      page: number;
      total: number;
      perPage?: number;
      totalPages: number;
    }>(
      http.get(`${this.urlV2}/list-pro`, {
        params: {
          page,
          perPage,
          search: toASCII(search),
          filterOwnership,
          filterFavourite: filterFavourite ? 1 : null,
          filterOrders,
          filterTags,
        },
      }),
    );
  },

  async deleteWebsite(domain: string, config: RequestConfig) {
    return await hAsync(
      http.delete(`${this.urlV2}`, {
        data: { domain },
        domain,
        ...config,
      }),
    );
  },

  async putDisplayInProList(websites: DisplayProWebsitesRequestParams) {
    return await hAsync<boolean>(
      http.put(`${this.urlV3}/flags/bulk/display-in-pro-list`, websites),
    );
  },

  async getWebsitesMonitoringStatus() {
    return await hAsync<IWebsiteMonitoring[]>(
      http.get(`${this.urlV3}/flags/monitoring`),
    );
  },

  async putWebsiteMonitoringStatus({
    params,
    headers,
  }: IWebsiteMonitoringPutRequestParams) {
    return await hAsync<boolean>(
      http.put(`${this.urlV3}/flags/monitoring`, null, {
        params,
        headers: {
          [Header.USERNAME]: headers.username,
          [Header.DOMAIN]: headers.vhost,
        },
        noHostingHeaders: true,
      }),
    );
  },

  async getOwnershipTransferEligibility(params: { email: string }) {
    return await hAsync<IOwnershipTransferEligibilityResponse>(
      http.get(`${this.urlV3}/ownership/transfer/eligibility`, { params }),
    );
  },

  async postTransferRequest({
    data,
    config,
  }: {
    data: IWebsiteOwnershipRequestData;
    config: RequestConfig;
  }) {
    return await hAsync<IWebsiteOwnershipResponse>(
      http.post(`${this.urlV3}/ownership/transfer/token`, data, config),
    );
  },

  async getOwnershipTransferList(
    listingMode: OwnershipTransferListingParam = LISTING_MODE_PARAMETER.ALL,
  ) {
    return await hAsync<IOwnershipTransfer[]>(
      http.get(`${this.urlV3}/ownership/transfer/list`, {
        params: { listingMode },
      }),
    );
  },

  async postTransferRequestAccept({
    data,
    config,
  }: {
    data: IWebsiteTransferAcceptRequestData;
    config: RequestConfig;
  }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/ownership/transfer/accept`, data, {
        ...config,
        noHostingHeaders: true,
      }),
    );
  },

  async getTags() {
    return await hAsync<{ name: string }[]>(http.get(`${this.urlV3}/tag`));
  },

  async postTag(name: string) {
    return await hAsync<boolean>(
      http.post(
        `${this.urlV3}/tag`,
        { name },
        {
          hideToastr: true,
        },
      ),
    );
  },

  async deleteTag(name: string) {
    return await hAsync<boolean>(
      http.delete(`${this.urlV3}/tag`, { data: { name } }),
    );
  },

  async patchTag(name: string, newName: string) {
    return await hAsync<boolean>(
      http.patch(
        `${this.urlV3}/tag`,
        { name, newName },
        {
          hideToastr: true,
        },
      ),
    );
  },

  async assignTag(tag: string, headers: PartialHeaders) {
    return await hAsync<boolean>(
      http.post(
        `${this.urlV3}/tag/assignment`,
        { tagName: tag },
        {
          noHostingHeaders: true,
          headers,
        },
      ),
    );
  },

  async deleteAssignedTag(tag: string, headers: PartialHeaders) {
    return await hAsync<boolean>(
      http.delete(`${this.urlV3}/tag/assignment`, {
        noHostingHeaders: true,
        headers,
        data: { tagName: tag },
      }),
    );
  },

  async changeWebsiteDomain(domain: string, newDomain: string) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/change-domain`, { domain, newDomain }),
    );
  },
  async changeWebsiteDomainWithHeaders(
    { domain, newDomain }: IChangeWebsiteDomainRequest,
    headers: { username: string; orderId: string },
  ) {
    return await hAsync<boolean>(
      http.post(
        `${this.urlV3}/change-domain`,
        { domain, newDomain },
        {
          domain,
          noHostingHeaders: true,
          headers: {
            [Header.USERNAME]: headers.username,
            [Header.ORDER_ID]: headers.orderId,
            [Header.DOMAIN]: domain,
          },
        },
      ),
    );
  },

  async getCmsDetect(url: string, directory = '/') {
    return await hAsync(
      http.post(
        `${this.urlV3}/technology/detect`,
        { url, directory },
        {
          hideToastr: true,
          whitelistedStatusCodes: [422, 400],
        },
      ),
    );
  },
};
