import type { HInvoice } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_INVOICING}`,
  async getInvoices(params = {}) {
    return await hAsync<HInvoice[]>(
      http.get(`${this.url}/api/jwt/invoice/customer`, {
        params,
      }),
    );
  },
  async getInvoice(id: string) {
    return await hAsync<HInvoice>(
      http.get(`${this.url}/api/jwt/invoice/${id}`),
    );
  },
  async getInvoicePdf(invoiceId: string) {
    return await hAsync<{ file: string }>(
      http.get(`${this.url}/api/jwt/invoice/${invoiceId}/pdf`),
    );
  },
  async requestIndonesianTaxInvoice(invoiceId: string, npwp: string) {
    return hAsync<boolean>(
      http.post(`${this.url}/api/jwt/invoice/${invoiceId}/efaktur/npwp`, {
        npwp,
      }),
    );
  },
  async getIndonesianTaxInvoicePdf(invoiceId: string) {
    return await hAsync<{ file: string }>(
      http.get(`${this.url}/api/jwt/invoice/${invoiceId}/efaktur/pdf`),
    );
  },
};
