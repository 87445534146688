import type { IH5GDatabasesList } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/h5g/websites`,
  async getWebsiteDatabasesList(websiteUid: string) {
    return await hAsync<IH5GDatabasesList>(
      http.get(`${this.url}/${websiteUid}/databases`, {
        noHostingHeaders: true,
      }),
    );
  },
  async createDatabase(websiteUid: string, name: string) {
    return await hAsync<boolean>(
      http.post(
        `${this.url}/${websiteUid}/databases`,
        { name },
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async createUser(
    websiteUid: string,
    database: string,
    userData: {
      name: string;
      password: string;
    },
  ) {
    return await hAsync<boolean>(
      http.post(
        `${this.url}/${websiteUid}/databases/${database}/users`,
        userData,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async deleteUser(websiteUid: string, database: string, user: string) {
    return await hAsync<boolean>(
      http.delete(
        `${this.url}/${websiteUid}/databases/${database}/users/${user}`,
        {
          noHostingHeaders: true,
        },
      ),
    );
  },
  async getPhpMyAdminUrl(websiteUid: string, database: string) {
    return await hAsync<string>(
      http.get(`${this.url}/${websiteUid}/databases/${database}/php-my-admin`, {
        noHostingHeaders: true,
      }),
    );
  },
};
