<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import HButtonV1 from '@/components/HButtons/HButtonV1.vue';
import HeaderLogo from '@/components/Header/HeaderLogo.vue';
import ScreenSizeSelect from '@/components/ScreenSizeSelect.vue';
import { useGlobals, useOnboarding } from '@/composables';
import { useProfileStore } from '@/stores';
import type { ScreenMode } from '@/types';
import { AmplitudeEvent, SCREEN_MODE } from '@/types';

type Props = {
  isManualImport?: boolean;
};

type Emits = {
  'on-back': [];
  'on-template-select': [];
};

const props = withDefaults(defineProps<Props>(), {
  isManualImport: false,
});
const emit = defineEmits<Emits>();

const router = useRouter();
const route = useRoute();
const profileStore = useProfileStore();

const { isAddonOnboarding } = useOnboarding();
const { amplitudeV2 } = useGlobals();

const store = useStore();

const goBack = () => {
  if (props.isManualImport) {
    emit('on-back');

    return;
  }

  router.push({
    name: 'onboarding_wp_templates',
    params: route.params,
  });
};

const onScreenModeChange = (screen: ScreenMode) => {
  const screenSize =
    store.state.templatePreview.screenSize === screen
      ? SCREEN_MODE.DESKTOP
      : screen;

  store.commit('templatePreview/SET_SCREEN_SIZE', screenSize);
};

const previewSelect = () => {
  if (profileStore.account?.isPro) {
    amplitudeV2(AmplitudeEvent.Onboarding.WP_TEMPLATE_SELECTED, {
      isAddon: Number(isAddonOnboarding.value),
      isWebproOnboarding: true,
    });
  }

  if (props.isManualImport) {
    emit('on-template-select');

    return;
  }

  store.commit('templatePreview/TOGGLE_PREVIEW_SELECT');
};
</script>

<template>
  <div class="header__top">
    <div
      :id="`hpanel_tracking-onboarding-template-preview-${$route.params.slug}_go_back_button`"
      class="header__col header__back-button"
      @click="goBack"
    >
      <HpIcon class="h-mr-20" icon="icon-arrow-back" dark />
      <HeaderLogo />
    </div>
    <div class="header__col flex-grow-1 justify-content-center">
      <ScreenSizeSelect
        :screen-size="store.state.templatePreview.screenSize"
        @on-screen-mode-change="onScreenModeChange"
      />
    </div>
    <div class="header__col">
      <HButtonV1
        :id="`hpanel_tracking-onboarding-template-preview-${$route.params.slug}_continue_button`"
        primary
        @click="previewSelect"
      >
        Select & Continue
      </HButtonV1>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../sass/pages/preview';
</style>
