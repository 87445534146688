import store from '@/store';
import type { BillingSubscription } from '@/types';
import { SubscriptionStatus } from '@/types';

export const isAutorenewOn = (subscription: BillingSubscription) => {
  if (
    subscription.hasScheduledChanges &&
    (subscription.scheduledChanges?.remainingBillingCycles === 1 ||
      subscription.canceledAt)
  ) {
    return false;
  }

  return (
    [SubscriptionStatus.ACTIVE, SubscriptionStatus.IN_TRIAL].includes(
      subscription?.status,
    ) &&
    !!subscription?.nextBillingAt &&
    !subscription?.canceledAt &&
    subscription.remainingBillingCycles !== 1 &&
    !!store.getters['paymentMethods/getDefaultPaymentMethod']
  );
};
