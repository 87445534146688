import DOMPurify from 'dompurify';
import type { Directive, DirectiveBinding } from 'vue';

const vSafeHtml: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding<string>) {
    setInnerHtml(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding<string>) {
    setInnerHtml(el, binding);
  },
};

const setInnerHtml = (el: HTMLElement, binding: DirectiveBinding<string>) => {
  if (!binding.value) {
    return;
  }

  const currentValue = el.innerHTML;
  const newValue = DOMPurify.sanitize(binding.value, { ADD_ATTR: ['target'] });

  if (currentValue === newValue) {
    return;
  }

  el.innerHTML = newValue;
};

export default vSafeHtml;
