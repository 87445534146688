import store from '@/store/index';
import type { HResourceType } from '@/types';

type HResourcesPusherActionData = {
  id: number;
  type: HResourceType;
};

export default (event: string, data: HResourcesPusherActionData) => {
  if (event === 'resource.suspended') {
    store.dispatch('fetchHostingOrders');
    if (data.type === 'hosting') {
      store.dispatch('fetchHostingOrders');
    }

    return;
  }
};
