import { capiRepo } from '@/repositories';
import type {
  CpanelWordPressState,
  CommitFunction,
  CpanelWordPressInstallationState,
  CPanelWordPressInstallation,
} from '@/types';
import {
  CpanelWordPressStoreEnum,
  SoftaculousEnum,
  CpanelWordPressEnum,
} from '@/types';

const state: CpanelWordPressState = {
  installation: {
    loaded: false,
    loading: false,
    data: [],
  },
  currentManagedInstallationId: null,
};

const getters = {
  [CpanelWordPressStoreEnum.Getters.GET_CURRENT_MANAGED_SITE](
    state: CpanelWordPressState,
  ): CpanelWordPressInstallationState {
    return state.installation.data.find(
      ({ insid }) => insid === state.currentManagedInstallationId,
    );
  },

  [CpanelWordPressStoreEnum.Getters.GET_STAGING_LIST_BY_INSTALLATION_ID](
    state: CpanelWordPressState,
  ): CPanelWordPressInstallation[] {
    return state.installation.data.filter(
      ({ isStaging }) => isStaging === state.currentManagedInstallationId,
    );
  },

  [CpanelWordPressStoreEnum.Getters.GET_CLONE_LIST_BY_INSTALLATION_ID](
    state: CpanelWordPressState,
  ): any[] {
    return state.installation.data.filter(({ isCloned }) => isCloned === 1);
  },
};

const mutations = {
  [CpanelWordPressStoreEnum.Mutations.SET_INSTALLATIONS](
    state: CpanelWordPressState,
    installations: string[],
  ) {
    state.installation.data = installations;
    state.installation.loaded = true;
  },

  [CpanelWordPressStoreEnum.Mutations.SET_INSTALLATION_LOADING](
    state: CpanelWordPressState,
    loading: boolean,
  ) {
    state.installation.loading = loading;
  },

  [CpanelWordPressStoreEnum.Mutations.SET_CURRENT_MANAGED_ID](
    state: CpanelWordPressState,
    installationId: string,
  ) {
    state.currentManagedInstallationId = installationId;
  },

  [CpanelWordPressStoreEnum.Mutations.DELETE_CURRENT_MANAGED_ID](
    state: CpanelWordPressState,
  ) {
    state.currentManagedInstallationId = null;
  },

  [CpanelWordPressStoreEnum.Mutations.SET_FORCE_HTTPS](
    state: CpanelWordPressState,
    { param, value }: any,
  ) {
    setDataForWpInstallation(state, param, value);
  },

  [CpanelWordPressStoreEnum.Mutations.UPDATE_FORCE_HTTPS](
    state: CpanelWordPressState,
    { param, value }: any,
  ) {
    const data = getDataForWpInstallation(state, param);

    if (data) {
      data.isHttpsRedirecting = value;
    }
  },

  [CpanelWordPressStoreEnum.Mutations.SET_LITE_SPEED](
    state: CpanelWordPressState,
    { param, value }: any,
  ) {
    setDataForWpInstallation(state, param, value);
  },
};

const actions = {
  async [CpanelWordPressStoreEnum.Actions.FETCH_INSTALLATIONS](
    { commit }: CommitFunction,
    serviceId: string,
  ) {
    commit(CpanelWordPressStoreEnum.Mutations.SET_INSTALLATION_LOADING, true);

    const [{ data }, error] = await capiRepo.getSoftaculousInstallations(
      serviceId,
    );

    commit(CpanelWordPressStoreEnum.Mutations.SET_INSTALLATION_LOADING, false);

    if (error) {
      return;
    }

    const installations =
      data?.installations?.[SoftaculousEnum.SoftwareId.WORDPRESS] || {};

    commit(
      CpanelWordPressStoreEnum.Mutations.SET_INSTALLATIONS,
      Object.values(installations),
    );
  },

  async [CpanelWordPressStoreEnum.Actions.FETCH_FORCE_HTTPS](
    { commit }: CommitFunction,
    { serviceId, domain }: any,
  ) {
    const [{ data }] = await capiRepo.getSslStatus(serviceId, domain);

    if (data) {
      commit(CpanelWordPressStoreEnum.Mutations.SET_FORCE_HTTPS, {
        param: CpanelWordPressEnum.State.FORCE_HTTPS,
        value: data,
      });
    }
  },

  async [CpanelWordPressStoreEnum.Actions.FETCH_LITE_SPEED](
    { commit }: CommitFunction,
    { serviceId, path }: any,
  ) {
    const [{ data }] = await capiRepo.getWordpressAcceleratorStatus(
      serviceId,
      path,
    );

    if (data) {
      if (data.status.toLowerCase() === 'unknown') {
        await capiRepo.syncWordpressAccelerator(serviceId, 'scan');
      }

      commit(CpanelWordPressStoreEnum.Mutations.SET_LITE_SPEED, {
        param: CpanelWordPressEnum.State.LITE_SPEED,
        value: data.status.toLowerCase(),
      });
    }
  },
};

const getDataForWpInstallation = (
  state: CpanelWordPressState,
  param: string,
) => {
  const installation = state.installation.data.find(
    ({ insid }) => insid === state.currentManagedInstallationId,
  );

  if (installation) {
    return installation[param];
  }

  return null;
};

const setDataForWpInstallation = (
  state: CpanelWordPressState,
  param: string,
  value: any,
) => {
  const installation = state.installation.data.find(
    ({ insid }) => insid === state.currentManagedInstallationId,
  );

  if (installation) {
    installation[param] = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
