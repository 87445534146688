import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/sections`,

  async getSections(params) {
    return await hAsync(http.get(this.url, { params, hideToastr: true }));
  },
};
