<template>
  <div
    v-if="!disableSubheader"
    class="h-subheader"
    :class="{
      'justify-content-center': !frontendSettingsStore.state.breadcrumbs,
      'hide-on-mobile': isHiddenOnMobile,
    }"
  >
    <div
      v-if="
        !frontendSettingsStore.state.breadcrumbs &&
        !frontendSettingsStore.state.hideBackButton
      "
      class="h-subheader__arrow"
      @click="handleBack"
    >
      <HpIcon icon="ic-arrow-left" class="cursor-pointer" />
      <span v-if="!hideBackWord" v-trans>
        {{ frontendSettingsStore.state.backButtonLabel || 'Back' }}
      </span>
    </div>
    <div
      :class="[
        frontendSettingsStore.state.breadcrumbs
          ? 'h-subheader__left'
          : 'h-subheader__center',
      ]"
    >
      <h2
        v-if="!frontendSettingsStore.state.hideTitle"
        :class="[
          frontendSettingsStore.state.breadcrumbs
            ? 'h-subheader__title'
            : 'h-subheader__title--centered text-heading-1',
        ]"
        translate="no"
      >
        <slot />
      </h2>
      <HSubheaderBreadcrumbs
        v-if="
          frontendSettingsStore.state.breadcrumbs &&
          (!frontendSettingsStore.state.hideTitle ||
            (frontendSettingsStore.state.hideTitle && !global))
        "
        translate="no"
        :bread-crumbs="frontendSettingsStore.state.breadcrumbs"
      />
    </div>
    <div v-if="$slots.tools" class="h-subheader__right">
      <PlanBoostIsActive
        v-if="$route.name === 'order_order_usage' && isBoostActivated"
      />
      <slot name="tools" />
    </div>
  </div>
</template>

<script>
import HSubheaderBreadcrumbs from '@/components/HSubheaderBreadcrumb';
import PlanBoostIsActive from '@/components/Hosting/PlanBoost/PlanBoostIsActive';
import { usePlanBoost } from '@/composables';
import { useFrontendSettingsStore } from '@/stores';

export default {
  name: 'HSubheader',
  components: { HSubheaderBreadcrumbs, PlanBoostIsActive },
  props: {
    hideBackWord: Boolean,
    global: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isBoostActivated } = usePlanBoost();
    const frontendSettingsStore = useFrontendSettingsStore();

    return {
      frontendSettingsStore,
      isBoostActivated,
    };
  },
  computed: {
    isHiddenOnMobile() {
      return this.$route.meta.hideSubheaderMobile;
    },
    disableSubheader() {
      const disabledEndpoints = [
        'auth-success',
        'email-auth',
        'application-auth',
        'backup-auth',
      ];

      return disabledEndpoints.includes(this.$route.name);
    },
  },
  methods: {
    handleBack() {
      if (this.frontendSettingsStore.state.backArrowPath) {
        this.$router.push(this.frontendSettingsStore.state.backArrowPath);

        return;
      }
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/layout/subheader';

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}
.h-subheader {
  &__arrow {
    top: 4px;
  }

  &__title:empty {
    display: none;
  }

  &__title {
    word-break: break-all;
  }

  @media (max-width: 576px) {
    &__right {
      width: 100%;
    }
  }
}
.h-subheader h2:not(.h-subheader__title) {
  word-break: break-all;
  margin: 0;

  @media (max-width: 576px) {
    min-width: 100%;
    margin-top: 40px;
  }
}
</style>
