import { whFolderIndexManagerRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  directories: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingOtherFolderIndexManagerDirectories(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'directories', data);
    },
    addHostingOtherFolderIndexManagerDirectories(state, data) {
      const currentStateVariable = getStateVariable(state, 'directories') ?? [];

      currentStateVariable.push(data);

      setForDomain(state, null, 'directories', currentStateVariable);
    },
    setHostingOtherFolderIndexManagerLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingOtherFolderIndexManagerDirectories: (state) =>
      getStateVariable(state, 'directories'),
    getHostingOtherFolderIndexManagerLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingOtherFolderIndexManagerIndex(context) {
      const [{ data, requestDomain }] =
        await whFolderIndexManagerRepo.getDirectoryIndexes(
          getCurrentOrderDetails(),
        );

      if (data) {
        context.commit('setHostingOtherFolderIndexManagerDirectories', {
          data,
          requestDomain,
        });
        context.commit('setHostingOtherFolderIndexManagerLoaded', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingOtherFolderIndexManagerStore({ dispatch, commit }, payload) {
      commit('addHostingOtherFolderIndexManagerDirectories', payload);

      const response = await whFolderIndexManagerRepo.createDirectoryIndexes(
        getCurrentOrderDetails(),
        payload,
      );

      dispatch('hostingOtherFolderIndexManagerIndex');

      return response;
    },
    async hostingOtherFolderIndexManagerDestroy({ dispatch }, payload) {
      const [{ data }, error] =
        await whFolderIndexManagerRepo.deleteDirectoryIndexes(
          getCurrentOrderDetails(),
          payload,
        );

      dispatch('hostingOtherFolderIndexManagerIndex');

      return [{ data }, error];
    },
  },
};
