<template>
  <HHostingOrderDetailsListSkeleton v-if="!getOrderDetailsLoaded" :rows="5" />
  <HPortlet v-else :disabled-loader="hardRefreshLoader" no-body-padding>
    <div :class="{ row: inline }" class="py-3 px-3">
      <DetailListItem
        v-if="currentOrderDetails.order.title"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>Hosting plan</Trans>
          <span id="hosting-details-ic-arrow-up" @click="openPlanBoostModal">
            <HpIcon
              v-if="isPlanUpgradeable(currentOrderDetails.plan)"
              v-tooltip="
                isBoostAllowed
                  ? { content: planBoostTooltipText, trigger: 'hover focus' }
                  : planBoostTooltipText
              "
              icon="ic-arrow-up"
              primary
              class="edit-icon"
              v-bind="{ [boostIconClass]: true }"
            />
          </span>
        </template>
        <template #heading>
          <span>
            {{ currentOrderDetails.order.title }}
            <br />
            <PlanBoostIsActive v-if="isBoostActivated" class="h-mt-4" />
          </span>
        </template>
      </DetailListItem>

      <DetailListItem
        v-if="currentOrderDetails.server"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>Server</Trans>
          <span @click="openChangeDcModal(false)">
            <HpIcon
              v-tooltip="
                isDcTransferAllowed
                  ? { content: dcTooltipText, trigger: 'hover focus' }
                  : dcTooltipText
              "
              view-box="-3 -3 24 24"
              icon="ic-edit"
              class="edit-icon"
              primary
              v-bind="{ [iconClassNameVariable]: true }"
            />
          </span>
        </template>
        <template #heading>
          <span v-tooltip="`${serverInfo}`" class="cursor-pointer">
            {{ serverInfo }}
          </span>
        </template>
      </DetailListItem>
      <DetailListItem
        v-if="currentOrderDetails.ip"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>IP address</Trans>
        </template>
        <template #heading>
          <span>
            {{ currentOrderDetails.ip }}
          </span>
        </template>
      </DetailListItem>

      <hr />

      <DetailListItem
        v-if="mainDomain"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>Main Domain</Trans>
        </template>
        <template #heading>
          <span
            v-tooltip="toUnicode(mainDomain.vhost)"
            class="h-section__domain"
          >
            <Navigate
              data-click-id="hpanel_tracking-hosting-main_domain-sidebar"
              :route-to="getPreviewDomain(mainDomain.vhost)"
              target="_blank"
            >
              {{ toUnicode(mainDomain.vhost) }}
            </Navigate>
          </span>
        </template>
      </DetailListItem>

      <DetailListItem
        v-if="currentDomain.rootdir"
        v-tooltip="currentDomain.rootdir"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>Home root</Trans>
        </template>
        <template #heading>
          <span>
            {{ currentDomain.rootdir }}
          </span>
        </template>
      </DetailListItem>

      <hr />

      <DetailListItem
        v-if="currentOrderDetails.webServer"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>Web Server</Trans>
        </template>
        <template #heading>
          <span>
            {{ currentOrderDetails.webServer }}
          </span>
        </template>
      </DetailListItem>

      <DetailListItem :class="{ 'col-6 col-md-4 col-lg-3': inline }">
        <template #sub>
          <Trans>Hostinger nameservers</Trans>
        </template>
        <template #heading>
          <div
            v-for="(nameserver, index) in nameservers"
            :key="`nameserver-${index}`"
          >
            {{ nameserver }}
          </div>
        </template>
      </DetailListItem>

      <DetailListItem
        v-if="currentOrderDetails.databaseVersion"
        :class="{ 'col-6 col-md-4 col-lg-3': inline }"
      >
        <template #sub>
          <Trans>MySQL version</Trans>
        </template>
        <template #heading>
          <span>
            {{ currentOrderDetails.databaseVersion }}
          </span>
        </template>
      </DetailListItem>

      <DetailListItem :class="{ 'col-6 col-md-4 col-lg-3': inline }">
        <template #sub>
          <div class="d-flex justify-content-between">
            <Trans>MySQL Databases</Trans>
          </div>
        </template>

        <template #heading>
          <DetailListItemProgressbar
            :data="currentOrderDetails.usage.databases.value"
          />
        </template>
      </DetailListItem>

      <DetailListItem :class="{ 'col-6 col-md-4 col-lg-3': inline }">
        <template #sub>
          <div class="d-flex justify-content-between">
            <Trans>Order Disk Usage</Trans>
            <span class="u-bold mb-0">
              {{ calculatePercentage(currentOrderDetails.usage.storage) }}
            </span>
          </div>
        </template>

        <template #heading>
          <DetailListItemProgressbar
            :data="currentOrderDetails.usage.storage"
          />
        </template>
      </DetailListItem>

      <DetailListItem :class="{ 'col-6 col-md-4 col-lg-3': inline }">
        <template #sub>
          <div class="d-flex justify-content-between">
            <Trans>Order Inodes</Trans>
            <span class="u-bold mb-0">
              {{ calculatePercentage(currentOrderDetails.usage.inodes) }}
            </span>
          </div>
        </template>

        <template #heading>
          <DetailListItemProgressbar :data="currentOrderDetails.usage.inodes" />
        </template>
      </DetailListItem>

      <DetailListItem :class="{ 'col-6 col-md-4 col-lg-3': inline }">
        <template #sub>
          <div class="d-flex justify-content-between">
            <Trans>Subdomains</Trans>
            <span class="u-bold mb-0">
              {{ calculatePercentage(currentOrderDetails.usage.subdomains) }}
            </span>
          </div>
        </template>

        <template #heading>
          <DetailListItemProgressbar
            :data="currentOrderDetails.usage.subdomains"
          />
        </template>
      </DetailListItem>

      <div class="d-flex justify-content-center">
        <HButtonV1
          v-tooltip="'Refresh your order statistics. It may take some time.'"
          primary
          sm
          hp-icon="icon-sync"
          @click="hardRefreshStatistics()"
        />
      </div>
    </div>
  </HPortlet>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import HButtonV1 from '@/components/HButtons/HButtonV1';
import DetailListItem from '@/components/Hosting/DetailListItem';
import DetailListItemProgressbar from '@/components/Hosting/DetailListItemProgressbar';
import PlanBoostIsActive from '@/components/Hosting/PlanBoost/PlanBoostIsActive';
import HHostingOrderDetailsListSkeleton from '@/components/Loaders/SkeletonCompositions/HHostingOrderDetailsListSkeleton';
import Navigate from '@/components/Navigate';
import HPortlet from '@/components/Portlet/HPortlet.vue';
import { usePlanBoost } from '@/composables';
import {
  toUnicode,
  isHostingPlanUpgradable,
  getPercentage,
} from '@/utils/helpers';
import hostingServerMixin from '@/utils/mixins/hosting/hostingServerMixin';
import previewDomainMixin from '@/utils/mixins/previewDomainMixin';

export default {
  name: 'HostingOrderDetailsList',
  data() {
    return {
      hardRefreshLoader: false,
    };
  },
  components: {
    HButtonV1,
    DetailListItemProgressbar,
    Navigate,
    DetailListItem,
    HPortlet,
    HHostingOrderDetailsListSkeleton,
    PlanBoostIsActive,
  },
  mixins: [previewDomainMixin, hostingServerMixin],
  props: {
    inline: Boolean,
  },
  setup() {
    const {
      isBoostAllowed,
      openPlanBoostModal,
      isBoostActivated,
      planBoostTooltipText,
    } = usePlanBoost();

    return {
      isBoostAllowed,
      planBoostTooltipText,
      isBoostActivated,
      openPlanBoostModal,
    };
  },
  computed: {
    mainDomain() {
      return this.currentOrderDetails.vhosts.find(
        (vhost) => vhost.vhostType === 'main',
      );
    },
    currentDomain() {
      return this.currentOrderDetails.vhosts.find(
        ({ vhost }) => vhost === this.$route.params.domain,
      );
    },
    nameservers() {
      if (!this.currentDomain.nameservers) return;

      return Object.keys(this.currentDomain.nameservers);
    },
    boostIconClass() {
      return this.isBoostAllowed ? 'primary' : 'gray';
    },
    serverInfo() {
      const { title, hostname } = this.currentOrderDetails.server || {
        title: '',
        hostname: '',
      };

      return `${title}, ${hostname}`;
    },
    ...mapGetters([
      'currentOrderDetails',
      'getOrderDetailsLoaded',
      'getDomainIsPointing',
    ]),
  },
  watch: {
    mainDomain(newVal) {
      if (!newVal) return;
      this.mainDomainSetPointing();
    },
  },
  methods: {
    toUnicode,
    isPlanUpgradeable(planId) {
      return isHostingPlanUpgradable(planId);
    },
    calculatePercentage(data) {
      return `${getPercentage(data.value, data.limit, true)}%`;
    },
    hardRefreshStatistics() {
      this.hardRefreshLoader = true;
      this.hostingOrdersDetailsHardRefresh().finally(() => {
        this.hardRefreshLoader = false;
      });
    },
    async mainDomainSetPointing() {
      const mainDomainVhost = this.mainDomain.vhost;
      const mainDomainPointing = this.getDomainIsPointing(mainDomainVhost);
      if (
        mainDomainVhost !== this.$route.params.domain &&
        !mainDomainPointing
      ) {
        await this.$store.dispatch('domainIsPointingIndex', mainDomainVhost);
      }
    },
    ...mapActions(['hostingOrdersDetailsHardRefresh']),
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/link';

.edit-icon {
  vertical-align: middle;
  margin-left: 8px;
  cursor: pointer;
}
.plan-boost-container {
  color: var(--light);
  background: var(--success);
  padding: 0px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
  max-width: min-content;
  text-transform: uppercase;
}
</style>
