import { objectEntries } from '@vueuse/core';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import type { Component } from 'vue';
import { computed, ref } from 'vue';

import {
  Cookie,
  INITIAL_STATE,
  type IFrontendSettingsState,
  type TFrontendSettingsStateKey,
} from '@/types';

export const useFrontendSettingsStore = defineStore(
  'frontendSettingsStore',
  () => {
    const state = ref<IFrontendSettingsState>({ ...INITIAL_STATE });

    const setState = <
      Key extends TFrontendSettingsStateKey,
      Value extends IFrontendSettingsState[Key],
    >(
      key: Key,
      value: Value,
    ) => {
      state.value[key] = value;
    };

    const setPageSettings = (
      meta: Record<
        TFrontendSettingsStateKey,
        IFrontendSettingsState[TFrontendSettingsStateKey]
      >,
    ) => {
      const IGNORE_KEYS = [
        'showSshLogin',
        'showBrowserTerminal',
        'sideMenuHiddenLinks',
      ];

      objectEntries(state.value).forEach(([stateKey]) => {
        if (IGNORE_KEYS.includes(stateKey)) return;

        if (meta[stateKey] === undefined) {
          setState(stateKey, INITIAL_STATE[stateKey]);
        } else {
          setState(stateKey, meta[stateKey]);
        }
      });
    };

    const toggleAsideLeftShow = (value?: boolean) => {
      state.value.mAsideLeftShow = value ?? !state.value.mAsideLeftShow;
    };

    const toggleFeedbackOpen = (value?: boolean) => {
      // Used by auto-tests to prevent visual tests issues
      if (Cookies.get(Cookie.DISABLED_FEEDBACK)) return;

      state.value.feedbackOpen = value ?? !state.value.feedbackOpen;
    };

    const toggleHeaderMenuMobileShow = () => {
      if (window.innerWidth <= 1320) {
        state.value.asideHeaderMenuMobile = !state.value.asideHeaderMenuMobile;
      }
    };

    const headerMenuResponsiveTogglerPopover = ref<{
      content: Component;
      isActive: boolean;
      toggleAction: () => void;
    } | null>(null);

    const setHeaderMenuResponsiveTogglerPopover = ({
      content,
      isActive,
      toggleAction,
    }: {
      content: Component;
      isActive: boolean;
      toggleAction: () => void;
    }) => {
      headerMenuResponsiveTogglerPopover.value = {
        content,
        isActive,
        toggleAction,
      };
    };

    const resetHeaderMenuResponsiveTogglerPopover = () => {
      headerMenuResponsiveTogglerPopover.value = null;
    };

    const isNavigationVisible = computed(
      () =>
        !['simple-header', 'onboarding', false].includes(state.value.header),
    );

    return {
      state,
      isNavigationVisible,
      headerMenuResponsiveTogglerPopover,
      toggleAsideLeftShow,
      toggleFeedbackOpen,
      toggleHeaderMenuMobileShow,
      setPageSettings,
      setState,
      setHeaderMenuResponsiveTogglerPopover,
      resetHeaderMenuResponsiveTogglerPopover,
    };
  },
);
