import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_PAYMENTS_V2}/jwt/api/v1/payments`,

  async getFailedAutoRenewalPayments(params) {
    return await hAsync(http.get(`${this.url}/failed/recurrent`, { params }));
  },
  async getUserPayments() {
    return await hAsync(http.get(`${this.url}`));
  },
};
