<template>
  <a :href="to" :target="target" @click="$emit('click')">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'HButtonLink',
  props: {
    to: String,
    target: [String, Boolean],
  },
  emits: ['click'],
};
</script>
