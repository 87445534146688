export default {
  state: {
    pusher: null,
  },
  mutations: {
    SET_PUSHER(state, pusherWorker) {
      state.pusher = pusherWorker;
    },
  },
};
