import { accessLogsRepo, statisticsRepo } from '@/repositories';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';

const getFormattedOrderId = (orderId) => {
  const nonNumberRegex = /^[^0-9]+/;

  return orderId.replace(nonNumberRegex, '');
};

const explanations = {
  'Total requests': {
    explanation:
      'Total requests show HTTP or/and HTTPS requests to the website',
  },
  'Unique IP addresses': {
    explanation:
      'The number of distinct IP addresses that accessed your websites.',
  },
  Bandwidth: {
    explanation:
      'Bandwidth is the amount of data that was transmitted in a fixed amount of time.',
  },
  'HTTP 5xx errors': {
    explanation: 'Number of failed requests on website',
  },
};

const initial = {
  logs: {},
  graph: {
    'Total requests': {},
    'Unique IP addresses': {},
    Bandwidth: {},
    'HTTP 5xx errors': {},
  },
  errorsGraph: {},
  topRequests: [],
  topRequestsForFilters: { ipAddresses: [], country: [], requests: [] },
  isLoadingLogs: false,
  ipAddresses: [],
  country: [],
  requests: [],

  isLoadingTopRequests: false,
  isLoadingTopRequestsForFilters: false,
  isLoadingGraph: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_IS_LOADING_TOP_REQUESTS(state, data) {
      setForDomain(state, currentOrderId(), 'isLoadingTopRequests', data);
    },
    SET_IS_LOADING_TOP_REQUESTS_FOR_FILTERS(state, data) {
      setForDomain(
        state,
        currentOrderId(),
        'isLoadingTopRequestsForFilters',
        data,
      );
    },
    SET_IS_LOADING_LOGS(state, data) {
      setForDomain(state, currentOrderId(), 'isLoadingLogs', data);
    },
    SET_LOGS(state, data) {
      setForDomain(state, currentOrderId(), 'logs', data);
    },
    SET_GRAPH(state, data) {
      setForDomain(state, currentOrderId(), 'graph', data);
    },
    SET_ERRORS_GRAPH(state, data) {
      setForDomain(state, currentOrderId(), 'errorsGraph', data);
    },
    SET_TOP_REQUESTS(state, data) {
      setForDomain(state, currentOrderId(), 'topRequests', data);
    },
    SET_TOP_REQUESTS_FOR_FILTERS(state, data) {
      setForDomain(state, currentOrderId(), 'topRequestsForFilters', data);
    },
    SET_IS_LOADING_GRAPH(state, data) {
      setForDomain(state, currentOrderId(), 'isLoadingGraph', data);
    },
  },
  getters: {
    getIsLoadingTopRequests: (state) =>
      getStateVariable(state, 'isLoadingTopRequests', currentOrderId()),
    getIsLoadingLogs: (state) =>
      getStateVariable(state, 'isLoadingLogs', currentOrderId()),
    getIsLoadingGraph: (state) =>
      getStateVariable(state, 'isLoadingGraph', currentOrderId()),
    getLogs: (state) => getStateVariable(state, 'logs', currentOrderId()),
    getTopRequests: (state) =>
      getStateVariable(state, 'topRequests', currentOrderId()),
    getTopRequestsForFiltersByName: (state) => (name) => {
      const allRequests = getStateVariable(
        state,
        'topRequestsForFilters',
        currentOrderId(),
      );

      return allRequests[name];
    },
    getGraph: (state) => getStateVariable(state, 'graph', currentOrderId()),
    getErrorsGraph: (state) =>
      getStateVariable(state, 'errorsGraph', currentOrderId()),
    getIsLoadingTopRequestsForFilters: (state) =>
      getStateVariable(
        state,
        'isLoadingTopRequestsForFilters',
        currentOrderId(),
      ),
  },
  actions: {
    async fetchTopRequests(
      { commit, getters },
      { orderId: providedOrderId, domain, timeFilter, filter },
    ) {
      const account = getters.getCurrentAccountWithDomain(domain);

      const referenceId = account?.referenceId || providedOrderId;
      const username =
        account?.username || getters.currentOrderDetails.username;

      const orderId = getFormattedOrderId(String(referenceId));

      commit('SET_IS_LOADING_TOP_REQUESTS', true);
      const [{ data }, err] = await accessLogsRepo.getTopRequests(
        orderId,
        username,
        domain,
        timeFilter,
        filter,
        account,
      );
      if (!err) commit('SET_TOP_REQUESTS', data);
      commit('SET_IS_LOADING_TOP_REQUESTS', false);
    },
    async fetchTopRequestsForFilters(
      { commit, getters },
      { orderId: providedOrderId, domain, timeFilter },
    ) {
      commit('SET_IS_LOADING_TOP_REQUESTS_FOR_FILTERS', true);

      const account = getters.getCurrentAccountWithDomain(domain);

      const referenceId = account?.referenceId || providedOrderId;
      const username =
        account?.username || getters.currentOrderDetails.username;

      const orderId = getFormattedOrderId(String(referenceId));

      const responses = await Promise.all([
        accessLogsRepo.getTopRequests(
          orderId,
          username,
          domain,
          timeFilter,
          'ip',
          account,
        ),
        accessLogsRepo.getTopRequests(
          orderId,
          username,
          domain,
          timeFilter,
          'request',
          account,
        ),
        accessLogsRepo.getTopRequests(
          orderId,
          username,
          domain,
          timeFilter,
          'country',
          account,
        ),
      ]);

      const [{ data: dataIp }, errIp] = responses[0];
      const [{ data: dataRequest }, errRequest] = responses[1];
      const [{ data: dataCountry }, errCountry] = responses[2];

      const topRequestForFilters = {
        ipAddresses: [],
        requests: [],
        country: [],
      };

      if (!errIp) topRequestForFilters.ipAddresses = dataIp;
      if (!errRequest) topRequestForFilters.requests = dataRequest;
      if (!errCountry) topRequestForFilters.country = dataCountry;

      commit('SET_TOP_REQUESTS_FOR_FILTERS', topRequestForFilters);
      commit('SET_IS_LOADING_TOP_REQUESTS_FOR_FILTERS', false);
    },
    async fetchFilteredLogs({ commit, getters }, paramsForLogs) {
      const paramsForLogsValue = { ...paramsForLogs };

      const account = getters.getCurrentAccountWithDomain(paramsForLogs.domain);

      const username =
        account?.username || getters.currentOrderDetails.username;

      const referenceId =
        account?.referenceId ||
        paramsForLogsValue.orderId ||
        getters.getCurrentOrderWithDomain(paramsForLogsValue.domain).id;

      const orderId = getFormattedOrderId(String(referenceId));

      paramsForLogsValue.orderId = orderId;
      paramsForLogsValue.username = username;

      commit('SET_IS_LOADING_LOGS', true);

      const [{ data }, err] = await accessLogsRepo.getAccessLogs(
        paramsForLogsValue,
      );

      if (!err) commit('SET_LOGS', data);

      commit('SET_IS_LOADING_LOGS', false);
    },
    async fetchGraph({ commit }, { orderId, domain, period, step }) {
      commit('SET_IS_LOADING_GRAPH', true);
      const [{ data }, err] = await statisticsRepo.getStatisticsAccessLogs({
        order_id: orderId,
        domain,
        step,
        period,
      });
      if (!err) {
        Object.keys(data).forEach((key) => {
          data[key].explanation = explanations[key]?.explanation;
        });
        commit('SET_GRAPH', data);
      }
      commit('SET_IS_LOADING_GRAPH', false);
    },
    async fetchGraphStatus(
      { commit },
      { orderId, domain, period, step, from, to },
    ) {
      commit('SET_IS_LOADING_GRAPH', true);
      const [{ data }, err] =
        await statisticsRepo.getStatisticsAccessLogsStatus({
          orderId,
          domain,
          step,
          period,
          from,
          to,
        });
      if (!err) {
        Object.keys(data).forEach((key) => {
          data[key].explanation = explanations[key]?.explanation;
        });
        commit('SET_ERRORS_GRAPH', data);
      }
      commit('SET_IS_LOADING_GRAPH', false);
    },
  },
};
