import { hostingEmailsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  gSuiteMailboxes: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingEmailsGSuiteMailboxes(state, { data, requestdomain }) {
      setForDomain(state, requestdomain, 'gSuiteMailboxes', data);
    },
    setHostingEmailsGSuiteLoaded(state, { data, requestdomain }) {
      setForDomain(state, requestdomain, 'loaded', data);
    },
  },
  getters: {
    getHostingEmailsGSuiteMailboxes: (state) =>
      getStateVariable(state, 'gSuiteMailboxes'),
    getHostingEmailsGSuiteLoaded: (state) => getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingEmailsGSuiteIndex({ commit }) {
      const [{ data, requestDomain }, error] =
        await hostingEmailsRepo.getGsuites();

      if (!error) {
        commit('setHostingEmailsGSuiteMailboxes', {
          data: data.mailboxes,
          requestDomain,
        });
        commit('setHostingEmailsGSuiteLoaded', { data: true, requestDomain });
      }
    },
  },
};
