import type { NavigationGuard } from 'vue-router';

import { useReferrals } from '@/composables/useReferrals';
import { useProfileStore } from '@/stores/profile/profileStore';
import { Route } from '@/types';

export const referralsGuard: NavigationGuard = async () => {
  const { isFromReferralsRestrictedBrand } = useReferrals();
  const profileStore = useProfileStore();

  if (!profileStore.isProfileLoaded) {
    await profileStore.fetchProfile();
  }

  if (isFromReferralsRestrictedBrand.value) {
    return { name: Route.Base.HOME };
  }
};
