import { computed } from 'vue';

import { usePaymentMethod } from '@/composables/hBilling/usePaymentMethod';
import { useProfileStore } from '@/stores';
import type {
  IBasePaymentRequest,
  IBillingPurchaseRequest,
  IBillingUpgradeRequest,
  IPurchasePaymentRequest,
  IUpgradePaymentRequest,
} from '@/types';
import { getCouponRequest } from '@/utils/helpers/purchaseHelpers';

interface IBaseBillingRequestParams extends IBasePaymentRequest {
  cvcRefreshRequired?: boolean;
  isTrial?: boolean;
}

interface IPurchaseBillingRequestParams
  extends IPurchasePaymentRequest,
    IBaseBillingRequestParams {}

interface IUpgradeBillingRequestParams
  extends IUpgradePaymentRequest,
    IBaseBillingRequestParams {}

export const useBillingRequest = () => {
  const profileStore = useProfileStore();
  const { defaultPaymentMethod } = usePaymentMethod();

  const billingAddress = computed(() => {
    const firstName = profileStore.contact?.firstName;
    const lastName = profileStore.contact?.lastName;
    const company = profileStore.contact?.companyName;
    const address_1 = profileStore.contact?.address;
    const address_2 = null;
    const city = profileStore.contact?.city;
    const zip = profileStore.contact?.zip;
    const country = profileStore.contact?.countryCode;
    const phone = profileStore.contact?.phone;
    const email = profileStore.contact?.email;

    return {
      firstName,
      lastName,
      company,
      address_1,
      address_2,
      city,
      zip,
      country,
      phone,
      email,
    };
  });

  const getCommonRequestParams = (
    params: Omit<IBaseBillingRequestParams, 'itemPriceId'>,
  ) => ({
    customerId: profileStore.account?.id,
    redirectReturn: params.redirectReturn,
    redirectCancel: params.redirectCancel,
    transactionDetails: {
      metadata: {
        cvcRefreshRequired: params.cvcRefreshRequired ?? false,
      },
    },
    methodId: defaultPaymentMethod.value?.id,
    ...(params.isTrial ? { isTrial: true } : {}),
    ...getCouponRequest(params.coupon),
  });

  const getFinalQuantity = (quantity?: number) => quantity || 1;

  const getPurchaseRequest = ({
    quantity,
    domain,
    resourceId,
    ...params
  }: IPurchaseBillingRequestParams): IBillingPurchaseRequest => ({
    ...getCommonRequestParams(params),
    billingAddress: billingAddress.value,
    currency: profileStore.currency.code,
    items: [
      {
        itemId: params.itemPriceId,
        quantity: getFinalQuantity(quantity),
        metadata: {
          domain,
          resourceId,
        },
      },
    ],
  });

  const getUpgradeRequest = ({
    quantity,
    subscriptionId,
    itemPriceId,
    ...params
  }: IUpgradeBillingRequestParams): IBillingUpgradeRequest => ({
    ...getCommonRequestParams(params),
    subscriptionId,
    itemPriceId,
    quantity: getFinalQuantity(quantity),
  });

  return { getPurchaseRequest, getUpgradeRequest, billingAddress };
};
