import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { STORE_PERSISTENT_KEYS } from '@/types';

export const useHDomainsStore = defineStore(
  'hDomainsStore',
  () => {
    const transferableTlds = ref<string[]>([]);
    const isDomainRegistrationPrompted = ref(false);

    const getIsTldTransferable = (tld: string) =>
      transferableTlds.value.includes(tld);

    const setIsDomainRegistrationPrompted = (value: boolean) => {
      isDomainRegistrationPrompted.value = value;
    };

    const fetchTransferableTldsList = async () => {
      const [{ data }, err] = await hDomainsRepo.transferableTldsListIndex();

      if (err) return;

      transferableTlds.value = data;
    };

    return {
      transferableTlds,
      getIsTldTransferable,
      fetchTransferableTldsList,
      isDomainRegistrationPrompted,
      setIsDomainRegistrationPrompted,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.H_DOMAINS_STORE },
  },
);
