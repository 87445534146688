<script lang="ts" setup>
import { computed } from 'vue';

import type { NavigationMenuItem } from '@/types';

const TagTypes = {
  ROUTER_LINK: 'router-link',
  A: 'a',
  BUTTON: 'button',
};

type Props = {
  isButton?: boolean;
  navigationItem: NavigationMenuItem;
};
type Emits = {
  (
    eventName: 'click',
    event: Event,
    payload: NavigationMenuItem,
    type: string,
  ): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const tag = computed(() => {
  if (props.isButton) {
    return TagTypes.BUTTON;
  }

  if (props.navigationItem?.to) {
    return TagTypes.ROUTER_LINK;
  }

  if (props.navigationItem?.href) {
    return TagTypes.A;
  }

  return TagTypes.BUTTON;
});

const componentProps = computed(() => {
  if (tag.value === TagTypes.ROUTER_LINK) {
    return {
      to: props.navigationItem.to,
    };
  }

  if (tag.value === TagTypes.A) {
    return {
      href: props.navigationItem.href,
      target: '_blank',
    };
  }

  return {};
});

const dataQa = computed(() => {
  if (!props.navigationItem.dataQa) {
    return `navigate-${props.navigationItem.slug}`;
  }

  return props.navigationItem.dataQa;
});
</script>
<template>
  <Component
    :is="tag"
    v-bind="componentProps"
    v-tooltip="navigationItem.tooltip"
    :data-qa="dataQa"
    class="hp-menu__item-wrapper"
    :disabled="navigationItem.isDisabled"
    @click="emit('click', $event, navigationItem, tag)"
  >
    <div class="hp-menu__item-content">
      <div class="hp-menu__item-content-wrapper">
        <div class="hp-menu__item-link-icon-container">
          <slot name="icon" />
        </div>
        <slot />
      </div>
      <slot name="subtitle" />
    </div>
    <slot name="actions" />
  </Component>
</template>
<style lang="scss">
.hp-menu__item-wrapper {
  color: var(--dark);
  padding: var(--hmenu-padding);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  gap: 8px;

  &:disabled {
    cursor: not-allowed;
    color: var(--gray-darker);
  }

  &:hover:not(:disabled) {
    text-decoration: none;
    color: var(--dark);
  }
}

.hp-menu__item {
  &-dropdown-icon {
    height: 16px;
    width: 16px;
  }
  &-link {
    &:not(.hp-menu__item-link--active, .hp-menu__item-wrapper:disabled):hover {
      background-color: var(--gray-light);
    }

    &--active {
      background-color: var(--primary-base-selected);

      .hp-menu__item {
        &-subtitle,
        &-title {
          color: var(--primary-dark);
        }
      }
    }

    &-icon {
      &-container {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
      }
    }
  }

  &-content {
    width: 100%;
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;

    &-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &-subtitle {
    color: var(--gray-darker);
    font-weight: normal;
  }

  &-title {
    font-weight: 500;
    word-wrap: break-word;
    text-align: left;
  }

  &-label {
    border-radius: 50px !important;
    font-weight: bold !important;
  }
}
</style>
