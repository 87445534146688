<template>
  <div class="boost-plan">
    <div v-if="currentStep == 0">
      <h1 v-trans>Boost Your Website Performance</h1>

      <Trans tag="p">
        Increase your hosting plan limits for
        <strong>24 hours for free.</strong>
        Boosting is recommended when you want:
      </Trans>

      <ul class="boost-plan__list">
        <li>
          <p v-trans>to handle traffic spikes</p>
        </li>
        <li>
          <p v-trans>to import large amounts of data faster</p>
        </li>
        <li>
          <p v-trans>to test a better plan without any commitment</p>
        </li>
      </ul>
      <PlanBoostTable
        :is-loading="!loaded"
        :resources="boostPlanResources"
        :plan-names="boostPlanNames"
      />
      <div v-trans class="boost-plan__warning">
        Hosting plan boosting for 24 hours is available one time per month
      </div>
      <div class="boost-plan__button-container">
        <HButtonV1 text primary @click="close">Cancel</HButtonV1>
        <HButtonV1 primary @click="boostPlan">Boost</HButtonV1>
      </div>
    </div>
    <div v-if="currentStep == 1" class="boost-plan__modal-body">
      <CircleLoader />
      <h1 v-trans>Processing</h1>
      <p v-trans>Your hosting plan boosting is in progress</p>
    </div>
    <div v-if="currentStep == 2" class="boost-plan__modal-body">
      <HImage
        v-if="!boostError"
        class="h-mb-24"
        src="@/images/loader-check.svg"
        :alt="$t(lastViewImageAlt)"
      />
      <HImage
        v-if="boostError"
        class="h-mb-24"
        src="@/images/loader-failed.svg"
        :alt="$t('Error')"
      />
      <h1 v-trans>
        {{ lastViewTitle }}
      </h1>
      <p>{{ lastViewText }}</p>
      <HButtonV1
        v-if="currentStep == 2"
        text
        primary
        outline
        class="h-mt-24"
        no-margin
        @click="close"
      >
        Close
      </HButtonV1>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import PlanBoostTable from '@/components/Hosting/PlanBoost/PlanBoostTable';
import CircleLoader from '@/components/Loaders/CircleLoader';
import { accountRepo } from '@/repositories';
import hostingServerMixin from '@/utils/mixins/hosting/hostingServerMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'PlanBoostModal',
  components: {
    CircleLoader,
    PlanBoostTable,
  },
  mixins: [modalsMixin, hostingServerMixin],
  data() {
    return {
      boostError: '',
    };
  },
  created() {
    this.planBoostDetailsIndex(this.data.domain);
  },
  computed: {
    lastViewTitle() {
      return this.boostError
        ? 'Plan Boosting failed'
        : 'Plan Boosting is Activated';
    },
    lastViewText() {
      return this.boostError
        ? `${this.$t('Plan boosting failed')} ${this.boostError}`
        : `${this.$t('Boosting is activated until')} ${this.boostedUntil}`;
    },
    lastViewImageAlt() {
      return this.boostError ? 'Error' : 'Success';
    },
    boostedUntil() {
      return dayjs().add(24, 'hours').format('YYYY-MM-DD HH:mm');
    },
    ...mapGetters({
      boostedOrders: 'getBoostedOrders',
      boostPlanResources: 'getBoostPlanResources',
      boostPlanNames: 'getBoostPlanNames',
      loaded: 'getBoostPlanDetailsLoaded',
    }),
    ...mapGetters(['currentOrderDetails']),
  },
  methods: {
    async boostPlan() {
      this.goToNextStep();
      const [{ data }, err] = await accountRepo.boostPlan({
        domain: this.data.domain,
      });
      if (data) {
        if (!this.data.skipBoostIndex) this.hostingOrdersDetailsBoostedIndex();
        if (this.data.onSuccess) this.data.onSuccess();

        this.SET_BOOSTED_ORDERS_LOADED(false);
        if (this.data.onSuccess) this.data.onSuccess();
      }

      if (err) {
        this.boostError = err.error.message;
      }
      this.goToNextStep();
    },

    ...mapMutations(['SET_BOOSTED_ORDERS_LOADED']),
    ...mapActions([
      'boostHostingPlan',
      'hostingOrdersDetailsBoostedIndex',
      'planBoostDetailsIndex',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.boost-plan {
  min-height: 600px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__list {
    margin-top: 8px;
    padding-left: 0px;
    list-style: none;
    margin-left: 4px;

    li {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    li::before {
      content: '\2022';
      font-weight: 700;
      width: 16px;
      color: var(--gray);
    }
  }
  &__button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;
  }
  &__warning {
    background: var(--warning-light);
    margin-top: 24px;
    margin-bottom: 18px;
    padding: 8px 16px;
    border-radius: 4px;

    span {
      margin-left: 8px;
    }
  }
  &__modal-body {
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
