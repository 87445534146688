import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { useProfileStore, useVpsDomainStore, useVHostsStore } from '@/stores';
import { useHDomainsStore } from '@/stores/domain/hDomainsStore';
import { Route, Hosting } from '@/types';

export default async function (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const { fetchTransferableTldsList } = useHDomainsStore();
  const profileStore = useProfileStore();
  const domain = to.params.domain as string;

  const calls: Promise<void>[] = [fetchTransferableTldsList()];

  if (profileStore.isAccessManager) {
    fetchExternalDomains();
  }

  if (!isDomainFound(domain)) {
    calls.push(fetchExternalDomains());
  }

  await Promise.all(calls);

  if (isDomainFound(domain)) {
    return next();
  }

  if (isVpsDomainFound(domain)) {
    return next();
  }

  return next({ name: Route.Domain.MY_DOMAINS });
}

const isVpsDomainFound = (domain: string) => {
  const { externalDomains } = useVpsDomainStore();

  return !!externalDomains?.find(({ name }) => name === domain);
};

const isDomainFound = (domain: string) => {
  const { getIsDomainExternal } = useVHostsStore();

  return !!getIsDomainExternal(domain);
};

const fetchExternalDomains = async () => {
  const { fetchVHosts } = useVHostsStore();

  const params = {
    types: [
      Hosting.Vhost.MAIN,
      Hosting.Vhost.ADDON,
      Hosting.Vhost.WEBSITE_BUILDER,
    ],
  };

  await fetchVHosts(params);
};
