import type { RouteLocation, RouteRecordRaw } from 'vue-router';

import accountRoutes from './websites/account';
import advancedRoutes from './websites/advanced';
import databaseRoutes from './websites/databases';
import domainRoutes from './websites/domains';
import emailRoutes from './websites/emails';
import filesRoutes from './websites/files';
import orderRoutes from './websites/order';
import performanceRoutes from './websites/performance';
import securityRoutes from './websites/security';
import websiteRoutes from './websites/website';
import wordpressRoutes from './websites/wordpress';

import hostingGuard from '@/guards/hosting/hostingGuard';
import { suspendedWebsiteGuard } from '@/guards/suspendedWebsiteGuard';
import { websiteDashboardGuard } from '@/guards/websiteDashboardGuard';
import { websitesGuard } from '@/guards/websitesGuard';
import { Route } from '@/types';

export const websitesRoute = {
  path: ':domain/:subdomain?',
  component: () => import('@/views/Hosting/HostingDashboardWrapper.vue'),
  beforeEnter: websiteDashboardGuard,
  children: [
    {
      path: '',
      name: Route.Websites.WEBSITE_DASHBOARD,
      beforeEnter: [suspendedWebsiteGuard, hostingGuard],
      meta: {
        title: 'Dashboard',
        breadcrumbsTitle: { title: '{domain}', translate: false },
        breadcrumbs: [Route.Base.WEBSITES],
        sideMenuExists: true,
        sideMenuComponent: 'SectionSideMenu',
        rateServicesTitle: 'How would you rate website dashboard experience?',
      },
      component: () =>
        import('@/views/Hosting/HostingDashboard/HostingDashboard.vue'),
    },
    {
      path: 'redirect',
      name: Route.Websites.WEBSITES_REDIRECT,
      meta: {
        title: 'Redirecting...',
        sideMenuExists: false,
        showSubheader: false,
        header: 'simple-header',
        setFullscreen: true,
      },
      redirect: (to: RouteLocation) => ({
        query: { ...to.query, ...to.params },
        name: 'redirect',
      }),
    },
    ...orderRoutes,
    ...accountRoutes,
    ...advancedRoutes,
    ...databaseRoutes,
    ...domainRoutes,
    ...emailRoutes,
    ...filesRoutes,
    ...websiteRoutes,
    ...wordpressRoutes,
    ...securityRoutes,
    ...performanceRoutes,
    {
      path: 'analytics',
      name: Route.HostingPerformance.ACCESS_LOGS,
      meta: {
        title: 'Analytics',
        breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
        sideMenuExists: true,
        sideMenuComponent: 'SectionSideMenu',
      },
      component: () => import('@/views/Hosting/Performance/AccessLogs.vue'),
    },
  ],
};

const websitesRoutes = [
  {
    path: '/websites',
    children: [
      {
        path: '',
        name: Route.Base.WEBSITES,
        meta: {
          title: 'Websites',
          sideMenuExists: false,
          menu: ['horizontal'],
          breadcrumbs: [],
          rateServicesTitle:
            'Did you find everything you were looking for on this page?',
        },
        beforeEnter: (to, from, next) => websitesGuard(to, from, next),
        component: () => import('@/views/Websites/WebsitesWrapper.vue'),
      },
      {
        path: ':domain/dashboard',
        name: Route.Websites.BUILDER_DASHBOARD,
        meta: {
          title: 'Dashboard',
        },

        component: () => import('@/views/Websites/WebsiteDashboardWrapper.vue'),
      },
      {
        path: 'create-report',
        name: Route.Websites.CREATE_REPORT,
        meta: {
          title: 'Report Intro Page',
          breadcrumbs: false,
          backArrowPath: {
            name: Route.Base.WEBSITES,
          },
        },
        component: () =>
          import('@/views/Websites/CreateReport/CreateReport.vue'),
      },
      {
        path: 'creating-report',
        name: Route.Websites.CREATING_REPORT,
        meta: {
          fullscreen: true,
          hideBackButton: true,
          breadcrumbs: false,
        },
        component: () =>
          import('@/views/Websites/CreateReport/CreatingReport.vue'),
      },
      {
        path: 'migrations',
        name: Route.Websites.MIGRATIONS_REQUESTS,
        meta: {
          title: 'Migrations Requests',
          menu: [],
          breadcrumbs: [Route.Base.WEBSITES],
          rateServicesTitle: 'How would you rate website migration process?',
        },
        component: () => import('@/views/Hosting/MigrationsRequests.vue'),
      },
      {
        path: 'migrations/add-migration',
        name: Route.Websites.ADD_MIGRATION,
        meta: {
          title: 'Add Migration Request',
          menu: [],
          breadcrumbs: false,
        },
        component: () =>
          import(
            '@/views/Hosting/Website/MigrationRequest/AddMigrationRequest.vue'
          ),
      },
      {
        path: 'migrations/edit-migration/:id',
        name: Route.Websites.EDIT_MIGRATION,
        meta: {
          title: 'Edit Migration Request',
          menu: [],
          breadcrumbs: false,
        },
        component: () =>
          import(
            '@/views/Hosting/Website/MigrationRequest/AddMigrationRequest.vue'
          ),
      },
      websitesRoute,
    ],
  },
] as RouteRecordRaw[];

export default websitesRoutes;
