import type { NavigationGuard } from 'vue-router';

import { useFrontendSettingsStore, useVpsFirewallStore } from '@/stores';
import { Route } from '@/types';

export const vpsFirewallManageGuard: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const firewallStore = useVpsFirewallStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!firewallStore.isLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await firewallStore.getGroups();
    frontendSettingsStore.setState('pageLoading', false);
  }

  if (!firewallStore.getGroupById(to.params.groupId as string)) {
    return next({ name: Route.Vps.FIREWALL, params: to.params });
  }

  return next();
};
