<script setup lang="ts">
import { computed } from 'vue';

import CouponActive from '@/components/Modals/Parts/Coupon/CouponActive.vue';
import CouponInactive from '@/components/Modals/Parts/Coupon/CouponInactive.vue';
import CouponInput from '@/components/Modals/Parts/Coupon/CouponInput.vue';
import { useToggle } from '@/composables';

type Props = {
  title?: string;
  value?: string;
  loading: boolean;
  error: string;
  activeCoupon: string;
};

interface Emits {
  (eventName: 'on-apply', coupon: string): void;
  (eventName: 'on-remove'): void;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  title: 'Coupon code',
});

const activeCouponValue = computed(() => props.activeCoupon);

const {
  isToggled: isInputActive,
  toggle: toggleInput,
  toggleOff: hideInput,
} = useToggle();

const onToggleInput = () => {
  toggleInput();

  if (!isInputActive.value) emit('on-remove');
};

const applyCoupon = (coupon: string) => emit('on-apply', coupon);
const removeCoupon = () => {
  hideInput();
  emit('on-remove');
};
</script>

<template>
  <CouponInactive
    v-if="!activeCouponValue"
    :title="props.title"
    :value="props.value"
    :active="isInputActive"
    :error="error"
    :loading="loading"
    @on-toggle="onToggleInput"
  />

  <CouponInput
    v-if="!activeCouponValue && isInputActive"
    :loading="loading"
    :error="error"
    :active-coupon="activeCoupon"
    @on-apply="applyCoupon"
  />

  <CouponActive
    v-if="activeCouponValue && !error"
    :title="props.title"
    :value="props.value"
    :active-coupon="activeCoupon"
    @on-remove="removeCoupon"
  />
</template>
