import cookies from 'js-cookie';
import type { NavigationGuard } from 'vue-router';

import { Cookie, Route } from '@/types';

const playgroundGuard: NavigationGuard = (to, from, next) => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isDevelopment || cookies.get(Cookie.PLAYGROUND)) {
    return next();
  }

  next({ name: Route.Base.NOT_FOUND });
};

export default playgroundGuard;
