<script setup lang="ts">
import dayjs from 'dayjs';
import cookies from 'js-cookie';
import { computed, ref, watch } from 'vue';

import LanguageListItem from '@/components/Language/LanguageListItem.vue';
import { useGlobals } from '@/composables/useGlobals';
import { useLanguages } from '@/composables/useLanguages';
import { useLanguageStore } from '@/stores/languageStore';
import {
  AVAILABLE_LANGUAGE_CONFIGURATIONS,
  AmplitudeEvent,
  Cookie,
  LANGUAGE_CODE,
  type TLanguageCode,
} from '@/types';
import { slugLanguageSelected } from '@/utils/services/i18nService';

type Props = {
  searchTerm?: string;
};

type Emits = {
  close: [];
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { amplitudeV2 } = useGlobals();
const languageStore = useLanguageStore();
const { getCurrentLanguageCode, isSlugSelectionEnabled } = useLanguages();

const handleCurrentLanguageChange = () => {
  const currentLanguageCode = getCurrentLanguageCode();

  return currentLanguageCode === 'en_US' ? 'en_GB' : currentLanguageCode;
};

const currentLanguageCode = ref(handleCurrentLanguageChange());

const handleLanguageClick = async (language: TLanguageCode | 'slug') => {
  emits('close');

  if (language === 'slug') {
    slugLanguageSelected.value = true;
    cookies.set(Cookie.SLUG_LANGUAGE_SELECTED, 'true');
    changeLanguageByRerender(currentLanguageCode.value);

    return;
  }

  if (slugLanguageSelected.value) {
    slugLanguageSelected.value = false;
    cookies.set(Cookie.SLUG_LANGUAGE_SELECTED, 'false');
    changeLanguageByRerender(language);

    return;
  }

  amplitudeV2(AmplitudeEvent.LanguageSelector.LANGUAGE_SELECTED, {
    language,
  });

  await languageStore.changeLanguage(language);

  const localeLanguage =
    language === LANGUAGE_CODE.ar_AR ? LANGUAGE_CODE.en_GB : language;

  dayjs.locale(localeLanguage);
};

const changeLanguageByRerender = async (language: TLanguageCode) => {
  /**
   * Need to change language twice to ensure the rerender.
   * Because if user changes language back to current language, it won't catch the change and won't rerender.
   */
  const anyOtherLanguage = Object.values(LANGUAGE_CODE).find(
    (code) => code !== currentLanguageCode.value,
  )!;

  await languageStore.changeLanguage(anyOtherLanguage);
  await languageStore.changeLanguage(language);
};

const filteredLanguages = computed(() => {
  if (!props.searchTerm) {
    return AVAILABLE_LANGUAGE_CONFIGURATIONS;
  }
  const searchTerm = props.searchTerm?.toLowerCase();

  return AVAILABLE_LANGUAGE_CONFIGURATIONS.filter(
    ({ name, nativeName }) =>
      nativeName.toLowerCase().includes(searchTerm) ||
      name.toLowerCase().includes(searchTerm),
  );
});

const columns = computed(() => {
  const columnCount = 3;
  const itemsPerColumn = Math.ceil(
    filteredLanguages.value.length / columnCount,
  );

  return Array.from({ length: columnCount }, (_, index) =>
    filteredLanguages.value.slice(
      index * itemsPerColumn,
      (index + 1) * itemsPerColumn,
    ),
  );
});

watch(
  () => languageStore.currentLanguage,
  () => {
    // Update when language changes in store. This is needed because of the cookie language
    currentLanguageCode.value = handleCurrentLanguageChange();
  },
);
</script>

<template>
  <div class="languages">
    <div v-for="(column, index) in columns" :key="index">
      <div
        v-for="({ code }, rowIndex) in column"
        :id="`hpanel_tracking-h_header-languages_selector-${code}`"
        :key="code"
        :data-qa="`hpanel_tracking-h_header-languages_selector-${code}`"
        class="languages__column"
      >
        <LanguageListItem
          :current-language-code="currentLanguageCode"
          :language-code="code"
          :active="currentLanguageCode === code && !slugLanguageSelected"
          @language-click="handleLanguageClick"
        />
        <LanguageListItem
          v-if="
            rowIndex === column.length - 1 &&
            index === columns.length - 1 &&
            isSlugSelectionEnabled
          "
          :current-language-code="currentLanguageCode"
          :language-code="'slug'"
          :active="slugLanguageSelected"
          @language-click="handleLanguageClick"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.languages {
  @import '@/sass/_global-mixins.scss';
  height: 100%;
  overflow: auto;
  padding: 16px 16px 220px 16px;
  margin: 0;
  @include customScroll;

  @media (min-width: 577px) {
    grid-column-gap: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }
}
</style>
