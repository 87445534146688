import { useRoute } from 'vue-router';

import {
  h5gWebsitesRepo,
  websiteBuilderRepo,
  websiteRepo,
} from '@/repositories';
import { Website } from '@/types';

export const useAddDomain = () => {
  const route = useRoute();

  const getConnectDomainRequest = () => {
    const isBuilderWebsite = route.query.websiteType === Website.Type.BUILDER;
    const isH5GWebsite =
      route.query.websiteType === Website.Type.WORDPRESS &&
      !!route.query.websiteUid;

    if (isH5GWebsite) {
      return h5gWebsitesRepo.changeDomain.bind(h5gWebsitesRepo);
    }

    if (isBuilderWebsite) {
      return websiteBuilderRepo.changeWebsiteDomain.bind(websiteBuilderRepo);
    }

    return websiteRepo.changeWebsiteDomain.bind(websiteRepo);
  };

  return {
    getConnectDomainRequest,
  };
};
