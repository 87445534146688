import type { CpanelViolationResponse } from '@/types';
import type {
  ICPanelMail,
  ICPanelPhp,
  ICPanelService,
  ICPanelSoftaculousInstallRequest,
  ICPanelSoftaculousPatchRequest,
  CapiTopSenderEmail,
} from '@/types/models/cpanel/cPanelModels';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_CAPI_V2}/api`,

  async getCPanelServices() {
    return await hAsync<ICPanelService[]>(http.get(`${this.url}/v1/cpanel`));
  },

  async getCPanelServiceById(serviceId: string) {
    return await hAsync<ICPanelService>(
      http.get(`${this.url}/v1/cpanel/${serviceId}`, {
        params: { displayHostingPackageDetail: 1 },
      }),
    );
  },

  async getCPanelJump(
    serviceId: string,
    params?: {
      app?: string;
      locale?: string;
      preferredDomain?: string;
    },
  ) {
    return await hAsync<{ url: string }>(
      http.get(`${this.url}/v1/cpanel/${serviceId}/jump`, { params }),
    );
  },

  async changePassword(
    serviceId: string,
    payload: {
      password: string;
      enabledigest?: boolean;
      dbPassUpdate?: boolean;
    },
  ) {
    return await hAsync<{ app: string[] }>(
      http.post(`${this.url}/v1/cpanel/${serviceId}/change-password`, payload),
    );
  },

  async cpanelOrderShow(serviceId: string) {
    return await hAsync(http.get(`${this.url}/v1/cpanel/${serviceId}`));
  },

  async cpanelSetupStore(
    serviceId: string,
    payload: {
      password: string;
      sld: string;
      tld: string;
      email: string;
      lang: string;
      location: string;
    },
  ) {
    return await hAsync(
      http.patch(`${this.url}/v1/cpanel/${serviceId}`, payload),
    );
  },

  async cpanelLocationIndex(plan: string) {
    return await hAsync(
      http.get(`${this.url}/server/available-location`, { params: { plan } }),
    );
  },

  async cpanelManageShell(serviceId: string, actionType: string) {
    return await hAsync(
      http.patch(`${this.url}/v1/cpanel/${serviceId}/pusher-manage-shell`, {
        actionType,
      }),
    );
  },

  async syncService(serviceId: string) {
    return await hAsync(http.get(`${this.url}/v1/cpanel/${serviceId}/sync`));
  },

  async cpanelViolations(serviceId: string) {
    return await hAsync<CpanelViolationResponse[]>(
      http.get(`${this.url}/v1/cpanel/${serviceId}/violations`),
    );
  },

  async deleteCPanelViolations(serviceId: string, violationId: number) {
    return await hAsync(
      http.delete(
        `${this.url}/v1/cpanel/${serviceId}/violations/${violationId}`,
      ),
    );
  },

  async getWhmJump(
    serviceId: string,
    payload: { hostname: string; locale?: string; app?: string },
  ) {
    return await hAsync<{ url: string }>(
      http.post(`${this.url}/v1/whm/${serviceId}/jump-reseller`, payload),
    );
  },

  async checkWhmDnsZone(domain: string, plan: string) {
    return await hAsync(
      http.post(`${this.url}/v1/whm/check-zone`, { domain, plan }),
    );
  },

  async getCPanelPhp(serviceId: string) {
    return await hAsync<ICPanelPhp>(
      http.get(`${this.url}/v1/cpanel/${serviceId}/php`),
    );
  },

  async updateCPanelPhp(serviceId: string, version: string) {
    return await hAsync<{ version: string }>(
      http.post(`${this.url}/v1/cpanel/${serviceId}/php/change`, {
        version,
      }),
    );
  },

  async cpanelSoftaculousDomains(serviceId: string, forceSync = false) {
    return await hAsync(
      http.get(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/domains`,
        { params: { forceSync: Number(forceSync) } },
      ),
    );
  },

  async cpanelMail(serviceId: string) {
    return await hAsync<ICPanelMail>(
      http.get(`${this.url}/v1/cpanel/${serviceId}/mail`),
    );
  },

  async getSoftaculousInstallations(serviceId: string) {
    return await hAsync<any>(
      http.get(`${this.url}/v1/cpanel/${serviceId}/softaculous/script`),
    );
  },

  async upgradeSoftaculousInstallations(serviceId: string, insId: string) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/upgrade`,
        { insId },
      ),
    );
  },

  async installSoftaculousScript(
    serviceId: string,
    payload: ICPanelSoftaculousInstallRequest,
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script`,
        payload,
        {
          hideToastr: true,
        },
      ),
    );
  },

  async patchSoftaculousInstallation(
    serviceId: string,
    payload: ICPanelSoftaculousPatchRequest,
  ) {
    return await hAsync(
      http.patch(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script`,
        payload,
      ),
    );
  },

  async getSslStatus(serviceId: string, domain: string) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/ssl-hosting-status`,
        { domain },
      ),
    );
  },

  async forceHttps(serviceId: string, domain: string, state: 1 | 0) {
    return await hAsync(
      http.post(`${this.url}/v1/cpanel/${serviceId}/softaculous/force-https`, {
        domain,
        state,
      }),
    );
  },

  async getWordpressAcceleratorStatus(serviceId: string, path: string) {
    return await hAsync<any>(
      http.get(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/status-wordpress-accelerator`,
        {
          params: {
            path,
          },
        },
      ),
    );
  },

  async syncWordpressAccelerator(
    serviceId: string,
    action?: 'scan' | 'refresh',
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/sync-wordpress-accelerator`,
        { param: action },
      ),
    );
  },

  async setWordpressAccelerator(
    serviceId: string,
    path: string,
    status?: 'enabled' | 'disabled',
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/set-wordpress-accelerator`,
        {
          path,
          status,
        },
      ),
    );
  },

  async autoSignOn(serviceId: string, insId: string) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/auto-sign-on`,
        { insId },
      ),
    );
  },

  async softaculousImportManualInstallations(serviceId: string) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/import-all`,
      ),
    );
  },

  async softaculousCloneScript(
    serviceId: string,
    payload: {
      insId: string;
      domain: string;
      protocol: 1 | 2 | 3 | 4;
      db?: string;
      directory?: string;
      overwriteExisting?: boolean;
    },
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/clone`,
        payload,
      ),
    );
  },

  async softaculousCreateStaging(
    serviceId: string,
    payload: {
      insId: string;
      domain: string;
      protocol: 1 | 2 | 3 | 4;
      db?: string;
      directory?: string;
    },
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/staging`,
        payload,
      ),
    );
  },

  async softaculousPushLiveStaging(
    serviceId: string,
    payload: {
      insID: string;
      customPush?: number;
      overwriteFile?: number;
      pushDB?: number;
      structuralChangeTables?: string[];
      datachangeTables?: string[];
      pushViews?: number;
    },
  ) {
    return await hAsync(
      http.post(
        `${this.url}/v1/cpanel/${serviceId}/softaculous/script/push-to-live`,
        payload,
      ),
    );
  },

  async softaculousDeleteScript(
    serviceId: string,
    params: {
      insID: string;
      removeDir?: number;
      removeDatadir?: number;
      removeDB?: number;
      removeDbuser?: number;
      noEmail?: number;
    },
  ) {
    return await hAsync(
      http.delete(`${this.url}/v1/cpanel/${serviceId}/softaculous/script`, {
        params,
      }),
    );
  },

  async getMailStatistic(
    serviceId: string,
    params: {
      startTime: number;
      endTime: number;
    },
  ) {
    return await hAsync(
      http.get(`${this.url}/v1/system/cpanel/${serviceId}/mail/statistic`, {
        params,
      }),
    );
  },

  async unsuspendOutgoingMail(serviceId: string) {
    return await hAsync(
      http.post(
        `${this.url}/v1/system/cpanel/${serviceId}/mail/unsuspend-outgoing`,
        null,
        {
          hideToastr: true,
        },
      ),
    );
  },

  async getDomains(serviceId: string) {
    return await hAsync(
      http.get(`${this.url}/v1/cpanel/${serviceId}/domain/addon`),
    );
  },

  async getTopSenderEmail(serviceId: string) {
    return await hAsync<CapiTopSenderEmail>(
      http.get(`${this.url}/v1/system/cpanel/${serviceId}/smtp/top-sender`),
    );
  },
};
