import type { NavigationGuard } from 'vue-router';

import { Route } from '@/types';
import { getJwtToken } from '@/utils/helpers/authHelpers';

const authGuard: NavigationGuard = (to, from, next) => {
  if (!getJwtToken()) return next({ name: Route.Base.LOGIN });
  next();
};

export default authGuard;
