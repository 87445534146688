import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route, Email } from '@/types';
import { isFreePlan } from '@/utils/helpers/emailsHelper';

export const premiumPlanNudgeGuard: NavigationGuard = async (to) => {
  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const domain = to.params.domain as string;

  const emailDetails = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.HOSTINGER,
    domain,
  );

  const getManagementPageRoute = () => ({
    name: Route.Email.HMAIL_MANAGEMENT,
    params: { domain },
  });

  if (!isFreePlan(emailDetails.plan.name)) {
    return getManagementPageRoute();
  }

  await store.dispatch('fetchEmailDomainAndPlanDetails', {
    domain,
    orderId: emailDetails.orderId,
  });

  if (!store.getters.getEmailsDetails.data.domain.showPremiumPlanNudge) {
    return getManagementPageRoute();
  }
};
