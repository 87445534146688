<script setup lang="ts">
import type { Slots } from 'vue';
import { computed, useSlots } from 'vue';

import HpCircleLoader from '@/components/Loaders/HpCircleLoader.vue';

interface Props {
  md?: boolean;
  id?: string;
  sm?: boolean;
  lg?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  outline?: boolean;
  danger?: boolean;
  gray?: boolean;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
  dark?: boolean;
  light?: boolean;
  primary?: boolean;
  default?: boolean;
  secondary?: boolean;
  icon?: string;
  active?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  block?: boolean;
  text?: boolean;
  nowrap?: boolean;
  underlineHover?: boolean;
  noDefaultHoverEffect?: boolean;
}

interface Emits {
  (eventName: 'on-click'): void;
}

const props = withDefaults(defineProps<Props>(), { md: true });
const emit = defineEmits<Emits>();
const slots = useSlots();

const clickButton = () => {
  if (!props.disabled && !props.loading) emit('on-click');
};

const findButtonText = (slots: Slots): string | undefined => {
  if (!slots.default) return;
  if (slots.default().length === 1) {
    return slots.default()[0].children as string;
  }
};

const buttonGaTrackingId = computed(() => {
  const buttonTextId =
    findButtonText(slots)?.trim().replace(/[\W_]/g, '_').toLowerCase() || '';

  return `hpanel_tracking-${buttonTextId}_button`;
});

const buttonClass = computed(() => ({
  'h-button-danger': props.danger,
  'h-button-success': props.success,
  'h-button-info': props.info,
  'h-button-warning': props.warning,
  'h-button-dark': props.dark,
  'h-button-outline': props.outline,
  'h-button-light': props.light || props.default,
  'h-button-primary': props.primary,
  'h-button-secondary': props.secondary,
  'h-button-gray': props.gray,
  'h-button-text': props.text,
  'h-button--active': props.active,
  'my-2': !props.noMargin,
  'h-button--block': props.block,
  'h-button--no-padding': props.noPadding,
  'h-button--loading': props.loading,
  'h-button--disabled': props.disabled,
  'h-button-sm': props.sm,
  'h-button-md': props.md,
  'h-button-lg': props.lg,
  'h-button--full-width': props.fullWidth,
  'h-button--nowrap': props.nowrap,
  'h-button--no-hover-effect': props.noDefaultHoverEffect,
  'h-button-underline-hover': props.underlineHover,
}));
</script>

<template>
  <button
    :id="id || buttonGaTrackingId"
    part="button"
    class="h-button"
    :class="buttonClass"
    @click="clickButton"
  >
    <i v-if="icon" aria-hidden="true" :class="icon" />
    <slot name="icon" />
    <slot />

    <slot name="icon-right" />
    <slot v-if="loading" name="loading">
      <HpCircleLoader
        xs
        centered
        absolute
        :success="success"
        :info="info"
        :warning="warning"
        :danger="danger"
        :primary="primary"
        :gray="gray"
        :secondary="secondary"
        :dark="dark"
        :outline="outline"
        :text="text"
      />
    </slot>
  </button>
</template>

<style lang="scss" scoped>
a.h-button,
.h-button {
  --color: #212529;
  --bg-color: transparent;
  --border-color: transparent;
  --hover-color: transparent;

  color: var(--color);
  background: var(--bg-color);
  border-color: var(--gray-border);
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  user-select: none;
  border-radius: 4px;
  align-self: center;

  &.h-button-outline {
    border: 1px solid var(--gray-border);
    background: transparent;
    color: var(--bg-color);

    &:hover,
    &:focus,
    &:active {
      background: var(--hover-color);
    }
  }

  &:hover {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05)
    ) !important;
  }

  &--disabled,
  &[disabled] {
    cursor: not-allowed !important;
    opacity: 1 !important;

    .circle-loader {
      pointer-events: none;
    }

    &:focus,
    &:hover,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid var(--secondaryText) !important;
      background: var(--secondaryText) !important;
      color: var(--light) !important;
    }

    border: 1px solid var(--secondaryText) !important;
    background: var(--secondaryText) !important;
    color: var(--light) !important;

    &.h-button-text {
      border: 1px solid transparent !important;
      background: transparent !important;
      color: var(--secondaryText) !important;
    }

    &.h-button-outline {
      border: 1px solid var(--gray-border) !important;
      background: transparent !important;
      color: var(--secondaryText) !important;
    }
  }

  &--full-width {
    width: 100% !important;
  }

  &--block {
    width: 100% !important;
  }

  &--loading {
    opacity: 1 !important;
    cursor: not-allowed;
    color: transparent !important;
  }

  &--loading .circle-loader {
    cursor: not-allowed;
    pointer-events: none;
  }

  &--loading.h-button--disabled {
    color: transparent !important;
    .circle-loader {
      .path {
        stroke: var(--light) !important;
      }
    }
  }

  span {
    pointer-events: none !important;
  }

  @media only screen and (max-width: 575px) {
    span {
      white-space: break-spaces;
    }
  }

  @media (min-width: 576px) {
    width: auto;
    margin: 0 0;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  &--pill {
    border-radius: 2rem;
  }

  &--no-padding {
    padding: 4px 6px !important;
    line-height: 1rem !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  border-radius: 4px !important;

  > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  &--active {
    background: var(--primary) !important;
    color: var(--color) !important;
  }

  &--nowrap {
    > * {
      white-space: nowrap !important;
    }
  }
}

a.h-button-outline {
  &:hover {
    color: var(--bg-color);
  }
}

.h-button-md {
  font-size: 14px !important;
  padding: 8px 24px !important;
  line-height: 24px !important;

  &--no-padding {
    padding: 4px 6px !important;
    line-height: 1rem !important;
  }
}

.h-button-sm {
  font-size: 12px !important;
  padding: 8px 16px !important;
  line-height: 16px !important;

  &--no-padding {
    padding: 4px 6px !important;
    line-height: 1rem !important;
  }
}

.h-button-lg {
  padding: 12px 32px !important;
  line-height: 24px !important;

  &--no-padding {
    padding: 4px 6px !important;
    line-height: 1rem !important;
  }
}

.h-button.h-button-text {
  border: 1px solid transparent;
  background: transparent;
  color: var(--bg-color);
  border-color: transparent;
  background-image: none !important;

  &:not(.h-button--no-hover-effect):not(.h-button--no-state-styling):hover {
    background: var(--hover-color);
  }

  &.h-button-underline-hover {
    padding-left: 0px !important;
    padding-right: 0px !important;
    &:focus {
      box-shadow: none !important;
    }
    &:active {
      border: 1px solid transparent;
      background-color: transparent !important;
      color: var(--bg-color) !important;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      border-bottom: 2px solid;
      visibility: hidden;
    }

    &:hover {
      &::before {
        visibility: visible;
      }
    }
  }
}

.h-button.h-button-text.h-button--disabled {
  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: transparent !important;
    background: transparent !important;
    color: var(--secondaryText);
  }

  border-color: transparent !important;
  background: transparent !important;
  color: var(--secondaryText);
}

.h-button.h-button-outline.h-button--disabled {
  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--secondaryText);
    background: var(--secondaryText);
    color: var(--secondaryText) !important;
  }

  border: 1px solid var(--secondaryText);
  background: var(--secondaryText);
  color: var(--secondaryText);
}

.h-button.h-button-text.h-button--loading,
.h-button.h-button-outline.h-button--loading {
  color: transparent !important;

  &:focus,
  &:hover,
  &:active {
    color: transparent !important;
  }
}

a.h-button {
  &:hover {
    text-decoration: none;
  }
}

a.h-button-text,
.h-button-text {
  &:active,
  &:focus {
    color: var(--bg-color);
    background: transparent !important;
  }

  &:hover {
    background-image: none;
    border-color: transparent !important;
    background: transparent !important;
  }
}

a.h-button.h-button--no-state-styling,
.h-button.h-button--no-state-styling {
  &:focus,
  &:hover,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    color: var(--primary) !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
  }
}

a.h-button-primary,
.h-button-primary {
  --color: var(--light);
  --bg-color: var(--primary);
  --border-color: var(--primary);
  --hover-color: var(--primary-light);
}

a.h-button-secondary,
.h-button-secondary {
  --color: var(--light);
  --bg-color: var(--secondary);
  --border-color: var(--secondary);
  --hover-color: var(--danger-light);
}

a.h-button-success,
.h-button-success {
  --color: var(--light);
  --bg-color: var(--success);
  --border-color: var(--success);
  --hover-color: var(--success-light);
}

a.h-button-info,
.h-button-info {
  --color: var(--light);
  --bg-color: var(--primary-hostinger);
  --border-color: var(--primary-hostinger);
  --hover-color: var(--info-light);
}

a.h-button-warning,
.h-button-warning {
  --color: var(--dark);
  --bg-color: var(--warning);
  --border-color: var(--warning);
  --hover-color: var(--warning-light);
}

a.h-button-warning-regular,
.h-button-warning-regular {
  --color: var(--dark);
  --bg-color: var(--warning-regular);
  --border-color: var(--warning-regular);
  --hover-color: var(--warning-light);
}

a.h-button-danger,
.h-button-danger {
  --color: var(--light);
  --bg-color: var(--danger);
  --border-color: var(--danger);
  --hover-color: var(--danger-light);
}

a.h-button-light,
.h-button-light {
  --color: var(--dark);
  --bg-color: var(--light);
  --border-color: var(--light);
  --hover-color: var(--gray-dark);
}

a.h-button-dark,
.h-button-dark {
  --color: var(--light);
  --bg-color: var(--dark);
  --border-color: var(--dark);
  --hover-color: var(--gray-border);
}

a.h-button-black,
.h-button-black {
  --color: var(--light);
  --bg-color: var(--dark);
  --border-color: var(--dark);
  --hover-color: var(--gray-border);
}

a.h-button-gray,
.h-button-gray {
  --color: var(--light);
  --bg-color: var(--gray);
  --border-color: var(--gray);
  --hover-color: var(--gray-border);
}

a.h-button-gray-light,
.h-button-gray-light {
  --color: var(--dark);
  --bg-color: var(--gray-light);
  --border-color: var(--gray-light);
  --hover-color: var(--gray-border);
}

a.h-button-danger-light,
.h-button-danger-light {
  --color: var(--light);
  --bg-color: var(--danger-light);
  --border-color: var(--danger-light);
  --hover-color: var(--danger-light);
}

a.h-button-success-dark,
.h-button-success-dark {
  --color: var(--light);
  --bg-color: var(--success-dark);
  --border-color: var(--success-dark);
  --border-color: var(--success-dark);
}

a.h-button-success-light,
.h-button-success-light {
  --color: var(--light);
  --bg-color: var(--success-light);
  --border-color: var(--success-light);
  --hover-color: var(--success-light);
}

a.h-button-warning-light,
.h-button-warning-light {
  --color: var(--light);
  --bg-color: var(--warning-light);
  --border-color: var(--warning-light);
  --hover-color: var(--warning-light);
}

a.h-button-warning-dark,
.h-button-warning-dark {
  --color: var(--light);
  --bg-color: var(--warning-dark);
  --border-color: var(--warning-dark);
  --border-color: var(--warning-light);
}

.h-button--loading {
  &:focus,
  &:hover,
  &:active {
    color: transparent !important;
  }
}

.h-button-image {
  border-radius: 50% !important;
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  img {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  & img:hover {
    transform: scale(1.1);
  }
}
</style>
