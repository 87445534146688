import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  restUrl: `${process.env.VITE_API_REST}/v2/plan`,

  async hostingUpgradeRecommendations({ fault, hideToastr, domain }) {
    return await hAsync(
      http.get(`${this.restUrl}/recommendation`, {
        params: fault,
        hideToastr,
        domain,
      }),
    );
  },
};
