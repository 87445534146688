import { capiRepo } from '@/repositories';
import type {
  CommitFunction,
  CpanelPhpState,
  CpanelPhpDetailState,
} from '@/types';
import { CpanelPhpStoreEnum } from '@/types';

const state: CpanelPhpState = {
  detail: {
    loaded: false,
    loading: false,
    data: {
      selectedVersion: '',
      defaultVersion: '',
    },
  },
  currentManagedDetailId: null,
};

const getters = {
  [CpanelPhpStoreEnum.Getters.GET_PHP_MANAGED](
    state: CpanelPhpState,
  ): CpanelPhpDetailState {
    return state.detail.data;
  },
};

const mutations = {
  [CpanelPhpStoreEnum.Mutations.SET_PHP_DATA](
    state: CpanelPhpState,
    details: string[],
  ) {
    state.detail.data = details;
    state.detail.loaded = true;
  },
  [CpanelPhpStoreEnum.Mutations.SET_PHP_LOADING](loading: boolean) {
    state.detail.loading = loading;
  },
};

const actions = {
  async [CpanelPhpStoreEnum.Actions.FETCH_PHP_DATA](
    { commit }: CommitFunction,
    serviceId: string,
  ) {
    commit(CpanelPhpStoreEnum.Mutations.SET_PHP_LOADING, true);

    const [{ data }] = await capiRepo.getCPanelPhp(serviceId);

    commit(CpanelPhpStoreEnum.Mutations.SET_PHP_LOADING, false);

    const details = data || {};

    commit(CpanelPhpStoreEnum.Mutations.SET_PHP_DATA, details);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
