<template>
  <div class="d-inline-block align-middle">
    <div class="radio__element-container">
      <label
        class="radio__element radio__element--thick mb-0"
        :class="{
          'radio__element--primary': active,
          'radio__element--disabled': disabled,
          'radio__element--fancy': fancy,
        }"
      >
        <input disabled type="radio" :checked="active" />
        <span />
        <div v-if="!disabled" class="radio__overlay" />
      </label>
    </div>
  </div>
</template>

<script>
import { useModal } from '@/composables';

export default {
  name: 'HRadioCheck',
  inject: {
    modalOpened: {
      default: false,
    },
  },
  props: {
    active: Boolean,
    disabled: Boolean,
    fancy: Boolean,
  },
  setup() {
    const { setModalClosingType } = useModal();

    return {
      setModalClosingType,
    };
  },
  mounted() {
    if (this.modalOpened) {
      this.setModalClosingType('input');
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/components/forms/radio';
</style>
