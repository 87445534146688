<template>
  <HPortlet no-body-padding>
    <div v-for="n in rows" :key="n">
      <div class="py-3 px-3">
        <div class="h-section">
          <HSkeletonLoader :width="80" :height="18" class="h-mt-4 h-mb-8" />
          <HSkeletonLoader width="100%" :height="18" />
        </div>
      </div>
    </div>
  </HPortlet>
</template>
<script>
import HPortlet from '../../Portlet/HPortlet';
import HSkeletonLoader from '../HSkeletonLoader';

export default {
  name: 'HHostingOrderDetailsListSkeleton',
  components: {
    HPortlet,
    HSkeletonLoader,
  },
  props: {
    rows: Number,
  },
};
</script>
