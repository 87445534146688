import { useCookies } from '@/composables';
import { useFeedbackStore } from '@/stores';
import { Cookie, CSAT_CUSTOM_IDENTIFIER } from '@/types';

export const useConnectDomainCSAT = () => {
  const feedbackStore = useFeedbackStore();

  const trigger = () => {
    const { cookies } = useCookies();

    if (!cookies[Cookie.FEEDBACK_CLOSED]) {
      feedbackStore.openCSATFeedback({
        location: CSAT_CUSTOM_IDENTIFIER.CONNECT_DOMAIN,
        firstStepTitle: 'v2.csat.connect-domain.how-satisfied-were-you',
        secondStepTitle: 'v2.csat.connect-domain.how-would-you-improve',
      });
    }
  };

  return {
    trigger,
  };
};
