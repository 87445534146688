<template>
  <div>
    <Trans tag="p">
      Website migration can be done, but
      <strong>current website files will be changed</strong>
      with the migrated files
    </Trans>
    <div class="text-right h-mt-24">
      <HButtonV1
        id="migrations_close-button-override-migration"
        no-margin
        text
        primary
        @click="close"
      >
        Close
      </HButtonV1>
      <HButtonV1
        id="migrations_override-button-override-migration"
        no-margin
        primary
        @click="action"
      >
        Continue
      </HButtonV1>
    </div>
  </div>
</template>

<script>
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'OverrideDirectoryModal',
  mixins: [modalsMixin],
  methods: {
    action() {
      this.data.onSuccess();
      this.close();
    },
  },
};
</script>
