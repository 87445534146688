import { createRouter, createWebHistory } from 'vue-router';

import accountAccess from '@/router/routes/accountAccess';
import autoRefundRoutes from '@/router/routes/autoRefund';
import baseRoutes from '@/router/routes/baseRoutes';
import billingRoutes from '@/router/routes/billingRoutes';
import cPanelRoutes from '@/router/routes/cPanelRoutes';
import developerTools from '@/router/routes/developerTools';
import domainRoutes from '@/router/routes/domains';
import emailAccountCreateRoutes from '@/router/routes/emails/emailAccountCreateRoutes';
import emailCpanelRoutes from '@/router/routes/emails/emailCpanelRoutes';
import emailOnboardingRoutes from '@/router/routes/emails/emailOnboardingRoutes';
import emailsRoutes from '@/router/routes/emails/emails';
import proEmailOnboardingRoutes from '@/router/routes/emails/proEmailOnboardingRoutes';
import proTitanEmailOnboardingRoutes from '@/router/routes/emails/proTitanEmailOnboardingRoutes';
import titanEmailOnboardingRoutes from '@/router/routes/emails/titanEmailOnboardingRoutes';
import H5GOnboarding from '@/router/routes/h5g/h5gOnboarding';
import h5gWebsite from '@/router/routes/h5g/h5gWebsite';
import hbillingRoutes from '@/router/routes/hbillingRoutes';
import hostingRoutes from '@/router/routes/hosting';
import hostingerProRoutes from '@/router/routes/hostingerPro/hostingerPro';
import migrationsOnboarding from '@/router/routes/migrationsOnboarding';
import onboardingV2 from '@/router/routes/onboardingV2';
import playgroundRoutes from '@/router/routes/playground';
import profileRoutes from '@/router/routes/profile';
import referralRoutes from '@/router/routes/referrals';
import refund from '@/router/routes/refund';
import saleRoutes from '@/router/routes/saleRoutes';
import serverOnboardingRoutes from '@/router/routes/servers/onboarding';
import serverPurchaseRoutes from '@/router/routes/servers/purchase';
import serverRoutes from '@/router/routes/servers/servers';
import websiteBuilderRoutes from '@/router/routes/websiteBuilderRoutes';
import websitesRoutes from '@/router/routes/websitesRoutes';

/**
 * This is the main router file where all the routes are imported and initialized
 * ! Do not import this into any of the components, modules or composables use `useRouter` from `vue-router` instead in Vue context
 * ! or import { router } from '@/routes' outside Vue context
 */
export const routerWithRoutes = createRouter({
  history: createWebHistory(),
  routes: [
    ...onboardingV2,
    ...baseRoutes,
    ...websitesRoutes,
    ...autoRefundRoutes,
    ...hostingRoutes,
    ...domainRoutes,
    ...emailsRoutes,
    ...serverRoutes,
    ...serverOnboardingRoutes,
    ...serverPurchaseRoutes,
    ...refund,
    ...profileRoutes,
    ...referralRoutes,
    ...billingRoutes,
    ...hbillingRoutes,
    ...cPanelRoutes,
    ...emailCpanelRoutes,
    ...emailOnboardingRoutes,
    ...proEmailOnboardingRoutes,
    ...titanEmailOnboardingRoutes,
    ...proTitanEmailOnboardingRoutes,
    ...saleRoutes,
    ...emailAccountCreateRoutes,
    ...hostingerProRoutes,
    ...playgroundRoutes,
    ...migrationsOnboarding,
    ...websiteBuilderRoutes,
    ...H5GOnboarding,
    ...h5gWebsite,
    ...developerTools,
    ...accountAccess,
  ],
});
