import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useGlobals } from '@/composables';
import { whCronJobRepo } from '@/repositories';
import type { IHostingCronJob, IHostingAccount } from '@/types';

type HostingAccountIdentifier = {
  username: IHostingAccount['username'];
};

export const useHostingAdvancedCronJobStore = defineStore(
  'hostingAdvancedCronJobStore',
  () => {
    const { toastr, t } = useGlobals();

    const isLoading = ref(false);

    const cronJobs = ref<IHostingCronJob[]>([]);
    const fetchCronJobs = async (accountDetails: HostingAccountIdentifier) => {
      isLoading.value = true;
      const [{ data }, err] = await whCronJobRepo.getCronJobs(accountDetails);

      if (!err) cronJobs.value = data;

      isLoading.value = false;
    };

    const deleteCronJob = async (
      accountDetails: HostingAccountIdentifier,
      item: IHostingCronJob,
    ) => {
      const [_, error] = await whCronJobRepo.deleteCronJob(
        accountDetails,
        item.pwkey,
      );

      if (!error) {
        toastr.s(t('Cron Job deleted successfully'));
        fetchCronJobs(accountDetails);
      }
    };

    return {
      isLoading,
      cronJobs,
      fetchCronJobs,
      deleteCronJob,
    };
  },
);
