import { isArray } from 'lodash';

export const vnodeToString = (items) => {
  let message = '';

  if (items) {
    for (const item of items) {
      if (!isArray(item.children)) {
        if (
          ![
            'Symbol(Text)',
            'Symbol(Fragment)',
            'Symbol()',
            'Symbol(v-txt)',
          ].includes(item.type.toString())
        ) {
          message += openTag(item) + item.children + closeTag(item);
        } else {
          message += item.children;
        }
      } else if (item.type === 'a') {
        message += openTag(item) + item.children[0].text + closeTag(item);
      } else {
        const childrenString = vnodeToString(item.children);
        message += childrenString;
      }
    }
  }

  return message;
};

export const openTag = (item) => {
  let openTag = `<${item.type.toString()}`;

  // to maintain the same order as old trans component version
  if (item.props) {
    const props = Object.entries(item.props).filter(([key]) => key !== 'class');

    props.forEach(([key, value]) => {
      openTag += ` ${key}="${value}"`;
    });
  }

  if (item.props && item.props.class) {
    openTag += ` class="${item.props.class}"`;
  }

  return `${openTag}>`;
};
export const closeTag = (item) => `</${item.type.toString()}>`;
