<script setup lang="ts">
import type { HAction } from './hpActionModel';

import HpAction from '@/components/HpActions/HpAction.vue';

interface Props {
  actions: HAction[];
  positionY: string;
}

interface Emits {
  (eventName: 'close-action', payload: HAction): void;
}

const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(), { positionY: 'bottom' });
</script>

<template>
  <div
    class="h-actions"
    :class="{
      'h-actions--bottom': positionY === 'bottom',
      'h-actions--top': positionY === 'top',
    }"
  >
    <TransitionGroup name="fade-slow">
      <template v-for="action in actions" :key="action.id">
        <HpAction
          :action="action"
          @close-action="emit('close-action', $event)"
        />
      </template>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
.fade-slow-enter-to {
  opacity: 1;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 0.6s;
}
.fade-slow-enter-from,
.fade-slow-leave-to {
  opacity: 0;
}

.d-flex {
  display: flex;
}

.h-actions {
  z-index: var(--z-index-hp-action);
  width: 470px;
  position: fixed;
  right: 32px;
  color: #212529;

  &--bottom {
    bottom: 92px;
  }

  &--top {
    top: 102px;
    @media (max-width: 576px) {
      top: calc(52px + 32px);
    }
  }
}
</style>
