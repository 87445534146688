<template>
  <button :disabled="disabled" @click.prevent="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'HButtonSimple',
  props: {
    disabled: Boolean,
  },
  emits: ['click'],
};
</script>
