import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useProPanelStore = defineStore('proPanelStore', () => {
  const isInProPanel = ref(false);

  const setIsInProPanel = (value: boolean) => {
    isInProPanel.value = value;
  };

  return {
    setIsInProPanel,
    isInProPanel,
  };
});
