<template>
  <div class="h-100">
    <div class="h-100" @click.prevent="openUpsellModal">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import orderModalsMixin from '../../../utils/mixins/orderModalsMixin';

import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { AmplitudeLocation } from '@/types';

export default {
  name: 'OrderRenewSection',
  mixins: [orderModalsMixin],
  data() {
    return {
      modalUpsell: {
        data: {
          title: 'Renew hosting plan',
        },
        show: false,
      },
    };
  },
  methods: {
    openUpsellModal() {
      const order = this.getCurrentOrder;

      this.$openModal({
        component: { RenewModal },
        data: {
          amplitudeSource: AmplitudeLocation.Base.SIDE_MENU,
          title: this.$t('Renew your {service}', {
            service: order.title,
          }),
          subtitle: this.$t(
            'Review your selected invoice and proceed to checkout',
          ),
          itemPriceId: order.productName,
          subscriptionId: order.id,
          serviceType: order.service,
          redirect: `${window.origin}`,
          onSuccess: () => {
            this.$store.dispatch('billingItems/getBillingItems');
          },
        },
        steps: [
          {
            hideX: true,
          },
        ],
      });
    },
  },
  computed: {
    ...mapGetters(['getCurrentOrder']),
  },
};
</script>
