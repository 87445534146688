import { capiRepo } from '@/repositories';
import { router } from '@/router';
import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';
import { useProfileStore } from '@/stores';
import { toASCII, getSplittedDomainParts } from '@/utils/helpers';
import { onboardingRoutingByStatus } from '@/utils/services/onboarding';

const initial = {
  cpanelOnboarding: false,
  cpanelOrderDetails: {},
  cpanelPlanId: null,
  selectedLanguage: null,
  password: null,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    ONBOARDING_STATE_RELOAD: (state) => (state.data = [...state.data]),
    SET_IS_CPANEL_ONBOARDING(state, data) {
      setForDomain(state, currentOrderId(), 'cpanelOnboarding', data);
    },
    SET_CPANEL_PLAN_ID(state, data) {
      setForDomain(state, currentOrderId(), 'cpanelPlanId', data);
    },
    SET_CPANEL_LANGUAGE(state, data) {
      setForDomain(state, currentOrderId(), 'selectedLanguage', data);
    },
    SET_CPANEL_PASSWORD(state, data) {
      setForDomain(state, currentOrderId(), 'password', data);
    },
    SET_CPANEL_ORDER_DETAILS(state, data) {
      setForDomain(state, currentOrderId(), 'cpanelOrderDetails', data);
    },
  },
  getters: {
    getIsCpanelOnboarding: (state) =>
      getStateVariable(state, 'cpanelOnboarding', currentOrderId()),
    getCpanelPlanId: (state) =>
      getStateVariable(state, 'cpanelPlanId', currentOrderId()),
    getSelectedLanguage: (state) =>
      getStateVariable(state, 'selectedLanguage', currentOrderId()),
    getCpanelPassword: (state) =>
      getStateVariable(state, 'password', currentOrderId()),
    getCpanelOrderDetails: (state) =>
      getStateVariable(state, 'cpanelOrderDetails', currentOrderId()),
  },
  actions: {
    async fetchAvailableLocations({ commit, getters }) {
      const plan = getters.getCpanelOrderDetails?.plan;

      const [{ data }, error] = await capiRepo.cpanelLocationIndex(plan);

      if (error) return;

      commit('SET_DATA_CENTERS', data.dataCenters);
      commit('SET_BEST_DATA_CENTER', data.bestDataCenter);
      commit('SET_COORDINATES', data.coordinates);
    },
    async submitCpanelOnboarding({ getters, commit }) {
      const profileStore = useProfileStore();
      const [sld, tld] = getSplittedDomainParts(
        toASCII(getters.getOnboardingDomainName),
      );

      const [{ data }, error] = await capiRepo.cpanelSetupStore(
        getters.getCpanelPlanId,
        {
          sld,
          tld,
          location: getters.getSelectedDatacenterKey,
          password: getters.getCpanelPassword,
          lang: getters.getSelectedLanguage?.locale || 'en',
          email: profileStore.account?.email,
        },
      );

      if (error) {
        router.push({
          name: 'onboarding_overview',
        });
        commit('setAccountCreateSubmitted', false);
        commit('setAddStep', 'onboarding_overview');

        return [{ data }, error];
      }

      return [{ data }, error];
    },
    async getCpanelOnboardingState({ getters, commit }) {
      const [{ data }, error] = await capiRepo.getCPanelServiceById(
        getters.getCpanelPlanId || router.currentRoute.value.query.planId,
      );

      if (error) return;

      commit('SET_CPANEL_ORDER_DETAILS', data);

      if (data.status === 'activated') {
        commit('setAccountCreateSubmitted', true);
      }

      const isDomainSetupInProgress =
        getters.getOnboardingDomainToSetup &&
        router.currentRoute.value.name === 'onboarding_domain_setup';

      onboardingRoutingByStatus(
        data,
        getters.getAccountCreateSubmitted && !isDomainSetupInProgress,
        true,
      );

      return [{ data }, error];
    },
  },
};
