import type { NavigationGuard } from 'vue-router';
import { useStore } from 'vuex';

import { useGlobals } from '@/composables';
import { useFrontendSettingsStore, useWebsitesStore } from '@/stores';
import { Route } from '@/types';

export const websiteDashboardGuard: NavigationGuard = async (to) => {
  const store = useStore();
  const websitesStore = useWebsitesStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const { toastr, t } = useGlobals();

  if (!store.getters.getOrdersLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await store.dispatch('fetchHostingOrders');
    frontendSettingsStore.setState('pageLoading', false);
  }

  if (websitesStore.isWebsiteBeingRemoved(to.params.domain as string)) {
    toastr.e(t('Website is being removed'));

    return { name: Route.Base.HOME };
  }
};
