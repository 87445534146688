export interface RedirectState {
  routeName: string;
  prevRouteName: string;
  params: {
    [key: string]: any;
  };
}

export enum RedirectMutations {
  SET_REDIRECT = 'SET_REDIRECT',
  UNSET_REDIRECT = 'UNSET_REDIRECT',
  SET_PREV_ROUTE_NAME = 'SET_PREV_ROUTE_NAME',
}
