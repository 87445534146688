import type {
  IAccess,
  IGrantAccessRequest,
  IHasJWTToken,
  IInviteeManager,
  IRequestAccessRequest,
  RequestConfig,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_H_SSO}/v1/access`,
  invitationsUrl: `${process.env.VITE_API_H_SSO}/v1/invitations`,
  postGrant(data: IGrantAccessRequest) {
    return hAsync<boolean>(
      http.post(`${this.url}/grant`, data, {
        whitelistedStatusCodes: [400, 422, 404],
      }),
    );
  },
  postRequest(data: IRequestAccessRequest, config: RequestConfig = {}) {
    return hAsync<boolean>(
      http.post(`${this.url}/request`, data, {
        ...config,
        whitelistedStatusCodes: [
          ...(config?.whitelistedStatusCodes || []),
          400,
          422,
          429,
        ],
      }),
    );
  },
  postImpersonate(id: number) {
    return hAsync<IHasJWTToken>(
      http.post(`${this.url}/${id}/impersonate`, undefined, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  deleteAccess(id: number) {
    return hAsync<boolean>(
      http.delete(`${this.url}/${id}/revoke`, {
        withTokenUpdate: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  getAccess() {
    return hAsync<IAccess>(http.get(`${this.url}`));
  },
  getInvitations() {
    return hAsync<IInviteeManager[]>(
      http.get(`${this.invitationsUrl}`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  deleteInvitation(invitationId: number | string) {
    return hAsync<boolean>(
      http.delete(`${this.invitationsUrl}/${invitationId}/revoke`, {
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
  patchConfirmAccessRequest(id: number) {
    return hAsync(
      http.patch(
        `${this.url}/${id}/confirm`,
        {},
        { whitelistedStatusCodes: [400, 422, 429] },
      ),
    );
  },
};
