<template>
  <div class="inline-field" :class="computedClass">
    <div class="inline-field__main-wrapper" @click="$emit('focus')">
      <template v-if="!schema.loading">
        <Component
          :is="fieldComponent"
          :schema="{
            ...schema,
            id: fieldId,
          }"
          :value="
            schema.type === 'select' && schema.value ? schema.value : null
          "
          :model="model"
          :inline-field="true"
          :search-by-value="searchByValue || null"
          @h-select-opened="hselectOpened"
          @multi-values="getMultiValues"
          @on-change="clearValidationErrors"
          @on-keyup-enter="$emit('on-keyup-enter')"
        />
        <p
          v-if="allErrors.length === 0 && schema.placeholder && schema.label"
          v-trans
          class="inline-field__helper"
          :helperText="schema.placeholder"
          :class="{ 'position-md-absolute': absoluteError }"
        >
          {{ schema.placeholder }}
        </p>
        <div
          v-if="allErrors.length > 0"
          id="errorsBlock"
          class="d-flex align-items-center text-danger"
          :class="{ 'position-md-absolute': absoluteError }"
        >
          <div class="d-flex flex-column">
            <span
              v-for="(error, errorKey) in allErrors"
              :key="errorKey"
              class="inline-field__error"
            >
              {{ $t(error) }}
            </span>
          </div>
        </div>
      </template>
      <DataLoader v-else sm />
    </div>
  </div>
</template>

<script>
import { isFunction, isArray, isString, uniqueId } from 'lodash';

import FieldPartHint from '@/components/Form/FieldParts/FieldPartHint';
import DataLoader from '@/components/Loaders/DataLoader.vue';
import { useModal } from '@/composables';
import { fieldElementDynamicImports } from '@/utils/services/form/formComponents';
import validators from '@/utils/services/form/validators';

export default {
  name: 'OnboardingField',
  components: {
    FieldPartHint,
    DataLoader,
    ...fieldElementDynamicImports,
  },
  props: {
    schema: Object,
    model: Object,
    err: {
      type: null,
      required: false,
    },
    absoluteError: {
      type: Boolean,
      default: false,
    },
    searchByValue: Boolean,
  },
  emits: ['focus', 'h-select-opened', 'multi-values', 'on-keyup-enter'],
  inject: {
    modalOpened: {
      default: false,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  setup() {
    const { setModalClosingType } = useModal();

    return {
      setModalClosingType,
    };
  },
  created() {
    if (this.modalOpened) {
      this.setModalClosingType('input');
    }
  },
  computed: {
    computedClass() {
      return {
        'inline-field--error': this.allErrors.length > 0,
        [`inline-field--${this.schema.background}`]: this.schema.background,
      };
    },
    allErrors() {
      if (isArray(this.err)) {
        return this.errors.concat(this.err);
      } else if (this.err && isString(this.err)) {
        return this.errors.concat([this.err]);
      }

      return this.errors;
    },
    fieldId() {
      return this.schema.id || uniqueId(this.schema.model);
    },
    fieldValue() {
      return this.model[this.schema.model];
    },
    fieldComponent() {
      switch (this.schema.type) {
        case 'password':
          return 'field-element-password';
        case 'textarea':
          return 'field-element-textarea';
        case 'select':
          return 'field-element-select';
        case 'date':
          return 'field-element-date';
        case 'input-group':
          return 'field-element-input-group';
        case 'checkbox':
          return 'field-element-checkbox';
        case 'radio':
          return 'field-element-radio';
        case 'text':
          return 'field-element-text';
        default:
          return 'field-element-input';
      }
    },
    isRequired() {
      return this.schema.required;
    },
  },
  methods: {
    validate() {
      this.clearValidationErrors();

      this.validateField(this.schema);

      this.schema.input?.forEach((inputField) =>
        this.validateField(inputField),
      );

      try {
        if (this.schema.prepend) this.validateField(this.schema.prepend.schema);
        if (this.schema.append) this.validateField(this.schema.append.schema);
      } catch (e) {
        // For Save validation
      }

      return !this.errors.length > 0;
    },
    validateField(fieldSchema) {
      //Check if model exists
      if (!fieldSchema.model) return;

      if (this.checkRequired(fieldSchema)) {
        return false;
      }
      if (fieldSchema.validate) {
        if (isFunction(fieldSchema.validate)) {
          this.callValidation(fieldSchema.validate, fieldSchema);
        } else if (isArray(fieldSchema.validate)) {
          fieldSchema.validate.forEach((validationFunction) =>
            this.callValidation(validationFunction, fieldSchema),
          );
        }
      }
    },
    checkRequired(schema) {
      if (schema.required && schema.model) {
        return this.callValidation(validators.required, schema);
      }

      return false;
    },
    callValidation(validationFunction, schema, model = null) {
      const schemaValue = schema || this.schema;
      const modelValue = model || this.model;
      // If Error already exists, stop validating other cases
      if (this.errors.length > 0) return;

      const validationResult = validationFunction(
        modelValue[schemaValue.model],
        schemaValue,
        modelValue,
      );

      if (validationResult) this.errors = this.errors.concat(validationResult);

      return validationResult;
    },
    clearValidationErrors() {
      this.errors = [];
    },
    hselectOpened(data) {
      this.$emit('h-select-opened', data);
    },
    getMultiValues(data) {
      this.$emit('multi-values', data);
    },
  },
  watch: {
    fieldValue() {
      this.clearValidationErrors();
    },
    isRequired(newRequiredValue) {
      if (!newRequiredValue) {
        this.errors = [];
      }
    },
  },
};
</script>
<style lang="scss">
@import '../../sass/components/forms/form';
@import '../../sass/components/forms/inline-field';
</style>
