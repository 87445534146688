import { cloneDeep } from 'lodash';

import { whRedirectsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { toUnicode } from '@/utils/helpers';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

const initial = {
  redirects: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_DOMAIN_REDIRECTS(state, { data, requestDomain }) {
      data.forEach((redirect) => {
        try {
          if (redirect.uri?.startsWith('/')) {
            redirect.uri = redirect?.uri?.substring(1);
          }
          const uri = toUnicode(redirect?.uri);
          redirect.vhost =
            toUnicode(redirect?.vhost) +
            (uri?.startsWith('/') ? '' : '/') +
            uri;
          redirect.vhost = `${redirect?.protocol}://${redirect?.vhost}`;

          redirect.url =
            (redirect?.url?.startsWith('https://') ? 'https://' : 'http://') +
            toUnicode(redirect?.url?.replace(/^http(s)?:\/\//gim, ''));
        } catch (e) {
          //
        }
      });

      setForDomain(state, requestDomain, 'redirects', data);
    },
    REMOVE_DOMAIN_REDIRECT(state, removeId) {
      const currentStateVariable = getStateVariable(state, 'redirects');
      setForDomain(
        state,
        null,
        'redirects',
        currentStateVariable.filter(({ id }) => id !== removeId),
      );
    },
    ADD_DOMAIN_REDIRECT(state, data) {
      const currentStateVariable = getStateVariable(state, 'redirects');

      currentStateVariable.push(data);

      setForDomain(state, null, 'redirects', currentStateVariable);
    },
    SET_DOMAIN_REDIRECTS_LOADED(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingDomainsRedirectsRedirects: (state) =>
      getStateVariable(state, 'redirects'),
    getHostingDomainsRedirectsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingDomainsRedirectsIndex(context) {
      const [{ data, requestDomain }] = await whRedirectsRepo.getRedirects(
        getCurrentOrderDetails(),
      );
      context.commit('SET_DOMAIN_REDIRECTS', { data, requestDomain });
      context.commit('SET_DOMAIN_REDIRECTS_LOADED', {
        data: true,
        requestDomain,
      });
    },
    async hostingDomainsRedirectsStore(context, redirectData) {
      context.commit('ADD_DOMAIN_REDIRECT', cloneDeep(redirectData));
      const [{ data }, err] = await whRedirectsRepo.createRedirect(
        getCurrentOrderDetails(),
        redirectData,
      );
      context.dispatch('hostingDomainsRedirectsIndex', true);

      return [{ data }, err];
    },
    async hostingDomainsRedirectsDestroy(context, id) {
      const [{ data }] = await whRedirectsRepo.deleteRedirect(
        getCurrentOrderDetails(),
        id,
      );
      context.dispatch('hostingDomainsRedirectsIndex', true);

      return [{ data }];
    },
  },
};
