<script setup lang="ts">
import { ref, onUnmounted, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import HCheckbox from '@/components/HCheckbox.vue';
import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal } from '@/composables';
import { Route, AmplitudeEvent } from '@/types';
import { toUnicode } from '@/utils/helpers';
import { openChangeSldModal } from '@/utils/services/domains/hDomains';

const { t, amplitudeV2 } = useGlobals();
const router = useRouter();
const { openModal } = useModal();

interface Props {
  data: {
    domain: string;
  };
}

const props = defineProps<Props>();

type Emits = {
  close: [void];
};

const emits = defineEmits<Emits>();

const isConfirmed = ref(false);

const openChangeDomainModal = () => {
  amplitudeV2(AmplitudeEvent.Domain.CHANGE_SUSPICIOUS_ENTERED);

  openChangeSldModal(props.data.domain, {
    headerText: 'Change Domain Name',
    backButton: {
      text: 'Additional Check Required',
      action: () => {
        openModal({
          component: {
            SuspiciousDomainModal: 'SuspiciousDomainModal',
          },
          data: {
            domain: props.data.domain,
          },
        });
      },
    },
    callback: () => {
      amplitudeV2(AmplitudeEvent.Domain.SUSPICIOUS_DOMAIN_CHANGED);
    },
  });
};

const goToDomainSetup = () => {
  amplitudeV2(AmplitudeEvent.Domain.SUSPICIOUS_CHECK_CONFIRMED);

  router.push({
    name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
    params: { domain: props.data.domain },
  });
};

const sendSuspiciousDomainDetectedAmplitudeEvent = () => {
  amplitudeV2(AmplitudeEvent.Domain.SUSPICIOUS_KEYWORD_DETECTED);
};

onMounted(() => {
  document
    .querySelector('.suspicious-domain-modal__action-change')
    ?.addEventListener('click', openChangeDomainModal);
});

onUnmounted(() => {
  document
    .querySelector('.suspicious-domain-modal__action-change')
    ?.removeEventListener('click', openChangeDomainModal);
});

sendSuspiciousDomainDetectedAmplitudeEvent();
</script>

<template>
  <div class="suspicious-domain-modal">
    <h2 class="h-mb-8">{{ t('Additional Check Required') }}</h2>
    <Trans
      tag="p"
      class="h-mb-24"
      :translate-params="{ domain: toUnicode(props.data.domain) }"
    >
      Your domain
      <strong>{domain}</strong>
      has some increased risk keywords. This can occur due to many reasons, for
      example, you might be violating the copyright laws.
    </Trans>
    <Trans tag="p">
      <a class="suspicious-domain-modal__action-change">
        Change your domain name
      </a>
      or let us perform additional check to make sure everything is all right.
    </Trans>
    <div class="suspicious-domain-modal__confirmation">
      <HCheckbox
        class="h-mr-8"
        :active="isConfirmed"
        thick
        @click="isConfirmed = !isConfirmed"
      />
      <span>
        {{
          t(
            'I understand, that additional check is required to validate the domain',
          )
        }}
      </span>
    </div>
    <HModalActions
      no-padding
      mobile-reverse
      class="h-mt-24"
      close-text="Cancel"
      @close="emits('close')"
    >
      <HButton
        :is-disabled="!isConfirmed"
        data-qa="submit-confirm-suspicions-domain"
        @click="goToDomainSetup"
      >
        {{ t('Confirm') }}
      </HButton>
    </HModalActions>
  </div>
</template>

<style lang="scss">
.suspicious-domain-modal {
  &__action-change {
    font-weight: 700;
    cursor: pointer;
  }

  &__confirmation {
    margin-top: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: var(--gray-light);
  }
}
</style>
