import { computed } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { useRoute } from 'vue-router';

import { useOnboardingStatusCheck, useResourceMapper } from '@/composables';
import wordpressRepo from '@/repositories/wordpressRepo';
import { router } from '@/router';
import store from '@/store';
import { useHostingSetupStore, useProfileStore } from '@/stores';
import type { CMSDetails } from '@/types';
import { Onboarding, Route, Hosting, Header } from '@/types';
import { mapKeyValue, toUnicode } from '@/utils/helpers';

export const useOnboarding = () => {
  const route = useRoute();
  const profileStore = useProfileStore();
  const { isProHostingSetupOnboarding } = useOnboardingStatusCheck();
  const hostingSetupStore = useHostingSetupStore();
  const { getResourceBySubscriptionId } = useResourceMapper();

  const routeName = computed(() => route.name as Route.Email);
  const currentOrderDetails = computed(() => store.getters.currentOrderDetails);

  const onboardingOrder = computed(() =>
    getResourceBySubscriptionId(route.params.order_id as string),
  );

  const hasHigherPlan = computed(() => {
    const upgradablePlans = [
      Hosting.Plan.STARTER,
      Hosting.Plan.PREMIUM,
      Hosting.Plan.WORDPRESS_SINGLE,
      Hosting.Plan.WORDPRESS_PREMIUM,
    ];

    return !upgradablePlans.includes(currentOrderDetails.value?.plan);
  });

  const isEmailOnboarding = computed(() =>
    [
      Route.Email.EMAIL_ONBOARDING_BUY_DOMAIN,
      Route.Email.EMAIL_ONBOARDING_SETUP_DOMAIN,
      Route.Email.EMAIL_ONBOARDING_EXISTING_DOMAIN,
      Route.EmailPro.EMAIL_ONBOARDING_BUY_DOMAIN,
      Route.EmailPro.EMAIL_ONBOARDING_SETUP_DOMAIN,
      Route.EmailPro.EMAIL_ONBOARDING_EXISTING_DOMAIN,
    ].includes(routeName.value),
  );

  const isTitanEmailOnboarding = computed(() =>
    [
      Route.Email.TITAN_ONBOARDING_DOMAIN_BUY,
      Route.Email.TITAN_ONBOARDING_DOMAIN_SELECT,
      Route.Email.TITAN_ONBOARDING_DOMAIN_EXISTING,
      Route.Email.TITAN_ONBOARDING_START,
      Route.Email.TITAN_ONBOARDING_FINISH,
    ].includes(routeName.value),
  );
  const currentOnboardingType = computed(() =>
    isEmailOnboarding.value
      ? Onboarding.Type.EMAIL_ONBOARDING
      : Onboarding.Type.ONBOARDING,
  );
  const nextStep = (step: Onboarding.Step, params = {} as any) => {
    if (['is-experienced', 'select-application'].includes(step)) {
      store.commit('hostingMigrations/SET_SKIPPED_MIGRATIONS_DATA', {
        type: 'remove',
        data: store.getters.getOnboardingDomainId,
      });
    }

    if (
      step === Onboarding.Step.BUILD_OR_MIGRATE &&
      route.params?.website_type === Onboarding.Platform.WORDPRESS
    ) {
      router.push({
        name: Route.Onboarding.SELECT_WORDPRESS,
        params: router.currentRoute.value.params,
        query: router.currentRoute.value.query,
      });
      store.commit('setSelectedCms', Onboarding.Platform.WORDPRESS);
      store.commit('setAddStep', Route.Onboarding.SELECT_WORDPRESS);

      return;
    }

    switch (step) {
      case Onboarding.Step.CREATE_WEBSITE:
        router.push({
          name: Route.Onboarding.START,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.START);
        break;
      case Onboarding.Step.SURVEY:
        router.push({
          name: Route.Onboarding.SURVEY,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.SURVEY);
        break;
      case Onboarding.Step.WEBSITE_TYPE_SURVEY:
        router.push({
          name: Route.Onboarding.WEBSITE_TYPE_SURVEY,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.WEBSITE_TYPE_SURVEY);
        break;
      case Onboarding.Step.BUILD_OR_MIGRATE:
        router.push({
          name: Route.Onboarding.BUILD_OR_MIGRATE,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.BUILD_OR_MIGRATE);
        break;
      case Onboarding.Step.SELECT_APPLICATION:
        router.push({
          name: Route.Onboarding.TOOL,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.TOOL);
        break;
      case Onboarding.Step.OTHER_PLATFORMS:
        router.push({
          name: Route.Onboarding.OTHER_PLATFORMS,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.OTHER_PLATFORMS);
        break;
      case Onboarding.Step.SELECT_WORDPRESS:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.SELECT_WORDPRESS);
        break;
      case Onboarding.Step.RECOMMMENDED_TOOLS:
        router.push({
          name: Route.Onboarding.RECOMMENDED,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.RECOMMENDED);
        break;
      case Onboarding.Step.SELECT_TRANSFER:
        router.push({
          name: Route.Onboarding.MIGRATION,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.MIGRATION);
        break;
      case Onboarding.Step.CHOOSEN_APP:
        switch (params.app) {
          case 'wordpress':
          case 'woo':
            nextStep(Onboarding.Step.CHOOSE_TEMPLATE);
            break;

          default:
            nextStep(Onboarding.Step.CHOOSE_APP_NAME);
        }
        break;
      case Onboarding.Step.EXISTING_DOMAIN:
        router.push({
          name: Route.Onboarding.EXISTING_DOMAIN,
          params: { ...router.currentRoute.value.params, ...params },
          query: { handleSearch: params.handleSearch },
        });
        store.commit('setAddStep', Route.Onboarding.EXISTING_DOMAIN);
        break;
      case Onboarding.Step.BUY_NEW_DOMAIN:
        router.push({
          name: Route.Onboarding.BUY_NEW_DOMAIN,
          params: { ...router.currentRoute.value.params, ...params },
          query: { handleSearch: params.handleSearch },
        });
        store.commit('setAddStep', Route.Onboarding.BUY_NEW_DOMAIN);
        break;

      case Onboarding.Step.EXISTING_DOMAIN_SUMMARY:
        router.push({
          name: Route.Onboarding.EXISTING_DOMAIN_SUMMARY,
          params: { ...router.currentRoute.value.params, ...params },
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.EXISTING_DOMAIN_SUMMARY);
        break;

      case Onboarding.Step.CREATE_CPANEL_PASSWORD:
        router.push({
          name: Route.Onboarding.CPANEL_PASSWORD,
          params: { ...router.currentRoute.value.params, ...params },
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.CPANEL_PASSWORD);
        break;
      case Onboarding.Step.SELECT_WORDPRESS_PLUGIN:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS_PLUGIN,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.SELECT_WORDPRESS_PLUGIN);
        break;
      case Onboarding.Step.SELECT_WORDPRESS_PRESETS:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS_PRESETS,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.SELECT_WORDPRESS_PRESETS);
        break;
      case Onboarding.Step.SELECT_WORDPRESS_AI_CONTENT:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS_AI_CONTENT,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit(
          'setAddStep',
          Route.Onboarding.SELECT_WORDPRESS_AI_CONTENT,
        );
        break;
      case Onboarding.Step.CHOOSE_TEMPLATE:
        router.push({
          name: Route.Onboarding.WP_TEMPLATES,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.WP_TEMPLATES);
        break;
      case Onboarding.Step.SELECT_WORDPRESS_THEME_COLOR:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS_THEME_COLOR,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit(
          'setAddStep',
          Route.Onboarding.SELECT_WORDPRESS_THEME_COLOR,
        );
        break;
      case Onboarding.Step.SELECT_WORDPRESS_THEME_FONT:
        router.push({
          name: Route.Onboarding.SELECT_WORDPRESS_THEME_FONT,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit(
          'setAddStep',
          Route.Onboarding.SELECT_WORDPRESS_THEME_FONT,
        );
        break;
      case Onboarding.Step.CHOOSE_APP_NAME:
        router.push({
          name: Route.Onboarding.CHOOSE_APP_NAME,
          params: { ...router.currentRoute.value.params, ...params },
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.CHOOSE_APP_NAME);
        break;
      case Onboarding.Step.OVERVIEW:
        router.push({
          name: Route.Onboarding.OVERVIEW,
          params: {
            ...router.currentRoute.value.params,
            ...params,
          },
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.OVERVIEW);
        break;
      case Onboarding.Step.BEING_BUILT:
        router.push({
          name: Route.Hosting.BEING_BUILT,
          params: { ...router.currentRoute.value.params, ...params },
          query: router.currentRoute.value.query,
        });
        break;
      case Onboarding.Step.ONBOARDING_COMPLETED:
        router.push({
          name: Route.Onboarding.COMPLETED,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.COMPLETED);
        break;
      case Onboarding.Step.ONBOARDING_DOMAIN_SETUP:
        router.push({
          name: Route.Onboarding.DOMAIN_SETUP,
          params: {
            ...router.currentRoute.value.params,
            domain_order_id: params.domain_order_id,
          },
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.COMPLETED);
        break;
      case Onboarding.Step.ONBOARDING_CPANEL_GETTING_READY:
        router.push({
          name: Route.Onboarding.CPANEL_GETTING_READY,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.COMPLETED);
        break;

      case Onboarding.Step.THEME_CARD_PREVIEW:
        router.push({
          name: Route.Onboarding.THEME_CARD_PREVIEW,
          params: router.currentRoute.value.params,
          query: router.currentRoute.value.query,
        });
        store.commit('setAddStep', Route.Onboarding.THEME_CARD_PREVIEW);
        break;
    }
  };

  const isAddonOnboarding = computed(
    () => route.params.type === Route.OnboardingParam.ADDON,
  );

  const isWebsiteBuilderSelected = computed(
    () => store.getters.getSelectedCms === Onboarding.Platform.BUILDER,
  );

  const addonType = computed(() => store.getters.getAddonType);

  const isNoneCmsSelected = computed(
    () => store.getters.getSelectedCms === Onboarding.Platform.EMPTY,
  );

  const isSurveyBlogSelected = computed(() =>
    [store.getters.getSurveyData.website_type, addonType.value].includes(
      Onboarding.Survey.WebsiteType.BLOG,
    ),
  );

  const isCurrentRouteHostingerBlogTemplate = computed(() =>
    [
      Route.Onboarding.SELECT_WORDPRESS_THEME_COLOR,
      Route.Onboarding.SELECT_WORDPRESS_THEME_FONT,
    ].includes(route.name as Route.Onboarding),
  );

  const isSingleOrPremium = computed(() => {
    const PLAN_SINGLE = 'single';
    const PLAN_PREMIUM = 'premium';
    const PLAN_STARTER = 'starter';

    return [PLAN_SINGLE, PLAN_PREMIUM, PLAN_STARTER].some((plan: string) =>
      onboardingOrder.value?.config?.plan?.includes(plan),
    );
  });

  const isWordpressPlan = computed(
    () =>
      (store.getters.isOrderWooPlan as boolean) ||
      (store.getters.isOrderWordPressPlan as boolean),
  );

  const wordpressHostingFirstStep = computed(() =>
    isWordpressPlan.value
      ? Onboarding.Step.SELECT_WORDPRESS
      : Onboarding.Step.BUILD_OR_MIGRATE,
  );

  const isOtherCmsSelected = computed(
    () =>
      ![
        Onboarding.Platform.BUILDER,
        Onboarding.Platform.WORDPRESS,
        Onboarding.Platform.EMPTY,
      ].includes(store.getters.getSelectedCms),
  );

  const isWordPressSelected = computed(
    () => store.getters.getSelectedCms === Onboarding.Platform.WORDPRESS,
  );

  const shouldSkipOnboardingOverview = computed(
    () => isAddonOnboarding.value || isWebsiteBuilderSelected.value,
  );

  const saveCMSDetails = (
    selectedCMS: Onboarding.Platform,
    credentials: CMSDetails = { email: '', password: '' },
  ) => {
    store.commit('setSelectedCms', selectedCMS);
    store.commit('setSelectedCmsName', getCMSName(selectedCMS));

    store.commit('setCmsCredentials', {
      cmsDetails: credentials,
      cms: selectedCMS,
    });
    store.commit('setCmsTemplate');
    store.commit('setMigrationDetails', {});
    store.commit('storeFileUploadLink');
    store.commit('storeFileUploadName');
  };
  const getCMSName = (selectedCMS: Onboarding.Platform) => {
    const CMS_NAMES = {
      [Onboarding.Platform.WORDPRESS]: 'WordPress',
      [Onboarding.Platform.WOO]: 'WordPress with WooCommerce',
      [Onboarding.Platform.JOOMLA]: 'Joomla',
      [Onboarding.Platform.BUILDER]: 'Hostinger Website Builder',
      default: Onboarding.Platform.OTHER,
    };

    return mapKeyValue(CMS_NAMES, selectedCMS);
  };

  const clearCmsData = () => {
    store.dispatch('clearCmsData');
  };

  const clearWebsiteImport = () => {
    store.commit('storeFileUploadLink');
    store.commit('storeFileUploadName');
  };

  const setSkippedOnboarding = () => {
    store.commit('SET_SKIPPED_ONBOARDING', true);
  };

  const getWpPresetData = () => {
    if (isProHostingSetupOnboarding.value) {
      return {
        theme: hostingSetupStore.selectedWordPressTheme,
        plugins: hostingSetupStore.selectedWordPressPluginsFlat,
        domain: toUnicode(hostingSetupStore.selectedDomain),
      };
    }

    return {
      theme: store.getters.getCmsTemplate,
      plugins: store.getters.getSelectedWordpressPlugins,
      domain: store.getters.getOnboardingDomainName,
    };
  };

  const createWordPressPresetFromSelection = async () => {
    if (!isWordPressSelected.value) return;
    const { theme, plugins, domain } = getWpPresetData();
    if (!plugins.length && !theme) return;

    const headers = {
      [Header.CLIENT_ID]: profileStore.clientOrAccessManagerClientId || '',
    };

    const requestBody = {
      name: `${domain} template`,
      collection: {
        plugins: plugins?.length ? plugins : [],
        themes: theme ? [theme] : [],
      },
    };

    await wordpressRepo.postWordpressCollection({
      data: requestBody,
      headers,
    });
  };

  const handleLeaveOnboarding = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    if (to.name === Route.Onboarding.START && from.query?.preselectedPlatform) {
      router.push({ name: Route.Base.WEBSITES });

      return false;
    }

    return true;
  };

  return {
    nextStep,
    isAddonOnboarding,
    isWebsiteBuilderSelected,
    isNoneCmsSelected,
    shouldSkipOnboardingOverview,
    isEmailOnboarding,
    isTitanEmailOnboarding,
    currentOnboardingType,
    isWordPressSelected,
    isWordpressPlan,
    isOtherCmsSelected,
    wordpressHostingFirstStep,
    isSurveyBlogSelected,
    isSingleOrPremium,
    saveCMSDetails,
    clearCmsData,
    setSkippedOnboarding,
    clearWebsiteImport,
    addonType,
    hasHigherPlan,
    isCurrentRouteHostingerBlogTemplate,
    createWordPressPresetFromSelection,
    handleLeaveOnboarding,
  };
};
