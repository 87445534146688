import { seoRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  allowManage: false,
  scans: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setHostingWebsiteSeoToolkitData(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'allowManage', data.allowManage);
      setForDomain(state, requestDomain, 'scans', data.scans);
    },
    setHostingWebsiteSeoToolkitLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getHostingWebsiteSeoToolkitScans: (state) =>
      getStateVariable(state, 'scans'),
    getHostingWebsiteSeoToolkitAllowManage: (state) =>
      getStateVariable(state, 'allowManage'),
    getHostingWebsiteSeoToolkitLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingWebsiteSeoToolkitIndex(context) {
      const [{ data, requestDomain }, err] = await seoRepo.getSeoShow();

      if (!err) {
        context.commit('setHostingWebsiteSeoToolkitData', {
          data,
          requestDomain,
        });
        context.commit('setHostingWebsiteSeoToolkitLoaded', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingWebsiteSeoToolkitShow(context, id) {
      return await seoRepo.getSeoIndex(id);
    },
  },
};
