<script setup lang="ts">
//JSON-slugs-migrated
import { onMounted } from 'vue';

import { useGlobals, useImpersonation } from '@/composables';
import { useProfileStore } from '@/stores';

interface Emits {
  (eventName: 'banner-mounted'): void;
}
const emit = defineEmits<Emits>();
const { t } = useGlobals();

const { exitImpersonationMode } = useImpersonation();
const profileStore = useProfileStore();

onMounted(() => {
  emit('banner-mounted');
});
</script>

<template>
  <div class="access-manager-banner h-px-40">
    <Trans
      class="text-bold-2"
      :translate-params="{ email: profileStore.contact?.email }"
    >
      v2.you.are.managing.email.account
    </Trans>
    <hp-hyper-link bold :color.prop="'dark'" @action="exitImpersonationMode">
      {{ t('v2.exit') }}
    </hp-hyper-link>
  </div>
</template>

<style lang="scss" scoped>
.access-manager-banner {
  padding: 8px 0;
  display: flex;
  align-items: center;
  background-color: var(--warning);
  justify-content: space-between;
}
</style>
