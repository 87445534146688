<template>
  <div>
    <Trans
      :translate-params="{
        hostingName: data.hostingName,
        startDate: toLocalTime(data.startDate, 'HH:mm'),
      }"
      tag="p"
      class="h-px-40"
    >
      Maintenance for
      <strong>{hostingName}</strong>
      plan is currently in progress (started at {startDate} your local time). As
      a result, your website(s) under {hostingName} plan might be unavailable
      for 60 minutes.
    </Trans>
    <div class="d-flex justify-content-end h-p-24">
      <HButtonV1 no-margin primary text @click="close">Close</HButtonV1>
    </div>
  </div>
</template>

<script>
import modalsMixin from '../../../utils/mixins/modalsMixin';

import { toLocalTime } from '@/utils/helpers';

export default {
  name: 'MaintenanceInProgressModal',
  mixins: [modalsMixin],
  methods: { toLocalTime },
};
</script>
