import { hDnsRepo } from '@/repositories';
import type {
  HDnsState,
  DnsProviderDataModel,
  DnsProviderDataDataModel,
  CommitFunction,
} from '@/types';
import { HDnsMutations, HDnsActions, HDnsGetters } from '@/types';
import { toASCII } from '@/utils/helpers';

const state: HDnsState = {
  dnsProviderData: [],
};

const mutations = {
  [HDnsMutations.SET_PROVIDER_DATA](
    state: HDnsState,
    data: DnsProviderDataModel,
  ) {
    const { domain: newDomain } = data;
    const dataExistsForDomain = state.dnsProviderData.findIndex(
      ({ domain }) => domain === newDomain,
    );
    if (dataExistsForDomain >= 0) {
      state.dnsProviderData[dataExistsForDomain] = data;
    } else {
      state.dnsProviderData.push(data);
    }
  },
};

const getters = {
  [HDnsGetters.GET_DNS_PROVIDER_DATA_BY_DOMAIN]:
    (state: HDnsState) =>
    (domainToFind: string): DnsProviderDataDataModel | null => {
      const { data } = state.dnsProviderData.find(
        ({ domain }) => domain === domainToFind,
      ) || { data: null };

      return data;
    },
};

const actions = {
  async [HDnsActions.FETCH_DNS_PROVIDER_DATA](
    { commit }: CommitFunction,
    domain: string,
  ) {
    const [{ data }, err] = await hDnsRepo.getDomainDNSProvider(
      toASCII(domain.toLowerCase()),
    );
    commit(HDnsMutations.SET_PROVIDER_DATA, { data: data || null, domain });

    return err;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
