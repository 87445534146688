import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { ICON_CONFIGURATION } from '@/data/paymentIcons';
import { hBillingRepo, razorpayRepo } from '@/repositories';
import store from '@/store/index';
import type { PaymentDetails, PaymentMethod } from '@/types';
import { Payment, HIcon } from '@/types';
import {
  snakeToPascal,
  snakeToCamelObj,
} from '@/utils/services/namingConventionsService';

const CARD_NUMBER_REGEX =
  '^(?<visa>4[0-9])|' +
  '(?<maestro>(?:5[0678]|6304|6390|67))|' +
  '(?<mastercard>5[1-5][0-9])|' +
  '(?<discover>6(?:011|5[0-9]{2})[0-9])|' +
  '(?<amex>3[47][0-9])|' +
  '(?<diners>3(?:0[0-5]|[68][0-9])?[0-9])|' +
  '(?<jcb>(?:2131|1800|35[0-9]{3})[0-9])$';

export const usePaymentMethodStore = defineStore('paymentMethodStore', () => {
  const defaultCard = ref<PaymentMethod['identifier'] | null>(null);
  const selectedPaymentMethod = ref<PaymentMethod | null>(null);
  const selectedPaymentMethodId = ref<string | null>(null);

  const paymentMethod = computed<PaymentMethod | null>(() => {
    if (selectedPaymentMethodId.value) {
      if (selectedPaymentMethod.value?.isExpired) {
        return null;
      }

      return selectedPaymentMethod.value;
    }

    return store.getters['paymentMethods/getDefaultPaymentMethod'];
  });

  const razorPayDigits = ref<
    Record<
      number,
      { digits: string | null; revealed: boolean; loading: boolean }
    >
  >({});

  const revealRazorPayDigits = (paymentMethod: PaymentMethod) => {
    if (!razorPayDigits.value[paymentMethod.id]) {
      razorPayDigits.value[paymentMethod.id] = {
        digits: null,
        revealed: false,
        loading: false,
      };
    }

    if (razorPayDigits.value[paymentMethod.id].loading) {
      return;
    }

    if (razorPayDigits.value[paymentMethod.id].revealed) {
      clearRazorpayDataById(paymentMethod);

      return;
    }

    loadRazorpayDigits(paymentMethod);
  };

  const loadRazorpayDigits = async (paymentMethod: PaymentMethod) => {
    razorPayDigits.value[paymentMethod.id].loading = true;
    razorPayDigits.value[paymentMethod.id].revealed = false;
    const [{ data }, err] = await razorpayRepo.getRazorpayCardLastFourDigits(
      paymentMethod.id,
    );

    if (data) razorPayDigits.value[paymentMethod.id].digits = data.digits;

    razorPayDigits.value[paymentMethod.id].loading = false;
    razorPayDigits.value[paymentMethod.id].revealed = true;
    if (err) clearRazorpayDataById(paymentMethod);
  };

  const clearRazorpayDataById = (paymentMethod: PaymentMethod) => {
    delete razorPayDigits.value[paymentMethod.id];
  };

  const clearAllRazorpayData = () => {
    razorPayDigits.value = {};
  };

  const clearSelectedData = () => {
    selectedPaymentMethodId.value = null;
    selectedPaymentMethod.value = null;
  };

  const razorPayDetails = (paymentMethod: PaymentMethod) =>
    razorPayDigits.value?.[paymentMethod.id] ?? {
      digits: null,
      revealed: false,
      loading: false,
    };

  const setSelectedPaymentMethod = (id: string | null) => {
    if (!id || id === Payment.NEW_PAYMENT_METHOD) {
      selectedPaymentMethodId.value = id;
      selectedPaymentMethod.value = null;

      return;
    }

    const paymentMethodById = getPaymentMethodById(id) ?? null;

    if (paymentMethodById) {
      selectedPaymentMethodId.value = id;

      selectedPaymentMethod.value = paymentMethodById;
    }
  };

  const getPaymentMethodById = (id: number | string): PaymentMethod | null =>
    store.getters['paymentMethods/getPaymentMethodById'](Number(id)) ?? null;

  const setDefaultCard = (
    data: PaymentMethod['identifier'],
    id: PaymentMethod['id'],
  ) => {
    defaultCard.value = data;
    setSelectedPaymentMethod(String(id));
  };

  const getAlternativePaymentMethodIcon = ({
    paymentMethod: methodName,
  }: PaymentMethod) => {
    if (!methodName) return `icDefault`;

    const alternativeMethod = ICON_CONFIGURATION.find(
      ({ method }) => method === methodName,
    );

    if (!alternativeMethod) return 'ic-credit-card';

    return alternativeMethod.iconName;
  };

  const getPaymentIconName = (data?: PaymentDetails | PaymentMethod) => {
    let paymentDetails: any;

    if (!data) {
      paymentDetails = store.getters['billing/getFullPaymentMethods'].find(
        (method: PaymentMethod) => method.identifier === defaultCard.value,
      );
    } else {
      paymentDetails = data;
    }

    paymentDetails = snakeToCamelObj(paymentDetails);

    if (paymentDetails?.paymentMethod === Payment.Method.APPLEPAY) {
      return HIcon.ICON_APPLE_PAY;
    }

    if (paymentDetails?.paymentMethod === Payment.Method.UPI) {
      return HIcon.ICON_UPI_PAYMENT;
    }

    if (paymentDetails?.paymentMethod !== Payment.Method.CARD) {
      return getAlternativePaymentMethodIcon(paymentDetails);
    }

    if (
      paymentDetails?.merchantAccount === Payment.Method.RAZORPAY &&
      paymentDetails?.paymentMethod === Payment.Method.CARD
    ) {
      const cards = {
        Visa: 'visa',
        Maestro: 'maestro',
        MasterCard: 'mastercard',
        Discover: 'discover',
        Amex: 'amex',
        Diners: 'diners',
        JCB: 'jcb',
      };

      const cardsKeys = Object.keys(cards);
      const cardKey = cardsKeys.find((key) =>
        paymentDetails?.identifier.includes(key),
      ) as keyof typeof cards;

      if (!cardKey) return 'icDefault';

      const iconName = `ic_${cards[cardKey]}`;

      return `${snakeToPascal(iconName)}`;
    }

    try {
      const matches = paymentDetails?.identifier.match(
        new RegExp(CARD_NUMBER_REGEX),
      );

      if (matches && matches.groups) {
        const iconName = `ic_${Object.keys(matches.groups).find(
          (key) => matches.groups[key],
        )}`;

        return `${snakeToPascal(iconName)}`;
      }
    } catch (e) {
      // Do Nothing
    }

    return `icDefault`;
  };

  const getCardType = (number: string) => {
    if (!number) return 'default';

    try {
      const matches = number.match(new RegExp(CARD_NUMBER_REGEX));

      if (matches && matches.groups) {
        return Object.keys(matches.groups).find((key) => matches.groups?.[key]);
      }
    } catch (e) {
      // Do Nothing
    }

    return `icDefault`;
  };

  const fetchAndSetPaymentMethods = async () => {
    const [{ data }, error] = await hBillingRepo.getPaymentMethods();

    if (!data || error) return;

    const cardIdentifier = data.find(
      ({ identifier }) => !!identifier,
    )?.identifier;

    if (!error && cardIdentifier) {
      defaultCard.value = cardIdentifier;
    }
  };

  return {
    getPaymentMethodById,
    paymentMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    selectedPaymentMethodId,
    defaultCard,
    setDefaultCard,
    getAlternativePaymentMethodIcon,
    getPaymentIconName,
    getCardType,
    fetchAndSetPaymentMethods,
    revealRazorPayDigits,
    razorPayDetails,
    clearAllRazorpayData,
    clearSelectedData,
  };
});
