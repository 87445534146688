import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useStore } from 'vuex';

import { websiteRepo } from '@/repositories';
import { STORE_PERSISTENT_KEYS, Website } from '@/types';
import type { AccountVhost, ProWebsiteType } from '@/types';

export const useHostingDashboardStore = defineStore(
  'hostingDashboardStore',
  () => {
    const store = useStore();

    const isWordPressWebsite = ref(false);
    const websiteDetails = ref<ProWebsiteType | null>(null);

    const getVhostDetailsByDomain = (domain: string) =>
      store.getters.currentOrderDetails?.vhosts.find(
        (vhostItem: AccountVhost) => vhostItem.vhost === domain,
      );

    const fetchWebsiteDetails = async (domain: string) => {
      const [{ data }, err] = await websiteRepo.getProList({
        page: 1,
        perPage: 100,
        search: domain,
      });

      if (err || !data) return;

      websiteDetails.value =
        data.data.find((item: ProWebsiteType) => item.website === domain) ||
        data.data[0];

      isWordPressWebsite.value =
        websiteDetails.value?.type === Website.Type.WORDPRESS;
    };

    const getIsFreeSubdomain = (domain: string) =>
      getVhostDetailsByDomain(domain)?.isFreeSubdomain;

    return {
      websiteDetails,
      fetchWebsiteDetails,
      getIsFreeSubdomain,
      isWordPressWebsite,
      getVhostDetailsByDomain,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.HOSTING_DASHBOARD_STORE },
  },
);
