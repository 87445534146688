import DomainAutoConnectLoaderModal from '@/components/Modals/HModal/DomainAutoConnectLoaderModal.vue';
import { useModal, useGlobals } from '@/composables';
import type { EntriConfigModel } from '@/types';
import { Entri, AmplitudeEvent } from '@/types';
import { performEntriConnection } from '@/utils/helpers/entriHelper';
import { errorLogger } from '@/utils/services/errorLogging';

export const useEntri = () => {
  const { amplitudeV2 } = useGlobals();
  const { openModal, closeModal } = useModal();

  const entriFailure = (connectionResult?: string) => {
    amplitudeV2(AmplitudeEvent.Onboarding.DOMAIN_AUTOCONNECT_FAILURE, {
      reason: connectionResult,
    });

    return Entri.SetupStatus.FAILURE;
  };

  const onEntriStart = async ({
    config,
    provider,
  }: {
    config: EntriConfigModel;
    provider?: string;
  }) => {
    const UNKNOWN_PROVIDER = 'Unknown';
    try {
      openModal({
        component: { DomainAutoConnectLoaderModal },
        data: {
          provider:
            provider === UNKNOWN_PROVIDER || !provider ? null : provider,
        },
      });
      const connectionResult = await performEntriConnection({ ...config });

      closeModal();

      if (connectionResult === Entri.CloseStatus.FINISHED_SUCCESSFULLY) {
        amplitudeV2(AmplitudeEvent.Onboarding.DOMAIN_AUTOCONNECT_SUCCESS);

        return Entri.SetupStatus.SUCCESS;
      }

      return entriFailure();
    } catch (error) {
      errorLogger.logError(error as Error);

      return entriFailure();
    }
  };

  return {
    onEntriStart,
  };
};
