import type { SurveyForWhoAnswers, WordPressWebsiteTypeAnswer } from '@/types';

export interface ISurveyAnswer {
  questionSlug: 'website_type' | 'website_for' | 'vps_for' | 'vps_type';
  answer: SurveyForWhoAnswers | WordPressWebsiteTypeAnswer | string;
}

export const ONBOARDING_SURVEY_IDENTIFIER = {
  HOSTING: 'hosting_onboarding',
  VPS: 'vps_onboarding',
} as const;
