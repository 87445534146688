<script setup lang="ts">
import type { FieldOption, FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useRouter, useRoute } from 'vue-router';

import {
  useAddWebsite,
  useGlobals,
  useModal,
  useExperiment,
} from '@/composables';
import {
  useHostingerProStore,
  useHostingSetupStore,
  useProfileStore,
} from '@/stores';
import type { HostingOrderType, ModalEmits } from '@/types';
import { Route, FieldType, AmplitudeEvent, Experiment } from '@/types';
import { mapKeyValue, toUnicode } from '@/utils/helpers';

type Props = {
  data: {
    hostingOrders: HostingOrderType[];
    topSelectionOption?: { field: FieldOption; route?: RouteLocationNamedRaw };
    onSuccess?: Function;
    hasPurchaseOption?: boolean;
    shouldLoadOnConfirmAction?: boolean;
  };
};
interface Emits extends ModalEmits {}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const hostingerProStore = useHostingerProStore();

const { isExperimentActive: isWpPresetsExperimentActive } = useExperiment(
  Experiment.ID.WEBPRO_WP_PRESETS,
);
const hostingSetupStore = useHostingSetupStore();
const route = useRoute();
const router = useRouter();
const { t, amplitudeV2 } = useGlobals();
const isLoadingConfirmAction = ref(false);

const isHostingPurchaseAvailable = computed(
  () =>
    'hasPurchaseOption' in props.data && props.data.hasPurchaseOption !== false,
);

const defaultTopSelectionOption = computed(() =>
  isHostingPurchaseAvailable.value
    ? {
        field: {
          label: t('Get new hosting plan'),
          value: Route.Base.BUY_HOSTING,
        },
        route: undefined,
      }
    : undefined,
);

const { close } = useModal(emits);

const { getIsLimitReached, getIsManagedOrder } = useAddWebsite();
const profileStore = useProfileStore();
const isLimitExceeded = ref(false);
const selectedPlanName = ref<HostingOrderType>();

const hostingSelectSchema = computed(() => ({
  type: FieldType.select,
  label: t('Select Hosting Plan'),
  custom: {
    itemsPerList: 7,
  },
  values: createSelectionList(),
  validation: {
    required: true,
  },
}));

const enterPageAmplitudeEventConfig = computed(() => {
  const AMPLITUDE_EVENT_CONFIG = {
    [Route.HostingerPro.SELECT_ONBOARDING_FLOW]: {
      event:
        AmplitudeEvent.HostingerProSpace
          .ONBOARDING_ADD_WEBSITE_OWN_ACCOUNT_SELECTED,
    },
    default: {
      event: null,
    },
  };

  return mapKeyValue(AMPLITUDE_EVENT_CONFIG, route.name as string);
});

const topSelectionOption = computed(
  () => props.data.topSelectionOption || defaultTopSelectionOption.value,
);

const createSelectionList = () => {
  const orders = props.data.hostingOrders.map((hostingOrder) => {
    const { title, username, orderId, domain } = hostingOrder;
    const email =
      'resource' in hostingOrder
        ? hostingOrder.resource.metadata?.customerEmail
        : profileStore.contact?.email;

    return {
      label: `${email} - ${title} - ${toUnicode(domain) || t('pending setup')}`,
      value: String(username || orderId),
    };
  });

  return topSelectionOption.value
    ? [topSelectionOption.value.field, ...orders]
    : orders;
};

const getCurrentOrder = (selectedHosting: string) =>
  props.data.hostingOrders.find(({ username, orderId }) => {
    const identifier = String(username || orderId);

    return identifier === selectedHosting;
  });

const goToCart = () => {
  if (selectedPlanName.value && 'resource' in selectedPlanName.value) {
    hostingerProStore.recipientEmail =
      selectedPlanName.value.resource.metadata.customerEmail;

    return router.push({
      name: Route.Referral.SELECT_HOSTING_PLAN,
      query: route.query,
    });
  }

  return router.push({
    name: Route.Base.BUY_HOSTING,
    query: route.query,
  });
};

const onChange = ({ values }: FormValues) => {
  const routeName =
    topSelectionOption.value?.route?.name ||
    topSelectionOption.value?.field.value;

  if (values.selectedHosting === topSelectionOption.value?.field.value) {
    return router.push({
      name: routeName,
      query: topSelectionOption.value?.route?.query,
    });
  }
  const currentOrder = getCurrentOrder(values.selectedHosting);
  selectedPlanName.value = currentOrder;

  if (getIsLimitReached(currentOrder)) {
    isLimitExceeded.value = true;

    return;
  }

  isLimitExceeded.value = false;
};

const sendAmplitudeEvent = (currentOrder?: HostingOrderType) => {
  const isManagedOrder = currentOrder ? getIsManagedOrder(currentOrder) : false;
  amplitudeV2(AmplitudeEvent.HostingerProSpace.ADD_WEBSITE_CLIENT_SELECTED, {
    isWebproService: !isManagedOrder,
  });
};

const onSubmit = async ({ values }: FormValues) => {
  const currentOrder = getCurrentOrder(values.selectedHosting);
  sendAmplitudeEvent(currentOrder);

  hostingSetupStore.isActivePresetsExperiment =
    isWpPresetsExperimentActive.value;

  if (props.data.shouldLoadOnConfirmAction) {
    isLoadingConfirmAction.value = true;
  }

  if (props.data.onSuccess) await props.data.onSuccess(currentOrder);
  close();

  isLoadingConfirmAction.value = false;
};

const triggerAmplitudeEvent = () => {
  amplitudeV2(enterPageAmplitudeEventConfig.value.event);
};

(() => {
  triggerAmplitudeEvent();
})();
</script>

<template>
  <div>
    <HForm @on-change="onChange($event)" @on-submit="onSubmit($event)">
      <HFormField
        name="selectedHosting"
        :schema="hostingSelectSchema"
        class="h-mt-8 h-mb-16"
      />
      <HSnackbar
        v-if="isLimitExceeded"
        background-color="gray-light"
        class="h-mb-16"
      >
        <span>
          <Trans :translate-params="{ plan: selectedPlanName?.title }">
            You have reached the maximum number of websites allowed to host
            under {plan}.
          </Trans>
          <span
            v-if="isHostingPurchaseAvailable"
            class="purchase-new-plan-link"
            @click="goToCart"
          >
            {{ t('Purchase new Hosting plan') }}
          </span>
        </span>
      </HSnackbar>
      <div class="text-right">
        <HButton v-qa-generate class="h-mr-8" variant="text" @click="close">
          {{ t('Cancel') }}
        </HButton>
        <HButton
          v-qa-generate
          :is-loading="isLoadingConfirmAction"
          :is-disabled="isLimitExceeded"
          h-form-submit
        >
          {{ t('Select plan') }}
        </HButton>
      </div>
    </HForm>
  </div>
</template>

<style lang="scss">
.purchase-new-plan-link {
  margin-left: 4px;
  cursor: pointer;
  font-weight: bold;
  color: var(--primary);
}
</style>
