import { useResourceMapper } from '@/composables';

const empty = {
  isEligible: false,
  eligibleFor: '',
  eligibleType: '',
  freeTlds: [],
};

export default {
  namespaced: true,
  state: {
    isEligible: false,
    eligibleFor: '',
    eligibleType: '',
    freeTlds: [],
  },
  mutations: {
    SET_FREE_DOMAIN_DATA(
      state,
      { isEligible, freeTlds, eligibleFor, eligibleType },
    ) {
      state.isEligible = isEligible;
      state.freeTlds = freeTlds;
      state.eligibleFor = eligibleFor;
      state.eligibleType = eligibleType;
    },
  },
  getters: {
    getIsEligible: (state) => state.isEligible,
  },
  actions: {
    fetchFreeDomainStatus({ commit }) {
      // TODO isChargeBeeClient this module should moved to store after chargebee migration

      const { getResourcesByTypesAndStates } = useResourceMapper();

      const freeDomainResources = getResourcesByTypesAndStates({
        types: ['free_domain'],
        states: ['pending_setup'],
      });

      if (!freeDomainResources.length) {
        commit('SET_FREE_DOMAIN_DATA', empty);

        return;
      }

      const freeDomain = freeDomainResources[0];

      commit('SET_FREE_DOMAIN_DATA', {
        isEligible: true,
        freeTlds: freeDomain.config.tlds.map((tld) => `.${tld}`),
      });
    },
  },
};
