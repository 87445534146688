import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Email, Route } from '@/types';

const getOrder = (id: string) =>
  store.getters['emails/getPremiumMailById'](Email.EmailProvider.GOOGLE, id);

const googleWorkspaceSetupGuard: NavigationGuard = async (to, from, next) => {
  if (!store.state.emails.loaded) await store.dispatch('emails/fetchEmails');
  const order = getOrder(to.params.orderId as string);

  if (order && order.status === 'active') {
    return next({
      name: Route.Email.GOOGLE_WORKSPACE_MANAGEMENT,
      params: { domain: order.domain },
    });
  }

  return next();
};

export default googleWorkspaceSetupGuard;
