<script setup lang="ts">
import { computed } from 'vue';
import type { RouteRecordName } from 'vue-router';

import HMenuItemWrapper from '@/components/HMenuV2/HMenuItemWrapper.vue';
import HPopover from '@/components/HPopover.vue';
import { useGlobals } from '@/composables/useGlobals';
import { useScreen } from '@/composables/useScreen';
import { useFrontendSettingsStore } from '@/stores/frontendSettingsStore';
import { useHPanelControlsStore } from '@/stores/hpanelControlsStore';
import { HIcon, type HIconUnion, type NavigationMenuItem } from '@/types';

const { t } = useGlobals();
type Props = {
  navigationItem: NavigationMenuItem;
  currentRoute?: RouteRecordName;
  expanded?: boolean;
  isChild?: boolean;
  appendIcon?: HIconUnion;
};

type Emits = {
  (eventName: 'toggle-expanded', event: Event, payload: string): void;
  (eventName: 'click', event: Event, payload: NavigationMenuItem): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { amplitudeV2 } = useGlobals();
const frontendSettingsStore = useFrontendSettingsStore();
const screen = useScreen();
const hpanelControlsStore = useHPanelControlsStore();

const isActive = computed(() => {
  if (
    props.currentRoute &&
    (props.currentRoute === props.navigationItem.to?.name ||
      props.currentRoute === props.navigationItem.slug)
  ) {
    return true;
  }

  if (props.navigationItem?.activeSubroutes?.length) {
    return props.navigationItem.activeSubroutes.includes(
      props?.currentRoute ?? '',
    );
  }

  return false;
});

const isPopoverActive = computed(
  () =>
    !!props.navigationItem.popover?.isActive &&
    frontendSettingsStore.isNavigationVisible &&
    !screen.isLarge.value &&
    hpanelControlsStore.navigationLevel === 1,
);

const handleItemClick = (
  event: Event,
  navigationItem: NavigationMenuItem,
  type: string,
) => {
  if (type === 'button' && navigationItem.children?.length) {
    emit('toggle-expanded', event, navigationItem.slug);

    return;
  }

  if (navigationItem.amplitude) {
    amplitudeV2(
      navigationItem.amplitude.eventName,
      navigationItem.amplitude.eventProperties,
    );
  }

  if (navigationItem.action) {
    navigationItem.action();
  }

  emit('click', event, navigationItem);
};
</script>

<template>
  <li class="hp-menu__item">
    <HPopover
      :is-active="isPopoverActive"
      placement="right"
      background-color="meteorite-dark-2"
      variant="small"
    >
      <template #trigger>
        <HMenuItemWrapper
          :navigation-item="navigationItem"
          class="hp-menu__item-link"
          :class="[
            {
              'hp-menu__item-link--child': isChild,
              'hp-menu__item-link--active':
                isActive && !navigationItem.children?.length,
            },
          ]"
          @click="handleItemClick"
        >
          <template #icon>
            <HpIcon
              v-if="navigationItem.icon"
              :key="navigationItem.icon"
              view-box="0 0 16 16"
              height="16"
              width="16"
              :color="isActive ? 'primary' : 'gray'"
              class="hp-menu__item-link-icon"
              :icon="(navigationItem.icon as HIconUnion)"
            />
          </template>
          <template #actions>
            <div class="hp-menu__item-right-container">
              <HpIcon
                v-if="navigationItem.children?.length"
                :key="`${navigationItem.slug}-dropdown-icon`"
                class="h-ml-0 hp-menu__item-dropdown-icon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                :class="{
                  'hp-menu__item-dropdown-icon--expanded': expanded,
                }"
                gray
                :icon="HIcon.IC_CHEVRON_UP_FILLED_16"
              />

              <HpIcon
                v-if="appendIcon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                gray
                :icon="appendIcon"
              />
            </div>
          </template>
          <span class="hp-menu__item-title">
            {{ t(navigationItem.title) }}
          </span>
          <template v-if="navigationItem.subtitle" #subtitle>
            <span class="hp-menu__item-subtitle">
              {{ t(navigationItem.subtitle) }}
            </span>
          </template>
        </HMenuItemWrapper>
      </template>
      <template #content>
        <Component :is="navigationItem.popover?.content" />
      </template>
    </HPopover>
  </li>
</template>

<style lang="scss" scoped>
.hp-menu {
  &__item {
    list-style-type: none;

    &-right-container {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &-dropdown-icon {
      transition: transform 0.3s;
      transform: rotate(180deg);

      &--expanded {
        transform: unset;
      }
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-link {
      justify-content: space-between;
    }

    &--disabled {
      color: var(--gray-darker);
    }
  }
}
</style>
