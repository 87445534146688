import flockManagementModule from './flockManagementModule';
import googleWorkspaceManagementModule from './googleWorkspaceManagementModule';
import hMailManagementModule from './hMailManagementModule';

import { useHostingerMail } from '@/composables';
import { EmailLegacyPlans } from '@/data/emails/emailPlans';
import { hMailIntegrationRepo, hostingEmailsRepo } from '@/repositories';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useResourcesStore } from '@/stores/resourcesStore';
import { SubscriptionStatus, SubscriptionResourceType, Email } from '@/types';
import { toASCII } from '@/utils/helpers';

const initialModuleState = {
  emailOrders: [],
  emails: [],
  loaded: false,
  isLoading: false,
};
export default {
  namespaced: true,
  state: initialModuleState,
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, initialModuleState);
    },
    SET_EMAILS: (state, payload) => {
      state.emails = payload;
    },
    SET_EMAILS_LOADED(state, payload) {
      state.loaded = payload;
    },
    SET_EMAILS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    UPDATE_HOSTINGER_EMAIL_ORDER(state, payload) {
      const updatedEmailOrders = state.emailOrders.map((item) =>
        item.id === payload.id
          ? {
              ...item,
              ...payload,
            }
          : item,
      );
      state.emailOrders = updatedEmailOrders;
    },
    SET_EMAIL_ORDERS: (state, payload) => {
      state.emailOrders = payload;
    },
  },
  getters: {
    getAvailableDomainsForEmailByType: (state) => (type) => {
      const resourcesStore = useResourcesStore();
      const resources = resourcesStore.resources;

      const isDomainUsedForEmail = (domain) =>
        state.emails.some(
          (email) => email.domain === domain && email.provider === type,
        );

      return resources.filter((domainResource) => {
        const isCorrectStatus = [
          SubscriptionStatus.ACTIVE,
          SubscriptionStatus.PENDING_SETUP,
        ].includes(domainResource.state);

        const isCorrectResourceType = [
          SubscriptionResourceType.DOMAIN,
          SubscriptionResourceType.FREE_DOMAIN,
        ].includes(domainResource.type);

        return (
          isCorrectStatus &&
          isCorrectResourceType &&
          !isDomainUsedForEmail(domainResource.title) &&
          domainResource.title
        );
      });
    },
    getPremiumMailByDomain: (state) => (currentDomain) =>
      state.emails.find(
        ({ domain }) =>
          domain === toASCII(currentDomain) || domain === currentDomain,
      ),
    getPremiumMail: (state) => (type, currentDomain) =>
      state.emails.find(
        ({ provider, domain }) =>
          provider === type &&
          (domain === toASCII(currentDomain) || domain === currentDomain),
      ),
    getPremiumMailById: (state) => (type, emailId) =>
      state.emails.find(
        ({ id, provider }) => provider === type && id === emailId,
      ),
    getActiveSetup: (state) => {
      const { isHostingerMailAvailable } = useHostingerMail();
      const availableEmailProvider = isHostingerMailAvailable.value
        ? Email.EmailProvider.HOSTINGER
        : Email.EmailProvider.TITAN;

      return state.emails?.filter(
        ({ provider, status }) =>
          provider === availableEmailProvider &&
          [Email.OrderStatus.PENDING_SETUP, Email.OrderStatus.INITIAL].includes(
            status,
          ),
      );
    },
    getHostingerPendingSetupById: (state) => (emailOrderId) =>
      state.emails.find(
        ({ status, orderId }) =>
          orderId === emailOrderId && status !== Email.OrderStatus.ACTIVE,
      ),
    getLoaded: (state) => state.loaded,
    getEmailOrderByResellerOrderId: (state) => (resellerOrderId) =>
      state.emailOrders.find(
        (emailOrder) => emailOrder.resellerOrderId === resellerOrderId,
      ),
    getLegacyHostingerMailOrders: (state) =>
      state.emailOrders.filter((emailOrder) =>
        EmailLegacyPlans.includes(emailOrder?.plan),
      ),
  },
  actions: {
    async fetchEmails({ commit, dispatch }) {
      commit('SET_EMAILS_LOADED', false);
      commit('SET_EMAILS_LOADING', true);

      await dispatch('fetchEmailOrders');

      let currentPage = 1;
      let lastPage = 1;
      let allEmails = [];
      do {
        const [{ data: emails, meta }] =
          await hMailIntegrationRepo.getAllServices({
            page: currentPage,
            perPage: 100,
          });

        lastPage = meta.lastPage;

        currentPage++;

        const profileStore = useProfileStore();

        // To ensure that the profile data is already available before filtering owned emails.
        await profileStore.waitForProfileFetch();

        const ownedEmails = emails.filter((email) =>
          profileStore.getIsSameClientId(email.customerId),
        );

        allEmails = [...allEmails, ...ownedEmails];
      } while (lastPage >= currentPage);

      commit('SET_EMAILS', allEmails);

      commit('SET_EMAILS_LOADED', true);
      commit('SET_EMAILS_LOADING', false);
    },
    async fetchEmailOrders({ commit }) {
      const [{ data: hMailOrders }] = await hostingEmailsRepo.getEmailOrders({
        format: 'v1',
      });

      if (hMailOrders.length) {
        commit('SET_EMAIL_ORDERS', hMailOrders);
      }
    },
    async upgradeById({ commit }, { hMailId, plan, seats }) {
      const [{ data }, err] = await hostingEmailsRepo.upgradeEmailOrder(
        hMailId,
        {
          plan,
          seats,
        },
      );
      if (!err && data) {
        commit('UPDATE_HOSTINGER_EMAIL_ORDER', {
          id: hMailId,
          pendingUpgrade: data.pendingUpgrade,
        });
      }

      return [{ data }, err];
    },
    async fetchHostingerEmailById({ commit }, id) {
      const [{ data }, err] = await hostingEmailsRepo.getEmailOrderById(id, {
        format: 'v1',
      });
      if (!err && data) {
        commit('UPDATE_HOSTINGER_EMAIL_ORDER', data);
      }
    },
  },
  modules: {
    googleWorkspaceManagement: googleWorkspaceManagementModule,
    flockManagement: flockManagementModule,
    hMailManagementModule,
  },
};
