import { ref } from 'vue';

import {
  usePlanBoost,
  useHostingCardActions,
  useHostingOrders,
  useHostingResources,
} from '@/composables';
import type { HostingCardOrder, OrderSuspendReason } from '@/types';
import { Hosting, BillingOrder } from '@/types';

const NOT_PAYMENT_SUSPEND_REASONS = [
  Hosting.SuspendReason.ABUSE,
  Hosting.SuspendReason.FRAUD,
  Hosting.SuspendReason.FRAUD_PAYMENT,
  Hosting.SuspendReason.INACTIVITY,
  Hosting.SuspendReason.SELF_CANCELED,
  Hosting.SuspendReason.CUSTOMER_REQUESTED,
  Hosting.SuspendReason.VERIFICATION_FAILED,
];

const REACTIVATION_PERIOD =
  BillingOrder.Timing.REACTIVATION_PERIOD_IN_DAYS * 24 * 60 * 60 * 1000;

export const useHostingStatusCapsule = () => {
  const { getHostingCardData } = useHostingOrders();
  const {
    getIsViewResourcesVisible,
    isOrderBeingInstalled,
    getIsPlanMaintenance,
  } = useHostingCardActions();
  const { getIsCurrentlyBoosted } = usePlanBoost();

  const activeCapsule = ref('');

  const getExpiresSoon = (order: HostingCardOrder) => {
    const timeLeft = new Date(order.expiresAt).getTime() - Date.now();

    return (
      order.status === Hosting.OrderStatus.ACTIVE &&
      timeLeft <= REACTIVATION_PERIOD &&
      timeLeft > 0
    );
  };

  const getIsPlanUnderMaintenance = (order: HostingCardOrder) =>
    isMaintenanceStatusActive(order) || isMaintenanceStatusUpcoming(order);

  const isMaintenanceStatusActive = (order: HostingCardOrder) =>
    getIsPlanMaintenance({
      maintenanceStatus: Hosting.MaintenanceStatus.ACTIVE,
      order,
    });

  const isMaintenanceStatusUpcoming = (order: HostingCardOrder) =>
    getIsPlanMaintenance({
      maintenanceStatus: Hosting.MaintenanceStatus.UPCOMING,
      order,
    });

  const getIsSomeResourceLimitReached = (order: HostingCardOrder) => {
    const { isSomeResourceLimitReached } = useHostingResources(order);

    return isSomeResourceLimitReached.value;
  };

  const getDisplaySuspendCapsule = (order: HostingCardOrder) => {
    const suspendedStatus =
      order.status === Hosting.OrderStatus.SUSPENDED && order.reason;

    const eligibleSuspendReasons = [
      ...NOT_PAYMENT_SUSPEND_REASONS,
      Hosting.SuspendReason.NON_PAYMENT,
      Hosting.CBSuspendReason.NOT_PAID,
      Hosting.SuspendReason.EXPIRED,
    ];

    return (
      suspendedStatus &&
      eligibleSuspendReasons.includes(order.reason as OrderSuspendReason)
    );
  };
  const getDisplayStatusBar = (order: HostingCardOrder) => {
    const performanceDrop = !!getIsViewResourcesVisible(order);
    const boostedStatus = getIsCurrentlyBoosted(order);
    const expiredStatus =
      order.status === Hosting.OrderStatus.EXPIRED &&
      !boostedStatus &&
      new Date(order.expiresAt).getTime() + REACTIVATION_PERIOD < Date.now();

    const statusConditions =
      getExpiresSoon(order) ||
      expiredStatus ||
      performanceDrop ||
      getIsTransferring(order) ||
      getDisplaySuspendCapsule(order) ||
      boostedStatus ||
      getIsPlanUnderMaintenance(order) ||
      getIsSomeResourceLimitReached(order);

    const additionalConditions = !isOrderBeingInstalled(order);

    return statusConditions && additionalConditions;
  };
  const getIsTransferring = (order: HostingCardOrder) =>
    getHostingCardData(order)?.websites[0]?.inTransfer;

  const getOrderStatus = (order: HostingCardOrder) => {
    if (getIsSomeResourceLimitReached(order)) {
      return (activeCapsule.value =
        Hosting.OrderStatus.SOME_RESOURCE_LIMIT_REACHED);
    }
    if (getIsViewResourcesVisible(order)) {
      return (activeCapsule.value = Hosting.OrderStatus.PERFORMANCE_DROP);
    }
    if (getIsCurrentlyBoosted(order)) {
      return (activeCapsule.value = Hosting.OrderStatus.BOOSTED);
    }
    if (getIsTransferring(order)) {
      return (activeCapsule.value = Hosting.OrderStatus.TRANSFERRING);
    }
    if (getIsPlanUnderMaintenance(order)) {
      return (activeCapsule.value = Hosting.OrderStatus.MAINTENANCE);
    }
    activeCapsule.value = order.status;

    return activeCapsule.value;
  };

  const getOrderNotifications = (order: HostingCardOrder) => {
    type NotificationsMap = {
      [Hosting.OrderStatus.SUSPENDED]: boolean;
      [Hosting.OrderStatus.ACTIVE]: boolean;
      [Hosting.OrderStatus.EXPIRED]: boolean;
      [Hosting.OrderStatus.SOME_RESOURCE_LIMIT_REACHED]: boolean;
      [Hosting.OrderStatus.PERFORMANCE_DROP]: boolean;
      [Hosting.OrderStatus.BOOSTED]: boolean;
      [Hosting.OrderStatus.TRANSFERRING]: boolean;
      [Hosting.OrderStatus.MAINTENANCE]: boolean;
    };

    const NOTIFICATIONS_MAP: NotificationsMap = {
      [Hosting.OrderStatus.SUSPENDED]:
        order.status === Hosting.OrderStatus.SUSPENDED,
      [Hosting.OrderStatus.ACTIVE]:
        order.status === Hosting.OrderStatus.ACTIVE && getExpiresSoon(order),
      [Hosting.OrderStatus.EXPIRED]:
        order.status === Hosting.OrderStatus.EXPIRED,
      [Hosting.OrderStatus.SOME_RESOURCE_LIMIT_REACHED]:
        getIsSomeResourceLimitReached(order),
      [Hosting.OrderStatus.PERFORMANCE_DROP]: getIsViewResourcesVisible(order),
      [Hosting.OrderStatus.BOOSTED]: getIsCurrentlyBoosted(order),
      [Hosting.OrderStatus.TRANSFERRING]: getIsTransferring(order),
      [Hosting.OrderStatus.MAINTENANCE]: getIsPlanUnderMaintenance(order),
    };

    return Object.keys(NOTIFICATIONS_MAP).filter(
      (key) => NOTIFICATIONS_MAP[key as keyof NotificationsMap],
    );
  };

  return {
    getOrderNotifications,
    getDisplayStatusBar,
    getIsPlanUnderMaintenance,
    getOrderStatus,
    getExpiresSoon,
    isMaintenanceStatusActive,
    getIsSomeResourceLimitReached,
  };
};
