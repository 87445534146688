<script setup lang="ts">
import type {
  HAction,
  HActionButton,
  HActionDropdown,
  HActionDropdownOptions,
} from './hpActionModel';

import HpDropdownButton from '@/components/HpButtons/HpDropdownButton.vue';

interface Props {
  action: HAction;
}

interface Emits {
  (eventName: 'close-action', payload: HAction): void;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const emitCloseAction = (action: HAction) => {
  emit('close-action', action);
};

const onSelect = (
  option: HActionDropdownOptions,
  dropdown: HActionDropdown,
  action: HAction,
) => {
  dropdown.callback(option);
  emitCloseAction(action);
};

const onActionButtonClick = (button: HActionButton, action: HAction) => {
  button.callback();
  emitCloseAction(action);
};
</script>

<template>
  <div
    v-if="
      action.payload && (action.payload.dropdowns || action.payload.buttons)
    "
    class="h-actions__buttons"
  >
    <template v-if="action.payload.dropdowns">
      <HpDropdownButton
        v-for="dropdown in action.payload.dropdowns"
        :key="dropdown.btnText"
        :about="dropdown.btnText"
        :button-text="dropdown.btnText"
        :button-props="{ noMargin: true, tabindex: 0 }"
        :dropdown-options="dropdown.options"
        @selected-option="onSelect($event, dropdown, action)"
      />
    </template>
    <template v-if="action.payload.buttons">
      <HButtonV1
        v-for="button in action.payload.buttons"
        :key="button.text"
        no-margin
        v-bind="button.props"
        centered
        @click="onActionButtonClick(button, action)"
      >
        {{ button.text }}
        <template v-if="button.externalLink" #icon-right>
          <HpIcon
            primary
            icon="icon-launch"
            static-view-box
            :width="20"
            :height="20"
          />
        </template>
      </HButtonV1>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.h-actions {
  &__buttons {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: 576px) {
      flex-direction: column;
      gap: 16px;
      align-items: stretch;
    }

    &__left {
      justify-content: flex-start;
    }
  }
}
</style>
