<template>
  <div class="h-progress" :class="hProgressClasses">
    <div
      class="h-progress__bar"
      :class="progressBarClass"
      role="progressbar"
      :style="{ width: value + '%' }"
      :aria-valuenow="value"
      :aria-valuemin="min"
      :aria-valuemax="max"
    />
  </div>
</template>
<script>
import { getClasses } from '@/utils/helpers';

export default {
  props: {
    progressBarClass: String,
    value: [String, Number],
    min: {
      type: [Number, String],
      default: 0,
      required: false,
    },
    max: {
      type: [Number, String],
      default: 100,
      required: false,
    },
    xs: Boolean,
    sm: Boolean,
    md: Boolean,
    lg: Boolean,
    long: Boolean,
    primary: Boolean,
    primaryBg: Boolean,
    danger: Boolean,
  },
  computed: {
    hProgressClasses() {
      return getClasses('h-progress', this.$props);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/progress';
</style>
