import { defineStore } from 'pinia';
import { computed } from 'vue';

import { useResourceMapper } from '@/composables';
import { useVpsServerStore } from '@/stores';
import { HRESOURCES_TYPE, HVps, type IVpsResource } from '@/types';
import { toLocalTime } from '@/utils/helpers';

export const useVpsResourceStore = defineStore('vpsResource', () => {
  const serverStore = useVpsServerStore();

  const { getResourcesByTypesAndStates } = useResourceMapper();

  const latestVpsResource = computed(() => {
    const resources = vpsResources.value;

    return resources.length > 0 ? resources[resources.length - 1] : null;
  });

  const vpsResources = computed((): IVpsResource[] =>
    getResourcesByTypesAndStates({
      types: [HRESOURCES_TYPE.VIRTUAL_MACHINE],
    }),
  );

  const vpsDailyBackupsResources = computed((): IVpsResource[] =>
    getResourcesByTypesAndStates({
      types: [HRESOURCES_TYPE.DAILY_BACKUP],
    }),
  );

  const currentVpsResource = computed(() =>
    getVpsResource(serverStore.currentServer.metadata?.orderId),
  );

  const getVpsResource = (orderId: string) =>
    vpsResources.value.find(({ metadata }) => metadata?.orderId === orderId);

  const getVpsType = (orderId: string) => {
    const resource = getVpsResource(orderId);

    const matchingType = HVps.OrderTypes.find((type) =>
      resource?.metadata?.planCode?.includes(type),
    );

    return matchingType || 'vps';
  };

  const getVpsExpiration = (orderId: string) =>
    toLocalTime(getVpsResource(orderId)?.expiresAt || '');

  return {
    vpsResources,
    vpsDailyBackupsResources,
    latestVpsResource,
    currentVpsResource,
    getVpsType,
    getVpsExpiration,
    getVpsResource,
  };
});
