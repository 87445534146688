import { toASCII } from '@/utils/helpers';
import { removeFalsyKeysFromObject } from '@/utils/helpers/requestHelper';

export const CMS_WEBSITE_BUILDER = 'website-builder';
export const CMS_WOOCOMMERCE = 'woo';
export const CMS_WORDPRESS = 'wordpress';
export const FALLBACK_CONFIG = 'fallbackConfig';

export const INSTALLATION_TYPE_WEBSITE_BUILDER = 'website_builder';
export const INSTALLATION_TYPE_WORDPRESS = 'wordpress';
export const INSTALLATION_TYPE_INSTALLATRON = 'installatron';

export const PLUGIN_WOOCOMMERCE = 'woocommerce';

export const ADDON_ONBOARDING_CONFIGURATION = {
  [CMS_WEBSITE_BUILDER]: {
    type: INSTALLATION_TYPE_WEBSITE_BUILDER,
    isOnboardingConfigNeeded: false,
  },
  [CMS_WOOCOMMERCE]: {
    type: INSTALLATION_TYPE_WORDPRESS,
    applicationOverride: CMS_WORDPRESS,
    plugins: [PLUGIN_WOOCOMMERCE],
    isOnboardingConfigNeeded: true,
  },
  [CMS_WORDPRESS]: {
    type: INSTALLATION_TYPE_WORDPRESS,
    applicationOverride: CMS_WORDPRESS,
    plugins: [],
    isOnboardingConfigNeeded: true,
  },
  [FALLBACK_CONFIG]: {
    type: INSTALLATION_TYPE_INSTALLATRON,
    plugins: [],
    isOnboardingConfigNeeded: true,
  },
};

export const mapOnboardingGettersToAddonOnboardingRequest = (getters) => {
  const {
    getOnboardingDomainName,
    getSelectedCms,
    getCmsCredentials,
    getCmsTemplate,
    templatePaletteId,
    templateLayoutId,
    templateFontId,
    getMigrationType,
    getOnboardingDomainId,
    getAiGenerateContent,
    getMigrationDetailsForSubmit,
    getFileStorageUrl,
    getSelectedWordpressPlugins,
    getSelectedWordpressPreset,
    getSelectedWordpressPresetPlugins,
  } = getters;

  const skippedMigrations =
    getters['hostingMigrations/getSkippedMigrationsIds'];

  const domain = getOnboardingDomainName;
  let request = { domain };

  if (getSelectedCms) {
    const cmsConfiguration =
      ADDON_ONBOARDING_CONFIGURATION[getSelectedCms] ||
      ADDON_ONBOARDING_CONFIGURATION[FALLBACK_CONFIG];

    const { type, applicationOverride, plugins, isOnboardingConfigNeeded } =
      cmsConfiguration;

    const wordpressPresetTheme =
      getSelectedWordpressPreset?.themes?.[0]?.slug || '';

    const onboardingConfig = isOnboardingConfigNeeded
      ? {
          application: applicationOverride || getSelectedCms,
          login: getCmsCredentials?.email,
          theme: getCmsTemplate || wordpressPresetTheme,
          themePalette: templatePaletteId,
          themeLayout: templateLayoutId,
          themeFont: templateFontId,
          plugins: [
            ...getSelectedWordpressPlugins.map((item) => item.slug),
            ...getSelectedWordpressPresetPlugins,
            ...plugins,
          ],
          ...getCmsCredentials,
        }
      : undefined;

    request = {
      type,
      domain,
      onboardingConfig,
    };
  }

  if (getAiGenerateContent) {
    request.onboardingConfig.aiGenerateContent = getAiGenerateContent;
  }

  const isMigrationNeeded =
    getMigrationType && !skippedMigrations?.includes(getOnboardingDomainId);

  if (isMigrationNeeded) {
    const migrationConfig = {
      panel: getMigrationType,
      migrate_to: toASCII(getOnboardingDomainName),
      ...getMigrationDetailsForSubmit,
    };
    const cleanedMigrationConfig = removeFalsyKeysFromObject(migrationConfig);
    request.migrationConfig = cleanedMigrationConfig;
  }

  if (getFileStorageUrl) {
    request = {
      type: 'website_import',
      domain,
      onboardingConfig: {
        storageUrl: getFileStorageUrl,
      },
    };
  }

  return request;
};
