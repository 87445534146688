<template>
  <div v-if="!loading" class="ns-flow">
    <div v-if="currentStep === 0">
      <div class="ns-flow__illustration-wrapper">
        <HImage
          :src="imgSrcOpeningIllustration"
          :alt="$t('Start domain nameserver change')"
          class="ns-flow__illustration"
        />
      </div>
      <h1 class="ns-flow__title">
        <Trans :translate-params="{ domain: usedDomain }">
          v2.lets.connect.domain.to.your.website
        </Trans>
      </h1>
      <Trans tag="p" class="ns-flow__subtitle">
        v2.to.make.your.website.accessible.via.a.domain.that.s.registered.elsewhere.you.need.to.update.your.domains.name.servers.Follow.this.step.by.step.guide
      </Trans>
      <div v-if="providerData" class="ns-flow__description">
        <div class="d-inline-block">
          <Trans
            :translate-params="{
              provider: providerData.registrar,
              domain: usedDomain,
            }"
          >
            Your domain
            <span class="ns-flow__domain">{domain}</span>
            is registered at {provider}
          </Trans>
        </div>
      </div>
      <div v-if="!providerData" class="ns-flow__description-no-provider">
        <div class="ns-flow__provider-form">
          <HFormFieldDeprecated
            name="provider"
            :schema="provider"
            @change="onProviderChange"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="ns-flow__title">
        <Trans :translate-params="{ domain: usedDomain }">
          Change nameservers of
          <span class="ns-flow__domain">{domain}</span>
        </Trans>
      </h1>
      <div class="ns-flow__instructions">
        <div class="ns-flow__instructions-step">
          <div class="ns-flow__instructions-step-header">
            <div class="ns-flow__instructions-step-icon">1</div>
            <span
              class="ns-flow__instructions-step-title"
              :class="{
                'ns-flow__instructions-step--active': providerInstructions,
              }"
              @click="openInstructions"
            >
              {{ loginToProviderText }}
              <HpIcon
                v-if="providerInstructions"
                view-box="0 0 36 36"
                icon="ic-open-in-new"
                primary
              />
            </span>
          </div>
          <div class="ns-flow__instructions-step-body">
            <Trans>
              v2.find.the.domain.settings.page.with.your.current.name.servers
            </Trans>
            <div class="ns-flow__instructions-step-old-ns">
              <div v-for="nameserver in oldNameservers" :key="nameserver">
                {{ nameserver }}
              </div>
            </div>
          </div>
        </div>
        <div class="ns-flow__instructions-step--icon">
          <HpIcon view-box="0 0 36 36" icon="icon-arrow-forward" gray />
        </div>
        <div class="ns-flow__instructions-step">
          <div class="ns-flow__instructions-step-header">
            <div class="ns-flow__instructions-step-icon">2</div>
            <Trans
              class="ns-flow__instructions-step-title"
              :translate-params="{ brand: profileStore.clientBrandTitle }"
            >
              Change to {brand} Nameservers
            </Trans>
          </div>
          <div class="ns-flow__instructions-step-body">
            <Trans>
              v2.delete.the.name.servers.and.replace.them.with.hostingers.name.servers.save.your.changes
            </Trans>
            <div class="ns-flow__instructions-step-new-ns">
              <div
                v-for="nameserver in hostingerNameservers"
                :key="nameserver"
                @click="copyString(nameserver)"
              >
                <span>{{ nameserver }}</span>
                <HImage
                  :alt="$t('copy to clipboard')"
                  src="@/images/copy.svg"
                  class="ns-flow__copy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="ns-flow__footer"
      :class="{ 'ns-flow__footer--reverse': currentStep === 1 }"
    >
      <div class="ns-flow__actions">
        <HButtonV1
          v-if="currentStep === 0"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-let_s_start_button`"
          :disabled="!providerData && !selectedProvider"
          primary
          @click="goToNextStep"
        >
          v2.domain.ns.flow.start
        </HButtonV1>
        <HButtonV1
          v-if="currentStep === 1"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-finish_button`"
          primary
          @click="onFinish"
        >
          v2.domain.ns.flow.done
        </HButtonV1>
      </div>
    </div>
  </div>
  <HDomainNsFlowModalSkeleton v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import HFormFieldDeprecated from '@/components/HFormDeprecated/HFormFieldDeprecated';
import HDomainNsFlowModalSkeleton from '@/components/Loaders/SkeletonCompositions/HDomainNsFlowModalSkeleton';
import { useEntri, useBrands } from '@/composables';
import { useConnectDomainCSAT } from '@/composables/useConnectDomainCSAT';
import { domainsRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import { Entri, HDnsActions } from '@/types';
import { toUnicode, toASCII } from '@/utils/helpers';
import { checkAutomaticConnectionAvailability } from '@/utils/helpers/entriHelper';
import modalsMixin from '@/utils/mixins/modalsMixin';
import { copyString } from '@/utils/services/copyService';
import { i18n } from '@/utils/services/i18nService';

export default {
  components: { HDomainNsFlowModalSkeleton, HFormFieldDeprecated },
  mixins: [modalsMixin],
  data() {
    return {
      providerData: null,
      loading: false,
      selectedProvider: null,
      otherProvider: null,
      videoUrl: null,
      loginLink: null,
      providerTitle: null,
      provider: {
        type: 'select',
        noValue: true,
        placeholder: 'Select provider',
        values: [
          { label: 'SiteGround', value: 'siteground' },
          { label: 'GoDaddy', value: 'godaddy' },
          { label: 'Bluehost', value: 'bluehost' },
          { label: 'Hostgator', value: 'hostgator' },
          { label: i18n.t('Other'), value: 'other' },
        ],
      },
      other: {
        type: 'input',
        label: i18n.t('Domain provider (Optional)'),
      },
      providerNameservers: {
        siteground: ['ns1.siteground.net', 'ns2.siteground.net'],
        godaddy: ['ns1.godaddy.com', 'ns2.godaddy.com'],
        bluehost: ['ns1.bluehost.com', 'ns2.bluehost.com'],
        hostgator: ['ns1.hostgator.com', 'ns2.hostgator.com'],
        other: ['ns1.yourprovider.com', 'ns2.yourprovider.com'],
      },
      hostingerNameservers: ['ns1.dns-parking.com', 'ns2.dns-parking.com'],
    };
  },
  created() {
    this.loadProviderData();
  },
  computed: {
    orderId() {
      return this.$route.params.order_id || this.data?.orderId;
    },
    usedDomain() {
      return (
        toUnicode(this.getExistingDomainName) || toUnicode(this.data?.domain)
      );
    },
    link() {
      return `https://www.whatsmydns.net/#NS/${this.getExistingDomainName}`;
    },
    providerInstructions() {
      return this.providerData?.providerUrl || this.loginLink;
    },
    oldNameservers() {
      if (this.providerData) {
        return this.providerData.nameservers;
      } else if (this.selectedProvider) {
        return this.providerNameservers[this.selectedProvider];
      }

      return ['ns1.example.com', 'ns2.example.com'];
    },

    loginToProviderText() {
      const provider =
        this.providerData?.registrar || this.providerTitle || 'your provider';

      return this.providerInstructions
        ? this.$t('Log into your {provider} account', { provider })
        : this.$t("Log into your domain host's account");
    },
    imgSrcOpeningIllustration() {
      return this.isFromNiagahoster
        ? '@/images/niagahoster/domains/illustration-opening.svg'
        : '@/images/domains/Illstration-opening.svg';
    },
    imgSrcClosingIllustration() {
      return this.isFromNiagahoster
        ? '@/images/niagahoster/domains/Illustration-closing.svg'
        : '@/images/domains/Illustration-closing.svg';
    },
    ...mapGetters(['getExistingDomainName']),
  },
  setup() {
    const { onEntriStart } = useEntri();
    const { isFromNiagahoster } = useBrands();
    const profileStore = useProfileStore();
    const csat = useConnectDomainCSAT();

    return {
      profileStore,
      onEntriStart,
      isFromNiagahoster,
      csat,
    };
  },
  methods: {
    copyString,
    async checkEntriConnection() {
      const clientId = this.orderId;
      const domain = this.usedDomain;
      const serviceType = Entri.ServiceType.WEB_HOSTING;

      const { config, isAutomaticSetupAvailable, provider } =
        await checkAutomaticConnectionAvailability({
          serviceType,
          domain,
          clientId,
        });

      if (isAutomaticSetupAvailable) {
        this.close();

        await this.onEntriStart({
          config,
          provider,
          domain,
          clientId,
          serviceType,
        });
      }
    },
    async loadProviderData() {
      this.loading = true;

      if (this.data?.isEntriCheck) {
        await this.checkEntriConnection();
      }

      if (this.usedDomain) {
        const [{ data }] = await domainsRepo.getDomainProviderData(
          toASCII(this.usedDomain),
        );
        this[HDnsActions.FETCH_DNS_PROVIDER_DATA](toASCII(this.usedDomain));

        const providersData = data.shift();
        if (providersData) this.providerData = providersData;
      }
      this.loading = false;
    },
    openInstructions() {
      if (!this.providerInstructions) return;
      window.open(this.providerInstructions, '_blank');
    },
    async onFinish() {
      if (this.otherProvider) {
        domainsRepo.storeOtherDomainProvider(
          toASCII(this.usedDomain),
          this.otherProvider,
        );
      }
      if (this.data?.onSuccess) this.data.onSuccess();
      this.csat.trigger();
      this.close();
    },
    goToStep(step) {
      if (step > this.currentStep) return;
      this.goToPreviousStep(step);
    },
    setLinkData(providerTitle) {
      const links = {
        godaddy: 'https://sso.godaddy.com/',
        hostgator: 'https://www.hostgator.com/',
        namecheap: 'https://www.namecheap.com/myaccount/login-signup',
        namecom: 'https://www.name.com/account/login',
        siteground: 'https://login.siteground.com/login',
        bluehost: 'https://www.bluehost.com/my-account/login',
      };
      this.loginLink = links[this.selectedProvider];
      this.providerTitle = providerTitle;
    },
    onProviderChange(provider) {
      this.selectedProvider = provider.value;
      this.setLinkData(provider.label);
    },
    onOtherProviderChange(input) {
      this.otherProvider = input.value;
    },
    ...mapActions([HDnsActions.FETCH_DNS_PROVIDER_DATA]),
  },
};
</script>

<style lang="scss">
@import '../../../sass/components/ns-change-modal';

.ns-flow__copy {
  width: 19px;
  height: auto;
}
</style>
