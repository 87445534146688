import dayjs from 'dayjs';
import { isObject } from 'lodash';

import type { IndexedDBStorageValues, INDEXED_DB_STORAGE } from '@/types';
import { getParsedJwtToken } from '@/utils/helpers/authHelpers';

const DB_NAME = 'HClientDB';
const STORE_NAME = 'Storage';
const DB_VERSION = 1;

export type IndexedDBStorageKeys = keyof typeof INDEXED_DB_STORAGE;

interface StorageItem {
  value: any;
  expiresAt?: number;
  createdAt?: number;
}

export default {
  async getDb(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: 'name' });
        }
      };

      request.onsuccess = (event: Event) =>
        resolve((event.target as IDBOpenDBRequest).result);

      request.onerror = (event: Event) =>
        reject((event.target as IDBOpenDBRequest).error);
    });
  },

  getStorageName() {
    const { sub } = getParsedJwtToken() || {};

    return `h-client-${sub}`;
  },

  async setValue(
    name: IndexedDBStorageValues,
    value: object | string,
    expiresAt: number,
  ): Promise<boolean> {
    const db = (await this.getDb()) as IDBDatabase;
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    const storageName = this.getStorageName();

    return new Promise((resolve, reject) => {
      // Retrieve data from store
      const getRequest = store.get(storageName);

      getRequest.onsuccess = () => {
        const data = getRequest.result ? getRequest.result.value : {};
        const storageValue = isObject(data) ? data : {};

        // Update data in store
        const putRequest = store.put({
          name: storageName,
          value: {
            ...storageValue,
            [name]: {
              value,
              createdAt: dayjs().unix(),
              ...(expiresAt ? { expiresAt } : {}),
            },
          },
        });

        // Handle the completion of the put operation
        putRequest.onsuccess = () => resolve(true);
        putRequest.onerror = () => reject(putRequest.error);
      };

      getRequest.onerror = () => reject(getRequest.error);
    });
  },
  async getValue(name: IndexedDBStorageValues) {
    const data = await this.getStorage();

    if (!data || !isObject(data[name])) return undefined;

    return data?.[name]?.value;
  },

  async deleteValue(name: IndexedDBStorageValues) {
    const db = (await this.getDb()) as IDBDatabase;
    const storageName = this.getStorageName();
    const data = await this.getStorage();

    if (!data) return;

    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    delete data[name];

    store.put({ name: storageName, value: data });

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve(true);
      transaction.onerror = () => reject(transaction.error);
    });
  },

  async getStorage(): Promise<
    Partial<Record<IndexedDBStorageValues, StorageItem>>
  > {
    const db = (await this.getDb()) as IDBDatabase;
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.get(this.getStorageName());

      request.onsuccess = () =>
        resolve(request.result ? request.result.value : {});
      request.onerror = () => reject(request.error);
    });
  },

  async cleanUpOldData() {
    const db = (await this.getDb()) as IDBDatabase;

    const storageName = this.getStorageName();
    const storage = await this.getStorage();

    if (!isObject(storage)) return;

    const maxDataAgeInSeconds = 30 * 24 * 60 * 60; // 30 days

    (Object.keys(storage) as IndexedDBStorageValues[]).forEach((key) => {
      const value = storage[key];

      if (value?.expiresAt && value?.expiresAt < dayjs().unix()) {
        delete storage[key];
      }

      if (
        value?.createdAt &&
        value.createdAt + maxDataAgeInSeconds < dayjs().unix()
      ) {
        delete storage[key];
      }
    });

    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    try {
      if (Object.keys(storage).length) {
        store.put({ name: storageName, value: storage });
      } else {
        store.delete(storageName);
      }
    } catch (e) {
      // to prevent logging in case if user don't have db, but code tries to delete
      // otherwise error would be logged by ErrorBoundary
    }

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve(true);
      transaction.onerror = () => reject(transaction.error);
    });
  },
};
