import type {
  HLabelTheme,
  Onboarding,
  RequestConfig,
  Status,
  WordPressWebsiteTypeAnswer,
} from '@/types';

export interface WordpressPage {
  application?: string;
  directory: string;
  dbName?: string;
  language?: string;
  url?: string;
  sitetitle?: string;
  login?: string;
  email?: string;
  domain: string;
  isValid?: number;
  isWoocommerce?: number;
  isDetected?: number;
  validationError?: string;
  vhostType: string;
  phpVersion?: number;
  rootdir?: string;
  username?: string;
}

export interface WordpressTranslation {
  englishName: string;
  iso: Record<number, string>;
  language: string;
  nativeName: string;
  package: string;
  strings: { continue: string };
  updated: string;
  version: string;
}

export interface IWordpressTemplateImage {
  id: string;
  palette: string;
  layout: string;
  image: string;
}

export interface IWordpressTemplateLayout {
  id: string;
  title: string;
  image: string;
}

export interface IWordpressTemplateFont {
  id: string;
  title: string;
  url: string;
  headerFontFamily: string;
  bodyFontFamily: string;
}

export interface IWordpressTemplatePalette {
  id: string;
  title: string;
  image: string;
  isActive?: boolean;
  colors: string[];
}

export interface WordpressCachePlugin {
  name: string;
  status: string;
}

export interface WordpressTheme {
  featuredImageUrl: string;
  link: string;
  icon?: string;
  isCustom?: boolean;
  slug: string;
  title: string;
  logo: string;
  imgUrl?: string;
  phpRequired?: string;
  wpRequired?: string;
  fullImageUrl?: string;
  description?: string;
  palettes?: IWordpressTemplatePalette[];
  layouts?: IWordpressTemplateLayout[];
  images?: IWordpressTemplateImage[];
  fonts?: IWordpressTemplateFont[];
}

export interface IWordPressThemeResponseV3 {
  blog: WordpressTheme[];
  default: WordpressTheme[];
  'online-store': WordpressTheme[];
  portfolio: WordpressTheme[];
  business: WordpressTheme[];
  'affiliate-marketing': WordpressTheme[];
}

export interface WordPressPluginModel {
  name: string;
  status: string;
  version: string;
  update: string;
  vulnerabilities: string[];
}

export type WordPressPluginsBasedOnWebsiteType = {
  [K in WordPressWebsiteTypeAnswer]?: OnboardingWordPressPluginModel[];
} & {
  default: OnboardingWordPressPluginModel[];
};

export type WordPressPluginsBasedOnSearch = {
  [K: string]: OnboardingWordPressPluginModel;
};
export interface OnboardingWordPressPluginModel {
  slug: string;
  name: string;
  description: string;
  link: string;
  preselected?: boolean;
  icons?: {
    ['1x']?: string;
    ['2x']?: string;
    default?: string;
  };
}

export interface WordpressSettings {
  autoUpdateEnabled?: string;
  coreUpdateEnabled?: string;
  themesUpdateEnabled?: string;
  pluginsUpdateEnabled?: string;
}

export interface WordpressSettingsGETRequest extends RequestConfig {
  params: {
    directory: string;
  };
}
export interface WordpressSettingsRequest {
  directory: string;
  settings: WordpressSettings;
}

export interface WordpressUpdateLogDetails {
  name: string;
  title: string;
  type: string;
  previousVersion: string;
  currentVersion: string;
  status: Status;
}

export interface WordpressUpdateLog {
  details: WordpressUpdateLogDetails[];
  restoreArchiveId: string;
  archiveFileName?: string;
  backupSource?: string;
  snapshotId?: string;
  updatedAt: string;
  status: Status;
}
export interface WordpressUpdateLogs {
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
  data: WordpressUpdateLog[];
}

export interface WordpressUpdateError {
  type: string;
  reason: string;
}
export interface WordpressAutoUpdateStatus {
  username: string;
  vhost: string;
  directory: string;
  status: Status;
  createdAt: string;
  errors: WordpressUpdateError[];
}

export interface WordpressRestoreRequest {
  archiveId: string;
  directory: string;
}

export interface WordpressPluginSelection {
  slug: Onboarding.WordPressPluginSlug;
  value: boolean;
  name?: string;
}

export interface IWordpressLoginRequest {
  domain: string;
  directory: string;
  username: string;
  orderId: string;
}

export interface IWordpressManagedWebsite {
  username: string;
  website: string;
  directory: string;
  clientId: string;
  orderId: string;
  softwareId: string;
  vhostType: string;
  createdAt: string;
}

export interface IWordpressManagedWebsiteWithEmail
  extends IWordpressManagedWebsite {
  ownerEmail: string;
}

export interface IWordpressCompatibilityRequest {
  directory?: string;
  domain?: string;
  wpVersion?: string;
}

export interface IDragAndDropWebsiteFiles {
  directory?: string;
  files: string[];
  username?: string;
  orderId?: string;
}

export interface IWordpressCompatibilityAction {
  action: string;
  version: string;
}

export interface IWordpressCompatibility {
  phpVersion: string;
  wpVersion: string;
  compatibility: string;
  suggestedPhpAction: IWordpressCompatibilityAction;
  suggestedWpAction: IWordpressCompatibilityAction;
  availablePhpActions: IWordpressCompatibilityAction[];
  availableWpActions: IWordpressCompatibilityAction[];
}

export interface IWordpressComponent {
  category: string;
  slug: string;
  name?: string;
  status?: string;
  type?: string;
  version?: string;
  recommended?: boolean;
}

export interface ICompatibilityConfig {
  [key: string]: {
    title: string;
    subtitle: string;
    theme: HLabelTheme;
  };
}

export interface IStagingEnvironment {
  application: string;
  createdAt: string;
  dbName: string;
  deployedAt: string | null;
  directory: string;
  domain: string;
  email: string;
  isDetected: '0' | '1';
  isValid: '0' | '1';
  isWoocommerce: '0' | '1';
  language: string;
  login: string;
  productionDirectory: string;
  productionDomain: string;
  sitetitle: string;
  status: StagingEnvironmentStatus;
  url: string;
  validationError: string | null;
  vhostType: string;
}

export interface IWordPressLitespeedPresets {
  directory: string;
  perPage?: number;
  page?: number;
  domain?: string;
}

export interface IWordPresssLitespeedLogsResponse {
  data: IWordPressLitespeedLogs[];
  page: number;
  perPage: number;
}

export interface IWordPressLitespeedLogs {
  changedFrom: string;
  changedTo: string;
  backupKey: string;
  createdAt: string;
  isRestored: string;
  type: string;
}

export const LITESPEED_PRESET_TYPE = {
  APPLY: 'apply',
  RESTORE: 'restore',
} as const;

export type StagingEnvironmentStatus =
  (typeof STAGING_STATUSES)[keyof typeof STAGING_STATUSES];

export const STAGING_STATUSES = {
  DEPLOYING: 'deploying',
  REVERTING: 'reverting',
  DONE: 'done',
  IN_PROGRESS: 'inprogress',
  FAILED: 'failed',
} as const;

export const WORDPRESS_ADMIN_SOURCE = {
  WEBSITE_LIST: 'website_list',
  WEBSITE_DASHBOARD: 'website_dashboard',
  WORDPRESS_OVERVIEW: 'wordpress_overview',
  ONBOARDING: 'onboarding',
} as const;

export type WordPressAdminSource =
  (typeof WORDPRESS_ADMIN_SOURCE)[keyof typeof WORDPRESS_ADMIN_SOURCE];
