import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useStore } from 'vuex';

import { useVpsServerStore } from './vpsServerStore';

import { hVpsRepo } from '@/repositories';
import { handleDuplicateHvpsRequest } from '@/store/storeHelper';
import type { ISshKey, ServerSshKeys } from '@/types';

export const useVpsSshStore = defineStore('vpsSsh', () => {
  const { commit } = useStore();

  const serverStore = useVpsServerStore();

  const sshKeys = ref<ServerSshKeys>({});

  const setSshKeys = (serverId: string, details: ISshKey[]) => {
    sshKeys.value[serverId] = details;
  };

  const getSshKeys = (serverId?: string) => {
    const managedId = serverStore.managedId;
    const index = managedId ? managedId : serverId;
    if (!index) return [];

    return sshKeys.value[index];
  };

  const fetchSshKeys = async (serverId: string, force: boolean = false) => {
    const [{ data }, error] = await hVpsRepo.getSshKeys(serverId, {
      cache: !force ? 60 : undefined,
    });

    if (!error) setSshKeys(serverId, data);
  };

  const createSshKey = async (payload: { name: string; key: string }) => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    serverStore.setServerLock(serverId, true);

    const [{ isReplay }, error] = await hVpsRepo.createSshKey(
      serverId,
      payload,
    );

    if (isReplay) return handleDuplicateHvpsRequest(commit, serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => fetchSshKeys(String(serverId), true),
    });
  };

  const deleteSshKey = async (keyId: number) => {
    const serverId = serverStore.managedId;
    if (!serverId) return;

    serverStore.setServerLock(serverId, true);

    const [{ isReplay }, error] = await hVpsRepo.deleteSshKey(serverId, {
      id: keyId,
    });

    if (isReplay) return handleDuplicateHvpsRequest(commit, serverId);

    if (error) {
      serverStore.setServerLock(serverId, false);

      return;
    }

    serverStore.setUpdatingServer({ serverId });

    serverStore.fetchServerById({
      serverId,
      callback: () => fetchSshKeys(String(serverId), true),
    });
  };

  return {
    sshKeys,
    getSshKeys,
    fetchSshKeys,
    createSshKey,
    deleteSshKey,
  };
});
