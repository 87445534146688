import { defineStore } from 'pinia';
import { ref } from 'vue';

import { wordpressRepo } from '@/repositories';
import type { WordpressTranslation } from '@/types';

export const useOnboardingWordPressLanguagesStore = defineStore(
  'onboardingWordPressLanguagesStore',
  () => {
    const wordPressLanguages = ref<WordpressTranslation[]>([]);
    const isLoadingWordPressLanguages = ref(false);

    const fetchWordPressLanguages = async () => {
      isLoadingWordPressLanguages.value = true;
      const [{ data }, err] = await wordpressRepo.getWordpressLanguageList();
      isLoadingWordPressLanguages.value = false;

      if (err) return;

      wordPressLanguages.value = data;
    };

    return {
      fetchWordPressLanguages,
      wordPressLanguages,
      isLoadingWordPressLanguages,
    };
  },
  { persist: { key: 'onboarding-wordpress-languages-store' } },
);
