import type {
  IHostingAccount,
  ICacheManagerStatus,
  ICacheManagerPurgeRequest,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
  domain: IHostingAccount['domain'];
};

export default {
  url: ({ username, domain }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/vhosts/${domain}/cache`,

  async getCacheStatus(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync<ICacheManagerStatus>(
      http.get(`${this.url(accountIdentifier)}/status`),
    );
  },

  async enableCache(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync(http.patch(`${this.url(accountIdentifier)}/enable`));
  },

  async disableCache(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync(http.patch(`${this.url(accountIdentifier)}/disable`));
  },

  async purgeCache(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    requestData: ICacheManagerPurgeRequest,
  ) {
    return await hAsync(
      http.delete(this.url(accountIdentifier), { data: requestData }),
    );
  },
};
