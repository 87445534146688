<template>
  <DataLoader v-if="domainsLoading" class="text-center h-px-40 h-pb-60" />
  <div v-else-if="currentStep === 0" :key="currentStep">
    <div class="h-px-40">
      <p v-trans>Select a website you would like to install SSL to:</p>
      <OnboardingField :schema="domainSelect" :model="setupModel" />
    </div>
    <HModalActions @close="close">
      <HButtonV1
        id="hpanel_tracking-ssl_setup_modal-submit_button-install_ssl"
        primary
        no-margin
        :disabled="!setupModel.selected || settingUp"
        :loading="settingUp"
        @click="setupAction"
      >
        Install SSL
      </HButtonV1>
    </HModalActions>
  </div>
  <div v-else-if="currentStep === 1" :key="currentStep">
    <p v-trans class="h-px-40">
      It looks like your domain is still being activated. No worries! We will
      automatically install SSL once your domain is fully registered and
      pointing to our nameservers!
    </p>
    <HModalActions @close="close" />
  </div>
  <div v-else-if="currentStep === 2" :key="currentStep">
    <div class="h-px-40">
      <p v-trans class="h-mb-16">
        Your SSL is now installed and we have automatically forced HTTPS on your
        domain!
      </p>
      <template v-if="successUrl">
        <p v-trans class="d-inline-block">Your can visit your website here:</p>
        <a :href="successUrl" target="_blank" class="text-primary">
          {{ successUrl }}
        </a>
      </template>
    </div>
    <HModalActions @close="close" />
  </div>
  <div v-else-if="currentStep === 3" :key="currentStep">
    <div class="h-px-40">
      <Trans :translate-params="{ domain: successUrl }" tag="p">
        Your SSL is being installed for
        <span class="text-primary">{domain}</span>
        in the background. HTTPS will be automatically forced on your domain!
      </Trans>
    </div>
    <HModalActions @close="close" />
  </div>
</template>

<script>
import { isFunction } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import NoActiveHostingPlan from './NoActiveHostingPlan.vue';

import HModalActions from '@/components/HModals/HModalActions';
import DataLoader from '@/components/Loaders/DataLoader';
import OnboardingField from '@/components/Onboarding/OnboardingField';
import { useResourceMapper } from '@/composables';
import { sslRepo } from '@/repositories';
import { toUnicode } from '@/utils/helpers';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'SslInstallSetupModal',
  mixins: [modalsMixin],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DataLoader,
    OnboardingField,
    HModalActions,
  },
  setup() {
    const { mappedHomepageOrders } = useResourceMapper();

    return { mappedHomepageOrders };
  },
  async created() {
    if (!this.activeHostingExist) {
      this.$openModal({
        component: { NoActiveHostingPlan },
      });
    }

    if (this.data.domains) return;
    this.domainsLoading = true;
    const [{ data }] = await sslRepo.getDomainsToSetupSsl(this.data.params, {
      cache: 300,
    });
    if (Array.isArray(data)) {
      const suspendedSsl = [...this.orderSsls, ...this.autoinstallSsls].filter(
        (ssl) =>
          [ssl.accountStatus || ssl.accountOrderStatus].includes('suspended'),
      );

      const suspendedAcc = this.getAllAccounts.filter(
        (account) => account.status === 'suspended',
      );

      const unsuspendedSsl = data.filter(
        (order) =>
          !suspendedSsl.find(
            (ssl) =>
              order.domain === ssl.domain &&
              order.mainDomain === ssl.mainDomain,
          ),
      );

      let unsuspended = unsuspendedSsl.filter(
        (order) =>
          !suspendedAcc.find(
            (ssl) =>
              order.domain === ssl.domain && order.orderId === ssl.orderId,
          ),
      );

      const domainsUnderSuspendedAcc = [];

      suspendedAcc.forEach((ssl) => {
        if (ssl.parkedDomains) {
          domainsUnderSuspendedAcc.push(...ssl.parkedDomains);
        }
        if (ssl.subdomains) domainsUnderSuspendedAcc.push(...ssl.subdomains);
      });

      unsuspended = unsuspendedSsl.filter(
        (order) =>
          !domainsUnderSuspendedAcc.find((ssl) =>
            [ssl.parkedDomain, ssl.fullDomain].includes(order.domain),
          ),
      );

      this.domainsToSetup = unsuspended.map((domainToSetup) => ({
        label: toUnicode(domainToSetup.domain),
        value: {
          accountOrderId: domainToSetup.accountOrderId,
          domain: domainToSetup.domain,
          username: domainToSetup.username,
          mainDomain: domainToSetup.mainDomain,
        },
      }));
    }
    this.domainsLoading = false;
  },
  data() {
    return {
      settingUp: false,
      setupModel: {
        selected: null,
      },
      domainsToSetup: [],
      domainsLoading: false,
    };
  },
  computed: {
    successUrl() {
      if (this.setupModel.selected) {
        return `https://${toUnicode(this.setupModel.selected.domain)}`;
      }

      return '';
    },
    pendingOrders() {
      const filteredSsls = [...this.orderSsls, ...this.autoinstallSsls].filter(
        (ssl) => ssl.setupUrl,
      );

      return [
        ...filteredSsls,
        ...this.mappedHomepageOrders.pendingOrders.filter(
          (order) => order.serviceName === 'ssl_install',
        ),
      ];
    },
    domainSelect() {
      return {
        type: 'select',
        extra: false,
        noTrans: true,
        values: this.setupDomainValues,
        model: 'selected',
        required: true,
      };
    },
    setupDomainValues() {
      const labelToUnicode = (domainObj) => ({
        label: toUnicode(domainObj.label),
        value: domainObj.value,
      });

      const data = this.data?.domains?.length
        ? this.data.domains
        : this.domainsToSetup;

      return data.map((domainObj) => labelToUnicode(domainObj));
    },
    activeHostingExist() {
      const hostingOrders = this.mappedHomepageOrders.activeOrders.find(
        (order) => order.section === 'Hosting',
      );

      return !!hostingOrders?.orders?.length;
    },
    setupCall() {
      return this.$store.dispatch('setupSslStore', {
        domain: this.setupModel.selected.domain,
        accountOrderId:
          this.setupModel.selected.accountOrderId ||
          this.setupModel.selected.accountId,
        username: this.setupModel.selected.username,
        mainDomain: this.setupModel.selected.mainDomain,
      });
    },
    ...mapGetters(['getAllAccounts']),
    ...mapState({
      orderSsls: (state) => state.ssl.sslCertificates,
      autoinstallSsls: (state) => state.ssl.autoinstallSslCertificates,
    }),
  },
  methods: {
    async setupAction() {
      this.settingUp = true;

      const [, error] = await this.setupCall;

      if (!error || error.code === 218) {
        this.$hj('action_ssl-install-setup-modal_install-ssl');

        if (isFunction(this.data.onSuccess)) {
          this.data.onSuccess(this.setupModel.selected.mainDomain);
        }

        this.goToNextStep(3);

        this.settingUp = false;

        return;
      }

      this.settingUp = false;

      this.$openModal({
        component: { ErrorOccurred },
      });
    },
  },
};
</script>
