import type { NavigationGuard } from 'vue-router';

import { useProfileStore } from '@/stores';
import { Route } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';

const onboardingGuard: NavigationGuard = (to, from, next) => {
  const profileStore = useProfileStore();

  if (!to.query.cpanel) {
    return next({ ...to, name: Route.OnboardingV2.BASE_PATH } as any);
  }

  if (profileStore.isAccessManager) {
    toastr.e('Action is allowed only for account owner');

    return next({ name: Route.Base.HOME });
  }

  next();
};

export default onboardingGuard;
