import type {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { useH5GOnboarding } from '@/composables';
import { Route } from '@/types';

const h5gOnboardingGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isH5GFeatureEnabled } = useH5GOnboarding();

  if (!isH5GFeatureEnabled.value || !to.params.h5gId) {
    return next({ name: Route.Base.HOME });
  }

  next();
};

export default h5gOnboardingGuard;
