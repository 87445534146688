import { defineStore } from 'pinia';
import { ref, computed, readonly } from 'vue';
import { useRouter } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { hRefundsRepo } from '@/repositories';
import { Route, type HRefundInvoice, type HRefundInvoiceItem } from '@/types';

export const useRefundStore = defineStore('refundStore', () => {
  const currentStep = ref(0);
  const { t } = useGlobals();

  const STEPS_CONFIG = computed(() => [
    {
      title: t('v2.services.you.want.to.refund'),
      stepper: {
        title: t('v2.services'),
        index: 0,
        id: '0',
      },
    },
    {
      title: t('v2.reason.why.you.want.a.refund'),
      label: t('v2.optional'),
      stepper: {
        title: t('v2.refund.reason'),

        index: 1,
        id: '1',
      },
    },
    {
      title: t('v2.select.refund.method'),
      stepper: {
        title: t('v2.refund.method'),
        index: 2,
        id: '2',
      },
    },
    {
      title: t('Refund summary'),
      stepper: {
        title: t('Summary'),
        index: 3,
        id: '3',
      },
    },
  ]);

  const surveyData = ref({
    reason: '',
    comment: '',
  });

  const invoice = ref<HRefundInvoice | null>(null);
  const selectedPaymentMethodId = ref<string | null>(null);
  const selectedInvoiceItems = ref<HRefundInvoiceItem[]>([]);
  const isLoading = ref(false);

  const router = useRouter();

  const getInvoice = async (invoiceId: HRefundInvoice['id']) => {
    if (invoice.value?.id === invoiceId) {
      return invoice;
    }

    return await fetchInvoice(invoiceId);
  };

  const fetchInvoice = async (invoiceId: HRefundInvoice['id']) => {
    isLoading.value = true;

    const [{ data }, _] = await hRefundsRepo.getInvoice(invoiceId);

    if (
      !data?.refundable &&
      (!data?.supported || !data?.isRefundableToBalance)
    ) {
      return router.replace({
        name: Route.Base.PAID_INVOICES,
      });
    }

    invoice.value = data;

    isLoading.value = false;
  };

  const nextStep = () => {
    if (currentStep.value < STEPS_CONFIG.value.length) {
      currentStep.value++;
    }
  };

  const previousStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--;
    }
  };

  const setStep = (step: number) => {
    if (step >= 1 && step <= STEPS_CONFIG.value.length) {
      currentStep.value = step;
    }
  };

  const getStepperConfig = () => STEPS_CONFIG.value.map((step) => step.stepper);

  const currentStepConfig = computed(
    () =>
      STEPS_CONFIG.value.find(
        (step) => step.stepper.index === currentStep.value,
      ) || STEPS_CONFIG.value[0],
  );

  const setInvoice = (newInvoice: HRefundInvoice) => {
    invoice.value = newInvoice;
  };

  const setSelectedPaymentMethodId = (paymentMethod: string | null) => {
    selectedPaymentMethodId.value = paymentMethod;
  };

  const setSelectedInvoiceItems = (invoiceItems: HRefundInvoiceItem[]) => {
    selectedInvoiceItems.value = invoiceItems;
  };

  const setLoading = (loading: boolean) => {
    isLoading.value = loading;
  };

  const resetStore = () => {
    currentStep.value = 0;
    invoice.value = null;
    isLoading.value = false;
    surveyData.value.comment = '';
    surveyData.value.reason = '';
    selectedInvoiceItems.value = [];
    selectedPaymentMethodId.value = null;
  };

  return {
    currentStep,
    invoice,
    selectedPaymentMethodId: readonly(selectedPaymentMethodId),
    isLoading,
    currentStepConfig,
    STEPS_CONFIG,
    selectedInvoiceItems: readonly(selectedInvoiceItems),
    getStepperConfig,
    getInvoice,
    nextStep,
    previousStep,
    setStep,
    setInvoice,
    setSelectedPaymentMethodId,
    resetStore,
    setLoading,
    surveyData,
    setSelectedInvoiceItems,
  };
});
