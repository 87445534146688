import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/activity-logs`,

  async getHostingOtherActivityLogIndex({ limit, page, username, type }) {
    return await hAsync(
      http.get(this.url, {
        params: {
          type,
          per_page: limit,
          page,
          username,
        },
      }),
    );
  },
};
