export default {
  namespaced: true,
  state: {
    screenSize: 'desktop',
    previewSelect: false,
  },
  mutations: {
    SET_SCREEN_SIZE(state, size) {
      state.screenSize = size;
    },
    TOGGLE_PREVIEW_SELECT(state) {
      state.previewSelect = !state.previewSelect;
    },
  },
};
