import type {
  IHostingAccount,
  RequestConfig,
  WebsiteMalware,
  IMalwareMetrics,
  IMalwareFiles,
  IMalwareFilesRequest,
  IMalwareCleanupStatus,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  username: IHostingAccount['username'];
};

export default {
  url: ({ username }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/accounts/${username}/malware`,

  async getMalwareOverview(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    config: RequestConfig = {},
  ) {
    return await hAsync<WebsiteMalware>(
      http.get(`${this.url(accountIdentifier)}/overview`, {
        hideToastr: true,
        ...config,
      }),
    );
  },

  async getMalwareMetrics(accountIdentifier: HostingWebsiteAccountIdentifier) {
    return await hAsync<IMalwareMetrics>(
      http.get(`${this.url(accountIdentifier)}/metrics`),
    );
  },

  async getMalwareList(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    params: IMalwareFilesRequest,
  ) {
    return await hAsync<IMalwareFiles>(
      http.get(`${this.url(accountIdentifier)}/files`, { params, plain: true }),
    );
  },

  async getMalwareCleanupStatus(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    domain: string,
  ) {
    return await hAsync<IMalwareCleanupStatus>(
      http.get(`${this.url(accountIdentifier)}/${domain}/site-cleanups`),
    );
  },
};
