import { defineStore } from 'pinia';
import { ref } from 'vue';

import whClientAddonsRepo from '@/repositories/whApi/whClientAddonsRepo';
import { type DeveloperToolsWebsite } from '@/types';

export const useDeveloperToolsWebsitesStore = defineStore(
  'developerToolsWebsitesStore',
  () => {
    const isAllAssignedWebsitesLoaded = ref(false);
    const assignedWebsites = ref<{
      [key: number]: DeveloperToolsWebsite[];
    }>({});

    const fetchAssignedWebsites = async (resourceId: number) => {
      const [{ data }, error] = await whClientAddonsRepo.getAssignedWebsites(
        resourceId,
      );

      if (data) {
        assignedWebsites.value[resourceId] = data;
      }

      return error;
    };

    const updateWebsites = async (
      executeOperation:
        | typeof whClientAddonsRepo.assignWebsite
        | typeof whClientAddonsRepo.deleteAssignedWebsite,
      websites: DeveloperToolsWebsite[],
      resourceId: number,
    ) =>
      await Promise.all(
        websites.map(async ({ domain, username }) => {
          const requestParams = {
            domain,
            username,
          };
          const [_, error] = await executeOperation(resourceId, requestParams);

          return {
            website: requestParams,
            error,
          };
        }),
      );

    const assignWebsites = async (
      websites: DeveloperToolsWebsite[],
      resourceId: number,
    ) => {
      const websitesToAdd = websites.filter(
        (website) =>
          !assignedWebsites.value[resourceId]?.some(
            ({ domain }) => domain === website.domain,
          ),
      );

      return await updateWebsites(
        whClientAddonsRepo.assignWebsite.bind(whClientAddonsRepo),
        websitesToAdd,
        resourceId,
      );
    };

    const removeWebsites = async (
      websites: DeveloperToolsWebsite[],
      resourceId: number,
    ) => {
      const websitesToRemove =
        assignedWebsites.value[resourceId]?.filter(
          (assignedWebsite) =>
            !websites?.some(({ domain }) => domain === assignedWebsite.domain),
        ) ?? [];

      return await updateWebsites(
        whClientAddonsRepo.deleteAssignedWebsite.bind(whClientAddonsRepo),
        websitesToRemove,
        resourceId,
      );
    };

    const saveWebsites = async (
      websites: DeveloperToolsWebsite[],
      resourceId: number,
    ) => {
      // Should first remove, then assign.
      // Having this running parallel can cause errors when near the website limits.
      const removeResponses = await removeWebsites(websites, resourceId);
      const addResponses = await assignWebsites(websites, resourceId);

      if ([...addResponses, ...removeResponses].some(({ error }) => error)) {
        const failedAdds = addResponses.filter(({ error }) => error);
        const websitesWithoutFailedAdds = websites.filter(
          (website) =>
            !failedAdds.some(
              ({ website: { domain } }) => domain === website.domain,
            ),
        );

        const failedToDeleteWebsites = removeResponses
          .filter(({ error }) => error)
          .map(({ website }) => website);

        assignedWebsites.value[resourceId] = [
          ...websitesWithoutFailedAdds,
          ...failedToDeleteWebsites,
        ];

        return false;
      }

      assignedWebsites.value[resourceId] = websites;

      return true;
    };

    const $reset = () => {
      isAllAssignedWebsitesLoaded.value = false;
      assignedWebsites.value = {};
    };

    return {
      isAllAssignedWebsitesLoaded,
      assignedWebsites,
      fetchAssignedWebsites,
      saveWebsites,
      $reset,
    };
  },
);
