import { objectEntries } from '@vueuse/core';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useResourceMapper } from '@/composables';
import { useWidgetsStore } from '@/stores';
import { useWHOrdersStore } from '@/stores/whOrdersStore';
import {
  BUILDER_WIDGETS,
  HOSTING_RESOURCE_WIDGET_TYPES,
  HRESOURCE_ITEM_STATE,
  HRESOURCE_ITEM_TYPE,
  Hosting,
  SINGLE_WIDGET_TYPES,
  WEBSITE_WIDGETS,
  WH_ORDER_OWNERSHIP,
  WH_ORDER_WEBSITE_STATUS,
  WIDGET_CONFIGURATION,
  type IWidget,
  type ResourceOrItem,
  type WHOrderWebsite,
  type WidgetType,
} from '@/types';
import type { ResourceWithSubscription } from '@/utils/mappers/resourcesMapper';

export const useWidgets = () => {
  const { activeHostingResources } = useResourceMapper();
  const store = useStore();
  const widgetsStore = useWidgetsStore();
  const whOrdersStore = useWHOrdersStore();
  const getIsWebsiteWidget = (widgetType: WidgetType) =>
    WEBSITE_WIDGETS.includes(widgetType);

  const getIsHostingResourceWidget = (widgetType: WidgetType) =>
    HOSTING_RESOURCE_WIDGET_TYPES.includes(widgetType);

  const getIsBuilderWidget = (widgetType: WidgetType) =>
    BUILDER_WIDGETS.includes(widgetType);

  const selectableWebsites = computed(() =>
    whOrdersStore
      .getWebsitesByOwnershipAndStatus(
        WH_ORDER_OWNERSHIP.OWNED,
        WH_ORDER_WEBSITE_STATUS.ENABLED,
      )
      .flatMap((item) => {
        const account = store.getters.getAccountByDomain(item.domain);

        if (!account) return [];

        if (account.type === Hosting.AccountType.BUILDER) return [];

        return item;
      }),
  );

  const selectableHostingResources = computed(() =>
    activeHostingResources.value.flatMap((resource) => {
      if (!resource.title && store.getters.isBuilderOnlyWebsites) {
        const title = resource.items.find(
          ({ type, state }) =>
            type === HRESOURCE_ITEM_TYPE.WEBSITE &&
            state === HRESOURCE_ITEM_STATE.ACTIVE,
        )?.domain;

        return {
          ...resource,
          title: title || '',
        };
      }

      if (!resource.title) return [];

      return resource;
    }),
  );

  const getResourceIdentifier = (resourceOrItem: ResourceOrItem) => {
    if ('domain' in resourceOrItem) {
      return {
        resourceId: null,
        domainName: resourceOrItem.domain || '',
      };
    }

    return {
      resourceId: String(resourceOrItem.id),
      domainName: resourceOrItem.title || '',
    };
  };

  const filterWidgetByType = (widget: IWidget, widgetType?: WidgetType) =>
    widget.type === widgetType;

  const hasWidgetTypeAvailableWebsiteResources = (
    widgetType: WidgetType,
    widgets: IWidget[],
    hostingResourceItems: WHOrderWebsite[],
  ) => {
    const hasWidgetAvailableResources =
      getAvailableResourceWebsitesByWidget(
        widgetType,
        widgets,
        hostingResourceItems,
      ).length > 0;

    return hasWidgetAvailableResources;
  };

  const getAlreadyPlacedResourcesByWidgetType = (
    widgetType: WidgetType,
    widgets: IWidget[],
  ) =>
    widgets
      .filter((widget) => filterWidgetByType(widget, widgetType))
      .map((widget) => ({
        resourceId: widget.resourceId,
        domainName: widget.domainName,
        type: widget.type,
      }));

  const filterAlreadyPlacedResource = (
    widgetType: WidgetType,
    widgets: IWidget[],
    resourceOrItem: ResourceOrItem,
    ignoreWidget?: IWidget,
  ) => {
    const alreadyPlacedResourcesByType = getAlreadyPlacedResourcesByWidgetType(
      widgetType,
      widgets,
    );

    const identifier = getResourceIdentifier(resourceOrItem);
    const { domainName: ignoreResourceDomain } = ignoreWidget || {};

    if (ignoreWidget && identifier.domainName === ignoreResourceDomain) {
      return true;
    }

    return !alreadyPlacedResourcesByType.some(
      (resource) => resource.domainName === identifier.domainName,
    );
  };

  const getAvailableResourceWebsitesByWidget = (
    widgetType: WidgetType | undefined,
    widgets: IWidget[],
    hostingResourceItems: WHOrderWebsite[],
    ignoreWidget?: IWidget,
  ) => {
    if (!widgetType) return [];

    return hostingResourceItems.filter((resource) =>
      filterAlreadyPlacedResource(widgetType, widgets, resource, ignoreWidget),
    );
  };

  const getAvailableHostingResourcesByWidgetType = (
    widgetType: WidgetType | undefined,
    widgets: IWidget[],
    hostingResources: ResourceWithSubscription[],
    ignoreWidget?: IWidget,
  ) => {
    if (!widgetType) return [];

    return hostingResources.filter((resource) =>
      filterAlreadyPlacedResource(widgetType, widgets, resource, ignoreWidget),
    );
  };

  const widgetConfigurations = computed(() =>
    objectEntries(WIDGET_CONFIGURATION)
      .map(([, value]) => ({
        ...value,
        id: value.type,
      }))
      .filter((widgetConfiguration) =>
        filterUnavailableWidgets(
          widgetConfiguration.type,
          widgetsStore.widgets,
          {
            websites: selectableWebsites.value,
            hosting: selectableHostingResources.value,
          },
        ),
      ),
  );

  const filterUnavailableWidgets = (
    widgetType: WidgetType,
    widgets: IWidget[],
    resources: {
      websites: WHOrderWebsite[];
      hosting: ResourceWithSubscription[];
    },
  ) => {
    if (
      SINGLE_WIDGET_TYPES.includes(widgetType) &&
      widgetsStore.isWidgetTypeAlreadyPlaced(widgetType)
    ) {
      return false;
    }

    if (getIsBuilderWidget(widgetType) && store.getters.isBuilderOnlyWebsites) {
      return hasWidgetTypeAvailableHostingResources(
        widgetType,
        widgets,
        resources.hosting,
      );
    }

    if (
      store.getters.isBuilderOnlyWebsites &&
      !SINGLE_WIDGET_TYPES.includes(widgetType)
    ) {
      return false;
    }

    if (getIsWebsiteWidget(widgetType)) {
      return hasWidgetTypeAvailableWebsiteResources(
        widgetType,
        widgets,
        resources.websites,
      );
    }

    if (getIsHostingResourceWidget(widgetType)) {
      return hasWidgetTypeAvailableHostingResources(
        widgetType,
        widgets,
        resources.hosting,
      );
    }

    return true;
  };

  const hasWidgetTypeAvailableHostingResources = (
    widgetType: WidgetType | undefined,
    widgets: IWidget[],
    hostingResources: ResourceWithSubscription[],
  ) => {
    const hasWidgetAvailableResources =
      getAvailableHostingResourcesByWidgetType(
        widgetType,
        widgets,
        hostingResources,
      ).length > 0;

    return hasWidgetAvailableResources;
  };

  const hasAvailableWidgets = computed(
    () => widgetConfigurations.value.length > 0,
  );

  return {
    selectableWebsites,
    selectableHostingResources,
    getIsWebsiteWidget,
    getIsHostingResourceWidget,
    getIsBuilderWidget,
    getResourceIdentifier,
    filterWidgetByType,
    hasWidgetTypeAvailableWebsiteResources,
    getAlreadyPlacedResourcesByWidgetType,
    filterAlreadyPlacedResource,
    getAvailableResourceWebsitesByWidget,
    getAvailableHostingResourcesByWidgetType,
    widgetConfigurations,
    hasAvailableWidgets,
  };
};
