<script setup lang="ts">
import {
  FieldType,
  type FieldSchema,
  type IFieldOnChangeEvent,
} from '@hostinger/hcomponents';
import { computed, ref, watch } from 'vue';

import HDrawer from '@/components/HDrawer.vue';
import LanguageList from '@/components/Language/LanguageList.vue';
import LanguageSelectionModal from '@/components/Modals/HModal/LanguageSelectionModal.vue';
import { useGlobals, useModal, useScreen } from '@/composables';
import { useHPanelControlsStore } from '@/stores';
import { HIcon } from '@/types';

const search = ref('');

const { t } = useGlobals();
const { openModal, closeModal, currentModalName } = useModal();
const { isSmall } = useScreen();

const hpanelControlsStore = useHPanelControlsStore();

const searchSchema = computed(
  (): FieldSchema => ({
    type: FieldType.text,
    label: t('Search for a language'),
    icon: {
      left: HIcon.ICON_MAGNIFYING_GLASS,
    },
    custom: {
      hideOptionalLabel: true,
    },
  }),
);

const handleSearch = ({ value }: IFieldOnChangeEvent) => {
  search.value = value as string;
};

const isLanguageDrawerActive = computed(
  () => hpanelControlsStore.isLanguageSelectionOpen,
);

const handleBackToProfileClick = () => {
  hpanelControlsStore.toggleLanguageSelection();
  hpanelControlsStore.toggleAccountDropdown();
};

watch(
  () => [hpanelControlsStore.isLanguageSelectionOpen, isSmall.value],
  ([isOpen, isSmall]) => {
    if (!isOpen) {
      return;
    }

    if (!isSmall) {
      openModal({
        component: { LanguageSelectionModal },
        data: {
          onClose: async () => {
            hpanelControlsStore.toggleLanguageSelection();
          },
        },
      });

      return;
    }

    if (currentModalName.value === 'LanguageSelectionModal') {
      closeModal();
    }
  },
);
</script>

<template>
  <HDrawer
    v-if="isSmall"
    :is-active="isLanguageDrawerActive"
    position="right"
    show-logo
    @on-close="hpanelControlsStore.toggleLanguageSelection"
  >
    <div class="drawer-content" data-qa="language-selection-drawer">
      <div class="top-controls">
        <button
          v-if="!hpanelControlsStore.isLanguageSelectionInHeader"
          class="top-controls__profile-button"
          @click="handleBackToProfileClick"
        >
          <HpIcon dark role="button" icon="icon-chevron-left" width="16" />
          <span class="profile-title text-body-2 text-dark">
            {{ t('Profile') }}
          </span>
        </button>
        <HFormField
          :schema="searchSchema"
          name="search"
          class="search"
          @on-change="handleSearch"
        />
      </div>
      <LanguageList
        :search-term="search"
        @close="hpanelControlsStore.toggleLanguageSelection"
      />
    </div>
  </HDrawer>
</template>

<style lang="scss" scoped>
.search {
  margin: 0;
}

.drawer-content {
  height: 100%;
}

.top-controls {
  border-bottom: 1px solid;
  border-color: var(--general-border-color);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__profile-button {
    background-color: unset;
    border: none;
    outline: none;

    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 12px;
    text-transform: capitalize;
    cursor: pointer;
  }
}

.profile-title {
  font-weight: 500;
}
</style>
