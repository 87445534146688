<template>
  <li
    :id="`hpanel_tracking-asidemenu-submenu-dropdown-${item.slug}`"
    :key="componentKey"
    class="h-nav__item h-nav__item--submenu"
    :class="menuItemOpened"
  >
    <a
      :id="`hpanel_tracking-asidemenu-submenu-dropdown-${item.slug}`"
      href
      class="h-nav__link h-nav__toggle"
      :class="{
        'h-nav__toggle--disabled': item.isDisabled,
        'h-nav__link--active': isActiveRoute,
      }"
      :data-click-id="`hpanel_tracking-asidemenu-submenu-dropdown-${item.slug}`"
      @click.prevent="change(item.isDisabled)"
    >
      <HpIcon
        v-if="hasChildren"
        :key="showSubmenu"
        :primary="isActiveRoute"
        gray
        static-view-box
        :icon="showSubmenu ? 'IcArrowDown' : 'IcArrowRight'"
      />
      <HpIcon
        :icon="getIconName(item)"
        static-view-box
        :primary="isActiveRoute"
        gray
        class="h-mr-8"
        :class="{ 'h-ml-24': !hasChildren }"
      />
      <span
        class="h-nav__section"
        :class="{ 'h-nav__section--title': isActiveRoute }"
      >
        <Trans>{{ item.title }}</Trans>
      </span>
    </a>
    <div class="h-nav__submenu">
      <ul class="h-nav__subnav">
        <Component
          :is="getRouteComponent(section)"
          v-for="section in item.children"
          v-show="!section?.isHidden"
          :id="`hpanel_tracking-asidemenu-submenu-link-${section.slug}`"
          :key="section.slug"
          custom-active-class="h-nav__submenu--active"
          custom-class="h-nav__submenu-item"
          :item="getRouteByName(section.url, section.query)"
          :link="section.link"
          :title="section.title"
          :is-disabled="section.isDisabled"
          @click="handleClick(section)"
          @after-navigate="closeSidemenu($event, section)"
          @send-amplitude="
            onAmplitudeEvent({
              event: section.event,
              location: section.eventLocation,
            })
          "
        />
      </ul>
    </div>
  </li>
</template>
<script>
import HMenuAction from '@/components/HMenu/HMenuAction.vue';
import HMenuLink from '@/components/HMenu/HMenuLink.vue';
import HMenuRenew from '@/components/HMenu/HMenuRenew.vue';
import HMenuRouterLink from '@/components/HMenu/HMenuRouterLink.vue';
import { useAddWebsite } from '@/composables';
import { useFrontendSettingsStore } from '@/stores';
import { AmplitudeLocation } from '@/types';
import { authRedirectService } from '@/utils/services/http/authRedirectService';
import RouteParser from '@/utils/services/navigation/RouteParser';

export default {
  name: 'SubmenuElement',
  components: {
    HMenuRouterLink,
    HMenuLink,
    HMenuRenew,
    HMenuAction,
  },
  emits: ['submenu-open', 'submenuClose'],
  props: {
    item: Object,
    searchInProgress: Boolean,
  },
  data() {
    return {
      showSubmenu: !!this.searchInProgress,
    };
  },
  computed: {
    componentKey() {
      return (this.searchInProgress ? 1 : 0) + this.item.title;
    },
    menuItemOpened() {
      return { 'h-nav__item--open': this.showSubmenu || this.searchInProgress };
    },
    isActiveRoute() {
      const routeParent = this.$route.name;

      return routeParent === this.item.slug;
    },
    hasChildren() {
      return !!(this.item.children && this.item.children.length);
    },
  },
  setup() {
    const { addWebsiteRedirectByCurrentOrderId } = useAddWebsite();
    const frontendSettingsStore = useFrontendSettingsStore();

    return {
      frontendSettingsStore,
      addWebsiteRedirectByCurrentOrderId,
    };
  },
  methods: {
    isLogoMakerSection(section) {
      return section.slug === 'logo_maker';
    },
    closeSidemenu() {
      this.frontendSettingsStore.toggleAsideLeftShow(false);
    },
    change(isDisabled) {
      if (isDisabled) return;

      if (!this.hasChildren) {
        this.handleClick(this.item);
      }

      this.showSubmenu = !this.showSubmenu;
    },
    close() {
      this.showSubmenu = false;
    },
    open() {
      this.showSubmenu = true;
    },
    getIconName(item) {
      return item.menuIcon || `Ic${item.title?.replace(' ', '')}Aside`;
    },
    getRouteByName(routeName, query) {
      const routeNameValue =
        routeName === 'emails_email_accounts' ? 'emails' : routeName;

      if (query) {
        RouteParser.getRouteByName(routeNameValue).element.query = query;
      }

      return RouteParser.getRouteByName(routeNameValue);
    },

    onAmplitudeEvent({ event, location }) {
      if (!event) return;
      this.$amplitudeV2(event, {
        location: location || AmplitudeLocation.Base.SIDE_MENU,
      });
    },
    handleClick(section) {
      if (this.isLogoMakerSection(section)) {
        authRedirectService.redirectToLogoMaker(
          'https://www.hostinger.com',
          'hpanel-sidebar',
        );

        return;
      }

      if (section.action) {
        section.action();
      }

      if (section.slug === 'access_logs') {
        this.$router.push({ name: 'access_logs' });
      }

      if (section.slug === 'add_website') {
        this.addWebsiteRedirectByCurrentOrderId();
      }
    },
    getRouteComponent(section) {
      if (this.isLogoMakerSection(section)) {
        return 'h-menu-action';
      }

      if (section.slug === 'renew') return 'h-menu-renew';
      if (section.slug === 'add_website') return 'h-menu-link';
      if (section.slug === 'website_builder') return 'h-menu-router-link';

      return (section.routeTo || section.url) && !section.link
        ? 'h-menu-router-link'
        : 'h-menu-link';
    },
  },
  watch: {
    showSubmenu(value) {
      if (value && !this.searchInProgress) {
        this.$emit('submenu-open', this._uid);
      } else {
        this.$emit('submenuClose', this._uid);
      }
    },
  },
  created() {
    const currentRouteName = this.$router.currentRoute.value.name;

    if (!currentRouteName) return;

    if (this.item) {
      this.showSubmenu = !!this.item.children.find((child) =>
        currentRouteName.startsWith(child.url),
      );
    }

    if (!this.showSubmenu) {
      const currentRouteParentName = RouteParser.getRouteByUrl(
        this.$router.currentRoute.value.matched[0].path,
      ).name;

      this.showSubmenu = this.item.slug === currentRouteParentName;
    }
  },
};
</script>

<style lang="scss" scoped>
.h-nav {
  margin-left: -24px;

  &__section {
    font-weight: 700;
    color: var(--gray) !important;
    &--title {
      color: var(--primary) !important;
    }
  }

  &__link {
    padding: 16px 24px;
    border-radius: 0px 32px 32px 0px;

    &:hover {
      background-color: var(--primary-dark-hover);
    }

    &--active {
      background-color: var(--primary-hover);

      span {
        color: var(--primary) !important;
      }

      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }

  &__item {
    padding: 0;
  }

  &__toggle {
    margin: 0;

    &--disabled {
      cursor: not-allowed;
      background-color: var(--primary-dark-hover);
    }
  }

  &__subnav {
    padding: 0;
  }

  &__submenu-item {
    text-decoration: none;
  }
}
</style>
