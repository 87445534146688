<template>
  <div class="h-list">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'HList',
};
</script>
<style lang="scss">
@import '@/sass/components/h-list';
</style>
