import { pick } from 'lodash';

import { Hosting, Client, type TBrandId } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

export const WEBSITE_COUNT_TOOLTIP =
  'The maximum amount of websites allowed per account.';
export const VISITORS_TOOLTIP =
  'This number shows the approximate traffic your websites can handle.';
export const WP_STAGING_TOOLTIP =
  'Test changes in a staging environment before releasing them to the live version of your website.';
export const OBJECT_CACHE_TOOLTIP =
  'Object caching is a process that stores database query results in order to quickly bring them back up the next time they are needed. This can reduce WordPress website response times up to 3 times.';
export const DAILY_BACKUPS_TOOLTIP =
  'Protect your website data with automatic backups. Daily backups are stored for 7 days, and weekly backups are stored for 7 weeks.';
export const WEEKLY_BACKUPS_TOOLTIP =
  "Protect your website's data from accidents. We keep daily backups of the last 7 days and weekly backups of the last 7 weeks. A full backup restore is the fastest way to restore your site and limit the effects of website downtime on your site traffic.";
export const CPU_CORES_TOOLTIP = 'The amount of CPU power given to the server.';
export const BANDWIDTH_TOOLTIP =
  'Make the most of your web hosting without worrying whether your site’s traffic exceeds bandwidth limits. You can transfer as much data as you need to build an online presence for your business. Although all uses of hosting services provided by Hostinger are subject to our Terms of Service, we hope you will use bandwidth only for the regular operation of a personal or small to medium business website.';
export const STORAGE_TOOLTIP = 'The amount of allocated storage space.';
export const FREE_DOMAIN_TOOLTIP =
  'Register a domain for free for one year when purchasing a yearly (or longer) hosting plan. Additional terms apply, as not all TLDs are eligible for this promotion. Free domain must be registered within 12 months of acquiring the hosting plan, or it will be cancelled.';
export const FREE_CDN_TOOLTIP =
  'CDN (Content Delivery Network) optimizes website speed by caching content and delivering it from the visitor’s nearest location, resulting in up to 40% loading times.';
export const RESOURCE_ALLOCATION_TOOLTIP =
  'Our cloud plans offer powerful dedicated resources and are optimized for efficiency and speed that guarantees a top performance of your websites. Every single resource is used and managed exclusively by you';
export const DATABASES_TOOLTIP =
  'The number of databases that you will be able to have on your hosting account.';
export const EMAIL_ACCOUNTS_TOOLTIP =
  'The number of email accounts you can host with the selected plan.';
export const ENTRY_PROCESSES_TOOLTIP = 'Number of website triggered processes.';
const PERFORMANCE_TOOLTIP =
  'Performance is measured by allocated resources to the Hosting plan as well as enabled performance features, such as Hostinger CDN and WordPress Object Cache.';
const FILES_AND_DIRECTORIES_TOOLTIP =
  'Items that correspond to the number of files and folders you have on your account (inodes)';
const PHP_WORKERS_LIMIT_TOOLTIP =
  'Amount of requests that can be served simultaneously by server';
const RAM_TOOLTIP = 'Available Random Access Memory (RAM) per plan.';

export enum UpgradePerkID {
  DISK_SPACE = 'diskSpace',
  BANDWIDTH = 'bandwidth',
  DATABASES = 'database',
  WEBSITES = 'website',
  DAILY_BACKUPS = 'dailyBackups',
  VISITS_MONTHLY = 'visitsMonthly',
  EMAIL_ACCOUNTS = 'emailAccounts',
  WORDPRESS_STAGING = 'wpStaging',
  FREE_CDNS = 'freeCDNs',
  OBJECT_CACHE = 'objectCache',
  CPU_SPEED = 'cpuSpeed',
  CPU_CORES = 'cpuCores',
  PERFORMANCE = 'performance',
  RAM = 'ram',
  DEDICATED_IP = 'dedicatedIP',
  WORDPRESS_AI_TOOLS = 'wordpressAITools',
  PRIORITY_SUPPORT = 'prioritySupport',
  ENTRY_PROCESSES = 'entryProcesses',
  RESOURCE_ALLOCATION = 'resourceAllocation',
  DDOS_PROTECTION = 'ddosProtection',
  FILES_AND_DIRECTORIES = 'filesAndDirectories',
  PHP_WORKERS_LIMIT = 'phpWorkersLimit',
  WOOCOMMERCE_OPTIMIZATION = 'woocommerceOptimization',
}
export enum UpgradePerk {
  DISK_SPACE = 'Storage',
  BANDWIDTH = 'Bandwidth',
  DATABASES = 'Databases',
  WEBSITES = 'Websites',
  DAILY_BACKUPS = 'Daily backups',
  VISITS_MONTHLY = 'Monthly visits',
  EMAIL_ACCOUNTS = 'Email accounts',
  WORDPRESS_STAGING = 'WordPress staging',
  FREE_CDNS = 'Free CDN',
  OBJECT_CACHE = 'Object cache for WordPress',
  CPU_SPEED = 'CPU speed',
  CPU_CORES = 'CPU',
  PERFORMANCE = 'Performance',
  RAM = 'RAM (GB)',
  DEDICATED_IP = 'Dedicated IP address',
  WORDPRESS_AI_TOOLS = 'WordPress AI tools',
  PRIORITY_SUPPORT = 'Priority support',
  ENTRY_PROCESSES = 'Entry processes',
  RESOURCE_ALLOCATION = 'Resource allocation',
  DDOS_PROTECTION = 'DDoS Protection',
  FILES_AND_DIRECTORIES = 'Files and directories',
  PHP_WORKERS_LIMIT = 'PHP workers limit',
  WOOCOMMERCE_OPTIMIZATION = 'WooCommerce Optimization',
}

export const HOSTING_UPGRADE_PERKS: any = {
  [Hosting.Plan.STARTER]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '50 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: '2',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '1 website',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'No',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '~10 000 visits',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '1',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'No',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'No',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'No',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '100%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '1',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Standard',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '0.768 GB' },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'No',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
  },

  [Hosting.Plan.PREMIUM]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '100 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '100 websites',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'No',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '~25 000 visits',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'No',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'No',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'No',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '100%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '1',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Standard',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '1 GB' },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'No',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
    [UpgradePerkID.DDOS_PROTECTION]: {
      name: UpgradePerk.DDOS_PROTECTION,
      value: 'Standard',
    },
  },

  [Hosting.Plan.BUSINESS]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '200 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '100 websites',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '100 000 visits',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'Yes',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '200%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '2',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Increased (Up to 5x)',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '1.5 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'No',
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '30',
      tooltip: ENTRY_PROCESSES_TOOLTIP,
    },
    [UpgradePerkID.RESOURCE_ALLOCATION]: {
      name: UpgradePerk.RESOURCE_ALLOCATION,
      value: 'Shared resources',
      tooltip: RESOURCE_ALLOCATION_TOOLTIP,
    },
    [UpgradePerkID.DDOS_PROTECTION]: {
      name: UpgradePerk.DDOS_PROTECTION,
      value: 'Enhanced',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '0.6 Million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '60',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
    [UpgradePerkID.WOOCOMMERCE_OPTIMIZATION]: {
      name: UpgradePerk.WOOCOMMERCE_OPTIMIZATION,
      value: 'Basic',
    },
  },

  [Hosting.Plan.CLOUD_ECONOMY]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '200 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300 websites',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '200 000 visits',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'Yes',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '200%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '2 cores',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: {
      name: UpgradePerk.RAM,
      value: '3 GB',
      tooltip: RAM_TOOLTIP,
    },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'Yes',
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '50',
      tooltip: ENTRY_PROCESSES_TOOLTIP,
    },
    [UpgradePerkID.RESOURCE_ALLOCATION]: {
      name: UpgradePerk.RESOURCE_ALLOCATION,
      value: 'Dedicated resources',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '2 Million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '100',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
    [UpgradePerkID.WOOCOMMERCE_OPTIMIZATION]: {
      name: UpgradePerk.WOOCOMMERCE_OPTIMIZATION,
      value: 'Standard',
    },
  },

  [Hosting.Plan.CLOUD_PROFESSIONAL]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '250 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300 websites',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '300 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'Yes',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '400%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '4 cores',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: {
      name: UpgradePerk.RAM,
      value: '6 GB',
      tooltip: RAM_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '100',
      tooltip: ENTRY_PROCESSES_TOOLTIP,
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '2.8 Million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '200',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
  },

  [Hosting.Plan.CLOUD_ENTERPRISE]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '300 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
      tooltip: DATABASES_TOOLTIP,
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300 websites',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '400 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
      tooltip: EMAIL_ACCOUNTS_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: {
      name: UpgradePerk.FREE_CDNS,
      value: 'Yes',
      tooltip: FREE_CDN_TOOLTIP,
    },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '600%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '6 cores',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
      tooltip: PERFORMANCE_TOOLTIP,
    },
    [UpgradePerkID.RAM]: {
      name: UpgradePerk.RAM,
      value: '12 GB',
      tooltip: RAM_TOOLTIP,
    },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'Yes',
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '150',
      tooltip: ENTRY_PROCESSES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '300',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '3.2 Million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
  },
};

export const mapHostingRenewUpgradePerks = (
  renewKey: string,
  upgradeKey: string,
  clientBrandId?: TBrandId,
) => {
  const RENEW_UPGRADE_PERKS = {
    [Hosting.Plan.STARTER]: [
      [UpgradePerkID.DISK_SPACE],
      [UpgradePerkID.DATABASES],
      [UpgradePerkID.WEBSITES],
      [UpgradePerkID.VISITS_MONTHLY],
      [UpgradePerkID.EMAIL_ACCOUNTS],
    ],
    [Hosting.Plan.PREMIUM]: [
      [UpgradePerkID.DISK_SPACE],
      [UpgradePerkID.DAILY_BACKUPS],
      [UpgradePerkID.WORDPRESS_STAGING],
      [UpgradePerkID.FREE_CDNS],
      [UpgradePerkID.PERFORMANCE],
      [UpgradePerkID.WORDPRESS_AI_TOOLS],
      [UpgradePerkID.DDOS_PROTECTION],
    ],
    [Hosting.Plan.BUSINESS]: [
      [UpgradePerkID.WEBSITES],
      [UpgradePerkID.DEDICATED_IP],
      [UpgradePerkID.PERFORMANCE],
      [UpgradePerkID.FILES_AND_DIRECTORIES],
      [UpgradePerkID.PRIORITY_SUPPORT],
      [UpgradePerkID.PHP_WORKERS_LIMIT],
      [UpgradePerkID.WOOCOMMERCE_OPTIMIZATION],
    ],
    [Hosting.Plan.CLOUD_ECONOMY]: [
      [UpgradePerkID.DISK_SPACE],
      [UpgradePerkID.PHP_WORKERS_LIMIT],
      [UpgradePerkID.CPU_CORES],
      [UpgradePerkID.RAM],
      [UpgradePerkID.FILES_AND_DIRECTORIES],
    ],
    [Hosting.Plan.CLOUD_PROFESSIONAL]: [
      [UpgradePerkID.DISK_SPACE],
      [UpgradePerkID.PHP_WORKERS_LIMIT],
      [UpgradePerkID.CPU_CORES],
      [UpgradePerkID.RAM],
      [UpgradePerkID.FILES_AND_DIRECTORIES],
    ],
    default: [
      [UpgradePerkID.DISK_SPACE],
      [UpgradePerkID.WEBSITES],
      [UpgradePerkID.CPU_CORES],
      [UpgradePerkID.RAM],
      [UpgradePerkID.ENTRY_PROCESSES],
    ],
  };

  const oldPlansMapping: Record<string, Hosting.Plan> = {
    [Hosting.Plan.WORDPRESS_SINGLE]: Hosting.Plan.STARTER,
    [Hosting.Plan.WORDPRESS_PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WORDPRESS_BUSINESS]: Hosting.Plan.BUSINESS,
    [Hosting.Plan.WORDPRESS_CLOUD_ECONOMY]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL]:
      Hosting.Plan.CLOUD_PROFESSIONAL,
    [Hosting.Plan.WOO_STARTER]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WOO_PREMIUM]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_BUSINESS]: Hosting.Plan.CLOUD_PROFESSIONAL,
  };

  const perksPlanKey = oldPlansMapping[renewKey] ?? renewKey;
  const perksToCompare = mapKeyValue(RENEW_UPGRADE_PERKS, perksPlanKey);
  const renewPerks = pick(HOSTING_UPGRADE_PERKS[renewKey], perksToCompare);
  const upgradePerks = pick(HOSTING_UPGRADE_PERKS[upgradeKey], perksToCompare);

  if (
    [Client.BrandId.Hostinger.COM_BR].some(
      (brand) => clientBrandId && brand.includes(clientBrandId),
    ) &&
    ['300 websites', '100 websites'].includes(renewPerks.website?.value)
  ) {
    renewPerks.website.tooltip = '';
  }

  return {
    perks: Object.values(renewPerks),
    renew: Object.values(renewPerks).map((perk) => perk.value),
    upgrade: Object.values(upgradePerks).map((perk) => perk.value),
  };
};

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_SINGLE] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.STARTER];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_PREMIUM] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.PREMIUM];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_BUSINESS] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.BUSINESS];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_CLOUD_ECONOMY] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_ECONOMY];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_PROFESSIONAL];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_STARTER] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.PREMIUM];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_PREMIUM] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_ECONOMY];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_BUSINESS] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_PROFESSIONAL];

export const mapCommonPerksHostingPlanBySlug = (key: string) => {
  const HOSTING_SLUG_MAP = {
    [Hosting.Plan.STARTER]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.BUSINESS]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.CLOUD_ECONOMY]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.CLOUD_PROFESSIONAL]: Hosting.PlanSlug.CLOUD_PROFESSIONAL,
    [Hosting.Plan.CLOUD_ENTERPRISE]: Hosting.PlanSlug.CLOUD_ENTERPRISE,
    [Hosting.Plan.WORDPRESS_SINGLE]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WORDPRESS_SINGLE_SPECIAL]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WORDPRESS_PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WORDPRESS_PREMIUM_SPECIAL]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WORDPRESS_BUSINESS]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.WORDPRESS_BUSINESS_SPECIAL]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.WOO_STARTER]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WOO_PREMIUM]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_BUSINESS]: Hosting.PlanSlug.CLOUD_PROFESSIONAL,
    [Hosting.Plan.WORDPRESS_CLOUD_ECONOMY]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.WEBLINK_BRONZE]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WEBLINK_PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WEBLINK_BUSINESS]: Hosting.PlanSlug.BUSINESS,
    default: Hosting.PlanSlug.STARTER,
  };

  return mapKeyValue(HOSTING_SLUG_MAP, key);
};

export const mapCommonPerksHostingPlanByName = (key: string) => {
  const HOSTING_PLAN_MAP = {
    [Hosting.Plan.STARTER]: Hosting.Plan.STARTER,
    [Hosting.Plan.PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.BUSINESS]: Hosting.Plan.BUSINESS,
    [Hosting.Plan.CLOUD_ECONOMY]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.CLOUD_PROFESSIONAL]: Hosting.Plan.CLOUD_PROFESSIONAL,
    [Hosting.Plan.CLOUD_ENTERPRISE]: Hosting.Plan.CLOUD_ENTERPRISE,
    [Hosting.Plan.WORDPRESS_SINGLE]: Hosting.Plan.STARTER,
    [Hosting.Plan.WORDPRESS_PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WORDPRESS_BUSINESS]: Hosting.Plan.BUSINESS,
    [Hosting.Plan.WORDPRESS_CLOUD_ECONOMY]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_STARTER]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WOO_PREMIUM]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_BUSINESS]: Hosting.Plan.CLOUD_PROFESSIONAL,
    [Hosting.Plan.WEBLINK_BRONZE]: Hosting.Plan.STARTER,
    [Hosting.Plan.WEBLINK_PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WEBLINK_BUSINESS]: Hosting.Plan.BUSINESS,
    default: Hosting.Plan.STARTER,
  };

  return mapKeyValue(HOSTING_PLAN_MAP, key);
};
