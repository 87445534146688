<template>
  <div
    class="copy-to-clipboard"
    @click="copyString($refs.toCopy.textContent, 'Copied successfully')"
  >
    <p ref="toCopy">
      <slot />
    </p>

    <HImage
      :alt="$t('copy to clipboard')"
      src="@/images/copy-dark.svg"
      class="h-ml-16"
    />
  </div>
</template>

<script>
import { copyString } from '@/utils/services/copyService';

export default {
  name: 'CopyToClipboard',
  methods: {
    copyString,
  },
};
</script>

<style scoped lang="scss">
@import '../sass/components/copy-to-clipboard';
</style>
