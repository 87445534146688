import type { NavigationGuard } from 'vue-router';

import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useGlobals } from '@/composables/useGlobals';
import { useFrontendSettingsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { Route } from '@/types';

const domainResourceGuard: NavigationGuard = async (to, from, next) => {
  const {
    getHDomainResourceByDomain,
    fetchHDomainsResources,
    hDomainsResources,
  } = useHDomainResourceStore();
  const frontendSettingsStore = useFrontendSettingsStore();
  const { t, toastr } = useGlobals();

  const { isSuspicious } = useHDomainState();

  if (!hDomainsResources.length) {
    frontendSettingsStore.setState('pageLoading', true);
    await fetchHDomainsResources();
    frontendSettingsStore.setState('pageLoading', false);
  }
  const currentDomain = to.params.domain as string;
  const domainResource = getHDomainResourceByDomain(currentDomain);
  if (!domainResource) {
    next({ name: Route.Domain.MY_DOMAINS });

    return;
  }

  if (isSuspicious(domainResource)) {
    toastr.w(
      t(
        'We are reviewing your domain, it might take up to 24 hours to activate it',
      ),
    );
    next({ name: Route.Domain.MY_DOMAINS });

    return;
  }

  next();
};

export default domainResourceGuard;
