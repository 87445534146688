import { migrationRepo } from '@/repositories';

export default {
  namespaced: true,
  state: {
    skippedMigrationsIds: [],
    isOverridable: {
      loaded: false,
      data: false,
    },
    tableLoading: false,
    buttonLoading: {
      id: null,
      loading: false,
    },
    isConnected: {
      loaded: false,
      data: false,
      slug: '',
    },
    existingMigrations: {
      data: [],
    },
  },
  getters: {
    getSkippedMigrationsIds: (state) => state.skippedMigrationsIds,
  },
  mutations: {
    DELETE_MIGRATION: (state, { id }) => {
      let existingMigrations = state.existingMigrations;
      existingMigrations = existingMigrations.data.filter(
        (item) => item.id !== id,
      );
      state.existingMigrations.data = existingMigrations;
    },
    SET_LOADING: (state, { type, data }) => {
      let loadingData = state[type];
      const dataIsObject = typeof data === 'object' && data !== null;
      if (dataIsObject) {
        loadingData.loading = data.loading;
        if (data.id) loadingData.id = data.id;

        state.loadingData = loadingData;

        return;
      }
      loadingData = data;
      state[type] = loadingData;
    },
    SET_CONNECTED: (state, { type, data }) => {
      const item = state[type];
      item.loaded = data.loaded;
      item.data = data.connected;
      item.slug = data.slug;
      state[type] = item;
    },
    SET_LOADED_DATA: (state, { type, data }) => {
      const items = state[type];
      items.loaded = true;
      items.data = data;
      state[type] = items;
    },

    SET_SKIPPED_MIGRATIONS_DATA: (state, { type, data }) => {
      if (!data) return;
      if (
        type === 'add' &&
        !state.skippedMigrationsIds.find((id) => id === data)
      ) {
        return (state.skippedMigrationsIds = [
          ...state.skippedMigrationsIds,
          data,
        ]);
      }
      if (type === 'remove') {
        return (state.skippedMigrationsIds = state.skippedMigrationsIds.filter(
          (item) => item !== data,
        ));
      }
    },
  },
  actions: {
    async fetchIsConnected({ commit }, params) {
      try {
        const [{ data }, error] =
          params.admin_url || params.adminUrl
            ? await migrationRepo.verifyWpMigrationCredentials(params)
            : await migrationRepo.verifyCpanelMigrationCredentials(params);

        commit('SET_CONNECTED', {
          type: 'isConnected',
          data: {
            connected:
              data === 'login_success' || data === 'cannot_submit_details',
            loaded: true,
            slug: data,
          },
        });

        return [{ data }, error];
      } catch (error) {
        commit('SET_CONNECTED', {
          type: 'isConnected',
          data: {
            connected: false,
            loaded: false,
            slug: 'wp_credentials_error',
          },
        });
      }
    },
    async fetchExistingMigrations({ commit }) {
      commit('SET_LOADING', { type: 'tableLoading', data: true });
      try {
        const [{ data }] = await migrationRepo.getMigrations();
        commit('SET_LOADING', { type: 'tableLoading', data: false });
        commit('SET_LOADED_DATA', { type: 'existingMigrations', data });
      } catch (error) {
        commit('SET_LOADING', { type: 'tableLoading', data: false });
      }
    },

    async deleteMigration({ commit }, id) {
      commit('SET_LOADING', {
        type: 'buttonLoading',
        data: { id, loading: true },
      });
      try {
        await migrationRepo.destroyMigration(id);

        commit('DELETE_MIGRATION', { id });

        commit('SET_LOADING', {
          type: 'buttonLoading',
          data: { id, loading: false },
        });
      } catch (error) {
        commit('SET_LOADING', {
          type: 'buttonLoading',
          data: { id, loading: false },
        });
      }
    },
  },
};
