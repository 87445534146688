import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import DCSelectionModalV2 from '@/components/Modals/HModal/DCSelectionModalV2.vue';
import PlanSelectionModal from '@/components/Modals/HModal/PlanSelectionModal.vue';
import OwnershipTransferSuccessModal from '@/components/Modals/HModal/Website/OwnershipTransferSuccessModal.vue';
import WebsiteOwnershipTransferModal from '@/components/Modals/HModal/WebsiteOwnershipTransferModal.vue';
import {
  useAddWebsite,
  useCookies,
  useModal,
  useResourceMapper,
} from '@/composables';
import { hBillingRepo, websiteRepo } from '@/repositories';
import {
  useOnboardingDataCenterStore,
  useOrdersStore,
  useResourcesStore,
  useWebsiteOwnershipTransferStore,
} from '@/stores';
import type { IOwnershipTransferExtended, OrderWithLimits } from '@/types';
import {
  Cookie,
  Header,
  Hosting,
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  Route,
} from '@/types';
import { timeout } from '@/utils/helpers';
import { removeQueryParamFromUrl } from '@/utils/services/windowService';

const TIMEOUT_FOR_SUCCESS_ANIMATION = 1000;

export const useOwnershipTransferActions = () => {
  const { openModal, closeModal } = useModal();
  const ordersStore = useOrdersStore();
  const { hasAnyOrders } = storeToRefs(ordersStore);
  const websiteOwnershipTransferStore = useWebsiteOwnershipTransferStore();
  const { acceptingTransfer } = storeToRefs(websiteOwnershipTransferStore);
  const { orders, processWebsiteUpgradeData, openUpgradeModal } =
    useAddWebsite();
  const router = useRouter();
  const onboardingDataCenterStore = useOnboardingDataCenterStore();
  const resourceStore = useResourcesStore();
  const { cookie: orderTokenFromCookie } = useCookies(
    Cookie.LATEST_ORDER_TOKEN,
  );

  const initiateOwnershipTransfer = async (
    transfer: IOwnershipTransferExtended,
  ) => {
    await ordersStore.getOrdersWithLimits();

    if (!hasAnyOrders.value) {
      router.push({
        name: Route.Base.BUY_HOSTING,
        params: { flow: Hosting.HostingPurchaseRouteParam.OWNERSHIP_TRANSFER },
      });

      return;
    }
    const hasMultipleOrders = ordersStore.getHasMultipleOrders({
      showManaged: false,
    });

    if (hasMultipleOrders) {
      openPlanSelectionModal(transfer);

      return;
    }

    handleLimitsAndTransfer(transfer, orders.value[0]);
  };

  const openPlanSelectionModal = (transfer: IOwnershipTransferExtended) => {
    openModal({
      component: { PlanSelectionModal },
      data: {
        hostingOrders: orders.value,
        onSuccess: (order: OrderWithLimits) => {
          handleLimitsAndTransfer(transfer, order);
        },
        isWebsiteOwnershipTransfer: true,
      },
    });
  };

  const handleLimitsAndTransfer = async (
    transfer: IOwnershipTransferExtended,
    order: OrderWithLimits,
  ) => {
    const { isLimitReached, isAvailableToSetup } =
      processWebsiteUpgradeData(order);

    if (isLimitReached) {
      openUpgradeModal(order, {
        onSuccess: async () => {
          ordersStore.getOrdersWithLimits();

          await timeout(TIMEOUT_FOR_SUCCESS_ANIMATION);
          await startOwnershipTransfer(transfer, order.orderId);
        },
        goBackCustom: async () => {
          closeModal();
          openPlanSelectionModal(transfer);
        },
        isNotClosable: true,
      });
    }

    if (isAvailableToSetup && !order.hasAccount) {
      selectDataCenterAndTransferWebsite(order.orderId);

      return;
    }

    if (isAvailableToSetup) {
      await timeout(TIMEOUT_FOR_SUCCESS_ANIMATION);
      startOwnershipTransfer(transfer, order.orderId);
    }
  };

  const startOwnershipTransfer = async (
    transfer: IOwnershipTransferExtended,
    orderId: string,
    preferredDataCenter?: string,
  ) => {
    removeQueryParamFromUrl('transferToken');

    const config = {
      headers: {
        [Header.ORDER_ID]: orderId,
        [Header.DOMAIN]: transfer.temporaryDomain,
      },
    };
    const data = {
      token: transfer.transferToken,
      preferredDatacenter: preferredDataCenter,
    };
    const [, error] = await websiteRepo.postTransferRequestAccept({
      data,
      config,
    });
    if (error) return;

    openOwnershipTransferSuccessModal();
    websiteOwnershipTransferStore.$reset();
    await websiteOwnershipTransferStore.fetchOwnershipTransferList();
  };

  const openOwnershipTransferSuccessModal = () => {
    openModal({
      component: { OwnershipTransferSuccessModal },
    });
  };

  const selectDataCenterAndTransferWebsite = async (orderId: string) => {
    removeQueryParamFromUrl('transferToken');
    if (!acceptingTransfer.value) return;

    const { getResourceByReferenceId } = useResourcesStore();

    const resource = getResourceByReferenceId(`${orderId}`);
    const subscriptionId = resource?.chargebeeSubscriptionId;

    await onboardingDataCenterStore.fetchAvailableDataCenters(
      subscriptionId || '',
    );
    const bestDataCenter = onboardingDataCenterStore.bestDataCenter;
    const dataCenters = onboardingDataCenterStore.dataCenters;

    if (!Object.keys(dataCenters).length) {
      startOwnershipTransfer(acceptingTransfer.value, orderId);

      return;
    }

    const [dcKey, dcValue] = Object.entries(bestDataCenter)[0];

    openModal({
      component: { DCSelectionModalV2 },
      data: {
        dataCenters,
        dataCenter: bestDataCenter,
        selectedDataCenter: {
          label: dcValue,
          value: dcKey,
        },
        coordinates: onboardingDataCenterStore.coordinates,
        onSuccess: (preferredDataCenter: string) => {
          if (!acceptingTransfer.value) return;
          startOwnershipTransfer(
            acceptingTransfer.value,
            orderId,
            preferredDataCenter,
          );
        },
      },
    });
  };

  const getNewestPendingSetupHostingSubscriptionId = () => {
    const { getResourcesByTypesAndStates } = useResourceMapper();
    const pendingHostingResources = getResourcesByTypesAndStates({
      types: [HRESOURCES_TYPE.HOSTING],
      states: [HRESOURCES_STATE.PENDING],
    });

    const newestActiveHostingResource = pendingHostingResources.reduce(
      (newest, item) =>
        dayjs(item.createdAt).isAfter(dayjs(newest.createdAt)) ? item : newest,
    );

    return newestActiveHostingResource?.chargebeeSubscriptionId || null;
  };

  const getSubscriptionIdByOrderToken = async (orderToken: string) => {
    const [{ data }, err]: any = await hBillingRepo.getOrderByOrderToken(
      orderToken,
    );
    if (err) return null;

    return data?.items?.[0].chargebeeSubscriptionId || null;
  };

  const getReferenceIdByOrderToken = async () => {
    const orderToken = orderTokenFromCookie.value;
    let subscriptionId = null;
    if (orderToken) {
      subscriptionId = await getSubscriptionIdByOrderToken(orderToken);
    }

    if (!subscriptionId) {
      subscriptionId = getNewestPendingSetupHostingSubscriptionId();
    }

    return (
      resourceStore.getResourceBySubscriptionId(subscriptionId)?.referenceId ||
      null
    );
  };

  const getTransferFromQueryParam = async (params: {
    transferToken: string;
  }) => {
    let transfer = websiteOwnershipTransferStore.findTransferByToken(
      params.transferToken,
    );

    if (!transfer) {
      await websiteOwnershipTransferStore.fetchOwnershipTransferList();
      transfer = websiteOwnershipTransferStore.findTransferByToken(
        params.transferToken,
      );
    }

    const isPendingTransfer =
      transfer?.transferRequestState ===
      Hosting.OwnershipTransferRequestState.PENDING;

    if (!transfer || !isPendingTransfer) {
      removeQueryParamFromUrl('transferToken');

      return null;
    }

    return transfer;
  };

  const handleOwnershipTransferRedirect = async (params: {
    transferToken: string;
  }) => {
    const orderId = await getReferenceIdByOrderToken();
    const transfer = await getTransferFromQueryParam(params);
    removeQueryParamFromUrl('transferToken');
    if (!orderId || !transfer) return;
    acceptingTransfer.value = transfer;
    selectDataCenterAndTransferWebsite(orderId);
  };

  const getRedirectAfterPurchaseLink = () => {
    const transferToken =
      websiteOwnershipTransferStore.acceptingTransfer?.transferToken;
    if (!transferToken) return `${window.origin}`;

    return `${window.origin}/?transferToken=${transferToken}`;
  };

  const openOwnershipTransferModal = () => {
    openModal({
      component: { WebsiteOwnershipTransferModal },
      data: {
        onSuccess() {
          websiteOwnershipTransferStore.fetchOwnershipTransferList();
        },
      },
    });
  };

  return {
    initiateOwnershipTransfer,
    openOwnershipTransferSuccessModal,
    selectDataCenterAndTransferWebsite,
    handleOwnershipTransferRedirect,
    getRedirectAfterPurchaseLink,
    openOwnershipTransferModal,
  };
};
