import { useStore } from 'vuex';

import { useGlobals } from '@/composables';
import { serverInfoRepo, externalApiRepo } from '@/repositories';
import { AmplitudeEvent, type DataCenterName } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

export const usePinger = () => {
  const store = useStore();
  const { amplitudeV2 } = useGlobals();

  type DataCenter = {
    datacenter: DataCenterName;
    url: string;
  };

  const getPingUrl = (dataCenter: DataCenter): string => {
    const cacheBuster = Math.random();

    return `${dataCenter.url}?cacheBuster=${cacheBuster}`;
  };

  const getDeviceType = (): string => {
    const mobileDeviceWidth = 576;

    return window.innerWidth <= mobileDeviceWidth ? 'mobile' : 'desktop';
  };

  const measurePingPerformance = async (
    targetUrl: string,
    orderId: string,
    device: string,
    dataCenter: DataCenterName,
    skipPinger = false,
  ): Promise<number> => {
    let latency = -1;
    try {
      const start = performance.now();
      await externalApiRepo.ping(targetUrl);
      const end = performance.now();
      latency = Number((end - start).toFixed(2));
    } catch (err) {
      amplitudeV2(AmplitudeEvent.Pinger.PINGER_ERROR, {
        dataCenter,
        targetUrl,
      });
      errorLogger.logError(err as Error);
    } finally {
      if (skipPinger) return latency;

      await serverInfoRepo
        .postDCsPinger({
          params: { ip: '0.0.0.0', targetUrl, device, latency },
          config: { orderId },
        })
        .catch((postError) => errorLogger.logError(postError as Error));
    }

    return latency;
  };

  const measureDataCenterLatency = async (
    dataCenter: DataCenter,
    orderId: string,
    skipPinger = false,
  ): Promise<{
    datacenter: DataCenterName;
    latency: number;
    error: boolean;
  }> => {
    const cachelessUrl = getPingUrl(dataCenter);
    const device = getDeviceType();
    const latency = await measurePingPerformance(
      cachelessUrl,
      orderId,
      device,
      dataCenter.datacenter,
      skipPinger,
    );

    return {
      datacenter: dataCenter.datacenter,
      latency,
      error: latency === -1,
    };
  };

  const handleDataCenterSelection = async (
    latencies: Array<{ datacenter: string; latency: number; error: boolean }>,
  ): Promise<void> => {
    const allUrlsSuccessful = latencies.every((latency) => !latency.error);
    allUrlsSuccessful && (await store.dispatch('updateLatencies', latencies));

    const suggestedDataCenter = allUrlsSuccessful
      ? store.getters.getLatencies[0]
      : store.getters.getBestDatacenter;
    suggestedDataCenter &&
      store.commit('SET_SELECTED_DATA_CENTER', {
        [suggestedDataCenter.datacenter]: suggestedDataCenter.label,
      });

    amplitudeV2(AmplitudeEvent.Pinger.PINGER_RESULTS, {
      suggestedDataCenter: suggestedDataCenter?.datacenter || 'none',
    });
  };

  const pingDCs = async (
    dataCenters: DataCenter[],
    { orderId, skipPinger }: { orderId: string; skipPinger?: boolean },
  ): Promise<
    Array<{ datacenter: DataCenterName; latency: number; error: boolean }>
  > => {
    if (!dataCenters || dataCenters.length === 0) {
      return [];
    }

    const latencies = [];
    for (const dataCenter of dataCenters) {
      try {
        const latencyInfo = await measureDataCenterLatency(
          dataCenter,
          orderId,
          skipPinger,
        );
        latencies.push(latencyInfo);
      } catch (err) {}
    }

    return latencies;
  };

  return {
    handleDataCenterSelection,
    measurePingPerformance,
    pingDCs,
  };
};
