<script setup lang="ts">
import { ref } from 'vue';

import type { HActionDropdownOptions } from '../HpActions/hpActionModel';

import HpButton from '@/components/HpButtons/HpButton.vue';
import HpOptionsList from '@/components/HpOptionsList.vue';
import HpIcon from '@/components/Icons/HpIcon.vue';

interface Props {
  dropdownOptions: HActionDropdownOptions[];
  buttonText: string;
  buttonProps: object;
}

interface Emits {
  (eventName: 'selected-option', payload: HActionDropdownOptions): void;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const showHideOptions = ref(false);
</script>

<template>
  <div class="h-button-dropdown">
    <HpButton
      text
      primary
      class="h-button-dropdown__button"
      :class="{ 'h-button-dropdown__button--hovered': showHideOptions }"
      @blur="showHideOptions = false"
      @click="showHideOptions = !showHideOptions"
    >
      {{ buttonText }}
      <template #icon-right>
        <HpIcon
          primary
          static-view-box
          icon="icon-arrow-drop-down"
          class="h-button-dropdown__button-arrow"
          :class="{
            'h-button-dropdown__button-arrow--rotated': showHideOptions,
          }"
        />
      </template>
    </HpButton>
    <HpOptionsList
      :is-open="showHideOptions"
      :options="dropdownOptions"
      @selected-option="emit('selected-option', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.h-button-dropdown {
  position: relative;
  margin-right: 8px;

  &__button {
    display: flex;
    justify-content: center;
    &:not(:last-child) {
      @media (min-width: 576px) {
        margin-right: 0px;
      }
    }
    &--hovered {
      background-color: var(--primary-light);
    }
  }
  &__button-arrow {
    margin-left: 2px;
    vertical-align: bottom;
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    &--rotated {
      transform: rotate(-180deg);
    }
  }

  &__dropdown-options {
    width: 100%;
    top: calc(100% + 4px);
    position: absolute;

    @media (min-width: 576px) {
      width: unset;
    }
  }
}
</style>
