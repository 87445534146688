<script lang="ts" setup>
import { computed } from 'vue';

type DimensionValue = number | string;

type Props = {
  circle?: boolean;
  rounded?: boolean;
  roundedXs?: boolean;
  roundedXl?: boolean;
  width?: DimensionValue;
  height?: DimensionValue;
  isInline?: boolean;
};

const props = defineProps<Props>();

const classes = computed(() => ({
  'skeleton-loader--circle': props.circle,
  'skeleton-loader--rounded': props.rounded,
  'skeleton-loader--rounded-xs': props.roundedXs,
  'skeleton-loader--rounded-xl': props.roundedXl,
  'skeleton-loader--inline': props.isInline,
}));

const getSkeletonSize = (value?: DimensionValue) => {
  if (Number.isInteger(value)) {
    return `${value}px`;
  }

  return value;
};
</script>

<template>
  <div
    data-qa="loader"
    class="skeleton-loader"
    :class="{ ...classes }"
    :style="{
      'max-width': getSkeletonSize(width),
      height: getSkeletonSize(height),
      width: props.isInline ? getSkeletonSize(width) : undefined,
    }"
  />
</template>

<style lang="scss" scoped>
@import '../../sass/components/skeleton-loader';
</style>
