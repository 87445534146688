import cookies from 'js-cookie';

import { clearUserData } from './dataClearService';
import { errorLogger } from './errorLogging';

import authRepo from '@/repositories/authRepo';
import { useProfileStore } from '@/stores';
import { Cookie } from '@/types';
import { timeout } from '@/utils/helpers';
import { parseJwt, getJwtToken } from '@/utils/helpers/authHelpers';
import { getWindowQueryParams } from '@/utils/services/windowService';

const JWT_STALE_TIME = 1 / 24; // 1 hour

export const setAuthToken = (token: string) => {
  const tokenPrefix = 'Bearer ';
  const bearerToken = token.startsWith(tokenPrefix)
    ? token
    : `${tokenPrefix}${token}`;
  localStorage.setItem(Cookie.JWT, bearerToken);

  cookies.set(Cookie.JWT_NOT_STALE, '1', { expires: JWT_STALE_TIME });

  (window as any).updatePusherToken(token);
};

/**
 * Authenticates a user based on the JWT token from the URL or local storage.
 */
export const authenticate = () => {
  const tokenFromParams = getWindowQueryParams()?.jwt;
  const cachedJWTToken = getJwtToken();

  if (!tokenFromParams && !cachedJWTToken) {
    redirectToLogin();

    return;
  }
  watchJWTChangeInOtherTab(tokenFromParams || cachedJWTToken);

  if (!tokenFromParams) return;

  clearUserData();

  setAuthToken(tokenFromParams);
};

/**
 * Logout that performs token invalidation
 * (e.g., when a user clicked a logout button)
 */
export const logoutWithTokenInvalidation = async () => {
  await authRepo.logout();

  appForcedLogout();
};

const getBrand = () => {
  const host = window.location.hostname;
  const STAGE_URL_PART = 'hpanel-stage.';
  const profileStore = useProfileStore();

  if (host.includes(STAGE_URL_PART)) {
    return host.replace(STAGE_URL_PART, '');
  }

  return profileStore.account?.brand.domain || 'hostinger.com';
};

/**
 * Redirects to login page based on the brand.
 */
export const redirectToLogin = () => {
  const brand = getBrand();
  const template = process.env.VITE_LOGOUT_REDIRECT_TEMPLATE as string;
  const url = template.replace('{BRAND}', brand);
  window.location.assign(url);
};

const ABSURDLY_LONG_TIME = 999999;

/**
 * Clears all user data and redirects to login page. Await this function
 * to prevent any further code execution. It is not suitable if some
 * indication (e.g., loader) has to be displayed within the UI.
 */
export const appForcedLogout = async () => {
  clearUserData();
  redirectToLogin();
  errorLogger.ignoreSentryErrors();
  await timeout(ABSURDLY_LONG_TIME);
};

let pendingRequests: Array<() => void> = [];

const releasePendingRequests = () => {
  pendingRequests.forEach((resolve) => resolve());
  pendingRequests = [];
};

let isRefreshInProgress = false;

/**
 * Creates a promise that will be resolved when token refresh is finished.
 */
const waitForTokenRefreshToFinish = () =>
  new Promise<void>((resolve) => {
    pendingRequests.push(resolve);
  });

export const checkAndWaitForTokenRefresh = async () => {
  if (!isRefreshInProgress) {
    return;
  }
  await waitForTokenRefreshToFinish();
};

/**
 * Performs token refresh and releases all pending requests
 */
export const refreshToken = async (tokenFromRequest: string) => {
  const currentToken = getJwtToken();

  // If some request has failed after token refresh,
  // then we don't need to refresh it again. This code
  // handles the case when requests are executed in
  // parallel and some request fails after a token was refreshed.
  const hasTokenChanged = currentToken !== tokenFromRequest;

  if (isRefreshInProgress || hasTokenChanged) {
    return;
  }

  isRefreshInProgress = true;
  const [{ data }, error] = await authRepo.refresh();

  if (error || !data?.token) {
    await appForcedLogout();

    return;
  }

  setAuthToken(data.token);
  isRefreshInProgress = false;
  releasePendingRequests();
};

/**
 * Checks and performs refresh of jwt if token was last updated more than
 * JWT_STALE_TIME amount of time ago
 */
export const refreshTokenIfInitiallyStale = async () => {
  const JWTNotStale = !!cookies.get(Cookie.JWT_NOT_STALE);
  const currentJWT = getJwtToken();
  if (JWTNotStale || !currentJWT) {
    return;
  }

  await refreshToken(currentJWT);
};

/**
 * This function is required only for integration tests,
 * when token refresh is tested multiple times in a row
 * after logout. In real world the state resets with the
 * redirect to login page.
 */
export const resetTokenRefreshState = () => {
  isRefreshInProgress = false;
  pendingRequests = [];
};

const handleJWTChangeInOtherTab = (
  initialClientId: string,
  event: StorageEvent,
) => {
  if (!event.newValue) {
    return;
  }
  const newClientId = parseJwt(event.newValue).sub;

  if (initialClientId !== newClientId) {
    window.location.assign('/');
  }
};

const watchJWTChangeInOtherTab = (initialJwt: string | null) => {
  if (!initialJwt) {
    return;
  }

  const initialClientId = parseJwt(initialJwt).sub;

  window.addEventListener('storage', (event) => {
    if (event.key !== Cookie.JWT) {
      return;
    }

    handleJWTChangeInOtherTab(initialClientId, event);
  });
};
