import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useVpsServerStore } from './vpsServerStore';

import { hVpsRepo } from '@/repositories';
import type { IVpsFirewall, IVpsFirewallRules } from '@/types';

export const useVpsFirewallStore = defineStore('vpsFirewallStore', () => {
  const isLoading = ref(false);
  const isLoadingGroup = ref(false);
  const isLoaded = ref(false);
  const isError = ref(false);
  const groups = ref<IVpsFirewall[]>([]);

  const serverStore = useVpsServerStore();

  const getGroups = async (isRefetch = false, isSilent = false) => {
    if (isLoaded.value && !isRefetch) {
      return;
    }

    if (!isSilent) isLoading.value = true;

    isError.value = false;

    const [{ data }, error] = await hVpsRepo.getFirewallGroups();

    if (error) {
      isLoading.value = false;
      isError.value = true;

      return;
    }

    groups.value = data;
    isLoading.value = false;
    isLoaded.value = true;
  };

  const createGroup = async (id: string, name: string) => {
    const [{ data }, err] = await hVpsRepo.createFirewallGroup(id, name);
    if (err) return [{ data }, err];

    groups.value = [...groups.value, data];

    return [{ data }, err];
  };

  const addRule = async (
    id: string,
    groupId: string,
    rule: IVpsFirewallRules,
  ) => {
    const [{ data }, err] = await hVpsRepo.postAddFirewallRule(
      id,
      groupId,
      rule,
    );

    if (err) return [{ data }, err];

    const groupIndex = getGroupIndexById(groupId);

    groups.value[groupIndex].rules.push(data);
    groups.value[groupIndex].synced = false;

    return [{ data }, err];
  };

  const deleteRule = async (
    serverId: string,
    groupId: string,
    ruleId: string,
  ) => {
    const [{ data }, err] = await hVpsRepo.deleteFirewallRule(
      serverId,
      groupId,
      ruleId,
    );

    if (err) return [{ data }, err];

    const groupIndex = getGroupIndexById(groupId);

    groups.value[groupIndex].rules = groups.value[groupIndex].rules.filter(
      ({ id }) => id !== ruleId,
    );
    groups.value[groupIndex].synced = false;

    return [{ data }, err];
  };

  const editRule = async (
    serverId: string,
    groupId: string,
    rules: IVpsFirewallRules,
  ) => {
    const [{ data }, err] = await hVpsRepo.patchEditFirewallRule(
      serverId,
      groupId,
      rules,
    );

    if (err) return [{ data }, err];

    const groupIndex = getGroupIndexById(groupId);
    const ruleIndex = getRuleIndex(groupId, rules.id || '');

    groups.value[groupIndex].rules[ruleIndex] = data;
    groups.value[groupIndex].synced = false;

    return [{ data }, err];
  };

  const syncRules = async (id: string, groupId: string) => {
    const [{ data }, err] = await hVpsRepo.syncFirewallRules(id, groupId);

    if (err) return [{ data }, err];

    serverStore.setServerLock(Number(id), true);
    serverStore.setUpdatingServer({
      serverId: Number(id),
    });

    return [{ data }, err];
  };

  const editGroup = async (id: string, groupId: string, name: string) => {
    const [{ data }, err] = await hVpsRepo.editFirewallGroup(id, groupId, name);
    if (err) return [{ data }, err];

    const groupIndex = groups.value.findIndex(
      ({ id }) => id === Number(groupId),
    );

    groups.value[groupIndex] = data;

    return [{ data }, err];
  };

  const deleteGroup = async (id: string, groupId: number) => {
    const [{ data }, err] = await hVpsRepo.deleteFirewallGroup(id, groupId);

    if (err) return [{ data }, err];

    groups.value = groups.value.filter(({ id }) => id !== groupId);

    return [{ data }, err];
  };

  const getGroupById = (id: number | string) =>
    groups.value.find((group) => group.id === Number(id));

  const getGroupIndexById = (id: number | string) =>
    groups.value.findIndex((group) => group.id === Number(id));

  const getRuleIndex = (groupId: number | string, ruleId: number | string) =>
    groups.value[getGroupIndexById(groupId)].rules.findIndex(
      ({ id }) => Number(id) === Number(ruleId),
    );

  return {
    isLoading,
    isLoadingGroup,
    isLoaded,
    isError,
    groups,
    editRule,
    getGroups,
    createGroup,
    addRule,
    deleteRule,
    syncRules,
    editGroup,
    deleteGroup,
    getGroupById,
    getGroupIndexById,
  };
});
