import { ref } from 'vue';

export const useLoading = () => {
  const isLoading = ref(false);

  const setIsLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  return {
    isLoading,
    setIsLoading,
    startLoading,
    stopLoading,
  };
};
