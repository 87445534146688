export const getWindowQueryParams = () =>
  Object.fromEntries(new URLSearchParams(window.location.search).entries());

export const removeQueryParamFromUrl = (paramName: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(paramName);
  history.replaceState(null, '', url.toString());
};

export const externalRedirect = async (link: string) => {
  window.open(link, '_blank');
};
