export const MIGRATIONS_ONBOARDING_STEPS = {
  WEBSITE_TO_MIGRATE_NAME: 'websiteToMigrateName',
  BUILDER: 'builder',
  SELECT_WEBSITE_PLATFORM: 'selectWebsitePlatform',
  HOW_TO_MIGRATE_WORDPRESS_WEBSITE: 'howToMigrateWordpressWebsite',
  WHERE_TO_MIGRATE_WEBSITE: 'whereToMigrateWebsite',
  UPLOAD_BACKUPS: 'uploadBackups',
  ENTER_CPANEL_DETAILS: 'cpanelMigrationDetails',
  ENTER_OTHER_CONTROL_PANEL_MIGRATION_DETAILS:
    'otherControlPanelMigrationDetails',
  MIGRATIONS_REQUIREMENTS: 'migrationsRequirements',
  ENTER_WORDPRESS_DETAILS: 'enterWordPressDetails',
  SUMMARY_V2: 'summaryV2',
  SUMMARY: 'summary',
} as const;

export const PANEL_LOGIN_METHOD = {
  CPANEL: 'cpanel',
  WORDPRESS_ADMIN: 'wp_admin',
  OTHER: 'other',
};

export const WORDPRESS_ACCESS_METHOD = {
  WP_ADMIN_LOGIN: 'wp_admin_login',
  BACKUP_FILE: 'backup_file',
  OTHER: 'other',
} as const;

export const WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS = {
  WORDPRESS_ADMIN_DETAILS: 'wordpressAdminDetails',
  BACKUP_FILES_AND_DATABASES: 'backupFilesAndDatabases',
} as const;

export const MIGRATION_PLATFORMS = {
  WORDPRESS: 'wordpress',
  CPANEL: 'cpanel',
  OTHER: 'other',
} as const;

export const MAIN_MIGRATION_PLATFORMS = {
  WORDPRESS: 'wordpress',
  CPANEL: 'cpanel',
} as const;

export type MigrationsOnboardingStep =
  (typeof MIGRATIONS_ONBOARDING_STEPS)[keyof typeof MIGRATIONS_ONBOARDING_STEPS];

export type WordPressHowToMigrateAnswer =
  (typeof WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS)[keyof typeof WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS];

export type MigrationPlatform =
  (typeof MIGRATION_PLATFORMS)[keyof typeof MIGRATION_PLATFORMS];

export type MainMigrationPlatform =
  (typeof MAIN_MIGRATION_PLATFORMS)[keyof typeof MAIN_MIGRATION_PLATFORMS];

export interface IMigrationsSummaryTableData {
  title: string;
  rows: IMigrationsSummaryTableRow[];
}

export interface IMigrationsSummaryTableRow {
  label: string;
  value: string;
  description?: string;
}
