import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SSO}/v1/google-vouchers`,

  async checkVouchers() {
    return await hAsync(http.get(this.url));
  },
  async claimVouchers(email) {
    return await hAsync(http.post(this.url, { email }));
  },
  async getVoucherEligible() {
    return await hAsync(http.get(`${this.url}/is-eligible`));
  },
};
