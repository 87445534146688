import { router } from '@/router';
import store from '@/store';
import type { IHostingAccount } from '@/types';

export const getCurrentOrderDetails = (
  accountDomain: IHostingAccount['domain'] | null = null,
) => {
  const currentDomain =
    accountDomain || router.currentRoute.value.params.domain;
  const currentAccount = !currentDomain
    ? store.getters.getCurrentAccount
    : store.getters.getCurrentAccountWithDomain(currentDomain);

  return currentAccount || {};
};
