export namespace AmplitudeEvent {
  export enum Csat {
    REFERRAL_PROMO_SHOWN = 'csat.referral_modal.shown',
  }

  export enum Trustpilot {
    SHOWN = 'trustpilot.popup.shown',
    ENTER = 'trustpilot.popup.enter',
    SCORED = 'trustpilot.popup.scored',
  }
  export enum AccountActions {
    BANNER_SHOWN = 'account_actions.banner.shown',
    ACTION_ENTER = 'account_actions.action.enter',
    NUDGE_HIDDEN = 'account_actions.action.hidden',
  }

  export enum LanguageSelector {
    LANGUAGE_ENTER = 'language.enter',
    LANGUAGE_SELECTED = 'language.selected',
  }

  export enum Home {
    NO_SERVICES_ENTER = 'home_page.no_services.enter',
    WITH_SERVICES_ENTER = 'home.with_services.enter',
    REFERRALS_BUTTON_SHOWN = 'home_page.referrals.shown',
    ACCOUNT_SHARING_BUTTON_SHOWN = 'home_page.account_sharing.shown',
    ACCOUNT_SHARING_ENTER = 'home_page.account_sharing.enter',
  }

  export enum WhatsNew {
    ENTER = 'whats_new.enter',
  }

  export enum Onboarding {
    ONBOARDING_START = 'onboarding.start',
    SURVEY_START = 'onboarding.survey.start',
    SURVEY_SKIPPED = 'onboarding.survey.skipped',
    SURVEY_FOR_WHO_CHOSEN = 'onboarding.survey.for_who_chosen',
    SURVEY_MIGRATE_SELECTED = 'onboarding.website.migrate_selected',
    SURVEY_ADDITIONAL_HELP_CHOSEN = 'onboarding.survey.additional_help_chosen',
    SURVEY_WHAT_TYPE_CHOSEN = 'onboarding.survey.what_type_chosen',
    SURVEY_WHO_CREATES_CHOSEN = 'onboarding.survey.who_creates_chosen',
    SURVEY_FINISHED = 'onboarding.survey.finished',
    AGREEMENT_SIGNED = 'onboarding.agreement.signed',
    DOMAIN_OPTION_CHOSEN = 'onboarding.domain.option_chosen',
    DOMAIN_AUTOCONNECT_CHOSEN = 'onboarding.domain.existing_domain_auto_connect_chosen',
    DOMAIN_AUTOCONNECT_SKIPPED = 'onboarding.domain.existing_domain_skip_connect_chosen',
    DOMAIN_AUTOCONNECT_SUCCESS = 'onboarding.domain.autoconnect_success',
    DOMAIN_AUTOCONNECT_FAILURE = 'onboarding.domain.autoconnect_failure',
    DOMAIN_BUY_DOMAIN_CHOSEN = 'onboarding.domain.buy_domain_chosen',
    DOMAIN_CLIENT_BUY_DOMAIN_CHOSEN = 'onboarding.domain.client_domain_chosen',
    DOMAIN_CHOOSE_LATER = 'onboarding.domain.choose_later',
    SUMMARY_FINISHED = 'onboarding.summary.finished',
    SUMMARY_EDIT_SELECTION = 'onboarding.summary.edit_selection',
    WP_PLUGINS_SEARCHED = 'onboarding.wp_plugins.searched',
    WP_TEMPLATE_SEARCHED = 'onboarding.wp_template.searched',
    WP_PLUGINS_SELECTED = 'onboarding.wp_plugins.selected',
    WP_PLUGINS_SKIPPED = 'onboarding.wp_plugins.skipped',
    BACK_BUTTON_CLICKED = 'onboarding.back_button.selected',
    BUILD_SELECTED = 'onboarding.website.build_selected',
    MIGRATE_SELECTED = 'onboarding.website.migrate_selected',
    PLATFORM_SELECTED = 'onboarding.website.platform_selected',
    PLATFORM_CHIP_SELECTED = 'onboarding.website.platform_closeup',
    TESTIMONIAL_OPENED = 'onboarding.website.testimonial_opened',
    TESTIMONIAL_SCROLLED = 'onboarding.website.testimonial_scrolled',
    DOMAIN_BUY_DOMAIN_TAKEN = 'onboarding.domain.buy_domain_taken',
    MIGRATION_UPLOAD_COMPLETED = 'onboarding.migration.upload_completed',
    WP_GENERATE_CONTENT_SELECTED = 'onboarding.wp_generate_content.selected',
    WP_GENERATE_CONTENT_SKIPPED = 'onboarding.wp_generate_content.skipped',
    WP_ACCOUNT_CREATED = 'onboarding.wp_account.created',
    WP_TEMPLATE_SELECTED = 'onboarding.wp_template.selected',
    WP_TEMPLATE_SKIPPED = 'onboarding.wp_template.skipped',
    WP_GENERATE_CONTENT_VIEWED = 'onboarding.wp_generate_content.enter',
    DOMAIN_CLAIM_FREE_CHOSEN = 'onboarding.domain.claim_free_domain_chosen',
    DOMAIN_CLAIM_EXISTING_CHOSEN = 'onboarding.domain.existing_domain_chosen',
    WP_APPEARANCE_SELECTED = 'onboarding.wp_appearance.selected',
    WP_APPEARANCE_SKIPPED = 'onboarding.wp_appearance.skipped',
    WP_STARTER_TEMPLATE_SELECTED = 'onboarding.starter_template.selected',
    WP_STARTER_TEMPLATE_SKIPPED = 'onboarding.starter_template.skipped',
    WP_STARTER_TEMPLATE_PREVIEW = 'onboarding.starter_template.preview',
    WP_STARTER_TEMPLATE_PREVIEW_DETAILS = 'onboarding.starter_template.preview_details',
    ONBOARDING_COMPLETED = 'onboarding.completed',
    GRANT_ACCESS_SELECTED = 'onboarding.website.grant_access_selected',
    GRANT_ACCESS_SKIPPED = 'onboarding.grant_access.skipped',
    GRANT_ACCESS_EMAIL_SENT = 'onboarding.grant_access.email_sent',
    PRESETS_SHOWN = 'onboarding.wp_presets.shown',
    PRESET_CHOSEN = 'onboarding.wp_presets.chosen',
    INSTALL_SKIP = 'onboarding.install_skip',
    WP_BUILDER_TYPE_SELECTED = 'onboarding.wp_builder_type.selected',
    WP_AI_BUILDER_LOADING = 'onboarding.wp_ai_builder.loading',
  }

  export enum Migration {
    MIGRATION_ADD_REQUEST = 'account.migrate_website.add_request',
    MIGRATION_START = 'websites.migration.start',
    MIGRATION_UPLOAD_CHOSEN = 'websites.migration.upload_chosen',
    MIGRATION_WEBSITE_URL_CHOSEN = 'websites.migration.website_url_chosen',
    MIGRATION_PLATFORM_DETECTED = 'websites.migration.platform_detected',
    MIGRATION_SUMMARY_SHOWN = 'websites.migration.summary_shown',
    MIGRATION_SUMMARY_UPDATED = 'websites.migration.summary_updated',
  }

  export enum Pinger {
    PINGER_ERROR = 'hosting.pinger.error',
    PINGER_RESULTS = 'hosting.pinger.results',
  }

  export enum Domain {
    OFFER_SHOWN = 'domain.get_new_domain.offer_shown',
    OFFER_CHOSEN = 'domain.get_new_domain.offer_chosen',
    CROSS_SELL_OFFER_CHOSEN = 'domain.cross_sell.offer_chosen',
    CROSS_SELL_OFFER_SHOWN = 'domain.cross_sell.offer_shown',
    CROSS_SELL_OFFER_ENTERED = 'domain.cross_sell.enter',
    CHANGE_SLD_SHOWN = 'domain.domain_taken.shown',
    SLD_CHANGED = 'domain.domain_taken.sld_changed',
    AUTO_RENEW_ENABLED = 'domain.renew.auto_toggle_on',
    AUTO_RENEW_DISABLED = 'domain.renew.auto_toggle_off',
    MANAGEMENT_ENTERED = 'domain.manage.enter',
    DNS_MANAGEMENT_ENTERED = 'domain.dns_or_nameservers.enter',
    CHECKER_ENTERED = 'domain.get_new_domain.enter',
    TRANSFER_ENTERED = 'domain.transfer_domain.enter',
    CLAIM_FREE_DOMAIN_ENTERED = 'domain.claim_free_domain.enter',
    CLAIM_FREE_DOMAIN_CHECK_AVAILABILITY = 'domain.claim_free_domain.check_availability',
    CLAIM_FREE_DOMAIN_CLAIMED = 'domain.claim_free_domain.domain_claimed',
    BILLING_AUTO_RENEW_ENABLED = 'billing.auto_renew.toggle_on',
    BILLING_AUTO_RENEW_DISABLED = 'billing.auto_renew.toggle_off',
    ALREADY_TAKEN = 'domain.get_new_domain.domain_taken',
    PROMOTION_OFFER_SHOWN = 'domain.get_new_domain_promotion.offer_shown',
    PROMOTION_OFFER_CHOSEN = 'domain.get_new_domain_promotion.offer_chosen',
    ALL_ENDINGS_OFFER_SHOWN = 'domain.get_new_domain_all_endings.offer_shown',
    CATEGORY_OFFER_SHOWN = 'domain.get_new_domain_category.offer_shown',
    CATEGORY_OFFER_CHOSEN = 'domain.get_new_domain_category.offer_chosen',
    ALL_ENDINGS_OFFER_CHOSEN = 'domain.get_new_domain_all_endings.offer_chosen',
    PURCHASE_COMPLETED_ONBOARDING = 'onboarding.domain.buy_domain_completed',
    ENTER = 'domains.enter',
    FILTER_CHOSEN = 'domains.filter_chosen',
    TRANSFER_MORE_OPTIONS_CHOSEN = 'domain.transfer_domain.buy_new.more_options',
    TRANSFER_OFFER_CHOSEN = 'domain.transfer_domain.offer_chosen',
    TRANSFER_OFFER_SHOWN = 'domain.transfer_domain.offer_shown',
    THIRD_PARTY_MANAGE_ENTERED = 'domain.3rd_party_manage.enter',
    THIRD_PARTY_MANAGE_CONNECT_DOMAIN_ENTERED = 'domain.3rd_party_manage.connect_domain.enter',
    DOMAIN_ONLY_HOSTING_OFFER_SHOWN = 'domain.domain_only_hosting_offer.offer_shown',
    AUTO_RENEW_TOGGLE_ON = 'domain.auto_renew.toggle_on',
    FORWARDING_CREATED = 'domain.forwarding.created',
    FORWARDING_REMOVED = 'domain.forwarding.removed',
    FORWARDING_ENTER = 'domain.forwarding.enter',
    TLD_NOT_SUPPORTED = 'domain.get_new_domain.domain_not_supported',
    INITIATE_DOMAIN_MOVE_ENTER = 'domain.initiate_domain_move.enter',
    INITIATE_DOMAIN_MOVE_REQUEST_SENT = 'domain.initiate_domain_move.request_sent',
    CANCEL_DOMAIN_MOVE_ENTER = 'domain.cancel_domain_move.enter',
    CANCEL_DOMAIN_MOVE_CONFIRMED = 'domain.cancel_domain_move.confirmed',
    DOMAIN_MOVE_REQUEST_SHOWN = 'domain.domain_move_request.shown',
    DOMAIN_MOVE_REQUEST_ACCEPTED = 'domain.domain_move_request.accepted',
    DOMAIN_MOVE_REQUEST_DECLINED = 'domain.domain_move_request.declined',
    PENDING_DOMAIN_MOVE_CHECK_AGAIN = 'domain.pending_domain_move.check_again',
    DOMAIN_REGISTRATION_COMPLETED = 'domain.domain_registration.completed',
    DOMAIN_ACTION_BAR_CHOSEN = 'domain.action_bar.action_chosen',
    DOMAIN_ACTION_BAR_CONFIRMED = 'domain.action_bar.action_confirmed',
    DOMAIN_GENERATE_DOMAIN_SUBMITTED = 'domain.generate_domain.generate',
    DOMAIN_GENERATE_DOMAIN_OPENED = 'domain.generate_domain.enter',
    DOMAIN_TRANSFER_REQUIREMENT_FAILED = 'domain.transfer_domain.requirements_not_met',
    DOMAIN_ACTION_TYPE_CHOSEN = 'domain.action_bar.action_type_chosen',
    TRANSFERS_ENTER = 'domain.transfers.enter',
    TRANSFER_ENTER = 'domain.transfer_domain.enter',
    CONTACT_INFORMATION_ENTERED = 'domain.contact_information.enter',
    TRANSFER_INFORMATION_ENTERED = 'domain.transfer_information.enter',
    DOMAIN_REGISTRATION_ENTER = 'domain.domain_registration.enter',
    DOMAIN_REGISTRATION_EDIT_ENTER = 'domain.domain_registration.edit_enter',
    MOVE_DOMAIN_INFORMATION_SHOWN = 'domain.move_domain.information_shown',
    CHANGE_NAMESERVERS_ENTERED = 'domain.change_nameservers.enter',
    NAMESERVERS_CHANGED = 'domain.change_nameservers.changed',
    NAMESERVERS_IS_NOT_VALID = 'domain.confirm_nameservers.modal_shown',
    NAMESERVERS_MODAL_ERROR_SHOWN = 'domain.change_nameservers.error_shown',
    CONTACTS_CHANGE_ENTERED = 'domain.change_contacts.enter',
    CONTACTS_CHANGED = 'domain.change_contacts.changed',
    CREATE_PROFILE_ENTERED = 'domain.create_new_profile.enter',
    PROFILE_CREATED = 'domain.create_new_profile.created',
    PERIOD_CHOICE_SHOWN = 'domain.get_new_domain.period_choice_shown',
    PROMOTION_PERIOD_CHOICE_SHOWN = 'domain.get_new_domain_promotion.period_choice_shown',
    CATEGORY_PERIOD_CHOICE_SHOWN = 'domain.get_new_domain_category.period_choice_shown',
    SUSPICIOUS_KEYWORD_DETECTED = 'domain.suspicious_domain.suspicious_keyword_detected',
    SUSPICIOUS_CHECK_CONFIRMED = 'domain.suspicious_domain.additional_check_confirmed',
    CHANGE_SUSPICIOUS_ENTERED = 'domain.change_suspicious_domain.enter',
    SUSPICIOUS_DOMAIN_CHANGED = 'domain.change_suspicious_domain.domain_changed',
    TRANSFER_SETUP_SHOWN = 'domain.pending_transfer_setup.shown',
    TRANSFER_SETUP_ENTER = 'domain.pending_transfer_setup.enter',
    TRANSFER_SETUP_COMPLETED = 'domain.pending_transfer_setup.setup_completed',
    TRANSFER_SETUP_FAILED = 'domain.pending_transfer_setup.setup_failed',
    INSTANT_DOMAIN_REGISTRATION_VALIDATION_FAIL = 'domain.domain_registration.validation_failed',
    DOMAIN_QR_CODE_GENERATE = 'domain.qr_code.generate_qr_code',
    DOMAIN_QR_CODE_DOWNLOAD = 'domain.qr_code.download_qr_code',
    WPP_DISABLE_MODAL = 'domain.privacy_protection.confirmation_modal_shown',
    WPP_DISABLED = 'domain.privacy_protection.disabled',
    WPP_ENABLED = 'domain.privacy_protection.enabled',
    TRANSFER_LOCK_ENABLED = 'domain.transfer_lock.enabled',
    TRANSFER_LOCK_DISABLED = 'domain.transfer_lock.disabled',
    TRANSFER_LOCK_MODAL_SHOWN = 'domain.transfer_lock.confirmation_modal_shown',
    PROPAGATION_BANNER_SHOWN = 'domain.propagation_banner.shown',
    PROPAGATION_MODAL_SHOWN = 'domain.propagation_modal.shown',
    PROPAGATION_MODAL_REFRESH = 'domain.propagation_modal.refresh',
    PROPAGATION_MODAL_LEARN_MORE = 'domain.propagation_modal.learn_more',
  }

  export enum Vps {
    OFFER_SHOWN = 'vps.new.offer_shown',
    OFFER_CHOSEN = 'vps.new.offer_chosen',
    MANAGEMENT_ENTERED = 'vps.manage.enter',
    SETTINGS_ENTERED = 'vps.settings.enter',
    SETTINGS_EMERGENCY_MODE = 'vps.settings.emergency_mode',
    SETTINGS_RESET_FIREWALL = 'vps.settings.reset_firewall',
    SETTINGS_RESET_SSH = 'vps.settings.reset_ssh',
    SETTINGS_TUN_TAP = 'vps.settings.tuntap',
    SETTINGS_NFS = 'vps.settings.nfs',
    SETTINGS_CHANGE_DNS = 'vps.settings.change_dns',
    SETTINGS_CHANGE_HOSTNAME = 'vps.settings.change_hostname',
    SETTINGS_SSH_KEY_ADD = 'vps.settings.ssh_key_add',
    SETTINGS_RESET_ROOT_PASSWORD = 'vps.settings.reset_root_pass',
    SETTINGS_DELETE_LOGS = 'vps.settings.delete_logs',
    SETTINGS_DISK_QUOTA = 'vps.settings.disk_quota',
    SETTINGS_PTR_SET = 'vps.settings.ptr_set',
    SERVER_USAGE_ENTERED = 'vps.server_usage.enter',
    SNAPSHOTS_AND_BACKUPS_ENTERED = 'vps.snapshots_backups.enter',
    LATEST_ACTIONS_ENTERED = 'vps.latest_actions.enter',
    OPERATING_SYSTEM_ENTERED = 'vps.operating_system.enter',
    HELP_ENTERED = 'vps.help.enter',
    UPGRADE_OFFER_SHOWN = 'vps.upgrade.offer_shown',
    UPGRADE_OFFER_CHOSEN = 'vps.upgrade.offer_chosen',
    RENEW_OFFER_SHOWN = 'vps.renew.offer_shown',
    VPS_PURCHASE_OFFER_SHOWN = 'purchase_modal.vps_hosting.offer_shown',
    VPS_PURCHASE_OFFER_CHOSEN = 'purchase_modal.vps_hosting.offer_chosen',
    VPS_PURCHASE_MODAL_CLOSED = 'purchase_modal.vps_hosting.closed',
    ENTER = 'vps.enter',
    OVERVIEW_ENTERED = 'vps.overview.enter',
    AI_ASSISTANT_ENTERED = 'vps.ai_assistant.enter',
    LICENSES_ENTERED = 'vps.licenses.enter',
    MALWARE_SCANNER_ENTERED = 'vps.malware_scanner.enter',
    FIREWALL_ENTERED = 'vps.firewall.enter',
    FIREWALL_CREATE = 'vps.firewall.create',
    FIREWALL_CREATED = 'vps.firewall.created',
    FIREWALL_ACTIVATE = 'vps.firewall.activate',
    FIREWALL_ACTIVATED = 'vps.firewall.activated',
    FIREWALL_DEACTIVATE = 'vps.firewall.deactivate',
    FIREWALL_DEACTIVATED = 'vps.firewall.deactivated',
    FIREWALL_EDIT = 'vps.firewall.edit',
    FIREWALL_DELETE = 'vps.firewall.delete',
    FIREWALL_DELETED = 'vps.firewall.deleted',
    FIREWALL_RULE_ADD = 'vps.firewall.rule_add',
    FIREWALL_RULE_DELETE = 'vps.firewall.rule_delete',
    FIREWALL_RULE_DELETED = 'vps.firewall.rule_deleted',
    FIREWALL_RULE_EDIT = 'vps.firewall.rule_edit',
    FIREWALL_RULE_EDITED = 'vps.firewall.rule_edited',
    TUTORIALS_ENTERED = 'vps.tutorials.enter',
    ONBOARDING_ENTERED = 'vps.onboarding.enter',
    ONBOARDING_START = 'vps.onboarding.start',
    ONBOARDING_LOCATION_CHOSEN = 'vps.onboarding.location_chosen',
    ONBOARDING_OS_TYPE_CHOSEN = 'vps.onboarding.os_type_chosen',
    ONBOARDING_OS_CHOSEN = 'vps.onboarding.os_chosen',
    ONBOARDING_INSTALL_FEATURES = 'vps.onboarding.install_features',
    ONBOARDING_ACCOUNT_SETUP = 'vps.onboarding.account_setup',
    ONBOARDING_BUY_DOMAIN = 'vps.onboarding.buy_domain',
    ONBOARDING_COMPLETED = 'vps.onboarding.completed',
    ONBOARDING_PASSWORD_CHOSEN = 'vps.onboarding.password_chosen',
    TERMINAL_ENTERED = 'vps.terminal.enter',
    OS_PASSWORD_RESET_ENTERED = 'vps.operating_system_password_reset.enter',
    OS_CHANGE_ENTERED = 'vps.operating_system_change_os.enter',
    OS_BUY_LICENSE_ENTERED = 'vps.operating_system_buy_license.enter',
    OS_REINSTALL_ENTERED = 'vps.operating_system_reinstall.enter',
    REFERRAL_ENTERED = 'vps.refer.enter',
    KB_ENTERED = 'vps.kb.enter',
    VPS_REBOOT_ENTER = 'vps.reboot_vps.enter',
    VPS_UPDATE_ROOT_PASSWORD_ENTER = 'vps.update_root_password.enter',
    DNS_MANAGER_ENTERED = 'vps.dns_manager.enter',
    AI_ASSISTANT_MESSAGE_SENT = 'vps.ai_assistant.message_sent',
    AI_ASSISTANT_MESSAGE_RECEIVED = 'vps.ai_assistant.message_received',
    SURVEY_SUBMIT = 'vps.survey.submit',
  }

  export enum Email {
    EXISTING_DOMAIN_CHOSEN = 'email.onboarding.existing_domain.chosen',
    CHOOSE_DOMAIN_CHOSEN = 'email.onboarding.choose_domain.chosen',
    CREATE_EMAIL_FILLED = 'email.onboarding_create_email.filled',
    CREATE_EMAIL_SKIPPED = 'email.onboarding_create_email.skipped',
    DNS_SETTINGS_CONTINUE = 'email.onboarding_dns_settings.continue',
    SUMMARY_FINISHED = 'email.onboarding_summary.finished',
    UPGRADE_OFFER_SHOWN = 'email.upgrade.offer_shown',
    MANAGEMENT_ENTERED = 'email.manage.enter',
    RENEW_SHOWN = 'email.renew.offer_shown',
    RENEW_CHOSEN = 'email.renew.offer_chosen',
    OFFER_SHOWN = 'email.new.offer_shown',
    OFFER_CHOSEN = 'email.upgrade.offer_chosen',
    EMAIL_NEW_TRIAL_OFFER_SHOWN = 'email.new.trial_offer_shown',
    NEW_TRIAL_CHOSEN = 'email.new.trial_offer_chosen',
    TRIAL_LIMIT_REACHED = 'email.trial_limit.reached',
    ACCOUNTS_ENTERED = 'email.email_accounts.enter',
    IMPORT_ENTERED = 'email.import_email.enter',
    ALIAS_ENTERED = 'email.email_alias.enter',
    LOGS_ENTERED = 'email.email_logs.enter',
    AUTO_RENEW_TOGGLE_ON = 'email.auto_renewal.toggle_on',
    DNS_SETTINGS_ENTERED = 'email.dns_settings.enter',
    CONFIGURATION_SETTINGS_ENTERED = 'email.configuration_settings.enter',
    AUTOMATIC_REPLY_ENTERED = 'email.automatic_reply.enter',
    CUSTOM_DKIM_ENTERED = 'email.custom_dkim.enter',
    EMAIL_CHOSEN = 'email.new.offer_chosen',
    ENTER = 'emails.enter',
    ONBOARDING_ENTERED = 'email.onboarding.enter',
    APP_SETUP_WIZARD_SELECTED = 'email.app_setup_wizard_email_client.selected',
    APP_SETUP_WIZARD_CONFIGURATION_TYPE_SELECTED = 'email.app_setup_wizard_configuration_type.selected',
    APP_SETUP_WIZARD_CLIENT_PROFILE_GENERATE = 'email.app_setup_wizard_client_profile.generate',
    APP_SETUP_WIZARD_CLIENT_PROFILE_COPIED = 'email.app_setup_wizard_client_profile.copied',
    APP_SETUP_WIZARD_CLIENT_PROFILE_DOWNLOADED = 'email.app_setup_wizard_client_profile.downloaded',
    APP_SETUP_WIZARD_CLIENT_HELP_ARTICLE_CLICKED = 'email.app_setup_wizard_help_article.clicked',
    APP_SETUP_WIZARD_SETUP_SKIPPED = 'email.app_setup_wizard_setup.skipped',
    APP_SETUP_WIZARD_SETUP_COMPLETED = 'email.app_setup_wizard_setup.completed',
    APP_SETUP_WIZARD_SETUP_CREATE_ANOTHER_ACCOUNT_ENTER = 'email.app_setup_wizard_create_another_account.enter',
    EMAIL_TITAN_ONBOARDING_OFFER_SHOW = 'email.titan_onboarding_hosting_offer.offer_shown',
    EMAIL_APP_SETUP_WIZARD_ENTER = 'email.app_setup_wizard.enter',
    EMAIL_RECEIVE_EMAILS_CARD_ENTER = 'email.receive_emails.enter',
    EMAIL_PROTECT_EMAIL_ACCOUNT_ENTER = 'email.protect_email_account.enter',
    EMAIL_INCREASE_EMAIL_DELIVERABILITY_CARD_ENTER = 'email.increase_email_deliverability.enter',
    EMAIL_MESSAGE_AUTHENTICATION_ENTER = 'email.email_message_authentication.enter',
    EMAIL_CONNECT_DOMAIN_CHECK_AGAIN = 'email.connect_domain.check_again',
    EMAIL_CONNECT_DOMAIN_AUTOMATICALLY = 'email.connect_domain.connect_automatically',
    EMAIL_CONNECT_DOMAIN_ENTER = 'email.connect_domain.enter',
    EMAIL_CONNECT_DOMAIN_BANNER_SHOWN = 'email.connect_domain.banner_shown',
    EMAIL_INCREASE_EMAIL_DELIVERABILITY_CARD_SHOWN = 'email.increase_email_deliverability.card_shown',
    EMAIL_PROTECT_ACCOUNT_CARD_SHOWN = 'email.protect_email_account.card_shown',
    EMAIL_RECEIVE_EMAILS_CARD_SHOWN = 'email.receive_emails.card_shown',
    EMAIL_CONNECT_DOMAIN_AUTOMATICALLY_SET_DEFAULT = 'email.connect_domain_automatically.set_default',
    EMAIL_EXPECTED_DOMAIN_RECORDS_COPY_AS_TEXT = 'email.expected_records.copy_as_text',
    EMAIL_CLAIM_FREE_EMAIL_BANNER_SHOWN = 'email.claim_free_email.banner_shown',
    EMAIL_CLAIM_FREE_EMAIL_ENTER = 'email.claim_free_email.enter',
    EMAIL_CLAIM_FREE_EMAIL_DOMAIN_CHOSEN = 'email.claim_free_email.domain_chosen',
    EMAIL_PENDING_HOSTING_SETUP_BANNER_SHOWN = 'email.pending_hosting_setup.banner_shown',
    EMAIL_NEW_PERIOD_CHOICE_SHOWN = 'email.new.period_choice_shown',
    EMAIL_WEBMAIL_ENTER = 'email.webmail.enter',
    EMAIL_ADD_RECORDS_STATUS_SHOWN = 'email.add_records.status_shown',
    EMAIL_DOMAIN_STATUS_BANNER_SHOWN = 'email.domain_status.banner_shown',
    EMAIL_OVERVIEW_NAVIGATE_TO_SUBSCRIPTION = 'email.renew_domain.navigate_to_subscription',
    EMAIL_SETUP_ENTER = 'email.setup.enter',
    EMAIL_GET_PLAN_OR_MAILBOX_ENTER = 'email.get_plan_or_mailbox.enter',
    EMAIL_UPGRADE_SELECT_DOMAIN_ENTER = 'email.upgrade.select_domain_enter',
    EMAIL_NEW_DURATION_CHANGED = 'email.new.duration_changed',
    EMAIL_NEW_ADDITIONAL_ACCOUNT_ADDED = 'email.new.additional_account_added',
    EMAIL_NEW_ADDITIONAL_ACCOUNT_REMOVED = 'email.new.additional_account_removed',
    EMAIL_UPGRADE_ADDITIONAL_ACCOUNT_ADDED = 'email.upgrade.additional_account_added',
    EMAIL_UPGRADE_ADDITIONAL_ACCOUNT_REMOVED = 'email.upgrade.additional_account_removed',
    EMAIL_UPGRADE_DURATION_CHANGED = 'email.upgrade.duration_changed',
    EMAIL_UPGRADE_PERIOD_CHOICE_SHOWN = 'email.upgrade.period_choice_shown',
    EMAIL_CLAIM_FREE_EMAIL_SETUP_ENTER = 'email.claim_free_email.setup_enter',
    EMAIL_CLAIM_FREE_EMAIL_TIMEOUT_ERROR_SHOWN = 'email.claim_free_email.timeout_error_shown',
    EMAIL_GET_STARTED_CHECKLIST_SHOWN = 'email.get_started.checklist_shown',
    EMAIL_UPGRADE_FREE_OPTION_CHOSEN = 'email.upgrade.free_option_chosen',
    EMAIL_VIEW_LIMITS_ENTER = 'email.view_limits.enter',
    EMAIL_ACCOUNTS_ENTER = 'email.accounts.enter',
    EMAIL_CHANGE_PASSWORD_ENTER = 'email.change_password.enter',
    EMAIL_CHANGE_PASSWORD_CHANGED = 'email.change_password.changed',
    EMAIL_ACCOUNT_SETTINGS_ENTER = 'email.account_settings.enter',
    EMAIL_ACCOUNT_SETTINGS_CHANGED = 'email.account_settings.changed',
    EMAIL_CREATE_FORWARDER_ENTER = 'email.create_forwarder.enter',
    EMAIL_CREATE_FORWARDER_CREATED = 'email.create_forwarder.created',
    EMAIL_CREATE_ALIAS_ENTER = 'email.create_alias.enter',
    EMAIL_CREATE_ALIAS_CREATED = 'email.create_alias.created',
    EMAIL_CREATE_AUTOMATIC_REPLY_ENTER = 'email.create_automatic_reply.enter',
    EMAIL_CREATE_AUTOMATIC_REPLY_CREATED = 'email.create_automatic_reply.created',
    EMAIL_CREATE_CATCH_ALL_ENTER = 'email.create_catch_all.enter',
    EMAIL_CREATE_CATCH_ALL_CREATED = 'email.create_catch_all.created',
    EMAIL_DELETE_ACCOUNT_ENTER = 'email.delete_account.enter',
    EMAIL_DELETE_ACCOUNT_DELETED = 'email.delete_account.deleted',
    EMAIL_UPGRADE_FREE_EMAIL_SUBSCRIPTION_BANNER_SHOWN = 'email.upgrade_free_email_subscription.banner_shown',
    EMAIL_APP_SETUP_WIZARD_EMAIL_USAGE_TYPE_SELECTED = 'email.app_setup_wizard_email_usage_type.selected',
  }

  export enum BlackFriday {
    BLACK_FRIDAY_UPGRADE_BANNER_SHOWN = 'black_friday.upgrade.banner_shown',
    BLACK_FRIDAY_OFFER_SHOWN = 'black_friday.upgrade.offer_shown',
    BLACK_FRIDAY_OFFER_CHOSEN = 'black_friday.upgrade.offer_chosen',
    BLACK_FRIDAY_OFFER_BACK = 'black_friday.upgrade.back',

    BLACK_FRIDAY_BANNER_OFFER_MINIMISED = 'black_friday.banner.offer_minimised',
    BLACK_FRIDAY_BANNER_SHOWN = 'black_friday.banner.shown',

    BLACK_FRIDAY_BANNER_OFFER_CHOSEN = 'black_friday.banner.offer_chosen',
    BLACK_FRIDAY_BANNER_OFFER_EXPANDED = 'black_friday.banner.offer_expanded',
    BLACK_FRIDAY_SELECT_PLAN_SHOWN = 'black_friday.select_plan.shown',
    BLACK_FRIDAY_SELECT_PLAN_SELECTED = 'black_friday.select_plan.selected',
    BLACK_FRIDAY_BANNER_OFFER_SHOWN = 'black_friday.banner.offer_shown',
  }

  export enum Support {
    BOOK_A_CALL = 'support.book_a_call.enter',
  }

  export enum Hosting {
    CROSS_SELL_OFFER_CHOSEN = 'hosting.cross_sell_protect_domain.offer_chosen',
    COPY_WEBSITE_ENTER = 'hosting.copy_website.enter',
    COPY_WEBSITE_START = 'hosting.copy_website.modal_confirmation.start',
    COPY_WEBSITE_ADD = 'hosting.copy_website.add_website.add',
    BACKUPS_FILES_CLICK = 'web_hosting.backup.files_next_step.clicked',
    BACKUPS_DATABASES_CLICK = 'web_hosting.backup.show_databases_button.clicked',
    BACKUPS_FULL_CLICK = 'web_hosting.backup.full_restore.clicked',
    BACKUPS_RESTORE_ENTER = 'hosting.restore_website.enter',
    BACKUPS_RESTORE_SELECTED = 'hosting.restore_website.restore_selected',
    BACKUPS_RESTORE_CONFIRMED = 'hosting.restore_website.restore_confirmed',
    BACKUPS_MANAGE_ENTER = 'hosting.backup_manage.enter',
    INODES_FILES_UPGRADE_OFFER_SHOWN = 'hosting.inode_manage_files_upgrade.offer_shown',
    INODES_FILES_MANAGE_ENTER = 'hosting.inode_manage_files.enter',
    RENEW_OFFER_SHOWN = 'hosting.renew.offer_shown',
    UPGRADE = 'hosting.upgrade.upgrade',
    UPGRADE_OFFER_SHOWN = 'hosting.upgrade.offer_shown',
    UPGRADE_OFFER_SHOWN_OC_TIP = 'hosting.upgrade.object_cache_tip',
    UPGRADE_OFFER_CHOSEN = 'hosting.upgrade.offer_chosen',
    UPGRADE_LIMITS_REACHED = 'hosting.upgrade.limits_reached',
    UPGRADE_LIMITS_ALMOST_REACHED = 'hosting.upgrade.limits_almost_reached',
    DOMAINS_RENEW_ENTER = 'hosting.domains_renew.enter',
    DOMAINS_MANAGE_ENTER = 'hosting.domains_manage.enter ',
    DETAILS_ENTER = 'hosting.see_hosting_details.enter',
    EMAILS_MANAGE_ENTER = 'hosting.emails_manage.enter',
    CLOUD_HOSTING_PURCHASE_OFFER_SHOWN = 'purchase_modal.cloud_hosting.offer_shown',
    SHARED_HOSTING_PURCHASE_OFFER_SHOWN = 'purchase_modal.shared_hosting.offer_shown',
    CLOUD_HOSTING_PURCHASE_OFFER_CHOSEN = 'purchase_modal.cloud_hosting.offer_chosen',
    SHARED_HOSTING_PURCHASE_OFFER_CHOSEN = 'purchase_modal.shared_hosting.offer_chosen',
    CLOUD_HOSTING_PURCHASE_MODAL_CLOSED = 'purchase_modal.cloud_hosting.closed',
    SHARED_HOSTING_PURCHASE_MODAL_CLOSED = 'purchase_modal.shared_hosting.closed',
    NO_SERVICES_ENTER = 'hosting.no_services.enter',
    TIP_CLOSED = 'hosting.tip.closed',
    OFFER_SHOWN = 'hosting.new.offer_shown',
    OFFER_CHOSEN = 'hosting.new.offer_chosen',
    OBJECT_CACHE_ENABLED = 'hosting.object_cache.enabled',
    OBJECT_CACHE_DISABLED = 'hosting.object_cache.disabled',
    SWITCH_OLD_HOSTING_PAGE = 'hosting.switched_to_old',
    SWITCH_NEW_HOSTING_PAGE = 'hosting.switched_to_new',
    DASHBOARD_ENTER = 'hosting.dashboard.enter',
    SWITCH_OLD_DASHBOARD = 'hosting.dashboard_version.switched_to_old',
    ACCESS_WEBSITE_ENTER = 'hosting.access_website.enter',
    EDIT_WEBSITE_ENTER = 'hosting.edit_website.enter',
    EDIT_ECOMMERCE_ENTER = 'hosting.edit_ecommerce.enter',
    CONNECT_DOMAIN_SHOWN = 'hosting.connect_domain.shown',
    CONNECT_DOMAIN_ENTER = 'hosting.connect_domain.enter',
    CONNECT_EXTERNAL_DOMAIN_ENTER = 'hosting.connect_external_domain.enter',
    DATA_CENTER_ENTER = 'hosting.data_centers.enter',
    WEBSITES_LIST_ENTER = 'hosting.websites_added.enter',
    RESOURCE_USAGE_ENTER = 'hosting.resource_usage.enter',
    ADD_WEBSITE_ENTER = 'hosting.add_website.enter',
    MIGRATE_WEBSITE_ENTER = 'hosting.migrate_website.enter',
    RENEW_HOSTING_EXPIRED = 'hosting.renew.hosting_expired',
    RENEW_HOSTING_EXPIRING = 'hosting.renew.hosting_expiring',
    PENDING_SETUP_ENTER = 'hosting.pending_setup.enter',
    PENDING_SETUP_TRIGGER = 'hosting.pending_setup.trigger',
    RENEW_OFFER_CHOSEN = 'hosting.renew.offer_chosen',
    RENEW_HOSTING_SUSPENDED = 'hosting.renew.hosting_suspended',
    RENEW_HOSTING_CANCELED = 'hosting.renew.hosting_canceled',
    CONTACT_US_HOSTING_SUSPENDED = 'hosting.contact_us.hosting_suspended',
    MANAGE_ENTER = 'hosting.manage.enter',
    RENAME_ENTER = 'hosting.rename.enter',
    RENAME_RENAMED = 'hosting.rename.renamed',
    ENTER_BOOST_24H = 'hosting.boost_24h.enter',
    ENABLED_BOOST_24H = 'hosting.boost_24h.enabled',
    ENTER = 'hosting.enter',
    ENTER_PERFORMANCE_SCORE_DETAILS = 'hosting.load_details.enter',
    INODES_RUNNING_OUT_ISSUE_SHOWN = 'hosting.inodes_running_out_issue.shown',
    REDUCE_INODES_CLICKED = 'hosting.reduce_inodes.clicked',
    FULL_DISK_ISSUE_SHOWN = 'hosting.full_disk_issue.shown',
    REDUCE_DISK_USAGE_CLICKED = 'hosting.reduce_disk_usage.clicked',
    INCREASE_DISK_STORAGE_CLICKED = 'hosting.increase_disk_storage.clicked',
    DISCOVER_THE_PATH_CLICKED = 'hosting.discover_the_path.clicked',
    OPEN_FILE_MANAGER_ENTER = 'hosting.open_file_manager.enter',
    UPGRADE_PERIOD_CHOICE_SHOWN = 'hosting.upgrade.period_choice_shown',
    WORDPRESS_EDIT_WEBSITE_ENTER = 'wordpress.edit_website.enter',
    WORDPRESS_EDIT_WEBSITE_SHOW = 'wordpress.edit_website.shown',
    RESOURCES_EXCEEDED_LIMITS_SHOW = 'hosting.exceeded_limits.shown',
    CDN_BENCHMARK_SHOWN = 'hosting.cdn.benchmark_shown',
    TRANSFER_DOMAIN_ENTER = 'hosting.transfer_domain.enter',
    AI_TROUBLESHOOTER_PROBLEM_SHOWN = 'hosting.aitroubleshooter.problem_shown',
    AI_TROUBLESHOOTER_FIX = 'hosting.aitroubleshooter.fix',
    AI_TROUBLESHOOTER_TRY_AGAIN = 'hosting.aitroubleshooter.tryagain',
    AI_TROUBLESHOOTER_SUCCESS = 'hosting.aitroubleshooter.success ',
    AI_TROUBLESHOOTER_FAILED = 'hosting.aitroubleshooter.failed ',
    AI_TROUBLESHOOTER_SURVEY_FILLED = 'hosting.aitroubleshooter.survey_filled',
    AI_TROUBLESHOOTER_MONITORING_SKIP = 'hosting.aitroubleshooter.monitoring.skip',
    AI_TROUBLESHOOTER_MONITORING_SAVE = 'hosting.aitroubleshooter.monitoring.save',
    HOSTING_RENEW_OR_UPGRADE_OFFER_SHOWN = 'hosting.renew_or_upgrade.offer_shown',
    HOSTING_RENEW_OR_UPGRADE_CLOSED = 'hosting.renew_or_upgrade.closed',
    STAGING_PLUGINS_WORDPRESS_UPDATE = 'hosting.staging.wordpress_plugins_update.update',
    STAGING_PLUGINS_WORDPRESS_DELETE = 'hosting.staging.wordpress_plugins_delete.delete',
    STAGING_PLUGINS_WORDPRESS_ACTIVATE = 'hosting.staging.wordpress_plugins_activate.activate',
    STAGING_PLUGINS_WORDPRESS_MODAL_UPDATE = 'hosting.staging.wordpress_plugins_modal.update',
    SECURITY_PLUGINS_WORDPRESS_UPDATE = 'hosting.security.wordpress_plugins_update.update',
    SECURITY_PLUGINS_WORDPRESS_DELETE = 'hosting.security.wordpress_plugins_delete.delete',
    SECURITY_PLUGINS_WORDPRESS_ACTIVATE = 'hosting.security.wordpress_plugins_activate.activate',
    SECURITY_PLUGINS_WORDPRESS_MODAL_UPDATE = 'hosting.security.wordpress_plugins_modal.update',
    STAGING_THEMES_WORDPRESS_UPDATE = 'hosting.staging.wordpress_themes_update.update',
    STAGING_THEMES_WORDPRESS_DELETE = 'hosting.staging.wordpress_themes_delete.delete',
    STAGING_THEMES_WORDPRESS_MODAL_UPDATE = 'hosting.staging.wordpress_themes_update_modal.update',
    SECURITY_THEMES_WORDPRESS_UPDATE = 'hosting.security.wordpress_themes_update.update',
    SECURITY_THEMES_WORDPRESS_DELETE = 'hosting.security.wordpress_themes_delete.delete',
    SECURITY_THEMES_WORDPRESS_MODAL_UPDATE = 'hosting.security.wordpress_themes_update_modal.update',
    BACKUPFILE_GENERATE_ENTER = 'hosting.backupfile.generate.enter',
    BACKUPFILE_GENERATE = 'hosting.backupfile.generate',
    BACKUPFILE_GENERATE_CONFIRMATION = 'hosting.backupfile.generate.confirmation',
    BACKUPFILE_DOWNLOAD = 'hosting.backupfile.download',
    MIGRATION_DOMAIN_ADD = 'hosting.migration.domain_add',
    MIGRATION_WORDPRESS_CHECK = 'hosting.migration.wordpress_check',
    MIGRATION_WORDPRESS_METHOD = 'hosting.migration.wordpress_method',
    MIGRATION_LOGIN = 'hosting.migration.login',
    MIGRATION_BACKUP_UPLOAD = 'hosting.migration.backupfile_upload',
    MIGRATION_CHOOSE_PLATFORM = 'hosting.migration.choose_platform',
    MIGRATION_CHOOSE_DOMAIN = 'hosting.migration.choose_domain',
    MIGRATION_FINISHED = 'hosting.migration.finished',
    WORDPRESS_DETECT = 'hosting.website.wordpress_detect',
    WORDPRESS_INSTALL = 'hosting.website.wordpress_install',
    OPTIMIZATION_PROFILES_SHOWN = 'hosting.optimization_profiles.shown',
    OPTIMIZATION_PROFILES_ENTER = 'hosting.optimization_profiles.enter',
    OPTIMIZATION_PROFILES_CHOOSE = 'hosting.optimization_profiles.choose',
    OPTIMIZATION_PROFILES_SUBMIT = 'hosting.optimization_profiles.submit',
    OPTIMIZATION_PROFILES_RESTORE = 'hosting.optimization_profiles.restore',
    HOSTING_FILE_MANAGER_ENTER = 'hosting.file_manager.enter',
    TIP_SHOWN = 'hosting.tip.shown',
    TIP_ENTER = 'hosting.tip.enter',
    BUILDER_UPGRADE_OFFER_SHOWN = 'hosting.builder_upgrade.offer_shown',
    CROSS_SELL_PROTECT_DOMAIN_OFFER_CHOSEN = 'hosting.cross_sell_protect_domain.offer_chosen',
    DOMAIN_RESTORE_ENTER = 'hosting.domain_restore.enter',
    WEBSITES_FINISH_REGISTRATION_ENTER = 'hosting.websites.finish_registration.enter',
    FINISH_DOMAIN_REGISTRATION_ENTER = 'hosting.finish_domain_registration.enter',
    RENEW_DOMAIN_ENTER = 'hosting.renew_domain.enter',
    MALWARE_SCANNER_SCAN_REQUEST = 'hosting.malware_scanner.scan_request',
    MALWARE_SCANNER_OFFER_SHOWN = 'hosting.malware_scanner.offer_shown',
    MALWARE_SCANNER_OFFER_CHOSEN = 'hosting.malware_scanner.offer_chosen',
    TRANSFER_DOMAIN_POSSIBLE_SHOWN = 'hosting.transfer_domain.possible_shown',
    CLEAR_CACHE_PREVIEW_ENTER = 'hosting.clear_cache_preview.enter',
  }

  export enum Wordpress {
    WP_COMPATIBILITY_SHOWN = 'wordpress.compatibility_checker.shown',
    WP_COMPATIBILITY_CHANGE = 'wordpress.compatibility_checker.change',
    WP_COMPATIBILITY_CONFIRM = 'wordpress.compatibility_checker.confirm',
    WORDPRESS_PLUGIN_INSTALLED = 'wordpress.plugin.installed',
  }

  export enum HostingCTA {
    BACKUPFILE_AVAILABLE = 'backupfile.available',
    BACKUPFILE_GENERATING = 'backupfile.generating',
    BACKUPFILE_DOWNLOAD = 'backupfile.download',
  }

  export enum NoCacheMode {
    NO_CACHE_MODE_ENABLE_ENABLED = 'no_cache_mode.enable.enabled',
    NO_CACHE_MODE_DISABLE_DISABLED = 'no_cache_mode.disable.disabled',
    NO_CACHE_MODE_CONFIRMATION_SHOWN = 'no_cache_mode.confirmation.shown',
    NO_CACHE_MODE_CONFIRMATION_CONFIRMED = 'no_cache_mode.confirmation.confirmed',
  }

  export enum CDN {
    CDN_ENTER = 'hosting.cdn.enter',
    CDN_ENABLE = 'hosting.cdn.enable',
    CDN_DISABLE = 'hosting.cdn.disable',
    CDN_PURGE = 'hosting.cdn.purge_cache',
    CDN_DEV_MODE = 'hosting.cdn.dev_mode',
  }

  export enum WebsiteMigration {
    WEBSITE_MIGRATION_ADD_REQUEST_ENTER = 'website_migration.add_request.enter',
    WEBSITE_MIGRATION_ADD_REQUEST_SUCCESS = 'website_migration.add_request.submit_success',
    WEBSITE_MIGRATION_ADD_REQUEST_FAILED = 'website_migration.add_request.submit_fail',
  }

  export enum Websites {
    WEBSITES_ENTER_CREATE_OR_MIGRATE = 'websites.create_or_migrate.enter',
    WEBSITES_BUILDER_DEMO_SHOWN = 'websites.builder_demo.offer_shown',
    WEBSITES_BUILDER_DEMO_CLOSED = 'websites.builder_demo.offer_closed',
    WEBSITE_BUILDER_DEMO_BUILDER_SELECTED = 'website_builder.demo.builder_selected',
    WEBSITES_CHANGE_DOMAIN_SEEN = 'websites.change_domain.option_seen',
    WEBSITES_CHANGE_DOMAIN_ENTER = 'websites.change_domain.enter',
    WEBSITES_CHANGE_DOMAIN_CHOSEN = 'websites.change_domain.option_chosen',
    WEBSITES_CHANGE_DOMAIN_MODAL_CLOSE = 'websites.change_domain.close',
    WEBSITES_CHANGE_DOMAIN_MODAL_CONFIRMS_DELETE_CHECKMARK = 'websites.change_domain.confirms_checkmark',
    WEBSITES_CHANGE_DOMAIN_SUCCESS_CLOSE = 'websites.change_domain.success_closed',
    WEBSITES_CHANGE_DOMAIN_DOMAIN_CHANGE = 'websites.change_domain.change',
    WEBSITES_CHANGE_DOMAIN_DOMAIN_INPUT = 'websites.change_domain.domain_input',
    WEBSITES_CONNECT_DOMAIN_SHOWN = 'websites.connect_domain.shown',
    WEBSITES_CONNECT_DOMAIN_ENTER = 'websites.connect_domain.enter',
    WEBSITES_CONNECT_EXTERNAL_DOMAIN_ENTER = 'websites.connect_external_domain.enter',
    WEBSITES_ADD_WEBSITE_PLAN_CHOSEN = 'websites.add_website.plan_chosen',
    WEBSITES_REDIRECT_BUY_HOSTING = 'websites.redirect_to_buy_hosting.enter',
    WEBSITES_SUSPENDED_CONTACT_SUPPORT = 'websites.suspended.contact_support',
    ENTER = 'websites.enter',
    WEBSITES_DELETE_ENTER = 'websites.delete.enter',
    WEBSITES_DELETE_DELETED = 'websites.delete.deleted',
    WEBSITES_ENTER_DUPLICATE = 'websites.duplicate_website.enter',
    WEBSITE_COMPLETED_DUPLICATE = 'websites.duplicate_website.completed',
    WEBSITES_CREATE_REPORT_ENTER = 'websites.create_report.enter',
    WEBSITES_CREATE_REPORT_CREATE = 'websites.create_report.create_report',
    WEBSITES_CREATE_REPORT_CREATED = 'websites.create_report.report_created',
    WEBSITES_CREATE_REPORT_DOWNLOADED = 'websites.create_report.report_downloaded',
    WEBSITES_DEFAULT_VIEW_CHANGED = 'websites.default_view.changed',
    WEBSITES_CREATE_OR_MIGRATE_CREATE = 'websites.create_or_migrate.create',
    WEBSITES_STAGING_ENTER = 'websites.staging.enter',
    WEBSITES_PHPMYADMIN_ENTER = 'websites.phpmyadmin.enter',
    WEBSITES_BACKUPS_ENTER = 'websites.backups.enter',
    WEBSITES_FILE_MANAGER_ENTER = 'websites.file_manager.enter',
    WEBSITES_EDIT_WEBSITE_ENTER = 'websites.edit_website.enter',
    WEBSITES_SHOW_FILTER_CHANGED = 'websites.show_filter.changed',
    WEBSITES_PHP_INFO_ENTER = 'websites.php_info.enter',
    WORDPRESS_UPDATE_ENTER = 'websites.wp_update.enter',
    WORDPRESS_UPDATE = 'websites.wp_update.update',
    RELOAD_PERFORMANCE_SCORE = 'websites.performance_score.reload',

    WEBSITE_ADD_WEBSITE_ENTER = 'websites.add_website.enter',
    WEBSITE_ADD_WEBSITE_OPTION_CHOSEN = 'websites.add_website.option_chosen',
    WEBSITE_REFRESH_WORDPRESS_UPDATE = 'websites.wordpress_update.refresh',

    WEBSITES_CONNECT_DOMAIN_OPTION_CHOSEN = 'websites.connect_domain.option_chosen',

    WEBSITE_TRANSFER_OWNERSHIP_ENTER = 'websites.transfer_ownership.enter',
    WEBSITE_TRANSFER_OWNERSHIP_EMAIL_PROVIDED = 'websites.transfer_website.email_provided',
    WEBSITE_TRANSFER_OWNERSHIP_REQUEST_SENT = 'websites.transfer_website.request_sent',
    WEBSITE_TRANSFER_OWNERSHIP_TRANSFER_START = 'websites.transfer_website.transfer_start',

    WEBSITES_FAVORITES_WEBSITE_ADDED = 'websites.favorites.website_added',
    WEBSITES_FAVORITES_WEBSITE_REMOVED = 'websites.favorites.website_removed',
    WEBSITES_FAVORITES_SHOWN = 'websites.favorites.shown ',

    TAG_MANAGER_ENTER = 'websites.tag_manager.enter',
    TAG_MANAGER_TAG_CHANGED = 'websites.tag_manager.tag_changed',
    TAG_MANAGER_SAVED = 'websites.tag_manager.saved',
    TAG_MANAGER_FILTERED = 'websites.tag_manager.filtered',
    TAG_MANAGER_STATUS_CHANGED = 'websites.tag_manager.status_changed',
    TAG_MANAGER_WEBSITE_TAGGED = 'websites.tag_manager.website_tagged',

    WEBSITE_AI_SOCIAL_TOOL_BANNER_SHOWN = 'websites.ai_social_tool.banner_shown',
    WEBSITE_EMAIL_MARKETING_TOOL_BANNER_SHOWN = 'websites.email_marketing_tool.banner_shown',
  }

  export enum CmsSwitch {
    CMS_MIGRATE_TO_BUILDER_SUCCESS = 'CMS_migration.change.success',
    CMS_MIGRATE_TO_BUILDER_FAILED = 'CMS_migration.change.failed',
    CMS_RESTORE_PREVIOUS_ENTER = 'CMS_migration.restore_previous.enter',
    CMS_RESTORE_PREVIOUS_RESTORE = 'CMS_migration.restore_previous.restore',
    CMS_RESTORE_PREVIOUS_CANCEL = 'CMS_migration.restore_previous.cancel',
    CMS_RESTORE_PREVIOUS_SUCCESS = 'CMS_migration.restore_previous.success',
    CMS_RESTORE_PREVIOUS_FAILED = 'CMS_migration.restore_previous.failed',
  }

  export enum PowerStore {
    LIST_ENTER = 'store.list.enter',
  }

  export enum CrossSell {
    DOMAIN_ONLY_SHOWN = 'home_page.domain_only.enter',
    DOMAIN_PURCHASED_24H_AGO = 'domain.purchased.24h_ago',
  }

  export enum SSL {
    ENTER = 'ssl.enter',
  }

  export enum Billing {
    ENTER = 'billing.enter',
    RENEW_OFFER_CHOSEN = 'billing.renew.offer_chosen',
    RENEW_OFFER_SHOWN = 'billing.renew.offer_shown',
    BILLING_RENEW_PERIOD_CHOICE_SHOWN = 'billing.renew.period_choice_shown',
    UPDATE_PAYMENT_INFORMATION_SHOWN = 'billing.update_payment_information.banner_shown',
    UPDATE_PAYMENT_INFORMATION_ENTER = 'billing.update_payment_information.enter',
    BILLING_RENEW_ENTER = 'billing.renew.enter',
    BILLING_REFUND_SERVICES_SELECTED = 'billing.refund.services_selected',
    BILLING_PRODUCT_ENTER = 'billing.product.enter',
    BILLING_PAID_INVOICES_ENTER = 'billing.paid_invoices.enter',
    BILLING_REFUND_ENTER = 'billing.refund.enter',
    BILLING_REFUND_SUBMITTED = 'billing.refund.submitted',
    BILLING_REFUND_SKIP_SURVEY = 'billing.refund.skip_survey',
    BILLING_AUTO_RENEW_DISABLE_ENTER = 'billing.auto_renew.disable_enter',
    BILLING_AUTO_RENEW_DISABLE_DISABLED = 'billing.auto_renew.disable_disabled',
  }

  export enum Help {
    ENTER = 'help.enter',
    ISSUE_SEARCH_SEARCHED = 'help.issue_search.searched',
    TOPIC_SEARCH_SEARCHED = 'help.topic_search.searched',
    TOPIC_SEARCH_ARTICLE_OPENED = 'help.topic_search.article_opened',
    TOPIC_SEARCH_EMAIL_SEND = 'help.topic_search.email_send',
    TOPIC_SEARCH_CHAT_OPENED = 'help.topic_search.chat_opened',
    WEBSITE_ISSUES_DOMAIN_SELECTED = 'help.website_issues.domain_selected',
    WEBSITE_ISSUES_HOSTING_ORDER_SUSPENDED = 'help.website_issues.hosting_order_suspended',
    WEBSITE_ISSUES_DOMAIN_SUSPENDED = 'help.website_issues.domain_suspended',
    WEBSITE_ISSUES_DOWNTIME = 'help.website_issues.downtime',
    WEBSITE_ISSUES_DOMAIN_NOT_POINTING = 'help.website_issues.domain_not_pointing',
    DOMAIN_SUSPENDED_RENEW_DOMAIN_ORDER = 'help.domain_suspended.renew_domain_order',
    DOMAIN_SUSPENDED_NEED_ASSISTANCE_ENTER = 'help.domain_suspended.need_assistance_enter',
    DOMAIN_SUSPENDED_TICKET_SUBMITED = 'help.domain_suspended.ticket_submited',
    DOMAIN_IS_NOT_VERIFIED_TICKET_SUBMITED = 'help.domain_is_not_verified.ticket_submited',
    HOSTING_ORDER_SUSPENDED_RENEW_HOSTING_ORDER = 'help.hosting_order_suspended.renew_hosting_order',
    HOSTING_ORDER_SUSPENDED_NEED_ASSISTANCE_ENTER = 'help.hosting_order_suspended.need_assistance_enter',
    HOSTING_ORDER_SUSPENDED_TICKET_SUBMITED = 'help.hosting_order_suspended.ticket_submited',
    DOWNTIME_NEED_ASSISTANCE_ENTER = 'help.downtime.need_assistance_enter',
    DOMAIN_NOT_POINTING_CONNECT_DOMAIN = 'help.domain_not_pointing.connect_domain',
    DOMAIN_NOT_POINTING_NEED_ASSISTANCE_ENTER = 'help.domain_not_pointing.need_assistance_enter',
    ACTIVE_PROBLEM_SHOWN = 'help.active_problem.shown', // ? we show user an active problem
    ACTIVE_PROBLEM_ENTER = 'help.active_problem.enter', // ? user enters feature to solve the problem
    ACTIVE_PROBLEM_LEARN_MORE = 'help.active_problem.learn_more', // ? user clicks on `learn more`
    ACTIVE_PROBLEM_SOLVED = 'help.active_problem.solved', // ? user solves the problem
    PAYMENTS_AND_REFUNDS_ENTER = 'help.payments_and_refunds.enter',
    PAYMENTS_AND_REFUNDS_REFUND_ENTER = 'help.payments_and_refunds.refund_enter',
    PAYMENTS_AND_REFUNDS_REFUND_REQUESTED = 'help.payments_and_refunds.refund_requested',
    WEBSITE_ISSUES_NOT_VERIFIED = 'help.website_issues.domain_is_not_verified',
    WEBSITE_ISSUES_NOT_VERIFIED_CTA_CLICK = 'help.domain_is_not_verified.verify_email',
    WEBSITE_ISSUES_NOT_VERIFIED_ASSISTANCE = 'help.domain_is_not_verified.need_assistance_enter',
    WEBSITE_ISSUES_SUSPENDED = 'help.website_issues.domain_status_suspended',
    WEBSITE_ISSUES_SUSPENDED_ASSISTANCE = 'help.domain_status_suspended.create_ticket_clicked',
    ACTIVE_TICKETS_SHOWN = 'help.active_tickets.shown',
    ACTIVE_TICKETS_TICKET_ENTER = 'help.active_tickets.ticket_enter',
    DOMAIN_SELECTED = 'help.domains.domain_selected',
    VPS_SELECTED = 'help.vps.vps_selected',
    MAILBOX_SELECTED = 'help.emails.mailbox_selected',
    BILLING_PAYMENT_SELECTED = 'help.billing.payment_selected',
    WORDPRESS_ERROR_NEED_ASSISTANCE_ENTER = 'help.wordpress_error.need_assistance_enter',
  }

  export enum Referral {
    BANNER_SHOWN = 'referrals.banner.shown',
    BANNER_FIND_OUT_MORE_CHOSEN = 'referrals.banner.find_out_more_chosen',
    BANNER_CLOSED = 'referrals.banner.closed',
    BANNER_ENTER = 'referrals.banner.enter',
    INVITE_CLICKED = 'referrals.invite.clicked',
    CHECK_PROGRESS_ENTER = 'referrals.check_progress.enter',
    ADD_PAYMENT_METHOD_ENTER = 'referrals.add_payment_method.enter',
    REFERRALS_ENTER = 'referrals.enter',
    REFERRAL_PAYOUT_METHOD_CHANGE_ENTER = 'referrals.payment_method_change.enter',
    REFERRAL_PAYOUT_METHOD_CHANGE_METHOD_CHOSEN = 'referrals.payment_method_change.method_chosen',
    REFERRAL_PAYOUT_METHOD_CHANGE_METHOD_CHANGED = 'referrals.payment_method_change.method_changed',
    REFERRAL_COPY_LINK_COPIED = 'referrals.copy_link.copied',
    REFERRAL_SOCIAL_LINK_ENTER = 'referrals.social_link.enter',
    REFERRAL_INVITE_EMAIL_CLICKED = 'referrals.invite.email.clicked',
    REFER_AND_EARN_ENTER = 'referrals.refer&earn.enter',
    REFERRAL_TERMS_OF_SERVICE_ENTER = 'referrals.terms_of_service.enter',
    REFERRALS_TABLE_ENTER = 'referrals.my_referrals.referrals_enter',
    PAYOUTS_TABLE_ENTER = 'referrals.my_referrals.payouts_enter',
    REFERRALS_REFER_AND_EARN_TAB_ENTER = 'referrals.refer&earn.enter',
    REFERRALS_MY_REFERRALS_TAB_ENTER = 'referrals.my_referrals.enter',
    REFERRALS_NUDGE_SHOWN = 'referrals.nudge.shown',
  }

  export enum H5G {
    ADD_WEBSITE = 'h5g.add_website',
    DASHBOARD_ENTER = 'h5g.dashboard.enter',
    RESOURCES_USAGE_ENTER = 'h5g.resources-usage.enter',
    CDN_ENTER = 'h5g.cdn.enter',
    MALWARE_SCANNER_ENTER = 'h5g.malware-scanner.enter',
    SSL_ENTER = 'h5g.ssl.enter',
    SUBDOMAINS_ENTER = 'h5g.subdomains.enter',
    AUTO_INSTALLER_ENTER = 'h5g.auto-installer.enter',
    ADD_WEBSITE_ENTER = 'h5g.add-website.enter',
    LOGO_MAKER_ENTER = 'h5g.logo-maker.enter',
    FILE_MANAGER_ENTER = 'h5g.file-manager.enter',
    BACKUPS_ENTER = 'h5g.backups.enter',
    FTP_ACCOUNTS_ENTER = 'h5g.ftp-accounts.enter',
    DATABASES_ENTER = 'h5g.databases.enter',
    DNS_ZONE_EDITOR_ENTER = 'h5g.dns-zone-editor.enter',
    PHP_CONFIGURATION_ENTER = 'h5g.php-configuration.enter',
    CRON_JOBS_ENTER = 'h5g.cron-jobs.enter',
    SSH_ACCESS_ENTER = 'h5g.ssh-access.enter',
    PHP_INFO_ENTER = 'h5g.php-info.enter',
  }

  export enum HostingerProSpace {
    ENTER = 'prospace.enter',
    ADD_WEBSITE_WP_PRESET_INSTALLED = 'prospace.add_website.wp_preset_installed',
    ADD_WEBSITE_WP_PRESET_SKIP = 'prospace.add_website.wp_preset_skip',
    ADD_NEW_CLIENT_ENTER = 'prospace.add_new_client.enter',
    ADD_NEW_CLIENT_ADD = 'prospace.add_new_client.add',
    REMOVE_CLIENT_REMOVED = 'prospace.remove_client.removed',
    CLIENT_MANAGE_ENTER = 'prospace.client_manage.enter',
    CREATE_CART_BANNER_SHOWN = 'prospace.create_cart_banner.shown',
    CREATE_CART_BANNER_ENTER = 'prospace.create_cart_banner.enter',
    CART_CLIENT_SELECTED = 'prospace.cart_client.selected',
    CART_CLIENT_ADDED = 'prospace.cart_client.added',
    CART_SENT = 'prospace.cart.sent',
    HOSTING_ENTER = 'prospace.hosting.enter',
    HOSTING_DOMAINS_ENTER = 'prospace.domains.enter',
    DOMAINS_BUY_DOMAIN_ENTER = 'prospace.domain.buy_domain_enter',
    DOMAINS_BUY_DOMAIN_DOMAIN_TAKEN = 'prospace.domain.buy_domain_domain_taken',
    DOMAINS_BUY_DOMAIN_OFFER_SHOWN = 'prospace.domain.buy_domain_offer_shown',
    DOMAINS_BUY_DOMAIN_OFFER_CHOSEN = 'prospace.domain.buy_domain_offer_chosen',
    DOMAINS_BUY_DOMAIN_SELECTED = 'prospace.domain.buy_domain_selected',
    HOSTING_EMAILS_ENTER = 'prospace.emails.enter',
    SELECT_ALL_WEBSITES_SELECTED = 'prospace.select_all_websites.selected',
    BULK_ACTIONS_SHOWN = 'prospace.bulk_actions.shown',
    BULK_ACTIONS_CHOSEN = 'prospace.bulk_actions.chosen',
    EDIT_WEBSITE_ENTER = 'prospace.edit_website.enter',
    WEBSITE_MANAGE_ENTER = 'prospace.website_manage.enter',
    WEBSITES_ENTER = 'prospace.websites.enter',
    CLIENTS_ENTER = 'prospace.clients.enter',
    EMAILS_ENTER = 'prospace.emails.enter',
    EMAILS_MANAGE_ENTER = 'prospace.emails_manage.enter',
    EMAILS_SETUP_ENTER = 'prospace.emails_setup.enter',
    PARTNER_BENEFITS_ENTER = 'prospace.partner_benefits.enter',
    HOSTING_OFFER_SHOWN = 'prospace.hosting_new.offer_shown',
    HOSTING_OFFER_CHOSEN = 'prospace.hosting_new.offer_chosen',
    DOMAINS_ENTER = 'prospace.domains.enter',
    EARNINGS_CLICKED = 'prospace.earnings.clicked',
    UPCOMING_PAYMENTS_CLICKED = 'prospace.upcoming_payments.clicked',
    HOW_DOES_IT_WORK_CLICKED = 'prospace.how_does_it_work.clicked',
    ADD_WEBSITES_PLAN_ENTER = 'prospace.websites_add_your_plan.enter',
    ADD_WEBSITES_PLAN_ADDED = 'prospace.websites_add_your_plan.added',
    ADD_WEBSITE_ENTER = 'prospace.add_website.enter',
    ADD_WEBSITE_CLIENT_SELECTED = 'prospace.add_website.client_selected',
    ADD_WEBSITE_PLATFORM_SELECTED = 'prospace.add_website.platform_selected',
    ADD_WEBSITE_WP_ACCOUNT_CREATED = 'prospace.add_website.wp_account_created',
    ADD_WEBSITE_CLAIM_FREE_DOMAIN_SELECTED = 'prospace.add_website.claim_free_domain_selected',
    ADD_WEBSITE_CHOOSE_DOMAIN_SELECTED = 'prospace.add_website.choose_domain_selected',
    ADD_WEBSITE_EXISTING_DOMAIN_SELECTED = 'prospace.add_website.existing_domain_selected',
    ADD_WEBSITE_BUY_DOMAIN_SELECTED = 'prospace.add_website.buy_domain_selected',
    ADD_WEBSITE_BUY_OPTION_VISIBLE = 'prospace.add_website.buy_option_visible',
    ADD_WEBSITE_DOMAIN_OFFER_SHOWN = 'prospace.add_website.buy_domain_offer_shown',
    ADD_WEBSITE_DOMAIN_DOMAIN_TAKEN = 'prospace.add_website.buy_domain_domain_taken',
    ADD_WEBSITE_DOMAIN_BUY_DOMAIN_OFFER_CHOSEN = 'prospace.add_website.buy_domain_offer_chosen',
    ADD_WEBSITE_SUMMARY_SHOWN = 'prospace.add_website.summary_shown',
    ONBOARDING_ENTER = 'prospace.onboarding.enter',
    ONBOARDING_GET_STARTED_ENTER = 'prospace.onboarding_get_started.enter',
    ONBOARDING_HOW_TO_USE_ENTER = 'prospace.onboarding_how_to_use.enter',
    ONBOARDING_SKIPPED = 'prospace.onboarding.skipped',
    ONBOARDING_ADD_WEBSITE_OWN_ACCOUNT_ENTER = 'prospace.onboarding_add_website_own_account.enter',
    ONBOARDING_ADD_WEBSITE_CLIENT_ACCOUNT_ENTER = 'prospace.onboarding_add_website_client_account.enter',
    ONBOARDING_ADD_WEBSITE_OWN_ACCOUNT_SELECTED = 'prospace.onboarding_add_website_own_account.client_selected',
    ONBOARDING_ADD_WEBSITES_CLIENT_ACCOUNT_SELECTED = 'prospace.onboarding_add_website_client_account.client_selected',
    HELP_ENTER = 'prospace.help.enter',
    CHAT_NOW_ENTER = 'prospace.chat_now.enter',
    ONBOARDING_COMPLETED = 'prospace.client.onboarding_completed',
    PRO_FILE_MANAGER_ENTER = 'prospace.websites_file_manager.enter',
    PRO_PHPMYADMIN_ENTER = 'prospace.websites_phpmyadmin.enter',
    PRO_STAGING_ENTER = 'prospace.websites_staging.enter',
    PRO_BACKUPS_ENTER = 'prospace.websites_backups.enter',
    PRO_EXPLORE_PROPANEL_OFFER_SHOWN = 'prospace.explore_propanel.offer_shown',
    PRO_EXPLORE_PROPANEL_ENTER = 'prospace.explore_propanel.enter',
    PRO_EXPLORE_PROPANEL_CANCEL = 'prospace.explore_propanel.cancel',
    PRO_VPS_REFERRAL_LINK_COPIED = 'prospace.vps_new.referral_copied',
    PRO_HOSTING_REFERRAL_LINK_COPIED = 'prospace.hosting.referral_copied',
    VPS_ADD_TO_CART = 'prospace.vps_new.added_to_cart',
    VPS_CART_SENT = 'prospace.vps_new.cart_sent',
    VPS_ADD_NEW_PLAN_ENTER = 'prospace.vps_add_new_plan.enter',
    VPS_NEW_OFFER_SHOWN = 'prospace.vps_new.offer_shown',
    VPS_NEW_OFFER_CHOSEN = 'prospace.vps_new.offer_chosen',
    WP_PRESETS_ENTER = 'prospace.wp_presets.enter',
    WP_PRESETS_NAME_SELECTED = 'prospace.wp_presets.name_selected',
    WP_PRESETS_THEME_SELECTED = 'prospace.wp_presets.theme_selected',
    WP_PRESETS_PLUGINS_SELECTED = 'prospace.wp_presets.plugins_selected ',
    DELETE_WEBSITE_ENTER = 'prospace.websites_delete.enter',
    DELETE_WEBSITE_DELETED = 'prospace.websites_delete.deleted',
    PRO_WEBSITES_CONNECT_DOMAIN_CONNECTED = 'prospace.websites_connect_domain.connected',
    BLACK_FRIDAY_OFFER_SHOWN = 'black_friday.prospace.offer_shown',
    BLACK_FRIDAY_BANNER_SHOWN = 'black_friday.prospace.banner_shown',
    BLACK_FRIDAY_OFFER_CHOSEN = 'black_friday.prospace.offer_chosen',
    CLIENT_MANAGEMENT_POPUP_SHOWN = 'prospace.popup.shown',
    CLIENT_MANAGEMENT_POPUP_ENTER = 'prospace.popup.enter',
    CLIENT_MANAGEMENT_POPUP_LEARN_MORE = 'prospace.popup.learn_more',
    PROSPACE_REFERRALS_SHOWN = 'propspace.referrals.shown',
    PROSPACE_REFERRALS_ENTER = 'prospace.referrals.enter',
    PROSPACE_ACCOUNT_SHARING_SHOWN = 'prospace.account_sharing.shown',
    PROSPACE_ACCOUNT_SHARING_ENTER = 'prospace.account_sharing.enter',
    PROSPACE_HPANEL_AND_PROPANEL_MERGE_MODAL_SHOWN = 'prospace.hpanel_and_propanel_merge.modal_shown',
    PROSPACE_HPANEL_AND_PROPANEL_MERGE_MODAL_CLOSED = 'prospace.hpanel_and_propanel_merge.modal_closed',
    PROSPACE_HPANEL_AND_PROPANEL_MERGE_LEARN_MORE = 'prospace.hpanel_and_propanel_merge.learn_more',
  }

  export enum AccessList {
    MANAGE_ACCOUNTS_CLIENT_MANAGE_ENTER = 'manage_accounts.client_manage.enter',
    MANAGE_ACCOUNTS_COMPONENT_SHOWN = 'manage_accounts.component.shown',
  }

  export enum AccountSharing {
    ACCOUNT_SHARING_ENTER = 'account_sharing.enter',
    ACCOUNT_SHARING_MANAGE_ENTER = 'account_sharing.manage.enter',
    ACCOUNT_SHARING_REQUEST_ACCESS_ENTER = 'account_sharing.request_access.enter',
    ACCOUNT_SHARING_REQUEST_ACCESS_REQUESTED = 'account_sharing.request_access.requested',
    ACCOUNT_SHARING_GRANT_ACCESS_ENTER = 'account_sharing.grant_access.enter',
    ACCOUNT_SHARING_GRANT_ACCESS_REQUESTED = 'account_sharing.grant_access.requested',
    ACCOUNT_SHARING_GRANT_SERVICE_SELECTED = 'account_sharing.grant_service.selected',
    ACCOUNT_SHARING_GRANT_WEBSITE_SELECTED = 'account_sharing.grant_website.selected',
    ACCOUNT_SHARING_BUY_WEBSITE_SELECTED = 'account_sharing.buy_website.selected',
    ACCOUNT_SHARING_NOTIFY_SELECTED = 'account_sharing.notify.selected',
  }

  export enum AccountSettings {
    EMAIL_NOTIFICATION_APPLY_TO_WEBSITE_ENTER = 'account_settings.email_notification_apply_to_website.enter',
    EMAIL_NOTIFICATION_APPLY_TO_WEBSITE_SAVED = 'account_settings.email_notification_apply_to_website.changes_saved',
  }

  export enum Marketplace {
    LIST_ENTER = 'store.list.enter',
    RECOMMENDATION_SHOWN = 'marketplace.recommendation.shown',
    NEW_OFFER_SHOWN = 'marketplace.new.offer_shown',
  }

  export enum Service {
    LOGO_MAKER_CLICK = 'service.logo_maker.click',
    LOGO_DESIGN_CLICK = 'service.logo_design.click',
    WEBSITE_DESIGN_CLICK = 'service.website_design.click',
    WEBSITE_DEVELOPMENT_CLICK = 'service.website_development.click',
    WEB_PROGRAMMING_CLICK = 'service.web_programming.click',
    SEO_CLICK = 'service.seo.click',
    ARTICLES_AND_BLOG_POSTS_CLICK = 'service.articles_and_blog_posts.click',
    WEB_SPEED_OPTIMIZATION_CLICK = 'service.web_speed_optimization.click',
    BANNER_DESIGN_CLICK = 'service.banner_design.click',
  }

  export enum RankingCoach {
    NEW_OFFER_CHOSEN = 'ranking_coach.new.offer_chosen',
  }

  export enum PhoneNumber {
    NOTIFICATION_SHOWN = 'whatsapp_notifications.notification.shown',
    NOTIFICATION_LATER_CLICKED = 'whatsapp_notifications.later.clicked',
    NOTIFICATION_CLOSE_CLICKED = 'whatsapp_notifications.close.clicked',
    COMPLETED_SHOWN = 'whatsapp_notifications.completed.shown ',
    FORM_COMPLETED = 'whatsapp_notifications.phone_number.added',
    ENABLE_CLICKED = 'whatsapp_notifications.enable.clicked',
    DISABLE_CLICKED = 'whatsapp_notifications.disable.clicked',
  }

  export enum AISearch {
    CLOSED = 'ai_search.closed',
    ENTER = 'ai_search.enter',
    RESULT_ENTER = 'ai_search.result.enter',
    INPUT_PROVIDED = 'ai_search.search.input_provided',
  }

  export enum Account {
    MIGRATE_WEBSITE_ENTER = 'account.migrate_website.enter',
    ENTER = 'account.enter',
  }

  export enum AccountSecurity {
    ENTER = 'account_security.enter',
  }

  export enum AccountInformation {
    ENTER = 'account_information.enter',
  }

  export enum WebsiteBuilder {
    OFFER_SHOWN = 'website_builder.pay_2_publish.offer_shown',
    INVOICE_PAID = 'website_builder.pay_2_publish.invoice_paid',
    ENTER = 'website_builder.pay_2_publish.enter',
    TRIAL_SHOWN = 'domain.builder_trial.shown',
    TRIAL_CHOSEN = 'domain.builder_trial.chosen',
  }

  export enum Widget {
    EDIT_WIDGETS_ENTER = 'home_page.edit_widgets.enter',
    EDIT_WIDGETS_CHANGES_SAVED = 'home_page.edit_widgets.changes_saved',
    EDIT_WIDGETS_SERVICES_CHANGED = 'home_page.edit_widgets.services_changed',
    EDIT_WIDGETS_WIDGET_DELETED = 'home_page.edit_widgets.widget_deleted',
    EDIT_WIDGETS_WIDGET_POSITION_CHANGED = 'home_page.edit_widgets.widget_position_changed',
    ADD_WIDGETS_ENTER = 'home_page.add_widgets.enter',
    ADD_WIDGETS_WIDGET_SELECTED = 'home_page.add_widgets.widget_selected',
    ADD_WIDGETS_SERVICE_SELECTION_SHOWN = 'home_page.add_widgets.service_selection_shown',
    ADD_WIDGETS_SERVICES_CHOSEN = 'home_page.add_widgets.services_chosen',
    EDIT_WIDGETS_CANCELED = 'home_page.edit_widgets.canceled',
  }

  export enum PresetsOffer {
    SHOWN = 'hosting.wp_presets.offer_shown',
    CHOSEN = 'hosting.wp_presets.offer_chosen',
    NOTIFY_ME = 'hosting.wp_presets.offer_notify_me',
  }

  export enum SideBar {
    SEARCHED = 'side_bar.search_bar.searched',
    MAIN_MENU = 'navigation.main_menu.enter',
    OVERVIEW = 'navigation.overview.enter',
  }

  export enum WordpressPresets {
    ENTER = 'hosting.wp_presets.enter',
    CREATE_ENTER = 'hosting.wp_presets_create_new.enter',
    DELETE = 'hosting.wp_presets.delete',
    EDIT = 'hosting.wp_presets.edit',
    INSTALL_ON_WEBSITE = 'hosting.wp_presets_install.enter',
  }

  export enum DeveloperTools {
    ENTER = 'addons.enter',
    NEW_OFFER_SHOWN = 'addons.new.offer_shown',
    NEW_OFFER_CHOSEN = 'addons.new.offer_chosen',
    WEBSITES_SELECTION_SHOWN = 'addons.websites_selection.shown',
    WEBSITES_SELECTION_SAVED = 'addons.websites_selection.saved',
    SUBSCRIPTION_MANAGEMENT_ENTER = 'addons.subscription_management.enter',
    SUBSCRIPTION_EDIT_ENTER = 'addons.subscription_edit.enter',
    UPGRADE_ENTER = 'addons.upgrade.enter',
    AUTO_RENEWAL_CHANGED = 'addons.auto_renewal.changed',
    WEBSITES_MONITORING_SELECTED = 'addons.websites_monitoring.selected',
    WEBSITES_MONITORING_SAVED = 'addons.websites_monitoring.saved',
    REPORTS_CREATE_REPORT_ENTER = 'addons.create_report.enter',
    REPORTS_EDIT_REPORT_ENTER = 'addons.edit_report.enter',
    REPORTS_LAYOUT_SELECTED = 'addons.report_layout_selected',
    REPORTS_SCHEDULE_SELECTED = 'addons.schedule_report.selected',
    REPORTS_DOWNLOAD_REPORT = 'addons.download_report',
    REPORTS_REPORT_DELETED = 'addons.report_deleted',
    REPORTS_SELECT_TEMPLATE = 'addons.template.selected',
    REPORTS_GENERATED_REPORTS_SCHEDULE_ENTER = 'addons.generated_reports.schedule.enter',
    REPORTS_GENERATED_REPORTS_SCHEDULE_SAVED = 'addons.generated_reports.schedule.saved',
    REPORTS_TEMPLATES_ENTER = 'addons.templates.enter',
    REPORTS_EDIT_TEMPLATE_SELECTED = 'addons.edit_template.selected',
    REPORTS_TEMPLATE_DELETED = 'addons.template.deleted',
    GIVE_FEEDBACK_ENTER = 'addons.give_feedback.enter',
    REPORTS_SAVE_AS_TEMPLATE_SELECTED = 'addons.save_as_template.selected',
  }

  export enum UdsPackage {
    ACTION_SHOWN = 'uds_package.action_shown',
  }
}

export namespace AmplitudeLocation {
  export enum Base {
    LIST = 'list',
    HOME_PAGE = 'home_page',
    SIDE_MENU = 'side_menu',
    PRO_SPACE = 'prospace',
    HPANEL = 'hpanel',
    DASHBOARD = 'dashboard',
    WEBSITES = 'websites',
    DOMAIN_CHECKER = 'domain_checker',
    DOMAIN_TRANSFER = 'domain_transfer',
    DASHBOARD_DOMAIN = 'dashboard_domain',
    DASHBOARD_HOSTING = 'dashboard_hosting',
    POWER_STORE = 'power_store',
    HOSTING = 'hosting',
    HOSTING_V2 = 'hosting-v2',
    HOSTING_ONBOARDING_V2 = 'hosting_onboarding_v2',
    EMAIL_ONBOARDING = 'email_onboarding',
    TITAN_ONBOARDING = 'titan_onboarding',
    THIRD_PARTY_DOMAIN_MANAGEMENT = '3rd_party_domain_manage',
    THIRD_PARTY_DOMAINS = '3rd_party_domain',
    STAGING = 'staging',
    RESOURCE_USAGE = 'resource_usage',
    EXCEEDED_LIMITS = 'exceeded_limits',
    DASHBOARD_BANNER = 'dashboard_banner',
    CDN_BENCHMARK_UPGRADE = 'cdn_benchmark_upgrade',
    PROFILE_DROPDOWN = 'profile_dropdown',
    ACCOUNT_ACCESS = 'account_access',
    HOSTING_DASHBOARD = 'hosting_dashboard',
    POP_UP_SIDE_MENU = 'pop_up/side_menu',
    HOMEPAGE_HOSTING_OFFER = 'homepage_hosting_offer',
    INFORMATION_CARD = 'information_card',
    DOMAINS_PAGE = 'domains_page',
    BILLINGS_PAGE = 'billings_page',
    TOP_BAR = 'top_bar',
    AI_TROUBLESHOOTER = 'ai_troubleshooter',
    ONBOARDING = 'onboarding',
    URL = 'url',
    HELP = 'help',
    WIDGET = 'widget',
    PROMOTIONAL_BANNER = 'promotional_banner',
    RENEWAL = 'renewal',
    UPGRADE = 'upgrade',
    CSAT_REFERRAL_PROMO = 'csat_referral_nudge',
  }

  export enum Action {
    HOMEPAGE_LOGO = 'homepage_logo',
    HOME_BUTTON = 'home_button',
    TOP_BAR_BUTTON_HELP_BUTTON = 'top_bar_button_help_button',
    PROFILE_DROPDOWN_GET_HELP_BUTTON = 'profile_dropdown_get_help_button',
    BOTTOM_LEFT_CORNER_BUTTON = 'bottom_left_corner_button',
    PROFILE_DROPDOWN = 'profile_dropdown',
    HIDE_ALL_BUTTON = 'hide_all_button',
    SHOW_MORE_TOP_BUTTON = 'show_more_top_button',
    SHOW_LESS_BUTTON = 'show_less_button',
    SHOW_MORE_BOTTOM_BUTTON = 'show_more_bottom_button',
  }

  export enum Domain {
    DOMAIN_MANAGEMENT = 'domain_management',
    DNS_NAMESERVERS_PAGE = 'dns_nameservers_page',
    NOT_TEMPORARY_DOMAIN = 'not_temporary_domain',
    TEMPORARY_DOMAIN = 'temporary_domain',
    DOMAINS_TAB = 'domains_tab',
  }

  export enum Billing {
    SUBSCRIPTIONS_PAGE_SETUP = 'subscriptions-page-setup',
    SUBSCRIPTION_DETAILS_SETUP = 'subscription-details-setup',
  }

  export enum Email {
    CREATE_EMAIL_ACCOUNT = 'create_email_account',
    EMAIL_DASHBOARD_RENEW_CARD = 'email-dashboard-renew-card',
    EMAIL_CONNECT_DOMAIN = 'email_connect_domain',
    EMAIL_PENDING_HOSTING_SETUP = 'emails_pending_hosting_setup',
    EMAIL_PENDING_HOSTING_SETUP_EXPLORE_OTHER_PLANS = 'emails_pending_hosting_setup_explore_other_plans',
    EMAIL_DASHBOARD_SERVICE_CARD = 'email-dashboard-service-card',
    EMAIL_DASHBOARD_ACCOUNTS_CARD = 'email-dashboard-accounts-card',
    EMAIL_OVERVIEW = 'email_overview',
    EMAILS_CREATE_NEW_ACCOUNT = 'emails_create_new_account',
    GET_PLAN_OR_MAILBOX_DROPDOWN = 'get_plan_or_mailbox_dropdown',
    MAILBOX = 'mailbox',
    PLAN_OR_MAILBOX = 'plan_or_mailbox',
    CLAIM_FREE_EMAIL_BANNER = 'claim_free_email_banner',
    EMAIL_OVERVIEW_CHECKLIST = 'email_overview_checklist',
    MAIL_ONBOARDING_CREATE_MAILBOX_SKIP = 'mail-onboarding-create-mailbox-skip',
    EMAIL_APP_WIZARD_SETUP_SKIP = 'email-app-wizard-setup-skip',
    EMAIL_APP_WIZARD_SETUP_COMPLETE = 'email-app-wizard-setup-complete',
    HOSTINGER_EMAIL_UPGRADE_PAGE = 'hostinger-email-upgrade-page',
    HEMAIL_ONBOARDING_END_GO_TO_DASHBOARD = 'hemail-onboarding-end-go-to-dashboard',
    EMAIL_PURCHASE_PAGE = 'emails-purchase-page',
    EMAILS_LIST_SETUP = 'emails-list-setup',
    EMAILS_HOME_PAGE_SETUP = 'emails-home-page-setup',
    TRIAL_OFFER_PAGE = 'trial-offer-page',
    EMAILS_PENDING_HOSTING_SETUP = 'emails_pending_hosting_setup',
    DOMAIN_MANAGEMENT_EMAIL_SETUP = 'domain-management-email-setup',
    HCART_PURCHASE = 'hcart-purchase',
    EMAIL_ACCOUNTS = 'email_accounts',
    GET_PLAN_OR_MAILBOX_DROPDOWN_FREE_PLAN = 'get_plan_or_mailbox_dropdown_free_plan',
    GET_PLAN_OR_MAILBOX_DROPDOWN_PAID_PLAN = 'get_plan_or_mailbox_dropdown_paid_plan',
    EMAIL_FORWARDERS = 'email_forwarders',
    EMAIL_SETUP_MODAL = 'email_setup_modal',
    EMAIL_UPSELL_PAGE = 'email_upsell_page',
    EMAIL_APP_SETUP_WIZARD = 'email_app_setup_wizard',
  }

  export enum Hosting {
    PLAN_DETAILS = 'plan_details',
    PLAN_DETAILS_V2 = 'plan_details_v2',
    BACKUPS = 'backups',
    HOSTING_RENEW_OR_UPGRADE_MODAL = 'hosting_renew_or_upgrade_modal',
    TOP_BAR = 'top_bar',
    TIPS_TO_IMPROVE = 'tips_to_improve',
    FILE_MANAGER = 'file_manager',
    WORDPRESS_DASHBOARD = 'wordpress_dashboard',
    WEBSITE_DASHBOARD = 'website_dashboard',
    H5G_DASHBOARD = 'h5g_dashboard',
    HOSTING_DASHBOARD_SERVICE_CARD = 'hosting-dashboard-service-card',
    HOSTING_DASHBOARD_SERVICE_CARD_PENDING_SETUP = 'hosting-dashboard-service-card-pending-setup',
  }

  export enum Feature {
    OBJECT_CACHE = 'object_cache',
    OBJECT_CACHE_TIP = 'object_cache_tip',
    CDN_UPGRADE = 'cdn_upgrade',
    INODES_RUNNING_OUT = 'inodes_running_out',
    FULL_DISK = 'full_disk',
    WORDPRESS_AUTOMATIC_UPDATES = 'wordpress_automatic_updates',
    WORDPRESS_OVERVIEW = 'wordpress_overview',
    WORDPRESS_SECURITY = 'wordpress_security',
  }

  export enum Status {
    HOSTING_EXPIRING = 'hosting_expiring',
    HOSTING_EXPIRED = 'hosting_expired',
    HOSTING_SUSPENDED = 'hosting_suspended',
    HOSTING_CANCELED = 'hosting_canceled',
    HOSTING_LIMIT_REACHED = 'limit_reached',
    HOSTING_LIMITS_ALMOST_REACHED = 'limits_almost_reached',
    HOSTING_EXCEEDED_LIMITS = 'exceeded_limits',
    VPS_CANCELED = 'vps_canceled',
  }

  export enum CrossSell {
    DOMAIN_CROSS_SELL_ONBOARDING = 'domain_cross_sell_onboarding',
  }

  export enum H5G {
    SIDEBAR = 'sidebar',
  }

  export enum HostingerProSpace {
    WEBSITES = 'websites',
    CLIENTS = 'clients',
    PARTNER_BENEFITS = 'partner_benefits',
    CART_ICON = 'cart_icon',
    ADD_WEBSITE = 'add_website',
    CLIENTS_FIRST_CLIENT = 'clients_first_client',
    ADD_CLIENT = 'add_client',
    SIDE_BAR = 'side_bar',
    ONBOARDING_NO_SERVICES_WEBPRO = 'onboarding_no_services_webpro',
    ONBOARDING_NO_SERVICES_CLIENT = 'onboarding_no_services_client',
    WEBPRO_BUYS = 'webpro_buys',
    WEBPRO_CLIENT_BUYS = 'webpro_client_buys',
    PRO_PANEL = 'pro_panel',
    HPANEL_AND_PROPANEL_MERGE_MODAL = 'hpanel_and_propanel_merge_modal',
  }

  export enum Websites {
    LIST_VIEW = 'list_view',
    HYPERLINK = 'hyper_link',
  }

  export enum Vps {
    ADD_DOMAIN = 'vps_add_domain',
    DASHBOARD = 'vps_dashboard',
    OPERATING_SYSTEM = 'vps_operating_system',
    FIREWALL = 'vps_firewall',
    EMERGENCY_MODE = 'vps_emergency_mode',
    DNS_MANAGER = 'vps_dns_manager',
    SETTINGS = 'vps_settings',
    LICENSES = 'vps_licenses',
    OVERVIEW = 'vps_overview',
    SNAPSHOTS_BACKUPS = 'vps_snapshots_backups',
    SERVER_USAGE = 'vps_server_usage',
    MALWARE_SCANNER = 'vps_malware_scanner',
    LATEST_ACTIONS = 'vps_latest_actions',
    SSH_KEYS = 'vps_ssh_keys',
    UPGRADE = 'vps_upgrade',
    PURCHASE = 'vps_purchase',
  }

  export enum Refund {
    REFUND_REQUEST = 'refund_request',
  }

  export enum Custom {
    HOME_MANAGE_BUTTON = 'home_page_manage_button',
    HOME_WEBSITE_NAME = 'home_page_website_name',
    HOME_WEBSITE_LIST = 'home_page_website_list',
    BREADCRUMBS = 'breadcrumbs',
  }

  export enum Referral {
    SHARE_YOUR_REFERRAL_LINK = 'share_your_referral_link',
    SELECT_HOSTING_PLAN = 'select_hosting_plan',
  }

  export enum DeveloperTool {
    GENERATED_REPORTS_TABLE = 'generated_reports_table',
    SCHEDULED_REPORTS_TABLE = 'scheduled_reports_table',
    CREATE_REPORT_FLOW = 'create_report_flow',
    EDIT_REPORT_FLOW = 'edit_report_flow',
  }
}

export type AmplitudeLocationTypes =
  | AmplitudeLocation.Base
  | AmplitudeLocation.Domain
  | AmplitudeLocation.Email
  | AmplitudeLocation.Hosting
  | AmplitudeLocation.Feature
  | AmplitudeLocation.Status
  | AmplitudeLocation.CrossSell
  | AmplitudeLocation.H5G
  | AmplitudeLocation.HostingerProSpace
  | AmplitudeLocation.Websites
  | AmplitudeLocation.Vps
  | AmplitudeLocation.Refund
  | AmplitudeLocation.Custom;

export enum AmplitudeContext {
  HOSTING = 'hosting',
  VPS = 'vps',
  VPS_LICENSE_OPERATING_SYSTEM_PAGE_MODAL = 'vps_license_operating_system_page_modal',
  BLACK_FRIDAY = 'black_friday',
}

export namespace AmplitudeParam {
  export enum Product {
    WEB_HOSTING = 'Web_Hosting',
    CLOUD_HOSTING = 'Cloud_Hosting',
    WORDPRESS_HOSTING = 'WordPress_Hosting',
    VPS_HOSTING = 'VPS_Hosting',
    TITAN_EMAIL = 'Titan_Email',
    HOSTINGER_EMAIL = 'Hostinger_Email',
    GOOGLE_WORKSPACE_EMAIL = 'Google_Workspace_Email',
    NORD_LOCKER = 'NordLocker',
    NORDVPN = 'NordVPN',
    NORDPASS_PASSWORD_MANAGER = 'NordPass_Password_Manager',
    WHATAGRAPH_DATA_ANALYTICS = 'Whatagraph_Data_Analytics',
  }

  export enum AccountAction {
    CLAIM_FREE_DOMAIN = 'claim_free_domain',
    RESEND_EMAIL_VERIFICATION = 'resend_email_verification',
    CHANGE_EMAIL_ADDRESS = 'change_email_address',
    ENALBE_2FA = 'enable_2fa',
    CHANGE_PAYMENT_METHOD = 'change_payment_method',
    PENDING_SETUP = 'pending_setup',
    PENDING_SETUP_IN_PROGRESS = 'pending_setup_in_progress',
    RENEW_EXPIRING_CPANEL = 'renew_expiring_cpanel',
    RENEW_EXPIRED_CPANEL = 'renew_expired_cpanel',
    MANAGED_PENDING_SETUP = 'managed_pending_setup',
    HOSTING_RESOURCES_LIMIT_REACHED = 'hosting_resources_limit_reached',
    HOSTING_RESOURCES_NEAR_LIMIT = 'hosting_resources_near_limit',
    GRANT_ACCESS = 'grant_access',
    PAYMENT_CARD_ALREADY_EXPIRED = 'payment_card_already_expired',
    PAYMENT_CARD_IS_EXPIRING = 'payment_card_is_expiring',
    ADD_PAYMENT_METHOD = 'add_payment_method',
    TRANSFER_OWNERSHIP_REQUEST = 'transfer_ownership_request',
    RESTORE_CANCELLED_HOSTING = 'restore_cancelled_hosting',
    RENEW_EXPIRED_HOSTING = 'renew_expired_hosting',
    RENEW_EXPIRING_HOSTING = 'renew_expiring_hosting',
    RENEW_EXPIRED_DOMAIN = 'renew_expired_domain',
    RESTORE_EXPIRED_DOMAIN = 'restore_expired_domain',
    RENEW_EXPIRING_DOMAIN = 'renew_expiring_domain',
    RENEW_EXPIRED_VPS = 'renew_expired_vps',
    RENEW_EXPIRING_VPS = 'renew_expiring_vps',
    RENEW_EXPIRED_MAIL = 'renew_expired_mail',
    RENEW_EXPIRING_MAIL = 'renew_expiring_mail',
    ACCEPT_WEBSITE_TRANSFER_REQUEST = 'accept_website_transfer_request',
    UPGRADE_HOSTING_LIMITS_REACHED = 'upgrade_hosting_limits_reached',
    UPGRADE_HOSTING_LIMITS_ALMOST_REACHED = 'upgrade_hosting_limits_almost_reached',
    PENDING_SETUP_HOSTING = 'pending_setup_hosting',
    CONTINUE_SETUP_HOSTING = 'continue_setup_hosting',
    START_OVER_SETUP_HOSTING = 'start_over_setup_hosting',
    PENDING_SETUP_VPS = 'pending_setup_vps',
    PENDING_SETUP_DOMAIN = 'pending_setup_domain',
    PENDING_SETUP_DOMAIN_TRANSFER = 'pending_setup_domain_transfer',
    PENDING_SETUP_MAIL = 'pending_setup_mail',
    PENDING_SETUP_CPANEL_HOSTING = 'pending_setup_cpanel_hosting',
  }

  export enum ProPurchaseWhoPays {
    CUSTOMER_PAYS = 'client',
    WEBPRO_PAYS = 'webpro',
  }

  export enum Websites {
    SWITCHER = 'switcher',
    MODAL = 'modal',
  }

  export enum Type {
    PHP = 'php',
    WORDPRESS = 'wordpress',
    PLUGIN = 'plugin',
  }

  export enum Level {
    ALERT = 'alert',
    WARNING = 'warning',
  }

  export enum Action {
    DISABLE_PLUGINS = 'disable_plugins',
  }

  export enum ProSpace {
    HOW_TO_ADD_CLIENTS_AND_MANAGE_SERVICES_ARTICLE = 'How_to_add_clients_and_manage_services',
    FAQ_ABOUT_MOVED_FUNCTIONALITIES_ARTICLE = 'FAQ_about_moved_functionalities',
  }

  export enum Domain {
    CLAIM_FREE_DOMAIN = 'claim_free_domain',
    BUY_A_DOMAIN = 'buy_a_domain',
    USE_EXISTING_DOMAIN = 'use_existing_domain',
  }

  export enum Localization {
    NEW_UPGRADE = 'new_upgrade',
  }

  export enum Widget {
    EDIT_BUTTON = 'edit_button',
    EDIT_WIDGETS = 'edit_widgets',
    ADD_WIDGETS = 'add_widgets',
  }

  export enum TagAction {
    ADDED = 'added',
    REMOVED = 'removed',
    MODIFIED = 'modified',
  }

  export enum ParamState {
    DISABLED = 'disabled',
    ENABLED = 'enabled',
    NONE = 'NONE',
  }
}
