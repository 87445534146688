import { defineAsyncComponent } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import googleWorkspaceGuard from '@/guards/emails/googleWorkspaceGuard';
import googleWorkspaceSetupGuard from '@/guards/emails/googleWorkspaceSetupGuard';
import { Route } from '@/types';

export default [
  {
    path: '/google-workspace/purchase',
    name: Route.Email.GOOGLE_WORKSPACE_PURCHASE,
    meta: {
      sideMenuExists: false,
      title: 'v2.select.your.google.workspace.plan',
      breadcrumbs: false,
      hideBackWord: true,
    },
    component: () =>
      import('@/views/Emails/GoogleWorkspace/GoogleWorkspacePurchase.vue'),
  },
  {
    path: '/google-workspace/setup/:orderId',
    name: Route.Email.GOOGLE_WORKSPACE_SETUP,
    meta: {
      title: 'v2.finish.your.google.workspace.setup',
      sideMenuExists: false,
      showSubheader: false,
    },
    beforeEnter: googleWorkspaceSetupGuard,
    component: () =>
      import('@/views/Emails/GoogleWorkspace/GoogleWorkspaceSetup.vue'),
  },
  {
    path: '/migrate-gsuite/setup/:orderId',
    name: Route.Email.MIGRATE_GSUITE,
    meta: {
      sideMenuExists: false,
      showSubheader: false,
    },
    beforeEnter: googleWorkspaceSetupGuard,
    component: () =>
      import('@/views/Emails/GoogleWorkspace/GoogleGsuiteMigration.vue'),
  },
  {
    path: '/google-workspace/:domain?/:orderId?',
    name: Route.Email.GOOGLE_WORKSPACE_MANAGEMENT,
    meta: {
      title: 'Google Workspace',
      subHeaderComponent: defineAsyncComponent(
        () =>
          import('@/components/Emails/GoogleWorkspace/GoogleWorkspaceLogo.vue'),
      ),
      breadcrumbsTitle: {
        title: '@{domain}',
        translate: false,
      },
      breadcrumbs: ['emails'],
      sideMenuExists: false,
    },
    beforeEnter: googleWorkspaceGuard,
    component: () =>
      import('@/views/Emails/GoogleWorkspace/GoogleWorkspaceManagement.vue'),
  },
  {
    path: '/google-workspace/:domain/:orderId/upgrade',
    name: Route.Email.GOOGLE_WORKSPACE_UPGRADE,
    meta: {
      title: 'v2.upgrade.your.google.workspace.plan',
      breadcrumbs: false,
      sideMenuExists: false,
    },
    beforeEnter: googleWorkspaceGuard,
    component: () =>
      import('@/views/Emails/GoogleWorkspace/GoogleWorkspacePlanUpgrade.vue'),
  },
] as RouteRecordRaw[];
