import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { Route } from '@/types';

export const useDeveloperToolsStore = defineStore('developerToolsStore', () => {
  const landingLocation = ref<Route.DeveloperTools | null>(null);

  const setLandingLocation = (location: Route.DeveloperTools | null) => {
    landingLocation.value = location;
  };

  return {
    landingLocation,
    setLandingLocation,
  };
});
