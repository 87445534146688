const animationObserver = (options) =>
  new IntersectionObserver((entries, animationObserver) => {
    entries.forEach((entry) => {
      const animation = options === '' ? 'fadeInDown' : options;
      if (entry.isIntersecting) {
        entry.target.classList.add(animation);
        animationObserver.unobserve(entry.target);
      }
    });
  });

export default {
  beforeMount(el, binding) {
    el.classList.add('before-enter');
    const options = binding.value || '';
    const observer = animationObserver(options);
    observer.observe(el);
  },
};
