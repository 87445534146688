export const SIMPLE_TIMER_VARIANTS = {
  METEORITE: 'meteorite',
  METEORITE_DARK: 'meteoriteDark',
  WHITE: 'white',
  BLACK: 'black',
  PRIMARY: 'primary',
  DEFAULT: 'default',
} as const;

export type SimpleTimerVariant =
  (typeof SIMPLE_TIMER_VARIANTS)[keyof typeof SIMPLE_TIMER_VARIANTS];
