import type {
  SurveyReqParams,
  OnboardingCompleted,
  OnboardingState,
} from '@/types';
import { toASCII, hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const ONBOARDING_API_URL = `${process.env.VITE_API_SSO}/v1/onboarding`;
const ONBOARDING_PROGRESS_URL = `${process.env.VITE_API_WH_API}/api/hapi/v1/onboardings`;

export default {
  surveysUrl: `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`,

  async storeSurveyResults(params: SurveyReqParams, orderId: string) {
    return await hAsync<boolean>(
      http.post(
        `${ONBOARDING_API_URL}/survey`,
        {
          ...params,
          orderId,
        },
        {
          whitelistedStatusCodes: [400, 422, 429],
        },
      ),
    );
  },

  async getOnboardingCompleted(orderId: string, domain: string) {
    return await hAsync<OnboardingCompleted>(
      http.get(`${ONBOARDING_API_URL}/completed`, {
        params: { orderId, domain: toASCII(domain) },
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },

  async getOnboardingAutoInstaller(orderId: string) {
    return await hAsync(
      http.get(`${ONBOARDING_API_URL}/auto-installer`, {
        params: { orderId },
        hideToastr: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },

  async getOnboardingProgress(subscriptionId: string) {
    return await hAsync<OnboardingState>(
      http.get(`${ONBOARDING_PROGRESS_URL}/${subscriptionId}/progress`, {
        hideToastr: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },

  async putOnboardingProgress(
    subscriptionId: string,
    data: Partial<OnboardingState>,
  ) {
    return await hAsync(
      http.put(`${ONBOARDING_PROGRESS_URL}/${subscriptionId}/progress`, data, {
        hideToastr: true,
        plain: true,
        whitelistedStatusCodes: [400, 422, 429],
      }),
    );
  },
};
