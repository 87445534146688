import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import TwoChoicePromptModal from '@/components/Modals/HModal/TwoChoicePromptModal.vue';
import {
  useGlobals,
  useH5GOnboarding,
  useModal,
  useOnboardingWordPressThemesV2,
} from '@/composables';
import { surveyRepo } from '@/repositories';
import {
  useHostingerProStore,
  useOnboardingDataCenterStore,
  useOnboardingStore,
  useProfileStore,
  useSubscriptionsStore,
  useOnboardingWordPressThemesStore,
  useMigrationsOnboardingStore,
} from '@/stores';
import {
  type OnboardingStep,
  type OnboardingScenario,
  Route,
  MIGRATIONS_ONBOARDING_STEPS,
} from '@/types';
import {
  AmplitudeEvent,
  CREATE_OR_MIGRATE,
  ONBOARDING_STEPS,
  Onboarding,
  BILLING_ANALYTICS_DATA_KEY,
  ONBOARDING_SCENARIO,
  PLATFORM,
  WORDPRESS_HOSTING_BUY_PAGES,
  WEBSITE_BUILDER_BUY_PAGES,
  H5G_ONBOARDING_STEPS,
  WORDPRESS_APPEARANCE_TYPE,
  WORDPRESS_WEBSITE_TYPE_ANSWER,
  WORDPRESS_VISUAL_SELECT,
} from '@/types';
import { toASCII, mapKeyValue } from '@/utils/helpers';

const isAddonScenario = (
  onboardingScenario: OnboardingScenario | 'default',
) => {
  if (onboardingScenario === 'default') {
    return false;
  }

  return (
    [
      ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON,
      ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON,
      ONBOARDING_SCENARIO.ADDON,
    ] as OnboardingScenario[]
  ).includes(onboardingScenario);
};

export const useOnboardingV2 = () => {
  const onboardingStore = useOnboardingStore();
  const profileStore = useProfileStore();
  const onboardingDCStore = useOnboardingDataCenterStore();
  const subscriptionStore = useSubscriptionsStore();
  const { fetchAndPreloadWordPressThemes } = useOnboardingWordPressThemesV2();
  const router = useRouter();
  const route = useRoute();
  const { amplitudeV2, t } = useGlobals();
  const { openModal, closeModal } = useModal();
  const onboardingWordPressThemeStore = useOnboardingWordPressThemesStore();
  const { isAccessManager } = storeToRefs(useProfileStore());
  const hostingerProStore = useHostingerProStore();
  const { canUsePresetsInOnboarding } = storeToRefs(hostingerProStore);
  const { isH5GFeatureEnabled } = useH5GOnboarding();
  const migrationOnboardingStore = useMigrationsOnboardingStore();

  const isLoadingOnboarding = ref(false);

  const subscriptionId = computed(
    () => (route?.params.order_id || route?.params.orderId) as string,
  );
  const onboardingScenario = route?.params.scenario as
    | OnboardingScenario
    | undefined;

  const isH5GOnboarding = computed(
    () => !!route?.params?.h5gId && isH5GFeatureEnabled.value,
  );

  const isBuilderPlatformSelected = computed(
    () => onboardingStore.state.platformSelect === PLATFORM.BUILDER,
  );

  const isWordPressPlatformSelected = computed(
    () => onboardingStore.state.platformSelect === PLATFORM.WORDPRESS,
  );

  const hasSelectedWordPressAppearance = computed(() => {
    const { wordpressThemeSelect, wordPressPreviewAstraTemplate } =
      onboardingStore.state;

    const hasWordPressThemeSelected = !!wordpressThemeSelect;

    const hasWordPressAstraTemplate = !!wordPressPreviewAstraTemplate?.id;

    return (
      isWordPressPlatformSelected.value &&
      (hasWordPressThemeSelected || hasWordPressAstraTemplate)
    );
  });

  const wordPressAppearanceType = computed(() => {
    const { wordPressVisualSelect } = onboardingStore.state;
    if (wordPressVisualSelect === WORDPRESS_VISUAL_SELECT.PRE_BUILT_WEBSITE) {
      return WORDPRESS_APPEARANCE_TYPE.STARTER_TEMPLATE;
    }

    return WORDPRESS_APPEARANCE_TYPE.THEME;
  });

  const selectedPlatformByRedirect = computed(() => {
    if (!subscriptionStore.subscriptionOrder) {
      return null;
    }

    const redirectedFrom =
      subscriptionStore.subscriptionOrder.analyticsData?.find(
        ({ key }) => key === BILLING_ANALYTICS_DATA_KEY.PAGE_NAME,
      )?.value;

    if (WORDPRESS_HOSTING_BUY_PAGES.includes(redirectedFrom || '')) {
      amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
        platformSelected: Onboarding.Platform.WORDPRESS,
        wpBusinessClient: isBusinessOrAbovePlan.value,
        subscriptionId: subscriptionId.value,
        preselectedAutomatically: true,
        ...(!isAccessManager.value && {
          isWebpro: onboardingStore.isIdentifiedPro,
        }),
      });

      return Onboarding.Platform.WORDPRESS;
    }

    if (WEBSITE_BUILDER_BUY_PAGES.includes(redirectedFrom || '')) {
      amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
        platformSelected: Onboarding.Platform.BUILDER,
        wpBusinessClient: isBusinessOrAbovePlan.value,
        subscriptionId: subscriptionId.value,
        preselectedAutomatically: true,
        ...(!isAccessManager.value && {
          isWebpro: onboardingStore.isIdentifiedPro,
        }),
      });

      return Onboarding.Platform.BUILDER;
    }

    return null;
  });

  const isMigrationSelected = computed(
    () => onboardingStore.state.createOrMigrate === CREATE_OR_MIGRATE.MIGRATE,
  );

  const handleQuickInstallMigrationScenario = async () => {
    onboardingStore.setState(
      ONBOARDING_STEPS.CREATE_OR_MIGRATE,
      CREATE_OR_MIGRATE.MIGRATE,
    );
    onboardingStore.setIsEligibleForSurvey(false);

    await onboardingStore.goToStep(
      migrationOnboardingStore.isOnboardingSubmitOnDemand
        ? ONBOARDING_STEPS.WHAT_TO_MIGRATE
        : ONBOARDING_STEPS.DOMAIN,
    );
  };

  const handleQuickInstallMigrationAddonScenario = async () => {
    onboardingStore.setIsAddon(true);
    onboardingStore.setState(
      ONBOARDING_STEPS.CREATE_OR_MIGRATE,
      CREATE_OR_MIGRATE.MIGRATE,
    );
    onboardingStore.setIsEligibleForSurvey(false);

    await onboardingStore.goToStep(
      migrationOnboardingStore.isOnboardingSubmitOnDemand
        ? ONBOARDING_STEPS.WHAT_TO_MIGRATE
        : ONBOARDING_STEPS.DOMAIN,
    );
  };

  const handlePreselectedWordPressScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      wpBusinessClient: isBusinessOrAbovePlan.value,
      preselectedAutomatically: true,
      subscriptionId: subscriptionId.value,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.CREATE_OR_MIGRATE);
    }
  };

  const handleQuickInstallWordPressAddonScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setIsAddon(true);
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      preselectedAutomatically: true,
      wpBusinessClient: isBusinessOrAbovePlan.value,
      subscriptionId: subscriptionId.value,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP);
    }
  };

  const handleQuickInstallEmptyWebsiteAddonScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setIsAddon(true);

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.NONE,
      preselectedAutomatically: true,
      wpBusinessClient: isBusinessOrAbovePlan.value,
      subscriptionId: subscriptionId.value,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
    }
  };

  const handleQuickInstallEmptyWebsiteSetupScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.NONE,
      preselectedAutomatically: true,
      wpBusinessClient: isBusinessOrAbovePlan.value,
      subscriptionId: subscriptionId.value,
    });
    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
    }
  };

  const handleQuickInstallWordPressSetupScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );
    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      preselectedAutomatically: true,
      wpBusinessClient: isBusinessOrAbovePlan.value,
      subscriptionId: subscriptionId.value,
    });
    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP);
    }
  };

  const handleDefaultScenario = async (isEligibleForSurvey: boolean) => {
    if (isH5GOnboarding.value) {
      await onboardingStore.goToStep(H5G_ONBOARDING_STEPS.H5G_AGREE);
    } else if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.CREATE_OR_MIGRATE);
    }
  };

  const handleDefaultAddonScenario = async (isEligibleForSurvey: boolean) => {
    onboardingStore.setIsAddon(true);

    return handleDefaultScenario(isEligibleForSurvey);
  };

  const handleDomainChangeScenario = async () => {
    await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
  };

  const fetchInitialData = async () => {
    if (isH5GOnboarding.value) return;

    const promiseArray = [
      onboardingStore.fetchWordPressTemplates(),
      onboardingStore.fetchWordPressWebsiteTypes(subscriptionId.value),
      fetchAndPreloadWordPressThemes(),
      onboardingWordPressThemeStore.fetchWordPressThemes({
        subscriptionId: subscriptionId.value,
      }),
    ];

    if (
      !isAddonOnboarding.value &&
      onboardingScenario !== ONBOARDING_SCENARIO.MIGRATION_CHANGE_DOMAIN
    ) {
      promiseArray.push(
        onboardingDCStore.fetchOnboardingDataCenters(subscriptionId.value),
      );
    }

    await Promise.all(promiseArray);
  };

  const fetchRequiredDataAndSetInitialStep = async () => {
    isLoadingOnboarding.value = true;

    if (canUsePresetsInOnboarding.value) {
      await hostingerProStore.fetchPresets();
    }

    const NON_SURVEY_NEEDED_SCENARIOS_HANDLER_MAP = {
      [ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION]:
        handleQuickInstallMigrationScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON]:
        handleQuickInstallMigrationAddonScenario,
      default: undefined,
    };
    const nonSurveyNeededScenarioHandler =
      onboardingScenario &&
      mapKeyValue(NON_SURVEY_NEEDED_SCENARIOS_HANDLER_MAP, onboardingScenario);

    if (nonSurveyNeededScenarioHandler) {
      await nonSurveyNeededScenarioHandler();
      onboardingStore.removeAllCompletedSteps();
      isLoadingOnboarding.value = false;

      return;
    }

    const isEligible = await getSurveyEligibility();
    const SURVEY_NEEDED_SCENARIOS_HANDLER_MAP = {
      [ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON]:
        handleQuickInstallWordPressAddonScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_SETUP]:
        handleQuickInstallWordPressSetupScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON]:
        handleQuickInstallEmptyWebsiteAddonScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_SETUP]:
        handleQuickInstallEmptyWebsiteSetupScenario,
      [ONBOARDING_SCENARIO.SETUP_WORDPRESS_PRESELECTED]:
        handlePreselectedWordPressScenario,
      [ONBOARDING_SCENARIO.ADDON]: handleDefaultAddonScenario,
      [ONBOARDING_SCENARIO.MIGRATION_CHANGE_DOMAIN]: handleDomainChangeScenario,
      default: handleDefaultScenario,
    };
    const surveyNeededScenarioHandler = mapKeyValue(
      SURVEY_NEEDED_SCENARIOS_HANDLER_MAP,
      onboardingScenario || 'default',
    );

    if (
      onboardingStore.shouldContinueOnboarding &&
      !isAddonScenario(onboardingScenario || 'default')
    ) {
      const progress = await onboardingStore.loadProgress(subscriptionId.value);

      if (progress?.complete?.currentStep) {
        await onboardingStore.goTo(progress.complete.currentStep);
        isLoadingOnboarding.value = false;
      }

      return;
    }

    await surveyNeededScenarioHandler(isEligible);
    onboardingStore.setIsEligibleForSurvey(isEligible);
    onboardingStore.removeAllCompletedSteps();
    isLoadingOnboarding.value = false;
  };

  const getSurveyEligibility = async (): Promise<boolean> => {
    const response = await surveyRepo.getIsEligibleForSurvey(
      subscriptionId.value,
    );

    const [{ data }] = response;

    return data?.isEligible || false;
  };

  const goToTemplatePreview = () => {
    const redirectRouteData = {
      name: ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE,
    };
    router.push(redirectRouteData);

    onboardingStore.addCompletedStep(onboardingStore.currentStep);

    onboardingStore.setStep(ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE);
  };

  const goToDomainRegistration = (
    domain: string,
    nextStep: OnboardingStep,
    { isClaimable = false }: { isClaimable?: boolean } = {},
  ) => {
    const redirectRouteData = {
      name: isH5GOnboarding.value
        ? H5G_ONBOARDING_STEPS.H5G_DOMAIN_REGISTRATION
        : ONBOARDING_STEPS.DOMAIN_REGISTRATION,
      params: { domain: toASCII(domain) },
      query: {
        redirect: nextStep,
        order_id: subscriptionId.value,
        isClaimable: isClaimable ? '1' : '0',
        isMigration: isMigrationSelected.value ? '1' : '0',
      },
    };
    router.push(redirectRouteData);
    onboardingStore.addCompletedStep(onboardingStore.currentStep);
    onboardingStore.setStep(nextStep);
  };

  const isAddonOnboarding = computed(() => onboardingStore.isAddon);

  const isBusinessOrAbovePlan = computed(() =>
    subscriptionStore.getIsSubscriptionHostingPlanBusinessOrAbove(
      subscriptionId.value,
    ),
  );

  const nextStepAfterPluginOrPresetSetup = computed(() => {
    const { wordPressPreviewAstraTemplate } = onboardingStore.state;

    const isPreBuiltWebsiteSelected = !!wordPressPreviewAstraTemplate?.id;

    const isBlogWebsiteTypeSelected =
      onboardingStore.state.typeOfWebsite ===
      WORDPRESS_WEBSITE_TYPE_ANSWER.BLOG;

    const shouldRedirectToAIContentGeneration =
      isBusinessOrAbovePlan.value &&
      !isPreBuiltWebsiteSelected &&
      isBlogWebsiteTypeSelected;

    if (shouldRedirectToAIContentGeneration) {
      return ONBOARDING_STEPS.WORDPRESS_AI_CONTENT_GENERATION;
    }

    return ONBOARDING_STEPS.DOMAIN;
  });

  const openContinueOnboardingModal = (
    subscriptionId: string,
    handler?: () => void,
  ) => {
    const handleAction = () => {
      handler
        ? handler()
        : router.push({
            name: Route.OnboardingV2.BASE_PATH,
            params: {
              order_id: subscriptionId,
            },
          });
      closeModal();
    };

    openModal({
      component: { TwoChoicePromptModal },
      data: {
        title: t('v2.continue-setup-query'),
        subtitle: t('v2.start-from-beginning-or-continue'),
        secondaryActionLabel: t('v2.start-over'),
        primaryActionLabel: t('v2.continue-setup'),
        primaryAction: () => {
          onboardingStore.setShouldContinueOnboarding(true);
          handleAction();
        },
        secondaryAction: () => {
          onboardingStore.$reset();
          handleAction();
        },
      },
    });
  };

  const redirectToMigration = (domain?: string) => {
    const redirectParams = {
      domain: domain || onboardingStore.state[ONBOARDING_STEPS.DOMAIN]?.domain,
      order_id: subscriptionId.value,
      scenario: route.params?.scenario,
      username: route.params.username,
    };

    migrationOnboardingStore.onboardingRedirectionParams = redirectParams;

    if (onboardingScenario === ONBOARDING_SCENARIO.MIGRATION_CHANGE_DOMAIN) {
      amplitudeV2(AmplitudeEvent.Migration.MIGRATION_SUMMARY_UPDATED, {
        websiteToMigrate:
          onboardingStore.state[ONBOARDING_STEPS.DOMAIN]?.domain,
      });
      router.replace({
        name: MIGRATIONS_ONBOARDING_STEPS.SUMMARY_V2,
        params: {
          domain: onboardingStore.state[ONBOARDING_STEPS.DOMAIN]?.domain,
          order_id: subscriptionId.value,
        },
      });

      return;
    }

    router.replace({
      name: Route.MigrationsOnboarding.BASE_PATH,
      params: {
        domain:
          domain || onboardingStore.state[ONBOARDING_STEPS.DOMAIN]?.domain,
        order_id: subscriptionId.value,
      },
    });
  };

  return {
    subscriptionId,
    selectedPlatformByRedirect,
    isLoadingOnboarding,
    isMigrationSelected,
    hasSelectedWordPressAppearance,
    wordPressAppearanceType,
    isH5GOnboarding,
    isWordPressPlatformSelected,
    isBuilderPlatformSelected,
    goToTemplatePreview,
    fetchRequiredDataAndSetInitialStep,
    fetchInitialData,
    goToDomainRegistration,
    openContinueOnboardingModal,
    isAddonOnboarding,
    isBusinessOrAbovePlan,
    nextStepAfterPluginOrPresetSetup,
    redirectToMigration,
    onboardingScenario,
  };
};
