<script lang="ts" setup>
import { ref, watch } from 'vue';

import { getAsset } from '@/utils/helpers/assetsHelpers';

type Props = {
  poster?: string;
  src: string;
  type: string;
  muted?: boolean;
  playsinline?: boolean;
  loop?: boolean;
  autoplay?: boolean;
  preload?: string;
  width?: string;
  isVideoPlaying?: boolean;
};

const props = defineProps<Props>();
const videoRef = ref<HTMLVideoElement | null>(null);

const isVideoPlayingLocal = ref(false);

watch(
  () => props.isVideoPlaying,
  (value) => {
    value ? playVideo() : pauseVideo();
  },
);

const playVideo = async () => {
  if (!videoRef.value) return;

  try {
    await videoRef.value.play();

    isVideoPlayingLocal.value =
      !videoRef.value.paused &&
      videoRef.value.readyState > videoRef.value.HAVE_CURRENT_DATA;
  } catch {}
};

const pauseVideo = () => {
  if (!videoRef.value || !isVideoPlayingLocal.value) return;

  videoRef.value.pause();
  videoRef.value.loop = false;
};
</script>

<template>
  <video
    ref="videoRef"
    :poster="poster ? getAsset(poster) : ''"
    :muted="muted"
    :preload="preload"
    :playsinline="playsinline"
    :loop="loop"
    :width="width"
    :autoplay="autoplay"
  >
    <source :src="getAsset(src)" :type="type" />
  </video>
</template>
