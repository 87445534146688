import whCacheManagerRepo from '@/repositories/whApi/whCacheManagerRepo';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { getCurrentOrderDetails } from '@/utils/helpers/whHostingHelpers';

export default {
  state: {
    initial: {
      status: false,
      loaded: false,
    },
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_CACHE_MANAGER_STATUS(state, data) {
      setForDomain(state, null, 'status', data);
      setForDomain(state, null, 'loaded', true);
    },
  },
  getters: {
    getCacheState: (state) => getStateVariable(state),
  },
  actions: {
    async getCacheStatus({ commit }) {
      const [{ data }, err] = await whCacheManagerRepo.getCacheStatus(
        getCurrentOrderDetails(),
      );
      commit('SET_CACHE_MANAGER_STATUS', !err ? data.enabled : false);
    },
    async updateCacheStatus({ dispatch }, status) {
      let response;
      if (status === true) {
        response = await whCacheManagerRepo.enableCache(
          getCurrentOrderDetails(),
          status,
        );
      } else {
        response = await whCacheManagerRepo.disableCache(
          getCurrentOrderDetails(),
          status,
        );
      }
      await dispatch('getCacheStatus');

      return response;
    },
    async cachePurge({ dispatch }, payload) {
      const [{ data }, err] = await whCacheManagerRepo.purgeCache(
        getCurrentOrderDetails(),
        payload,
      );
      if (!err) await dispatch('getCacheStatus');

      return [{ data }, err];
    },
  },
};
