<template>
  <div>
    <div
      class="h-data-table"
      :class="{
        'h-data-table--no-border': noBorder,
        'h-data-table--bulky': bulky,
        'h-data-table--clickable-row': clickable,
        'h-data-table--bulky-botomless': bottomless,
        'h-data-table-md-responsive': responsiveMd,
        'h-data-table-sm-responsive': responsiveSm,
        'h-data-table-xs-responsive': responsiveXs,
      }"
    >
      <div
        v-if="!hideSearchBar"
        class="h-data-table__header mb-1"
        :class="{
          'h-data-table__header--with-refresh-button': withRefreshButton,
        }"
      >
        <div class="w-100" :class="searchClass">
          <HDataTableSearch
            v-model:h-search="hDataSearch"
            :searching="searchingBack"
            :search-id="searchBarId"
            :search-error="hDataSearchError"
            search-icon-left
            :placeholder="searchPlaceholder"
          />
        </div>
        <HButtonV1
          v-if="withRefreshButton"
          primary
          outline
          no-margin
          sm
          hp-icon="icon-refresh"
          :loading="showLoadingRefreshButton"
          @click="$emit('refresh-data')"
        >
          Refresh
        </HButtonV1>
      </div>

      <HDataTableRowSkeletonLoader
        v-if="loading"
        :columns="Object.keys(header).length"
        :rows="skeletonLoaderRows"
      />

      <div v-show="!loading">
        <table
          :id="tableId"
          :ref="tableId"
          class="table h-data-table"
          :class="[
            { 'table--valign-middle': verticalAlign },
            { 'table-striped': striped },
            { 'h-data-table--no-pagination': noPagination },
            { 'has-fixed-header-width': hasFixedHeaderWidth },
            { 'table--keep-padding': keepPaddingOnMobile },
            tableClass,
          ]"
        >
          <caption>
            <slot name="caption" />
          </caption>
          <thead>
            <tr v-if="!noDataFound">
              <th
                v-for="sortableHeader in sortableHeaders"
                :key="sortableHeader.value"
                :class="[
                  { 'cursor-pointer': sortableHeader.sortable },
                  headerClass,
                  `h-data-table__header--width-${sortableHeader.value}`,
                  headerWidth(sortableHeader),
                ]"
                :scope="sortableHeader"
                @click="
                  !sortableHeader.checkbox
                    ? changeSortingDirection(sortableHeader)
                    : toggleCheck(sortableHeader.active)
                "
              >
                <HButtonV1
                  v-if="sortableHeader.button"
                  class="h-m-0"
                  primary
                  sm
                  :disabled="sortableHeader.button.disabled"
                  v-bind="{
                    [sortableHeader.button.type]: true,
                    [sortableHeader.button.color]: true,
                  }"
                  @click="sortableHeader.button.action"
                >
                  {{ sortableHeader.button.text }}
                </HButtonV1>
                <HCheckbox
                  v-if="sortableHeader.checkbox"
                  class="h-data-table__checkbox"
                  :active="sortableHeader.active"
                  :partly-checked="sortableHeader.partlyChecked"
                />
                <Trans>{{ sortableHeader.text }}</Trans>
                <HpIcon
                  v-if="sortableHeader.tooltipIcon"
                  v-tooltip="sortableHeader.tooltipIcon.text"
                  class="h-data-table__tooltip-icon"
                  :icon="sortableHeader.tooltipIcon.icon"
                  gray
                  static-view-box
                />
                <div class="h-data-table__icons-wrapper">
                  <div
                    v-if="sortableHeader.sortable"
                    class="h-data-table__icons"
                  >
                    <i
                      class="h-data-table__icon h-data-table__icon--up"
                      :class="{
                        'h-data-table__icon--active':
                          sortableHeader.direction === 'up',
                      }"
                    />
                    <i
                      class="h-data-table__icon"
                      :class="{
                        'h-data-table__icon--active':
                          sortableHeader.direction === 'down',
                      }"
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            :key="body.length"
            class="h-data-table__body"
            :class="{ 'h-data-table--no-border': noItemBorder }"
          >
            <div v-if="isLocked" class="h-data-table__body-cover">
              <slot name="cover" />
            </div>
            <slot
              v-for="(item, itemKey) in body"
              name="items"
              :item="item"
              :item-key="itemKey"
            />
          </tbody>
        </table>
        <div
          v-if="showFooter"
          class="text-right h-pt-16 h-data-table__footer"
          :class="[
            footerClass,
            {
              'h-data-table__footer--no-border': noFooterBorder,
            },
          ]"
        >
          <HDataTablePerPage
            v-if="!hidePerPageSelect && !noPagination"
            class="d-inline-block"
            :h-per-page="itemsPerPage"
            @update-per-page="onHPerPage($event)"
          />
          <HDataTablePagination
            v-if="!noPagination"
            v-model:h-page="currentPage"
            class="d-inline-block"
            :total-pages="totalPageCount"
            :max-pages="4"
            :from="itemRangeFrom"
            :to="itemRangeTo"
            :total="totalItemCount"
          />
        </div>
      </div>
      <div v-if="showNoSearchResults">
        <NoSearchResults
          title="Nothing found"
          subtitle="Search phrase found no results."
          class="my-5"
        />
      </div>
      <slot v-if="showNoData" name="nodata">
        <NoData :title="noDataTitle" :subtitle="noDataSubtitle" class="my-5" />
      </slot>
    </div>
  </div>
</template>
<script>
import HCheckbox from '../../components/HCheckbox';
import HDataTablePagination from '../HDataTable/HDataTablePagination';
import HDataTablePerPage from '../HDataTable/HDataTablePerPage';
import HDataTableSearch from '../HDataTable/HDataTableSearch';
import NoData from '../NoData';
import NoSearchResults from '../NoSearchResults';

import HDataTableRowSkeletonLoader from '@/components/Loaders/SkeletonCompositions/HDataTableRowSkeletonLoader.vue';
import { replaceNonLatinCharacters } from '@/utils/helpers';
import {
  setItemsPerPage,
  getItemsPerPage,
} from '@/utils/services/paginationService';

const SHOW_PER_PAGE = 5;
const DATA_CONNECTION_ID_ATTR = 'data-connection-id';

export default {
  components: {
    HDataTableSearch,
    HDataTablePerPage,
    HDataTablePagination,
    NoSearchResults,
    NoData,
    HCheckbox,
    HDataTableRowSkeletonLoader,
  },
  props: {
    header: Object,
    tableId: {
      type: String,
      default: 'h-data-table_body',
    },
    headerClass: String,
    footerClass: String,
    tableClass: String,
    body: Array,
    noFooter: Boolean,
    noItemBorder: Boolean,
    noFooterBorder: Boolean,
    bulky: Boolean,
    noBorder: Boolean,
    clickable: Boolean,
    showLoader: Boolean,
    responsiveMd: Boolean,
    responsiveSm: Boolean,
    verticalAlign: {
      type: Boolean,
      default: true,
    },
    responsiveXs: {
      type: Boolean,
      default: true,
    },
    bottomless: Boolean,
    searchClass: String,
    searchBarId: String,
    sortedSearch: {
      type: Boolean,
      default: true,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    noPagination: Boolean,
    searchIconLeft: Boolean,
    withBackendSearch: {
      type: Boolean,
      default: false,
    },
    searchingBack: {
      type: Boolean,
      default: false,
    },
    noDataTitle: {
      type: String,
      default: 'Nothing found',
    },
    noDataSubtitle: {
      type: String,
      default: 'No data to show',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    hasFixedHeaderWidth: {
      type: Boolean,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    keepPaddingOnMobile: Boolean,
    withRefreshButton: {
      type: Boolean,
      default: false,
    },
    showLoadingRefreshButton: {
      type: Boolean,
      default: false,
    },
    initialItemsPerPage: {
      type: Number,
      default: 5,
    },
    isLocked: Boolean,
    isPersistedItemsPerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortableHeaders: [],
      hDataSearch: '',
      hDataSearchError: '',
      itemsPerPage: this.isPersistedItemsPerPage
        ? getItemsPerPage(this.initialItemsPerPage)
        : this.initialItemsPerPage,
      currentPage: 1,
      totalItemCount: 0,
      totalPageCount: 0,
      itemRangeFrom: 0,
      itemRangeTo: 0,
      noDataFound: false,
      itemCounterHidden: false,
      searchNoResults: false,
      foundNonLatinCharacters: false,
      filteredIndexes: [],
      skeletonLoaderRows: SHOW_PER_PAGE,
      table: null,
      tBody: null,
      rows: [],
    };
  },
  emits: [
    'table-search',
    'table-search-string',
    'backend-search',
    'toggle-check',
    'refresh-data',
    'page-changed',
  ],
  watch: {
    header: {
      handler() {
        const oldSortableHeaders = [...this.sortableHeaders];
        this.getSortableHeaders();
        oldSortableHeaders.forEach((oldHeader, index) => {
          this.sortableHeaders[index].direction = oldHeader.direction;
        });
      },
      deep: true,
    },
    hDataSearch() {
      this.$emit('table-search');
      this.$emit('table-search-string', this.hDataSearch);
      if (this.withBackendSearch) {
        const hasInvalidChars = /[^A-Za-z0-9._-\s]/.test(this.hDataSearch);
        this.hDataSearchError = '';
        if (hasInvalidChars) {
          this.hDataSearchError = this.$t(
            'Search can only contain numbers, letters, underscores, hyphens or dots',
          );

          return;
        }
        this.$emit('backend-search', this.hDataSearch?.trim());

        return;
      }
      this.currentPage = 1;
      if (this.hDataSearch) {
        this.filterItemsBySearch();
        this.itemRangeTo = this.totalItemCount;
        this.totalPageCount = Math.ceil(
          this.totalItemCount / this.itemsPerPage,
        );
      } else {
        this.tableRows.forEach((r) => (r.rows[0].style.display = ''));
        this.totalItemCount = this.body.length;
        this.totalPageCount = Math.ceil(this.body.length / this.itemsPerPage);
        this.searchNoResults = false;
        this.filteredIndexes = [];
      }
      this.paginate();
    },
    currentPage() {
      this.paginate();
    },
    itemsPerPage() {
      if (!this.hDataSearch) {
        this.totalPageCount = Math.ceil(this.body.length / this.itemsPerPage);
      } else {
        this.totalPageCount = Math.ceil(
          this.totalItemCount / this.itemsPerPage,
        );
      }
      this.paginate();
    },
    body: {
      async handler(value, oldValue) {
        if (value !== oldValue) {
          await this.initialiseTable();
          if (this.hDataSearch && !this.withBackendSearch) {
            this.$nextTick(() => {
              this.filterItemsBySearch();
              this.itemRangeTo = this.totalItemCount;
              this.totalPageCount = Math.ceil(
                this.totalItemCount / this.itemsPerPage,
              );
              this.paginate();
            });
          }
        }
      },
      deep: true,
    },
    hideSearchBar(value, oldValue) {
      if (oldValue !== value && value && !this.withBackendSearch) {
        this.hDataSearch = '';
      }
    },
    filteredIndexes: {
      handler() {
        if (!this.filteredIndexes.length && this.hDataSearch) {
          this.searchNoResults = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.table = this.$refs[this.tableId];
    this.tBody = this.table.tBodies[0];
    this.rows = [...this.tBody.rows];

    this.initialiseTable();
  },
  methods: {
    getConnectionId(el) {
      return el.getAttribute(DATA_CONNECTION_ID_ATTR);
    },
    async initialiseTable() {
      if (this.noFooter) this.itemsPerPage = this.body?.length;

      if (!this.hDataSearch) this.noDataFound = Boolean(!this.body?.length);

      this.totalItemCount = this.body?.length;
      this.totalPageCount = Math.ceil(this.body?.length / this.itemsPerPage);
      this.getSortableHeaders();
      await this.paginate();
    },
    toggleCheck(active) {
      this.$emit('toggle-check', active);
    },
    getSortableHeaders() {
      this.sortableHeaders = [];
      let columnIndex = 0;

      for (const element in this.header) {
        const header = this.header[element];
        const col = {
          text: header.text,
          value: header.value || element,
          checkbox: header.checkbox,
          button: header.button,
          active: header.active,
          partlyChecked: header.partlyChecked,
          sortable:
            typeof header.sortable === 'undefined' ? false : header.sortable,
          direction: header.direction || '',
          icon: '',
          index: columnIndex++,
          type: header.type,
          width: header.width,
          tooltipIcon: header.tooltipIcon,
        };
        this.sortableHeaders.push(col);
      }
    },
    changeSortingDirection(header) {
      if (!header.sortable) return;

      this.sortableHeaders.map((sortableHeader) => {
        if (sortableHeader.value === header.value) {
          if (header.direction === 'up' || header.direction === '') {
            return (sortableHeader.direction = 'down');
          }

          if (header.direction === 'down') {
            return (sortableHeader.direction = 'up');
          }
        }
        sortableHeader.direction = '';
        sortableHeader.icon = '';
      });
      this.sortItems(header);
    },
    sortItems(header) {
      // Take fresh instance of table and body before manipulating
      const table = this.$refs[this.tableId];
      const tBody = table.tBodies[0];
      const rows = [...tBody.rows];

      this.rows = this.isExpandableTable
        ? this.sortExpandableItems(header)
        : this.sortRows(rows, header);

      this.rows.map((row) => {
        tBody.appendChild(row);
      });

      this.paginate();
    },
    sortExpandableItems(header) {
      const EXPANDABLE_ROW_CLASS = 'h-expandable-row';

      const mainRows = this.rows.filter(
        (row) => ![...row.classList].includes(EXPANDABLE_ROW_CLASS),
      );

      const expandableRows = this.rows.filter((row) =>
        [...row.classList].includes(EXPANDABLE_ROW_CLASS),
      );

      const sortedMainRows = this.sortRows(mainRows, header);

      let additionalIndex = 1;
      sortedMainRows.forEach((row, index) => {
        const sortIndex = index + additionalIndex;
        row.sortIndex = sortIndex;
        additionalIndex = additionalIndex + 1;
      });

      expandableRows.forEach((row) => {
        const groupedMainRow = sortedMainRows.find(
          (mainRow) =>
            this.getConnectionId(mainRow) === this.getConnectionId(row),
        );

        row.sortIndex = groupedMainRow.sortIndex + 1;
      });

      return [...sortedMainRows, ...expandableRows].sort(
        (itemA, itemB) => itemA.sortIndex - itemB.sortIndex,
      );
    },
    sortRows(rows, header) {
      const direction = header.direction === 'down' ? 1 : -1;

      return rows.sort((itemA, itemB) => {
        const itemATextContent = itemA.cells[header.index].textContent.trim();
        const itemBTextContent = itemB.cells[header.index].textContent.trim();
        if (header.type === 'number') {
          return (
            direction * Number(itemATextContent) - Number(itemBTextContent)
          );
        }

        if (header.type === 'date') {
          return (
            direction * new Date(itemATextContent) - new Date(itemBTextContent)
          );
        }

        return direction * itemATextContent.localeCompare(itemBTextContent);
      });
    },
    searchRow(text, searchValue, row) {
      const hasExactMath = text.toLowerCase().indexOf(searchValue) > -1;

      if (hasExactMath) {
        row.rows.forEach((r) => {
          r.style.display = '';
        });
        this.searchNoResults = false;
        this.foundNonLatinCharacters = true;
        this.totalItemCount++;

        return true;
      }
    },
    searchRows(headers) {
      this.tableRows.forEach((row) => {
        row.rows.forEach((r) => {
          r.style.display = 'none';
        });

        for (const header of headers) {
          const rowText =
            row.rows[0].getElementsByTagName('td')[header.index].textContent;
          const dataSearch = this.hDataSearch.trim().toLowerCase();

          const isFound = this.searchRow(
            replaceNonLatinCharacters(rowText),
            replaceNonLatinCharacters(dataSearch),
            row,
          );

          if (isFound) {
            break;
          }
        }
      });
    },
    filterItemsBySearch() {
      this.itemRangeFrom = 0;
      this.searchNoResults = true;
      this.foundNonLatinCharacters = false;
      this.totalItemCount = 0;
      const headers = this.sortedSearch
        ? this.sortableHeaders.filter((h) => h.sortable)
        : this.sortableHeaders;

      // Update Rows before search
      this.rows = [...this.tBody.rows];

      this.searchRows(headers);

      this.$nextTick(() => {
        if (!this.table) return;

        const filteredRowsIndexes = [];

        this.tableRows.forEach((row, index) => {
          let visible = false;
          row.rows.forEach((r) => {
            if (r.style.display !== 'none') visible = true;
          });
          if (visible) return filteredRowsIndexes.push(index);
        });

        this.filteredIndexes = filteredRowsIndexes;
      });
    },
    onHPerPage(value) {
      this.itemsPerPage = value;
      this.currentPage = 1;

      if (this.isPersistedItemsPerPage) {
        setItemsPerPage(value);
      }
    },
    async paginate() {
      if (!this.noPagination) {
        this.itemRangeFrom = (this.currentPage - 1) * this.itemsPerPage;
        this.itemRangeTo =
          parseInt(this.itemRangeFrom) + parseInt(this.itemsPerPage);

        if (!this.hDataSearch) {
          if (this.itemRangeTo >= this.body?.length) {
            this.itemRangeTo = this.body?.length;
          }

          await this.$nextTick();
          if (!this.table) return;

          this.tableRows.forEach((row, index) => {
            if (
              (index >= this.itemRangeFrom && index < this.itemRangeTo) ||
              this.body?.length <= this.itemRangeFrom
            ) {
              this.showRows(row);
            } else {
              this.hideRows(row);
            }
          });
        } else {
          if (this.itemRangeTo >= this.totalItemCount) {
            this.itemRangeTo = this.totalItemCount;
          }

          await this.$nextTick();
          if (!this.table) return;
          if (!this.filteredIndexes.length) return;
          this.tableRows.forEach((row, index) => {
            const filteredIndex = this.filteredIndexes.indexOf(index);
            if (
              (filteredIndex !== -1 &&
                filteredIndex >= this.itemRangeFrom &&
                filteredIndex < this.itemRangeTo) ||
              this.totalItemCount <= this.itemRangeFrom
            ) {
              this.showRows(row);
            } else {
              this.hideRows(row);
            }
          });
        }

        this.$emit('page-changed');
      }
    },
    showRows(row) {
      row.rows.forEach((r) => {
        r.style.display = '';
      });
    },
    hideRows(row) {
      row.rows.forEach((r) => (r.style.display = 'none'));
    },
    headerWidth(header) {
      return header?.width ? `h-data-table__header--width-${header.width}` : '';
    },
  },
  computed: {
    isExpandableTable() {
      return !!this.rows[0].getAttribute(DATA_CONNECTION_ID_ATTR);
    },
    showFooter() {
      return (
        this.body.length > SHOW_PER_PAGE &&
        !this.noFooter &&
        !this.noDataFound &&
        !this.searchNoResults
      );
    },
    noBackendSearchResults() {
      return this.hDataSearch && !this.body?.length;
    },
    hideSearchBar() {
      return (
        (this.body?.length <= SHOW_PER_PAGE && !this.withBackendSearch) ||
        this.noDataFound ||
        this.noSearch
      );
    },
    hidePerPageSelect() {
      return this.body?.length <= SHOW_PER_PAGE;
    },
    hideItemCounter() {
      return this.body?.length <= SHOW_PER_PAGE;
    },
    hidePagination() {
      return this.totalPageCount === 1;
    },
    tableRows() {
      this.body?.length;
      const tableItems = this.rows.reduce((acc, item) => {
        if (item.hasAttribute(DATA_CONNECTION_ID_ATTR)) {
          //Group by connection id
          if (acc) {
            const itemId = item.getAttribute(DATA_CONNECTION_ID_ATTR);

            let found = null;

            acc.forEach((i) => {
              if (i.id === itemId) {
                i.rows.push(item);
                found = true;
              }
            });

            if (found) return acc;

            return [...acc, { rows: [item], id: itemId }];
          }
          const itemId = item.getAttribute(DATA_CONNECTION_ID_ATTR);

          return [{ rows: [item], id: itemId }];
        }
        if (acc) return [...acc, { rows: [item] }];

        return [{ rows: [item] }];
      }, []);

      return tableItems;
    },
    showNoSearchResults() {
      return (
        (this.searchNoResults || this.noBackendSearchResults) && !this.loading
      );
    },
    showNoData() {
      return this.noDataFound && !this.hDataSearch && !this.loading;
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/components/h-data-table';
@import '../../sass/components/table';

.h-data-table {
  &__body {
    position: relative;

    &-cover {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}
</style>
