export interface HToastrDropdownOptions {
  title: string;
  duration: number;
}

export interface HToastrDropdown {
  btnText: string;
  options: HToastrDropdownOptions[];
  callback: (option: HToastrDropdownOptions) => void;
  show?: boolean;
  btnProps?: object;
}

export interface HToastrButton {
  callback: () => void;
  text?: string;
  props?: object;
  externalLink?: boolean;
}

export interface HToastrMessage {
  title?: string;
  status?: string;
  id?: number;
  payload: {
    customId?: string;
    timeout?: number;
    forceTimeout?: boolean;
    html?: string;
    text?: string;
    imagePath?: string;
    icon?: string;
    iconType?: string;
    closeCallback?: () => void;
    shouldDisplay?: () => boolean;
    buttons?: HToastrButton[];
    message?: string;
    dropdowns?: HToastrDropdown[];
  };
}

export interface HToastrInput {
  title?: string;
  dropdowns?: HToastrDropdown[];
  customId?: string;
  timeout?: number;
  forceTimeout?: boolean;
  text?: string;
  imagePath?: string;
  icon?: string;
  iconType?: string;
  closeCallback?: () => void;
  shouldDisplay?: () => boolean;
  buttons?: HToastrButton[];
  html?: string;
  status?: string;
  message?: string;
  id?: number;
  optionalData?: {
    [key: string]: any;
  };
}

export interface HToastrState {
  index: number;
  hToastrMessages: HToastrMessage[];
}

export enum HToastrMutations {
  ADD_HTOASTR_MESSAGE = 'ADD_HTOASTR_MESSAGE',
  REMOVE_HTOASTR_MESSAGE = 'REMOVE_HTOASTR_MESSAGE',
  REMOVE_HTOASTR_MESSAGE_CUSTOM_ID = 'REMOVE_HTOASTR_MESSAGE_CUSTOM_ID',
}
