import type {
  DkimRecord,
  EmailOrder,
  GoogleWorkspaceOrder,
  IAliasCreationRequest,
  IAutoReplyCreationRequest,
  IAutoReplyUpdateRequest,
  ICatchAllCreationRequest,
  IDkimChangeRequest,
  IDkimRequest,
  IDomainCheckRequest,
  IEmailAccessLogsParams,
  IEmailAccountStatusUpdateRequest,
  IEmailActionLogsParams,
  IEmailDomainDetails,
  IEmailForwarderCreationRequest,
  IEmailImportTaskParams,
  IEmailInboundLogsParams,
  IEmailOrderParams,
  IEmailOrderUpgradeRequest,
  IEmailOutboundLogsParams,
  IGoogleMXRecordRequest,
  IGoogleWSSetupRequest,
  ITitanMailSetupRequest,
  IV2EmailAccountCreationRequest,
} from '@/types';
import { Error } from '@/types';
import { hAsync, toASCII } from '@/utils/helpers';
import { getEmailOrderId } from '@/utils/helpers/emailsHelper';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SSO}/v1/emails`,
  emailImportUrl: process.env.VITE_API_EMAIL_IMPORT_V2,
  restUrl: `${process.env.VITE_API_REST}/v2/emails`,
  hmailCoreUrl: `${process.env.VITE_API_HMAIL_CORE_V2}/api/v2`,
  hmailCoreEmailsUrl: `${process.env.VITE_API_HMAIL_CORE_V2}/api/v1/emails`,
  hmailTrailUrl: `${process.env.VITE_API_HMAIL_TRAIL_V2}/api/v1`,
  hmailIntegrationUrl: `${process.env.VITE_API_HMAIL_INTEGRATION}/public/api/v1`,

  async getEmailAccounts(domain: string, orderId?: string) {
    const params = orderId ? { orderId } : getEmailOrderId(domain);

    return await hAsync(
      http.get(
        `${this.hmailCoreEmailsUrl}/account/details/${toASCII(domain)}`,
        { params },
      ),
    );
  },

  async setupFlockEmail(params: ITitanMailSetupRequest) {
    return hAsync(
      http.post(`${this.url}/flock/setup`, params, { hideToastr: true }),
    );
  },
  async getFlockAutoLogin(orderId: string) {
    return await hAsync(
      http.get(`${this.url}/flock/auto-login`, {
        params: { orderId },
      }),
    );
  },

  async googleGsuiteRetry(actionId: string) {
    return await hAsync(
      http.post(
        `${this.hmailIntegrationUrl}/google-workspace/actions/${actionId}/retry`,
      ),
    );
  },

  async setupGoogleGsuite(params: IGoogleWSSetupRequest, orderId: string) {
    return await hAsync(
      http.post(
        `${this.hmailIntegrationUrl}/google-workspace/setup/${orderId}`,
        {
          ...params,
        },
      ),
    );
  },

  setGoogleMxRecord(data: IGoogleMXRecordRequest) {
    return hAsync(http.post(`${this.restUrl}/google-mx-record`, data));
  },

  getMxRecords() {
    return hAsync(http.get(`${this.restUrl}/mx-records`));
  },

  async getGoogleGsuiteActions(orderId: string) {
    return await hAsync(
      http.get(
        `${this.hmailIntegrationUrl}/google-workspace/actions/${orderId}`,
      ),
    );
  },

  async getGoogleWorkspaceData(orderId: string) {
    return await hAsync<GoogleWorkspaceOrder>(
      http.get(`${this.hmailIntegrationUrl}/google-workspace/${orderId}`),
    );
  },

  async getGsuiteSetupData(orderId: string) {
    return await hAsync(
      http.get(`${this.hmailIntegrationUrl}/google-workspace/setup/${orderId}`),
    );
  },

  async getGsuiteAvailability(domain: string) {
    return await hAsync<{ exists: boolean }>(
      http.get(
        `${this.hmailIntegrationUrl}/google-workspace/availability/${domain}`,
      ),
    );
  },

  getGsuites() {
    return hAsync(http.get(`${this.restUrl}/gsuites`));
  },

  async setupEmail(orderId: string, domain: string) {
    return await hAsync(
      http.post(
        `${this.hmailCoreEmailsUrl}/hostinger/setup`,
        { domain: toASCII(domain) },
        { params: { order_id: orderId }, hideToastr: true },
      ),
    );
  },
  async getUpgradeData(order_id: string) {
    return await hAsync(
      http.get(`${this.url}/hostinger`, {
        params: { order_id },
      }),
    );
  },

  async getAllAccounts() {
    return await hAsync(http.get(`${this.hmailCoreUrl}/accounts`));
  },

  async createEmailAccount(request: IV2EmailAccountCreationRequest) {
    return await hAsync(
      http.post(`${this.hmailCoreUrl}/accounts`, request, {
        plain: true,
        whitelistedErrorCodes: [
          Error.Email.ACCOUNT_ALREADY_EXISTS,
          Error.Email.INSECURE_PASSWORD,
        ],
      }),
    );
  },
  async deleteEmailAccount(resourceId: string) {
    return await hAsync<void>(
      http.delete(`${this.hmailCoreUrl}/accounts/${resourceId}`),
    );
  },
  async patchAccountStatus(
    domainResourceId: string,
    request: IEmailAccountStatusUpdateRequest,
  ) {
    return await hAsync(
      http.patch(
        `${this.hmailCoreUrl}/accounts/${domainResourceId}/status`,
        request,
        {
          plain: true,
        },
      ),
    );
  },
  async domainServiceControl(serviceControl: boolean, domain: string) {
    return await hAsync(
      http.patch(
        `${this.hmailCoreEmailsUrl}/manage-domain-suspend`,
        {
          serviceControl,
          domain,
        },
        { params: getEmailOrderId() },
      ),
    );
  },
  async updateEmailAccountPassword(email: string, password: string) {
    return await hAsync(
      http.patch(
        `${this.hmailCoreEmailsUrl}/account/password`,
        { password },
        { params: { email, ...getEmailOrderId() } },
      ),
    );
  },
  async getDomainDetails(domain: string, orderId?: string) {
    const params = orderId ? { orderId } : getEmailOrderId(domain);

    return await hAsync(
      http.get(`${this.hmailCoreEmailsUrl}/domain/details/${toASCII(domain)}`, {
        params,
      }),
    );
  },
  async getPlanDetails(
    plan: string,
    revision: string,
    domain: string,
    orderId?: string,
  ) {
    const params = orderId ? { orderId } : getEmailOrderId(domain);

    return await hAsync(
      http.get(`${this.hmailCoreEmailsUrl}/plan/details/${plan}/${revision}`, {
        params,
      }),
    );
  },

  async getForwards(domain: string) {
    return await hAsync(
      http.get(
        `${this.hmailCoreEmailsUrl}/forwarder/details/${toASCII(domain)}`,
        {
          params: getEmailOrderId(),
        },
      ),
    );
  },
  async createForwarder(request: IEmailForwarderCreationRequest) {
    return await hAsync(
      http.post(`${this.hmailCoreEmailsUrl}/forwarder/create`, request, {
        params: getEmailOrderId(),
        hideToastr: true,
      }),
    );
  },
  async deleteForwarder(from: string, to: string) {
    return await hAsync(
      http.delete(`${this.hmailCoreEmailsUrl}/forwarder`, {
        data: {
          addressFrom: toASCII(from),
          addressTo: toASCII(to),
          ...getEmailOrderId(),
        },
      }),
    );
  },
  async updateForwarder(email: string, serviceControl: boolean) {
    return await hAsync(
      http.patch(
        `${this.hmailCoreEmailsUrl}/forwarder/keep-copy`,
        {
          email,
          serviceControl,
        },
        { params: getEmailOrderId() },
      ),
    );
  },
  async resendForwarder(addressFrom: string, addressTo: string, brand: string) {
    return await hAsync(
      http.post(
        `${this.hmailCoreEmailsUrl}/forwarder/verify/resend`,
        {
          addressFrom,
          addressTo,
          brand,
        },
        { params: getEmailOrderId() },
      ),
    );
  },

  async createCatchAll(request: ICatchAllCreationRequest) {
    return await hAsync(
      http.post(`${this.hmailCoreEmailsUrl}/catchall/create`, request, {
        params: getEmailOrderId(),
      }),
    );
  },
  async deleteCatchAll(domain: string) {
    return await hAsync(
      http.delete(`${this.hmailCoreEmailsUrl}/catchall/${toASCII(domain)}`, {
        params: getEmailOrderId(),
      }),
    );
  },
  async resendCatchAll(domain: string, addressTo: string, brand: string) {
    return await hAsync(
      http.post(
        `${this.hmailCoreEmailsUrl}/catchall/verify/resend`,
        { domain: toASCII(domain), addressTo, brand },
        { params: getEmailOrderId() },
      ),
    );
  },

  async getAliases(domainResourceId: string) {
    return await hAsync(
      http.get(`${this.hmailCoreUrl}/domains/${domainResourceId}/alias`),
    );
  },
  async createAlias(request: IAliasCreationRequest) {
    return await hAsync(
      http.post(`${this.hmailCoreUrl}/aliases`, request, {
        plain: true,
        whitelistedErrorCodes: [
          Error.Email.ACCOUNT_ALREADY_EXISTS,
          Error.Email.ALIAS_DUPLICATE,
        ],
      }),
    );
  },
  async deleteAlias(id: string) {
    return await hAsync(http.delete(`${this.hmailCoreUrl}/aliases/${id}`));
  },

  async getAutoReply(domainResourceId: string) {
    return await hAsync(
      http.get(`${this.hmailCoreUrl}/domains/${domainResourceId}/autoreplies`),
    );
  },
  async postAutoReply(request: IAutoReplyCreationRequest) {
    return await hAsync(
      http.post(`${this.hmailCoreUrl}/auto-replies`, request, {
        plain: true,
      }),
    );
  },
  async putAutoReply(id: string, request: IAutoReplyUpdateRequest) {
    return await hAsync(
      http.put(`${this.hmailCoreUrl}/auto-replies/${id}`, request, {
        plain: true,
      }),
    );
  },
  async deleteAutoReply(id: string) {
    return await hAsync(
      http.delete(`${this.hmailCoreUrl}/auto-replies/${id}`, {
        plain: true,
      }),
    );
  },

  async getEmailImportRequests(domain: string) {
    return hAsync(
      http.get(`${this.emailImportUrl}/api/v1/domains/${toASCII(domain)}`),
    );
  },

  async newEmailImportRequests(params: IEmailImportTaskParams) {
    return hAsync(http.post(`${this.emailImportUrl}/api/v1/tasks`, params));
  },

  async retryEmailImportRequest(taskId: string) {
    return hAsync(http.put(`${this.emailImportUrl}/api/v1/tasks/${taskId}`));
  },

  async cancelEmailImportRequest(taskId: string) {
    return hAsync(http.delete(`${this.emailImportUrl}/api/v1/tasks/${taskId}`));
  },

  async getMailServiceControl() {
    return await hAsync(http.get(`${this.restUrl}/mail-service-control`));
  },

  async getEmailActionLogs(params: IEmailActionLogsParams, domain: string) {
    const orderId = getEmailOrderId(domain)?.orderId;

    return await hAsync(
      http.get(`${this.hmailTrailUrl}/action/${orderId}`, {
        params,
      }),
    );
  },
  async getEmailAccessLogs(
    params: IEmailAccessLogsParams,
    domain: string,
    account?: string,
  ) {
    const path = account
      ? `${toASCII(domain)}/${toASCII(account)}`
      : `${toASCII(domain)}`;

    return await hAsync(
      http.get(`${this.hmailTrailUrl}/access/${path}`, {
        params,
      }),
    );
  },
  async getEmailInboundLogs(params: IEmailInboundLogsParams, domain: string) {
    return await hAsync(
      http.get(`${this.hmailTrailUrl}/inbound/${toASCII(domain)}`, {
        params,
      }),
    );
  },
  async getEmailOutboundLogs(params: IEmailOutboundLogsParams, domain: string) {
    return await hAsync(
      http.get(`${this.hmailTrailUrl}/outbound/${toASCII(domain)}`, {
        params,
      }),
    );
  },

  async getEmailOrders(params: IEmailOrderParams) {
    return await hAsync<EmailOrder[]>(
      http.get(`${this.hmailCoreUrl}/orders`, {
        params,
      }),
    );
  },

  async getEmailOrderById(orderId: string, params?: IEmailOrderParams) {
    return hAsync<EmailOrder>(
      http.get(`${this.hmailCoreUrl}/orders/${orderId}`, {
        params,
      }),
    );
  },

  async upgradeEmailOrder(
    orderId: string,
    { plan, seats }: IEmailOrderUpgradeRequest,
  ) {
    return hAsync(
      http.post(`${this.hmailCoreUrl}/orders/${orderId}/upgrade`, {
        plan,
        seats,
      }),
    );
  },

  async checkIsDomainUsed({ orderId, domain }: IDomainCheckRequest) {
    return hAsync(
      http.post(
        `${this.hmailCoreUrl}/domains/check`,
        {
          orderId,
          domain,
        },
        { plain: true },
      ),
    );
  },

  async getDkim({ domainResourceId }: IDkimRequest) {
    return hAsync<DkimRecord[]>(
      http.get(`${this.hmailCoreUrl}/dkim/${domainResourceId}`),
    );
  },

  async createDkim({ domainResourceId }: IDkimRequest) {
    return hAsync<DkimRecord>(
      http.post(`${this.hmailCoreUrl}/dkim/${domainResourceId}`),
    );
  },

  async verifyDkim({ domainResourceId, hostname }: IDkimChangeRequest) {
    return hAsync<DkimRecord>(
      http.post(`${this.hmailCoreUrl}/dkim/verify/${domainResourceId}`, {
        hostname,
      }),
    );
  },

  async deleteDkim({ domainResourceId, hostname }: IDkimChangeRequest) {
    return hAsync<DkimRecord[]>(
      http.delete(`${this.hmailCoreUrl}/dkim/${domainResourceId}`, {
        params: {
          hostname,
        },
      }),
    );
  },

  async getEmailDomainDetails(domainResourceId: string) {
    return hAsync<IEmailDomainDetails>(
      http.get(`${this.hmailCoreUrl}/domains/${domainResourceId}`),
    );
  },

  async checkPassword(password: string) {
    return hAsync<{ leaked: boolean }>(
      http.post(`${this.hmailCoreUrl}/password/check`, { password }),
    );
  },
};
