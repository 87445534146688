import { useResourceMapper } from '@/composables';
import { router } from '@/router';
import store from '@/store';
import { useVpsServerStore, useResourcesStore } from '@/stores';
import { RedirectMutations, Route, Redirect } from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const SKIPABLE_GUARD_ROUTES = [Route.Base.WEBSITES];

const DEPRECATED_ROUTES = {
  [Redirect.Section.WEBSITES_LIST]: Route.Base.WEBSITES,
} as const;

const SECTION_TO_ROUTE_SWITCH = {
  ...DEPRECATED_ROUTES,
  [Redirect.Section.ORDER_UPGRADE]: Route.Order.ORDER_UPGRADE,
  [Redirect.Section.STAGING]: Route.HostingWordpress.STAGING,
  [Redirect.Section.BACKUPS]: Route.HostingFiles.BACKUPS,
  [Redirect.Section.WORDPRESS_SECURITY]: Route.HostingWordpress.SECURITY,
  [Redirect.Section.EXTERNAL_DOMAIN_DNS]: Route.Domain.EXTERNAL_DOMAIN_DNS,
  [Redirect.Section.CONNECT_DOMAIN]: Route.Email.CONNECT_DOMAIN,
  [Redirect.Section.PHP_CONFIGURATION]: Route.HostingAdvanced.PHP_CONFIGURATION,
  [Redirect.Section.UPGRADE_HOSTING]: Route.Hosting.HOSTING_UPGRADE_V2,
  [Redirect.Section.UPGRADE_EMAIL]: Route.Email.UPGRADE_PLAN,
  default: null,
};

export const handleCRMRedirects = async (params: Record<string, string>) => {
  const domain = params.domain || params.manage_domain;

  const sectionSwitch = mapKeyValue(SECTION_TO_ROUTE_SWITCH, params.section);

  if (sectionSwitch) {
    params.section = sectionSwitch;
  }

  const baseRoute = {
    name: params.section,
    params: {
      domain,
      ...params,
    },
  };
  errorLogger.addBreadcrumb({
    name: 'handleCRMRedirects',
    data: { ...params, jwt: undefined },
  });

  const { getMappedMonolithOrderById } = useResourceMapper();

  if (params.section === Redirect.Section.ACCOUNT_SHARING) {
    return router.replace({
      name: Route.Profile.ACCOUNT_SHARING,
      query: { tab: params.category },
    });
  }

  if (
    params.section === Redirect.Section.BILLING &&
    params.subscription_id &&
    params.action === Redirect.Action.RENEW
  ) {
    return router.replace({
      ...baseRoute,
      query: { renewSubscriptionId: params.subscription_id },
    });
  }

  if (
    params.section === Redirect.Section.DOMAIN_CHECKER &&
    params.action === Redirect.Action.GENERATE
  ) {
    return router.replace({
      ...baseRoute,
      query: {
        tab: Redirect.Action.GENERATE,
      },
    });
  }

  if (
    params.section === Redirect.Section.ORDER_DETAILS &&
    params.action === Redirect.Action.TRANSFER
  ) {
    return router.replace({
      ...baseRoute,
      query: {
        transfer: 'true',
      },
    });
  }

  if (
    params.section === Redirect.Section.WEBSITES &&
    params.domain &&
    params.action
  ) {
    return router.replace({
      ...baseRoute,
      query: { domain: params.domain, action: params.action },
    });
  }

  if (params.section === Redirect.Section.DOMAIN_REGISTER && params.order_id) {
    const data = getMappedMonolithOrderById(params.order_id);

    if (data) {
      // @ts-ignore
      const { hresourceId, domainSld, domainTld } = data;

      const routeName = hresourceId
        ? 'register-domain-resource'
        : 'register-domain';

      const registrationParams = {
        ...(hresourceId
          ? { domain: domainSld + domainTld }
          : {
              order_id: params.order_id,
              domain_sld: domainSld,
              domain_tld: domainTld,
            }),
      };

      store.commit(RedirectMutations.SET_REDIRECT, registrationParams);

      return router.replace({
        name: routeName,
        params: { ...registrationParams },
      });
    }
  }

  if (params.section === Route.Hosting.HOSTING_UPGRADE_V2 && domain) {
    return redirectToHostingUpgrade(domain);
  }

  store.commit(RedirectMutations.SET_REDIRECT, baseRoute.params);

  if (params.section === Redirect.Section.EMAIL_FORWARDERS) {
    return router.replace({
      ...baseRoute,
      query: { orderId: params.order_id },
    });
  }

  if (
    params.section === Redirect.Section.EMAIL_FLOCK_MANAGEMENT &&
    params.action === Redirect.Action.CREATE_EMAIL
  ) {
    return router.replace({
      ...baseRoute,
      query: { emailCreation: 'true' },
    });
  }

  const baseParams = {
    domain,
    skipGuard: String(
      !!SKIPABLE_GUARD_ROUTES.includes(params.section as Route.Base),
    ),
    ...params,
  };

  if (
    params.section === Redirect.Section.EMAIL_FLOCK_MANAGEMENT &&
    params.action
  ) {
    return router.replace({
      ...baseRoute,
      name: 'flock_dashboard',
    });
  }

  store.commit(RedirectMutations.SET_REDIRECT, baseParams);

  if (params.section === Redirect.Section.SERVER) {
    redirectToServerDashboard(params);

    return;
  }

  return router.replace({
    name:
      params.section === Redirect.Section.PANEL
        ? Route.Base.HOME
        : params.section || Route.Base.HOME,
    params: baseParams,
  });
};

const redirectToServerDashboard = async (params: Record<string, string>) => {
  const serverStore = useVpsServerStore();

  if (!serverStore.servers.length) {
    await serverStore.fetchServers();
  }

  const server = serverStore.getServerByOrderId(params.order_id);
  if (!server) return;

  router.replace({
    path: `/server/${server.id}/${params.category}`,
  });
};

const redirectToHostingUpgrade = async (domain: string) => {
  const resourcesStore = useResourcesStore();
  await resourcesStore.fetchResources();

  return router.replace({
    name: Route.Hosting.HOSTING_UPGRADE_V2,
    params: {
      id: domain,
    },
  });
};
