<script setup lang="ts">
import { onMounted, ref, watch, nextTick } from 'vue';

import { useLanguageStore } from '@/stores';

const props = defineProps<{ isExpanded: boolean }>();

const content = ref<Element>();
const height = ref();

const languageStore = useLanguageStore();

const calculateHeight = async () => {
  if (!content.value) {
    height.value = 'auto';

    return;
  }

  await nextTick();

  height.value = `${content.value.scrollHeight}px`;
};

onMounted(async () => {
  calculateHeight();
});

watch(
  () => languageStore.currentLanguage,
  () => {
    calculateHeight();
  },
);

watch(
  () => props.isExpanded,
  () => {
    calculateHeight();
  },
);

onMounted(async () => {
  calculateHeight();
});
</script>

<template>
  <div
    ref="content"
    class="animate-height"
    :class="{
      'animate-height--expanded': isExpanded,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.animate-height {
  transition: all 0.5s cubic-bezier(0.32, 0.72, 0, 1);
  overflow: hidden;
  max-height: 0;
  opacity: 0;

  &--expanded {
    max-height: v-bind(height);
    opacity: 1;
  }
}
</style>
