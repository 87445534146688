import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useDataCenter, useResourceMapper } from '@/composables';
import { datacentersRepo } from '@/repositories';
import { useResourcesStore } from '@/stores';
import type {
  DataCenterMap,
  DataCenterCoordinatesMap,
  SuggestedDataCenterMap,
  PingedDataCenter,
} from '@/types';

export const useOnboardingDataCenterStore = defineStore(
  'OnboardingDataCenterStore',
  () => {
    const dataCenters = ref({} as DataCenterMap);
    const coordinates = ref({} as DataCenterCoordinatesMap);
    const bestDataCenter = ref<SuggestedDataCenterMap | {}>({});
    const pingedDataCenters = ref<PingedDataCenter[]>([]);

    const { pingDataCenters: ping } = useDataCenter();
    const { getResourcesByIdempotencyKey } = useResourceMapper();

    const sortDataCentersByLatency = (dataCenters: PingedDataCenter[]) =>
      dataCenters.sort((a, b) => a.latency - b.latency);

    const pingDataCenters = async (subscriptionId: string) => {
      const currentOnboardingOrder =
        getResourcesByIdempotencyKey(subscriptionId);

      if (!currentOnboardingOrder?.referenceId) return;

      const response = await ping({
        plan: currentOnboardingOrder?.config?.plan,
        orderId: currentOnboardingOrder.referenceId,
      });

      if (!response) return;
      pingedDataCenters.value = sortDataCentersByLatency(response);

      const closestDataCenter = pingedDataCenters.value?.[0]?.datacenter;

      const foundDataCenter = dataCenters.value[closestDataCenter];

      if (!foundDataCenter) return;

      bestDataCenter.value = {
        [closestDataCenter]: foundDataCenter,
      };
    };

    const fetchAvailableDataCenters = async (subscriptionId: string) => {
      const { getResourceBySubscriptionId } = useResourcesStore();

      const resource = getResourceBySubscriptionId(subscriptionId);

      if (!resource || !resource?.config?.plan) return false;

      const [{ data }, error] = await datacentersRepo.getAvailableDatacenters(
        resource.config.plan,
        resource.referenceId as string,
      );

      if (error || !data) return false;

      dataCenters.value = data.dataCenters;
      coordinates.value = data.coordinates;
      bestDataCenter.value = data.bestDataCenter;

      return true;
    };

    const fetchOnboardingDataCenters = async (subscriptionId: string) => {
      const isDataAvailable = await fetchAvailableDataCenters(subscriptionId);
      if (!isDataAvailable) return;

      await pingDataCenters(subscriptionId);
    };

    return {
      fetchAvailableDataCenters,
      fetchOnboardingDataCenters,
      dataCenters,
      coordinates,
      bestDataCenter,
    };
  },
  { persist: { key: 'onboarding-data-center-store' } },
);
