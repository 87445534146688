import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'files',
    name: Route.HostingFiles.FILES,
    meta: {
      title: 'Files',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#files' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'file-manager',
        name: Route.HostingFiles.FILE_MANAGER,
        meta: {
          title: 'File Manager',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingFiles.FILES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Files/FileManagerSelect.vue'),
      },
      {
        path: 'import-website',
        name: Route.HostingFiles.IMPORT_WEBSITE,
        meta: {
          title: 'Import Website',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingFiles.FILES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Files/ImportWebsite.vue'),
      },
      {
        path: 'change-password',
        name: Route.HostingFiles.CHANGE_PASSWORD,
        meta: {
          title: 'Change Password',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingFiles.FILES,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Accounts/AccountChangePassword.vue'),
      },
      {
        path: 'ftp-accounts',
        name: Route.HostingFiles.FTP_ACCOUNTS,
        meta: {
          title: 'FTP Accounts',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingFiles.FILES,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Files/FtpAccounts.vue'),
      },
      {
        path: 'backups',
        name: Route.HostingFiles.BACKUPS,
        meta: {
          title: 'Backups',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingFiles.FILES,
          ],

          rateServicesTitle: 'How would you rate backup restoration process?',
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Files/Backups.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
