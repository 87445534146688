<template>
  <HDataTableRowSkeletonLoader v-if="isLoading" :columns="3" :rows="7" />
  <div v-else class="boost-plan-table">
    <div class="boost-plan-table__header">
      <div class="boost-plan-table__heading--primary" />
      <div
        class="boost-plan-table__heading--secondary"
        :class="{
          'boost-plan-table__heading--disabled': step === 2,
        }"
        @click="handleStepChange"
      >
        <div class="boost-plan-table__heading--row">
          <div class="boost-plan-table__heading--column">
            <span v-trans>Current plan</span>
            <div class="boost-plan-table__heading--plan-name">
              {{ planNames.current }}
            </div>
          </div>
          <div class="boost-plan-table__heading--icon-right">
            <HpIcon primary icon="ic-keyboard-arrow-down" static-viewbox />
          </div>
        </div>
      </div>
      <div
        class="boost-plan-table__heading--secondary"
        :class="{
          'boost-plan-table__heading--disabled': step === 1,
        }"
        @click="handleStepChange"
      >
        <div class="boost-plan-table__heading--row">
          <div class="boost-plan-table__heading--icon-left">
            <HpIcon primary icon="ic-keyboard-arrow-down" static-viewbox />
          </div>
          <div class="boost-plan-table__heading--column">
            <span v-trans>After boosting</span>
            <div class="boost-plan-table__heading--plan-name">
              {{ planNames.recommended }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(resource, index) in resources"
      :key="index"
      class="boost-plan-table__row"
    >
      <div class="boost-plan-table__item--primary">
        <span>
          <strong v-trans>
            {{ resource.name }}
          </strong>
          <HpIcon
            v-if="resource.tooltip"
            v-tooltip="resource.tooltip"
            class="h-ml-4"
            icon="ic-info"
            view-box="0 -4 36 36"
            gray
          />
        </span>
      </div>
      <div
        class="boost-plan-table__item--secondary"
        :class="{
          'boost-plan-table__item--disabled': step === 2,
        }"
      >
        <span>{{ resource.currentValue }}</span>
      </div>
      <div
        class="boost-plan-table__item--secondary"
        :class="{
          'boost-plan-table__item--disabled': step === 1,
        }"
      >
        <span>{{ resource.boostedValue }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import HDataTableRowSkeletonLoader from '@/components/Loaders/SkeletonCompositions/HDataTableRowSkeletonLoader';

export default {
  components: { HDataTableRowSkeletonLoader },
  props: {
    isLoading: Boolean,
    resources: Array,
    planNames: Object,
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    handleStepChange() {
      if (this.step === 1) this.step = 2;
      else this.step = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.boost-plan-table {
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    font-weight: bold;
    flex-direction: row;
    border-bottom: 1px solid var(--gray-border);
  }
  &__heading {
    &--primary {
      width: 40%;
    }
    &--secondary {
      width: 30%;
      padding: 8px 0px;
    }
    &--row {
      display: flex;
      flex-direction: row;
      height: 100%;
    }
    &--column {
      width: 100%;
    }
    &--icon-right {
      display: none;
    }
    &--icon-left {
      display: none;
    }
    &--plan-name {
      font-weight: normal;
      font-size: 12px;
      color: var(--gray);
    }
  }
  &__row {
    display: flex;
    border-bottom: 1px solid var(--gray-border);
  }

  &__item {
    &--primary {
      width: 40%;
      padding: 8px 0px;
    }
    &--secondary {
      padding: 8px 0px;
      width: 30%;
    }
  }

  @media (max-width: 600px) {
    &__heading {
      &--primary {
        width: 50%;
        box-shadow: 3px 1px 2px 0px var(--gray-border);
      }
      &--secondary {
        width: 50%;
        padding: 8px 8px;
        cursor: pointer;
      }
      &--row {
        align-items: center;
      }
      &--icon-right {
        display: flex;
        align-content: center;
        transform: rotate(-90deg);
      }
      &--icon-left {
        display: flex;
        align-content: center;
        transform: rotate(90deg);
        margin-left: -10px;
      }
      &--disabled {
        display: none;
      }
      &--plan-name {
        font-weight: normal;
        font-size: 12px;
        color: var(--gray);
      }
    }

    &__item {
      &--primary {
        width: 50%;
        box-shadow: 3px 1px 2px 0px var(--gray-border);
      }
      &--secondary {
        width: 50%;
        padding: 8px 8px;
        display: flex;
        align-items: center;
      }
      &--disabled {
        display: none;
      }
    }
  }
}
</style>
