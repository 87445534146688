import { Client, HIcon } from '@/types';

export const REFERRAL_IMAGE_BRAND_MAP = {
  [Client.Brand.HOSTINGER]: {
    inviteImage: '@/images/referrals/referral-invite-icon.svg',
    rewardImage: '@/images/referrals/referral-enjoy-icon.svg',
  },
  [Client.Brand.NG_CO_ID]: {
    inviteImage: '@/images/niagahoster/referrals/referral-invite-icon.svg',
    rewardImage: '@/images/niagahoster/referrals/referral-enjoy-icon.svg',
  },
};

export const ICON_IMAGE_BRAND_MAP = {
  [Client.Brand.HOSTINGER]: {
    copyIcon: '@/images/copy.svg',
  },
  [Client.Brand.NG_CO_ID]: {
    copyIcon: '@/images/niagahoster/copy.svg',
  },
};

export const ILLUSTRATION_IMAGE_BRAND_MAP = {
  [Client.Brand.HOSTINGER]: {
    openingImage: '@/images/domains/Illstration-opening.svg',
    proPanelGetStartedImage: '@/images/proPanel/getStartedImage.svg',
  },
  [Client.Brand.NG_CO_ID]: {
    openingImage: '@/images/niagahoster/domains/illustration-opening.svg',
    proPanelGetStartedImage:
      '@/images/niagahoster/hostinger_pro_get_started.svg',
  },
};

export const DOMAIN_IMAGE_BRAND_MAP = {
  [Client.Brand.HOSTINGER]: {
    websiteImage: '@/images/illustrations/CrossSell/HostingCrossSell.svg',
    emailImage: '@/images/illustrations/CrossSell/EmailCrossSell.svg',
    protectImage: '@/images/illustrations/CrossSell/DomainCrossSell.svg',
    wwwImage: '@/images/product-icons/domain.svg',
  },
  [Client.Brand.NG_CO_ID]: {
    websiteImage:
      '@/images/niagahoster/illustrations/CrossSell/HostingCrossSell.svg',
    emailImage:
      '@/images/niagahoster/illustrations/CrossSell/EmailCrossSell.svg',
    protectImage:
      '@/images/niagahoster/illustrations/CrossSell/DomainCrossSell.svg',
    wwwImage: '@/images/product-icons/niagahoster/domain.svg',
  },
};

export const ICON_BRAND_MAP = {
  default: {
    transferRequire: HIcon.ICON_TRANSFER_REQUIREMENTS,
    domainUnlocked: HIcon.ICON_DOMAIN_UNLOCKED,
    authCode: HIcon.ICON_AUTH_CODE,
  },
  [Client.Brand.NG_CO_ID]: {
    transferRequire:
      '@/images/niagahoster/icons/domains/transfer-requirements.svg',
    domainUnlocked: '@/images/niagahoster/icons/domains/domain-unlocked.svg',
    authCode: '@/images/niagahoster/icons/domains/auth-code.svg',
  },
};
