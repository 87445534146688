<template>
  <div
    class="checkbox"
    :class="[
      checkboxColor,
      {
        'checkbox--partly-checked': partlyChecked,
        'checkbox--gray': gray,
        'checkbox--disabled': disabled,
        'checkbox--fancy': fancy,
        'checkbox--thick': thick,
      },
    ]"
  >
    <input
      :id="id"
      type="checkbox"
      class="checkbox__input"
      :checked="active"
      :disabled="disabled"
    />
    <span class="checkbox__checkmark" />
    <span v-if="!disabled" class="checkbox__overlay" />
  </div>
</template>

<script>
//JSON-slugs-migrated
export default {
  name: 'HCheckbox',
  props: {
    active: Boolean,
    partlyChecked: Boolean,
    checkboxColor: String,
    gray: Boolean,
    disabled: Boolean,
    fancy: Boolean,
    thick: Boolean,
    id: String,
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/components/checkbox';
</style>
