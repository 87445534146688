import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Email, Route } from '@/types';
import { getOriginRoute, isTopPlan } from '@/utils/helpers/emailsHelper';

export const emailIsUpgradingGuard: NavigationGuard = async (to) => {
  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const domain = to.params.domain as string;

  const emailDetails = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.HOSTINGER,
    domain,
  );

  if (!emailDetails) {
    return { name: Route.Email.EMAILS };
  }

  const hmailOrder = store.getters['emails/getEmailOrderByResellerOrderId'](
    emailDetails.orderId,
  );

  if (hmailOrder.pendingUpgrade || isTopPlan(hmailOrder.plan)) {
    return getOriginRoute(to, domain);
  }
};
