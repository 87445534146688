export interface HDnsState {
  dnsProviderData: Array<DnsProviderDataModel>;
}

export interface DnsProviderDataModel {
  domain: string;
  data: DnsProviderDataDataModel | null;
}
export interface DnsProviderDataDataModel {
  name?: string;
  metadata?: {
    providerUrl?: string;
    instructionUrl?: string;
  };
  nameservers?: Array<string>;
  domainconnectDomainSettings?: DomainconnectDomainSettingsModel;
}

interface DomainconnectDomainSettingsModel {
  providerId: string;
  providerName: string;
  providerDisplayName: string;
  urlSyncUX: string;
  urlAsyncUX: string;
  urlAPI: string;
  width: string;
  height: string;
  urlControlPanel: string;
  nameServers: Array<string>;
}

export enum HDnsMutations {
  SET_PROVIDER_DATA = 'SET_PROVIDER_DATA',
}

export enum HDnsActions {
  FETCH_DNS_PROVIDER_DATA = 'fetchDnsProviderData',
}

export enum HDnsGetters {
  GET_DNS_PROVIDER_DATA_BY_DOMAIN = 'getDnsProviderDataByDomain',
}
