import { router } from '@/router';
import type { RedirectState } from '@/types';
import { RedirectMutations } from '@/types';

const state: RedirectState = {
  routeName: '',
  prevRouteName: '',
  params: {},
};

const mutations = {
  [RedirectMutations.SET_REDIRECT](
    state: RedirectState,
    params: { [key: string]: any },
  ) {
    state.routeName = String(router.currentRoute.value.name) || '';
    state.params = { ...state.params, ...params };
  },
  [RedirectMutations.UNSET_REDIRECT](state: RedirectState) {
    state.routeName = '';
    state.params = {};
  },
  [RedirectMutations.SET_PREV_ROUTE_NAME](
    state: RedirectState,
    prevRouteName: string,
  ) {
    state.prevRouteName = prevRouteName;
  },
};

export default {
  state,
  mutations,
};
