<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import WordPressInstallationButton from '@/components/CPanel/WordPress/WordPressInstallationButton.vue';
import WordPressRefreshInstallationText from '@/components/CPanel/WordPress/WordPressRefreshInstallationText.vue';
import WordPressScanInstallationButton from '@/components/CPanel/WordPress/WordPressScanInstallationButton.vue';
import { StoreEnum, Route } from '@/types';

const route = useRoute();
const store = useStore();

const installation = computed(
  () =>
    store.state.hosting[StoreEnum.ModuleNames.CPANEL_WORDPRESS].installation,
);

const hasInstallations = computed(
  (): boolean => installation.value.loaded && installation.value.data.length,
);

const isWordpress = computed(() => route.name === Route.CPanel.WORDPRESS);
</script>

<template>
  <WordPressRefreshInstallationText v-if="isWordpress && !hasInstallations" />
  <WordPressScanInstallationButton v-if="isWordpress && hasInstallations" />
  <WordPressInstallationButton v-if="isWordpress && hasInstallations" />
</template>
