export default {
  namespaced: true,
  state: {
    currentStep: '',
  },
  mutations: {
    SET_CURRENT_STEP(state, value) {
      state.currentStep = value;
    },
  },
};
