import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { reportRepo } from '@/repositories';
import type { GetReportTemplateResponse } from '@/types';

export const useReportTemplatesStore = defineStore(
  'reportTemplatesStore',
  () => {
    const list = ref<GetReportTemplateResponse[]>();
    const isLoadingList = ref(false);
    const isDeleting = ref(false);

    const totalListItems = computed(() => list.value?.length || 0);

    const hasTemplates = computed(() => !!list.value?.length);

    const fetchTemplatesList = async () => {
      isLoadingList.value = true;

      const [{ data }, error] = await reportRepo.getTemplates();

      if (error) {
        isLoadingList.value = false;

        return;
      }
      list.value = data;
      isLoadingList.value = false;
    };

    const deleteTemplate = async (targetId: number) => {
      isDeleting.value = true;
      const [_, error] = await reportRepo.deleteTemplate(targetId);

      isDeleting.value = false;
      if (error) {
        return false;
      }

      fetchTemplatesList();

      return true;
    };

    const getTemplateById = (targetId: number) =>
      list.value?.find(({ id }) => id === targetId);

    const getHasTemplateById = (targetId: number) =>
      !!getTemplateById(targetId);

    const getTemplateNameById = (targetId: number) => {
      const structure = getTemplateById(targetId);

      return structure?.name || '';
    };

    return {
      list,
      isLoadingList,
      hasTemplates,
      totalListItems,
      isDeleting,
      fetchTemplatesList,
      deleteTemplate,
      getTemplateById,
      getTemplateNameById,
      getHasTemplateById,
    };
  },
  {
    persist: { key: 'report-templates-store' },
  },
);
