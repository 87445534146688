import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  ssoUrl: `${process.env.VITE_API_SSO}/v1/seo`,
  restUrl: `${process.env.VITE_API_REST}/v2/seo`,

  async getDomains(params) {
    return await hAsync(http.get(`${this.ssoUrl}/setup/domains`, { params }));
  },
  async activateDomain(setupDetails) {
    return await hAsync(http.post(`${this.ssoUrl}/activate`, setupDetails));
  },

  async getSeoShow(params) {
    return await hAsync(http.get(this.restUrl, { params }));
  },

  async getSeoIndex(id) {
    return await hAsync(http.get(`${this.restUrl}/${id}`));
  },
};
