import type { ModalObject } from '@/types';

export default [
  {
    name: 'DeleteWebsiteModal',
    type: 'HModal',
    closingType: 'confirmation',
    steps: [
      {
        hideX: true,
      },
      {
        hideX: true,
      },
      { hideX: false },
    ],
  },
  {
    name: 'ChangeCmsFromBuilderModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'TemporaryDomainDisclaimerModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'ChangeWebsiteDomainModal',
    type: 'HModal',
    closingType: 'confirmation',
    steps: [
      {
        title: 'Change Website’s Domain',
        hideX: true,
      },
    ],
  },
  {
    name: 'WebsiteOwnershipTransferModal',
    type: 'HModal',
    closingType: 'confirmation',
    steps: [
      {
        hideX: true,
      },
      {
        hideX: true,
        goBackText: 'Transfer Ownership',
      },
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'OwnershipTransferSuccessModal',
    type: 'HModal',
    subtype: 'Website',
    closingType: 'confirmation',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'SaveWebsiteAsPresetModal',
    type: 'HModal',
    closingType: 'input',
    steps: [
      {
        hideX: true,
      },
    ],
  },
] as ModalObject[];
