<script setup lang="ts">
import type { ScreenMode, HColor } from '@/types';
import { HIcon as HIconName, SCREEN_MODE } from '@/types';

type Props = {
  screenSize: ScreenMode;
  iconSize?: number;
  activeIconColor?: HColor;
  noMargin?: boolean;
};

type Emits = {
  'on-screen-mode-change': [payload: ScreenMode];
};

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  activeIconColor: 'light',
  iconSize: 18,
});

const toggleSize = (screenSize: ScreenMode) => {
  emit('on-screen-mode-change', screenSize);
};

const getScreenSizeIconColor = (screen: ScreenMode) =>
  props.screenSize === screen ? props.activeIconColor : 'gray';
</script>

<template>
  <ul class="preview__size-list p-0" :class="{ 'm-0': noMargin }">
    <li
      class="preview__size-list-item preview__size-list-item--desktop"
      :class="{
        'preview__size-list-item--active': screenSize === SCREEN_MODE.DESKTOP,
      }"
      @click="toggleSize(SCREEN_MODE.DESKTOP)"
    >
      <HpIcon
        :icon="HIconName.ICON_LAPTOP_LIGHT"
        :color="getScreenSizeIconColor(SCREEN_MODE.DESKTOP)"
        :height="iconSize"
        :width="iconSize"
      />
    </li>
    <li
      class="preview__size-list-item preview__size-list-item--tablet"
      :class="{
        'preview__size-list-item--active': screenSize === SCREEN_MODE.TABLET,
      }"
      @click="toggleSize(SCREEN_MODE.TABLET)"
    >
      <HpIcon
        :icon="HIconName.ICON_TABLET_MAC"
        :color="getScreenSizeIconColor(SCREEN_MODE.TABLET)"
        :height="iconSize"
        :width="iconSize"
      />
    </li>
    <li
      class="preview__size-list-item preview__size-list-item--mobile"
      :class="{
        'preview__size-list-item--active': screenSize === SCREEN_MODE.MOBILE,
      }"
      @click="toggleSize(SCREEN_MODE.MOBILE)"
    >
      <HpIcon
        :icon="HIconName.ICON_SMARTPHONE"
        :color="getScreenSizeIconColor(SCREEN_MODE.MOBILE)"
        :height="iconSize"
        :width="iconSize"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
@import '../sass/pages/preview/preview-size';
</style>
