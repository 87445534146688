import type {
  ReportScheduledListItem,
  ScheduleListResponse,
  ScheduleParamsWithEmail,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_WH_API}/api/hapi/v1`,

  async getScheduleById({ id }: { id: number }) {
    return await hAsync<ReportScheduledListItem>(
      http.get(`${this.url}/website-report-schedules/${id}`, {
        plain: true,
      }),
    );
  },

  async getScheduledReportsList(params: { page: number; perPage: number }) {
    return await hAsync<ScheduleListResponse>(
      http.get(`${this.url}/website-report-schedules`, {
        plain: true,
        params,
      }),
    );
  },

  async deleteSchedule({ id }: { id: number }) {
    return await hAsync<void>(
      http.delete(`${this.url}/website-report-schedules/${id}`),
    );
  },

  async editSchedule({ id, data }: { id: number; data: any }) {
    return await hAsync(
      http.put(`${this.url}/website-report-schedules/${id}`, data, {
        plain: true,
      }),
    );
  },

  async createScheduleReportFromGeneratedReport(
    id: string,
    data: ScheduleParamsWithEmail,
  ) {
    return await hAsync<ReportScheduledListItem>(
      http.post(`${this.url}/website-reports/${id}/schedule`, data, {
        plain: true,
      }),
    );
  },

  async createTemplateFromGeneratedReport(id: string, data: { name: string }) {
    return await hAsync<void>(
      http.post(`${this.url}/website-reports/${id}/templates`, data, {
        plain: true,
      }),
    );
  },

  async createTemplateFromScheduledReport(id: string, data: { name: string }) {
    return await hAsync<void>(
      http.post(`${this.url}/website-report-schedules/${id}/templates`, data, {
        plain: true,
      }),
    );
  },
};
