export default {
  props: {
    to: {
      type: [Object, String, Boolean],
      default: null,
    },
    target: {
      type: [String, Boolean],
      default: null,
    },
    id: String,
    formGeneratorId: String,
    sm: Boolean,
    md: { type: Boolean, default: true },
    lg: Boolean,
    loading: Boolean,
    noTrans: Boolean,
    disabled: Boolean,
    outline: Boolean,
    danger: Boolean,
    gray: Boolean,
    success: Boolean,
    info: Boolean,
    warning: Boolean,
    dark: Boolean,
    light: Boolean,
    primary: Boolean,
    default: Boolean,
    secondary: Boolean,
    icon: String,
    hpIcon: String,
    active: Boolean,
    noMargin: Boolean,
    noPadding: Boolean,
    noBorder: Boolean,
    block: Boolean,
    text: Boolean,
    nowrap: Boolean,
    underlineHover: Boolean,
    noDefaultHoverEffect: Boolean,
    noStateStyling: Boolean,
    centered: Boolean,
    iconRight: Boolean,
  },
};
