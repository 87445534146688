import type { DnsRecord } from '@/types';

export const dnsRecordsBuilder: DnsRecord[] = [
  {
    value: 'connect.hostinger.com',
    type: 'ALIAS',
    host: '@',
    ttl: 3600,
  },
  {
    value: 'connect.hostinger.com',
    type: 'CNAME',
    host: 'www',
    ttl: 3600,
  },
];

export const dnsRecordsBuilderNs = [
  {
    value: 'ns1.dns-parking.com',
    type: 'NS',
    host: '@',
    ttl: 3600,
  },
  {
    value: 'ns2.dns-parking.com',
    type: 'NS',
    host: '@',
    ttl: 3600,
  },
];
