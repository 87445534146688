interface Message {
  error: string;
  success: string;
}
const messages: Record<string, Message> = {
  ct_create_backup: {
    error: 'Failed to create a backup',
    success: 'Backup successfully created',
  },
  ct_delete_backup: {
    error: 'Failed to delete a backup',
    success: 'Backup successfully deleted',
  },
  ct_restart: {
    error: 'Failed to reboot VPS',
    success: 'VPS successfully rebooted',
  },
  ct_recreate: {
    error: 'Failed to create VPS',
    success: 'VPS created successfully',
  },
  ct_start: {
    error: 'Failed to start VPS',
    success: 'VPS started successfully',
  },
  ct_set_rootpasswd: {
    error: 'Failed to change root password',
    success: 'Root password has been changed',
  },
  ct_set_nameserver: {
    error: 'Failed to set DNS resolvers',
    success: 'DNS resolvers set successfully',
  },
  ct_create: {
    error: 'Failed to create VPS',
    success: 'VPS created successfully',
  },
  ct_stop: {
    error: 'Failed to stop VPS',
    success: 'VPS stopped successfully',
  },
  ct_destroy: {
    error: 'Failed to delete VPS',
    success: 'VPS has been deleted',
  },
  ct_suspend: {
    error: 'Failed to suspend VPS',
    success: 'VPS has been suspended',
  },
  ct_reset_fw: {
    error: 'Failed to reset firewall',
    success: 'Firewall configuration was reset to default',
  },
  ct_set_hostname: {
    error: 'Failed to change hostname',
    success: 'Hostname successfully changed',
  },
  ct_create_snapshot: {
    error: 'Failed to create a snapshot',
    success: 'Snapshot created successfully',
  },
  collector_set_rate_limit: {
    error: 'Failed to set up network speed',
    success: 'Network speed activated successfully',
  },
  ct_set_cpu: {
    error: 'Failed to set up CPU',
    success: 'CPU activated successfully',
  },
  ct_unsuspend: {
    error: 'Failed to activate VPS',
    success: 'VPS has been activated',
  },
  collector_set_smtp_rate_limit: {
    error: 'Failed to set up SMTP limit',
    success: 'SMTP limit set successfully',
  },
  ct_transfer: {
    error: 'Failed to transfer VPS',
    success: 'The server transfer was successful',
  },
  ct_install_cpanel: {
    error: 'Failed to install cPanel',
    success: 'cPanel installed successfully',
  },
  ct_add_ip_addr: {
    error: 'Failed to add IP address',
    success: 'IP address added successfully',
  },
  ct_restore_backup: {
    error: 'Backup restoration failed. Try again later or a different backup',
    success: 'Backup restored successfully',
  },
  ct_enable_disk_quota: {
    error: 'Failed to enable Disk Quota',
    success: 'Disk Quota enabled',
  },
  ct_enable_tun_tap_adapter: {
    error: 'Failed to enable TUN/TAP Adapter',
    success: 'TUN/TAP Adapter enabled',
  },
  ct_disable_tun_tap_adapter: {
    error: 'Failed to disable TUN/TAP Adapter',
    success: 'TUN/TAP Adapter disabled',
  },
  ssh_add_pubkey: {
    error: 'Failed to add Public Key',
    success: 'Public Key added',
  },
  ct_restore_snapshot: {
    error: 'Failed to restore a snapshot',
    success: 'Snapshot restored successfully',
  },
  ct_install_multicraft: {
    error: 'Failed to install Multicraft Panel',
    success: 'Multicraft Panel installed successfully',
  },
  ct_del_all_ip_addr: {
    error: 'Failed to delete IP address',
    success: 'IP address deleted',
  },
  ct_vestacp_update_ip: {
    error: 'Failed to install Vesta Control Panel',
    success: 'Vesta Control panel installed successfully',
  },
  ct_set_diskspace: {
    error: 'Failed to set Disk Space',
    success: 'Disk space set successfully',
  },
  ct_set_vswap_memory: {
    error: 'Failed to set RAM',
    success: 'RAM set successfully',
  },
  ct_connectivity_test: {
    error: 'Failed connectivity test',
    success: 'Connectivity test has been successful',
  },
  ssh_remove_pubkey: {
    error: 'Failed to remove Public Key',
    success: 'Public Key removed',
  },
  ct_recovery: {
    error: 'Failed to enter Emergency mode',
    success: 'Emergency mode enabled',
  },
  ct_reset_ssh: {
    error: 'Failed to reset SSH keys',
    success: 'SSH configuration was reset to default',
  },
  ct_install_vnc: {
    error: 'Failed to install VNC Panel',
    success: 'VNC Panel installed successfully',
  },
  ct_del_ip_addr: {
    error: 'Failed to delete IP address',
    success: 'IP address has been deleted',
  },
  ct_disable_disk_quota: {
    error: 'Failed to disable Disk Quota',
    success: 'Disk Quota disabled',
  },
  ct_install_cpanel_niagahoster: {
    error: 'Failed to install cPanel',
    success: 'cPanel installed successfully',
  },
  ct_install_cyberpanel: {
    error: 'Failed to install Cyber Panel',
    success: 'Cyber Panel installed successfully',
  },
  ct_disk_cleanup: {
    error: 'Failed to delete unnecessary logs of your VPS',
    success: 'Successful delete unnecessary logs of your VPS',
  },
  ct_enable_nfsd_support: {
    error: 'Failed to enable NFS support',
    success: 'NFS support enabled',
  },
  ct_disable_nfsd_support: {
    error: 'Failed to disable NFS support',
    success: 'NFS support disabled',
  },
};
export default messages;
