<template>
  <ul class="h-nav">
    <li class="h-nav__item">
      <RouterLink
        :id="`hpanel_tracking-asidemenu-submenu-link-dashboard`"
        class="h-nav__link"
        :class="{ 'h-nav__link-active': isDashboardActive }"
        :to="dashboardRoute"
        @click="dashboardClick"
      >
        <HpIcon
          :primary="isDashboardActive"
          gray
          static-view-box
          class="h-mr-8 h-ml-24"
          icon="IcDashboardAside"
        />
        <span v-trans class="h-nav__section">Dashboard</span>
      </RouterLink>
    </li>
    <AsideMenuSkeletonLoader v-if="isAsideMenuLoading" />
    <SubmenuElement
      v-for="(menuElement, index) in menu"
      ref="submenu"
      :key="domainKey + index"
      :item="menuElement"
      :search-in-progress="searchInProgress"
      @submenu-open="submenuOpened"
      @after-navigate="closeSidebar"
    />
  </ul>
</template>

<script>
import AsideMenuSkeletonLoader from '@/components/Loaders/SkeletonCompositions/AsideMenuSkeletonLoader.vue';
import SubmenuElement from '@/components/SideMenu/SubmenuElement.vue';
import { useGlobals } from '@/composables';
import { useFrontendSettingsStore } from '@/stores';
import { Route, AmplitudeEvent, AmplitudeLocation } from '@/types';

export default {
  name: 'AsideMenu',
  components: { SubmenuElement, AsideMenuSkeletonLoader },
  props: {
    menu: Array,
    searchInProgress: Boolean,
  },
  setup() {
    const frontendSettingsStore = useFrontendSettingsStore();
    const { amplitudeV2 } = useGlobals();

    return {
      frontendSettingsStore,
      amplitudeV2,
    };
  },
  computed: {
    isAsideMenuLoading() {
      return !this.menu.length && !this.searchInProgress;
    },
    isDashboardActive() {
      return this.$route?.name === Route.Websites.WEBSITE_DASHBOARD;
    },
    dashboardRoute() {
      return {
        name: Route.Websites.WEBSITE_DASHBOARD,
        params: this.$route.params,
      };
    },
    domainKey() {
      return this.$route?.params?.domain;
    },
  },
  methods: {
    dashboardClick() {
      if (!this.isDashboardActive) {
        this.amplitudeV2(AmplitudeEvent.Hosting.DASHBOARD_ENTER, {
          location: AmplitudeLocation.Base.SIDE_MENU,
        });
      }
      this.closeSidebar();
    },
    closeSidebar() {
      this.frontendSettingsStore.toggleAsideLeftShow(false);
    },
    submenuOpened(uid) {
      // Close other Submenus
      this.$refs?.submenu?.forEach((item) => {
        if (item._uid !== uid) item.close();
      });
    },
    openSubmenuElements() {
      this.$refs?.submenu?.forEach((item) => item.open());
    },
    closeSubmenuElements() {
      this.$refs?.submenu?.forEach((item) => item.close());
    },
  },
  watch: {
    searchInProgress(val) {
      if (val) {
        this.openSubmenuElements();
      } else {
        this.closeSubmenuElements();
      }
    },
    isDashboardActive(val) {
      if (val) {
        this.closeSubmenuElements();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h-nav {
  margin-left: -24px;

  &__section {
    font-weight: 700;
    color: var(--gray) !important;
  }

  &__link {
    padding: 16px 24px;
    border-radius: 0px 32px 32px 0px;

    &:hover {
      background-color: var(--primary-dark-hover);
    }
  }

  &__link-active {
    background-color: var(--primary-hover);

    span {
      color: var(--primary) !important;
    }

    &:hover {
      background-color: var(--primary-hover);
    }
  }

  &__item {
    padding: 0;
  }

  &__toggle {
    margin: 0;
  }
}
</style>
