<template>
  <div class="ns-flow__loader">
    <HSkeletonLoader rounded :height="200" :width="350" class="h-mb-16" />
    <HSkeletonLoader :height="24" :width="300" class="h-mb-16" />
    <HSkeletonLoader :height="12" :width="500" class="h-mb-16" />
    <HSkeletonLoader :height="12" :width="400" class="h-mb-16" />
    <HSkeletonLoader :height="42" :width="120" class="h-mb-16" />
  </div>
</template>

<script>
import HSkeletonLoader from '@/components/Loaders/HSkeletonLoader.vue';

export default {
  components: {
    HSkeletonLoader,
  },
};
</script>

<style lang="scss" scoped>
.ns-flow__loader {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>
