import type { BrandConfiguration } from '@/data/brand/brand';
import { BRAND_CONFIGURATION } from '@/data/brand/brand';
import { mapKeyValue } from '@/utils/helpers/helpers';

export const getBrandConfigById = (
  brandId?: string | number,
): BrandConfiguration => {
  if (!brandId) {
    return mapKeyValue(BRAND_CONFIGURATION, 'default');
  }

  return mapKeyValue(BRAND_CONFIGURATION, brandId);
};
