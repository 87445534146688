export const getThreeDsVerifyErrorMsg = [
  'Payment verification failed. Please get in touch with support.',
  'The services will be activated in about an hour. If otherwise, please get in touch with support.',
  'Payment token was not found',
];

export const getThreeDsVerifyErrors = (errorCode: number) => {
  let errorMessage = getThreeDsVerifyErrorMsg[0]; // Default value

  switch (errorCode) {
    case 2047:
      errorMessage = getThreeDsVerifyErrorMsg[0];
      break;
    case 2048:
      errorMessage = getThreeDsVerifyErrorMsg[1];
      break;
    case 2049:
      errorMessage = getThreeDsVerifyErrorMsg[2];
      break;
  }

  return errorMessage;
};
