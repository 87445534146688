<script setup lang="ts">
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';

import HNotificationCard from '@/components/HNotificationCard.vue';
import ApplicationSetup from '@/components/Hosting/Website/Autoinstaller/ApplicationSetup.vue';
import WebsiteCredentials from '@/components/Hosting/Website/Autoinstaller/WebsiteCredentials.vue';
import CircleLoader from '@/components/Loaders/CircleLoader.vue';
import {
  useGlobals,
  useCpanel,
  useDomain,
  useModal,
  useCpanelWordpress,
} from '@/composables';
import { applicationDetails } from '@/data/cpanel/cPanelWordpress';
import { capiRepo } from '@/repositories';
import type { CPanelWordPressInstallationForm, ModalEmits } from '@/types';
import { Route } from '@/types';
import { toUnicode, toASCII } from '@/utils/helpers';

interface Emits extends ModalEmits {}
const emits = defineEmits<Emits>();

const { close } = useModal(emits);
const { t, toastr } = useGlobals();
const { serviceId } = useCpanel();
const { wordpressInstallations, fetchWordpressInstallations } =
  useCpanelWordpress();

const router = useRouter();
const installerInProgress = ref(false);
const currentStep = ref(0);
const websiteCredentials = ref(null);
const applicationSetup = ref(null);
const errorMessage = ref();
const domainList = ref();

const form: CPanelWordPressInstallationForm = reactive({});
const savedForm: CPanelWordPressInstallationForm = {};

const { domain } = useDomain();

const getSoftaculousDomainList = async () => {
  const [{ data }, err] = await capiRepo.cpanelSoftaculousDomains(
    serviceId.value,
    true,
  );

  if (!err) {
    // @ts-ignore
    return data.map((domain: string) => ({
      label: toUnicode(domain),
      value: domain,
    }));
  }

  return [];
};

const domainWithDirectory = computed(() =>
  form.websiteCredentials?.directory
    ? `${toASCII(domain.value)}/${form.websiteCredentials?.directory}`
    : domain.value,
);

const displayDomain = computed(() => toUnicode(domainWithDirectory.value));

const stepsData = computed(() => {
  const defaultTitle = `${t('Install')} ${
    applicationDetails.name
  } - ${toUnicode(domain.value)}`;
  const defaultDescription = t(applicationDetails.description);

  return [
    {
      title: defaultTitle,
      description: defaultDescription,
      buttonId: 'hpanel_tracking-cpanel-wordpress-website-credentials-next',
      buttonTitle: 'Next',
      action: () => nextStep(),
    },
    {
      title: defaultTitle,
      description: defaultDescription,
      buttonId: 'hpanel_tracking-cpanel-wordpress-application-setup-next',
      buttonTitle: 'Install',
      action: () => install(),
    },
    {
      title: t('Overwrite current files?'),
      description: '',
      buttonId: 'hpanel_tracking-cpanel-wordpress-overwrite-next',
      buttonTitle: 'Overwrite',
      action: () => install(),
    },
  ];
});

const hasExistingInstallation = () => {
  const softaculouseOverwrite = document.getElementById('overwrite_existing');
  if (softaculouseOverwrite) {
    //@ts-ignore
    return softaculouseOverwrite.checked;
  }

  const currentDirectory = form.websiteCredentials?.directory;
  const domainValue = toASCII(domain.value);

  return !!wordpressInstallations.value.data.find((application: any) => {
    const applicationDirectory = application.softurl.split('/').pop();

    return (
      (currentDirectory === '' && applicationDirectory === domainValue) ||
      (application.softdomain === domainValue &&
        currentDirectory === applicationDirectory)
    );
  });
};

const backStep = () => {
  currentStep.value--;
};

const nextStep = async () => {
  const isValid =
    currentStep.value === 0
      ? // @ts-ignore
        await websiteCredentials.value.validate()
      : true;
  if (isValid) {
    currentStep.value++;
  }
};

const onChange = (values: object) => {
  Object.assign(form, { ...form, ...values });
};

const closeModal = () => {
  currentStep.value = 0;
  close();
};

const install = async () => {
  const existingInstallation = hasExistingInstallation();
  if (currentStep.value < 2 && existingInstallation) {
    nextStep();

    return;
  }

  errorMessage.value = '';
  installerInProgress.value = true;
  currentStep.value = 0;

  const { websiteCredentials, applicationSetup } = form;

  const request = {
    softId: applicationDetails.softid,
    submit: 1,
    domain: toASCII(form.websiteCredentials?.domain || domain.value),
    directory: websiteCredentials?.directory,
    dbPrefix: 'wp_',
    adminUsername: websiteCredentials?.login,
    adminEmail: websiteCredentials?.email,
    adminPass: websiteCredentials?.passwd,
    language: applicationSetup?.language || 'en',
    siteName: websiteCredentials?.sitetitle,
    siteDesc: 'Site description',
    overwriteExisting: Number(existingInstallation),
    protocol: websiteCredentials?.protocol,
    euAutoUpgrade: 0,
  };

  if (applicationSetup?.autoup && applicationSetup?.autoup > 0) {
    request.euAutoUpgrade = 1;
  }

  const [_, error] = await capiRepo.installSoftaculousScript(
    serviceId.value,
    // @ts-ignore
    request,
  );

  if (error) {
    savedForm.websiteCredentials = websiteCredentials;
    savedForm.applicationSetup = applicationSetup;
    // @ts-ignore
    errorMessage.value = error.error.message;
    installerInProgress.value = false;

    return;
  }
  installerInProgress.value = false;
  closeModal();

  toastr.s(t(`${websiteCredentials?.sitetitle} successfully installed`));
  await fetchWordpressInstallations();

  return router.push({
    name: Route.CPanel.WORDPRESS,
    params: {
      domain: domain.value,
    },
  });
};

(async () => {
  domainList.value = await getSoftaculousDomainList();
})();
</script>

<template>
  <div>
    <div v-if="!installerInProgress && !!domainList">
      <div v-show="currentStep === 1" class="action h-mb-16" @click="backStep">
        <!-- @vue-skip -->
        <HpIcon
          gray
          icon="ic-arrow-left"
          view-box="0 0 28 28"
          class="align-middle"
        />
        <span class="devider" />
        <span v-trans class="step-title">Enter administrator credentials</span>
      </div>
      <h2 v-trans>{{ stepsData[currentStep].title }}</h2>

      <Trans
        v-if="currentStep === 2"
        tag="p"
        :translate-params="{
          domain: domainWithDirectory,
          displayDomain,
        }"
      >
        To install WordPress, you would need to overwrite current files.
        Overwriting will delete your previous installation on
        <a href="{domain}" rel="noopener" target="_blank">{displayDomain}</a>
        .
      </Trans>
      <Trans v-else tag="p">
        {{ stepsData[currentStep].description }}
      </Trans>
      <div>
        <HNotificationCard
          v-if="errorMessage"
          icon="icon-error"
          icon-theme="danger"
          danger-light
        >
          {{ errorMessage }}
        </HNotificationCard>
        <WebsiteCredentials
          v-show="currentStep === 0"
          ref="websiteCredentials"
          :application-details="applicationDetails"
          :domain-list="domainList"
          :hide-database-selection="true"
          :saved-form="savedForm.websiteCredentials"
          @update:model-value="onChange"
        />
        <ApplicationSetup
          v-show="currentStep === 1"
          ref="applicationSetup"
          :application-details="applicationDetails"
          :no-version="true"
          :no-requirement="true"
          :version-list="[]"
          :saved-form="savedForm.applicationSetup"
          @on-change="onChange"
        />
      </div>
      <div class="text-right h-mt-24">
        <HButtonV1
          id="hpanel_tracking-website-autoinstaller-new-application-close"
          primary
          text
          class="h-mr-16 text-primary"
          @click="closeModal"
        >
          Close
        </HButtonV1>
        <HButtonV1
          :id="stepsData[currentStep].buttonId"
          primary
          @click="stepsData[currentStep].action"
        >
          {{ stepsData[currentStep].buttonTitle }}
        </HButtonV1>
      </div>
    </div>
    <div v-else class="installation-progress">
      <div class="installation-progress__row">
        <CircleLoader centered />
      </div>
      <div class="installation-progress__row">
        <Trans tag="h1" class="installation-progress__row-title">
          Processing
        </Trans>
        <p v-trans>WordPress installation is on progress</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.installation-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__row {
    width: 100%;
    text-align: center;
    margin-top: 8px;

    &-title {
      margin-bottom: 0;
    }
  }
}
</style>
