<template>
  <RouterLink :to="to">
    <slot />
  </RouterLink>
</template>

<script>
export default {
  name: 'HButtonRouterLink',
  props: {
    to: Object,
  },
};
</script>
