export enum Header {
  CORRELATION_ID = 'X-CORRELATION-ID',
  DOMAIN = 'X-Hpanel-Domain',
  IS_REPLAY = 'is-replay',
  IDEMPOTENCY_KEY = 'idempotency-Key',
  AUTHORIZATION = 'Authorization',
  USERNAME = 'X-Hpanel-Username',
  ORDER_ID = 'X-Hpanel-Order-ID',
  CLIENT_ID = 'X-Hpanel-Client-ID',
}

export type PartialHeaders = Partial<Record<Header, string>>;
