import cookies from 'js-cookie';

import { hBillingRepo } from '@/repositories';
import { useProfileStore, useResourcesStore } from '@/stores';
import { Cookie } from '@/types';
import { timeout } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import hLocalStorage from '@/utils/services/localStorageService';

const GTM_URL = 'https://www.googletagmanager.com/gtm.js';
const GTAG_TARGET_ID = process.env?.VITE_GTAG_TARGET_ID || '';
const GTM_SESSION_UPDATED_AT_KEY = 'gtm-session-updated-at';

export const CONSENT_COOKIE_VALUES = {
  DENIED: 'denied',
  STATISTICS: 'statistics',
  ADVERTISING: 'advertising',
  GRANTED: 'granted',
  BLOCKED: 'blocked',
} as const;

// This gtag code has to be written this way, not with an
// arrow function because any other way it does not work
// @ts-ignore
function gtag(...args:any[]) { // eslint-disable-line
  window.dataLayer.push(arguments); // eslint-disable-line
}

// Function to get session_id as a Promise
export const getGtmSessionId = (): Promise<string | null> =>
  new Promise((resolve) => {
    const cookieConsent = cookies.get(Cookie.COOKIE_CONSENT) || '';
    if (!cookieConsent.includes(CONSENT_COOKIE_VALUES.STATISTICS)) {
      return resolve(null);
    }

    const updatedAt = hLocalStorage.getValue(GTM_SESSION_UPDATED_AT_KEY);

    // `isSessionExpired` ensures a new session ID is fetched if more than 30 minutes have passed since the last update.
    // This prevents tracking inactive session ID to be sent to billing EPs
    const isSessionExpired =
      updatedAt &&
      Math.floor((Date.now() - Number(updatedAt)) / 1000 / 60) > 30;

    if (isSessionExpired) {
      gtag('get', GTAG_TARGET_ID, 'session_id', (sessionId: string) => {
        cookies.set(Cookie.SESSION_ID, sessionId);
        hLocalStorage.setValue(GTM_SESSION_UPDATED_AT_KEY, Date.now());

        return resolve(sessionId);
      });
    }

    return resolve(cookies.get(Cookie.SESSION_ID) ?? null);
  });

window.dataLayer = window.dataLayer ?? [];

export const loadScript = (onReady: Function): HTMLScriptElement => {
  const doc: Document = document;
  const script: HTMLScriptElement = doc.createElement('script');

  const scriptLoadListener: (event: Event) => void = () => {
    onReady?.();
    script.removeEventListener('load', scriptLoadListener);
  };

  script.addEventListener('load', scriptLoadListener);

  window.dataLayer?.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });

  script.async = true;
  script.defer = true;
  const queryString: URLSearchParams = new URLSearchParams({
    id: process.env.VITE_GTM_ID || '',
  });

  script.src = `${GTM_URL}?${queryString}`;

  const parentElement: HTMLElement = doc.body;

  if (typeof parentElement?.appendChild !== 'function') {
    throw new Error('parentElement must be a DOM element');
  }

  parentElement.appendChild(script);

  return script;
};

export const initGTM = () =>
  new Promise<void>((resolve, reject) => {
    try {
      if (!process.env.VITE_GTM_ID) {
        resolve();

        return;
      }

      const cookieConsent = cookies.get(Cookie.COOKIE_CONSENT) || '';

      if (!cookieConsent) {
        cookies.set(Cookie.COOKIE_CONSENT, CONSENT_COOKIE_VALUES.DENIED);
      }

      const consentIncludesStatistics = cookieConsent.includes(
        CONSENT_COOKIE_VALUES.STATISTICS,
      );

      const isAdvertisingGranted = cookieConsent.includes(
        CONSENT_COOKIE_VALUES.ADVERTISING,
      );
      const consent = {
        ad_storage: isAdvertisingGranted
          ? CONSENT_COOKIE_VALUES.GRANTED
          : CONSENT_COOKIE_VALUES.DENIED,
        ad_user_data: isAdvertisingGranted
          ? CONSENT_COOKIE_VALUES.GRANTED
          : CONSENT_COOKIE_VALUES.DENIED,
        ad_personalization: isAdvertisingGranted
          ? CONSENT_COOKIE_VALUES.GRANTED
          : CONSENT_COOKIE_VALUES.DENIED,
        analytics_storage: consentIncludesStatistics
          ? CONSENT_COOKIE_VALUES.GRANTED
          : CONSENT_COOKIE_VALUES.DENIED,
      };
      const consentInitData = {
        consent,
        _clear: true,
      };

      gtag('consent', 'default', consent);

      setDataLayer({
        event: 'consent_init',
        ...consentInitData,
      });

      if (consentIncludesStatistics) {
        gtag('js', new Date());
        gtag('config', GTAG_TARGET_ID);
        gtag('get', GTAG_TARGET_ID, 'session_id', (sessionId: string) => {
          hLocalStorage.setValue(GTM_SESSION_UPDATED_AT_KEY, Date.now());
          cookies.set(Cookie.SESSION_ID, sessionId);
        });
        gtag('get', GTAG_TARGET_ID, 'client_id', (clientId: string) =>
          cookies.set(Cookie.CLIENT_ID, clientId),
        );
      }

      loadScript(() => resolve());
    } catch (error) {
      reject(error);
    }
  });

export const initAnalytics = async () => {
  const profileStore = useProfileStore();
  const resourcesStore = useResourcesStore();

  try {
    await initGTM();

    if (profileStore.isStaff || process.env.NODE_ENV === 'development') {
      return;
    }

    const gtm = {
      isNewUser: !!profileStore.account?.hasSingleLoginHistoryRecord,
    };
    const brand = profileStore.account?.brand.domain || '';
    const oauth = profileStore.linkedSocialLogin;
    const email = profileStore.contact?.email || '';
    const address1 = profileStore.contact?.address || '';
    const city = profileStore.contact?.city || '';
    const state = profileStore.contact?.state || '';
    const country = profileStore.contact?.countryCode || '';

    const gtmData = {
      ...gtm,
      isUser: resourcesStore.hasActiveOrder,
    };

    await setSiteData({
      gtm: gtmData,
      brand,
      oauth,
      email,
      address1,
      city,
      state,
      country,
    });

    const [{ data }, err] = await hBillingRepo.getClientAnalytics();

    if (err || !data?.length) return;

    for (const { dataLayer, id } of data) {
      setDataLayer(dataLayer);

      if (!id) continue;

      await hBillingRepo.acknowledgeClientAnalytics(id);
    }
  } catch (error) {
    errorLogger.logError(error as Error);
  }
};

export const setDataLayer = (event: any) => {
  try {
    (window.dataLayer || []).push(event);
  } catch (e) {}
};

const encodeSHA256 = async (input: string) => {
  const utf8 = new TextEncoder().encode(input);

  const hashBuffer = await window.crypto.subtle.digest('SHA-256', utf8);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
};

export const setSiteData = async ({
  gtm = {},
  brand,
  oauth,
  email,
  address1,
  city,
  state,
  country,
}: {
  gtm?: Record<string, string | number | boolean>;
  brand: string;
  oauth: string;
  email: string;
  address1?: string;
  city?: string;
  state?: string;
  country?: string;
}) => {
  await timeout(1000);

  try {
    const domain = brand.replace(/^www\./i, '');

    const data = {
      hostname: brand ?? 'hostinger.*',
      referrer: !!oauth ? `https://www.${domain}` : document.referrer,
      sha256_email_address: await encodeSHA256(email),
      'address.street': address1,
      'address.city': city,
      'address.region': state,
      'address.country': country,
    } as any;

    for (const key in gtm) {
      if (!gtm.hasOwnProperty(key)) continue;

      data[key] = gtm[key];
    }

    setDataLayer({
      event: 'contentReady',
      ...data,
    });
  } catch (e) {}
};

export const sendGoogleAnalyticsEvent = (eventName: string, data: Object) => {
  setDataLayer({
    event: eventName,
    ...data,
  });
};
