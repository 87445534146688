import currencyCommon from '../currencyCommon';

import { useProfileStore } from '@/stores';
import { minorUnitCurrencyData } from '@/utils/services/chargebeeCurrencyService';

const PERIOD_UNIT_YEAR = 'year';

const currencyServiceObject = {
  isStart() {
    const profileStore = useProfileStore();
    const reg = new RegExp('({price})$');

    return reg.test(profileStore.currency?.format);
  },
  symbol() {
    const profileStore = useProfileStore();

    return profileStore.currency?.format?.replace('{price}', '');
  },
  code() {
    const symbol = this.symbol();
    const currency = Object.values(currencyCommon).find(
      (currency) => currency.symbol === symbol,
    );

    return currency ? currency.code : '';
  },
  mainPrice(price) {
    let formattedPrice = Math.floor(parseFloat(price)).toString();
    const profileStore = useProfileStore();

    if (formattedPrice.length > 3) {
      const position = formattedPrice.length - 3;
      const thousandsSep = profileStore.currency?.thousandsSep;
      formattedPrice =
        formattedPrice.substring(0, position) +
        thousandsSep +
        formattedPrice.substring(position);
    }

    return formattedPrice;
  },
  centsPrice(price) {
    const stringifiedPrice = String(price);
    const profileStore = useProfileStore();

    if (
      !(stringifiedPrice.indexOf('.') + 1) ||
      !profileStore.currency?.decimalCount
    ) {
      return null;
    }

    return stringifiedPrice.substr(stringifiedPrice.indexOf('.') + 1);
  },
  formatByCurrency(
    price,
    currency,
    { fixedCurrency, monthly, isChargebeePrice } = {},
  ) {
    let tempPrice = price;

    const { price: chargebeePrice, precision: chargebeePricePrecision } =
      isChargebeePrice
        ? minorUnitCurrencyData({
            minorCurrencyUnit: tempPrice,
            currencyCode: fixedCurrency,
          })
        : {};

    if (monthly) tempPrice = tempPrice / 12;

    const decimalPoint = currency?.decimalPoint;
    const precision = isChargebeePrice
      ? chargebeePricePrecision
      : currency?.decimalCount;

    tempPrice = isChargebeePrice ? chargebeePrice : tempPrice;
    tempPrice = this.precision(tempPrice, precision);

    if (tempPrice === 'NaN') tempPrice = '_.__';

    if (decimalPoint) {
      tempPrice = `${tempPrice}`?.replace(/\./g, decimalPoint);
    }

    const thousandsSep = currency?.thousandsSep;
    const inThousands =
      thousandsSep && tempPrice.length > precision + (precision ? 4 : 3);

    if (inThousands) {
      const position = tempPrice.length - precision - (precision ? 4 : 3);
      tempPrice =
        tempPrice.substring(0, position) +
        thousandsSep +
        tempPrice.substring(position);
    }
    const inMillions =
      inThousands &&
      thousandsSep &&
      tempPrice.length > precision + (precision ? 8 : 7);
    if (inMillions) {
      const position = tempPrice.length - precision - (precision ? 8 : 7);

      tempPrice =
        tempPrice.substring(0, position) +
        thousandsSep +
        tempPrice.substring(position);
    }

    if (!fixedCurrency) {
      return currency.format?.replace('{price}', tempPrice);
    }

    return `${fixedCurrency}${tempPrice}`;
  },
  format(price, options = {}) {
    const profileStore = useProfileStore();

    return this.formatByCurrency(price, profileStore?.currency, options);
  },
  precision(price, precision = 2, returnNumber) {
    const result = (Math.round(parseFloat(price) * 100) / 100).toFixed(
      precision,
    );

    return returnNumber ? Number(result) : result;
  },
  monthlyPrice(price, period) {
    const periodInMonths =
      period?.periodUnit === PERIOD_UNIT_YEAR
        ? Number(period.period) * 12
        : period?.period;

    return price / (periodInMonths || 1);
  },
};

export default currencyServiceObject;
