<script setup lang="ts">
import LanguageList from '@/components/Language/LanguageList.vue';

type Emits = {
  close: [];
};

const emits = defineEmits<Emits>();
</script>

<template>
  <LanguageList @close="emits('close')" />
</template>
