import type { IHDomainResource } from '@hostinger/hdomains-status';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  useAccessList,
  useImpersonation,
  useGlobals,
  useManagedDomains,
} from '@/composables';
import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useDomainsTableStore } from '@/stores/domain/domainsTableStore';
import type { BillingSubscription } from '@/types';
import { AmplitudeEvent, Route, SelectedDomainsList } from '@/types';
import { handleDomainResourceSetup } from '@/utils/services/domains/hDomains';

const isUserInitiatedTableActionInProgress = ref(false);

// This composable combines both managed and your own domains for the domains table
export const useCombinedDomainsTable = (sortedDomains?: IHDomainResource[]) => {
  const domainsTableStore = useDomainsTableStore();

  const router = useRouter();
  const { amplitudeV2 } = useGlobals();
  const { getIsManagedDomain } = useManagedDomains();
  const { enterImpersonationMode } = useImpersonation();
  const { getAccessIdByClientId } = useAccessList();
  const { isActive, isDomainResource } = useHDomainState();

  const domains = ref(sortedDomains || []);

  const isManagedExternalDomainsLoading = ref(false);

  const onHeaderDropdownChange = (value: SelectedDomainsList) => {
    amplitudeV2(AmplitudeEvent.Domain.FILTER_CHOSEN, { filterChosen: value });
    domainsTableStore.selectedDomainsList = value;
  };

  const getIsDomainVisible = (hDomainResource: IHDomainResource) => {
    const { selectedDomainsList } = domainsTableStore;

    if (selectedDomainsList === SelectedDomainsList.CLIENT_DOMAINS) {
      return getIsManagedDomain(hDomainResource);
    }

    if (selectedDomainsList === SelectedDomainsList.MY_DOMAINS) {
      return !getIsManagedDomain(hDomainResource);
    }

    return true;
  };

  const hasDomainsFilter = computed(() => {
    const hasManagedDomains = !!domains.value.some((item) =>
      getIsManagedDomain(item),
    );

    const hasOwnDomains = domains.value.some(
      (item) => !getIsManagedDomain(item),
    );

    return hasManagedDomains && hasOwnDomains;
  });

  const enterDomainImpersonationMode = async (
    hDomainResource: IHDomainResource,
    routeName: Route.Domain,
    routeQuery?: { [key: string]: string },
  ) => {
    const clientId = hDomainResource.customerCustomId;
    const queryObject = {
      name: routeName,
      redirectParams: {
        name: Route.Domain.MY_DOMAINS,
      },
      query: routeQuery,
      params: { domain: hDomainResource.title },
    };

    await enterImpersonationMode({
      accessId: getAccessIdByClientId(clientId),
      queryObject,
    });
  };

  const navigateToDomainSetup = async (hDomainResource: IHDomainResource) => {
    if (getIsManagedDomain(hDomainResource)) {
      await enterDomainImpersonationMode(
        hDomainResource,
        Route.Domain.MY_DOMAINS,
      );
    }

    await handleDomainResourceSetup(hDomainResource);
  };

  const navigateToManagedDomain = async (
    hDomainResource: IHDomainResource,
    subscription?: BillingSubscription,
  ) => {
    if (getIsManagedDomain(hDomainResource)) {
      await enterDomainImpersonationMode(
        hDomainResource,
        Route.Domain.DOMAIN_MANAGEMENT,
        {
          isAutoRenewalDisabled: '1',
        },
      );

      return;
    }

    router.push({
      name: Route.Domain.DOMAIN_MANAGEMENT,
      params: {
        domain: hDomainResource.title,
        subscriptionId: subscription?.id,
      },
    });
  };

  const navigateToDnsManagement = (hDomainResource: IHDomainResource) => {
    if (getIsManagedDomain(hDomainResource)) {
      enterDomainImpersonationMode(hDomainResource, Route.Domain.DOMAIN_DNS, {
        isAutoRenewalDisabled: '1',
      });

      return;
    }

    router.replace({
      name: Route.Domain.DOMAIN_DNS,
      params: {
        domain: hDomainResource.title,
      },
    });
  };

  const navigateToChangeContactDetails = (
    hDomainResource: IHDomainResource,
  ) => {
    if (getIsManagedDomain(hDomainResource)) {
      enterDomainImpersonationMode(
        hDomainResource,
        Route.Domain.DOMAIN_SETTINGS,
        {
          isAutoRenewalDisabled: '1',
        },
      );

      return;
    }

    router.replace({
      name: Route.Domain.DOMAIN_SETTINGS,
      params: {
        domain: hDomainResource.title,
      },
    });
  };

  const getIsDomainSelectCheckboxDisabled = (
    hDomainResource: IHDomainResource,
  ) => !isActive(hDomainResource) || !isDomainResource(hDomainResource);

  return {
    isManagedExternalDomainsLoading,
    navigateToManagedDomain,
    hasDomainsFilter,
    getIsDomainVisible,
    navigateToDomainSetup,
    onHeaderDropdownChange,
    navigateToDnsManagement,
    navigateToChangeContactDetails,
    isUserInitiatedTableActionInProgress,
    getIsDomainSelectCheckboxDisabled,
  };
};
