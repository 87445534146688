<template>
  <div
    v-tooltip="tooltip"
    class="h-list-item"
    :class="{
      'h-list-item--disabled': disabled,
      'h-list-item--danger': danger,
    }"
  >
    <div class="d-none d-md-block">
      <div v-if="simple">
        <slot />
      </div>
      <Component
        :is="getButtonType"
        v-else
        :id="id"
        :target="target"
        :to="to"
        :loading="loading"
        @click="clickItem"
      >
        <div class="d-flex">
          <slot name="listIcon" />
          <Component :is="getComponent">
            <slot />
          </Component>
        </div>
      </Component>
    </div>

    <HButtonV1
      :id="id"
      v-bind="$props"
      class="d-md-none d-flex"
      @click="clickItem"
    >
      <template #icon>
        <slot name="listIcon" />
      </template>
      <slot />
    </HButtonV1>
  </div>
</template>
<script>
import HListButton from './HListButton';
import HListLink from './HListLink';

import hButtonProps from '../../utils/mixins/hButtonProps';
import HButtonV1 from '../HButtons/HButtonV1';

export default {
  name: 'HListItem',
  components: {
    HListButton,
    HListLink,
    HButtonV1,
  },
  mixins: [hButtonProps],
  props: {
    simple: {
      type: Boolean,
      default: null,
    },
    to: {
      type: [Object, String, Boolean],
      default: null,
    },
    target: {
      type: [String, Boolean],
      default: null,
    },
    noTrans: Boolean,
    icon: String,
    brand: {
      type: Boolean,
      default: null,
    },
    danger: Boolean,
    loading: Boolean,
    disabled: Boolean,
    id: String,
    tooltip: String,
  },
  emits: ['click'],
  methods: {
    clickItem() {
      this.$emit('click');
    },
  },
  computed: {
    getButtonType() {
      return this.to ? 'h-list-link' : 'h-list-button';
    },
    getComponent() {
      return this.noTrans ? 'span' : 'trans';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../sass/components/h-list-item';
</style>
