import { detailsRepo } from '@/repositories';

export default {
  state: {
    dailyBackup: {},
    gsuite: {},
    flockMailPremium: {},
    flockMailPro: {},
    seoPro: {},
    sslInstall: {},
    loaded: false,
  },
  mutations: {
    SET_HOSTING_UPSELL_DATA(state, payload) {
      state.dailyBackup = payload['dailyBackup:dailyBackup'];
      state.flockMailPremium = payload['flockMail:premium'];
      state.flockMailPro = payload['flockMail:pro'];
      state.seoPro = payload['seo:pro'];
      state.sslInstall = payload['sslInstall:sslInstall'];
      state.loaded = true;
    },
  },
  getters: {
    getHostingUpsellGsuite(state) {
      return state.gsuite;
    },
    getHostingUpsellFlockMailPremium(state) {
      return state.flockMailPremium;
    },
    getHostingUpsellFlockMailPro(state) {
      return state.flockMailPro;
    },
    getHostingUpsellLoaded(state) {
      return state.loaded;
    },
  },
  actions: {
    async hostingUpsellsIndex({ commit }) {
      const [{ data }] = await detailsRepo.getHostingUpsellIndex();
      commit('SET_HOSTING_UPSELL_DATA', data || {});
    },
  },
};
