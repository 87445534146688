import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useAccessManagerStore } from '@/stores';
import { Route } from '@/types';
import { sanitizeUrl } from '@/utils/helpers';
import { authRedirectService } from '@/utils/services/http/authRedirectService';

const handleWebsiteBuilder = async (resourceId: string) => {
  const [{ data }] = await authRedirectService.redirectToBuilder(resourceId);
  window.open(data?.redirectUrl, '_self');
};

const isJSONParsable = (string: string) => {
  try {
    JSON.parse(decodeURIComponent(string));

    return true;
  } catch (error) {
    return false;
  }
};

export const useProPanelRedirect = () => {
  const router = useRouter();
  const accessManagerStore = useAccessManagerStore();
  const store = useStore();

  const handleProRedirect = async (queryString: string) => {
    if (!isJSONParsable(queryString)) {
      return router.push({ name: Route.Base.HOME });
    }

    const queryObject = JSON.parse(decodeURIComponent(queryString));

    const sanitizedQueryParams = sanitizeUrl({
      input: queryObject,
      isQueryObject: true,
    }) as Record<string, any>;

    if (sanitizedQueryParams.resourceId) {
      store.commit('SET_REDIRECT', { showGlobalAppLoader: true });
      await handleWebsiteBuilder(sanitizedQueryParams.resourceId);

      return;
    }

    if (sanitizedQueryParams.redirectParams?.name) {
      accessManagerStore.redirectParams = sanitizedQueryParams.redirectParams;
    }

    if (sanitizedQueryParams.name) {
      return router.push(sanitizedQueryParams);
    }

    return router.push({ name: Route.Base.HOME });
  };

  return {
    handleProRedirect,
  };
};
