import { whMalwareRepo } from '@/repositories';
import { router } from '@/router';

const getCurrentOrderDetails = (getters) => {
  const currentDomain = router.currentRoute.value.params.domain;
  const currentAccount = !currentDomain
    ? getters.getCurrentAccount
    : getters.getCurrentAccountWithDomain(currentDomain);

  const { username, domain } = currentAccount || {};

  return {
    username,
    domain,
  };
};

export default {
  state: {
    overview: null,
    metrics: null,
    list: null,
    loadingOverview: true,
    loadingMetrics: true,
    loadingList: true,
  },
  mutations: {
    SET_MALWARE_OVERVIEW(state, payload) {
      state.overview = payload;
    },
    SET_MALWARE_METRICS(state, payload = {}) {
      state.metrics = payload;
    },
    SET_MALWARE_LIST(state, payload) {
      state.list = payload;
    },
    SET_MALWARE_LOADING_OVERVIEW(state, payload) {
      state.loadingOverview = payload;
    },
    SET_MALWARE_LOADING_METRICS(state, payload) {
      state.loadingMetrics = payload;
    },
    SET_MALWARE_LOADING_LIST(state, payload) {
      state.loadingList = payload;
    },
  },
  getters: {
    getMalwareOverview: (state) => state.overview,
    getMalwareMetrics: (state) => state.metrics,
    getMalwareList: (state) => state.list,
    getMalwareLoadingOverviewStatus: (state) => state.loadingOverview,
    getMalwareLoadingMetricsStatus: (state) => state.loadingMetrics,
    getMalwareLoadingListStatus: (state) => state.loadingList,
  },
  actions: {
    async fetchMalwareOverview({ commit, getters }) {
      commit('SET_MALWARE_OVERVIEW', null);
      commit('SET_MALWARE_LIST', null);
      commit('SET_MALWARE_LOADING_OVERVIEW', true);
      const [{ data }, err] = await whMalwareRepo.getMalwareOverview(
        getCurrentOrderDetails(getters),
      );

      if (!err) {
        commit('SET_MALWARE_OVERVIEW', data);
      }
      commit('SET_MALWARE_LOADING_OVERVIEW', false);
    },
    async fetchMalwareMetrics({ commit, getters }) {
      commit('SET_MALWARE_METRICS');
      commit('SET_MALWARE_LOADING_METRICS', true);
      const [{ data }, err] = await whMalwareRepo.getMalwareMetrics(
        getCurrentOrderDetails(getters),
      );

      if (!err) {
        commit('SET_MALWARE_METRICS', data);
      }
      commit('SET_MALWARE_LOADING_METRICS', false);
    },
    async fetchMalwareList({ commit, getters }, params) {
      commit('SET_MALWARE_LOADING_LIST', true);
      const [{ data }, err] = await whMalwareRepo.getMalwareList(
        getCurrentOrderDetails(getters),
        params,
      );
      if (!err) {
        commit('SET_MALWARE_LIST', data);
      }
      commit('SET_MALWARE_LOADING_LIST', false);
    },
  },
};
