import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';

import { accountRepo } from '@/repositories';
import store from '@/store/index';
import { Route } from '@/types';

const setMainDomainGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const loaded = store.getters.getOrderDetailsLoaded;
  const details = store.getters.currentOrderDetails;

  const hostingAccount = {
    name: Route.Websites.WEBSITE_DASHBOARD,
    params: { domain: to.params.domain },
  };

  if (loaded && details) {
    return details.vhosts && details.vhosts.length > 1
      ? next()
      : next(hostingAccount);
  }

  const [{ data }, err] = await accountRepo.getAccountDetails({
    domain: to.params.domain as string,
  });

  return !err && data && data.orderVhosts.length > 1
    ? next()
    : next(hostingAccount);
};

export default setMainDomainGuard;
