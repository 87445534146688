import {
  BANDWIDTH_TOOLTIP,
  CPU_CORES_TOOLTIP,
  DAILY_BACKUPS_TOOLTIP,
  DEDICATED_IP_TOOLTIP,
  OBJECT_CACHE_TOOLTIP,
  STORAGE_TOOLTIP,
  VISITORS_TOOLTIP,
  WEBSITE_COUNT_TOOLTIP,
  WP_STAGING_TOOLTIP,
  WORDPRESS_AI_TOOLS_TOOLTIP,
  PHP_WORKERS_LIMIT_TOOLTIP,
  FILES_AND_DIRECTORIES_TOOLTIP,
  WEEKLY_BACKUPS_TOOLTIP,
  FREE_CDN_TOOLTIP,
  DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
  MYSQL_MAX_CONNECTIONS_TOOLTIP,
  PHP_WORKERS_TOOLTIP,
  STARTER_WOOCOMMERCE_TOOLTIP,
  ADVANCED_WOOCOMMERCE_TOOLTIP,
  EXPERT_WOOCOMMERCE_TOOLTIP,
  WORDPRESS_AUTOUPDATES_TOOLTIP,
  SUBDOMAIN_TOOLTIP,
  STANDARD_WOOCOMMERCE_TOOLTIP,
  BASIC_WOOCOMMERCE_TOOLTIP,
  WP_MULTISITE_TOOLTIP,
  WP_CLI_SSH_TOOLTIP,
} from './hostingPurchasePerks';
import { EMAIL_ACCOUNTS_TOOLTIP } from './hostingUpgradeRenewModalPerks';

import type { HostingUpgradePerks } from '@/types';
import { Hosting } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

export enum UpgradePerkID {
  DISK_SPACE = 'diskSpace',
  BANDWIDTH = 'bandwidth',
  DATABASES = 'database',
  WEBSITES = 'website',
  DAILY_BACKUPS = 'dailyBackups',
  VISITS_MONTHLY = 'visitsMonthly',
  EMAIL_ACCOUNTS = 'emailAccounts',
  WORDPRESS_STAGING = 'wpStaging',
  FREE_CDNS = 'freeCDNs',
  OBJECT_CACHE = 'objectCache',
  CPU_SPEED = 'cpuSpeed',
  CPU_CORES = 'cpuCores',
  PERFORMANCE = 'performance',
  RAM = 'ram',
  DEDICATED_IP = 'dedicatedIP',
  WORDPRESS_AI_TOOLS = 'wordpressAITools',
  PRIORITY_SUPPORT = 'prioritySupport',
  ENTRY_PROCESSES = 'entryProcesses',
  FILES_AND_DIRECTORIES = 'filesAndDirectories',
  PHP_WORKERS_LIMIT = 'phpWorkersLimit',
  DDOS_PROTECTION = 'ddosProtection',
  WOO_COMMERCE = 'wooCommerce',
  FREE_DOMAIN = 'freeDomain',
  FREE_SSL = 'freeSSL',
  WEBSITE_BUILDER = 'websiteBuilder',
  MANAGED_WORDPRESS = 'managedWordPress',
  WEBSITE_MIGRATION = 'websiteMigration',
  ON_DEMAND_BACKUP = 'onDemandBackup',
  BACKUPS = 'backups',
}

export enum UpgradePerk {
  DISK_SPACE = 'Storage',
  BANDWIDTH = 'Bandwidth',
  DATABASES = 'Databases',
  WEBSITES = 'Websites',
  DAILY_BACKUPS = 'Daily backups',
  VISITS_MONTHLY = 'Monthly visits',
  EMAIL_ACCOUNTS = 'Email accounts',
  WORDPRESS_STAGING = 'WordPress Staging Tool',
  FREE_CDNS = 'Free CDN',
  OBJECT_CACHE = 'Object cache for WordPress',
  CPU_SPEED = 'CPU speed',
  CPU_CORES = 'CPU cores',
  PERFORMANCE = 'Performance',
  RAM = 'RAM',
  DEDICATED_IP = 'Dedicated IP address',
  WORDPRESS_AI_TOOLS = 'WordPress AI tools',
  PRIORITY_SUPPORT = 'Priority support',
  ENTRY_PROCESSES = 'Entry processes',
  FILES_AND_DIRECTORIES = 'Files and directories (inodes)',
  PHP_WORKERS_LIMIT = 'PHP workers limit',
  DDOS_PROTECTION = 'DDoS Protection',
  WOO_COMMERCE = 'WooCommerce',
  FREE_DOMAIN = 'Free domain',
  FREE_SSL = 'Free SSL',
  WEBSITE_BUILDER = 'Website Builder',
  MANAGED_WORDPRESS = 'Managed WordPress',
  WEBSITE_MIGRATION = 'Website migration',
  ON_DEMAND_BACKUP = 'On-demand backup',
  BACKUPS = 'Backups',
}

export const HOSTING_UPGRADE_PERKS_V2: HostingUpgradePerks = {
  [Hosting.Plan.STARTER]: {
    upgradePaths: {
      [Hosting.Plan.PREMIUM]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SINGLE_WEBSITE,
              upgrade: Hosting.NewPerks.WEBSITES,
            },
            textValues: { current: '1', upgrade: '100' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.SSD_STORAGE,
            textValues: { current: 50, upgrade: 100 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '200 000', upgrade: '400 000' },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SINGLE_EMAIL,
              upgrade: Hosting.NewPerks.EMAIL,
            },
            textValues: { current: '', upgrade: 100 },
            tooltip: EMAIL_ACCOUNTS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.STARTER_WOOCOMMERCE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: STARTER_WOOCOMMERCE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_MULTISITE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_MULTISITE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WP_CLI_AND_SSH,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_CLI_SSH_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.SUBDOMAINS,
            textValues: { current: '2', upgrade: '100' },
            tooltip: SUBDOMAIN_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: 25, upgrade: 40 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
          },
        ],
      },
      [Hosting.Plan.BUSINESS]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SINGLE_WEBSITE,
              upgrade: Hosting.NewPerks.WEBSITES,
            },
            textValues: { current: 1, upgrade: 100 },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: 50, upgrade: 200 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '200 000', upgrade: '600 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SINGLE_EMAIL,
              upgrade: Hosting.NewPerks.EMAIL,
            },
            textValues: { current: '', upgrade: 100 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: EMAIL_ACCOUNTS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.BASIC_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: BASIC_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_ECONOMY]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SINGLE_WEBSITE,
              upgrade: Hosting.NewPerks.WEBSITES,
            },
            textValues: { current: 1, upgrade: 300 },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: 50, upgrade: 200 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '200 000', upgrade: '2 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SINGLE_EMAIL,
              upgrade: Hosting.NewPerks.EMAIL,
            },
            textValues: { current: '', upgrade: 100 },
            tooltip: EMAIL_ACCOUNTS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            tooltip: DEDICATED_IP_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: STANDARD_WOOCOMMERCE_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_PROFESSIONAL]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SINGLE_WEBSITE,
              upgrade: Hosting.NewPerks.WEBSITES,
            },
            textValues: { current: '1', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: 50, upgrade: 250 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '200 000', upgrade: '3 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SINGLE_EMAIL,
              upgrade: Hosting.NewPerks.EMAIL,
            },
            textValues: { current: '', upgrade: 100 },
            tooltip: EMAIL_ACCOUNTS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: ADVANCED_WOOCOMMERCE_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_ENTERPRISE]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SINGLE_WEBSITE,
              upgrade: Hosting.NewPerks.WEBSITES,
            },
            textValues: { current: '1', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: 50, upgrade: 300 },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '200 000', upgrade: '4 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SINGLE_EMAIL,
              upgrade: Hosting.NewPerks.EMAIL,
            },
            textValues: { current: '', upgrade: 100 },
            tooltip: EMAIL_ACCOUNTS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: EXPERT_WOOCOMMERCE_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
    },
  },
  [Hosting.Plan.PREMIUM]: {
    upgradePaths: {
      [Hosting.Plan.BUSINESS]: {
        perks: [
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: '100', upgrade: '200' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '400 000', upgrade: '600 000' },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.BASIC_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: BASIC_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            tooltip: {
              current: DAILY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_ECONOMY]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: '100', upgrade: '200' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '400 000', upgrade: '2 000 000' },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: STANDARD_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: DEDICATED_IP_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_PROFESSIONAL]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: '100', upgrade: '250' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '400 000', upgrade: '3 000 000' },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: ADVANCED_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: DEDICATED_IP_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FREE_CDN_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WP_STAGING_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: OBJECT_CACHE_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_ENTERPRISE]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.SSD_STORAGE,
              upgrade: Hosting.NewPerks.NVME_STORAGE,
            },
            textValues: { current: '100', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '400 000', upgrade: '4 000 000' },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STARTER_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STARTER_WOOCOMMERCE_TOOLTIP,
              upgrade: EXPERT_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.WEEKLY_BACKUPS,
              upgrade: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: WEEKLY_BACKUPS_TOOLTIP,
              upgrade: DAILY_ON_DEMAND_BACKUPS_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            tooltip: DEDICATED_IP_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FREE_CDN,
            tooltip: FREE_CDN_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
            tooltip: WP_STAGING_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.OBJECT_CACHE,
            tooltip: OBJECT_CACHE_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
            tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
              upgrade: Hosting.NewPerks.SMART_WP_UPDATES,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
              upgrade: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
        ],
      },
    },
  },
  [Hosting.Plan.BUSINESS]: {
    upgradePaths: {
      [Hosting.Plan.CLOUD_ECONOMY]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '200', upgrade: '200' },
            state: Hosting.PerkStatus.SUCCESS,
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '600 000', upgrade: '2 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.SUBDOMAINS,
            textValues: { current: '100', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: SUBDOMAIN_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.BASIC_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: BASIC_WOOCOMMERCE_TOOLTIP,
              upgrade: STANDARD_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: DEDICATED_IP_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '60', upgrade: '100' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '75', upgrade: '100' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
        ],
      },
      [Hosting.Plan.CLOUD_PROFESSIONAL]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '200', upgrade: '250' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '600 000', upgrade: '3 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.SUBDOMAINS,
            textValues: { current: '100', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: SUBDOMAIN_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.BASIC_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: BASIC_WOOCOMMERCE_TOOLTIP,
              upgrade: ADVANCED_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: DEDICATED_IP_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '60', upgrade: '200' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '75', upgrade: '125' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
        ],
      },
      [Hosting.Plan.CLOUD_ENTERPRISE]: {
        perks: [
          {
            text: Hosting.NewPerks.WEBSITES,
            textValues: { current: '100', upgrade: '300' },
            tooltip: WEBSITE_COUNT_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '200', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
            textValues: { current: '600 000', upgrade: '4 000 000' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.SUBDOMAINS,
            textValues: { current: '100', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: SUBDOMAIN_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.BASIC_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: BASIC_WOOCOMMERCE_TOOLTIP,
              upgrade: EXPERT_WOOCOMMERCE_TOOLTIP,
            },
          },
          {
            text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: DEDICATED_IP_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.PRIORITY_SUPPORT,
            state: {
              current: Hosting.PerkStatus.DANGER,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '60', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '75', upgrade: '150' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
        ],
      },
    },
  },
  [Hosting.Plan.CLOUD_ECONOMY]: {
    upgradePaths: {
      [Hosting.Plan.CLOUD_PROFESSIONAL]: {
        perks: [
          {
            text: Hosting.NewPerks.VISITORS,
            textValues: { current: '200 000', upgrade: '300 000' },
            tooltip: VISITORS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '200', upgrade: '250' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.RAM,
            textValues: { current: '3', upgrade: '6' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.CPU_CORES,
            textValues: { current: '2', upgrade: '4' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '100', upgrade: '200' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '100', upgrade: '125' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STANDARD_WOOCOMMERCE_TOOLTIP,
              upgrade: ADVANCED_WOOCOMMERCE_TOOLTIP,
            },
          },
        ],
      },
      [Hosting.Plan.CLOUD_ENTERPRISE]: {
        perks: [
          {
            text: Hosting.NewPerks.VISITORS,
            textValues: { current: '200 000', upgrade: '400 000' },
            tooltip: VISITORS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '200', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.RAM,
            textValues: { current: '3', upgrade: '12' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.CPU_CORES,
            textValues: { current: '2', upgrade: '6' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '100', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '100', upgrade: '150' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: STANDARD_WOOCOMMERCE_TOOLTIP,
              upgrade: EXPERT_WOOCOMMERCE_TOOLTIP,
            },
          },
        ],
      },
    },
  },
  [Hosting.Plan.CLOUD_PROFESSIONAL]: {
    upgradePaths: {
      [Hosting.Plan.CLOUD_ENTERPRISE]: {
        perks: [
          {
            text: Hosting.NewPerks.VISITORS,
            textValues: { current: '300 000', upgrade: '400 000' },
            tooltip: VISITORS_TOOLTIP,
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.NVME_STORAGE,
            textValues: { current: '250', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.RAM,
            textValues: { current: '6', upgrade: '12' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.CPU_CORES,
            textValues: { current: '4', upgrade: '6' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
          },
          {
            text: Hosting.NewPerks.PHP_WORKERS,
            textValues: { current: '200', upgrade: '300' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: PHP_WORKERS_TOOLTIP,
          },
          {
            text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
            textValues: { current: '125', upgrade: '150' },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
          },
          {
            text: {
              current: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
              upgrade: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
            },
            state: {
              current: Hosting.PerkStatus.WARNING,
              upgrade: Hosting.PerkStatus.SUCCESS,
            },
            tooltip: {
              current: ADVANCED_WOOCOMMERCE_TOOLTIP,
              upgrade: EXPERT_WOOCOMMERCE_TOOLTIP,
            },
          },
        ],
      },
    },
  },
};
export const HOSTING_UPGRADE_PERKS: any = {
  [Hosting.Plan.STARTER]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '50 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: { name: UpgradePerk.DATABASES, value: '2' },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '1',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'No',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '10 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '1',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'No',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'No' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'No',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '100%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '1',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Standard',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '0.768 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'No',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'No',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
  },

  [Hosting.Plan.PREMIUM]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '100 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '100',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'No',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '25 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'No',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'No' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'No',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '100%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '1',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Standard',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '1 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'No',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'No',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
    [UpgradePerkID.DDOS_PROTECTION]: {
      name: UpgradePerk.DDOS_PROTECTION,
      value: 'Standard',
    },
  },

  [Hosting.Plan.BUSINESS]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '200 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '100',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '100 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'Yes' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '200%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '2',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Increased (Up to 5x)',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '1.5 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'No',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'No',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '30',
    },
    [UpgradePerkID.DDOS_PROTECTION]: {
      name: UpgradePerk.DDOS_PROTECTION,
      value: 'Enhanced',
    },
    [UpgradePerkID.WOO_COMMERCE]: {
      name: UpgradePerk.WOO_COMMERCE,
      value: 'Standard',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '0.6 million',
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '60',
    },
  },

  [Hosting.Plan.CLOUD_ECONOMY]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '200 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '200 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'Yes' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '200%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '2',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '3 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'Yes',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '50',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '2 million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '100',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
    [UpgradePerkID.WOO_COMMERCE]: {
      name: UpgradePerk.WOO_COMMERCE,
      value: 'Advanced',
    },
  },

  [Hosting.Plan.CLOUD_PROFESSIONAL]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '250 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '300 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'Yes' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '400%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '4',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '6 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'Yes',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '100',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '2.8 million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '200',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
  },

  [Hosting.Plan.CLOUD_ENTERPRISE]: {
    [UpgradePerkID.DISK_SPACE]: {
      name: UpgradePerk.DISK_SPACE,
      value: '300 GB',
      tooltip: STORAGE_TOOLTIP,
    },
    [UpgradePerkID.BANDWIDTH]: {
      name: UpgradePerk.BANDWIDTH,
      value: 'Unlimited',
      tooltip: BANDWIDTH_TOOLTIP,
    },
    [UpgradePerkID.DATABASES]: {
      name: UpgradePerk.DATABASES,
      value: 'Unlimited',
    },
    [UpgradePerkID.WEBSITES]: {
      name: UpgradePerk.WEBSITES,
      value: '300',
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    [UpgradePerkID.DAILY_BACKUPS]: {
      name: UpgradePerk.DAILY_BACKUPS,
      value: 'Yes',
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    [UpgradePerkID.VISITS_MONTHLY]: {
      name: UpgradePerk.VISITS_MONTHLY,
      value: '400 000',
      tooltip: VISITORS_TOOLTIP,
    },
    [UpgradePerkID.EMAIL_ACCOUNTS]: {
      name: UpgradePerk.EMAIL_ACCOUNTS,
      value: '100',
    },
    [UpgradePerkID.WORDPRESS_STAGING]: {
      name: UpgradePerk.WORDPRESS_STAGING,
      value: 'Yes',
      tooltip: WP_STAGING_TOOLTIP,
    },
    [UpgradePerkID.FREE_CDNS]: { name: UpgradePerk.FREE_CDNS, value: 'Yes' },
    [UpgradePerkID.OBJECT_CACHE]: {
      name: UpgradePerk.OBJECT_CACHE,
      value: 'Yes',
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    [UpgradePerkID.CPU_SPEED]: { name: UpgradePerk.CPU_SPEED, value: '600%' },
    [UpgradePerkID.CPU_CORES]: {
      name: UpgradePerk.CPU_CORES,
      value: '6',
      tooltip: CPU_CORES_TOOLTIP,
    },
    [UpgradePerkID.PERFORMANCE]: {
      name: UpgradePerk.PERFORMANCE,
      value: 'Maximum (Up to 10x)',
    },
    [UpgradePerkID.RAM]: { name: UpgradePerk.RAM, value: '12 GB' },
    [UpgradePerkID.DEDICATED_IP]: {
      name: UpgradePerk.DEDICATED_IP,
      value: 'Yes',
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    [UpgradePerkID.WORDPRESS_AI_TOOLS]: {
      name: UpgradePerk.WORDPRESS_AI_TOOLS,
      value: 'Yes',
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    [UpgradePerkID.PRIORITY_SUPPORT]: {
      name: UpgradePerk.PRIORITY_SUPPORT,
      value: 'Yes',
    },
    [UpgradePerkID.ENTRY_PROCESSES]: {
      name: UpgradePerk.ENTRY_PROCESSES,
      value: '150',
    },
    [UpgradePerkID.FILES_AND_DIRECTORIES]: {
      name: UpgradePerk.FILES_AND_DIRECTORIES,
      value: '3.2 million',
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    [UpgradePerkID.PHP_WORKERS_LIMIT]: {
      name: UpgradePerk.PHP_WORKERS_LIMIT,
      value: '300',
      tooltip: PHP_WORKERS_LIMIT_TOOLTIP,
    },
  },
};

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_SINGLE] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.STARTER];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_PREMIUM] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.PREMIUM];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_BUSINESS] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.BUSINESS];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_CLOUD_ECONOMY] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_ECONOMY];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.CLOUD_PROFESSIONAL];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_STARTER] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.STARTER];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_PREMIUM] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.PREMIUM];

HOSTING_UPGRADE_PERKS[Hosting.Plan.WOO_BUSINESS] =
  HOSTING_UPGRADE_PERKS[Hosting.Plan.BUSINESS];

export const mapCommonPerksHostingPlanBySlug = (key: string) => {
  const HOSTING_SLUG_MAP = {
    [Hosting.Plan.STARTER]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.BUSINESS]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.CLOUD_ECONOMY]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.CLOUD_PROFESSIONAL]: Hosting.PlanSlug.CLOUD_PROFESSIONAL,
    [Hosting.Plan.CLOUD_ENTERPRISE]: Hosting.PlanSlug.CLOUD_ENTERPRISE,
    [Hosting.Plan.WORDPRESS_SINGLE]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WORDPRESS_SINGLE_SPECIAL]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WORDPRESS_PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WORDPRESS_PREMIUM_SPECIAL]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WORDPRESS_BUSINESS]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.WORDPRESS_BUSINESS_SPECIAL]: Hosting.PlanSlug.BUSINESS,
    [Hosting.Plan.WOO_STARTER]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WOO_PREMIUM]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_BUSINESS]: Hosting.PlanSlug.CLOUD_PROFESSIONAL,
    [Hosting.Plan.WORDPRESS_CLOUD_ECONOMY]: Hosting.PlanSlug.CLOUD_ECONOMY,
    [Hosting.Plan.WEBLINK_BRONZE]: Hosting.PlanSlug.STARTER,
    [Hosting.Plan.WEBLINK_PREMIUM]: Hosting.PlanSlug.PREMIUM,
    [Hosting.Plan.WEBLINK_BUSINESS]: Hosting.PlanSlug.BUSINESS,
    default: Hosting.PlanSlug.STARTER,
  };

  return mapKeyValue(HOSTING_SLUG_MAP, key);
};

export const mapCommonPerksHostingPlanByName = (key: string) => {
  const HOSTING_PLAN_MAP = {
    [Hosting.Plan.STARTER]: Hosting.Plan.STARTER,
    [Hosting.Plan.PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.BUSINESS]: Hosting.Plan.BUSINESS,
    [Hosting.Plan.CLOUD_ECONOMY]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.CLOUD_PROFESSIONAL]: Hosting.Plan.CLOUD_PROFESSIONAL,
    [Hosting.Plan.CLOUD_ENTERPRISE]: Hosting.Plan.CLOUD_ENTERPRISE,
    [Hosting.Plan.WORDPRESS_SINGLE]: Hosting.Plan.STARTER,
    [Hosting.Plan.WORDPRESS_PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WORDPRESS_BUSINESS]: Hosting.Plan.BUSINESS,
    [Hosting.Plan.WORDPRESS_CLOUD_ECONOMY]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_STARTER]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WOO_PREMIUM]: Hosting.Plan.CLOUD_ECONOMY,
    [Hosting.Plan.WOO_BUSINESS]: Hosting.Plan.CLOUD_PROFESSIONAL,
    [Hosting.Plan.WEBLINK_BRONZE]: Hosting.Plan.STARTER,
    [Hosting.Plan.WEBLINK_PREMIUM]: Hosting.Plan.PREMIUM,
    [Hosting.Plan.WEBLINK_BUSINESS]: Hosting.Plan.BUSINESS,
    default: Hosting.Plan.STARTER,
  };

  return mapKeyValue(HOSTING_PLAN_MAP, key);
};
